import React from 'react';
import InlineEditReadViewContainer from '@atlassian/jira-issue-field-inline-edit-read-view-container/src/ui/index.tsx';
import type { Props } from './types';

/**
 * Responsible for rendering a non-editable variant of the read view for an issue field. This provides consistent
 * container styles and spacing with an editable issue field.
 */
export const ReadViewNonEditable = ({
	children,
	hideEditButton,
	isLoading,
	readViewFitContainerHeight,
}: Props) => (
	<InlineEditReadViewContainer
		hideEditButton={hideEditButton}
		isLoading={isLoading}
		readViewFitContainerHeight={readViewFitContainerHeight}
	>
		{children}
	</InlineEditReadViewContainer>
);
