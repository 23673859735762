import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { CREATED } from '@atlassian/jira-platform-field-config';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

type Props = {
	issueKey: IssueKey;
};

const useCreatedDateField = ({ issueKey }: Props) => {
	const [value] = useFieldValue({ issueKey, fieldKey: CREATED });

	return [{ value }] as const;
};

export default useCreatedDateField;
