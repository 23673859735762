// eslint-disable-next-line import/order
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';

import { fireTrackAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-track-event.tsx';
import log from '@atlassian/jira-common-util-logging/src/log';

export const trackLoadedIssueView = (analyticsEvent: UIAnalyticsEvent, ratio: number | null) => {
	const eventPayload = {
		action: 'viewed',
		attributes: {
			...(ratio === null ? {} : { issueDetailsSidebarRatio: ratio }),
		},
	};

	try {
		fireTrackAnalytics(analyticsEvent, eventPayload);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		log.safeErrorWithoutCustomerData('issue.viewed', error.message, error);
	}
};
