import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State, ContextState, EditorState, ReactionsState } from './types';

type StoreApi = StoreActionApi<State>;

const setContextState =
	(contextState: ContextState) =>
	({ setState, getState }: StoreApi) => {
		const newState: State = {
			...getState(),
			context: contextState,
		};
		setState(newState);
	};

const setEditorState =
	(editorState: EditorState) =>
	({ setState, getState }: StoreApi) => {
		const newState: State = {
			...getState(),
			editor: editorState,
		};
		setState(newState);
	};

const setReactionsState =
	(reactionsState: ReactionsState) =>
	({ setState, getState }: StoreApi) => {
		const newState: State = {
			...getState(),
			reactions: reactionsState,
		};
		setState(newState);
	};

export const actions = { setContextState, setEditorState, setReactionsState } as const;
export type Actions = typeof actions;
