import React, { useCallback } from 'react';
import { useFragment, graphql } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { SingleLineTextInlineEditView } from '@atlassian/jira-issue-field-single-line-text-inline-edit-full/src/ui/single-line-text/index.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import { IssueViewFieldHeading } from '@atlassian/jira-issue-view-layout-field-heading/src/ui/index.tsx';
import type { ui_issueViewLayoutSingleLineTextField_IssueViewSingleLineTextField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutSingleLineTextField_IssueViewSingleLineTextField.graphql';

export interface IssueViewSingleLineTextFieldProps {
	area?: Area;
	fragmentKey: ui_issueViewLayoutSingleLineTextField_IssueViewSingleLineTextField$key;
}

export const IssueViewSingleLineTextField = ({
	area,
	fragmentKey,
}: IssueViewSingleLineTextFieldProps) => {
	const issueId = useIssueId();
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();

	const data = useFragment<ui_issueViewLayoutSingleLineTextField_IssueViewSingleLineTextField$key>(
		graphql`
			fragment ui_issueViewLayoutSingleLineTextField_IssueViewSingleLineTextField on JiraSingleLineTextField {
				...ui_issueViewLayoutFieldHeading_IssueViewFieldHeading
				...singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditView_fragmentRef
				fieldId
				type
				__typename
			}
		`,
		fragmentKey,
	);

	const onSubmit = useCallback(
		(value: string) => {
			issueId &&
				fieldChangeRequested(issueId, data.fieldId, value, undefined, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChangeRequested, issueId],
	);

	const onSubmitSucceeded = useCallback(
		(value: string) => {
			issueId &&
				fieldChanged(issueId, data.fieldId, value, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChanged, issueId],
	);

	const onSubmitFailed = useCallback(
		() => issueId && fieldChangeFailed(issueId, data.fieldId),
		[data.fieldId, fieldChangeFailed, issueId],
	);

	return (
		<Box testId={`issue.views.field.single-line-text-inline-edit.${data.fieldId}`}>
			<IssueViewFieldHeading
				area={area}
				fragmentKey={data}
				testId={`issue.issue-view-layout.issue-view-single-line-text-field.${data.fieldId}`}
			>
				<Box xcss={fieldWrapperStyles}>
					<SingleLineTextInlineEditView
						fragmentRef={data}
						onSubmit={onSubmit}
						onSubmitSucceeded={onSubmitSucceeded}
						onSubmitFailed={onSubmitFailed}
					/>
				</Box>
			</IssueViewFieldHeading>
		</Box>
	);
};

const fieldWrapperStyles = xcss({
	marginLeft: 'space.negative.100',
	marginRight: 'space.100',
});
