import React from 'react';
import { styled } from '@compiled/react';
// eslint-disable-next-line import/order
import { statusCategories } from '@atlassian/jira-common-constants';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import type { TimeTrackingState } from '@atlassian/jira-issue-shared-types/src/common/types/time-tracking-type.tsx';
import { ProgressTracker } from '@atlassian/jira-issue-view-common/src/component/progress-tracker/view.tsx';
import { useTimeTrackingConfiguration } from '@atlassian/jira-settings-service/src/main.tsx';

export type Props = {
	issueTimeTracking: TimeTrackingState;
	statusCategory: string | undefined;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ issueTimeTracking, statusCategory }: Props) => {
	const [config] = useTimeTrackingConfiguration();

	const { remainingEstimateSeconds, originalEstimateSeconds, timeSpentSeconds } = issueTimeTracking;

	const value = {
		loggedTime: timeSpentSeconds,
		remainingTime: remainingEstimateSeconds,
	};
	const estimatedTime = originalEstimateSeconds;
	const isDone = statusCategory === statusCategories.DONE;

	return (
		// eslint-disable-next-line jira/integration/test-id-by-folder-structure
		<ProgressTrackerContainer data-testid="issue.views.common.issue-line-card.issue-line-card.mini-time-log-graph">
			<ProgressTracker
				value={value}
				estimatedTime={estimatedTime}
				config={config}
				isDone={isDone}
				isCompact
				shouldHighlightOnHover
			/>
		</ProgressTrackerContainer>
	);
};
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ProgressTrackerContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 5}px`,
	position: 'relative',
});
