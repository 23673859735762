import type { ScreenTabDataPublic, ScreenTabsDataPublic } from '../types/screen-tabs.tsx';
import { isNotEmptyString } from '../utils/is-not-empty-string';

const isValidScreenTabId = (internalScreenTabs: ScreenTabsDataPublic, id: unknown): id is string =>
	isNotEmptyString(id) && internalScreenTabs.find((tab) => tab.id === id) !== undefined;

const isValidScreenTabIsActive = (isActive: unknown): isActive is boolean | undefined =>
	typeof isActive === 'boolean' || typeof isActive === 'undefined';

const isValidScreenTabIsVisible = (isVisible: unknown): isVisible is boolean | undefined =>
	typeof isVisible === 'boolean' || typeof isVisible === 'undefined';

const isValidScreenTabData = (
	internalScreenTabs: ScreenTabsDataPublic,
	{ id, isVisible, isActive }: { id: unknown; isVisible: unknown; isActive: unknown },
) =>
	isValidScreenTabId(internalScreenTabs, id) &&
	isValidScreenTabIsVisible(isVisible) &&
	isValidScreenTabIsActive(isActive);

const countNumberOfActiveScreenTabs = (acc: number, { isActive }: { isActive: unknown }) => {
	if (isValidScreenTabIsActive(isActive) && isActive) {
		return acc + 1;
	}
	return acc;
};

const countNumberOfHiddenActiveScreenTabs = (
	acc: number,
	{ isActive, isVisible }: { isActive: unknown; isVisible: unknown },
) => {
	if (
		isValidScreenTabIsActive(isActive) &&
		isActive &&
		isValidScreenTabIsVisible(isVisible) &&
		!isVisible
	) {
		return acc + 1;
	}

	return acc;
};

// takes a partial screenTabsArray from the iframe that has been validated and merges it with the internal state
export const screenTabsPublicToInternalTransformer = (
	internalScreenTabs: ScreenTabsDataPublic,
	screenTabsFromApp: ScreenTabsDataPublic,
): ScreenTabsDataPublic => {
	let numberOfActiveScreenTabs = 0;

	const internalActiveScreenTabIndex = internalScreenTabs.findIndex((tab) => tab.isActive === true);

	const screenTabsInternalUpdated = internalScreenTabs.map(
		(screenTabInternal: ScreenTabDataPublic) => {
			const screenTabFromApp = screenTabsFromApp.find((tab) => tab.id === screenTabInternal.id);
			if (!screenTabFromApp) {
				return screenTabInternal;
			}
			numberOfActiveScreenTabs += screenTabFromApp.isActive === true ? 1 : 0;
			return {
				id: screenTabInternal.id,
				isVisible: screenTabFromApp.isVisible ?? true,
				isActive: screenTabFromApp.isActive,
			};
		},
	);

	// if no active tab is specified. default to the first
	if (
		numberOfActiveScreenTabs === 0 &&
		internalActiveScreenTabIndex === -1 &&
		typeof screenTabsInternalUpdated[0]?.isActive !== 'undefined'
	) {
		screenTabsInternalUpdated[0].isActive = true;
	}
	return screenTabsInternalUpdated;
};

// checks that screenTabsFromApp can be transformed into the internal state
export const screenTabsPublicToInternalValidator = (
	internalScreenTabs: ScreenTabsDataPublic,
	screenTabsFromApp: unknown, // a partial screentabs data containing only changed tabs
): screenTabsFromApp is ScreenTabsDataPublic => {
	if (!Array.isArray(screenTabsFromApp)) {
		return false;
	}

	const screenTabsFromAppFiltered = screenTabsFromApp.filter((data) =>
		isValidScreenTabData(internalScreenTabs, data),
	);
	const numberOfActiveScreenTabs = screenTabsFromApp.reduce(countNumberOfActiveScreenTabs, 0);
	const numberOfHiddenActiveScreenTabs = screenTabsFromApp.reduce(
		countNumberOfHiddenActiveScreenTabs,
		0,
	);

	return (
		numberOfActiveScreenTabs <= 1 &&
		screenTabsFromApp.length === screenTabsFromAppFiltered.length &&
		numberOfHiddenActiveScreenTabs === 0
	);
};

// get validation errors when screenTabsFromApp cannot be transformed into the internal state
export const getScreenTabsPublicToInternalValidationErrors = (
	internalScreenTabs: ScreenTabsDataPublic | null,
	screenTabsFromApp: unknown, // a partial screentabs data containing only changed tabs
): string[] => {
	if (!internalScreenTabs) {
		return ['There are no screen tabs in the current view.'];
	}

	if (!Array.isArray(screenTabsFromApp)) {
		return ['Your app must return screen tabs as an array.'];
	}

	const errors = [];
	if (screenTabsFromApp.reduce(countNumberOfActiveScreenTabs, 0) > 1) {
		errors.push('Only one screen tab can be in focus.');
	}

	if (screenTabsFromApp.reduce(countNumberOfHiddenActiveScreenTabs, 0) > 0) {
		errors.push("You can't hide a screen tab that's currently in focus.");
	}

	return errors;
};
