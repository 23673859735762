import { toQuotedString } from '@atlassian/jira-common-jql/src/helpers';
import type { Label } from '@atlassian/jira-shared-types/src/rest/jira/label.tsx';

const CUSTOM_FIELD_PREFIX = 'customfield_';

// TODO: JIV-15082 look into whether the backend can handle these util functions
export const getJqlFieldId = (fieldId: string): string => {
	if (fieldId.startsWith(CUSTOM_FIELD_PREFIX)) {
		const customId = fieldId.substring(CUSTOM_FIELD_PREFIX.length, fieldId.length);

		return `cf[${customId}]`;
	}
	return fieldId;
};

export const getLabelHref = (fieldId: string, label: Label, baseSearchUrl = '/issues/'): string => {
	const jqlEncodedValue = encodeURIComponent(
		`${getJqlFieldId(fieldId)} = ${toQuotedString(label)}`,
	);

	return `${baseSearchUrl}?jql=${jqlEncodedValue}`;
};
