import { ff } from '@atlassian/jira-feature-flagging-using-meta';

const issueType = () => `
    ... on JiraIssueTypeField {
        fieldId
        issueType {
            issueTypeId
            name
            avatar {
                xsmall
            }
        }
    }
`;

const summary = () => `
    ... on JiraSingleLineTextField {
        fieldId
        text
    }
`;

const color = () => `
    issueColorField {
      color {
        colorKey
      }
    }
`;

const status = () => `
    ... on JiraStatusField {
      fieldId
      status {
        statusCategory{
          statusCategoryId
        }
      }
    }
`;

const fieldsByIdOld = () => `fieldsById(ids:["summary", "issuetype"]){
  edges{
    node{
      ${issueType()}
      ${summary()}
    }
  }
}`;

const fieldsById = () => `fieldsById(ids:["summary", "issuetype", "status"]){
  edges{
    node{
      ${issueType()}
      ${summary()}
      ${status()}
    }
  }
}`;

export const parentCandidatesForExistingIssueQuery = () => `
    jira {
      parentCandidatesWithInfoForExistingIssue(key: $issueKey, cloudId: $cloudId, searchTerm: $searchTerm, excludeDone: $excludeDone, first: $first) {
        parentCandidates {
          edges {
            node {
              issueId
              key
              ${color()}
              ${
								ff(
									'request-isdone-when-requesting-parent-candidates-and-display-in-parent-field_3becy',
								)
									? fieldsById()
									: fieldsByIdOld()
							}
            }
          }
        }
        message
      }
    }
`;
