import type { IntlShape } from '@atlassian/jira-intl';
import messages from './messages';

const phoneNumberRegex = /^\+?[\d ]+$/;
const formatRegex = /\s+/g;

export const validate =
	(formatMessage: IntlShape['formatMessage']) =>
	(currentValue: string): string | undefined => {
		if (currentValue === '') {
			return undefined;
		}
		if (!phoneNumberRegex.test(currentValue)) {
			return formatMessage(messages.invalidPhoneNumber);
		}

		return undefined;
	};

export const formatReadValue = (value?: string | null): string | undefined =>
	value?.replaceAll(formatRegex, '');
