import { getBufferReader } from './utils';

export async function* readJsonStream<T>(response: Response): AsyncGenerator<T> {
	const reader = getBufferReader(response);
	let doneStreaming = false;
	let remainder = '';
	do {
		try {
			const { value, done } = await reader.read();

			doneStreaming = done;
			if (value) {
				try {
					const processedChunks = `${remainder}${value}`.split('\n'); // assumes that all JSON chunks are separated by a new-line;

					remainder = '';
					for (let chunk of processedChunks) {
						try {
							yield JSON.parse(chunk);
						} catch (e) {
							// the chunk may be incomplete, so we'll save it for the next iteration.
							remainder = chunk;
						}
					}
				} catch (e) {
					const error = new Error(`Error Processing Stream Data: ${value}`);
					throw error;
				}
			}
		} catch (e) {
			// If we abort the call, this exception bubbles up, so we want to catch it and exit gracefully
			if (e instanceof DOMException && e.name === 'AbortError') {
				doneStreaming = true;
			} else {
				throw e;
			}
		}
	} while (!doneStreaming);
}
