import React, { useCallback } from 'react';
import { useFragment, graphql } from 'react-relay';
import { Box } from '@atlaskit/primitives';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { DateTimeInlineEditView } from '@atlassian/jira-issue-field-date-time-inline-edit-full/src/ui/date-time/index.tsx';
import type { AggJiraDateTime } from '@atlassian/jira-issue-field-date-time-inline-edit-full/src/ui/date-time/types.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import { IssueViewFieldHeading } from '@atlassian/jira-issue-view-layout-field-heading/src/ui/index.tsx';
import type { ui_issueViewLayoutDateTimeField_IssueViewDateTimeField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutDateTimeField_IssueViewDateTimeField.graphql';

export interface IssueViewDateTimeFieldProps {
	area?: Area;
	fragmentKey: ui_issueViewLayoutDateTimeField_IssueViewDateTimeField$key;
}

export const IssueViewDateTimeField = ({ area, fragmentKey }: IssueViewDateTimeFieldProps) => {
	const issueId = useIssueId();
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();

	const data = useFragment<ui_issueViewLayoutDateTimeField_IssueViewDateTimeField$key>(
		graphql`
			fragment ui_issueViewLayoutDateTimeField_IssueViewDateTimeField on JiraDateTimePickerField {
				...ui_issueViewLayoutFieldHeading_IssueViewFieldHeading
				...dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditView
				fieldId
				type
				__typename
			}
		`,
		fragmentKey,
	);

	const onSubmit = useCallback(
		(value: AggJiraDateTime) => {
			issueId &&
				fieldChangeRequested(issueId, data.fieldId, value, undefined, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChangeRequested, issueId],
	);

	const onSubmitSucceeded = useCallback(
		(value: AggJiraDateTime) => {
			issueId &&
				fieldChanged(issueId, data.fieldId, value, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChanged, issueId],
	);

	const onSubmitFailed = useCallback(
		() => issueId && fieldChangeFailed(issueId, data.fieldId),
		[data.fieldId, fieldChangeFailed, issueId],
	);

	return (
		<Box testId={`issue.views.field.date-inline-edit.${data.fieldId}`}>
			<IssueViewFieldHeading
				area={area}
				fragmentKey={data}
				testId={`issue.issue-view-layout.issue-view-date-time-field.${data.fieldId}`}
			>
				<Box
					// eslint-disable-next-line jira/integration/test-id-by-folder-structure
					testId="issue-field-date-time.ui.issue-field-date-time--container"
				>
					<DateTimeInlineEditView
						fragmentRef={data}
						onSubmit={onSubmit}
						onSubmitSucceeded={onSubmitSucceeded}
						onSubmitFailed={onSubmitFailed}
					/>
				</Box>
			</IssueViewFieldHeading>
		</Box>
	);
};
