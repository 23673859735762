import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { doc, p } from '@atlaskit/adf-utils/builders';
import type { Emoji } from './constants';

// NOTE:
// previously we tried to utilise doc(p(emoji(emo), ` ${message}`));
// however, we need to have emoji provider on this to get accurate emoji representation.
// fallback to emoji font icon for now.
// if this change, please update and refactor the useCannedCommentsAnalytics (we use string match for some analytics)
export const getCannedCommentContent = (message: string, emo: Emoji): ADF =>
	doc(p(`${emo.text} ${message}`));
