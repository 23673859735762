import { createSelector } from 'reselect';
import { getAllBranches } from '../branches';
import { getBuildProviders, getBuildsCount } from '../builds';
import { getAllCommitsCount, getAllRepositories } from '../commits';
import { getDeploymentsCount } from '../deployments';
import { getAllInstanceTypeDetails } from '../index';
import { getAllPullRequests } from '../pullrequests';
import { getRemoteLinksTotalCount } from '../remote-links';

export const getDetailsReceivedEventAttributes = createSelector(
	[
		getAllInstanceTypeDetails,
		getAllRepositories,
		getAllCommitsCount,
		getAllBranches,
		getAllPullRequests,
		getBuildProviders,
		getBuildsCount,
		getDeploymentsCount,
		getRemoteLinksTotalCount,
	],
	(
		instanceTypes,
		repositories,
		commitsCount,
		branches,
		pullrequests,
		buildProviders,
		buildsCount,
		deploymentsCount,
		remoteLinksCount,
	) => ({
		instanceTypeCount: instanceTypes.length,
		buildProvidersCount: buildProviders.length,
		reposCount: repositories.length,
		commitsCount,
		branchesCount: branches.length,
		pullrequestsCount: pullrequests.length,
		buildsCount,
		deploymentsCount,
		remoteLinksCount,
	}),
);
