import noop from 'lodash/noop';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { noopJiraUpdateService } from '../../../field/mapping/common';
import { archivedMapping } from './archived';
import { commentsCountMapping } from './comments';
import { connectionMapping } from './connection';
import { dateMapping } from './date';
import { documentMapping } from './document';
import { externalReferenceMapping } from './external-reference';
import { externalReferencePropertyMapping } from './external-reference-property';
import { intervalStringMapping } from './interval';
import { issueIdMapping } from './issue-id';
import { issueKeyMapping } from './issue-key';
import { issueTypeMapping } from './issue-type';
import { labelsMapping } from './labels';
import { linkedIssuesProgressMapping } from './linked-issues-progress';
import { linkedDeliveryIssuesStatusMapping } from './linked-issues-status';
import { numDataPointsMapping } from './num-data-points';
import { numberMapping } from './number';
import { optionMapping } from './option';
import { optionsMapping } from './options';
import { playMapping } from './play';
import { projectMapping } from './project';
import { reactionsMapping } from './reactions';
import { statusMapping } from './status';
import { stringMapping } from './string';
import type { FieldMapping } from './types';
import { userMapping } from './user';
import { usersMapping } from './users';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FieldMappingResponse = FieldMapping<any>;

const DEFAULT_MAPPING = {
	field: undefined,
	getValueFromJiraIssue: () => undefined,
	getFieldValueForJiraUpdate: <T,>(value: T | null | undefined): T | null =>
		value !== undefined ? value : null,
	setMutable: noop,
	setImmutable: <T,>(propertyMaps: T): T => propertyMaps,
	remove: <T,>(propertyMaps: T): T => propertyMaps,
	modifyImmutableIfMultiValueField: <T,>(propertyMaps: T): T => propertyMaps,
	comparator: () => 0,
	valueAccessor: () => undefined,
	valueAccessorToExport: () => '',
	getAllValues: () => ({}),
	getGroupIdentities: () => [],
	getGroupIdentitiesSelector: () => () => ({}),
	rollupOperations: () => [],
	allowEmptyGroup: false,
	isMultiValueField: false,
	isSupportedByIssueUpdateApi: false,
	getLabel: () => undefined,
	getFilter: () => undefined,
	...noopJiraUpdateService,
} as const;

export const fieldMapping = (
	issuesRemote: IssuesRemote,
	allFields: Field[],
	field?: Field,
): FieldMappingResponse => {
	switch (field?.type) {
		case FIELD_TYPES.ISSUE_TYPE:
			return issueTypeMapping(issuesRemote, field);
		case FIELD_TYPES.ISSUE_KEY:
			return issueKeyMapping(issuesRemote, field);
		case FIELD_TYPES.ISSUE_ID:
			return issueIdMapping(issuesRemote, field);
		case FIELD_TYPES.DATE:
		case FIELD_TYPES.UPDATED:
		case FIELD_TYPES.CREATED:
			return dateMapping(issuesRemote, field);
		case FIELD_TYPES.SUMMARY:
		case FIELD_TYPES.SHORT_TEXT:
		case FIELD_TYPES.HYPERLINK:
		case FIELD_TYPES.ARCHIVED_ON:
			return stringMapping(issuesRemote, field);
		case FIELD_TYPES.ASSIGNEE:
		case FIELD_TYPES.CREATOR:
		case FIELD_TYPES.REPORTER:
			return userMapping(issuesRemote, field);
		case FIELD_TYPES.PEOPLE:
		case FIELD_TYPES.JSW_PEOPLE:
		case FIELD_TYPES.ARCHIVED_BY:
			return usersMapping(issuesRemote, field);
		case FIELD_TYPES.ARCHIVED:
			return archivedMapping(issuesRemote, field);
		case FIELD_TYPES.SINGLE_SELECT:
			return optionMapping(issuesRemote, field);
		case FIELD_TYPES.MULTI_SELECT:
		case FIELD_TYPES.JSW_MULTI_SELECT:
			return optionsMapping(issuesRemote, field);
		case FIELD_TYPES.LABELS:
		case FIELD_TYPES.CUSTOM_LABELS:
			return labelsMapping(issuesRemote, field);
		case FIELD_TYPES.ATLAS_GOAL:
		case FIELD_TYPES.ATLAS_PROJECT:
			return externalReferenceMapping(issuesRemote, field);
		case FIELD_TYPES.ATLAS_PROJECT_STATUS:
			return externalReferencePropertyMapping(issuesRemote, field, allFields);
		case FIELD_TYPES.INSIGHTS:
			return numDataPointsMapping(issuesRemote, field);
		case FIELD_TYPES.NUMBER:
		case FIELD_TYPES.SLIDER:
		case FIELD_TYPES.RATING:
		case FIELD_TYPES.CHECKBOX:
		case FIELD_TYPES.FORMULA:
		case FIELD_TYPES.LINKED_ISSUES:
			return numberMapping(issuesRemote, field);
		case FIELD_TYPES.STATUS:
			return statusMapping(issuesRemote, field);
		case FIELD_TYPES.DELIVERY_STATUS:
			return linkedDeliveryIssuesStatusMapping(field);
		case FIELD_TYPES.DELIVERY_PROGRESS:
			return linkedIssuesProgressMapping(field);
		case FIELD_TYPES.DESCRIPTION:
			return documentMapping(issuesRemote, field);
		case FIELD_TYPES.ISSUE_COMMENTS:
			return commentsCountMapping(issuesRemote, field);
		case FIELD_TYPES.VOTES:
			return playMapping(issuesRemote, field);
		case FIELD_TYPES.INTERVAL:
			return intervalStringMapping(issuesRemote, field, allFields);
		case FIELD_TYPES.REACTIONS:
			return reactionsMapping(issuesRemote, field);
		case FIELD_TYPES.PROJECT:
			return projectMapping(field);
		case FIELD_TYPES.CONNECTION:
			return connectionMapping(field);
		default:
			return DEFAULT_MAPPING;
	}
};
