import React, { memo } from 'react';
import { styled } from '@compiled/react';
import IssueClassicStoryPointField from '@atlassian/jira-issue-field-classic-story-point/src/ui/index.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading';
import {
	FieldHeadingTitle,
	SideBySideField,
	FieldWrapper,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin';
import type { Props } from './types';

export const View = ({
	issueKey,
	fieldId: fieldKey,
	showPinButton,
	issueId,
	label,
	noValueText,
	onUpdate,
}: Props) => (
	<StoryPointsFieldWrapper>
		<FieldHeading>
			<FieldHeadingTitle>{label}</FieldHeadingTitle>
			{showPinButton === true && <FieldPin fieldId={fieldKey} label={label} />}
		</FieldHeading>
		<SideBySideField>
			<IssueClassicStoryPointField
				label={label}
				issueKey={issueKey}
				issueId={issueId}
				fieldKey={fieldKey}
				noValueText={noValueText}
				isFitContainerWidthReadView
				onUpdate={onUpdate}
			/>
		</SideBySideField>
	</StoryPointsFieldWrapper>
);

export default memo<Props>(View);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StoryPointsFieldWrapper = styled(FieldWrapper)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div > div > div > div > div > div > div': {
		width: '100%',
	},
});
