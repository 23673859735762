import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { getSupportsIssueCreation, isCompatibleSortingPanel } from '../../state/context/selectors';
import { getAllIssues } from '../../state/entities/selectors';
import type { State } from '../../state/types';
import AddChild from './add-child';
import Meatballs from './meatballs';
import SortByMenu from './sort-by-menu';
import Title from './title';
import Heading from './view';

export default flowWithSafeComponent(
	connect(
		(state: State) => ({
			Title,
			Meatballs,
			AddChild,
			SortByMenu,
			allIssues: getAllIssues(state),
			supportsIssueCreation: getSupportsIssueCreation(state),
			isSortingAvailable: isCompatibleSortingPanel(state),
		}),
		{},
	),
)(Heading);
