import React from 'react';
import ClockIcon from '@atlaskit/icon/core/migration/clock--emoji-frequent';
import { getCommandPaletteIssueActionsPriority } from '@atlassian/jira-command-palette-common/src/common/utils/get-command-palette-issue-actions-priority/index.tsx';
import { getCommandPaletteIssueActionsHeader } from '@atlassian/jira-command-palette-common/src/common/utils/index.tsx';
import { useCommandKeywords } from '@atlassian/jira-command-palette-common/src/controllers/use-command-keywords/index.tsx';
import { COMMAND_PALETTE_REGISTRY_IDS } from '@atlassian/jira-command-palette-registry/src/common/constants/registry/index.tsx';
import { RegisterCommands } from '@atlassian/jira-command-palette-registry/src/ui/register-commands/index.tsx';
import {
	type Command,
	CommandActionType,
} from '@atlassian/jira-command-palette/src/common/types/commands/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { ISSUE_LOG_WORK_PREFIX } from './constants';
import messages from './messages';

type Props = { onEditRequest: () => void };
export const RegisterTimeTrackingCommandPalette = ({ onEditRequest }: Props) => {
	const issueKey = useIssueKey();
	const { formatMessage } = useIntl();

	const { getKeywords } = useCommandKeywords();

	const commands: Command[] = [
		{
			id: `${ISSUE_LOG_WORK_PREFIX}-${issueKey}`,
			name: formatMessage(messages.logWork),
			keywords: getKeywords('logWorkSynonyms'),
			primaryAction: {
				type: CommandActionType.PERFORM,
				perform: () => setTimeout(onEditRequest, 0),
			},
			shortcut: 'q',
			section: getCommandPaletteIssueActionsHeader(issueKey),
			priority: getCommandPaletteIssueActionsPriority('ISSUE_HEADER_ITEMS'),
			components: {
				LeftIcon: () => <ClockIcon color="currentColor" label={formatMessage(messages.logWork)} />,
			},
			analytics: {
				action: 'logWork',
			},
		},
	];

	return (
		<RegisterCommands
			registrationId={COMMAND_PALETTE_REGISTRY_IDS.LOG_WORK}
			commands={commands}
			deps={[issueKey]}
		/>
	);
};
