import React from 'react';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { SERVICEDESK_REQUEST_PANEL } from '@atlassian/jira-issue-view-common-constants/src/onboarding-constants';
import { connect } from '@atlassian/jira-react-redux';
import RequestDetailsPanel, { type StateProps, type Props } from './main';

export const RequestDetailsPanelWithSpotlight = (props: Props) => (
	<SpotlightTarget name={SERVICEDESK_REQUEST_PANEL}>
		<RequestDetailsPanel {...props} />
	</SpotlightTarget>
);

export default connect(
	(state): StateProps => ({
		// Logic from isLoadingSelector in @atlassian/jira-issue-view/src/common/state/selectors/issue-selector
		// @ts-expect-error - TS2339 - Property 'ui' does not exist on type '{}'.
		isIssueViewLoading: state.ui.loadingStage === 'LOADING',
	}),
	{},
)(RequestDetailsPanelWithSpotlight);
