import React, { memo, type ChangeEvent } from 'react';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { Box, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { Y300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { NUMBER_WARNING_ICON_TEST_ID } from '../../common/constants';
// eslint-disable-next-line import/order
import type { NumberValue } from '../../common/types';

import messages from '../messages';

type Props = {
	isInvalid?: boolean;
	type: string;
	defaultValue: NumberValue;
	onChange?: (arg1: ChangeEvent<HTMLInputElement>) => void;
};

export const NumberEdit = memo<Props>((props: Props) => {
	const { isInvalid = false, type, defaultValue, ...rest } = props;
	const { formatMessage } = useIntl();

	const value = defaultValue === null ? '' : Number(defaultValue);

	return (
		<Box xcss={containerStyles}>
			<Textfield
				isCompact
				aria-label={formatMessage(messages.accessibleLabel)}
				isInvalid={isInvalid}
				type={type}
				value={value}
				step="any"
				autoFocus
				elemAfterInput={
					isInvalid === true ? (
						<Box xcss={warningWrapperStyles} testId={NUMBER_WARNING_ICON_TEST_ID}>
							<WarningIcon
								size="medium"
								primaryColor={token('color.icon.warning', Y300)}
								label=""
							/>
						</Box>
					) : null
				}
				{...rest}
			/>
		</Box>
	);
});

const containerStyles = xcss({
	width: '100%',
	cursor: 'auto',
});

const warningWrapperStyles = xcss({
	marginLeft: 'space.negative.050',
	marginRight: 'space.050',
});
