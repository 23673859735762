import type { InlineExtensionDefinition, DocNode as ADF } from '@atlaskit/adf-schema';
import { filter, traverse } from '@atlaskit/adf-utils/traverse';
import { type EditorActions, setTextSelection } from '@atlaskit/editor-core';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { TEMPLATE_VARIABLE_EXTENSION_KEY } from '@atlassian/jira-template-variable-editor-extension/src/ui/constants.tsx';
import { type IssueViewData, type IssueViewDataKeys, ADFNode } from './types';

const DOC_TYPE = 'doc';
const PARAGRAPH_NODE_TYPE = 'paragraph';

export const extractNodesFromFirstParagraph = (document: ADF | InlineExtensionDefinition) => {
	if (
		document &&
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.type === DOC_TYPE &&
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		Array.isArray(document.content) &&
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.content.length &&
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.content[0].type === PARAGRAPH_NODE_TYPE
	) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const firstNodeContent = document.content[0].content;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const contentWithoutFirstParagraph = document.content.slice(1);
		if (Array.isArray(firstNodeContent) && Array.isArray(contentWithoutFirstParagraph)) {
			const newContent =
				Array.isArray(firstNodeContent) && Array.isArray(contentWithoutFirstParagraph)
					? [...firstNodeContent, ...contentWithoutFirstParagraph]
					: [];
			const formattedDocument = { ...document, content: newContent };
			return formattedDocument;
		}
	}
	return document;
};
export const replaceTemplateVariables = (document: ADF, issueData: IssueViewData): ADF => {
	const updatedDoc = traverse(document, {
		inlineExtension: (extNode) => {
			if (extNode.attrs) {
				const { extensionKey, text, parameters } = extNode.attrs;
				if (extensionKey === TEMPLATE_VARIABLE_EXTENSION_KEY) {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					if (!issueData[text as IssueViewDataKeys]) {
						return false;
					}

					switch (parameters?.type) {
						case ADFNode.INLINE_CARD:
							return {
								type: 'inlineCard',
								attrs: {
									// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
									url: issueData[text as IssueViewDataKeys],
								},
							};
						case ADFNode.TEXT:
							return {
								type: 'text',
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								text: issueData[text as IssueViewDataKeys],
							};
						default:
							return {
								type: 'text',
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								text: issueData[text as IssueViewDataKeys],
							};
					}
				}
			}
			return extNode;
		},
	});
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return updatedDoc as ADF;
};

export const insertADFNodesInEditor = (
	doc: ADF | InlineExtensionDefinition,
	editorActions?: EditorActions,
): void => {
	if (editorActions) {
		const editorView = editorActions._privateGetEditorView();

		if (editorView) {
			try {
				editorView.focus();
				const { selection, schema, tr } = editorView.state || {};
				const { from, to } = selection;
				const formattedDoc = extractNodesFromFirstParagraph(doc);
				const cannedResponseNodes = schema.nodeFromJSON(formattedDoc);
				const replacementTextLength = cannedResponseNodes.content.size
					? from + cannedResponseNodes.content.size
					: from + 1;
				const emptyPara = schema.nodeFromJSON({ type: 'text', text: ' ' });
				editorView.dispatch(tr.replaceRangeWith(from, to, cannedResponseNodes));
				setTextSelection(editorView, replacementTextLength);
				editorView.dispatch(
					editorView.state.tr.insert(replacementTextLength, emptyPara).scrollIntoView(),
				);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				fireErrorAnalytics({
					error,
					meta: {
						id: 'insertADFNodesInEditor',
					},
				});
			}
		}
	}
};

export const insertCannedResponseInEditor = (
	doc: ADF,
	issueData: IssueViewData,
	editorActions?: EditorActions,
): void => {
	const replacedContent = replaceTemplateVariables(doc, issueData);
	if (replacedContent) {
		insertADFNodesInEditor(replacedContent, editorActions);
	}
};

export const focusEditor = (editorActions?: EditorActions): void => {
	if (editorActions) {
		const editorView = editorActions._privateGetEditorView();
		if (editorView) {
			editorView.focus();
			const { selection } = editorView.state || {};
			const { from } = selection;
			setTextSelection(editorView, from);
		}
	}
};

export const hasTemplateVariables = (document: ADF): boolean => {
	const links = filter(
		document,
		(node) =>
			!!(
				node.type === 'inlineExtension' &&
				node.attrs?.extensionKey === TEMPLATE_VARIABLE_EXTENSION_KEY
			),
	);

	return !!links.length;
};
