import React from 'react';
import { ErrorBoundaryFlag as ErrorBoundary } from '@atlassian/jira-error-boundary-flag-renderer';
import { useIntl } from '@atlassian/jira-intl';
import MetaDate from '@atlassian/jira-issue-meta-date/src/main.tsx';
import { RESOLVED_DATE_TEST_ID } from '../../common/constants';
import messages from './messages';

type Props = {
	date: string;
};

export const ResolvedDate = ({ date }: Props) => {
	const { formatMessage } = useIntl();
	return date !== null && date !== '' ? (
		<ErrorBoundary>
			<MetaDate
				testId={RESOLVED_DATE_TEST_ID}
				date={date}
				text={formatMessage(messages.resolvedLabel)}
			/>
		</ErrorBoundary>
	) : null;
};
