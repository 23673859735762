import { useCallback } from 'react';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { getAriConfig } from '@atlassian/jira-platform-ari';
import type { InvitationCapabilitiesV3Response } from './types';

export const useCanInvite = () =>
	useCallback((cloudId: string, product: string) => canInvite(cloudId, product), []);

export const getInvitationCapabilitiesV3Url = (cloudId: string): string =>
	`/gateway/api/v3/invitations/capabilities?resource=ari:cloud:platform::site/${cloudId}`;

export const canInvite = async (cloudId: string, product: string): Promise<boolean> => {
	const capabilities = await fetchJson<InvitationCapabilitiesV3Response[]>(
		getInvitationCapabilitiesV3Url(cloudId),
	);

	const capability = capabilities.find((cap) => {
		const ari = getAriConfig(cap.resourceARI);
		return ari.resourceOwner === product;
	});

	if (!capability) return false;

	return (
		capability.directInvite?.mode === 'ANYONE' ||
		capability.invitePendingApproval?.mode === 'ANYONE'
	);
};
