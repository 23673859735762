import type { Sender } from 'xstate';

import { Fragment, type Node as PMNode } from '@atlaskit/editor-prosemirror/model';
import { convertMarkdownToProsemirror } from '@atlassian/ai-model-io/convert-markdown-to-prosemirror';
import type { MultiPromptType } from '@atlassian/editor-ai-common/src/utils/multiPrompts';

import { CONFIG_ITEM_KEYS } from '../config-items/config-item-keys';
import { isInitialContextSelectionRange, type Positions } from '../config-items/config-items';
import { getMentionMap } from '../utils/mentions/get-mention-map';

import type {
	AIExperienceMachineContext,
	AIExperienceMachineEvent,
} from './get-ai-experience-service';

/**
 * This is responsible for processing the parsed data from
 * streamResponseParser() and passing data into the
 * AIExperienceMachine over in createAIExperienceMachine().
 */
export function streamingService(
	context: AIExperienceMachineContext,
	event: AIExperienceMachineEvent,
) {
	async function callbackHandler(callback: Sender<AIExperienceMachineEvent>) {
		const abortController = new AbortController();

		/**
		 * Vary prompt request triggered - if latest response exists, we are interrogating,
		 * and do not want to use the original config item (i.e. may possibly be summarise/transform)
		 *
		 * If it is a generate with tag, the response will be captured within
		 * the latest "latest response, prompt", flow.
		 *
		 * REVISIT THIS: analytics is based on the config item, however
		 * interrogation analytics uses base generate instead to complete;
		 */
		let configItemToUse = context.configItem;
		if (context.baseGenerate && context.latestPromptResponse) {
			// If there is a latestPromptResponse and the context.configItem has a key 'Rovo Agent',
			// then we should use the context.configItem so we can interrogate with the Rovo Agent
			configItemToUse =
				context.configItem && context.configItem.key === CONFIG_ITEM_KEYS.ROVO_AGENT
					? context.configItem
					: context.baseGenerate;
		}

		/**
		 * Get mention map for the current document
		 * We can no longer use the node attrs to get the mention name
		 * So we now need to get all the mentions and create a mapping that can be used by the
		 * markdown serializer
		 */
		let mentionMap = {};
		if (context.getMentionNameDetails) {
			mentionMap = await getMentionMap({
				node: context.editorView.state.doc,
				getMentionNameDetails: context.getMentionNameDetails,
			});
		}

		const initialContext = configItemToUse.getInitialContext?.({
			editorView: context.editorView,
			positions: context.positions,
			intl: context.intl,
			updateIdMap: ({ idMap, selectionType }) => {
				callback({ type: 'update idMap', context: { idMap, selectionType } });
			},
			mentionMap,
		});

		if (initialContext?.contentStatistics) {
			callback({
				type: 'storeContextStatistics',
				contextStatistics: { inputDocument: initialContext.contentStatistics },
			});
		}

		if (!initialContext) {
			throw new Error('Initial context is undefined');
		}

		const promptRequest = configItemToUse.triggerPromptRequest({
			analyticsContext: context.analyticsContext,
			initialContext,
			promptInput: context.userInput,
			promptInputADF: context.userADFInput,
			latestPromptResponse: context.latestPromptResponse || undefined,
			formatMessage: context.intl.formatMessage,
			editorSchema: context.editorView.state.schema,
		});

		const cacheKey = JSON.stringify({
			initialContext,
			userInput: context.userInput,
			agent: context.configItem.agent,
			//TODO: multiPrompts experiment cleanup - editor_ai_-_multi_prompts
			multiPrompt: context.multiPrompts,
		});

		// Right now we only ever have one object in history as we do not
		// yet support traversing the history object array. This is also to
		// prevent us saving large documents into history multiple times.
		const lastHistoryItem = context.responseHistory.entries[0];
		if (lastHistoryItem && cacheKey === lastHistoryItem.cacheKey) {
			callback({
				type: 'same cache key',
			});
			return;
		}

		const streaming = promptRequest({
			abortController,
			// Note -- when we collapse to a single "prompt request" with "api v2"
			// this would be a good opportunity to group these and pass them as a group
			generativeAIApiUrl: context.aiProvider.generativeAIApiUrl,
			product: context.aiProvider.product,
			getFetchCustomHeaders: context.aiProvider.getFetchCustomHeaders,
			channelId: context.channelId,
		});

		//TODO: multiPrompts experiment cleanup - editor_ai_-_multi_prompts
		const additionalPromptRequest = configItemToUse.additionalTriggerPromptRequest?.({
			analyticsContext: context.analyticsContext,
			initialContext,
			promptInput: context.userInput,
			promptInputADF: context.userADFInput,
			latestPromptResponse: context.latestPromptResponse || undefined,
			formatMessage: context.intl.formatMessage,
			editorSchema: context.editorView.state.schema,
		});

		const additionalStreaming = additionalPromptRequest?.({
			abortController,
			// Note -- when we collapse to a single "prompt request" with "api v2"
			// this would be a good opportunity to group these and pass them as a group
			generativeAIApiUrl: context.aiProvider.generativeAIApiUrl,
			product: context.aiProvider.product,
			getFetchCustomHeaders: context.aiProvider.getFetchCustomHeaders,
			channelId: context.channelId,
		});

		if (additionalStreaming && additionalPromptRequest) {
			callback({
				type: 'handle additional prompts',
				multiPrompts: [],
			});
		}

		(async function () {
			for await (const streamItem of streaming) {
				if (streamItem.state === 'loaded') {
					const rovoActions = streamItem.data.rovoActions || [];
					let markdown = streamItem.data.content;

					if (rovoActions.length > 0) {
						const { selectionType } = context.configItem;
						const idealSuggestion = selectionType === 'range' ? 'replace' : 'insert';
						const action =
							rovoActions.find((x) => x.data.suggestion === idealSuggestion) ?? rovoActions[0];

						// There is a scenario where we are in selectionType === 'empty'
						// but AI returns replace. In this case, we override the replace
						// suggestion with insert.
						if (selectionType === 'empty' && action.data.suggestion === 'replace') {
							// This should also update the value by reference in rovoActions
							action.data.suggestion = 'insert';
						}
						// The opposite can happen too
						else if (selectionType === 'range' && action.data.suggestion === 'insert') {
							// This should also update the value by reference in rovoActions
							action.data.suggestion = 'replace';
						}

						markdown += `\n\n---\n\n${action.data.content}`;
					}

					// loading done on success
					callback({
						type: 'complete',
						markdown,
						cacheKey,
						modelInput: {
							selection: isInitialContextSelectionRange(initialContext)
								? initialContext.selection
								: undefined,
						},
						inputOutputDiffRatio: streamItem.data.meta?.inputOutputDiffRatio,
						rovoActions,
					});
					continue;
				} else if (streamItem.state === 'failed') {
					//loading done on api failed

					// If the error comes from ConvoAI
					if ('apiName' in streamItem && streamItem.apiName === 'assistance-service') {
						if (
							['RATE_LIMIT', 'OPENAI_RATE_LIMIT_USER_ABUSE'].includes(streamItem.guard) &&
							'retryAfter' in streamItem
						) {
							callback({
								type: 'rate limited',
								retryAfter: streamItem.retryAfter!,
								errorInfo: {
									statusCode: streamItem.statusCode,
									errorKey: streamItem.guard,
									apiName: streamItem.apiName,
									errorContent: 'error' in streamItem ? streamItem.error : undefined,
								},
							});
							continue;
						}

						if (streamItem.guard === 'HIPAA_CONTENT_DETECTED') {
							callback({
								type: 'hipaa content fail',
								errorInfo: {
									statusCode: streamItem.statusCode,
									errorKey: streamItem.guard,
									apiName: streamItem.apiName,
									errorContent: 'error' in streamItem ? streamItem.error : undefined,
								},
							});
							continue;
						}

						if (streamItem.guard === 'EXCEEDING_CONTEXT_LENGTH_ERROR') {
							callback({
								type: 'token limit fail',
								errorInfo: {
									statusCode: streamItem.statusCode,
									errorKey: streamItem.guard,
									apiName: streamItem.apiName,
									errorContent: 'error' in streamItem ? streamItem.error : undefined,
								},
							});
							continue;
						}

						if (streamItem.guard === 'ACCEPTABLE_USE_VIOLATIONS') {
							callback({
								type: 'AUP violation detected',
								cacheKey,
								modelInput: {
									selection: isInitialContextSelectionRange(initialContext)
										? initialContext.selection
										: undefined,
								},
								errorInfo: {
									statusCode: streamItem.statusCode,
									errorKey: streamItem.guard,
									apiName: streamItem.apiName,
									errorContent: 'error' in streamItem ? streamItem.error : undefined,
								},
							});
							continue;
						}

						// All other errors from ConvoAI
						callback({
							type: 'api fail',
							errorInfo: {
								statusCode: streamItem.statusCode,
								errorKey: streamItem.guard,
								apiName: streamItem.apiName,
								errorContent: 'error' in streamItem ? streamItem.error : undefined,
							},
						});
						continue;
					}

					if ('reason' in streamItem && streamItem.reason === 'backend-input-guard') {
						if (streamItem.guard === 'INPUT_EXCEEDS_TOKEN_LIMIT') {
							callback({
								type: 'token limit fail',
								errorInfo: {
									errorKey: streamItem.guard,
									statusCode: streamItem.statusCode,
								},
							});
							continue;
						}
						if (
							streamItem.guard === 'INPUT_TOO_SHORT_TO_SUMMARIZE' ||
							streamItem.guard === 'INPUT_TOO_SHORT_TO_PROCESS'
						) {
							callback({
								type: 'input too short fail',
								errorInfo: {
									errorKey: streamItem.guard,
									statusCode: streamItem.statusCode,
								},
							});
							continue;
						}
					}
					if ('reason' in streamItem && streamItem.reason === 'rate-limited') {
						callback({
							type: 'rate limited',
							retryAfter: streamItem.retryAfter!,
							errorInfo: {
								statusCode: streamItem.statusCode,
							},
						});
						continue;
					}

					if ('reason' in streamItem && streamItem.reason === 'response-too-similar') {
						callback({
							type: 'response too similar',
							cacheKey,
							modelInput: {
								selection: isInitialContextSelectionRange(initialContext)
									? initialContext.selection
									: undefined,
							},
							inputOutputDiffRatio: streamItem.data.meta?.inputOutputDiffRatio,
						});
						continue;
					}

					callback({
						type: 'api fail',
						errorInfo: {
							statusCode: streamItem.statusCode,
						},
					});
				} else if (streamItem.state === 'aup-violation') {
					callback({
						type: 'AUP violation detected',
						cacheKey,
						modelInput: {
							selection: isInitialContextSelectionRange(initialContext)
								? initialContext.selection
								: undefined,
						},
						errorInfo: {
							statusCode: streamItem.statusCode,
						},
					});
					continue;
				} else {
					callback({
						type: 'stream',
						markdown: streamItem.data.content,
						loadingStatus: streamItem.data.meta?.loadingStatus,
					});
					continue;
				}
			}
		})();

		//TODO: multiPrompts experiment cleanup - editor_ai_-_multi_prompts
		if (additionalStreaming) {
			(async function () {
				const multiPrompts: MultiPromptType[] = [];
				for await (const streamItem of additionalStreaming) {
					if (streamItem.state === 'loaded') {
						if (streamItem.name) {
							multiPrompts.push({
								name: streamItem.name,
								content: streamItem.data.content,
							});
							callback({
								type: 'handle additional prompts',
								multiPrompts: [...multiPrompts],
							});
							continue;
						}
					}
				}
			})();
		}

		// Cleanup function
		return () => {
			abortController.abort();
		};
	}

	return callbackHandler;
}

//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
export function streamingServiceAIButton(
	context: AIExperienceMachineContext,
	event: AIExperienceMachineEvent,
) {
	async function callbackHandler(callback: Sender<AIExperienceMachineEvent>) {
		const abortController = new AbortController();

		/**
		 * Vary prompt request triggered - if latest response exists, we are interrogating,
		 * and do not want to use the original config item (i.e. may possibly be summarise/transform)
		 *
		 * If it is a generate with tag, the response will be captured within
		 * the latest "latest response, prompt", flow.
		 *
		 * REVISIT THIS: analytics is based on the config item, however
		 * interrogation analytics uses base generate instead to complete;
		 */
		let configItemToUse = context.configItem;
		if (context.baseGenerate && context.latestPromptResponse) {
			// If there is a latestPromptResponse and the context.configItem has a key 'Rovo Agent',
			// then we should use the context.configItem so we can interrogate with the Rovo Agent
			configItemToUse =
				context.configItem && context.configItem.key === CONFIG_ITEM_KEYS.ROVO_AGENT
					? context.configItem
					: context.baseGenerate;
		}

		/**
		 * Get mention map for the current document
		 * We can no longer use the node attrs to get the mention name
		 * So we now need to get all the mentions and create a mapping that can be used by the
		 * markdown serializer
		 */
		let mentionMap = {};
		if (context.getMentionNameDetails) {
			mentionMap = await getMentionMap({
				node: context.editorView.state.doc,
				getMentionNameDetails: context.getMentionNameDetails,
			});
		}

		const { triggeredFor, positions, editorView, idMap } = context;
		const { state } = editorView;
		const { schema, doc } = state;
		if (triggeredFor?.isBlock) {
			const nodeTypeName = triggeredFor.name;
			// Summarise, Make shorter and Suggest a title does not need to preserve structure
			//	and will not be replacing block elements.
			if (
				[
					CONFIG_ITEM_KEYS.SUMMARISE_WRITING,
					CONFIG_ITEM_KEYS.MAKE_SHORTER,
					CONFIG_ITEM_KEYS.SUGGEST_A_TITLE,
					CONFIG_ITEM_KEYS.FIND_ACTION_ITEMS,
				].includes(configItemToUse.key)
			) {
				triggerRequest(positions, (streamContent) => ({ markdown: streamContent }));
			} else if (nodeTypeName === 'layoutSection') {
				const layoutSection = doc.nodeAt(positions[0]);
				if (layoutSection) {
					const emptyLayout = layoutSection.copy();
					const newLayoutColumns: Array<PMNode> = [];

					const triggerRequestCalls: Array<() => void> = [];
					let currentRequestCallIndex = 0;
					const isComplete = () => {
						const nextCallRequest = triggerRequestCalls[currentRequestCallIndex + 1];
						if (nextCallRequest) {
							currentRequestCallIndex = currentRequestCallIndex + 1;
							nextCallRequest();
						}

						return !nextCallRequest;
					};

					layoutSection.forEach(async (node, offset, index) => {
						const startPos = positions[0] + offset;
						const endPos = startPos + node.nodeSize;

						function streamContentProcessor(streamContent: string) {
							const { pmFragment } = convertMarkdownToProsemirror({
								schema,
								markdown: streamContent,
								idMap: idMap!,
								featureToggles: {
									markdownPlus: true,
									markdownPlusExtensions: true,
									markdownPlusPanels: true,
									markdownPlusDecisions: true,
								},
							});
							newLayoutColumns[index] = node.copy(pmFragment);
							const newLayout = emptyLayout.copy(Fragment.from(newLayoutColumns));

							return {
								markdown: streamContent,
								fragment: Fragment.from(newLayout),
							};
						}

						const triggerCellRequest = () => {
							triggerRequest([startPos, endPos], streamContentProcessor, isComplete);
						};
						triggerRequestCalls.push(triggerCellRequest);
					});
					triggerRequestCalls[0]();
				}
			} else if (nodeTypeName === 'panel') {
				const panel = doc.nodeAt(positions[0]);
				if (panel) {
					const emptyPanel = panel.copy();
					const startPos = positions[0] + 1;
					const endPos = positions[1] - 1;

					const streamContentProcessor = (streamContent: string) => {
						const { pmFragment } = convertMarkdownToProsemirror({
							schema,
							markdown: streamContent,
							idMap: idMap!,
							featureToggles: {
								markdownPlus: true,
								markdownPlusExtensions: true,
								markdownPlusPanels: true,
								markdownPlusDecisions: true,
							},
						});
						const newPanel = emptyPanel.copy(pmFragment);

						return {
							markdown: streamContent,
							fragment: Fragment.from(newPanel),
						};
					};

					triggerRequest([startPos, endPos], streamContentProcessor);
				}
			} else if (
				nodeTypeName === 'table' ||
				nodeTypeName === 'tableCell' ||
				nodeTypeName === 'tableHeader'
			) {
				triggerRequest(positions, (streamContent) => ({ markdown: streamContent }));
			} else if (nodeTypeName === 'expand' || nodeTypeName === 'nestedExpand') {
				const expand = doc.nodeAt(positions[0]);
				if (expand) {
					const emptyExpand = expand.copy();
					const startPos = positions[0] + 1;
					const endPos = positions[1] - 1;

					const streamContentProcessor = (streamContent: string) => {
						const { pmFragment } = convertMarkdownToProsemirror({
							schema,
							markdown: streamContent,
							idMap: idMap!,
							featureToggles: {
								markdownPlus: true,
								markdownPlusExtensions: true,
								markdownPlusPanels: true,
								markdownPlusDecisions: true,
							},
						});
						const newExpand = emptyExpand.copy(pmFragment);

						return {
							markdown: streamContent,
							fragment: Fragment.from(newExpand),
						};
					};

					triggerRequest([startPos, endPos], streamContentProcessor);
				}
			} else {
				triggerRequest(positions, (streamContent) => ({ markdown: streamContent }));
			}
		} else {
			triggerRequest(positions, (streamContent) => ({ markdown: streamContent }));
		}

		async function triggerRequest(
			positions: Positions,
			streamContentProcessor: (streamContent: string) => { markdown: string; fragment?: Fragment },
			isComplete?: () => boolean,
		) {
			const initialContext = configItemToUse.getInitialContext?.({
				editorView: context.editorView,
				positions: positions,
				intl: context.intl,
				updateIdMap: ({ idMap, selectionType }) => {
					callback({ type: 'update idMap', context: { idMap, selectionType } });
				},
				mentionMap,
			});

			if (initialContext?.contentStatistics) {
				callback({
					type: 'storeContextStatistics',
					contextStatistics: { inputDocument: initialContext.contentStatistics },
				});
			}

			if (!initialContext) {
				throw new Error('Initial context is undefined');
			}

			const promptRequest = configItemToUse.triggerPromptRequest({
				initialContext,
				promptInput: context.userInput,
				promptInputADF: context.userADFInput,
				latestPromptResponse: context.latestPromptResponse || undefined,
				formatMessage: context.intl.formatMessage,
				editorSchema: context.editorView.state.schema,
			});

			const cacheKey = JSON.stringify({
				initialContext,
				userInput: context.userInput,
				agent: context.configItem.agent,
			});

			// Right now we only ever have one object in history as we do not
			// yet support traversing the history object array. This is also to
			// prevent us saving large documents into history multip[le times.
			const lastHistoryItem = context.responseHistory.entries[0];
			if (lastHistoryItem && cacheKey === lastHistoryItem.cacheKey) {
				callback({
					type: 'same cache key',
				});
				return;
			}

			const streaming = promptRequest({
				abortController,
				// Note -- when we collapse to a single "prompt request" with "api v2"
				// this would be a good opportunity to group these and pass them as a group
				generativeAIApiUrl: context.aiProvider.generativeAIApiUrl,
				product: context.aiProvider.product,
				getFetchCustomHeaders: context.aiProvider.getFetchCustomHeaders,
				channelId: context.channelId,
			});

			(async function () {
				for await (const streamItem of streaming) {
					if (streamItem.state === 'loaded') {
						const rovoActions = streamItem.data.rovoActions || [];
						let markdown = streamItem.data.content;
						let processedResponse = { markdown };
						if (rovoActions.length > 0) {
							const { selectionType } = context.configItem;
							const idealSuggestion = selectionType === 'range' ? 'replace' : 'insert';
							const action =
								rovoActions.find((x) => x.data.suggestion === idealSuggestion) ?? rovoActions[0];

							// There is a scenario where we are in selectionType === 'empty'
							// but AI returns replace. In this case, we override the replace
							// suggestion with insert.
							if (selectionType === 'empty' && action.data.suggestion === 'replace') {
								// This should also update the value by reference in rovoActions
								action.data.suggestion = 'insert';
							}
							// The opposite can happen too
							else if (selectionType === 'range' && action.data.suggestion === 'insert') {
								// This should also update the value by reference in rovoActions
								action.data.suggestion = 'replace';
							}

							markdown += `\n\n---\n\n${action.data.content}`;
						} else {
							processedResponse = streamContentProcessor(streamItem.data.content);
						}

						// loading done on success
						if (isComplete) {
							const isCompleted = isComplete();
							if (isCompleted) {
								callback({
									type: 'complete',
									...processedResponse,
									cacheKey,
									modelInput: {
										selection: isInitialContextSelectionRange(initialContext)
											? initialContext.selection
											: undefined,
									},
									inputOutputDiffRatio: streamItem.data.meta?.inputOutputDiffRatio,
									rovoActions,
								});
							} else {
								callback({
									type: 'stream',
									...processedResponse,
									loadingStatus: streamItem.data.meta?.loadingStatus,
								});
							}
						} else {
							callback({
								type: 'complete',
								...processedResponse,
								cacheKey,
								modelInput: {
									selection: isInitialContextSelectionRange(initialContext)
										? initialContext.selection
										: undefined,
								},
								inputOutputDiffRatio: streamItem.data.meta?.inputOutputDiffRatio,
								rovoActions,
							});
						}

						continue;
					} else if (streamItem.state === 'failed') {
						//loading done on api failed

						// If the error comes from ConvoAI
						if ('apiName' in streamItem && streamItem.apiName === 'assistance-service') {
							if (
								['RATE_LIMIT', 'OPENAI_RATE_LIMIT_USER_ABUSE'].includes(streamItem.guard) &&
								'retryAfter' in streamItem
							) {
								callback({
									type: 'rate limited',
									retryAfter: streamItem.retryAfter!,
									errorInfo: {
										statusCode: streamItem.statusCode,
										errorKey: streamItem.guard,
										apiName: streamItem.apiName,
										errorContent: 'error' in streamItem ? streamItem.error : undefined,
									},
								});
								continue;
							}

							if (streamItem.guard === 'HIPAA_CONTENT_DETECTED') {
								callback({
									type: 'hipaa content fail',
									errorInfo: {
										statusCode: streamItem.statusCode,
										errorKey: streamItem.guard,
										apiName: streamItem.apiName,
										errorContent: 'error' in streamItem ? streamItem.error : undefined,
									},
								});
								continue;
							}

							if (streamItem.guard === 'EXCEEDING_CONTEXT_LENGTH_ERROR') {
								callback({
									type: 'token limit fail',
									errorInfo: {
										statusCode: streamItem.statusCode,
										errorKey: streamItem.guard,
										apiName: streamItem.apiName,
										errorContent: 'error' in streamItem ? streamItem.error : undefined,
									},
								});
								continue;
							}

							if (streamItem.guard === 'ACCEPTABLE_USE_VIOLATIONS') {
								callback({
									type: 'AUP violation detected',
									cacheKey,
									modelInput: {
										selection: isInitialContextSelectionRange(initialContext)
											? initialContext.selection
											: undefined,
									},
									errorInfo: {
										statusCode: streamItem.statusCode,
										errorKey: streamItem.guard,
										apiName: streamItem.apiName,
										errorContent: 'error' in streamItem ? streamItem.error : undefined,
									},
								});
								continue;
							}

							// All other errors from ConvoAI
							callback({
								type: 'api fail',
								errorInfo: {
									statusCode: streamItem.statusCode,
									errorKey: streamItem.guard,
									apiName: streamItem.apiName,
									errorContent: 'error' in streamItem ? streamItem.error : undefined,
								},
							});
							continue;
						}

						if ('reason' in streamItem && streamItem.reason === 'backend-input-guard') {
							if (streamItem.guard === 'INPUT_EXCEEDS_TOKEN_LIMIT') {
								callback({
									type: 'token limit fail',
									errorInfo: {
										errorKey: streamItem.guard,
										statusCode: streamItem.statusCode,
									},
								});
								continue;
							}
							if (
								streamItem.guard === 'INPUT_TOO_SHORT_TO_SUMMARIZE' ||
								streamItem.guard === 'INPUT_TOO_SHORT_TO_PROCESS'
							) {
								callback({
									type: 'input too short fail',
									errorInfo: {
										errorKey: streamItem.guard,
										statusCode: streamItem.statusCode,
									},
								});
								continue;
							}
						}
						if ('reason' in streamItem && streamItem.reason === 'rate-limited') {
							callback({
								type: 'rate limited',
								retryAfter: streamItem.retryAfter!,
								errorInfo: {
									statusCode: streamItem.statusCode,
								},
							});
							continue;
						}

						if ('reason' in streamItem && streamItem.reason === 'response-too-similar') {
							callback({
								type: 'response too similar',
								cacheKey,
								modelInput: {
									selection: isInitialContextSelectionRange(initialContext)
										? initialContext.selection
										: undefined,
								},
								inputOutputDiffRatio: streamItem.data.meta?.inputOutputDiffRatio,
							});
							continue;
						}

						callback({
							type: 'api fail',
							errorInfo: {
								statusCode: streamItem.statusCode,
							},
						});
					} else if (streamItem.state === 'aup-violation') {
						callback({
							type: 'AUP violation detected',
							cacheKey,
							modelInput: {
								selection: isInitialContextSelectionRange(initialContext)
									? initialContext.selection
									: undefined,
							},
							errorInfo: {
								statusCode: streamItem.statusCode,
							},
						});
						continue;
					} else {
						callback({
							type: 'stream',
							loadingStatus: streamItem.data.meta?.loadingStatus,
							...streamContentProcessor(streamItem.data.content),
						});
						continue;
					}
				}
			})();

			if (!triggeredFor?.isBlock) {
				//TODO: multiPrompts experiment cleanup - editor_ai_-_multi_prompts
				const additionalPromptRequest = configItemToUse.additionalTriggerPromptRequest?.({
					analyticsContext: context.analyticsContext,
					initialContext,
					promptInput: context.userInput,
					promptInputADF: context.userADFInput,
					latestPromptResponse: context.latestPromptResponse || undefined,
					formatMessage: context.intl.formatMessage,
					editorSchema: context.editorView.state.schema,
				});

				const additionalStreaming = additionalPromptRequest?.({
					abortController,
					// Note -- when we collapse to a single "prompt request" with "api v2"
					// this would be a good opportunity to group these and pass them as a group
					generativeAIApiUrl: context.aiProvider.generativeAIApiUrl,
					product: context.aiProvider.product,
					getFetchCustomHeaders: context.aiProvider.getFetchCustomHeaders,
					channelId: context.channelId,
				});

				if (additionalStreaming && additionalPromptRequest) {
					callback({
						type: 'handle additional prompts',
						multiPrompts: [],
					});
				}

				//TODO: multiPrompts experiment cleanup - editor_ai_-_multi_prompts
				if (additionalStreaming) {
					(async function () {
						const multiPrompts: MultiPromptType[] = [];
						for await (const streamItem of additionalStreaming) {
							if (streamItem.state === 'loaded') {
								if (streamItem.name) {
									multiPrompts.push({
										name: streamItem.name,
										content: streamItem.data.content,
									});
									callback({
										type: 'handle additional prompts',
										multiPrompts: [...multiPrompts],
									});
									continue;
								}
							}
						}
					})();
				}
			}
		}

		// Cleanup function
		return () => {
			abortController.abort();
		};
	}

	return callbackHandler;
}
