import React, { type ReactNode } from 'react';
import {
	type JSErrorBoundaryProps,
	JSErrorBoundary,
} from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../../constants';

type Props = {
	children: ReactNode;
	fallback: JSErrorBoundaryProps['fallback'];
	onError: JSErrorBoundaryProps['onError'];
};

export const ErrorBoundary = ({ children, fallback }: Props) => (
	<JSErrorBoundary
		id="deliverySummary"
		fallback={fallback}
		packageName={PACKAGE_NAME}
		teamName={TEAM_NAME}
	>
		{children}
	</JSErrorBoundary>
);
