import type { Action } from '@atlassian/react-sweet-state';
import { getAtlassianAccountWithRethrow } from '../services';
import type { State } from './types';

export const actions = {
	fetchAtlassianAccountData:
		(): Action<State> =>
		async ({ setState, getState }) => {
			const { isFetching, wasFetchedOnce } = getState();
			if (isFetching || wasFetchedOnce) {
				return;
			}

			setState({ isFetching: true });

			try {
				const data = await getAtlassianAccountWithRethrow();

				if (data !== null) {
					setState({ data });
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				setState({ fetchError: error });
			} finally {
				setState({ isFetching: false, wasFetchedOnce: true });
			}
		},
} as const;
