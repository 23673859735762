import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import type {
	ApprovalValue,
	ApproverFieldValue,
	GroupApproverFieldValue,
	ServerApproval,
} from '@atlassian/jira-issue-shared-types/src/common/types/approval-type.tsx';
import { SERVICEDESK_APPROVAL_TYPE } from '@atlassian/jira-platform-field-config';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { getApprovalWithMergeApprovers } from './utils';

type Props = {
	issueKey: IssueKey;
	approvalFieldId?: string;
};

const getApproval = (
	activeApproval?: ServerApproval,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	linkedFieldValue:
		| null
		| ApproverFieldValue
		| ReadonlyArray<ApproverFieldValue>
		| ReadonlyArray<GroupApproverFieldValue>,
	title: string,
	linkedFieldType: null | string,
) => {
	if (!activeApproval) return null;
	if (linkedFieldType == null)
		return { ...activeApproval, linkedFieldName: '', isSingleUserPicker: false };

	return getApprovalWithMergeApprovers(activeApproval, linkedFieldValue, title, linkedFieldType);
};

const useApprovalField = ({ issueKey, approvalFieldId = SERVICEDESK_APPROVAL_TYPE }: Props) => {
	// Approvers field value
	const [{ value: approvalValue }, { saveValue: saveApprovers }] = useEditField<
		ApprovalValue | null,
		null
	>({
		fieldKey: approvalFieldId,
		issueKey,
		fieldType: SERVICEDESK_APPROVAL_TYPE,
		initialValue: null,
	});

	const activeApproval = approvalValue?.activeApproval;
	const activeConfiguration = activeApproval ? activeApproval.configuration : null;

	const linkedFieldId = activeConfiguration?.approvers.customFieldId ?? '';

	const [{ value: fieldConfig }] = useFieldConfig(issueKey, linkedFieldId);
	const { isEditable, title, autoCompleteUrl, schema } = fieldConfig || {};
	const { custom: linkedFieldType } = schema || {};

	// Linked Field value
	// Approvals contains a linked field in the glance panel. This is the main value that is updated
	const [{ value: linkedFieldValue }, { saveValue: saveLinkedFieldValue }] = useEditField<
		| ApproverFieldValue
		| ReadonlyArray<ApproverFieldValue>
		| ReadonlyArray<GroupApproverFieldValue>
		| null,
		null
	>({
		fieldKey: linkedFieldId,
		fieldType: linkedFieldId,
		issueKey,
		initialValue: null,
	});

	// @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
	const approval = getApproval(activeApproval, linkedFieldValue, title, linkedFieldType);

	return {
		approval,
		isEditable,
		saveApprovers,
		saveLinkedFieldValue,
		linkedFieldId,
		autoCompleteUrl,
	};
};

export default useApprovalField;
