import { useMemo } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { ForgeCustomFieldValue } from '@atlassian/jira-forge-ui-types/src/common/types/contexts/custom-field.tsx';
import type {
	ServiceResult,
	ServiceParams,
} from '@atlassian/jira-forge-ui-types/src/common/types/custom-field';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { createSaveCustomField } from './create-save-custom-field';

const useSaveCustomField = ({
	issueKey,
	fieldId,
	fieldType,
	source,
}: ServiceParams): ((value: ForgeCustomFieldValue) => Promise<ServiceResult>) => {
	const [, { saveValue }] = useEditField<ForgeCustomFieldValue, unknown, ServiceResult>({
		fieldKey: fieldId,
		issueKey,
		fieldType,
		initialValue: null,
		saveField: createSaveCustomField(source),
	});

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({
		action: 'submit',
		actionSubject: 'forgeCustomFieldEdit',
	});

	return async (valueToSave: ForgeCustomFieldValue) => saveValue(valueToSave, null, analyticsEvent);
};

const useCreateServiceInner = (params: ServiceParams) => {
	const saveCustomField = useSaveCustomField(params);

	return useMemo<{
		save: (value: ForgeCustomFieldValue) => Promise<ServiceResult>;
	}>(
		() => ({
			save: (value: ForgeCustomFieldValue) => saveCustomField(value),
		}),
		[saveCustomField],
	);
};

type CustomFieldsService = {
	save: (value: ForgeCustomFieldValue) => Promise<ServiceResult>;
};

export const useCustomFieldService = (params: ServiceParams): CustomFieldsService =>
	useCreateServiceInner(params);
