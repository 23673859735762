import React from 'react';
import { graphql, useFragment } from 'react-relay';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import { IssueViewSingleLineTextField } from '@atlassian/jira-issue-view-layout-single-line-text-field/src/ui/index.tsx';
import { IssueViewSingleLineTextMessageField } from '@atlassian/jira-issue-view-layout-single-line-text-message-field/src/ui';
import {
	MESSAGE_VIEW_CF_TYPE,
	MESSAGE_EDIT_CF_TYPE,
	SUMMARY_TYPE,
	RANK_CF_TYPE,
} from '@atlassian/jira-platform-field-config';
import type { ui_issueViewLayoutSingleLineText_IssueViewSingleLineText$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutSingleLineText_IssueViewSingleLineText.graphql';

export interface IssueViewSingleLineTextProps {
	area?: Area;
	fragmentKey: ui_issueViewLayoutSingleLineText_IssueViewSingleLineText$key;
}
/**
 * If you implement MESSAGE_VIEW_CF_TYPE or MESSAGE_EDIT_CF_TYPE remember to update
 * getRelayLayoutExclusions in jira/src/packages/issue/issue-view-layout-templates/components/layout-item/src/index.tsx
 */
export const IssueViewSingleLineText = ({ area, fragmentKey }: IssueViewSingleLineTextProps) => {
	const data = useFragment<ui_issueViewLayoutSingleLineText_IssueViewSingleLineText$key>(
		graphql`
			fragment ui_issueViewLayoutSingleLineText_IssueViewSingleLineText on JiraSingleLineTextField
			@argumentDefinitions(
				issueViewRelaySingleLineTextMessageFieldFlag: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-single-line-text-message.relayprovider"
				}
			) {
				...ui_issueViewLayoutSingleLineTextMessageField_IssueViewSingleLineTextMessageField
					@include(if: $issueViewRelaySingleLineTextMessageFieldFlag)
				...ui_issueViewLayoutSingleLineTextField_IssueViewSingleLineTextField
				type
			}
		`,
		fragmentKey,
	);

	switch (data.type) {
		// Summary field is added directly to foundation section
		case SUMMARY_TYPE:
		case RANK_CF_TYPE:
			return null;
		case MESSAGE_VIEW_CF_TYPE:
		case MESSAGE_EDIT_CF_TYPE:
			return <IssueViewSingleLineTextMessageField area={area} fragmentKey={data} />;
		default:
			return <IssueViewSingleLineTextField area={area} fragmentKey={data} />;
	}
};
