import React, { forwardRef, useState } from 'react';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import Button from '@atlaskit/button';
import { DateTimePicker } from '@atlaskit/datetime-picker';
import EmojiFrequentIcon from '@atlaskit/icon/core/migration/clock--emoji-frequent';
import { SpotlightManager, SpotlightTarget, SpotlightTransition } from '@atlaskit/onboarding';
import Popup, { type PopupComponentProps } from '@atlaskit/popup';
import { Box, xcss, type XCSS } from '@atlaskit/primitives';
import CoordinationClient from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	useIsAgentOrProjectAdmin,
	useIsIssueOfIncidentsPractice,
} from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import { standardizeLocale } from '@atlassian/jira-issue-format-date/src/common/utils.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import { formatWithLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { DATE_FORMAT } from './constants';
import messages from './messages';

export const newDate = (args?: string | number | Date | undefined) =>
	args ? new Date(args) : new Date();

const CustomPopupContainer = forwardRef<HTMLDivElement, PopupComponentProps>(
	({ children, 'data-testid': testId, xcss: _xcss, ...containerProps }, ref) => (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Box xcss={dateTimePickerWrapperStyle} testId={testId} {...containerProps} ref={ref}>
			{children}
		</Box>
	),
);

export const EventOccurredAtSelector = (props: {
	initialValue?: number | null;
	onChange: (selectedEventOccurredAtDateTime: number | null) => void;
}) => {
	const locale = useLocale();
	const { formatMessage, formatDate } = useIntl();
	const standardizedLocale = standardizeLocale(locale);

	const currentUser = useCurrentUser();
	const timeZone =
		(!currentUser.loading &&
			!currentUser.error &&
			'timeZone' in currentUser.data.user &&
			currentUser.data.user.timeZone) ||
		Intl.DateTimeFormat().resolvedOptions().timeZone;

	const [dateTimeValue, updateDateTimeValue] = useState(
		props.initialValue ? utcToZonedTime(newDate(props.initialValue), timeZone).toISOString() : null,
	);
	const [isSpotlightActive, setIsSpotlightActive] = useState(true);
	const [showDateTimePicker, changeShowDateTimePicker] = useState(false);
	const isIssueOfIncidentPractice = useIsIssueOfIncidentsPractice();
	const isAgentOrProjectAdmin = useIsAgentOrProjectAdmin();

	const timePickerOptions = (() => {
		const options: Array<string> = [];

		let currentDate = newDate(newDate().setHours(0, 0, 0, 0));
		const currentDateDay = Number(currentDate.getDay());
		while (currentDate.getDay() === currentDateDay) {
			options.push(formatWithLocale(currentDate, 'p', locale));
			currentDate = newDate(currentDate.valueOf() + 15 * 60 * 1000);
		}

		return options;
	})();

	const onChangeDateTimePicker = (value: string) => {
		if (value !== '') {
			const zonedTimeInUtc = zonedTimeToUtc(newDate(value), timeZone);
			updateDateTimeValue(value);
			changeShowDateTimePicker(false);
			props.onChange(zonedTimeInUtc.getTime());
		} else {
			updateDateTimeValue(null);
			props.onChange(null);
		}
	};

	if (!isIssueOfIncidentPractice || !isAgentOrProjectAdmin) return null;

	return (
		<SpotlightManager>
			<Popup
				isOpen={showDateTimePicker}
				onClose={() => changeShowDateTimePicker(false)}
				popupComponent={CustomPopupContainer}
				placement="bottom-end"
				content={() => (
					<DateTimePicker
						onChange={onChangeDateTimePicker}
						locale={standardizedLocale}
						value={dateTimeValue || undefined}
						datePickerProps={{
							...(dateTimeValue == null
								? {
										placeholder: formatWithLocale(utcToZonedTime(newDate(), timeZone), 'P', locale),
									}
								: {}),
							maxDate: utcToZonedTime(newDate(), timeZone).toISOString(),
						}}
						timePickerProps={{
							...(dateTimeValue == null && {
								timeIsEditable: true,
								placeholder: formatWithLocale(utcToZonedTime(newDate(), timeZone), 'p', locale),
							}),
							times: timePickerOptions,
						}}
					/>
				)}
				trigger={(triggerProps) => (
					// eslint-disable-next-line react/jsx-props-no-spreading
					<Box {...triggerProps} xcss={wrapperStyle}>
						<SpotlightTarget name="send-events-to-past-target">
							<Button
								iconBefore={<EmojiFrequentIcon color="currentColor" spacing="spacious" label="" />}
								onClick={() => {
									changeShowDateTimePicker(!showDateTimePicker);
								}}
								isSelected={showDateTimePicker}
							>
								{showDateTimePicker ? (
									formatMessage(messages.selectDateAndTimeText)
								) : (
									<>
										{dateTimeValue
											? formatDate(dateTimeValue, DATE_FORMAT)
											: formatMessage(messages.addToPast)}
									</>
								)}
							</Button>
						</SpotlightTarget>
					</Box>
				)}
			/>
			{isSpotlightActive && (
				<CoordinationClient
					messageId="spotlight-for-manual-incident-timelines"
					messageType="engagement"
				>
					<SpotlightTransition>
						<JiraSpotlight
							actions={[
								{
									onClick: () => setIsSpotlightActive(false),
									text: 'Dismiss',
								},
								{
									appearance: 'subtle',
									onClick: () => {
										// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
										window.open(
											'https://support.atlassian.com/jira-service-management-cloud/docs/what-is-the-incident-timeline/',
											'_blank',
										);
									},
									text: 'Learn more',
								},
							]}
							heading={formatMessage(messages.spotlightHeading)}
							target="send-events-to-past-target"
							key="send-events-to-past-target"
							targetRadius={3}
							dialogPlacement="right middle"
							messageId="issue-comment-base.ui.comment.event-occurred-at-selector.jira-spotlight.send-events-to-past-target"
							messageType="engagement"
						>
							{formatMessage(messages.spotlightDescription)}
						</JiraSpotlight>
					</SpotlightTransition>
				</CoordinationClient>
			)}
		</SpotlightManager>
	);
};

const dateTimePickerWrapperStyle: XCSS = xcss({
	width: '21rem',
});

const wrapperStyle: XCSS = xcss({
	paddingLeft: 'space.050',
});
