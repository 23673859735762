import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type ChatAppCommunication from './ui';
import type { Props } from './ui/chatops-communication-field/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyChatAppCommunicationTool = lazyForPaint<typeof ChatAppCommunication>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-incident-management-chat-app-communication-tool" */ './ui'
		),
	{ ssr: false },
);

export const AsyncChatAppCommunicationTool = (props: Props) => (
	<Placeholder name="servicedesk-incident-management-chat-app-communication-tool" fallback={null}>
		<LazyChatAppCommunicationTool {...props} />
	</Placeholder>
);
