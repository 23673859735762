import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { ProjectType } from '@atlassian/jira-common-constants';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import type {
	FlagFailureConstant,
	FlagSuccessConstant,
} from '@atlassian/jira-issue-view-common-constants/src/flags.tsx';
import type {
	IssueKey,
	BaseUrl,
	ProjectKey,
	IssueId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import type { State } from './issue-type';

export type TransformerContext = {
	baseUrl: BaseUrl;
	projectKey: ProjectKey;
	projectType?: ProjectType;
	issueKey: IssueKey;
	fieldId: string;
};

export type SaveFieldArguments<T> = {
	baseUrl: BaseUrl;
	issueKey: IssueKey;
	issueId: IssueId;
	fieldMetaKey: string;
	fieldId: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any;
	fieldEditSessionId?: string;
	// This is for data that is required to save the field, but not persisted in the field value.
	saveFieldData?: T;
};

export type FieldOptions<T> = {
	isRichTextField: boolean;
	isOptimistic: boolean;
	shouldSaveDraft: boolean;
	canContainMediaContent: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	saveField: (arg1: SaveFieldArguments<T>, state: State) => Promise<any>;
	shouldDiscardChangeOnFailure: boolean;
	onSaveFailureFlagType: FlagFailureConstant | null;
	onSaveSuccessFlagType: FlagSuccessConstant | null;
};

export type AllowedValuesOverrides = {
	operation: 'ALLOW' | 'EXCLUDE';
	ids: string[];
};

type ExtraOwnProps = {
	allowedValuesOverrides: AllowedValuesOverrides;
};

type AdditionalProps<T> = (
	state: State,
	intl: IntlShape,
	fieldOptions: FieldOptions<T>,
	extraOwnProps?: ExtraOwnProps,
) => {
	// you should avoid setting label in additionalProps, as this will override text only for the field label in Issue view. While label/name in JQL search, admin configuration, fields tab title etc. will be different, still being the old text.
	label?: string;
	showPinButton?: boolean;
};

export type ConnectFieldConfig<T> = {
	fieldId: string;
	shouldSaveDraft: boolean;
	transformFromStateValue: (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		stateValue: any,
		transformerContext: TransformerContext,
		intl: IntlShape, // eslint-disable-next-line @typescript-eslint/no-explicit-any
	) => any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	transformToStateValue: (componentValue?: any) => any;
	fallbackInvalidMessage: (intl: IntlShape) => string;
	isOptimistic: boolean;
	canContainMediaContent: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	saveField: (arg1: SaveFieldArguments<T>, state: State) => Promise<any>;
	additionalProps: AdditionalProps<T>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	additionalCallbacks: (dispatch: any) => any;
	shouldDiscardChangeOnFailure: boolean;
	onSaveFailureFlagType: FlagFailureConstant | null;
	onSaveSuccessFlagType: FlagSuccessConstant | null;
};

export const CONTEXT = 'context' as const;
export const CONTENT = 'content' as const;
export const CONTENT_REQUEST = 'content-request' as const;
export const FOUNDATION = 'foundation' as const;
export const TAB_PANEL = 'tab-panel' as const;

export type PartialConnectFieldConfig<T> = {
	area?:
		| typeof CONTENT
		| typeof CONTEXT
		| typeof CONTENT_REQUEST
		| typeof FOUNDATION
		| typeof TAB_PANEL;
	fieldId: string;
	transformFromStateValue?: (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		stateValue: any,
		transformerContext: TransformerContext,
		intl: IntlShape, // eslint-disable-next-line @typescript-eslint/no-explicit-any
	) => any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	transformToStateValue?: (componentValue?: any) => any;
	fallbackInvalidMessage?: (intl: IntlShape) => string;
	isOptimistic?: boolean;
	canContainMediaContent?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	saveField?: (arg1: SaveFieldArguments<T>, state: State) => Promise<any>;
	additionalProps?: AdditionalProps<T>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	additionalCallbacks?: (dispatch: any) => any;
	shouldDiscardChangeOnFailure?: boolean;
	shouldSaveDraft?: boolean;
	onSaveFailureFlagType?: FlagFailureConstant;
	onSaveSuccessFlagType?: FlagSuccessConstant;
};

export type Area =
	| typeof CONTENT
	| typeof CONTEXT
	| typeof CONTENT_REQUEST
	| typeof FOUNDATION
	| typeof TAB_PANEL;

export type OwnProps = {
	fieldId: string;
	containerWidth?: number;
	area?: Area;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	payload?: any;
	createAnalyticsEvent?: CreateUIAnalyticsEvent;
	intl: IntlShape;
};

export type MapStateToConfig<T> = (arg1: State, arg2: OwnProps) => PartialConnectFieldConfig<T>;

export type ReduxConnectParams = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mapStateToProps: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mapDispatchToProps: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mergeStateAndDispatchProps: any;
};

export type ObjectWithIntl = {
	intl: IntlShape;
	extraOwnProps: ExtraOwnProps;
};
