import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { Box } from '@atlaskit/primitives';
import { defaultTimeTrackingConfiguration } from '@atlassian/jira-common-constants/src/jira-settings.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import type {
	DefaultUnit,
	Format,
} from '@atlassian/jira-issue-shared-types/src/common/types/jira-settings-type.tsx';
import {
	WrappedTimeTrackingView,
	mapStateToProps,
	mapDispatchToProps,
} from '@atlassian/jira-issue-view-base/src/context/time-tracking';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import { ConnectRelayHOC } from '@atlassian/jira-issue-view-connect-relay';
import type {
	ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField$data,
	ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField$key,
} from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField.graphql';

export interface IssueViewTimeTrackingFieldProps {
	area?: Area;
	fragmentKey: ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField$key;
}

const mapRelayToProps = (
	relayData: ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField$data,
) => ({
	fieldId: relayData.fieldId,
	// Copied from src/packages/issue/agg-field-transformers/src/common/utils/time-tracking-config-transformer.tsx
	config: {
		isTimeTrackingEnabled:
			relayData.timeTrackingSettings?.isJiraConfiguredTimeTrackingEnabled ||
			defaultTimeTrackingConfiguration.isTimeTrackingEnabled,
		hoursPerDay:
			relayData.timeTrackingSettings?.workingHoursPerDay ||
			defaultTimeTrackingConfiguration.hoursPerDay,
		daysPerWeek:
			relayData.timeTrackingSettings?.workingDaysPerWeek ||
			defaultTimeTrackingConfiguration.daysPerWeek,
		format: relayData.timeTrackingSettings?.defaultFormat
			? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(relayData.timeTrackingSettings?.defaultFormat.toLowerCase() as Format)
			: defaultTimeTrackingConfiguration.format, // NOTE: The original gira transformer returns undefined here
		defaultUnit: relayData.timeTrackingSettings?.defaultUnit
			? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(relayData.timeTrackingSettings?.defaultUnit.toLowerCase() as DefaultUnit)
			: defaultTimeTrackingConfiguration.defaultUnit,
	},
	label: relayData.name,
	// TODO [RELAY]: These should come from the server and relay state but currently have some complex logic inside redux selectors
	// so we're leaving them in redux until we can spend time to figure out how to move them.
	// isVisible: true,
	// isEditable: data.isEditableInIssueView,
	// issueKey: 'BEN-123',
	// issueId: '123',
	// isDone: boolean;
	// shouldDisplayRollUpDataControl: boolean;
	// isRollingUpData: boolean;
	// value: TimeTrackingState;
	// timeTrackingCalculationSelector: (arg1: boolean) => TimeTrackingState;

	// Local-only state
	// isMobile: boolean;
	// fieldId: string;
	// showPinButton?: boolean;

	// "FE wiring" - These will never be derived from relay, they are not really "state"
	// invalidTimeFormatMessage: ReactNode;
	// placeholderMessage: string;
	// formatMessage: FormatMessage;
	// cancelButtonLabel: string;
	// rollupLabel: string;
	// editButtonLabel: string;
	// confirmButtonLabel: string;
	// timeRemainingFieldLabel: string;
	// onEditRequest: () => void;
	// onToggleRollingUpData: (arg1: boolean, arg2: UIAnalyticsEvent) => void;
});

const ConnectedTimeTrackingView = ConnectRelayHOC(
	mapRelayToProps,
	mapStateToProps,
	mapDispatchToProps,
)(WrappedTimeTrackingView);

export const IssueViewTimeTrackingField = (props: IssueViewTimeTrackingFieldProps) => {
	const data = useFragment<ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField$key>(
		graphql`
			fragment ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField on JiraTimeTrackingField {
				name
				fieldId
				__typename
				timeTrackingSettings {
					isJiraConfiguredTimeTrackingEnabled
					workingDaysPerWeek
					workingHoursPerDay
					defaultUnit
					defaultFormat
				}
			}
		`,
		props.fragmentKey,
	);

	const intl = useIntl();

	return (
		<JSErrorBoundary id="issue.issue-view.context.time-tracking-field">
			<Box data-testId="issue-field-time-tracking-readview-full.ui.time-tracking.wrapper">
				<ConnectedTimeTrackingView
					area={props.area || 'context'}
					intl={intl}
					fieldId={data.fieldId}
					relayData={data}
				/>
			</Box>
		</JSErrorBoundary>
	);
};
