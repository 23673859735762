import { useCallback, useMemo } from 'react';
import { fetchQuery, graphql } from 'relay-runtime';
import type { User } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment';
import type { fetchUsersForWatchersFieldQuery } from '@atlassian/jira-relay/src/__generated__/fetchUsersForWatchersFieldQuery.graphql';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id';

type Params = {
	issueKey: IssueKey;
	query: string;
};

export const useFetchUsersForWatchersField = () => {
	const cloudId = useCloudId();

	const fetchUsers = useCallback(
		async ({ issueKey, query }: Params): Promise<User[]> => {
			const data = await fetchQuery<fetchUsersForWatchersFieldQuery>(
				getRelayEnvironment(),
				graphql`
					query fetchUsersForWatchersFieldQuery(
						$cloudId: ID!
						$issueKey: String!
						$query: String!
					) {
						jira {
							issueByKey(key: $issueKey, cloudId: $cloudId) {
								watchesField: fieldByIdOrAlias(idOrAlias: "watches")
									@optIn(to: "JiraIssueFieldByIdOrAlias") {
									... on JiraWatchesField {
										suggestedWatchers(searchBy: $query) {
											edges {
												node {
													id
													accountId
													name
													picture
												}
											}
										}
									}
								}
							}
						}
					}
				`,
				{
					issueKey,
					query,
					cloudId,
				},
			).toPromise();
			return (
				data?.jira?.issueByKey?.watchesField?.suggestedWatchers?.edges?.map((edge) => ({
					id: edge?.node?.accountId ?? '',
					ari: edge?.node?.id ?? '',
					displayName: edge?.node?.name ?? '',
					avatarUrl: edge?.node?.picture ?? '',
				})) ?? []
			);
		},
		[cloudId],
	);
	return useMemo(() => ({ fetchUsers }), [fetchUsers]);
};
