// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createLinkedIssueButtonLabel: {
		id: 'issue.linked-issue-create.button-label',
		defaultMessage: 'Create linked issue',
		description:
			'Text on the create linked issue button. The button allows user to create a new issue and link it to the current issue.',
	},
});
