import type { RemoteLinkInformation } from '../../model';
import type { RemoteLinkAction } from '../../model/remote-links';
import type {
	RemoteLinkAttributeTuple,
	RemoteLinkLabel,
	RemoteLinkProvider as RemoteLinkProviderRest,
	RemoteLinksByType,
	RemoteLinkType,
} from '../../rest/details/types';
import { isVersionRelatedLinksProvider } from './utils';

type RemoteLinkProviderTransformedRest = {
	readonly name: string;
	readonly homeUrl: string;
	readonly logoUrl?: string;
	readonly documentationUrl?: string;
	readonly actions?: {
		readonly id: {
			label: RemoteLinkLabel;
			templateUrl: string;
		};
	};
};

type RemoteLinkProviderTransformed =
	| {
			readonly id: RemoteLinkProviderTransformedRest;
	  } // eslint-disable-next-line @typescript-eslint/no-explicit-any
	| Record<any, any>;

// Exported for testing
export const transformIntoRemoteLinkProvider = (
	providerId: string,
	remoteLinkProvider: RemoteLinkProviderTransformedRest,
) => ({
	id: providerId,
	name: remoteLinkProvider.name,
	homeUrl: remoteLinkProvider.homeUrl,
	logoUrl: remoteLinkProvider.logoUrl ? remoteLinkProvider.logoUrl : null,
	documentationUrl: remoteLinkProvider.documentationUrl
		? remoteLinkProvider.documentationUrl
		: null,
});

// Exported for testing
export const transformIntoRemoteLinkActions = (
	remoteLinkProvider: RemoteLinkProviderTransformedRest,
	actionIds?: string[],
	attributeMap?: RemoteLinkAttributeTuple[],
): RemoteLinkAction[] => {
	if (remoteLinkProvider.actions && actionIds) {
		const remoteLinkProviderActions = remoteLinkProvider.actions;
		return (
			actionIds
				// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly id: { label: RemoteLinkLabel; templateUrl: string; }; }'.
				.filter((actionId) => remoteLinkProviderActions[actionId])
				.map((actionId) => {
					// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly id: { label: RemoteLinkLabel; templateUrl: string; }; }'.
					const baseUrl = remoteLinkProviderActions[actionId].templateUrl;
					return {
						id: actionId,
						// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly id: { label: RemoteLinkLabel; templateUrl: string; }; }'.
						label: remoteLinkProviderActions[actionId].label.value,
						url: attributeMap
							? attributeMap.reduce(
									(resultUrl, substitution) =>
										resultUrl.replace(`{${substitution.key}}`, substitution.value),
									baseUrl,
								)
							: baseUrl,
					};
				})
		);
	}
	return [];
};

// Exported for testing
/* eslint-disable no-param-reassign */
export const transformRemoteLinkProviders = (
	providers: RemoteLinkProviderRest[],
): RemoteLinkProviderTransformed =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	providers.reduce<Record<string, any>>((result, provider) => {
		const { id, actions, ...restOfProvider } = provider;

		result[id] = {
			...restOfProvider,
			...(actions && {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				actions: actions.reduce<Record<string, any>>((actionResult, action) => {
					const { id: actionId, ...restOfAction } = action;
					actionResult[actionId] = restOfAction;
					return actionResult;
				}, {}),
			}),
		};

		return result;
	}, {});

export const transformRemoteLinks = (
	remoteLinksByType: RemoteLinksByType,
): RemoteLinkInformation => {
	const remoteLinkProviders = remoteLinksByType.providers.filter(
		(provider) => !isVersionRelatedLinksProvider(provider.id),
	);

	const modifiedProviders = transformRemoteLinkProviders(remoteLinkProviders);

	const remoteLinkTypes = remoteLinksByType.types.filter((type) => {
		const filteredLinks = type.remoteLinks.filter(
			(link) => !isVersionRelatedLinksProvider(link.providerId),
		);
		return filteredLinks.length > 0;
	});

	return remoteLinkTypes.reduce(
		(result, element: RemoteLinkType) => {
			const { type, remoteLinks } = element;

			const links = remoteLinks.filter((link) => !isVersionRelatedLinksProvider(link.providerId));

			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
			result.remoteLinksByType[type] = links
				.map((remoteLink) => {
					const { providerId, actionIds, attributeMap, description, status, ...restOfRemoteLink } =
						remoteLink;
					// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'RemoteLinkProviderTransformed'.
					const remoteLinkProvider = modifiedProviders[providerId];
					if (!remoteLinkProvider) {
						return {};
					}

					return {
						provider: transformIntoRemoteLinkProvider(providerId, remoteLinkProvider),
						description: description || null,
						status: status || null,
						...restOfRemoteLink,
						actions: transformIntoRemoteLinkActions(remoteLinkProvider, actionIds, attributeMap),
					};
				})
				.filter((remoteLink) => Object.keys(remoteLink).length !== 0);
			return result;
		},
		{ numProviders: remoteLinkProviders.length, remoteLinksByType: {} },
	);
};
