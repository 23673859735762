import React, { useCallback, useState } from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { PRETTY } from '@atlassian/jira-common-constants/src/jira-settings.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { getDefaultTimeTrackingUnit } from '@atlassian/jira-issue-format-time/src/common/utils/default-time-tracking-unit/index.tsx';
import type {
	OptionalTime,
	TimeTracking,
	TimeTrackingConfig,
} from '@atlassian/jira-issue-shared-types/src/common/types/jira-settings-type.tsx';
import type { FormValues } from '@atlassian/jira-issue-view-common-types/src/log-time-modal-type';
import type { MediaContext } from '@atlassian/jira-issue-view-common-types/src/media-context-type';
import isTimeStringValidOrBlank from '@atlassian/jira-issue-view-common-utils/src/time-string/is-time-string-valid-or-blank/index.tsx';
import timeStringToSeconds from '@atlassian/jira-issue-view-common-utils/src/time-string/time-string-to-seconds/index.tsx';
import { isNumeric } from '@atlassian/jira-issue-view-common-utils/src/type-check/index.tsx';
import { removeNewLineCharactersFromAdf } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import { timeTrackingFormatter } from '@atlassian/jira-time-tracking-formatter/src/main.tsx';
import type { FormState } from './modal/footer/types.tsx';
import LogTimeModal from './modal/view.tsx';

export type LogTimeValidator = (arg1: FormValues) => boolean;

export type Props = {
	isCollapsible?: boolean;
	isDone?: boolean;
	isWaiting?: boolean;
	isRollingUpData: boolean;
	isClassicProject: boolean;
	shouldDisplayRollUpDataControl: boolean;
	shouldUpdateDate?: boolean;
	initialValue: FormValues;
	// the dialog needs to access both individual (for the current issue)
	// and aggregated time tracking data because the progress tracker can display
	// a rolled up view, whereas the form component always deals with individual data
	timeTracking: TimeTracking;
	rolledUpTimeTracking: TimeTracking;
	estimatedTime: OptionalTime;
	rolledUpEstimatedTime: OptionalTime;
	config: TimeTrackingConfig;
	header: string;
	timeZone: string | undefined;
	validator: LogTimeValidator;
	mediaContext: MediaContext;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mentionProvider: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mentionEncoder: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	activityProvider: any;
	externalId: string;
	onSubmit: (arg1: FormValues, arg2: UIAnalyticsEvent) => void;
	onCancel: () => void;
	onWorkDescriptionChangeFailure: () => void;
	onToggleRollingUpData: (arg1: boolean, arg2: UIAnalyticsEvent) => void;
};

export const LogTimeModalWithValidation = ({
	isCollapsible = false,
	isDone = false,
	isWaiting = false,
	shouldUpdateDate = false,
	isRollingUpData,
	isClassicProject,
	shouldDisplayRollUpDataControl,
	initialValue,
	timeTracking,
	rolledUpTimeTracking,
	estimatedTime,
	rolledUpEstimatedTime,
	config,
	header,
	timeZone,
	validator,
	mediaContext,
	mentionProvider,
	mentionEncoder,
	activityProvider,
	externalId,
	onSubmit,
	onCancel,
	onWorkDescriptionChangeFailure,
	onToggleRollingUpData,
}: Props) => {
	const intl = useIntl();

	const [state, setState] = useState(() => ({
		// We save the original value so that any changes to the passed in value do not affect
		// the rendering of the progress tracker.
		value: initialValue,
		// Until the user sets a date manually, we use this defaultDateStarted value to
		// calculate the form value when the user updates the logged time.
		defaultDateStarted: Date.now(),
		isTimeLoggedValid: true,
		isTimeRemainingValid: true,
		hasUserInteracted: false,
	}));

	const getTimeStringToSeconds = useCallback(
		(timeString: string) => {
			const { daysPerWeek, hoursPerDay } = config;
			return timeStringToSeconds(daysPerWeek, hoursPerDay)(timeString);
		},
		[config],
	);

	const secondsToTimeString = useCallback(
		(seconds: number): string => {
			// At this time we need to force the value into an english formatted
			// time string eg: `1w 1d 1h 1m` as that is the only format supported
			// on entry, future changes will support localised values.
			const intlForTimeTrackingInput = {
				...intl,
				formatMessage: ({ defaultMessage }: { defaultMessage?: string }) => defaultMessage || '',
			};
			return timeTrackingFormatter(
				seconds,
				{
					workingHoursPerDay: config.hoursPerDay,
					workingDaysPerWeek: config.daysPerWeek,
					timeFormat: config.format || PRETTY,
					defaultUnit: config.defaultUnit,
				},
				intlForTimeTrackingInput,
			);
		},
		[config.daysPerWeek, config.defaultUnit, config.format, config.hoursPerDay, intl],
	);

	const applyDefaultTimeFormat = useCallback(
		(value: string, onValueChange: (arg1: string) => void) => {
			let newValue = value.trim();

			// If user doesn't type the valid format (m, h, d, or w), use default unit format set in the configuration.
			if (isNumeric(newValue)) {
				const { defaultUnit } = config;
				newValue = `${newValue}${getDefaultTimeTrackingUnit(defaultUnit)}`;
			}

			if (newValue !== value) {
				onValueChange(newValue);
			}
		},
		[config],
	);

	const onTimeLoggedChange = useCallback(
		(timeLogged: string) => {
			const isTimeLoggedValidNew = isTimeStringValidOrBlank(timeLogged);

			const timeLoggedSeconds = getTimeStringToSeconds(timeLogged) || 0;

			setState(({ value, ...oldState }) => {
				let newState = {
					...oldState,
					value: {
						...value,
						timeLogged,
						timeLoggedSeconds,
					},
					hasUserInteracted: true,
					isTimeLoggedValid: isTimeLoggedValidNew,
				};

				// Update the remaining time if the time logged is valid and there was an initial
				// remaining time value. We skip this if the time logged input is invalid, or there
				// is no initial time remaining value to compare to.
				if (isTimeLoggedValidNew && initialValue.timeRemaining) {
					const originalTimeLoggedSeconds = getTimeStringToSeconds(initialValue.timeLogged) || 0;
					const originalTimeRemaining = initialValue.timeRemainingSeconds || 0;
					const timeRemainingSecondsDelta =
						originalTimeLoggedSeconds + originalTimeRemaining - timeLoggedSeconds;
					const timeRemainingSeconds =
						timeRemainingSecondsDelta > 0 ? timeRemainingSecondsDelta : 0;
					const timeRemaining = secondsToTimeString(timeRemainingSeconds);

					newState = {
						...newState,
						value: {
							...newState.value,
							timeRemaining,
							timeRemainingSeconds,
						},
						isTimeRemainingValid: true,
					};
				}
				return newState;
			});
		},
		[
			getTimeStringToSeconds,
			initialValue.timeLogged,
			initialValue.timeRemaining,
			initialValue.timeRemainingSeconds,
			secondsToTimeString,
		],
	);

	const onTimeRemainingChange = useCallback(
		(timeRemaining: string) => {
			const timeRemainingSeconds = getTimeStringToSeconds(timeRemaining) || 0;

			setState(({ value, ...oldState }) => ({
				...oldState,
				value: {
					...value,
					timeRemaining,
					timeRemainingSeconds,
				},
				hasUserInteracted: true,
				isTimeRemainingValid: isTimeStringValidOrBlank(timeRemaining),
			}));
		},
		[getTimeStringToSeconds],
	);

	const onTimeLoggedBlur = useCallback(() => {
		applyDefaultTimeFormat(state.value.timeLogged, onTimeLoggedChange);
	}, [applyDefaultTimeFormat, onTimeLoggedChange, state]);

	const onTimeRemainingBlur = useCallback(() => {
		applyDefaultTimeFormat(state.value.timeRemaining, onTimeRemainingChange);
	}, [applyDefaultTimeFormat, onTimeRemainingChange, state.value.timeRemaining]);

	const onDateStartedChange = useCallback((dateStarted: string | null) => {
		// @ts-expect-error - TS2345 - Argument of type '({ value, ...oldState }: { value: FormValues; defaultDateStarted: number; isTimeLoggedValid: boolean; isTimeRemainingValid: boolean; hasUserInteracted: boolean; }) => { value: { dateStarted: string | null; ... 4 more ...; workDescription: DocNode; }; defaultDateStarted: null; hasUserInteracted: true; isTimeLoggedVal...' is not assignable to parameter of type 'SetStateAction<{ value: FormValues; defaultDateStarted: number; isTimeLoggedValid: boolean; isTimeRemainingValid: boolean; hasUserInteracted: boolean; }>'.
		setState(({ value, ...oldState }) => ({
			...oldState,
			value: { ...value, dateStarted },
			defaultDateStarted: null,
			hasUserInteracted: true,
		}));
	}, []);

	const onWorkDescriptionChange = useCallback((workDescription: ADF) => {
		setState(({ value, ...oldState }) => ({
			...oldState,
			value: { ...value, workDescription },
			hasUserInteracted: true,
		}));
	}, []);

	const getDateStarted = useCallback(() => {
		const {
			value: { dateStarted, timeLogged },
			defaultDateStarted,
		} = state;

		if (!shouldUpdateDate || dateStarted) {
			return dateStarted;
		}

		if (!defaultDateStarted) {
			return null;
		}

		// If the user has not manually set a date, then calculate the date value by subtracting
		// the time logged from the current timestamp.
		const timeLoggedSeconds = getTimeStringToSeconds(timeLogged) || 0;

		// Handle the case where timeLoggedSeconds is too large and causes Date.toISOString to
		//  throw an error.
		try {
			return new Date(defaultDateStarted - timeLoggedSeconds * 1000).toISOString();
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			return new Date(defaultDateStarted).toISOString();
		}
	}, [getTimeStringToSeconds, shouldUpdateDate, state]);

	const getValue = useCallback(
		(): FormValues => ({
			...state.value,
			dateStarted: getDateStarted(),
		}),
		[getDateStarted, state.value],
	);

	const handleSubmit = useCallback(
		(_: unknown, analyticsEvent: UIAnalyticsEvent) => {
			const { workDescription } = state.value;
			const modifiedAdf =
				typeof workDescription !== 'string'
					? removeNewLineCharactersFromAdf(workDescription)
					: workDescription;
			const modifiedForm = {
				...getValue(),
				workDescription: modifiedAdf,
			};

			onSubmit(modifiedForm, analyticsEvent);
		},
		[getValue, onSubmit, state.value],
	);

	const getIsCollapsed = useCallback(
		() => isCollapsible && !state.value.timeLogged,
		[isCollapsible, state.value.timeLogged],
	);

	const getFormState = useCallback((): FormState => {
		if (isWaiting) {
			return 'submitting';
		}
		if (!state.hasUserInteracted) {
			return 'invalid';
		}
		if (validator(getValue())) {
			return 'valid';
		}

		return 'invalid';
	}, [getValue, isWaiting, state.hasUserInteracted, validator]);

	const getProgressTrackerValue = useCallback(() => {
		const { timeLogged, timeRemaining } = state.value;
		const timeTrackingNew = isRollingUpData ? rolledUpTimeTracking : timeTracking;

		// If there is a valid value in the time logged field, calculate the delta between the
		// initial value and the user input value in seconds, and add that to the existing
		// timeTracking value.
		// Otherwise, fall back to the existing timeTracking value.
		const timeLoggedSeconds = getTimeStringToSeconds(timeLogged) || 0;
		const originalTimeLoggedSeconds = getTimeStringToSeconds(initialValue.timeLogged) || 0;
		const timeLoggedDelta = timeLoggedSeconds - originalTimeLoggedSeconds;
		const loggedTime =
			timeLoggedDelta || timeTrackingNew.loggedTime
				? (timeLoggedDelta || 0) + (timeTrackingNew.loggedTime || 0)
				: undefined;

		// If there is a valid value in the time remaining field, we want to use that value:
		// However in rolling up estimates we calculate the difference and then use it as the
		// delta for the new value, when not rolling up we just whatever value the user has provided.
		// For any non-valid value provided by the uyser, fall back to the existing `timeTracking` value
		const timeRemainingSeconds = getTimeStringToSeconds(timeRemaining);
		const originalTimeRemainingSeconds = getTimeStringToSeconds(initialValue.timeRemaining) || 0;
		const timeRemainingDelta = (timeRemainingSeconds || 0) - originalTimeRemainingSeconds;
		const isTimeRemainingValidNew = typeof timeRemainingSeconds === 'number';

		let { remainingTime } = timeTrackingNew;
		if (isTimeRemainingValidNew) {
			remainingTime = isRollingUpData
				? (timeTrackingNew.remainingTime || 0) + timeRemainingDelta
				: timeRemainingSeconds || 0;
		}

		return { loggedTime, remainingTime };
	}, [
		getTimeStringToSeconds,
		initialValue.timeLogged,
		initialValue.timeRemaining,
		isRollingUpData,
		rolledUpTimeTracking,
		state.value,
		timeTracking,
	]);

	return (
		<LogTimeModal
			value={getValue()}
			progressTrackerValue={getProgressTrackerValue()}
			estimatedTime={estimatedTime}
			rolledUpEstimatedTime={rolledUpEstimatedTime}
			config={config}
			header={header}
			formState={getFormState()}
			timeZone={timeZone}
			isCollapsed={getIsCollapsed()}
			isDone={isDone}
			isTimeLoggedValid={state.isTimeLoggedValid}
			isTimeRemainingValid={state.isTimeRemainingValid}
			onSubmit={handleSubmit}
			onCancel={onCancel}
			onTimeLoggedChange={onTimeLoggedChange}
			onTimeLoggedBlur={onTimeLoggedBlur}
			onTimeRemainingChange={onTimeRemainingChange}
			onTimeRemainingBlur={onTimeRemainingBlur}
			onDateStartedChange={onDateStartedChange}
			onWorkDescriptionChange={onWorkDescriptionChange}
			onWorkDescriptionChangeFailure={onWorkDescriptionChangeFailure}
			mediaContext={mediaContext}
			mentionProvider={mentionProvider}
			mentionEncoder={mentionEncoder}
			activityProvider={activityProvider}
			externalId={externalId}
			shouldDisplayRollUpDataControl={shouldDisplayRollUpDataControl}
			isRollingUpData={isRollingUpData}
			onToggleRollingUpData={onToggleRollingUpData}
			isClassicProject={isClassicProject}
		/>
	);
};

export default LogTimeModalWithValidation;
