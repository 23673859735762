import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import VoteButtonRefresh from '../../common/vote-button-refresh';
import { VOTERS_UI_STYLED_BUTTON } from '../../constants';
import VoteIcon from '../icon';
import { StyledButton } from './styled';
import type { Props } from './types';

export const VoteButton = ({
	onClick,
	// @ts-expect-error - TS2322 - Type 'null' is not assignable to type 'string'.
	tooltip = null,
	isSelected = false,
	isDisabled = false,
	ariaLabel = '',
}: Props) => (
	<Tooltip content={tooltip} position="top">
		{isVisualRefreshEnabled() ? (
			<VoteButtonRefresh
				testId={VOTERS_UI_STYLED_BUTTON}
				onClick={onClick}
				isSelected={isSelected}
				label={ariaLabel}
				aria-expanded={isSelected}
				isDisabled={isDisabled}
			/>
		) : (
			<StyledButton
				testId={VOTERS_UI_STYLED_BUTTON}
				onClick={onClick}
				isSelected={isSelected}
				appearance="subtle"
				spacing="default"
				aria-label={ariaLabel}
				aria-expanded={isSelected}
				isDisabled={isDisabled}
			>
				<VoteIcon isSelected={isSelected} />
			</StyledButton>
		)}
	</Tooltip>
);

export default VoteButton;
