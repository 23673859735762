import React, { type ReactNode, useCallback, useState } from 'react';
import type { ProjectType } from '@atlassian/jira-common-constants';
import { IssueSelect as IssueSelectRaw } from '@atlassian/jira-polaris-lib-issue-select/src/ui/index.tsx';
import type { SelectedProject } from '../../../common/ui/project-picker/option';
import { useArchivedFieldsConfig } from '../../../controllers/field/selectors/field-hooks';
import { useIssueActions } from '../../../controllers/issue/main.tsx';
import { useSelectedIssueKey } from '../../../controllers/issue/selectors/properties/hooks';
import { useIdeaIssueTypes } from '../../../controllers/project/selectors/project-hooks.tsx';
import type { GroupedOption, IssueOption } from './types';

type IssueSelectProps = {
	isDisabled: boolean;
	autoFocus: boolean;
	isAttachedToBody: boolean;
	shouldStartImmediateLoading?: boolean;
	projectId: string | undefined;
	excludedProjectTypes: ProjectType[];
	defaultOptions?: GroupedOption;
	hideArchived: boolean;
	isIdeasSelect?: boolean;
	placeholder?: string;
	createOption?: {
		onCreateStart: () => void;
		onCreateEnd: () => void;
	};
	excludedIssueIds?: number[];
	onIssueSelected: (arg1: IssueOption | undefined) => void;
	renderOptionIconAfter?: (option: IssueOption) => ReactNode;
	onProjectChanged?: (payload: SelectedProject | undefined) => void;
};

const EXCLUDED_ISSUE_IDS: IssueSelectProps['excludedIssueIds'] = [];

export const IssueSelect = ({
	isDisabled,
	autoFocus,
	projectId,
	excludedProjectTypes,
	excludedIssueIds = EXCLUDED_ISSUE_IDS,
	hideArchived,
	isIdeasSelect,
	placeholder,
	defaultOptions,
	onIssueSelected,
	createOption,
	isAttachedToBody,
	renderOptionIconAfter,
	onProjectChanged,
	shouldStartImmediateLoading,
}: IssueSelectProps) => {
	const [selectedIssue, setSelectedIssue] = useState<IssueOption | undefined>(undefined);
	const { createAndUpdate } = useIssueActions();
	const [ideaTypes] = useIdeaIssueTypes();
	const selectedIssueKey = useSelectedIssueKey();
	const [{ archivedField }] = useArchivedFieldsConfig();

	const handleCreateIssue = useCallback(
		(summary: string) => {
			if (createOption !== undefined && ideaTypes !== undefined) {
				createOption.onCreateStart();
				try {
					createAndUpdate(
						ideaTypes[0],
						summary,
						{ summary },
						// eslint-disable-next-line @typescript-eslint/no-empty-function
						() => {},
						(_localIssueId, _overrideState, issue) => {
							const newSelectedIssue = {
								label: summary,
								value: issue.key,
								item: {
									id: Number.parseInt(issue.id, 10),
									key: issue.key,
									img: issue.fields.issuetype.iconUrl,
									summaryText: summary,
								},
							};

							setSelectedIssue(newSelectedIssue);
							onIssueSelected(newSelectedIssue);
						},
					);
				} finally {
					createOption.onCreateEnd();
				}
			}
		},
		[createAndUpdate, createOption, ideaTypes, onIssueSelected],
	);

	return (
		<IssueSelectRaw
			archivedFieldLabel={archivedField?.label}
			selectedIssueKey={selectedIssueKey}
			isDisabled={isDisabled}
			autoFocus={autoFocus}
			placeholder={placeholder}
			defaultOptions={defaultOptions}
			isAttachedToBody={isAttachedToBody}
			shouldStartImmediateLoading={shouldStartImmediateLoading}
			selectedIssue={selectedIssue}
			setSelectedIssue={setSelectedIssue}
			onCreateIssue={handleCreateIssue}
			projectId={projectId}
			excludedProjectTypes={excludedProjectTypes}
			onIssueSelected={onIssueSelected}
			excludedIssueIds={excludedIssueIds}
			renderOptionIconAfter={renderOptionIconAfter}
			hideArchived={hideArchived}
			isIdeasSelect={isIdeasSelect}
			onProjectChanged={onProjectChanged}
			createOption={createOption}
		/>
	);
};
