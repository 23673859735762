import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { RecaptchaTermsAndPolicy } from '@atlassian/google-recaptcha';
import { expVal } from '@atlassian/jira-feature-experiments';
import { SuccessAutoDismissFlag } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages';
import type { AssigneeInvitedFlagProps } from './types';

export const AssigneeInvitedFlag = ({
	email,
	onCancel,
	onDismissed,
	...props
}: AssigneeInvitedFlagProps) => {
	const { formatMessage } = useIntl();

	return (
		<SuccessAutoDismissFlag
			title={formatMessage(messages.title)}
			description={
				<>
					{formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.descriptionIssueTermRefresh
							: messages.description,
						{
							email,
							strong: (chunks: ReactNode[]) => <Text as="strong">{chunks}</Text>,
						},
					)}
					<RecaptchaTermsAndPolicyWrapper>
						<RecaptchaTermsAndPolicy />
					</RecaptchaTermsAndPolicyWrapper>
				</>
			}
			onDismissed={onDismissed}
			actions={[
				{
					content: formatMessage(messages.actionCancel),
					onClick: onCancel,
				},
			]}
			{...props}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RecaptchaTermsAndPolicyWrapper = styled.div({
	font: token('font.body.UNSAFE_small'),
	marginTop: token('space.100', '8px'),
});
