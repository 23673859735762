import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	atlassianIntelligence: {
		id: 'issue-improve-issue-dropdown.atlassian-intelligence',
		defaultMessage: 'Atlassian Intelligence',
		description: 'The content to be displayed in the dropdown for improve issue',
	},
	buttonImproveIssue: {
		id: 'issue-improve-issue-dropdown.button-improve-issue',
		defaultMessage: 'Improve issue',
		description: 'Button label for improving issue in the quick add list',
	},
	aiSuggestRelatedResourcesLabel: {
		id: 'issue-improve-issue-dropdown.ai-suggest-related-resources-label',
		defaultMessage: 'Suggest related resources',
		description: 'The label to be used on the suggest related resources to trigger the AI feature',
	},
	aiSuggestImproveDescriptionLabel: {
		id: 'issue-improve-issue-dropdown.ai-suggest-improve-description-label',
		defaultMessage: 'Improve issue description',
		description: 'The label to be used on the improve issue description to trigger the AI feature',
	},
	aiSuggestSummarizeCommentsLabel: {
		id: 'issue-improve-issue-dropdown.ai-suggest-summarize-comments-label',
		defaultMessage: 'Summarize comments',
		description: 'The label to be used on the summarise comments to trigger the AI feature',
	},
	aiSuggestChildIssuesLabel: {
		id: 'issue-improve-issue-dropdown.ai-suggest-child-issues-label',
		defaultMessage: 'Suggest child issues',
		description: 'The label to be used on the suggest child issues to trigger the AI feature',
	},
	aiSuggestRelatedResourcesDisabledMessage: {
		id: 'issue-improve-issue-dropdown.ai-suggest-related-resources-disabled-message',
		defaultMessage:
			'Can’t suggest related resources because application links are disabled on this site.',
		description: 'The message for disabled state of the suggest related resources AI feature',
	},
	aiSuggestImproveDescriptionDisabledMessage: {
		id: 'issue-improve-issue-dropdown.ai-suggest-improve-description-disabled-message',
		defaultMessage: 'First enter an issue description to improve it with AI.',
		description: 'The message for disabled state of the improve issue description AI feature',
	},
	aiSuggestSummarizeCommentsDisabledMessage: {
		id: 'issue-improve-issue-dropdown.ai-suggest-summarize-comments-disabled-message',
		defaultMessage: 'No comments on this issue yet, so can’t summarize them with AI.',
		description: 'The message for disabled state of the summarise comments AI feature',
	},
	aiSuggestChildIssuesDisabledState: {
		id: 'issue-improve-issue-dropdown.ai-suggest-child-issues-disabled-state',
		defaultMessage: 'Can’t add child issues to a sub-task.',
		description: 'The message for disabled state of the suggest child issues AI feature',
	},
});
