import React, { useCallback } from 'react';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import { DateTimePicker } from '@atlaskit/datetime-picker';
import availableTimes from '@atlassian/jira-common-constants/src/datetimepicker-available-times';
import dateMessages from '@atlassian/jira-common-date/src/utils/date-messages/index.tsx';
import { convertISODateFormat } from '@atlassian/jira-compatibility';
import { useIntl } from '@atlassian/jira-intl';
import type { ParseValue } from '@atlassian/jira-issue-view-common-utils/src/date-time-formatter/types.tsx';

export type Props = {
	isDisabled?: boolean;
	value?: string | null | undefined;
	autoFocus?: boolean;
	timeZone: string | undefined;
	parseValue: ParseValue;
	onChange: (iso: string | null) => void;
	weekStartDay?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;
	locale?: string;
};

export const LegacyDateTimeFieldView = ({
	isDisabled = false,
	value = undefined,
	autoFocus = false,
	timeZone,
	parseValue,
	onChange,
	weekStartDay = undefined,
	locale = 'en-US',
}: Props) => {
	const { formatMessage } = useIntl();

	const onValChange = useCallback(
		(iso: string) => {
			const convertedIso = convertISODateFormat(iso);
			onChange(isNaN(Date.parse(convertedIso)) ? null : convertedIso);
		},
		[onChange],
	);

	const parseValueWithTimeZone = useCallback(
		(val: string | null, date: string | null, time: string | null) =>
			parseValue(val, date, time, timeZone),
		[parseValue, timeZone],
	);

	return (
		<DateTimePicker
			/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
			testId="issue.common.component.legacy-date-time.date-time-picker"
			autoFocus={autoFocus}
			isDisabled={isDisabled}
			// empty string to keep this a controlled component (undefined will make it uncontrolled)
			value={value || ''}
			onChange={onValChange}
			// @ts-expect-error - TS2322 - Type '((val: string | null, date: string | null, time: string | null) => { dateValue: string | null; timeValue: string | null; zoneValue: string | null; }) | undefined' is not assignable to type '((dateTimeValue: string, date: string, time: string, timezone: string) => { dateValue: string; timeValue: string; zoneValue: string; }) | undefined'.
			parseValue={!isNil(timeZone) && timeZone !== '' ? parseValueWithTimeZone : undefined}
			datePickerProps={{
				weekStartDay,
				placeholder: formatMessage(dateMessages.placeholderDate),
			}}
			/* only pass locale if feature flag is enabled */
			locale={locale}
			timePickerProps={{
				times: availableTimes,
				timeIsEditable: true,

				selectProps: {
					formatCreateLabel: (val: unknown) => val,

					// Want to show the dropdown option if the user input
					// matches the value (22:30) or the label (10:30pm)
					filterOption: (option: { value: string; label: string }, val: string) =>
						!val || option.value === val || option.label === val,
				},
			}}
		/>
	);
};

export default LegacyDateTimeFieldView;
