import { createSelector } from 'reselect';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { EDIT_MODAL } from '@atlassian/jira-issue-view-common-types/src/worklog-type';
import { TIME_TRACKING } from '@atlassian/jira-issue-view-configurations';
import {
	isRichTextFieldEditingSelector,
	isFieldEditingSelector,
} from '../../../common/state/selectors/field-selector';
import { openModalSelector } from '../../../common/state/selectors/time-tracking-selector';
import { isAttachmentPickerPopupOpenSelector } from '../../../selectors/attachment-picker-selector';
import { isCommentEditorOpenSelector } from '../../../selectors/comment-selector';
import { isFormInEditModeSelector } from '../../../selectors/proforma-selector';

const selector = createSelector(
	isRichTextFieldEditingSelector,
	isCommentEditorOpenSelector,
	isAttachmentPickerPopupOpenSelector,
	isFormInEditModeSelector,
	isFieldEditingSelector(TIME_TRACKING),
	openModalSelector,
	(
		isEditingRichTextField,
		isEditingComment,
		isAttachmentPickerPopupOpen,
		isProformaFormInEditMode,
		isTimeTrackingAddModalOpen,
		openTimeTrackingModal,
	) =>
		!isEditingRichTextField &&
		!isEditingComment &&
		!isAttachmentPickerPopupOpen &&
		!isProformaFormInEditMode &&
		!isTimeTrackingAddModalOpen &&
		!(openTimeTrackingModal === EDIT_MODAL),
);

export const isIssueDropzoneAndClipboardUploadEnabled = (
	state: State,
): ReturnType<typeof selector> => selector(state);
