// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createNewItem: {
		id: 'issue.fix-versions.create-new-component-message',
		defaultMessage: 'Create new version',
		description: '',
	},
	recent: {
		id: 'issue.fix-versions.create-new-component-message.recent',
		defaultMessage: 'Recent versions',
		description: 'Header displayed in versions list used to mark recently selected items',
	},
	releasedHeading: {
		id: 'issue.fix-versions.released-message',
		defaultMessage: 'Released',
		description: 'Header displayed in versions list used to mark released items',
	},
	unreleasedHeading: {
		id: 'issue.fix-versions.unreleased-message',
		defaultMessage: 'Unreleased',
		description: 'Header displayed in versions list used to mark unreleased items',
	},
});
