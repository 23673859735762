import get from 'lodash/get';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { PROFORMA_FORMS } from '@atlassian/jira-platform-field-config';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useFormsInIssueStatus } from '../forms-status-in-issue-service';
import type { Content } from './types';

type Props = {
	issueKey: IssueKey;
	onSuccess?: (arg1: Content) => void;
	onFailure?: (arg1: Error) => void;
};

export const useForms = ({ issueKey, onSuccess, onFailure }: Props) => {
	/*
	 * useFieldConfig and useEditField grab info out of 2 react-sweet-state containers on the
	 * issue view.
	 * */
	const [fieldConfig] = useFieldConfig(issueKey, PROFORMA_FORMS);
	const fieldType = get(fieldConfig.value, 'schema.type', 'proforma-forms');

	const [{ value, error }] = useEditField<Content, null>({
		fieldKey: PROFORMA_FORMS,
		issueKey,
		fieldType,
		initialValue: {
			hasIssueForms: undefined,
			hasProjectForms: undefined,
		},
		onSuccess,
		onFailure,
	});

	const [issueViewAddFormButtonClickedCount, { onClickAddFormButton, resetAddFormButtonCount }] =
		useFormsInIssueStatus();

	return [
		{
			value: {
				hasIssueForms: value?.hasIssueForms,
				hasProjectForms: value?.hasProjectForms,
				issueViewAddFormButtonClickedCount,
			},
			error,
			fieldConfig,
		},
		{ onClickAddFormButton, resetAddFormButtonCount },
	] as const;
};
