/* eslint-disable @atlassian/relay/unused-fields */
/* eslint-disable @atlassian/relay/query-restriction */
import React, { useCallback, useMemo } from 'react';
import type { Dispatch } from 'redux';
import flow from 'lodash/flow';
import { graphql, useFragment } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import { fireUiAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-ui-event.tsx';
import { log } from '@atlassian/jira-common-util-logging';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import type { User } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import { ADD_WATCHER_ERROR } from '@atlassian/jira-issue-view-common-constants';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { transformEdgesToTransformedNodes } from '@atlassian/jira-relay-utils/src/utils/transform-edges-to-transformed-nodes';
import type {
	addWatchers_issueViewWatchers_AddWatchersRelay$key,
	addWatchers_issueViewWatchers_AddWatchersRelay$data,
} from '@atlassian/jira-relay/src/__generated__/addWatchers_issueViewWatchers_AddWatchersRelay.graphql';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useOnShowFlag } from '../../../../controllers/watchers-context';
import { useWatchesMutation } from '../../../../services/use-watches-mutation';
import type { State } from '../../../model/types';
import { addWatcherRequest, type AddWatcherRequestAction } from '../../../state/actions';
import {
	getCanManageWatchers,
	getBaseUrl,
	getIssueKey,
	getWatchersListUsers,
} from '../../../state/selectors';
import { AddWatchers } from './view';

type StateProps = {
	issueKey: IssueKey;
	canManageWatchers: boolean;
	baseUrl: BaseUrl;
	watchers: User[];
};

type DispatchProps = {
	onAddWatcher: (arg1: User, arg2: () => void, arg3: () => void, arg4: UIAnalyticsEvent) => void;
};

export const AddWatchersOld = flow(
	withFireUiAnalytics({
		onShowAddWatchersAnalytics: 'showAddWatchers',
	}),
	connect(
		(state: State): StateProps => ({
			canManageWatchers: getCanManageWatchers(state),
			baseUrl: getBaseUrl(state),
			issueKey: getIssueKey(state),
			watchers: getWatchersListUsers(state),
		}),
		(dispatch: Dispatch<AddWatcherRequestAction>): DispatchProps => ({
			onAddWatcher: (user, onSuccess, onError, analyticsEvent) => {
				dispatch(addWatcherRequest({ user, onSuccess, onError }));
				fireUiAnalytics(analyticsEvent, { name: 'addWatcher' });
			},
		}),
	),
)(AddWatchers);

const transformNodeToUser = (
	node: NonNullable<
		NonNullable<
			NonNullable<
				NonNullable<
					addWatchers_issueViewWatchers_AddWatchersRelay$data['selectedUsersConnection']
				>['edges']
			>[number]
		>['node']
	>,
): User => ({
	id: node.accountId,
	displayName: node.name,
	avatarUrl: node.picture,
	ari: node.id,
});

const AddWatchersRelay = ({
	watches,
	...rest
}: {
	watches: addWatchers_issueViewWatchers_AddWatchersRelay$key;
	onShowAddWatchersAnalytics: (arg1: UIAnalyticsEvent) => void;
} & Omit<StateProps, 'watchers'>) => {
	const data = useFragment<addWatchers_issueViewWatchers_AddWatchersRelay$key>(
		graphql`
			fragment addWatchers_issueViewWatchers_AddWatchersRelay on JiraWatchesField {
				id
				watch {
					count
					isWatching
					...useWatchesMutation_Mutation_Updatable
				}
				type
				fieldId
				selectedUsersConnection {
					edges {
						node {
							... on User {
								id
								accountId
								name
								picture
							}
						}
					}
				}
			}
		`,
		watches,
	);

	const watchers = useMemo(
		() => transformEdgesToTransformedNodes(data.selectedUsersConnection, transformNodeToUser),
		[data.selectedUsersConnection],
	);

	const { onUpdate } = useWatchesMutation();

	const { onShowFlag } = useOnShowFlag();
	const onAddWatcher = useCallback(
		async (
			user: User,
			onSuccess: () => void,
			onError: () => void,
			analyticsEvent: UIAnalyticsEvent,
		) => {
			const handleError = (error: Error) => {
				onShowFlag?.(ADD_WATCHER_ERROR);
				log.safeErrorWithoutCustomerData('issue.watchers.add.watcher', error?.message, error);
				onError?.();
			};
			if (!user?.ari) {
				handleError(new Error('Could not find user to delete'));
				return;
			}
			onUpdate({
				id: data.id,
				userAri: user?.ari,
				isWatching: true,
				onError: handleError,
				onSuccess,
				watch: data.watch,
				field: {
					type: data.type,
					fieldId: data.fieldId,
					watch: {
						count: data?.watch?.count,
						isWatching: data?.watch?.isWatching,
					},
				},
			});
			fireUiAnalytics(analyticsEvent, { name: 'addWatcher' });
		},
		[data, onShowFlag, onUpdate],
	);

	return <AddWatchers {...rest} watchers={watchers} onAddWatcher={onAddWatcher} />;
};

export const AddWatchersNew = flow(
	withFireUiAnalytics({
		onShowAddWatchersAnalytics: 'showAddWatchers',
	}),
	connect((state: State) => ({
		canManageWatchers: getCanManageWatchers(state),
		issueKey: getIssueKey(state),
	})),
)(AddWatchersRelay);

export default componentWithCondition(
	() => ff('relay-migration-issue-fields-watchers_iqhn4'),
	AddWatchersNew,
	AddWatchersOld,
);
