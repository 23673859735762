import type { FlagConfiguration } from '@atlassian/jira-flags';
import {
	LINK_PARENT_FAILURE,
	REORDER_CHILDREN_FAILED,
	type LinkParentFailureAction,
	type ReorderChildrenFailedAction,
} from '../state/entities/actions';
import messages from './messages';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	action: LinkParentFailureAction | ReorderChildrenFailedAction,
): FlagConfiguration | undefined => {
	switch (action.type) {
		case LINK_PARENT_FAILURE: {
			return {
				type: 'error',
				title: messages.linkParentErrorTitle,
				description: messages.linkParentErrorDescription,
			};
		}

		case REORDER_CHILDREN_FAILED: {
			return {
				type: 'error',
				title: messages.reorderErrorTitle,
				description: messages.reorderErrorDescription,
			};
		}

		default:
			return undefined;
	}
};
