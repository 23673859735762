import React, { type ReactNode, type ReactElement } from 'react';
import { Text } from '@atlaskit/primitives';
import TextWithIcon from '@atlassian/jira-common-components-text-with-icon/src/view.tsx';
import SelectInlineEdit from '../common/select-inline-edit';
import type {
	SelectValueShape,
	ServerSuggestions,
} from '../common/select-inline-edit/select-field/types';
import type { SelectInlineEditProps } from '../common/select-inline-edit/types';

export type Props = SelectInlineEditProps & {
	components?:
		| undefined
		| {
				Menu: ReactNode;
		  };
	getDataFromCache?: () => Promise<ServerSuggestions>;
	componentsProps?:
		| undefined
		| {
				isChecked: boolean;
				onChange: () => undefined | Promise<undefined>;
		  };
	value: SelectValueShape | null | undefined;
	titleView?: ReactNode;
	fieldContentWrapper?: (arg1: { children: ReactNode; topMargin?: number }) => ReactElement;
	renderReadView?: () => ReactElement;
	onChange: (arg1: SelectValueShape | null | undefined) => void;
	onDataRequest?: (arg1: { isInitial: boolean }) => void;
	onDataLoaded?: (arg1: { isInitial: boolean }) => void;
};

const defaultRenderReadView = (props: Props) => () => {
	const { value, noValueText } = props;

	if (value && !Array.isArray(value)) {
		const { iconUrl, content } = value;

		if (iconUrl !== undefined) {
			return <TextWithIcon iconUrl={iconUrl}>{content}</TextWithIcon>;
		}
		return content;
	}

	return <Text color="color.text.subtlest">{noValueText}</Text>;
};

const SingleSelectInlineEditView = (props: Props) => (
	<SelectInlineEdit
		{...props}
		// @ts-expect-error - TS2322 - Type '() => string | JSX.Element' is not assignable to type '() => ReactElement<any, string | JSXElementConstructor<any>>'.
		renderReadView={props.renderReadView || defaultRenderReadView(props)}
		isMulti={false}
		isInvalid={props.value?.isInvalid}
		noOptionsMessage={props.value?.noOptionsMessage}
	/>
);

SingleSelectInlineEditView.displayName = 'SingleSelectInlineEditView';

export default SingleSelectInlineEditView;
