import { useMemo } from 'react';
import type { MediaContext } from '@atlassian/jira-issue-media-provider/src/common/types.tsx';
import type {
	FilmStripViewMediaContext,
	UploadContext,
	MediaUploadTokenResult,
	MediaUploadTokenError,
	MediaReadTokenResult,
} from './types';

const MILLISECONDS_IN_A_SECOND = 1000;
const MILLISECONDS_IN_A_MINUTE = 60 * MILLISECONDS_IN_A_SECOND;

const isUploadTokenError = (
	uploadToken: UploadContext | null,
): uploadToken is MediaUploadTokenError => {
	if (!uploadToken) {
		return false;
	}
	if ('errorId' in uploadToken && uploadToken.errorId) {
		return true;
	}
	if ('errorMessage' in uploadToken && uploadToken.errorMessage) {
		return true;
	}
	return false;
};
const isUploadTokenValid = (
	uploadToken: UploadContext | null,
): uploadToken is MediaUploadTokenResult =>
	Boolean(uploadToken) && !isUploadTokenError(uploadToken);

const getUploadContext = (uploadToken: UploadContext | null): MediaContext['uploadContext'] => {
	if (!isUploadTokenValid(uploadToken)) {
		return null;
	}
	const mediaUploadContext = {
		clientId: uploadToken.clientId,
		serviceHost: uploadToken.endpointUrl,
		collection: uploadToken.targetCollection,
		token: uploadToken.token,
		tokenLifespanInMs: uploadToken.tokenDurationInMin * MILLISECONDS_IN_A_MINUTE,
		areTokenPermissionsUpToDate: true,
		tokenIssueTimestamp: -1,
	};

	return mediaUploadContext;
};

const getViewContext = (readToken: MediaReadTokenResult | null): MediaContext['viewContext'] => {
	if (!readToken) {
		return null;
	}
	const mediaViewContext = {
		clientId: readToken.clientId,
		serviceHost: readToken.endpointUrl,
		collection: null,
		token: readToken.token,
		tokenLifespanInMs: readToken.tokenLifespanInSeconds * MILLISECONDS_IN_A_SECOND,
		areTokenPermissionsUpToDate: true,
		tokenIssueTimestamp: -1,
	};

	return mediaViewContext;
};

const getMediaProviderFromTokens = ({
	uploadToken,
	readToken,
}: {
	uploadToken: UploadContext | null;
	readToken: MediaReadTokenResult | null;
}): MediaContext => {
	const uploadContext = getUploadContext(uploadToken);
	let viewContext = getViewContext(readToken);
	if (!viewContext) {
		viewContext = null;
		if (uploadContext) {
			viewContext = { ...uploadContext, areTokenPermissionsUpToDate: true };
		}
	}

	return {
		viewContext,
		uploadContext,
		userAuth: null,
	};
};

export const useMediaClientConfig = (mediaContext: FilmStripViewMediaContext) => {
	const { uploadToken, readToken = null } = mediaContext;
	const mediaProvider = useMemo(
		() => getMediaProviderFromTokens({ uploadToken, readToken }),
		[uploadToken, readToken],
	);
	const { viewContext } = mediaProvider;

	return useMemo(
		() => ({
			authProvider: () =>
				Promise.resolve({
					baseUrl: viewContext?.serviceHost || '',
					clientId: viewContext?.clientId || '',
					token: viewContext?.token || '',
				}),
			useSha256ForUploads: true,
		}),
		[viewContext],
	);
};
