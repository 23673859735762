/**
 * @jsxRuntime classic
 * @jsx jsx
 */
/** @jsxFrag */
import React from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import { type JSONDocNode } from '@atlaskit/editor-json-transformer';
import type { Schema } from '@atlaskit/editor-prosemirror/model';
import { Box, Flex, Text, xcss } from '@atlaskit/primitives';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';
import { token } from '@atlaskit/tokens';

import type { EditorAgent } from '../../../utils/agents';
import { useAgentRefinementTagProps } from '../../hooks/use-agent-refinement-tag-props';
import { BackIconButton } from '../BackIconButton/BackIconButton';
import { EditPromptHoverTag } from '../EditPromptHoverTag/EditPromptHoverTag';
import { LatestPromptRenderer } from '../LatestPromptRenderer/LatestPromptRenderer';
import { RefinementTag } from '../RefinementTag/RefinementTag';

import { messages } from './messages';

const PROMPT_CONTENT_ID = 'ai-latest-prompt-content';

const headerEditStyles = css({
	cursor: 'pointer',
	border: 'none',
	width: '100%',
	backgroundColor: 'transparent',
	color: 'inherit',
	textAlign: 'start',
	fontSize: 'unset',
	lineHeight: 'inherit',
	borderRadius: token('border.radius.200', '4px'),
	borderBottomLeftRadius: 0,
	borderBottomRightRadius: 0,
	'&:hover': {
		backgroundColor: token('color.background.neutral.subtle.hovered', '#091E420F'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:hover #edit-prompt-hover-button': {
		opacity: 1,
	},
	'&:active': {
		backgroundColor: token('color.background.neutral.subtle.pressed', '#091E4224'),
	},
	'&:focus': {
		outline: `2px solid ${token('color.border.focused', '#4C9AFF')}`,
		outlineOffset: token('space.negative.025', '-2px'),
	},
});

const contentContainerStyles = css({
	lineHeight: '20px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	hyphens: 'auto',
	wordWrap: 'break-word',
});

const fullWidth = xcss({
	width: '100%',
});

const titleContentWrapper = xcss({
	flexGrow: 1,
	// https://css-tricks.com/flexbox-truncated-text/
	minWidth: '0',
});

const wrapText = xcss({
	whiteSpace: 'normal',
});

const headerStyles = css({
	padding: token('space.150', '12px'),
	overflowWrap: 'break-word',
	overflow: 'hidden',
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'div p': {
		lineHeight: '20px',
	},
});

// header has width/padding at the same time
// if there is very long prompt, header will be wider than parent
// so it should be border-box
const headerBoxSizingStyles = css({
	boxSizing: 'border-box',
});

type Props = {
	/**
	 * Label for the parent if the user selects a submenu via a parent (`Change tone` -> `Neutral`)
	 * If the user selects a submenu directly, this will be empty (search and select `Change tone to Neutral`)
	 */
	parentPresetPromptLabel?: string;
	/**
	 * Short title for the submenu if the user selects a submenu via a parent (`Change tone` -> `Neutral`)
	 * If the user selects a submenu directly, this will be empty (search and select `Change tone to Neutral`)
	 */
	childShortTitle?: string;
	prompt?: string;
	promptADF?: JSONDocNode;
	presetTitle?: string;
	onEditPromptClick?: () => void;
	providerFactory: ProviderFactory;
	schema: Schema;
	agent?: EditorAgent;
	showBack?: boolean;
	onBack?: () => unknown;
};

export const LatestPromptHeader = ({
	parentPresetPromptLabel,
	childShortTitle,
	prompt,
	promptADF,
	presetTitle,
	providerFactory,
	onEditPromptClick,
	agent,
	showBack,
	onBack,
}: Props) => {
	const { formatMessage } = useIntl();

	// when used in Loading screen, there is no onEditPromptClick
	let canEdit = !!onEditPromptClick;
	if (agent && !editorExperiment('platform_editor_ai_command_palate_improvement', 'test')) {
		canEdit = false;
	}

	const promptContent = React.useMemo(() => {
		if (editorExperiment('platform_editor_ai_command_palate_improvement', 'test')) {
			if (promptADF) {
				return (
					<Flex alignItems="center">
						<LatestPromptRenderer promptADF={promptADF} providerFactory={providerFactory} />
						{!presetTitle && (
							<EditPromptHoverTag isClearMode={!presetTitle && !prompt} isPlaceholder={!canEdit} />
						)}
					</Flex>
				);
			}

			if (prompt) {
				return <div css={contentContainerStyles}>{prompt}</div>;
			}

			return null;
		}

		if (!editorExperiment('platform_editor_ai_command_palate_improvement', 'test')) {
			if (promptADF) {
				return <LatestPromptRenderer promptADF={promptADF} providerFactory={providerFactory} />;
			}

			return <Box xcss={wrapText}>{prompt}</Box>;
		}
	}, [canEdit, prompt, promptADF, providerFactory, presetTitle]);

	const refinementTagProps = useAgentRefinementTagProps(agent);

	const header = React.useMemo(() => {
		if (editorExperiment('platform_editor_ai_command_palate_improvement', 'test')) {
			return (
				<Flex>
					{showBack && (
						<BackIconButton inPromptHeader isDisabled={!canEdit} stopPropagation onClick={onBack} />
					)}
					<Flex direction="column" gap="space.050" xcss={titleContentWrapper}>
						{presetTitle && (
							<Flex alignItems="center">
								{parentPresetPromptLabel &&
								childShortTitle &&
								!editorExperiment('platform_editor_ai_command_palette_post_ga', 'test') ? (
									<Flex alignItems="center" gap="space.050">
										<RefinementTag label={parentPresetPromptLabel} {...refinementTagProps} />
										<Text weight="bold" testId="child-short-title">
											{childShortTitle}
										</Text>
									</Flex>
								) : (
									<div
										onClick={(e) => {
											e.stopPropagation();
											e.preventDefault();
										}}
									>
										<RefinementTag
											label={presetTitle}
											{...refinementTagProps}
											onClick={
												editorExperiment('platform_editor_ai_command_palette_post_ga', 'test')
													? () => {
															if (onBack) {
																onBack();
															}
														}
													: undefined
											}
										/>
									</div>
								)}
								{presetTitle && (
									<EditPromptHoverTag
										isClearMode={!!presetTitle && !prompt}
										isPlaceholder={!canEdit}
									/>
								)}
							</Flex>
						)}
						{promptContent}
					</Flex>
				</Flex>
			);
		}

		return (
			<>
				{presetTitle && <RefinementTag label={presetTitle} {...refinementTagProps} />}
				{presetTitle && (prompt || promptADF) && <Box paddingBlockStart="space.050" />}
				{promptContent}
			</>
		);
	}, [
		presetTitle,
		refinementTagProps,
		prompt,
		promptADF,
		promptContent,
		showBack,
		canEdit,
		onBack,
		parentPresetPromptLabel,
		childShortTitle,
	]);

	if (!(prompt || presetTitle)) {
		return null;
	}

	return (
		<Box testId="prompt-header" xcss={fullWidth}>
			{!canEdit ? (
				<div
					css={[
						headerStyles,
						editorExperiment('platform_editor_ai_command_palate_improvement', 'test') &&
							headerBoxSizingStyles,
						contentContainerStyles,
					]}
					id={PROMPT_CONTENT_ID}
					data-testid={PROMPT_CONTENT_ID}
				>
					{header}
				</div>
			) : (
				// eslint-disable-next-line @atlaskit/design-system/no-html-button
				<button
					type="button"
					data-testid="edit-prompt-button"
					onClick={onEditPromptClick}
					css={[
						headerStyles,
						editorExperiment('platform_editor_ai_command_palate_improvement', 'test') &&
							headerBoxSizingStyles,
						contentContainerStyles,
						headerEditStyles,
					]}
					aria-label={formatMessage(messages.editPrompt)}
					aria-describedby={PROMPT_CONTENT_ID}
				>
					<div id={PROMPT_CONTENT_ID} data-testid={PROMPT_CONTENT_ID}>
						{header}
					</div>
				</button>
			)}
		</Box>
	);
};
