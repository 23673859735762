import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	ideasTitle: {
		id: 'issue-view-jpd-ideas.ideas-title',
		defaultMessage: 'Ideas',
		description: 'Title for ideas panel section in JSW issue view',
	},
	ideasPickerPlaceholder: {
		id: 'issue-view-jpd-ideas.ideas-picker-placeholder',
		defaultMessage: 'Search for ideas',
		description: 'Placeholder text for ideas in issue picker select component.',
	},
	linkButton: {
		id: 'issue-view-jpd-ideas.link-button',
		defaultMessage: 'Link',
		description: 'Label for link button.',
	},
	cancelButton: {
		id: 'issue-view-jpd-ideas.cancel-button',
		defaultMessage: 'Cancel',
		description: 'Label for cancel button.',
	},
});
