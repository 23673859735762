import { createSelector } from 'reselect';
import {
	SITE_ACCESS_LEVEL,
	type SharingSettings,
} from '@atlassian/jira-polaris-domain-view/src/sharing/settings/index.tsx';
import type { PublicSharingControls } from '@atlassian/jira-polaris-lib-control-sharing/src/controllers/types.tsx';
import type { State, Props } from '../types';

const getCurrentViewUUID = (state: State) => state.containerProps?.viewUUID;
const getViewsSharingSettings = (state: State) => state.sharingSettings;
const getViewsSharingStakeholders = (state: State) => state.sharingStakeholders;
const getPermissions = (state: State) => state.permissions;

const getCurrentViewSettings = createSelector(
	getCurrentViewUUID,
	getViewsSharingSettings,
	(viewUUID, sharingSettings) => (viewUUID ? sharingSettings[viewUUID] : undefined),
);

const getCurrentViewStakeholders = createSelector(
	getCurrentViewUUID,
	getViewsSharingStakeholders,
	(viewUUID, sharingStakeholders) => (viewUUID ? sharingStakeholders[viewUUID] : undefined),
);

const getSharingSiteConfig = (state: State) => state.siteConfig;

export const getIsSharingSiteConfigLoading = createSelector(
	getSharingSiteConfig,
	(config) => config.isLoading,
);

const getSitePublicSharingConfig = (state: State) => state.sitePublicSharingConfig;

const getIsSitePublicSharingLoading = createSelector(
	getSitePublicSharingConfig,
	({ isLoading }) => isLoading,
);

const getIsSitePublicSharingEnabled = createSelector(
	getSitePublicSharingConfig,
	({ isEnabled }) => isEnabled,
);

const getSitePublicSharingError = createSelector(getSitePublicSharingConfig, ({ error }) => error);

export const getIsCurrentViewOpenedAsPublic = createSelector(
	getIsSitePublicSharingEnabled,
	(state: State, props?: Props) => !!props?.isSharedView,
	(isEnabled, isSharedView) => isEnabled && isSharedView,
);

export const getProjectAccessLevel = (state: State) => state.projectAccessLevel;

const getSharingSiteConfigError = createSelector(getSharingSiteConfig, (config) => config.error);

export const getIsSharingOnSiteEnabled = createSelector(
	getSharingSiteConfig,
	(config) => config.isEnabled,
);

export const getCurrentViewSharingSettings = createSelector(
	getCurrentViewSettings,
	getProjectAccessLevel,
	(sharing, projectAccessLevel): SharingSettings | undefined => {
		if (!sharing?.settings) return;

		const { settings } = sharing;
		const hasSiteAccessLevel =
			projectAccessLevel && ['OPEN', 'LIMITED'].includes(projectAccessLevel);

		return {
			...settings,
			generalAccessLevel: hasSiteAccessLevel ? SITE_ACCESS_LEVEL : settings.generalAccessLevel,
		};
	},
);

export const getPublicSharingControls = createSelector(
	[getIsSitePublicSharingEnabled, getPermissions],
	(isSitePublicSharingEnabled, permissions): PublicSharingControls => {
		if (isSitePublicSharingEnabled === false) {
			return {
				status: 'disabled',
				reason: 'global-configuration',
			};
		}

		if (permissions?.canPublishPublicDiscoveryViews !== true) {
			return {
				status: 'disabled',
				reason: 'missing-permissions',
			};
		}

		return {
			status: 'enabled',
		};
	},
);

export const getCurrentViewSharingStakeholders = createSelector(
	getCurrentViewStakeholders,
	(sharing) => sharing?.stakeholders,
);

export const getIsCurrentViewSharingSettingLoading = createSelector(
	getCurrentViewSettings,
	(sharingSettings) => sharingSettings?.isLoading !== false,
);

export const getIsCurrentViewSharingStakeholdersLoading = createSelector(
	getCurrentViewStakeholders,
	(sharingStakeholders) => sharingStakeholders?.isLoading !== false,
);

export const getIsCurrentViewSharingSettingsInitialLoading = createSelector(
	getIsCurrentViewSharingSettingLoading,
	getCurrentViewSharingSettings,
	(isLoading, settings) => isLoading && settings === undefined,
);

export const getIsCurrentViewSharingStakeholdersInitialLoading = createSelector(
	getIsCurrentViewSharingStakeholdersLoading,
	getCurrentViewSharingStakeholders,
	(isLoading, stakeholders) => isLoading && stakeholders === undefined,
);

export const getIsCurrentViewSharingInitialLoading = createSelector(
	getIsCurrentViewSharingSettingsInitialLoading,
	getIsCurrentViewSharingStakeholdersInitialLoading,
	(isSettingsInitialLoading, isStakeholdersInitialLoading) =>
		isSettingsInitialLoading || isStakeholdersInitialLoading,
);

export const getIdeaDescriptionFieldKey = createSelector(
	getCurrentViewSharingSettings,
	(settings) => settings?.ideaDescription.fieldKey,
);

export const getCurrentViewSharingLinkEnabled = createSelector(
	getCurrentViewSharingSettings,
	(settings) => settings?.sharingLinkEnabled,
);

export const hasCurrentViewSharingSettingsError = createSelector(
	getCurrentViewSettings,
	getSharingSiteConfigError,
	getSitePublicSharingError,
	(sharingSettings, siteConfigError, sitePublicSharingError) =>
		(sharingSettings?.error && sharingSettings.error.statusCode !== 404) ||
		siteConfigError !== undefined ||
		sitePublicSharingError !== undefined,
);

export const hasCurrentViewSharingError = createSelector(
	hasCurrentViewSharingSettingsError,
	getCurrentViewStakeholders,
	(hasSettingsError, sharingStakeholders) =>
		(sharingStakeholders?.error && sharingStakeholders.error.statusCode !== 404) ||
		hasSettingsError,
);

export const getIsPublishDialogContentLoading = createSelector(
	getIsSharingOnSiteEnabled,
	getIsSitePublicSharingEnabled,
	getIsCurrentViewSharingInitialLoading,
	getIsSharingSiteConfigLoading,
	getIsSitePublicSharingLoading,
	(
		isSharingEnabledOnSite,
		isSitePublicSharingEnabled,
		isSharingInitialLoading,
		isSharingSiteConfigLoading,
		isSitePublicSharingLoading,
	) =>
		(isSharingEnabledOnSite && isSitePublicSharingEnabled && isSharingInitialLoading) ||
		isSharingSiteConfigLoading ||
		isSitePublicSharingLoading,
);
