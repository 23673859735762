import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type { ServerGiraUser, ServerRestUser } from './user-type';

export const JIRA_COMMENT_VISIBILITY_TYPE_GROUP = 'JiraGroupVisibility' as const;
export const JIRA_COMMENT_VISIBILITY_TYPE_ROLE = 'JiraRoleVisibility' as const;

/**
 * The group to which this comment is visible. Based on Gira's `JiraCommentVisibility` field.
 */
export type JiraGroupVisibilityNode = {
	__typename: typeof JIRA_COMMENT_VISIBILITY_TYPE_GROUP;
	group: {
		groupId: string;
		name: string;
	};
};

/**
 * The role to which this comment is visible. Based on Gira's `JiraCommentVisibility` field.
 */
export type JiraRoleVisibilityNode = {
	__typename: typeof JIRA_COMMENT_VISIBILITY_TYPE_ROLE;
	name: string;
};

/**
 * When an unknown typename has been entered, OTHER is an arbitrary string chosen
 */
export type JiraUnknownVisibilityNode = {
	__typename: 'OTHER';
	name: string;
};

export type ServerGiraCommentVisibilityNode =
	| JiraGroupVisibilityNode
	| JiraRoleVisibilityNode
	| JiraUnknownVisibilityNode;

export type ServerGiraCommentVisibilityEdge = { node: ServerGiraCommentVisibilityNode };

export type ServerGiraTransformedCommentVisibility = {
	type: string;
	value: string;
	identifier?: string;
};

export type ServerGiraCommentVisibility =
	| ServerGiraCommentVisibilityEdge
	| ServerGiraTransformedCommentVisibility;

/**
 * Common comment details between REST and Gira endpoints.
 */
export interface ServerBaseComment {
	id: string;
	created: number;
	updated?: number;
	jsdPublic?: boolean;
}

/**
 * A comment associated with an issue. Based on Gira's `Comment` field.
 */
export interface ServerGiraComment extends ServerBaseComment {
	author: ServerGiraUser;
	updateAuthor?: ServerGiraUser;
	jiraCommentVisibility?: ServerGiraCommentVisibilityNode | ServerGiraCommentVisibility;
	jsdAuthorCanSeeRequest?: boolean;
	bodyAdf: ADF;
}

/**
 * A comment associated with an issue. Based on REST's `Comment` value.
 *
 * See: https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-issue-comments
 */
export interface ServerRestComment extends ServerBaseComment {
	author: ServerRestUser;
	updateAuthor?: ServerRestUser;
	body: ADF;
}

export type ServerComment = ServerGiraComment | ServerRestComment;
