import uniqBy from 'lodash/uniqBy';
import { MOBILE_ISSUE } from '@atlassian/jira-common-constants/src/analytics-sources';
import {
	useAnalyticsSource,
	useEstimationStatistic,
	useIssueKey,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIssueFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { useFieldsValues } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { usePinnedFields } from '@atlassian/jira-issue-field-pin/src/utils';
import {
	type GlanceItem,
	type ContextPanelItem,
	type LayoutContainerNode,
	layoutContainerItemTypes,
	type LayoutContainerTemplateItem,
} from '@atlassian/jira-issue-layout-common-constants';
import {
	useEcosystemGlances,
	useEcosystemContextPanels,
} from '@atlassian/jira-issue-view-ecosystem-service/src/services/main.tsx';
import { useLayoutContainerByType } from '@atlassian/jira-issue-view-layout/src/services/main.tsx';
import { getLayoutItemId } from '@atlassian/jira-issue-view-layout/src/services/utils.tsx';
import { DEV_SUMMARY_TYPE, TIME_TRACKING_TYPE } from '@atlassian/jira-platform-field-config';
import {
	useProjectConfiguration,
	useProjectContext,
	useProjectKey,
} from '@atlassian/jira-project-context-service/src/main';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { useTimeTrackingConfiguration } from '@atlassian/jira-settings-service/src/main.tsx';
import {
	addChangeboardingRefToFirstItem,
	ecosystemGlancesToLayoutItem,
	ecosystemContextsToLayoutItem,
	useForgeGlancesAsLayoutItems,
	shouldHideNullUneditableField,
	getPinnedFieldItems,
	useForgeContextPanelsAsLayoutItems,
} from './utils';

export type Layout = {
	primaryItems: LayoutContainerTemplateItem[];
	secondaryItems: LayoutContainerTemplateItem[];
	// @deprecated Ecosystem glance panels - will be replaced with contextPanels
	ecosystemItems: GlanceItem[];
	// @deprecated Forge glance panels - will be replaced with contextPanels
	forgeItems: GlanceItem[];
	pinnedFields: LayoutContainerNode[];
	apps: {
		// Forge & Connect issue-context panels
		contextPanels: ContextPanelItem[];
	};
};

const emptyLayout = (): Layout => ({
	primaryItems: [],
	secondaryItems: [],
	ecosystemItems: [],
	forgeItems: [],
	pinnedFields: [],
	apps: { contextPanels: [] },
});

// devSummary is a mandatory field, however we only add it to nextGen here cause nextGen and classic treat it differently.
// in a classic project devSummary field is always added to layout config and cannot be removed from there (although can be hidden)
// and therefore is always part of API response.
// We don't have this for next gen and hence need to add it on frontend.
const nextGenMandatoryFields = [
	{ type: layoutContainerItemTypes.panel, panelItemId: DEV_SUMMARY_TYPE },
];

export const usePrimarySecondaryItems = (alwaysPrimaryFieldIds: string[] = []): Layout => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const { ecosystemEnabled } = useProjectConfiguration(projectKey);
	const projectContext = useProjectContext(projectKey);
	const [pinnedFields] = usePinnedFields(issueKey);
	const [ecosystemGlances] = useEcosystemGlances();
	const [ecosystemContextPanelsItems] = useEcosystemContextPanels();
	const [primaryItems] = useLayoutContainerByType(issueKey, 'PRIMARY');
	const [secondaryItems] = useLayoutContainerByType(issueKey, 'SECONDARY');
	const [issueFieldsValues] = useFieldsValues(issueKey);
	const [{ value: issueFieldsConfig }] = useIssueFieldConfig(issueKey);
	const [timeTrackingConfiguration] = useTimeTrackingConfiguration();
	const [permissions] = useProjectPermissions(projectKey);
	const analyticsSource = useAnalyticsSource();
	const forgeGlanceItems = useForgeGlancesAsLayoutItems();
	const forgeContextPanelItems = useForgeContextPanelsAsLayoutItems();

	const isNextGen = projectContext === undefined ? false : projectContext.isSimplified;
	const estimationStatisticFieldId: string | undefined = useEstimationStatistic();

	if (!issueFieldsConfig) return emptyLayout();

	const ecosystemGlanceLayoutItems = ecosystemGlancesToLayoutItem(ecosystemGlances);
	const forgeGlanceLayoutItems = ecosystemEnabled === true ? forgeGlanceItems : [];

	const ecosystemContextPanelItems = ecosystemContextsToLayoutItem(ecosystemContextPanelsItems);

	const isNativeJiraTimeTrackingEnabled =
		timeTrackingConfiguration.isTimeTrackingEnabled &&
		issueFieldsConfig[TIME_TRACKING_TYPE] !== undefined;
	const isMobile = analyticsSource === MOBILE_ISSUE;
	const canLogWork = isNativeJiraTimeTrackingEnabled && permissions.canLogWork && !isMobile;

	const items: LayoutContainerNode[] = [
		...primaryItems,
		...secondaryItems,
		...(isNextGen ? nextGenMandatoryFields : []),
	];

	const dedupedItems = uniqBy(items, getLayoutItemId);

	const layout = emptyLayout();
	const pinnedFieldsSet: Set<string> = new Set(pinnedFields);
	const alwaysPrimaryFieldsSet: Set<string> = new Set(alwaysPrimaryFieldIds);
	const secondaryItemFieldsSet: Set<string> = new Set(
		secondaryItems.map((item) => getLayoutItemId(item)) || [],
	);
	dedupedItems.forEach((contextItem: LayoutContainerNode) => {
		const id = getLayoutItemId(contextItem);
		if (pinnedFieldsSet.has(id)) {
			return;
		}

		// 1. The estimation statistic field ID state only exists on pages that need it. On those
		// pages, the field must be a primary item, regardless of the issue layout configuration.
		// 2. Products can specify field IDs they want to hoist into the primary items at all times.
		if (id === estimationStatisticFieldId || alwaysPrimaryFieldsSet.has(id)) {
			layout.primaryItems.push(contextItem);
			return;
		}

		// Empty read-only fields should be skipped
		if (
			shouldHideNullUneditableField(
				id,
				issueFieldsConfig,
				issueFieldsValues?.[id],
				canLogWork,
				isNativeJiraTimeTrackingEnabled,
			)
		) {
			return;
		}

		if (secondaryItemFieldsSet.has(id)) {
			layout.secondaryItems.push(contextItem);
		} else {
			layout.primaryItems.push(contextItem);
		}
	});

	layout.primaryItems = addChangeboardingRefToFirstItem([...layout.primaryItems]);
	layout.ecosystemItems = ecosystemGlanceLayoutItems;
	layout.forgeItems = forgeGlanceLayoutItems;
	layout.pinnedFields = getPinnedFieldItems(dedupedItems, pinnedFields);
	layout.apps.contextPanels = [...ecosystemContextPanelItems, ...forgeContextPanelItems];

	return layout;
};
