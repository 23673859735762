import React, { useCallback } from 'react';

import { useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import ChevronLeftIcon from '@atlaskit/icon/utility/chevron-left';
import { Flex, xcss } from '@atlaskit/primitives';

import messages from './messages';

const inPromptHeaderStyles = xcss({
	display: 'inline-flex',
	marginTop: 'space.negative.025',
	marginBottom: 'space.negative.025',
	// make room for the focus ring
	marginRight: 'space.050',
});

type Props = {
	// if true, the button will be styled (move up a bit) to match the prompt header
	inPromptHeader?: boolean;
	isDisabled?: boolean;
	stopPropagation?: boolean;
	onClick?: () => void;
};

const toolTipProps: React.ComponentProps<typeof IconButton>['tooltip'] = { position: 'top' };

export const BackIconButton = ({
	inPromptHeader,
	isDisabled = false,
	stopPropagation,
	onClick,
}: Props) => {
	const { formatMessage } = useIntl();

	const onClickIconButton = useCallback(
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			if (stopPropagation) {
				event.stopPropagation();
			}
			onClick?.();
		},
		[stopPropagation, onClick],
	);

	const btn = (
		<IconButton
			isDisabled={isDisabled}
			appearance="subtle"
			icon={ChevronLeftIcon}
			label={formatMessage(messages.backLabel)}
			spacing="compact"
			isTooltipDisabled={isDisabled}
			onClick={onClickIconButton}
			testId="back-icon-button"
			tooltip={toolTipProps}
		/>
	);

	if (inPromptHeader) {
		return <Flex xcss={[inPromptHeaderStyles]}>{btn}</Flex>;
	}

	return btn;
};
