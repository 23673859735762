import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { JiraProjectGetResponse } from './types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { JiraProjectGetResponse };

export const getProject = (id: string): Promise<JiraProjectGetResponse> =>
	fetchJson(`/rest/api/3/project/${id}`, {
		method: 'GET',
	});
