/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	editButtonLabel: {
		id: 'inline-edit-lite.read-view.edit-button-label',
		defaultMessage: 'Edit',
		description:
			'Aria label to be used by assistive technologies when interacting with the edit button.',
	},
	confirmButtonLabel: {
		id: 'inline-edit-lite.edit-view.confirm-button-label',
		defaultMessage: 'Confirm',
		description:
			'Aria label to be used by assistive technologies when interacting with the confirm button.',
	},
	cancelButtonLabel: {
		id: 'inline-edit-lite.edit-view.cancel-button-label',
		defaultMessage: 'Cancel',
		description:
			'Aria label to be used by assistive technologies when interacting with the cancel button.',
	},
	submitButtonLabel: {
		id: 'inline-edit-lite.edit-view.submit-button-label',
		defaultMessage: 'Submit',
		description:
			'Aria label to be used by assistive technologies when interacting with the submit button.',
	},
});
