import { associateGlobalFields } from './associate-global-fields';
import {
	createField,
	createCalculatedField,
	createPlayField,
	updatePlayFieldParameters,
	createConnectionField,
} from './create-field';
import { createValueDecoration, createValueDecorationBulk } from './decorations/create.tsx';
import { deleteValueDecoration, deleteAllValueDecorations } from './decorations/delete.tsx';
import { updateValueDecoration, updateRules } from './decorations/update/index.tsx';
import { deleteField, deleteFieldLocally } from './delete-field';
import {
	throttledFetchSnippetProviders,
	fetchArchivedSnippetProviders,
} from './fetch-snippet-providers';
import { resetHighlightedFields, setHighlightedFields } from './highlight-fields';
import { updateDeliveryDateAggregationType } from './interval-field-configuration/update-delivery-date-aggregation-type.tsx';
import { updateDeliveryDateSelectedField } from './interval-field-configuration/update-delivery-date-selected-field.tsx';
import { updateIntervalFieldSource } from './interval-field-configuration/update-interval-source.tsx';
import { loadFields } from './load-fields';
import { loadGlobalLabels } from './load-global-labels';
import { loadSelectedFields } from './load-selected-fields';
import {
	resetNewlyAddedGlobalFields,
	setNewlyAddedGlobalFields,
} from './newly-added-global-fields';
import { addOption } from './options/add-option.tsx';
import { deleteOption } from './options/delete-option.tsx';
import { rankOptions } from './options/rank-options.tsx';
import { renameOption } from './options/rename-option.tsx';
import { updateFieldOptionAutoFormattingDisabled } from './options/update-field-option-auto-formatting-disabled.tsx';
import { updateFieldOptionWeightType } from './options/update-field-option-weight-type';
import { updateFieldOptionWeight } from './options/update-field-option-weight.tsx';
import { updateDeliveryFieldPresentation } from './update-delivery-field-presentation';
import { updateDescription } from './update-description';
import { updateEmoji } from './update-emoji';
import { updateFieldConfiguration } from './update-field-configuration';
import { updateFieldFormula } from './update-field-formula';
import { updateLabel } from './update-label';
import { updatePlayField } from './update-play-max-spend';
import { updateSystemFieldHidden } from './update-system-field-hidden';
import { updateUserFieldDisplayMode } from './update-user-field-display-mode';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	addOption,
	createCalculatedField,
	createField,
	createPlayField,
	createConnectionField,
	createValueDecoration,
	createValueDecorationBulk,
	deleteField,
	deleteFieldLocally,
	deleteOption,
	deleteValueDecoration,
	deleteAllValueDecorations,
	throttledFetchSnippetProviders,
	fetchArchivedSnippetProviders,
	loadFields,
	loadSelectedFields,
	rankOptions,
	renameOption,
	updateFieldFormula,
	updateDeliveryFieldPresentation,
	updateDescription,
	updateEmoji,
	updateFieldOptionWeight,
	updateFieldOptionWeightType,
	updateFieldOptionAutoFormattingDisabled,
	updateLabel,
	updateRules,
	updateValueDecoration,
	updatePlayFieldParameters,
	updatePlayField,
	updateFieldConfiguration,
	updateUserFieldDisplayMode,
	updateIntervalFieldSource,
	updateDeliveryDateSelectedField,
	updateDeliveryDateAggregationType,
	updateSystemFieldHidden,
	associateGlobalFields,
	setNewlyAddedGlobalFields,
	resetNewlyAddedGlobalFields,
	setHighlightedFields,
	resetHighlightedFields,
	loadGlobalLabels,
};
