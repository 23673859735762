import { useEffect, useMemo, useState } from 'react';
import { createSessionStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/session-storage/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import {
	CONFLUENCE_CONTROLS_STORAGE_PROVIDER_KEY,
	CONFLUENCE_CONTROLS_STORAGE_KEY,
} from '../../constants';
import { getConfluenceApplicationProperties } from '../../services/application-properties';
import {
	getSessionStorageData,
	isStorageKeyExpired,
	setSessionStorageData,
} from '../../services/session-storage';

const storage = createSessionStorageProvider(CONFLUENCE_CONTROLS_STORAGE_PROVIDER_KEY);
const TIME_TO_LIVE = 18000000; // 30 minutes

export const useIsCrossSellEnabled = (controlKey: string) => {
	const [isCrossSellEnabled, setIsCrossSellEnabled] = useState<boolean>(false);
	const { cloudId } = useTenantContext();

	const applicationPropertiesCache = useMemo(
		() => getSessionStorageData(CONFLUENCE_CONTROLS_STORAGE_KEY, storage) ?? null,
		[],
	);

	const shouldFetchApplicationProperty = useMemo(
		() =>
			!isCrossSellEnabled &&
			(isStorageKeyExpired(CONFLUENCE_CONTROLS_STORAGE_KEY, storage) ||
				!applicationPropertiesCache),
		[applicationPropertiesCache, isCrossSellEnabled],
	);

	useEffect(() => {
		const getConfluenceApplicationPropertyData = async () => {
			try {
				const confluenceApplicationPropertiesMap =
					await getConfluenceApplicationProperties(cloudId);

				setSessionStorageData({
					key: CONFLUENCE_CONTROLS_STORAGE_KEY,
					value: confluenceApplicationPropertiesMap,
					ttl: TIME_TO_LIVE,
					storage,
				});

				setIsCrossSellEnabled(confluenceApplicationPropertiesMap[controlKey].value);
			} catch (e) {
				const error = e instanceof Error ? e : new Error('Failed to get application properties');
				fireErrorAnalytics({
					meta: {
						id: 'useIsCrossSellEnabled',
						packageName: 'jiraConfluenceIntegrationControls',
					},
					error,
				});
			}
		};

		if (shouldFetchApplicationProperty) {
			getConfluenceApplicationPropertyData();
		} else {
			setIsCrossSellEnabled(applicationPropertiesCache[controlKey].value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return isCrossSellEnabled;
};
