import { useState, useMemo, useEffect, useCallback } from 'react';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldsValues } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { LayoutItems } from '@atlassian/jira-issue-view-layout-templates-services/src/services/context/visible-hidden/visible-hidden-items/index.tsx';
import { isEmpty } from '@atlassian/jira-issue-view-layout-templates-utils';
import { getLayoutItemId } from '@atlassian/jira-issue-view-layout/src/services/utils.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';

const localStorage = createLocalStorageProvider('show-empty-fields');

const SHOW_EMPTY_FIELDS = 'show-empty-fields';

export const useHideWhenEmptyExperimentEnabled = (items: LayoutItems) => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey) || null;
	const isJSMProject = projectType === 'service_desk';

	if (isJSMProject || items?.hasSecondaryItems) {
		return false;
	}
	return expVal('issue_view_hide_when_empty_fields_experiment', 'isHideWhenEmptyEnabled', false);
};

export const useHideWhenEmpty = (items: LayoutItems) => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey) || null;
	const isJWMProject = projectType === 'business';
	const initialHideWhenEmptyState =
		localStorage?.get(SHOW_EMPTY_FIELDS) === undefined
			? true
			: localStorage?.get(SHOW_EMPTY_FIELDS);
	const [showHiddenWhenEmptyFields, toggleHiddenWhenEmpty] = useState(initialHideWhenEmptyState);

	const [issueFieldsValues] = useFieldsValues(issueKey);
	const [currentIssueFieldsValues, setCurrentIssueFieldsValues] =
		useState<typeof issueFieldsValues>(undefined);
	const [initialIssueFieldsCalculated, setInitialIssueFieldsCalculated] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		if (issueFieldsValues && !initialIssueFieldsCalculated) {
			setCurrentIssueFieldsValues(issueFieldsValues);
			setInitialIssueFieldsCalculated(true);
		}
	}, [issueFieldsValues, initialIssueFieldsCalculated, items.visibleItems]);

	const visibleItems = useMemo(
		() =>
			items.visibleItems.filter(
				(item) => !isEmpty(currentIssueFieldsValues?.[getLayoutItemId(item)]),
			),
		[currentIssueFieldsValues, items.visibleItems],
	);

	const noOfEmptyFields = useMemo(() => {
		if (!showHiddenWhenEmptyFields) {
			const emptyFields = items.visibleItems.filter((item) =>
				isEmpty(currentIssueFieldsValues?.[getLayoutItemId(item)]),
			);

			// @ts-expect-error - TS2339 - Property 'panelItemId' does not exist on type 'LayoutContainerTemplateItem'.
			if (isJWMProject && emptyFields.some((item) => item.panelItemId === 'devSummary')) {
				return emptyFields.length - 1;
			}
			return emptyFields.length;
		}
		const emptyFields = items.visibleItems.filter((item) =>
			isEmpty(issueFieldsValues?.[getLayoutItemId(item)]),
		);

		// @ts-expect-error - TS2339 - Property 'panelItemId' does not exist on type 'LayoutContainerTemplateItem'.
		if (isJWMProject && emptyFields.some((item) => item.panelItemId === 'devSummary')) {
			return emptyFields.length - 1;
		}

		return emptyFields.length;
	}, [
		issueFieldsValues,
		items.visibleItems,
		currentIssueFieldsValues,
		showHiddenWhenEmptyFields,
		isJWMProject,
	]);

	const onButtonToggled = useCallback(() => {
		setCurrentIssueFieldsValues(issueFieldsValues);
		toggleHiddenWhenEmpty(!showHiddenWhenEmptyFields);
		localStorage.set(SHOW_EMPTY_FIELDS, !showHiddenWhenEmptyFields);
		showHiddenWhenEmptyFields
			? fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'hideEmptyFields')
			: fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'showEmptyFields');
	}, [issueFieldsValues, showHiddenWhenEmptyFields, createAnalyticsEvent]);

	const onVisibleItemsToggleExpanded = useCallback(() => {
		setCurrentIssueFieldsValues(issueFieldsValues);
	}, [issueFieldsValues]);

	return {
		visibleItems: showHiddenWhenEmptyFields ? items.visibleItems : visibleItems,
		showToggleButton: noOfEmptyFields > 0,
		showHiddenWhenEmptyFields,
		noOfEmptyFields,
		onButtonToggled,
		onVisibleItemsToggleExpanded,
	};
};
