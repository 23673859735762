import {
	createContainer,
	createHook,
	createStore,
	createSubscriber,
} from '@atlassian/react-sweet-state';
import { type Actions, actions as subscriberActions } from './actions';
import type { IncidentConfigurationContainerProps, State } from './types';

export const initialState: State = {
	configuration: undefined,
	fetchStatus: undefined,
};

export const incidentConfigurationStore = createStore<State, Actions>({
	name: 'JSDIncidentConfiguration',
	initialState,
	actions: subscriberActions,
});

export const IncidentConfigurationSubscriber = createSubscriber<State, Actions>(
	incidentConfigurationStore,
);

export const useIncidentConfiguration = createHook(incidentConfigurationStore);

export const IncidentConfigurationProvider = createContainer<
	State,
	Actions,
	IncidentConfigurationContainerProps
>(incidentConfigurationStore);
