import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { NEW_COMMENT_ID } from '../../../selectors/comment-constants';
import { issueIdSelector } from './context-selector';
import { projectIdSelector } from './issue-selector';

const commentIdSelector = (state: State, { commentId }: { commentId?: string }) =>
	commentId || NEW_COMMENT_ID;

const contextIdentifierSelectorResultFn = memoize(
	(issueId, containerId, childObjectId) => ({
		objectId: issueId,
		containerId,
		childObjectId,
	}),
	(...args) => args.join('_'),
);

export const contextIdentifierSelector = createSelector(
	issueIdSelector,
	projectIdSelector,
	commentIdSelector,
	(issueId, containerId, childObjectId) =>
		contextIdentifierSelectorResultFn(issueId, containerId, childObjectId),
);

// sadly there is no easy way to reuse the list of arguments:
// https://stackoverflow.com/questions/46596235/flow-generic-type-for-function-expression-arrow-functions
const memoizeContextIdentifierObject = memoize(
	(issueId?: IssueId | null, projectId?: number | null, commentId?: string) => ({
		objectId: String(issueId),
		containerId: String(projectId),
		...(typeof commentId === 'string' && { childObjectId: commentId }),
	}),
	(issueId?: IssueId | null, projectId?: number | null, commentId?: string) =>
		`${String(issueId)}-${String(projectId)}-${String(commentId)}`,
);

// selectors created by `createSelector` always cache a single result, which is acceptable for identical usages
// but for parameterised usages (like here, with `commentId`), we need a different strategy to produce stable results
// https://github.com/reduxjs/reselect#q-can-i-share-a-selector-across-multiple-component-instances
export const memoizedContextIdentifierSelector = (state: State, commentId: string) => {
	const { objectId, containerId, childObjectId } = contextIdentifierSelector(state, {
		commentId,
	});
	return memoizeContextIdentifierObject(objectId, containerId, childObjectId);
};
