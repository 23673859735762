import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import createEntry from '../../common/utils/api';
import { props } from './constants';

export type CachedVersion = {
	name: string;
	id: string;
	ari?: string;
	archived?: boolean;
	released?: boolean;
	projectId: ProjectId;
};

export default createEntry<CachedVersion>(props);
