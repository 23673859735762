/**
 * @generated SignedSource<<dbde8dfe85c538d487fce8e929f05bb6>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutMultiUser_IssueViewMultiUser$data = {
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutMultiUserField_IssueViewMultiUserField">;
  readonly " $fragmentType": "ui_issueViewLayoutMultiUser_IssueViewMultiUser";
};
export type ui_issueViewLayoutMultiUser_IssueViewMultiUser$key = {
  readonly " $data"?: ui_issueViewLayoutMultiUser_IssueViewMultiUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutMultiUser_IssueViewMultiUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserrelayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutMultiUser_IssueViewMultiUser",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutMultiUserField_IssueViewMultiUserField"
        }
      ]
    }
  ],
  "type": "JiraMultipleSelectUserPickerField"
};

(node as any).hash = "87b86ae570f2263e86a6d46b35a33f45";

export default node;
