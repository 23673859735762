import { createSelector } from 'reselect';
import { isMobileSelector, isBitbucketSelector } from '../common/state/selectors/context-selector';
import { permissionsSelector } from '../common/state/selectors/permissions-selector';
import {
	issueTypesForClassicProjectEpicSelector,
	hasUnsupportedType,
} from './issue-types-selector';

const hasUnsupportedTypeSelector = createSelector(
	issueTypesForClassicProjectEpicSelector,
	(issueTypes) => !!issueTypes.find(hasUnsupportedType),
);

export const canCreateIssuesInEpicSelector = createSelector(
	permissionsSelector,
	hasUnsupportedTypeSelector,
	isMobileSelector,
	isBitbucketSelector,
	// @ts-expect-error - TS2339 - Property 'canCreateIssuesInClassicProjectEpic' does not exist on type 'Permissions'.
	({ canCreateIssuesInClassicProjectEpic }, hasUnsupportedTypes, isMobile, isBitbucket) => {
		const shouldHideForMobileOrBitbucket = hasUnsupportedTypes ? isMobile || isBitbucket : false;
		return canCreateIssuesInClassicProjectEpic && !shouldHideForMobileOrBitbucket;
	},
);
