import get from 'lodash/get';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fg } from '@atlassian/jira-feature-gating';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { defaultSaveField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/utils.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import type { FieldConfiguration } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { TeamValue } from '../common/types';

export type UseTeamField = (arg1: {
	issueKey: IssueKey;
	fieldKey: string;
	initialValue?: TeamValue | null;
	onSuccess?: (newValue: TeamValue) => void;
	onFailure?: (error: Error, newValue: TeamValue) => void;
}) => [
	{
		value: TeamValue;
		error: Error | null;
		fieldConfig: FieldConfiguration<TeamValue> | null;
	},
	{
		saveValue: (value: TeamValue, meta: null, analyticsEvent: UIAnalyticsEvent) => Promise<void>;
		resetError: () => void;
	},
];

const saveField = async (
	issueKey: IssueKey,
	fieldKey: string,
	newValue: TeamValue,
	baseUrl: string,
) => {
	const newId = newValue?.id ?? null;

	return defaultSaveField(issueKey, fieldKey, newId, baseUrl);
};

export const useTeamField: UseTeamField = ({
	issueKey,
	fieldKey,
	onSuccess,
	onFailure,
	initialValue,
}) => {
	// useFieldConfig now returns an object { value, loading } instead of just the fieldConfig value
	// if possible when touching this, try and refactor to pass the new object instead of just the value
	const [{ value: fieldConfig }] = useFieldConfig(issueKey, fieldKey);
	const fieldType = get(fieldConfig, 'schema.type', 'array');

	const [{ value, error }, { saveValue, resetError }] = useEditField<TeamValue, null>({
		fieldKey,
		issueKey,
		fieldType,
		initialValue: fg('jwm_list_new_copy_paste_behaviour') ? initialValue || null : null,
		onSuccess,
		saveField,
		onFailure,
	});

	return [
		{ value, error, fieldConfig },
		{ saveValue, resetError },
	];
};
