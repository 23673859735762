import uuid from 'uuid';
import type { Action } from '@atlassian/react-sweet-state';
import type { AIResponse } from '../../common/types';
import type { Actions, State, Props, PromptId } from './types';

function onHandlePrompt<Payload>(
	nextPrompt: string,
	promptId: PromptId,
): Action<State<Payload>, Props<Payload>, Promise<AIResponse<Payload> | undefined>> {
	return async (_, { onPrompt }) => {
		try {
			return await onPrompt(nextPrompt, promptId);
		} catch (e) {
			// Ignore errors as this is expected to be handled in the consumer
			return undefined;
		}
	};
}

export const actions: Actions = {
	onUpdate(prompt: string): Action<State> {
		return ({ setState }) => {
			// Update input prompt
			setState({ prompt });
		};
	},
	onSubmit<Payload>(nextPrompt: string): Action<State<Payload>, Props<Payload>> {
		const nextPromptId = uuid();

		return async ({ getState, setState, dispatch }, { onPromptSuccess }) => {
			// Set loading state
			setState({
				isLoading: true,
				isSubmitted: true,
				submittedPrompt: nextPrompt,
				promptId: nextPromptId,
			});

			const { cancellable } = getState();
			// Cancel any in-flight requests
			if (cancellable) {
				cancellable();
			}

			const cancellablePromise = new Promise<undefined>((resolve, reject) => {
				// Allow this request to be cancelled
				setState({
					cancellable: reject,
				});
			});

			try {
				const nextResponse = await Promise.race<AIResponse<Payload> | undefined>([
					dispatch(onHandlePrompt<Payload>(nextPrompt, nextPromptId)),
					cancellablePromise,
				]);

				nextResponse?.type === 'success' &&
					onPromptSuccess &&
					onPromptSuccess(nextResponse.payload);

				// Set successful response into state
				setState({
					isLoading: false,
					response: nextResponse,
				});
			} catch (e) {
				// Pending promise was cancelled
			}
		};
	},
};
