import { messages } from './messages';

export const genericUimErrorFlagProperties = {
	message: messages.genericUimErrorFlagDescription,
	title: messages.applyingUimErrorFlagTitle,
};

export const applyingUimErrorFlagProperties = {
	message: messages.applyingUimErrorFlagDescription,
	title: messages.applyingUimErrorFlagTitle,
};

export const loadingUimErrorFlagProperties = {
	message: messages.genericUimErrorFlagDescription,
	title: messages.loadingUimErrorFlagTitle,
};

export const missingScopesUimErrorFlagProperties = {
	message: messages.missingScopesUimErrorFlagDescription,
	title: messages.missingScopesUimErrorFlagTitle,
};

export const perFieldHookErrorFlagProperties = {
	message: messages.perFieldHookErrorFlagDescription,
	title: messages.perFieldHookErrorFlagTitle,
};
