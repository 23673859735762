import {
	BAD_REQUEST,
	NOT_FOUND,
	FORBIDDEN,
	UNAUTHORIZED,
	CONFLICT,
	PRECONDITION_FAILED,
} from '@atlassian/jira-common-constants/src/http-status-codes';
import FetchError, {
	ABORT_ERROR_NAME,
	TYPE_ERROR_NAME,
	ValidationError,
} from '@atlassian/jira-fetch/src/utils/errors';

const errorCases4xx = [
	BAD_REQUEST,
	FORBIDDEN,
	UNAUTHORIZED,
	NOT_FOUND,
	CONFLICT,
	PRECONDITION_FAILED,
];

const statusCodeIs4xx = (statusCode: number): boolean =>
	errorCases4xx.some((status) => status === statusCode);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (error: Error | ValidationError | FetchError): boolean => {
	const isValidationError = error instanceof ValidationError;

	if (isValidationError) {
		return false;
	}

	const isFetchError = error instanceof FetchError;
	if (isFetchError && !!error.statusCode && statusCodeIs4xx(error.statusCode)) {
		return false;
	}

	if (error.name === TYPE_ERROR_NAME || error.name === ABORT_ERROR_NAME) {
		return false;
	}

	return true;
};
