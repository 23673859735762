import React, { type ComponentType, useEffect, useCallback, useState } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { useProjectKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useChildPanelRenderTrigger } from '@atlassian/jira-issue-view-common-views/src/child-issues-panel/hooks/child-panel-render-trigger';
import { useShouldTriggerAiSuggestedIssues } from '@atlassian/jira-issue-view-common-views/src/child-issues-panel/hooks/main.tsx';
import {
	CLASSIC_PORTFOLIO_CHILDREN,
	CLASSIC_SUBTASKS,
	CHILDREN_ISSUES_PANEL,
} from '@atlassian/jira-issue-view-common-views/src/child-issues-panel/model/types.tsx';
import { useTriggerIssueCreateModal } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/main.tsx';
import { useIsSimplifiedProject } from '@atlassian/jira-project-context-service/src/main.tsx';
import type { Props } from '../types';
import type { RenderChildComponentType } from './types';

/**
 * Add GIC properties into content-items-view in order to manage rendered components
 */
export const withGICHook =
	(ContentItemsComponent: ComponentType<Props> | React.FC<Props>) => (props: Props) => {
		const [{ isModalOpen, callbackPayload }] = useTriggerIssueCreateModal();

		const { childIssuesPanelType } = callbackPayload?.data || {};

		const getRenderComponentValue = useCallback(() => {
			if (!isModalOpen) return false;
			switch (childIssuesPanelType) {
				case CHILDREN_ISSUES_PANEL:
				case CLASSIC_SUBTASKS:
				case CLASSIC_PORTFOLIO_CHILDREN:
					return childIssuesPanelType;
				default:
					return false;
			}
		}, [childIssuesPanelType, isModalOpen]);

		const [renderChildComponent, setRenderChildComponent] =
			useState<RenderChildComponentType>(getRenderComponentValue());

		useEffect(() => {
			const newRenderChildValue = getRenderComponentValue();
			if (newRenderChildValue !== false) {
				setRenderChildComponent(newRenderChildValue);
			}
		}, [getRenderComponentValue]);

		const projectKey = useProjectKey();
		const isSimplifiedProject = useIsSimplifiedProject(projectKey);
		const shouldSuggestAiIssues = !props.isEpic ? useShouldTriggerAiSuggestedIssues() : false;

		let whichChildPanelRenderTriggered;
		if (fg('jira-ai-issue-view-improve-issues-button')) {
			[{ whichChildPanelRenderTriggered }] = useChildPanelRenderTrigger();
		}

		return (
			<ContentItemsComponent
				{...props}
				shouldRenderSubtasks={
					props.shouldRenderSubtasks ||
					renderChildComponent === CLASSIC_SUBTASKS ||
					(fg('jira-ai-issue-view-improve-issues-button')
						? whichChildPanelRenderTriggered === CLASSIC_SUBTASKS
						: !isSimplifiedProject && shouldSuggestAiIssues)
				}
				shouldRenderChildrenIssues={
					props.shouldRenderChildrenIssues ||
					renderChildComponent === CHILDREN_ISSUES_PANEL ||
					(fg('jira-ai-issue-view-improve-issues-button')
						? whichChildPanelRenderTriggered === CHILDREN_ISSUES_PANEL
						: isSimplifiedProject && shouldSuggestAiIssues)
				}
				shouldRenderPortfolioChildrenIssues={
					props.shouldRenderPortfolioChildrenIssues ||
					renderChildComponent === CLASSIC_PORTFOLIO_CHILDREN
				}
			/>
		);
	};
