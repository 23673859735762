import React, { useCallback } from 'react';
import get from 'lodash/get';
import isNaN from 'lodash/isNaN';
import { DateTimePicker } from '@atlaskit/datetime-picker';
import availableTimes from '@atlassian/jira-common-constants/src/datetimepicker-available-times';
import { convertISODateFormat } from '@atlassian/jira-compatibility';
import { ff } from '@atlassian/jira-feature-flagging';
import { standardizeLocale } from '@atlassian/jira-issue-format-date/src/common/utils.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import { isDataExtra } from '@atlassian/jira-platform-services-user-current/src/types.tsx';
import { getLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import type { TimeZone } from '@atlassian/jira-shared-types/src/general.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import type { Props, ParseValueFn } from './types';
import { parseDateTimeValue as onParseValue } from './utils';

export const DEFAULT_WEEK_START_DAY = 0; // Sunday

/**
 * DateTimeEditView lets you edit the datetime of the field
 *
 *
 * @param props {@link DateTimeEditView}
 * */
export const DateTimeEditView = ({
	autoFocus,
	isDisabled,
	isInvalid,
	timeZone,
	value,
	onChange,
	datePlaceholder,
	timePlaceholder,
	onDatePickerKeyDown,
}: Props) => {
	const locale = useLocale();
	const {
		data: { user },
	} = useCurrentUser();
	// eslint-disable-next-line no-nested-ternary
	const currentUserTimeZone = ff('relay-migration-issue-fields-date-time_eaqd2')
		? 'timeZone' in user
			? user.timeZone || null
			: null
		: isDataExtra(user)
			? user?.timeZone || null
			: null;
	const timeZoneToUse: TimeZone | null = timeZone !== undefined ? timeZone : currentUserTimeZone;
	const handleOnChange = useCallback(
		(isoDateTimeValue?: string | null) => {
			const convertedIso = convertISODateFormat(isoDateTimeValue ?? '');
			if (isNaN(Date.parse(convertedIso))) {
				onChange?.(null);
			} else {
				onChange?.(convertedIso);
			}
		},
		[onChange],
	);

	const parseValue: ParseValueFn = useCallback(
		(dateTimeValue, fallbackDate, fallbackTime, fallbackTimeZone) =>
			onParseValue(dateTimeValue, fallbackDate, fallbackTime, timeZoneToUse ?? fallbackTimeZone),
		[timeZoneToUse],
	);

	const standardizedLocale = standardizeLocale(locale);
	return (
		<DateTimePicker
			testId="issue-field-date-time-editview-full.ui.date-time.date-time-picker"
			locale={standardizedLocale}
			autoFocus={autoFocus}
			isDisabled={isDisabled}
			isInvalid={isInvalid}
			value={value || ''}
			onChange={handleOnChange}
			parseValue={timeZoneToUse != null ? parseValue : undefined}
			timePickerProps={{
				placeholder: timePlaceholder,
				times: availableTimes,
				timeIsEditable: true,
				selectProps: {
					formatCreateLabel: (val: string) => val,

					// Want to show the dropdown option if the user input
					// matches the value (22:30) or the label (10:30pm)
					filterOption: (
						option: {
							label: string;
							value: unknown;
						},
						val: string,
					) => !val || option.value === val || option.label === val,
				},
			}}
			// Use of non-standardized locale is intentional, getLocale map accounts for this.

			datePickerProps={{
				placeholder: datePlaceholder || '',
				weekStartDay: get(getLocale(locale), 'options.weekStartsOn', DEFAULT_WEEK_START_DAY),
				...(onDatePickerKeyDown
					? {
							selectProps: {
								onKeyDown: onDatePickerKeyDown,
							},
						}
					: {}),
			}}
		/>
	);
};
