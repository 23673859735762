import { type MutableRefObject, useEffect, useRef } from 'react';
import type { Store } from 'redux';
import createHistory from 'history/createBrowserHistory';
import createMemoryHistory from 'history/createMemoryHistory';
import type { AssociatedIssuesContextActions } from '@atlassian/jira-associated-issues-context-service/src/actions.tsx';
import {
	categoryIdForStatusCategory,
	statusCategoryForId,
} from '@atlassian/jira-common-constants/src/status-categories';
import type { SubProduct } from '@atlassian/jira-common-constants/src/sub-product-types';
import { fg } from '@atlassian/jira-feature-gating';
import type { AttachmentServiceActions } from '@atlassian/jira-issue-attachments-base/src/services/attachments-service/types.tsx';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type {
	IssueContext,
	IssueContextServiceActions,
} from '@atlassian/jira-issue-context-service/src/types.tsx';
import { getMaxTokenLength } from '@atlassian/jira-issue-fetch-services-common/src/common/utils/token-browser-utils';
import type { Transition } from '@atlassian/jira-issue-fetch-services/src/types';
import type { FieldConfigServiceActions } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import type { FieldValueServiceActions } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { IssueRefreshLoadingActions } from '@atlassian/jira-issue-field-base/src/services/issue-refresh-loading-service/index.tsx';
import type { MediaContextServiceActions } from '@atlassian/jira-issue-media-context-service/src/types.tsx';
import type { IssueViewNonCriticalDataActions } from '@atlassian/jira-issue-non-critical-gira-service/src/controllers/use-issue-view-non-critical-data/types.tsx';
import type { IssueRefreshServiceActions } from '@atlassian/jira-issue-refresh-service/src/types.tsx';
import type { IssueScrollActions } from '@atlassian/jira-issue-scroll/src/services/types.tsx';
import type { UserPreferenceActions } from '@atlassian/jira-issue-user-preference-services/src/types.tsx';
import type { State as IssueState } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import {
	getFieldType,
	isRichTextSupported,
} from '@atlassian/jira-issue-view-common-utils/src/fields/index.tsx';
import { storeFields } from '@atlassian/jira-issue-view-common-utils/src/utils/fields-extraction';
import type { ResourceManager } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources/prefetched-resource-manager/index.tsx';
import { defaultConnectFieldConfig } from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field';
import type { EcosystemActions } from '@atlassian/jira-issue-view-ecosystem-service/src/services/types.tsx';
import type {
	IssueViewFieldUpdateActions,
	FieldChangedEvent,
} from '@atlassian/jira-issue-view-field-update-events';
import { ChangeEventTypes } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/constants.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import type { ForgeServiceActions } from '@atlassian/jira-issue-view-forge-service/src/services/types.tsx';
import type { IssueViewLayoutActions } from '@atlassian/jira-issue-view-layout/src/services/types.tsx';
import type { RecentIssuesActions } from '@atlassian/jira-issue-view-services/src/recent-issues-service/types.tsx';
import { setContext } from '@atlassian/jira-issue-view-store/src/actions/context-actions';
import type { ExternalAction } from '@atlassian/jira-issue-view-store/src/actions/external-actions';
import { fetchIssueRemoteDataRequest } from '@atlassian/jira-issue-view-store/src/actions/issue-remote-data-actions';
import { fetchLoggedInUserRequest } from '@atlassian/jira-issue-view-store/src/actions/user-fetch-actions';
import {
	fetchForgeRequest,
	fetchIssueRequest,
	refreshIssueRequest,
} from '@atlassian/jira-issue-view-store/src/common/actions/issue-fetch-actions';
import { fieldSaveSuccess } from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-save-actions';
import type { WorkflowTransitionsActions } from '@atlassian/jira-issue-workflow-transitions-services/src/types.tsx';
import {
	DESCRIPTION_TYPE,
	TEXT_AREA_CF_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	MULTI_SELECT_CF_TYPE,
	SELECT_CF_TYPE,
	URL_CF_TYPE,
	RADIO_BUTTONS_CF_TYPE,
	TEXT_CF_TYPE,
	FIX_VERSIONS_TYPE,
	AFFECTS_VERSIONS_TYPE,
	USER_CF_TYPE,
	REPORTER_TYPE,
	MULTI_USER_CF_TYPE,
	COMPONENTS_TYPE,
	PEOPLE_CF_TYPE,
	STATUS_TYPE,
	TIME_ESTIMATE_TYPE,
} from '@atlassian/jira-platform-field-config';
import type { ProjectContextServiceActions } from '@atlassian/jira-project-context-service/src/types.tsx';
import type { ProjectPermissionActions } from '@atlassian/jira-project-permissions-service/src/types.tsx';
import type { DataProvider } from '@atlassian/jira-providers-issue/src/model/data-provider.tsx';
import type { Transition as ProviderTransition } from '@atlassian/jira-providers-issue/src/model/issue.tsx';
import type { JiraSettingsActions } from '@atlassian/jira-settings-service/src/types.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { TenantContext } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import type { HookResult } from '@atlassian/jira-testing-library';
import type { Route } from '@atlassian/react-resource-router';
import { initialiseMetrics, type StartRenderingOptions } from '../../../common/metrics';
import createStore from '../../../store';
import { consumePrefetchedDataIfAble } from './prefetched-data-consumption';
import type { Props } from './types';

export const getContext = (props: Props, tenantContext: TenantContext, orgId?: string | null) => {
	const {
		baseUrl,
		activationId,
		cloudId,
		licensedProducts,
		locale,
		atlassianAccountId,
		productCrossSellConfig,
		siteAdminStatus,
		isAnonymous,
		appEditions,
		appPermissions,
		licenseStates,
		firstActivationDateMs,
	} = tenantContext;

	return {
		analyticsSource: props.analyticsSource,
		appEditions,
		appPermissions,
		atlassianAccountId,
		baseUrl,
		backButtonUrl: props.backButtonUrl,
		boardId: props.boardId,
		boardType: props.boardType,
		cloudId,
		activationId,
		orgId,
		estimationStatistic: props.estimationStatistic,
		firstActivationDateMs,
		isAnonymous,
		isLoadedWithPage: props.isLoadedWithPage,
		isLoading: false,
		isModal: props.isModal,
		isSpaEnabled: props.isSpaEnabled,
		issueKey: props.issueKey,
		issueNavigatorData: props.issueNavigatorData,
		licensedProducts,
		licenseStates,
		locale,
		loginRedirectUrl: props.loginRedirectUrl,
		maxTokenLength: getMaxTokenLength(),
		onIssueKeyChange: props.onIssueKeyChange,
		productCrossSellConfig,
		rapidViewId: props.rapidViewId,
		rapidViewMap: props.rapidViewMap,
		siteAdminStatus,
		viewMode: props.viewMode,
	};
};

// getIssueContext returns the data for storing in the new sweet state store, which does not contain
// the data from tenantContext
export const getIssueContext = (props: Props): IssueContext => {
	const context: IssueContext = {
		analyticsSource: props.analyticsSource,
		backButtonUrl: props.backButtonUrl,
		boardId: props.boardId,
		boardType: props.boardType,
		estimationStatistic: props.estimationStatistic,
		isLoadedWithPage: props.isLoadedWithPage,
		isLoading: false,
		isModal: props.isModal,
		isSpaEnabled: props.isSpaEnabled,
		issueKey: props.issueKey,
		issueNavigatorData: props.issueNavigatorData,
		loginRedirectUrl: props.loginRedirectUrl,
		maxTokenLength: getMaxTokenLength(),
		onIssueKeyChange: props.onIssueKeyChange,
		rapidViewId: props.rapidViewId,
		rapidViewMap: props.rapidViewMap?.map((arr: Array<string>) =>
			arr.map((key) => toIssueKey(key)),
		),
		viewMode: props.viewMode,
	};
	return context;
};

export const transformTransition = (transition: ProviderTransition): Transition => ({
	id: transition.id,
	name: transition.name,
	hasScreen: transition.hasScreen,
	isGlobal: transition.isGlobal,
	isInitial: transition.isInitial,
	isConditional: transition.isConditional,
	isLooped: transition.isLooped,
	to: {
		id: transition.to.id,
		name: transition.to.name,
		description: transition.to.description ?? '',
		statusCategory: {
			id: categoryIdForStatusCategory(statusCategoryForId(transition.to.statusCategory?.id)),
		},
	},
	from: transition.from,
});
export const getStore = (
	props: Props,
	tenantContext: TenantContext,
	subProduct: SubProduct | null,
	dataProvider: DataProvider,
	recentIssuesActions: RecentIssuesActions,
	issueRefreshServiceActions: IssueRefreshServiceActions,
	fieldsValueActions: FieldValueServiceActions,
	fieldsConfigActions: FieldConfigServiceActions,
	issueViewLayoutActions: IssueViewLayoutActions,
	issueViewFieldUpdateActions: IssueViewFieldUpdateActions,
	forgeActions: ForgeServiceActions,
	ecosystemActions: EcosystemActions,
	userPreferenceActions: UserPreferenceActions,
	prefetchedResourceManager: ResourceManager,
	attachmentActions: AttachmentServiceActions,
	issueScrollActions: IssueScrollActions,
	permissionActions: ProjectPermissionActions,
	jiraSettingsActions: JiraSettingsActions,
	projectContextActions: ProjectContextServiceActions,
	issueContextActions: IssueContextServiceActions,
	workflowTransitionsActions: WorkflowTransitionsActions,
	orgId: string | null | null | undefined,
	mediaContextActions: MediaContextServiceActions,
	issueRefreshLoadingActionsRef: MutableRefObject<IssueRefreshLoadingActions>,
	associatedIssuesContextActions?: AssociatedIssuesContextActions,
	issueViewNonCriticalDataActions?: IssueViewNonCriticalDataActions,
) => {
	const recentIssue = recentIssuesActions.getRecentIssue(props.issueKey);
	const initialState = recentIssue
		? {
				...recentIssue,
				// Some props for the same issue might have changed - e.g. analyticsSource
				context: {
					...recentIssue.context,
					...getContext(props, tenantContext, orgId),
				},
			}
		: undefined;

	return createStore({
		attachmentActions,
		onChange: props.onChange,
		onError: props.onError,
		dataProvider,
		history: props.history || (__SERVER__ ? createMemoryHistory() : createHistory()),
		initialState,
		issueKey: props.issueKey,
		subProduct,
		issueRefreshServiceActions,
		fieldsValueActions,
		fieldsConfigActions,
		issueViewLayoutActions,
		issueViewFieldUpdateActions,
		forgeActions,
		ecosystemActions,
		prefetchedResourceManager,
		recentIssuesActions,
		userPreferenceActions,
		issueScrollActions,
		permissionActions,
		jiraSettingsActions,
		projectContextActions,
		issueContextActions,
		workflowTransitionsActions,
		mediaContextActions,
		associatedIssuesContextActions,
		issueRefreshLoadingActionsRef,
		issueViewNonCriticalDataActions,
	});
};

export const setup = (
	props: Props,
	tenantContext: TenantContext,
	dataProvider: DataProvider,
	store: Store<IssueState>,
	recentIssuesActions: RecentIssuesActions,
	fieldsValueActions: FieldValueServiceActions,
	fieldsConfigActions: FieldConfigServiceActions,
	userPreferenceActions: UserPreferenceActions,
	prefetchedResourceManager: ResourceManager,
	dispatchExternalAction: (action: ExternalAction) => void,
	attachmentActions: AttachmentServiceActions,
	issueContextActions: IssueContextServiceActions,
	permissionActions: ProjectPermissionActions,
	route: Route | null,
	issueViewLayoutActions: IssueViewLayoutActions,
	forgeActions: ForgeServiceActions,
	ecosystemActions: EcosystemActions,
	jiraSettingsActions: JiraSettingsActions,
	projectContextActions: ProjectContextServiceActions,
	orgId?: string | null,
	associatedIssuesContextActions?: AssociatedIssuesContextActions,
) => {
	const recentIssue = recentIssuesActions.getRecentIssue(props.issueKey);
	const recentIssuesCount = recentIssuesActions.getRecentIssuesCount();

	const startRenderingOptions: StartRenderingOptions = {
		analyticsSource: props.analyticsSource,
		issueContextActions,
		isLoadedWithPage: props.isLoadedWithPage || false,
		isSPA: !props.isInitialRender,
		isRecentIssue: !!recentIssue,
		recentIssuesCount,
		dataProvider,
		store,
		route,
	};

	initialiseMetrics(startRenderingOptions);

	// Calling set context for recent issues is necessary because our context-epic currently expects
	// a setContext to be fired for every issue viewed for bento-to-bento transitions to work.
	// The data will not change since we have already merged the context in the initial state
	store.dispatch(setContext(getContext(props, tenantContext, orgId)));
	issueContextActions.setIssueContext(getIssueContext(props));

	if (props.dispatchExternalActionRef) {
		props.dispatchExternalActionRef(dispatchExternalAction);
	}

	if (recentIssue) {
		store.dispatch(refreshIssueRequest());
	} else {
		const prefetchDataConsumed =
			prefetchedResourceManager &&
			consumePrefetchedDataIfAble({
				resourceManager: prefetchedResourceManager,
				savePreferences: (preferences) => userPreferenceActions.setUserPreferences(preferences),
				storeFieldsWithContext: (data, project) =>
					storeFields(data, fieldsValueActions, fieldsConfigActions, props.issueKey, project),
				storeIssueContainersLayoutData: (issueContainers) => {
					issueViewLayoutActions.setIssueViewContainersLayout(props.issueKey, issueContainers);
				},
				storeEcosystemData: (ecosystem, issueKey) => {
					ecosystemActions.setEcosystem(ecosystem, issueKey);
				},
				saveProjectContext: (projectKey, projectContext) => {
					projectContextActions.setProjectContext(projectKey, projectContext);
				},
				permissionActions,
				attachmentActions,
				jiraSettingsActions,
				store,
				baseUrl: tenantContext.baseUrl,
				issueContextActions,
				issueKey: props.issueKey,
				associatedIssuesContextActions,
			});

		if (prefetchDataConsumed) {
			// Data is readily available and has been fed into the storeRef on creation
			// We only need to fire the forge fetch
			store.dispatch(fetchForgeRequest());
			prefetchedResourceManager.clearAll();
		} else {
			// this "else" could mean any of:
			// - prefetched-data is still loading
			// - data returned with errors - we'll kickoff redux fetching in this case for its retry handling of transient errors
			store.dispatch(fetchIssueRequest());
		}

		store.dispatch(fetchLoggedInUserRequest());
	}

	if (!__SERVER__) {
		store.dispatch(fetchIssueRemoteDataRequest(props.issueKey));
	}
};

export const forwardValueToReduxStore = (
	event: FieldChangedEvent,
	fieldOptions: { isRichTextField: boolean } = { isRichTextField: false },
) =>
	fieldSaveSuccess(
		event.meta.fieldId,
		{
			...defaultConnectFieldConfig,
			...fieldOptions,
		},
		event.meta.fieldValue,
		{
			updatedFieldValue: event.meta.fieldValue,
		},
	);

export const useSweetStateToReduxSynchronization = (store: Store<IssueState>) => {
	const [, { registerEventHandler }] = useIssueViewFieldUpdateEvents();
	const issueId = useIssueId();

	useEffect(() => {
		const unregister = registerEventHandler('onChange', (event) => {
			if (event.type !== ChangeEventTypes.FIELD_CHANGED || event.issueId !== issueId) {
				return;
			}
			const field = store.getState()?.fields[event.fieldId];
			const fieldType = getFieldType(field);
			switch (fieldType) {
				case TEXT_AREA_CF_TYPE:
					store.dispatch(
						forwardValueToReduxStore(event, {
							isRichTextField: isRichTextSupported(field),
						}),
					);
					break;
				case DESCRIPTION_TYPE:
					store.dispatch(
						forwardValueToReduxStore(event, {
							isRichTextField: true,
						}),
					);
					break;
				case MULTI_CHECKBOXES_CF_TYPE:
				case SELECT_CF_TYPE:
				case MULTI_SELECT_CF_TYPE:
				case TEXT_CF_TYPE:
				case URL_CF_TYPE:
				case RADIO_BUTTONS_CF_TYPE:
				case FIX_VERSIONS_TYPE:
				case USER_CF_TYPE:
				case REPORTER_TYPE:
				case COMPONENTS_TYPE:
				case MULTI_USER_CF_TYPE:
				case AFFECTS_VERSIONS_TYPE:
				case PEOPLE_CF_TYPE:
				case STATUS_TYPE:
					store.dispatch(forwardValueToReduxStore(event));
					break;
				case TIME_ESTIMATE_TYPE:
					if (fg('uim-orginal-estimate-field-support_ugzqt')) {
						store.dispatch(forwardValueToReduxStore(event));
					}
					break;
				default:
					break;
			}
		});

		return unregister;
	}, [registerEventHandler, store, issueId]);

	return null;
};

/**
 * Any set of actions which come from a sweet state store that has an issue scoped Container need to use this to ensure that epics retain an up to date reference to the actions of the current issue
 * */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useScopedStoreActions = <THook extends (...args: any[]) => any>(
	hook: THook,
	...args: Parameters<THook>
): MutableRefObject<ReturnType<THook>> => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result: HookResult<any> = hook(...args);
	const actions = Array.isArray(result) ? result[1] : result;

	const actionsRef = useRef(actions);
	actionsRef.current = actions;

	return actionsRef;
};
