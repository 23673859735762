import { onSelectAllChange } from './bulk-select';
import { fetchLinkedAlerts } from './fetch-linked-alerts';
import { fetchSavedSearches } from './fetch-saved-searches';
import { openLinkAlertsModal, closeLinkAlertsModal, initLinkModal } from './link-modal';
import { setTextSearch, setSelectedSavedSearchId } from './link-modal-filter';
import { linkSelectedAlerts } from './link-selected-alerts';
import { onAlertCheckboxChanged } from './on-alert-checkbox-changed';
import { searchAlerts } from './search-alerts';
import { unlinkAlert } from './unlink-alert';
import { openViewModal, closeViewModal } from './view-modal';

export const actions = {
	closeViewModal,
	openViewModal,
	fetchLinkedAlerts,
	searchAlerts,
	openLinkAlertsModal,
	closeLinkAlertsModal,
	initLinkModal,
	fetchSavedSearches,
	setTextSearch,
	setSelectedSavedSearchId,
	onAlertCheckboxChanged,
	linkSelectedAlerts,
	unlinkAlert,
	onSelectAllChange,
};

export type Actions = typeof actions;

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	predefinedIdToNameMap,
	PREDEFINED_FILTER_ALL,
	PREDEFINED_FILTER_ASSIGNED,
} from './fetch-saved-searches/constants';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { transformEscalatorResponse } from './fetch-saved-searches';
