import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types';
import type { IssueKey, ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ChildIssuesPanelType, SortingOption } from '../../model/types';
import { generateChildIssuesJQL } from '../generate-child-issues-jql';
import { generateNinBasePath } from '../generate-nin-base-path';

export const generateViewInSearchLink = (
	projectKey: ProjectKey,
	projectType: ProjectType,
	isSimplifiedProject: boolean,
	parentKey: IssueKey,
	childIssuesPanelType: ChildIssuesPanelType,
	hasExceededIssuesLimitInitialLoad: boolean,
	sortByValue: SortingOption | null = null,
) =>
	`${generateNinBasePath()}?${generateChildIssuesJQL(
		parentKey,
		childIssuesPanelType,
		hasExceededIssuesLimitInitialLoad,
		sortByValue || undefined,
	)}`;
