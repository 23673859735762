import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	showMoreTooltip: {
		// eslint-disable-next-line jira/i18n/id-named-by-package
		id: 'issue-field-labels-readview-full.labels.compact.show-more-tooltip',
		defaultMessage: 'See more labels',
		description: 'Label for trigger button to open a dialog with a list of labels',
	},
});
