import type { FlattenedCommand } from '../../../types/commands/index.tsx';
import { hasAction } from '../../../utils';
import { SCROLL_DIRECTION } from './constants';

export const isElementVisibleInContainer = (
	element: HTMLElement,
	container: HTMLElement,
): boolean => {
	const modalBodyRect = container.getBoundingClientRect();
	const elementRect = element.getBoundingClientRect();
	return elementRect.top >= modalBodyRect.top && elementRect.bottom <= modalBodyRect.bottom;
};

export const getScrollToIndex = (
	results: FlattenedCommand[],
	newActiveIndex: number,
	scrollTo: SCROLL_DIRECTION,
): number => {
	const commandResultIndex = results.findIndex(
		(result) => !result.isGroup && result.index === newActiveIndex,
	);

	if (scrollTo === SCROLL_DIRECTION.START && commandResultIndex > 0) {
		// Verify if there is any active command before this id
		const prevActiveCommandIndex = results.findIndex(
			(result, index) => index < commandResultIndex && !result.isGroup && hasAction(result.command),
		);
		if (prevActiveCommandIndex === -1) {
			return 0;
		}
	}

	if (scrollTo === SCROLL_DIRECTION.END) {
		// Verify if there is any active command after this id
		const afterActiveCommandIndex = results.findIndex(
			(result, index) => index > commandResultIndex && !result.isGroup && hasAction(result.command),
		);
		if (afterActiveCommandIndex === -1) {
			return results.length - 1;
		}

		// Scrolling down, so group headings don't need to be taken into account
		return commandResultIndex;
	}

	const isCommandAfterGroupHeading =
		commandResultIndex > 0 && results[commandResultIndex - 1].isGroup;
	return isCommandAfterGroupHeading ? commandResultIndex - 1 : commandResultIndex;
};

export const scrollResultIntoView = ({
	target,
	direction,
	scrollContainer,
}: {
	target: HTMLElement;
	direction: SCROLL_DIRECTION;
	scrollContainer: HTMLElement;
}) => {
	const targetRect = target.getBoundingClientRect();
	const containerRect = scrollContainer.getBoundingClientRect();
	const gapScroll = 4;

	if (direction === SCROLL_DIRECTION.END) {
		const commandPosition = targetRect.bottom - containerRect.top;
		const amountToScroll = commandPosition - containerRect.height;
		const pos = amountToScroll + gapScroll + scrollContainer.scrollTop;

		scrollContainer.scrollTo({ top: pos });
	} else {
		const commandPosition = containerRect.top - targetRect.top;
		const amountToScroll = scrollContainer.scrollTop - commandPosition;
		const pos = amountToScroll - gapScroll;

		scrollContainer.scrollTo({ top: pos });
	}
};

// TODO: delete this function on compal_smooth_transition_on_size_change cleanup
export const scrollResultIntoViewOld = (
	scrollToResult: HTMLElement,
	scrollTo: SCROLL_DIRECTION,
) => {
	if (!scrollToResult?.parentElement) return;

	const parent = scrollToResult.parentElement;
	const elemRect = scrollToResult.getBoundingClientRect();
	const parentRect = parent.getBoundingClientRect();
	const gapScroll = 4;

	if (scrollTo === SCROLL_DIRECTION.END) {
		const commandPosition = elemRect.bottom - parentRect.top;
		const amountToScroll = commandPosition - parentRect.height;
		const pos = amountToScroll + gapScroll + parent.scrollTop;

		scrollToResult.parentElement.scrollTo({ top: pos });
	} else {
		const commandPosition = parentRect.top - elemRect.top;
		const amountToScroll = parent.scrollTop - commandPosition;
		const pos = amountToScroll - gapScroll;

		scrollToResult.parentElement.scrollTo({ top: pos });
	}
};
