import gqlTagPolaris from 'graphql-tag';
import type {
	jira_polaris_ApplyProjectTemplate_applyPolarisProjectTemplate as ApplyPolarisProjectTemplateMutation,
	jira_polaris_ApplyProjectTemplateVariables as ApplyPolarisProjectTemplateVariables,
} from './__generated_apollo__/jira_polaris_ApplyProjectTemplate';
import type { ApplyProjectTemplate } from './types';

const APPLY_PROJECT_TEMPLATE_QUERY = gqlTagPolaris`
mutation jira_polaris_ApplyProjectTemplate($input: ApplyPolarisProjectTemplateInput!) {
    applyPolarisProjectTemplate(input: $input) {
        success
        errors {
            message
        }
    }
}
`;

export const applyProjectTemplate: ApplyProjectTemplate = async (
	client,
	projectAri,
	ideaTypeAri,
	template,
) => {
	const { errors } = await client.mutate<
		ApplyPolarisProjectTemplateMutation,
		ApplyPolarisProjectTemplateVariables
	>({
		mutation: APPLY_PROJECT_TEMPLATE_QUERY,
		variables: {
			input: {
				project: projectAri,
				ideaType: ideaTypeAri,
				template,
			},
		},
	});

	if (errors !== undefined) {
		throw new Error(
			`polaris-ideas.apply-project-template-error:${errors.map((e) => e.message).join(', ')}`,
		);
	}

	return true;
};
