import type { View, ViewDraft } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';
import { saveView } from '../save';
import { applyDraftToView } from '../utils/autosave';
import { updateViewState } from '../utils/state';
import { currentViewFilter } from '../utils/views';
import { resetDraft } from './reset-draft';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { resetDraft };

export const setDraft =
	(draft: ViewDraft): Action<State, Props> =>
	({ getState, setState }, { currentViewSlug }) => {
		const state = getState();
		const { changedView, viewSets } = updateViewState(
			state.viewSets,
			currentViewFilter(currentViewSlug),
			(view: View): View => ({
				...view,
				draft,
			}),
		);

		if (changedView) {
			setState({ viewSets });
		}
	};

export const setAutosaveEnabled =
	(isAutosaveEnabled: boolean): Action<State, Props> =>
	({ getState, setState, dispatch }, { currentViewSlug }) => {
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			(view: View): View => ({
				...view,
				modified: true,
				isAutosaveEnabled,
			}),
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveView(changedView.id));
			dispatch(resetDraft());
		}
	};

export const saveUnsavedChanges =
	(): Action<State, Props> =>
	async ({ getState, setState, dispatch }, { currentViewSlug }) => {
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			(view: View): View => ({ ...applyDraftToView(view), modified: true }),
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveView(changedView.id));
		}
	};
