import React, { useCallback, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import {
	useLocalAssociatedIssuesContext,
	useRemoteAssociatedIssuesContext,
} from '@atlassian/jira-associated-issues-context-service/src/context.tsx';
import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import JiraIssueLineCard from '@atlassian/jira-issue-view-common-views/src/issue-line-card-content/main.tsx';
import { RemoteIssueLineCardError } from '@atlassian/jira-issue-view-common-views/src/issue-line-card/view/remote-issue-line-card-error/index.tsx';
import {
	fireOperationalAnalytics,
	MountEvent,
	useAnalyticsEvents,
	type Attributes,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
// eslint-disable-next-line import/order
import { toIssueId, toIssueKey, type IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

import type { LinkedIssueAndLinkId } from '../types';

type Props = {
	isInteractive: boolean;
	isHovering: boolean;
	isActive: boolean;
	isFocused: boolean;
	issue: LinkedIssueAndLinkId;
	canAddIssueLinks: boolean;
	groupId: string;
	projectType: ProjectType;
	onDelete: (groupId: string, issueKey: IssueKey, globalId?: string) => void;
	onClick?: (event: MouseEvent) => void;
	onEditIssue?: (issueKey: IssueKey) => void;
};

export const IssueLinkLineCard = ({
	isHovering,
	isActive,
	isFocused,
	issue,
	groupId,
	isInteractive,
	onDelete,
	canAddIssueLinks,
	projectType,
	onClick,
	onEditIssue,
}: Props) => {
	const getAttributes = useCallback(
		() => ({
			projectType,
			groupId,
			linkedIssueId: issue.id,
			isRemote: issue.isRemote || false,
			hasError: issue.hasError || false,
			errorType: issue.errorType || '',
			remoteIssueLinkError:
				issue.remoteIssueLinkError && issue.remoteIssueLinkError.error
					? issue.remoteIssueLinkError.error
					: 'NONE',
		}),
		[projectType, groupId, issue],
	);
	const [localAssociatedIssuesContext] = useLocalAssociatedIssuesContext();

	const [remoteAssociatedIssuesContext] = useRemoteAssociatedIssuesContext();

	const buildMountEvent = () => (
		<MountEvent
			onMount={(analyticsEvent: UIAnalyticsEvent) =>
				fireOperationalAnalytics(
					analyticsEvent,
					'issueView.IssueLinkLineCard loaded',
					'IssueLinkLineCard',
					getAttributes(),
				)
			}
		/>
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleUserClickAnalytics = useCallback(
		(actionSubjectId: string) => {
			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'issueView.IssueLinkLineCard',
			});
			const attributes: Attributes = getAttributes();
			fireUIAnalytics(analyticsEvent, actionSubjectId, attributes);
		},
		[createAnalyticsEvent, getAttributes],
	);

	const handleEditIssue = useCallback(() => {
		if (issue.issueKey) {
			onEditIssue?.(toIssueKey(issue.issueKey));
		}
	}, [issue.issueKey, onEditIssue]);

	const renderCard = () => {
		const handleDelete = (issueKey: IssueKey, globalId?: string) =>
			onDelete && onDelete(groupId, issueKey, globalId);

		if (issue.remoteIssueLinkError) {
			return (
				<RemoteIssueLineCardError
					issueTypeIconUrl={issue.issueTypeIconUrl}
					issueTypeName={issue.issueTypeName}
					issueKey={issue.issueKey}
					remoteServerHostname={issue.remoteServerHostname}
					globalId={issue.globalId}
					isHovering={isHovering}
					isFocused={isFocused}
					issueLink={issue.issueLink}
					error={issue.remoteIssueLinkError.error}
					handleUserClickAnalytics={handleUserClickAnalytics}
					canRemove={canAddIssueLinks}
					// @ts-expect-error - TS2774 - This condition will always return true since this function is always defined. Did you mean to call it instead?
					onDelete={onDelete && handleDelete}
				/>
			);
		}

		const canChangeStatus = isInteractive && !issue.remoteServerHostname;

		const {
			id,
			issueKey,
			issueLink,
			issueTypeIconUrl,
			issueTypeName,
			issuePriorityUrl,
			issuePriorityName,
			issueSummary,
			statusCategory,
			statusCategoryId,
			statusId,
			statusName,
			assigneeUrl,
			assigneeDisplayName,
			isLoading,
			errorInlineMessage,
			hasError,
			estimateFieldId,
			isRemote,
			globalId,
			remoteServerHostname,
		} = issue;

		let isResolved;
		if (isRemote) {
			isResolved = globalId ? remoteAssociatedIssuesContext[globalId]?.isResolved : false;
		} else {
			isResolved = issueKey ? localAssociatedIssuesContext[issueKey]?.isResolved : false;
		}

		return (
			<JiraIssueLineCard
				linkId={issue.linkId}
				id={toIssueId(id)}
				issueKey={toIssueKey(issueKey ?? '')}
				issueLink={issueLink}
				issueTypeIconUrl={issueTypeIconUrl}
				issueTypeName={issueTypeName}
				issuePriorityUrl={issuePriorityUrl}
				issuePriorityName={issuePriorityName}
				issueSummary={issueSummary}
				statusCategory={statusCategory}
				statusCategoryId={statusCategoryId}
				statusId={statusId}
				statusName={statusName}
				assigneeUrl={assigneeUrl}
				assigneeDisplayName={assigneeDisplayName}
				isLoading={isLoading}
				errorInlineMessage={errorInlineMessage}
				hasError={hasError}
				estimateFieldId={estimateFieldId}
				isRemote={isRemote}
				globalId={globalId}
				remoteServerHostname={remoteServerHostname}
				isHovering={isHovering}
				isActive={isActive}
				isFocused={isFocused}
				isInteractive={canChangeStatus}
				// @ts-expect-error - TS2774 - This condition will always return true since this function is always defined. Did you mean to call it instead?
				onDelete={onDelete && handleDelete}
				canRemove={canAddIssueLinks}
				projectType={projectType}
				onHandleUserClickAnalytics={handleUserClickAnalytics}
				isInlineEditEnabled={false}
				shouldDisplayDevOpsData
				onClick={onClick}
				isResolved={isResolved}
				onEdit={handleEditIssue}
			/>
		);
	};

	return (
		<ReportErrors id="IssueLinkLineCard" packageName="jiraIssueView" attributes={getAttributes()}>
			{renderCard()}
			{buildMountEvent()}
		</ReportErrors>
	);
};
