import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { IssueAdjustmentsState } from '../../types';

type UpdateActiveScreenTabPayload = {
	selected: number;
};

/**
 * Update active screen tab using provided selected tab index
 * @param {UpdateActiveScreenTabPayload} payload - contains selected tab index
 */
export const updateActiveScreenTab =
	({ selected }: UpdateActiveScreenTabPayload) =>
	({ getState, setState }: StoreActionApi<IssueAdjustmentsState>) => {
		const { screenTabs } = getState();
		if (screenTabs) {
			setState({
				screenTabs: screenTabs.map((tab, index) => ({
					...tab,
					isActive: index === selected,
				})),
			});
		}
	};
