import type { SortField } from '@atlassian/jira-polaris-domain-field/src/sort/types';
import type { Filter } from '@atlassian/jira-polaris-domain-view/src/filter/types';
import type { SortMode } from '@atlassian/jira-polaris-domain-view/src/sort/types';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';

const mergeFilters = (permanentFilters: Filter[], temporaryFilters?: Filter[]): Filter[] => {
	return [
		...permanentFilters,
		...(temporaryFilters ?? []).map(({ isTemporary, ...filterProperties }) => ({
			...filterProperties,
		})),
	];
};

const mergeSortBy = (permanentSortBy?: SortField[], temporarySortBy?: SortField[]): SortField[] => {
	return [
		...(permanentSortBy ?? []),
		...(temporarySortBy ?? []).map(({ isTemporary, ...sortByProperties }) => ({
			...sortByProperties,
		})),
	];
};

const resolveSortMode = (
	sortMode: SortMode | undefined,
	sortByLength: number,
): SortMode | undefined => {
	return sortMode !== 'fieldSort' && sortByLength > 0 ? 'fieldSort' : sortMode;
};

export const applyTemporaryDataToView = (view: View): View => {
	const updatedFilters = mergeFilters(view.filter, view.temporaryData?.filter);
	const updatedSortBy = mergeSortBy(view.sortBy, view.temporaryData?.sortBy);
	const updatedSortMode = resolveSortMode(view.sortMode, updatedSortBy.length);

	return {
		...view,
		filter: updatedFilters,
		sortBy: updatedSortBy,
		sortMode: updatedSortMode,
	};
};
