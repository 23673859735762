import React from 'react';

import { useIntl } from 'react-intl-next';

import { Box, Inline, Text } from '@atlaskit/primitives';
import { RovoLogo } from '@atlassian/conversation-assistant-ui-components';

import messages from './messages';

// TODO: Remove during platform_editor_ai_command_palette_post_ga cleanup
export const PoweredByRovo = () => {
	const { formatMessage } = useIntl();

	return (
		<Inline space="space.025">
			<Box>
				<Text size="small">{formatMessage(messages.agentsFooterRightText)}</Text>
			</Box>
			<RovoLogo label={formatMessage(messages.agentsFooterRightText)} />
		</Inline>
	);
};
