import React, { type SyntheticEvent, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { PanelSource } from '../../types';
import messages from './messages';

type Props = {
	id: string;
	field: ReactNode;
	children: ReactNode;
	isOpen: boolean;
	onToggle: () => void;
	source: PanelSource;
};

export const ExpandPanel = ({ id, field, isOpen, children, onToggle, source }: Props) => {
	const { formatMessage } = useIntl();
	const controlContentId = `expand-panel-${id}`;

	const openedOrClosed = isOpen ? 'closed' : 'opened';

	return (
		<>
			<Container>
				<FieldContainer>{field}</FieldContainer>
				<Button
					testId={controlContentId}
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					onClick={(e: SyntheticEvent<any>, analyticsEvent: UIAnalyticsEvent) => {
						fireUIAnalytics(analyticsEvent, 'issueViewCustomerContextExpand', {
							context: id,
							openedOrClosed,
							source,
						});
						onToggle();
					}}
					appearance="subtle"
					aria-expanded={isOpen}
					aria-controls={controlContentId}
					aria-label={formatMessage(isOpen ? messages.collapse : messages.expand)}
					iconBefore={isOpen ? <ChevronUpIcon label="" /> : <ChevronDownIcon label="" />}
				/>
			</Container>
			<div hidden={!isOpen} id={controlContentId}>
				{children}
			</div>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldContainer = styled.div({
	flexGrow: '1',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	height: token('space.500', '40px'),
	alignItems: 'center',
	justifyContent: 'center',
});
