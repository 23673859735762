import React from 'react';
import { graphql, useFragment } from 'react-relay';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import { IssueViewMultiUserField } from '@atlassian/jira-issue-view-layout-multi-user-field/src/ui/index.tsx';
import {
	MULTI_USER_CF_TYPE,
	PARTICIPANTS_CF_TYPE,
	REQUEST_PARTICIPANTS_CF_TYPE,
} from '@atlassian/jira-platform-field-config';
import type { ui_issueViewLayoutMultiUser_IssueViewMultiUser$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutMultiUser_IssueViewMultiUser.graphql';

export interface IssueViewMultiUserProps {
	area?: Area;
	fragmentKey: ui_issueViewLayoutMultiUser_IssueViewMultiUser$key;
}

export const IssueViewMultiUser = ({ area, fragmentKey }: IssueViewMultiUserProps) => {
	const data = useFragment<ui_issueViewLayoutMultiUser_IssueViewMultiUser$key>(
		graphql`
			fragment ui_issueViewLayoutMultiUser_IssueViewMultiUser on JiraMultipleSelectUserPickerField
			@argumentDefinitions(
				issueViewRelayMultiUserFieldFlag: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-multi-user.relayprovider"
				}
			) {
				type
				...ui_issueViewLayoutMultiUserField_IssueViewMultiUserField
					@include(if: $issueViewRelayMultiUserFieldFlag)
			}
		`,
		fragmentKey,
	);

	switch (data.type) {
		case MULTI_USER_CF_TYPE:
			return <IssueViewMultiUserField area={area} fragmentKey={data} />;
		case PARTICIPANTS_CF_TYPE:
		case REQUEST_PARTICIPANTS_CF_TYPE:
			return null; // TODO implement
		default:
			return null;
	}
};
