import {
	CORE_PROJECT,
	SOFTWARE_PROJECT,
	SERVICE_DESK_PROJECT,
	PRODUCT_DISCOVERY_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types';
import { useProjectContext } from '@atlassian/jira-providers-project-context';

// TODO: Should I make this a standalone package (packages/issue-archival/use-archive-issue-url)?
export const useArchiveIssueUrl = () => {
	// TODO: Check if this is the correct way to get the project context and will always be available, it shouldn't be behind any feature flag
	const { data: projectContext } = useProjectContext();
	const { projectType, simplified, projectKey } = projectContext || {};

	switch (projectType) {
		case SOFTWARE_PROJECT: {
			if (simplified) {
				return `/jira/software/projects/${projectKey}/issues/archive`;
			}
			return `/jira/software/c/projects/${projectKey}/issues/archive`;
		}
		case SERVICE_DESK_PROJECT: {
			return `/jira/servicedesk/projects/${projectKey}/issues/archive`;
		}
		case CORE_PROJECT: {
			return `/jira/core/projects/${projectKey}/issues/archive`;
		}
		case PRODUCT_DISCOVERY_PROJECT: {
			return `/jira/product-discovery/projects/${projectKey}/issues/archive`;
		}
		default: {
			// TODO: Throw an error
			return '/jira/issues/archive';
		}
	}
};
