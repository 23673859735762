/**
 * @generated SignedSource<<64a42f72ca311d07de8b4b087b3db4a5>>
 * @relayHash 8cb16d718b8a652de5bcc691f9ceb179
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 0dfa05a481ef015a18bdd0efe193900d020073fe872039fbe9222dc48e073402

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraUpdateSingleSelectFieldInput = {
  id: string;
  operation: JiraSingleSelectOperationInput;
};
export type JiraSingleSelectOperationInput = {
  fieldOption?: string | null | undefined;
  id?: string | null | undefined;
  operation: JiraSingleValueFieldOperations;
};
export type singleSelect_issueFieldSingleSelect_SingleSelectField_Mutation$variables = {
  input: JiraUpdateSingleSelectFieldInput;
};
export type singleSelect_issueFieldSingleSelect_SingleSelectField_Mutation$data = {
  readonly jira: {
    readonly updateSingleSelectField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly " $fragmentSpreads": FragmentRefs<"singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditView">;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type singleSelect_issueFieldSingleSelect_SingleSelectField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateSingleSelectField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly fieldConfig: {
          readonly isEditable: boolean | null | undefined;
        } | null | undefined;
        readonly fieldOption: {
          readonly id: string;
          readonly optionId: string;
          readonly value: string | null | undefined;
        } | null | undefined;
        readonly id: string;
        readonly name: string;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type singleSelect_issueFieldSingleSelect_SingleSelectField_Mutation = {
  rawResponse: singleSelect_issueFieldSingleSelect_SingleSelectField_Mutation$rawResponse;
  response: singleSelect_issueFieldSingleSelect_SingleSelectField_Mutation$data;
  variables: singleSelect_issueFieldSingleSelect_SingleSelectField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "singleSelect_issueFieldSingleSelect_SingleSelectField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraSingleSelectFieldPayload",
            "kind": "LinkedField",
            "name": "updateSingleSelectField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraSingleSelectField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditView"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "singleSelect_issueFieldSingleSelect_SingleSelectField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraSingleSelectFieldPayload",
            "kind": "LinkedField",
            "name": "updateSingleSelectField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraSingleSelectField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "name"
                  },
                  {
                    "concreteType": "JiraOption",
                    "kind": "LinkedField",
                    "name": "fieldOption",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "value"
                      },
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "optionId"
                      }
                    ]
                  },
                  {
                    "concreteType": "JiraFieldConfig",
                    "kind": "LinkedField",
                    "name": "fieldConfig",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "isEditable"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "0dfa05a481ef015a18bdd0efe193900d020073fe872039fbe9222dc48e073402",
    "metadata": {},
    "name": "singleSelect_issueFieldSingleSelect_SingleSelectField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "dde6d33d65044027e5ae40fdf07231ee";

export default node;
