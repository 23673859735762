import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { getIsClassicSubtaskPanel } from '../../state/context/selectors';
import {
	getAllIssues,
	getInProgressIssues,
	getCompletedIssues,
} from '../../state/entities/selectors';
import type { State } from '../../state/types';
import { ProgressSummaryView } from './view';

export default flowWithSafeComponent(
	connect((state: State) => {
		const totalIssues = getAllIssues(state);
		const inProgressIssues = getInProgressIssues(state);
		const completedIssues = getCompletedIssues(state);

		return {
			isClassicSubtaskPanel: getIsClassicSubtaskPanel(state),
			totalIssueCount: totalIssues.length,
			inProgressIssueCount: inProgressIssues.length,
			completedIssueCount: completedIssues.length,
		};
	}, {}),
)(ProgressSummaryView);
