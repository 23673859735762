import type { IEnvironment } from 'relay-runtime';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { AiMateXProductType } from '@atlassian/jira-issue-hooks/src/services/use-ai-mate-product-type';
import type { Application } from '@atlassian/jira-shared-types/src/application.tsx';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { getSummaryFromAIMate } from '../../../services/ai-mate';
import { GENERAL_ERROR_ADF } from '../../../services/ai-mate/constants';
import type { ContainerProps, State } from '../types';

export const loadSummary =
	(
		cloudId: CloudId,
		environment: IEnvironment,
		issueKey: string,
		productType: AiMateXProductType,
		application?: Application,
		edition?: ApplicationEdition,
	): Action<State, ContainerProps> =>
	async ({ getState, setState }) => {
		if (getState().content !== null) {
			setState({ isPanelShown: true });
			return;
		}

		setState({ isGenerating: true, isPanelShown: true, content: null });

		try {
			const data = await getSummaryFromAIMate({
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				currentUrl: `${window.location.origin}/browse/${issueKey}`,
				productType,
				application,
				edition,
			});
			setState({ isGenerating: false, content: data, error: undefined });
		} catch (error) {
			const resolvedError = error instanceof Error ? error : undefined;
			log.safeErrorWithoutCustomerData(
				'issue.smart-request-summary.request-summary-state.src.controllers.state.actions.load-summary',
				'Error generating smart summary',
				resolvedError,
			);
			setState({
				isGenerating: false,
				isPanelShown: true,
				content: GENERAL_ERROR_ADF,
				error: resolvedError,
			});
		}
	};
