import { useCallback, useEffect, useState } from 'react';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import {
	getUserProperty,
	setUserProperties,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { createStore, createHook, type Action } from '@atlassian/react-sweet-state';

const localAdControlStore = createLocalStorageProvider('jira_loom_ad_controls');

interface State {
	adControlDismissed: {
		[adControlKey: string]: boolean;
	};
	loading: boolean;
}

const initialState: State = {
	loading: false, // loading when useLoomAdControls is rendered
	adControlDismissed: {},
};

interface InitLoomAdControlsProps {
	areProductSuggestionsEnabled: boolean;
	atlassianAccountId: string | null;
	adControlKeyWithCloudId: string;
}

const actions = {
	initLoomAdControls:
		({
			areProductSuggestionsEnabled,
			atlassianAccountId,
			adControlKeyWithCloudId,
		}: InitLoomAdControlsProps): Action<State> =>
		async ({ getState, setState }) => {
			const { adControlDismissed } = getState();
			if (!areProductSuggestionsEnabled || !atlassianAccountId) {
				setState({
					loading: false,
					adControlDismissed: {
						...adControlDismissed,
						[adControlKeyWithCloudId]: true,
					},
				});
				return;
			}
			// Async fetching of adcontrol settings via local storage or userProperties API
			const { loading } = getState();
			if (loading) {
				return;
			}
			setState({ loading: true });
			const adControlSetting = await getLoomAdControl(atlassianAccountId, adControlKeyWithCloudId);
			setState({
				loading: false,
				adControlDismissed: {
					...adControlDismissed,
					[adControlKeyWithCloudId]: adControlSetting,
				},
			});
		},
	setLoomAdControl:
		(adControlKeyWithCloudId: string, dismissed: boolean): Action<State> =>
		({ getState, setState }) => {
			const { adControlDismissed } = getState();
			setState({
				adControlDismissed: {
					...adControlDismissed,
					[adControlKeyWithCloudId]: dismissed,
				},
			});
		},
} as const;

type Actions = typeof actions;

const store = createStore<State, Actions>({
	name: 'loom-utils.loom-ad-controls',
	initialState,
	actions,
});

const useLoomAdControlsStore = createHook(store);

const setLocalAdControl = (adControlKey: string, dismissed: boolean) => {
	// Log failed instances of saving to local storage without altering users experience
	try {
		localAdControlStore.set(adControlKey, dismissed);
	} catch (error) {
		// log error
		fireErrorAnalytics({
			error: error instanceof Error ? error : new Error('Failed to set local ad control'),
			meta: {
				id: 'setLocalAdControl',
				packageName: 'jiraLoomUtils',
				teamName: 'cross-flow-growth',
			},
			attributes: {
				adControlKey,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
};

const getLoomAdControl = async (accountId: string, adControlKey: string) => {
	try {
		const localResult = localAdControlStore.get(adControlKey);
		if (localResult !== undefined) {
			return localResult;
		}
		const result = await getUserProperty<boolean>(accountId, adControlKey);
		setLocalAdControl(adControlKey, result);
		return result;
	} catch (error) {
		// If not found, then the user hasn't dismissed the ad.
		if (error instanceof FetchError && error.statusCode === 404) {
			setLocalAdControl(adControlKey, false);
			return false;
		}
		fireErrorAnalytics({
			error: error instanceof Error ? error : new Error('Failed to get loom ad control'),
			meta: {
				id: 'getLoomAdControl',
				packageName: 'jiraLoomUtils',
				teamName: 'cross-flow-growth',
			},
			attributes: {
				adControlKey,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
		// Assume dismissed ad if there is an error
		return true;
	}
};

const dismissLoomAd = async (accountId: string, adControlKey: string) => {
	try {
		setLocalAdControl(adControlKey, true);
		await setUserProperties(accountId, adControlKey, JSON.stringify(true));
	} catch (error) {
		fireErrorAnalytics({
			error: error instanceof Error ? error : new Error('Failed to dismiss loom ad'),
			meta: {
				id: 'dismissLoomAd',
				packageName: 'jiraLoomUtils',
				teamName: 'cross-flow-growth',
			},
			attributes: {
				adControlKey,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
};

export const useLoomAdControls = (adControlKey: string) => {
	const [userHasDismissedAd, setUserHasDismissedAd] = useState(true);
	const { atlassianAccountId, areProductSuggestionsEnabled, cloudId } = useTenantContext();
	const adControlKeyWithCloudId = `${adControlKey}-${cloudId}`;
	const [{ adControlDismissed }, { initLoomAdControls, setLoomAdControl }] =
		useLoomAdControlsStore();
	useEffect(() => {
		if (adControlDismissed[adControlKeyWithCloudId] === undefined) {
			initLoomAdControls({
				atlassianAccountId,
				areProductSuggestionsEnabled,
				adControlKeyWithCloudId,
			});
		} else {
			setUserHasDismissedAd(adControlDismissed[adControlKeyWithCloudId] ?? true);
		}
	}, [
		atlassianAccountId,
		areProductSuggestionsEnabled,
		adControlDismissed,
		initLoomAdControls,
		adControlKeyWithCloudId,
	]);

	const dismissAd = useCallback(async () => {
		if (atlassianAccountId) {
			setUserHasDismissedAd(true);
			setLoomAdControl(adControlKeyWithCloudId, true);
			await dismissLoomAd(atlassianAccountId, adControlKeyWithCloudId);
		}
	}, [adControlKeyWithCloudId, atlassianAccountId, setLoomAdControl]);

	return { userHasDismissedAd, dismissAd };
};
