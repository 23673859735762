import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import { useUFOComponentExperience } from '@atlassian/ufo';
import { jsmActivityFeedFilterExperience } from './common/ui/experience';
import type { ActivityFeedFilter, ActivityFeedFilterProps } from './ui';

const ActivityFeedFilterComponent = lazyForPaint<typeof ActivityFeedFilter>(() =>
	import(/* webpackChunkName: "async-jsm-activity-feed-filter" */ './ui/index').then(
		(module) => module.ActivityFeedFilter,
	),
);

export const AsyncActivityFeedFilter = (props: ActivityFeedFilterProps) => {
	useUFOComponentExperience(jsmActivityFeedFilterExperience);
	return (
		<Placeholder name="async-activity-feed-filter" fallback={null}>
			<ActivityFeedFilterComponent {...props} />
		</Placeholder>
	);
};
