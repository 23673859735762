import React from 'react';
import Badge from '@atlaskit/badge';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import type { ClassicStoryPointValue } from '../../common/types';
import { EmptyValue, HoverContainer } from './styled';

type Props = {
	isEditable: boolean;
	noValueText?: string;
	value: ClassicStoryPointValue;
};

const TEST_ID_CONTAINER = 'issue-field-classic-story-point.ui.inline-view-field-container';
const TEST_ID = 'issue-field-classic-story-point.ui.inline-view-field';

export const ClassicStoryPointView = ({ isEditable, noValueText, value }: Props) => {
	const { formatNumber } = useIntl();

	const badgeProps = {
		min: Number.MAX_SAFE_INTEGER * -1,
		max: Number.MAX_SAFE_INTEGER,
	};

	if (value === null) {
		return <EmptyValue data-testid={TEST_ID}>{noValueText}</EmptyValue>;
	}

	const BadgeComp = (
		<Badge testId={TEST_ID} {...badgeProps}>
			{formatNumber(value)}
		</Badge>
	);

	if (isEditable) {
		return <HoverContainer data-testid={TEST_ID_CONTAINER}>{BadgeComp}</HoverContainer>;
	}

	return BadgeComp;
};

export default ClassicStoryPointView;
