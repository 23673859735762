import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { useIntl } from '@atlassian/jira-intl';
import type { customerOrganizations_servicedeskCustomerServiceIssueViewPanel_CustomerOrganization$key } from '@atlassian/jira-relay/src/__generated__/customerOrganizations_servicedeskCustomerServiceIssueViewPanel_CustomerOrganization.graphql';
import { useEntitlementFieldValue } from '../../controllers/use-entitlement-field-value';
import { Divider, Header } from '../details';
import { Organization } from '../organization';
import messages from './messages';

export const CUSTOMER_ORGANIZATIONS_DIVIDER_TESTID =
	'servicedesk-customer-service-issue-view-panel.ui.customer.customer-details.customer-organizations.divider';

type Props = {
	organizations: readonly customerOrganizations_servicedeskCustomerServiceIssueViewPanel_CustomerOrganization$key[];
};

type OrganizationProps = {
	organization: customerOrganizations_servicedeskCustomerServiceIssueViewPanel_CustomerOrganization$key;
	isLast: boolean;
};

export const CustomerOrganization = ({ organization, isLast }: OrganizationProps) => {
	const { id, name } = useFragment(
		graphql`
			fragment customerOrganizations_servicedeskCustomerServiceIssueViewPanel_CustomerOrganization on CustomerServiceOrganization {
				id
				name
			}
		`,
		organization,
	);

	return (
		<Organization
			organization={{
				id,
				name,
			}}
			isLast={isLast}
			panelSource="CUSTOMER_ORGANIZATIONS"
		/>
	);
};

export const CustomerOrganizations = ({ organizations }: Props) => {
	const { formatMessage } = useIntl();
	const entitlementFieldValue = useEntitlementFieldValue();

	if (!organizations || organizations.length === 0) {
		if (entitlementFieldValue) {
			return <Divider data-testid={CUSTOMER_ORGANIZATIONS_DIVIDER_TESTID} />;
		}

		return null;
	}

	return (
		<>
			<Divider data-testid={CUSTOMER_ORGANIZATIONS_DIVIDER_TESTID} />
			<Header>{formatMessage(messages.organizationDetailsHeader)}</Header>
			{organizations.map((organization, index) => (
				<CustomerOrganization
					key={`customer-organization-${index}`}
					organization={organization}
					isLast={index === organizations.length - 1}
				/>
			))}
		</>
	);
};
