import type { PriorityValue } from '@atlassian/jira-servicedesk-incident-management-common/src/constants.tsx';
import type {
	ALERT_STATUS,
	RESPONDER,
	MODAL_DISPLAY_STATE,
	CREATED_BY_ME,
	SHARED_WITH_ME,
	PREDEFINED,
} from './constants';

export type SavedSearchCategory = typeof CREATED_BY_ME | typeof SHARED_WITH_ME | typeof PREDEFINED;

export type SavedSearch = {
	id: string;
	name: string;
	description: string | null;
	alertSearchQuery: string;
};

export type AlertId = string;
export const toAlertId = (value: string): AlertId => value;

export type ResponderType =
	| typeof RESPONDER.TEAM
	| typeof RESPONDER.USER
	| typeof RESPONDER.SCHEDULE
	| typeof RESPONDER.ESCALATION;

export type AlertStatus =
	| typeof ALERT_STATUS.OPEN
	| typeof ALERT_STATUS.CLOSED
	| typeof ALERT_STATUS.ACKED
	| typeof ALERT_STATUS.SNOOZED;

export type ModalDisplayState =
	| typeof MODAL_DISPLAY_STATE.DATA
	| typeof MODAL_DISPLAY_STATE.ERROR
	| typeof MODAL_DISPLAY_STATE.EMPTY
	| typeof MODAL_DISPLAY_STATE.NO_INCIDENT
	| typeof MODAL_DISPLAY_STATE.NO_OPSGENIE_ACCOUNT;

export type Responder = {
	type: ResponderType;
	name: string;
	id: string;
	avatarUrl: string | null;
};

export type AcknowledgedBy = {
	atlassianAccountId: string;
	avatarUrl: string | null;
	displayName: string;
	userId: string;
};

export type LinkedAlert = {
	id: AlertId;
	tinyId: string;
	priority: PriorityValue;
	message: string;
	owner: string | null;
	responders: Responder[];
	acknowledgedBy: AcknowledgedBy | null;
	isLinked: boolean;
	status: AlertStatus;
	count: number;
	createdAt: string;
	url: string;
	summary?: 'summary'; // added this extra property to render summary field on alert table.
};

export type SavedSearchByCategory = Record<SavedSearchCategory, SavedSearch[]>;

export type AlertsSelection = {
	selectedAlertIds: AlertId[];
	selectableAlertIds: AlertId[];
};

export type UseAlertsSelectionState = () => [AlertsSelection, (shouldSelectAll: boolean) => void];
