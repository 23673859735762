import { createStore, createActionsHook } from '@atlassian/react-sweet-state';
import { UnarchiveIssueModalActions, type Actions } from './actions';
import { initialState } from './constants';
import type { State } from './types';

export const UnarchiveIssueModalStore = createStore<State, Actions>({
	initialState,
	actions: UnarchiveIssueModalActions,
	name: 'unarchiveIssueModalStore',
});

export const useUnarchiveIssueModalActions = createActionsHook<State, Actions>(
	UnarchiveIssueModalStore,
);
