import React, { type ComponentType, type ReactNode } from 'react';
import { PACKAGE_NAME } from '../consts';
import { toErrorId, toErrorMessage } from '../types';
import { logError } from '../util/error-logger';

export type Props = {
	message: string;
	openingTag: string;
	closingTag: string;
	Component: ComponentType<{
		children: ReactNode;
	}>;
};

/**
 * @deprecated Use src/packages/platform/i18n-tools/formatted-i18n-message instead
 *
 * Formats a message replacing the text enclosed between openingTag and closingTag with a React
 * component that receives that enclosed text as a child node, useful for inline styled components
 * with proper translations and link placements across different languages
 *
 * Usage example:
 * - message: "My {start}link{end} is awesome"
 * - openingTag: "{start}"
 * - closingTag: "{end}"
 * - Component: MyComponent
 *
 * Resulting component:
 * My <MyComponent>link</MyComponent> is awesome
 */
export const FormattedReactMessage = (props: Props) => {
	const { message, openingTag, closingTag, Component } = props;

	const startIndex = message.indexOf(openingTag);
	const endIndex = message.indexOf(closingTag);

	if (startIndex === -1 || endIndex === -1 || startIndex > endIndex) {
		logError(
			PACKAGE_NAME,
			toErrorId('formatReactMessage'),
			toErrorMessage(`Malformed message: "${message}"`),
		);

		// message is malformed, so let's return original message stripped off any tags
		return <>{message.replace(openingTag, '').replace(closingTag, '')}</>;
	}

	const parts = message.split(openingTag);
	if (parts.length === 1) {
		return <>{message.replace(closingTag, '')}</>;
	}

	const before = parts[0];
	const [children, after] = parts[1].split(closingTag);

	return (
		<>
			{before}
			<Component>{children}</Component>
			{after}
		</>
	);
};
