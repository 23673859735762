/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/standard-button';
import Close from '@atlaskit/icon/glyph/editor/close';
import Tooltip from '@atlaskit/tooltip';

import { buttonAutoFocusRingFix } from '../../styles/focus';

import messages from './messages';

type Props = {
	onCancel: () => void;
	tabIndex?: number;
	autoFocus?: boolean;
};

export const CancelIconButton = ({ onCancel, tabIndex, autoFocus }: Props) => {
	const { formatMessage } = useIntl();
	return (
		<Tooltip content={formatMessage(messages.cancelTooltip)} position="top">
			<Button
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css={buttonAutoFocusRingFix}
				aria-label={formatMessage(messages.cancelLabel)}
				testId="cancel-icon-button"
				type="button"
				onClick={onCancel}
				autoFocus={autoFocus}
				iconBefore={<Close label={formatMessage(messages.cancelTooltip)} />}
				appearance="subtle"
				spacing="compact"
				tabIndex={tabIndex}
			></Button>
		</Tooltip>
	);
};
