import { createSelector } from 'reselect';
import set from 'lodash/set';
import memoizeOne from 'memoize-one';
import type { ConnectionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/connection/types';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types';
import type { State, PropertyMaps } from '../../../types';
import { defaultMapping } from '../default';
import type { FieldMapping, GroupIdMap } from '../types';

const connectionMappingInternal = (field: Field): FieldMapping<ConnectionFieldValue[]> => {
	const valueAccessor: FieldMapping<ConnectionFieldValue[]>['valueAccessor'] = (
		state,
		props,
		localIssueId,
	) => state.properties.connection[field.key]?.[localIssueId] || [];

	return {
		...defaultMapping,
		field,
		valueAccessor,
		setMutable: (maps: PropertyMaps, localIssueId: LocalIssueId, value?: ConnectionFieldValue[]) =>
			set(maps.connection, [field.key, localIssueId], value),
		getGroupIdentitiesSelector: (fieldKey, issueIdsSelector) =>
			createSelector(
				issueIdsSelector,
				(state: State) => state.properties.connection[fieldKey],
				(ids, connection) =>
					ids.reduce(
						(result, localIssueId) =>
							Object.assign<GroupIdMap<ConnectionFieldValue[]>, GroupIdMap<ConnectionFieldValue[]>>(
								result,
								{
									[localIssueId]:
										connection?.[localIssueId]?.map((value) => ({
											groupIdentity: value.id,
											value: [value],
										})) || [],
								},
							),
						{},
					),
			),
		allowEmptyGroup: true,
		isMultiValueField: true,
	};
};

export const connectionMapping = memoizeOne(connectionMappingInternal);
