import { useMemo } from 'react';
import {
	useAnalyticsEvents,
	fireTrackAnalytics,
	type ActionSubjectAndAction,
} from '@atlassian/jira-product-analytics-bridge';
import type { AnalyticsAttributesUntyped } from '@atlassian/jira-product-analytics-bridge/src/types';

export const relatedResourcesAnalyticsAttrs = {
	aiFeatureName: 'relatedResources',
	isAIFeature: 1,
	proactiveAIGenerated: 0,
	userGeneratedAI: 1,
} as const;

type Params = {
	channelId?: string | null;
};
export const useRelatedResourcesAnalytics = (params: Params = {}) => {
	const { channelId } = params;
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const actions = useMemo(() => {
		const analyticsEvent = createAnalyticsEvent({});
		// TODO GRAVITYAI-1714 use real value for singleInstrumentationID in follow up PR
		const defaultAttrs = { ...relatedResourcesAnalyticsAttrs, singleInstrumentationID: channelId };
		return {
			analyticsEvent,
			fireTrack: (name: ActionSubjectAndAction, id: string, attrs?: AnalyticsAttributesUntyped) =>
				fireTrackAnalytics(analyticsEvent, name, id, { ...defaultAttrs, ...attrs }),
		};
	}, [channelId, createAnalyticsEvent]);
	return actions;
};
