import React, { useMemo } from 'react';
import memoizeOne from 'memoize-one';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { ForgeScreenEvent } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import UserInlineEditViewWithIntl from '@atlassian/jira-issue-internal-fields/src/user/view';
import type { UserOption, UserOptionValue } from '@atlassian/jira-issue-user-picker/src/types.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages';
import { getIssueModalAkDropdownPortal } from '@atlassian/jira-issue-view-common-utils/src/get-element/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import {
	fieldAutoCompleteUrlSelector,
	isFieldRequiredSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector';
import { isFieldInTabSelector } from '@atlassian/jira-issue-view-store/src/selectors/tab-selector';
import {
	transformFromStateValue,
	transformToStateValue,
} from '../../../../../../assignee/assignee-transformer';
import { useAnalyticsAttributesContext } from '../../../analytics/atrributes-context';
import type { CustomFieldProps } from '../../../types';

export type ReturnType = {
	displayName: string;
	accountId: string;
	avatarUrls: {
		['48x48']: string;
	};
};

type Props = CustomFieldProps<UserOptionValue, ReturnType> & {
	isFieldRequired: boolean;
	isFieldInTab: boolean;
	fetchSuggestions: (query: string, sessionId?: string) => Promise<UserOption[]>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transformSuggestions = (suggestions: any) =>
	suggestions.users ? suggestions.users.map(transformFromStateValue) : [];

const fetchSuggestionsFactory = memoizeOne(
	(autoCompleteUrl): ((query: string, sessionId?: string) => Promise<UserOption[]>) =>
		(query) => {
			if (autoCompleteUrl === null) {
				return Promise.resolve([]);
			}
			return fetchJson(`${autoCompleteUrl}${query}`).then(transformSuggestions);
		},
);

export const getEmptyOption = memoizeOne((intl: IntlShape) => ({
	avatarUrl: '',
	name: intl.formatMessage(genericMessages.noValue),
}));

export const View = ({
	shouldFireScreenEvent,
	isFieldRequired,
	extension,
	type,
	value,
	isFieldInTab,
	...editProps
}: Props) => {
	const intl = useIntl();

	const onChange = (newValue: UserOptionValue) => {
		editProps.onChange(transformToStateValue(newValue));
	};

	const portalElement = isFieldInTab
		? // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			getIssueModalAkDropdownPortal() || document.body || undefined
		: undefined;

	const analyticsAttributes = useAnalyticsAttributesContext();
	const attributes = useMemo(
		() => ({ ...analyticsAttributes, renderMode: 'default' }),
		[analyticsAttributes],
	);

	return (
		<>
			{shouldFireScreenEvent && <ForgeScreenEvent attributes={attributes} />}
			<UserInlineEditViewWithIntl
				{...editProps}
				value={transformFromStateValue(value)}
				emptyOption={isFieldRequired ? undefined : getEmptyOption(intl)}
				// @ts-expect-error - TS2322 - Type 'Element | undefined' is not assignable to type 'HTMLElement | undefined'.
				portalElement={portalElement}
				onChange={onChange}
			/>
		</>
	);
};

export default connect(
	(state, { fieldId }: { fieldId: string }) => ({
		isFieldInTab: isFieldInTabSelector(fieldId)(state),
		fetchSuggestions: fetchSuggestionsFactory(fieldAutoCompleteUrlSelector(fieldId)(state)),
		isFieldRequired: isFieldRequiredSelector(fieldId)(state),
	}),
	{},
)(View);
