import { SafePlugin } from '@atlaskit/editor-common/safe-plugin';
import type { ExtractInjectionAPI, PMPluginFactoryParams } from '@atlaskit/editor-common/types';
import { browser, isEmptyDocument, isParagraphNode } from '@atlaskit/editor-common/utils';
import type { EditorState } from '@atlaskit/editor-prosemirror/state';
import { Decoration, DecorationSet } from '@atlaskit/editor-prosemirror/view';

import type { NextEditorPluginAI } from '../../editor-plugin-ai';

import { aiPlaceholderPluginKey } from './ai-placeholder-plugin-key';
import messages from './messages';

// Copied from packages/editor/editor-plugin-placeholder/src/plugin.ts
interface PlaceholderState {
	hasPlaceholder: boolean;
	placeholderText?: string;
	pos?: number;
}

export const emptyPlaceholder: PlaceholderState = { hasPlaceholder: false };

const placeholderTestId = 'ai-placeholder-test-id';

function getPlaceholderState(editorState: EditorState): PlaceholderState {
	return aiPlaceholderPluginKey.getState(editorState);
}
export function createPlaceholderDecoration(
	editorState: EditorState,
	placeholderText: string,
	pos: number = 1,
): DecorationSet {
	const placeholderDecoration = document.createElement('span');
	let placeholderNodeWithText = placeholderDecoration;

	placeholderDecoration.setAttribute('data-testid', placeholderTestId);
	placeholderDecoration.setAttribute('aria-hidden', 'true');
	placeholderDecoration.className = 'placeholder-decoration';

	// PM sets contenteditable to false on Decorations so Firefox doesn't display the flashing cursor
	// So adding an extra span which will contain the placeholder text
	if (browser.gecko) {
		const placeholderNode = document.createElement('span');
		placeholderNode.setAttribute('contenteditable', 'true'); // explicitly overriding the default Decoration behaviour
		placeholderDecoration.appendChild(placeholderNode);
		placeholderNodeWithText = placeholderNode;
	}

	placeholderNodeWithText.textContent = placeholderText || ' ';

	// ME-2289 Tapping on backspace in empty editor hides and displays the keyboard
	// Add a editable buff node as the cursor moving forward is inevitable
	// when backspace in GBoard composition
	if (browser.android && browser.chrome) {
		const buffNode = document.createElement('span');
		buffNode.setAttribute('class', 'placeholder-android');
		buffNode.setAttribute('contenteditable', 'true');
		buffNode.textContent = ' ';
		placeholderDecoration.appendChild(buffNode);
	}

	return DecorationSet.create(editorState.doc, [
		Decoration.widget(pos, placeholderDecoration, {
			side: 0,
			key: 'placeholder',
		}),
	]);
}

export function createPlaceholderStateFrom(
	placeholder: string,
	editorState: EditorState,
	removePlaceholder: boolean,
): PlaceholderState {
	if (
		!removePlaceholder &&
		isCursorAtBeginningOfEmptyParagraph(editorState) &&
		editorState.selection.$from.depth === 1 &&
		!isEmptyDocument(editorState.doc) &&
		isParagraphNode(editorState.selection.$from.node())
	) {
		return setPlaceholderState(placeholder, editorState.selection.$from.pos);
	}

	return emptyPlaceholder;
}

function setPlaceholderState(placeholderText: string, pos?: number): PlaceholderState {
	return {
		hasPlaceholder: true,
		placeholderText,
		pos: pos ? pos : 1,
	};
}

export function isCursorAtBeginningOfEmptyParagraph(state: EditorState) {
	const { $from, empty } = state.selection;
	return empty && $from.parentOffset === 0 && $from.parent.content.size === 0;
}

function shouldRemovePlaceholder(
	state: EditorState,
	api: ExtractInjectionAPI<typeof NextEditorPluginAI> | undefined,
) {
	// check if editorViewMode plugin exists, and if it's in view mode
	const isViewMode =
		api?.editorViewMode && api?.editorViewMode?.sharedState?.currentState()?.mode === 'view';

	// check if api is available and if typeAhead plugin exists
	const isTypeAheadOpen = api?.typeAhead?.actions?.isOpen(state);

	const placeholderState = getPlaceholderState(state);

	return placeholderState?.hasPlaceholder && (isViewMode || isTypeAheadOpen);
}

/**
 * @deprecated This method is deprecated and should be cleaned up once all products using AI
 * are on composable editor.
 */
function deprecatedShouldRemovePlaceholder(state: EditorState) {
	const editorViewModePlugin = state.plugins.find(
		(plugin) => (plugin as any).key === 'editorViewMode$',
	);

	const editorViewModePluginState = editorViewModePlugin?.getState(state);

	// check if api is available and if editorViewMode plugin exists
	const isViewMode = editorViewModePluginState && editorViewModePluginState?.mode === 'view';

	// check if typeahead is open -> dispatch a tr that removes the placeholder
	const typeAheadPlugin = state.plugins.find(
		(plugin) => (plugin as any).key === 'typeAheadPlugin$',
	);

	// check if api is available and if typeAhead plugin exists
	const isTypeAheadOpen =
		typeAheadPlugin && typeAheadPlugin.getState(state)?.decorationSet?.find().length > 0;

	const placeholderState = getPlaceholderState(state);

	return placeholderState?.hasPlaceholder && (isViewMode || isTypeAheadOpen);
}

export function createAIPlaceholderPlugin({
	getIntl,
	api,
}: {
	getIntl: PMPluginFactoryParams['getIntl'];
	api: ExtractInjectionAPI<typeof NextEditorPluginAI> | undefined;
}) {
	const persistentPlaceholderText = getIntl().formatMessage(messages.persistentPlaceholderText);
	return new SafePlugin({
		key: aiPlaceholderPluginKey,
		state: {
			init: (_, state) => {
				return createPlaceholderStateFrom(
					persistentPlaceholderText,
					state,
					api ? shouldRemovePlaceholder(state, api) : deprecatedShouldRemovePlaceholder(state),
				);
			},
			apply: (tr, _pluginState, _oldEditorState, newEditorState) => {
				return createPlaceholderStateFrom(
					persistentPlaceholderText,
					newEditorState,
					Boolean(tr.getMeta(aiPlaceholderPluginKey)?.aiPlaceholderRemove),
				);
			},
		},
		appendTransaction: (_transactions, _oldState, newState) => {
			if (
				api ? shouldRemovePlaceholder(newState, api) : deprecatedShouldRemovePlaceholder(newState)
			) {
				return newState.tr.setMeta(aiPlaceholderPluginKey, {
					aiPlaceholderRemove: true,
				});
			}
			return;
		},
		props: {
			decorations(editorState): DecorationSet | undefined {
				const placeholderState = getPlaceholderState(editorState);

				if (
					placeholderState.hasPlaceholder &&
					placeholderState.placeholderText &&
					placeholderState.pos !== undefined
				) {
					return createPlaceholderDecoration(
						editorState,
						placeholderState.placeholderText,
						placeholderState.pos,
					);
				}
				return;
			},
		},
	});
}
