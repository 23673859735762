import { createSelector } from 'reselect';
import { isMobileSelector } from '../common/state/selectors/context-selector';
import {
	errorSelector,
	isSimplifiedProjectSelector,
	isClassicProjectSelector,
	isServiceDeskSelector,
} from '../common/state/selectors/issue-selector';
import {
	canAdministerJiraPermissionsSelector,
	canAdministerProjectPermissionsSelector,
} from '../common/state/selectors/permissions-selector';
import { shouldShowChangeboardingSelector } from './changeboarding-selector';
import { isSubtaskTypesPresentSelector } from './subtask-types-selector';
import { hasSubtasksSelector, isSubtaskSelector } from './subtasks-selector';

const canAdministerSimplifiedIssueTypeSelector = createSelector(
	canAdministerProjectPermissionsSelector,
	canAdministerJiraPermissionsSelector,
	(isProjectAdmin, isJiraAdmin) => isProjectAdmin || isJiraAdmin,
);

export const shouldShowSwiftLinkSelector = createSelector(
	isSimplifiedProjectSelector,
	canAdministerSimplifiedIssueTypeSelector,
	(isSimplified, isAdmin) => isAdmin && isSimplified,
);

export const shouldShowActionsSelector = createSelector(
	errorSelector,
	isMobileSelector,
	(error, isMobile) => !error && !isMobile,
);

export const CONFIG_LINK_MODE_SHOW = 'show' as const;
export const CONFIG_LINK_MODE_HIDE = 'hide' as const;
export const CONFIG_LINK_MODE_DISABLED_PERMISSION = 'disabled_permission' as const;
export const CONFIG_LINK_MODE_DISABLED_NO_REQUEST_TYPE = 'disabled_no_request_type' as const;
export const CONFIG_LINK_MODE_DISABLED_NO_SCREEN_ID = 'disabled_no_screen_id' as const;

export type ConfigLinkMode =
	| typeof CONFIG_LINK_MODE_SHOW
	| typeof CONFIG_LINK_MODE_HIDE
	| typeof CONFIG_LINK_MODE_DISABLED_PERMISSION
	| typeof CONFIG_LINK_MODE_DISABLED_NO_REQUEST_TYPE
	| typeof CONFIG_LINK_MODE_DISABLED_NO_SCREEN_ID;

export const canConvertToSubtaskSelector = createSelector(
	isSubtaskSelector,
	hasSubtasksSelector,
	isSubtaskTypesPresentSelector,
	isClassicProjectSelector,
	(isSubtask, hasSubtasks, hasSubtaskTypes, isClassic) =>
		!isSubtask && !hasSubtasks && hasSubtaskTypes && isClassic,
);

export const canConvertToIssueSelector = createSelector(
	isSubtaskSelector,
	isClassicProjectSelector,
	(isSubtask, isClassic) => isSubtask && isClassic,
);

export const shouldShowFindOutMoreSelector = createSelector(
	isServiceDeskSelector,
	shouldShowChangeboardingSelector,
	(isServiceDeskProject, shouldShowChangeboarding) =>
		isServiceDeskProject || shouldShowChangeboarding,
);
