import React, { useCallback, useState, useMemo } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';
import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';
import SentimentEditViewEntryPoint from '@atlassian/jira-issue-field-sentiment-editview-full/src/entrypoint';
import type {
	NullableOption,
	SentimentEditViewProps,
} from '@atlassian/jira-issue-field-sentiment-editview-full/src/ui/sentiment/types.tsx';
import { SentimentReadView } from '@atlassian/jira-issue-field-sentiment-readview-full/src/ui/sentiment/index.tsx';
import type { sentiment_issueFieldSentimentInlineEditFull_SentimentInlineEditView$key as SentimentFragment } from '@atlassian/jira-relay/src/__generated__/sentiment_issueFieldSentimentInlineEditFull_SentimentInlineEditView.graphql';
import type { sentiment_useUpdateIssueViewSentimentField_Mutation as SentimentMutation } from '@atlassian/jira-relay/src/__generated__/sentiment_useUpdateIssueViewSentimentField_Mutation.graphql';
import type { SentimentInlineEditViewWithIsEditableProps } from './types';

const isEqualSentiment = (a: NullableOption, b: NullableOption) => a?.value === b?.value;

export const SentimentInlineEditView = ({
	fragmentKey,
	isEditable,
	editViewPopupAlignBlock,
	menuPosition,
	spacing = 'compact',
	attributes,
	readViewFitContainerHeight,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
}: SentimentInlineEditViewWithIsEditableProps) => {
	const data = useFragment<SentimentFragment>(
		graphql`
			fragment sentiment_issueFieldSentimentInlineEditFull_SentimentInlineEditView on JiraServiceManagementSentimentField {
				...sentiment_issueFieldSentimentReadviewFull_SentimentReadView
				id
				fieldId
				type
				name
				sentiment {
					sentimentId
					name
				}
			}
		`,
		fragmentKey,
	);

	const [commit] = useMutation<SentimentMutation>(graphql`
		mutation sentiment_useUpdateIssueViewSentimentField_Mutation(
			$input: JiraServiceManagementUpdateSentimentFieldInput!
		) {
			jira {
				updateSentimentField(input: $input) @optIn(to: "JiraIssueFieldMutations") {
					success
					errors {
						message
					}
					field {
						id
						sentiment {
							sentimentId
							name
						}
					}
				}
			}
		}
	`);

	const { id: ariId, fieldId, sentiment, name: fieldName, type: fieldType } = data;
	const initialValue = useMemo(
		() =>
			sentiment
				? {
						label: sentiment.name ?? '',
						value: sentiment.sentimentId ?? '',
					}
				: null,
		[sentiment],
	);
	const [updatedValue, setUpdatedValue] = useState<NullableOption>(initialValue);

	const handleSubmit = useCallback(
		(option: NullableOption, { onSuccess, onFail }: OnSubmitCallbacks) => {
			onSubmit?.(option);
			if (option?.value != null) {
				commit({
					variables: {
						input: {
							id: ariId,
							operation: {
								operation: 'SET',
								sentimentId: option?.value,
							},
						},
					},
					onCompleted: (mutationData) => {
						if (mutationData.jira?.updateSentimentField?.success) {
							onSuccess();
						} else {
							onFail();
						}
					},
					onError(error) {
						onFail(error);
					},
					optimisticResponse: {
						jira: {
							updateSentimentField: {
								success: true,
								errors: null,
								field: {
									id: ariId,
									sentiment: {
										sentimentId: option.value,
										name: option.label,
									},
								},
							},
						},
					},
				});
			}
		},
		[onSubmit, commit, ariId],
	);

	const {
		hasServerValidationError,
		handleCancel,
		handleEdit,
		handleConfirm,
		handleChangeAndConfirm,
		invalidMessage,
		isEditing,
	} = useFieldInlineEditActions({
		attributes,
		fieldId,
		fieldName,
		fieldType,
		initialValue,
		isValueEqual: isEqualSentiment,
		onSubmit: handleSubmit,
		onSubmitFailed,
		onSubmitSucceeded,
		onUpdateValue: setUpdatedValue,
		updatedValue,
	});

	const renderReadView = useCallback(() => <SentimentReadView fragmentKey={data} />, [data]);

	const getEditViewProps = (fieldProps: ValidationFieldProps): SentimentEditViewProps => ({
		...fieldProps,
		spacing,
		fieldId: ariId,
		fieldName,
		autoFocus: true,
		value: updatedValue,
		onChange: handleChangeAndConfirm,
		openMenuOnFocus: true,
		menuPosition,
	});
	return (
		<Box xcss={inlineEditContainerStyledWrapper}>
			<FieldInlineEditLiteWithEntryPoint
				editViewPopupAlignBlock={editViewPopupAlignBlock}
				editViewPopupMinWidth="small"
				editViewEntryPoint={SentimentEditViewEntryPoint}
				editViewEntryPointParams={{}}
				getEditViewProps={getEditViewProps}
				fieldName={fieldName}
				hasUnsubmittedChanges={hasServerValidationError}
				invalidMessage={invalidMessage}
				isEditing={isEditing}
				isEditable={isEditable}
				onCancel={handleCancel}
				onConfirm={handleConfirm}
				onEdit={handleEdit}
				readViewFitContainerHeight={readViewFitContainerHeight}
				readView={renderReadView}
				hideActionButtons
			/>
		</Box>
	);
};

const inlineEditContainerStyledWrapper = xcss({
	width: '100%',
	marginLeft: 'space.negative.100',
});
