import { ff } from '@atlassian/jira-feature-flagging';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { emptyIssueData } from '../../common/constants';
import useIssueViewNonCriticalData from '../use-issue-view-non-critical-data';

export const useDeletableAttachmentsCount = (issueKey: IssueKey) => {
	const [issueState] = useIssueViewNonCriticalData(issueKey);
	const { value: issueData = emptyIssueData, isReady = false } = issueState ?? {};
	const { deletableAttachmentsCount: value = undefined } = issueData;

	return [{ value, isReady }] as const;
};

const defaultValue = [
	{
		value: undefined,
		isReady: true,
	},
] as const;

export default functionWithCondition(
	() => ff('issue.details.gira-agg-migration-attachments_ecebe'),
	useDeletableAttachmentsCount,
	() => defaultValue,
);
