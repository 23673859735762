import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { Dispatch } from 'redux';
import debounce from 'lodash/debounce';
import flow from 'lodash/flow';
import { Card, CardLoading, DateOverrideContext } from '@atlaskit/media-card';
import type { FileIdentifier } from '@atlaskit/media-client';
import { FilmstripView } from '@atlaskit/media-filmstrip';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { useCanDeleteAttachment } from '@atlassian/jira-issue-attachments-base/src/controllers/index.tsx';
import { useIssueAttachments } from '@atlassian/jira-issue-attachments-base/src/services/attachments-service/main.tsx';
import withIssueAttachmentsDelete from '@atlassian/jira-issue-attachments-base/src/ui/with-issue-attachments-delete/main.tsx';
import AttachmentsError from '@atlassian/jira-issue-attachments-table/src/ui/error/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import withViewMediaClientConfig from '@atlassian/jira-issue-media-provider/src/controllers/view-media-client-config/index.tsx';
import type { Attachment } from '@atlassian/jira-issue-shared-types/src/common/types/attachments';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { safeComponent } from '@atlassian/jira-issue-view-common-utils/src/safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import {
	cardCoverUpdateRequest,
	type CardCoverUpdateRequestAction,
} from '@atlassian/jira-issue-view-store/src/actions/card-cover-actions';
import {
	scrollToAttachmentComment,
	type ScrollToAttachmentCommentAction,
	scrollToAttachmentWorklog,
	type ScrollToAttachmentWorklogAction,
} from '@atlassian/jira-issue-view-store/src/actions/issue-scroll-actions';
import {
	fetchUploadContextRequest,
	type FetchUploadContextRequestAction,
} from '@atlassian/jira-issue-view-store/src/common/media/upload-context/upload-context-actions';
import {
	fetchViewContextRequest,
	type FetchViewContextRequest,
} from '@atlassian/jira-issue-view-store/src/common/media/view-context/view-context-actions';
import { isMobileSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { isPreviewSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import { mediaContextSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/media-context-selector';
import {
	cardCoverSelector,
	isCardCoverEnabledSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/card-cover-selectors';
import { mediaFeatureFlags } from '../../../media-feature-flags';
import type { CardOffset, DispatchProps, Props } from './types';
import { getActionsForAttachment } from './utils';

type Action =
	| CardCoverUpdateRequestAction
	| ScrollToAttachmentWorklogAction
	| ScrollToAttachmentCommentAction
	| FetchViewContextRequest
	| FetchUploadContextRequestAction;

// defaultImageCardDimensions are copied from @atlaskit/media-card as flow doesn't recognise the export
const defaultImageCardDimensions = {
	width: 156,
	height: 125,
} as const;
const DEBOUNCE_DURATION = 300;

const fakeCard = (key: string) => <CardLoading key={key} dimensions={defaultImageCardDimensions} />;

export const AttachmentFilmstrip = (props: Props) => {
	const intl = useIntl();
	const [filmstripOffset, setFilmstripOffset] = useState<number>(0);
	const [filmstripStartAt, setFilmstripStartAt] = useState<number>(0);
	const [cardOffsets, setCardOffsets] = useState<CardOffset[]>([]);
	const [animate, setAnimate] = useState<boolean>(false);

	const {
		cardCover,
		isMobile,
		isPreview,
		mediaContext,
		onCardCoverHide,
		onCardCoverShow,
		onScrollToComments,
		onScrollToWorklogs,
		viewMediaClientConfig,
		onDeleteAttachment,
		canDeleteAttachmentMap,
	} = props;
	const issueKey = useIssueKey();
	const [
		{
			value: { attachments, totalCount },
			meta: { startAt: attachmentStartAt },
			error,
		},
		{ refreshAttachments },
	] = useIssueAttachments(issueKey);
	// @ts-expect-error - TS7031 - Binding element 'newOffset' implicitly has an 'any' type. | TS7031 - Binding element 'offsets' implicitly has an 'any' type.
	const handleSizeChange = useCallback(({ offset: newOffset, offsets }) => {
		setCardOffsets(offsets);
		setFilmstripOffset(newOffset);
	}, []);
	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedRefreshAttachments = useCallback(debounce(refreshAttachments, DEBOUNCE_DURATION), [
		refreshAttachments,
	]);

	useEffect(() => {
		if (attachmentStartAt === 0) {
			setFilmstripOffset(0);
		}
	}, [attachmentStartAt]);

	const handleScrollChange = useCallback(
		// @ts-expect-error - TS7031 - Binding element 'newAnimate' implicitly has an 'any' type. | TS7031 - Binding element 'newOffset' implicitly has an 'any' type.
		({ animate: newAnimate, offset: newOffset }) => {
			const newStartAt = cardOffsets.findIndex((cardOffset) => cardOffset.left > newOffset) - 1;
			setAnimate(newAnimate);
			setFilmstripStartAt(newStartAt);
			setFilmstripOffset(newOffset);
			debouncedRefreshAttachments(issueKey, { startAt: newStartAt });
		},
		[cardOffsets, debouncedRefreshAttachments, issueKey],
	);
	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const attachmentsArray = attachments || [];
	const canDeleteAttachmentMapModal = useCanDeleteAttachment();
	const items = useMemo(
		() =>
			attachmentsArray.map((attachment: Attachment) => {
				const identifier = {
					mediaItemType: 'file',
					id: attachment.mediaApiFileId,
					attachmentId: attachment.id,
					...(mediaContext.viewContext && mediaContext.viewContext.collection !== null
						? { collectionName: mediaContext.viewContext.collection }
						: {}),
				};

				const actions = getActionsForAttachment(
					attachment.id,
					attachment.parentName,
					attachment.parentId,
					cardCover,
					fg('convert_with_issue_attachments_delete_modal')
						? canDeleteAttachmentMapModal[attachment.id]
						: canDeleteAttachmentMap[attachment.id],
					intl,
					issueKey,
					onDeleteAttachment,
					onCardCoverShow,
					onCardCoverHide,
					onScrollToComments,
					onScrollToWorklogs,
				);

				return {
					identifier,
					selectable: false,
					actions,
					isLazy: !isMobile,
					hasRestrictedParent: attachment.hasRestrictedParent,
				};
			}),
		[
			attachmentsArray,
			canDeleteAttachmentMap,
			cardCover,
			intl,
			isMobile,
			issueKey,
			mediaContext.viewContext,
			onCardCoverHide,
			onCardCoverShow,
			onDeleteAttachment,
			onScrollToComments,
			onScrollToWorklogs,
			canDeleteAttachmentMapModal,
		],
	);

	const getCards = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'visibleItems' implicitly has an 'any' type.
		(visibleItems) => {
			const attachmentIdentifiers: FileIdentifier[] = visibleItems.map(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(item: { identifier: any }) => item.identifier,
			);
			// @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type. | TS7006 - Parameter 'index' implicitly has an 'any' type.
			const visibleCards = visibleItems.map((item, index) => {
				if (isPreview || error) {
					return fakeCard(`card-${index}`);
				}

				const isInternalAttachment = item.hasRestrictedParent;

				return (
					<Card
						key={item.identifier.id}
						mediaClientConfig={viewMediaClientConfig}
						mediaViewerItems={attachmentIdentifiers}
						useInlinePlayer={false}
						dimensions={defaultImageCardDimensions}
						shouldOpenMediaViewer
						featureFlags={mediaFeatureFlags()}
						titleBoxBgColor={
							isInternalAttachment
								? // TODO: https://product-fabric.atlassian.net/browse/DSP-8582
									token('color.background.accent.purple.subtle', colors.Y75)
								: undefined
						}
						titleBoxIcon={isInternalAttachment ? 'LockFilledIcon' : undefined}
						shouldEnableDownloadButton
						{...item}
						testId={`issue.views.issue-base.content.attachment.filmstrip-view.attachment-id.${item.identifier.attachmentId}`}
					/>
				);
			});
			const endFakeCardsCount = totalCount - visibleCards.length - attachmentStartAt;

			const beginningFakeCards = Array.from({ length: attachmentStartAt }).map((_, index) =>
				fakeCard(`card-${index}`),
			);

			const endFakeCards = Array.from({ length: endFakeCardsCount }).map((_, index) =>
				fakeCard(`card-${totalCount - endFakeCardsCount + index}`),
			);
			return [...beginningFakeCards, ...visibleCards, ...endFakeCards] as const;
		},
		[attachmentStartAt, error, isPreview, totalCount, viewMediaClientConfig], // only recalculate the cards when items changes
	);

	const onClick = useCallback(() => {
		refreshAttachments(issueKey, {
			startAt: filmstripStartAt,
		});
	}, [filmstripStartAt, issueKey, refreshAttachments]);

	const overrideCreationDate = useMemo(() => {
		const overrides: Record<string, number> = {};

		attachments.forEach((attachment) => {
			overrides[attachment.mediaApiFileId] = attachment.createdDate * 1000;
		});

		return overrides;
	}, [attachments]);

	if (error) {
		return <AttachmentsError shouldHideImage onClick={onClick} />;
	}

	return (
		<div
			// eslint-disable-next-line jira/integration/test-id-by-folder-structure
			data-testid="issue.views.issue-base.content.attachment.filmstrip-panel"
		>
			<DateOverrideContext.Provider value={overrideCreationDate}>
				<FilmstripView
					offset={filmstripOffset}
					animate={animate}
					onSize={handleSizeChange}
					onScroll={handleScrollChange}
					// @ts-expect-error - TS2322 - Type '{ children: readonly any[]; offset: number; animate: boolean; onSize: ({ offset: newOffset, offsets }: any) => void; onScroll: ({ animate: newAnimate, offset: newOffset }: any) => void; mediaClientConfig: MediaClientConfig; featureFlags: { newCardExperience: boolean; folderUploads: boolean; mediaInline: boolean; med...' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<FilmstripView> & Pick<Readonly<FilmstripViewProps> & Readonly<...>, never> & Partial<...> & Partial<...>'.
					mediaClientConfig={viewMediaClientConfig}
					featureFlags={mediaFeatureFlags()}
					testId="issue-view-base.content.attachment.filmstrip-view"
				>
					{getCards(items)}
				</FilmstripView>
			</DateOverrideContext.Provider>
		</div>
	);
};

const AttachmentFilmstripOld = flow(
	withIssueAttachmentsDelete,
	withViewMediaClientConfig,
	connect(
		(state: State) => ({
			mediaContext: mediaContextSelector(state),
			isMobile: isMobileSelector(state),
			cardCover: isCardCoverEnabledSelector(state) ? cardCoverSelector(state) : null,
			isPreview: isPreviewSelector(state),
		}),
		(dispatch: Dispatch<Action>): DispatchProps => ({
			onCardCoverShow: (currentAttachmentId, newAttachmentId) => {
				dispatch(cardCoverUpdateRequest(currentAttachmentId, newAttachmentId));
			},
			onCardCoverHide: (currentAttachmentId) => {
				dispatch(cardCoverUpdateRequest(currentAttachmentId));
			},
			onScrollToComments: () => {
				dispatch(scrollToAttachmentComment());
			},
			onScrollToWorklogs: () => {
				dispatch(scrollToAttachmentWorklog());
			},

			onViewRefresh: () => {
				dispatch(fetchViewContextRequest());
			},
			onUploadRefresh: () => {
				dispatch(fetchUploadContextRequest());
			},
		}),
	),
	safeComponent(),
)(AttachmentFilmstrip);

const AttachmentFilmstripNew = flow(
	withViewMediaClientConfig,
	connect(
		(state: State) => ({
			mediaContext: mediaContextSelector(state),
			isMobile: isMobileSelector(state),
			cardCover: isCardCoverEnabledSelector(state) ? cardCoverSelector(state) : null,
			isPreview: isPreviewSelector(state),
		}),
		(dispatch: Dispatch<Action>): DispatchProps => ({
			onCardCoverShow: (currentAttachmentId, newAttachmentId) => {
				dispatch(cardCoverUpdateRequest(currentAttachmentId, newAttachmentId));
			},
			onCardCoverHide: (currentAttachmentId) => {
				dispatch(cardCoverUpdateRequest(currentAttachmentId));
			},
			onScrollToComments: () => {
				dispatch(scrollToAttachmentComment());
			},
			onScrollToWorklogs: () => {
				dispatch(scrollToAttachmentWorklog());
			},

			onViewRefresh: () => {
				dispatch(fetchViewContextRequest());
			},
			onUploadRefresh: () => {
				dispatch(fetchUploadContextRequest());
			},
		}),
	),
	safeComponent(),
)(AttachmentFilmstrip);

export default componentWithFG(
	'convert_with_issue_attachments_delete_modal',
	AttachmentFilmstripNew,
	AttachmentFilmstripOld,
);
