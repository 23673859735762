import memoizeOne from 'memoize-one';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { transformFromStateValue } from '../assignee/assignee-transformer';

const isMatchingDefaultOption = (query?: string, option?: string) =>
	!!query && !!option && option.toLowerCase().startsWith(query.toLowerCase());

export const getEmptyOption = memoizeOne((anonymousUser) => ({
	avatarUrl: '',
	name: anonymousUser,
	id: null,
}));

export const getEmptyOptionArray = memoizeOne((anonymousUser: string) => [
	getEmptyOption(anonymousUser),
]);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default memoizeOne((autoCompleteUrl, anonymousUser, isRequired) => async (query: any) => {
	if (!autoCompleteUrl) {
		return [];
	}

	const defaultOptions =
		!isRequired && isMatchingDefaultOption(query, anonymousUser)
			? [getEmptyOption(anonymousUser)]
			: [];

	const response = await fetchJson(`${autoCompleteUrl}${query}`);

	const fetchedOptions = response.map(transformFromStateValue);

	return [...defaultOptions, ...fetchedOptions] as const;
});
