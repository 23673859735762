import {
	BAD_REQUEST,
	FORBIDDEN,
	NOT_FOUND,
	PROXY_AUTHENTICATION_REQUIRED,
	REQUEST_TIMEOUT,
	TOO_MANY_REQUESTS,
	UNAUTHORIZED,
	UPGRADE_REQUIRED,
} from '@atlassian/jira-common-constants/src/http-status-codes';
import { fg } from '@atlassian/jira-feature-gating';
import type { ValidationError } from '@atlassian/jira-fetch/src/utils/errors';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import {
	isClientFetchError,
	isHttpClientErrorResponse,
} from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import type { IssueErrorAttributes } from '@atlassian/jira-issue-shared-types/src/common/types/error-type';
import {
	AUTHENTICATION_ERROR,
	CONNECTIVITY_ERROR,
	type IssueError,
	NOT_FOUND_OR_NO_PERMISSION_ERROR,
	UNKNOWN_ERROR,
} from '@atlassian/jira-issue-shared-types/src/common/types/error-type.tsx';
import {
	ATTACHMENTS_PER_ISSUE_LIMIT_EXCEEDED_ERROR_MESSAGE,
	COMMENTS_PER_ISSUE_LIMIT_EXCEEDED_ERROR_MESSAGE,
	ISSUE_LIMIT_FIELDS_ERROR_MESSAGE_LIST,
	REMOTE_ISSUE_LINKS_PER_ISSUE_LIMIT_EXCEEDED_ERROR_MESSAGE,
	WORKLOGS_PER_ISSUE_LIMIT_EXCEEDED_ERROR_MESSAGE,
} from '@atlassian/jira-issue-view-common-constants/src/errors.tsx';
import {
	COMMENTS_PER_ISSUE_LIMIT_EXCEEDED,
	ISSUE_ATTACHMENTS_PER_ISSUE_LIMIT_EXCEEDED,
	ISSUE_REMOTE_LINKS_PER_ISSUE_LIMIT_EXCEEDED,
	WORKLOGS_PER_ISSUE_LIMIT_EXCEEDED,
} from '@atlassian/jira-issue-view-common-constants/src/flags.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { IssueViewFetchError } from './issue-view-fetch-error';

const isIssueNetworkError = (error: Error): error is FetchError | IssueViewFetchError =>
	isClientFetchError(error) ||
	isHttpClientErrorResponse(error) ||
	error instanceof FetchError ||
	error instanceof IssueViewFetchError;

export const errorTypes = new Set([
	AUTHENTICATION_ERROR,
	CONNECTIVITY_ERROR,
	NOT_FOUND_OR_NO_PERMISSION_ERROR,
	UNKNOWN_ERROR,
]);

// Those status codes should NOT be sent as TaskFailed
export const statusCodesNotImpactingReliability = [
	BAD_REQUEST,
	PROXY_AUTHENTICATION_REQUIRED,
	REQUEST_TIMEOUT,
	UPGRADE_REQUIRED,
	TOO_MANY_REQUESTS,
];

const isPreCalculatedErrorType = (
	error: Error,
): error is Error & { message: IssueError; errorAttributes: IssueErrorAttributes } =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	errorTypes.has(error.message as IssueError);

export const getIssueErrorAttributes = (
	error: Error,
	accountIdLoggedInUser: AccountId | null,
): { errorType: IssueError; errorAttributes: IssueErrorAttributes } => {
	// TODO Cleanup with JIV-14818
	if (isPreCalculatedErrorType(error)) {
		return { errorType: error.message, errorAttributes: error.errorAttributes };
	}

	const errorType: IssueError = UNKNOWN_ERROR;
	const errorAttributes: IssueErrorAttributes = { errorMessage: error.message };

	if (isIssueNetworkError(error)) {
		const { statusCode, traceId } = error;
		Object.assign(errorAttributes, { statusCode, traceId });

		if (!accountIdLoggedInUser || statusCode === UNAUTHORIZED) {
			return { errorType: AUTHENTICATION_ERROR, errorAttributes };
		}

		if (statusCode === NOT_FOUND || statusCode === FORBIDDEN) {
			return { errorType: NOT_FOUND_OR_NO_PERMISSION_ERROR, errorAttributes };
		}

		// Relay Error - could be mainIssueAggQuery
		if (error instanceof IssueViewFetchError && error?.getIsRelayError()) {
			if (
				isClientFetchError(error) ||
				(statusCode && statusCodesNotImpactingReliability.includes(statusCode))
			) {
				return { errorType: CONNECTIVITY_ERROR, errorAttributes };
			}

			fg('issue-view-add-userexperience-attribute-taskfail')
				? Object.assign(errorAttributes, {
						userExperience: error.userExperience || 'unknownExperience',
					})
				: {};

			fg('issue-view-added-endpoint-attribute-in-taskfail')
				? Object.assign(errorAttributes, {
						errorMessage: `${error.message}, endpoint: ${error.endpoint || 'unknown'}`,
						endpoint: error.endpoint || 'unknown',
					})
				: {};

			// If the statusCode is undefine for Relay Error we want to set it as UNKNOWN_ERROR to (taskFail)
			return { errorType: UNKNOWN_ERROR, errorAttributes };
		}

		// Return CONNECTIVITY_ERROR if the statusCode is undefined for un-relay error
		if (!statusCode || statusCodesNotImpactingReliability.includes(statusCode)) {
			return { errorType: CONNECTIVITY_ERROR, errorAttributes };
		}
	}

	return { errorType, errorAttributes };
};

export const loginViaRedirectUrl = async (loginRedirectUrl: string | undefined) => {
	if (!loginRedirectUrl) {
		return;
	}
	const encodedRedirectUrl = encodeURIComponent(loginRedirectUrl);
	const logInUrl = `/login.jsp?os_destination=${encodedRedirectUrl}`;

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.open(logInUrl, '_blank');
};

export const getIssueLimitErrorField = (error: ValidationError) => {
	const errorMessagesList: string[] = error.message.split('; ');

	if (errorMessagesList.length === 0) {
		return undefined;
	}

	const fieldLimitErrorList = ISSUE_LIMIT_FIELDS_ERROR_MESSAGE_LIST.filter(
		(fieldLimitErrorMessage) =>
			errorMessagesList.some((message) => message.includes(fieldLimitErrorMessage)),
	);

	// we prioritise comments/worklogs, if there are limits for both comments/worklogs and attachments.
	if (fieldLimitErrorList.includes(COMMENTS_PER_ISSUE_LIMIT_EXCEEDED_ERROR_MESSAGE)) {
		return COMMENTS_PER_ISSUE_LIMIT_EXCEEDED;
	}

	if (fieldLimitErrorList.includes(WORKLOGS_PER_ISSUE_LIMIT_EXCEEDED_ERROR_MESSAGE)) {
		return WORKLOGS_PER_ISSUE_LIMIT_EXCEEDED;
	}

	if (fieldLimitErrorList.includes(ATTACHMENTS_PER_ISSUE_LIMIT_EXCEEDED_ERROR_MESSAGE)) {
		return ISSUE_ATTACHMENTS_PER_ISSUE_LIMIT_EXCEEDED;
	}

	if (fieldLimitErrorList.includes(REMOTE_ISSUE_LINKS_PER_ISSUE_LIMIT_EXCEEDED_ERROR_MESSAGE)) {
		return ISSUE_REMOTE_LINKS_PER_ISSUE_LIMIT_EXCEEDED;
	}

	return undefined;
};
