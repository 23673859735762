import { useCallback } from 'react';
import { graphql, useMutation } from 'react-relay';
import { JiraIssuefieldvalueAri } from '@atlassian/ari/jira/issuefieldvalue';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { useSaveCascadeSelectField_Mutation as CascadingSelectMutation } from '@atlassian/jira-relay/src/__generated__/useSaveCascadeSelectField_Mutation.graphql';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { SaveField } from '../types';

export const useSaveCascadeSelectField = (issueId: IssueId): SaveField => {
	const cloudId = useCloudId();

	const [commit] = useMutation<CascadingSelectMutation>(graphql`
		mutation useSaveCascadeSelectField_Mutation($input: JiraUpdateCascadingSelectFieldInput!)
		@raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateCascadingSelectField(input: $input) {
					success
					errors {
						message
					}
					field {
						cascadingOption {
							parentOptionValue {
								id
								value
								optionId
							}
							childOptionValue {
								id
								value
								optionId
							}
						}
					}
				}
			}
		}
	`);

	return useCallback(
		async (_issueKey, fieldKey, value) =>
			new Promise((resolve, reject) => {
				commit({
					variables: {
						input: {
							id: JiraIssuefieldvalueAri.create({
								siteId: cloudId,
								issueId,
								fieldId: fieldKey,
							}).toString(),
							operation: {
								operation: 'SET',
								parentOption: value?.parentId,
								...(value?.childId ? { childOption: value?.childId } : {}),
							},
						},
					},
					onCompleted(data) {
						if (!data.jira?.updateCascadingSelectField) {
							reject(new Error('Error while mutating cascading select'));
							return;
						}

						const { success, errors, field } = data.jira.updateCascadingSelectField;
						if (!success || errors?.length) {
							reject(
								errors?.[0].message
									? new ValidationError(errors[0].message)
									: new Error('Error while mutating cascading select'),
							);
							return;
						}
						resolve(
							field?.cascadingOption &&
								typeof field.cascadingOption.parentOptionValue?.value === 'string'
								? {
										ari: field.cascadingOption.parentOptionValue?.id,
										id: field.cascadingOption.parentOptionValue?.optionId,
										value: field.cascadingOption.parentOptionValue?.value,
										child:
											typeof field.cascadingOption.childOptionValue?.value === 'string'
												? {
														ari: field.cascadingOption.childOptionValue?.id,
														id: field.cascadingOption.childOptionValue?.optionId,
														value: field.cascadingOption.childOptionValue?.value,
													}
												: null,
									}
								: null,
						);
					},
					onError: reject,
				});
			}),
		[cloudId, issueId, commit],
	);
};
