import {
	ASSIGNEE_TYPE,
	FIX_VERSIONS_TYPE,
	VERSION_CF_TYPE,
	ORGANIZATIONS_CF_TYPE,
	SPRINT_TYPE,
	MULTI_VERSION_CF_TYPE,
	TEXT_AREA_CF_TYPE,
	TEXT_CF_TYPE,
	SELECT_CF_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	MULTI_SELECT_CF_TYPE,
	USER_CF_TYPE,
	PEOPLE_CF_TYPE,
	RADIO_BUTTONS_CF_TYPE,
	URL_CF_TYPE,
	GROUP_CF_TYPE,
	MULTI_GROUP_CF_TYPE,
} from '@atlassian/jira-platform-field-config';

export const DEFAULT_ATTRIBUTES = {
	isInlineEditing: false,
	isCommandPaletteEditing: false,
	isDragEditing: false,
};

export const START_ACTION = 'clicked';
export const START_ACTION_SUBJECT = 'item';
export const START_ACTION_SUBJECT_ID = 'issue';

export const END_ACTION = 'updated';
export const END_ACTION_SUBJECT = 'issue';
export const END_ACTION_SUBJECT_ID = 'ended';

export const FIELD_TYPE_MAP: Record<string, string> = {
	[ASSIGNEE_TYPE]: 'singleUserPicker',
	[FIX_VERSIONS_TYPE]: 'versions',
	[VERSION_CF_TYPE]: 'versions',
	[ORGANIZATIONS_CF_TYPE]: 'organizations',
	[SPRINT_TYPE]: 'link',
	[MULTI_VERSION_CF_TYPE]: 'multiVersion',
	[TEXT_AREA_CF_TYPE]: 'richText',
	[TEXT_CF_TYPE]: 'singleLineText',
	[SELECT_CF_TYPE]: 'singleSelect',
	[MULTI_CHECKBOXES_CF_TYPE]: 'checkbox',
	[MULTI_SELECT_CF_TYPE]: 'multiSelect',
	[USER_CF_TYPE]: 'singleUser',
	[PEOPLE_CF_TYPE]: 'multiUser',
	[RADIO_BUTTONS_CF_TYPE]: 'radio',
	[URL_CF_TYPE]: 'url',
	[GROUP_CF_TYPE]: 'singleGroup',
	[MULTI_GROUP_CF_TYPE]: 'multiGroup',
};
