import { useEffect } from 'react';
import { useFlagService } from '@atlassian/jira-flags/src';
import { useNotification } from '@atlassian/jira-issue-adjustments/src/controllers/issue-adjustments/hooks/use-notification';
import { useIssueAdjustmentsActions } from '@atlassian/jira-issue-adjustments/src/controllers/issue-adjustments/main';
import { useNotificationFlagConfig } from './hooks/use-notification-flag-config';

export const NotificationFlags = () => {
	const notificationProperties = useNotification();
	const { showFlag } = useFlagService();
	const notificationFlag = useNotificationFlagConfig(notificationProperties);
	const { resetNotification } = useIssueAdjustmentsActions();

	useEffect(() => {
		if (notificationFlag) {
			showFlag(notificationFlag);
			resetNotification();
		}
	}, [notificationFlag, resetNotification, showFlag]);
	return null;
};
