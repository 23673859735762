import React from 'react';
import { styled } from '@compiled/react';
import { useIsModal } from '@atlassian/jira-issue-context-service/src/main.tsx';
import Avatar from './avatar-view';
import ContainerMask from './container-mask-view';
import SkeletonBackground, { CleanDiv } from './skeleton-background-view';
import SkeletonContainer from './skeleton-container-view';
import TextLineMask from './text-line-mask-view';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FormContainer = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Border = styled(CleanDiv)({
	width: '8px',
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => {
	const isModal = useIsModal();

	return (
		<SkeletonContainer data-testid="issue.skeleton.container">
			<SkeletonBackground />
			<ContainerMask isModal={isModal} paddingTop={0} paddingBottom={0}>
				<ContainerMask isModal={isModal} paddingTop={32} paddingBottom={12}>
					<TextLineMask isModal={isModal} fontSize={14} lineHeight={16} intrusion={300} />
				</ContainerMask>
				<FormContainer>
					<Avatar />
					<Border isModal={isModal} />
					<TextLineMask isModal={isModal} fontSize={40} lineHeight={40} intrusion={0} />
				</FormContainer>
			</ContainerMask>
		</SkeletonContainer>
	);
};
