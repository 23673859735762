import uuid from 'uuid';
import type { RulesLogic } from '@atlassian/jira-polaris-lib-json-logic';
import { FIELD_TYPES } from '../field-types';
import type { FieldType } from '../field-types/types.tsx';
import { ValueRuleOperator, JsonLogicOperator } from './constants';
import type {
	LocalDecorationId,
	ValueDecoration,
	ValueDecorationLogic,
	ValueDecorationRules,
	ValueRule,
} from './types';

export const isDecorationWithRules = (
	decoration?: Omit<ValueDecoration, 'localDecorationId' | 'backendId'>, // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
): decoration is ValueDecorationRules => (decoration as ValueDecorationRules).rules !== undefined;

export const isDecorationsWithRules = (
	decoration?: Omit<ValueDecoration, 'localDecorationId' | 'backendId'>[],
): decoration is ValueDecorationRules[] =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	(decoration as ValueDecorationRules[]).some(({ rules }) => rules && rules.length > 0);

export const isDecorationWithLogic = (
	decoration?: Omit<ValueDecoration, 'localDecorationId' | 'backendId'>, // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
): decoration is ValueDecorationLogic => (decoration as ValueDecorationLogic).logic !== undefined;

export const isDecorationsWithLogic = (
	decoration?: Omit<ValueDecoration, 'localDecorationId' | 'backendId'>[],
): decoration is ValueDecorationLogic[] =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	(decoration as ValueDecorationLogic[]).some(({ logic }) => !!logic);

export const generateLocalDecorationId = (): LocalDecorationId => uuid();

export const convertLogicToRules = (logic: RulesLogic | undefined): ValueRule[] => {
	if (!logic) return [];

	const [operator, values] = Object.entries(logic)[0];

	if (!operator) {
		throw new Error('incompatible operator for conversion');
	}

	switch (operator) {
		case JsonLogicOperator.EQ:
			return [
				{
					value: values[0].toString(),
					operator: ValueRuleOperator.EQ,
				},
			];
		case JsonLogicOperator.GT:
			return [
				{
					value: values[1].toString(),
					operator: ValueRuleOperator.GT,
				},
			];
		case JsonLogicOperator.LT:
			if (values[1]?.var === 'daysUntilDueDate') {
				return [
					{
						value: (values[0] + 1).toString(),
						operator: ValueRuleOperator.GTE,
					},
					{
						value: values[2].toString(),
						operator: ValueRuleOperator.LT,
					},
				];
			}
			return [
				{
					value: values[1].toString(),
					operator: ValueRuleOperator.LT,
				},
			];
		case JsonLogicOperator.LTE:
			return [
				{
					value: values[0].toString(),
					operator: ValueRuleOperator.GTE,
				},
				{
					value: values[2].toString(),
					operator: ValueRuleOperator.LTE,
				},
			];
		default:
			return [];
	}
};

type FieldTypeConvertRulesToLogic = (rules: ValueRule[]) => RulesLogic | undefined;

const dateConvertRulesToLogic: FieldTypeConvertRulesToLogic = (rules) => {
	if (rules.length < 2) {
		const { operator } = rules[0];

		switch (operator) {
			case ValueRuleOperator.LT:
				return { [JsonLogicOperator.LT]: [{ var: 'daysUntilDueDate' }, 0] };
			default:
				throw new Error(`Expected only ${ValueRuleOperator.LT} operator but got ${operator}`);
		}
	}

	const { value: firstValue } = rules[0];
	const { value: secondValue } = rules[1];
	const firstValueInt = parseInt(firstValue, 10);
	const secondValueInt = parseInt(secondValue, 10);

	return {
		[JsonLogicOperator.LT]: [firstValueInt - 1, { var: 'daysUntilDueDate' }, secondValueInt],
	};
};

type DefaultConvertRulesToLogicFactoryOptions = {
	parseStringAsNumber: (value: string) => number;
};

const defaultConvertRulesToLogicFactory = ({
	parseStringAsNumber,
}: DefaultConvertRulesToLogicFactoryOptions): FieldTypeConvertRulesToLogic => {
	const defaultConvertRulesToLogicWithParse: FieldTypeConvertRulesToLogic = (rules) => {
		if (rules.length < 2) {
			const { operator, value } = rules[0];
			const valueNumber = parseStringAsNumber(value);

			switch (operator) {
				case ValueRuleOperator.LT:
					return { [JsonLogicOperator.LT]: [{ var: 'value' }, valueNumber] };
				case ValueRuleOperator.GT:
					return { [JsonLogicOperator.GT]: [{ var: 'value' }, valueNumber] };
				case ValueRuleOperator.EQ:
					if (Number.isNaN(valueNumber)) {
						return { [JsonLogicOperator.EQ]: [value, { var: 'label' }] };
					}
					return { [JsonLogicOperator.EQ]: [valueNumber, { var: 'id' }] };

				default:
					break;
			}
		}

		const { operator: firstOperator, value: firstValue } = rules[0];
		const { value: secondValue } = rules[1];
		const firstValueNumber = parseStringAsNumber(firstValue);
		const secondValueNumber = parseStringAsNumber(secondValue);

		switch (firstOperator) {
			case ValueRuleOperator.GTE:
				return { [JsonLogicOperator.LTE]: [firstValueNumber, { var: 'value' }, secondValueNumber] };
			case ValueRuleOperator.LTE:
				return { [JsonLogicOperator.LTE]: [secondValueNumber, { var: 'value' }, firstValueNumber] };
			default:
				break;
		}

		return undefined;
	};

	return defaultConvertRulesToLogicWithParse;
};

const numberConvertRulesToLogic: FieldTypeConvertRulesToLogic = defaultConvertRulesToLogicFactory({
	parseStringAsNumber: (value) => parseFloat(value),
});

const defaultConvertRulesToLogic: FieldTypeConvertRulesToLogic = defaultConvertRulesToLogicFactory({
	parseStringAsNumber: (value) => parseInt(value, 10),
});

export const convertRulesToLogic = (
	rules: ValueRule[],
	fieldType?: FieldType,
): RulesLogic | undefined => {
	switch (fieldType) {
		case FIELD_TYPES.DATE:
		case FIELD_TYPES.INTERVAL:
			return dateConvertRulesToLogic(rules);
		case FIELD_TYPES.NUMBER:
		case FIELD_TYPES.SLIDER:
		case FIELD_TYPES.FORMULA:
		case FIELD_TYPES.LINKED_ISSUES:
			return numberConvertRulesToLogic(rules);
		default:
			return defaultConvertRulesToLogic(rules);
	}
};
