import React, { useCallback, useEffect, type FC } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import TaskIcon from '@atlaskit/icon/glyph/task';
import Lozenge from '@atlaskit/lozenge';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { COMMAND_PALETTE_ISSUE_ACTIONS } from '@atlassian/jira-command-palette-common/src/common/constants.tsx';
import { getCommandPaletteIssueActionsPriority } from '@atlassian/jira-command-palette-common/src/common/utils/get-command-palette-issue-actions-priority/index.tsx';
import { getCommandPaletteIssueActionsHeader } from '@atlassian/jira-command-palette-common/src/common/utils/index.tsx';
import { useCommandKeywords } from '@atlassian/jira-command-palette-common/src/controllers/use-command-keywords/index.tsx';
import { CommandFieldHeader } from '@atlassian/jira-command-palette-issue-actions-common/src/common/ui/command-field-header/index.tsx';
import { COMMAND_PALETTE_REGISTRY_IDS } from '@atlassian/jira-command-palette-registry/src/common/constants/registry/index.tsx';
import { useSubscribeCommands } from '@atlassian/jira-command-palette-registry/src/controllers/use-subscribe-commands/index.tsx';
import {
	type ChildResult,
	type RenderChildrenProps,
	type HeaderProps,
	type Command,
	CommandActionType,
} from '@atlassian/jira-command-palette/src/common/types/commands/index.tsx';
import { components } from '@atlassian/jira-command-palette/src/common/ui/components/index.tsx';
import { useCommandPaletteExpandedCommand } from '@atlassian/jira-command-palette/src/controllers/command-palette/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIssueAri } from '@atlassian/jira-issue-hooks/src/services/use-issue-ari';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { STATUS_COMMAND_ID } from './constants';
import { messages } from './messages';
import type { CommandPaletteStatusChildrenProps, StatusViewCommandProps } from './types';
import { useSaveStatusField } from './use-save-status-field';
import { getTransitionKeywords } from './utils';

export const CommandPaletteStatusChildren: FC<CommandPaletteStatusChildrenProps> = ({
	childrenProps,
	statusViewProps,
}) => {
	const { setLoading, setChildResult } = childrenProps;
	const { wasFetchSuccessful, currentStatus, transitions, isLoading, renderTransition, onSuccess } =
		statusViewProps;

	const issueKey = useIssueKey();

	const issueId = useIssueAri();
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { saveStatusField } = useSaveStatusField(issueKey, issueId);

	const loadTransitions = useCallback((): ChildResult => {
		if (!wasFetchSuccessful) return { commands: [] };

		const transitionCommands: Command[] = transitions.map((transition) => ({
			id: `${STATUS_COMMAND_ID}-${transition.id}`,
			name: transition.name,
			components: {
				Name: () => (
					<TransitionWrapper>
						{renderTransition({ transition }, { addTransitionArrowSpacing: true })}
					</TransitionWrapper>
				),
			},
			keywords: getTransitionKeywords(transition),
			primaryAction: {
				type: CommandActionType.PERFORM,
				perform: () => {
					fireTrackAnalytics(createAnalyticsEvent({}), 'issueStatus updated', {
						oldValId: currentStatus.id,
						oldStatusCategoryId: currentStatus.statusCategory.id,
						newValId: transition.to.id,
						newStatusCategoryId: transition.to.statusCategory.id,
						hasScreen: transition.hasScreen,
						isConditional: transition.isConditional,
					});

					saveStatusField(transition, () => {
						onSuccess?.(transition.to, false, createAnalyticsEvent({}));
					});
				},
			},
			analytics: {
				action: 'changeStatus',
				actionCategory: COMMAND_PALETTE_ISSUE_ACTIONS,
			},
		}));

		const commands: Command[] = [
			{
				id: `${STATUS_COMMAND_ID}-${currentStatus.id}-current`,
				name: currentStatus.name,
				components: {
					Name: () => (
						<TransitionWrapper>
							<SelectedStatusContainer>
								<Lozenge isBold>{currentStatus.name}</Lozenge>
							</SelectedStatusContainer>
							<CurrentStatusContainer>{formatMessage(messages.current)}</CurrentStatusContainer>
						</TransitionWrapper>
					),
				},
				primaryAction: {
					type: CommandActionType.PERFORM,
					perform: noop,
				},
			},
			...transitionCommands,
		];

		return { commands };
	}, [
		createAnalyticsEvent,
		currentStatus.id,
		currentStatus.name,
		currentStatus.statusCategory.id,
		formatMessage,
		onSuccess,
		renderTransition,
		saveStatusField,
		transitions,
		wasFetchSuccessful,
	]);

	useEffect(() => {
		setLoading(isLoading);
	}, [isLoading, setLoading]);

	useEffect(() => {
		setChildResult(loadTransitions());
	}, [loadTransitions, setChildResult]);

	return <components.Body {...childrenProps} />;
};

export const CommandPaletteStatusViewOld: FC<StatusViewCommandProps> = (props) => {
	const issueKey = useIssueKey();
	const { formatMessage } = useIntl();
	const { getKeywords } = useCommandKeywords();
	const subscribeCommands = useSubscribeCommands();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [{ expandedCommand, isOpen }] = useCommandPaletteExpandedCommand();
	const { onFetch } = props;

	const renderChildren = useCallback(
		(childrenProps: RenderChildrenProps) => (
			<CommandPaletteStatusChildren childrenProps={childrenProps} statusViewProps={props} />
		),
		[props],
	);

	const renderHeader = useCallback(
		(base: HeaderProps) => <CommandFieldHeader {...base} issueKey={issueKey} />,
		[issueKey],
	);

	useEffect(() => {
		const statusCommand: Command = {
			id: STATUS_COMMAND_ID,
			name: formatMessage(messages.editStatus),
			shortcut: 'd',
			placeholder: formatMessage(messages.searchTransition),
			keywords: getKeywords('changeStatusSynonyms'),
			section: getCommandPaletteIssueActionsHeader(issueKey),
			priority: getCommandPaletteIssueActionsPriority('STATUS'),
			components: {
				LeftIcon: () => <TaskIcon label="" />,
			},
			primaryAction: {
				type: CommandActionType.COMMAND_LIST,
				fallback: {
					onNoResults: {
						label: formatMessage(messages.fallbackNoResults),
					},
				},
				components: {
					Body: renderChildren,
					Header: renderHeader,
				},
			},
			analytics: {
				action: 'changeStatusMenu',
			},
		};
		const unsubscribe = subscribeCommands(COMMAND_PALETTE_REGISTRY_IDS.STATUS, [statusCommand]);

		return () => unsubscribe();
	}, [formatMessage, getKeywords, issueKey, renderChildren, renderHeader, subscribeCommands]);

	const expandedCommandId = expandedCommand?.id;
	useEffect(() => {
		if (isOpen && expandedCommandId === STATUS_COMMAND_ID && onFetch) {
			onFetch(createAnalyticsEvent({}));
		}
	}, [expandedCommandId, isOpen, onFetch, createAnalyticsEvent]);

	return null;
};

export const CommandPaletteStatusViewNew: FC<StatusViewCommandProps> = ({
	onFetch,
	wasFetchSuccessful,
	currentStatus,
	transitions,
	renderTransition,
	onSuccess,
}) => {
	const issueKey = useIssueKey();
	const { formatMessage } = useIntl();
	const { getKeywords } = useCommandKeywords();
	const subscribeCommands = useSubscribeCommands();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [{ expandedCommand, isOpen }, { setChildCommands }] = useCommandPaletteExpandedCommand();

	const renderHeader = useCallback(
		(base: HeaderProps) => <CommandFieldHeader {...base} issueKey={issueKey} />,
		[issueKey],
	);

	const issueId = useIssueAri();
	const { saveStatusField } = useSaveStatusField(issueKey, issueId);
	const expandedCommandId = expandedCommand?.id;

	const loadTransitions = useCallback((): ChildResult => {
		if (!wasFetchSuccessful) return { commands: [] };

		const transitionCommands: Command[] = transitions.map((transition) => ({
			id: `${STATUS_COMMAND_ID}-${transition.id}`,
			name: transition.name,
			components: {
				Name: () => (
					<TransitionWrapper>
						{renderTransition({ transition }, { addTransitionArrowSpacing: true })}
					</TransitionWrapper>
				),
				LeftIcon: !expandedCommandId ? () => <TaskIcon label="" /> : undefined,
			},
			keywords: getTransitionKeywords(transition),
			primaryAction: {
				type: CommandActionType.PERFORM,
				perform: () => {
					fireTrackAnalytics(createAnalyticsEvent({}), 'issueStatus updated', {
						oldValId: currentStatus.id,
						oldStatusCategoryId: currentStatus.statusCategory.id,
						newValId: transition.to.id,
						newStatusCategoryId: transition.to.statusCategory.id,
						hasScreen: transition.hasScreen,
						isConditional: transition.isConditional,
					});

					saveStatusField(transition, () => {
						onSuccess?.(transition.to, false, createAnalyticsEvent({}));
					});
				},
			},
			analytics: {
				action: 'changeStatus',
				actionCategory: COMMAND_PALETTE_ISSUE_ACTIONS,
			},
		}));

		const currentCommand: Command[] = expandedCommandId
			? [
					{
						id: `${STATUS_COMMAND_ID}-${currentStatus.id}-current`,
						name: currentStatus.name,
						components: {
							LeftIcon: !expandedCommandId
								? () => <TaskIcon label={formatMessage(messages.editStatus)} />
								: undefined,
							Name: () => (
								<TransitionWrapper>
									<SelectedStatusContainer>
										<Lozenge isBold>{currentStatus.name}</Lozenge>
									</SelectedStatusContainer>
									<CurrentStatusContainer>{formatMessage(messages.current)}</CurrentStatusContainer>
								</TransitionWrapper>
							),
						},
						primaryAction: {
							type: CommandActionType.PERFORM,
							perform: noop,
						},
					},
				]
			: [];

		const commands: Command[] = [...currentCommand, ...transitionCommands];

		return { commands };
	}, [
		createAnalyticsEvent,
		currentStatus.id,
		currentStatus.name,
		currentStatus.statusCategory.id,
		formatMessage,
		onSuccess,
		renderTransition,
		saveStatusField,
		transitions,
		wasFetchSuccessful,
		expandedCommandId,
	]);

	useEffect(() => {
		const { commands } = loadTransitions();

		const statusCommand: Command = {
			id: STATUS_COMMAND_ID,
			name: formatMessage(messages.editStatus),
			shortcut: 'd',
			placeholder: formatMessage(messages.searchTransition),
			keywords: getKeywords('changeStatusSynonyms'),
			section: getCommandPaletteIssueActionsHeader(issueKey),
			priority: getCommandPaletteIssueActionsPriority('STATUS'),
			components: {
				LeftIcon: () => <TaskIcon label="" />,
			},
			primaryAction: {
				type: CommandActionType.COMMAND_LIST,
				fallback: {
					onNoResults: {
						label: formatMessage(messages.fallbackNoResults),
					},
				},
				components: {
					Header: renderHeader,
				},
				commands,
			},
			analytics: {
				action: 'changeStatusMenu',
			},
		};

		const unsubscribe = subscribeCommands(COMMAND_PALETTE_REGISTRY_IDS.STATUS, [statusCommand]);

		return () => unsubscribe();
	}, [
		formatMessage,
		getKeywords,
		issueKey,
		loadTransitions,
		renderHeader,
		subscribeCommands,
		expandedCommand?.id,
	]);

	useEffect(() => {
		if (isOpen && onFetch) {
			onFetch(createAnalyticsEvent({}));
		}
	}, [isOpen, onFetch, createAnalyticsEvent]);

	useEffect(() => {
		if (isOpen && expandedCommandId === STATUS_COMMAND_ID) {
			setChildCommands(loadTransitions());
		}
	}, [expandedCommandId, isOpen, loadTransitions, setChildCommands]);

	return null;
};

export const CommandPaletteStatusView = componentWithFG(
	'command_palette_improve_status',
	CommandPaletteStatusViewNew,
	CommandPaletteStatusViewOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TransitionWrapper = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	marginLeft: token('space.050', '4px'),
	height: '24px',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SelectedStatusContainer = styled.div({
	flexGrow: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CurrentStatusContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N400),
});
