import React, { type ReactNode } from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder';

type BoundaryProps = {
	children: ReactNode;
	fallback?: ReactNode;
	id: string;
};

export const Boundary = ({ children, id, fallback = <></> }: BoundaryProps) => (
	<ErrorBoundary id={id} packageName="issue" render={() => fallback}>
		<Placeholder name="boundary" fallback={fallback}>
			{children}
		</Placeholder>
	</ErrorBoundary>
);
