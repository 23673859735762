/** @jsx jsx */
import React, { useEffect, type ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { IssueViewRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { CONTEXT_AREA } from '@atlassian/jira-issue-layout-common-constants';
import type { AdditionalEventAttributes } from '@atlassian/jira-issue-view-layout-group/src/common/types';
import { ItemList } from '@atlassian/jira-issue-view-layout-templates-item-list';
import type { LayoutItems } from '@atlassian/jira-issue-view-layout-templates-services/src/services/context/visible-hidden/visible-hidden-items/index.tsx';
import { ContextGroup } from '@atlassian/jira-issue-view-layout-templates-views-context-group';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useHideWhenEmpty } from '../../../../../common/use-hide-when-empty';
import messages from './messages';

type Props = {
	issueViewRelayFragment?: IssueViewRelayFragment | null;
	items: LayoutItems;
	visibleItemsSubtitle?: ReactNode;
	additionalContextEventAttributes?: AdditionalEventAttributes;
	actionButton?: React.ComponentProps<typeof ContextGroup>['actionButton'] | null;
};

const styles = css({
	height: '24px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	font: token('font.body.small'),
});

export const HideWhenEmpty = ({
	items,
	issueViewRelayFragment,
	visibleItemsSubtitle,
	additionalContextEventAttributes,
	actionButton,
}: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const {
		visibleItems,
		showToggleButton,
		noOfEmptyFields,
		showHiddenWhenEmptyFields,
		onButtonToggled,
		onVisibleItemsToggleExpanded,
	} = useHideWhenEmpty(items);

	useEffect(() => {
		showToggleButton
			? fireUIAnalytics(createAnalyticsEvent({}), 'button show', 'hideEmptyFields')
			: fireUIAnalytics(createAnalyticsEvent({}), 'button hide', 'hideEmptyFields');
	}, [showToggleButton, createAnalyticsEvent]);

	return (
		<>
			<ContextGroup
				title={formatMessage(messages.detailsText)}
				groupId="details-group"
				subTitle={visibleItemsSubtitle}
				onChange={onVisibleItemsToggleExpanded}
				initialOpened
				additionalEventAttributes={additionalContextEventAttributes}
				actionButton={actionButton}
			>
				<ItemList
					items={visibleItems}
					area={CONTEXT_AREA}
					layoutItemsDataFragment={issueViewRelayFragment ?? null}
				/>
				{showToggleButton && (
					<Button appearance="default" onClick={onButtonToggled} css={styles}>
						{showHiddenWhenEmptyFields
							? formatMessage(messages.hideEmptyFieldsButtonText)
							: formatMessage(messages.showEmptyFieldsButtonText, {
									noOfEmptyFields,
								})}
					</Button>
				)}
			</ContextGroup>
		</>
	);
};
