import React, { useEffect, useMemo } from 'react';

import { FormattedMessage } from 'react-intl-next';

import { ErrorScreen } from '@atlassian/generative-ai-modal/screens/Error';

import {
	useAnalyticsFlow,
	useAnalyticsFlowEventsQueue,
} from '../../analytics/analytics-flow/analyticsFlowUtils';
import globalMessages from '../../messages';
import { useAIExperienceCommonDataContext } from '../useAIExperienceCommonData';

export function RateLimitScreenWithLogic({
	loadingState,
	errorInfo,
}: {
	loadingState: string;
	errorInfo: {
		errorKey?: string;
		statusCode?: number;
	};
}) {
	const aiExperienceCommonData = useAIExperienceCommonDataContext();
	const { endExperience, sendToAIExperienceMachine } = aiExperienceCommonData;
	const documentationLink = `https://support.atlassian.com/organization-administration/docs/excessive-use-in-atlassian-intelligence/`;

	// https://developer.atlassian.com/platform/localization/i18n-formatting/avoiding-concatenation/#links
	const errorMsg = (
		<FormattedMessage
			{...globalMessages.rateLimitEnforced}
			values={{
				link: (message: React.ReactNode[]) => (
					<a href={documentationLink} target="_blank" rel="noopener noreferrer">
						{message}
					</a>
				),
			}}
		/>
	);

	const analyticsFlow = useAnalyticsFlow();
	const attributes = useMemo(() => {
		return {
			errorType: loadingState === 'rate limit active' ? 'rate limit enforced' : 'rate limit lifted',
			...errorInfo,
		};
	}, [errorInfo, loadingState]);
	// Ensure the error attributes are available when the error event is fired
	useEffect(() => {
		analyticsFlow.addAttributes(attributes);
	}, [analyticsFlow, attributes]);
	useAnalyticsFlowEventsQueue({
		stepName: 'apiError',
		attributes,
	});

	return (
		<ErrorScreen
			text={errorMsg}
			onCancel={() => {
				endExperience();
			}}
			showRetryButton={false}
			onRetry={() => {
				sendToAIExperienceMachine({ type: 'api retry' });
			}}
		/>
	);
}
