import React, { type ComponentType } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import CommentVisibilitiesContainer from './container';
import CommentVisibilitiesSubscriber from './subscriber';

type Props = {
	issueKey: IssueKey;
	shouldFetchCommentVisibilities: boolean;
	baseUrl: BaseUrl;
	createAnalyticsEvent: (arg1: { action: string }) => UIAnalyticsEvent;
};

type CommentVisibilitiesComponentProps<T> = T &
	Props & {
		fetchCommentVisibilities?: () => void;
	};

const withCommentVisibilities =
	<T,>(
		WrappedComponent: ComponentType<CommentVisibilitiesComponentProps<T>>,
	): ComponentType<CommentVisibilitiesComponentProps<T>> =>
	(props: CommentVisibilitiesComponentProps<T>) => {
		const { baseUrl, issueKey, shouldFetchCommentVisibilities, createAnalyticsEvent } = props;

		return (
			<CommentVisibilitiesContainer
				baseUrl={baseUrl}
				issueKey={issueKey}
				createAnalyticsEvent={createAnalyticsEvent}
				scope={issueKey}
				key={issueKey}
			>
				<CommentVisibilitiesSubscriber>
					{(_, { fetch }) => (
						<WrappedComponent
							{...props}
							fetchCommentVisibilities={shouldFetchCommentVisibilities ? fetch : undefined}
						/>
					)}
				</CommentVisibilitiesSubscriber>
			</CommentVisibilitiesContainer>
		);
	};

export default withCommentVisibilities;
