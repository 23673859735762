import { useCallback } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useAnalyticsEvents, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { CONFLUENCE } from '@atlassian/jira-shared-types/src/application-key.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useCloudName } from '@atlassian/jira-tenant-context-controller/src/components/cloud-name/index.tsx';
import { SOURCE, type Source } from '../services/feature-flag';
import { useInvitees } from '../services/get-invitees';
import { getPageTitle } from '../services/get-page-title';
import { useInviteUsers } from '../services/invite';
import { useCanInvite } from '../services/invite-capability';
import { useOptOut } from '../services/opt-out';
import { type LinkData, isConfluenceLink } from '../utils';
import { invitePromptOnLinkInsertExperience } from './experiences';
import { useInviteErrorFlag } from './invite-error-flag';
import { useInvitePromptFlag } from './invite-prompt-flag';
import { useInvitePromptFlagV5 } from './invite-prompt-flag-v5';
import { useInviteSuccessFlag } from './invite-success-flag';

export const useInvitePrompt = (createIssueProjectKey?: string) => {
	const canInvite = useCanInvite();
	const getInvitees = useInvitees(createIssueProjectKey);
	const showInvitePromptFlag = useInvitePromptFlag();
	const showInvitePromptFlagV5 = useInvitePromptFlagV5();
	const showSuccessFlag = useInviteSuccessFlag();
	const showErrorFlag = useInviteErrorFlag();
	const inviteUsers = useInviteUsers();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { getOptOutStatus, setOptOutStatus, incrementViewCount, shouldShowOptOutOption } =
		useOptOut();
	return useCallback(
		async (continueUrl: string, data: LinkData, source: Source = SOURCE.ISSUE_VIEW) => {
			try {
				invitePromptOnLinkInsertExperience.start();
				const { tenantId, product, name } = data;
				const hasOptedOut = await getOptOutStatus();
				if (hasOptedOut) {
					return;
				}

				const canInviteToProduct = await canInvite(tenantId, product);
				if (!canInviteToProduct) {
					return;
				}

				const users = await getInvitees(tenantId, product, 10);
				if (users.length === 0) {
					return;
				}

				const targetPageLabel = name || (await getPageTitle(continueUrl));

				const isV5Enabled = fg('groot-aioli-v5-gate');
				const isV5Eligible = isV5Enabled && expValEquals('groot-aioli-v5', 'cohort', 'variation');
				const v1Scenario = product === CONFLUENCE && source === SOURCE.ISSUE_VIEW;

				if (!isV5Eligible && !v1Scenario) {
					fireTrackAnalytics(createAnalyticsEvent({}), 'eligibility checked', {
						flagKey: 'groot-aioli-v5',
						flagCohort: 'not-enrolled',
						ineligibilityReason: {
							isV5Eligible,
							product,
							source,
						},
					});
					return;
				}

				const showFlag = isV5Eligible ? showInvitePromptFlagV5 : showInvitePromptFlag;
				const integration = isV5Eligible ? 'invitePromptOnLinkV5' : 'invitePromptOnLink';

				showFlag({
					users,
					product,
					source,
					targetPageLabel,
					allowOptOut: await shouldShowOptOutOption(),
					onSubmit: async () => {
						try {
							const response = await inviteUsers({
								continueUrl,
								users,
								tenantId,
								product,
								integration,
							});

							showSuccessFlag({
								users,
								product,
								requireAdminApproval: response.accessRequested.length > 0,
								isAIOLIV5: isV5Eligible,
							});
						} catch {
							showErrorFlag();
						}
					},
					onDismiss: incrementViewCount,
					onOptOut: setOptOutStatus,
				});

				invitePromptOnLinkInsertExperience.success();
				return true;
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				fireErrorAnalytics({
					meta: {
						id: 'useInvitePromptExperiment',
						packageName: 'jiraInvitePromptOnLinkInsert',
					},
					error,
				});
				invitePromptOnLinkInsertExperience.failure();
			}
		},
		[
			canInvite,
			getInvitees,
			getOptOutStatus,
			incrementViewCount,
			inviteUsers,
			setOptOutStatus,
			shouldShowOptOutOption,
			showErrorFlag,
			showInvitePromptFlag,
			showInvitePromptFlagV5,
			showSuccessFlag,
			createAnalyticsEvent,
		],
	);
};

export const useInvitePromptExperimentV2 = (createIssueProjectKey?: string) => {
	// aioli v2 is re-run under v5 with some eligibility changes
	return useInvitePrompt(createIssueProjectKey);
};

export const useInviteOnCreateLink = (link: string) => {
	const cloudId = useCloudId();
	const cloudName = useCloudName();
	const triggerInviteExperiment = useInvitePrompt();
	return useCallback(() => {
		if (isConfluenceLink(link, cloudName)) {
			triggerInviteExperiment(link, { tenantId: cloudId, product: CONFLUENCE }, SOURCE.CREATE_LINK);
		}
	}, [cloudId, cloudName, link, triggerInviteExperiment]);
};
