import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import WithValidationOnBlur from '../../../../with-validation-on-blur/view.tsx';
import TimeLogTextField from '../../time-log-text-field/view.tsx';
import messages from '../messages';

export type TimeRemainingProps = {
	isDisabled: boolean;
	isValid: boolean;
	value: string;
	onChange: (arg1: string) => void;
	onBlur: () => void;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ isValid, value, onChange, onBlur, isDisabled }: TimeRemainingProps) => {
	const { formatMessage } = useIntl();

	return (
		<WithValidationOnBlur isValid={isValid}>
			{(shouldAppearInvalid) => (
				<TimeLogTextField
					value={value}
					isInvalid={shouldAppearInvalid}
					invalidMessage={formatMessage(messages.timeInvalidMessage)}
					onChange={onChange}
					onBlur={onBlur}
					isDisabled={isDisabled}
					label={formatMessage(messages.timeRemaining)}
					triggerBtnAriaLabel={formatMessage(messages.timeRemainingTriggerBtnLabel)}
					name={messages.timeRemaining.id}
					description={formatMessage(messages.timeRemainingInfo)}
				/>
			)}
		</WithValidationOnBlur>
	);
};
