import { createHash } from 'crypto';
import memoizeOne from 'memoize-one';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-legacy
import { getTenantContext_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-common-util-get-tenant-context';

export const getCloudId = memoizeOne(() => {
	const { cloudId } = getTenantContext_DEPRECATED_DO_NOT_USE();
	return cloudId;
});

/**
 * encrypt String attribute using SHA256 by concating cloud id as prefix, where
 * cloudId call is memoized
 * @returns string of the hash
 */
export const memoizedHashString = (attribute: string): string => {
	const cloudId = getCloudId();
	const hash = createHash('sha256').update(`${cloudId}_${attribute.trim()}`).digest('hex');
	return hash;
};
