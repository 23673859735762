import React, { type ComponentType, type FC, useContext } from 'react';
import { CompactModeConsumer, CompactModeContext } from './services/provider';

export const useIsCompactMode = () => useContext(CompactModeContext);

// FIXME: JIV-17455 should be fully typed
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default <P extends { isCompact?: boolean }>(
		WrappedComponent: ComponentType<P>,
	): FC<Omit<P, 'isCompact'>> =>
	(props) => (
		<CompactModeConsumer>
			{(isCompact) => (
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				<WrappedComponent {...(props as P)} isCompact={isCompact} />
			)}
		</CompactModeConsumer>
	);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	CompactModeProvider,
	CompactModeContext,
	COMPACT_LAYOUT_MAX_WIDTH,
} from './services/provider/index';
