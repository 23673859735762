import { useMemo } from 'react';
import { useIssueFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { RESPONDERS_CF_TYPE } from '@atlassian/jira-platform-field-config';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

export type ResponderConfig = {
	respondersFieldIdKey: string;
	searchUrl: string;
	fieldType: string;
};

export const useRespondersConfig: (issueKey: IssueKey) => ResponderConfig = (
	issueKey: IssueKey,
) => {
	const [{ value: fieldConfig }] = useIssueFieldConfig(issueKey);

	const [respondersFieldIdKey, searchUrl, fieldType] = useMemo(() => {
		if (fieldConfig != null) {
			const fieldId = Object.keys(fieldConfig).find(
				(fieldKey) => fieldConfig[fieldKey].schema.custom === RESPONDERS_CF_TYPE,
			);
			if (fieldId != null) {
				const respondersConfig = fieldConfig[fieldId];
				const {
					autoCompleteUrl,
					schema: { type },
				} = respondersConfig;
				return [fieldId, autoCompleteUrl || '', type];
			}
		}
		return ['', '', 'array'] as const;
	}, [fieldConfig]);

	return { respondersFieldIdKey, searchUrl, fieldType };
};
