import React from 'react';
import memoizeOne from 'memoize-one';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { useAdjustmentsEnabled } from '@atlassian/jira-issue-adjustments/src/controllers/adjustments-context/index.tsx';
import useAssigneeField from '@atlassian/jira-issue-field-assignee/src/services/use-assignee-field/index.tsx';
import { useUserCustomField } from '@atlassian/jira-issue-field-base-user-picker/src/services/use-user-picker-field/index.tsx';
import type { IssueConfiguration } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import { useCascadeSelectField } from '@atlassian/jira-issue-field-cascading-select/src/service/cascading-select-field-service/index.tsx';
import { useCheckboxSelectField } from '@atlassian/jira-issue-field-checkbox-select/src/services/use-checkbox-select-field/index.tsx';
import { useComponentsField } from '@atlassian/jira-issue-field-components-field/src/services/use-components-field/index.tsx';
import { useDateTimeField } from '@atlassian/jira-issue-field-date-time/src/services/use-date-time-field/index.tsx';
import { useDateField } from '@atlassian/jira-issue-field-date/src/services/use-date-field/index.tsx';
import { useLabelsField } from '@atlassian/jira-issue-field-labels/src/services/labels-field-service/index.tsx';
import { useMultiUserCustomField } from '@atlassian/jira-issue-field-multi-user-picker-editview-full/src/services/use-multi-user-picker-field/index.tsx';
import { useNumberField } from '@atlassian/jira-issue-field-number/src/services/index.tsx';
import { useOriginalEstimateFieldWithTimeTracking } from '@atlassian/jira-issue-field-original-estimate/src/services';
import type { ParentFieldOption } from '@atlassian/jira-issue-field-parent/src/common/types';
import { useParentField } from '@atlassian/jira-issue-field-parent/src/services/parent-field/index.tsx';
import type {
	ParentIdShape,
	ParentFieldResponse,
} from '@atlassian/jira-issue-field-parent/src/services/parent-field/types';
import { usePeopleCustomField } from '@atlassian/jira-issue-field-people/src/services/use-people-custom-field/index.tsx';
import { usePriorityField } from '@atlassian/jira-issue-field-priority-chip/src/services/index.tsx';
import { useRadioSelectCustomField } from '@atlassian/jira-issue-field-radio-select/src/services/index.tsx';
import { useReporterField } from '@atlassian/jira-issue-field-reporter/src/services/use-reporter-field/index.tsx';
import {
	useDescriptionField,
	useParagraphField,
} from '@atlassian/jira-issue-field-rich-text/src/services/index.tsx';
import {
	useSingleSelectField,
	useMultiSelectField,
} from '@atlassian/jira-issue-field-select/src/services/use-select-field/index.tsx';
import { useTextCustomField } from '@atlassian/jira-issue-field-single-line-text/src/services/index.tsx';
import { useStatusField } from '@atlassian/jira-issue-field-status/src/services/use-status-service/index.tsx';
import { useSummaryField } from '@atlassian/jira-issue-field-summary-common/src/services/index.tsx';
import { useUrlCustomField } from '@atlassian/jira-issue-field-url/src/services/use-url-custom-field/index.tsx';
import { useVersionsFields } from '@atlassian/jira-issue-field-versions/src/ui/services/use-versions-fields/index.tsx';
import {
	SUMMARY_TYPE,
	DESCRIPTION_TYPE,
	PRIORITY_TYPE,
	ASSIGNEE_TYPE,
	TEXT_AREA_CF_TYPE,
	URL_CF_TYPE,
	TEXT_CF_TYPE,
	MULTI_SELECT_CF_TYPE,
	SELECT_CF_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	RADIO_BUTTONS_CF_TYPE,
	FIX_VERSIONS_TYPE,
	NUMBER_CF_TYPE,
	LABELS_TYPE,
	DATE_CF_TYPE,
	DATETIME_CF_TYPE,
	USER_CF_TYPE,
	REPORTER_TYPE,
	MULTI_USER_CF_TYPE,
	COMPONENTS_TYPE,
	PARENT_TYPE,
	CASCADING_SELECT_CF_TYPE,
	AFFECTS_VERSIONS_TYPE,
	PEOPLE_CF_TYPE,
	STATUS_TYPE,
	TIME_ESTIMATE_TYPE,
} from '@atlassian/jira-platform-field-config';
import type { IssueKey, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { isFieldTypeSupported } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/index.tsx';
import type { FieldType } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/views/types.tsx';
import { ISSUE_VIEW_VIEW_TYPE } from '../../../common/constants';
import { FieldProcessor } from './field-processor';
import type { UseFieldHook } from './field-processor/types';
import { OnChangeHandlerRegistration } from './onchange-handler-registration';
import { adaptIssueViewPerFieldHook } from './utils';

type Props = {
	issueKey: IssueKey;
	issueId: IssueId;
	fieldsConfig: IssueConfiguration | null;
};

export const getHooksLookup = memoizeOne(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(): Record<FieldType, UseFieldHook<any, any, any, any>> => ({
		[SUMMARY_TYPE]: adaptIssueViewPerFieldHook({
			hook: useSummaryField,
			saveFnName: 'saveValue',
		}),
		[DESCRIPTION_TYPE]: adaptIssueViewPerFieldHook({
			hook: useDescriptionField,
			saveFnName: 'saveValue',
		}),
		[ASSIGNEE_TYPE]: adaptIssueViewPerFieldHook({
			hook: useAssigneeField,
			saveFnName: 'saveValue',
		}),
		[PRIORITY_TYPE]: adaptIssueViewPerFieldHook({
			hook: usePriorityField,
			saveFnName: 'saveById',
		}),
		[URL_CF_TYPE]: adaptIssueViewPerFieldHook({
			hook: useUrlCustomField,
			saveFnName: 'saveValue',
		}),
		[TEXT_CF_TYPE]: adaptIssueViewPerFieldHook({
			hook: useTextCustomField,
			saveFnName: 'saveValue',
		}),
		[MULTI_CHECKBOXES_CF_TYPE]: adaptIssueViewPerFieldHook({
			hook: useCheckboxSelectField,
			saveFnName: 'saveById',
		}),
		[TEXT_AREA_CF_TYPE]: adaptIssueViewPerFieldHook({
			hook: useParagraphField,
			saveFnName: 'saveValue',
		}),
		[SELECT_CF_TYPE]: adaptIssueViewPerFieldHook({
			hook: useSingleSelectField,
			saveFnName: 'saveValue',
		}),
		[MULTI_SELECT_CF_TYPE]: adaptIssueViewPerFieldHook({
			hook: useMultiSelectField,
			saveFnName: 'saveById',
		}),
		[RADIO_BUTTONS_CF_TYPE]: adaptIssueViewPerFieldHook({
			hook: useRadioSelectCustomField,
			saveFnName: 'saveById',
		}),
		[FIX_VERSIONS_TYPE]: adaptIssueViewPerFieldHook({
			hook: function useVersions(props) {
				return useVersionsFields({ ...props, fieldKey: FIX_VERSIONS_TYPE });
			},
			saveFnName: 'saveById',
		}),
		[NUMBER_CF_TYPE]: adaptIssueViewPerFieldHook({
			hook: useNumberField,
			saveFnName: 'saveValue',
		}),
		[DATE_CF_TYPE]: adaptIssueViewPerFieldHook({
			hook: useDateField,
			saveFnName: 'saveValue',
		}),
		[LABELS_TYPE]: adaptIssueViewPerFieldHook({
			hook: useLabelsField,
			saveFnName: 'saveValue',
		}),
		[DATETIME_CF_TYPE]: adaptIssueViewPerFieldHook({
			hook: useDateTimeField,
			saveFnName: 'saveValue',
		}),
		[USER_CF_TYPE]: adaptIssueViewPerFieldHook({
			hook: useUserCustomField,
			saveFnName: 'saveValue',
		}),
		[REPORTER_TYPE]: adaptIssueViewPerFieldHook({
			hook: useReporterField,
			saveFnName: 'saveValue',
		}),
		[COMPONENTS_TYPE]: adaptIssueViewPerFieldHook({
			hook: useComponentsField,
			saveFnName: 'saveById',
		}),
		[MULTI_USER_CF_TYPE]: adaptIssueViewPerFieldHook({
			hook: useMultiUserCustomField,
			saveFnName: 'saveValue',
		}),
		[PARENT_TYPE]: adaptIssueViewPerFieldHook<
			ParentFieldOption | null,
			null,
			ParentFieldResponse,
			ParentIdShape | null
		>({
			hook: useParentField,
			saveFnName: 'saveById',
		}),
		[AFFECTS_VERSIONS_TYPE]: adaptIssueViewPerFieldHook({
			hook: useVersionsFields,
			saveFnName: 'saveById',
		}),
		[PEOPLE_CF_TYPE]: adaptIssueViewPerFieldHook({
			hook: usePeopleCustomField,
			saveFnName: 'saveValue',
		}),
		[STATUS_TYPE]: adaptIssueViewPerFieldHook({
			hook: useStatusField,
			saveFnName: 'saveById',
		}),
		...(ff('ui-modifications-cascading-select-field-support-_bjjiv')
			? {
					[CASCADING_SELECT_CF_TYPE]: adaptIssueViewPerFieldHook({
						// TODO: Remove `as any` from type on ui-modifications-cascading-select-field-support-_bjjiv FF clean up.
						// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
						hook: useCascadeSelectField as any,
						saveFnName: 'saveValue',
					}),
				}
			: // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
				({} as Record<typeof CASCADING_SELECT_CF_TYPE, UseFieldHook<any, any, any, any>>)),

		...(fg('uim-orginal-estimate-field-support_ugzqt')
			? {
					[TIME_ESTIMATE_TYPE]: adaptIssueViewPerFieldHook({
						// TODO: Remove `as any` from type on uim-orginal-estimate-field-support_ugzqt FF clean up.

						hook: useOriginalEstimateFieldWithTimeTracking,
						saveFnName: 'saveValue',
					}),
				}
			: // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
				({} as Record<typeof TIME_ESTIMATE_TYPE, UseFieldHook<any, any, any, any>>)),
	}),
);

export const FieldsProcessor = ({ issueKey, fieldsConfig, issueId }: Props) => {
	const isEnabled = useAdjustmentsEnabled();

	return isEnabled ? (
		<>
			<OnChangeHandlerRegistration fieldsConfig={fieldsConfig} />
			{fieldsConfig &&
				Object.entries(fieldsConfig)
					.map(([fieldId, { schema }]) => {
						const fieldType: string = schema.custom ?? schema.system;
						const isSupported =
							isFieldTypeSupported(ISSUE_VIEW_VIEW_TYPE, fieldType) &&
							!!getHooksLookup()[fieldType];

						if (!isSupported) {
							return null;
						}

						const useField = getHooksLookup()[fieldType];

						return (
							<FieldProcessor
								key={fieldId}
								issueKey={issueKey}
								issueId={issueId}
								useField={useField}
								fieldId={fieldId}
								fieldType={fieldType}
							/>
						);
					})
					.filter((x) => x !== null)}
		</>
	) : null;
};
