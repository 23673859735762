/**
 * @jsxRuntime classic
 * @jsx jsx
 */
/** @jsxFrag */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import Lozenge from '@atlaskit/lozenge';
import { Box, Text } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';

import messages from './messages';
import { footerInformationStyles, footerStyles } from './styles';
import { type Disclaimer } from './types';

// TODO: Remove during platform_editor_ai_command_palette_post_ga cleanup
export const FooterOld = ({
	showBetaLozenge,
	disclaimer,
	additionalFooterText,
	footerRightContent,
}: {
	showBetaLozenge?: boolean;
	disclaimer?: Disclaimer;
	additionalFooterText?: string;
	footerRightContent?: React.ReactNode;
}) => {
	const { formatMessage } = useIntl();

	return (
		// eslint-disable-next-line react/react-in-jsx-scope, @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values
		<div css={footerStyles}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
			<div css={footerInformationStyles}>
				{showBetaLozenge && (
					<Lozenge testId="beta-lozenge" appearance="inprogress">
						{formatMessage(messages.betaLabel)}
					</Lozenge>
				)}
				{disclaimer ? (
					<Box testId="custom-disclaimer">
						<Tooltip content={disclaimer.tooltip} position="top">
							{disclaimer.url ? (
								<a href={disclaimer.url} target="_blank" rel="noopener noreferrer">
									{disclaimer.text}
								</a>
							) : (
								<span>{disclaimer.text}</span>
							)}
						</Tooltip>
					</Box>
				) : (
					<Text as="p">
						{formatMessage(messages.disclaimer) + ' '}
						<a
							href="https://www.atlassian.com/trust/atlassian-intelligence"
							target="_blank"
							rel="noopener noreferrer"
						>
							{formatMessage(messages.disclaimerLink)}
						</a>
						{additionalFooterText && <Text as="strong">. {additionalFooterText}</Text>}
					</Text>
				)}
			</div>
			{footerRightContent}
		</div>
	);
};
