import React from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { Emphasise } from '@atlassian/jira-issue-view-activity-common/src/styles';
import GroupedChange from '../../../../common/ui/grouped-change';
import HistoryItem from '../../../../common/ui/history-item';
import type { ArchivedChangedHistoryItem } from '../../../../types';
import messages from './messages';

type Props = {
	isGrouped?: Boolean;
	historyItem: ArchivedChangedHistoryItem;
};

const ArchivedHistoryItem = ({ historyItem, isGrouped = false }: Props) => {
	const { formatMessage } = useIntl();
	const { actor, timestamp, fromValue } = historyItem;
	const isArchived = fromValue === 'false';
	return (
		<FormattedMessage
			{...(isArchived ? messages.archived : messages.unarchived)}
			values={{
				issue: (
					<Emphasise key="issue">
						<FormattedMessage {...messages.issue} />
					</Emphasise>
				),
			}}
		>
			{(...action) =>
				isGrouped && expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false) ? (
					<GroupedChange
						field={formatMessage(isArchived ? messages.archived : messages.unarchived)}
						from={null}
						to={null}
					/>
				) : (
					<HistoryItem
						testId="issue-history.ui.history-items.history-item.archived-history-item.history-item"
						actor={actor}
						action={action}
						timestamp={timestamp}
						change={null}
					/>
				)
			}
		</FormattedMessage>
	);
};

export default ArchivedHistoryItem;
