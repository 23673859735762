import type { ComponentType } from 'react';
import type { Color } from '@atlassian/data-classification-level';
import type { StatusCategory } from '@atlassian/jira-common-constants/src/status-categories';
import type { ActivitySortOrderType } from '@atlassian/jira-issue-shared-types/src/common/types/activity-sort-order-type.tsx';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

const HISTORY_GROUPED_ITEMS_CATEGORY = 'HISTORY' as const;

export type User = {
	accountId?: string;
	displayName: string;
	avatarUrl?: string;
};

export type Status = {
	statusCategory: StatusCategory;
	name: string;
};

export type Priority = {
	iconUrl: string;
	name: string;
};

export type DataClassification = {
	name: string;
	color: {
		colorKey: Color;
	};
	guidelines: string;
};

export const ASSIGNEE_CHANGED = 'ASSIGNEE_CHANGED' as const;
export const STATUS_CHANGED = 'STATUS_CHANGED' as const;
export const COMMENT_CHANGED = 'COMMENT_CHANGED' as const;
export const ARCHIVED_CHANGED = 'ARCHIVED_CHANGED' as const;
export const ATTACHMENT_CHANGED = 'ATTACHMENT_CHANGED' as const;
export const PRIORITY_CHANGED = 'PRIORITY_CHANGED' as const;
export const FLAG_CHANGED = 'FLAG_CHANGED' as const;
export const FIELD_CHANGED = 'FIELD_CHANGED' as const;
export const NUMERIC_FIELD_CHANGED = 'NUMERIC_FIELD_CHANGED' as const;
export const RICH_TEXT_FIELD_CHANGED = 'RICH_TEXT_FIELD_CHANGED' as const;
export const ISSUE_CREATED = 'ISSUE_CREATED' as const;
export const WORK_LOGGED = 'WORK_LOGGED' as const;
export const TIME_CHANGED = 'TIME_CHANGED' as const;
export const PARENT_ISSUE_CHANGED = 'PARENT_ISSUE_CHANGED' as const;
export const DATE_ISSUE_CHANGED = 'DATE_ISSUE_CHANGED' as const;
export const RESPONDERS_CHANGED = 'RESPONDERS_CHANGED' as const;
export const AFFECTED_SERVICES_CHANGED = 'AFFECTED_SERVICES_CHANGED' as const;
export const RESPONDERS_FIELD = 'Responders' as const;
export const AFFECTED_SERVICES_FIELD = 'Affected services' as const;
export const RESPONDER_TYPE_TEAM = 'team' as const;
export const RESPONDER_TYPE_USER = 'user' as const;
export const DATA_CLASSIFICATION_CHANGED = 'DATA_CLASSIFICATION_CHANGED' as const;

export interface BaseHistoryItem {
	fieldType?: 'jira' | 'custom';
}

export type GroupedHistoryItem = {
	type: typeof HISTORY_GROUPED_ITEMS_CATEGORY;
	actor: User | null;
	timestamp: Date;
	items: HistoryItem[];
};

export interface AssigneeChangedHistoryItem extends BaseHistoryItem {
	type: typeof ASSIGNEE_CHANGED;
	actor: User | null;
	timestamp: Date;
	fromUser: User | null;
	toUser: User | null;
}

export interface StatusChangedHistoryItem extends BaseHistoryItem {
	type: typeof STATUS_CHANGED;
	actor: User | null;
	timestamp: Date;
	fromStatus: Status | null;
	toStatus: Status | null;
}

export interface CommentChangedHistoryItem extends BaseHistoryItem {
	type: typeof COMMENT_CHANGED;
	actor: User | null;
	timestamp: Date;
}

export interface ArchivedChangedHistoryItem extends BaseHistoryItem {
	type: typeof ARCHIVED_CHANGED;
	actor: User | null;
	timestamp: Date;
	fromValue?: string | null;
}

export const ATTACHMENT_UPLOADED = 'ATTACHMENT_UPLOADED' as const;
export const ATTACHMENT_DELETED = 'ATTACHMENT_DELETED' as const;

export interface AttachmentChangedHistoryItem extends BaseHistoryItem {
	type: typeof ATTACHMENT_CHANGED;
	actor: User | null;
	timestamp: Date;
	filename: string;
	action: typeof ATTACHMENT_UPLOADED | typeof ATTACHMENT_DELETED;
}

export interface PriorityChangedHistoryItem extends BaseHistoryItem {
	type: typeof PRIORITY_CHANGED;
	actor: User | null;
	timestamp: Date;
	fromPriority: Priority;
	toPriority: Priority;
}

export const FLAG_ADDED = 'FLAG_ADDED' as const;
export const FLAG_REMOVED = 'FLAG_REMOVED' as const;

export interface FlagChangedHistoryItem extends BaseHistoryItem {
	type: typeof FLAG_CHANGED;
	actor: User | null;
	timestamp: Date;
	action: typeof FLAG_ADDED | typeof FLAG_REMOVED;
}

export interface FieldChangedHistoryItem extends BaseHistoryItem {
	type: typeof FIELD_CHANGED;
	actor: User | null;
	field: string;
	timestamp: Date;
	fromValue: string | null;
	toValue: string | null;
}

export interface RichTextFieldChangedHistoryItem extends BaseHistoryItem {
	type: typeof RICH_TEXT_FIELD_CHANGED;
	actor: User | null;
	field: string;
	timestamp: Date;
	fromValue: string | null;
	toValue: string | null;
}

export interface NumericFieldChangedHistoryItem extends BaseHistoryItem {
	type: typeof NUMERIC_FIELD_CHANGED;
	actor: User | null;
	field: string;
	timestamp: Date;
	fromValue: number | null;
	toValue: number | null;
}

export interface ParentIssueChangedHistoryItem extends BaseHistoryItem {
	type: typeof PARENT_ISSUE_CHANGED;
	actor: User | null;
	timestamp: Date;
	fromValue: string | null;
	toValue: string | null;
}

export interface IssueCreatedHistoryItem extends BaseHistoryItem {
	type: typeof ISSUE_CREATED;
	actor: User | null;
	timestamp: Date;
}

export interface WorkLoggedHistoryItem extends BaseHistoryItem {
	type: typeof WORK_LOGGED;
	actor: User | null;
	timestamp: Date;
	comment: string;
	timeSpent: string;
}

export type TimeTrackingConfig = {
	daysPerWeek: number;
	hoursPerDay: number;
};

export interface TimeChangedHistoryItem extends BaseHistoryItem {
	type: typeof TIME_CHANGED;
	actor: User | null;
	field: string;
	timestamp: Date;
	timeTrackingConfig: TimeTrackingConfig | null;
	fromTime: number | null;
	toTime: number | null;
}

export interface DateChangedHistoryItem extends BaseHistoryItem {
	type: typeof DATE_ISSUE_CHANGED;
	actor: User | null;
	field: string;
	timestamp: Date;
	fromDate: string | null;
	toDate: string | null;
	fieldSchema: string;
}

export type Responder = {
	ari: string;
	name: string;
	avatarUrl: string;
	type: typeof RESPONDER_TYPE_TEAM | typeof RESPONDER_TYPE_USER;
};

export interface RespondersChangedHistoryItem extends BaseHistoryItem {
	type: typeof RESPONDERS_CHANGED;
	actor: User | null;
	field: string;
	timestamp: Date;
	fromValue: Responder[] | null;
	toValue: Responder[] | null;
}

export interface AffectedServicesChangedHistoryItem extends BaseHistoryItem {
	type: typeof AFFECTED_SERVICES_CHANGED;
	actor: User | null;
	field: string;
	timestamp: Date;
	fromValue: string | null;
	toValue: string | null;
	fromDisplayValue: string | null;
	toDisplayValue: string | null;
}

export interface DataClassificationChangedHistoryItem extends BaseHistoryItem {
	type: typeof DATA_CLASSIFICATION_CHANGED;
	actor: User | null;
	field: string;
	timestamp: Date;
	fromClassification: DataClassification | null;
	toClassification: DataClassification | null;
}

export type HistoryItem =
	| AssigneeChangedHistoryItem
	| StatusChangedHistoryItem
	| CommentChangedHistoryItem
	| ArchivedChangedHistoryItem
	| AttachmentChangedHistoryItem
	| PriorityChangedHistoryItem
	| FlagChangedHistoryItem
	| FieldChangedHistoryItem
	| RichTextFieldChangedHistoryItem
	| NumericFieldChangedHistoryItem
	| TimeChangedHistoryItem
	| WorkLoggedHistoryItem
	| IssueCreatedHistoryItem
	| ParentIssueChangedHistoryItem
	| DateChangedHistoryItem
	| RespondersChangedHistoryItem
	| AffectedServicesChangedHistoryItem
	| DataClassificationChangedHistoryItem
	| GroupedHistoryItem;

export type Props = {
	issueKey: IssueKey;
	baseUrl: BaseUrl;
	timeZone?: string;
	sortOrder: ActivitySortOrderType;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	GroupedActivityEntryComponent?: ComponentType<any>;
};
