/**
 * @generated SignedSource<<8f9e4179a4c23b69abc3b7e281f0c1fe>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueFieldDataClassification_IssueViewDataClassification$data = {
  readonly fields: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: "JiraDataClassificationField";
        readonly " $fragmentSpreads": FragmentRefs<"ui_issueClassificationBadge_IssueClassificationBadgeNew_dataClassificationFragment" | "ui_issueClassificationBadge_IssueClassificationBadgeOld_dataClassificationFragment">;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ui_issueFieldDataClassification_IssueViewDataClassification";
};
export type ui_issueFieldDataClassification_IssueViewDataClassification$key = {
  readonly " $data"?: ui_issueFieldDataClassification_IssueViewDataClassification$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueFieldDataClassification_IssueViewDataClassification">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueFieldDataClassification_IssueViewDataClassification",
  "selections": [
    {
      "concreteType": "JiraIssueFieldConnection",
      "kind": "LinkedField",
      "name": "fields",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueFieldEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "__typename"
                    },
                    {
                      "kind": "FragmentSpread",
                      "name": "ui_issueClassificationBadge_IssueClassificationBadgeOld_dataClassificationFragment"
                    },
                    {
                      "kind": "FragmentSpread",
                      "name": "ui_issueClassificationBadge_IssueClassificationBadgeNew_dataClassificationFragment"
                    }
                  ],
                  "type": "JiraDataClassificationField"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "e74d192b732b6f7431bd7225d6d5a25f";

export default node;
