/**
 * @generated SignedSource<<051c0f22470b8cf4d49de70f6e4b18cb>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal$data = {
  readonly __typename: string;
  readonly fieldId: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"relayLayoutItems_issueViewLayoutTemplatesLayoutItem_RelayLayoutItemFragmentContainer">;
  readonly " $fragmentType": "src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal";
};
export type src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal$key = {
  readonly " $data"?: src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal$data;
  readonly " $fragmentSpreads": FragmentRefs<"src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayAssigneeFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayCascadingSelectFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayDateFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayDateTimeFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayGoalsFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayNumberFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayPeopleAndApproversFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayPriorityFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayProjectFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelaySingleLineTextFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelaySprintFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayUrlFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "jsmSentimentCustomFieldFlag"
    }
  ],
  "kind": "Fragment",
  "name": "src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "issueViewRelayAssigneeFieldFlag",
          "variableName": "issueViewRelayAssigneeFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelayCascadingSelectFlag",
          "variableName": "issueViewRelayCascadingSelectFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelayDateFieldFlag",
          "variableName": "issueViewRelayDateFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelayDateTimeFieldFlag",
          "variableName": "issueViewRelayDateTimeFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelayGoalsFlag",
          "variableName": "issueViewRelayGoalsFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelayNumberFieldFlag",
          "variableName": "issueViewRelayNumberFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelayPeopleAndApproversFieldFlag",
          "variableName": "issueViewRelayPeopleAndApproversFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelayPriorityFieldFlag",
          "variableName": "issueViewRelayPriorityFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelayProjectFieldFlag",
          "variableName": "issueViewRelayProjectFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelaySingleLineTextFieldFlag",
          "variableName": "issueViewRelaySingleLineTextFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelaySprintFieldFlag",
          "variableName": "issueViewRelaySprintFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelayUrlFieldFlag",
          "variableName": "issueViewRelayUrlFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "jsmSentimentCustomFieldFlag",
          "variableName": "jsmSentimentCustomFieldFlag"
        }
      ],
      "kind": "FragmentSpread",
      "name": "relayLayoutItems_issueViewLayoutTemplatesLayoutItem_RelayLayoutItemFragmentContainer"
    }
  ],
  "type": "JiraIssueField",
  "abstractKey": "__isJiraIssueField"
};

(node as any).hash = "604b11b57707478f5626b05b4b5891b4";

export default node;
