import React from 'react';
import memoizeOne from 'memoize-one';
import { useFragment, graphql } from 'react-relay';
import Avatar from '@atlaskit/avatar';
import { useIntl } from '@atlassian/jira-intl';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import { transformToSuggestions } from '@atlassian/jira-issue-field-people-form-field/src/ui/people-field/index.tsx';
import type { AggUser } from '@atlassian/jira-issue-user-picker-edit-view/src/common/types';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages';
import TagView from '@atlassian/jira-issue-view-internal-tagview/src/tag-view';
import type { people_issueFieldPeopleReadviewFull_PeopleReadView$key as PeopleFragment } from '@atlassian/jira-relay/src/__generated__/people_issueFieldPeopleReadviewFull_PeopleReadView.graphql';
import type { PeopleReadViewProps } from './types';

/**
 * The PeopleReadView will show a read only view of People field.
 * @param props [PeopleReadViewProps](./types.tsx)
 */

const toTags = memoizeOne((users: ReadonlyArray<AggUser>) =>
	users.map((user) => ({
		value: user.id,
		content: user.name,
		elemBefore: <Avatar size="xsmall" src={user.picture} />,
	})),
);

export const PeopleReadView = ({ fragmentRef, shouldHideLinks }: PeopleReadViewProps) => {
	const { formatMessage } = useIntl();

	const data = useFragment<PeopleFragment>(
		graphql`
			fragment people_issueFieldPeopleReadviewFull_PeopleReadView on JiraPeopleField {
				selectedUsersConnection {
					# eslint-disable-next-line @atlassian/relay/unused-fields
					edges {
						node {
							accountId
							id
							accountStatus
							name
							picture
							... on AtlassianAccountUser {
								email
							}
						}
					}
				}
			}
		`,
		fragmentRef,
	);

	const defaultValue = transformToSuggestions(data.selectedUsersConnection);

	if (!defaultValue) {
		return <EmptyFieldText />;
	}

	return (
		<TagView
			value={toTags(defaultValue)}
			noValueText={formatMessage(genericMessages.noValue)}
			tagAppearance="rounded"
			shouldHideLinks={shouldHideLinks}
			isUserTag
		/>
	);
};
