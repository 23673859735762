// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	successFlagMessage: {
		id: 'issue.command-palette-assignee.use-save-assignee-field.success-flag-message',
		defaultMessage: 'Assignee updated',
		description: 'Toast flag that will be displayed when user changes the assignee of the issue',
	},
	errorFlagTitle: {
		id: 'issue.command-palette-assignee.use-save-assignee-field.error-flag-title',
		defaultMessage: 'We couldn’t update the assignee',
		description: 'Title of the toast flag if something goes wrong tying to save the new assignee',
	},
	errorFlagDescription: {
		id: 'issue.command-palette-assignee.use-save-assignee-field.error-flag-description',
		defaultMessage: 'Wait a few moments, then try again.',
		description:
			'Description of the toast flag if something goes wrong tying to save the new assignee',
	},
});
