import type {
	FieldInternalMetadata,
	FieldMapInternalMetadata,
} from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/types/field-internal-metadata';
import type {
	FieldChangesMapPublic,
	FieldChangesPublic,
} from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/types/fields/field-changes';
import { FIELD_HISTORY_TYPE } from '../../../constants';
import type { HistorySingleAppChange } from '../../../types';

const normalizeFieldForHistory = (
	fieldId: string,
	fieldChanges: FieldChangesPublic,
	fieldMetadata: FieldInternalMetadata,
): HistorySingleAppChange => {
	// Can't type Object.keys in a better way
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return (Object.keys(fieldChanges) as (keyof FieldChangesPublic)[]).reduce<HistorySingleAppChange>(
		(acc, property) => {
			const normalizedKey: keyof HistorySingleAppChange = `${fieldId}|${property}`;

			acc[normalizedKey] = {
				fieldId,
				fieldType: fieldMetadata.fieldType,
				property,
				type: FIELD_HISTORY_TYPE,
			};

			return acc;
		},
		{},
	);
};

type Payload = {
	fieldsChanges: FieldChangesMapPublic;
	internalFormMetadata: FieldMapInternalMetadata;
};

export const normalizeFieldsAppliedChangesForHistory = ({
	fieldsChanges,
	internalFormMetadata,
}: Payload): HistorySingleAppChange => {
	return Object.entries(fieldsChanges).reduce<HistorySingleAppChange>(
		(acc, [fieldId, fieldChanges]: [string, FieldChangesPublic]) => {
			const normalizedFieldHistory = normalizeFieldForHistory(
				fieldId,
				fieldChanges,
				internalFormMetadata[fieldId],
			);

			Object.assign(acc, normalizedFieldHistory);

			return acc;
		},
		{},
	);
};
