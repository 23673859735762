import React, { type ReactNode, useContext, useMemo } from 'react';
import { ProfilerContext as RLLProfilerContext } from 'react-loosely-lazy';
import PlatformUFOInteractionIgnore from '@atlaskit/ufo-interaction-ignore';

/**
 * Jira wrapper for platform/packages/react-ufo/ufo-interaction-ignore - incorporates ignoring of react-loosely-lazy profiler
 *
 * Prevent a subtree from holding up an interaction
 * Use this when you have a component which loads in late, but
 * isn't considered to be a breach of SLO
 *
 * ```js
 * <App>
 *   <Main />
 *   <Sidebar>
 *     <UFOInteractionIgnore>
 *       <InsightsButton />
 *     </UFOInteractionIgnore>
 *   </Sidebar>
 * </App>
 * ```
 *
 * Has an `ignore` prop, to allow you to use it conditionally
 */
export default function UFOInteractionIgnore({
	children,
	ignore = true,
}: {
	children?: ReactNode;
	ignore?: boolean;
}) {
	const parentProfilerContext = useContext(RLLProfilerContext);

	const ignoredRLLProfilerContext = useMemo(
		() => ({
			...parentProfilerContext,
			// Replace with lodash/noop
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			placeholderFallBackMounted() {},
			// Replace with lodash/noop
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			placeholderFallBackUnmounted() {},
		}),
		[parentProfilerContext],
	);

	// react-18: Use children directly
	const kids = children != null ? children : null;

	if (!ignore) {
		return <>{kids}</>;
	}

	return (
		<PlatformUFOInteractionIgnore ignore={ignore}>
			<RLLProfilerContext.Provider value={ignoredRLLProfilerContext}>
				{kids}
			</RLLProfilerContext.Provider>
		</PlatformUFOInteractionIgnore>
	);
}
