import React, { useCallback } from 'react';
import type { Dispatch } from 'redux';
import { createSelector } from 'reselect';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { AnalyticsSubject } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import { DONE } from '@atlassian/jira-common-constants/src/status-categories';
import { useIntl } from '@atlassian/jira-intl';
import type { TimeTrackingState } from '@atlassian/jira-issue-shared-types/src/common/types/time-tracking-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import type { FormValues } from '@atlassian/jira-issue-view-common-types/src/log-time-modal-type';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { mentionProviderSelector } from '@atlassian/jira-issue-view-services/src/mentions/mention-provider-selector.tsx';
import {
	addWorklogRequest,
	type AddWorklogRequestAction,
	updateTimeRemainingRequest,
	type UpdateTimeRemainingRequestAction,
	closeModal,
	type CloseModalAction,
} from '@atlassian/jira-issue-view-store/src/common/actions/worklog-actions';
import {
	loggedInUserTimeZoneSelector,
	isClassicProjectSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import { mediaContextSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/media-context-selector';
import {
	timeTrackingSelector,
	timeTrackingWithSubtasksOrChildrenRollupSelector,
	modalIsSavingSelector,
	timeTrackingConfigurationSelector,
	shouldDisplayRollUpDataControlSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/time-tracking-selector';
import { statusCategorySelector } from '@atlassian/jira-issue-view-store/src/selectors/status-selector';
import { useWorklogRollingUpData } from '@atlassian/jira-worklog-time-tracking/src/services/context/index.tsx';
import AddModal, { type OwnProps, type StateProps, type DispatchProps } from './view';

type ReduxStateProps = Omit<
	StateProps & {
		timeTrackingValue: TimeTrackingState;
		rolledUpTimeTrackingValue: TimeTrackingState;
	},
	'timeTracking' | 'rolledUpTimeTracking' | 'isRollingUpData'
>;

type ReduxDispatchProps = Omit<DispatchProps, 'onToggleRollingUpData'>;

type WrappedWorklogModalProps = Omit<ReduxStateProps & ReduxDispatchProps & OwnProps, 'intl'>;

const WrappedWorklogModal = (props: WrappedWorklogModalProps) => {
	const intl = useIntl();
	const { timeTrackingValue, rolledUpTimeTrackingValue, ...viewProps } = props;
	const [isRollingUpData, { markRollingUpData, unmarkRollingUpData }] = useWorklogRollingUpData();

	const onToggleRollingUpData = useCallback(
		(includeChildIssues: boolean) => {
			includeChildIssues ? markRollingUpData() : unmarkRollingUpData();
		},
		[markRollingUpData, unmarkRollingUpData],
	);

	return (
		<AddModal
			{...viewProps}
			intl={intl}
			timeTracking={timeTrackingValue}
			rolledUpTimeTracking={rolledUpTimeTrackingValue}
			isRollingUpData={isRollingUpData}
			onToggleRollingUpData={onToggleRollingUpData}
		/>
	);
};

const AddModalWithAnalyticsAndIntl = withFireUiAnalytics({
	onSubmit: 'submitAddWorklog',
	onCancel: 'cancelAddWorklog',
})(AnalyticsSubject('worklog')(WrappedWorklogModal));

type Action = AddWorklogRequestAction | UpdateTimeRemainingRequestAction | CloseModalAction;

const rolledUpTimeTrackingValueMemoized = () =>
	createSelector(
		(state: State) => timeTrackingWithSubtasksOrChildrenRollupSelector(state)(true),
		(res) => res,
	);
const mapStateToPropsFactory = () => {
	const rolledUpTimeTrackingValueInstance = rolledUpTimeTrackingValueMemoized();
	return (state: State): ReduxStateProps => ({
		timeTrackingConfiguration: timeTrackingConfigurationSelector(state),
		rolledUpTimeTrackingValue: rolledUpTimeTrackingValueInstance(state),
		timeTrackingValue: timeTrackingSelector(state),
		timeZone: loggedInUserTimeZoneSelector(state),
		isDone: statusCategorySelector(state) === DONE,
		isSaving: modalIsSavingSelector(state),
		mediaContext: mediaContextSelector(state),
		mentionProvider: mentionProviderSelector(state),
		shouldDisplayRollUpDataControl: shouldDisplayRollUpDataControlSelector(state),
		isClassicProject: isClassicProjectSelector(state),
	});
};

export default connect(
	mapStateToPropsFactory,
	(dispatch: Dispatch<Action>): ReduxDispatchProps => ({
		onSubmit: (values: FormValues, analyticsEvent: UIAnalyticsEvent) => {
			const { timeLogged, timeRemaining } = values || {};
			if (!timeLogged && timeRemaining) {
				dispatch(updateTimeRemainingRequest(timeRemaining, analyticsEvent));
			} else {
				dispatch(addWorklogRequest(values, analyticsEvent));
			}
		},
		onCancel: () => {
			dispatch(closeModal());
		},
	}),
)(AddModalWithAnalyticsAndIntl);
