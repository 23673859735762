// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	move: {
		id: 'issue.move',
		defaultMessage: 'Move',
		description: 'Label for the issue actions item to move the issue',
	},
});
