// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';

import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const submitButtonStyles = css({
	marginLeft: 'auto',
	paddingRight: token('space.100', '8px'),
	paddingLeft: token('space.100', '8px'),
	marginRight: token('space.075', '6px'),
});
