import type {
	Priority,
	ServerRestPriority,
} from '@atlassian/jira-issue-shared-types/src/common/types/priority-type.tsx';
import type { PrioritySuggestionsArray } from './types';

const toItem = (priority: ServerRestPriority): Priority => ({
	content: priority.name,
	value: priority.id,
	iconUrl: priority.iconUrl,
});

export const transformFromStateValue = (stateValue: ServerRestPriority | null): Priority | null =>
	stateValue && toItem(stateValue);

export const transformToStateValue = (value: Priority | null): ServerRestPriority | null =>
	value && {
		name: value.content,
		id: value.value,
		iconUrl: value.iconUrl,
	};

export const transformSuggestions = (
	suggestionsFromServer: ServerRestPriority[],
): PrioritySuggestionsArray => [
	{
		items: suggestionsFromServer.map(toItem),
	},
];
