import { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type {
	ClientEventValidator,
	ClientValidator,
} from '@atlassian/jira-issue-field-validation-handler/src/controllers/client-validation-handler/types.tsx';
import { messages } from './messages';
import type { JiraDatePickerValue } from './types';

export const useDateValidator = (
	fieldName: string,
	isRequired: boolean,
): ClientEventValidator<JiraDatePickerValue> => {
	const { formatMessage } = useIntl();

	const submit: ClientValidator<JiraDatePickerValue> = useCallback(
		(value: JiraDatePickerValue) => {
			if (isRequired && (!value || value.trim().length === 0)) {
				return {
					type: 'error',
					message: formatMessage(messages.requiredErrorMessage, { fieldName }),
				};
			}

			return null;
		},
		[fieldName, formatMessage, isRequired],
	);

	return { submit };
};
