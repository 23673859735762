import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log';

const localStorage = createLocalStorageProvider('issue-ecosystem');

const COLLAPSED_PANELS = 'collapsed-panels' as const;

const LOG_LOCATION = 'issue.ecosystem.local-storage';

export const getCollapsedPanels = (): string[] => {
	try {
		return localStorage.get(COLLAPSED_PANELS) || [];
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		log.safeErrorWithoutCustomerData(LOG_LOCATION, `getCollapsedPanels failed: ${error}`, error);

		return [];
	}
};

export const setCollapsedPanel = (contentPanelKey: string) => {
	const storedKeys = getCollapsedPanels();

	try {
		localStorage.set(COLLAPSED_PANELS, [...storedKeys, contentPanelKey]);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		log.safeErrorWithoutCustomerData(LOG_LOCATION, `setCollapsedPanel failed: ${error}`, error);
	}
};

export const unsetCollapsedPanel = (contentPanelKey: string) => {
	const storedKeys = getCollapsedPanels();

	try {
		localStorage.set(
			COLLAPSED_PANELS,
			storedKeys.filter((key) => key !== contentPanelKey),
		);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		log.safeErrorWithoutCustomerData(LOG_LOCATION, `unsetCollapsedPanel failed: ${error}`, error);
	}
};
