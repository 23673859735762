import flowRight from 'lodash/flowRight';
import memoizeOne from 'memoize-one';
import { defineMessages } from '@atlassian/jira-intl';
import RadioInlineEditView from '@atlassian/jira-issue-internal-fields/src/radio-select/radio-inline-edit-view';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages';
import type { AllowedValuesOverrides } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field';
import { withExtraOwnProps } from '@atlassian/jira-issue-view-common-views/src/with-extra-own-props';
import {
	isFieldRequiredSelector,
	fieldAllowedValuesSelector,
	fieldNameSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector';
import { filterAllowedValues } from '../../common/utils/filter-select-allowed-values';
import {
	transformFromStateValue,
	transformToStateValue,
	transformSuggestions,
	type RadioOption,
} from './radio-select-transformer';

// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
const messages = defineMessages({
	placeholder: {
		id: 'issue.radio-select.text-for-dropdown-when-nothing-selected',
		defaultMessage: 'Select {fieldName}',
		description: '',
	},
});

export const filterValues = memoizeOne(
	(values: RadioOption[], allowedValuesOverrides?: AllowedValuesOverrides) =>
		transformSuggestions(filterAllowedValues<RadioOption>(values, allowedValuesOverrides)),
);

const RadioButton = flowRight(
	withExtraOwnProps,
	connectField((stateOnMount, ownPropsOnMount) => ({
		fieldId: ownPropsOnMount.fieldId,
		transformFromStateValue,
		transformToStateValue,
		additionalProps: (state, intl, fieldOptions, extraOwnProps) => ({
			options: filterValues(
				fieldAllowedValuesSelector(ownPropsOnMount.fieldId)(state),
				extraOwnProps?.allowedValuesOverrides,
			),
			placeholder: intl.formatMessage(messages.placeholder, {
				fieldName: fieldNameSelector(ownPropsOnMount.fieldId)(state),
			}),
			noValueText: intl.formatMessage(genericMessages.noValue),
			showPinButton: getShowPinButton(ownPropsOnMount.area),
			isRequired: isFieldRequiredSelector(ownPropsOnMount.fieldId)(state),
		}),
	})),
)(RadioInlineEditView);

export default RadioButton;
