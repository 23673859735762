/**
 * We may eventually need to split the Jira presets out to the various
 * - JSW
 * - JSM
 * - JWM
 * - JPD
 *
 * Variations, however we'll start simple for now.
 */
/**
 * Please reference
 * https://hello.atlassian.net/wiki/spaces/CONF/pages/2714833063/Gen+AI+in+Editor+EAP2+-+Product-specific+requirements
 * Also see: https://hello.atlassian.net/wiki/spaces/EDAI/pages/2731933104/Editor+AI+EAP+Part+2+Dev+Documentation+Prebuilt+Providers+Plugin+Presets
 */

import { fg } from '@atlaskit/platform-feature-flags';

import type { EditorPluginAIConfigItemMarkdown } from '../config-items/config-items';
import { type AIPlugin, EditorPluginAI, NextEditorPluginAI } from '../editor-plugin-ai';
import { createEditorPluginAIProvider } from '../provider/provider';
import type { ActionOverrides, ActionSideEffects, EditorPluginAIProvider } from '../types';

import { brainstormConfigItem } from './config-items-empty/brainstorm/brainstorm';
import { generateContentConfigItem } from './config-items-empty/generate-content/generate-content';
import { suggestResponseConfigItem } from './config-items-empty/suggest-response/suggest-response';
import { summarizePageConfigItem } from './config-items-empty/summarize-page/summarize-page';
import { translationEmptyConfigItems } from './config-items-empty/translate/translate';
import {
	casualTone,
	educationalTone,
	empatheticTone,
	neutralTone,
	professionalTone,
} from './config-items-range/change-tone/change-tone';
import { fixSpellingAndGrammar, improveWriting } from './config-items-range/enhance/enhance';
import { generateFromSelectionConfigItem } from './config-items-range/generate-from-selection/generate-from-selection';
import type { GetAdditionalProps } from './config-items-range/reformat-with-template/reformat-with-template';
import { reformatWithTemplateConfigItem } from './config-items-range/reformat-with-template/reformat-with-template';
import { shortenConfigItem } from './config-items-range/shorten/shorten';
import { summarizeConfigItem } from './config-items-range/summarize/summarize';
import { translationRangeConfigItems } from './config-items-range/translate/translate';
import type { BaseCreateProviderProps, CreatePrebuiltEditorPluginAI } from './types';
import { _getFetchCustomHeaders, deriveProactiveAIConfig } from './utils';

// Jira
/**
 * This is written here as a "wrapper" in order to retain control of
 * the other values passed into the provider, e.g. configItems,
 * etc. - due to the high level of uncertainty, we'll keep this in place until
 * past phase 3.
 *
 * (design ethos: having control/flexibility in the API)
 *
 */

type CreateEditorPluginAIProviderProps = BaseCreateProviderProps & {
	product: 'JSW' | 'JWM' | 'JSM' | 'JPD';
	allowIssueReformatter?: boolean;
	allowSuggestResponse?: boolean;
	getAdditionalProps?: GetAdditionalProps;
	issueId?: string;
	actionSideEffects?: ActionSideEffects;
	actionOverrides?: ActionOverrides;
};

export const createJiraEditorPluginAIProvider = (
	props: CreateEditorPluginAIProviderProps,
): EditorPluginAIProvider => {
	// Now deprecated - migrate to handleFeedbackSubmission
	const onExperienceEvent = props?.onExperienceEvent || {
		THUMBS_UP: () => {},
		THUMBS_DOWN: () => {},
		REVIEW_STATE_ENTERED: () => {},
		EXPERIENCE_COMPLETE: () => {},
	};

	const reformatWithTemplate =
		props.allowIssueReformatter && fg('platform.reformat-issue-with-template-in-jira_jc0pb')
			? [
					reformatWithTemplateConfigItem({
						getAdditionalProps: props.getAdditionalProps || (() => ({})),
					}),
				]
			: [];

	// Suggest response is only enabled for JSM
	const isSuggestResponseEnabled =
		props.allowSuggestResponse &&
		props.issueId !== undefined &&
		fg('platform_editor_ai_suggested_response_fg') &&
		props.product === 'JSM';

	const suggestResponse: EditorPluginAIConfigItemMarkdown<'empty'>[] = isSuggestResponseEnabled
		? [suggestResponseConfigItem(props.issueId)]
		: [];

	// Only hide brainstorm when Draft reply is made visible
	const brainstorm: EditorPluginAIConfigItemMarkdown<'empty'>[] = isSuggestResponseEnabled
		? []
		: [brainstormConfigItem];

	return createEditorPluginAIProvider({
		disableAISelectionToolbar: true,
		emptyConfig: {
			baseGenerate: generateContentConfigItem,
			getSuggestions() {
				let list = [
					...suggestResponse,
					summarizePageConfigItem,
					improveWriting,
					fixSpellingAndGrammar,
					...brainstorm,
					shortenConfigItem,
					professionalTone,
					empatheticTone,
					casualTone,
					neutralTone,
					educationalTone,
				];
				if (fg('platform.reformat-issue-with-template-in-jira_jc0pb')) {
					list = [...reformatWithTemplate, ...list];
				}

				if (fg('platform_editor_ai_translation_prompts')) {
					list.push(...translationEmptyConfigItems);
				}

				return list;
			},
		},
		rangeConfig: {
			baseGenerate: generateFromSelectionConfigItem,
			getSuggestions() {
				let list = [
					summarizeConfigItem,
					improveWriting,
					fixSpellingAndGrammar,
					shortenConfigItem,
					professionalTone,
					empatheticTone,
					casualTone,
					neutralTone,
					educationalTone,
				];
				if (fg('platform.reformat-issue-with-template-in-jira_jc0pb')) {
					list = [...reformatWithTemplate, ...list];
				}
				if (fg('platform_editor_ai_translation_prompts')) {
					list.push(...translationRangeConfigItems);
				}

				return list;
			},
		},
		generativeAIApiUrl: props.generativeAIApiUrl,
		product: props.product,
		getFetchCustomHeaders: props?.getFetchCustomHeaders || _getFetchCustomHeaders,
		onExperienceEvent,
		handleFeedbackSubmission: props?.handleFeedbackSubmission,
		AIButtonWrapper: props.AIButtonWrapper,
		proactiveAIToolbarButtonWrapper: props.proactiveAIToolbarButtonWrapper,
		PromptEditor: props.PromptEditor,
		disableInterrogation: props.disableInterrogation ?? false,
		proactiveAIConfig: {
			...deriveProactiveAIConfig(),
			...props.proactiveAIConfig,
		},
		getChannelVariables: props.getChannelVariables,
		actionSideEffects: props.actionSideEffects,
		actionOverrides: props.actionOverrides,
		isRovoEnabled: props.isRovoEnabled ?? true,
	});
};

/**
 * Intended for use with Jira editors
 *
 */
export const createJiraEditorPluginAI: CreatePrebuiltEditorPluginAI = ({
	editorPluginAIProvider,
	/**
	 * While you don't have a aiGlobalOptIn setup, you can pass in a dummy
	 * object with the status set to 'enabled' and a no-op triggerOptInFlow
	 *
	 * @example
	 * ```ts
	 * const editorPluginAI = createJiraEditorPluginAI({
	 *  editorPluginAIProvider,
	 *  aiGlobalOptIn: { status: 'enabled', triggerOptInFlow: () => {} },
	 * });
	 * ```
	 *
	 * Before setting up the opt-in nag, you may want to refactor to use the
	 * disabled state, rather than the dynamically added plugin strategy.
	 *
	 * @example
	 * ```ts
	 * const editorPluginAI = createJiraEditorPluginAI({
	 *  editorPluginAIProvider,
	 *  aiGlobalOptIn: { status: aiOptInStatus ? 'enabled' : 'disabled', triggerOptInFlow: () => {} },
	 * });
	 * ```
	 *
	 */
	aiGlobalOptIn,
}) => {
	const editorPluginAI = new EditorPluginAI({
		editorPluginAIProvider,
		aiGlobalOptIn,
	});

	return {
		editorPluginAI,
		nextEditorPluginAI: NextEditorPluginAI,
	};
};

/**
 * Intended for use with Jira editors (when using the new editor)
 * (Currently only used for the 12-jira-clone-with-pubsub example).
 *
 */
export const PageNextEditorPlugin: AIPlugin = ({
	config: { editorPluginAIProvider, aiGlobalOptIn },
	api,
}): ReturnType<typeof NextEditorPluginAI> => {
	const nextEditorPluginAI = NextEditorPluginAI({
		config: {
			editorPluginAIProvider,
			aiGlobalOptIn: aiGlobalOptIn || {
				status: 'enabled',
				triggerOptInFlow: () => {},
			},
		},
		api,
	});
	return nextEditorPluginAI;
};
