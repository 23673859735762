import React from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import type { AiUpsellInEditorFreeModalProps } from './types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyAiUpsellInEditorFreeModal = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-ai-upsell-in-editor-free-modal" */ './index'),
	{
		ssr: false,
	},
);

export const AsyncAiUpsellInEditorFreeModal = (props: AiUpsellInEditorFreeModalProps) => (
	<ErrorBoundary
		id="asyncAiUpsellInEditorFreeModal"
		packageName="jira-growth-ai-upsell-in-editor-free-modal"
		teamName="growth-tako"
		render={() => <></>}
	>
		<LazyAiUpsellInEditorFreeModal {...props} />
	</ErrorBoundary>
);
