import React, { type ReactElement, type ReactNode } from 'react';
import { lazy } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type { Props as AsyncLazyIssueNumberFieldType } from './types';
import { NumberView as IssueNumberFieldReadonly } from './view';
import type IssueNumberFieldType from './index';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { IssueNumberFieldReadonly };

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const AsyncLazyIssueNumberField = lazy<typeof IssueNumberFieldType>(
	() => import(/* webpackChunkName: "async-number-inline-edit-view" */ './index'),
);
export const AsyncIssueNumberField = (props: AsyncLazyIssueNumberFieldType) => (
	<ErrorBoundary
		id="issue-field-number"
		packageName="jira-issue-field-number"
		render={() => (
			<IssueNumberFieldReadonly
				isEditable={props.isEditable !== undefined ? props.isEditable : false}
			/>
		)}
	>
		<Placeholder
			name="issue-field-number-readonly"
			fallback={
				<IssueNumberFieldReadonly
					isEditable={props.isEditable !== undefined ? props.isEditable : false}
				/>
			}
		>
			<AsyncLazyIssueNumberField {...props} />
		</Placeholder>
	</ErrorBoundary>
);

export type IssueNumberFieldBoundaryProps = {
	children: ReactNode;
	fallback: ReactElement | null;
	packageName: string;
	onError?: () => void;
};

export const IssueNumberFieldBoundary = ({
	children,
	fallback,
	packageName,
	onError,
}: IssueNumberFieldBoundaryProps) => (
	<JSErrorBoundary
		id="issue-number-field"
		packageName={packageName}
		fallback={fallback ? () => fallback : undefined}
		onError={onError}
	>
		<Placeholder name="issue-number-field-boundary" fallback={fallback}>
			{children}
		</Placeholder>
	</JSErrorBoundary>
);
