import { combineEpics } from 'redux-observable';
import type { OnShowFlag } from '../model/types';
import { createEpic as createAddWatcherRequestEpic } from './add-watcher-request';
import { createEpic as createFetchWatchersRequestEpic } from './fetch-watchers-request';
import { createEpic as createRemoveWatcherRequestEpic } from './remove-watcher-request';
import { createEpic as createToggleWatchingRequestEpic } from './toggle-watching-request';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ onShowFlag }: { onShowFlag: OnShowFlag }) =>
	combineEpics(
		createAddWatcherRequestEpic(onShowFlag),
		createFetchWatchersRequestEpic(onShowFlag),
		createRemoveWatcherRequestEpic(onShowFlag),
		createToggleWatchingRequestEpic(onShowFlag),
	);
