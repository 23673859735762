import React, { Component, useCallback } from 'react';
import memoize from 'lodash/memoize';
import type { ActionMeta } from '@atlassian/jira-common-components-picker/src/model';
import type {
	ServerSuggestions,
	Option,
	SelectValueShape,
} from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types';
import MultiSelectInlineEditView, {
	type Props as MultiSelectInlineEditViewProps,
} from '@atlassian/jira-issue-internal-field-select/src/multi-select-inline-edit';
import { LABELS } from '@atlassian/jira-issue-view-configurations';
import { LABELS_TYPE } from '@atlassian/jira-platform-field-config';
import type { LabelsSuggestionList } from '@atlassian/jira-shared-types/src/rest/jira/label.tsx';
import { getFieldLoadedValueSubscriber } from '@atlassian/jira-smart-field-prefetch/src/context.tsx';
import { getMemoizedPrefetchSuggestionsFactory } from './fetch-suggestions';
import type { Props, State } from './types';

// eslint-disable-next-line jira/react/no-class-components
class LabelsView extends Component<Props, State> {
	static displayName = 'LabelsView';

	state = {
		data: [],
		loading: false,
		error: false,
	};

	componentDidMount() {
		this.props.initFieldOptions();
		this.getDataFromCache();
	}

	getDataFromCache = () => {
		this.props.getDataFromCache?.().then((data: ServerSuggestions) => {
			this.setState({ data, loading: false });
		});
	};

	fetchSuggestions =
		(fetchSuggestions: (arg1: string, arg2: string | undefined) => Promise<ServerSuggestions>) =>
		(query: string, sessionId?: string) =>
			fetchSuggestions(query, sessionId).then((data) => {
				this.setState({ data });
				return data;
			});

	onEditRequest = () => {
		this.props.onEditRequest();
	};

	onConfirm = () => {
		this.props.onConfirm();
	};

	onCancel = () => {
		this.props.onCancel();
	};

	onChange = (selectedValues: SelectValueShape[], _actionMeta?: ActionMeta<Option>): void => {
		this.props.onChange(selectedValues);
	};

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onDataRequest = ({ isInitial: _ }: { isInitial: boolean }): void => {};

	// go/jfe-eslint
	// @ts-expect-error - TS2741 - Property 'isInitial' is missing in type '{}' but required in type '{ isInitial: boolean; }'.
	onDataLoaded = ({
		isInitial: _,
	}: {
		isInitial: boolean;
		// eslint-disable-next-line @typescript-eslint/no-empty-function
	} = {}): void => {};

	renderMultiSelectInlineEditView(props: Props) {
		const { initFieldOptions, issueKey, createAnalyticsEvent, formatMessage, ...otherProps } =
			props;

		const instrumentationProps: Partial<MultiSelectInlineEditViewProps> =
			this.props.fieldId === LABELS_TYPE
				? {
						onEditRequest: this.onEditRequest,
						onConfirm: this.onConfirm,
						onCancel: this.onCancel,
						onChange: this.onChange,
						onDataRequest: this.onDataRequest,
						onDataLoaded: this.onDataLoaded,
					}
				: {};

		const { data, loading, error } = this.state;
		const initialData = { data, loading, error };
		const fetchSuggestionsFromProps = this.fetchSuggestions(props.fetchSuggestions);

		return (
			<MemoizedLabels
				debounceFetchSuggestionsTime={400}
				initialData={initialData}
				{...otherProps}
				fetchSuggestions={fetchSuggestionsFromProps}
				{...instrumentationProps}
				issueKey={issueKey}
			/>
		);
	}

	renderWithCachedValue() {
		const { issueKey } = this.props;
		const FieldLoadedValueSubscriber = getFieldLoadedValueSubscriber<LabelsSuggestionList>(
			issueKey,
			LABELS,
		);
		return (
			<FieldLoadedValueSubscriber fieldId={LABELS}>
				{/* @ts-expect-error - TS7006 - Parameter 'prefetchResult' implicitly has an 'any' type. */}
				{(prefetchResult) => {
					const fetchSuggestions = getMemoizedPrefetchSuggestionsFactory(
						prefetchResult,
						this.props,
					);
					const newProps = {
						...this.props,
						fetchSuggestions,
					};
					return this.renderMultiSelectInlineEditView(newProps);
				}}
			</FieldLoadedValueSubscriber>
		);
	}

	render() {
		if (this.props.fieldId === LABELS) {
			return this.renderWithCachedValue();
		}
		return this.renderMultiSelectInlineEditView(this.props);
	}
}

const MemoizedLabels = (props: MultiSelectInlineEditViewProps) => {
	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const fetchSuggestions = useCallback(
		memoize(props.fetchSuggestions, (url) => `${url}_${props.fieldEditSessionId || ''}`),
		[props.fetchSuggestions, props.fieldEditSessionId, props.invalidMessage],
	);

	return <MultiSelectInlineEditView {...props} fetchSuggestions={fetchSuggestions} />;
};

export default LabelsView;
