import { useMemo } from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { useTenantHasConfluence } from '@atlassian/jira-business-confluence-pages/src/utils/use-tenant-has-confluence/index.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { ConfluencePage } from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import type { FailedRemoteLink } from '@atlassian/jira-issue-shared-types/src/common/types/remote-link-error-type.tsx';
import type { ConfluenceAppLink } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { useEmbeddedPageTracking } from '../use-embedded-page-tracking';
import {
	filterLinkableConfluenceUrls,
	filterNewConfluenceLinks,
	findUniqueLinkUrls,
	batchCreateConfluenceContentLinks,
} from './utils';

export const useLinkNewConfluenceContent = ({
	currentLinkedConfluenceContent,
	isLinkIssuesEnabled = false,
	confluenceAppLinks = [],
	onAutoLinkPagesRequest,
	onAutoLinkPagesComplete,
}: {
	currentLinkedConfluenceContent: (ConfluencePage | FailedRemoteLink)[] | undefined | null;
	isLinkIssuesEnabled?: boolean;
	confluenceAppLinks?: ConfluenceAppLink[];
	onAutoLinkPagesRequest: () => void;
	onAutoLinkPagesComplete: (confluencePageLink: (ConfluencePage | FailedRemoteLink)[]) => void;
}) => {
	const tenantHasConfluence = useTenantHasConfluence();
	const issueId = useIssueId();

	const isTenantConfiguredToBeEligibleForAutoLinkPagesExp = useMemo(
		() => tenantHasConfluence && isLinkIssuesEnabled,
		[tenantHasConfluence, isLinkIssuesEnabled],
	);

	const fireAnalyticsEvent = useEmbeddedPageTracking();

	const linkNewConfluenceContent = (description: ADF) => {
		if (!issueId) return;

		const [autoLinkPagesExperimentConfig, autoLinkPagesExperimentfireExposureEvent] =
			UNSAFE_noExposureExp('jira-issue_auto-link-pages-on-link-paste');

		const [, autoLinkPagesExperimentfireExposureEventAA] = UNSAFE_noExposureExp(
			'jira-issue_auto-link-pages-on-link-paste_aa',
		);

		const autoLinkPagesExperimentCohort = autoLinkPagesExperimentConfig.get(
			'cohort',
			'not-enrolled',
		);

		const uniqueLinkUrls = findUniqueLinkUrls(description);
		const linkableConfluenceUrls = filterLinkableConfluenceUrls(uniqueLinkUrls, confluenceAppLinks);

		const isEligibleForAutoLinkPagesExp =
			isTenantConfiguredToBeEligibleForAutoLinkPagesExp && linkableConfluenceUrls.length > 0;

		if (isEligibleForAutoLinkPagesExp) {
			autoLinkPagesExperimentfireExposureEventAA();
			autoLinkPagesExperimentfireExposureEvent();

			if (autoLinkPagesExperimentCohort === 'experiment') {
				const newLinkableConfluenceUrls = filterNewConfluenceLinks(
					linkableConfluenceUrls,
					currentLinkedConfluenceContent || [],
				);
				batchCreateConfluenceContentLinks({
					issueId,
					urls: newLinkableConfluenceUrls,
					onAutoLinkPagesRequest,
					onAutoLinkPagesComplete,
					fireAnalyticsEvent,
				});
			}
		}
	};

	return {
		linkNewConfluenceContent,
	};
};
