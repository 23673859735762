import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import type { IssueViewRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import type { IssueConfiguration } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import type { IssueFields } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import {
	type CustomItemType,
	type LayoutContainerTemplateItem,
	type LayoutContainerFieldItem,
	layoutContainerItemTypes,
	type LayoutContainerTabItem,
	CUSTOM_ITEM_TYPE,
} from '@atlassian/jira-issue-layout-common-constants';
import { getForgeFieldType } from '@atlassian/jira-issue-view-common-utils/src/layout/index.tsx';
import { isForgeUiModificationsScreenTabsSupportEnabled } from '@atlassian/jira-issue-view-feature-flags';
import { ItemList } from '@atlassian/jira-issue-view-layout-templates-item-list';
import { getAtlassianCustomFieldType } from '@atlassian/jira-issue-view-layout-templates-utils';
import { getLayoutItemId } from '@atlassian/jira-issue-view-layout/src/services/utils.tsx';
import type { LayoutFields } from '@atlassian/jira-platform-field-config';
import type { TabData } from './types';

type ExtractTabProps = {
	// TODO Decomp JIV-12514 - add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment?: IssueViewRelayFragment | null;
	items: LayoutContainerTabItem[];
	area: string;
	isTabFieldVisible: (item: LayoutContainerTemplateItem) => boolean;
	// TODO UIM-2161: make mandatory when cleaning FG 'forge_ui_modifications_for_screen_tabs'
	ItemListComponent?: typeof ItemList;
};

export type TabFieldVisibleProps = {
	item: LayoutContainerTemplateItem;
	issueFieldsConfig: IssueConfiguration | null;
	issueFieldsValues: IssueFields | undefined;
	layoutFieldTypes: LayoutFields;
};

export const isTabFieldVisibleUtil = ({
	item,
	issueFieldsConfig,
	issueFieldsValues,
	layoutFieldTypes,
}: TabFieldVisibleProps) => {
	const id = getLayoutItemId(item);

	/**
	 * This ensures that specific JSM fields are always visible
	 */
	if (item.type === CUSTOM_ITEM_TYPE) {
		return true;
	}

	const fieldConfig = issueFieldsConfig && issueFieldsConfig[id];

	if (!fieldConfig) {
		return false;
	}

	const fieldIsEmpty =
		issueFieldsValues && (issueFieldsValues[id] === null || issueFieldsValues[id] === undefined);
	const forgeCustomFieldType = getForgeFieldType(fieldConfig) || '';

	if (!fieldConfig.isEditable && fieldIsEmpty) {
		/**
		 * This ensures that read-only forge fields with no value are always
		 * visible as their value is most likely rendered using value function
		 * which is not stored in the issueFieldsValues
		 */
		if (forgeCustomFieldType) {
			return true;
		}
		return false;
	}

	const { custom, system, type } = fieldConfig.schema || {};

	const systemType = system !== null ? system : '';
	const fieldType =
		getAtlassianCustomFieldType(custom) || forgeCustomFieldType || systemType || type;

	if (fieldType === undefined) {
		return false;
	}

	return Object.values(layoutFieldTypes).includes(fieldType);
};

/**
 * We are filtering items just in case to actually be LayoutContainerTabItem[] since our BE is providing us a collection (tabs) coming through here
 * Rather than expecting LayoutContainerTemplateItem[] which includes LayoutContainerFieldItem and LayoutContainerPanelItem
 * See JRACLOUD-76139 for more information.
 */
export const extractTabArrayOld = ({
	items,
	area,
	issueViewRelayFragment,
	isTabFieldVisible,
}: ExtractTabProps) =>
	items
		.map((tab) => {
			if (tab.type !== layoutContainerItemTypes.tab) return null;

			const itemsList: (LayoutContainerFieldItem | CustomItemType)[] = tab.items.nodes.filter(
				(item: LayoutContainerTemplateItem) => isTabFieldVisible(item),
			);

			if (itemsList.length !== 0) {
				return {
					id: getLayoutItemId(tab),
					label: tab.name,
					content: (
						<Container>
							<ItemList
								items={itemsList}
								area={area}
								layoutItemsDataFragment={issueViewRelayFragment ?? null}
							/>
						</Container>
					),
				};
			}

			return null;
		})
		.filter((tab) => tab !== null);

export const extractTabArrayNew = ({
	items,
	area,
	issueViewRelayFragment,
	isTabFieldVisible,
	ItemListComponent = ItemList,
}: ExtractTabProps) =>
	items.reduce<TabData[]>((acc, tab) => {
		if (tab.type !== layoutContainerItemTypes.tab) return acc;

		const itemsList: (LayoutContainerFieldItem | CustomItemType)[] = tab.items.nodes.filter(
			(item: LayoutContainerTemplateItem) => isTabFieldVisible(item),
		);

		if (itemsList.length !== 0) {
			acc.push({
				id: getLayoutItemId(tab),
				label: tab.name,
				content: (
					<Container>
						<ItemListComponent
							items={itemsList}
							area={area}
							layoutItemsDataFragment={issueViewRelayFragment ?? null}
						/>
					</Container>
				),
			});
		}

		return acc;
	}, []);

export const extractTabArray = functionWithCondition(
	() => isForgeUiModificationsScreenTabsSupportEnabled(),
	extractTabArrayNew,
	extractTabArrayOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	paddingTop: token('space.200', '16px'),
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
});
