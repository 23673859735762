/**
 * @generated SignedSource<<03290ecb7db5a77582725a7b397705c9>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraTimeFormat = "DAYS" | "HOURS" | "PRETTY" | "%future added value";
export type JiraTimeUnit = "DAY" | "HOUR" | "MINUTE" | "WEEK" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField$data = {
  readonly __typename: "JiraTimeTrackingField";
  readonly fieldId: string;
  readonly name: string;
  readonly timeTrackingSettings: {
    readonly defaultFormat: JiraTimeFormat | null | undefined;
    readonly defaultUnit: JiraTimeUnit | null | undefined;
    readonly isJiraConfiguredTimeTrackingEnabled: boolean | null | undefined;
    readonly workingDaysPerWeek: number | null | undefined;
    readonly workingHoursPerDay: number | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField";
};
export type ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField$key = {
  readonly " $data"?: ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "concreteType": "JiraTimeTrackingSettings",
      "kind": "LinkedField",
      "name": "timeTrackingSettings",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isJiraConfiguredTimeTrackingEnabled"
        },
        {
          "kind": "ScalarField",
          "name": "workingDaysPerWeek"
        },
        {
          "kind": "ScalarField",
          "name": "workingHoursPerDay"
        },
        {
          "kind": "ScalarField",
          "name": "defaultUnit"
        },
        {
          "kind": "ScalarField",
          "name": "defaultFormat"
        }
      ]
    }
  ],
  "type": "JiraTimeTrackingField"
};

(node as any).hash = "0ff21e6ed91ef01668b9d3c9580b5ec7";

export default node;
