import type { TagColor } from '@atlaskit/tag';
import { token } from '@atlaskit/tokens';
import type { IntlShape } from '@atlassian/jira-intl';
import messages from './messages';

type Color = {
	label: string;
	value: string;
	tag: TagColor;
};

export const DEFAULT_COLOR = 'greyLight';
const DEFAULT_COLOR_VALUE = token('color.background.accent.gray.subtler', '#EBECF0');

const availableColours: TagColor[] = [
	'redLight',
	'orangeLight',
	'yellowLight',
	'greenLight',
	'blueLight',
	'purpleLight',
	DEFAULT_COLOR,
	'red',
	'orange',
	'yellow',
	'green',
	'blue',
	'purple',
	'grey',
];

export const getTagColor = (color?: string | null): TagColor =>
	(color && availableColours.find((c) => c === color)) || 'greyLight';

export const getColorValue = (
	formatMessage: IntlShape['formatMessage'],
	colorTag: string,
): string => {
	const colorPalette = getColorPickerPalette(formatMessage);
	const selectedColor = colorPalette.find((c) => c.tag === colorTag);
	return selectedColor ? selectedColor.value : DEFAULT_COLOR_VALUE;
};

export const getColorTag = (
	formatMessage: IntlShape['formatMessage'],
	colorValue: string,
): TagColor => {
	const colorPalette = getColorPickerPalette(formatMessage);
	const selectedColor = colorPalette.find((c) => c.value === colorValue);
	return selectedColor ? selectedColor.tag : DEFAULT_COLOR;
};

export const getRandomColor = (formatMessage: IntlShape['formatMessage']) => {
	const colorPalette = getColorPickerPalette(formatMessage);
	const randomColor = colorPalette[Math.floor(Math.random() * colorPalette.length)];
	return randomColor;
};

export const getColorPickerPalette = (formatMessage: IntlShape['formatMessage']): Color[] => [
	{
		label: formatMessage(messages.lightRed),
		value: token('color.background.accent.red.subtler', '#FFD5D2'),
		tag: 'redLight',
	},
	{
		label: formatMessage(messages.lightOrange),
		value: token('color.background.accent.orange.subtler', '#FEDEC8'),
		tag: 'orangeLight',
	},
	{
		label: formatMessage(messages.lightYellow),
		value: token('color.background.accent.yellow.subtler', '#F8E6A0'),
		tag: 'yellowLight',
	},
	{
		label: formatMessage(messages.lightGreen),
		value: token('color.background.accent.green.subtler', '#BAF3DB'),
		tag: 'greenLight',
	},
	{
		label: formatMessage(messages.lightBlue),
		value: token('color.background.accent.blue.subtler', '#CCE0FF'),
		tag: 'blueLight',
	},
	{
		label: formatMessage(messages.lightPurple),
		value: token('color.background.accent.purple.subtler', '#DFD8FD'),
		tag: 'purpleLight',
	},
	{
		label: formatMessage(messages.lightGrey),
		value: DEFAULT_COLOR_VALUE,
		tag: DEFAULT_COLOR,
	},
	{
		label: formatMessage(messages.red),
		value: token('color.background.accent.red.subtle', '#F87168'),
		tag: 'red',
	},
	{
		label: formatMessage(messages.orange),
		value: token('color.background.accent.orange.subtle', '#FEA362'),
		tag: 'orange',
	},
	{
		label: formatMessage(messages.yellow),
		value: token('color.background.accent.yellow.subtle', '#F5CD47'),
		tag: 'yellow',
	},
	{
		label: formatMessage(messages.green),
		value: token('color.background.accent.green.subtle', '#4BCE97'),
		tag: 'green',
	},
	{
		label: formatMessage(messages.blue),
		value: token('color.background.accent.blue.subtle', '#579DFF'),
		tag: 'blue',
	},
	{
		label: formatMessage(messages.purple),
		value: token('color.background.accent.purple.subtle', '#9F8FEF'),
		tag: 'purple',
	},
	{
		label: formatMessage(messages.grey),
		value: token('color.background.accent.gray.subtle', '#8590A2'),
		tag: 'grey',
	},
];
