import React, { type ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay';
import Tooltip from '@atlaskit/tooltip';
import { type IntlShape, useIntl } from '@atlassian/jira-intl';
import type {
	DeploymentState,
	DevOpsEnvironmentCategory,
	statusIcon_softwareReleasesVersionDetailIssueList$key,
} from '@atlassian/jira-relay/src/__generated__/statusIcon_softwareReleasesVersionDetailIssueList.graphql';
import { DeploymentIcons } from '@atlassian/jira-software-deployments-icons-common/src/ui/index.tsx';
import { DeploymentStateOptions } from '@atlassian/jira-software-deployments-icons-common/src/ui/types.tsx';
import messages from './messages';

type Props = {
	jiraSummarisedDeployments: statusIcon_softwareReleasesVersionDetailIssueList$key;
};

type FormatMessage = IntlShape['formatMessage'];

const mapState = (
	state: DeploymentState,
	environmentType: DevOpsEnvironmentCategory,
	formatMessage: FormatMessage,
): { message: string; icon: ReactNode } => {
	switch (state) {
		case DeploymentStateOptions.PENDING:
			return {
				message: formatMessage(messages.pending),
				icon: <DeploymentIcons deploymentState={DeploymentStateOptions.PENDING} size="medium" />,
			};
		case DeploymentStateOptions.IN_PROGRESS:
			return {
				message: formatMessage(messages.inProgress),
				icon: (
					<DeploymentIcons deploymentState={DeploymentStateOptions.IN_PROGRESS} size="medium" />
				),
			};
		case DeploymentStateOptions.SUCCESSFUL:
			if (environmentType === 'PRODUCTION') {
				return {
					message: formatMessage(messages.successful),
					icon: (
						<DeploymentIcons deploymentState={DeploymentStateOptions.SUCCESSFUL} size="medium" />
					),
				};
			}
			return {
				message: formatMessage(messages.successful),
				icon: (
					<DeploymentIcons
						deploymentState={DeploymentStateOptions.SUCCESSFULNONPRODUCTION}
						size="medium"
					/>
				),
			};
		case DeploymentStateOptions.FAILED:
			return {
				message: formatMessage(messages.failed),
				icon: <DeploymentIcons deploymentState={DeploymentStateOptions.FAILED} size="medium" />,
			};
		case DeploymentStateOptions.ROLLED_BACK:
			return {
				message: formatMessage(messages.rolledBack),
				icon: (
					<DeploymentIcons deploymentState={DeploymentStateOptions.ROLLED_BACK} size="medium" />
				),
			};
		case DeploymentStateOptions.CANCELLED:
			return {
				message: formatMessage(messages.cancelled),
				icon: <DeploymentIcons deploymentState={DeploymentStateOptions.CANCELLED} size="medium" />,
			};
		case DeploymentStateOptions.UNKNOWN:
			return {
				message: formatMessage(messages.unknown),
				icon: <DeploymentIcons deploymentState={DeploymentStateOptions.UNKNOWN} size="medium" />,
			};
		default:
			return {
				message: formatMessage(messages.unknown),
				icon: <DeploymentIcons deploymentState={DeploymentStateOptions.UNKNOWN} size="medium" />,
			};
	}
};

export const StatusIcon = ({ jiraSummarisedDeployments }: Props) => {
	const { formatMessage } = useIntl();
	const deploymentSummary = useFragment(
		graphql`
			fragment statusIcon_softwareReleasesVersionDetailIssueList on DevOpsSummarisedDeployments {
				deploymentState
				deploymentEnvironment {
					category
				}
			}
		`,
		jiraSummarisedDeployments,
	);

	const state = deploymentSummary.deploymentState ? deploymentSummary.deploymentState : 'UNKNOWN';
	const environment = deploymentSummary.deploymentEnvironment?.category
		? deploymentSummary.deploymentEnvironment.category
		: 'UNMAPPED';
	const { icon, message } = mapState(state, environment, formatMessage);

	return <Tooltip content={message}>{icon}</Tooltip>;
};
