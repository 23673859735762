import React, {
	Component,
	type ComponentType,
	type ReactNode,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
	type CSSProperties,
} from 'react';
import { styled } from '@compiled/react';
import keycode from 'keycode';
import { CreatableSelect, createFilter, type OptionProps, type components } from '@atlaskit/select';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { defaultSelectStyles } from '@atlassian/jira-issue-field-select-base/src/ui/react-select-styles/styled.tsx';
import type { ConfluencePage } from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import SelectWrapper, {
	type ValidationState,
} from '@atlassian/jira-issue-view-common-views/src/select-validation-wrapper/select-validation-wrapper';
import { toHref } from '@atlassian/jira-shared-types/src/general.tsx';
import LinkItem from '../link-list/link-item/view.tsx';
import PageItem from '../link-list/page-item/view.tsx';
import messages from './messages';

type State = {
	isMenuOpen: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value?: any | null;
};

// export const Option = (
//     props: JSX.LibraryManagedAttributes<
//         typeof components.Option,
//         ComponentProps<typeof components.Option>
//     >,
// ) => {
//     const { innerProps, innerRef, getStyles, data, selectProps } = props;
//     const style = getStyles('option', props);
//     return (
//         <OptionContainer
//             /* eslint-disable-next-line jira/react/no-style-attribute */
//             style={style}
//         >
//             <div {...innerProps} ref={innerRef}>
//                 {!data.page ? (
//                     // @ts-expect-error - TS2339 - Property 'value' does not exist on type 'CommonProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>> & State & { children: ReactNode; ... 4 more ...; data: any; }'.
//                     <LinkItem href={toHref(props.value)} />
//                 ) : (
//                     <PageItem
//                         key={`item_key-${data.page.href}`}
//                         {...data}
//                         selectProps={selectProps}
//                     />
//                 )}
//             </div>
//         </OptionContainer>
//     );
// };

export const OptionNew = (props: OptionProps<SelectOption>) => {
	const { innerProps, innerRef, getStyles, data, selectProps } = props;
	const style = getStyles('option', props);
	return (
		<OptionContainer
			/* eslint-disable-next-line @typescript-eslint/consistent-type-assertions, jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */
			style={style as CSSProperties}
		>
			<div {...innerProps} ref={innerRef}>
				{!data.page ? (
					<LinkItem href={toHref(props.value)} />
				) : (
					<PageItem
						key={`item_key-${data.page.href}`}
						{...data}
						// @ts-expect-error - TS2559 - Type 'Props<SelectOption, false, GroupBase<SelectOption>>' has no properties in common with type 'SelectProps'.
						selectProps={selectProps}
					/>
				)}
			</div>
		</OptionContainer>
	);
};

type SelectOption = {
	label: string;
	value: string;
	page: ConfluencePage;
};

type SelectOptionGroup = {
	label: string;
	options: SelectOption[];
};

type Options = [SelectOptionGroup] | [];

type CustomComponent = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: ComponentType<any>;
	// @ts-expect-error - TS2411 - Property 'Option' of type '(CommonProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>> & State & { children: ReactNode; ... 4 more ...; data: any; }) | undefined' is not assignable to 'string' index type 'ComponentType<any>'.
	Option?: JSX.LibraryManagedAttributes<
		typeof components.Option,
		ComponentProps<typeof components.Option>
	>;
};

export type Props = {
	isLoading: boolean;
	isLoadingOptions?: boolean;
	isValidNewOption?: (input: string) => boolean;
	options: SelectOption[] | SelectOptionGroup[] | Options;
	// eslint-disable-next-line jira/react/handler-naming
	filterOption?: (arg1: SelectOption) => boolean;
	validationMessage?: ReactNode;
	validationState?: ValidationState;
	link?: string;
	customComponents?: CustomComponent;
	additionalProps?: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[key: string]: any;
	};
	// eslint-disable-next-line jira/react/handler-naming
	noOptionsMessage?: () => ReactNode | string;
	onChange: (arg1: string | null) => void;
	onSelect?: () => void;
	onEnter?: () => void;
	onMenuOpen?: () => void;
	onMenuClose?: () => void;
	onBlur?: () => void;
	onInputChange?: (input: string) => void;
	intl: IntlShape;
};

// eslint-disable-next-line jira/react/no-class-components
export default class LinkSelect extends Component<Props, State> {
	static defaultProps = {
		isLoading: false,
		isLoadingOptions: false,
		link: undefined,
	};

	state: State = {
		isMenuOpen: false,
		value: undefined,
	};

	onKeyDown = (event: KeyboardEvent) => {
		switch (event.keyCode) {
			case keycode('enter'):
				// only report enter event when menu is closed.
				if (this.props.onEnter && !this.state.isMenuOpen) {
					this.props.onEnter();
					event.stopPropagation();
				}
				break;

			default:
				break;
		}
	};

	onMenuOpen = () => {
		const { onMenuOpen } = this.props;
		if (onMenuOpen) {
			onMenuOpen();
		}
		this.setState({ isMenuOpen: true });
	};

	onInputChange = (
		input: string,
		{
			action,
		}: {
			action: string;
		},
	) => {
		const { onInputChange } = this.props;
		if (action === 'input-change') {
			onInputChange && onInputChange(input);
		}
	};

	onBlur = () => {
		const { onBlur } = this.props;
		onBlur && onBlur();
	};

	onMenuClose = () => {
		this.setState({ isMenuOpen: false });
		const { onMenuClose } = this.props;
		if (onMenuClose) {
			onMenuClose();
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleChange = (newValue: any | null) => {
		if (newValue && newValue.page) {
			this.props.onSelect && this.props.onSelect();
		}
		this.setState({ value: newValue });
		this.props.onChange(newValue ? newValue.value : null);
	};

	isValidNewOption = (inputValue: string) => {
		const { isValidNewOption } = this.props;
		return isValidNewOption && isValidNewOption(inputValue);
	};

	render() {
		const { value } = this.state;
		const {
			options,
			intl: { formatMessage },
			link,
			isLoading,
			isLoadingOptions,
			filterOption = createFilter(),
			customComponents = {},
			additionalProps = {},
			validationState,
			validationMessage,
		} = this.props;

		const loading = isLoading || isLoadingOptions;

		// warning isn't a valid in Atlaskit Select yet, fall back to 'error'.
		// TODO: https://jdog.jira-dev.com/browse/BENTO-11817
		const sanitisedValidationState =
			validationState === 'warning' ? 'error' : validationState || 'default';

		return (
			<SelectWrapper
				validationState={this.props.validationState || 'default'}
				validationMessage={validationMessage || null}
			>
				<CreatableSelect
					isClearable
					isDisabled={isLoading}
					// @ts-expect-error - TS2322 - Type '((option: Option, rawInput: string) => boolean) | ((arg1: SelectOption) => boolean)' is not assignable to type '((option: Option, rawInput: string) => boolean) | null | undefined'.
					filterOption={filterOption}
					isLoading={loading}
					onChange={this.handleChange}
					value={link === '' || !value ? null : value}
					components={{
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						Option: OptionNew as any,
						...customComponents,
					}}
					options={options}
					placeholder={formatMessage(messages.enterOrSelectConfluenceLink)}
					validationState={sanitisedValidationState}
					getOptionLabel={(option) => (option.page ? option.page.href : option.value)}
					maxMenuHeight={256}
					autoFocus
					menuPlacement="auto"
					minMenuHeight={256}
					styles={defaultSelectStyles}
					// @ts-expect-error - TS2322 - Type '(event: KeyboardEvent) => void' is not assignable to type 'KeyboardEventHandler'.
					onKeyDown={this.onKeyDown}
					onMenuOpen={this.onMenuOpen}
					onMenuClose={this.onMenuClose}
					noOptionsMessage={() => formatMessage(messages.noOptionsMessage)}
					isValidNewOption={this.isValidNewOption}
					additionalProps={additionalProps}
					onInputChange={this.onInputChange}
					onBlur={this.onBlur}
					{...(this.props.noOptionsMessage
						? { noOptionsMessage: this.props.noOptionsMessage }
						: null)}
				/>
			</SelectWrapper>
		);
	}
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionContainer = styled.div({
	height: '46px',
});
