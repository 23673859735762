import React from 'react';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { createUseEntryPointContext } from '@atlassian/jira-entry-point/src/controllers/utils/create-use-entry-point-context/index.tsx';
import { AiIssueBreakdownEntryPoint } from '../../entrypoint';

export const AiIssueBreakdownEntryPointContext =
	createUseEntryPointContext<typeof AiIssueBreakdownEntryPoint>();

const EMPTY_ENTRY_POINT_PARAMS = {};
export const AiIssueBreakdownEntryPointContextProvider = ({
	children,
}: React.PropsWithChildren<{}>) => {
	const entryPoint = useEntryPoint(AiIssueBreakdownEntryPoint, EMPTY_ENTRY_POINT_PARAMS);

	return (
		<AiIssueBreakdownEntryPointContext.Provider value={entryPoint}>
			{children}
		</AiIssueBreakdownEntryPointContext.Provider>
	);
};
