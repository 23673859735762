import { ff } from '@atlassian/jira-feature-flagging';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import type { EcosystemOperation } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/ecosystem.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { emptyIssueData } from '../../common/constants';
import useIssueViewNonCriticalData from '../use-issue-view-non-critical-data';

export const useConnectOperations = (issueKey: IssueKey) => {
	const [issueState] = useIssueViewNonCriticalData(issueKey);
	const { value: issueData = emptyIssueData, isReady = false } = issueState ?? {};
	const { operations: value = [] } = issueData;

	return [{ value, isReady }] as const;
};

const defaultValue = [
	{
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		value: [] as EcosystemOperation[],
		isReady: true,
	},
] as const;

export default functionWithCondition(
	() => ff('issue-view-remove-connect-operations-from-critical-fetch_vtk4w'),
	useConnectOperations,
	() => defaultValue,
);
