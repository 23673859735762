import {
	createContainer,
	createStore,
	createHook,
	createActionsHook,
} from '@atlassian/react-sweet-state';
import { loadPermissions } from './actions/load-permissions/index.tsx';
import { loadViewPermissionsForCurrentUser } from './actions/load-view-permissions-for-current-user/index.tsx';
import { switchPreviewContributorMode } from './actions/preview-contributor-mode/index.tsx';
import { setContributorCreateIssuePermission } from './actions/set-contributor-create-issue-permission/index.tsx';
import type { State, Props } from './types';

const actions = {
	loadPermissions,
	switchPreviewContributorMode,
	setContributorCreateIssuePermission,
	loadViewPermissionsForCurrentUser,
} as const;

export type Actions = typeof actions;

const initialState: State = {
	meta: {
		loaded: false,
		loading: false,
		error: undefined,
	},
	permissions: [],
	contributorPermissions: [
		{ key: 'EDIT_OWN_COMMENTS', havePermission: true },
		{ key: 'ADD_COMMENTS', havePermission: true },
		{ key: 'DISCOVERY_VOTE', havePermission: true },
		{ key: 'CREATE_ISSUES', havePermission: true },
		{ key: 'CREATE_ATTACHMENTS', havePermission: true },
		{ key: 'VIEW_PROJECTS', havePermission: true },
		{ key: 'VIEW_ISSUES', havePermission: true },
		{ key: 'DELETE_OWN_ATTACHMENTS', havePermission: true },
		{ key: 'CREATE_DISCOVERY_INSIGHTS', havePermission: true },
		{ key: 'CREATE_DISCOVERY_VIEW_COMMENTS', havePermission: true },
		{ key: 'DELETE_OWN_COMMENTS', havePermission: true },
		{ key: 'BROWSE_PROJECTS', havePermission: true },
	],
	viewPermissions: {},
	isPreviewContributorMode: false,
};

export const PermissionsStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisPermissionsStore',
});

export const usePermissions = createHook(PermissionsStore);
export const usePermissionActions = createActionsHook(PermissionsStore);

export const PermissionsContainer = createContainer<State, Actions, Props>(PermissionsStore, {
	onInit:
		() =>
		({ dispatch }) =>
			dispatch(loadPermissions()),
	onUpdate:
		() =>
		({ dispatch }) =>
			dispatch(loadPermissions()),
});

export const useIsPreviewContributorMode = createHook(PermissionsStore, {
	selector: (state) => state.isPreviewContributorMode,
});
