import type { PanelDataTypes } from '../../../model/types';

// CHANGE TAB
export const CHANGE_TAB = 'state.actions.ui.CHANGE_TAB' as const;

export type ChangeTabAction = {
	type: typeof CHANGE_TAB;
	payload: {
		activeTab: PanelDataTypes;
	};
};

export const changeTab = (activeTab: PanelDataTypes): ChangeTabAction => ({
	type: CHANGE_TAB,
	payload: {
		activeTab,
	},
});

// SET APPLICATION-TYPE
export const SET_SELECTED_APPLICATION_TYPE =
	'state.actions.ui.SET_SELECTED_APPLICATION_TYPE' as const;

export type SetSelectedApplicationTypeAction = {
	type: typeof SET_SELECTED_APPLICATION_TYPE;
	payload: {
		selectedApplicationType: string;
	};
};

export const setSelectedApplicationType = (
	selectedApplicationType: string,
): SetSelectedApplicationTypeAction => ({
	type: SET_SELECTED_APPLICATION_TYPE,
	payload: {
		selectedApplicationType,
	},
});

// DISCOVERY

export const OPEN_PULL_REQUEST_CREATION_DISCOVERY =
	'state.actions.ui.OPEN_PULL_REQUEST_CREATION_DISCOVERY' as const;

export const openPullRequestCreationDiscovery = () => ({
	type: OPEN_PULL_REQUEST_CREATION_DISCOVERY,
});

export const CLOSE_PULL_REQUEST_CREATION_DISCOVERY =
	'state.actions.ui.CLOSE_PULL_REQUEST_CREATION_DISCOVERY' as const;

export const closePullRequestCreationDiscovery = () => ({
	type: CLOSE_PULL_REQUEST_CREATION_DISCOVERY,
});

export type Action = ChangeTabAction | SetSelectedApplicationTypeAction;
