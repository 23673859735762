import React from 'react';
import { lazy, type LazySuspenseProps } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { Props as CreateReleaseModalProps } from './index';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyCreateReleaseModal = lazy(() =>
	import(/* webpackChunkName: "async-create-release-modal" */ './index').then(
		(module) => module.CreateReleaseModal,
	),
);

export const CreateReleaseModal = ({
	fallback = null,
	...props
}: CreateReleaseModalProps & { fallback?: LazySuspenseProps['fallback'] }) => (
	<Placeholder name="create-release-modal" fallback={fallback}>
		<LazyCreateReleaseModal {...props} />
	</Placeholder>
);
