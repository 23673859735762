import React, { useEffect } from 'react';
import noop from 'lodash/noop';
import { ExperienceTracker as ViewExperienceTracker } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracker-consumer/tracker-base/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import {
	MultilineFieldHeading,
	MultilineFieldHeadingTitle,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin';
import { CONTENT, CONTEXT } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import {
	SectionHeading,
	SectionHeadingTitle,
	DraftIndicator,
	HeadingWithDraft,
} from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view';
import draftMessages from '@atlassian/jira-issue-view-common/src/messages/drafts';
import { DESCRIPTION } from '@atlassian/jira-issue-view-configurations';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import {
	useIssueLayout,
	MIN_EDITOR_SIDEBAR_CONTAINER_WIDTH,
} from '@atlassian/jira-issue-view-services/src/issue-layout-service/context';
import {
	isFieldEditingSelector,
	isFieldWaitingSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector';
import { fieldHasDraftSelector } from '@atlassian/jira-issue-view-store/src/selectors/drafts/selectors';
import { TextareaWrapperWithoutMargin } from '../switching-multiline-field/view.tsx';
import type { DescriptionProps, DescriptionWrapperProps, InjectedProps } from './types';
import RichDescription from './view';

export const Description = (props: DescriptionProps) => {
	const {
		area = CONTEXT,
		fieldId,
		hasDraft,
		isEditing,
		isWaiting,
		onExperienceSuccess = noop,
	} = props;

	const issueKey = useIssueKey();
	const [{ value }] = useFieldConfig(issueKey, DESCRIPTION);
	const label = value?.title;

	const intl = useIntl();
	const HeadingWrapper = area === CONTENT ? SectionHeading : MultilineFieldHeading;
	const HeadingTitle = area === CONTENT ? SectionHeadingTitle : MultilineFieldHeadingTitle;
	const showPinButton = getShowPinButton(area);
	const [, { updateSidebarMinWidth, resetSidebarMinWidth }] = useIssueLayout();

	useEffect(() => {
		if (area === CONTEXT) {
			if (isEditing === true) {
				updateSidebarMinWidth(MIN_EDITOR_SIDEBAR_CONTAINER_WIDTH);
				return;
			}
			resetSidebarMinWidth();
		}
	}, [isEditing, area, updateSidebarMinWidth, resetSidebarMinWidth]);

	useEffect(() => {
		onExperienceSuccess();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<TextareaWrapperWithoutMargin>
			<HeadingWrapper data-testid="issue-view-base.common.description.heading-wrapper">
				<HeadingWithDraft data-testid="issue-view-base.common.description.heading-with-draft">
					<HeadingTitle
						/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
						data-testid="issue.views.issue-base.common.description.label"
					>
						{label}
					</HeadingTitle>
					{hasDraft && !isEditing && !isWaiting ? (
						<DraftIndicator
							/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
							data-testid="issue.views.issue-base.common.description.draft-indicator"
							aria-label={intl.formatMessage(draftMessages.draftIndicator)}
						>
							{`• ${intl.formatMessage(draftMessages.draftIndicator)}`}
						</DraftIndicator>
					) : null}
					{showPinButton === true && <FieldPin fieldId={fieldId} label={label} />}
				</HeadingWithDraft>
			</HeadingWrapper>
			<RichDescription />
		</TextareaWrapperWithoutMargin>
	);
};

const DescriptionWrapper = (props: DescriptionWrapperProps) => (
	<ErrorBoundary prefixOverride="issue" id="Description">
		<ViewExperienceTracker location="description-container">
			{({ onExperienceSuccess }) => (
				<Description onExperienceSuccess={onExperienceSuccess} {...props} />
			)}
		</ViewExperienceTracker>
	</ErrorBoundary>
);

export default connect(
	(state): InjectedProps => ({
		hasDraft: fieldHasDraftSelector(DESCRIPTION)(state),
		isEditing: isFieldEditingSelector(DESCRIPTION)(state),
		isWaiting: isFieldWaitingSelector(DESCRIPTION)(state),
	}),
	{},
)(DescriptionWrapper);
