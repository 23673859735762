import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	editSummary: {
		id: 'issue-field-summary.inline-read.edit-summary',
		defaultMessage: 'Edit Summary',
		description: 'Label for edit summary icon',
	},
	noPermission: {
		id: 'issue-field-summary.inline-read.no-permission',
		defaultMessage: 'Contact your admin for the Edit Issues permission to edit this summary',
		description: 'Label for users with no permission to edit',
	},
});
