import { createSelector } from 'reselect';
import type {
	EntitiesState,
	State,
	UiState,
} from '@atlassian/jira-issue-view-common-types/src/issue-type';

export const uiSelector: (arg1: State) => UiState = (state) => state.ui;
export const entitiesSelector = (state: State): EntitiesState => state.entities;

export const isPreviewSelector = createSelector(uiSelector, (ui) => ui.loadingStage === 'PREVIEW');
