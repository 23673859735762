import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { type Action, createStore, createHook } from '@atlassian/react-sweet-state';
import { OPTED_OUT_FROM_ALL_IDENTIFIER } from '../../common/constants';
import { extractLinkDetails, optOut, isOptedOut, optOutFromAll } from '../../utils';

export type recommendationDetails = {
	id: string;
	domain: string;
	name: string;
	optedOutAppIdentifiers: Array<string>;
	priority: number;
} | null;

export type InstalledProvidersType = {
	id: string;
	__typename: string;
};

export type ClearedRecommendationType = {
	isCleared: boolean;
	clearedRecommendationName: string | null | undefined;
};

type State = {
	clearedRecommendation: ClearedRecommendationType;
	showRecommendation: boolean;
	recommendationDetails: recommendationDetails;
	installedProviders: Array<InstalledProvidersType> | undefined;
};

export const initialState = {
	clearedRecommendation: { isCleared: false, clearedRecommendationName: null },
	showRecommendation: false,
	recommendationDetails: null,
	installedProviders: undefined,
};

const localStorage = createLocalStorageProvider('dev-ops-app-recommendations');
const OPTED_OUT_APPS = 'opted-out-app-categories-from-link-paste';

const shouldReplaceExistingRecommendation = (
	newLink: recommendationDetails,
	currentLink: recommendationDetails,
) => {
	// Check the priority if there is a recommendationDetail is set
	if (currentLink) {
		return newLink && newLink.priority < currentLink.priority;
	}
	return true;
};

export const setPastedLinkDetails =
	(link: string): Action<State> =>
	({ getState, setState }) => {
		const newLink = extractLinkDetails(link);
		const currentLink = getState().recommendationDetails;
		const isShowingRecommendation = getState().showRecommendation;

		if (
			newLink &&
			!hasOptedOut(newLink.optedOutAppIdentifiers) &&
			!isShowingRecommendation &&
			shouldReplaceExistingRecommendation(newLink, currentLink)
		) {
			setState({ ...getState(), recommendationDetails: newLink });
		}
	};

export const setInstalledProviders =
	(installedProviders: Array<InstalledProvidersType>): Action<State> =>
	({ getState, setState }) =>
		setState({ ...getState(), installedProviders });

export const setShowRecommendation =
	(showRecommendationState: boolean): Action<State> =>
	({ getState, setState }) => {
		setState({ ...getState(), showRecommendation: showRecommendationState });
	};

export const clearRecommendation =
	(): Action<State> =>
	({ setState }) => {
		setState({
			showRecommendation: false,
			recommendationDetails: null,
		});
	};

export const optOutFromRecommendation =
	(appId: string): Action<State> =>
	({ getState, setState }) => {
		optOut(localStorage, OPTED_OUT_APPS, appId);
		const recommendationName = getState().recommendationDetails?.name;
		setState({
			clearedRecommendation: {
				isCleared: true,
				clearedRecommendationName: recommendationName,
			},
			showRecommendation: false,
			recommendationDetails: null,
		});
	};

export const clearOptOutPanel =
	(): Action<State> =>
	({ setState }) => {
		setState({
			clearedRecommendation: { isCleared: false, clearedRecommendationName: null },
			showRecommendation: false,
			recommendationDetails: null,
		});
	};

export const optOutFromAllRecommendations =
	(): Action<State> =>
	({ setState }) => {
		optOutFromAll(localStorage, OPTED_OUT_APPS);
		setState({
			clearedRecommendation: { isCleared: false, clearedRecommendationName: null },
			showRecommendation: false,
			recommendationDetails: null,
		});
	};

const hasOptedOut = (optOutIdentifiers: Array<string>): boolean =>
	isOptedOut(localStorage, OPTED_OUT_APPS, optOutIdentifiers);

/**
 * If the user has chosen to opt out of everything,
 * then the localStorage should only have one value -> [OPTED_OUT_FROM_ALL_IDENTIFIER]
 *
 */
export const hasOptedOutFromAllRecommendations = (): boolean =>
	isOptedOut(localStorage, OPTED_OUT_APPS, [OPTED_OUT_FROM_ALL_IDENTIFIER]);

export const actions = {
	setPastedLinkDetails,
	setInstalledProviders,
	setShowRecommendation,
	clearRecommendation,
	optOutFromRecommendation,
	clearOptOutPanel,
	optOutFromAllRecommendations,
} as const;

export type Actions = typeof actions;

export const Store = createStore<State, Actions>({
	name: 'linkPasteRecommendations',
	initialState,
	actions,
});

export const useDevOpsAppRecommendationsStore = createHook(Store);
