import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type {
	SaveField,
	IdOnlyResponse,
} from '@atlassian/jira-issue-field-base/src/common/types.tsx';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { PARENT_TYPE } from '@atlassian/jira-platform-field-config';
import type { ParentFieldOption } from '../../common/types';
import type { ParentFieldResponse, ParentIdShape, Props } from './types';

export const saveParentField: SaveField<ParentFieldOption | null, ParentFieldResponse> = async (
	issueKey,
	fieldKey,
	newValue,
) =>
	performPutRequest(`/rest/api/3/issue/${issueKey}/`, {
		body: JSON.stringify({
			fields: {
				[fieldKey]:
					newValue && newValue.value
						? {
								id: newValue.value,
							}
						: null,
			},
		}),
	});

export const mapNonOptimisticParentResponse = (
	response: IdOnlyResponse<ParentFieldResponse> | null,
) => {
	if (!response?.fields) {
		return null;
	}
	return response.fields.parent;
};

export const useParentField = (props: Props) => {
	const { fieldKey, issueId, issueKey, onSuccess, onFailure, initialValue = null } = props;

	const [{ value: fieldConfig }] = useFieldConfig(issueKey, fieldKey);
	const fieldType = fieldConfig?.schema.custom ?? PARENT_TYPE;

	const [{ error, value }, { resetError, saveValue, saveById }] = useEditField<
		ParentFieldOption | null,
		null,
		ParentFieldResponse,
		ParentIdShape | null
	>({
		fieldKey,
		fieldType,
		issueId,
		issueKey,
		onSuccess,
		onFailure,
		initialValue,
		saveField: saveParentField,
		mapNonOptimisticResponse: mapNonOptimisticParentResponse,
	});

	const valueOption: ParentFieldOption | null = value || null;

	return [
		{ value: valueOption, fieldConfig, error },
		{
			saveValue,
			saveById,
			resetError,
		},
	] as const;
};
