import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorReportDescription: {
		id: 'atlassian-intelligence.common.feedback-button.feedback-popup-content.error-report-description',
		defaultMessage: 'Share your query to help us improve search results.',
		description: 'Description shown in the feedback for AI search dialog.',
	},
	shareQuery: {
		id: 'atlassian-intelligence.common.feedback-button.feedback-popup-content.share-query',
		defaultMessage: 'Share Query',
		description: 'ext shown for a button that will submit feedback on click',
	},
	cancel: {
		id: 'atlassian-intelligence.common.feedback-button.feedback-popup-content.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel feedback submission and close dialog',
	},
	/* eslint-disable jira/i18n/id-named-by-package */
	heading: {
		id: 'atlassian-intelligence.prompt-response.feedback.feedback-popup-content.heading',
		defaultMessage: 'Help us improve Atlassian Intelligence',
		description: 'Heading shown in the feedback for AI search dialog.',
	},
	close: {
		id: 'atlassian-intelligence.prompt-response.feedback.feedback-popup-content.close',
		defaultMessage: 'Close',
		description: 'Accessible label (not visible) for a close button',
	},
	sendFeedback: {
		id: 'atlassian-intelligence.prompt-response.feedback.feedback-popup-content.send-feedback',
		defaultMessage: 'Send feedback',
		description: 'Text shown for a button that will submit feedback on click',
	},
	contactConsentPrompt: {
		id: 'atlassian-intelligence.prompt-response.feedback.feedback-popup-content.contact-consent-prompt',
		defaultMessage:
			'Yes, Atlassian teams can contact me to learn about my experiences to improve Atlassian products and services. I acknowledge the <link>Atlassian Privacy Policy</link>.',
		description:
			'Text displayed next to a checkbox where user can consent to be contacted by Atlassian',
	},
	researchConsentPrompt: {
		id: 'atlassian-intelligence.prompt-response.feedback.feedback-popup-content.research-consent-prompt',
		defaultMessage: "I'd like to participate in product research.",
		description:
			'Text displayed next to a checkbox where user can consent to participate in product research.',
	},
	textAreaHeader: {
		id: 'atlassian-intelligence.prompt-response.feedback.feedback-popup-content.text-area-header',
		defaultMessage: 'How was your search experience?',
		description: 'Text displayed above the txt area where a user can enter their feedback',
	},
	shareQueryPromptJnlis612: {
		id: 'atlassian-intelligence.prompt-response.feedback.feedback-popup-content.share-query-prompt-jnlis-612',
		defaultMessage: 'Include my search query and response in my feedback',
		description:
			'Updated text displayed next to a checkbox where user can consent to share their AI search input and output',
	},
	contactConsentPromptJnlis612: {
		id: 'atlassian-intelligence.prompt-response.feedback.feedback-popup-content.contact-consent-prompt-jnlis-612',
		defaultMessage:
			'Atlassian can contact me to learn more about this feedback and I acknowledge the <link>Atlassian Privacy Policy</link>',
		description:
			'Updated text displayed next to a checkbox where user can consent to be contacted by Atlassian',
	},
	researchConsentPromptJnlis612: {
		id: 'atlassian-intelligence.prompt-response.feedback.feedback-popup-content.research-consent-prompt-jnlis-612',
		defaultMessage: 'I want to participate in Atlassian’s product research',
		description:
			'Updated text displayed next to a checkbox where user can consent to participate in product research.',
	},
});
