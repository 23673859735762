import { useEffect, useState, useMemo, useCallback } from 'react';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { createSessionStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/session-storage/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { EMBEDDED_CONFLUENCE_ISSUE_VIEW_CREATE_CONFLUENCE_CONTENT_CROSS_FLOW_KEY } from '@atlassian/jira-issue-create-confluence-content/src/services/experiments';
import {
	fireAnalytics,
	useAnalyticsEvents,
	SCREEN_EVENT_TYPE,
	UI_EVENT_TYPE,
} from '@atlassian/jira-product-analytics-bridge';

export const COHORTS = {
	EXPERIMENT: 'experiment',
	NOT_ENROLLED: 'not_enrolled',
	CONTROL: 'control',
};

export const JSW_XFLOW_NUDGE_EXP = 'jsw_issue_view_nudge_exp';

const SPOTLIGHT_SUPPRESSED = 'spotlightSuppressed';
const SPOTLIGHT_VIEW_COUNT = 'timesFlagViewed';

export const useXflowNudgeExperiment = () => {
	const [enabled, setEnabled] = useState(false);

	useEffect(() => {
		const cohort = expVal('jsw_issue_view_nudge_exp', 'cohort', 'not_enrolled');
		setEnabled(cohort === COHORTS.EXPERIMENT);
	}, []);

	return enabled;
};

export const usePulse = (
	experimentKey: string,
	pulseLimit = 3,
	embeddedConfluenceSource?: string,
) => {
	const [pulseEnabled, setPulseEnabled] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const sessionStorage = useMemo(
		() => createSessionStorageProvider(experimentKey),
		[experimentKey],
	);
	const localStorage = useMemo(() => createLocalStorageProvider(experimentKey), [experimentKey]);

	const getPulseViewCount = useCallback(
		() => localStorage.get(SPOTLIGHT_VIEW_COUNT) ?? 0,
		[localStorage],
	);

	const shouldShowPulse = useCallback(
		(): boolean =>
			!sessionStorage.get(SPOTLIGHT_SUPPRESSED) &&
			!localStorage.get(SPOTLIGHT_SUPPRESSED) &&
			getPulseViewCount() < pulseLimit,
		[localStorage, sessionStorage, getPulseViewCount, pulseLimit],
	);

	const triggerSpotlightAnalytics = (action: string, actionSubject: string, actionKey: string) => {
		const analyticsEvent =
			experimentKey === EMBEDDED_CONFLUENCE_ISSUE_VIEW_CREATE_CONFLUENCE_CONTENT_CROSS_FLOW_KEY
				? {
						event: { action, actionSubject },
						type: UI_EVENT_TYPE,
					}
				: { event: {}, type: SCREEN_EVENT_TYPE };

		fireAnalytics(createAnalyticsEvent(analyticsEvent.event), analyticsEvent.type, {
			attributes: {
				actionKey,
				flagKey: experimentKey,
				growthInitiative: 'cross-flow',
				embeddedConfluenceSource,
			},
		});
	};

	const onHover = () => {
		triggerSpotlightAnalytics('viewed', 'changeboardingSpotlight', 'onHover');
	};

	const onOk = () => {
		triggerSpotlightAnalytics('clicked', 'changeboardingSpotlight', 'onOk');
	};

	const onDismiss = () => {
		triggerSpotlightAnalytics('dismissed', 'changeboardingSpotlight', 'onDismiss');
	};

	const suppressPulse = () => {
		localStorage.set(SPOTLIGHT_SUPPRESSED, true);
		sessionStorage.set(SPOTLIGHT_SUPPRESSED, true);
		setPulseEnabled(false);
	};

	useEffect(() => {
		if (pulseEnabled) localStorage.set(SPOTLIGHT_VIEW_COUNT, getPulseViewCount() + 1);
	}, [pulseEnabled, localStorage, getPulseViewCount]);

	useEffect(() => setPulseEnabled(shouldShowPulse()), [shouldShowPulse]);

	return { pulseEnabled, suppressPulse, onHover, onOk, onDismiss };
};
