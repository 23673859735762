import React, { type ComponentType, type ReactNode, useMemo } from 'react';
import { ExperienceSuccessTracker as ViewExperienceSuccessTracker } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracker-consumer/result-declared/index.tsx';
import { ExperienceTracker as ViewExperienceTracker } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracker-consumer/tracker-base/index.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { fieldTypeSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector';
import { getComponentDisplayName } from '../common';
import {
	getFieldViewTrackerProviderWhitelist,
	NEVER_TRACKING_FIELD_VIEW_EXPERIENCE,
} from './fields';

type ComponentBeingTrackedProps<T> = T & {
	fieldId: string;
	onExperienceSuccess?: undefined | (() => void);
};

export const withViewExperienceTracker =
	(location: string) =>
	// FIXME: JIV-17455: improve type inheritance
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint, @typescript-eslint/no-explicit-any
	<T extends any>(
		ComponentBeingTracked: ComponentType<ComponentBeingTrackedProps<T>>,
	): ComponentType<T & { fieldId: string }> => {
		const WithViewExperienceTracker = (props: T & { fieldId: string }) => (
			<ViewExperienceTracker location={location}>
				{({ onExperienceSuccess }) => (
					<ComponentBeingTracked onExperienceSuccess={onExperienceSuccess} {...props} />
				)}
			</ViewExperienceTracker>
		);

		WithViewExperienceTracker.displayName = `WithViewExperienceTracker(${getComponentDisplayName(
			ComponentBeingTracked,
		)})`;

		return WithViewExperienceTracker;
	};

type FieldBeingTrackedProps<T> = T & {
	fieldId: string;
};

type ConnectedFieldTrackerProps<T> = {
	ownProps: FieldBeingTrackedProps<T>;
	fieldType: string | null;
};

type WithViewExperienceTrackerWrapperProps = {
	fieldType: string | null;
	locationSuffix?: string;
	children: ReactNode;
};

export const WithViewExperienceTrackerWrapper = ({
	fieldType,
	locationSuffix,
	children,
}: WithViewExperienceTrackerWrapperProps) => {
	const fieldTypeExperienceTrackingWhitelist =
		fieldType && getFieldViewTrackerProviderWhitelist(fieldType);

	const location = useMemo(
		() => ['field', fieldType, ...(locationSuffix ? [locationSuffix] : [])].join('-'),
		[fieldType, locationSuffix],
	);

	if (
		fieldType == null ||
		fieldTypeExperienceTrackingWhitelist === NEVER_TRACKING_FIELD_VIEW_EXPERIENCE
	) {
		return <>{children}</>;
	}

	const parentProviders = Array.isArray(fieldTypeExperienceTrackingWhitelist)
		? fieldTypeExperienceTrackingWhitelist
		: null;

	return (
		<ViewExperienceSuccessTracker location={location} parentProviders={parentProviders}>
			{children}
		</ViewExperienceSuccessTracker>
	);
};

export const withFieldViewExperienceTracker =
	(locationSuffix?: string) =>
	<T,>(ComponentBeingTracked: ComponentType<FieldBeingTrackedProps<T>>) => {
		const WithViewExperienceTracker = ({ fieldType, ownProps }: ConnectedFieldTrackerProps<T>) => {
			const fieldTypeExperienceTrackingWhitelist =
				fieldType && getFieldViewTrackerProviderWhitelist(fieldType);

			if (
				fieldType == null ||
				fieldTypeExperienceTrackingWhitelist === NEVER_TRACKING_FIELD_VIEW_EXPERIENCE
			) {
				return <ComponentBeingTracked {...ownProps} />;
			}

			const parentProviders = Array.isArray(fieldTypeExperienceTrackingWhitelist)
				? fieldTypeExperienceTrackingWhitelist
				: null;

			const location = ['field', fieldType, ...(locationSuffix ? [locationSuffix] : [])].join('-');

			return (
				// If the component mounts without throwing or mounting its own failure component, then the field has succeeded
				<ViewExperienceSuccessTracker location={location} parentProviders={parentProviders}>
					<ComponentBeingTracked {...ownProps} />
				</ViewExperienceSuccessTracker>
			);
		};

		WithViewExperienceTracker.displayName = `WithFieldViewExperienceTracker(${getComponentDisplayName(
			ComponentBeingTracked,
		)}`;

		return connect(
			(state: State, ownProps: FieldBeingTrackedProps<T>) => ({
				fieldType: fieldTypeSelector(ownProps.fieldId)(state),
			}),
			null, // Prevent fieldType from being injected into the original props
			({ fieldType }, dispatchProps, ownProps) => ({ fieldType, ownProps }),
		)(WithViewExperienceTracker);
	};
