import queryString from 'query-string';

export const getRapidViewIdFromUrl = (): number =>
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	queryString.parse(window.location.search).rapidView;

export const getLegacyIssueViewUrl = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const params = queryString.parse(window.location.search);
	params.oldIssueView = 'true';

	/* eslint-disable jira/jira-ssr/no-unchecked-globals-usage */
	return `${window.location.protocol}//${window.location.host}${
		window.location.pathname
	}?${queryString.stringify(params)}${window.location.hash}`;
};
