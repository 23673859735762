import React, { type FC } from 'react';
import { xcss, Flex, Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { GeneratingAnimation } from './generating-animation';
import messages from './messages';

type Props = {
	message?: string;
};
export const StreamingStatusBar: FC<Props> = ({ message }) => {
	const { formatMessage } = useIntl();

	return (
		<Flex xcss={containerStyle}>
			<GeneratingAnimation alt={formatMessage(messages.generating)} />
			<Box as="p" xcss={textStyle}>
				{message}
			</Box>
		</Flex>
	);
};

const containerStyle = xcss({
	alignItems: 'center',
	paddingBlockStart: 'space.150',
	paddingBlockEnd: 'space.150',
	paddingInline: 'space.150',
	gap: 'space.100',
});

const textStyle = xcss({
	color: 'color.text.subtlest',
	margin: 'space.0',
	padding: 'space.0',
	display: 'flex',
	alignItems: 'center',
	gap: 'space.050',
});
