import React from 'react';
import { ButtonItem } from '@atlaskit/menu';
import useToggleFlag from './hook';
import type { Props } from './types';

export const FlagIssueActionView = ({
	onClick,
	itemKey,
	label,
	setDropdownItemRef,
	isSelected = false,
}: Props) => {
	const toggleFlag = useToggleFlag(itemKey, onClick);

	return (
		<ButtonItem
			key="flagIssue"
			/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
			data-testid="issue.views.issue-base.foundation.issue-actions.add-to-issue-dropdown-group.flag.button-item"
			onClick={toggleFlag}
			isSelected={isSelected}
			ref={setDropdownItemRef}
			role="menuitem"
		>
			{label}
		</ButtonItem>
	);
};

export default FlagIssueActionView;
