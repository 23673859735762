import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import rankField from '@atlassian/jira-issue-fields-ranker/src/services/index.tsx';
import type { Version } from '@atlassian/jira-issue-shared-types/src/common/types/issue-type.tsx';

/*
 * Rank list of versions by calling Field recommendations service
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	fieldId: string,
	versions: Version[],
	issueId?: string | number,
	projectId?: string | number,
	sessionId?: string,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
): Promise<Version[]> =>
	rankField(fieldId, versions, issueId, projectId, sessionId, createAnalyticsEvent);
