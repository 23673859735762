import { createSelector } from 'reselect';
import filter from 'lodash/fp/filter';
import flatMap from 'lodash/fp/flatMap';
import getOr from 'lodash/fp/getOr';
import pipe from 'lodash/fp/pipe';
import type { BuildProvider } from '../../../../model/builds';
import { getAllInstanceTypeDetails } from '../index';

const filterProvidersWithoutBuilds: (arg1: BuildProvider[]) => BuildProvider[] = filter(
	(provider) => provider.builds.length > 0,
);

export const getBuildProviders = createSelector(
	[getAllInstanceTypeDetails],
	pipe(flatMap(getOr([], 'buildProviders')), filterProvidersWithoutBuilds),
);

const getBuilds = createSelector([getBuildProviders], flatMap(getOr([], 'builds')));

export const getBuildsCount = createSelector([getBuilds], (builds) => builds.length);

export const hasBuilds = createSelector([getBuildsCount], (count) => count > 0);
