// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'issue.labels.label',
		defaultMessage: 'Labels',
		description: 'Field label. Represents labels attached to the issue.',
	},
	placeholder: {
		id: 'issue.labels.text-for-edit-view-when-no-value-is-provided',
		defaultMessage: 'Select Label',
		description: '',
	},
	createNewItem: {
		id: 'issue.labels.create-new-item-message',
		defaultMessage: 'New label',
		description: '',
	},
	recent: {
		id: 'issue.labels.recent-items',
		defaultMessage: 'Recent labels',
		description: 'Header displayed in labels list used to mark recently selected items',
	},
	all: {
		id: 'issue.labels.all-items',
		defaultMessage: 'All labels',
		description: 'Header displayed in labels list used to mark fetched from server items',
	},
});
