import type { Dispatch } from 'redux';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type { ProjectType } from '@atlassian/jira-common-constants';
import type { UploadContext } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/media-context.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import {
	changeCommentVisibility,
	editCommentUpdate,
	setCommentAttachmentInProgress,
	type ChangeCommentVisibility,
	type EditCommentUpdate,
	type SetCommentAttachmentInProgressAction,
} from '@atlassian/jira-issue-view-store/src/actions/comment-actions';
import { mediaContextSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/media-context-selector';
import { NEW_COMMENT_ID } from '@atlassian/jira-issue-view-store/src/selectors/comment-constants';
import EventHandlersProvider from './view';

type StateProps = {
	uploadContext: UploadContext | null;
	projectType?: ProjectType;
};

type Action = ChangeCommentVisibility | EditCommentUpdate | SetCommentAttachmentInProgressAction;

type DispatchProps = {
	setCommentAttachmentInProgress: (arg1: boolean) => Action;
	onEditCommentUpdate: (arg1: ADF) => void;
};

export default connect(
	(state: State): StateProps => ({
		uploadContext: mediaContextSelector(state).uploadContext,
		projectType: state.entities.project?.projectType,
	}),
	(dispatch: Dispatch<Action>): DispatchProps => ({
		onEditCommentUpdate: (value) => {
			// Attachments go into a new Service Desk internal comment by default
			dispatch(changeCommentVisibility(NEW_COMMENT_ID, true));
			dispatch(editCommentUpdate(NEW_COMMENT_ID, value));
		},
		setCommentAttachmentInProgress: (isInProgress) =>
			dispatch(setCommentAttachmentInProgress(isInProgress)),
	}),
)(EventHandlersProvider);
