import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import { fireUiAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-ui-event.tsx';
// eslint-disable-next-line import/order
import ErrorState from '@atlassian/jira-common-components-error-state';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { TabItem } from '@atlassian/jira-issue-shared-types/src/common/types/layout-item-type.tsx';
import type { TabNode } from '@atlassian/jira-issue-view-common-types/src/tab-type';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { PanelContentContainerInner } from '../context-panel/view/styled';
import messages from './messages';

type RenderedFieldAnalytics = {
	renderedFieldsCount: number;
	unrenderedFieldsCount: number;
	unrenderedFieldTypes: string;
};

export type Props = {
	isProjectAdmin: boolean;
	renderedItems: TabNode[];
	tabItems: TabItem[];
	contentWidth: number;
	onComponentMounted: (analytics: RenderedFieldAnalytics) => void;
};

export const TabPanel = ({
	isProjectAdmin,
	renderedItems,
	tabItems,
	contentWidth,
	onComponentMounted,
}: Props) => {
	const { formatMessage } = useIntl();

	useEffect(() => {
		const renderedTabItemTypes: Set<string> = new Set(renderedItems.map((item) => item.fieldType));
		const tabItemIds: string[] = tabItems.map((item) => item.id);

		const unrenderedTabItemTypes: string[] = tabItemIds.filter(
			(tabItemId) => !renderedTabItemTypes.has(tabItemId),
		);

		onComponentMounted({
			renderedFieldsCount: renderedTabItemTypes.size,
			unrenderedFieldsCount: unrenderedTabItemTypes.length,
			unrenderedFieldTypes: unrenderedTabItemTypes.join(','),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Ensure that we at least have some fields to render, otherwise
	// display an empty error state
	if (renderedItems.every((i) => i.component == null)) {
		return (
			<ErrorState
				size="narrow"
				isSubtle
				header={formatMessage(messages.emptyStateHeader)}
				description={
					isProjectAdmin
						? formatMessage(messages.emptyStateMessageAdmin)
						: formatMessage(messages.emptyStateMessage)
				}
			/>
		);
	}

	return (
		<PanelContentContainer>
			<PanelContentContainerInner maxWidth={contentWidth}>
				{renderedItems.map((item) => item.component)}
			</PanelContentContainerInner>
		</PanelContentContainer>
	);
};

export default flowWithSafeComponent(
	// @ts-expect-error - Argument of type '<T extends ComponentType<any>, Props = ComponentPropsWithRef<T>>(WrappedComponent: T) => FC<Omit<{ [K in keyof Props]: K extends "onComponentMounted" ? (...args: [......]) => ReturnType<...> : Props[K]; }, keyof WithAnalyticsEventsProps>>' is not assignable to parameter of type 'FlowStep<T, ComponentType<Matching<{ onComponentMounted: (analytics: RenderedFieldAnalytics, analyticsEvent: UIAnalyticsEvent) => void; } & {}, Omit<{ [K in keyof Props]: K extends "onComponentMounted" ? (...args: [......]) => ReturnType<...> : Props[K]; }, keyof WithAnalyticsEventsProps>>>>'.
	ComponentWithAnalytics('fieldTab', {
		onComponentMounted: 'rendered',
	}),
	connect(
		// FIXME: this is mapDispatch
		(state, props: { panelKey: string; type: string }) => ({
			onComponentMounted: (analytics: RenderedFieldAnalytics, analyticsEvent: UIAnalyticsEvent) => {
				fireUiAnalytics(analyticsEvent, {
					name: props.panelKey,
					attributes: {
						contextPanelType: props.type,
						...analytics,
					},
				});
			},
		}),
		{},
	),
)(TabPanel);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PanelContentContainer = styled.div({
	padding: `${token('space.200', '16px')} ${token('space.100', '8px')} ${token(
		'space.100',
		'8px',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	)} ${gridSize * 1.25}px`,
	overflow: 'auto',
	height: '100%',
});
