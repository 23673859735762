import React from 'react';
import { isFedRamp } from '@atlassian/atl-context';
import { ff } from '@atlassian/jira-feature-flagging';
import { connect } from '@atlassian/jira-react-redux';
import {
	BRANCH_PANEL_DATA_TYPE,
	BUILD_PANEL_DATA_TYPE,
	COMMIT_PANEL_DATA_TYPE,
	PULLREQUEST_PANEL_DATA_TYPE,
	DEPLOYMENT_PANEL_DATA_TYPE,
	FEATURE_FLAGS_PANEL_DATA_TYPE,
	REMOTE_LINKS_PANEL_DATA_TYPE,
} from '../../common/model/constants';
import type { AppProps, Tab } from '../model/types';
import { changeTab } from '../state/actions/ui';
import type { State } from '../state/reducers/types';
import { isError, isLoading } from '../state/selectors/details';
import { getActiveTab } from '../state/selectors/ui';
import {
	DetailsHeader,
	Branches,
	Builds,
	Commits,
	Deployments,
	PullRequests,
	FeatureFlags,
	RemoteLinks,
} from './async';
import messages from './messages';
import Details from './view';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const tabs = [
	{
		type: BRANCH_PANEL_DATA_TYPE,
		messageDescriptor: messages.branchTabLabel,
		TabContent: Branches,
	},
	{
		type: COMMIT_PANEL_DATA_TYPE,
		messageDescriptor: messages.commitTabLabel,
		TabContent: Commits,
	},
	{
		type: PULLREQUEST_PANEL_DATA_TYPE,
		messageDescriptor: messages.pullRequestsTabLabel,
		TabContent: PullRequests,
	},
] as Tab[];

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const buildDeploymentFeatureFlagTabs = [
	{
		type: BUILD_PANEL_DATA_TYPE,
		messageDescriptor: messages.buildTabLabel,
		TabContent: Builds,
	},
	{
		type: DEPLOYMENT_PANEL_DATA_TYPE,
		messageDescriptor: messages.deploymentsTabLabel,
		TabContent: Deployments,
	},
	{
		type: FEATURE_FLAGS_PANEL_DATA_TYPE,
		messageDescriptor: messages.featureFlagsLabel,
		TabContent: FeatureFlags,
	},
] as Tab[];

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const remoteLinksTabs = [
	{
		type: REMOTE_LINKS_PANEL_DATA_TYPE,
		messageDescriptor: messages.remoteLinksLabel,
		TabContent: RemoteLinks,
	},
] as Tab[];

const getAvailableTabs = (showRemoteLinks: boolean): Tab[] => {
	let availableTabs = tabs;
	if (ff('isotopes-dev-details-hide-tabs-fedramp_ew0ag') && isFedRamp()) {
		return availableTabs;
	}

	availableTabs = [...availableTabs, ...buildDeploymentFeatureFlagTabs];

	if (showRemoteLinks) {
		availableTabs = [...availableTabs, ...remoteLinksTabs];
	}

	return availableTabs;
};

const ConnectedDetails = connect(
	(state: State) => ({
		activeTab: getActiveTab(state),
		isLoading: isLoading(state),
		isError: isError(state),
	}),
	{
		onChangeTab: changeTab,
	},
)(Details);

const DevelopmentDetails = (props: AppProps) => (
	<ConnectedDetails
		{...props}
		tabs={getAvailableTabs(props.showRemoteLinks)}
		Header={DetailsHeader}
	/>
);

export const getAvailableTabsFunc = getAvailableTabs;
export default DevelopmentDetails;
