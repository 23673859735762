import { createSelector } from 'reselect';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import type { ShareAsCommentState } from '@atlassian/jira-issue-view-common-types/src/share-as-comment-type';

const shareAsCommentSelector = (state: State): ShareAsCommentState => state.shareAsComment;

export const getValueForSharingAsComment = createSelector(
	shareAsCommentSelector,
	(shareAsComment) => shareAsComment.value,
);

export const getTimestampForSharingAsComment = createSelector(
	shareAsCommentSelector,
	(shareAsComment) => shareAsComment.timestamp,
);
