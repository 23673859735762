/* eslint-disable @typescript-eslint/no-non-null-assertion */ // Remove on relay-migration-issue-fields-watchers_iqhn4 cleanup
/* eslint-disable @atlassian/relay/must-colocate-fragment-spreads */
import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { graphql, useFragment } from 'react-relay';
import { ff } from '@atlassian/jira-feature-flagging';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import {
	isLoadingSelector,
	errorSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import { isWatchesPresentSelector } from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/watch-toggle-selector';
import type { header_issueViewWatchers_WatchersItem$key } from '@atlassian/jira-relay/src/__generated__/header_issueViewWatchers_WatchersItem.graphql';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { IssueWatchersLoading, IssueWatchersBoundary } from '../async';
import IssueWatchersSync from '../index';

type Props = {
	isWatchesPresent: boolean;
	isLoading: boolean;
	error: string | null;
	watches?: header_issueViewWatchers_WatchersItem$key; // Remove optional on relay-migration-issue-fields-watchers_iqhn4 cleanup
};

// TODO reevaluate phase - forPaint is simply for initial parity
export const IssueWatchers = lazyForPaint<typeof IssueWatchersSync>(
	() => import(/* webpackChunkName: "async-issue-watchers" */ '../index'),
);

const WatchersItem = ({ isWatchesPresent, isLoading, error, watches }: Props) => {
	const loggedInAccountId = useAccountId();
	const shouldRenderWatchers = !error && !!loggedInAccountId;
	const isWatchersLoading = !isWatchesPresent || isLoading;

	const data = ff('relay-migration-issue-fields-watchers_iqhn4')
		? // eslint-disable-next-line @atlassian/relay/query-restriction, react-hooks/rules-of-hooks
			useFragment<header_issueViewWatchers_WatchersItem$key>(
				graphql`
					fragment header_issueViewWatchers_WatchersItem on JiraWatchesField {
						...src_issueViewWatchers_WatchersAppWithRelay
					}
				`,

				watches!,
			)
		: undefined;

	if (ff('relay-migration-issue-fields-watchers_iqhn4') && !data) {
		return null;
	}

	if (!shouldRenderWatchers) {
		return null;
	}

	if (isWatchersLoading) {
		return <IssueWatchersLoading />;
	}

	return (
		<IssueWatchersBoundary packageName="issue" fallback={<IssueWatchersLoading />}>
			{ff('relay-migration-issue-fields-watchers_iqhn4') ? (
				<IssueWatchersSync watches={data!} />
			) : (
				<IssueWatchers watches={data!} />
			)}
		</IssueWatchersBoundary>
	);
};

export const Watchers = connect(
	(state) => ({
		isWatchesPresent: isWatchesPresentSelector(state),
		isLoading: isLoadingSelector(state),
		error: errorSelector(state),
	}),
	{},
)(WatchersItem);
