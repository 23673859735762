import isValidUrl from '@atlassian/jira-common-util-is-url';

type typeOfErrors =
	| readonly {
			readonly message: string | null;
	  }[]
	| null;

export const validationForErrors = (
	showValidationMessage?: boolean,
	invalidMessage?: string | null,
	errors?: typeOfErrors,
) => {
	if (showValidationMessage) return invalidMessage;
	if (errors && errors.length) return errors[0].message;
	return null;
};

// eslint-disable-next-line no-script-url
export const forbiddenScheme = 'javascript:';

export const isAllowed = (value: string | null) => {
	if (value === '' || value === null) return true;
	if (isValidUrl(value)) return true;
	if (!value.startsWith(forbiddenScheme)) return true;
	return false;
};
