import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { COMPACT_WRAPPER_COMPONENT_SELECTOR } from '@atlassian/jira-issue-view-common/src/component/inline-edit';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TextareaWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`${COMPACT_WRAPPER_COMPONENT_SELECTOR} > *`]: {
		marginLeft: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"[class*='ContentWrapper']": {
		paddingLeft: 0,
		paddingRight: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InlineEditContainer = styled.div<{ isEditable: boolean; isEditing: boolean }>(
	{
		width: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& div[data-read-view-fit-container-width]': {
			marginLeft: token('space.negative.100', '-8px'),
			display: 'flex',
			alignItems: 'center',
			wordBreak: 'break-word',
			paddingTop: 0,
			paddingBottom: 0,
			paddingLeft: 0,
			paddingRight: token('space.075', '6px'),
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditable }) =>
		isEditable
			? { marginTop: token('space.negative.050', '-4px') }
			: {
					marginTop: token('space.negative.100', '-8px'),
					paddingBottom: token('space.050', '4px'),
				},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditing }) => isEditing && `margin-right: ${token('space.100', '8px')}`,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TextAreaFieldWrapper = styled.div({
	marginLeft: token('space.negative.100', '-8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ScrollableAreaWrapper = styled.div<{ isEditable: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingLeft: ({ isEditable }) => (isEditable ? token('space.075', '6px') : undefined),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmptyValueWrapper = styled.div<{ isEditable: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingTop: ({ isEditable }) => (isEditable ? 0 : token('space.050', '4px')),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingBottom: ({ isEditable }) => (isEditable ? 0 : token('space.025', '2px')),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingLeft: ({ isEditable }) => (isEditable ? token('space.075', '6px') : 0),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingRight: ({ isEditable }) => (isEditable ? token('space.075', '6px') : 0),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TextContentAreaWrapper = styled.div({
	marginLeft: token('space.075', '6px'),
});
