import React, { useCallback } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import Badge from '@atlaskit/badge';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
// eslint-disable-next-line import/order
import { ff } from '@atlassian/jira-feature-flagging';

import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { NUMBER_BADGE_TEST_ID } from '../../common/constants';
import type { NumberValue } from '../../common/types';

type Props = {
	isEditable: boolean;
	value?: NumberValue;
	setIsEditing?: (arg1: boolean) => void;
};

const noValueText = '-';

export const NumberView = ({ isEditable, value, setIsEditing }: Props) => {
	const { formatNumber } = useIntl();

	const handleOnClick = useCallback(() => {
		setIsEditing?.(true);
	}, [setIsEditing]);

	if (value === undefined) {
		return null;
	}

	const displayValue = value === null ? noValueText : formatNumber(value);
	const BadgeComp = <Badge testId={NUMBER_BADGE_TEST_ID}>{displayValue}</Badge>;
	const BadgeComponent = isEditable ? (
		<HoverContainer data-testid={`${NUMBER_BADGE_TEST_ID}-hover-container`} onClick={handleOnClick}>
			{BadgeComp}
		</HoverContainer>
	) : (
		BadgeComp
	);

	return <BadgeWrapper>{BadgeComponent}</BadgeWrapper>;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HoverContainerControl = styled.div({
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'&:hover > span': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: `${token('color.text', colors.N800)} !important`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: `${token('color.background.neutral.hovered', colors.N90)} !important`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const HoverContainerExperiment = styled2.div({
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'&:hover > span': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: `${token('color.text', colors.N800)} !important`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: `${token('color.background.neutral.hovered', colors.N90)} !important`,
	},
});

const HoverContainer = styledComponentWithCondition(
	() => ff('compiled-react-migration-issue-view_u9myz'),
	HoverContainerExperiment,
	HoverContainerControl,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BadgeWrapper = styled2.div({
	border: '2px solid transparent',
});
