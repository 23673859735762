import { getCurrentHostPattern } from '@atlassian/jira-issue-view-common-utils/src/strip-url-to-issue-key/index.tsx';
import { toBaseUrl, toHref } from '@atlassian/jira-shared-types/src/general.tsx';
import type { SelectItemAppLink } from '../jira-app-links/types';

export const LOCAL_JIRA_APP_LINK_ID = 'local';
export const LOCAL_JIRA_APP_LINK_LABEL = 'Local';

export const LOCAL_JIRA_APP_LINK: SelectItemAppLink = {
	label: LOCAL_JIRA_APP_LINK_LABEL,
	value: LOCAL_JIRA_APP_LINK_ID,
	fullObject: {
		id: LOCAL_JIRA_APP_LINK_ID,
		name: LOCAL_JIRA_APP_LINK_LABEL,
		baseUrl: __SERVER__ ? toBaseUrl('') : getCurrentHostPattern(),
		authUrl: toHref(''),
		requireCredentials: false,
	},
};

export const ISSUE_SEARCH_DEBOUNCE_TIME = 500;
