import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import reduce from 'lodash/reduce';
import without from 'lodash/without';
import { PRODUCT_DISCOVERY_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { RemoteJiraIssue } from '@atlassian/jira-polaris-remote-issue/src/controllers/crud/types.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { IssueType } from '../../../project/types';
import { getFieldMappings } from '../../selectors/fields';
import { getFilteredIssueIds } from '../../selectors/filters';
import {
	getCreatedIssueIds,
	getCreatedProperties,
	getIssueIdsInCreation,
	createGetIssueAnalyticsAttributes,
} from '../../selectors/properties';
import { getSortedIssueIds, getSortedIssueIndex } from '../../selectors/sort';
import {
	IssueCreateGroupTypeUnknown,
	IssueCreateStatusCreated,
	IssueCreateStatusInTransition,
	type Props,
	type State,
	type PropertyMaps,
	type IssueCreatedProperty,
	type IssueCreatedPropertyItemGroupType,
	IssueCreateGroupTypeSpecified,
	IssueCreateGroupTypeEmpty,
	IssueCreateGroupTypeNoGroup,
	IssueCreateStatusInCreation,
	type IssueCreatedPropertyItem,
} from '../../types';
import { generateLocalIssueId } from '../../utils/local-id.tsx';
import { incrementOpenUpdateCounter } from '../real-time';
import { createIssueInternal, saveIssueInternal, updateLocalIssueIdToJiraId } from './utils';

export const cancelCreations =
	() =>
	({ getState, setState }: StoreActionApi<State>) => {
		const state = getState();

		const localIssueIds = getIssueIdsInCreation(state);

		if (localIssueIds !== undefined && localIssueIds.length > 0) {
			const { ids, properties } = state;
			const { created } = properties;

			const newIds: LocalIssueId[] = without(ids, ...localIssueIds);
			const newCreated = omit(created, localIssueIds);

			setState({
				properties: {
					...properties,
					created: newCreated,
				},
				ids: newIds,
			});
		}
	};

// Finds propper index of the issue where to place the new created issue id in the sorted list
// When we created a new issue it appears in certain place in the list, but after it's saved
// it can move to a different place because of sorting, we need to find the right place
// which are stable issues ids( not in creation state )
const getCreateIssuePosition = (
	createdIssueIds: LocalIssueId[],
	ids: LocalIssueId[],
	positionIndex: number,
	direction: -1 | 1,
) => {
	let idx = positionIndex;
	while (ids[idx]) {
		if (!createdIssueIds.includes(ids[idx])) {
			return ids[idx];
		}
		idx += direction;
	}
	return undefined;
};

/** create issue action for table view which takes the position where the new issue should be created in the table, considering sorting. */
export const createIssue =
	(
		positionIndex: number,
		localIssueId?: LocalIssueId,
		grouping: IssueCreatedPropertyItemGroupType = { groupType: IssueCreateGroupTypeUnknown },
	) =>
	({ getState, setState, dispatch }: StoreActionApi<State>, props: Props) => {
		// cancel already existing issue creations that have not been confirmed yet
		dispatch(cancelCreations());

		// we need to take state after `dispatch`
		// otherwise we will override `cancelCreations()` results
		const state = getState();

		// generate UUID if not passed in
		const newId = localIssueId ?? generateLocalIssueId();

		const ids = getSortedIssueIds(state, props);

		const createdIssueIds = (() => {
			if (
				(grouping.groupType === IssueCreateGroupTypeSpecified ||
					grouping.groupType === IssueCreateGroupTypeEmpty ||
					grouping.groupType === IssueCreateGroupTypeNoGroup) &&
				grouping.rankingAllowed
			) {
				return getIssueIdsInCreation(state);
			}

			return getCreatedIssueIds(state);
		})();

		const rankBefore = getCreateIssuePosition(createdIssueIds, ids, positionIndex, 1);
		const rankAfter = getCreateIssuePosition(createdIssueIds, ids, positionIndex - 1, -1);

		const newState = createIssueInternal({
			state,
			newId,
			rankBefore,
			rankAfter,
			grouping,
		});
		setState(newState);
	};

export const createIssueAfter =
	(
		anchor: LocalIssueId,
		localIssueId?: LocalIssueId,
		grouping?: IssueCreatedPropertyItemGroupType,
	) =>
	({ getState, dispatch }: StoreActionApi<State>, props: Props) => {
		const indexSelector = getSortedIssueIndex(anchor);

		const positionIndex = indexSelector(getState(), props);

		dispatch(createIssue(positionIndex + 1, localIssueId, grouping));
	};

export const createIssueBefore =
	(
		anchor: LocalIssueId,
		localIssueId?: LocalIssueId,
		grouping?: IssueCreatedPropertyItemGroupType,
	) =>
	({ getState, dispatch }: StoreActionApi<State>, props: Props) => {
		const indexSelector = getSortedIssueIndex(anchor);

		const positionIndex = indexSelector(getState(), props);

		dispatch(createIssue(positionIndex, localIssueId, grouping));
	};

/** cancels the issue creation for a given issue id */
export const cancelCreateIssue =
	(localIssueId: LocalIssueId) =>
	({ getState, setState }: StoreActionApi<State>) => {
		const state = getState();
		const { ids, properties } = state;
		const { created } = properties;

		const newIds = ids.filter((id) => id !== localIssueId);
		const newCreated = omit(created, [localIssueId]);

		setState({
			properties: {
				...properties,
				created: newCreated,
			},
			ids: newIds,
		});
	};

/** save Issue action - creates issue in Jira and also ranks it accordingly. */
export const saveIssue =
	(
		id: LocalIssueId,
		issueType: IssueType,
		summary: string,
		clonedCreatedProperty: IssueCreatedPropertyItem,
		clonedIds: LocalIssueId[],
		onCreatedIssueFiltered: (arg1: LocalIssueId) => void,
		onIssueSaved?: (options: {
			issueId: IssueId;
			localIssueId: LocalIssueId;
			createdProperty: IssueCreatedPropertyItem;
		}) => void,
		onIssueSaveError?: (arg1: Error) => void,
	) =>
	({ getState, setState, dispatch }: StoreActionApi<State>, props: Props) => {
		dispatch(incrementOpenUpdateCounter([id]));
		saveIssueInternal(
			getState,
			setState,
			dispatch,
			props,
			id,
			issueType,
			summary,
			clonedCreatedProperty,
			clonedIds,
			onCreatedIssueFiltered,
			onIssueSaved,
			onIssueSaveError,
		);
	};

// This function creates an issue with given issue type, summary and field value pair and immediately performs an update
// operation on it.
export const createAndUpdate =
	(
		issueType: IssueType,
		summary: string,
		optimisticUpdateFields: Record<FieldKey, unknown>,
		onOptimisticCreationFinished: (arg1: LocalIssueId) => void,
		updateOperation: (arg1: LocalIssueId, arg2: State, arg3: RemoteJiraIssue) => void,
	) =>
	({ getState, setState, dispatch }: StoreActionApi<State>, props: Props) => {
		const {
			projectId,
			rankField,
			isRankingEnabled,
			issuesRemote,
			onIssueCreationFailed,
			createAnalyticsEvent,
		} = props;
		if (projectId === undefined || issueType === undefined) {
			// TODO proper error handling
			throw new Error('project / issueType undefined');
		}

		const state = getState();

		const id = generateLocalIssueId();
		const optimisticProperties: PropertyMaps = {
			...state.properties,
			string: {
				...state.properties.string,
				summary: {
					...state.properties.string.summary,
					[id]: summary,
				},
			},
			created: {
				...state.properties.created,
				[id]: {
					status: IssueCreateStatusInTransition,
					groupType: IssueCreateGroupTypeUnknown,
					anchorBefore: undefined,
					anchorAfter: undefined,
				},
			},
		};
		const fieldMappings = getFieldMappings(state, props);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		Object.entries(optimisticUpdateFields).forEach(([fieldKey, fieldValue]: [any, any]) => {
			fieldMappings[fieldKey].setMutable(optimisticProperties, id, fieldValue);
		});
		setState({
			properties: optimisticProperties,
			ids: [...state.ids, id],
		});
		onOptimisticCreationFinished(id);

		dispatch(incrementOpenUpdateCounter([id]));

		issuesRemote
			.createIssue({
				rankField,
				issueTypeId: Number(issueType.jiraIssueTypeId),
				fieldsMap: { summary },
				isRankingEnabled,
				onCreated: (jiraIssueId) =>
					updateLocalIssueIdToJiraId(id, Number(jiraIssueId), getState, setState),
			})
			.then((response) => {
				const stateForUpdateOperation = getState();
				const { created } = stateForUpdateOperation.properties;
				const newCreated = omit(created, id);
				const properties = {
					...stateForUpdateOperation.properties,
					created: newCreated,
				};
				const updatedProperties = reduce(
					fieldMappings,
					(acc, mapping) => {
						const v = mapping.getValueFromJiraIssue(response);
						return mapping.setImmutable(acc, id, v !== null ? v : undefined);
					},
					properties,
				);

				setState({
					properties: updatedProperties,
				});
				updateOperation(id, getState(), response);

				const analyticsEvent = createAnalyticsEvent({});
				analyticsEvent.context.push({ objectId: String(response.id), objectType: 'issue' });
				fireTrackAnalytics(analyticsEvent, 'issue created', String(response.id), {
					...createGetIssueAnalyticsAttributes(id)(getState()),
					projectType: PRODUCT_DISCOVERY_PROJECT,
				});
			})
			.catch((error) => {
				onIssueCreationFailed(error);
			});
	};

/** remove 'created' marker for newly created issues, so that they end up in the correct sort position */
export const clearCreatedProperty =
	() =>
	({ getState, setState }: StoreActionApi<State>, props: Props) => {
		const state = getState();
		const { properties } = state;
		// @ts-expect-error - TS2554 - Expected 1 arguments, but got 2.
		const created = getCreatedProperties(state, props);
		// @ts-expect-error - TS2554 - Expected 1 arguments, but got 2.
		const createdIssueIds = getCreatedIssueIds(state, props);
		const newCreated: IssueCreatedProperty = {};

		createdIssueIds.forEach((id: LocalIssueId) => {
			if (created[id].status !== IssueCreateStatusCreated) {
				newCreated[id] = created[id];
			}
		});

		if (isEqual(newCreated, properties.created)) {
			return;
		}

		setState({
			properties: {
				...properties,
				created: newCreated,
			},
		});
	};

// This function creates an issue with given issue type, summary and field value pair
export const submitIdea =
	(
		issueType: IssueType,
		summary: string,
		optimisticUpdateFields: Record<FieldKey, unknown>,
		onSuccessfullyCreated: (issueKey: IssueKey, isFiltered?: boolean) => void,
		onCreationFinished: () => void,
	) =>
	({ getState, setState, dispatch }: StoreActionApi<State>, props: Props) => {
		const {
			projectId,
			rankField,
			isRankingEnabled,
			issuesRemote,
			onIssueCreationFailed,
			createAnalyticsEvent,
		} = props;
		if (projectId === undefined || issueType === undefined) {
			throw new Error('project / issueType undefined');
		}

		const state = getState();

		const id = generateLocalIssueId();
		const optimisticProperties: PropertyMaps = {
			...state.properties,
			string: {
				...state.properties.string,
				summary: {
					...state.properties.string.summary,
					[id]: summary,
				},
			},
			created: {
				...state.properties.created,
				[id]: {
					status: IssueCreateStatusInTransition,
					groupType: IssueCreateGroupTypeUnknown,
					anchorBefore: undefined,
					anchorAfter: undefined,
				},
			},
		};
		const fieldMappings = getFieldMappings(state, props);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		Object.entries(optimisticUpdateFields).forEach(([fieldKey, fieldValue]: [any, any]) => {
			fieldMappings[fieldKey].setMutable(optimisticProperties, id, fieldValue);
		});
		setState({
			properties: optimisticProperties,
			ids: [...state.ids, id],
		});
		dispatch(incrementOpenUpdateCounter([id]));
		issuesRemote
			.createIssue({
				rankField,
				issueTypeId: Number(issueType.jiraIssueTypeId),
				fieldsMap: optimisticUpdateFields,
				isRankingEnabled,
				onCreated: (jiraIssueId) =>
					updateLocalIssueIdToJiraId(id, Number(jiraIssueId), getState, setState),
			})
			.then((response) => {
				const stateForUpdateOperation = getState();
				const { created } = stateForUpdateOperation.properties;
				const newCreated = omit(created, id);
				const properties = {
					...stateForUpdateOperation.properties,
					created: newCreated,
				};
				const updatedProperties = reduce(
					fieldMappings,
					(acc, mapping) => {
						const v = mapping.getValueFromJiraIssue(response);
						return mapping.setImmutable(acc, id, v !== null ? v : undefined);
					},
					properties,
				);

				const newState = { ...getState(), properties: updatedProperties };
				setState(newState);

				const filteredIds = getFilteredIssueIds(newState, props);
				const isFiltered = !filteredIds.includes(id);

				const analyticsEvent = createAnalyticsEvent({});
				analyticsEvent.context.push({ objectId: String(response.id), objectType: 'issue' });
				fireTrackAnalytics(analyticsEvent, 'issue created', String(response.id), {
					...createGetIssueAnalyticsAttributes(id)(getState()),
					projectType: PRODUCT_DISCOVERY_PROJECT,
				});

				onSuccessfullyCreated(response.key, isFiltered);
			})
			.catch((error) => {
				onIssueCreationFailed(error);
			})
			.finally(() => onCreationFinished());
	};

export const safelySwapRowInCreationWithCreated =
	(createdId: LocalIssueId, createdInstance: IssueCreatedProperty[string]) =>
	({ getState, setState }: StoreActionApi<State>, props: Props) => {
		const state = getState();
		const { properties } = state;
		const { created } = properties;

		const createdRankingAllowed =
			createdInstance.groupType === IssueCreateGroupTypeSpecified ||
			createdInstance.groupType === IssueCreateGroupTypeEmpty ||
			createdInstance.groupType === IssueCreateGroupTypeNoGroup
				? createdInstance.rankingAllowed
				: undefined;
		const createdAnchorBefore = createdInstance.anchorBefore;
		const createdAnchorAfter = createdInstance.anchorAfter;

		const ids = getSortedIssueIds(state, props);

		if (ids[0] !== createdId) {
			return;
		}

		if (createdRankingAllowed) {
			return;
		}

		if (createdAnchorAfter === undefined || createdAnchorBefore) {
			return;
		}

		const inCreationId = Object.keys(created).find(
			(key) =>
				created[key].status === IssueCreateStatusInCreation &&
				created[key].anchorBefore === undefined &&
				created[key].anchorAfter === createdAnchorAfter,
		);

		if (!inCreationId) {
			return;
		}

		const nextState: State = {
			...state,
			properties: {
				...properties,
				created: {
					...created,
					[inCreationId]: {
						...created[inCreationId],
						anchorBefore: undefined,
						anchorAfter: createdId,
					},
				},
			},
		};

		setState(nextState);
	};
