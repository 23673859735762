import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { getIssueApiUrl } from './issue-urls';

export const updateOrganization = (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	fieldMetaKey: string,
	value: { id: unknown }[],
) =>
	performPutRequest(getIssueApiUrl(baseUrl, issueKey), {
		body: JSON.stringify({
			fields: {
				[fieldMetaKey]: value.map((org) => Number(org.id)),
			},
		}),
	});
