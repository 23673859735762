import { useMemo } from 'react';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { LayoutContainerTemplateItem } from '@atlassian/jira-issue-layout-common-constants';
import type { AdditionalEventAttributes } from '@atlassian/jira-issue-view-layout-group/src/common/types';
import { useLayoutItem } from '@atlassian/jira-issue-view-layout-templates-item-list-services';
import {
	GROUP_APP,
	GROUP_CUSTOM,
	GROUP_PANEL,
	GROUP_SYSTEM,
	type IssueViewLayoutItemTypeWithAnalytics,
} from '@atlassian/jira-issue-view-layout-templates-item-list/src/types';
import { getLayoutItemId } from '@atlassian/jira-issue-view-layout/src/services/utils.tsx';
import { useSecondaryItemIds } from '@atlassian/jira-issue-view-layout/src/utils.tsx';

type LayoutGroupsEvents = {
	appFieldTypes: string[];
	customFieldTypes: string[];
	panelItemTypes: string[];
	systemFieldTypes: string[];
};

// There are some cases in which fields are able to return DOM nodes with no content.
// We currently have no way of knowing if a field is going to return null/empty until
// render time. Most of the time, these fields will return null, but there are some
// fields like devPanel that return <div><div></div></div>.
// This ensures that we do not render the group heading and container in the case
// where the content is empty.
// sometimes there is A loading state (i.e in SLA panel) so we don't want this to be
// mistaken for empty
export const bottomLevelNodesAreEmpty = (node: HTMLElement) => {
	if (node.children.length) {
		return Array.prototype.every.call(node.children, bottomLevelNodesAreEmpty);
	}
	return node.innerHTML === '';
};

export const getSecondaryFieldsFromGroup = (
	secondaryItemIds: string[],
	fields: IssueViewLayoutItemTypeWithAnalytics[],
): string[] =>
	fields.map((field) => getLayoutItemId(field)).filter((id) => secondaryItemIds.includes(id));

// Groups fields depending on whether they are custom or not
export const groupFieldsByType = (fields: IssueViewLayoutItemTypeWithAnalytics[]) => {
	const groups: LayoutGroupsEvents = {
		appFieldTypes: [],
		customFieldTypes: [],
		panelItemTypes: [],
		systemFieldTypes: [],
	};

	fields.forEach((field) => {
		const { analytics } = field;
		switch (analytics.group) {
			case GROUP_APP:
				groups.appFieldTypes.push(analytics.value);
				break;
			case GROUP_CUSTOM:
				groups.customFieldTypes.push(analytics.value);
				break;
			case GROUP_PANEL:
				groups.panelItemTypes.push(analytics.value);
				break;
			case GROUP_SYSTEM:
				groups.systemFieldTypes.push(analytics.value);
				break;
			default:
				break;
		}
	});

	return groups;
};

// Helper function that creates the Additional Analytics object
export const generateAdditionalAnalyticsEventAttributes = (
	fields: LayoutGroupsEvents,
	numOfFields: number,
	numHiddenWhenEmptyItems: number,
): AdditionalEventAttributes => {
	const { appFieldTypes, customFieldTypes, panelItemTypes, systemFieldTypes } = fields;

	return {
		numHiddenWhenEmptyItems,
		panelItemTypes,
		systemFieldTypes,
		customFieldTypes,
		appFieldTypes,
		numOfFields,
	};
};

export const useGenerateAdditionalAnalyticsEventAttributes = (
	fields: LayoutContainerTemplateItem[],
): AdditionalEventAttributes => {
	const issueKey = useIssueKey();
	const secondaryItemIds = useSecondaryItemIds(issueKey);
	const layoutItemFields = useLayoutItem(fields);

	const numHiddenWhenEmptyItems = useMemo(
		() => getSecondaryFieldsFromGroup(secondaryItemIds, layoutItemFields).length,
		[layoutItemFields, secondaryItemIds],
	);

	const fieldsByGroupType = useMemo(() => groupFieldsByType(layoutItemFields), [layoutItemFields]);

	const additionalAnalyticsEvents = useMemo(
		() =>
			generateAdditionalAnalyticsEventAttributes(
				fieldsByGroupType,
				layoutItemFields.length,
				numHiddenWhenEmptyItems,
			),
		[fieldsByGroupType, layoutItemFields.length, numHiddenWhenEmptyItems],
	);

	return additionalAnalyticsEvents;
};

// SLA Panel closure function that helps finding the SLA Panel
export const makeObserver =
	(node: HTMLElement | null, hasItems: boolean, setHasItems: (hasItems: boolean) => void) =>
	(mutationsList: MutationRecord[]) => {
		mutationsList.forEach((mutation) => {
			if (mutation.type === 'childList' && node !== null) {
				if (!bottomLevelNodesAreEmpty(node)) {
					!hasItems && setHasItems(true);
				} else if (hasItems) {
					setHasItems(false);
				}
			}
		});
	};
