/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	classifyIssue: {
		id: 'issue.classify',
		defaultMessage: 'Classify issue',
		description: 'Label for the issue actions item to classify the issue',
	},
	classifyRequest: {
		id: 'request.classify',
		defaultMessage: 'Classify request',
		description: 'Label for the request actions item to classify the request in service project',
	},
});
