import memoizeOne from 'memoize-one';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import UserInlineEditView from '@atlassian/jira-issue-internal-fields/src/user/view';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages';
import { getIssueModalAkDropdownPortal } from '@atlassian/jira-issue-view-common-utils/src/get-element/index.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field';
import { issueKeySelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { isFieldRequiredSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector';
import { isFieldInTabSelector } from '@atlassian/jira-issue-view-store/src/selectors/tab-selector';
import { transformFromStateValue, transformToStateValue } from '../assignee/assignee-transformer';

export const fetchSuggestionsFactory = memoizeOne(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(fieldId: string, issueKey: string) => async (query: any) => {
		const suggestions = await fetchJson(
			`/rest/api/3/user/recommend?${new URLSearchParams({
				context: 'CustomUserField',
				...(issueKey && { issueKey }),
				...(fieldId && { fieldId }),
				query: query ?? '',
			})}`,
		);

		return suggestions.map(transformFromStateValue);
	},
);

export const getEmptyOption = memoizeOne((intl) => ({
	avatarUrl: '',
	name: intl.formatMessage(genericMessages.noValue),
}));
export default connectField((stateOnMount, ownPropsOnMount) => ({
	fieldId: ownPropsOnMount.fieldId,
	transformFromStateValue,
	transformToStateValue,
	additionalProps: (state, intl) => ({
		portalElement: isFieldInTabSelector(ownPropsOnMount.fieldId)(state)
			? // Using a portal element here because otherwise dropdowns would get cut off

				// See: https://jdog.jira-dev.com/browse/BENTO-4101

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				getIssueModalAkDropdownPortal() || document.body
			: undefined,
		fetchSuggestions: fetchSuggestionsFactory(ownPropsOnMount.fieldId, issueKeySelector(state)),
		emptyOption: isFieldRequiredSelector(ownPropsOnMount.fieldId)(state)
			? undefined
			: getEmptyOption(intl),
		showPinButton: getShowPinButton(ownPropsOnMount.area),
	}),
}))(UserInlineEditView);
