// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createReciprocalLinkCheckboxLabel: {
		id: 'issue.create-reciprocal-link.checkbox-label',
		defaultMessage: 'Create reciprocal link',
		description: 'Label of the create reciprocal link checkbox',
	},
	createReciprocalLinkCheckboxTooltip: {
		id: 'issue.create-reciprocal-link.checkbox-tooltip',
		defaultMessage:
			'Jira will create a link from the issue on the remote Jira instance back to this issue',
		description: 'Tooltip of the create reciprocal link checkbox',
	},
});
