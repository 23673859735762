import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import LinkIcon from '@atlaskit/icon/glyph/link';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { SimilarIssueLink } from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { useIssueLinkLocality } from '@atlassian/jira-issue-view-services/src/issue-link-locality-service/context';
import { addIssueLinkClick } from '@atlassian/jira-issue-view-store/src/actions/issue-links-actions';
import type { RelatedIssue } from '../../../common/types';
import { messages } from './messages';

export type IssueLineCardProps = RelatedIssue & {
	onAddIssueLinkClick: (linkedIssue: SimilarIssueLink) => void;
};

const IssueLineCard = ({
	id,
	issueTypeIconUrl,
	issueTypeName,
	issueKey,
	issueSummary,
	onAddIssueLinkClick,
}: IssueLineCardProps) => {
	const { formatMessage } = useIntl();

	const title = formatMessage(messages.issueTypeName, { issueTypeName });

	// We only want the JSM Similar Issues panel's "Link" button to show up if the standard
	// Jira linking panel dropdown has a local Jira instance selected. This is because the JSM
	// Similar Issues panel just shows related issues from the same instance, so we only want to
	// offer linking if the same instance is selected.
	const [{ isLinkingToLocalJira }] = useIssueLinkLocality();

	const issueLink = `/browse/${issueKey}`;

	return (
		<>
			<StyledIssueButton
				appearance="link"
				href={issueLink}
				iconBefore={<AsyncIcon alt={title} url={issueTypeIconUrl} />}
			>
				{issueKey} {issueSummary}
			</StyledIssueButton>
			{isLinkingToLocalJira && (
				<Tooltip content={formatMessage(messages.issueLinkingButton)} position="left">
					{({ ref, ...tooltipProps }) => (
						<StyledLinkingButton
							ref={ref}
							appearance="subtle"
							spacing="compact"
							{...tooltipProps}
							iconBefore={<LinkIcon label="" />}
							aria-label={formatMessage(messages.issueLinkingButtonAriaLabel)}
							onClick={(event) => {
								event.stopPropagation(); // to not 'click' the panel beneath the button
								onAddIssueLinkClick({
									id,
									iconUrl: issueTypeIconUrl,
									value: issueKey,
									fullObject: {
										id: Number(id),
										key: issueKey,
										keyHtml: issueKey,
										img: issueTypeIconUrl,
										summary: issueSummary,
										summaryText: issueSummary, // if not specified- other links re-render on add
									},
								});
							}}
						/>
					)}
				</Tooltip>
			)}
		</>
	);
};

export default connect(null, {
	onAddIssueLinkClick: addIssueLinkClick,
})(IssueLineCard);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledIssueButton = styled(Button)({
	marginTop: token('space.050', '4px'),
});

StyledIssueButton.displayName = 'StyledIssueButton';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledLinkingButton = styled(Button)({
	marginLeft: 'auto',
	alignSelf: 'center',
});

StyledLinkingButton.displayName = 'StyledLinkingButton';
