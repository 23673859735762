import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { AssignInvitedUserApiRequest } from './types';

const getRestApiPath = (issueKey: IssueKey) =>
	`/rest/internal/latest/issue/${issueKey}/assignInvitedUser`;

export const postAssignInvitedUsers = async ({
	issueKey,
	invitedUserId,
}: AssignInvitedUserApiRequest) =>
	performPostRequest(getRestApiPath(issueKey), {
		body: JSON.stringify({
			invitedUserId,
		}),
	});
