/**
 * Feature flags should be defined individually in './feature-flags.js'
 * There is processing on that file to automate analytics discovery.
 * We import them here so they can be exported as one value and given to
 * Editor/Renderer/Media components.
 *
 * NOTE: The use of functions vs raw values is intentional. You must use
 * `mediaFeatureFlags()` vs `mediaFeatureFlags` due to the fact that these
 * flags won't be available immediately. This relates to SST and launchdarkly.
 * Otherwise the getters would be executed immediately and blank values
 * will be passed.
 */

import { isMediaInlineEnabled } from './feature-flags';

export const mediaFeatureFlags = () => ({
	mediaInline: isMediaInlineEnabled(),
	// TODO: remove after media team clean up securedClipboard: https://product-fabric.atlassian.net/browse/CXP-2664
	securedClipboard: true,
});
