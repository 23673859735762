import React, { useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { TRIGGER_POINT_KEY_ISSUE_VIEW } from '@atlassian/jira-issue-archival-modal/src/constants.tsx';
import { useArchiveIssueModalActions } from '@atlassian/jira-issue-archival-modal/src/controllers/main.tsx';
import { useIssueKey, useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { Props } from './types';

const ArchiveIssue = ({ onClick, label, itemKey, onIssueDeleteSuccess, onClose }: Props) => {
	const issueKey = useIssueKey();
	const issueId = useIssueId();
	const { openArchiveIssueModal } = useArchiveIssueModalActions();

	const handleClick = useCallback(() => {
		const onIssueArchiveSuccess = () => {
			if (issueKey && issueId) onIssueDeleteSuccess?.({ issueKey, issueId });
		};
		openArchiveIssueModal({
			issueKey,
			issueId,
			onIssueArchiveSuccess,
			onCloseIssueViewModal: onClose,
			triggerPointKey: TRIGGER_POINT_KEY_ISSUE_VIEW,
		});
		onClick?.(itemKey);
	}, [openArchiveIssueModal, issueKey, issueId, onClose, onClick, itemKey, onIssueDeleteSuccess]);

	return (
		<DropdownItem key="archive" onClick={handleClick}>
			{label}
		</DropdownItem>
	);
};

export default ArchiveIssue;
