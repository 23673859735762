// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	upload: {
		id: 'issue.attachment-dropzone-message',
		defaultMessage: 'Drop file anywhere to upload to this issue',
		description: 'Text for attachment dropzone',
	},
	uploadImage: {
		id: 'issue.attachment-dropzone-message-image',
		defaultMessage: 'Upload Attachment',
		description: 'Text for upload attachment image',
	},
});
