import React from 'react';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import { AsyncLinkedAlertsField } from '@atlassian/jira-linked-alerts/src/async.tsx';

type LinkedAlertsProps = {
	count: number;
	area: Area;
	fieldId: string;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ count, fieldId, area }: LinkedAlertsProps) => (
	<AsyncLinkedAlertsField count={count} fieldId={fieldId} showPinButton={getShowPinButton(area)} />
);
