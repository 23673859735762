import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireScreenAnalytics, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { sendPendoTrackEvent } from '../../pendo';

export const BoardView = {
	opened: (event: UIAnalyticsEvent, accessLevel?: string) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/qM00ZERYuH-iFTxMnCfYLZpuKgQ
		sendPendoTrackEvent({
			actionSubjectAndAction: 'boardView opened',
			attributes: { accessLevel: accessLevel || '' },
		});

		// https://data-portal.internal.atlassian.com/analytics/registry/51089
		fireScreenAnalytics(event, { accessLevel });
	},
	cardDropped: (event: UIAnalyticsEvent, attributes: object = {}) => {
		sendPendoTrackEvent('boardViewCard dropped');

		// https://data-portal.internal.atlassian.com/analytics/registry/50694
		fireUIAnalytics(event, 'card dragged', 'idea', attributes);
	},
	columnDropped: (event: UIAnalyticsEvent, attributes: object) => {
		sendPendoTrackEvent('boardViewColumn dropped');
		// https://data-portal.internal.atlassian.com/analytics/registry/50789
		fireUIAnalytics(event, 'column dragged', 'fieldValue', attributes);
	},
	fieldValueUpdatedByDragBetweenColumns: (fieldType: string | undefined) => {
		sendPendoTrackEvent(`fieldValue updated ${fieldType} boardDragBetweenColumns`);
	},
	fieldValueUpdatedByDragBetweenSwimlanes: (fieldType: string | undefined) => {
		sendPendoTrackEvent(`fieldValue updated ${fieldType} boardDragBetweenSwimlanes`);
	},
};
