import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { Props } from './types';
import type StakeholderUpdatesModalContainer from './index';

const SendStakeholderUpdatesModalLazy = lazyForPaint<typeof StakeholderUpdatesModalContainer>(
	() => import(/* webpackChunkName: "async-send-stakeholder-updates-modal" */ './index'),
);

export const AsyncSendStakeholderUpdatesModal = ({ onCloseUpdateStakeholderModal }: Props) => (
	<Placeholder name="send-stakeholder-updates-modal" fallback={null}>
		<SendStakeholderUpdatesModalLazy
			onCloseUpdateStakeholderModal={onCloseUpdateStakeholderModal}
		/>
	</Placeholder>
);
