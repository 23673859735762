import React, { Component } from 'react';
import CommonDropzoneMediaPicker from '../../common/dropzone';
import EventHandlersProvider from '../common/event-handlers-provider';

type Props = {
	container: HTMLElement;
	onDragEnter: () => void;
	onDragLeave: () => void;
	onDrop: () => void;
};

// eslint-disable-next-line jira/react/no-class-components
export default class ServiceDeskDropzonePicker extends Component<Props> {
	getConfig() {
		return {
			container: this.props.container,
		};
	}

	render() {
		return (
			<EventHandlersProvider
				render={(eventHandlers) => (
					<CommonDropzoneMediaPicker
						config={this.getConfig()}
						onDragEnter={this.props.onDragEnter}
						onDragLeave={this.props.onDragLeave}
						onDrop={this.props.onDrop}
						eventHandlers={eventHandlers}
					/>
				)}
			/>
		);
	}
}
