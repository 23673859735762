import React from 'react';
import { DevDetailsDialogLoaderAsync } from '@atlassian/jira-development-details-loader/src/ui/async.tsx';
import DevDetailsDialogLoader from '@atlassian/jira-development-details-loader/src/ui/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { DEV_DETAILS_DIALOG_SCOPE_ID } from '../../common/constants';
import { ErrorBoundary } from '../../common/ui/error-boundary';
import { DevOpsSummaryContainer as BaseContainer } from '../../services/context';
import {
	DevOpsSummaryExperienceStart,
	useDevOpsSummaryExperienceFail,
} from '../../services/experience-tracking';
import type { Props } from './types';

export const DevOpsSummaryContainer = ({ children, issueIds, location }: Props) => {
	const cloudId = useCloudId();

	if (fg('change_dev_ops_summary_to_sync')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const onFail = useDevOpsSummaryExperienceFail();

		return (
			<UFOSegment name="DevOpsSummaryContainer">
				<DevOpsSummaryExperienceStart location={location} />
				<ErrorBoundary fallback={() => <>{children}</>} onError={onFail}>
					<DevDetailsDialogLoader scopeId={DEV_DETAILS_DIALOG_SCOPE_ID} />
					<BaseContainer cloudId={cloudId} issueIds={issueIds} location={location}>
						{children}
					</BaseContainer>
				</ErrorBoundary>
			</UFOSegment>
		);
	}

	return (
		<>
			<DevDetailsDialogLoaderAsync scopeId={DEV_DETAILS_DIALOG_SCOPE_ID} />
			<BaseContainer cloudId={cloudId} issueIds={issueIds} location={location}>
				{children}
			</BaseContainer>
		</>
	);
};
