import type { Details } from '../../../model';

export const FETCH_DETAIL = 'state.actions.details.FETCH_DETAIL' as const;

export type FetchDetailAction = {
	type: typeof FETCH_DETAIL;
};

export const fetchDetail = (): FetchDetailAction => ({
	type: FETCH_DETAIL,
});

export const SET_DETAIL_DATA = 'state.actions.details.SET_DETAIL_DATA' as const;

export const FIRE_BITBUCKET_DIFFSTAT_ANALYTICS_EVENT =
	'state.actions.details.FIRE_BITBUCKET_DIFFSTAT_ANALYTICS_EVENT' as const;

export const FIRE_DETAIL_DATA_FETCHED_EVENT =
	'state.actions.details.FIRE_DETAIL_DATA_FETCHED_EVENT' as const;

export type SetDetailDataPayload = {
	details: Details;
	isOptOutOfDeploymentDiscovery: boolean;
};

export type SetDetailDataAction = {
	type: typeof SET_DETAIL_DATA;
	payload: SetDetailDataPayload;
};

export type FireDetailDataFetchedEventAction = {
	type: typeof FIRE_DETAIL_DATA_FETCHED_EVENT;
	payload: SetDetailDataPayload;
};

export type FireBitbucketDiffstatAnalyticsEventAction = {
	type: typeof FIRE_BITBUCKET_DIFFSTAT_ANALYTICS_EVENT;
};

export const setDetailData = (payload: SetDetailDataPayload): SetDetailDataAction => ({
	type: SET_DETAIL_DATA,
	payload,
});

export const fireBitbucketDiffstatAnalyticsEvent =
	(): FireBitbucketDiffstatAnalyticsEventAction => ({
		type: FIRE_BITBUCKET_DIFFSTAT_ANALYTICS_EVENT,
	});

export const fireDetailDataFetchedEvent = (
	payload: SetDetailDataPayload,
): FireDetailDataFetchedEventAction => ({
	type: FIRE_DETAIL_DATA_FETCHED_EVENT,
	payload,
});

export const FETCHING_FAILED = 'state.actions.details.FETCHING_FAILED' as const;

export type FetchingFailedAction = {
	type: typeof FETCHING_FAILED;
};

export const fetchingFailed = (): FetchingFailedAction => ({
	type: FETCHING_FAILED,
});

export const OPT_OUT_DEPLOYMENTS_DISCOVERY = 'state.actions.OPT_OUT_DEPLOYMENTS_DISCOVERY' as const;

export type OptOutDeploymentDetailDiscoveryAction = {
	type: typeof OPT_OUT_DEPLOYMENTS_DISCOVERY;
};

export const optOutDeploymentDetailDiscovery = (): OptOutDeploymentDetailDiscoveryAction => ({
	type: OPT_OUT_DEPLOYMENTS_DISCOVERY,
});

export type Action =
	| FetchDetailAction
	| SetDetailDataAction
	| FireDetailDataFetchedEventAction
	| FireBitbucketDiffstatAnalyticsEventAction
	| FetchingFailedAction
	| OptOutDeploymentDetailDiscoveryAction;
