/**
 * @generated SignedSource<<c6941ce0937352fe894d00bbf0c33be2>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type headerActions_issueViewFoundation_HeaderActionsView$data = {
  readonly watchesField?: {
    readonly " $fragmentSpreads": FragmentRefs<"header_issueViewWatchers_WatchersItem">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelField">;
  readonly " $fragmentType": "headerActions_issueViewFoundation_HeaderActionsView";
};
export type headerActions_issueViewFoundation_HeaderActionsView$key = {
  readonly " $data"?: headerActions_issueViewFoundation_HeaderActionsView$data;
  readonly " $fragmentSpreads": FragmentRefs<"headerActions_issueViewFoundation_HeaderActionsView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssecuritylevelrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldswatchersrelayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "headerActions_issueViewFoundation_HeaderActionsView",
  "selections": [
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssecuritylevelrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelField"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldswatchersrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "watchesField",
          "args": [
            {
              "kind": "Literal",
              "name": "idOrAlias",
              "value": "watches"
            },
            {
              "kind": "Literal",
              "name": "ignoreMissingField",
              "value": true
            }
          ],
          "kind": "LinkedField",
          "name": "fieldByIdOrAlias",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "header_issueViewWatchers_WatchersItem"
                }
              ],
              "type": "JiraWatchesField"
            }
          ],
          "storageKey": "fieldByIdOrAlias(idOrAlias:\"watches\",ignoreMissingField:true)"
        }
      ]
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "5db434ec08059ef7f48324d8da98ad1f";

export default node;
