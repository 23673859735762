import React from 'react';
import WhiteboardIcon from '@atlaskit/icon/core/whiteboard';
import { token } from '@atlaskit/tokens';
import Whiteboard16IconLegacy from '@atlassian/jira-icons/src/ui/whiteboard-icon';

export const Whiteboard16Icon = () => (
	<WhiteboardIcon
		color={token('color.icon.accent.teal', '#00A3BF')}
		label=""
		LEGACY_fallbackIcon={Whiteboard16IconLegacy}
	/>
);
