import React from 'react';
import { SimpleTag } from '@atlaskit/tag';
import TagGroup from '@atlaskit/tag-group';
import { getTagColor } from '@atlassian/jira-servicedesk-customer-service-common/src/ui/color-picker-palette/index.tsx';
import type { ReadViewProps } from '../../../types';

export const MultiSelectFieldReadView = ({
	render,
	values,
	formattedOptions,
	getValueContainer,
	inInlineEdit,
}: ReadViewProps) => {
	const ValueContainer = getValueContainer();

	const formattedValues =
		values?.map((value) => {
			const formattedValue = formattedOptions?.find((option) => option.value === value);
			return {
				label: value,
				color: getTagColor(formattedValue?.color),
			};
		}) ?? [];

	return (
		<ValueContainer
			noVerticalPaddingWhenNotEmpty
			render={
				render ||
				(() => {
					if (!values || !values?.length) {
						return null;
					}

					return (
						<TagGroup>
							{formattedValues.map((value, i) => (
								<SimpleTag
									text={value.label}
									key={i}
									color={value.color}
									testId="servicedesk-customer-service-custom-details.common.ui.field.select-field.multi.read-view.multi-select-field-read-view-tag"
								/>
							))}
						</TagGroup>
					);
				})
			}
			noBorder={inInlineEdit}
		/>
	);
};
