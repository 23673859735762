import { useCallback, useEffect } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import type { ResourceManager } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources/prefetched-resource-manager/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IssueState } from '../../common/types';
import { useAllIssueViewAsyncData } from './context';
import type { UseIssueViewNonCriticalData } from './types';

const useIssueViewNonCriticalData = (
	issueKey: IssueKey,
	prefetchedResourceManager?: ResourceManager,
): Readonly<UseIssueViewNonCriticalData> => {
	const [allData, { fetch }] = useAllIssueViewAsyncData();
	const data = allData[issueKey] ?? {};
	const { isFetching = false, isReady = false } = data;

	const fetchNonCriticalData = useCallback(
		(key: IssueKey) => fetch(key, prefetchedResourceManager),
		[fetch, prefetchedResourceManager],
	);

	useEffect(() => {
		if (!!issueKey && !isFetching && !isReady) {
			// Trigger the data to be fetched and updated when the issue key changes.
			// In practice this should always come through the prefetchedResourceManager
			// as the fetch should always be kicked off by either the early script, or the
			// useLazyLoadingResourceManager at render time.
			fetchNonCriticalData(issueKey);
		}
	}, [issueKey, isFetching, isReady, fetchNonCriticalData]);

	return [data, { fetchNonCriticalData }] as const;
};

export const defaultValue = [
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	{} as IssueState,
	{
		fetchNonCriticalData: () => Promise.resolve(),
	},
] as const;

export default functionWithCondition(
	() => ff('issue-view-remove-connect-operations-from-critical-fetch_vtk4w'),
	useIssueViewNonCriticalData,
	() => defaultValue,
);
