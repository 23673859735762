import React, { useState, useCallback, useMemo } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import { lazyForPaint } from 'react-loosely-lazy';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import AkSpinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import { FIELD_KEY } from '../../common/types';
import { getVoteLabelForDropdown } from '../../common/utils';
import { VOTING_OPTIONS_MENU } from '../../constants';
// eslint-disable-next-line import/order
import messages from '../../messages';

// eslint-disable-next-line import/order
import VoteButton from '../button';

// eslint-disable-next-line import/order
import type VotersDropdownContent from '../dropdown-content';

import type { DropdownContentProps } from '../types';
import type { Props } from './types';

export const DropdownContent = lazyForPaint<typeof VotersDropdownContent>(
	() => import(/* webpackChunkName: "async-voters-dropdown" */ '../dropdown-content'),
);

// eslint-disable-next-line jira/styled/styled-component-order, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerContainer = styled2.div({
	height: '121px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

const fallback = (
	<SpinnerContainer>
		<AkSpinner size="medium" />
	</SpinnerContainer>
);

const LazyDropdownContent = ({
	setCloseDropdown,
	disableVotingProps,
	onFieldUpdated,
}: DropdownContentProps) => (
	<ErrorBoundary id="issue-voters-list" packageName="issue" render={() => fallback}>
		<Placeholder name="dropdown-content" fallback={fallback}>
			<DropdownContent
				setCloseDropdown={setCloseDropdown}
				disableVotingProps={disableVotingProps}
				onFieldUpdated={onFieldUpdated}
			/>
		</Placeholder>
	</ErrorBoundary>
);

export const VoterDropdown = ({ disableVotingProps, onFieldUpdated }: Props) => {
	const issueKey = useIssueKey();
	const [{ hasVoted, votes }] = useFieldValue({
		issueKey,
		fieldKey: FIELD_KEY,
	});
	const { formatMessage } = useIntl();
	const ariaLabel = useMemo(
		() => formatMessage(getVoteLabelForDropdown(hasVoted, votes), { votes }),
		[formatMessage, hasVoted, votes],
	);
	const tooltip = formatMessage(messages.voteOptionsTooltip);

	const [isOpen, setIsOpen] = useState<boolean>(false);

	const toggleDropdown = useCallback(() => {
		setIsOpen((prevIsOpen) => !prevIsOpen);
	}, []);

	const closeDropdown = useCallback(() => {
		setIsOpen(false);
	}, []);

	return (
		<Container>
			<Popup
				boundary="clippingParents"
				isOpen={isOpen}
				placement="auto-end"
				trigger={(triggerProps: TriggerProps) => (
					<div {...triggerProps}>
						<VoteButton
							onClick={toggleDropdown}
							tooltip={tooltip}
							ariaLabel={ariaLabel}
							isSelected={isOpen}
						/>
					</div>
				)}
				onClose={closeDropdown}
				content={() => (
					<ContentWrapper data-testid={VOTING_OPTIONS_MENU}>
						{isOpen && (
							<LazyDropdownContent
								setCloseDropdown={closeDropdown}
								onFieldUpdated={onFieldUpdated}
								disableVotingProps={disableVotingProps}
							/>
						)}
					</ContentWrapper>
				)}
				zIndex={layers.modal}
			/>
		</Container>
	);
};

export default VoterDropdown;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContainerControl = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	"[data-role='droplistContent']": {
		overflow: 'visible',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContainerExperiment = styled2.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	"[data-role='droplistContent']": {
		overflow: 'visible',
	},
});

const Container = styledComponentWithCondition(
	() => ff('compiled-react-migration-issue-view_zilee'),
	ContainerExperiment,
	ContainerControl,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentWrapper = styled2.div({
	width: '240px',
	minHeight: '121px',
	padding: `${token('space.050', '4px')} 0 0`,
});
