import React, { useEffect, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { FieldsCompactModeContext } from '@atlassian/jira-issue-view-layout-templates-compact-mode-provider';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { detailsView_servicedeskCustomerServiceIssueViewPanel$key } from '@atlassian/jira-relay/src/__generated__/detailsView_servicedeskCustomerServiceIssueViewPanel.graphql';
import type { CustomerServiceCustomDetailsEntityType } from '@atlassian/jira-relay/src/__generated__/tableCustomDetailsQuery.graphql';
import { ThrowOnQueryError } from '@atlassian/jira-servicedesk-customer-service-common/src/ui/throw-on-query-error/index.tsx';
import { ReadOnlyCustomDetail } from '@atlassian/jira-servicedesk-customer-service-custom-details/src/ui/read-only-custom-detail/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { type UFOExperience, ExperienceSuccess } from '@atlassian/ufo';
import { EmptyState } from '../empty-state';

type Props = {
	entityType: CustomerServiceCustomDetailsEntityType;
	details: detailsView_servicedeskCustomerServiceIssueViewPanel$key;
	editDetailsUrl: string;
	entityExperience?: UFOExperience;
};

export const DetailsView = ({ entityType, editDetailsUrl, details, entityExperience }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isJiraAdmin = useIsAdmin();
	const isCompact = useContext(FieldsCompactModeContext);

	const data = useFragment(
		graphql`
			fragment detailsView_servicedeskCustomerServiceIssueViewPanel on CustomerServiceCustomDetailValuesQueryResult {
				__typename
				... on CustomerServiceCustomDetailValues {
					results {
						id
						...readOnlyCustomDetail_servicedeskCustomerServiceCustomDetails
					}
				}
				... on QueryError {
					...throwOnQueryError_servicedeskCustomerServiceCommon
				}
			}
		`,
		details,
	);

	useEffect(() => {
		if (data.__typename === 'CustomerServiceCustomDetailValues') {
			fireTrackAnalytics(createAnalyticsEvent({}), 'issueViewCustomerContextExpand loaded', {
				entityType,
				numberOfCsmDetails: data.results?.length || 0,
				isJiraAdmin,
			});
		}
	}, [entityType, createAnalyticsEvent, isJiraAdmin, data]);

	if (data.__typename !== 'CustomerServiceCustomDetailValues') {
		return (
			<ThrowOnQueryError
				operationName="customDetails_servicedeskCustomerServiceIssueViewPanel"
				errorRef={data.__typename === 'QueryError' ? data : undefined}
			/>
		);
	}

	const { results } = data;

	if (results.length === 0) {
		return (
			<EmptyState
				context={entityType}
				detailsUrl={editDetailsUrl}
				entityExperience={entityExperience}
			/>
		);
	}

	return (
		<Box xcss={[wrapperStyles, isCompact && wrapperCompactStyles]}>
			{results.map((customDetailRef) => (
				<ReadOnlyCustomDetail
					key={customDetailRef.id}
					customDetailRef={customDetailRef}
					isInline={!isCompact}
				/>
			))}
			{entityExperience && <ExperienceSuccess experience={entityExperience} />}
		</Box>
	);
};

const wrapperStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	gap: 'space.050',
});

const wrapperCompactStyles = xcss({
	gap: 'space.100',
});
