import { fg } from '@atlassian/jira-feature-gating';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { User } from '@atlassian/jira-polaris-domain-collection';
import type { BulkUserResponse } from './types';

export const constructQuery = (accountIds: string[]) => {
	const mainQuery = new URLSearchParams(accountIds.map((accountId) => ['accountId', accountId]));
	if (fg('jpd-collections-use-maxresults-for-user-fetching')) {
		mainQuery.append('maxResults', '200');
	}

	return `${mainQuery}`;
};

export const fetchUsers = async (accountIds: string[]): Promise<User[]> => {
	const query = constructQuery(accountIds);
	return fetchJson<BulkUserResponse>(`/rest/api/3/user/bulk?${query}`).then(
		(response) => response.values,
	);
};
