import type { Storage } from '@atlassian/jira-browser-storage-providers/src/types.tsx';
import { ALL_DOMAINS, OPTED_OUT_FROM_ALL_IDENTIFIER } from '../common/constants';

export const extractLinkDetails = (link: string) =>
	ALL_DOMAINS.find(({ domain }) => link.includes(domain));

/**
 * This method simply parses the values stored within localStorage to a JSON object.
 * If somehow there is an issue with parsing then returning a blank array
 */
export const parsedOptedOutAppsFromLS = (
	localStorageName: Storage,
	optedOutAppName: string,
): Array<string> => {
	try {
		const optedOutApps = localStorageName.get(optedOutAppName);
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return JSON.parse(optedOutApps) as Array<string>;
	} catch (e) {
		return [];
	}
};

export const isOptedOut = (
	localStorageName: Storage,
	optedOutAppName: string,
	optedOutAppIdentifiers: Array<string>,
): boolean => {
	const optedOutApps = localStorageName.get(optedOutAppName);
	if (optedOutApps) {
		const parsedOptedOutApps = parsedOptedOutAppsFromLS(localStorageName, optedOutAppName);
		/**
		 * If `parsedOptedOutApps` is an empty array, then it indicates that there was an issue parsing the value from localStorage
		 * In such case, we are simply not gonna show the recommendation panel, so returning true here
		 */
		return parsedOptedOutApps.length > 0
			? Boolean(
					parsedOptedOutApps.find((appId: string) =>
						optedOutAppIdentifiers.includes(appId.toLowerCase()),
					),
				)
			: true;
	}
	return false;
};

export const optOut = (localStorageName: Storage, optedOutAppName: string, appId: string) => {
	const optedOutApps = localStorageName.get(optedOutAppName);

	let updatedApps: Array<string> = [];

	if (optedOutApps) {
		const parsedOptedOutApps = parsedOptedOutAppsFromLS(localStorageName, optedOutAppName);
		updatedApps = [...parsedOptedOutApps, appId.toLowerCase()];
	} else {
		updatedApps = [appId.toLowerCase()];
	}
	localStorageName.set(optedOutAppName, JSON.stringify(updatedApps));
};

export const optOutFromAll = (localStorageName: Storage, optedOutAppName: string) =>
	localStorageName.set(optedOutAppName, JSON.stringify([OPTED_OUT_FROM_ALL_IDENTIFIER]));
