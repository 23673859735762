import React from 'react';
import { styled } from '@compiled/react';
import EmptyValue from '@atlassian/jira-issue-empty-value';
import { Link } from '@atlassian/react-resource-router';
import type { Props } from './types';

const SprintContent = (props: Props) => {
	const { value, onClick, placeholder, shouldHideSprintLink = false } = props;

	const renderSprintContent = () => {
		if (shouldHideSprintLink) {
			return value && value.label;
		}

		return (
			<Link
				data-testid="issue-field-sprint-readview-full.ui.sprint.sprint-content.view-sprint-content-link"
				href={value?.href}
				onClick={onClick}
			>
				{value && value.label}
			</Link>
		);
	};

	return (
		<Ellipsis data-testid="issue-field-sprint-readview-full.ui.sprint.sprint-content.view-sprint-content">
			{value ? renderSprintContent() : <EmptyValue>{placeholder}</EmptyValue>}
		</Ellipsis>
	);
};

export default SprintContent;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Ellipsis = styled.div({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
