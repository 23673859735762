import { useState, useEffect } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { TTL, perProjectRequestCache } from './constants';
import type { BlockedAppContext, ConnectBlockedAppsResponse } from './types';

export const useHasBlockedConnectApps = () => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const [hasBlockedApps, setHasBlockedApps] = useState(false);

	useEffect(() => {
		if (!projectKey) return;

		fetchIsAnyConnectAppBlocked({ projectKey }).then((response) => {
			if (typeof response === 'boolean' && response) {
				setHasBlockedApps(true);
			}
		});
	}, [projectKey]);

	return hasBlockedApps;
};

export async function fetchIsAnyConnectAppBlocked(context: BlockedAppContext) {
	const headers = {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	};

	const uri = '/rest/atlassian-connect/latest/addon-access';
	const cacheKey = 'projectId' in context ? context.projectId.toString() : context.projectKey;

	try {
		const cachedResult = perProjectRequestCache.get(cacheKey);
		const now = Date.now();

		const isCacheResultTimePassed = Boolean(cachedResult && TTL < now - cachedResult.timestamp);

		if (isCacheResultTimePassed || !cachedResult) {
			const data = await performPostRequest<ConnectBlockedAppsResponse>(uri, {
				headers,
				body: JSON.stringify({
					context,
				}),
			});

			perProjectRequestCache.set(cacheKey, {
				value: data.isAnyAppBlocked,
				timestamp: now,
			});

			return data.isAnyAppBlocked;
		}

		return cachedResult.value;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			meta: {
				id: 'getConnectAccessNarrowingInfo',
				packageName: 'jiraBlockedAppsInfoBanner',
				teamName: 'smite',
			},
			error,
			sendToPrivacyUnsafeSplunk: true,
		});

		throw error;
	}
}
