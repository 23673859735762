/** @jsx jsx */
import React, { type Ref } from 'react';
import { css, jsx } from '@compiled/react';
import DropdownMenu, { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import { DragHandleButtonSmall } from '@atlaskit/pragmatic-drag-and-drop-react-accessibility/drag-handle-button-small';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

type ReorderMenuProps = {
	/**
	 * The index of the item this menu is associated with.
	 */
	itemIndex: number;
	onReorderItem: (args: { indexSource: number; indexDestination: number }) => void;
	numItems: number;
	fieldName: string;
};

export function ReorderMenu({ itemIndex, onReorderItem, numItems, fieldName }: ReorderMenuProps) {
	const intl = useIntl();

	return (
		<DropdownMenu
			testId="issue-view-layout-templates-item-list.ui.sortable-item-list.reorder-menu"
			trigger={({ triggerRef, ...triggerProps }) => (
				<div css={[triggerWrapperStyles, triggerProps.isSelected && isVisibleStyles]}>
					<DragHandleButtonSmall
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						ref={triggerRef as Ref<HTMLButtonElement>}
						label={intl.formatMessage(messages.triggerLabel, { fieldName })}
						appearance="subtle"
						{...triggerProps}
					/>
				</div>
			)}
		>
			<DropdownItemGroup>
				<DropdownItem
					testId="issue-view-layout-templates-item-list.ui.sortable-item-list.reorder-menu.move-up"
					onClick={() => {
						onReorderItem({ indexSource: itemIndex, indexDestination: itemIndex - 1 });
					}}
					isDisabled={itemIndex === 0}
				>
					{intl.formatMessage(messages.moveUp)}
				</DropdownItem>
				<DropdownItem
					testId="issue-view-layout-templates-item-list.ui.sortable-item-list.reorder-menu.move-down"
					onClick={() => {
						onReorderItem({ indexSource: itemIndex, indexDestination: itemIndex + 1 });
					}}
					isDisabled={itemIndex === numItems - 1}
				>
					{intl.formatMessage(messages.moveDown)}
				</DropdownItem>
			</DropdownItemGroup>
		</DropdownMenu>
	);
}

const triggerWrapperStyles = css({
	/**
	 * The --reorder-menu-opacity var is set by the SortableItemContainer
	 * when it is being hovered.
	 */
	opacity: 'var(--reorder-menu-opacity, 0)',
	'@supports not selector(:has(*))': {
		'&:focus-within': {
			opacity: 1,
		},
	},
	'@supports selector(:has(*))': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&:has(:focus-visible)': {
			opacity: 1,
		},
	},
});

const isVisibleStyles = css({
	opacity: 1,
});
