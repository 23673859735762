/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { type ComponentProps } from 'react';

import { jsx } from '@compiled/react';
import { useIntl } from 'react-intl-next';

import { IconTile } from '@atlaskit/icon';
// The linting is a false positive, this is a valid import
// eslint-disable-next-line @atlassian/tangerine/import/entry-points
import AkGoalIcon from '@atlaskit/icon/core/goal';

import {
	type GoalAppearance,
	type GoalStatus,
	type GoalStatusValue,
	isGoalAppearance,
} from '../types';

import { isMessageId, type MessageId, messages } from './messages';

export interface GoalIconProps {
	appearance: GoalAppearance;
	size?: ComponentProps<typeof IconTile>['size'];
	localizedLabel?: GoalStatus['localizedLabel'];
}

const appearanceToColorMap = {
	default: 'gray',
	off_track: 'red',
	at_risk: 'yellow',
	on_track: 'green',
} as const;

const appearanceToLabelMap = {
	default: 'GenericIconLabel',
	off_track: 'townsquare.api.goal-state.off-track',
	at_risk: 'townsquare.api.goal-state.at-risk',
	on_track: 'townsquare.api.goal-state.on-track',
} as const;

/**
 * The goal icon component is a wrapper which allows displaying a goal icon based on a semantic
 * appearance prop.
 */
export const GoalIcon = ({ appearance, size, localizedLabel }: GoalIconProps) => {
	const intl = useIntl();

	const labelMessageId: MessageId =
		localizedLabel && isMessageId(localizedLabel?.messageId)
			? localizedLabel.messageId
			: appearanceToLabelMap[appearance];

	return (
		<IconTile
			size={size}
			icon={AkGoalIcon}
			label={intl.formatMessage(messages[labelMessageId])}
			appearance={appearanceToColorMap[appearance]}
		/>
	);
};

/**
 * This is here to support backwards compatability with our old API. Where possible, source goal appearance
 * directly from the Atlassian GraphQL API: https://api.atlassian.com/graphql
 *
 * @param statusValue - The status of the goal. If you pass in an unsupported status, it will return the default appearance.
 */
export const normalizeGoalAppearance = (
	statusValue: GoalStatusValue | Lowercase<GoalStatusValue> | string,
): GoalAppearance => {
	const appearanceLowercase = statusValue.toLocaleLowerCase();

	return isGoalAppearance(appearanceLowercase) ? appearanceLowercase : 'default';
};
