import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	confirmationHeader: {
		id: 'issue-attachments-base.with-issue-attachments-delete.confirmation-header',
		defaultMessage: 'Delete this attachment?',
		description: 'Header for the delete single attachment confirmation modal',
	},
	confirmationContent: {
		id: 'issue-attachments-base.with-issue-attachments-delete.confirmation-content',
		defaultMessage: "Once you delete, it's gone for good.",
		description: 'Confirmation message for deleting single attachment',
	},
	confirmationCancel: {
		id: 'issue-attachments-base.with-issue-attachments-delete.confirmation-cancel',
		defaultMessage: 'Cancel',
		description: '',
	},
	confirmationSubmit: {
		id: 'issue-attachments-base.with-issue-attachments-delete.confirmation-submit',
		defaultMessage: 'OK',
		description: '',
	},
});
