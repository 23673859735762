import type { UserOption, AggUser } from '../../common/types';

export const transformAggToUserOption = (user: AggUser): UserOption => ({
	avatarUrl: user.picture,
	id: user.id,
	accountId: user.accountId,
	name: user.name,
	active: user.accountStatus === 'active',
	byline: user.email ?? undefined,
});
