// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'issue.views.common.child-issues-panel.heading.title.title',
		defaultMessage: 'Child issues',
		description: 'Heading for the child issues panel',
	},
});
