import React from 'react';
import { usePreloadedQuery, type PreloadedQuery, graphql } from 'react-relay';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { FieldsCompactModeProvider } from '@atlassian/jira-issue-view-layout-templates-compact-mode-provider';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import type { panelViewEntitlementQuery } from '@atlassian/jira-relay/src/__generated__/panelViewEntitlementQuery.graphql';
import { ExperienceSuccess } from '@atlassian/jira-servicedesk-customer-service-common/src/ui/experience-success/index.tsx';
import { ThrowOnQueryError } from '@atlassian/jira-servicedesk-customer-service-common/src/ui/throw-on-query-error/index.tsx';
import { isClientStatusCode } from '@atlassian/jira-servicedesk-customer-service-common/src/utils/is-client-status-code/index.tsx';
import { DetailsContainer } from '../../../common/ui/details-container';
import { DetailsView } from '../../../common/ui/details/details-view/index.tsx';
import { ErrorState } from '../../../common/ui/details/error-state/index.tsx';
import { getEditDetailsUrlByEntityType } from '../../../common/ui/details/utils.tsx';
import { customerServiceIssueViewPanelEntitlementExperience } from '../../../experiences';

type Props = {
	queryReference: PreloadedQuery<panelViewEntitlementQuery>;
};

const Inner = ({ queryReference }: Props) => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const {
		customerService: { entitlementById },
	} = usePreloadedQuery<panelViewEntitlementQuery>(
		graphql`
			query panelViewEntitlementQuery(
				$cloudId: ID!
				$entitlementId: ID!
				$filter: CustomerServiceFilterInput!
			) {
				customerService(cloudId: $cloudId)
					@required(action: THROW)
					@optIn(to: ["CustomerServiceProductsAndEntitlementsM1"]) {
					entitlementById(entitlementId: $entitlementId, filter: $filter) @required(action: THROW) {
						__typename
						... on CustomerServiceEntitlement {
							details {
								...detailsView_servicedeskCustomerServiceIssueViewPanel
							}
						}
						... on QueryError {
							extensions {
								statusCode
							}
							...throwOnQueryError_servicedeskCustomerServiceCommon
						}
					}
				}
			}
		`,
		queryReference,
	);

	const queryError = entitlementById.__typename === 'QueryError' ? entitlementById : undefined;
	const is4XX = queryError?.extensions?.find((e) => isClientStatusCode(e.statusCode));

	if (is4XX) {
		return (
			<>
				<ErrorState context="ENTITLEMENT" />
				<ExperienceSuccess experience={customerServiceIssueViewPanelEntitlementExperience} />
			</>
		);
	}

	if (entitlementById.__typename !== 'CustomerServiceEntitlement') {
		return <ThrowOnQueryError operationName="panelViewEntitlementQuery" errorRef={queryError} />;
	}

	return (
		<DetailsView
			entityType="ENTITLEMENT"
			editDetailsUrl={getEditDetailsUrlByEntityType(projectKey, 'ENTITLEMENT')}
			details={entitlementById.details}
			entityExperience={customerServiceIssueViewPanelEntitlementExperience}
		/>
	);
};

export const PanelView = (props: Props) => (
	<DetailsContainer
		context="ENTITLEMENT"
		entityExperience={customerServiceIssueViewPanelEntitlementExperience}
	>
		<FieldsCompactModeProvider>
			<Inner {...props} />
		</FieldsCompactModeProvider>
	</DetailsContainer>
);
