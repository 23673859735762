import React, { useCallback, useMemo } from 'react';
import noop from 'lodash/noop';
import Lozenge from '@atlaskit/lozenge';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler';
import messages from '@atlassian/jira-common-components-inline-edit/src/messages';
import type { ActionMeta } from '@atlassian/jira-common-components-picker/src/model';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { FieldDescription } from '@atlassian/jira-issue-field-description/src/ui/index.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading';
import {
	FieldHeadingTitle,
	FieldWrapper,
	SideBySideField,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin';
import { messages as selectInlineEditMessages } from './messages';
import SelectFieldView from './select-field';
import type { SelectValueShape, Option } from './select-field/types';
import {
	InlineEditContainer,
	LozengeWrapper,
	ReadViewContainer,
	SelectFieldViewContainer,
	readViewContainerSelectorName,
} from './styled';
import type { ViewProps } from './types';

export const SelectInlineEditView = ({
	isEditable = false,
	isEditing = false,
	isMobile = false,
	isMulti = false,
	isInvalid = false,
	hasAutocomplete = false,
	label = '',
	noValueText = null,
	classNamePrefix,
	allowEmptyValue = false,
	debounceFetchSuggestionsTime = 0,
	onChange = noop,
	onConfirm,
	onInputChange,
	fieldId,
	showPinButton,
	titleView = label,
	value,
	optionValuesSafeForAnalytics,
	fieldContentWrapper: FieldContentWrapper,
	renderReadView: renderReadViewInProps,
	renderEditView: renderEditViewInProps,
	fetchSuggestions,
	additionalAnalyticsProps,
	placeholder,
	invalidMessage,
	portalElement,
	canCreateNewItem,
	formatCreateLabel,
	styles,
	onCancel,
	onChangeMulti,
	footer,
	onEditRequest,
	onFooterSelect,
	shouldShowFooter,
	filterOption,
	formatOptionLabel,
	onDataRequest,
	onDataLoaded,
	components,
	componentsProps,
	issueKey,
	initialData,
	noOptionsMessage,
	showNewLozenge,
	renderAppEditView,
	onInlineEditContainerPointerEnter,
	onInlineEditContainerPointerLeave,
}: ViewProps) => {
	const testId = `issue.views.field.select.common.select-inline-edit.${fieldId}`;

	const { formatMessage } = useIntl();

	const handleOnChange = useCallback(
		(newValue: SelectValueShape | null, actionMeta: ActionMeta<Option>) => {
			onChange?.(newValue, actionMeta);
			onConfirm();
		},
		[onChange, onConfirm],
	);

	const hasValue = useMemo(
		// @ts-expect-error - TS2339 - Property 'length' does not exist on type 'SelectValueShape | SelectValueShape[]'.
		() => Boolean((isMulti === true && value?.length) || (isMulti === false && value)),
		[isMulti, value],
	);

	const editButtonLabel = useMemo(() => {
		if (!hasValue) {
			return formatMessage(selectInlineEditMessages.extendedEditButtonLabel, {
				inputFieldValue: noValueText,
				fieldName: label,
			});
		}
		// @ts-expect-error - TS2339 - Property 'content' does not exist on type 'SelectValueShape | SelectValueShape[]'.
		if (isMulti === false && value?.content !== undefined) {
			return formatMessage(selectInlineEditMessages.extendedEditButtonLabel, {
				// @ts-expect-error - TS2339 - Property 'content' does not exist on type 'SelectValueShape | SelectValueShape[]'.
				inputFieldValue: value.content,
				fieldName: label,
			});
		}

		return formatMessage(messages.editButtonLabel, {
			fieldName: label,
		});
	}, [formatMessage, hasValue, isMulti, label, noValueText, value]);

	const renderDefaultEditView = useCallback(
		() => (
			<SelectFieldViewContainer>
				<SelectFieldView
					onInputChange={onInputChange}
					classNamePrefix={classNamePrefix}
					initialData={initialData}
					componentsProps={componentsProps}
					components={components}
					fieldId={fieldId}
					value={value}
					fetchSuggestions={fetchSuggestions}
					additionalAnalyticsProps={additionalAnalyticsProps}
					placeholder={placeholder}
					isOpen={isEditing}
					isInvalid={isInvalid}
					invalidMessage={invalidMessage}
					hasAutocomplete={hasAutocomplete}
					debounceFetchSuggestionsTime={debounceFetchSuggestionsTime}
					isClearable={allowEmptyValue}
					portalElement={portalElement}
					canCreateNewItem={canCreateNewItem}
					formatCreateLabel={formatCreateLabel}
					styles={styles}
					autoFocus
					onChange={handleOnChange}
					footer={footer}
					onFooterSelect={onFooterSelect}
					shouldShowFooter={shouldShowFooter}
					onChangeMulti={onChangeMulti}
					isMulti={isMulti}
					filterOption={filterOption}
					formatOptionLabel={formatOptionLabel}
					onDataRequest={onDataRequest}
					onDataLoaded={onDataLoaded}
					optionValuesSafeForAnalytics={optionValuesSafeForAnalytics}
					ariaLabel={label}
					noOptionsMessage={noOptionsMessage}
				/>
			</SelectFieldViewContainer>
		),
		[
			additionalAnalyticsProps,
			allowEmptyValue,
			canCreateNewItem,
			classNamePrefix,
			components,
			componentsProps,
			debounceFetchSuggestionsTime,
			fetchSuggestions,
			onInputChange,
			fieldId,
			filterOption,
			footer,
			formatCreateLabel,
			formatOptionLabel,
			handleOnChange,
			hasAutocomplete,
			initialData,
			invalidMessage,
			isEditing,
			isMulti,
			isInvalid,
			label,
			onChangeMulti,
			onDataLoaded,
			onDataRequest,
			onFooterSelect,
			optionValuesSafeForAnalytics,
			placeholder,
			portalElement,
			shouldShowFooter,
			styles,
			value,
			noOptionsMessage,
		],
	);

	const renderEditView = useMemo(() => {
		if (renderAppEditView && fg('forge_cf_inline_edit_on_issue_view')) {
			return renderAppEditView;
		}
		if (renderEditViewInProps != null) {
			return renderEditViewInProps;
		}

		return renderDefaultEditView;
	}, [renderAppEditView, renderEditViewInProps, renderDefaultEditView]);

	const renderReadViewOld = useCallback(() => {
		if (FieldContentWrapper !== undefined && FieldContentWrapper !== null) {
			return <FieldContentWrapper>{renderReadViewInProps()}</FieldContentWrapper>;
		}
		return renderReadViewInProps();
	}, [FieldContentWrapper, renderReadViewInProps]);

	const renderReadView = useCallback(
		() => (
			<ReadViewContainer data-component-selector={readViewContainerSelectorName}>
				{renderReadViewOld()}
			</ReadViewContainer>
		),
		[renderReadViewOld],
	);

	return (
		<FieldWrapper data-testid={testId}>
			<FieldHeading fieldId={fieldId}>
				<FieldHeadingTitle>{titleView}</FieldHeadingTitle>
				{issueKey !== undefined && fieldId !== undefined && (
					<FieldDescription issueKey={issueKey} fieldKey={fieldId} label={label} />
				)}
				{ff('compass-components-in-jira-components_xw42u', false) && showNewLozenge && (
					<LozengeWrapper>
						<Lozenge appearance="new">{formatMessage(selectInlineEditMessages.newLozenge)}</Lozenge>
					</LozengeWrapper>
				)}
				{showPinButton === true && <FieldPin fieldId={fieldId} label={label} />}
			</FieldHeading>
			<SideBySideField isEditing={isEditing}>
				<EnterEscapeHandler onEscape={onCancel}>
					<InlineEditContainer
						onPointerEnter={onInlineEditContainerPointerEnter}
						onPointerLeave={onInlineEditContainerPointerLeave}
						isEditable={isEditable}
					>
						<FieldInlineEditStateLess
							testId={`${testId}.field-inline-edit-state-less`}
							isLabelHidden
							label={label}
							readView={renderReadView}
							editView={renderEditView}
							isEditing={isEditing}
							isEditable={isEditable}
							hideActionButtons={
								renderAppEditView && fg('forge_cf_inline_edit_on_issue_view') ? true : !isMulti
							}
							readViewFitContainerWidth={!isMobile}
							onConfirm={onConfirm}
							onCancel={onCancel}
							onEdit={onEditRequest}
							editButtonLabel={editButtonLabel}
							confirmButtonLabel={formatMessage(messages.confirmButtonLabel, {
								fieldName: label,
							})}
							cancelButtonLabel={formatMessage(messages.cancelButtonLabel, {
								fieldName: label,
							})}
						/>
					</InlineEditContainer>
				</EnterEscapeHandler>
			</SideBySideField>
		</FieldWrapper>
	);
};

export default SelectInlineEditView;
