import React, { useEffect, useMemo } from 'react';

import { FormattedMessage, useIntl } from 'react-intl-next';

import { ErrorScreen } from '@atlassian/generative-ai-modal/screens/Error';

import {
	useAnalyticsFlow,
	useAnalyticsFlowEventsQueue,
} from '../../analytics/analytics-flow/analyticsFlowUtils';
import messages from '../../messages';
import { useAIExperienceCommonDataContext } from '../useAIExperienceCommonData';

export function ApiFailedScreenWithLogic({
	errorInfo,
}: {
	errorInfo: {
		errorKey?: string;
		statusCode?: number;
		errorContent?: string;
		apiName?: string;
	};
}) {
	const aiExperienceCommonData = useAIExperienceCommonDataContext();
	const { endExperience, sendToAIExperienceMachine } = aiExperienceCommonData;

	const analyticsFlow = useAnalyticsFlow();
	const attributes = useMemo(() => {
		return {
			errorType: messages.apiError.defaultMessage,
			...errorInfo,
		};
	}, [errorInfo]);
	// Ensure the error attributes are available when the error event is fired
	useEffect(() => {
		analyticsFlow.addAttributes(attributes);
	}, [analyticsFlow, attributes]);
	useAnalyticsFlowEventsQueue({
		stepName: 'apiError',
		attributes,
	});

	const isConvoAIServerError = [
		'FEATURE_DISABLED',
		'FEATURE_DISABLED_ON_SITE',
		'NO_AGENT',
	].includes(errorInfo?.errorKey || '');

	const is5xxErrorRange =
		typeof errorInfo?.statusCode === 'number' &&
		errorInfo.statusCode >= 500 &&
		errorInfo.statusCode < 600;

	const isRetryEnabled =
		// 500 status codes should not be retryable
		!is5xxErrorRange &&
		// 9001 is a special case for elevate where we disable generate until
		// Q3
		errorInfo?.statusCode !== 9001;

	const { formatMessage } = useIntl();

	const documentationUrl = 'https://status.atlassian.com/';

	const errorMsg =
		is5xxErrorRange || isConvoAIServerError ? (
			<FormattedMessage
				{...messages.internalServerError}
				values={{
					link: (message: React.ReactNode[]) => (
						<a href={documentationUrl} target="_blank" rel="noopener noreferrer">
							{message}
						</a>
					),
				}}
			/>
		) : errorInfo?.statusCode === 9001 ? (
			formatMessage(messages.elevateDisabledGenetateError)
		) : (
			formatMessage(messages.apiError)
		);

	return (
		<ErrorScreen
			text={errorMsg}
			showRetryButton={isRetryEnabled}
			onRetry={function () {
				sendToAIExperienceMachine({ type: 'api retry' });
			}}
			onCancel={function () {
				endExperience();
			}}
		/>
	);
}
