import React, { createElement, type ReactElement } from 'react';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useReadOnlyField } from '../services';
import { ReadOnlyFieldView, type Props as ViewProps } from './view';

type Props = {
	// eslint-disable-next-line jira/react/handler-naming
	readView?: ((viewProps: ViewProps) => ReactElement) | null;
	fieldKey: string;
};

const ReadOnlyField = ({ fieldKey, readView }: Props) => {
	const issueKey = useIssueKey();
	const [{ value }] = useReadOnlyField({ issueKey, fieldKey });
	return readView != null && typeof readView === 'function' ? (
		createElement(readView, {
			value,
		})
	) : (
		<ReadOnlyFieldView value={value} />
	);
};

export default ReadOnlyField;
