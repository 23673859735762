import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import {
	ACCESS_ROLE_ERRORS,
	PRINCIPAL_TYPES,
} from '@atlassian/jira-polaris-domain-view/src/view-access/constants.tsx';
import type {
	AccessRole,
	PrincipalType,
} from '@atlassian/jira-polaris-domain-view/src/view-access/types';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import {
	isAccessForbiddenError,
	isNotFoundError,
} from '@atlassian/jira-polaris-lib-errors/src/controllers/utils';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Action } from '@atlassian/react-sweet-state';
import { applyAddViewAccessPrincipals } from '../../../services/jpd-views-service';
import { isSetViewAccessPrincipalRolesErrorResponse } from '../../../services/jpd-views-service/utils';
import type { State } from '../../types';
import { transformRoleToRoleAri } from '../../utils';
import { addViewAccessPrincipals } from '../add-view-access-principals';
import { loadViewPermissionsConfig } from '../load-view-permissions-config';

export const changeViewAccessPrincipalRole =
	({
		viewUUID,
		viewId,
		name,
		id,
		role,
		type,
		avatarUrl,
		createAnalyticsEvent,
		onActionFailed,
	}: {
		viewUUID: string;
		viewId?: string;
		name: string;
		id: string;
		role: AccessRole;
		type: PrincipalType;
		avatarUrl?: string;
		createAnalyticsEvent: CreateUIAnalyticsEvent;
		onActionFailed?: (err: Error) => void;
	}): Action<State> =>
	async ({ setState, getState, dispatch }) => {
		const { additionalAccess, config, accessLevel } = getState();

		const roleAri = transformRoleToRoleAri(role);
		const oldRoleAri =
			type === PRINCIPAL_TYPES.PROFILE
				? additionalAccess.profiles.find((item) => item.accountId === id)?.role
				: additionalAccess.groups.find((item) => item.groupId === id)?.role;

		if (!oldRoleAri) {
			await dispatch(
				addViewAccessPrincipals({
					viewUUID,
					principals: [{ id, name, type, avatarUrl }],
					role,
					viewId,
					createAnalyticsEvent,
					onActionFailed,
				}),
			);
			return;
		}

		try {
			experience.viewPermissions.changePrincipalRole.start();

			setState({
				additionalAccess: {
					profiles:
						type === PRINCIPAL_TYPES.PROFILE
							? additionalAccess.profiles.map((item) =>
									item.accountId === id ? { ...item, role: roleAri } : item,
								)
							: additionalAccess.profiles,
					groups:
						type === PRINCIPAL_TYPES.GROUP
							? additionalAccess.groups.map((item) =>
									item.groupId === id ? { ...item, role: roleAri } : item,
								)
							: additionalAccess.groups,
				},
				config: {
					...config,
					failures: undefined,
					skippedAccountFailures: config.skippedAccountFailures.filter(
						(skippedAccount) => skippedAccount.accountId !== id,
					),
				},
			});

			await applyAddViewAccessPrincipals(
				viewUUID,
				type === PRINCIPAL_TYPES.PROFILE ? [{ id, role: transformRoleToRoleAri(role) }] : [],
				type === PRINCIPAL_TYPES.GROUP ? [{ id, role: transformRoleToRoleAri(role) }] : [],
			);
			await dispatch(loadViewPermissionsConfig({ viewUUID, viewId, createAnalyticsEvent }));

			// https://data-portal.internal.atlassian.com/analytics/registry/69640
			fireTrackAnalytics(createAnalyticsEvent({}), 'viewAccessData roleChanged', {
				viewUuid: viewUUID,
				viewId,
				accessLevel,
				principalType: type,
				role,
			});

			sendPendoTrackEvent({
				actionSubjectAndAction: 'viewAccessData roleChanged',
				actionSubjectId: 'viewAccessData',
				attributes: {
					viewUuid: viewUUID,
					viewId: viewId || '',
					accessLevel,
					principalType: type,
					role,
				},
			});

			experience.viewPermissions.changePrincipalRole.success();

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			let errorResponse: unknown = error.originalResponse;

			try {
				if (typeof error.originalResponse?.json === 'function') {
					errorResponse = await error.originalResponse.json();
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (subError: any) {
				fireErrorAnalytics(
					createErrorAnalytics(
						'polaris.view-access.change-view-access-principals-role.parse-error',
						subError,
					),
				);
			}

			const currentState = getState();

			if (isSetViewAccessPrincipalRolesErrorResponse(errorResponse)) {
				let hasSkippedAccounts = false;
				let hasFailures = false;

				if (errorResponse.details.skippedAccounts?.[0]) {
					hasSkippedAccounts = true;

					// https://data-portal.internal.atlassian.com/analytics/registry/69691
					fireTrackAnalytics(
						createAnalyticsEvent({}),
						'viewAccessData setRoleFailureSkippedAccounts',
						{
							viewUuid: viewUUID,
							viewId,
							missingManageViewsPermissionCount: errorResponse.details.skippedAccounts.filter(
								(item) =>
									item.errorCode === ACCESS_ROLE_ERRORS.MISSING_MANAGE_DISCOVERY_VIEWS_PERMISSION,
							).length,
							missingProjectAccessCount: errorResponse.details.skippedAccounts.filter(
								(item) => item.errorCode === ACCESS_ROLE_ERRORS.MISSING_BROWSE_PROJECTS_PERMISSION,
							).length,
						},
					);

					sendPendoTrackEvent({
						actionSubjectAndAction: 'viewAccessData setRoleFailureSkippedAccounts',
						actionSubjectId: 'viewAccessData',
						attributes: {
							viewUuid: viewUUID,
							viewId: viewId || '',
							missingManageViewsPermissionCount: errorResponse.details.skippedAccounts.filter(
								(item) =>
									item.errorCode === ACCESS_ROLE_ERRORS.MISSING_MANAGE_DISCOVERY_VIEWS_PERMISSION,
							).length,
							missingProjectAccessCount: errorResponse.details.skippedAccounts.filter(
								(item) => item.errorCode === ACCESS_ROLE_ERRORS.MISSING_BROWSE_PROJECTS_PERMISSION,
							).length,
						},
					});

					setState({
						config: {
							...currentState.config,
							skippedAccountFailures: [
								...currentState.config.skippedAccountFailures.filter(
									(item) =>
										item.accountId !== errorResponse.details.skippedAccounts?.[0]?.accountId,
								),
								{
									accountId: errorResponse.details.skippedAccounts[0].accountId,
									errorCode: errorResponse.details.skippedAccounts[0].errorCode,
									name,
									requestedRole: role,
									avatarUrl,
								},
							],
						},
						additionalAccess: {
							profiles:
								type === PRINCIPAL_TYPES.PROFILE
									? currentState.additionalAccess.profiles.map((item) =>
											item.accountId === id ? { ...item, role: oldRoleAri } : item,
										)
									: currentState.additionalAccess.profiles,
							groups: currentState.additionalAccess.groups,
						},
					});
				}

				if (
					errorResponse.details.failures &&
					(errorResponse.details.failures.accountIds.length ||
						errorResponse.details.failures.groupIds.length)
				) {
					hasFailures = true;

					// https://data-portal.internal.atlassian.com/analytics/registry/69692
					fireTrackAnalytics(createAnalyticsEvent({}), 'viewAccessData setRoleFailure', {
						viewUuid: viewUUID,
						viewId,
						failuresCount: 1,
					});

					sendPendoTrackEvent({
						actionSubjectAndAction: 'viewAccessData setRoleFailure',
						actionSubjectId: 'viewAccessData',
						attributes: {
							viewUuid: viewUUID,
							viewId: viewId || '',
							failuresCount: 1,
						},
					});

					setState({
						config: {
							...currentState.config,
							failures: {
								role,
								principals: [
									{
										id,
										name,
										type,
									},
								],
							},
						},
						additionalAccess: {
							profiles:
								type === PRINCIPAL_TYPES.PROFILE
									? currentState.additionalAccess.profiles.map((item) =>
											item.accountId === id ? { ...item, role: oldRoleAri } : item,
										)
									: currentState.additionalAccess.profiles,
							groups:
								type === PRINCIPAL_TYPES.GROUP
									? currentState.additionalAccess.groups.map((item) =>
											item.groupId === id ? { ...item, role: oldRoleAri } : item,
										)
									: currentState.additionalAccess.groups,
						},
					});
				}

				if (hasSkippedAccounts || hasFailures) {
					experience.viewPermissions.changePrincipalRole.successWithReason(error);
				} else {
					experience.viewPermissions.changePrincipalRole.failure(error);
				}
			} else {
				if (isClientFetchError(error) || isAccessForbiddenError(error) || isNotFoundError(error)) {
					experience.viewPermissions.changePrincipalRole.abort(error);
				} else {
					experience.viewPermissions.changePrincipalRole.failure(error);
				}

				fireErrorAnalytics(
					createErrorAnalytics('polaris.view-access.change-view-access-principals-role', error),
				);

				onActionFailed?.(error);
			}
		}
	};
