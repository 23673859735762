import React, { useEffect, useCallback, useRef, type ReactNode, useMemo, useState } from 'react';
import noop from 'lodash/noop';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { SpotlightTarget } from '@atlaskit/onboarding';
import AKTooltip from '@atlaskit/tooltip';
import { AsyncSuggestResourcesQuickAction } from '@atlassian/jira-aiops-suggested-resources/src/ui/quick-action/async.tsx';
import { useTenantHasConfluence } from '@atlassian/jira-business-confluence-pages/src/utils/use-tenant-has-confluence/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	useIsIssueOfIncidentsPractice,
	usePracticesFieldValue,
} from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import { useShouldBeCompact } from '@atlassian/jira-issue-hooks/src/services/use-should-be-compact';
import { QUICK_ADDS } from '@atlassian/jira-issue-view-common-constants/src/onboarding-constants';
import {
	ADD_ISSUE_LINKS_ID,
	ADD_CONTENT_PANEL,
	SELECT_CONTENT_PANEL,
	SCHEDULE_CHANGE_ID,
	CREATE_SUBTASK_ID,
	CREATE_ISSUE_IN_EPIC_ID,
	CREATE_CHILD_ID,
} from '@atlassian/jira-issue-view-common-constants/src/quick-add-constants';
import { ISSUE_FIELD_SPOTLIGHT_PREFIX } from '@atlassian/jira-issue-view-common-constants/src/view-constants';
import { useLoomTouchpointVariantWithAdControls } from '@atlassian/jira-issue-view-common/src/component/loom-videos/ad-controls/use-loom-touchpoint-variant-with-ad-controls';
import { LOOM_QUICK_ADD_AD_CONTROL_DISMISS_KEY } from '@atlassian/jira-issue-view-common/src/component/loom-videos/constants';
import ContentPanelQuickAdd from '@atlassian/jira-issue-view-ecosystem/src/ecosystem-content-panel-quickadd-view';
import { useLinkAlertsModalActions } from '@atlassian/jira-linked-alerts/src/services/main.tsx';
import {
	LoomTouchpointVariant,
	type LoomTouchpointVariantType,
} from '@atlassian/jira-loom-utils/src/controllers/use-loom-touchpoint-variant/types.tsx';
import { useForms } from '@atlassian/jira-proforma-panel-in-issue-view-services/src/services/forms-status-service/index.tsx';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { useGlobalRefStoreActions } from '@atlassian/jira-refs-store';
import { AsyncDeveloperEscalationsButton } from '@atlassian/jira-servicedesk-customer-service-escalations-issue-view-button/src/async.tsx';
import { useIsDeveloperEscalationsEnabled } from '@atlassian/jira-servicedesk-customer-service-escalations-issue-view-button/src/controllers/use-is-developer-escalations-enabled/index.tsx';
import { AsyncReviewButton } from '@atlassian/jira-servicedesk-post-incident-review/src/ui/review-button/async.tsx';
import { CHANGES } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { PREMIUM_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { AddDesignItem } from '../add-design-item';
import { AddFormButton } from '../add-form-button/add-form-button-view/index.tsx';
import { useRegisterQuickAddInCommandPalette } from '../command-palette';
import { CreateConfluenceContentMenu } from '../create-confluence-content-menu';
import LinkButton from '../link-button/view.tsx';
import { LoomInsertLinkButton } from '../loom-insert-link-item-button/view.tsx';
import { LoomQuickAddItemView } from '../loom-record-item-button';
import QuickAddItemView from '../quick-add-item/view.tsx';
import QuickAddItemsCompact from '../quick-add-items-compact';
import { ScheduleChangeButton } from '../schedule-change-button/async.tsx';
import type { QuickAddItemToRender, QuickAddItemAllProps, CreateItemViewType } from '../types';
import { useDynamicDropdownItemsRenderer } from './hooks';
import getItemList from './item-list';
import messages from './messages';
import { QuickAddWrapper } from './styled';

interface QuickAddActionData {
	actions?: ReactNode[];
	apps?: ReactNode;
}

export const QuickAddItems = (props: QuickAddItemAllProps) => {
	const { formatMessage } = useIntl();
	const [, { register }] = useGlobalRefStoreActions();
	const {
		onAddAttachmentClick,
		onAddWebLinkClick,
		onLinkConfluencePageClick,
		fetchConfluenceAppLinks,
		triggerButtonClickAnalytics,
		onSelectContentPanel,
		isServiceDesk,
		isAnonymousUser,
		canCreateAttachment,
		canAddWebLinks,
		canLinkConfluencePage,
		onAddExtension,
		issueKey,
		containerWidth,
		onInsertLoomLinkClick,
	} = props;

	const { shouldBeCompact, setMeasuredRef, forceUpdate } = useShouldBeCompact(containerWidth);

	const [, { openLinkAlertsModal: onLinkAlertsClick }] = useLinkAlertsModalActions();

	const createSubtaskRef = useRef<HTMLElement>(null);
	const linkIssueRef = useRef<HTMLButtonElement>(null);

	const projectKey = useProjectKey(issueKey);
	const tenantContext = useTenantContext();
	const hasPremiumServiceDeskAccess = tenantContext.appEditions.serviceDesk === PREMIUM_EDITION;

	let isQuickActionsListView = false;
	/* eslint-disable react-hooks/rules-of-hooks */
	if (ff('issue_view_quick_actions_revamp_l8mbx') || fg('quick_actions_menu_ga')) {
		const projectType = useProjectType(projectKey);
		const isJSMProject = projectType === 'service_desk';
		const tenantHasConfluence = useTenantHasConfluence();
		isQuickActionsListView =
			!isJSMProject &&
			(fg('quick_actions_menu_ga') ||
				(tenantHasConfluence &&
					expVal('issue_view_quick_actions_revamp', 'isCompactQuickActions', false)));
	}
	const quickActionListViewContainerRef = useRef<HTMLDivElement>(null);
	const [openMenu, setOpenMenu] = useState<string | null>(null);

	const { dynamicDropdownItems, renderQuickActionItem } = useDynamicDropdownItemsRenderer(
		fg('quick_actions_menu_ga')
			? [
					{
						category: 'confluence',
						name: 'new-page',
						isDisabled: true,
						itemKey: 'confluence-quick-actions-new-page',
					},
					{
						category: 'confluence',
						name: 'new-whiteboard',
						isDisabled: true,
						itemKey: 'confluence-quick-actions-new-whiteboard',
					},
				]
			: [],
	);

	const [
		{
			value: { hasIssueForms, hasProjectForms },
		},
		{ onClickAddFormButton },
	] = useForms({
		issueKey: toIssueKey(issueKey),
	});

	useEffect(() => {
		fetchConfluenceAppLinks();
	}, [fetchConfluenceAppLinks, issueKey]);

	useEffect(() => {
		register('quick-add-items.create-subtask', createSubtaskRef.current);
		register('quick-add-items.link-issue', linkIssueRef.current);
	}, [register, createSubtaskRef, linkIssueRef]);

	const sendAnalyticsEvent = useCallback(
		(id: string, itemIndex: number, handleFunc: () => void) => () => {
			handleFunc();
			if (typeof triggerButtonClickAnalytics === 'function') {
				triggerButtonClickAnalytics(id, itemIndex, 4);
			}
		},
		[triggerButtonClickAnalytics],
	);

	const onAddChildCommand = useMemo(
		() =>
			getItemList({
				...props,
				formatMessage,
				onLinkAlertsClick,
				...(ff('enable_jsm_repackaging_changes_vtwk9') && { hasPremiumServiceDeskAccess }),
			}).issueActions.find(({ id }) =>
				[CREATE_SUBTASK_ID, CREATE_ISSUE_IN_EPIC_ID, CREATE_CHILD_ID].includes(id),
			)?.onClick,
		[formatMessage, onLinkAlertsClick, props, hasPremiumServiceDeskAccess],
	);

	const shouldRenderAttachCommand = !isServiceDesk && canCreateAttachment;
	useRegisterQuickAddInCommandPalette({
		onAddAttachmentClick,
		onAddAUrlClick: onAddWebLinkClick,
		onLinkConfluencePageClick,
		onAddChildCommand: onAddChildCommand ?? noop,
		isAttachCommandEnabled: shouldRenderAttachCommand,
		isAddAUrlEnabled: canAddWebLinks,
		isLinkConfluencePageEnabled: canLinkConfluencePage,
		isAddChildEnabled: onAddChildCommand !== undefined,
	});

	const createItemView: CreateItemViewType = useCallback(
		(itemToRender: QuickAddItemToRender) => {
			const {
				id,
				tooltip,
				label,
				icon,
				onClick,
				itemIndex,
				testId,
				hasGlobalSpotlightTarget,
				hasSpotlightPulse,
				noWrapper,
			} = itemToRender;

			const quickAddItemViewComponent = (
				<QuickAddItemView
					key={id}
					id={id}
					icon={icon}
					onClick={sendAnalyticsEvent(id, itemIndex, onClick)}
					label={label}
					compactMode={shouldBeCompact}
					isQuickActionsListView={isQuickActionsListView}
					testId={testId}
					hasGlobalSpotlightTarget={hasGlobalSpotlightTarget}
					hasSpotlightPulse={hasSpotlightPulse}
					noWrapper={noWrapper}
					{...(id === CREATE_SUBTASK_ID && { ref: createSubtaskRef })}
				/>
			);
			if (isQuickActionsListView) {
				return quickAddItemViewComponent;
			}
			return (
				<AKTooltip content={tooltip} position="bottom" key={id}>
					{quickAddItemViewComponent}
				</AKTooltip>
			);
		},
		[sendAnalyticsEvent, shouldBeCompact, createSubtaskRef, isQuickActionsListView],
	);

	const [{ canEditIssues }] = useProjectPermissions(projectKey);
	const [practices] = usePracticesFieldValue({ issueKey });
	const shouldShowScheduleChangeButton: boolean =
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		canEditIssues && (practices?.some((practice: any) => practice === CHANGES) ?? false);
	const isIssueOfIncidentsPractice = useIsIssueOfIncidentsPractice();
	const isDeveloperEscalationsEnabled = useIsDeveloperEscalationsEnabled();

	const { issueActions, linkingActions } = useMemo(
		() =>
			getItemList({
				...props,
				formatMessage,
				onLinkAlertsClick,
				isQuickActionsListView,
				...(ff('enable_jsm_repackaging_changes_vtwk9') && { hasPremiumServiceDeskAccess }),
			}),
		[formatMessage, hasPremiumServiceDeskAccess, isQuickActionsListView, onLinkAlertsClick, props],
	);

	const mappedIssueActions = useMemo(
		() => issueActions.map(createItemView),
		[createItemView, issueActions],
	);

	const dropdownMessage = shouldBeCompact
		? messages.linkDropdownButtonCompactLabel
		: messages.linkDropdownButtonLabel;
	const dropdownTooltipLabel = formatMessage(dropdownMessage);

	const linkButton = useMemo(
		() => (
			<LinkButton
				compactMode={shouldBeCompact}
				actions={linkingActions}
				tooltipLabel={dropdownTooltipLabel}
				ref={linkIssueRef}
				isQuickActionsListView={isQuickActionsListView}
			/>
		),
		[dropdownTooltipLabel, isQuickActionsListView, linkingActions, shouldBeCompact],
	);

	const linkButtonActions = useMemo(
		() =>
			isQuickActionsListView
				? [linkButton]
				: [
						<SpotlightTarget
							key="quick-add-web-link-button"
							name={`${ISSUE_FIELD_SPOTLIGHT_PREFIX}${ADD_ISSUE_LINKS_ID}`}
						>
							{linkButton}
						</SpotlightTarget>,
					],
		[isQuickActionsListView, linkButton],
	);

	const suggestResourcesAction: ReactNode[] = useMemo(
		() =>
			isIssueOfIncidentsPractice && fg('incident_suggested_resources')
				? [
						<AsyncSuggestResourcesQuickAction
							isCompactMode={shouldBeCompact}
							isQuickActionsListView={isQuickActionsListView}
							key="suggest-resources-action"
						/>,
					]
				: [],
		[isIssueOfIncidentsPractice, isQuickActionsListView, shouldBeCompact],
	);

	const reviewButton: ReactNode[] = useMemo(
		() =>
			isIssueOfIncidentsPractice && props.canCreateIssueLink
				? [
						<AsyncReviewButton
							onAddIssueLinkClick={props.onAddIssueLinkClick}
							compactMode={shouldBeCompact}
							isQuickActionsListView={isQuickActionsListView}
							key="async-review-button"
						/>,
					]
				: [],
		[
			isIssueOfIncidentsPractice,
			isQuickActionsListView,
			props.canCreateIssueLink,
			props.onAddIssueLinkClick,
			shouldBeCompact,
		],
	);

	const changeManagementActions: ReactNode[] = useMemo(
		() =>
			[
				shouldShowScheduleChangeButton && (
					<ScheduleChangeButton key={SCHEDULE_CHANGE_ID} createItemView={createItemView} />
				),
			].filter(Boolean),
		[createItemView, shouldShowScheduleChangeButton],
	);

	const developerEscalationsAction = useMemo(
		() =>
			isDeveloperEscalationsEnabled
				? [
						<AsyncDeveloperEscalationsButton
							key="async-developer-escalations-button"
							createItemView={createItemView}
						/>,
					]
				: [],
		[createItemView, isDeveloperEscalationsEnabled],
	);

	const ecosystemActions = useMemo(
		() => (
			<ContentPanelQuickAdd
				key="quick-add-content-panel"
				externalId="issue.ecosystem.quick-add-content-panel"
				// @ts-expect-error - TS7006 - Parameter 'addonModuleKey' implicitly has an 'any' type.
				onSelectContentPanel={(addonModuleKey) => {
					onSelectContentPanel(addonModuleKey);
					triggerButtonClickAnalytics(SELECT_CONTENT_PANEL, 3, 4);
				}}
				// @ts-expect-error - TS7006 - Parameter 'extension' implicitly has an 'any' type.
				onAddExtension={(extension) => {
					onAddExtension(extension);
					triggerButtonClickAnalytics(ADD_CONTENT_PANEL, 3, 4);
				}}
				createItemView={createItemView}
				startIndex={mappedIssueActions.length}
				isQuickActionsListView={isQuickActionsListView}
			/>
		),
		[
			createItemView,
			isQuickActionsListView,
			mappedIssueActions.length,
			onAddExtension,
			onSelectContentPanel,
			triggerButtonClickAnalytics,
		],
	);

	const formsActions = useMemo(
		() =>
			hasProjectForms
				? [
						<AddFormButton
							key="add-form-button"
							createItemView={createItemView}
							onClick={onClickAddFormButton}
							hasIssueForms={hasIssueForms}
							isQuickActionsListView={isQuickActionsListView}
						/>,
					]
				: [],
		[createItemView, hasIssueForms, hasProjectForms, isQuickActionsListView, onClickAddFormButton],
	);

	const designActions = useMemo(
		() =>
			!isAnonymousUser && canEditIssues
				? [
						<AddDesignItem
							key="add-design-button"
							createItemView={createItemView}
							onMount={forceUpdate}
						/>,
					]
				: [],
		[canEditIssues, createItemView, forceUpdate, isAnonymousUser],
	);

	const asyncConfluenceContentMenu = useMemo(
		() =>
			ff('platform.embedded-confluence.jira-issue.create-confluence-pages', false)
				? [
						<CreateConfluenceContentMenu
							key="create-confluence-content-menu"
							appearance={shouldBeCompact ? 'compact' : ''}
							isQuickActionsListView={isQuickActionsListView}
						/>,
					]
				: [],
		[isQuickActionsListView, shouldBeCompact],
	);

	let loomTouchpointVariant: LoomTouchpointVariantType = LoomTouchpointVariant.NONE;
	let dismissAd: () => Promise<void> = useCallback(() => Promise.resolve(), []);

	if (fg('loom_crossflow_enablement_in_jira')) {
		const touchPointVariants = useLoomTouchpointVariantWithAdControls(
			LOOM_QUICK_ADD_AD_CONTROL_DISMISS_KEY,
		);
		loomTouchpointVariant = touchPointVariants.loomTouchpointVariant;
		dismissAd = touchPointVariants.dismissAd;
	}

	const loomVideoButtons: React.JSX.Element[] = useMemo(() => {
		let loomButtons: React.JSX.Element[] = [];
		if (fg('loom_crossflow_enablement_in_jira')) {
			loomButtons = [
				// record Loom button could have crossflow, crossjoin or co-use touchpoints, so the logic is handled in the LoomQuickAddItemView
				<LoomQuickAddItemView
					key="loom-record-video"
					loomTouchpointVariant={loomTouchpointVariant}
					onDismissAd={dismissAd}
				/>,
			];

			if (loomTouchpointVariant === LoomTouchpointVariant.COUSE) {
				// insert existing Loom video button should only render when we are in the co-use scenario
				loomButtons = [
					...loomButtons,
					<LoomInsertLinkButton
						key="loom-insert-existing-video"
						onInsertLoomLinkClick={onInsertLoomLinkClick}
					/>,
				];
			}
		}

		return loomButtons;
	}, [dismissAd, loomTouchpointVariant, onInsertLoomLinkClick]);

	const confluenceButtons: React.JSX.Element[] = useMemo(
		() =>
			fg('quick_actions_menu_ga')
				? dynamicDropdownItems
						.filter((dropdownItem) => dropdownItem.category === 'confluence')
						.map((dropdownItem) => (
							<DropdownItem
								key={dropdownItem.itemKey}
								onClick={dropdownItem.onClick}
								elemBefore={dropdownItem.iconComponent}
								elemAfter={dropdownItem.itemBadgeComponent}
								{...dropdownItem.additionalDropdownProps}
							>
								{dropdownItem.labelComponent}
							</DropdownItem>
						))
				: [],
		[dynamicDropdownItems],
	);

	const getQuickAddActionData = useCallback((): QuickAddActionData | ReactNode[] => {
		if (isQuickActionsListView) {
			const confluenceContentContainer = <div ref={quickActionListViewContainerRef} />;

			return {
				actions: [
					...mappedIssueActions,
					...suggestResourcesAction,
					...linkButtonActions,
					...changeManagementActions,
					...formsActions,
					...reviewButton,
					...developerEscalationsAction,
					...designActions,
					...(fg('quick_actions_menu_ga') ? confluenceButtons : [confluenceContentContainer]),
					...loomVideoButtons,
				],
				apps: ecosystemActions,
			};
		}

		return [
			...mappedIssueActions,
			...suggestResourcesAction,
			linkButtonActions,
			...changeManagementActions,
			...formsActions,
			...reviewButton,
			...developerEscalationsAction,
			...designActions,
			...asyncConfluenceContentMenu,
			ecosystemActions,
		];
	}, [
		isQuickActionsListView,
		mappedIssueActions,
		suggestResourcesAction,
		linkButtonActions,
		changeManagementActions,
		formsActions,
		reviewButton,
		developerEscalationsAction,
		designActions,
		asyncConfluenceContentMenu,
		ecosystemActions,
		confluenceButtons,
		loomVideoButtons,
	]);

	if (isQuickActionsListView) {
		const quickAddActionData = getQuickAddActionData();

		if ('actions' in quickAddActionData && 'apps' in quickAddActionData) {
			const { actions, apps } = quickAddActionData;
			const setMenuOpen = (menuName: string, isOpen: boolean) => {
				setOpenMenu(isOpen ? menuName : null);
			};

			const { availableAddons, canInstallAddons } = props;

			const renderActions = useCallback(() => {
				return (
					<>
						{actions && actions.length ? (
							<QuickAddWrapper ref={setMeasuredRef('quick-add-items')}>
								<QuickAddItemsCompact
									actions={actions}
									apps={apps}
									availableAddons={availableAddons}
									canInstallAddons={canInstallAddons}
									setMenuOpen={setMenuOpen}
									openMenu={openMenu}
									{...(!fg('quick_actions_menu_ga') ? { quickActionListViewContainerRef } : {})}
								/>
								{ff('platform.embedded-confluence.jira-issue.create-confluence-pages', false) && (
									<CreateConfluenceContentMenu
										key="create-confluence-content-menu"
										appearance={shouldBeCompact ? 'compact' : ''}
										isQuickActionsListView={isQuickActionsListView}
										{...(!fg('quick_actions_menu_ga')
											? { quickActionsListViewContainer: quickActionListViewContainerRef }
											: { renderQuickActionItem })}
									/>
								)}
							</QuickAddWrapper>
						) : (
							<></>
						)}
					</>
				);
			}, [
				actions,
				apps,
				availableAddons,
				canInstallAddons,
				isQuickActionsListView,
				openMenu,
				renderQuickActionItem,
				setMeasuredRef,
				setMenuOpen,
				shouldBeCompact,
			]);

			return (
				<UFOSegment name="issue-quick-add-items">
					<ErrorBoundary id="jira.issuebase.quickadditems">
						{fg('quick_actions_menu_ga') ? (
							<>{renderActions()}</>
						) : (
							<SpotlightTarget name={QUICK_ADDS}>{renderActions()}</SpotlightTarget>
						)}
					</ErrorBoundary>
				</UFOSegment>
			);
		}
	}

	const actions = getQuickAddActionData();

	return (
		<UFOSegment name="issue-quick-add-items">
			<ErrorBoundary id="jira.issuebase.quickadditems">
				<SpotlightTarget name={QUICK_ADDS}>
					{Array.isArray(actions) && actions.length ? (
						<QuickAddWrapper ref={setMeasuredRef('quick-add-items')}>{actions}</QuickAddWrapper>
					) : (
						<div />
					)}
				</SpotlightTarget>
			</ErrorBoundary>
		</UFOSegment>
	);
};

QuickAddItems.defaultProps = {
	canCreateIssueLink: false,
	canCreateAttachment: false,
	canLinkConfluencePage: false,
	canAddWebLinks: false,
	triggerButtonClickAnalytics: noop,
};
