import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { getEscalatorBaseUrl } from '@atlassian/jira-servicedesk-incident-management-common/src/utils';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Configuration } from '../types';

const getUrl = (cloudId: CloudId): string =>
	`${getEscalatorBaseUrl(cloudId)}/context/incident-management`;

export type RemoteGetParams = {
	cloudId: CloudId;
};

export const remoteGet = async ({ cloudId }: RemoteGetParams): Promise<Configuration> =>
	fetchJson(getUrl(cloudId));
