import type {
	ViewContext,
	UploadContext,
	UserAuth,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/media-context.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StoreApi } from './types';

export const setViewContext =
	(issueKey: IssueKey, viewContext: ViewContext | null) =>
	({ setState, getState }: StoreApi) => {
		setState({
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			[issueKey as string]: {
				...getState()[issueKey],
				viewContext,
			},
		});
	};

export const setUploadContext =
	(issueKey: IssueKey, uploadContext: UploadContext | null) =>
	({ setState, getState }: StoreApi) => {
		setState({
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			[issueKey as string]: {
				...getState()[issueKey],
				uploadContext,
			},
		});
	};

export const setUserAuthContext =
	(issueKey: IssueKey, userAuth: UserAuth | null) =>
	({ setState, getState }: StoreApi) => {
		setState({
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			[issueKey as string]: {
				...getState()[issueKey],
				userAuth,
			},
		});
	};
