import type { Environment } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import {
	RECAPTCHA_SITE_KEY_PROD,
	RECAPTCHA_SITE_KEY_STAGING,
	RECAPTCHA_SITE_KEY_DEV,
} from './constants';

export const getRecaptchaSiteKey = (environment: Environment) => {
	switch (environment) {
		case 'prod':
			return RECAPTCHA_SITE_KEY_PROD;
		case 'staging':
			return RECAPTCHA_SITE_KEY_STAGING;
		default:
			return RECAPTCHA_SITE_KEY_DEV;
	}
};
