import unionBy from 'lodash/unionBy';
import queryString from 'query-string';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { trackFetchErrors } from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getEscalatorBaseUrl } from '@atlassian/jira-servicedesk-incident-management-common/src/utils';
import type { Paginated } from '@atlassian/jira-servicedesk-incident-management-services/src/common/types.tsx';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { SEARCH_ALERTS_PAGE_SIZE } from '../../../common/constants';
import type { LinkedAlert } from '../../../common/types';
import { SEARCH_ALERTS_ERROR } from '../../constants';
import type { EscalatorLinkedAlert, State, LinkedAlertIds } from '../../types';
import { transformEscalatorAlertResponse } from '../../utils/transform-alert';

let controller: AbortController;

type Response = Paginated<EscalatorLinkedAlert>;

type Args = {
	cloudId: CloudId;
	textSearch: string;
	startIndex: number;
	hasOpsgenieAccount: boolean | undefined;
	analyticsEvent: UIAnalyticsEvent;
};

export const searchAlerts =
	({ cloudId, textSearch, startIndex, hasOpsgenieAccount = false, analyticsEvent }: Args) =>
	async ({ getState, setState }: StoreActionApi<State>) => {
		if (!hasOpsgenieAccount) {
			return;
		}

		if (!controller) {
			controller = new AbortController();
		}

		const { linkModalAlerts: linkModalAlertsBeforeLoading, linkedAlerts } = getState();

		if (linkModalAlertsBeforeLoading.isLoading) {
			controller.abort();
			controller = new AbortController();
		}

		setState({
			linkModalAlerts: {
				...linkModalAlertsBeforeLoading,
				data: startIndex === 0 ? undefined : linkModalAlertsBeforeLoading.data,
				error: undefined,
				isLoading: true,
				startIndex,
			},
		});

		try {
			const extraQuery = '(NOT "incident-alert-type": "responder")';
			const query = {
				query: textSearch.length > 0 ? `${textSearch} AND ${extraQuery}` : extraQuery,
				start: startIndex,
				size: SEARCH_ALERTS_PAGE_SIZE,
			};

			const url = `${getEscalatorBaseUrl(cloudId)}/alerts/search?${queryString.stringify(query)}`;

			const response: Response = await fetchJson(url, {
				signal: controller.signal,
			});

			const linkedAlertIds = (linkedAlerts.data || []).reduce((acc, linkedAlert) => {
				acc[linkedAlert.id] = true;
				return acc;
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			}, {} as LinkedAlertIds);

			const newAlerts =
				response.results?.map((alert: EscalatorLinkedAlert) =>
					transformEscalatorAlertResponse(alert, linkedAlertIds),
				) || [];

			const { linkModalAlerts } = getState();
			setState({
				linkModalAlerts: {
					...linkModalAlerts,
					isLoading: false,
					data: {
						...linkModalAlerts.data,
						...response,
						// merging by id, to avoid inserting duplicates
						results: unionBy(
							linkModalAlerts.data?.results || [],
							newAlerts,
							(alert: LinkedAlert) => alert.id,
						),
					},
				},
			});

			fireOperationalAnalytics(analyticsEvent, 'searchAlerts succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			if (e.name === 'AbortError') {
				return;
			}
			trackFetchErrors({
				event: analyticsEvent,
				error: e,
				id: 'searchAlerts',
				sendToPrivacyUnsafeSplunk: true,
			});

			const { linkModalAlerts } = getState();
			setState({
				linkModalAlerts: {
					...linkModalAlerts,
					isLoading: false,
					error: { value: e.message, type: SEARCH_ALERTS_ERROR },
				},
			});
		}
	};
