import React from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Checkbox } from '@atlaskit/checkbox';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import {
	useIssueKey,
	useChildIssuesLimit,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useHasExceededChildIssuesLimitAnyPanelOnOrAfterLoad } from '@atlassian/jira-issue-view-services/src/child-issues/use-has-exceeded-child-issues-limit';
import { FireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	useIsClassicProject,
	useProjectKey,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import messages from './messages';

export const SUBTASKS = 'subtasks';
export const CHILD_ISSUES = 'child issues';

type Props = {
	isRollingUpData: boolean;
	location: string;
	actionSubjectId: string;
	onToggleRollingUpData: (arg1: boolean, arg2: UIAnalyticsEvent) => void;
};

export const RollupCheckbox = ({
	isRollingUpData,
	onToggleRollingUpData,
	location,
	actionSubjectId,
}: Props) => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const isClassicProject = useIsClassicProject(projectKey);
	const { formatMessage } = useIntl();
	const childIssuesPanelType = isClassicProject ? SUBTASKS : CHILD_ISSUES;

	const hasExceededChildIssuesLimitOnLoadOrAfter =
		useHasExceededChildIssuesLimitAnyPanelOnOrAfterLoad();

	const childIssuesLimit = useChildIssuesLimit();

	const checkbox = (
		<Checkbox
			isChecked={hasExceededChildIssuesLimitOnLoadOrAfter ? false : isRollingUpData}
			onChange={({ target: { checked } }, analyticsEvent) => {
				onToggleRollingUpData(checked, analyticsEvent);
			}}
			label={formatMessage(messages.includeChildIssueLabel, {
				childIssuesPanelType,
			})}
			name={location}
			isDisabled={hasExceededChildIssuesLimitOnLoadOrAfter}
		/>
	);

	return (
		<>
			{hasExceededChildIssuesLimitOnLoadOrAfter ? (
				<Tooltip
					content={formatMessage(messages.timetrackingRollupDisabledTooltipLabelWithLimit, {
						childIssuesPanelType,
						limit: childIssuesLimit,
					})}
					position="top"
				>
					{checkbox}
				</Tooltip>
			) : (
				checkbox
			)}
			<FireOperationalAnalytics
				action="exposed"
				actionSubject="feature"
				actionSubjectId={actionSubjectId}
			/>
		</>
	);
};
