import {
	commandPaletteDispatchEvent as originalDispatchEvent,
	commandPaletteSubscribeEvent as originalSubscribeEvent,
} from '@atlassian/jira-command-palette-subscriptions/src/common/dispatch-subscribe-event/index.tsx';
import type { JiraSubscriptionTypes } from './types';

export const commandPaletteDispatchEvent = (data: JiraSubscriptionTypes): void => {
	originalDispatchEvent<JiraSubscriptionTypes>(data);
};

export const commandPaletteSubscribeEvent = (
	eventId: JiraSubscriptionTypes['id'],
	callback: (data: JiraSubscriptionTypes) => void,
): (() => void) => originalSubscribeEvent<JiraSubscriptionTypes>(eventId, callback);
