// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentType, type ComponentProps } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { ModalContainer } from './ui';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const UpdateIncidentModalContainer = lazyForPaint<
	ComponentType<
		JSX.LibraryManagedAttributes<typeof ModalContainer, ComponentProps<typeof ModalContainer>>
	>
>(
	() =>
		import(/* webpackChunkName: "async-update-incident-modal" */ './ui').then(
			(module) => module.ModalContainer,
		),
	{ ssr: false },
);

export const AsyncUpdateIncidentModalContainer = () => (
	<Placeholder name="update-incident-modal" fallback={null}>
		<UpdateIncidentModalContainer />
	</Placeholder>
);
