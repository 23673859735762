import get from 'lodash/get';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { REPORTER_TYPE } from '@atlassian/jira-platform-field-config';
import { ReporterKey } from '@atlassian/jira-providers-issue/src/model/issue-system-fields';
import type { Props, ReporterValue } from './types';

export const useReporterField = ({
	issueKey,
	initialValue,
	onSubmit,
	onSuccess,
	onFailure,
}: Props) => {
	const fieldKey = ReporterKey;
	const [{ value: fieldConfig }] = useFieldConfig(issueKey, fieldKey);
	const fieldType = get(fieldConfig, 'schema.type', REPORTER_TYPE);

	const [{ value, error }, { saveValue, resetError }] = useEditField<ReporterValue, null>({
		fieldKey,
		issueKey,
		fieldType,
		initialValue: initialValue || null,
		onSubmit,
		onSuccess,
		onFailure,
	});

	return [
		{ value, error, fieldConfig },
		{ saveValue, resetError },
	] as const;
};
