import { styled } from '@compiled/react';
import { N30, B400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SelectedOptionWrapper = styled.div({
	borderTop: `1px solid ${token('color.border', N30)}`,
	padding: `${token('space.050', '4px')} 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	span: {
		color: token('color.link', B400),
	},
});
