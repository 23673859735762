import React from 'react';
import Avatar from '@atlaskit/avatar';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { SkeletonHeadingItem } from '@atlaskit/menu';
import { Box, Inline, xcss, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

export const GenericError = () => {
	const { formatMessage } = useIntl();

	return (
		<>
			<Box xcss={containerStyles}>
				<Inline xcss={avatarContainerStyles}>
					<Avatar size="small" />
				</Inline>
				<Box xcss={reporterContainerStyles}>
					<Box xcss={infoContainerStyles}>
						<SkeletonHeadingItem width="280px" isShimmering />
						<SkeletonHeadingItem width="80px" isShimmering />
					</Box>
					<SkeletonHeadingItem width="80px" isShimmering />
				</Box>
			</Box>
			<Box xcss={warningMessageStyles}>
				<ErrorIcon label="" primaryColor={token('color.icon.danger', '#C9372C')} />
				<Text>{formatMessage(messages.errorMessage)}</Text>
			</Box>
		</>
	);
};

const containerStyles = xcss({ display: 'flex', alignItems: 'center' });

const avatarContainerStyles = xcss({
	display: 'flex',
});

const reporterContainerStyles = xcss({
	paddingTop: 'space.100',
	gap: 'space.100',
	display: 'flex',
	flexDirection: 'column',
	flex: 1,
});

const infoContainerStyles = xcss({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
});

const warningMessageStyles = xcss({
	display: 'flex',
	marginTop: 'space.200',
	alignItems: 'center',
	gap: 'space.100',
});
