import { useCallback, useEffect, useState } from 'react';
import { getLabelsCache } from '@atlassian/jira-cache/src/services/labels/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log';
import type { LabelOption as Option } from '@atlassian/jira-issue-shared-types/src/common/types/labels-type.tsx';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type { Label } from '@atlassian/jira-shared-types/src/rest/jira/label.tsx';
import { mapCachedItemsToLabelOptions, transformCacheEntries } from './utils';

const useRerenderTrigger = (): [Symbol, () => void] => {
	const [token, setSymbol] = useState(Symbol('useRerenderTrigger_initial'));
	const refreshToken = useCallback(() => setSymbol(Symbol('useRerenderTrigger_rerender')), []);
	return [token, refreshToken];
};

export const useLabelsCache = (fieldId?: string) => {
	const labelsCache = getLabelsCache(fieldId);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [cachedLabels, setCachedLabels] = useState<Option[]>([]);
	const [cacheRefreshToken, refreshCache] = useRerenderTrigger();

	const addToCache = useCallback(
		(labels: Label[]) => {
			Promise.all(
				labels.map(async (label) => {
					try {
						const cachedOption = await labelsCache.get(label);

						fireOperationalAnalytics(createAnalyticsEvent({}), 'labelPickerCache set', {
							isFromCache: Boolean(cachedOption?.label),
						});

						return label && labelsCache.set(`${label}`, { label });
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
					} catch (error: any) {
						log.safeErrorWithoutCustomerData('issue-view.labels.cache.set', error.message);
					}
					return undefined;
				}),
			).then(() => {
				refreshCache(); // trigger an update of the returned cached labels value
			});
		},
		[createAnalyticsEvent, labelsCache, refreshCache],
	);

	const removeFromCache = useCallback(
		(labels: Label[]) => {
			Promise.all(
				labels.map(async (label) => {
					try {
						const cachedOption = await labelsCache.get(label);

						if (cachedOption) {
							fireOperationalAnalytics(createAnalyticsEvent({}), 'labelPickerCache removed');

							return cachedOption && labelsCache.remove(`${cachedOption.label}`);
						}
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
					} catch (error: any) {
						log.safeErrorWithoutCustomerData('issue-view.labels.cache.remove', error.message);
					}
					return undefined;
				}),
			).then(() => {
				refreshCache(); // trigger an update of the returned cached labels value
			});
		},
		[createAnalyticsEvent, labelsCache, refreshCache],
	);

	useEffect(() => {
		const getCachedLabels = async () => {
			const cachedItems = await labelsCache.getAll();
			const transformedCachedItems = transformCacheEntries(cachedItems);
			const labelOptions = mapCachedItemsToLabelOptions(transformedCachedItems);

			setCachedLabels(labelOptions);
		};

		getCachedLabels();
		// Having cacheRefreshToken in the deps array allows us to retrigger a loading of the cached values whenever refreshCache is called
	}, [cacheRefreshToken, labelsCache]);

	return [cachedLabels, { addToCache, removeFromCache }] as const;
};
