import React, { useContext } from 'react';
import { DevOpsAppRecommendationContext } from '@atlassian/jira-dev-ops-app-recommendations-context-provider';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';

const runtimeProps = {};
export const AsyncDevOpsAppRecommendationPanel = () => {
	const { entryPointReferenceSubject } = useContext(DevOpsAppRecommendationContext);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="devOpsAppRecommendationsPanel"
			packageName="jiraIssueViewBase"
			errorFallback="unmount"
			teamName="open-toolchain-arc"
			runtimeProps={runtimeProps}
		/>
	);
};
