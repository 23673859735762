import React, { useCallback } from 'react';
import { useFragment, graphql } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { useIssueId } from '@atlassian/jira-issue-context-service';
import { PeopleInlineEditView } from '@atlassian/jira-issue-field-people-inline-edit-full/src/ui/people';
import type { AggUser } from '@atlassian/jira-issue-user-picker-edit-view/src/common/types';
import { transformAggToUserConnection } from '@atlassian/jira-issue-user-picker-edit-view/src/utils/transform-agg-to-user-connection';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events';
import { IssueViewFieldHeading } from '@atlassian/jira-issue-view-layout-field-heading/src/ui/index.tsx';
import type { ui_issueViewLayoutApproversField_IssueViewApproversField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutApproversField_IssueViewApproversField.graphql';

export interface IssueViewApproversFieldProps {
	area?: Area;
	fragmentKey: ui_issueViewLayoutApproversField_IssueViewApproversField$key;
}

export const IssueViewApproversField = ({ area, fragmentKey }: IssueViewApproversFieldProps) => {
	const issueId = useIssueId();
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();

	const data = useFragment<ui_issueViewLayoutApproversField_IssueViewApproversField$key>(
		graphql`
			fragment ui_issueViewLayoutApproversField_IssueViewApproversField on JiraPeopleField {
				...ui_issueViewLayoutFieldHeading_IssueViewFieldHeading
				...people_issueFieldPeopleInlineEditFull_PeopleInlineEditView_fragmentRef
				fieldId
				type
				__typename
			}
		`,
		fragmentKey,
	);

	const onSubmit = useCallback(
		(value: AggUser[] | null) => {
			issueId &&
				fieldChangeRequested(
					issueId,
					data.fieldId,
					// value is converted into `JiraUserConnection` such that field transformation from agg to legacy works as expected
					transformAggToUserConnection(value),
					undefined,
					{
						type: data.type,
						__typename: data.__typename,
					},
				);
		},
		[data.__typename, data.fieldId, data.type, fieldChangeRequested, issueId],
	);

	const onSubmitSucceeded = useCallback(
		(value: AggUser[] | null) => {
			issueId &&
				fieldChanged(issueId, data.fieldId, transformAggToUserConnection(value), {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChanged, issueId],
	);

	const onSubmitFailed = useCallback(
		() => issueId && fieldChangeFailed(issueId, data.fieldId),
		[data.fieldId, fieldChangeFailed, issueId],
	);

	return (
		<IssueViewFieldHeading
			area={area}
			fragmentKey={data}
			testId={`issue.issue-view-layout.issue-view-approvers-field.${data.fieldId}`}
		>
			<Box xcss={fieldWrapperStyles}>
				<PeopleInlineEditView
					fragmentRef={data}
					onSubmit={onSubmit}
					onSubmitSucceeded={onSubmitSucceeded}
					onSubmitFailed={onSubmitFailed}
				/>
			</Box>
		</IssueViewFieldHeading>
	);
};

const fieldWrapperStyles = xcss({
	marginLeft: 'space.negative.100',
	marginRight: 'space.100',
});
