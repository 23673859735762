import type { ForgeCustomFieldValue } from '@atlassian/jira-forge-ui-types/src/common/types/contexts/custom-field.tsx';
import type {
	FieldType,
	CollectionType,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import type { UserOption } from '@atlassian/jira-issue-user-picker/src/types.tsx';
import type { StateUser } from '../../../../multi-user/transformer';
import { toCustomFieldValue as toGroupsCustomFieldValue } from '../fields/group/multi/transformer.tsx';
import { toCustomFieldValue as toGroupCustomFieldValue } from '../fields/group/single/transformer.tsx';
import type { Group as GroupType } from '../fields/group/types.tsx';
import { toCustomFieldValue as toUsersCustomFieldValue } from '../fields/user/multi/transformer.tsx';
import { toCustomFieldValue as toUserCustomFieldValue } from '../fields/user/single/transformer.tsx';

// TODO srybka
// We should have EACH supported custom field typed and properly validated from fetch to render
// At the moment we have typing which allows us to combine any type/collection value
// And we should have STRICT types on what combinations we support and how they are transformed

type FieldValue<T extends FieldType, C extends CollectionType, V> = {
	type: T;
	collection: C;
	value: V | null;
};

export type FieldValueString = FieldValue<'string', 'none', string>;
export type FieldValueNumber = FieldValue<'number', 'none', number>;
export type FieldValueUser = FieldValue<'user', 'none', UserOption>;
export type FieldValueUsers = FieldValue<'user', 'list', StateUser[]>;
export type FieldValueGroup = FieldValue<'group', 'none', GroupType>;
export type FieldValueGroups = FieldValue<'group', 'list', GroupType[]>;
export type FieldValueLabels = FieldValue<'string', 'list', string[]>;
export type FieldValueObjects = FieldValue<
	'object',
	'none',
	{
		[key: string]: unknown;
	}
>;
export type FieldValueDatetime = FieldValue<'datetime', 'none', string>;
export type FieldValueDate = FieldValue<'date', 'none', string>;

export type FieldValueType =
	| FieldValueString
	| FieldValueNumber
	| FieldValueUser
	| FieldValueUsers
	| FieldValueGroup
	| FieldValueGroups
	| FieldValueLabels
	| FieldValueObjects
	| FieldValueDatetime
	| FieldValueDate;

export const toForgeFieldValue = (fieldValue: FieldValueType): ForgeCustomFieldValue => {
	switch (fieldValue.type) {
		case 'user':
			return fieldValue.collection === 'list'
				? toUsersCustomFieldValue(fieldValue.value)
				: toUserCustomFieldValue(fieldValue.value);
		case 'group':
			return fieldValue.collection === 'list'
				? toGroupsCustomFieldValue(fieldValue.value)
				: toGroupCustomFieldValue(fieldValue.value);
		case 'datetime':
		case 'date':
		case 'string':
		case 'number':
		case 'object':
		default:
			return fieldValue.value;
	}
};
