/* eslint-disable @atlassian/relay/unused-fields */
import { useCallback } from 'react';
import { graphql, fetchQuery } from 'react-relay';
import { useProjectAri } from '@atlassian/jira-canned-response-common/src/ui/use-project-ari/index.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment';
import type { useProjectSpaceInfoGetConfluenceRelationshipQuery } from '@atlassian/jira-relay/src/__generated__/useProjectSpaceInfoGetConfluenceRelationshipQuery.graphql';
import { NULL_SPACE_OBJECT, type SpaceInfo } from '../types';

export const useProjectSpaceInfo = () => {
	const projectAri = useProjectAri();
	const getProjectSpaceInfo = useCallback(async (): Promise<SpaceInfo> => {
		const data = await fetchQuery<useProjectSpaceInfoGetConfluenceRelationshipQuery>(
			getRelayEnvironment(),
			graphql`
				query useProjectSpaceInfoGetConfluenceRelationshipQuery($projectAri: ID!) {
					devOps {
						ariGraph {
							relationships(
								filter: { from: $projectAri, type: "project-documentation-entity" }
								sort: { lastUpdatedSortDirection: DESC }
							) {
								nodes {
									to {
										id
										data {
											__typename
											... on ConfluenceSpace {
												spaceId
												name
												key
											}
											... on ConfluencePage {
												pageId
												space {
													spaceId
													name
													key
												}
											}
										}
									}
								}
							}
						}
					}
				}
			`,
			{
				projectAri,
			},
			{
				fetchPolicy: 'store-or-network',
			},
		).toPromise();

		const nodes = data?.devOps?.ariGraph?.relationships?.nodes;

		if (nodes && nodes?.length === 0) {
			return NULL_SPACE_OBJECT;
		}
		// This logic is copied from the way project pages gets the correct connected Confluence spaceKey
		const spacesAndPages =
			nodes?.filter(
				(node) =>
					node?.to?.data?.__typename &&
					['ConfluencePage', 'ConfluenceSpace'].includes(node?.to?.data?.__typename),
			) ?? [];

		const [relationshipNode] = spacesAndPages;

		if (relationshipNode?.to?.data?.__typename === 'ConfluenceSpace') {
			return {
				spaceKey: relationshipNode?.to?.data?.key,
				spaceId: relationshipNode?.to?.data?.spaceId,
				spaceName: relationshipNode?.to?.data?.name,
			};
		}
		if (relationshipNode?.to?.data?.__typename === 'ConfluencePage') {
			return {
				spaceKey: relationshipNode?.to?.data?.space?.key,
				spaceId: relationshipNode?.to?.data?.space?.spaceId,
				spaceName: relationshipNode?.to?.data?.space?.name,
			};
		}
		return NULL_SPACE_OBJECT;
	}, [projectAri]);

	return {
		getProjectSpaceInfo,
	};
};
