import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import type { FormatOptionLabelMeta } from '@atlaskit/select';
import { fontSizeSmall, colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { Option } from '../../common/types';
import type { SelectOptionProps } from './types';

export const SelectOptionWithAvatarAndDescription = (props: SelectOptionProps) => {
	const {
		option: { label, iconUrl, description },
		optionContext,
	} = props;
	return (
		<FlexRow>
			{Boolean(iconUrl) && (
				<AvatarWrapper>
					<Avatar appearance="square" size="xsmall" src={iconUrl} borderColor="transparent" />
				</AvatarWrapper>
			)}
			<FlexColumn>
				<div data-testid="issue-field-select-base.ui.format-option-label.c-label">{label}</div>
				{optionContext === 'menu' && Boolean(description) && (
					<Description>{description}</Description>
				)}
			</FlexColumn>
		</FlexRow>
	);
};

export const formatOptionLabel = (option: Option, { context }: FormatOptionLabelMeta<Option>) => (
	<SelectOptionWithAvatarAndDescription option={option} optionContext={context} />
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FlexRow = styled.div<{
	children?: ReactNode;
}>({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FlexColumn = styled.div<{
	children?: ReactNode;
}>({
	flexDirection: 'column',
	display: 'flex',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarWrapper = styled.div<{
	children?: ReactNode;
}>({
	marginRight: token('space.100', '8px'),
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.div<{
	children?: ReactNode;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontSize: `${fontSizeSmall}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N300),
});
