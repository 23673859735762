// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'issue.request-participants.text-for-edit-view-when-no-value-is-provided',
		defaultMessage: 'Select the Request Participants',
		description: 'Empty state text when no request participants is provided.',
	},
});
