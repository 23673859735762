import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import omit from 'lodash/omit';
import { useFragment, graphql } from 'react-relay';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from '@atlassian/jira-issue-empty-field-text/src/ui/messages';
import type { sprint_issueFieldSprintReadviewFull_SprintReadView$key as SprintFragment } from '@atlassian/jira-relay/src/__generated__/sprint_issueFieldSprintReadviewFull_SprintReadView.graphql';
import type { ClosedSprint } from '@atlassian/jira-shared-types/src/rest/jira/closed-sprint.tsx';
import type { SprintOption, SprintValue } from '../../common/types';
import {
	findActiveOrFutureSprint,
	filterClosedSprints,
	getRapidViewUrl,
	isObjectWithSprintState,
} from '../../common/utils';
import ClosedSprints from './closed-sprints';
import SprintContent from './sprint-content';
import type { SprintReadViewProps } from './types';

/**
 * The SprintReadView will show a read only view of Sprint field.
 * @param props [SprintReadViewProps](./types.tsx)
 */
export const SprintReadView = ({
	fragmentRef,
	shouldHideSprintLink = false,
	shouldHideClosedSprintsLinks = false,
	placeholder,
	onSprintClick,
	getUrl = getRapidViewUrl,
}: SprintReadViewProps) => {
	const { formatDate, formatMessage } = useIntl();
	const placeholderMessage = placeholder || formatMessage(messages.emptyFieldText);

	const { selectedSprintsConnection } = useFragment<SprintFragment>(
		graphql`
			fragment sprint_issueFieldSprintReadviewFull_SprintReadView on JiraSprintField {
				selectedSprintsConnection {
					edges {
						node {
							id
							sprintId
							name @required(action: THROW)
							state @required(action: THROW)
							endDate
						}
					}
				}
			}
		`,
		fragmentRef,
	);

	const sprints = useMemo<SprintValue[]>(
		() =>
			selectedSprintsConnection?.edges
				?.filter(Boolean)
				.map(({ node }) => node)
				.filter(Boolean)
				.filter(isObjectWithSprintState)
				.map((node) => ({
					id: node.id,
					sprintId: parseInt(node.sprintId, 10),
					name: node.name,
					state: node.state,
					endDate: node.endDate ?? undefined,
				})) ?? [],
		[selectedSprintsConnection?.edges],
	);

	const value = useMemo<SprintOption>(() => {
		const currentSprint = findActiveOrFutureSprint(sprints);

		if (!currentSprint) return null;

		const sprintOption = {
			label: currentSprint.name,
			value: currentSprint.id,
			href: getUrl(currentSprint.sprintId),
			state: currentSprint.state,
		};

		// If in backlog, use onSprintClick instead of href
		return onSprintClick ? omit(sprintOption, 'href') : sprintOption;
	}, [getUrl, onSprintClick, sprints]);

	const closedSprints = useMemo<ClosedSprint[]>(
		() =>
			filterClosedSprints(sprints)?.map((closedSprint: SprintValue) => ({
				id: closedSprint.sprintId,
				name: closedSprint.name,
				href: getUrl(closedSprint.sprintId),
				endDate: formatDate(closedSprint.endDate, {
					day: '2-digit',
					month: 'short',
					year: 'numeric',
				}),
			})),
		[formatDate, getUrl, sprints],
	);

	return (
		<ReadWrapper>
			<SprintContent
				value={value}
				shouldHideSprintLink={shouldHideSprintLink}
				placeholder={placeholderMessage}
				onClick={onSprintClick}
			/>

			{closedSprints.length > 0 && (
				<ClosedSprints
					closedSprints={closedSprints}
					shouldHideLinks={shouldHideClosedSprintsLinks}
				/>
			)}
		</ReadWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	width: '100%',
	flexWrap: 'wrap',
	gap: token('space.100', '8px'),
});
