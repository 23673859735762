import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	title: {
		id: 'invite-and-assign.common.assignee-invited-flag.title',
		defaultMessage: 'Success!',
		description: 'Flag title: Is shown to users after they invite and assign another user',
	},
	description: {
		id: 'invite-and-assign.common.assignee-invited-flag.description',
		defaultMessage:
			'We emailed <strong>{email}</strong> inviting them to join Jira. They will be assigned to the issue shortly.',
		description:
			'Flag description: Is shown to users after they invite and assign another user. {email} refers to the invited email address',
	},
	actionCancel: {
		id: 'invite-and-assign.common.assignee-invited-flag.action-cancel',
		defaultMessage: 'Cancel invite',
		description:
			'Flag action: Is shown to users after they invite and assign another user. Provides a way to cancel the invite',
	},
	descriptionIssueTermRefresh: {
		id: 'invite-and-assign.common.assignee-invited-flag.description-issue-term-refresh',
		defaultMessage:
			'We emailed <strong>{email}</strong> inviting them to join Jira. They will be assigned to the issue shortly.',
		description:
			'Flag description: Is shown to users after they invite and assign another user. {email} refers to the invited email address',
	},
});
