import flow from 'lodash/flow';
import { WithDataProvider } from '@atlassian/jira-providers-issue/src/components/context/consumer.tsx';
import WithInitialSpaRender from '../../../common/metrics/component/with-initial-spa-render';
import metricsContextProvider from '../../../common/metrics/context/provider';
import AppProvider from './main';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	IssueViewCriticalQueryPreloader,
	IssueViewRouteResourceConsumer,
} from './resource-manager';

export default flow(WithDataProvider, metricsContextProvider(), WithInitialSpaRender)(AppProvider);
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Props, AppProviderWithConfigProps, AppProviderProps } from './types';
