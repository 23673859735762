// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'issue.activity.worklog.edit-modal.title',
		defaultMessage: 'Edit work log',
		description: 'Title for the Edit work log modal',
	},
});
