/* eslint-disable @atlassian/relay/unused-fields */
import React, { useCallback, type ComponentPropsWithoutRef } from 'react';
import memoizeOne from 'memoize-one';
import { useFragment, graphql, useMutation, fetchQuery, useRelayEnvironment } from 'react-relay';
import type {
	SelectValueShape,
	ServerSuggestions,
} from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types';
import MultiSelectInlineEditView from '@atlassian/jira-issue-internal-field-select/src/multi-select-inline-edit';
import {
	formatOptionLabel,
	multiUserSelectFilterOption,
} from '@atlassian/jira-issue-view-base/src/context/multi-user';
import messages from '@atlassian/jira-issue-view-base/src/context/multi-user/messages';
import {
	type StateUser,
	transformToStateValue,
	transformFromStateValue,
	avatarSize,
} from '@atlassian/jira-issue-view-base/src/context/multi-user/transformer';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import { getDisplayName } from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field';
import {
	useConnectRelayField,
	type ConnectedLayoutProps,
	type PropsCallback,
} from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-relay-field';
import { ConnectedRelayFieldWrapper } from '@atlassian/jira-issue-view-common-views/src/connect-field/relay-field/field-wrapper';
import { mapNodes } from '@atlassian/jira-relay-utils/src/utils/map-nodes';
import type {
	ui_issueViewLayoutMultiUserField_IssueViewMultiUserField$key as ComponentsInlineEditFragment,
	ui_issueViewLayoutMultiUserField_IssueViewMultiUserField$data as ComponentsInlineEditFragmentData,
} from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutMultiUserField_IssueViewMultiUserField.graphql';
import type { ui_issueViewLayoutMultiUserField_IssueViewMultiUserField_Mutation } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutMultiUserField_IssueViewMultiUserField_Mutation.graphql';
import type { uiMultiUserFieldSearchRefetchQuery as uiMultiUserFieldSearchRefetchQueryType } from '@atlassian/jira-relay/src/__generated__/uiMultiUserFieldSearchRefetchQuery.graphql';
import type { AggUser } from '../common/types';

type ComponentValueShape = SelectValueShape[] | undefined;
type AggValueShape = Pick<
	ComponentsInlineEditFragmentData,
	'selectedUsersConnection'
>['selectedUsersConnection'];

export type IssueViewMultiUserFieldProps = ConnectedLayoutProps<ComponentsInlineEditFragment>;

export const toComponentValueShape = (fieldData: AggUser[]): StateUser[] =>
	fieldData.map((user) => ({
		displayName: user.name,
		avatarUrls: {
			[avatarSize]: user.picture,
		},
		id: user.id,
		accountId: user.accountId,
		email: user.email ?? undefined,
	}));

const transformComponentValueToAggShape = (componentValue: ComponentValueShape) => {
	return {
		selectedUsersConnection: {
			edges:
				componentValue?.map((option) => ({
					node: {
						__typename: 'AtlassianAccountUser',
						accountId: option.accountId ?? '',
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						id: option.id as string,
						name: option.content,
						picture: option.avatarUrl ?? '',
						email: option.email,
					},
				})) ?? [],
		},
	};
};
const transformSuggestions = memoizeOne((response: AggUser[] | null): ServerSuggestions => {
	const items: AggUser[] = response ?? [];

	return [
		{
			items: items.map((item) => ({
				content: item.name,
				value: String(item.accountId),
				accountId: item.accountId,
				id: item.id,
				avatarUrl: item.picture,
				email: item.email ?? undefined,
			})),
		},
	];
});

type AdditionalProps = Pick<
	ComponentPropsWithoutRef<typeof MultiSelectInlineEditView>,
	| 'fetchSuggestions'
	| 'placeholder'
	| 'noValueText'
	| 'tagAppearance'
	| 'isUserTag'
	| 'showPinButton'
> & {
	showDropdownOnClick: boolean;
	formatOptionLabel: typeof formatOptionLabel;
	transformToStateValue: typeof transformToStateValue;
	filterOption: typeof multiUserSelectFilterOption;
};

export function IssueViewMultiUserField(props: IssueViewMultiUserFieldProps) {
	const environment = useRelayEnvironment();
	const data = useFragment<ComponentsInlineEditFragment>(
		graphql`
			fragment ui_issueViewLayoutMultiUserField_IssueViewMultiUserField on JiraMultipleSelectUserPickerField {
				id
				fieldId
				type
				name
				description
				__typename

				fieldConfig {
					isEditable
				}
				issue {
					key
				}
				selectedUsersConnection {
					edges {
						node {
							accountId
							id
							name
							picture
							... on AtlassianAccountUser {
								email
							}
						}
					}
				}
			}
		`,
		props.fragmentKey,
	);

	const [commit] = useMutation<ui_issueViewLayoutMultiUserField_IssueViewMultiUserField_Mutation>(
		graphql`
			mutation ui_issueViewLayoutMultiUserField_IssueViewMultiUserField_Mutation(
				$input: JiraUpdateMultipleSelectUserPickerFieldInput!
			) @raw_response_type {
				jira @optIn(to: "JiraIssueFieldMutations") {
					updateMultipleSelectUserPickerField(input: $input) {
						success
						errors {
							message
						}
						field {
							id
							selectedUsersConnection {
								edges {
									node {
										accountId
										id
										name
										picture
										... on AtlassianAccountUser {
											email
										}
									}
								}
							}
						}
					}
				}
			}
		`,
	);

	const getComponentProps = useCallback<
		PropsCallback<
			ComponentsInlineEditFragment,
			ComponentsInlineEditFragmentData,
			ComponentValueShape,
			AggValueShape,
			AdditionalProps
		>
	>(
		({ intl, softRefreshCallbacks }) => {
			const fetchSuggestions = async (query: string): Promise<ServerSuggestions> => {
				const queryData = await fetchQuery<uiMultiUserFieldSearchRefetchQueryType>(
					environment,
					graphql`
						query uiMultiUserFieldSearchRefetchQuery(
							$id: ID!
							$searchBy: String!
							$first: Int!
							$after: String
						) {
							node(id: $id) {
								... on JiraMultipleSelectUserPickerField {
									users(searchBy: $searchBy, first: $first, after: $after)
										@connection(key: "multiUser_issueFieldMultiUser_users") {
										edges {
											node {
												id
												accountId
												name
												picture
												... on AtlassianAccountUser {
													email
												}
											}
										}
									}
								}
							}
						}
					`,
					{
						id: data.id,
						searchBy: query,
						first: 50,
						after: null,
					},
				).toPromise();

				return transformSuggestions(mapNodes<AggUser>(queryData?.node?.users));
			};
			return {
				jiraIssueField: data,

				value: transformFromStateValue(
					toComponentValueShape(mapNodes<AggUser>(data.selectedUsersConnection)),
				),

				onValueConfirm(componentValue) {
					const newAggValue = transformComponentValueToAggShape(componentValue);

					softRefreshCallbacks.onSubmit(newAggValue.selectedUsersConnection);

					const newIds = mapNodes<AggUser>(newAggValue.selectedUsersConnection).map(
						(value) => value.id,
					);

					commit({
						variables: {
							input: {
								id: data.id,
								operations: [
									{
										ids: newIds,
										operation: 'SET',
									},
								],
							},
						},
						onCompleted: (mutationData) => {
							if (mutationData.jira?.updateMultipleSelectUserPickerField?.success) {
								softRefreshCallbacks.onSubmitSucceeded(newAggValue.selectedUsersConnection);
							} else {
								softRefreshCallbacks.onSubmitFailed();
							}
						},
						onError() {
							softRefreshCallbacks.onSubmitFailed();
						},
						optimisticResponse: {
							jira: {
								updateMultipleSelectUserPickerField: {
									success: true,
									errors: [],
									field: {
										id: data.id,
										selectedUsersConnection: {
											...newAggValue.selectedUsersConnection,
										},
									},
								},
							},
						},
					});
				},

				additionalProps: {
					fetchSuggestions,
					placeholder: intl.formatMessage(messages.placeholder),
					noValueText: intl.formatMessage(genericMessages.noValue),
					tagAppearance: 'rounded',
					showDropdownOnClick: false,
					isUserTag: true,
					formatOptionLabel,
					showPinButton: getShowPinButton(props.area),
					filterOption: multiUserSelectFilterOption,
					transformToStateValue,
				},
			};
		},
		[commit, data, props.area, environment],
	);

	const connectField = useConnectRelayField(props, data, getComponentProps);
	const componentName = getDisplayName(MultiSelectInlineEditView);

	return (
		<ConnectedRelayFieldWrapper componentName={componentName}>
			{/* @ts-expect-error - TS2739 - ReturnType of useConnectRelayField do match View props interface as additionalProps are being spread */}
			<MultiSelectInlineEditView {...connectField.componentProps} />
		</ConnectedRelayFieldWrapper>
	);
}
