import { BRANCH_PANEL_DATA_TYPE } from '../../../../common/model/constants';
import {
	CHANGE_TAB,
	SET_SELECTED_APPLICATION_TYPE,
	OPEN_PULL_REQUEST_CREATION_DISCOVERY,
	CLOSE_PULL_REQUEST_CREATION_DISCOVERY,
	type Action,
} from '../../actions/ui';
import type { UiState } from './types';

export const defaultState: UiState = {
	activeTab: BRANCH_PANEL_DATA_TYPE,
	selectedApplicationType: undefined,
	isCreatingPullRequestFromDiscovery: false,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: UiState = defaultState, action: Action): UiState => {
	switch (action.type) {
		case CHANGE_TAB:
			return {
				...state,
				activeTab: action.payload.activeTab,
				isCreatingPullRequestFromDiscovery: false,
			};
		case SET_SELECTED_APPLICATION_TYPE:
			return {
				...state,
				selectedApplicationType: action.payload.selectedApplicationType,
				isCreatingPullRequestFromDiscovery: false,
			};
		// @ts-expect-error - TS2678 - Type '"state.actions.ui.OPEN_PULL_REQUEST_CREATION_DISCOVERY"' is not comparable to type '"state.actions.ui.CHANGE_TAB" | "state.actions.ui.SET_SELECTED_APPLICATION_TYPE"'.
		case OPEN_PULL_REQUEST_CREATION_DISCOVERY:
			return {
				...state,
				isCreatingPullRequestFromDiscovery: true,
			};
		// @ts-expect-error - TS2678 - Type '"state.actions.ui.CLOSE_PULL_REQUEST_CREATION_DISCOVERY"' is not comparable to type '"state.actions.ui.CHANGE_TAB" | "state.actions.ui.SET_SELECTED_APPLICATION_TYPE"'.
		case CLOSE_PULL_REQUEST_CREATION_DISCOVERY:
			return {
				...state,
				isCreatingPullRequestFromDiscovery: false,
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
