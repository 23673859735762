import { addSeconds } from 'date-fns';
import { toAri, parseAri, type Ari, getAriConfig } from '@atlassian/jira-platform-ari';
import type { ProjectTypeGQL } from '@atlassian/jira-polaris-remote-legacy-project/src/services/project-config/get/types';
import type { ProjectConfigFetcher } from '@atlassian/jira-polaris-remote-legacy-project/src/services/project-config/types.tsx';
import { toIssueTypeId, toProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { getProject, type JiraProjectGetResponse } from '../../../services/jira/get-project';
import type { IssueType, PolarisProject } from '../types';

const transformIssueType = (ideaType: ProjectTypeGQL['ideaTypes'][0]): IssueType => {
	const ari = toAri(ideaType.id);
	if (ari === undefined) {
		throw new Error(`polaris-project.bad-issuetype-ari: ${ideaType.id}`);
	}

	const parsed = parseAri(ari);
	if (parsed === undefined) {
		throw new Error(`polaris-project.bad-issuetype-resource: ${ideaType.id}`);
	}

	return {
		id: ari,
		name: ideaType.name,
		jiraIssueTypeId: toIssueTypeId(parsed.resourceId),
		iconUrl: ideaType.iconUrl != null ? ideaType.iconUrl : undefined,
	};
};

const transformSelectedDeliveryProject = (selectedDeliveryProject?: string | null) => {
	if (selectedDeliveryProject !== null && selectedDeliveryProject !== undefined) {
		const ari = toAri(selectedDeliveryProject);
		return ari;
	}
	return undefined;
};

const ONBOARDED_AT_ADJUST_TIME = 10; // 10 seconds
const transformOnboardedAtTimestamp = (onboardedAt?: string | null): string | undefined => {
	if (onboardedAt === null || onboardedAt === undefined) {
		return undefined;
	}

	// Increasing "onboardedAt" returned from polaris-api, since system clocks of polaris and jira can be unsynchronised
	// "onboardedAt" should be later than any "created" field of the template-generated issues
	return addSeconds(new Date(onboardedAt), ONBOARDED_AT_ADJUST_TIME).toISOString();
};

/**
 * Transform graphql response to project controller type PolarisProject.
 */
const transformResponse = (response: ProjectTypeGQL): PolarisProject => {
	const ari = toAri(response.id);
	if (ari === undefined) {
		throw new Error(`polaris-project.bad-project-ari: ${response.id}`);
	}

	if (response.ideaTypes.length === 0) {
		throw new Error(`polaris-project.no-idea-type: ${response.id}`);
	}

	return {
		id: ari,
		name: response.name,
		key: toProjectKey(response.key),
		jiraRankFieldKey: response.rankField != null ? response.rankField : undefined,
		ideaIssueTypes: response.ideaTypes.map(transformIssueType),
		onboarded: response.onboarded,
		avatarUrls: {
			'16x16': response.avatarUrls.x16,
			'24x24': response.avatarUrls.x24,
			'32x32': response.avatarUrls.x32,
			'48x48': response.avatarUrls.x48,
		},
		selectedDeliveryProject: transformSelectedDeliveryProject(response.selectedDeliveryProject),
		issueLinkType: {
			merge: response.issueLinkType.merge,
			delivery: response.issueLinkType.delivery,
			datapoint: response.issueLinkType.datapoint,
		},
		// @ts-expect-error TS2322 interface with generated types
		statusCategories:
			response.statusCategories?.map(({ __typename, ...statusCategory }) => statusCategory) || [],
		arjHierarchyConfiguration: Array.from(response.arjHierarchyConfiguration || []).map(
			({ __typename, ...arjHierarchyConfigurationLevel }) => arjHierarchyConfigurationLevel,
		),
		onboardedAtTimestamp: transformOnboardedAtTimestamp(response.onboardedAt),
		templateVersion: response.onboardTemplate ?? undefined,
		// fetched in a separate calls
		insights: null,
		plays: null,
	};
};

type FetchArguments = {
	containerAri: Ari;
	projectFetcher: ProjectConfigFetcher | undefined;
	noCache?: boolean;
	fireAnalyticsEvent?: boolean;
	isSiteAdmin: boolean;
};

const transform = (restProject: JiraProjectGetResponse, ari: Ari): PolarisProject => ({
	id: ari,
	name: restProject.name,
	key: toProjectKey(restProject.key),
	ideaIssueTypes: [],
	statusCategories: [],
	avatarUrls: {
		'16x16': restProject.avatarUrls['16x16'],
		'24x24': restProject.avatarUrls['24x24'],
		'32x32': restProject.avatarUrls['32x32'],
		'48x48': restProject.avatarUrls['48x48'],
	},
	insights: [],
	onboarded: true,
	selectedDeliveryProject: undefined,
	plays: [],
	issueLinkType: undefined,
	arjHierarchyConfiguration: undefined,
	onboardedAtTimestamp: undefined,
	templateVersion: undefined,
});

const fetchMinimal = async (containerAri: Ari) => {
	const { resourceId: projectId } = getAriConfig(containerAri);
	const response = await getProject(projectId);
	return transform(response, containerAri);
};

// Error message: [...] API-9998 permission check failed [...]
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isPermissionCheckError = (error: Error | any): boolean =>
	error instanceof Error ? error.message.search('API-9998') !== -1 : false;

export const fetch = async ({
	containerAri,
	projectFetcher,
	noCache,
	fireAnalyticsEvent,
	isSiteAdmin,
}: FetchArguments) => {
	if (projectFetcher) {
		try {
			const response = await projectFetcher(
				containerAri,
				noCache === true,
				fireAnalyticsEvent === true,
			);
			return transformResponse(response);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (isPermissionCheckError(error) && isSiteAdmin) {
				return fetchMinimal(containerAri);
			}
			throw error;
		}
	}
	return fetchMinimal(containerAri);
};
