import { defineMessages } from 'react-intl-next';

export default defineMessages({
	persistentPlaceholderText: {
		id: 'fabric.editor.ai.persistentPlaceholder.text',
		defaultMessage: `Type /ai for writing assistance`,
		description:
			'Message to users that displays in placeholder text when users cursor is inside the editor on an empty line',
	},
});
