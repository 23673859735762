import { useCallback } from 'react';
import { graphql, useMutation, useRelayEnvironment } from 'react-relay';
import { JiraIssueAri, JiraIssuefieldvalueAri } from '@atlassian/ari/jira';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { SaveField } from '@atlassian/jira-issue-field-base/src/common/types.tsx';
import { ISSUE_VIEW } from '@atlassian/jira-issue-transition-common-types/src/common/constants/index.tsx';
import { useTriggerIssueTransitionModal } from '@atlassian/jira-issue-transition-trigger/src/utils/use-trigger-issue-transition-modal/index.tsx';
import type { useStatusMutation as useStatusMutationQuery } from '@atlassian/jira-relay/src/__generated__/useStatusMutation.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { StatusFieldSaveByIdResponse, StatusSaveId } from '../types';
import { fetchStatusTransitionData } from './utils';

export function useStatusMutation(): SaveField<StatusSaveId, StatusFieldSaveByIdResponse> {
	const cloudId = useCloudId();
	const environment = useRelayEnvironment();
	const [, { openIssueTransitionModal }] = useTriggerIssueTransitionModal();
	const [commit] = useMutation<useStatusMutationQuery>(graphql`
		mutation useStatusMutation($input: JiraUpdateStatusFieldInput!) @raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateStatusByQuickTransition(input: $input) {
					success
					errors {
						message
					}
					field {
						id
						status {
							id
							statusId
							name
							description
							statusCategory {
								id
								colorName
								statusCategoryId
								key
								name
							}
						}
						transitions @optIn(to: "JiraStatusFieldOptions") {
							edges {
								node {
									transitionId
									name
									isAvailable
									hasScreen
									isGlobal
									isLooped
									isInitial
								}
							}
						}
					}
				}
			}
		}
	`);

	return useCallback(
		async (issueKey, fieldId, option) => {
			const fetchResult = await fetchStatusTransitionData(option, environment, {
				cloudId,
				issueKey,
				fieldId,
			});

			if (!fetchResult) {
				throw new Error('Failed to fetch status transition data');
			}

			return new Promise((resolve, reject) => {
				const { status, transition, issueId } = fetchResult;
				if (!transition?.hasScreen) {
					commit({
						variables: {
							input: {
								id: JiraIssuefieldvalueAri.create({
									siteId: cloudId,
									issueId,
									fieldId,
								}).toString(),
								statusTransitionId: Number(transition.transitionId),
							},
						},
						onCompleted(data) {
							if (!data.jira?.updateStatusByQuickTransition) {
								reject(new Error('Unknown error for status field occurred'));
								return;
							}

							const { success, errors } = data.jira.updateStatusByQuickTransition;

							if (!success || errors?.length) {
								reject(
									errors?.[0].message
										? new ValidationError(errors[0].message)
										: new Error('Unknown error for status field occurred'),
								);
								return;
							}

							resolve({
								id: transition.to.statusId,
								name: transition.to.name,
								statusCategory: {
									id: Number(transition.to.statusCategory.statusCategoryId),
									name: transition.to.statusCategory.name,
								},
							});
						},
						onError: reject,
					});
				} else {
					const handleDialogSuccess = async () => {
						resolve({
							id: transition.to.statusId,
							name: transition.to.name,
							statusCategory: {
								id: Number(transition.to.statusCategory.statusCategoryId),
								name: transition.to.statusCategory.name,
							},
						});
					};

					const handleDialogCancel = () => {
						resolve({
							id: status.statusId,
							name: status.name,
							statusCategory: {
								id: Number(status.statusCategory.statusCategoryId),
								name: status.statusCategory.name,
							},
						});
					};

					const ariIssueId = JiraIssueAri.create({
						issueId: String(issueId),
						siteId: cloudId,
					}).toString();

					openIssueTransitionModal?.({
						payload: {
							issueId: ariIssueId,
							issueKey,
							transitionId: transition?.transitionId,
						},
						triggerPointKey: ISSUE_VIEW,
						onDialogSuccess: handleDialogSuccess,
						onDialogCancel: handleDialogCancel,
						onDialogError: reject,
					});
				}
			});
		},
		[cloudId, commit, environment, openIssueTransitionModal],
	);
}
