/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useMemo } from 'react';

import { jsx } from '@emotion/react'; // eslint-disable-line @atlaskit/ui-styling-standard/use-compiled

import { Box, xcss } from '@atlaskit/primitives';

import { useElementSize } from '../../hooks/useElementSize';

import type { CustomDisclaimerProps } from './components/CustomDisclaimer';
import { FooterAIBrand } from './components/FooterAIBrand';
import { FooterDisclaimer } from './components/FooterDisclaimer';
import { AI_BREAKPOINTS, ROVO_BREAKPOINTS } from './constants';
import { type AIBrand } from './types';

const footerStyles = xcss({
	display: 'flex',
	flexWrap: 'wrap',
	paddingLeft: 'space.200',
	paddingRight: 'space.200',
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
	gap: 'space.050',
	borderTopLeftRadius: 'unset',
	borderTopRightRadius: 'unset',
	backgroundColor: 'elevation.surface',
	borderTopWidth: '1px',
	borderTopStyle: 'solid',
	borderTopColor: 'color.border',
	alignItems: 'center',
	justifyContent: 'space-between',
});

interface FooterProps {
	/** The brand to display */
	brand: AIBrand;
	/**
	 * Whether to show the disclaimer or not.
	 *
	 * Some pages, for example, `loading` screen should show only AI brand logo without disclaimer.
	 */
	showDisclaimer: boolean;
	/**
	 * Custom disclaimer to be displayed in the footer.
	 *
	 * @todo: Are we really need custom disclaimer?
	 *        We should check if it's used in any product and remove it support if not.
	 */
	disclaimer: CustomDisclaimerProps['disclaimer'] | undefined;
}

export function Footer({ brand, showDisclaimer, disclaimer }: FooterProps) {
	const [setElement, { width }] = useElementSize();

	const appearance = useMemo(() => {
		if (brand === 'atlassian-intelligence') {
			if (width < AI_BREAKPOINTS.medium) {
				return 'small' as const;
			}

			if (width < AI_BREAKPOINTS.large) {
				return 'medium' as const;
			}

			return 'large' as const;
		} else {
			if (width < ROVO_BREAKPOINTS.medium) {
				return 'small' as const;
			}

			if (width < ROVO_BREAKPOINTS.large) {
				return 'medium' as const;
			}

			return 'large' as const;
		}
	}, [width, brand]);

	return (
		<Box ref={setElement} xcss={footerStyles}>
			{showDisclaimer ? (
				<FooterDisclaimer disclaimer={disclaimer} appearance={appearance} />
			) : (
				<Box />
			)}
			<FooterAIBrand brand={brand} appearance={appearance} />
		</Box>
	);
}
