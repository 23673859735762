import React, { forwardRef, type HTMLProps } from 'react';
import { COMMAND_CONTAINER_ROW_TEST_ID, COMPAL_MENU_GROUP_ID } from '../constants';

export type GroupItemProps = {
	group?: string;
};

export const CommandPaletteSearchResultGroupItem = forwardRef<
	HTMLDivElement,
	HTMLProps<HTMLDivElement> & GroupItemProps
>(({ group, ...rest }, ref) => (
	<div
		ref={ref}
		data-testid={COMMAND_CONTAINER_ROW_TEST_ID}
		id={group ? `${COMPAL_MENU_GROUP_ID}-${group}` : undefined}
		{...rest}
	/>
));
