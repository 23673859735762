import React, { type ReactNode } from 'react';
import { useSpaStateTransition } from '@atlassian/jira-spa-state-controller/src/components/transition-state';
import type { Metrics } from '../context';
import { BentoMetrics } from './bento-metrics';

type Props = {
	isLoading: boolean;
	isPreview: boolean;
	isComplete: boolean;
	issueKey: string;
	metrics: Metrics;
	children?: ReactNode;
	error?: string;
};

// lastTransitionStartTime is coming from Date.now() inside src/packages/platform/controllers/spa-state/src/actions/page-events/index.js
// so we are converting it to origin relativeMillis for consistency with times used inside browser metrics.
const convertEpochMillisToOriginRelativeMillis = (lastTransitionStartTime: undefined | number) => {
	if (lastTransitionStartTime != null) {
		return lastTransitionStartTime - performance.timing.navigationStart;
	}
	return lastTransitionStartTime;
};

export const BentoMetricsWithSpaTimingInfo = (props: Props) => {
	const [{ isInitialRender, lastTransitionStartTime }] = useSpaStateTransition();

	return (
		<BentoMetrics
			{...props}
			isInitialRender={isInitialRender}
			lastTransitionStartTime={convertEpochMillisToOriginRelativeMillis(lastTransitionStartTime)}
		/>
	);
};
