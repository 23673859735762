import React from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import Badge from '@atlaskit/badge';
import { colors } from '@atlaskit/theme';
// eslint-disable-next-line import/order
import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { remainingEstimateViewTestId } from './constants';

export type Props = {
	defaultValue: string | undefined;
	isHoverable?: boolean;
	testId?: string;
};

export const RemainingEstimateView = ({
	isHoverable = false,
	defaultValue,
	testId = remainingEstimateViewTestId,
}: Props) => {
	const badge = <Badge testId={`${testId}.badge`}>{defaultValue}</Badge>;

	return (
		<ReadViewContainer data-testid={testId}>
			<BadgeWrapper>
				{isHoverable ? (
					<HoverContainer data-testid={`${testId}.hover-container`}>{badge}</HoverContainer>
				) : (
					badge
				)}
			</BadgeWrapper>
		</ReadViewContainer>
	);
};

export default RemainingEstimateView;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled2.div({
	wordBreak: 'break-word',
	position: 'relative',
	left: token('space.075', '6px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HoverContainerControl = styled.div({
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'&:hover > span': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: `${token('color.text', colors.N800)} !important`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: `${token('color.background.neutral.hovered', colors.N90)} !important`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	lineHeight: `${gridSize * 2.5}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const HoverContainerExperiment = styled2.div({
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'&:hover > span': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: `${token('color.text', colors.N800)} !important`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: `${token('color.background.neutral.hovered', colors.N90)} !important`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	lineHeight: `${gridSize * 2.5}px`,
});

const HoverContainer = styledComponentWithCondition(
	() => ff('compiled-react-migration-issue-view_u9myz'),
	HoverContainerExperiment,
	HoverContainerControl,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BadgeWrapper = styled2.div({
	display: 'inline-block',
	position: 'relative',
	marginTop: token('space.negative.075', '-6px'),
	marginBottom: token('space.negative.075', '-6px'),
	marginRight: token('space.negative.025', '-2px'),
});
