import type { IssueConfiguration } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types';

export const getFieldKeyByCustomType = (
	customType: string,
	fieldsConfiguration: IssueConfiguration,
) =>
	Object.keys(fieldsConfiguration).find(
		(field) =>
			fieldsConfiguration[field].schema.custom !== null &&
			fieldsConfiguration[field].schema.custom === customType,
	);
