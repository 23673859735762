// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'issue.forge.group.text-for-edit-view-when-no-value-is-provided',
		defaultMessage: 'Select group',
		description:
			"Placeholder displayed in an edit mode of the group custom field when the field's value isn't provided",
	},
});
