import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type { CustomerServiceCustomDetailsEntityType } from '@atlassian/jira-relay/src/__generated__/tableCustomDetailsQuery.graphql';
import { ExperienceFailure } from '@atlassian/jira-servicedesk-organizations-contacts-common/src/common/ui/experience-failure/index.tsx';
import { type UFOExperience, useUFOComponentExperience } from '@atlassian/ufo';
import { ErrorState } from '../details/error-state';

const LoadingComponent = () => (
	<Container>
		<LoadingContainer>
			<Spinner testId="servicedesk-customer-service-issue-view-panel.common.ui.details-container.spinner" />
		</LoadingContainer>
	</Container>
);

export const DetailsContainer = ({
	context,
	children,
	entityExperience,
}: {
	context: CustomerServiceCustomDetailsEntityType;
	children: ReactNode;
	entityExperience?: UFOExperience;
}) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	entityExperience && useUFOComponentExperience(entityExperience);

	return (
		<JSErrorBoundary
			id="DetailsContainer"
			packageName="jiraServicedeskCustomerServiceIssueViewPanel"
			teamName="boysenberry"
			sendToPrivacyUnsafeSplunk
			fallback={() => (
				<>
					<ErrorState context={context} />
					{entityExperience && <ExperienceFailure experience={entityExperience} />}
				</>
			)}
		>
			<Placeholder name="customer-service-issue-view-panel" fallback={<LoadingComponent />}>
				<Container>{children}</Container>
			</Placeholder>
		</JSErrorBoundary>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	borderRadius: '4px',
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.025', '2px'),
	paddingBottom: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LoadingContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: '160px',
	width: '100%',
});
