import React from 'react';

import { useIntl } from 'react-intl-next';
import type { MessageDescriptor } from 'react-intl-next';

import { ErrorScreen } from '@atlassian/generative-ai-modal/screens/Error';

import { useAnalyticsFlowEventsQueue } from '../../analytics/analytics-flow/analyticsFlowUtils';
import globalMessages from '../../messages';
import { useAIExperienceCommonDataContext } from '../useAIExperienceCommonData';

export function FailedScreenWithLogic({
	failureReason,
	errorInfo,
}: {
	failureReason?: string;
	errorInfo: {
		errorKey?: string;
		statusCode?: number;
	};
}) {
	const aiExperienceCommonData = useAIExperienceCommonDataContext();
	const { endExperience } = aiExperienceCommonData;
	const { formatMessage } = useIntl();
	const knownErrorMessages: { [key: string]: MessageDescriptor } = {
		'unknown fail': globalMessages.documentInsertError,
		'token limit fail': globalMessages.tokenLimitExceeded,
		'insert failure': globalMessages.documentInsertError,
		'input too short fail': globalMessages.inputTooShortError,
		'hipaa content fail': globalMessages.hipaaContentError,
	};

	const errorType = failureReason || 'unknown fail';
	const errorMessage = formatMessage(knownErrorMessages[errorType]);

	useAnalyticsFlowEventsQueue({
		stepName: 'failed',
		attributes: {
			errorType,
			...errorInfo,
		},
	});

	return (
		<ErrorScreen
			text={errorMessage}
			onCancel={function () {
				endExperience();
			}}
		/>
	);
}
