import type { OptionToFilter } from '@atlassian/jira-common-components-picker/src/model';
import { filterOptionByLabelAndFilterValues } from '@atlassian/jira-issue-internal-field-select/src/common/custom-format/filter-option';

type UserData = {
	email: string;
	avatarUrl: string;
	accountID: string;
	label: string;
	value: string | number;
	content: string;
};

type MultiUserSelectOption = {
	label: string;
	value: string | number;
	data: UserData;
};

export const filterOption = (option: MultiUserSelectOption, inputValue: string): boolean => {
	const userEmail = option.data.email;

	let optionToFilter: OptionToFilter = {
		label: option.label,
		value: option.value,
	};

	if (userEmail) {
		optionToFilter = { data: { filterValues: [userEmail] }, ...optionToFilter };
	}

	return filterOptionByLabelAndFilterValues(optionToFilter, inputValue);
};
