import { useCallback } from 'react';
import {
	EntryKeys,
	ENTRYPOINT_ID_JIRA_ISSUE_VIEW,
} from '@atlassian/jira-feedback-collector/src/constants.tsx';
import { useFeedbackCollectorActions } from '@atlassian/jira-feedback-collector/src/controllers/index.tsx';
import type { FeedbackCollectorProps } from '@atlassian/jira-feedback-collector/src/types.tsx';
import { useIsIssueOfIncidentsPractice } from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	FEEDBACK_COLLECTOR_LOCATION,
	FEEDBACK_COLLECTOR_ID,
	SESSION_ID,
	FEEDBACK_GROUP_ID,
	BENTO_FEEDBACK_TYPE_FIELD_ID,
	BENTO_FEEDBACK_TYPE_BUG,
	BENTO_FEEDBACK_TYPE_OTHER,
	TENANT_CLOUD_ID,
	SIMILAR_INCIDENTS_FEEDBACK_COLLECTOR_ID,
} from '../../common/constants';

export const useRelatedIssuesFeedbackCollector = (sessionId: string) => {
	const cloudId = useCloudId();

	const { openFeedbackCollector } = useFeedbackCollectorActions();
	const isIssueOfIncidentPractice = useIsIssueOfIncidentsPractice();

	const openRelatedIssuesFeedback = useCallback(() => {
		const feedbackPayload: FeedbackCollectorProps = {
			entrypointId: ENTRYPOINT_ID_JIRA_ISSUE_VIEW,
			feedbackGroupId: FEEDBACK_GROUP_ID,
			feedbackCollectorId: isIssueOfIncidentPractice
				? SIMILAR_INCIDENTS_FEEDBACK_COLLECTOR_ID
				: FEEDBACK_COLLECTOR_ID,
			// @ts-expect-error typeFieldId not defined in platform FeedbackCollectorProps type
			typeFieldId: BENTO_FEEDBACK_TYPE_FIELD_ID,
			typeBugDefaultValue: BENTO_FEEDBACK_TYPE_BUG,
			typeCommentDefaultValue: BENTO_FEEDBACK_TYPE_OTHER,
			typeSuggestionDefaultValue: BENTO_FEEDBACK_TYPE_OTHER,
			typeQuestionDefaultValue: BENTO_FEEDBACK_TYPE_OTHER,
			feedbackContext: [
				{
					id: EntryKeys.FEEDBACK_COLLECTOR_LOCATION,
					value: FEEDBACK_COLLECTOR_LOCATION,
				},
				{
					id: EntryKeys.FEEDBACK_COLLECTOR_ID,
					value: isIssueOfIncidentPractice
						? SIMILAR_INCIDENTS_FEEDBACK_COLLECTOR_ID
						: FEEDBACK_COLLECTOR_ID,
				},
				{ id: TENANT_CLOUD_ID, value: cloudId },

				{ id: SESSION_ID, value: sessionId },
			],
		};
		openFeedbackCollector(feedbackPayload);
	}, [openFeedbackCollector, sessionId, cloudId, isIssueOfIncidentPractice]);

	return { openRelatedIssuesFeedback };
};
