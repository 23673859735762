import isEmpty from 'lodash/isEmpty';
import type { IEnvironment } from 'relay-runtime';
import { fg } from '@atlassian/jira-feature-gating';
import { fireOperationalAnalyticsWithoutContext } from '@atlassian/jira-ui-modifications-analytics/src/common/utils/operational-analytics/index.tsx';
import {
	LOOKUP_SOURCE_ALLOWED_VALUES_PROPERTY,
	LOOKUP_SOURCE_USERS_ASYNC_DATA,
} from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/constants';
import type {
	FieldId,
	FieldMapFromIframe,
	Field,
	AppliedFieldsChanges,
} from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/types/field.tsx';
import type { FieldChangesMapPublic } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/types/fields/field-changes.tsx';
import type { FormDataPublic } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/types/fields/form-field-data.tsx';
import type { LookupData } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/types.tsx';
import type {
	TenantContext,
	ViewContext,
} from '@atlassian/jira-ui-modifications-types/src/common/types/context.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { AppId, ChangeId, IssueAdjustmentsState, StoreContainerProps } from '../../types';
import type { FieldValidationError } from '../../utils/errors/types';
import { UiModificationsFieldsValidationError } from '../../utils/errors/ui-modifications-field-validation-error';
import {
	fieldWasRegisteredDeprecated,
	fieldWasRegistered,
} from '../../utils/field-was-registered/index.tsx';
import { mapFieldPropertyNamesToInternal as mapFieldPropsFromPublicToInternalShape } from '../../utils/map-field-props-from-public-to-internal-shape/index.tsx';
import { prepareErrorsArray } from '../../utils/prepare-errors-array';
import { transformFieldToIframe } from '../../utils/supported-field-properties/index.tsx';
import { collectFieldsAppliedChangesHistory } from '../applied-changes-history/collect-history/index.tsx';
import { transformFieldChangesToInternalShape } from './transform-field-changes-to-internal-shape';

type UpdateFieldsPayload = {
	changeId: ChangeId;
	fieldsChanges: FieldMapFromIframe;
	lookupDataFromApi: Exclude<LookupData, typeof LOOKUP_SOURCE_ALLOWED_VALUES_PROPERTY>;
	tenantContext: TenantContext;
	viewContext: ViewContext;
	environment: IEnvironment;
	appId: AppId;
};

/**
 * Updates fields with the provided changes.
 */
export const updateFields =
	({
		changeId,
		fieldsChanges,
		lookupDataFromApi,
		tenantContext,
		viewContext,
		environment,
		appId,
	}: UpdateFieldsPayload): Action<IssueAdjustmentsState, StoreContainerProps, Promise<void>> =>
	async ({ setState, getState, dispatch }, { viewType }) => {
		if (fg('ui_modifications_multiple_apps')) {
			await dispatch(
				updateFieldsNew({
					changeId,
					fieldsChanges,
					lookupDataFromApi,
					tenantContext,
					viewContext,
					environment,
					appId,
				}),
			);

			return;
		}

		const appliedChanges: FieldMapFromIframe = getState().appliedChanges || {};
		const formData = getState().formData || {};
		const { internalFormMetadata, registeredFields, registeredFieldsDeprecated } = getState();
		const { changesToApply, incomingFormData, errorsByField } = await Object.keys(
			fieldsChanges,
		).reduce(
			async (
				acc: Promise<{
					changesToApply: FieldMapFromIframe;
					incomingFormData: FormDataPublic;
					errorsByField: { [key: string]: FieldValidationError[] };
				}>,
				fieldId: FieldId,
			) => {
				// Do not update fields that are not inside initialized data.
				// Validates if the field that comes from Iframe is supported
				// - because we add to formData only the supported ones.
				const fieldData: Field = formData[fieldId];
				const internalMetadata = internalFormMetadata[fieldId];
				const fieldIsRegistered = fg('ui_modifications_multiple_apps')
					? fieldWasRegistered(registeredFields, changeId, fieldId, appId)
					: fieldWasRegisteredDeprecated(registeredFieldsDeprecated, changeId, fieldId);

				// Only registered field Ids can be updated.
				if (!fieldIsRegistered || !fieldData) {
					// eslint-disable-next-line no-console
					console.warn(`UIM tried to apply changes to the unregistered field ${fieldId}`);

					fireOperationalAnalyticsWithoutContext('applyChanges', 'unregisteredField', viewType, {
						message: 'Attempt to apply changes to a field that was not registered',
						fieldId,
					});

					return acc;
				}

				const lookupData: LookupData = {
					[LOOKUP_SOURCE_ALLOWED_VALUES_PROPERTY]: internalMetadata.allowedValues,
					[LOOKUP_SOURCE_USERS_ASYNC_DATA]: lookupDataFromApi[LOOKUP_SOURCE_USERS_ASYNC_DATA],
				};

				// It updates properties in each field to avoid removing unchanged props for
				// selected field by another app or another onChange update.
				// Only supported properties are allowed.

				const supportedFieldChanges = await transformFieldChangesToInternalShape({
					fieldType: fieldData.fieldType,
					fieldId,
					fieldFromIframe: fieldsChanges?.[fieldId],
					lookupData,
					viewType,
					tenantContext,
					viewContext,
					environment,
				});

				if (supportedFieldChanges.isValid === false) {
					(await acc).errorsByField[fieldId] = supportedFieldChanges.errors;
					return acc;
				}

				// It converts changes to 'public' format to update the formData
				const publicShapeChanges = transformFieldToIframe(
					supportedFieldChanges.data,
					viewType,
					fieldData.fieldType,
					lookupData,
					fieldsChanges?.[fieldId],
					internalMetadata,
				);

				const transformedFieldChanges = mapFieldPropsFromPublicToInternalShape(
					supportedFieldChanges.data,
					viewType,
					fieldData.fieldType,
				);

				const combinedChangesToApply = {
					...appliedChanges[fieldId],
					...transformedFieldChanges,
				};

				if (!isEmpty(combinedChangesToApply)) {
					(await acc).changesToApply[fieldId] = combinedChangesToApply;
					(await acc).incomingFormData[fieldId] = {
						...formData[fieldId],
						...publicShapeChanges,
					};
				}

				return acc;
			},
			Promise.resolve({
				changesToApply: { ...appliedChanges },
				incomingFormData: { ...formData },
				errorsByField: {},
			}),
		);

		if (!isEmpty(errorsByField)) {
			throw new UiModificationsFieldsValidationError('Transformation failed', {
				errors: prepareErrorsArray(errorsByField),
			});
		}

		setState({
			appliedChanges: changesToApply,
			formData: incomingFormData,
		});
	};

export const updateFieldsNew =
	({
		changeId,
		fieldsChanges,
		lookupDataFromApi,
		tenantContext,
		viewContext,
		environment,
		appId,
	}: UpdateFieldsPayload): Action<IssueAdjustmentsState, StoreContainerProps, Promise<void>> =>
	async ({ setState, getState, dispatch }, { viewType }) => {
		const appliedChanges = getState().appliedChanges ?? {};
		const formData = getState().formData ?? {};
		const { internalFormMetadata, registeredFields } = getState();

		const { changesToApply, incomingFormData, errorsByField, newAppliedChangesPublic } =
			await Object.keys(fieldsChanges).reduce(
				async (
					acc: Promise<{
						changesToApply: AppliedFieldsChanges;
						newAppliedChangesPublic: FieldChangesMapPublic;
						incomingFormData: FormDataPublic;
						errorsByField: { [key: string]: FieldValidationError[] };
					}>,
					fieldId: FieldId,
				) => {
					// Do not update fields that are not inside initialized data.
					// Validates if the field that comes from Iframe is supported
					// - because we add to formData only the supported ones.
					const fieldData: Field = formData[fieldId];
					const internalMetadata = internalFormMetadata[fieldId];
					const fieldIsRegistered = fieldWasRegistered(registeredFields, changeId, fieldId, appId);

					// Only registered field Ids can be updated.
					if (!fieldIsRegistered || !fieldData) {
						// eslint-disable-next-line no-console
						console.warn(`UIM tried to apply changes to the unregistered field ${fieldId}`);

						fireOperationalAnalyticsWithoutContext('applyChanges', 'unregisteredField', viewType, {
							message: 'Attempt to apply changes to a field that was not registered',
							fieldId,
						});

						return acc;
					}

					const lookupData: LookupData = {
						[LOOKUP_SOURCE_ALLOWED_VALUES_PROPERTY]: internalMetadata.allowedValues,
						[LOOKUP_SOURCE_USERS_ASYNC_DATA]: lookupDataFromApi[LOOKUP_SOURCE_USERS_ASYNC_DATA],
					};

					// It updates properties in each field to avoid removing unchanged props for
					// selected field by another app or another onChange update.
					// Only supported properties are allowed.
					const supportedFieldChanges = await transformFieldChangesToInternalShape({
						fieldType: fieldData.fieldType,
						fieldId,
						fieldFromIframe: fieldsChanges?.[fieldId],
						lookupData,
						viewType,
						tenantContext,
						viewContext,
						environment,
					});

					if (supportedFieldChanges.isValid === false) {
						(await acc).errorsByField[fieldId] = supportedFieldChanges.errors;
						return acc;
					}

					// It converts changes to 'public' format to update the formData
					const publicShapeChanges = transformFieldToIframe(
						supportedFieldChanges.data,
						viewType,
						fieldData.fieldType,
						lookupData,
						fieldsChanges?.[fieldId],
						internalMetadata,
					);

					const transformedFieldChanges = mapFieldPropsFromPublicToInternalShape(
						supportedFieldChanges.data,
						viewType,
						fieldData.fieldType,
					);

					const combinedChangesToApply = {
						...appliedChanges[fieldId],
						...transformedFieldChanges,
					};

					if (!isEmpty(combinedChangesToApply)) {
						(await acc).newAppliedChangesPublic[fieldId] = publicShapeChanges;
						(await acc).changesToApply[fieldId] = combinedChangesToApply;
						(await acc).incomingFormData[fieldId] = {
							...formData[fieldId],
							...publicShapeChanges,
						};
					}

					return acc;
				},
				Promise.resolve({
					changesToApply: { ...appliedChanges },
					incomingFormData: { ...formData },
					newAppliedChangesPublic: {},
					errorsByField: {},
				}),
			);

		if (!isEmpty(errorsByField)) {
			throw new UiModificationsFieldsValidationError('Transformation failed', {
				errors: prepareErrorsArray(errorsByField),
			});
		}

		setState({
			appliedChanges: changesToApply,
			formData: incomingFormData,
		});

		dispatch(
			collectFieldsAppliedChangesHistory({
				appId,
				changeId,
				fieldsChanges: newAppliedChangesPublic,
			}),
		);
	};
