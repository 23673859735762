import React from 'react';
import { lazy } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import type { Props } from '../../confluence-placeholder-template-experiment/confluence-dummy-link/experiments/placeholder-template-co-use/view';
import { ConfluenceDummyLinkProvider } from '../../confluence-placeholder-template-experiment/controllers/confluence-dummy-link-context';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const DummyConfluenceLinkCoUseLazy = lazy(() =>
	import(
		/* webpackChunkName: "async-dummy-confluence-link-with-conf" */ '../../confluence-placeholder-template-experiment/confluence-dummy-link/experiments/placeholder-template-co-use/view'
	).then((module) => module.DummyConfluenceLinkCoUse),
);

export default function Component(props: Props) {
	return (
		<Placeholder name="confluence-dummy-link-co-use" fallback={null}>
			<ConfluenceDummyLinkProvider>
				<ContextualAnalyticsData
					sourceName="confluencePlaceholderTemplateCoUse"
					sourceType={SCREEN}
					attributes={{ experiment: 'placeholderTemplateAsOnboardingTouchpoint' }}
				>
					<DummyConfluenceLinkCoUseLazy {...props} />
				</ContextualAnalyticsData>
			</ConfluenceDummyLinkProvider>
		</Placeholder>
	);
}
