import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
// eslint-disable-next-line import/order
import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import 'focus-visible';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadOnlyColorWrapper = styled.button({
	display: 'inline-block',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 3.75}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 3.75}px`,
	border: '2px solid transparent',
	boxSizing: 'border-box',
	borderRadius: '6px',
	borderColor: 'transparent',
	backgroundColor: 'transparent',
	padding: 0,
	outline: 'none',
	transition: 'box-shadow 0.2s cubic-bezier(0.47, 0.03, 0.49, 1.38)',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:focus.focus-visible': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		borderColor: token('color.border.focused', colors.B200),
	},
});
