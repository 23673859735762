import React from 'react';
import { styled } from '@compiled/react';
import AkGoalIcon from '@atlaskit/icon/core/goal';
import { Box, xcss, Flex } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { GoalIcon, normalizeGoalAppearance } from '@atlassian/goals/icon';
import { useIntl } from '@atlassian/jira-intl';
import type { Goal } from '../../common/types';
import { GoalName } from '../../common/ui/goal-name/index.tsx';
import { GoalsCount } from '../../common/ui/goals-count/index.tsx';
import messages from './messages';

type Props = { goals: Goal[]; goalCount: number; isEditable: boolean; showPlaceholder?: boolean };

export const GoalsCellContent = ({
	goals,
	goalCount,
	isEditable,
	showPlaceholder = false,
}: Props) => {
	const { formatMessage } = useIntl();

	if (goalCount > 0 && goals !== null && goals.length > 0) {
		return (
			<Wrapper data-testid="business-goal-picker.ui.goals-cell-content.wrapper">
				<Flex xcss={iconStyles}>
					<GoalIcon size="16" appearance={normalizeGoalAppearance(goals[0].status || 'PENDING')} />
				</Flex>
				<GoalName name={goals[0].name} />
				<GoalsCount goalCount={goalCount} />
			</Wrapper>
		);
	}

	let noGoalsContent;
	if (showPlaceholder) {
		// if placeholder is displayed we wouldn't show 'add goal'
		noGoalsContent = <Box xcss={noGoalsStyles}>{formatMessage(messages.noGoals)}</Box>;
	} else if (isEditable) {
		noGoalsContent = (
			<Box xcss={addGoalStyles}>
				<AkGoalIcon label="" spacing="spacious" color={token('color.icon')} />
				{formatMessage(messages.addGoal)}
			</Box>
		);
	}

	return (
		<Wrapper data-testid="business-goal-picker.ui.goals-cell-content.wrapper">
			{noGoalsContent}
		</Wrapper>
	);
};

const GOAL_PICKER_ADD_GOAL_DISPLAY = '--jira-goal-picker-cell-add-goal-display';

const addGoalStyles = xcss({
	display: `var(${GOAL_PICKER_ADD_GOAL_DISPLAY}, none)`,
	gap: 'space.100',
	alignItems: 'center',
	color: 'color.text.subtlest',
});

const noGoalsStyles = xcss({
	gap: 'space.100',
	alignItems: 'center',
	color: 'color.text.subtlest',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	cursor: 'pointer',
	width: '100%',
	height: '100%',
	paddingRight: token('space.100', '8px'),
	boxSizing: 'border-box',
	flexGrow: 1,
	alignItems: 'center',
	gap: `${token('space.100', '8px')}`,
	'&:hover': {
		[GOAL_PICKER_ADD_GOAL_DISPLAY]: 'flex',
	},
	'&:focus-within': {
		[GOAL_PICKER_ADD_GOAL_DISPLAY]: 'flex',
	},
});
const iconStyles = xcss({
	width: 'space.200',
});
