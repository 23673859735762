import React from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import type { ActionMeta } from '@atlassian/jira-common-components-picker/src/model';
import { useIntl } from '@atlassian/jira-intl';
import { SelectableFieldEditViewWithFieldOptionsFragment } from '@atlassian/jira-issue-selectable-field-edit-view/src/ui/index.tsx';
import type {
	SelectableValueOption,
	NullableOptions,
} from '@atlassian/jira-issue-selectable-field-edit-view/src/ui/types';
import type { singleSelect_issueSelectableFieldQuery } from '@atlassian/jira-relay/src/__generated__/singleSelect_issueSelectableFieldQuery.graphql';
import messages from './messages';
import type { SingleSelectEditViewProps } from './types';
import { transformToSelectableValueOption, transformToOnChangeShape } from './utils';

export const SingleSelectEditView = (props: SingleSelectEditViewProps) => {
	const { formatMessage } = useIntl();
	const { value, fieldId, fieldName, onChange } = props;

	const suggestionsData = useLazyLoadQuery<singleSelect_issueSelectableFieldQuery>(
		graphql`
			query singleSelect_issueSelectableFieldQuery(
				$id: ID!
				$filterById: JiraFieldOptionIdsFilterInput
			) {
				...ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment
					@arguments(id: $id, filterById: $filterById)
			}
		`,
		{ id: fieldId, filterById: props.filterOptionsById },
		{ fetchPolicy: 'store-only' },
	);

	const handleOnChange = (
		newValue: NullableOptions,
		_actionMeta: ActionMeta<SelectableValueOption>,
	): void => {
		onChange?.(transformToOnChangeShape(newValue));
	};

	return (
		<SelectableFieldEditViewWithFieldOptionsFragment
			{...props}
			isClearable
			placeholder={
				fieldName
					? formatMessage(messages.placeholder, {
							fieldName,
						})
					: undefined
			}
			value={value ? [transformToSelectableValueOption(value)] : undefined}
			onChange={handleOnChange}
			fieldOptionsFragmentRef={suggestionsData}
		/>
	);
};
