import React from 'react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { AnalyticsSource } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import ViewTracker from '@atlassian/jira-analytics-web-react/src/components/view-tracker.tsx';
import { PRETTY } from '@atlassian/jira-common-constants/src/jira-settings.tsx';
import type { IntlShape } from '@atlassian/jira-intl';
import type { TimeTrackingConfig } from '@atlassian/jira-issue-shared-types/src/common/types/jira-settings-type.tsx';
import type { TimeTrackingState } from '@atlassian/jira-issue-shared-types/src/common/types/time-tracking-type.tsx';
import type { FormValues } from '@atlassian/jira-issue-view-common-types/src/log-time-modal-type';
import type { MediaContext } from '@atlassian/jira-issue-view-common-types/src/media-context-type';
import isTimeStringValidOrBlank from '@atlassian/jira-issue-view-common-utils/src/time-string/is-time-string-valid-or-blank/index.tsx';
import LogTimeModal from '@atlassian/jira-issue-view-common/src/component/log-time-modal';
import type { LogTimeValidator } from '@atlassian/jira-issue-view-common/src/component/log-time-modal/view.tsx';
import { MODAL } from '@atlassian/jira-product-analytics-bridge';
import { emptyAdfObject } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import { timeTrackingFormatter } from '@atlassian/jira-time-tracking-formatter/src/main.tsx';
import messages from './messages';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ActivityProvider = any;

export type OwnProps = {
	activityProvider: ActivityProvider;
	externalId: string;
	intl: IntlShape;
};

export type StateProps = {
	isDone: boolean;
	isSaving: boolean;
	isRollingUpData: boolean;
	isClassicProject: boolean;
	shouldDisplayRollUpDataControl: boolean;
	mediaContext: MediaContext;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mentionProvider: any | null;
	// eslint-disable-next-line jira/react/handler-naming
	mentionEncoder?: (arg1: string) => string | undefined;
	timeTrackingConfiguration: TimeTrackingConfig;
	timeTracking: TimeTrackingState;
	rolledUpTimeTracking: TimeTrackingState;
	timeZone: string | undefined;
};

export type DispatchProps = {
	onSubmit: (values: FormValues, analyticsEvent: UIAnalyticsEvent) => void;
	onCancel: () => void;
	onToggleRollingUpData: (arg1: boolean) => void;
};

export type Props = OwnProps & StateProps & DispatchProps;

/**
 * In the Add worklog modal, the form is valid if the "Date started" is valid, and either:
 * - "Time spent" AND "Time remaining" are valid non-empty values
 * - "Time spent" OR "Time remaining" is a valid non-empty value, and the other is empty
 *
 * This is because the Add worklog modal allows the user to set only a "Time remaining" value,
 * which means that the server value for Time remaining should be updated without actually
 * creating a new worklog.
 */
export const validator: LogTimeValidator = ({ timeLogged, timeRemaining, dateStarted }) => {
	const hasTimeLoggedOrTimeRemaining = !!timeLogged || !!timeRemaining;
	return (
		hasTimeLoggedOrTimeRemaining &&
		(isTimeStringValidOrBlank(timeLogged) || timeLogged === '0') &&
		isTimeStringValidOrBlank(timeRemaining) &&
		!!dateStarted
	);
};

function AddModal(props: Props) {
	const {
		timeTracking: timeTrackingState,
		rolledUpTimeTracking,
		timeTrackingConfiguration,
		intl,
	} = props;
	const { timeSpentSeconds, remainingEstimateSeconds, originalEstimateSeconds } = timeTrackingState;

	let timeRemaining = '';
	if (typeof remainingEstimateSeconds === 'number') {
		// At this time we need to force the value into an english formatted
		// time string eg: `1w 1d 1h 1m` as that is the only format supported
		// on entry, future changes will support localised values.
		const intlForTimeTrackingInput = {
			...intl,
			formatMessage: ({ defaultMessage }: { defaultMessage?: string }) => defaultMessage || '',
		};
		timeRemaining = timeTrackingFormatter(
			remainingEstimateSeconds,
			{
				workingHoursPerDay: timeTrackingConfiguration.hoursPerDay,
				workingDaysPerWeek: timeTrackingConfiguration.daysPerWeek,
				timeFormat: timeTrackingConfiguration.format || PRETTY,
				defaultUnit: timeTrackingConfiguration.defaultUnit,
			},
			intlForTimeTrackingInput,
		);
	}

	const initialValue = {
		timeLogged: '',
		timeRemaining,
		timeRemainingSeconds: remainingEstimateSeconds,
		workDescription: emptyAdfObject,
		dateStarted: null,
	};
	const timeTracking = {
		loggedTime: timeSpentSeconds,
		remainingTime: remainingEstimateSeconds,
	};
	return (
		<>
			<LogTimeModal
				initialValue={initialValue}
				timeTracking={timeTracking}
				rolledUpTimeTracking={{
					loggedTime: rolledUpTimeTracking.timeSpentSeconds,
					remainingTime: rolledUpTimeTracking.remainingEstimateSeconds,
				}}
				estimatedTime={originalEstimateSeconds}
				rolledUpEstimatedTime={rolledUpTimeTracking.originalEstimateSeconds}
				config={timeTrackingConfiguration}
				header={intl.formatMessage(messages.title)}
				timeZone={props.timeZone}
				isCollapsible
				isDone={props.isDone}
				isWaiting={props.isSaving}
				shouldUpdateDate
				validator={validator}
				onSubmit={props.onSubmit}
				onCancel={props.onCancel}
				onWorkDescriptionChangeFailure={noop}
				mediaContext={props.mediaContext}
				mentionProvider={props.mentionProvider || {}}
				mentionEncoder={props.mentionEncoder}
				activityProvider={props.activityProvider}
				externalId={props.externalId}
				shouldDisplayRollUpDataControl={props.shouldDisplayRollUpDataControl}
				isRollingUpData={props.isRollingUpData}
				onToggleRollingUpData={props.onToggleRollingUpData}
				isClassicProject={props.isClassicProject}
			/>
			<ViewTracker />
		</>
	);
}

export default AnalyticsSource('addWorklog', MODAL)(AddModal);
