import { trackFetchErrors } from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { PACKAGE_NAME } from '../../../../common/constants';
import { FETCH_STATUS } from '../../constants';
import type { State } from '../../types';
import { remoteGet, type RemoteGetParams } from '../remote-get';

export const fetchConfiguration =
	(): Action<State, RemoteGetParams> =>
	async ({ getState, setState }, { cloudId }) => {
		const { configuration: currentConfiguration, fetchStatus: currentFetchStatus } = getState();

		if (currentConfiguration || currentFetchStatus === FETCH_STATUS.inProgress) {
			return;
		}

		setState({ fetchStatus: FETCH_STATUS.inProgress });

		try {
			const configuration = await remoteGet({ cloudId });
			setState({
				fetchStatus: undefined,
				configuration,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				fetchStatus: error.statusCode === 403 ? FETCH_STATUS.forbiddenError : FETCH_STATUS.error,
			});
			trackFetchErrors({
				error,
				id: 'fetchConfiguration',
				packageName: PACKAGE_NAME,
				sendToPrivacyUnsafeSplunk: true,
			});
			throw error;
		}
	};
