import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { useAdjustedField } from '@atlassian/jira-issue-adjustments/src/controllers/issue-adjustments/main.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { FieldId } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/types/field.tsx';
import type { FieldType } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/views/types.tsx';
import { changeSource } from '../../../../common/constants';
import type { Field } from '../../../../common/types';
import type { HookActions, SaveValueAction, UseFieldHook } from './types';
import { useSetOverrides } from './use-set-overrides';

type LoadedFieldProcessorProps<ValueType, Meta, Result, IdShape> = {
	field: Field;
	actions: HookActions<ValueType, Meta, Result, IdShape>;
	issueKey: IssueKey;
};

const LoadedFieldProcessor = <ValueType, Meta, Result, IdShape>({
	field,
	actions,
	issueKey,
}: LoadedFieldProcessorProps<ValueType, Meta, Result, IdShape>) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [state, { initializeField, clearAppliedValue, setFieldValue }] = useAdjustedField(
		field.fieldId,
	);
	useSetOverrides({ field, issueKey, state });
	// saveValueRef reasoning: saveValue has no stable reference, and we don't want to invoke our useEffect based on changing ref
	// but still wants to call the latest version of it when needed
	const saveValueRef = useRef<SaveValueAction<ValueType, Meta, Result, IdShape>>(actions.saveValue);
	saveValueRef.current = actions.saveValue;

	useLayoutEffect(() => {
		const { metadata, ...rest } = field;

		initializeField({ field: rest, metadata: { fieldRenderer: metadata.renderer } });
		// Having the deps array empty makes initializeField run only once at component initialization
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (state.value !== undefined) {
			const analyticsEvent = createAnalyticsEvent({});
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			saveValueRef.current(state.value, changeSource as Meta, analyticsEvent);
			// Once we have applied the value, we clear it from the state so we don't apply it again
			// in the future and overwrite whatever the user has changed between now and then
			clearAppliedValue(field.fieldId);
		}
	}, [clearAppliedValue, createAnalyticsEvent, field.fieldId, state.value]);

	useEffect(() => {
		// We don't want to update the store when there is an ongoing process of UIM values applying
		const areOngoingUIMChanges = state.value !== undefined;
		if (areOngoingUIMChanges) {
			return;
		}

		/**
		 * Updates formData on the Issue View realtime and background refreshes.
		 * setTimeout - prevent race condition between processChange and setFieldValue, ensures that setFieldValue is called after processChange.
		 * So, we don't lose the trigerring changes by processChange.
		 */
		setTimeout(
			() =>
				setFieldValue({
					fieldId: field.fieldId,
					fieldType: field.fieldType,
					fieldValue: field.value,
				}),
			0,
		);
	}, [field.fieldId, field.fieldType, field.value, setFieldValue, state.value]);

	return null;
};

type Props<ValueType, Meta, Result, IdShape> = {
	issueKey: IssueKey;
	issueId: IssueId;
	useField: UseFieldHook<ValueType, Meta, Result, IdShape>;
	fieldId: FieldId;
	fieldType: FieldType;
};

export const FieldProcessor = <ValueType, Meta, Result, IdShape>({
	issueKey,
	issueId,
	useField,
	fieldId,
	fieldType,
}: Props<ValueType, Meta, Result, IdShape>) => {
	const { field, actions, loading } = useField({ issueKey, fieldId, fieldType, issueId });

	return loading === false && field ? (
		<LoadedFieldProcessor field={field} issueKey={issueKey} actions={actions} />
	) : null;
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { SaveValueAction } from './types';
