import { parentCandidatesForExistingIssueQuery } from './fragments';

export const PARENT_CANDIDATES_FOR_EXISTING_ISSUES_QUERY_WITH_INFO =
	'issueParentCandidatesForExistingIssuesQueryWithInfo';

export const buildParentCandidatesForExistingIssueQuery = () => `
    query jira_parenting_${PARENT_CANDIDATES_FOR_EXISTING_ISSUES_QUERY_WITH_INFO}($issueKey: String!, $cloudId: ID!, $searchTerm: String, $excludeDone: Boolean, $first: Int)  {
        ${parentCandidatesForExistingIssueQuery()}
    }
`;
