import React, { useCallback } from 'react';
import {
	dismissFlag as createDismissFlag,
	type FlagComponentProps,
	ErrorFlag,
	useFlagService,
} from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { generateId } from '../../utils';
import { messages } from './messages';

export const useInviteErrorFlag = () => {
	const { showFlag, dismissFlag } = useFlagService();
	return useCallback(() => {
		const id = `error-${generateId()}`;
		const dismiss = () => dismissFlag(createDismissFlag(id));

		showFlag({
			id,
			render: (props) => <InviteErrorFlag {...props} id={id} onDismiss={dismiss} />,
		});
	}, [dismissFlag, showFlag]);
};

export type Props = {
	id: FlagComponentProps['id'];
	onDismiss: FlagComponentProps['onDismissed'];
};

const JIRA_SUPPORT_LINK = 'https://support.atlassian.com/contact/';

export const InviteErrorFlag = ({ onDismiss, ...props }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<ErrorFlag
			{...props}
			onDismissed={onDismiss}
			title={formatMessage(messages.title)}
			description={
				<>
					<p>{formatMessage(messages.description)}</p>
				</>
			}
			actions={[
				{
					content: formatMessage(messages.action),
					href: JIRA_SUPPORT_LINK,
					target: '_blank',
				},
			]}
		/>
	);
};
