import type { TagColor } from '@atlaskit/tag';
import epicsCache from '@atlassian/jira-cache/src/services/epics/index.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import {
	MAP_CLASSIC_COLOR_TO_AGILE_COLOR,
	MAP_FROM_SERVER_COLOR,
	MAP_AGILE_COLOR_TO_AK_COLOR,
	MAP_COLOR_TO_AK_COLOR,
} from '@atlassian/jira-issue-epic-color/src/common/constants.tsx';
import type {
	AgileColor,
	Color,
	ServerColor,
} from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import { getOptionColorElemPatch } from '@atlassian/jira-issue-internal-fields/src/epic/view';
import type { TransformerContext } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import type { BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import type { AKColorKey, EpicLink } from '@atlassian/jira-shared-types/src/rest/jira/epic.tsx';
import messages from './messages';
import type { RestAPIResponse, Suggestion, SuggestionsGroups } from './types';

const getColor = (epic: EpicLink) => {
	// @ts-expect-error - TS2339 - Property 'colorKey' does not exist on type 'EpicLink'.
	const colorKey = epic.color ? epic.color.key : MAP_CLASSIC_COLOR_TO_AGILE_COLOR[epic.colorKey];
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return colorKey ? MAP_AGILE_COLOR_TO_AK_COLOR[colorKey as AgileColor] : null;
};

const toItem = (epic: EpicLink, baseUrl: BaseUrl): Suggestion => ({
	content: epic.summary,
	value: epic.key,
	isDone: epic.done,
	color: getColor(epic),
	href: `${baseUrl}/browse/${epic.key}`,
	fromCache: epic.fromCache,
});

export const transformFromStateValue = (
	stateValue: EpicLink | null,
	{ baseUrl }: Partial<TransformerContext>,
): // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
Suggestion | null => stateValue && toItem(stateValue, baseUrl);

const MAX_RESULTS = 5;

export const validateCacheItems = (rest: EpicLink[], cache: EpicLink[]): EpicLink[] =>
	cache.map((item) => {
		const duplicatedItem = rest.find((restItem) => restItem.key === item.key);
		if (duplicatedItem) {
			epicsCache.update(item.key, duplicatedItem);
			return duplicatedItem;
		}
		return item;
	});

export const transformCacheEntries = (epics: EpicLink[], excludeDone: boolean): EpicLink[] =>
	epics
		.filter(
			(item) =>
				!(excludeDone && item.done) && item.key && item.name && item.color && item.color.key,
		)
		.slice(-1 * MAX_RESULTS)
		.reverse();

const getAtlasKitColor = (epic: EpicLink): TagColor => {
	if (epic.issueColor) {
		return MAP_COLOR_TO_AK_COLOR[MAP_FROM_SERVER_COLOR[epic.issueColor.key]];
	}
	// Return default color if we can't find the issue color
	const colors = Object.values(MAP_AGILE_COLOR_TO_AK_COLOR);
	return colors[0];
};

export const toSuggestionsItem = (epic: EpicLink, fromCache = false): Suggestion => {
	const atlasKitColor = getAtlasKitColor(epic);

	// Return default color if we can't find the issue color
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const defaultColour = Object.keys(MAP_FROM_SERVER_COLOR)[0] as ServerColor;

	const color: Color = MAP_FROM_SERVER_COLOR[epic.issueColor?.key || defaultColour];

	return {
		color: atlasKitColor,
		content: epic.summary,
		value: epic.key,
		id: epic.id,
		description: epic.key,
		elemBefore: getOptionColorElemPatch(color),
		filterValues: [epic.summary, epic.key],
		isDone: epic.done,
		fromCache,
	};
};

export const transformToStateValue = (value: Suggestion | null): EpicLink | null => {
	if (!value) return null;

	const colorKeys = Object.keys(MAP_AGILE_COLOR_TO_AK_COLOR);
	const colorKey =
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		colorKeys.find((key) => MAP_AGILE_COLOR_TO_AK_COLOR[key as AgileColor] === value.color) ||
		colorKeys[0];

	return {
		name: value.content,
		summary: value.content,
		key: value.value,
		done: value.isDone,
		id: value.id,
		color: {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			key: colorKey as AKColorKey,
		},
		fromCache: value.fromCache,
	};
};

export const transformSuggestionsFromPublicAPIAndCache = (
	response?: RestAPIResponse,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	cache: EpicLink[],
	intl: IntlShape,
): SuggestionsGroups => {
	const epicLists = response?.values || [];

	const cacheLists = validateCacheItems(epicLists, cache);

	return [
		{
			heading: intl.formatMessage(messages.recent),
			items: cacheLists.map((item) => toSuggestionsItem(item, true)),
		},
		{
			heading: intl.formatMessage(messages.allEpics),
			items: epicLists.map((item) => toSuggestionsItem(item, false)),
		},
	];
};
