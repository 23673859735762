import type { ReactElement } from 'react';
// eslint-disable-next-line camelcase
import { withStoreForIssueViewShortcutActions_DO_NOT_USE } from '@atlassian/jira-issue-view-common-views/src/store-provider';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { openDeleteIssueModal } from '@atlassian/jira-issue-view-store/src/actions/issue-delete-actions';
import DeleteIssueButtonItemView from './button-item';
import DeleteIssueView from './main';
import messages from './messages';
import type { OwnProps, Props, DispatchProps } from './types';

const connectToProps = (Component: (arg1: Props) => ReactElement) =>
	connect(
		null,
		(dispatch): DispatchProps => ({
			onDeleteIssueClick: () => {
				dispatch(openDeleteIssueModal());
			},
		}),
	)(Component);

export default withStoreForIssueViewShortcutActions_DO_NOT_USE<OwnProps>(
	// @ts-expect-error - TS2345 - Argument of type 'ComponentClass<Omit<ShortcutComponentProps & StateProps & DispatchProps, "onDeleteIssueClick">, any> & { ...; }' is not assignable to parameter of type 'ComponentType<ShortcutComponentProps>'.
	connectToProps(DeleteIssueView),
);

export const deleteIssueLabel = messages.delete;

export const DeleteIssueButtonItem = withStoreForIssueViewShortcutActions_DO_NOT_USE<OwnProps>(
	// @ts-expect-error - TS2345 - Argument of type 'ComponentClass<Omit<ShortcutComponentProps & StateProps & DispatchProps, "onDeleteIssueClick">, any> & { ...; }' is not assignable to parameter of type 'ComponentType<ShortcutComponentProps>'.
	connectToProps(DeleteIssueButtonItemView),
);
