// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	viewInSearchButton: {
		id: 'issue.views.common.child-issues-panel.child-issues-limit-panel.view-in-search-button',
		defaultMessage: 'View in search',
		description: 'Button label view in search',
	},
	withTimetrackingChildIssuesLimitDescription: {
		id: 'issue.views.common.child-issues-panel.child-issues-limit-panel.with-timetracking-child-issues-limit-description-with-limit',
		defaultMessage:
			"The issue view can only display up to {limit} child issues. You can still add and bulk edit them from here, but you'll have to view them in search and won't be able to include them in time tracking. {learnMoreLink}",
		description: 'description for children limit panel on issues with timetracking included',
	},
	withoutTimetrackingChildIssuesLimitDescription: {
		id: 'issue.views.common.child-issues-panel.child-issues-limit-panel.without-timetracking-child-issues-limit-description-with-limit',
		defaultMessage:
			"The issue view can only display up to {limit} child issues. You can still add and bulk edit them from here, but you'll have to view them in search. {learnMoreLink}",
		description: 'description for children limit panel on issues without timetracking included',
	},
	childIssuesLimitTitle: {
		id: 'issue.views.common.child-issues-panel.child-issues-limit-panel.child-issues-limit-title',
		defaultMessage: 'View child issues in search',
		description: 'title for children limit panel',
	},
	learnMoreAboutChildIssuesLink: {
		id: 'issue.views.common.child-issues-panel.child-issues-limit-panel.learn-more-about-child-issues-link',
		defaultMessage: 'Learn more about child issues',
		description: 'Text for the link to the learn more in-product child issues help',
	},
});
