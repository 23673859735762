import { defaultLocale } from '@atlassian/jira-platform-utils-date-fns/src/constants.tsx';
import { BRANCH_PANEL_DATA_TYPE } from '../../../../common/model/constants';
import { INITIALIZE_APP_PROPS, type Action } from '../../actions/app';
import type { AppState } from './types';

export const defaultState: AppState = {
	baseUrl: '',
	issueId: '',
	issueKey: '',
	locale: defaultLocale,
	selectedTab: BRANCH_PANEL_DATA_TYPE,
	showRemoteLinks: false,
	projectAri: '',
	accountId: '',
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: AppState = defaultState, action: Action): AppState => {
	switch (action.type) {
		case INITIALIZE_APP_PROPS:
			return {
				...state,
				...action.payload,
			};
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
