import get from 'lodash/get';
import { stringify } from 'query-string';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { SaveField } from '@atlassian/jira-issue-field-base/src/common/types.tsx';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import getXsrfToken from '@atlassian/jira-platform-xsrf-token';
import {
	type IssueKey,
	type IssueId,
	toIssueKey,
} from '@atlassian/jira-shared-types/src/general.tsx';
import type { ClassicStoryPointValue } from '../common/types';

type Props = {
	issueKey: IssueKey;
	issueId: IssueId;
	fieldKey: string;
	onSuccess?: (newValue: ClassicStoryPointValue) => void;
};

export const saveField: SaveField<ClassicStoryPointValue, undefined> = async (
	issueId,
	fieldKey,
	newValue,
	_baseUrl,
) =>
	performPostRequest('/secure/AjaxIssueAction.jspa?decorator=none', {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: stringify({
			[fieldKey]: newValue,
			issueId,
			skipScreenCheck: true,
			atl_token: getXsrfToken(),
			singleFieldEdit: true,
		}),
	});

export const useClassicStoryPointField = ({ issueKey, issueId, fieldKey, onSuccess }: Props) => {
	// useFieldConfig now returns an object { value, loading } instead of just the fieldConfig value
	// if possible when touching this, try and refactor to pass the new object instead of just the value
	const [{ value: fieldConfig }] = useFieldConfig(issueKey, fieldKey);
	const fieldType = get(fieldConfig, 'schema.type', 'number');
	const saveClassicField = (
		_issueKey: IssueKey,
		_fieldKey: string,
		newValue: ClassicStoryPointValue,
		baseUrl: string,
	) => saveField(toIssueKey(issueId.toString()), fieldKey, newValue, baseUrl);

	const [{ value, error }, { saveValue, resetError }] = useEditField<
		ClassicStoryPointValue,
		null,
		undefined
	>({
		fieldKey,
		issueKey,
		fieldType,
		initialValue: null,
		saveField: saveClassicField,
		onSuccess,
		analyticsFieldTypeAlias: 'classicstorypoint',
	});

	return [
		{ value, error, fieldConfig },
		{ saveValue, resetError },
	] as const;
};
