import get from 'lodash/get';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { PEOPLE_CF_TYPE } from '@atlassian/jira-platform-field-config';
import type { Props, UserValue } from './types';

export const usePeopleCustomField = ({
	issueKey,
	fieldKey,
	onSubmit,
	onSuccess,
	onFailure,
}: Props) => {
	const [{ value: fieldConfig }] = useFieldConfig(issueKey, fieldKey);
	const fieldType = get(fieldConfig, 'schema.type', PEOPLE_CF_TYPE);

	const [{ value, error }, { saveValue, resetError }] = useEditField<UserValue, null>({
		fieldKey,
		issueKey,
		fieldType,
		initialValue: null,
		onSubmit,
		onSuccess,
		onFailure,
	});

	return [
		{ value, error, fieldConfig },
		{ saveValue, resetError },
	] as const;
};
