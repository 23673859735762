import React from 'react';
import { lazy } from 'react-loosely-lazy';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type { Context } from './common/types';
import type OptInBackOnboardingType from './ui/onboarding/main.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const AsyncLazyOptInBackOnboarding = lazy<typeof OptInBackOnboardingType>(
	() => import(/* webpackChunkName: "async-issue-details-onboarding-modal" */ './ui/onboarding'),
);
export const AsyncOptInBackOnboarding = (props: Context) => (
	<ErrorBoundary id="issue-onboarding-modal-lazy" packageName="jira-issue-onboarding-modal">
		<Placeholder name="async-issue-details-onboarding-modal" fallback={<></>}>
			<AsyncLazyOptInBackOnboarding {...props} />
		</Placeholder>
	</ErrorBoundary>
);
