import React, {
	type ReactNode,
	useCallback,
	useEffect,
	type MouseEvent,
	useState,
	useRef,
} from 'react';
import noop from 'lodash/noop';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, {
	DropdownItemGroup,
	type CustomTriggerProps,
	type OnOpenChangeArgs,
} from '@atlaskit/dropdown-menu';
import AddIcon from '@atlaskit/icon/core/migration/add';
import AppIcon from '@atlaskit/icon/core/migration/app--addon';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import { SpotlightPulse } from '@atlaskit/onboarding';
import { Inline } from '@atlaskit/primitives';
import AkToolTip from '@atlaskit/tooltip';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { EcosystemContentPanel } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/ecosystem.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useLoomOnboardingState } from '../loom-record-item-button/ui/loom-onboarding-state';
import QuickActionsChangeboarding, {
	type SpotlightTargetCoordinates,
} from '../quick-actions-changeboarding/changeboarding-message';
import { useShowSpotlight } from '../quick-actions-changeboarding/use-show-spotlight';
import { messages } from './messages';

type Props = {
	actions: ReactNode[];
	apps: ReactNode;
	availableAddons?: EcosystemContentPanel[];
	setMenuOpen: (menuName: string, isOpen: boolean) => void;
	openMenu: string | null;
	quickActionListViewContainerRef?: { current: HTMLDivElement | null };
	canInstallAddons?: boolean;
};

export const QuickAddItemsCompact = (props: Props) => {
	const {
		actions,
		apps,
		availableAddons,
		setMenuOpen,
		openMenu,
		quickActionListViewContainerRef,
		canInstallAddons,
	} = props;
	const { formatMessage } = useIntl();
	const add = formatMessage(messages.add);
	const appsMessage = formatMessage(messages.apps);
	const isLoomOnboardingFFEnabled = fg('jira_m25_onboarding_spotlights');
	const [loomOnboardingState, loomOnboardingStateActions] = useLoomOnboardingState();
	const dropdownRef = useRef<HTMLElement>(null);
	const spotlightRef = useRef<HTMLElement>(null);
	const [isSpotlightActive, setIsSpotlightActive] = useState(false);
	const [spotlightTargetCoordinates, setSpotlightTargetCoordinates] =
		useState<SpotlightTargetCoordinates | null>(null);
	const isQuickActionsGA = fg('quick_actions_menu_ga');

	const { shouldShowSpotlight, updateShouldShowSpotlight } = isQuickActionsGA
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useShowSpotlight()
		: { shouldShowSpotlight: false, updateShouldShowSpotlight: noop };

	const filteredAddons =
		availableAddons &&
		availableAddons.filter(
			(addon) =>
				!(
					addon.moduleKey.includes('linked-goals-content-panel') &&
					expVal('native_goals_on_issue_view', 'isGoalsFieldEnabled', false)
				),
		);

	const availableApps = filteredAddons && filteredAddons.length >= 1;
	const plusIconOnlyQuickAddButton = expVal(
		'issue_view_quick_actions_revamp',
		'plusIconOnlyQuickAddButton',
		false,
	);

	const handleClick = useCallback(() => {
		setMenuOpen('addButton', false);
	}, [setMenuOpen]);

	useEffect(() => {
		const node = quickActionListViewContainerRef?.current;
		if (node) {
			node.addEventListener('click', handleClick);
		}

		return () => {
			if (node) {
				node.removeEventListener('click', handleClick);
			}
		};
	}, [quickActionListViewContainerRef, handleClick]);

	useEffect(() => {
		if (isQuickActionsGA && openMenu === 'addButton' && dropdownRef.current) {
			// used timeout here as dropdown takes some time to take it's final position, maybe due to animation
			setTimeout(() => {
				const dropdownRect = dropdownRef.current?.getBoundingClientRect();
				if (dropdownRect) {
					const { top, left, right, bottom } = dropdownRect;
					setSpotlightTargetCoordinates({
						top,
						left,
						right,
						bottom,
					});
				}
			});
		}
	});

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClickWithAnalytics = useCallback(
		(originalOnClick?: (event: MouseEvent<HTMLElement>) => void, buttonState?: string) =>
			(event: MouseEvent<HTMLElement>) => {
				if (originalOnClick) {
					originalOnClick(event);
				}

				if (buttonState) {
					fireUIAnalytics(
						createAnalyticsEvent({}),
						'button clicked',
						`quickActionButtonRevamp${buttonState}`,
					);
				}
			},
		[createAnalyticsEvent],
	);
	const toggleMenuOpen: (menuName: string) => (params: OnOpenChangeArgs) => void = useCallback(
		(menuName: string) =>
			({ isOpen, event }: OnOpenChangeArgs) => {
				if (isQuickActionsGA && menuName === 'addButton' && shouldShowSpotlight) {
					if (!isOpen) {
						updateShouldShowSpotlight();
					}
					if (
						spotlightRef.current &&
						event.target &&
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						spotlightRef.current.contains(event.target as Node)
					) {
						setIsSpotlightActive(false);
						return;
					}
					setIsSpotlightActive(isOpen);
				}

				// This code will be removed as part of https://hello.jira.atlassian.cloud/browse/CTIA-2721
				// when Loom onboarding is complete.
				if (!isLoomOnboardingFFEnabled) {
					setMenuOpen(menuName, isOpen);
				}

				// Handle spotlight closing if ep message is active
				if (loomOnboardingState.isLoomOnboardingEPMessageActive && !isOpen) {
					// Call the ep coordination client stop method
					if (loomOnboardingState.loomOnboardingEPMessageStop) {
						loomOnboardingState.loomOnboardingEPMessageStop();
					}
					loomOnboardingStateActions.updateIsLoomOnboardingEPMessageActive(false);
					return;
				}

				setMenuOpen(menuName, isOpen);
			},
		[
			isQuickActionsGA,
			shouldShowSpotlight,
			isLoomOnboardingFFEnabled,
			loomOnboardingState,
			setMenuOpen,
			updateShouldShowSpotlight,
			loomOnboardingStateActions,
		],
	);

	const renderDropdownTrigger = ({ triggerRef, onClick, ...rest }: CustomTriggerProps) => {
		if (plusIconOnlyQuickAddButton && !isQuickActionsGA) {
			return (
				<IconButton
					{...rest}
					icon={() => <AddIcon color="currentColor" spacing="none" label="" LEGACY_size="small" />}
					ref={triggerRef}
					label={formatMessage(messages.buttonAddContent)}
					onClick={onClickWithAnalytics(onClick, '+')}
				/>
			);
		}
		if (isQuickActionsGA) {
			return (
				<SpotlightPulse radius={3} pulse={shouldShowSpotlight}>
					<Button
						{...rest}
						label={formatMessage(messages.buttonAddContent)}
						ref={triggerRef}
						onClick={onClickWithAnalytics(onClick, 'Add')}
						iconBefore={
							<AddIcon color="currentColor" spacing="none" label="" LEGACY_size="small" />
						}
					>
						{add}
					</Button>
				</SpotlightPulse>
			);
		}

		return (
			<Button
				{...rest}
				label={formatMessage(messages.buttonAddContent)}
				ref={triggerRef}
				onClick={onClickWithAnalytics(onClick, 'Add')}
				iconBefore={<AddIcon color="currentColor" spacing="none" label="" LEGACY_size="small" />}
			>
				{add}
			</Button>
		);
	};

	return (
		<>
			<Inline space="space.100">
				<DropdownMenu
					trigger={renderDropdownTrigger}
					testId="issue-view-foundation.quick-add.quick-add-items-compact.add-button-dropdown"
					shouldRenderToParent
					onOpenChange={toggleMenuOpen('addButton')}
					isOpen={openMenu === 'addButton'}
				>
					<DropdownItemGroup ref={isQuickActionsGA ? dropdownRef : undefined}>
						{actions}
					</DropdownItemGroup>
				</DropdownMenu>
				{(availableApps || canInstallAddons) && (
					<DropdownMenu
						trigger={({ triggerRef, onClick, ...rest }) => (
							<AkToolTip
								content={
									availableApps
										? formatMessage(messages.buttonAddApps)
										: formatMessage(messages.buttonAddAppsNoApps)
								}
							>
								{availableApps || isQuickActionsGA ? (
									<Button
										{...rest}
										label={formatMessage(messages.buttonAddApps)}
										ref={triggerRef}
										onClick={onClickWithAnalytics(onClick, 'Apps')}
										iconBefore={
											<AppIcon color="currentColor" label="" spacing="none" LEGACY_size="small" />
										}
									>
										{appsMessage}
									</Button>
								) : (
									<IconButton
										{...rest}
										ref={triggerRef}
										label={formatMessage(messages.buttonAddAppsNoApps)}
										onClick={onClickWithAnalytics(onClick, '...')}
										icon={() => (
											<ShowMoreHorizontalIcon
												color="currentColor"
												spacing="spacious"
												label=""
												LEGACY_size="small"
											/>
										)}
									/>
								)}
							</AkToolTip>
						)}
						testId="issue-view-foundation.quick-add.quick-add-items-compact.apps-button-dropdown"
						onOpenChange={toggleMenuOpen('appsButton')}
						isOpen={openMenu === 'appsButton'}
					>
						{apps}
					</DropdownMenu>
				)}
			</Inline>
			{isQuickActionsGA && isSpotlightActive && (
				<QuickActionsChangeboarding
					targetCoordinates={spotlightTargetCoordinates}
					ref={spotlightRef}
				/>
			)}
		</>
	);
};

export default QuickAddItemsCompact;
