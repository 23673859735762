import React, { useCallback, useMemo, useState, type FC } from 'react';
import { styled } from '@compiled/react';
import LikeIcon from '@atlaskit/icon/core/migration/thumbs-up--like';
import Lozenge from '@atlaskit/lozenge';
import { N500, N200, N70 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useFeedbackCollectorActions } from '@atlassian/jira-feedback-collector/src/controllers/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { FeedbackButton } from '../feedback-button';
import messages from './messages.tsx';

export const RELATED_RESOURCES_AI_EXPERIENCE = 'issue-ai-related-resources';
export const INTELLIGENT_WORK_BREAKDOWN_AI_EXPERIENCE = 'issue-ai-intelligent-work-breakdown';

export type Props = {
	isDisabled?: boolean;
	feedbackFormEntrypointId: string;
	feedbackCollectorId: string;
	aiExperience:
		| typeof RELATED_RESOURCES_AI_EXPERIENCE
		| typeof INTELLIGENT_WORK_BREAKDOWN_AI_EXPERIENCE;
	onFeedbackClicked: (aiFeedbackResult: 'up' | 'down') => void;
	onFeedbackSent: (aiFeedbackResult: 'up' | 'down') => void;
};

export const FeedbackForm: FC<Props> = ({
	isDisabled = false,
	aiExperience,
	feedbackFormEntrypointId,
	feedbackCollectorId,
	onFeedbackClicked,
	onFeedbackSent,
}) => {
	const [isFeedbackDisabled, setIsFeedbackDisabled] = useState(isDisabled);
	const [wasFeedbackSent, setWasFeedbackSent] = useState(false);

	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);

	const { openFeedbackCollector } = useFeedbackCollectorActions();
	const { formatMessage } = useIntl();

	const handleFeedbackSent = useCallback((wasSent: boolean) => {
		setWasFeedbackSent(wasSent);
		setIsFeedbackDisabled(wasSent);
	}, []);

	const getSentiment = useCallback((isPositive: boolean) => (isPositive ? 'good' : 'bad'), []);

	const getFeedbackContext = useCallback(
		(isPositive: boolean) => ({
			entrypointId: feedbackFormEntrypointId,
			feedbackCollectorId,
			feedbackContext: [
				{ id: 'sentiment', value: getSentiment(isPositive) },
				{ id: 'product', value: projectType ?? '' },
				{ id: 'ai-experience', value: aiExperience },
			],
			// Doing this allows for feedback to be reenabled if cancel button is clicked on feedback form
			onSubmit() {
				handleFeedbackSent(true);
				const aiFeedbackResult = isPositive ? 'up' : 'down';
				onFeedbackSent(aiFeedbackResult);
			},
			onClose() {
				handleFeedbackSent(false);
			},
		}),
		[
			feedbackFormEntrypointId,
			feedbackCollectorId,
			getSentiment,
			projectType,
			aiExperience,
			handleFeedbackSent,
			onFeedbackSent,
		],
	);

	const handleOnClick = useCallback(
		(isPositive: boolean) => {
			if (!isFeedbackDisabled) {
				setIsFeedbackDisabled(true);
				openFeedbackCollector(getFeedbackContext(isPositive));
			}
			const aiFeedbackResult = isPositive ? 'up' : 'down';
			onFeedbackClicked(aiFeedbackResult);
			// disable hover pop-up of feedback button
			return true;
		},
		[getFeedbackContext, isFeedbackDisabled, onFeedbackClicked, openFeedbackCollector],
	);

	const likeButton = useMemo(
		() => (
			<LikeIcon
				color={
					isFeedbackDisabled ? token('color.icon.disabled', N70) : token('color.icon.subtle', N200)
				}
				spacing="spacious"
				label={formatMessage(messages.feedbackIconLabel)}
			/>
		),
		[formatMessage, isFeedbackDisabled],
	);

	return (
		<FormContainer data-testid="atlassian-intelligence.common.ui.feedback-form.container">
			<FeedbackGroup>
				{wasFeedbackSent && (
					<Lozenge
						testId="atlassian-intelligence.common.ui.feedback-form.beta-lozenge"
						appearance="success"
					>
						{formatMessage(messages.feedbackSentLozenge)}
					</Lozenge>
				)}

				<ButtonStyle>
					<FeedbackButton
						testId="atlassian-intelligence.common.ui.feedback-form.like-button"
						isDisabled={isFeedbackDisabled}
						isPositive
						iconBefore={likeButton}
						onClick={() => handleOnClick(true)}
						appearance="subtle"
						tooltipContent={formatMessage(messages.tooltipLike)}
						spacing="compact"
					/>
				</ButtonStyle>

				<ButtonStyle isDislike>
					<FeedbackButton
						isDisabled={isFeedbackDisabled}
						isPositive={false}
						iconBefore={likeButton}
						onClick={() => handleOnClick(false)}
						appearance="subtle"
						tooltipContent={formatMessage(messages.tooltipDislike)}
						spacing="compact"
						testId="atlassian-intelligence.common.ui.feedback-form.dislike-button"
					/>
				</ButtonStyle>
			</FeedbackGroup>
		</FormContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const FormContainer = styled.div({
	display: 'flex',
	gap: token('space.050', '4px'),
	borderTopLeftRadius: 'unset',
	borderTopRightRadius: 'unset',
	borderBottomLeftRadius: `${token('border.radius.200', '6px')}`,
	borderBottomRightRadius: `${token('border.radius.200', '6px')}`,
	alignItems: 'center',
	justifyContent: 'space-between',
	color: token('color.text.subtle', N500),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const FeedbackGroup = styled.div({
	display: 'flex',
	flexWrap: 'wrap',
	alignItems: 'center',
	padding: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const ButtonStyle = styled.div<{
	isDislike?: boolean;
}>(
	{
		maxHeight: token('space.300', '24px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isDislike = false }) =>
		isDislike
			? {
					transform: 'rotate(180deg)',
				}
			: {},
);
