import React from 'react';
import logger from '@atlassian/jira-common-util-logging/src/log';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import { EDIT_ISSUE_COLOUR_ERROR } from '@atlassian/jira-issue-view-common-constants/src/flags.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { fieldSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector';
import { EPIC_COLOR_TYPE } from '@atlassian/jira-platform-field-config';
import {
	transformFromStateValueClassicSupport,
	transformToStateValueClassicSupport,
	colorFieldSelector,
	epicColorFieldSelector,
	issueColorFieldSelector,
} from './selectors';
import { saveField } from './services';
import { UnsafeColorPicker } from './view/view.tsx';

type Props = {
	field:
		| {
				key: string;
		  }
		| undefined;
	epicColorField:
		| {
				key: string;
		  }
		| undefined;
	issueColorField:
		| {
				key: string;
		  }
		| undefined;
};

type OwnProps = {
	fieldId: string;
};

const getFieldOptions = () => {
	const fieldOptions = {
		shouldDiscardChangeOnFailure: true,
		onSaveFailureFlagType: EDIT_ISSUE_COLOUR_ERROR,
	};

	return {
		saveField,
		...fieldOptions,
	};
};

const mapStateToConfig = (state: State, props: OwnProps) => {
	const field = fieldSelector(props.fieldId)(state);
	const isClassicColor = field ? field.schema.custom === EPIC_COLOR_TYPE : false;

	return {
		...getFieldOptions(),
		fieldId: props.fieldId,
		transformFromStateValue: (colorStr: string | null) =>
			transformFromStateValueClassicSupport(colorStr, isClassicColor),
		transformToStateValue: (colorStr: string) =>
			transformToStateValueClassicSupport(colorStr, isClassicColor),
	};
};

const ColorPickerFieldClassicSupport = flowWithSafeComponent(
	connectField((state, props) => mapStateToConfig(state, props)),
)(injectIntl(UnsafeColorPicker));

const MaybeColorPicker = (props: Props) => {
	/**
	 * Epic Color was historically used to hold the color value of the Epic issue type and
	 * is still used in Server. This has been migrated to the Issue Color field value and
	 * should theoretically have been copied over in the case of Server to Cloud migrations.
	 * This logging allows us to discover if there are any issues that contain an Epic Color field value
	 * but not an Issue Color field value, which shouldn't happen.
	 *  */
	if (props.epicColorField && !props.issueColorField) {
		logger.safeErrorWithoutCustomerData(
			'issue.color-picker',
			'Issue with epic color but no issue color is being rendered',
		);
	}
	if (props.field) {
		return <ColorPickerFieldClassicSupport fieldId={props.field.key} />;
	}
	return null;
};

const mapStateToProps = (state: State) => ({
	field: colorFieldSelector(state),
	epicColorField: epicColorFieldSelector(state),
	issueColorField: issueColorFieldSelector(state),
});

export default flowWithSafeComponent(connect(mapStateToProps, {}))(MaybeColorPicker);
