import type {
	NullableOptions as SelectableEditViewNullableOptions,
	SelectableValueOption as SelectableEditViewSelectableValueOption,
} from '@atlassian/jira-issue-selectable-field-edit-view/src/ui/types';
import type { Option as EditViewOptions, NullableOption as EditViewNullableOption } from './types';

export const getOptionsWithoutSelected = (
	selectOptions: SelectableEditViewSelectableValueOption[],
	selectedValue: SelectableEditViewSelectableValueOption | undefined,
) =>
	selectOptions.filter(
		(option: SelectableEditViewSelectableValueOption) =>
			option.selectableLabel !== selectedValue?.selectableLabel && option.id !== selectedValue?.id,
	);

export const transformToSelectableValueOption = (
	option: EditViewOptions,
): SelectableEditViewSelectableValueOption => ({
	id: option.value,
	optionId: option.optionId,
	selectableLabel: option.label || '',
	selectableGroupKey: null,
	selectableIconUrl: null,
});

export const transformToOnChangeShape = (
	value: SelectableEditViewNullableOptions,
): EditViewNullableOption => {
	let transformedValue = null;
	if (value && value.length && value?.[0].id) {
		transformedValue = {
			isDisabled: false,
			label: value[0].selectableLabel || '',
			value: value[0].id,
			optionId: value[0].optionId || '',
		};
	}
	return transformedValue;
};
