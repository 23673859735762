import keys from 'lodash/keys';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-track-event.tsx';
import { safeLinkTypeName } from '@atlassian/jira-common-constants/src/issue-link-types';
import { ff } from '@atlassian/jira-feature-flagging';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IssueLinks } from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import {
	closeAddIssueLink,
	createLinkedIssueSuccess,
	saveLinkedIssueSuccess,
} from '@atlassian/jira-issue-view-store/src/actions/issue-links-actions';
import {
	issueKeySelector,
	issueIdSelector,
	baseUrlSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { projectTypeSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import { toIssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import CreateLinkedIssueButtonView from './view';

const linkedIssueCreatedAnalyticsAction = 'created';

const fireLinkedIssueCreatedTrackAnalytics = (
	{ issueLinks, linkedIssues }: IssueLinks,
	analyticsEvent: UIAnalyticsEvent,
) => {
	const issueLinkId = keys(issueLinks)[0];
	// issueLinkId would be undefined when the newly created linked issue is not actually linked to the current
	// issue.
	if (issueLinkId !== undefined) {
		const issueLink = issueLinks[issueLinkId];
		const linkedIssue = linkedIssues[issueLink.linkedIssueKey];
		const analyticsAttributes = {
			action: linkedIssueCreatedAnalyticsAction,
			actionSubjectId: linkedIssue.id,
			attributes: {
				linkTypeName: safeLinkTypeName(issueLink.typeName),
				linkTypeId: issueLink.typeId,
				issueLinkId,
			},
		};
		fireTrackAnalytics(analyticsEvent, analyticsAttributes);
	}
};

export default flowWithSafeComponent(
	injectIntl,
	ComponentWithAnalytics('createLinkedIssueButton', {
		onLinkedIssueCreated: linkedIssueCreatedAnalyticsAction,
	}),
	connect(
		(state: State) => ({
			baseUrl: baseUrlSelector(state),
			sourceIssueKey: issueKeySelector(state),
			sourceIssueId: toIssueId(String(issueIdSelector(state))),
			projectType: projectTypeSelector(state),
		}),
		(dispatch) => ({
			onLinkedIssueCreated: (
				newIssueLinks: IssueLinks,
				analyticOrMaybePostAnalytic: UIAnalyticsEvent,
				analyticsEvent?: UIAnalyticsEvent,
			) => {
				dispatch(createLinkedIssueSuccess(newIssueLinks));
				if (ff('corex-operandi-issue-view-additional-logging_l6hj8')) {
					Object.entries(newIssueLinks.issueLinks).forEach(([optimisticId, issueLink]) => {
						dispatch(
							saveLinkedIssueSuccess(
								optimisticId,
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								analyticsEvent!,
								issueLink.linkedIssueKey,
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								analyticOrMaybePostAnalytic!,
							),
						);
					});

					dispatch(closeAddIssueLink());
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					fireLinkedIssueCreatedTrackAnalytics(newIssueLinks, analyticsEvent!);
				} else {
					Object.keys(newIssueLinks.issueLinks).forEach((optimisticId) => {
						dispatch(saveLinkedIssueSuccess(optimisticId, analyticOrMaybePostAnalytic));
					});

					dispatch(closeAddIssueLink());
					fireLinkedIssueCreatedTrackAnalytics(newIssueLinks, analyticOrMaybePostAnalytic);
				}
			},
		}),
	),
)(
	// @ts-expect-error - Argument of type '(props: Props) => React.JSX.Element' is not assignable to parameter of type 'ComponentType<{ intl: IntlShapeV2 | IntlShape | undefined; }>'.
	CreateLinkedIssueButtonView,
);
