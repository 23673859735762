import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import {
	setFilterInLocalStorage,
	TEXT_SEARCH_KEY,
	SAVED_SEARCH_ID_KEY,
} from '../../filter-local-storage';
import type { State } from '../../types';

export const setTextSearch =
	(textSearch: string, analyticsEvent: UIAnalyticsEvent) =>
	async ({ getState, setState }: StoreActionApi<State>) => {
		const { linkModalFilter } = getState();

		setState({
			linkModalFilter: {
				...linkModalFilter,
				textSearch,
			},
		});

		setFilterInLocalStorage(TEXT_SEARCH_KEY, textSearch, analyticsEvent);
	};

export const setSelectedSavedSearchId =
	(selectedSavedSearchId: string, analyticsEvent: UIAnalyticsEvent) =>
	async ({ getState, setState }: StoreActionApi<State>) => {
		const { linkModalFilter } = getState();

		setState({
			linkModalFilter: {
				...linkModalFilter,
				selectedSavedSearchId,
			},
		});

		setFilterInLocalStorage(SAVED_SEARCH_ID_KEY, selectedSavedSearchId, analyticsEvent);
	};
