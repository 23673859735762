// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	issueTypeTitle: {
		id: 'issues.issue-links.remote-issue-link-error.issue-type.title',
		defaultMessage: 'Issue type: {issueTypeName}',
		description: 'A text title for the issue type icon, mainly for accessibility purposes.',
	},
	remoteLinkAuthSummary: {
		id: 'issues.issue-links.remote-issue-link-error.auth-required.summary',
		defaultMessage: '{tagStart}Authenticate{tagEnd} to see additional information',
		description:
			'Summary text describing how to authenticate and see more information for remote issue link',
	},
	remoteLinkGeneralError: {
		id: 'issues.issue-links.remote-issue-link-error.general-error',
		defaultMessage: 'Failed to load remote issue',
		description: 'General error message for remote issue link',
	},
	remoteLinkMissingApplink: {
		id: 'issues.issue-links.remote-issue-link-error.missing-applink',
		defaultMessage: 'Failed to load remote issue because remote site is not linked',
		description: "Application link to remote instance doesn't exist",
	},
	remoteLinkAuthTooltip: {
		id: 'issues.issue-links.remote-issue-link-error.auth-required.tooltip',
		defaultMessage: 'Authenticate to {remoteServerHostname}',
		description: 'Tooltip message for remote issue link that requires authentication',
	},
	remoteLinkOpenLinkTooltip: {
		id: 'issues.issue-links.remote-issue-link-error.link-button.tooltip',
		defaultMessage: 'Open link in a new tab',
		description: 'Tooltip message for button opening the issue in a tab',
	},
	remoteLinkDeleteLinkTooltip: {
		id: 'issues.issue-links.remote-issue-link-error.delete-button.tooltip',
		defaultMessage: 'Unlink remote issue',
		description: 'Tooltip message for button deleting issue link',
	},
});
