// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	convertToIssue: {
		id: 'issue.convert-to-issue',
		defaultMessage: 'Convert to Issue',
		description: 'Meatball option to change a subtask to a parent level issue',
	},
});
