/* eslint-disable jira/react/no-style-attribute */

import React, { useCallback, type CSSProperties } from 'react';
import { styled } from '@compiled/react';
import { Text } from '@atlaskit/primitives';
import type { GroupProps, NoticeProps } from '@atlaskit/select';
// eslint-disable-next-line import/order
import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import isValidUrl from '@atlassian/jira-common-util-is-url';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import LinkSelect from '../../../link-select';
import type { ConfluenceResult, SelectOptionGroup } from '../../common/types';
import type { Options } from '../../services/search/types';
import messages from './messages';
import type { Props } from './types';

export const getNoOptions = (
	error: Error | null,
	type: ConfluenceResult,
	formatMessage: FormatMessage,
) => {
	if (error || type === 'search') {
		return <Text as="p">{formatMessage(messages.noResultOptionsMessage)}</Text>;
	}
	return <Text as="p">{formatMessage(messages.noRecentOptionsMessage)}</Text>;
};

export const LoadingMessageComponent = (props: NoticeProps<SelectOptionGroup, false>) => {
	const { innerProps, getStyles, options } = props;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const group = getStyles('group', props as GroupProps<SelectOptionGroup>);
	// The @atlaskit/select will export GroupHeadingProps, so for now we just use any as the temporary type
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	const groupHeading = getStyles('groupHeading', props as any);

	return (
		<MainContainer {...innerProps}>
			<Group
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- Ignored via go/DSP-18766
					group as CSSProperties
				}
			>
				{options.length > 0 ? (
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @atlaskit/ui-styling-standard/enforce-style-prop, @typescript-eslint/consistent-type-assertions
					<div style={groupHeading as CSSProperties}>
						{/* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */}
						{(options as Options)[0]?.label}
					</div>
				) : null}
			</Group>
		</MainContainer>
	);
};

const ConfluenceSearchSelect = (props: Props) => {
	const filterOption = () => true;
	const {
		spaceKey,
		isLoading,
		isLoadingOptions,
		options,
		onChange,
		validationState,
		validationMessage,
		onSelect,
		onEnter,
		link,
		fetchSuggestions,
		optionsError,
		resultType,
		clearSearchState,
	} = props;
	const { formatMessage } = useIntl();

	const onInputChange = useCallback(
		(input: string) => {
			if (!isValidUrl(input)) {
				fetchSuggestions(input);
			} else {
				clearSearchState();
			}
		},
		[clearSearchState, fetchSuggestions],
	);

	const onPageSelect = useCallback(() => {
		onSelect && onSelect();
		fetchSuggestions('');
	}, [fetchSuggestions, onSelect]);

	const clearSuggestions = useCallback(() => {
		fetchSuggestions('');
	}, [fetchSuggestions]);

	// @ts-expect-error - TS7006 - Parameter 'input' implicitly has an 'any' type.
	const isValidNewOption = useCallback((input) => isValidUrl(input), []);
	return (
		<LinkSelect
			options={options}
			onChange={onChange}
			intl={useIntl()}
			validationMessage={validationMessage}
			validationState={validationState}
			onSelect={onPageSelect}
			onEnter={onEnter}
			link={link}
			isLoading={isLoading}
			isLoadingOptions={isLoadingOptions}
			filterOption={filterOption}
			onInputChange={onInputChange}
			customComponents={{ LoadingMessageComponent }}
			onBlur={clearSuggestions}
			onMenuClose={clearSuggestions}
			isValidNewOption={isValidNewOption}
			noOptionsMessage={() => getNoOptions(optionsError, resultType, formatMessage)}
			additionalProps={{
				error: optionsError,
				type: resultType,
				spaceKey,
			}}
		/>
	);
};

export default ConfluenceSearchSelect;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MainContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 32}px`,
	color: token('color.text.subtle', 'hsl(0, 0%, 60%)'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Group = styled.div({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'start',
	flexWrap: 'nowrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `calc(100% - ${gridSize * 2}px)`,
});
