import React, { useCallback } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { toFlagId, useFlagsService } from '@atlassian/jira-flags';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { AssigneeInvitedFlag } from '../../common/ui/assignee-invited-flag';
import { isValidEmailAddress } from '../../common/utils';
import { useAssignInvitedUser } from '../assign-invited-user';
import { useInviteUser } from '../invite-user';
import type { InviteAndAssignProps } from './types';

const ASSIGNEE_INVITED_FLAG_ID = toFlagId('issue-assignee-invited-flag');

type InviteAndAssignHandler = (
	email: string,
	fireCustomAnalytics?: (analyticSource: string, attributes: Record<string, unknown>) => void,
) => Promise<void>;

export const useInviteAndAssign = ({
	issueKey,
	analyticsSource,
}: InviteAndAssignProps): InviteAndAssignHandler => {
	const { showFlag, dismissFlag } = useFlagsService();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const assignInvitedUser = useAssignInvitedUser({ analyticsSource });
	const inviteUser = useInviteUser({ analyticsSource });

	const inviteAndAssignHandler: InviteAndAssignHandler = useCallback(
		async (email, fireCustomAnalytics) => {
			if (!isValidEmailAddress(email)) {
				fireErrorAnalytics({
					meta: {
						id: 'inviteAndAssignHandler',
						packageName: 'jiraInviteAndAssign',
						teamName: 'jlove-makkuro',
					},
					error: new Error(`Not a valid email address: ${email}`),
				});
				return;
			}

			if (fireCustomAnalytics) {
				fireCustomAnalytics(analyticsSource, { type: 'email' });
			} else {
				fireUIAnalytics(createAnalyticsEvent({}), 'option selected', analyticsSource, {
					type: 'email',
				});
			}

			showFlag({
				id: ASSIGNEE_INVITED_FLAG_ID,
				render(flagProps) {
					return (
						<AssigneeInvitedFlag
							id={ASSIGNEE_INVITED_FLAG_ID}
							email={email}
							onDismissed={async () => {
								dismissFlag(ASSIGNEE_INVITED_FLAG_ID);
								const inviteResult = await inviteUser({
									email,
									issueKey,
								});
								if (inviteResult.isInviteSuccessful) {
									await assignInvitedUser({
										issueKey,
										userId: inviteResult.userId,
									});
								}
							}}
							onCancel={() => {
								dismissFlag(ASSIGNEE_INVITED_FLAG_ID);
								fireUIAnalytics(
									createAnalyticsEvent({
										actionSubject: 'button',
										action: 'clicked',
									}),
									'cancelButton',
									{ source: 'inviteAndAssignFlag', integration: analyticsSource },
								);
							}}
							{...flagProps}
						/>
					);
				},
			});
		},
		[
			createAnalyticsEvent,
			analyticsSource,
			issueKey,
			showFlag,
			dismissFlag,
			inviteUser,
			assignInvitedUser,
		],
	);

	return inviteAndAssignHandler;
};
