import type { AppStateProps } from '../../../model/types';

// INITIALIZE APP PROPS
export const INITIALIZE_APP_PROPS = 'state.actions.app.INITIALIZE_APP_PROPS' as const;

export type InitializeAppPropsAction = {
	type: typeof INITIALIZE_APP_PROPS;
	payload: AppStateProps;
};

export const initializeAppProps = (payload: AppStateProps): InitializeAppPropsAction => ({
	type: INITIALIZE_APP_PROPS,
	payload,
});

export type Action = InitializeAppPropsAction;
