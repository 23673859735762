/* The relay/unused-fields rule is disabled as this file is only responsible for fetching from AGG.
 * When we decompose this query and move to Relay fragments this will be removed.
 */

import { fetchQuery } from 'react-relay';
import type { IEnvironment } from 'relay-runtime';
import { metrics } from '@atlassian/browser-metrics';
import { extractProjectKey } from '@atlassian/jira-issue-fetch-services-common/src/common/utils/extract-project-key.tsx';
import {
	ISSUE_AGG_OPERATION_NAME,
	issueAggDataQueryGraphqlTag,
} from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { getMainIssueViewAggQueryVariables } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/utils';
import { isInSample } from '@atlassian/jira-issue-sample-utils/src/common/utils/index.tsx';
import {
	startCaptureGraphQlErrors,
	stopCaptureGraphQlErrors,
	type AGGError,
} from '@atlassian/jira-relay-errors';
import type {
	mainIssueAggQuery as mainIssueAggQueryType,
	mainIssueAggQuery$data as mainIssueAggQueryResponse,
} from '@atlassian/jira-relay/src/__generated__/mainIssueAggQuery.graphql';
import type { IssueKey, CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	startCapturingTraceIds,
	stopCapturingTraceIds,
	getTraceIds,
} from '@atlassian/relay-traceid';

export type IssueAggData = {
	data?: mainIssueAggQueryResponse;
	errors?: AGGError[];
	traceId?: string;
};

const ISSUE_AGG_DATA = 'issue-agg-data';
// visible for testing
export const timeToFetchIssueAggDataMetric = metrics.custom({
	key: ISSUE_AGG_DATA,
});

export const captureIssueViewAggErrors = (key: string) => {
	const errors = stopCaptureGraphQlErrors(key)
		.filter((errorWrapper) => errorWrapper.meta.operationName === ISSUE_AGG_OPERATION_NAME)
		.flatMap((errorWrapper) => errorWrapper.errors);
	return errors;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default async (
	issueKey: IssueKey,
	cloudId: CloudId,
	environment: IEnvironment,
): Promise<IssueAggData> => {
	if (isInSample()) {
		timeToFetchIssueAggDataMetric.start();
	}

	// ConcreteRequest has params, but ReaderFragment doesn't
	const operationName =
		'params' in issueAggDataQueryGraphqlTag ? issueAggDataQueryGraphqlTag.params.name : '';
	startCapturingTraceIds(operationName);

	const id = startCaptureGraphQlErrors();

	const projectKey = extractProjectKey(issueKey);

	const data = await fetchQuery<mainIssueAggQueryType>(environment, issueAggDataQueryGraphqlTag, {
		cloudId,
		issueKey,
		projectKey,
		...getMainIssueViewAggQueryVariables(),
	})
		.toPromise()
		.catch((error) => {
			// Cancel the metric and re-throw the error
			timeToFetchIssueAggDataMetric.cancel();
			throw error;
		});
	const errors = captureIssueViewAggErrors(id);

	const traceId = getTraceIds(operationName)[0] ?? '';
	stopCapturingTraceIds(operationName);

	// Best-effort attempt to provide `issueId` and is not guaranteed
	const issueId = data?.jira.issueByKey?.issueId;

	timeToFetchIssueAggDataMetric.stop({
		customData: issueId
			? {
					issueId,
				}
			: null,
	});

	return { data, errors, traceId };
};
