import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { Ari } from '@atlassian/jira-platform-ari';
import type { FieldsByKey } from '@atlassian/jira-polaris-domain-field/src/collections/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { ViewAccessLevel } from '@atlassian/jira-polaris-domain-view/src/view-access/types.tsx';
import type { ViewSet } from '@atlassian/jira-polaris-domain-view/src/view-set/types.tsx';
import type { ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { RankingRemote } from '@atlassian/jira-polaris-remote-ranking/src/types.tsx';
import type { ViewRemote } from '@atlassian/jira-polaris-remote-view/src/types.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { PolarisRouter } from '../route/types';
/**
 * routing identifier for router.routeTo
 * this is not a view domain type, may be either component state or a router type should
 * we introduce a router component / lib
 */
export const ViewSectionTypeViews = 'view';

/**
 * Container props that when changed should cause state to be refreshed
 */
type LoadingProps = {
	containerAri: Ari;
	fieldKeys: FieldKey[];
};

/**
 * Container props for the views data container
 */
export type Props = {
	scope: string;
	isSharedView?: boolean;
	isCollectionView?: boolean;
	containerAri: Ari;
	currentUser: AccountId | undefined;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	fields: FieldsByKey;
	currentViewSection: string | undefined;
	currentViewSlug: string | undefined;
	router: PolarisRouter;
	onViewUpdateFailed: (arg1: Error) => void;
	onViewLoadingFailed: (arg1: Error) => void;
	onActionFailed: (arg1: Error) => void;
	onTryAddCurrentUserAsSiteCreator: () => void;
	cloudId: string;
	hasNoProjectPermissions: boolean;
	viewRemote: ViewRemote;
	rankingRemote: RankingRemote;
	projectOnboardedAt: string | undefined;
	hasManageViewsPermission?: boolean;
	/**
	 * callback to be called when the delivery data fetching failed - can be delayed
	 */
	onDeliveryDataFetchFailed: (error: Error) => void;
	delayDeliveryDataFetchFailedNotification: boolean;

	// methods to sync with navigation app
	onSyncViewName: (viewId: string, type: ViewKind, name: string) => void;
	onSyncViewEmoji: (viewId: string, type: ViewKind, emoji: string | undefined) => void;
	onSyncViewDeletion: (viewId: string, type: ViewKind) => void;
};

export type State = {
	/**
	 * meta information on status of the issue state. does not contain any issue
	 * data; contains additional indicators on to the overall state of the state
	 */
	meta: {
		loading: boolean;
		error: Error | undefined;
		loadingProps: LoadingProps | undefined;
		deliveryDataFetchError?: Error | undefined;
	};
	viewSets: ViewSet[];
	/**
	 * props mirror for correct selector and hook handling
	 */
	containerProps: Props | undefined;
	prevContainerProps?: Props;
	isExporting: boolean;
	isReadyToExport: boolean;
	hasUnsavedChanges: boolean;
	forceReloadOnNextUpdate: boolean;
	hasManageCurrentViewPermission?: boolean;
	accessLevel?: ViewAccessLevel;
};
