import { addStakeholder } from './add-stakeholder';
import { getStakeholders } from './fetch-stakeholders';
import { getIssueVersion } from './get-issue-version';
import { getLatestUpdate } from './get-latest-update';
import { getLimits } from './get-limits';
import { removeStakeholder } from './remove-stakeholder';
import { updateStakeholder } from './update-stakeholder';

export const actions = {
	getStakeholders,
	addStakeholder,
	removeStakeholder,
	updateStakeholder,
	getLatestUpdate,
	getLimits,
	getIssueVersion,
} as const;

export type Actions = typeof actions;
