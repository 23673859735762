import { useCallback, useRef, useState } from 'react';
import uuid from 'uuid';
import { useOptionallyControlledEditingState } from '@atlassian/jira-issue-field-optional-editing-state-manager';

/**
 * Utility to provide editing states for a field.
 * Accounts for draft values, but not pending values, as these are expected to be handled with optimistic updates.
 */
export function useEditStates<TComponentValue>(
	fieldId: string,
	componentValue: TComponentValue,
	onEditChange?: (isEditing: boolean) => void,
) {
	const [isEditing, setIsEditing] = useOptionallyControlledEditingState(false, fieldId, {
		onChange: onEditChange,
	});

	const [editingSessionId, setEditingSessionId] = useState<string>('');

	// We store the editing value both in state, and in a ref.
	//
	// This is because some components call `onChange` and `onConfirm` in a single callback,
	// and we need to ensure that onConfirm doesn't receive the value from the previous render,
	// but we still need to trigger a re-render eventually (with useState).
	const [_editingValue, _setEditingValue] = useState<TComponentValue | undefined>(undefined);
	const editingValueRef = useRef(_editingValue);
	const setEditingValue = useCallback((editingValue: TComponentValue | undefined) => {
		editingValueRef.current = editingValue;
		_setEditingValue(editingValue);
	}, []);

	const value = _editingValue !== undefined ? _editingValue : componentValue;

	const startEditing = useCallback(() => {
		setIsEditing(true);
		setEditingSessionId(uuid.v4());
	}, [setIsEditing]);

	const cancelEditing = useCallback(
		(args?: { clearDraft?: boolean }) => {
			setIsEditing(false);
			if (args?.clearDraft) {
				setEditingValue(undefined);
			}
		},
		[setEditingValue, setIsEditing],
	);

	const submitEditing = useCallback(
		(callback?: (editingValue: TComponentValue) => void) => {
			const editingValue = editingValueRef.current;

			if (editingValue !== undefined) {
				callback?.(editingValue);
			}

			setIsEditing(false);
			setEditingValue(undefined);
		},
		[setEditingValue, setIsEditing],
	);

	return {
		isEditing,
		editingSessionId,
		value,
		setEditingValue,
		startEditing,
		cancelEditing,
		submitEditing,
	};
}
