import React, { memo } from 'react';
import { FieldHeading } from '@atlassian/jira-issue-field-heading';
import {
	FieldHeadingTitle,
	FieldWrapper,
	SideBySideField,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin';
import IssueTeamField from '@atlassian/jira-issue-field-team/src/ui/main.tsx';
import type { Props } from './types';

export const View = (props: Props) => {
	const { label, issueKey, fieldId, issueId, onUpdate, noValueText, showPinButton } = props;

	return (
		<FieldWrapper>
			<FieldHeading>
				<FieldHeadingTitle>{label}</FieldHeadingTitle>
				{showPinButton === true && <FieldPin fieldId={fieldId} label={label} />}
			</FieldHeading>
			<SideBySideField>
				<IssueTeamField
					label={label}
					issueKey={issueKey}
					issueId={issueId}
					fieldKey={fieldId}
					onUpdate={onUpdate}
					noValueText={noValueText}
					isFitContainerWidthReadView
				/>
			</SideBySideField>
		</FieldWrapper>
	);
};

export default memo<Props>(View);
