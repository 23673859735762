import { createSelector } from 'reselect';
import find from 'lodash/find';
import { IN_PROGRESS, DONE } from '@atlassian/jira-common-constants/src/status-categories';
import type { ChildIssueType } from '@atlassian/jira-issue-view-common-types/src/child-issue-type';
import { entitiesSelector } from '../selectors';
import type { EntitiesState } from './types';

export const getAllIssues = createSelector(
	entitiesSelector,
	(entitiesState) => entitiesState.issues || [],
);

export const getCompletedIssues = createSelector(getAllIssues, (issues) =>
	issues.filter((issue) => issue.statusCategory === DONE),
);

export const getIncompleteIssues = createSelector(getAllIssues, (issues) =>
	issues.filter((issue) => issue.statusCategory !== DONE),
);

export const getInProgressIssues = createSelector(getAllIssues, (issues) =>
	issues.filter((issue) => issue.statusCategory === IN_PROGRESS),
);

export const getIssueHierarchyLevel = createSelector(
	entitiesSelector,
	(entitiesState) => entitiesState.issueHierarchyLevel,
);

export const getIssue = (id: string) =>
	createSelector(getAllIssues, (issues) => find(issues, (issue) => issue.id === id));

export const getRecentlyCreatedIssue = createSelector(
	entitiesSelector,
	(entitiesState) => entitiesState.recentlyCreatedIssue,
);

export const getIssueTypes = createSelector(
	entitiesSelector,
	(entitiesState: EntitiesState) => entitiesState.issueTypes,
);

export const getNextGenChildrenIssueTypesJql = createSelector(
	getIssueTypes,
	(issueTypes: ChildIssueType[]) =>
		`issuetype in (${issueTypes.map((issueType) => issueType.id).join(',')})`,
);
