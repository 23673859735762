import React, { Component } from 'react';
import CommonDropzoneMediaPicker from '../common/dropzone';

type Props = {
	container: HTMLElement;
	onDragEnter: () => void;
	onDragLeave: () => void;
	onDrop: () => void;
};

// eslint-disable-next-line jira/react/no-class-components
export default class DropzoneMediaPicker extends Component<Props> {
	getConfig() {
		return {
			container: this.props.container,
		};
	}

	render() {
		return (
			<CommonDropzoneMediaPicker
				config={this.getConfig()}
				onDragEnter={this.props.onDragEnter}
				onDragLeave={this.props.onDragLeave}
				onDrop={this.props.onDrop}
			/>
		);
	}
}
