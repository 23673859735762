import React, { useMemo, type ComponentType } from 'react';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import { SpotlightManager, SpotlightTarget } from '@atlaskit/onboarding';
import { Box, xcss } from '@atlaskit/primitives';
import { IssueBreakdownButton } from '@atlassian/jira-ai-work-breakdown/src/ui/issue-breakdown-button';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIsAiOptInEnabled } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { ChildIssue } from '@atlassian/jira-issue-shared-types/src/common/types/children-issues-type.tsx';
import type { ChildIssuesLimitStatus } from '@atlassian/jira-issue-view-common-constants/src/child-issues.tsx';
import withContainerWidth from '@atlassian/jira-issue-view-common-utils/src/with-container-width';
import {
	SectionHeading,
	SectionHeadingIcons,
} from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { hasExceededIssuesLimitInitialLoad } from '../../common/util';
import { useIsAiEnabled } from '../utils';
import type { AddChildEventHandler } from './add-child/types';

// This number was calculated by hand to see when buttons started to overflow
const SINGLE_COLUMN_MAX_WIDTH = 440;

export type Props = {
	isSortingAvailable: boolean;
	Title: ComponentType;
	Meatballs: ComponentType<{
		childIssuesLimitStatus: ChildIssuesLimitStatus;
	}>;
	AddChild: ComponentType<{
		onClick?: AddChildEventHandler;
	}>;
	SortByMenu: ComponentType<{
		childIssuesLimitStatus: ChildIssuesLimitStatus;
	}>;
	allIssues: ChildIssue[];
	supportsIssueCreation: boolean | undefined;
	sourceName?: string;
	childIssuesLimitStatus: ChildIssuesLimitStatus;
	onAddChildClick: AddChildEventHandler | undefined;
	issueHierarchyLevel: number | undefined;
	containerWidth: number;
};

function Heading(props: Props) {
	const {
		Title,
		Meatballs,
		AddChild,
		SortByMenu,
		supportsIssueCreation,
		allIssues,
		onAddChildClick,
		sourceName,
		isSortingAvailable,
		childIssuesLimitStatus,
		issueHierarchyLevel,
		containerWidth,
	} = props;
	const isMeatballMenuEnabled =
		hasExceededIssuesLimitInitialLoad(childIssuesLimitStatus) || allIssues.length > 0;

	const isAiEnabled = useIsAiEnabled();

	const isAiOptInEnabled = ff('jira-intelligent-issue-breakdown_18syl')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsAiOptInEnabled()
		: false;

	const isAnonymousUser = ff('jira-intelligent-issue-breakdown_18syl')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsAnonymous()
		: false;

	const renderIssueBreakdownButton = useMemo(() => {
		return (
			<SpotlightManager>
				<SpotlightTarget name="suggestIssuesSpotlight">
					<IssueBreakdownButton
						issueHierarchyLevel={issueHierarchyLevel}
						isCompact={!!containerWidth && containerWidth <= SINGLE_COLUMN_MAX_WIDTH}
					/>
				</SpotlightTarget>

				<EngagementSpotlight engagementId="suggestIssuesSpotlight" />
			</SpotlightManager>
		);
	}, [containerWidth, issueHierarchyLevel]);

	return (
		<SectionHeading>
			{/* eslint-disable jsx-a11y/label-has-associated-control */}
			<label htmlFor={sourceName}>
				<Title />
			</label>
			<SectionHeadingIcons>
				{!ff('configurable-child-issues-panel_3ht06') && isSortingAvailable && (
					<SortByMenu childIssuesLimitStatus={childIssuesLimitStatus} />
				)}
				{isMeatballMenuEnabled ? (
					<Meatballs childIssuesLimitStatus={childIssuesLimitStatus} />
				) : null}
				{supportsIssueCreation ? <AddChild onClick={onAddChildClick} /> : null}

				{ff('jira-intelligent-issue-breakdown_18syl') &&
				!isAnonymousUser &&
				supportsIssueCreation &&
				isAiEnabled &&
				isAiOptInEnabled ? (
					<Box xcss={issueBreakdownButtonStyles}>{renderIssueBreakdownButton}</Box>
				) : null}
			</SectionHeadingIcons>
		</SectionHeading>
	);
}

export default withContainerWidth(Heading);

const issueBreakdownButtonStyles = xcss({
	marginLeft: 'space.075',
});

Heading.defaultProps = {
	isSortingAvailable: false,
	supportsIssueCreation: false,
	allIssues: [],
};
