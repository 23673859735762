import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	fieldsSectionLabel: {
		id: 'issue.issue-view.issue-details.register-shortcuts.fields-section-label',
		defaultMessage: 'Fields',
		description: 'Section heading for "Fields" shortcuts in the shortcuts dialog.',
	},
	issueActionsSectionLabel: {
		id: 'issue.issue-view.issue-details.register-shortcuts.issue-actions-section-label',
		defaultMessage: 'Issue Actions',
		description: 'Section heading for "Issue Actions" shortcuts in the shortcuts dialog.',
	},
	issueWorkflowsSectionLabel: {
		id: 'issue.issue-view.issue-details.register-shortcuts.issue-workflows-section-label',
		defaultMessage: 'Workflow',
		description: 'Section heading for "Workflow" shortcuts in the shortcuts dialog.',
	},
	issueActionComment: {
		id: 'issue.issue-view.issue-details.register-shortcuts.issue-action-comment',
		defaultMessage: 'Comment',
		description: 'Issue action label for comment in shortcut menu',
	},
	issueActionAssignToMe: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.issue-action-assign-to-me',
		defaultMessage: 'Assign to me',
		description: 'Issue action label for assign to me in shortcut menu',
	},
	assignToMeSuccessTitle: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.assign-to-me-success-title',
		defaultMessage: 'Successfully assigned to you',
		description: 'Success title telling the user that the issue has been assigned to them',
	},
	assignToMeSuccessDescription: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.assign-to-me-success-description',
		defaultMessage: "You're now the assignee for {issueKey}.",
		description: 'Success description telling the user that the issue has been assigned to them',
	},
	assignToMeFailureTitle: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.assign-to-me-failure-title',
		defaultMessage: "We couldn't set the assignee",
		description: 'Failure title telling the user that changing the assignee failed',
	},
	assignToMeFailureDescription: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.assign-to-me-failure-description',
		defaultMessage: 'Check your connection, then give it another try.',
		description: 'Failure description telling the user to check their connection',
	},
});
