import { createSelector } from 'reselect';
import type { ConfluencePage } from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import type { UiState } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { uiSelector } from '../common/state/selectors/issue-selector';
import {
	linkedConfluencePagesPagesSelector,
	mentionedConfluencePagesPagesSelector,
} from './confluence-content-selector';

export const recentlyVisitedConfluencePagesSelector = createSelector(
	uiSelector,
	// @ts-expect-error - TS2769 - No overload matches this call.
	linkedConfluencePagesPagesSelector,
	mentionedConfluencePagesPagesSelector,
	(
		ui: UiState,
		linkedPages: ConfluencePage[] = [],
		mentionedPages: ConfluencePage[] = [],
	): ConfluencePage[] => {
		const recentPages = ui.recentlyVisitedConfluencePages || [];
		const linkedPageHrefs = linkedPages.concat(mentionedPages).map((page) => page.href);
		return recentPages.filter((page) => !linkedPageHrefs.includes(page.href));
	},
);
