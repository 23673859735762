import React from 'react';
import StatusLozenge from '@atlassian/jira-common-components-status-lozenge/src/view.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { Emphasise } from '@atlassian/jira-issue-view-activity-common/src/styles';
import Change from '../../../../common/ui/change';
import GroupedChange from '../../../../common/ui/grouped-change';
import HistoryItem from '../../../../common/ui/history-item';
import type { StatusChangedHistoryItem } from '../../../../types';
import messages from './messages';

type Props = {
	isGrouped?: Boolean;
	historyItem: StatusChangedHistoryItem;
};

const TransitionHistoryItem = ({ historyItem, isGrouped = false }: Props) => {
	const { formatMessage } = useIntl();
	const { actor, timestamp, fromStatus, toStatus } = historyItem;
	return (
		<FormattedMessage
			{...messages.changed}
			values={{
				status: (
					<Emphasise key="status">
						{formatMessage(
							expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false)
								? messages.statusLowercase
								: messages.status,
						)}
					</Emphasise>
				),
			}}
		>
			{(...action) =>
				isGrouped && expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false) ? (
					<GroupedChange
						field={formatMessage(messages.status)}
						from={
							fromStatus && (
								<StatusLozenge category={fromStatus.statusCategory} name={fromStatus.name} />
							)
						}
						to={
							toStatus && <StatusLozenge category={toStatus.statusCategory} name={toStatus.name} />
						}
					/>
				) : (
					<HistoryItem
						// eslint-disable-next-line jira/integration/test-id-by-folder-structure
						testId="issue-history.ui.history-items.transition-history-item.history-item"
						actor={actor}
						action={action}
						timestamp={timestamp}
						change={
							<Change
								from={
									fromStatus && (
										<StatusLozenge category={fromStatus.statusCategory} name={fromStatus.name} />
									)
								}
								to={
									toStatus && (
										<StatusLozenge category={toStatus.statusCategory} name={toStatus.name} />
									)
								}
							/>
						}
					/>
				)
			}
		</FormattedMessage>
	);
};

export default TransitionHistoryItem;
