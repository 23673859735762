import isEmpty from 'lodash/isEmpty';
import { makeNewTitle } from '@atlassian/jira-polaris-component-navigation-store/src/controllers/views/actions/utils.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { VIEW_RANK } from '@atlassian/jira-polaris-domain-view/src/sort/constants.tsx';
import type { ViewSet } from '@atlassian/jira-polaris-domain-view/src/view-set/types.tsx';
import {
	VIEW_KIND_BOARD,
	VIEW_KIND_TABLE,
	VIEW_KIND_MATRIX,
	VIEW_KIND_TIMELINE,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import type {
	LocalViewId,
	ViewKind,
	View,
} from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import { extractViewAnalyticsData } from '../../selectors/view';
import { getCurrentView } from '../../selectors/view/current/index.tsx';
import { type State, type Props, ViewSectionTypeViews } from '../../types';
import { resetDraft } from '../autosave/reset-draft/index.tsx';
import { loadViewDescription } from '../description/load-view-description/index.tsx';
import { saveView } from '../save';
import { clearViewTemporaryData } from '../temporary-data/index.tsx';
import { applyDraftToView, getAutosaveAffectedViewConfigurations } from '../utils/autosave';
import { logViewError } from '../utils/errors';
import { applyTemporaryDataToView } from '../utils/temporary-data.tsx';
import { findView, findViewSet } from '../utils/views';

const addFieldIfNotExists = (fields: FieldKey[], fieldKey?: FieldKey) => {
	if (fieldKey === undefined) {
		return;
	}

	const fieldExists = fields.some((existingFieldKey) => existingFieldKey === fieldKey);
	if (!fieldExists) {
		fields.push(fieldKey);
	}
};

const createNewView = (
	currentView: View,
	kind: ViewKind,
	currentViewSet: ViewSet,
	groupByField?: FieldKey,
) => {
	const view: View = {
		...currentView,
		kind,
		id: `${currentView.id + Math.random() * 5}`,
		title: makeNewTitle(currentView.title),
		editable: true,
		modified: true,
		saving: false,
		saveError: undefined,
		isEditingTitle: true,
		groupBy: undefined,
		verticalGroupBy: undefined,
		hideEmptyGroups: false,
		hideEmptyColumns: false,
		fields: [...currentView.fields],
		viewId: undefined,
		viewLegacyId: undefined,
		rank: currentViewSet.views.length + 1,
		lastViewed: undefined,
		hasUserVisited: true,
		bulkEditHoveredFieldKey: undefined,
		matrixConfig: undefined,
		timelineConfig: undefined,
		emoji: undefined,
		groupValues: [],
		verticalGroupValues: [],
		isAutosaveEnabled: true,
	};

	if (
		[VIEW_KIND_BOARD, VIEW_KIND_TABLE].includes(currentView.kind) &&
		[VIEW_KIND_BOARD, VIEW_KIND_TABLE].includes(kind)
	) {
		view.groupBy = groupByField ?? currentView.groupBy;
		view.groupValues = currentView.groupValues;
		view.verticalGroupBy = currentView.verticalGroupBy;
		view.verticalGroupValues = currentView.verticalGroupValues;

		if (currentView.verticalGroupBy !== undefined) {
			addFieldIfNotExists(view.fields, currentView.verticalGroupBy);
		}
	}

	const { matrixConfig } = currentView;
	if (currentView.kind === VIEW_KIND_MATRIX && matrixConfig !== undefined) {
		if (kind === VIEW_KIND_TABLE || kind === VIEW_KIND_BOARD) {
			matrixConfig.axes?.forEach((axis) => {
				addFieldIfNotExists(view.fields, axis.field?.key);
			});
		} else if (kind === VIEW_KIND_MATRIX) {
			view.matrixConfig = {
				axes: [...matrixConfig.axes],
			};
		}
	}

	const { timelineConfig } = currentView;
	if (currentView.kind === VIEW_KIND_TIMELINE && timelineConfig !== undefined) {
		view.verticalGroupBy = currentView.verticalGroupBy;
		view.verticalGroupValues = currentView.verticalGroupValues;
		// markers shouldn't be copied when cloning a timeline
		view.markers = [];

		if (kind === VIEW_KIND_TABLE || kind === VIEW_KIND_BOARD) {
			[timelineConfig?.startDateField, timelineConfig?.dueDateField].forEach((field) => {
				if (field?.key) {
					addFieldIfNotExists(view.fields, field.key);
				}
			});
		} else if (kind === VIEW_KIND_TIMELINE) {
			view.timelineConfig = {
				...timelineConfig,
			};
		}
	}

	view.draft = getAutosaveAffectedViewConfigurations(view);

	return view;
};

const updateState = (
	currentViewSet: ViewSet,
	view: View,
	{ getState, setState }: StoreActionApi<State>,
) => {
	const updatedViewSets = getState().viewSets.map((viewSet) => ({
		...viewSet,
		views: viewSet.id === currentViewSet.id ? [...viewSet.views, view] : viewSet.views,
		viewSets: viewSet?.viewSets?.map((vSet) => ({
			...vSet,
			views: vSet.id === currentViewSet.id ? [...vSet.views, view] : vSet.views,
		})),
	}));
	setState({
		viewSets: updatedViewSets,
	});
};

// visible for testing
export const createOnViewSaved =
	({ viewRemote, rankingRemote, router, onViewUpdateFailed }: Props) =>
	(savedView?: View) => {
		if (savedView?.sortMode === VIEW_RANK && savedView?.viewId !== undefined) {
			rankingRemote
				.create({
					listId: savedView.viewId,
					items: savedView.issueRanking || [],
				})
				.catch(onViewUpdateFailed);
		}

		if (savedView?.kind === VIEW_KIND_TIMELINE) {
			const currentArrangementInfo = savedView.timelineConfig?.arrangementInformation;

			if (!isEmpty(currentArrangementInfo) && savedView.viewId !== undefined) {
				viewRemote
					.updateArrangementInfo({
						viewId: savedView.viewId,
						arrangement: JSON.stringify(currentArrangementInfo),
					})
					.catch((error) => {
						logViewError('apply-current-arrangement', error);
						onViewUpdateFailed(error);
					});
			}
		}

		if (savedView !== undefined) {
			router.routeTo({
				section: ViewSectionTypeViews,
				resource: savedView.slug,
			});
		}
	};

const cloneGivenView = (
	view: View,
	kind: ViewKind,
	groupByField: undefined | FieldKey,
	api: StoreActionApi<State>,
	props: Props,
) => {
	const viewSet = findViewSet(api.getState().viewSets, (v) => v.id === view.viewSetId);
	if (!viewSet) {
		return;
	}
	const clone = createNewView(view, kind, viewSet, groupByField);
	updateState(viewSet, clone, api);
	api.dispatch(
		saveView(clone.id, (savedView?: View) => {
			createOnViewSaved(props)(savedView);

			if (savedView) {
				const analyticsData = extractViewAnalyticsData(savedView);
				fireTrackAnalytics(
					props.createAnalyticsEvent({
						containers: analyticsData?.containers,
					}),
					'view created',
					{
						createdFrom: view.viewId,
						...analyticsData?.attributes,
					},
				);

				api.dispatch(loadViewDescription(String(savedView.viewId), true));
			}
		}),
	);
};

export const cloneCurrentView =
	(kind: ViewKind, groupByField?: FieldKey): Action<State, Props> =>
	async (api, props) => {
		const currentView = getCurrentView({ ...api.getState() }, props);
		currentView && cloneGivenView(currentView, kind, groupByField, api, props);
	};

export const cloneView =
	(id: LocalViewId, kind: ViewKind): Action<State, Props> =>
	async (api, props) => {
		const currentView = findView(api.getState().viewSets, (v) => v.id === id);
		currentView && cloneGivenView(currentView, kind, undefined, api, props);
	};

export const saveCurrentUnsavedViewAsNew = (): Action<State, Props> => (api, props) => {
	const currentView = getCurrentView({ ...api.getState() }, props);

	if (currentView) {
		api.dispatch(resetDraft(currentView.viewId));
		cloneGivenView(applyDraftToView(currentView), currentView.kind, undefined, api, props);
	}
};

export const saveCurrentViewWithTemporaryDataAsNew = (): Action<State, Props> => (api, props) => {
	const currentView = getCurrentView({ ...api.getState() }, props);

	if (currentView) {
		cloneGivenView(applyTemporaryDataToView(currentView), currentView.kind, undefined, api, props);
		api.dispatch(clearViewTemporaryData(currentView.viewId));
	}
};
