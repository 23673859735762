import {
	COMMENT_VISIBILITY_TYPE_GROUP,
	COMMENT_VISIBILITY_TYPE_ROLE,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/comments.tsx';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import { fireOperationalAnalyticsDeferred } from '@atlassian/jira-product-analytics-bridge';
import type { Action } from '@atlassian/react-sweet-state';
import type { State, ContainerProps } from '../types';
import { fetchCommentVisibilities } from './query';

const logError = (error: Error | TypeError): void => {
	trackOrLogClientError(
		'issue.fetch.comment-visibilities',
		'An error occurred attempting to fetch comment visibilities from gira',
		error,
	);
};

const sendCommentVisibilityAnalytics = (
	// @ts-expect-error - TS2304 - Cannot find name 'AnalyticsEvent'.
	createAnalyticsEvent: (arg1: { action: string }) => AnalyticsEvent,
	// @ts-expect-error - TS2304 - Cannot find name 'CommentVisibility'.
	commentVisibilities: Array<CommentVisibility>,
) => {
	const analyticsEvent = createAnalyticsEvent({
		action: 'fetched',
	});
	const commentVisibilityStats = {
		totalCount: commentVisibilities.length,
		roleCount: commentVisibilities.filter(
			(commentVisibility) => commentVisibility.type === COMMENT_VISIBILITY_TYPE_ROLE,
		).length,
		groupCount: commentVisibilities.filter(
			(commentVisibility) => commentVisibility.type === COMMENT_VISIBILITY_TYPE_GROUP,
		).length,
	};

	fireOperationalAnalyticsDeferred(
		analyticsEvent,
		'commentVisibilities fetched',
		commentVisibilityStats,
	);
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (): Action<State, ContainerProps> =>
	async ({ getState, setState }, { issueKey, baseUrl, createAnalyticsEvent }) => {
		const currentState = getState();

		if (currentState.loading || currentState.error || currentState.data) {
			return;
		}

		setState({
			loading: true,
			error: null,
			data: null,
		});

		try {
			const commentVisibilities = await fetchCommentVisibilities(baseUrl, issueKey);

			setState({
				loading: false,
				error: null,
				data: { commentVisibilities },
			});

			sendCommentVisibilityAnalytics(createAnalyticsEvent, commentVisibilities);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			logError(error);

			setState({
				loading: false,
				error,
				data: null,
			});
		}
	};
