import { useEffect } from 'react';

import { ExperienceTracker } from '@atlassian/experience-tracker';

import { isClientFetchError } from '../../common/utils/is-error';
import { logException } from '../../services/sentry';
import { useAnalytics } from '../analytics';

// Re-exporting some experience trackers packages
export { ExperienceTracker, ExperienceTrackerContext } from '@atlassian/experience-tracker';
export type { ExperienceTrackerAPI } from '@atlassian/experience-tracker/ExperienceTracker';

class ExperienceTrackerHandler {
	private static instance: ExperienceTrackerHandler;
	public experienceTracker = new ExperienceTracker();
	private sendAnalyticsEvent: ReturnType<typeof useAnalytics>['sendAnalyticsEvent'] | null = null;

	constructor() {
		this.experienceTracker.subscribe((event) => {
			// for FailEvent
			if ('error' in event) {
				// do not fail experience tracking if the error is a client fetch error
				if (isClientFetchError(event.error)) {
					return;
				}

				const errorMetadata = (event.error as any)?.metadata;

				// Filter 5xx errors since backend monitoring will cover this
				// as per https://hello.atlassian.net/wiki/spaces/CONVAI/pages/3547354267/Meeting+notes+FE+SLOs+which+one+to+promote
				if (errorMetadata?.statusCode >= 500) {
					return;
				}

				const error =
					event.error instanceof Error ? event.error : new Error(JSON.stringify(event.error));

				logException({
					exception: error,
					name: event.name,
					tags: {
						source: 'aiMateExperienceTracker',
						apiErrorMessage: errorMetadata?.rawErrorMessage,
						apiErrorCode: errorMetadata?.errorCode,
					},
				});
			}

			this.sendAnalyticsEvent?.(
				{
					eventType: 'operational',
					action: event.action,
					actionSubject: 'ui',
					attributes: {
						task: event.name,
						taskId: event.id,
						startTime: event.startTime,
						...event.attributes,
					},
				},
				{
					removeDefaultTags: true,
				},
			);
		});
	}

	setSendAnalyticsEvent(sendAnalyticsEvent: ReturnType<typeof useAnalytics>['sendAnalyticsEvent']) {
		this.sendAnalyticsEvent = sendAnalyticsEvent;
	}

	static getInstance() {
		if (!ExperienceTrackerHandler.instance) {
			ExperienceTrackerHandler.instance = new ExperienceTrackerHandler();
		}

		return ExperienceTrackerHandler.instance;
	}
}

export const useAIMateExperienceTracker = () => {
	const { sendAnalyticsEvent } = useAnalytics();

	useEffect(() => {
		ExperienceTrackerHandler.getInstance().setSendAnalyticsEvent(sendAnalyticsEvent);
	}, [sendAnalyticsEvent]);

	return ExperienceTrackerHandler.getInstance().experienceTracker;
};
