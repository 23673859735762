import React from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { IconButton } from '@atlaskit/button/new';
import ChevronLeftIcon from '@atlaskit/icon/utility/chevron-left';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	ASSIGNED_USER_CANNED_COMMENTS_KEY,
	CANNED_COMMENTS_KEY,
	CANNED_COMMENTS_TYPE,
} from '../../../common/constants';
import messages from '../../../common/messages';
import { getCannedCommentContent } from '../../../common/utils';
import { useScrollCarousel } from '../../../controllers/use-scroll-carousel';
import CannedComment from './canned-comment';

export type Props = {
	onClickCannedComment: (value: ADF) => void;
	commentsType?: string;
};

export const CannedCommentList = ({ onClickCannedComment, commentsType }: Props) => {
	const {
		setRef,
		setCanScrollDebounced,
		shouldHideScrollLeftButton,
		shouldHideScrollRightButton,
		scrollToNext,
		scrollToPrevious,
		onFocusCannedComment,
	} = useScrollCarousel();
	const { formatMessage } = useIntl();

	const commentsKey =
		commentsType === CANNED_COMMENTS_TYPE.ASSIGNED_USER
			? ASSIGNED_USER_CANNED_COMMENTS_KEY
			: CANNED_COMMENTS_KEY;

	return (
		<Box xcss={containerStyles}>
			{!shouldHideScrollLeftButton && (
				<Box xcss={[hoverButtonStyle, leftButtonStyle]}>
					<IconButton
						shape="circle"
						appearance="subtle"
						icon={ChevronLeftIcon}
						isTooltipDisabled
						label={formatMessage(messages.moreOptions)}
						onClick={scrollToPrevious}
						tabIndex={-1}
					/>
				</Box>
			)}
			<Box
				ref={setRef}
				xcss={commentsContainerStyle}
				onScroll={setCanScrollDebounced}
				role="group"
				aria-label={formatMessage(messages.buttonGroupLabel)}
			>
				{commentsKey.map((key) => (
					<CannedComment
						commentKey={key.commentKey}
						commentText={getCannedCommentContent(
							formatMessage(messages[key.commentKey]),
							key.emoji,
						)}
						buttonText={formatMessage(messages[key.buttonTextKey])}
						key={key.commentKey}
						onClick={onClickCannedComment}
						onFocus={onFocusCannedComment}
					/>
				))}
			</Box>
			{!shouldHideScrollRightButton && (
				<Box xcss={[hoverButtonStyle, rightButtonStyle]}>
					<IconButton
						shape="circle"
						appearance="subtle"
						icon={ChevronRightIcon}
						isTooltipDisabled
						label={formatMessage(messages.moreOptions)}
						onClick={scrollToNext}
						tabIndex={-1}
					/>
				</Box>
			)}
		</Box>
	);
};

const containerStyles = xcss({
	display: 'flex',
	position: 'absolute',
	bottom: token('space.150'),
	left: token('space.150'),
	right: token('space.150'),
	alignItems: 'center',
});

const commentsContainerStyle = xcss({
	width: '100%',
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	overflowX: 'hidden',
	gap: 'space.100',
	// Sufficient padding needed for outline styles on focus to not be hidden by overflow: hidden
	padding: 'space.075',
});

const hoverButtonStyle = xcss({
	zIndex: 'layer',
	alignItems: 'center',
	position: 'absolute',
	// NOTE: before changing this.
	// see jira/src/packages/jira-canned-comments/src/ui/canned-comments-placeholder/index.tsx:71
	background: `var(--jira-canned-comments-background-color, ${token('color.background.input')})`,
});

const rightButtonStyle = xcss({
	right: 'space.0',
});

const leftButtonStyle = xcss({
	left: 'space.0',
});
