import type { SelectedOption, Option } from '../../common/types';

export const splitParentChildOptions = (
	parentChildValue: SelectedOption,
): [Option | null, Option | null] => {
	if (!parentChildValue) {
		return [null, null];
	}
	const { label, value } = parentChildValue;
	// @ts-expect-error - TS2339 - Property 'child' does not exist on type 'Option | ParentChildOption'.
	const child = parentChildValue.child || null;
	return [{ label, value }, child];
};

// Auto focus child if we have a empty select, or a parent select with no child options
export const getIsChildAutoFocus = (value: SelectedOption, childOptions: Option[]) =>
	!value || childOptions?.length === 0;
