import { useLoomAdControls } from '@atlassian/jira-loom-utils/src/controllers/use-loom-ad-controls';
import { LoomTouchpointVariant } from '@atlassian/jira-loom-utils/src/controllers/use-loom-touchpoint-variant/types.tsx';
import { useLoomTouchpointWithProjectType } from '../use-loom-touchpoint-with-project-type';

export const useLoomTouchpointVariantWithAdControls = (adControlKey: string) => {
	const { loomTouchpointVariant } = useLoomTouchpointWithProjectType();
	const { userHasDismissedAd, dismissAd } = useLoomAdControls(adControlKey);
	return {
		loomTouchpointVariant:
			loomTouchpointVariant === LoomTouchpointVariant.CROSSFLOW && userHasDismissedAd
				? LoomTouchpointVariant.NONE
				: loomTouchpointVariant,
		dismissAd,
	};
};
