import { LOCAL } from '../../common/constants';

const MAX_RESULTS = 5;
const CACHE_SIZE = MAX_RESULTS * 2;
const EPICS_CACHE_KEY = 'epics';

export const props = {
	size: CACHE_SIZE,
	cacheKey: EPICS_CACHE_KEY,
	maxAge: 1000 * 60 * 60 * 24 * 7,
	storageType: LOCAL,
} as const;
