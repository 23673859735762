import { fireOperationalAnalyticsWithContext } from '@atlassian/jira-ui-modifications-analytics/src/common/utils/operational-analytics/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { ON_INIT_CHANGE_ID } from '../../../../constants';
import type { AppId, ChangeId, IssueAdjustmentsState, StoreContainerProps } from '../../types';
import { addAppsErrors } from '../app-errors';
import { setNotification } from '../notification';
import type { AppliedChangesHistory } from './types';
import { collectConflicts } from './utils/collect-conflicts';

const HISTORY_LOG_TTL = 60_000;

/**
 * Tidies up applied changes history by removing old entries by TTL.
 * It keeps the current changeId in history.
 */
const tidyUpAppliedChangesHistory = (
	appliedChangesHistory: AppliedChangesHistory,
	changeId: string,
) =>
	Object.fromEntries(
		Object.entries(appliedChangesHistory).filter(([existingChangeId, history]) => {
			return Date.now() - history.createdAt <= HISTORY_LOG_TTL || existingChangeId === changeId;
		}),
	);

type DetectAppliedChangesConflictsPayload = {
	appId: AppId;
	changeId: ChangeId;
};

/**
 * Runs detection over applied changes history to find conflicts.
 * It sends notifications to apps, users and metrics.
 */
export const detectAppliedChangesConflicts =
	({ appId, changeId }: DetectAppliedChangesConflictsPayload) =>
	(
		{ setState, getState, dispatch }: StoreActionApi<IssueAdjustmentsState>,
		{ viewType, createAnalyticsEvent }: StoreContainerProps,
	) => {
		const { appliedChangesHistory } = getState();
		const tidyAppliedChangesHistory = tidyUpAppliedChangesHistory(appliedChangesHistory, changeId);

		setState({ appliedChangesHistory: tidyAppliedChangesHistory });

		const appliedChangesHistoryChangeId = tidyAppliedChangesHistory[changeId];
		const newChanges = appliedChangesHistoryChangeId?.apps[appId];

		if (
			!appliedChangesHistoryChangeId ||
			!newChanges ||
			// No need to run conflicts checks for the first item in history
			Object.keys(appliedChangesHistoryChangeId.apps).length <= 1
		) {
			return;
		}

		const lifecycleHook = changeId === ON_INIT_CHANGE_ID ? 'onInit' : 'onChange';
		const { appErrorsMap, conflicts } = collectConflicts({
			appliedChangesHistory: appliedChangesHistoryChangeId.apps,
			newChanges,
			appIdOfNewChanges: appId,
			lifecycleHook,
		});

		if (conflicts.length) {
			fireOperationalAnalyticsWithContext(
				createAnalyticsEvent({}),
				'appliedChangesHistory conflicted',
				viewType,
				{
					conflictsCount: conflicts.length,
					conflicts,
					conflictedApps: Object.keys(appErrorsMap),
				},
			);

			dispatch(addAppsErrors({ errors: appErrorsMap }));
			dispatch(setNotification({ type: 'MULTIPLE_APPS_CONFLICTS' }));
		}
	};
