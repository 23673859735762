import queryString from 'query-string';
import {
	ROUTE_NAMES_RAPIDBOARD_BACKLOG,
	ROUTE_NAMES_RAPIDBOARD_USER_BACKLOG,
} from '@atlassian/jira-common-constants/src/spa-routes';
import type { ViewModeType } from '@atlassian/jira-issue-view-common-types/src/context-type';
import type { Route } from '@atlassian/react-resource-router';

type ViewMode = 'planning' | 'planning.nodetail' | 'detail' | '';

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const getViewMode = (): ViewMode => queryString.parse(window.location.search).view || '';

// Includes check is useful here in case LegacyJS loads first and/or Sidebar closed
export const deprecatedIsBacklogSidebar = (): boolean => getViewMode().includes('planning');

export const isBacklogSidebar = (route: Route): boolean =>
	route.name === ROUTE_NAMES_RAPIDBOARD_BACKLOG ||
	route.name === ROUTE_NAMES_RAPIDBOARD_USER_BACKLOG;

export const isBoardSidebar = (): boolean => getViewMode() === 'detail';

export const isInSidebar = (viewMode?: ViewModeType | null): boolean => {
	if (viewMode) {
		return viewMode === 'SIDEBAR';
	}
	return deprecatedIsBacklogSidebar() || isBoardSidebar();
};
