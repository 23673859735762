import React from 'react';
import { ApprovalPanel } from '@atlassian/jira-issue-view-context-approval/src';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { useResource } from '@atlassian/react-resource-router';

const ApprovalPanelWrapper = () => {
	const { data: projectContextData } = useResource(projectContextResource);

	return !projectContextData?.isProjectArchived ? <ApprovalPanel /> : null;
};

export default ApprovalPanelWrapper;
