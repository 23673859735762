import { stringify } from 'query-string';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import type { FieldConfiguration } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types';
import { secondsToWholeMinuteTimeString } from '@atlassian/jira-issue-format-time/src/common/utils/seconds-to-whole-minute-time-string/index.tsx';
import type { TimeTrackingState } from '@atlassian/jira-issue-shared-types/src/common/types/time-tracking-type.tsx';
import { TIME_TRACKING_TYPE } from '@atlassian/jira-platform-field-config';
import getXsrfToken from '@atlassian/jira-platform-xsrf-token';
import type { IssueKey, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

export type UseRemainingEstimateField = (arg1: {
	issueId: IssueId;
	issueKey: IssueKey;
	onSuccess?: () => void;
	save: ((value: number | undefined, id: string) => Promise<void>) | null;
}) => [
	{
		value: TimeTrackingState;
		error: Error | null;
		fieldConfig: FieldConfiguration<TimeTrackingState> | null;
	},
	{
		saveValue: (
			value: TimeTrackingState,
			meta: null,
			analyticsEvent: UIAnalyticsEvent,
		) => Promise<void>;
		resetError: () => void;
	},
];

export type SaveFieldArgs = {
	issueId: IssueId;
	newValue: TimeTrackingState;
	baseUrl: string;
	save: ((value: number | undefined, id: IssueId) => Promise<void>) | null;
};

export const saveField = async ({ issueId, newValue, save }: SaveFieldArgs) => {
	// https://jdog.jira-dev.com/browse/BENTO-792 - The Time Estimate field is always shown,
	// because we always the return the timeoriginalestimate field from the backend. In order to
	// prevent the field from being visible but not editable when Time Tracking is missing from the
	// screen config, we use this legacy endpoint to save the field.
	const remainingEstimateValue = newValue.remainingEstimateSeconds;

	if (save != null) {
		return save(remainingEstimateValue, issueId);
	}

	// Convert the value in seconds to a time string to send to Jira.
	// 1m is always 60s, so we can do this safely.
	// we have to convert the final value to a whole number as passing decimal
	// values in minutes errors.
	const value =
		remainingEstimateValue !== undefined
			? secondsToWholeMinuteTimeString(remainingEstimateValue)
			: null;

	return performPostRequest('/secure/AjaxIssueAction.jspa?decorator=none', {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: stringify({
			issueId,
			timetracking_remainingestimate: value,
			timetracking_targetsubfield: 'timetracking_remainingestimate',
			fieldsToForcePresent: 'timetracking',
			singleFieldEdit: true,
			skipScreenCheck: true,
			atl_token: getXsrfToken(),
		}),
	});
};

export const useRemainingEstimateField: UseRemainingEstimateField = ({
	issueId,
	issueKey,
	onSuccess,
	save,
}) => {
	// useFieldConfig now returns an object { value, loading } instead of just the fieldConfig value
	// if possible when touching this, try and refactor to pass the new object instead of just the value
	const [{ value: fieldConfig }] = useFieldConfig(issueKey, TIME_TRACKING_TYPE);
	const fieldType = fieldConfig?.schema.type || 'timetracking';

	const saveClassicField = (
		_: IssueKey,
		__: string,
		newValue: TimeTrackingState,
		baseUrl: string,
	) => saveField({ issueId, newValue, baseUrl, save });

	const [{ value, error }, { saveValue, resetError }] = useEditField<TimeTrackingState, null>({
		fieldKey: TIME_TRACKING_TYPE,
		issueKey,
		fieldType,
		initialValue: {
			originalEstimateSeconds: 0,
			timeSpentSeconds: 0,
			remainingEstimateSeconds: 0,
		},
		saveField: saveClassicField,
		onSuccess,
	});

	return [
		{
			value,
			error,
			fieldConfig,
		},
		{ saveValue, resetError },
	];
};
