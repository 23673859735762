/** @jsx jsx */
import { useCallback, useContext, useEffect, useMemo } from 'react';
import type { Dispatch } from 'redux';
import { css, jsx } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { IconTile } from '@atlaskit/icon';
import Page16Icon from '@atlaskit/icon-object/glyph/page/16';
import PageIcon from '@atlaskit/icon/core/page';
import type { CreatePayload } from '@atlaskit/link-create';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useTenantHasConfluence } from '@atlassian/jira-business-confluence-pages/src/utils/use-tenant-has-confluence/index.tsx';
import { useEmbeddedConfluenceSidePanel } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-embedded-confluence-side-panel/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIsFullPageIssueView } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { createConfluencePageLinkRequest } from '@atlassian/jira-issue-view-store/src/actions/confluence-pages-actions';
import { hasActivatedConfluenceWithinFourteenDaysSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { toHref } from '@atlassian/jira-shared-types/src/general.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import { ACTION_SUBJECTS, ACTIONS } from '../../../common/constants';
import { SET_ACTIVE_PAGE_DATA } from '../../../common/types/actions';
import { CONTENT_TYPE, type CreateContentModalCoreProps } from '../../../common/types/types';
import { ContentMenuContext } from '../../../controllers/create-content-menu-context';
import type { SpaceInfo } from '../../../controllers/types';
import { useConfluenceSite } from '../../../controllers/use-confluence-site';
import { useCreateContentClickBehavior } from '../../../controllers/use-create-content-click-behavior';
import { useEmbeddedConfluenceDefaultSpace } from '../../../controllers/use-embedded-confluence-default-space';
import { useEmbeddedPageTracking } from '../../../controllers/use-embedded-page-tracking';
import { useParentProduct } from '../../../controllers/use-parent-product';
import { createConfluenceDraft } from '../../../services/confluence-rest-api';
import type { CreateConfluenceDraftV2Response } from '../../../services/confluence-rest-api/types';
import {
	createActivePageUrl,
	createLinkCreatePayload,
	getURLFromConfluencePageResponse,
} from '../../../utils';
import messages from './messages';
import type { CreatePageMenuItemDispatchProps, CreatePageMenuItemStateProps } from './types';

const CreatePageMenuItemComponent = ({
	onCancel,
	onCloseComplete,
	onCreate,
	onFailure,
	onOpenComplete,
	onLinkPage,
	onCreateBehavior,
	isQuickActionsListView,
	renderQuickActionItem,
	hasActivatedConfluenceWithinFourteenDays,
}: CreateContentModalCoreProps &
	CreatePageMenuItemStateProps &
	CreatePageMenuItemDispatchProps) => {
	const { formatMessage } = useIntl();
	const {
		accessStatus,
		setIsCreateContentModalOpen,
		setCreateContentRuntimeProps,
		setContentMenuState,
		setIsEpModalOpen,
		embeddedConfluenceSource,
		userHasConfluenceAccess,
		setIsDeferSpaceSelection,
		setIsEpModalLoading,
	} = useContext(ContentMenuContext);
	const [, sidePanelActions] = useEmbeddedConfluenceSidePanel(embeddedConfluenceSource);
	const { openSidePanel, setIsLoadingForDeferSpaceSelection } = sidePanelActions;
	const fireAnalyticsEvent = useEmbeddedPageTracking();
	const getCreateContentClickBehavior = useCreateContentClickBehavior();
	const tenantHasConfluence = useTenantHasConfluence();
	const cloudId = useCloudId();
	const { getEmbeddedConfluenceDefaultSpace } = useEmbeddedConfluenceDefaultSpace();
	const { getConfluenceSite } = useConfluenceSite();
	const isFullIssueView = useIsFullPageIssueView();
	const actionSubjects = ACTION_SUBJECTS[CONTENT_TYPE.PAGE];
	const parentProduct = useParentProduct();

	const isEligibleForSidePanelEPExp = useMemo(
		() => isFullIssueView && !hasActivatedConfluenceWithinFourteenDays && parentProduct !== 'JSM',
		[isFullIssueView, hasActivatedConfluenceWithinFourteenDays, parentProduct],
	);
	const [, sidePanelEPExperimentfireExposureEventAA] = UNSAFE_noExposureExp(
		'jira_issue_view_side_by_side_modeless_ep_aa_exp',
	);

	const [sidePanelEPExperimentConfig, sidePanelEPExperimentfireExposureEvent] =
		UNSAFE_noExposureExp('jira_issue_view_side_by_side_modeless_ep_exp');

	const isInSidePanelEPExperimentCohort =
		sidePanelEPExperimentConfig.get('cohort', 'not-enrolled') === 'experiment';

	const openPageInEmbeddedConfluence = useCallback(
		(payload: CreatePayload) => {
			const openInModal = () => {
				setContentMenuState({
					type: SET_ACTIVE_PAGE_DATA,
					payload: { ...payload, url: activePageUrl },
				});

				setIsEpModalOpen(true);
			};

			const activePageUrl = createActivePageUrl(payload);
			onCreate && onCreate(payload);
			// Begin Side-by-side EP experiment exposure
			if (isEligibleForSidePanelEPExp) {
				sidePanelEPExperimentfireExposureEventAA();
				sidePanelEPExperimentfireExposureEvent();

				if (isInSidePanelEPExperimentCohort) {
					return openSidePanel({
						contentId: payload.objectId ?? '',
						contentType: CONTENT_TYPE.PAGE,
						data: payload,
						isLive: payload.data?.subType === 'live',
						isDraft: true,
						url: activePageUrl,
						onLinkPage,
						openInModal,
					});
				}
			}

			openInModal();
		},
		[
			isEligibleForSidePanelEPExp,
			isInSidePanelEPExperimentCohort,
			onCreate,
			onLinkPage,
			openSidePanel,
			setContentMenuState,
			setIsEpModalOpen,
			sidePanelEPExperimentfireExposureEvent,
			sidePanelEPExperimentfireExposureEventAA,
		],
	);

	const createConfluencePage = useCallback(async () => {
		const openCreateContentModal = () => {
			setIsCreateContentModalOpen(true);
			setCreateContentRuntimeProps({
				isOpen: true,
				isQuickActionsListView,
				contentType: CONTENT_TYPE.PAGE,
				onCancel,
				onCloseComplete,
				onCreate: openPageInEmbeddedConfluence,
				onFailure,
				onOpenComplete,
				onCreateBehavior,
			});
		};
		const updateState = ({
			isLoading,
			isDeferSpaceSelection,
		}: {
			isLoading: boolean;
			isDeferSpaceSelection?: boolean;
		}) => {
			if (isInSidePanelEPExperimentCohort && isEligibleForSidePanelEPExp) {
				setIsLoadingForDeferSpaceSelection({ isLoading, isDeferSpaceSelection });
			} else {
				setIsEpModalLoading(isLoading);
				isDeferSpaceSelection && setIsDeferSpaceSelection(isDeferSpaceSelection);
			}
		};

		updateState({ isLoading: true });
		const defaultSpace: SpaceInfo = await getEmbeddedConfluenceDefaultSpace();
		const { spaceId, spaceKey } = defaultSpace;

		const hostname = await getConfluenceSite();

		if (!spaceKey || !spaceId) {
			openCreateContentModal();
			setIsEpModalLoading(false);
			return;
		}

		const fetchResponse = await createConfluenceDraft(cloudId, spaceId);
		if (!fetchResponse.success) {
			fireErrorAnalytics({
				meta: {
					id: actionSubjects.CREATE,
					teamName: 'confluence-better-together',
				},
				attributes: { embeddedConfluenceSource },
				error: new Error(`Failed to create Confluence content: ${fetchResponse.error}`),
			});
			// Don't want to run onFailure here because we are defaulting back to using the Create Content Modal
			openCreateContentModal();
			updateState({ isLoading: false });
			return;
		}

		const createResponse: CreateConfluenceDraftV2Response = fetchResponse.result;

		const url = getURLFromConfluencePageResponse({
			id: createResponse.id,
			spaceKey,
			hostname,
		});

		// TODO: If experiment is successful, de-couple this from CreatePayload and Link-Create
		const payload = createLinkCreatePayload(createResponse, url, defaultSpace, cloudId);
		openPageInEmbeddedConfluence(payload);

		fireAnalyticsEvent({
			action: ACTIONS.SUCCESS,
			eventName: actionSubjects.CREATE,
			embeddedConfluenceSource,
			eventType: 'operational',
		});

		updateState({ isLoading: false, isDeferSpaceSelection: true });
	}, [
		actionSubjects.CREATE,
		cloudId,
		embeddedConfluenceSource,
		fireAnalyticsEvent,
		getConfluenceSite,
		getEmbeddedConfluenceDefaultSpace,
		isEligibleForSidePanelEPExp,
		isInSidePanelEPExperimentCohort,
		isQuickActionsListView,
		onCancel,
		onCloseComplete,
		onCreateBehavior,
		onFailure,
		onOpenComplete,
		openPageInEmbeddedConfluence,
		setCreateContentRuntimeProps,
		setIsCreateContentModalOpen,
		setIsDeferSpaceSelection,
		setIsEpModalLoading,
		setIsLoadingForDeferSpaceSelection,
	]);

	const onOpen = useCallback(() => {
		fireAnalyticsEvent({
			action: 'clicked',
			eventName: 'createPageMenuItem',
			embeddedConfluenceSource,
			userHasConfluenceAccess,
			tenantHasConfluence,
			crossJoinStatus: accessStatus,
		});
		return getCreateContentClickBehavior({
			accessStatus,
			createContent: createConfluencePage,
			tenantHasConfluence,
		})();
	}, [
		accessStatus,
		createConfluencePage,
		embeddedConfluenceSource,
		fireAnalyticsEvent,
		getCreateContentClickBehavior,
		tenantHasConfluence,
		userHasConfluenceAccess,
	]);

	const isQuickActionsGA = fg('quick_actions_menu_ga');

	useEffect(() => {
		isQuickActionsGA &&
			renderQuickActionItem &&
			renderQuickActionItem('confluence', 'new-page', {
				onClick: onOpen,
				isDisabled: false,
				testId:
					'issue-create-confluence-content.ui.create-confluence-content-menu.create-page-menu-item.create-page-menu-item',
				iconComponent: (
					<Box paddingInlineStart="space.050">
						{isVisualRefreshEnabled() ? (
							<IconTile icon={PageIcon} label="" appearance="blueBold" shape="square" size="16" />
						) : (
							// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
							<Page16Icon label="" />
						)}
					</Box>
				),
				labelComponent: (
					<span css={messageStyles}>
						{fg('quick_actions_menu_ga')
							? formatMessage(messages.newConfluencePageQuickActionsList)
							: formatMessage(messages.createConfluencepageQuickActionsList)}
					</span>
				),
			});
	}, [formatMessage, isQuickActionsGA, onOpen, renderQuickActionItem]);

	if (ff('issue_view_quick_actions_revamp_l8mbx') && isQuickActionsListView) {
		return isQuickActionsGA ? null : (
			<DropdownItem
				testId="issue-create-confluence-content.ui.create-confluence-content-menu.create-page-menu-item.create-page-menu-item"
				onClick={onOpen}
				elemBefore={
					<Box paddingInlineStart="space.050">
						<IconTile icon={PageIcon} label="" appearance="blueBold" shape="square" size="16" />
					</Box>
				}
			>
				<span css={messageStyles}>
					{isQuickActionsGA
						? formatMessage(messages.newConfluencePageQuickActionsList)
						: formatMessage(messages.createConfluencepageQuickActionsList)}
				</span>
			</DropdownItem>
		);
	}

	return (
		<DropdownItem
			testId="issue-create-confluence-content.ui.create-confluence-content-menu.create-page-menu-item.create-page-menu-item"
			onClick={onOpen}
			elemBefore={
				isVisualRefreshEnabled() ? (
					<IconTile icon={PageIcon} label="" appearance="blueBold" shape="square" size="16" />
				) : (
					// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
					<Page16Icon label="" />
				)
			}
		>
			{formatMessage(messages.createConfluencePageItem)}
		</DropdownItem>
	);
};

const messageStyles = css({
	paddingLeft: token('space.050'),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	onLinkPage: (link: string, analyticsEvent: UIAnalyticsEvent) =>
		dispatch(createConfluencePageLinkRequest(toHref(link), analyticsEvent)),
});

const mapStateToProps =
	() =>
	(state: State): CreatePageMenuItemStateProps => ({
		hasActivatedConfluenceWithinFourteenDays:
			hasActivatedConfluenceWithinFourteenDaysSelector(state),
	});

export const CreatePageMenuItem = connect(
	mapStateToProps,
	mapDispatchToProps,
)(CreatePageMenuItemComponent);
