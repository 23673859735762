// TODO move this client to a common place so that it can be used by everyone using assistance service

type BaseRequest<T> = {
	experienceId: string;
	agent_input_context: T;
};

type BaseResponse<T> = {
	content?: T;
	errorMessage?: string;
};
type InvokeAgentExchange<
	TAgentRequest extends BaseRequest<unknown>,
	TAgentResponse extends BaseResponse<unknown>,
> = {
	agentRequest: TAgentRequest;
	agentResponse: TAgentResponse;
};

type RelatedResourcesAgentRequest = {
	issue_key: string;
};
type RelatedResourcesAgentResponse = {
	suggested_resources?: { id: string; url: string }[];
};

type AgentRegistry = {
	jira_issue_related_resources_agent: InvokeAgentExchange<
		BaseRequest<RelatedResourcesAgentRequest>,
		BaseResponse<RelatedResourcesAgentResponse>
	>;
};

export const invokeAgent = async <K extends keyof AgentRegistry>(
	agentNamedId: K,
	agentRequest: AgentRegistry[K]['agentRequest'],
): Promise<AgentRegistry[K]['agentResponse']> => {
	const response = await fetch('/gateway/api/assist/chat/v1/invoke_agent', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'X-Product': 'jira',
			'X-Experience-Id': agentRequest.experienceId,
		},
		body: JSON.stringify({
			recipient_agent_named_id: agentNamedId,
			agent_input_context: agentRequest.agent_input_context,
		}),
	});

	if (!response.ok) {
		throw new Error(`API call failed: ${response.status}`);
	}

	const result: AssistanceServiceResponse = await response.json();

	const content: AgentRegistry[K]['agentResponse']['content'] = JSON.parse(result.message.content);

	const data: AgentRegistry[K]['agentResponse'] = {
		content,
	};

	return data;
};

type AssistanceServiceResponse = {
	message: {
		content: string;
	};
};
