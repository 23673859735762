import { createSelector } from 'reselect';
import { statusComplete } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { getIssueTypes } from '../entities/selectors';
import { uiSelector } from '../selectors';

export const getAddClickCount = createSelector(uiSelector, (uiState) => uiState.addClickCount);

export const getChildPanelView = createSelector(uiSelector, (uiState) => uiState.childPanelView);

export const getChildCreateSummary = createSelector(
	uiSelector,
	(uiState) => uiState.childCreateSummary,
);

export const getSelectedIssueType = createSelector(
	uiSelector,
	getIssueTypes,
	({ selectedIssueType }, issueTypes) =>
		// to maintain "single source of truth" return issueType matching selectedIssueType
		(selectedIssueType && issueTypes.find((it) => it.id === selectedIssueType.id)) || issueTypes[0],
);

export const getHideDone = createSelector(uiSelector, (uiState) => uiState.hideDone);

const getFetchIssueTypesStatus = createSelector(
	uiSelector,
	(uiState) => uiState.fetchIssueTypesStatus,
);

export const isFetchIssueTypesComplete = createSelector(
	getFetchIssueTypesStatus,
	(status) => status === statusComplete,
);
