import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { ReviewButtonProps } from './types';
import type ReviewButton from './index';

const ReviewButtonAsync = lazyForPaint<typeof ReviewButton>(
	() => import(/* webpackChunkName: "async-pir-review-button" */ './index'),
);

export const AsyncReviewButton = (props: ReviewButtonProps) => (
	<Placeholder name="pir-review-button" fallback={null}>
		<ReviewButtonAsync {...props} />
	</Placeholder>
);
