import { useMemo } from 'react';
import partition from 'lodash/partition';
import { fg } from '@atlassian/jira-feature-gating';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	type IssueFields,
	useFieldsValues,
} from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import {
	type LayoutContainerNode,
	layoutContainerItemTypes,
} from '@atlassian/jira-issue-layout-common-constants';
import {
	getIsDatePastOrDueSoon,
	getIsUTCDatePastOrDueSoon,
} from '@atlassian/jira-issue-view-common/src/utils/date-utils';
import { isEmpty } from '@atlassian/jira-issue-view-layout-templates-utils';
import { useLayoutContainerByType } from '@atlassian/jira-issue-view-layout/src/services/main.tsx';
import { getLayoutItemId } from '@atlassian/jira-issue-view-layout/src/services/utils.tsx';
import { usePrimarySecondaryItems } from '../context/visible-hidden/primary-secondary-items/index.tsx';

type LayoutFieldsMeta = {
	numberOfPinnedFields?: Number;
	numberOfEmptyPinnedFields?: Number;
	numberOfVisibleContextFields?: Number;
	numberOfEmptyVisibleContextFields?: Number;
	numberOfHiddenFields?: Number;
	numberOfConfiguredHiddenFields?: Number;
	numberOfContentFields?: Number;
	numberOfEmptyContentFields?: Number;
	isDueDatePast?: boolean;
	isDueSoon?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const filterItemsWithValue = (items: any[], issueFieldsValues: IssueFields) =>
	items.filter((item) => {
		const fieldValue = issueFieldsValues[getLayoutItemId(item)];

		// If the field is not present in the issueFieldsValues, it means the field
		// value is loaded asynchronously. An example of this is the devSummary field.
		if (fieldValue === undefined) {
			return false;
		}

		if (fieldValue && typeof fieldValue === 'object') {
			// If the field is an ADF field, we need to check if it has content
			if (fieldValue.type === 'doc') {
				return isEmpty(fieldValue.content);
			}

			// If the field is an parent link field, we need to check if it has data
			if (
				fieldValue.hasEpicLinkFieldDependency !== undefined &&
				fieldValue.showField !== undefined
			) {
				return isEmpty(fieldValue.data);
			}
		}

		return isEmpty(fieldValue);
	});

const flatteningContentItems = (contentItems: LayoutContainerNode[]): LayoutContainerNode[] =>
	contentItems.flatMap((contentItem) => {
		if (contentItem.type === layoutContainerItemTypes.tab) {
			const contentTabFields = flatteningContentItems(contentItem.items.nodes);

			return contentTabFields;
		}

		return contentItem;
	});

export const useLayoutFieldsMeta = (alwaysPrimaryFieldIds: string[] = []): LayoutFieldsMeta => {
	const items = usePrimarySecondaryItems(alwaysPrimaryFieldIds);

	const { pinnedFields, primaryItems, secondaryItems } = items;
	const issueKey = useIssueKey();
	const [issueFieldsValues] = useFieldsValues(issueKey);
	const [contentItems] = useLayoutContainerByType(issueKey, 'CONTENT');

	return useMemo(() => {
		if (!issueFieldsValues) {
			return {};
		}

		const [hiddenSecondaryFields = [], visibleSecondaryFields = []] = partition(
			secondaryItems,
			(item) => isEmpty(issueFieldsValues[getLayoutItemId(item)]),
		);

		const contentFields = flatteningContentItems(contentItems);

		const emptyContentFields = filterItemsWithValue(contentFields, issueFieldsValues);
		const emptyVisibleFields = filterItemsWithValue(primaryItems, issueFieldsValues);
		const emptyPinnedFields = filterItemsWithValue(pinnedFields, issueFieldsValues);

		const numberOfVisibleFields = primaryItems.length + visibleSecondaryFields.length;
		const dueDateParams = fg('issue_view_bug_fix_due_date_offset_by_a_day')
			? getIsUTCDatePastOrDueSoon(issueFieldsValues.duedate)
			: getIsDatePastOrDueSoon(issueFieldsValues.duedate);

		return {
			numberOfPinnedFields: pinnedFields.length,
			numberOfEmptyPinnedFields: emptyPinnedFields.length,
			numberOfVisibleContextFields: numberOfVisibleFields,
			numberOfEmptyVisibleContextFields: emptyVisibleFields.length,
			numberOfHiddenFields: hiddenSecondaryFields.length,
			numberOfConfiguredHiddenFields: secondaryItems.length,
			numberOfContentFields: contentFields.length,
			numberOfEmptyContentFields: emptyContentFields.length,
			// eslint-disable-next-line jira/ff/no-preconditioning
			...(fg('issue-viewed-event-add-due-date-attributes_lhefn') && issueFieldsValues.duedate
				? dueDateParams
				: {}),
		};
	}, [pinnedFields, primaryItems, secondaryItems, contentItems, issueFieldsValues]);
};
