import { createSelector } from 'reselect';
import { DESCRIPTION } from '@atlassian/jira-issue-view-configurations';
import { fieldEditingAdfValueSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector';
import { isEpicSelector } from '../common/state/selectors/issue-selector';
import { supportsChildCreationSelector } from '../issue-field/state/selectors/hierarchy-level-selector';
import { canCreateIssuesInEpicSelector } from './classic-project-epic-selector';
import { visibleCommentIdsSelector } from './comment-selector';
import { canLinkConfluencePageSelector } from './confluence-app-links-selector';
import { canCreateSubtaskSelector } from './subtasks-selector';

export const improveIssueDropdownPermissionsSelector = createSelector(
	canCreateSubtaskSelector,
	canCreateIssuesInEpicSelector,
	supportsChildCreationSelector,
	isEpicSelector,
	canLinkConfluencePageSelector,
	visibleCommentIdsSelector,
	(state) => fieldEditingAdfValueSelector(DESCRIPTION)(state),
	(
		canCreateSubtask,
		canCreateIssuesInEpic,
		supportsChildCreation,
		isEpic,
		canLinkConfluencePage,
		visibleCommentIds,
		fieldEditingAdfValue,
	) => {
		const shouldShowIssueInEpicButton = canCreateIssuesInEpic && !supportsChildCreation && isEpic;

		const shouldShowCreateSubtaskButton =
			canCreateSubtask && !supportsChildCreation && !shouldShowIssueInEpicButton;

		const hasIssueDescription = Number(fieldEditingAdfValue?.content?.length) > 0;

		return {
			shouldShowCreateSubtaskButton,
			shouldShowIssueInEpicButton,
			supportsChildCreation,
			canLinkConfluencePage,
			visibleCommentIds,
			hasIssueDescription,
		};
	},
);
