import React, { useCallback } from 'react';
import get from 'lodash/get';
import isNaN from 'lodash/isNaN';
import { DatePicker } from '@atlaskit/datetime-picker';
import { convertISODateFormat } from '@atlassian/jira-compatibility';
import { fg } from '@atlassian/jira-feature-gating';
import { standardizeLocale } from '@atlassian/jira-issue-format-date/src/common/utils.tsx';
import { getLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { DEFAULT_WEEK_START_DAY } from '../../common/constants';
import type { JiraDatePickerValue } from '../../common/types';

export type DateFieldEditViewProps = {
	isDisabled?: boolean;
	isInvalid?: boolean;
	autoFocus?: boolean;
	defaultIsOpen?: boolean;
	value: JiraDatePickerValue;
	onChange?: (value: JiraDatePickerValue) => void;
	hideIcon?: boolean;
	label?: string;
};

export const DateFieldEditView = ({
	autoFocus,
	isDisabled,
	isInvalid,
	value,
	defaultIsOpen = true,
	onChange,
	hideIcon,
	label,
}: DateFieldEditViewProps) => {
	const locale = useLocale();

	const handleOnChange = useCallback(
		(isoDateValue?: string | null) => {
			const convertedIso = convertISODateFormat(isoDateValue ?? '');
			if (isNaN(Date.parse(convertedIso))) {
				onChange?.(null);
			} else {
				onChange?.(convertedIso);
			}
		},
		[onChange],
	);

	const standardizedLocale = standardizeLocale(locale);
	// Use of non-standardized locale is intentional, getLocale map accounts for this.
	const startOfWeekDay = get(getLocale(locale), 'options.weekStartsOn', DEFAULT_WEEK_START_DAY);

	return (
		<DatePicker
			spacing="compact"
			testId="issue-field-date.ui.edit.issue-field-date-picker"
			locale={standardizedLocale}
			autoFocus={autoFocus}
			defaultIsOpen={defaultIsOpen}
			isDisabled={isDisabled}
			isInvalid={isInvalid}
			value={value || ''}
			onChange={handleOnChange}
			weekStartDay={startOfWeekDay}
			hideIcon={hideIcon}
			{...(fg('bulk-transitions-fix-a11y-violations') && {
				label,
			})}
		/>
	);
};
