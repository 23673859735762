import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { ReadViewContainer } from '@atlassian/jira-issue-field-inline-edit/src/styled.tsx';
import { LozengeChildNumberFieldContainer } from '@atlassian/jira-issue-field-number/src/ui';
import { NumberView as IssueNumberFieldReadonly } from '@atlassian/jira-issue-field-number/src/ui/view/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { stopPropagation } from '../../common/utils';

type Props = {
	issueKey: IssueKey;
	estimateFieldId: string;
};

export const ReadOnlyEstimate = ({ issueKey, estimateFieldId }: Props) => {
	const [estimateValue] = useFieldValue({ issueKey, fieldKey: estimateFieldId });

	return (
		<Container
			data-testid="issue-line-card.ui.read-only-estimate.readonly-estimate-field"
			role="button"
			onClick={stopPropagation}
			onFocus={stopPropagation}
		>
			{/* @ts-expect-error - TS2741 - Property 'hasValue' is missing in type '{ children: Element; }' but required in type 'Readonly<ThemedOuterStyledProps<ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement> & { hasValue: boolean; }, any>>'. */}
			<LozengeChildNumberFieldContainer>
				<ReadViewContainer>
					<IssueNumberFieldReadonly isEditable={false} value={estimateValue} />
				</ReadViewContainer>
			</LozengeChildNumberFieldContainer>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	paddingLeft: token('space.050', '4px'),
});
