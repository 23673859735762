import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import { useFragment, graphql } from 'react-relay';
import Badge from '@atlaskit/badge';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
// eslint-disable-next-line jira/styled/no-styled-import-alias
import { ReadViewContainer as BaseReadViewContainer } from '@atlassian/jira-issue-field-inline-edit/src/styled.tsx';
import type { originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView$key as OriginalEstimateFragment } from '@atlassian/jira-relay/src/__generated__/originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView.graphql';
import { timeTrackingFormatterWithDefaults } from '@atlassian/jira-time-tracking-formatter/src/main.tsx';
import type { TimeFormat, TimeUnit } from '@atlassian/jira-time-tracking-formatter/src/types.tsx';
import type { OriginalEstimateReadViewProps } from './types';

/**
 * The OriginalEstimateReadView will show a read only view of OriginalEstimate field.
 * @param props [OriginalEstimateReadViewProps](./types.tsx)
 */
export const OriginalEstimateReadView = ({
	fragmentRef,
	enableHover = false,
}: OriginalEstimateReadViewProps) => {
	const testId = 'issue-field-original-estimate-read-view';
	const data = useFragment<OriginalEstimateFragment>(
		graphql`
			fragment originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView on JiraTimeTrackingField {
				originalEstimate {
					timeInSeconds
				}
				timeTrackingSettings {
					workingHoursPerDay
					workingDaysPerWeek
					defaultFormat
					defaultUnit
				}
			}
		`,
		fragmentRef,
	);

	const intl = useIntl();
	const value = data.originalEstimate?.timeInSeconds;
	const readTimeString = useMemo(
		() =>
			timeTrackingFormatterWithDefaults(
				value || 0,
				{
					workingHoursPerDay: data?.timeTrackingSettings?.workingHoursPerDay,
					workingDaysPerWeek: data?.timeTrackingSettings?.workingDaysPerWeek,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					timeFormat: data?.timeTrackingSettings?.defaultFormat as TimeFormat,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					defaultUnit: data?.timeTrackingSettings?.defaultUnit as TimeUnit,
				},
				intl,
			),
		[
			intl,
			data?.timeTrackingSettings?.defaultFormat,
			data?.timeTrackingSettings?.defaultUnit,
			data?.timeTrackingSettings?.workingDaysPerWeek,
			data?.timeTrackingSettings?.workingHoursPerDay,
			value,
		],
	);

	const getRenderView = () => {
		const badge = <Badge testId={`${testId}.badge`}>{readTimeString}</Badge>;

		if (enableHover) {
			return <HoverContainer>{badge}</HoverContainer>;
		}

		return badge;
	};

	if (!data) {
		return <EmptyFieldText />;
	}

	return (
		<ReadViewContainer>
			<BadgeWrapper data-testid="issue-field-original-estimate-readview-full.ui.original-estimate.test-id">
				{getRenderView()}
			</BadgeWrapper>
		</ReadViewContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled(BaseReadViewContainer)({
	paddingRight: token('space.025', '2px'),
	marginRight: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HoverContainer = styled.div({
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:hover > span': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: `${token('color.text', colors.N800)} !important`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: `${token('color.background.neutral.hovered', colors.N90)} !important`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	lineHeight: `${gridSize * 2.5}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BadgeWrapper = styled.div({
	display: 'inline-block',
	position: 'relative',
	marginTop: token('space.negative.075', '-6px'),
	marginBottom: token('space.negative.075', '-6px'),
	marginRight: token('space.negative.025', '-2px'),
	marginLeft: token('space.025', '2px'),
});
