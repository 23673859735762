export const DEFAULT_AGENT = {
	id: 'ai_mate_agent',
	named_id: 'ai_mate_agent',
	name: 'AI Mate',
	description: 'AI Mate',
	creator_type: 'SYSTEM',
	available_plugins: {},
	is_default: true,
	visibility: 'PUBLIC',
} as const;

export const DEFAULT_AGENTS_NAMED_ID_LIST = new Set([
	'decision_director_agent',
	'user_manual_writer_agent',
	'product_requirements_expert_agent',
]);
