import React from 'react';
import { getAnalyticsAttributesFromExtension } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-analytics-attributes-from-extension';
import { ViewAnalyticsWrapper } from '@atlassian/jira-forge-ui-analytics/src/ui/issue-context-panel';
import type { LazyComponentProps } from '@atlassian/jira-forge-ui-async/src/types';
import {
	type LazyIssueContextRenderer,
	AsyncIssueContext,
} from '@atlassian/jira-forge-ui-async/src/ui/issue-context-panel/async';
import { useAnalyticsSource } from '@atlassian/jira-issue-context-service/src/main.tsx';

export const ForgeIssueContext = (props: LazyComponentProps<typeof LazyIssueContextRenderer>) => {
	const source = useAnalyticsSource();

	const { extension } = props;

	return (
		<ViewAnalyticsWrapper
			attributes={{
				source,
				...getAnalyticsAttributesFromExtension(extension),
			}}
			shouldFireInitializedEvent
		>
			<AsyncIssueContext {...props} />
		</ViewAnalyticsWrapper>
	);
};
