// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	ServerRestUser as User,
	User as Voter,
} from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Vote as VoteValue } from '@atlassian/jira-issue-shared-types/src/common/types/issue-type.tsx';

export const FIELD_KEY = 'votes' as const;

export type DisableVotingProps = {
	isDisabled: boolean;
	tooltips?: {
		addVote: string;
		removeVote: string;
	};
};
