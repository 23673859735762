import React, { useCallback } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import { Flex, Box, xcss, Inline, Text } from '@atlaskit/primitives';
import { useActionsCommandPalette } from '@atlassian/jira-command-palette/src/controllers/command-palette/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useLazyLottie } from '../../../common/utils';
import { messages } from './messages';
import { useAnimationData } from './utils';

type Props = {
	onDismiss: () => void;
};

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
export const isMacOs = () => /Mac/i.test(window?.navigator?.platform);

export const Content = ({ onDismiss }: Props) => {
	const isMac = isMacOs();
	const { formatMessage } = useIntl();
	const dataPromise = useAnimationData({ isMac });
	const lottieRef = useLazyLottie<HTMLDivElement>(dataPromise);

	const { open } = useActionsCommandPalette();

	const onTrynowClick = useCallback(
		(_: React.MouseEvent<HTMLButtonElement, MouseEvent>, event: UIAnalyticsEvent) => {
			fireUIAnalytics(event, 'compalSpotlightTryNow');
			onDismiss();
			open();
		},
		[open, onDismiss],
	);

	const onDismissClick = useCallback(
		(_: React.MouseEvent<HTMLButtonElement, MouseEvent>, event: UIAnalyticsEvent) => {
			fireUIAnalytics(event, 'compalSpotlightDismiss');
			onDismiss();
		},
		[onDismiss],
	);

	return (
		<Flex direction="column" xcss={wrapperStyles}>
			<Box xcss={animationStyles} ref={lottieRef} />
			<Box paddingBlock="space.200" paddingInline="space.250">
				<Heading size="xsmall">{formatMessage(messages.title)}</Heading>
				<Box paddingBlockEnd="space.100" paddingBlockStart="space.200">
					{formatMessage(messages.description, {
						strong: (...chunks: React.ReactNode[]) => <Text as="strong">{chunks.join('')}</Text>,
						isMac,
					})}
				</Box>
				<Inline alignInline="end" space="space.100">
					<Button appearance="subtle" onClick={onDismissClick}>
						{formatMessage(messages.dismissButton)}
					</Button>
					<Button onClick={onTrynowClick} appearance="discovery">
						{formatMessage(messages.trynowButton)}
					</Button>
				</Inline>
			</Box>
		</Flex>
	);
};

const wrapperStyles = xcss({
	width: '382px',
});

const animationStyles = xcss({
	height: '210px',
});
