// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noValue: {
		id: 'issue.common.multi-line-text.text-for-edit-view-when-no-value-is-provided',
		defaultMessage: 'None',
		description: 'The value to display in multiline fields when there is no value',
	},
});
