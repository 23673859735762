import React, { type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Checkbox } from '@atlaskit/checkbox';
import EditorInfoIcon from '@atlaskit/icon/core/migration/information--editor-info';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

type Props = {
	isChecked: boolean;
	onChange: (_: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => void;
	onShow: (analyticsEvent: UIAnalyticsEvent) => void;
};

export const CreateReciprocalLink = ({ isChecked, onChange, onShow }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<CreateReciprocalLinkCheckboxContainer>
			<Checkbox
				isChecked={isChecked}
				onChange={onChange}
				label={formatMessage(messages.createReciprocalLinkCheckboxLabel)}
				name="create-reciprocal-link"
			/>
			<Tooltip
				content={formatMessage(messages.createReciprocalLinkCheckboxTooltip)}
				onShow={onShow}
				position="bottom"
			>
				<EditorInfoIcon color="currentColor" spacing="spacious" LEGACY_size="medium" label="" />
			</Tooltip>
		</CreateReciprocalLinkCheckboxContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CreateReciprocalLinkCheckboxContainer = styled.div({
	flexGrow: 1,
	display: 'flex',
});
