import React from 'react';

import Icon from '@atlaskit/icon/core/globe';
import { fg } from '@atlaskit/platform-feature-flags';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';
import { token } from '@atlaskit/tokens';

import { type CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import type { EditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { createEditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { sliceOrNodeToMarkdown } from '../../../config-items/slice-or-node-to-markdown';
import { streamConvoAI } from '../../../provider/prompt-requests/convo-ai';
import { streamXPGenAI } from '../../../provider/prompt-requests/xp-gen-ai';
import { createInsertBelow, createReplace } from '../../config-item-actions/markdown';
import { isMinLength } from '../../config-item-visible/visibility-utils';
import { languageMessages, translateToMessages } from '../../translations/messages';
import { getSortedSupportedTranslationLanguages } from '../../translations/utils';

const SELECTION_TYPE = 'empty';

const supportedTranslationLanguages = getSortedSupportedTranslationLanguages();

export const translationEmptyConfigItems = supportedTranslationLanguages
	.map((language) => {
		const lang = languageMessages[language];
		if (!lang) {
			return false;
		}
		return createEditorPluginAIConfigItemMarkdown({
			key: `Translate to ${language}` as CONFIG_ITEM_KEYS,
			title: translateToMessages[`${language}Title`],
			description: translateToMessages[`${language}Description`],
			selectionType: 'empty',
			nestingConfig: {
				parentTestId: 'translate-to',
				parentTitle: editorExperiment('platform_editor_ai_command_palette_post_ga', 'test')
					? languageMessages.nestingParentTitlePostGA
					: languageMessages.nestingParentTitle,
				shortTitle: lang,
			},
			getInitialContext: ({ editorView, intl, updateIdMap, mentionMap }) => {
				const { markdown, contentStatistics } = sliceOrNodeToMarkdown({
					node: editorView.state.doc,
					editorView,
					convertTo: 'markdown-plus',
					updateIdMap,
					selectionType: SELECTION_TYPE,
					mentionMap,
				});

				return {
					document: markdown,
					userLocale: intl.locale,
					intentSchemaId: 'translate_intent_schema.json',
					contentStatistics,
					targetTranslationLanguage: lang.defaultMessage,
				};
			},
			triggerPromptRequest({ initialContext, editorSchema, analyticsContext }) {
				if (fg('platform_editor_ai_assistance_service')) {
					return streamConvoAI({
						aiSessionId: analyticsContext?.aiSessionId,
						transformationSubtype: lang.defaultMessage,
						userLocale: initialContext.userLocale,
						intentSchemaId: 'translate_intent_schema.json',
						editorSchema,
						fullDocument: initialContext.document,
					});
				}

				return streamXPGenAI({
					targetTranslationLanguage: lang.defaultMessage,
					userLocale: initialContext.userLocale,
					editorSchema,
					intentSchemaId: 'translate_intent_schema.json',
					contextList: [
						{
							type: 'ADF_MARKDOWN_V1',
							entity: initialContext.document,
							relationship: 'FULL_CONTENT',
						},
					],
				});
			},
			isVisible: ({ editorView, positions }) =>
				isMinLength({ editorView, minLength: 1, positions }),
			icon: () => <Icon label="" color={token('color.icon', '#44546F')} />,
			actions: [
				createInsertBelow({ appearance: 'secondary' }),
				createReplace({ appearance: 'primary' }),
			],
		});
	})
	.filter(Boolean) as EditorPluginAIConfigItemMarkdown<'empty'>[];
