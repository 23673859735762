import React from 'react';
import { SpotlightTarget } from '@atlaskit/onboarding';
import type { ApplicationKey } from '@atlassian/jira-common-constants/src/application-keys';
import { GIVE_FEEDBACK } from '@atlassian/jira-issue-view-common-constants/src/view-constants';
import FeedbackButton from '@atlassian/jira-issue-view-feedback-button';
import { feedbackComponentsProps } from './constants';
import type { Props } from './types';

export const canRenderFeedbackForApplication = (applicationKey: ApplicationKey): boolean =>
	Boolean(feedbackComponentsProps[applicationKey]);

const Feedback = ({ applicationKey, isCompact = false }: Props) => {
	if (applicationKey == null || !canRenderFeedbackForApplication(applicationKey)) {
		return null;
	}
	const feedbackProps = feedbackComponentsProps[applicationKey];
	return (
		<SpotlightTarget name={GIVE_FEEDBACK}>
			<FeedbackButton {...feedbackProps} isCompact={isCompact} />
		</SpotlightTarget>
	);
};

export default Feedback;
