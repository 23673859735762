import { useEffect, useMemo, useCallback } from 'react';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { Payload } from '@atlassian/jira-issue-create/src/common/types';
import { joinSuggestionsWithPrioritisedRecentlySelected } from '@atlassian/jira-issue-fields-suggestions-cache/src/common/utils.tsx';
import { useParentFieldSuggestionsCache } from '@atlassian/jira-issue-fields-suggestions-cache/src/services/index.tsx';
import type { IssueParent } from '@atlassian/jira-issue-parent-services/src/services/types.tsx';
import { JSW_CREATE_ISSUE_DIALOG } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/constants';
import type { GICPayload } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/types.tsx';

export const globalIssueCreateTrigger = (
	projectId?: string | null,
	issueTypeId?: string,
	openIssueCreateModal?: (data: GICPayload) => void,
) => {
	// If window.WRM is not available yet, just ignore the action.

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (openIssueCreateModal && window.IssueCreate && window.IssueCreate.Visibility) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const { isEnabled } = window.IssueCreate.Visibility;
		if (isEnabled) {
			const dataPayload: Payload = {};
			if (projectId !== null && projectId !== undefined) {
				dataPayload.project = { projectId };
			}
			if (issueTypeId !== null && issueTypeId !== undefined) {
				dataPayload.issueType = { issueTypeId };
			}
			openIssueCreateModal({
				payload: dataPayload,
				triggerPointKey: JSW_CREATE_ISSUE_DIALOG,
			});
			return;
		}
	}

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (window.WRM) {
		// eslint-disable-next-line jira/wrm/no-wrm-require, jira/jira-ssr/no-unchecked-globals-usage
		window.WRM.require(
			// eslint-disable-next-line jira/wrm/no-wr-prefix
			['wr!com.atlassian.jira.jira-quick-edit-plugin:quick-create-issue'],
			() => {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				const form = window.JIRA.Forms.createCreateIssueForm({
					pid: projectId,
					issueType: issueTypeId,
				});

				const dialog = form.asDialog({
					id: 'software-create-issue-dialog',
				});

				dialog.show();
			},
		);
	}
};

export const useSyncIssueParentsToParentFieldSuggestionCache = ({
	isSimplifiedProject,
	hasFetched,
	issueParents,
	isOpen,
}: {
	isSimplifiedProject: boolean;
	hasFetched: boolean;
	issueParents: IssueParent[];
	isOpen: boolean;
}) => {
	const issueKey = useIssueKey();
	const { setPrefetchedParents } = useParentFieldSuggestionsCache({
		issueKey,
	});

	useEffect(() => {
		if (isOpen && hasFetched) {
			setPrefetchedParents(issueParents);
		}
	}, [hasFetched, isSimplifiedProject, issueParents, setPrefetchedParents, isOpen]);
};

export const useIssueParentsWithRecentlySelected = ({
	issueParents,
	hasFetched,
}: {
	issueParents: IssueParent[];
	hasFetched: boolean;
}) => {
	const issueKey = useIssueKey();
	const { prefetchedParents, recentlySelectedParents } = useParentFieldSuggestionsCache({
		issueKey,
	});

	return useMemo(
		() =>
			joinSuggestionsWithPrioritisedRecentlySelected({
				recentlySelected: recentlySelectedParents,
				prefetched: hasFetched ? issueParents : prefetchedParents || [],
			}),
		[hasFetched, issueParents, prefetchedParents, recentlySelectedParents],
	);
};

export const useAddToRecentlySelectedParents = ({
	issueParents,
}: {
	issueParents: IssueParent[];
}) => {
	const issueKey = useIssueKey();
	const { setRecentlySelectedParent } = useParentFieldSuggestionsCache({
		issueKey,
	});

	return useCallback(
		(parentId: string) => {
			const parent = issueParents.find((p) => p.id === parentId);
			parent && setRecentlySelectedParent(parent);
		},
		[issueParents, setRecentlySelectedParent],
	);
};

export const useSyncLoadingState = ({
	isLoading,
	setIsLoading,
	issueParentsWithRecentlySelected,
}: {
	isLoading: boolean;
	setIsLoading: (isLoading: boolean) => void;
	issueParentsWithRecentlySelected: IssueParent[];
}) => {
	useEffect(() => {
		if (issueParentsWithRecentlySelected.length && isLoading) {
			setIsLoading(false);
		}
	}, [isLoading, issueParentsWithRecentlySelected.length, setIsLoading]);
};
