import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { colors } from '@atlaskit/theme';
// eslint-disable-next-line import/order
import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { CustomerServiceCustomDetailsEntityType } from '@atlassian/jira-relay/src/__generated__/tableCustomDetailsQuery.graphql';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { ExperienceSuccess, type UFOExperience } from '@atlassian/ufo';
import messages from './messages';

export const EMPTY_STATE_DESCRIPTION_TESTID =
	'servicedesk-customer-service-issue-view-panel.common.ui.details.empty-state.description';

const getDescription = (isJiraAdmin: boolean, context: CustomerServiceCustomDetailsEntityType) => {
	if (isJiraAdmin) {
		switch (context) {
			case 'CUSTOMER':
				return messages.descriptionCustomer;
			case 'ORGANIZATION':
				return messages.descriptionOrganization;
			case 'ENTITLEMENT':
				return messages.descriptionEntitlement;
			default:
				// This should never return but formatMessage requires a non null value
				return messages.descriptionCustomer;
		}
	}

	switch (context) {
		case 'CUSTOMER':
			return messages.notAdminDescriptionCustomer;
		case 'ORGANIZATION':
			return messages.notAdminDescriptionOrganization;
		case 'ENTITLEMENT':
			return messages.notAdminDescriptionEntitlement;
		default:
			return messages.notAdminDescriptionCustomer;
	}
};

export const EmptyState = ({
	context,
	detailsUrl,
	entityExperience,
}: {
	context: CustomerServiceCustomDetailsEntityType;
	detailsUrl?: string;
	entityExperience?: UFOExperience;
}) => {
	const isJiraAdmin = useIsAdmin();
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onLinkClicked = () =>
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'createCustomerContextDetails', {
			context,
		});

	return (
		<Container>
			<Description data-testid={EMPTY_STATE_DESCRIPTION_TESTID}>
				{formatMessage(getDescription(isJiraAdmin, context), {
					a: (children: ReactNode) =>
						isJiraAdmin && detailsUrl ? (
							<a
								onClick={onLinkClicked}
								href={detailsUrl}
								data-testid="servicedesk-customer-service-issue-view-panel.common.ui.details.empty-state.button"
							>
								{children}
							</a>
						) : (
							children
						),
				})}
			</Description>
			{entityExperience && <ExperienceSuccess experience={entityExperience} />}
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	margin: `${token('space.300', '8px')} 0`,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	minHeight: '140px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.div({
	textAlign: 'center',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 37}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N300),
	margin: `${token('space.100', '8px')} 0`,
});
