import { statusCategoryForId } from '@atlassian/jira-common-constants/src/status-categories';
import type { ServerAssociatedIssue } from '@atlassian/jira-issue-shared-types/src/common/types/associated-issue-type.tsx';
import { getIssueHtmlUrl } from '@atlassian/jira-issue-view-services/src/issue/issue-urls';
import { transformUser } from '@atlassian/jira-issue-view-services/src/issue/user-transformer';
import { type BaseUrl, toBaseUrl, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (childIssueFromServer: ServerAssociatedIssue, baseUrl: BaseUrl) => {
	const { id, key, fields } = childIssueFromServer;
	return {
		id,
		issueKey: key,
		issueLink: getIssueHtmlUrl(toBaseUrl(baseUrl), toIssueKey(key)),
		issueSummary: fields.summary,
		issueTypeIconUrl: fields.issuetype.iconUrl,
		issueTypeName: fields.issuetype.name,
		issuePriorityUrl: (fields.priority && fields.priority.iconUrl) || undefined,
		issuePriorityName: (fields.priority && fields.priority.name) || undefined,
		issuePrioritySequence: (fields.priority && fields.priority.sequence) || undefined,
		statusCategory: statusCategoryForId(fields.status.statusCategory.id),
		statusCategoryId: fields.status.statusCategory.id,
		statusName: fields.status.name,
		statusId: fields.status.id,
		assigneeUrl: fields.assignee && transformUser(fields.assignee).avatarUrl,
		assigneeDisplayName: fields.assignee && transformUser(fields.assignee).displayName,
		isLoading: false,
		errorType: undefined,
	};
};
