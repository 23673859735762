import React, { Component } from 'react';
import type { Store } from 'redux';
import AppBase from '@atlassian/jira-app-base';
import isShallowEqual from '@atlassian/jira-common-util-is-shallow-equal';
import { StartMetric } from '@atlassian/jira-development-common/src/performance-analytics/view.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { devDetailsTimeToLoad } from '../common/analytics/performance-analytics';
import type { AppProps } from './model/types';
import rootEpic from './ops';
import createStore from './state';
import { initializeAppProps } from './state/actions/app';
import type { State } from './state/reducers/types';
import DevelopmentDetails from './view';

// eslint-disable-next-line jira/react/no-class-components
export default class DevelopmentDetailsDialog extends Component<AppProps> {
	constructor(props: AppProps) {
		super(props);
		this.store = createStore(rootEpic);
		this.dispatchProps();
	}

	componentDidUpdate(prevProps: AppProps) {
		if (isShallowEqual(prevProps, this.props)) {
			return;
		}
		this.dispatchProps();
	}

	dispatchProps() {
		const { onCloseDialog, ...stateProps } = this.props;
		this.store.dispatch(initializeAppProps(stateProps));
	}

	store: Store<State>;

	render() {
		return (
			<AppBase id="developmentDetailsDialog" store={this.store}>
				<ReportErrors id="developmentDetailsDialog" packageName="jiraDevelopmentDetails">
					<StartMetric metric={devDetailsTimeToLoad} />
					<DevelopmentDetails {...this.props} />
				</ReportErrors>
			</AppBase>
		);
	}
}
