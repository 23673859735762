import React from 'react';
import { Inline } from '@atlaskit/primitives';
import ShowMorePopup, { type Props, type MinimumItemData } from './show-more-popup';

const ListWithPopup = <TData extends MinimumItemData>({
	items,
	ItemComponent,
	maxLimit,
	initialIsOpen,
	...restProps
}: Props<TData>) => {
	if (items.length === 0) {
		return null;
	}

	const [firstItem, ...restItems] = items;
	return (
		<Inline space="space.100" alignBlock="center" shouldWrap>
			<ItemComponent {...firstItem} />
			{restItems.length >= 1 && (
				<ShowMorePopup
					items={restItems}
					maxLimit={maxLimit}
					ItemComponent={ItemComponent}
					initialIsOpen={initialIsOpen}
					{...restProps}
				/>
			)}
		</Inline>
	);
};

export default ListWithPopup;
