import uniqBy from 'lodash/uniqBy';
import { toIssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ServerResponse, Labels, IssueOptionType } from './types';

export const transformOptions = ({ sections }: ServerResponse, labels: Labels) => {
	const historySearch = sections[0];
	const currentSearch = sections[1];
	const historySearchIssues = historySearch.issues;
	const currentSearchissues = currentSearch ? currentSearch.issues : [];
	const issues = uniqBy([...historySearchIssues, ...currentSearchissues], 'key');

	return [
		{
			label: currentSearch ? labels.matchingIssues : labels.recentIssues,
			options: issues.map<IssueOptionType>((issue) => ({
				issueId: toIssueId(String(issue.id)),
				value: issue.key,
				issueKey: issue.key,
				summary: issue.summaryText,
				imageUrl: issue.img,
			})),
		},
	];
};
