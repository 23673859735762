import { useRelayEnvironment } from 'react-relay';
import { ff } from '@atlassian/jira-feature-flagging';
import fetchIssueAggData from '@atlassian/jira-issue-fetch-services/src/services/issue-agg-data/index.tsx';
import fetchIssueGiraData from '@atlassian/jira-issue-fetch-services/src/services/issue-gira-data/index.tsx';
import { fetchIssueGiraNonCriticalData } from '@atlassian/jira-issue-fetch-services/src/services/issue-gira-non-critical-data/index.tsx';
import type { GraphQlResponse as GiraResponse } from '@atlassian/jira-issue-view-common-types/src/gira';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type PrefetchedResourceManager from '../prefetched-resource-manager';
import { useSelfClearingPrefetchedResourceManager } from '../prefetched-resource-manager';
import { getCacheEntry } from './utils';

/**
 * Wrapper for useSelfClearingPrefetchedResourceManager which will perform network fetches
 * initially and when the issue-key changes (in the render phase).
 *
 * Only ONE self-clearing PrefetchedResourceManager hooks should be present per-issue-view app
 * @param issueKey when this updates
 */
export const useLazyLoadingResourceManager = (issueKey: string) => {
	const cloudId = useCloudId();
	const environment = useRelayEnvironment();

	const onIssueKeyChange = ({
		prefetchedResourceManager,
	}: {
		prefetchedResourceManager: PrefetchedResourceManager;
	}) => {
		const getCachedGiraDataPromise = () => {
			const giraPromise = getCacheEntry(issueKey);
			if (giraPromise) {
				return giraPromise;
			}

			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return fetchIssueGiraData('', issueKey) as Promise<GiraResponse>;
		};

		const giraPromise = getCachedGiraDataPromise();

		prefetchedResourceManager.setGiraPromise(giraPromise);

		const aggPromise = fetchIssueAggData(issueKey, cloudId, environment);
		prefetchedResourceManager.setAggPromise(aggPromise);

		if (ff('issue-view-remove-connect-operations-from-critical-fetch_vtk4w')) {
			const giraNonCriticalPromise = fetchIssueGiraNonCriticalData(issueKey);
			prefetchedResourceManager.setGiraNonCriticalPromise(giraNonCriticalPromise);
		}
	};

	return useSelfClearingPrefetchedResourceManager(issueKey, onIssueKeyChange);
};
