import { createSelector } from 'reselect';
import { entitiesSelector } from '../common/state/selectors/issue-selector';
import { canLinkIssuesSelector } from './issue-actions-permissions-selector';
import { isIssueLinksEnabledSelector } from './issue-links-selector';

export const jiraAppLinksStateSelector = createSelector(
	entitiesSelector,
	(entities) =>
		entities.jiraAppLinks || {
			status: 'NOT_LOADED',
			remoteLinks: [],
		},
);

export const jiraAppLinksFetchStatusSelector = createSelector(
	jiraAppLinksStateSelector,
	(linksState) => linksState.status,
);

export const canLinkJiraRemoteIssuesSelector = createSelector(
	isIssueLinksEnabledSelector,
	canLinkIssuesSelector,
	(hasJiraAppLinksInTenant, isIssueLinksEnabled) => hasJiraAppLinksInTenant && isIssueLinksEnabled,
);

export const jiraAppLinksSelector = createSelector(
	jiraAppLinksStateSelector,
	(linksState) => linksState.remoteLinks,
);
