import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests';
import {
	toConfluencePageType,
	type ConfluencePage,
} from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type';
import type { FailedRemoteLink } from '@atlassian/jira-issue-shared-types/src/common/types/remote-link-error-type';
import {
	toConfluencePageId,
	toDisplayName,
	toFailedRemoteLinkId,
	toHref,
	toRemoteLinkGlobalId,
	type Href,
	type IssueId,
} from '@atlassian/jira-shared-types/src/general';

// NOTE: This api is copied from  { createConfluencePageLinkRequest } from '@atlassian/jira-issue-view/src/epics/confluence-pages-server';
// Ticket for implementing a better solution that doesn't require duplicate code: https://hello.jira.atlassian.cloud/browse/CBT-4078

type ServerResponse = {
	id: string;
	globalId: string;
	object: {
		title: string; // Page title,
		url: string;
		icon: {
			title: string; // Page or blogpost
		};
		status: {
			resolved: boolean; // If all data was fetched,
			icon: {
				title: string; // Display name,
				url16x16: string; // Display avatar or repair link if unresolved
			};
		};
	};
};

const transformConfluencePage = (response: ServerResponse): ConfluencePage | FailedRemoteLink => {
	if (response.object.status.resolved) {
		const confluencePage: ConfluencePage = {
			id: toConfluencePageId(response.id),
			globalId: toRemoteLinkGlobalId(response.globalId),
			href: toHref(response.object.url),
			title: response.object.title,
			type: toConfluencePageType(response.object.icon.title),
			lastUpdatedContext: {
				user: {
					displayName: toDisplayName(response.object.status.icon.title),
					avatarUrl: toHref(response.object.status.icon.url16x16),
				},
			},
		};
		return confluencePage;
	}
	const failedRemoteLink: FailedRemoteLink = {
		id: toFailedRemoteLinkId(response.id),
		error: response.object.status.icon.url16x16 ? 'APPLINK_REQ_AUTH' : 'REMOTE_SERVER',
		link: {
			id: toFailedRemoteLinkId(response.id),
			globalId: toRemoteLinkGlobalId(response.globalId),
			href: toHref(response.object.url),
		},
		repairLink: {
			href: toHref(response.object.status.icon.url16x16),
		},
	};
	return failedRemoteLink;
};

export const createConfluencePageLinkRequest = ({
	issueId,
	pageHref,
}: {
	issueId: IssueId;
	pageHref: Href;
}) =>
	performPostRequest(
		`/rest/confluenceIssueLink/1/confluence?issueId=${issueId}&pageUrl=${pageHref}`,
		{
			body: JSON.stringify({}),
		},
	).then(transformConfluencePage);
