import { ff } from '@atlassian/jira-feature-flagging';

export const isModernGlobalSubtaskIssueCreateTriggerEnabled = () =>
	ff('project.config.pce.modern.global-subtask-issue-create-trigger', false);

export const isModernGlobalIssueCreateTriggerEnabled = () =>
	ff('project.config.pce.modern.global-issue-create.trigger', false);

export const isModernGICEnabledForSubtask = () =>
	isModernGlobalIssueCreateTriggerEnabled() && isModernGlobalSubtaskIssueCreateTriggerEnabled();

export const isCustomMessageForLinkingWithoutPermissionsEnabled = () =>
	ff('issue.details.custom-message-for-linking-with-no-permissions_k7qsn', false);

export const isChildIssuePanelConfigurationEnabled = () =>
	ff('configurable-child-issues-panel_3ht06', false);
