import { useState, useMemo, useEffect, useCallback } from 'react';
import {
	getSessionStorageData,
	handleDataRequest,
	setSessionStorageData,
	updateBus,
} from './utils';

/**
 * Wires up cache in session storage and user function
 * @param storageKey - key for a data
 * @param defaultValue - default value in case there is nothing in the cache
 * @param reader - function to read value if there is nothing in the cache or undefined to skip reading
 */
export const usePersistentlyCachedRequest = <T,>(
	storageKey: string,
	defaultValue: T,
	reader: undefined | false | (() => T | Promise<T>),
): [T, (newValue: T) => void] => {
	const cachedValue = useMemo(() => getSessionStorageData<T>(storageKey), [storageKey]);

	const [{ value, storageKey: storedKey }, setStoredValue] = useState(() => ({
		storageKey,
		value: cachedValue,
	}));

	if (storedKey !== storageKey) {
		// yes, you can do it
		setStoredValue({
			storageKey,
			value: cachedValue,
		});
	}

	const setNewValue = useCallback(
		(newValue: T) => {
			setSessionStorageData(storageKey, newValue);
			updateBus.emit(storageKey, newValue);
		},
		[storageKey],
	);

	// note - updating value is done via effect and affects all consumers
	useEffect(
		() =>
			updateBus.subscribe(storageKey, (newValue: T) =>
				setStoredValue({
					storageKey,
					value: newValue,
				}),
			),
		[storageKey],
	);

	useEffect(() => {
		if (!reader) {
			return;
		}
		if (cachedValue === undefined) {
			handleDataRequest(storageKey, reader);
		}
	}, [reader, storageKey, cachedValue]);

	return useMemo(() => [value ?? defaultValue, setNewValue], [value, defaultValue, setNewValue]);
};
