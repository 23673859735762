import React, { useCallback, useEffect, useState } from 'react';
import { useFragment, graphql } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { DateInlineEditView } from '@atlassian/jira-issue-field-date-inline-edit-full/src/ui/date/index.tsx';
import type { AggJiraDate } from '@atlassian/jira-issue-field-date-inline-edit-full/src/ui/date/types.tsx';
import { useStatusField } from '@atlassian/jira-issue-field-status/src/services/use-status-service/index.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import { IssueViewFieldHeading } from '@atlassian/jira-issue-view-layout-field-heading/src/ui/index.tsx';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import type { ui_issueViewLayoutDateField_IssueViewDateField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutDateField_IssueViewDateField.graphql';

export interface IssueViewDateFieldProps {
	area?: Area;
	fragmentKey: ui_issueViewLayoutDateField_IssueViewDateField$key;
	includeUrgencyTreatment?: boolean;
}

export const IssueViewDateField = ({
	area,
	fragmentKey,
	includeUrgencyTreatment,
}: IssueViewDateFieldProps) => {
	const issueId = useIssueId();
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();

	const data = useFragment<ui_issueViewLayoutDateField_IssueViewDateField$key>(
		graphql`
			fragment ui_issueViewLayoutDateField_IssueViewDateField on JiraDatePickerField {
				...ui_issueViewLayoutFieldHeading_IssueViewFieldHeading
				...date_issueFieldDateInlineEditFull_DateInlineEditView_fragmentRef
				fieldId
				type
				__typename
			}
		`,
		fragmentKey,
	);

	const onSubmit = useCallback(
		(value: AggJiraDate) => {
			issueId &&
				fieldChangeRequested(issueId, data.fieldId, value, undefined, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChangeRequested, issueId],
	);

	const onSubmitSucceeded = useCallback(
		(value: AggJiraDate) => {
			issueId &&
				fieldChanged(issueId, data.fieldId, value, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChanged, issueId],
	);

	const onSubmitFailed = useCallback(
		() => issueId && fieldChangeFailed(issueId, data.fieldId),
		[data.fieldId, fieldChangeFailed, issueId],
	);

	return (
		<Box testId={`issue.views.field.date-inline-edit.${data.fieldId}`}>
			<IssueViewFieldHeading
				area={area}
				fragmentKey={data}
				testId={`issue.issue-view-layout.issue-view-date-field.${data.fieldId}`}
			>
				{/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */}
				<Box xcss={fieldWrapperStyles} testId="issue-field-date.ui.issue-field-date--container">
					<DateInlineEditView
						fragmentRef={data}
						onSubmit={onSubmit}
						onSubmitSucceeded={onSubmitSucceeded}
						onSubmitFailed={onSubmitFailed}
						includeUrgencyTreatment={includeUrgencyTreatment}
					/>
				</Box>
			</IssueViewFieldHeading>
		</Box>
	);
};

// Extracting new hook deps into this component so it's all feature gated.
// TODO: Merge this into IssueViewDateField when cleaning up `issue_view_due_date_colored_field_targeting_gate`
export const IssueViewDateFieldWithUrgencyTreatment = (props: IssueViewDateFieldProps) => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);

	const [{ value: currentStatus }] = useStatusField({
		issueKey,
	});

	const [isStatusNotDone, setIsStatusNotDone] = useState(false);

	useEffect(() => {
		if (currentStatus?.statusCategory.id !== 3) {
			setIsStatusNotDone(true);
		} else {
			setIsStatusNotDone(false);
		}
	}, [currentStatus?.statusCategory.id]);

	return (
		<IssueViewDateField
			{...props}
			includeUrgencyTreatment={projectType !== 'service_desk' && isStatusNotDone}
		/>
	);
};

const fieldWrapperStyles = xcss({
	marginLeft: 'space.negative.100',
	marginRight: 'space.100',
});
