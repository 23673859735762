import React from 'react';
import memoizeOne from 'memoize-one';
import Avatar from '@atlaskit/avatar';
import { ff } from '@atlassian/jira-feature-flagging';
import type {
	ServerSuggestions,
	SelectValueShape,
} from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types';
import { type AccountId, toAccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';

export type StateUser = {
	displayName: string;
	avatarUrls: {
		[key: string]: string;
	};
	id: AccountId | null;
	accountId?: AccountId | null;
	email?: string;
};

export type ServerUser = {
	displayName: string;
	avatarUrls: {
		[key: string]: string;
	};
	accountId: AccountId | null;
	emailAddress?: string;
};
export type UserResponse = {
	users?: ServerUser[];
};

export const avatarSize = '24x24';

export const transformToStateValue = memoizeOne(
	(value: SelectValueShape[]): StateUser[] =>
		value &&
		value.map((item) => ({
			displayName: item.content,
			accountId: item.accountId,
			id: toAccountId(String(item.value)),
			avatarUrls: {
				[avatarSize]: String(item.avatarUrl),
			},
			...(ff('relay-migration-issue-fields-multi-user') ? { email: item.email } : {}),
		})),
);

export const transformFromStateValue = memoizeOne(
	(value: StateUser[] | null): SelectValueShape[] => {
		// Custom fields can have a null value
		if (!value) {
			return [];
		}
		return value.map((item) => ({
			content: item.displayName,
			value: String(item.accountId),
			key: item.accountId,
			avatarUrl: item.avatarUrls && item.avatarUrls[avatarSize],
			accountId: item.accountId,
			elemBefore: (
				<Avatar
					size="xsmall"
					{...(isVisualRefreshEnabled() ? { borderColor: 'transparent' } : {})}
					src={item.avatarUrls && item.avatarUrls[avatarSize]}
				/>
			),
			...(ff('relay-migration-issue-fields-multi-user')
				? { id: item.id ?? undefined, email: item.email ?? undefined }
				: {}),
		}));
	},
);

export const transformSuggestions = memoizeOne(
	(response: ServerUser[] | null): ServerSuggestions => {
		const items: ServerUser[] = response ?? [];

		return [
			{
				items: items.map((item) => ({
					content: item.displayName,
					value: String(item.accountId),
					accountId: item.accountId,
					avatarUrl: item.avatarUrls[avatarSize],
					email: item.emailAddress,
				})),
			},
		];
	},
);
