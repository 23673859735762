import { useReducer, useEffect, useCallback, useRef } from 'react';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { Props, State, FetchSuggestions, SearchAction } from './types';
import {
	transformRecentOptions,
	transformSearchOptions,
	getSearchUrl,
	confluenceSearchReducer,
	getFilteredRecentOptions,
	mergeResponse,
} from './utils';

export const DEBOUNCE_TIME = 300;
const DEFAULT_COUNTER = {
	value: 0,
	input: '',
} as const;

// @ts-expect-error - TS7006 - Parameter 'options' implicitly has an 'any' type. | TS7006 - Parameter 'resolve' implicitly has an 'any' type. | TS7006 - Parameter 'reject' implicitly has an 'any' type.
const searchCall = (options, resolve, reject) => {
	const { inputValue, appId, spaceKey } = options;
	const modifiedSpaceKey = spaceKey !== '' ? `"${spaceKey}"` : ''; // Wrap spaceKey into quotes if spaceKey is passed otherwise it will fail for special characters in spaceKey
	performGetRequest(getSearchUrl(inputValue, appId, modifiedSpaceKey))
		.then(resolve)
		.catch(reject);
};

const debouncedFetch = debounce(searchCall, DEBOUNCE_TIME, {
	leading: false,
});

const ConfluenceSearchService = (props: Props) => {
	const {
		appId,
		spaceKey = '',
		children,
		recentOptions,
		labels,
		linkedPages,
		mentionedPages,
	} = props;

	const recentTransformedOption = transformRecentOptions(
		recentOptions,
		labels.recentPages,
		spaceKey,
	);

	// @ts-expect-error - TS2554 - Expected 3 arguments, but got 2.
	const [state, dispatch] = useReducer<State, SearchAction>(confluenceSearchReducer, {
		loading: false,
		options: recentTransformedOption,
		error: null,
		type: 'recent',
	});

	const counter = useRef<{
		value: number;
		input: string;
	}>(DEFAULT_COUNTER);

	useEffect(() => {
		// Recent has been changed
		// @ts-expect-error - TS2339 - Property 'type' does not exist on type 'never'. | TS2339 - Property 'options' does not exist on type 'never'.
		if (state.type === 'recent' && !isEqual(state.options, recentTransformedOption)) {
			// @ts-expect-error - TS2554 - Expected 0 arguments, but got 1.
			dispatch({
				type: 'SEARCH_API_RESET',
				payload: {
					options: recentTransformedOption,
				},
			});
		}
		// @ts-expect-error - TS2339 - Property 'options' does not exist on type 'never'. | TS2339 - Property 'type' does not exist on type 'never'.
	}, [recentTransformedOption, state.options, state.type]);

	const { loading, error, type, options } = state;
	const clearState = useCallback(() => {
		// @ts-expect-error - TS2554 - Expected 0 arguments, but got 1.
		dispatch({
			type: 'SEARCH_API_CLEAR',
			payload: {
				options: [],
			},
		});
		counter.current = DEFAULT_COUNTER;
	}, []);

	const handleResponse = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'response' implicitly has an 'any' type.
		(response, currentCounter: number) => {
			if (currentCounter === counter.current.value) {
				const filteredRecent = getFilteredRecentOptions(
					recentOptions,
					counter.current.input,
					spaceKey,
				);

				const searchResults = transformSearchOptions(
					response.result,
					labels.matchingPages,
					linkedPages,
					mentionedPages,
					filteredRecent,
				);
				// @ts-expect-error - TS2554 - Expected 0 arguments, but got 1.
				dispatch({
					type: 'SEARCH_API_DONE',
					payload: {
						options: mergeResponse(filteredRecent, searchResults),
					},
				});
			}
		},
		[labels.matchingPages, linkedPages, mentionedPages, recentOptions, spaceKey],
	);
	const handleError = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'fetchError' implicitly has an 'any' type.
		(fetchError, currentCounter: number) => {
			fireErrorAnalytics({
				error: fetchError,
				meta: {
					id: 'getConfluenceSearchService',

					packageName: 'jiraIssueView',
					teamName: 'bento',
				},
				sendToPrivacyUnsafeSplunk: true,
			});
			if (currentCounter === counter.current.value) {
				const filteredRecent = getFilteredRecentOptions(
					recentOptions,
					counter.current.input,
					spaceKey,
				);
				const searchResults = transformSearchOptions([], labels.matchingPages);
				const mergedOptions = mergeResponse(filteredRecent, searchResults);
				counter.current = DEFAULT_COUNTER;
				// @ts-expect-error - TS2554 - Expected 0 arguments, but got 1.
				dispatch({
					type: 'SEARCH_API_ERROR',
					payload: {
						options: mergedOptions,
						error:
							// @ts-expect-error - TS2367 - This condition will always return 'false' since the types '1' and '0' have no overlap.
							mergedOptions.length === 0 || mergedOptions[0].options.length === 0
								? fetchError
								: null,
					},
				});
			}
		},
		[labels.matchingPages, recentOptions, spaceKey],
	);
	const fetchSuggestions: FetchSuggestions = useCallback(
		(inputValue) => {
			counter.current.value += 1;
			counter.current.input = inputValue;
			const currentCounter = counter.current.value;
			if (inputValue && inputValue !== '') {
				// @ts-expect-error - TS2554 - Expected 0 arguments, but got 1.
				dispatch({
					type: 'SEARCH_API_LOADING',
					payload: { options: transformSearchOptions([], labels.matchingPages) },
				});
				debouncedFetch(
					{ inputValue, appId, spaceKey },
					// @ts-expect-error - TS7006 - Parameter 'response' implicitly has an 'any' type.
					(response) => handleResponse(response, currentCounter),
					// @ts-expect-error - TS7006 - Parameter 'err' implicitly has an 'any' type.
					(err) => handleError(err, currentCounter),
				);
				return;
			}
			if (currentCounter === counter.current.value) {
				counter.current = DEFAULT_COUNTER;
				// @ts-expect-error - TS2554 - Expected 0 arguments, but got 1.
				dispatch({
					type: 'SEARCH_API_RESET',
					payload: {
						options: recentTransformedOption,
					},
				});
			}
		},
		[appId, handleError, handleResponse, labels.matchingPages, recentTransformedOption, spaceKey],
	);

	return children({ fetchSuggestions, loading, error, type, options, clearState });
};

export default ConfluenceSearchService;
