import { useCallback } from 'react';
import { AvailableSitesProductType } from '@atlaskit/linking-common/types';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { getAvailableSites } from '../../services/confluence-rest-api/get-available-sites';

export const useConfluenceSite = () => {
	const cloudId = useCloudId();
	const getConfluenceSite = useCallback(async (): Promise<string> => {
		const siteOptionsResponse = await getAvailableSites();
		if (!siteOptionsResponse.success || siteOptionsResponse.result?.sites?.length <= 0) return '';

		const siteOptions = siteOptionsResponse.result.sites;
		const matchingSite = siteOptions
			.filter((site) => site.products.includes(AvailableSitesProductType.CONFLUENCE))
			.find((site) => site.cloudId === cloudId);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		return matchingSite?.url || siteOptions[0].url || window.location.origin;
	}, [cloudId]);
	return { getConfluenceSite };
};
