import React, { useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import AttachmentIcon from '@atlaskit/icon/glyph/attachment';
import { Flex } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';

export const LoomInsertLinkButton = ({
	onInsertLoomLinkClick,
}: {
	onInsertLoomLinkClick: () => void;
}) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({
		action: 'clicked',
		actionSubject: 'dropdownItem',
	});
	const handleInsertLoomLink = useCallback(() => {
		fireUIAnalytics(analyticsEvent, 'add-text-button-dropdown-insert-existing-loom-video');
		onInsertLoomLinkClick();
	}, [analyticsEvent, onInsertLoomLinkClick]);

	return (
		<DropdownItem
			key="loom-provision-button"
			elemBefore={<AttachmentIcon size="medium" label="" />}
			onClick={handleInsertLoomLink}
			testId="issue-view-foundation.quick-add.loom-insert-link-item-button.provision-button"
		>
			<Flex alignItems="center" gap="space.050">
				{formatMessage(messages.loomInsertExistingVideoButtonLabel)}
			</Flex>
		</DropdownItem>
	);
};
