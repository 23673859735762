import React, { useCallback, useMemo } from 'react';
import { useThemeObserver } from '@atlaskit/tokens';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import type { ConfluenceWhiteboard } from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import { embeddedWhiteboardModalEntrypoint } from '../../ui/embedded-whiteboard-modal/entrypoint';
import { useEmbeddedPageTracking } from '../use-embedded-page-tracking';
import { useParentProduct } from '../use-parent-product';
import { getSpaceKey } from '../utils/getSpaceKey.tsx';

const entryPointParams = {};
export const useEmbeddedWhiteboardModalEntryPoint = (
	whiteboard?: ConfluenceWhiteboard,
	embeddedConfluenceSource?: string,
) => {
	const parentProduct = useParentProduct();

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		embeddedWhiteboardModalEntrypoint,
		entryPointParams,
	);

	const fireAnalyticsEvent = useEmbeddedPageTracking();
	const themeState = useThemeObserver();

	const hostname = whiteboard?.hostname ? whiteboard.hostname : '';

	const spaceKey = whiteboard?.href ? getSpaceKey(whiteboard.href) : '';

	const onClose = useCallback(() => {
		fireAnalyticsEvent({
			action: 'clicked',
			eventName: 'closeLinkedConfluenceWhiteboard',
			embeddedConfluenceSource,
		});
		entryPointActions.unload();
	}, [fireAnalyticsEvent, entryPointActions, embeddedConfluenceSource]);

	const runtimeProps = useMemo(
		() => ({
			parentProduct,
			hostname,
			themeState,
			embeddedConfluenceSource,
			spaceKey,
			onClose,
			isOpen: true,
			modalTitle: whiteboard?.title ?? '',
			url: whiteboard?.href ?? '',
			contentId: whiteboard?.whiteboardId ?? '',
		}),
		[
			parentProduct,
			hostname,
			themeState,
			embeddedConfluenceSource,
			spaceKey,
			onClose,
			whiteboard?.title,
			whiteboard?.href,
			whiteboard?.whiteboardId,
		],
	);

	return whiteboard
		? {
				entryPointActions,
				entryPoint: (
					<JiraEntryPointContainer
						entryPointReferenceSubject={entryPointReferenceSubject}
						id="embeddedWhiteboardModalEntrypointContainer"
						packageName="@atlassian/jira-issue-create-confluence-content"
						teamName="confluence-better-together"
						runtimeProps={runtimeProps}
					/>
				),
			}
		: { entryPointActions: undefined, entryPoint: null };
};
