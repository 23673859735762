import React from 'react';
import { styled } from '@compiled/react';
// eslint-disable-next-line import/order
import Spinner from '@atlaskit/spinner';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

export const LoadingSpinner = () => (
	<SpinnerWrapper>
		<Spinner testId="issue-comment-base.ui.comment.comment-visibility.spinner.spinner" />
	</SpinnerWrapper>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerWrapper = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 18}px`,
});
