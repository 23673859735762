// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'issue.request-language.placeholder',
		defaultMessage: 'Select Request language',
		description: 'Empty state text when the language request field is empty.',
	},
	languageUnknown: {
		id: 'issue.request-language.languageUnknown',
		defaultMessage: 'Language unknown',
		description:
			"An option in the language request selector, which indicates that the request's language is unknown",
	},
});
