// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	url: {
		id: 'issue.views.web-links.addLink.label.url',
		defaultMessage: 'URL',
		description: 'The URL to a link outside Jira',
	},
	linkText: {
		id: 'issue.views.web-links.addLink.label.linkText',
		defaultMessage: 'Link text',
		description: 'The text to show to describe a link',
	},
	linkTextPlaceholder: {
		id: 'issue.views.web-links.addLink.label.linkTextPlaceholder',
		defaultMessage: 'Add a description...',
		description: 'Placeholder for the link title/text when adding a link',
	},
	invalidUrlMessage: {
		id: 'issue.views.web-links.addLink.label.invalidUrlMessage',
		// Seems like we ran into an edge case with the filtering for https which still fails with it in the ignore file
		defaultMessage: 'Enter a valid URL starting with http:// or https://',
		description: 'Text that gets displayed when URL is incorrect',
	},
	errorIconLabel: {
		id: 'issue.views.web-links.addLink.label.errorIcon',
		defaultMessage: 'Error icon',
		description: 'Error icon label',
	},
});
