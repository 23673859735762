// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	nonEditablePlaceholderText: {
		id: 'issue.views.field.rich-text.nonEditablePlaceholderText',
		defaultMessage: 'None',
		description:
			'Placeholder text for when there is no content in the multiline field and user does not have edit permission.',
	},
});
