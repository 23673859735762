import { useEffect } from 'react';
import { useIssueAdjustmentsActions } from '@atlassian/jira-issue-adjustments/src/controllers/issue-adjustments/main.tsx';
import type { IssueConfiguration } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import { ChangeEventTypes } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/constants.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import { isFieldTypeSupported } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/index.tsx';
import {
	type changeSource,
	UI_MODIFICATIONS_CHANGE,
	ISSUE_VIEW_VIEW_TYPE,
} from '../../../../common/constants';

type Props = {
	fieldsConfig: IssueConfiguration | null;
};

/**
 *
 * This function is prepared to handle the event type `FIELD_CHANGED` for the field `status`.
 * It is done this way to not over complicate before planned changes to the configurations.
 * It should be refactored to handle it from the configuration later on.
 * Avoid adding additional cases.
 */

const isExpectedEventType = (
	fieldId: string,
	eventType: keyof typeof ChangeEventTypes,
): eventType is
	| typeof ChangeEventTypes.FIELD_CHANGED
	| typeof ChangeEventTypes.FIELD_CHANGE_REQUESTED => {
	if (fieldId === 'status') {
		return eventType === ChangeEventTypes.FIELD_CHANGED;
	}
	return eventType === ChangeEventTypes.FIELD_CHANGE_REQUESTED;
};

export const OnChangeHandlerRegistration = ({ fieldsConfig }: Props) => {
	const { processChange } = useIssueAdjustmentsActions();
	const [, { registerEventHandler }] = useIssueViewFieldUpdateEvents();

	useEffect(() => {
		const unregister = registerEventHandler('onChange', (event) => {
			if (
				isExpectedEventType(event.meta.fieldId, event.type) &&
				fieldsConfig &&
				fieldsConfig[event.meta.fieldId]
			) {
				const { schema } = fieldsConfig[event.meta.fieldId];
				const fieldType: string = schema.custom ?? schema.system;
				const isSupported = isFieldTypeSupported(ISSUE_VIEW_VIEW_TYPE, fieldType);
				const eventSourceIsUIM =
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					(event.meta.additionalProperties as typeof changeSource)?.source ===
					UI_MODIFICATIONS_CHANGE;

				if (isSupported && !eventSourceIsUIM) {
					processChange({
						fieldId: event.meta.fieldId,
						fieldType,
						fieldValue: event.meta.fieldValue,
						eventType: 'change',
					});
				}
			}
		});

		return unregister;
	}, [fieldsConfig, registerEventHandler, processChange]);

	return null;
};
