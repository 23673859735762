import { useCallback, useEffect } from 'react';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { LICENSED_PRODUCTS } from '@atlassian/jira-common-util-get-tenant-context';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import {
	useAnalyticsEvents,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { useLicensedProducts } from '@atlassian/jira-tenant-context-controller/src/components/licensed-products/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { createStore, createHook, type Action } from '@atlassian/react-sweet-state';
import { addUFOCustomData } from '@atlassian/react-ufo/custom-data';
import usePressTracing from '@atlassian/react-ufo/use-press-tracing';
import { waitUntil } from '../../common/utils';
import { fetchLoomUserPermissions } from '../../services/loom-user-permissions';
import { fetchLicenseInformation } from '../../services/xflow';
import { useLoomRecorderLoadingBlanket } from '../use-loom-recorder-loading-blanket';
import { LoomTouchpointVariant, type LoomTouchpointVariantType } from './types';

interface State {
	loomTouchpointVariant?: LoomTouchpointVariantType;
	loading: boolean;
}

const initialState: State = {
	loading: false, // loading only starts when useLoomTouchpointVariant is rendered
};

interface InitLoomTouchpointVariantProps {
	cloudId: string;
	areProductSuggestionsEnabled: boolean;
	tenantHasLoomTCS: boolean;
}

interface CrossFlowSuccessCheckProps {
	cloudId: string;
	hideBlanket: () => void;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
}

const actions = {
	initLoomTouchpointVariant:
		({
			cloudId,
			areProductSuggestionsEnabled,
			tenantHasLoomTCS,
		}: InitLoomTouchpointVariantProps): Action<State> =>
		async ({ getState, setState }) => {
			if (!tenantHasLoomTCS) {
				setState({
					loading: false,
					loomTouchpointVariant: areProductSuggestionsEnabled
						? LoomTouchpointVariant.CROSSFLOW
						: LoomTouchpointVariant.NONE,
				});
				return;
			}
			// cross-join requires an async fetch for perms
			const { loading } = getState();
			if (loading) {
				return;
			}
			setState({ loading: true });
			try {
				const { permitted } = await fetchLoomUserPermissions(cloudId);
				setState({
					loading: false,
					loomTouchpointVariant: permitted
						? LoomTouchpointVariant.COUSE
						: LoomTouchpointVariant.CROSSJOIN,
				});
			} catch (error) {
				fireErrorAnalytics({
					error: error instanceof Error ? error : undefined,
					meta: {
						id: 'fetchLoomUserPermissions',
						packageName: 'jiraLoomUtils',
						teamName: 'cross-flow-growth',
					},
					sendToPrivacyUnsafeSplunk: true,
				});
				setState({ loading: false });
			}
		},
	crossFlowSuccessCheck:
		({ cloudId, hideBlanket, createAnalyticsEvent }: CrossFlowSuccessCheckProps): Action<State> =>
		async ({ setState }) => {
			// refetch license information from xflow endpoint since TCS doesn't update automatically
			try {
				const { ok: hasLoom, tries } = await waitUntil({
					fn: () => fetchLicenseInformation(cloudId),
					condition: (info) => info.products[LICENSED_PRODUCTS.LOOM]?.state === 'ACTIVE',
					maxTries: 2, // arbitrarily deciding on just 1 retry until we have more data to understand the eventual consistency here
					retryWait: 500,
				});
				addUFOCustomData({
					pollingTries: tries,
					hasLoom,
				});
				if (hasLoom) {
					// don't bother fetchLoomUserPermissions here, we assume if the site has loom, the admin will have been given a license during provisioning
					setState({
						loomTouchpointVariant: LoomTouchpointVariant.COUSE,
					});
				} else {
					hideBlanket();
				}
				fireOperationalAnalytics(
					createAnalyticsEvent({}),
					'jiraLoomUtils.fetchLicenseInformation success',
					{
						hasLoom,
						tries,
					},
				);
			} catch (error) {
				fireErrorAnalytics({
					error: error instanceof Error ? error : undefined,
					meta: {
						id: 'fetchLicenseInformation',
						packageName: 'jiraLoomUtils',
						teamName: 'cross-flow-growth',
					},
					sendToPrivacyUnsafeSplunk: true,
				});
				hideBlanket();
			}
		},
} as const;

type Actions = typeof actions;

const store = createStore<State, Actions>({
	name: 'loom-utils.loom-touchpoint-variant',
	initialState,
	actions,
});

const useLoomTouchpointVariantStore = createHook(store);

export const useLoomTouchpointVariant = () => {
	const [{ loomTouchpointVariant }, { initLoomTouchpointVariant, crossFlowSuccessCheck }] =
		useLoomTouchpointVariantStore();
	const { areProductSuggestionsEnabled } = useTenantContext();
	const cloudId = useCloudId();
	const licensedProducts = useLicensedProducts();
	const tenantHasLoomTCS = Boolean(licensedProducts[LICENSED_PRODUCTS.LOOM]);
	const [, { hideBlanket, startPolling }] = useLoomRecorderLoadingBlanket();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isSiteAdmin = useIsSiteAdmin();
	const tracePolling = usePressTracing('loom.cross-flow.license-information.poll');

	useEffect(() => {
		if (loomTouchpointVariant === undefined) {
			initLoomTouchpointVariant({
				cloudId,
				tenantHasLoomTCS,
				areProductSuggestionsEnabled,
			});
		}
	}, [
		areProductSuggestionsEnabled,
		cloudId,
		initLoomTouchpointVariant,
		loomTouchpointVariant,
		tenantHasLoomTCS,
	]);

	// when cross flow frontend is closed, it doesn't tell us whether cross-flow successfully occurred - we need to check if the variant has changed.
	const triggerCrossFlowSuccessCheck = useCallback(() => {
		// cross-flow would only be able to update the variant if the user is an admin
		// end-users get taken through request-trial flow, which does not provision Loom
		if (isSiteAdmin) {
			tracePolling(); // trigger the UFO tracing to start
			startPolling(); // render the UFO hold until the blanket is hidden
			crossFlowSuccessCheck({ cloudId, hideBlanket, createAnalyticsEvent });
		}
	}, [
		isSiteAdmin,
		crossFlowSuccessCheck,
		cloudId,
		hideBlanket,
		createAnalyticsEvent,
		startPolling,
		tracePolling,
	]);

	return {
		loomTouchpointVariant:
			loomTouchpointVariant === undefined ? LoomTouchpointVariant.NONE : loomTouchpointVariant,
		triggerCrossFlowSuccessCheck,
	};
};
