import React, { useCallback, useMemo, useState } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay';
import { ff } from '@atlassian/jira-feature-flagging';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';
import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';
import SprintEditViewEntryPoint from '@atlassian/jira-issue-field-sprint-editview-full/src/entrypoint';
import type {
	NullableOption,
	SprintEditViewProps,
} from '@atlassian/jira-issue-field-sprint-editview-full/src/ui/sprint/types';
import {
	findActiveOrFutureSprint,
	isObjectWithSprintState,
} from '@atlassian/jira-issue-field-sprint-readview-full/src/common/utils.tsx';
import { SprintReadView } from '@atlassian/jira-issue-field-sprint-readview-full/src/ui/sprint/index.tsx';
import type { sprint_issueFieldSprint_SprintField_Mutation as SprintMutation } from '@atlassian/jira-relay/src/__generated__/sprint_issueFieldSprint_SprintField_Mutation.graphql';
import type { sprint_issueFieldSprintInlineEditFull_SprintInlineEditView_fragmentRef$key as SprintFragment } from '@atlassian/jira-relay/src/__generated__/sprint_issueFieldSprintInlineEditFull_SprintInlineEditView_fragmentRef.graphql';
import type { sprint_issueFieldSprintInlineEditFull_SprintInlineEditViewIsEditable_fragmentRef$key as SprintWithIsEditableFragment } from '@atlassian/jira-relay/src/__generated__/sprint_issueFieldSprintInlineEditFull_SprintInlineEditViewIsEditable_fragmentRef.graphql';
import type { SprintInlineEditViewProps, SprintInlineEditViewWithIsEditableProps } from './types';
import { toOptimisticResponse, transformOptionToAgg } from './utils';

const isEqualSprint = (a: NullableOption, b: NullableOption) => a?.value === b?.value;

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components. This variant allows
 * consumers to define their own value to determine whether the field is editable.
 *
 * In most cases consumers should use `SprintInlineEditView` which will enforce that the user has permission to
 * edit the field within the issue view. However, this component can be used for experiences that have differing
 * permissions or want finer control for how this data is retrieved, e.g. lazy loading editability.
 *
 * @param props [SprintInlineEditViewWithIsEditableProps](./types.tsx)
 */
export const SprintInlineEditViewIsEditable = ({
	attributes,
	spacing = 'compact',
	editViewPopup,
	editViewPopupAlignBlock,
	fragmentRef,
	getUrl,
	isEditable: isFieldEditable,
	isEditing: startWithEditViewOpen,
	menuPosition,
	onRestrictSprints,
	onSubmit,
	onSubmitNew,
	onSubmitFailed,
	onSubmitSucceeded,
	onSubmitSucceededNew,
	projectKey,
	readViewFitContainerHeight,
	restrictSprints,
}: SprintInlineEditViewWithIsEditableProps) => {
	// #region Relay
	const data = useFragment<SprintWithIsEditableFragment>(
		graphql`
			fragment sprint_issueFieldSprintInlineEditFull_SprintInlineEditViewIsEditable_fragmentRef on JiraSprintField {
				...sprint_issueFieldSprintReadviewFull_SprintReadView
				id
				fieldId
				type
				name
				selectedSprintsConnection {
					edges {
						node {
							# Ensure we fetch all fields required for the read view so we can produce a valid
							# selectedSprintsConnection when optimisitcally updating the sprint.
							id
							sprintId
							name
							state
							# eslint-disable-next-line @atlassian/relay/unused-fields
							endDate
						}
					}
				}
			}
		`,
		fragmentRef,
	);
	const { id: uniqueFieldId, fieldId, type, name: fieldName, selectedSprintsConnection } = data;

	const [commit] = useMutation<SprintMutation>(graphql`
		mutation sprint_issueFieldSprint_SprintField_Mutation($input: JiraUpdateSprintFieldInput!)
		@raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateSprintField(input: $input) {
					success
					errors {
						message
					}
					field {
						id
						...sprint_issueFieldSprintReadviewFull_SprintReadView
					}
				}
			}
		}
	`);

	const sprintEdges = useMemo(
		() => selectedSprintsConnection?.edges?.filter(Boolean) ?? [],
		[selectedSprintsConnection?.edges],
	);

	const initialValue = useMemo((): NullableOption => {
		const sprintNodes = sprintEdges.map(({ node }) => node).filter(Boolean);
		const currentSprint = findActiveOrFutureSprint(sprintNodes);

		return currentSprint && isObjectWithSprintState(currentSprint)
			? {
					value: currentSprint.id,
					label: currentSprint.name ?? '',
					state: currentSprint.state,
					sprintId: currentSprint.sprintId,
				}
			: null;
	}, [sprintEdges]);
	const [updatedValue, setUpdatedValue] = useState<NullableOption>(initialValue);

	const handleSubmit = useCallback(
		(option: NullableOption, { onSuccess, onFail }: OnSubmitCallbacks) => {
			if (ff('relay-migration-issue-fields-sprint_jozpu')) {
				onSubmitNew?.(transformOptionToAgg(selectedSprintsConnection?.edges ?? [], option));
			} else {
				onSubmit?.(option);
			}

			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operation: {
							operation: 'SET',
							id: option?.value ?? null,
						},
					},
				},
				onCompleted: (mutationData) => {
					if (mutationData.jira?.updateSprintField?.success) {
						onSuccess();
					} else {
						onFail();
					}
				},
				onError(error) {
					onFail(error);
				},
				optimisticResponse: toOptimisticResponse(uniqueFieldId, option, sprintEdges),
			});
		},
		[commit, onSubmit, onSubmitNew, selectedSprintsConnection?.edges, sprintEdges, uniqueFieldId],
	);

	const handleSubmitSucceeded = ff('relay-migration-issue-fields-sprint_jozpu')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useCallback(
				(value: NullableOption) => {
					onSubmitSucceededNew?.(
						transformOptionToAgg(selectedSprintsConnection?.edges ?? [], value),
					);
				},
				[onSubmitSucceededNew, selectedSprintsConnection?.edges],
			)
		: onSubmitSucceeded;

	const {
		handleCancel,
		handleEdit,
		handleConfirm,
		handleChangeAndConfirm,
		hasServerValidationError,
		invalidMessage,
		isEditing,
	} = useFieldInlineEditActions({
		attributes,
		fieldId,
		fieldName,
		fieldType: type,
		initialValue,
		isValueEqual: isEqualSprint,
		onSubmit: handleSubmit,
		onSubmitFailed,
		onSubmitSucceeded: handleSubmitSucceeded,
		onUpdateValue: setUpdatedValue,
		startWithEditViewOpen,
		updatedValue,
	});

	const renderReadView = useCallback(
		() => <SprintReadView fragmentRef={data} getUrl={getUrl} />,
		[data, getUrl],
	);

	const getEditViewProps = (fieldProps: ValidationFieldProps): SprintEditViewProps => ({
		...fieldProps,
		spacing,
		fieldId: uniqueFieldId,
		fieldName,
		autoFocus: true,
		value: updatedValue,
		onChange: handleChangeAndConfirm,
		projectKey,
		onRestrictSprints,
		restrictSprints,
		menuPosition,
	});

	return (
		<FieldInlineEditLiteWithEntryPoint
			editViewPopup={editViewPopup}
			editViewPopupAlignBlock={editViewPopupAlignBlock}
			editViewEntryPoint={SprintEditViewEntryPoint}
			editViewEntryPointParams={{}}
			getEditViewProps={getEditViewProps}
			fieldName={fieldName}
			hasUnsubmittedChanges={hasServerValidationError}
			invalidMessage={invalidMessage}
			isEditing={isEditing}
			isEditable={isFieldEditable}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			onEdit={handleEdit}
			readViewFitContainerHeight={readViewFitContainerHeight}
			readView={renderReadView}
			hideActionButtons
		/>
	);
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components.
 *
 * @param props [SprintInlineEditViewProps](./types.tsx)
 */

export const SprintInlineEditView = ({ fragmentRef, ...props }: SprintInlineEditViewProps) => {
	const data = useFragment<SprintFragment>(
		graphql`
			fragment sprint_issueFieldSprintInlineEditFull_SprintInlineEditView_fragmentRef on JiraSprintField {
				...sprint_issueFieldSprintInlineEditFull_SprintInlineEditViewIsEditable_fragmentRef
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);

	return (
		<SprintInlineEditViewIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data.fieldConfig?.isEditable ?? false}
		/>
	);
};
