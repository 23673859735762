import React, { memo } from 'react';
// eslint-disable-next-line jira/styled/no-styled-import-alias
import { SkeletonCompiled as Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { useIsIssueOfIncidentsPractice } from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import { AsyncPlaybooksPanel } from './async';

const Playbooks = () => {
	const isIssueOfIncidentsPractice = useIsIssueOfIncidentsPractice();

	if (!isIssueOfIncidentsPractice) {
		return null;
	}

	return (
		<JSErrorBoundary id="AsyncPlaybooksPanel" packageName="jiraIssueViewBase" fallback="unmount">
			<Placeholder name="playbooks-panel" fallback={<Skeleton width="100%" height="68px" />}>
				<AsyncPlaybooksPanel />
			</Placeholder>
		</JSErrorBoundary>
	);
};

export default memo(Playbooks);
