/**
 * @generated SignedSource<<39081a401b62cee2b698c3154766991b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraProjectStyle = "COMPANY_MANAGED_PROJECT" | "TEAM_MANAGED_PROJECT" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay$data = {
  readonly fieldsById: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: "JiraParentIssueField";
        readonly fieldId: string;
        readonly id: string;
        readonly name: string;
        readonly parentIssue: {
          readonly id: string;
          readonly issueId: string;
        } | null | undefined;
        readonly type: string;
      } | {
        readonly __typename: "JiraProjectField";
        readonly name: string;
        readonly project: {
          readonly projectId: string | null | undefined;
          readonly projectStyle: JiraProjectStyle | null | undefined;
        } | null | undefined;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly issueId: string;
  readonly issueTypeField: {
    readonly issueType: {
      readonly avatar: {
        readonly medium: string | null | undefined;
      } | null | undefined;
      readonly hierarchy: {
        readonly level: number | null | undefined;
      } | null | undefined;
      readonly issueTypeId: string | null | undefined;
      readonly name: string;
    } | null | undefined;
  } | null | undefined;
  readonly key: string;
  readonly " $fragmentType": "issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay";
};
export type issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay$key = {
  readonly " $data"?: issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "issueId"
},
v2 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "name": "key"
    },
    (v1/*: any*/),
    {
      "concreteType": "JiraIssueTypeField",
      "kind": "LinkedField",
      "name": "issueTypeField",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueType",
          "kind": "LinkedField",
          "name": "issueType",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "ScalarField",
              "name": "issueTypeId"
            },
            {
              "concreteType": "JiraAvatar",
              "kind": "LinkedField",
              "name": "avatar",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "medium"
                }
              ]
            },
            {
              "concreteType": "JiraIssueTypeHierarchyLevel",
              "kind": "LinkedField",
              "name": "hierarchy",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "level"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "ids",
          "value": [
            "parent",
            "project"
          ]
        }
      ],
      "concreteType": "JiraIssueFieldConnection",
      "kind": "LinkedField",
      "name": "fieldsById",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueFieldEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "__typename"
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "name": "fieldId"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "type"
                    },
                    (v2/*: any*/),
                    {
                      "concreteType": "JiraIssue",
                      "kind": "LinkedField",
                      "name": "parentIssue",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/)
                      ]
                    }
                  ],
                  "type": "JiraParentIssueField"
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v2/*: any*/),
                    {
                      "concreteType": "JiraProject",
                      "kind": "LinkedField",
                      "name": "project",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "projectId"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "projectStyle"
                        }
                      ]
                    }
                  ],
                  "type": "JiraProjectField"
                }
              ]
            }
          ]
        }
      ],
      "storageKey": "fieldsById(ids:[\"parent\",\"project\"])"
    }
  ],
  "type": "JiraIssue"
};
})();

(node as any).hash = "e175d6b364a1b5bf54a067d0d51fb2e6";

export default node;
