import { createSelector } from 'reselect';
import {
	accountIdloggedInUserSelector,
	baseUrlSelector,
	cloudIdSelector,
	issueKeySelector,
	orgIdSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { projectTypeSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import IssueUserTeamMentionProvider from './issue-user-team-mention-provider';

export const mentionProviderSelector = createSelector(
	baseUrlSelector,
	issueKeySelector,
	accountIdloggedInUserSelector,
	projectTypeSelector,
	orgIdSelector,
	cloudIdSelector,
	(baseUrl, issueKey, loggedInAccountId, projectType, orgId, cloudId) =>
		new IssueUserTeamMentionProvider(
			baseUrl,
			issueKey,
			loggedInAccountId,
			`${baseUrl}/jira/people/search#createTeam`,
			projectType,
			orgId,
			cloudId,
		),
);
