import React from 'react';
import { usePreloadedQuery, type PreloadedQuery, graphql } from 'react-relay';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { FieldsCompactModeProvider } from '@atlassian/jira-issue-view-layout-templates-compact-mode-provider';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import type { panelViewOrganizationQuery } from '@atlassian/jira-relay/src/__generated__/panelViewOrganizationQuery.graphql';
import { ExperienceSuccess } from '@atlassian/jira-servicedesk-customer-service-common/src/ui/experience-success/index.tsx';
import { ThrowOnQueryError } from '@atlassian/jira-servicedesk-customer-service-common/src/ui/throw-on-query-error/index.tsx';
import { isClientStatusCode } from '@atlassian/jira-servicedesk-customer-service-common/src/utils/is-client-status-code/index.tsx';
import { customerServiceIssueViewPanelOrganizationExperience } from '../../../../experiences';
import { useEntitlementFieldValue } from '../../../controllers/use-entitlement-field-value';
import { Divider } from '../../details';
import { DetailsContainer } from '../../details-container';
import { DetailsView } from '../../details/details-view/index.tsx';
import { ErrorState } from '../../details/error-state/index.tsx';
import { getEditDetailsUrlByEntityType } from '../../details/utils.tsx';

type Props = {
	queryReference: PreloadedQuery<panelViewOrganizationQuery>;
	isLast?: boolean;
};

const Inner = ({ queryReference, isLast }: Props) => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const {
		customerService: { organizationByOrganizationId },
	} = usePreloadedQuery<panelViewOrganizationQuery>(
		graphql`
			query panelViewOrganizationQuery(
				$cloudId: ID!
				$organizationId: ID!
				$filter: CustomerServiceFilterInput!
			) {
				customerService(cloudId: $cloudId) @required(action: THROW) {
					organizationByOrganizationId(organizationId: $organizationId, filter: $filter)
						@required(action: THROW) {
						__typename
						... on CustomerServiceOrganization {
							id
							details {
								...detailsView_servicedeskCustomerServiceIssueViewPanel
							}
						}
						... on QueryError {
							extensions {
								statusCode
							}
							...throwOnQueryError_servicedeskCustomerServiceCommon
						}
					}
				}
			}
		`,
		queryReference,
	);

	const entitlementFieldValue = useEntitlementFieldValue();
	const hasEntitlement = !!entitlementFieldValue;
	const showDivider = !isLast || hasEntitlement;

	const queryError =
		organizationByOrganizationId.__typename === 'QueryError'
			? organizationByOrganizationId
			: undefined;
	const is4XX = queryError?.extensions?.find((e) => isClientStatusCode(e.statusCode));

	if (is4XX) {
		return (
			<>
				<ErrorState context="ORGANIZATION" />
				<ExperienceSuccess experience={customerServiceIssueViewPanelOrganizationExperience} />
			</>
		);
	}

	if (organizationByOrganizationId.__typename !== 'CustomerServiceOrganization') {
		return <ThrowOnQueryError operationName="panelViewOrganizationQuery" errorRef={queryError} />;
	}

	return (
		<>
			<DetailsView
				entityType="ORGANIZATION"
				editDetailsUrl={getEditDetailsUrlByEntityType(
					projectKey,
					'ORGANIZATION',
					organizationByOrganizationId.id,
				)}
				details={organizationByOrganizationId.details}
				entityExperience={customerServiceIssueViewPanelOrganizationExperience}
			/>
			{showDivider && (
				<Divider data-testid="servicedesk-customer-service-issue-view-panel.common.ui.organization.panel-view.divider" />
			)}
		</>
	);
};

export const PanelView = (props: Props) => (
	<DetailsContainer
		context="ORGANIZATION"
		entityExperience={customerServiceIssueViewPanelOrganizationExperience}
	>
		<FieldsCompactModeProvider>
			<Inner {...props} />
		</FieldsCompactModeProvider>
	</DetailsContainer>
);
