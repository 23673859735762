import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import SquareAddIconButton from '../../../../button/square-add-icon-button';
import messages from './messages';
import type { AddChildProps } from './types';

export const AddChild = ({ isClassicSubtaskPanel = false, onClick }: AddChildProps) => {
	const { formatMessage } = useIntl();
	const label = isClassicSubtaskPanel ? messages.createSubtaskLabel : messages.createChildLabel;

	return (
		<ErrorBoundary prefixOverride="issue" id="Safe(AddChild)">
			<Box xcss={buttonWrapperStyles}>
				<SquareAddIconButton onClick={onClick} label={formatMessage(label)} />
			</Box>
		</ErrorBoundary>
	);
};

const buttonWrapperStyles = xcss({
	marginLeft: 'space.075',
});
