import React from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { Box, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { SkeletonCompiled } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import { CannedCommentList } from './canned-comment-list';
import { CommentTextAreaPlaceholder } from './comment-text-area-placeholder';

type BaseProps = {
	placeholder?: string;
	onFocus?: () => void;
	onClick?: () => void;
	onChange?: () => void;
	commentsType?: string;
};

type LoadingProps = {
	isLoading: true;
	shouldShowLoadingStateSpinner?: boolean;
};

type NotLoadingProps = {
	isLoading?: false;
	shouldShowLoadingStateSpinner?: never;
	onClickCannedComment: (value: ADF) => void;
};

type Props = BaseProps & (LoadingProps | NotLoadingProps);

const NUM_OF_SKELETON_CANNED_COMMENTS = 5;

export const CannedCommentsPlaceholder = ({
	placeholder,
	onFocus,
	onClick,
	onChange,
	commentsType,
	...restProps
}: Props) =>
	restProps.isLoading ? (
		<Box xcss={containerStyles}>
			<CommentTextAreaPlaceholder
				onClick={onClick}
				onChange={onChange}
				placeholder={placeholder}
				isLoading={restProps.isLoading}
			/>
			{restProps.shouldShowLoadingStateSpinner && (
				<Box xcss={loadingStateSpinnerContainerStyles}>
					<Spinner size="small" />
				</Box>
			)}
			<Box xcss={loadingStateCannedCommentsContainerStyles}>
				<Box xcss={loadingStateCannedCommentsContainerInnerStyles}>
					{Array.from({ length: NUM_OF_SKELETON_CANNED_COMMENTS }, (_, index) => (
						<SkeletonCompiled key={index} height="20px" width="80px" />
					))}
				</Box>
			</Box>
		</Box>
	) : (
		<Box xcss={[containerStyles, hoveredStyle]}>
			<CannedCommentList
				onClickCannedComment={restProps.onClickCannedComment}
				commentsType={commentsType}
			/>
			<CommentTextAreaPlaceholder placeholder={placeholder} onFocus={onFocus} onClick={onClick} />
		</Box>
	);

const containerStyles = xcss({
	position: 'relative',
});

/**
 * Add css-var to adjust styling between parents and children.
 * hovering the div should make the background color of the textarea and button right and left the same
 * NOTE: if css-var name gets changed, please search for the usage of it within the children.
 *
 * Arguably, another solution is to use react state and event listener (mouseenter and mouseleave)
 * but we will need to wait extra render cycle for css to change.
 */
const hoveredStyle = xcss({
	'--jira-canned-comments-background-color': token('color.background.input'),
	':hover': {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-expect-error
		'--jira-canned-comments-background-color': token('color.background.input.hovered'),
	},
});

const loadingStateSpinnerContainerStyles = xcss({
	position: 'absolute',
	right: 'space.250',
	top: 'space.200',
});

const loadingStateCannedCommentsContainerStyles = xcss({
	overflow: 'hidden',
	position: 'absolute',
	bottom: 'space.250',
	left: 'space.250',
	right: 'space.250',
});

const loadingStateCannedCommentsContainerInnerStyles = xcss({
	display: 'inline-flex',
	gap: 'space.100',
	alignItems: 'center',
});
