import React, { useEffect, useRef } from 'react';
import noop from 'lodash/noop';
import DevPanelDetailsDialogAsync from '@atlassian/jira-development-details/src/main';
import { createAri } from '@atlassian/jira-platform-ari';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { State } from '../common/types';
import {
	loadDialogBridge,
	DevOpsDetailsDialogLoaderContainer,
	DevOpsDetailsDialogLoaderSubscriber,
} from '../controllers/main.tsx';
import type { Props } from './types';

const DevDetailsDialogLoader = (props: Props) => {
	const { onCloseDevDetailDialog, state, actions } = props;

	const hookedLocale = useLocale();

	const {
		issueId,
		issueKey,
		locale,
		dataTypeSelected,
		isDevPanelDetailsDialogShown,
		legacyDevPanelDetailsDialogShownCounter,
		legacyData,
	} = state ?? {};
	const { closeDetailDialog, updateState: updateStateAction } = actions ?? {};

	const onClose = () => {
		onCloseDevDetailDialog();
		closeDetailDialog();
	};

	const dialogBridge = useRef<unknown>(null);

	const currentLegacyDevPanelDetailsDialogShownCounter = useRef(
		legacyDevPanelDetailsDialogShownCounter,
	);

	useEffect(() => {
		const updatedState: Partial<State> = { locale: hookedLocale };
		updateStateAction(updatedState);
	}, [hookedLocale, updateStateAction]);
	useEffect(() => {
		/** Valid cases
		 *  When dialog is open
		 *  AND
		 *  issueId is a non empty string
		 *  AND
		 *  issueKey is a string
		 */
		const isShowingDialog =
			isDevPanelDetailsDialogShown ||
			currentLegacyDevPanelDetailsDialogShownCounter.current !==
				legacyDevPanelDetailsDialogShownCounter;

		const hasValidIssueId = typeof issueId === 'string' && issueId !== '';

		if (isShowingDialog && !hasValidIssueId) {
			throw new Error('Could not find a valid "issueId" value.');
		}
	}, [
		isDevPanelDetailsDialogShown,
		issueId,
		issueKey,
		legacyDevPanelDetailsDialogShownCounter,
		updateStateAction,
	]);

	useEffect(() => {
		async function waitforBridge() {
			await loadDialogBridge()
				.then((bridge) => {
					dialogBridge.current = bridge;
					// @ts-expect-error - TS2571 - Object is of type 'unknown'.
					dialogBridge.current.showDialog(
						issueKey ?? '',
						issueId ?? '',
						legacyData,
						dataTypeSelected,
						false,
					);
				})
				.catch((exception) => {
					throw exception;
				});
		}
		if (
			currentLegacyDevPanelDetailsDialogShownCounter.current !==
			legacyDevPanelDetailsDialogShownCounter
		) {
			currentLegacyDevPanelDetailsDialogShownCounter.current =
				legacyDevPanelDetailsDialogShownCounter;
			if (dialogBridge.current) {
				// @ts-expect-error - TS2571 - Object is of type 'unknown'.
				dialogBridge.current.showDialog(
					issueKey ?? '',
					issueId ?? '',
					legacyData,
					dataTypeSelected,
					false,
				);
			} else {
				waitforBridge();
			}
		}
	}, [
		legacyData,
		dataTypeSelected,
		dialogBridge,
		issueId,
		issueKey,
		legacyDevPanelDetailsDialogShownCounter,
	]);

	const projectContext = useProjectContext();
	const tenantContext = useTenantContext();
	const projectAri = createAri({
		resourceOwner: 'jira',
		resourceType: 'project',
		cloudId: tenantContext.cloudId,
		resourceId: projectContext.data?.projectId?.toString() ?? '',
	});
	return isDevPanelDetailsDialogShown ? (
		<DevPanelDetailsDialogAsync
			issueId={issueId ?? ''}
			issueKey={issueKey ?? ''}
			baseUrl=""
			// @ts-expect-error - TS2322 - Type 'Locale | undefined' is not assignable to type 'Locale'.
			locale={locale}
			// @ts-expect-error - TS2322 - Type 'PanelDataTypes | undefined' is not assignable to type 'PanelDataTypes'.
			selectedTab={dataTypeSelected}
			showRemoteLinks
			onCloseDialog={onClose}
			projectAri={projectAri || ''}
			accountId={tenantContext.atlassianAccountId || ''}
		/>
	) : null;
};

DevDetailsDialogLoader.defaultProps = {
	onCloseDevDetailDialog: noop,
	scopeId: '',
};

export const DevDetailDialogLoaderWithContainer = (props: Props) => (
	<DevOpsDetailsDialogLoaderContainer scope={props.scopeId}>
		<DevOpsDetailsDialogLoaderSubscriber>
			{(state, actions) => <DevDetailsDialogLoader state={state} actions={actions} />}
		</DevOpsDetailsDialogLoaderSubscriber>
	</DevOpsDetailsDialogLoaderContainer>
);

DevDetailDialogLoaderWithContainer.defaultProps = {
	onCloseDevDetailDialog: noop,
};

export default DevDetailDialogLoaderWithContainer;
