export const ADD_ATTACHMENT_ID = 'attachment';
export const CREATE_SUBTASK_ID = 'create-subtask';
export const CREATE_ISSUE_IN_EPIC_ID = 'create-issue-in-epic';
export const ADD_ISSUE_LINKS_ID = 'issue-links';
export const ADD_CONTENT_PANEL = 'add_contentpanel';
export const SELECT_CONTENT_PANEL = 'select_contentpanel';
export const CREATE_CHILD_ID = 'create-child';
export const LINK_CONF_PAGE_ID = 'link-confluence-page';
export const LINK_ALERTS_ID = 'link-alerts';
export const ADD_WEB_LINK_ID = 'add-web-link';
export const SCHEDULE_CHANGE_ID = 'schedule-change';
export const ADD_FORM_ID = 'add-form';
export const DESCRIPTION_ID = 'description';
export const INVESTIGATE_INCIDENT_ID = 'investigate-incident';
export const ADD_DESIGN_ID = 'add-design';
export const SUGGEST_RELATED_RESOURCES_ID = 'suggest-related-resources';
export const IMPROVE_ISSUE_DESCRIPTION_ID = 'improve-issue-description';
export const SUMMARIZE_COMMENTS_ID = 'summarize-comments';
export const SUGGEST_CHILD_ISSUES_ID = 'suggest-child-issues';
