import type { FieldsState } from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import type { ChildIssue } from '@atlassian/jira-issue-view-common-types/src/children-issues-type';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

const CHILD_ISSUE_ADDED = 'CHILD_ISSUE_ADDED' as const;
const ISSUE_CHILDREN_ORDER_CHANGED = 'ISSUE_CHILDREN_ORDER_CHANGED' as const;
const CHILD_ISSUE_FIELD_UPDATED = 'CHILD_ISSUE_FIELD_UPDATED' as const;

export const ChangeEventTypes = {
	[CHILD_ISSUE_ADDED]: CHILD_ISSUE_ADDED,
	[ISSUE_CHILDREN_ORDER_CHANGED]: ISSUE_CHILDREN_ORDER_CHANGED,
	[CHILD_ISSUE_FIELD_UPDATED]: CHILD_ISSUE_FIELD_UPDATED,
} as const;

export type ChildIssueAddedEvent = {
	type: typeof CHILD_ISSUE_ADDED;
	meta: {
		childIssue: ChildIssue;
		fieldsData?: FieldsState;
	};
};

export type IssueChildrenOrderChangedEvent = {
	type: typeof ISSUE_CHILDREN_ORDER_CHANGED;
	meta: {
		issueIds: IssueId[];
		rankId: number;
		isRankAfter: boolean;
	};
};

export type ChildIssueFieldUpdatedEvent = {
	type: typeof CHILD_ISSUE_FIELD_UPDATED;
	meta: {
		childIssueId: IssueId;
	};
};

export type ChangeEvent =
	| ChildIssueAddedEvent
	| IssueChildrenOrderChangedEvent
	| ChildIssueFieldUpdatedEvent;

export type OnChangeCallback = (event: ChangeEvent) => void;
