import { useMemo } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { getUserProperty, setUserProperty } from './user-properties';

const INVITE_PROMPT_ON_LINK_INSERT_VIEW = 'invite-prompt-on-link-insert-view';

const INVITE_PROMPT_ON_LINK_INSERT_OPT_OUT = 'invite-prompt-on-link-insert-opt-out';

const MAX_VIEW_COUNT = 2;

const shouldShowOptOutOption = async (accountId: string | null): Promise<boolean> => {
	if (!accountId) return false;
	const viewCount = await getViewCount(accountId);
	return viewCount >= MAX_VIEW_COUNT;
};

const incrementViewCount = async (accountId: string | null) => {
	if (!accountId) return;

	const viewCount = await getViewCount(accountId);
	if (viewCount < MAX_VIEW_COUNT) {
		try {
			await setUserProperty<number>({
				accountId,
				propertyKey: INVITE_PROMPT_ON_LINK_INSERT_VIEW,
				propertyValue: viewCount + 1,
			});
			// TODO: shall we fire an event here to record the user has viewed the flag?
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics({
				meta: {
					id: 'incrementViewCount',
					packageName: 'jiraInvitePromptOnLinkInsert',
				},
				error,
			});
		}
	}
};

const getViewCount = async (accountId: string): Promise<number> =>
	(
		await getUserProperty<number>({
			accountId,
			propertyKey: INVITE_PROMPT_ON_LINK_INSERT_VIEW,
			defaultValue: 0,
		})
	).value;

const getOptOutInvitePrompt = async (accountId: string | null): Promise<boolean> => {
	if (!accountId) return false;
	return (
		await getUserProperty<boolean>({
			accountId,
			propertyKey: INVITE_PROMPT_ON_LINK_INSERT_OPT_OUT,
			defaultValue: false,
		})
	).value;
};

const setOptOutInvitePrompt = async (accountId: string | null) => {
	if (!accountId) return;

	try {
		await setUserProperty<boolean>({
			accountId,
			propertyKey: INVITE_PROMPT_ON_LINK_INSERT_OPT_OUT,
			propertyValue: true,
		});
		// TODO: shall we fire an event here to record the user has opted out?
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			meta: {
				id: 'setOptOutInvitePrompt',
				packageName: 'jiraInvitePromptOnLinkInsert',
			},
			error,
		});
	}
};

export const useOptOut = () => {
	const accountId = useAccountId();

	return useMemo(
		() => ({
			shouldShowOptOutOption: () => shouldShowOptOutOption(accountId),
			incrementViewCount: () => incrementViewCount(accountId),
			getOptOutStatus: () => getOptOutInvitePrompt(accountId),
			setOptOutStatus: () => setOptOutInvitePrompt(accountId),
		}),
		[accountId],
	);
};
