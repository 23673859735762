import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { measureInitialPageLoadAsyncResourcesTiming } from '@atlassian/jira-forge-ui-analytics/src/common/utils/performance-analytics/main.tsx';
import { PERFORMANCE_KEYS } from '@atlassian/jira-forge-ui-analytics/src/constants';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

export const executeValueFunction = (customFieldId: string, issueIds: IssueId[]) => {
	const postValueFunction = performPostRequest('/rest/internal/3/forge/field/value', {
		body: JSON.stringify({
			customFieldId,
			issueIds,
		}),
	});
	return measureInitialPageLoadAsyncResourcesTiming(
		PERFORMANCE_KEYS.MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_VALUE_FUNCTION_START,
		PERFORMANCE_KEYS.MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_VALUE_FUNCTION_END,
		() => postValueFunction,
	);
};
