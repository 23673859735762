/**
 * @generated SignedSource<<590ebd3509bb69f7cac238d57015109a>>
 * @relayHash 211c2939835e4ad6f3fb1b49b006bfef
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ea55ede069cb31c98be556d99eda187228a71a07ae01f4cba7e7e671eab2dc84

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type multiSelect_issueSelectableFieldQuery$variables = {
  id: string;
};
export type multiSelect_issueSelectableFieldQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment">;
};
export type multiSelect_issueSelectableFieldQuery = {
  response: multiSelect_issueSelectableFieldQuery$data;
  variables: multiSelect_issueSelectableFieldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v3 = {
  "kind": "ScalarField",
  "name": "id"
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  {
    "kind": "Literal",
    "name": "searchBy",
    "value": ""
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "multiSelect_issueSelectableFieldQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "multiSelect_issueSelectableFieldQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "ScalarField",
                "name": "type"
              }
            ],
            "type": "JiraIssueField",
            "abstractKey": "__isJiraIssueField"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v4/*: any*/),
                "concreteType": "JiraSelectableValueConnection",
                "kind": "LinkedField",
                "name": "selectableValueOptions",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraSelectableValueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "selectableLabel"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "selectableGroupKey"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "selectableIconUrl"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "isDisabled"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "optionId"
                              }
                            ],
                            "type": "JiraOption"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "versionId"
                              }
                            ],
                            "type": "JiraVersion"
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "cursor"
                      }
                    ]
                  },
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                      }
                    ]
                  }
                ],
                "storageKey": "selectableValueOptions(first:20,searchBy:\"\")"
              },
              {
                "args": (v4/*: any*/),
                "filters": [
                  "searchBy",
                  "filterById"
                ],
                "handle": "connection",
                "key": "selectableValue_issueFieldEditviewFull_fieldOptions__selectableValueOptions",
                "kind": "LinkedHandle",
                "name": "selectableValueOptions"
              }
            ],
            "type": "JiraHasSelectableValueOptions",
            "abstractKey": "__isJiraHasSelectableValueOptions"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "ea55ede069cb31c98be556d99eda187228a71a07ae01f4cba7e7e671eab2dc84",
    "metadata": {},
    "name": "multiSelect_issueSelectableFieldQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "ce6badc1402bb3f94df7ed7da90986be";

export default node;
