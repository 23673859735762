import React from 'react';
import memoizeOne from 'memoize-one';
import { graphql, useFragment } from 'react-relay';
import GoalsInlineEditViewField from '@atlassian/jira-business-goals-issue-view-field/src/ui/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { IssueViewCascadingSelectField } from '@atlassian/jira-issue-view-layout-cascading-select-field/src/ui/index.tsx';
import { IssueViewCheckboxSelectField } from '@atlassian/jira-issue-view-layout-checkbox-select-field/src/ui/index.tsx';
import { IssueViewComponentsField } from '@atlassian/jira-issue-view-layout-components-field/src/ui/index.tsx';
import {
	IssueViewDateField,
	IssueViewDateFieldWithUrgencyTreatment,
} from '@atlassian/jira-issue-view-layout-date-field/src/ui/index.tsx';
import { IssueViewDateTimeField } from '@atlassian/jira-issue-view-layout-date-time-field/src/ui/index.tsx';
import { IssueViewGroupPickerField } from '@atlassian/jira-issue-view-layout-group-picker-field/src/ui/index.tsx';
import {
	IssueViewLabelsSystemField,
	type IssueViewLabelsSystemFieldFragment,
} from '@atlassian/jira-issue-view-layout-labels-system-field/src/ui/index.tsx';
import { IssueViewMultiUser } from '@atlassian/jira-issue-view-layout-multi-user/src/ui';
import { IssueViewNumberField } from '@atlassian/jira-issue-view-layout-number-field/src/ui/index.tsx';
import { IssueViewOriginalEstimateField } from '@atlassian/jira-issue-view-layout-original-estimate-field';
import { IssueViewParentField } from '@atlassian/jira-issue-view-layout-parent-field/src/ui/index.tsx';
import { IssueViewPeople } from '@atlassian/jira-issue-view-layout-people/src/ui/index.tsx';
import { IssueViewPriorityField } from '@atlassian/jira-issue-view-layout-priority-field/src/ui/index.tsx';
import { IssueViewProjectField } from '@atlassian/jira-issue-view-layout-project-field/src/ui/index.tsx';
import { IssueViewSentimentField } from '@atlassian/jira-issue-view-layout-sentiment-field/src/ui/index.tsx';
import { IssueViewSingleLineText } from '@atlassian/jira-issue-view-layout-single-line-text/src/ui/index.tsx';
import { IssueViewSingleSelectField } from '@atlassian/jira-issue-view-layout-single-select-field/src/ui/index.tsx';
import { IssueViewSingleSelectUserPickerField } from '@atlassian/jira-issue-view-layout-single-select-user-picker-field/src/ui/index.tsx';
import { IssueViewSprintField } from '@atlassian/jira-issue-view-layout-sprint-field/src/ui/index.tsx';
import { IssueViewTimeTrackingField } from '@atlassian/jira-issue-view-layout-time-tracking-field/src/ui/index.tsx';
import { IssueViewUrlField } from '@atlassian/jira-issue-view-layout-url-field/src/ui/index.tsx';
import type { issueViewEntitlementField_servicedeskCustomerServiceEntitlementsField_EntitlementsField$key } from '@atlassian/jira-relay/src/__generated__/issueViewEntitlementField_servicedeskCustomerServiceEntitlementsField_EntitlementsField.graphql';
import type { relayLayoutItems_issueViewLayoutTemplatesLayoutItem_RelayLayoutItemFragmentContainer$key as RelayLayoutItemFragmentContainerFragment } from '@atlassian/jira-relay/src/__generated__/relayLayoutItems_issueViewLayoutTemplatesLayoutItem_RelayLayoutItemFragmentContainer.graphql';
import type { ui_businessGoalsIssueViewField_GoalsInlineEditViewField$key as GoalsFragment } from '@atlassian/jira-relay/src/__generated__/ui_businessGoalsIssueViewField_GoalsInlineEditViewField.graphql';
import type { ui_issueViewLayoutCascadingSelectField_IssueViewCascadingSelectField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutCascadingSelectField_IssueViewCascadingSelectField.graphql';
import type { ui_issueViewLayoutCheckboxSelectField_IssueViewCheckboxSelectField$key as CheckboxSelectFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutCheckboxSelectField_IssueViewCheckboxSelectField.graphql';
import type { ui_issueViewLayoutComponentsField_IssueViewComponentsField$key as ComponentsFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutComponentsField_IssueViewComponentsField.graphql';
import type { ui_issueViewLayoutDateField_IssueViewDateField$key as DateFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutDateField_IssueViewDateField.graphql';
import type { ui_issueViewLayoutDateTimeField_IssueViewDateTimeField$key as DateTimeFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutDateTimeField_IssueViewDateTimeField.graphql';
import type { ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField$key as GroupPickerFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField.graphql';
import type { ui_issueViewLayoutMultiUser_IssueViewMultiUser$key as MultiUserFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutMultiUser_IssueViewMultiUser.graphql';
import type { ui_issueViewLayoutNumberField_IssueViewNumberField$key as NumberFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutNumberField_IssueViewNumberField.graphql';
import type { ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField$key as OriginalEstimateFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField.graphql';
import type { ui_issueViewLayoutParentField_IssueViewParentField$key as ParentIssueFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutParentField_IssueViewParentField.graphql';
import type { ui_issueViewLayoutPeople_IssueViewPeople$key as PeopleFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutPeople_IssueViewPeople.graphql';
import type { ui_issueViewLayoutPriorityField_IssueViewPriorityField$key as PriorityFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutPriorityField_IssueViewPriorityField.graphql';
import type { ui_issueViewLayoutProjectField_IssueViewProjectField$key as ProjectFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutProjectField_IssueViewProjectField.graphql';
import type { ui_issueViewLayoutSentimentField_IssueViewSentimentField$key as SentimentFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutSentimentField_IssueViewSentimentField.graphql';
import type { ui_issueViewLayoutSingleLineText_IssueViewSingleLineText$key as SingleLineTextFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutSingleLineText_IssueViewSingleLineText.graphql';
import type { ui_issueViewLayoutSingleSelectField_IssueViewSingleSelectField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutSingleSelectField_IssueViewSingleSelectField.graphql';
import type { ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField$key as SingleSelectUserPickerFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField.graphql';
import type { ui_issueViewLayoutSprintField_IssueViewSprintField$key as SprintFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutSprintField_IssueViewSprintField.graphql';
import type { ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField$key as TimeTrackingFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField.graphql';
import type { ui_issueViewLayoutUrlField_IssueViewUrlField$key as UrlFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutUrlField_IssueViewUrlField.graphql';
import { AsyncEntitlementsField } from '@atlassian/jira-servicedesk-customer-service-entitlements-field/src/async.tsx';
import type { LayoutItemElementMapGeneric, LayoutItemPropsMapEntry } from './types';
/**
 * Layout items in the map are Relay Components primarily keyed by the relevant __typename.
 * But when the same graphql type is shared by multiple Jira field types, the map may contain a nested mapping object to map the Jira field type to type to the relevant component.
 *
 * To add a new Item to this Map:
 * 1. Add a new entry to this type.
 *  a. How to FF:
 *    i. Make your entry nullable at the relevant level.
 *    ii. eg: {@code GraphqlType: null | LayoutItemMapEntryProps<...>}
 *    iii. Then when cleaning up the flag make the item mandatory.
 *    iv: eg: {@code GraphqlType: LayoutItemMapEntryProps<...>}
 *  b: What types to use for new entries to the type:
 *    i. Use {@link LayoutItemPropsMapEntry} if you only have a single component to register
 *    ii. Use {@link LayoutItemPropsMapEntryWithGlancePanel} if your component triggers a glance panel opening on the RHS of the issue view
 *    iii. Use {@link LayoutItemPropsSplitComponentsMapEntry} is you need to distinguish different components for the same graphql type
 * 2. Add the entry to {@link getLayoutItemMap}
 *  a. How to FF:
 *    i. Use a ternary to switch your new entry to null when the FF is enabled
 *    ii. eg: {@code GraphqlType: isFfEnabled() ? Component : null, }
 * 3. Add the relay fragment of the relevant component to the fragment in {@link RelayLayoutItemSwitcher}
 *  a. Use a ff using the {@code @include(if: $isFfEnabled)} directive with a new graphql query variable
 */
export type LayoutItemPropsMap = {
	JiraCascadingSelectField: null | LayoutItemPropsMapEntry<ui_issueViewLayoutCascadingSelectField_IssueViewCascadingSelectField$key>;
	JiraLabelsField: null | LayoutItemPropsMapEntry<IssueViewLabelsSystemFieldFragment>;
	JiraSingleSelectField: null | LayoutItemPropsMapEntry<ui_issueViewLayoutSingleSelectField_IssueViewSingleSelectField$key>;
	JiraServiceManagementEntitlementField: null | LayoutItemPropsMapEntry<issueViewEntitlementField_servicedeskCustomerServiceEntitlementsField_EntitlementsField$key>;
	JiraGoalsField: null | LayoutItemPropsMapEntry<GoalsFragment>;
	JiraServiceManagementSentimentField: null | LayoutItemPropsMapEntry<SentimentFragment>;
	JiraPriorityField: null | LayoutItemPropsMapEntry<PriorityFragment>;
	JiraNumberField: null | LayoutItemPropsMapEntry<NumberFragment>;
	JiraSingleSelectUserPickerField: null | LayoutItemPropsMapEntry<SingleSelectUserPickerFragment>;
	JiraSprintField: null | LayoutItemPropsMapEntry<SprintFragment>;
	JiraSingleLineTextField: null | LayoutItemPropsMapEntry<SingleLineTextFragment>;
	JiraParentIssueField: null | LayoutItemPropsMapEntry<ParentIssueFragment>;
	JiraDatePickerField: null | LayoutItemPropsMapEntry<DateFragment>;
	JiraProjectField: null | LayoutItemPropsMapEntry<ProjectFragment>;
	JiraDateTimePickerField: null | LayoutItemPropsMapEntry<DateTimeFragment>;
	JiraUrlField: null | LayoutItemPropsMapEntry<UrlFragment>;
	JiraTimeTrackingField: null | LayoutItemPropsMapEntry<TimeTrackingFragment>;
	JiraCheckboxesField: null | LayoutItemPropsMapEntry<CheckboxSelectFragment>;
	JiraPeopleField: null | LayoutItemPropsMapEntry<PeopleFragment>;
	JiraComponentsField: null | LayoutItemPropsMapEntry<ComponentsFragment>;
	JiraOriginalTimeEstimateField: null | LayoutItemPropsMapEntry<OriginalEstimateFragment>;
	JiraMultipleSelectUserPickerField: null | LayoutItemPropsMapEntry<MultiUserFragment>;
	JiraSingleGroupPickerField: null | LayoutItemPropsMapEntry<GroupPickerFragment>;
};
/**
 * Calculated literal type of the expected return value of {@link getLayoutItemMap}. See {@link LayoutItemElementMapGeneric} for how this calculation is performed.
 */
export type LayoutItemElementMap = LayoutItemElementMapGeneric<LayoutItemPropsMap>;

/**
 * See {@link LayoutItemPropsMap} for instructions.
 * Memoized function instead of static object because we want it to be initialised once in a react 'render' lifecycle for FFing changes and easier mocking
 * You may need to cast the output to  {@link LayoutItemElementMapNonLiteral} if you need to access the map with a non-literal string
 */
export const getLayoutItemMap = memoizeOne(
	(): LayoutItemElementMap => ({
		JiraCascadingSelectField: ff(
			'issue.details.relay-cascading-select-field-issue-view-layout-integration',
		)
			? {
					item: IssueViewCascadingSelectField,
				}
			: null,

		JiraLabelsField: {
			item: IssueViewLabelsSystemField,
		},

		JiraSingleSelectField: {
			item: IssueViewSingleSelectField,
		},

		JiraServiceManagementEntitlementField: {
			item: AsyncEntitlementsField,
		},

		JiraGoalsField: expVal('native_goals_on_issue_view', 'isGoalsFieldEnabled', false)
			? {
					item: GoalsInlineEditViewField,
				}
			: null,

		JiraPriorityField: ff('relay-migration-issue-fields-priority_ri9vi')
			? {
					item: IssueViewPriorityField,
				}
			: null,

		JiraSingleSelectUserPickerField:
			ff('relay-migration-issue-fields-assignee-ld') ||
			ff('relay-migration-issue-fields-reporter_jfzs2') ||
			ff('relay-migration-issue-fields-user')
				? {
						item: IssueViewSingleSelectUserPickerField,
					}
				: null,

		JiraSprintField: ff('relay-migration-issue-fields-sprint_jozpu')
			? {
					item: IssueViewSprintField,
				}
			: null,

		JiraNumberField: ff('relay-migration-issue-fields-number_q0em4')
			? {
					item: IssueViewNumberField,
				}
			: null,

		JiraServiceManagementSentimentField: ff('sentiment-custom-field')
			? {
					item: IssueViewSentimentField,
				}
			: null,

		JiraSingleLineTextField:
			ff('relay-migration-issue-fields-single-line-text_in6w6') ||
			ff('relay-migration-issue-fields-single-line-text-message')
				? {
						item: IssueViewSingleLineText,
					}
				: null,

		JiraParentIssueField: ff('relay-migration-issue-fields-parent_5p1ac')
			? {
					item: IssueViewParentField,
				}
			: null,

		JiraDatePickerField: ff('relay-migration-issue-fields-date_ptj72')
			? {
					item: fg('issue_view_due_date_colored_field_targeting_gate')
						? IssueViewDateFieldWithUrgencyTreatment
						: IssueViewDateField,
				}
			: null,

		JiraProjectField: ff('relay-migration-issue-fields-project-select_w0xem')
			? {
					item: IssueViewProjectField,
				}
			: null,

		JiraDateTimePickerField: ff('relay-migration-issue-fields-date-time_eaqd2')
			? {
					item: IssueViewDateTimeField,
				}
			: null,

		JiraUrlField: ff('relay-migration-issue-fields-url_ob8uu')
			? {
					item: IssueViewUrlField,
				}
			: null,

		JiraTimeTrackingField: ff('relay-migration-issue-fields-time-tracking_eefh0')
			? {
					item: IssueViewTimeTrackingField,
				}
			: null,

		JiraCheckboxesField: ff('relay-migration-issue-fields-checkbox-select_ytl5n')
			? {
					item: IssueViewCheckboxSelectField,
				}
			: null,

		JiraPeopleField:
			ff('relay-migration-issue-fields-people') || ff('relay-migration-issue-fields-approvers')
				? {
						item: IssueViewPeople,
					}
				: null,

		JiraComponentsField: ff('relay-migration-issue-fields-components-select_prpz1')
			? {
					item: IssueViewComponentsField,
				}
			: null,

		JiraOriginalTimeEstimateField: ff('relay-migration-issue-fields-original-estimate_eb4wv')
			? {
					item: IssueViewOriginalEstimateField,
				}
			: null,

		JiraMultipleSelectUserPickerField: ff('relay-migration-issue-fields-multi-user')
			? {
					item: IssueViewMultiUser,
				}
			: null,

		JiraSingleGroupPickerField: ff('relay-migration-issue-fields-group-picker')
			? {
					item: IssueViewGroupPickerField,
				}
			: null,
	}),
);

type RelayLayoutItemSwitcherProps = {
	fragmentKey: RelayLayoutItemFragmentContainerFragment | null;
	area: string;
	// There will be other properties in the payload for layout items registered with {@link LayoutItemPropsWithPanel}. The payload can be specified as the second generic argument
	onOpenPanel: (payload: { title: string }) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Component: any;
	isLast?: boolean;
};

/**
 * Component used to aggregate all layout-controlled components' relay fragments.
 *
 * see {@link LayoutItemPropsMap} for instructions
 */
export const RelayLayoutItemFragmentContainer = ({
	fragmentKey,
	area,
	Component,
	onOpenPanel,
	isLast,
}: RelayLayoutItemSwitcherProps) => {
	const data = useFragment<RelayLayoutItemFragmentContainerFragment>(
		graphql`
			# Fragment on Node so that we can support a union type of non-JiraIssueFields eventually
			fragment relayLayoutItems_issueViewLayoutTemplatesLayoutItem_RelayLayoutItemFragmentContainer on Node
			@argumentDefinitions(
				issueViewRelayCascadingSelectFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayGoalsFlag: { type: "Boolean!", defaultValue: false }
				jsmSentimentCustomFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayPriorityFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayAssigneeFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelaySprintFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayNumberFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelaySingleLineTextFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayParentFieldFlag: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-parent"
				}
				issueViewRelayDateFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayProjectFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayDateTimeFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayUrlFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayTimeTrackingFieldFlag: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-time-tracking.relayprovider"
				}
				issueViewRelayCheckboxSelectFieldFlag: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-checkbox-select.relayprovider"
				}
				issueViewRelayPeopleAndApproversFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayComponentsFieldFlag: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-components.relayprovider"
				}
				issueViewRelayOriginalEstimateFieldFlag: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-original-estimate.relayprovider"
				}
				# TODO: change relay provider to include participants in follow up PR
				issueViewRelayMultiUserAndParticipantsFieldFlag: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-multi-user.relayprovider"
				}
				issueViewRelayGroupPickerFieldFlag: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-group-picker.relayprovider"
				}
			) {
				# Add new fragments for Relay powered fields here:
				...ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField
					@include(if: $issueViewRelayGroupPickerFieldFlag)
				...ui_issueViewLayoutMultiUser_IssueViewMultiUser
					@include(if: $issueViewRelayMultiUserAndParticipantsFieldFlag)
				...ui_issueViewLayoutPeople_IssueViewPeople
					@include(if: $issueViewRelayPeopleAndApproversFieldFlag)
				...ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField
					@include(if: $issueViewRelayTimeTrackingFieldFlag)
				...ui_issueViewLayoutCheckboxSelectField_IssueViewCheckboxSelectField
					@include(if: $issueViewRelayCheckboxSelectFieldFlag)
				...ui_issueViewLayoutComponentsField_IssueViewComponentsField
					@include(if: $issueViewRelayComponentsFieldFlag)
				...ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField
					@include(if: $issueViewRelayOriginalEstimateFieldFlag)
				...ui_issueViewLayoutProjectField_IssueViewProjectField
					@include(if: $issueViewRelayProjectFieldFlag)
				...ui_issueViewLayoutUrlField_IssueViewUrlField @include(if: $issueViewRelayUrlFieldFlag)
				...ui_issueViewLayoutSingleLineText_IssueViewSingleLineText
					@include(if: $issueViewRelaySingleLineTextFieldFlag)
				...ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField
					@include(if: $issueViewRelayAssigneeFieldFlag)
				...ui_issueViewLayoutDateField_IssueViewDateField @include(if: $issueViewRelayDateFieldFlag)
				...ui_issueViewLayoutDateTimeField_IssueViewDateTimeField
					@include(if: $issueViewRelayDateTimeFieldFlag)
				...ui_issueViewLayoutLabelsSystemField_IssueViewLabelsSystemField
				...ui_issueViewLayoutSingleSelectField_IssueViewSingleSelectField
				...ui_issueViewLayoutSentimentField_IssueViewSentimentField
					@include(if: $jsmSentimentCustomFieldFlag)
				...ui_issueViewLayoutNumberField_IssueViewNumberField
					@include(if: $issueViewRelayNumberFieldFlag)
				...ui_issueViewLayoutCascadingSelectField_IssueViewCascadingSelectField
					@include(if: $issueViewRelayCascadingSelectFlag)
				# eslint-disable-next-line @atlassian/relay/must-colocate-fragment-spreads
				...issueViewEntitlementField_servicedeskCustomerServiceEntitlementsField_EntitlementsField
				...ui_businessGoalsIssueViewField_GoalsInlineEditViewField
					@include(if: $issueViewRelayGoalsFlag)
				...ui_issueViewLayoutPriorityField_IssueViewPriorityField
					@include(if: $issueViewRelayPriorityFieldFlag)
				...ui_issueViewLayoutSprintField_IssueViewSprintField
					@include(if: $issueViewRelaySprintFieldFlag)
				...ui_issueViewLayoutParentField_IssueViewParentField
					@include(if: $issueViewRelayParentFieldFlag)
			}
		`,
		fragmentKey,
	);

	return <Component area={area} fragmentKey={data} onOpenPanel={onOpenPanel} isLast={isLast} />;
};
