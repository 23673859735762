import type { ProjectType } from '@atlassian/jira-common-constants';
import { ff } from '@atlassian/jira-feature-flagging';
import type { Option } from '@atlassian/jira-issue-field-multi-select-editview-full/src/ui/multi-select/types.tsx';
import type {
	SelectValueShape,
	Option as SelectOption,
} from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';
import type { SelectableValueOption } from '@atlassian/jira-issue-selectable-field-edit-view/src/ui/types';
import type { Version } from '@atlassian/jira-issue-shared-types/src/common/types/issue-type.tsx';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { getVersionUrl } from './utils';

type ReleaseVersion = { ari: string } & Version;

export const transformFromStateValue = (
	value: ReleaseVersion[],
	{
		projectKey,
		projectType,
		fieldId,
	}: {
		projectKey: ProjectKey;
		projectType?: ProjectType;
		fieldId: string;
	},
): SelectValueShape[] =>
	value
		? value.map((version) => ({
				value: version.id,
				content: version.name || '',
				href: getVersionUrl({ version, fieldId, projectKey, projectType }),
				ari: version.ari,
			}))
		: [];

export const transformToStateValue = (value: SelectValueShape[] | null): Version[] | null =>
	value &&
	value.map((item) => ({
		name: item.content,
		id: String(item.value),
		ari: item.ari,
	}));

export const transformRelayToReduxValue = (
	option: Option | SelectableValueOption,
): SelectOption => {
	if (
		!ff('issue.details.relay-release-version-field-issue-view-integration') &&
		'label' in option
	) {
		return {
			value: option.versionId ?? '',
			label: option.label,
			content: option.label,
			ari: option.value,
		};
	}

	return {
		value: option.versionId ?? '',
		label: option.selectableLabel ?? '',
		content: option.selectableLabel ?? '',
		ari: option.id,
	};
};

/**
 * Transforms a redux value to a relay selectable value.
 * @param option
 * @returns a combanation of Option and SelectableValueOption types since the conditional
 * component props type required both. However, when the
 * 'issue.details.relay-release-version-field-issue-view-integration' feature flag
 * is cleaned up, the return type will be changed to only SelectableValueOption.
 */
export const transformReduxToRelayValue = (option: SelectValueShape): SelectableValueOption => ({
	versionId: String(option.value),
	selectableLabel: option.content || '',
	selectableGroupKey: null,
	selectableIconUrl: null,
	id: option.ari || '',
});
