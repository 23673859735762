import { createFilter } from '@atlaskit/select';
import { toConfluencePageType } from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import {
	toRemoteLinkGlobalId,
	toConfluencePageId,
	toHref,
} from '@atlassian/jira-shared-types/src/general.tsx';
import type { SelectOption, SelectOptionGroup } from '../../common/types';
import type { ServerResponse, Pages, State, SearchAction } from './types';

export const LIMIT = 20;
const SUPPORTED_CONTENT_TYPES = ['blogpost', 'page'];

const filterRecentResults = createFilter();

export const getSearchUrl = (query: string, appId: string, spaceKey = ''): string =>
	`/rest/confluenceIssueLink/1/confluence/search?query=${query}&appId=${appId}&spaceKey=${spaceKey}&maxResults=${LIMIT}&_=${Date.now()}`;

export const transformRecentOptions = (
	options: SelectOption[],
	label: string,
	spaceKey: string,
): [SelectOptionGroup] => {
	const filteredOptions = options.filter((option) => {
		if (spaceKey) {
			return option.page.spaceKey === spaceKey;
		}
		return true;
	});
	return [
		{
			label,
			options: filteredOptions,
		},
	];
};

export const transformSearchOptions = (
	options: ServerResponse[],
	label: string,
	linkedPages: Pages = [],
	mentionPages: Pages = [],
	filteredRecent: SelectOption[] = [],
): [SelectOptionGroup] => {
	const recentPages = filteredRecent.map((option) => option.page);

	const linkedPageHrefs = linkedPages
		.concat(recentPages, mentionPages)
		// @ts-expect-error - TS2339 - Property 'href' does not exist on type 'FailedRemoteLink | ConfluencePage'.
		.map((page) => page.href || '');

	return [
		{
			label,
			options: options
				.filter((page) => SUPPORTED_CONTENT_TYPES.includes(page.type))
				.map<SelectOption>((option) => {
					const page = {
						id: toConfluencePageId(option.id.toString()),
						globalId: toRemoteLinkGlobalId(''),
						href: toHref(option.url),
						title: option.title,
						type: toConfluencePageType(option.type),
						space: option.spaceName !== undefined ? option.spaceName : '',
						lastModified:
							option.lastModified !== undefined ? new Date(option.lastModified).getTime() : 0,
					};

					return {
						label: option.title,
						value: option.url,
						page,
						data: page,
					};
				})
				.filter((option) => !linkedPageHrefs.includes(option.page.href)),
		},
	];
};

export const confluenceSearchReducer = (state: State, action: SearchAction): State => {
	const { type, payload = {} } = action;
	const { options = [], error = null } = payload;
	const currentState = { loading: false, options, error, type: 'search' };
	switch (type) {
		case 'SEARCH_API_LOADING':
			// @ts-expect-error - TS2322 - Type '{ loading: true; options: Options; error: Error | null; type: string; }' is not assignable to type 'State'.
			return {
				...currentState,
				loading: true,
			};
		case 'SEARCH_API_DONE':
		case 'SEARCH_API_ERROR':
			// @ts-expect-error - TS2322 - Type '{ loading: boolean; options: Options; error: Error | null; type: string; }' is not assignable to type 'State'.
			return currentState;

		case 'SEARCH_API_CLEAR':
			// @ts-expect-error - TS2322 - Type '{ options: []; loading: boolean; error: Error | null; type: string; }' is not assignable to type 'State'.
			return {
				...currentState,
				options: [],
			};

		case 'SEARCH_API_RESET':
			return {
				...currentState,
				type: 'recent',
			};
		default:
			return state;
	}
};

export const getFilteredRecentOptions = (
	allRecent: SelectOption[],
	input: string,
	spaceKey: string,
): SelectOption[] =>
	allRecent.filter((option) => {
		if (spaceKey) {
			// @ts-expect-error - TS2345 - Argument of type 'SelectOption' is not assignable to parameter of type 'Option'.
			return option.page.spaceKey === spaceKey ? filterRecentResults(option, input) : false;
		}
		// @ts-expect-error - TS2345 - Argument of type 'SelectOption' is not assignable to parameter of type 'Option'.
		return filterRecentResults(option, input);
	});

export const mergeResponse = (
	recent: SelectOption[],
	search: [SelectOptionGroup],
): [SelectOptionGroup] => {
	if (search && search.length > 0 && recent.length > 0) {
		const { options, label } = search[0];
		return [
			{
				label,
				options: [...recent, ...options].slice(0, LIMIT),
			},
		];
	}
	return search;
};
