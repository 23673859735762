import React, { useMemo } from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { IssueTaskDecisionProvider } from '@atlassian/jira-issue-task-decision-provider/src/controllers/index.tsx';
import { useCommentTasksUpdater } from '@atlassian/jira-issue-task-decision-provider/src/controllers/use-comment-tasks-updater/index.tsx';
import Renderer from '@atlassian/jira-issue-view-media-renderer';
import { useEditorState } from '../../../../state/context.tsx';
import type { Props, PropsWithTaskDecisionProvider } from './types';

const ConnectedRenderer = (props: PropsWithTaskDecisionProvider) => {
	const { adf, disableActions, taskDecisionProvider } = props;

	const [{ mentionProvider, contextIdentifier, mediaContext }] = useEditorState();

	if (!mentionProvider || !contextIdentifier || !mediaContext) {
		return null;
	}

	return (
		<Renderer
			adf={adf}
			disableActions={disableActions}
			mentionProvider={mentionProvider}
			taskDecisionProvider={taskDecisionProvider}
			contextIdentifier={contextIdentifier}
			mediaContext={mediaContext}
		/>
	);
};

const ConnectedRendererWithTaskDecisionProvider = (props: Props) => {
	const { canEdit, commentId } = props;

	const toggleTask = useCommentTasksUpdater(commentId);
	const taskDecisionProvider = useMemo(
		() => new IssueTaskDecisionProvider(toggleTask),
		[toggleTask],
	);

	return (
		<ConnectedRenderer
			{...props}
			disableActions={!canEdit}
			taskDecisionProvider={taskDecisionProvider}
		/>
	);
};

export default componentWithCondition(
	() => expVal('issue_view_action_items', 'isActionItemsEnabled', false),
	ConnectedRendererWithTaskDecisionProvider,
	ConnectedRenderer,
);
