import React, { Component } from 'react';
import type {
	EcosystemGlanceType,
	Glance as ConnectGlance,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/ecosystem.tsx';
import {
	FORGE_ENTITY_TYPE,
	CONNECT_ENTITY_TYPE,
} from '@atlassian/jira-issue-view-common-constants/src/ecosystem-constants.tsx';

import type { Glance as ForgeGlance } from '@atlassian/jira-issue-view-common-types/src/forge-types';

import type { GlanceUiState } from '@atlassian/jira-issue-view-common-types/src/glance-type';
import type { Glance as GlanceReference } from '@atlassian/jira-issue-view-layout/src/services/types';
import type { RenderStyles, PassedDownRefs } from '../../side-panel/types';
import SidePanel from '../../side-panel/view.tsx';
import { ConnectGlancePanel } from '../connect/connect-glance-panel-view';
import ForgeGlancePanel from '../forge/issue-glance/panel-view';

export type Props = {
	glance: ConnectGlance | ForgeGlance;
	glanceType: EcosystemGlanceType;
	openedGlance: ConnectGlance | ForgeGlance | GlanceUiState | GlanceReference | null;
	refs: PassedDownRefs;
};

// eslint-disable-next-line jira/react/no-class-components
export class GlanceAnimatedPanel extends Component<Props> {
	static defaultProps = {
		openedGlance: null,
	};

	renderGlance(styles: RenderStyles, isOpen: boolean) {
		const { glance } = this.props;
		switch (glance.glanceType) {
			case CONNECT_ENTITY_TYPE:
				return (
					<ConnectGlancePanel
						styles={styles}
						appKey={glance.appKey}
						moduleKey={glance.moduleKey}
						key={`issue.ecosystem.glance.panel_${glance.appKey}_${glance.moduleKey}`}
					/>
				);

			case FORGE_ENTITY_TYPE:
				return (
					<ForgeGlancePanel
						// @ts-expect-error - this field does not exist
						panelWidth={styles.panelWidth}
						styles={styles}
						key={`issue.forge.glance.panel_${glance.appKey}_${glance.moduleKey}`}
						glance={glance}
						isOpen={isOpen}
					/>
				);
			default:
				return null;
		}
	}

	render() {
		const { openedGlance, glance, refs } = this.props;
		const isOpen =
			!!openedGlance &&
			openedGlance.appKey === glance.appKey &&
			openedGlance.moduleKey === glance.moduleKey;

		return (
			<SidePanel refs={refs} isOpen={isOpen}>
				{(styles: RenderStyles) => this.renderGlance(styles, isOpen)}
			</SidePanel>
		);
	}
}

export default GlanceAnimatedPanel;
