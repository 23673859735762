import React, { useMemo } from 'react';
import type { ActiveKeyMap } from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-registry';
import { ff } from '@atlassian/jira-feature-flagging';
import { FormattedMessage } from '@atlassian/jira-intl';
import { KEYBOARD_SHORTCUT_PRIORITY, MENU_SHORTCUT_DIALOG_OPEN } from '../constant';
import { messages } from '../messages';
import type { TriggerDotDialogShortcutAction } from '../types';

const useMenuShortcuts = (triggerShortcutAction: TriggerDotDialogShortcutAction): ActiveKeyMap =>
	useMemo(
		() => ({
			[MENU_SHORTCUT_DIALOG_OPEN]: {
				callback: () => {
					triggerShortcutAction('triggerShortcutDialogOpen', MENU_SHORTCUT_DIALOG_OPEN);
				},
				label:
					ff('compal-sunset-and-remove-dot-menu_66j8q') &&
					ff('compal-dot-menu-shortcut-integration_dvvg2') ? (
						<FormattedMessage {...messages.openCompalDialog} />
					) : (
						<FormattedMessage {...messages.openShortcutDialog} />
					),
				priority: KEYBOARD_SHORTCUT_PRIORITY,
			},
		}),
		[triggerShortcutAction],
	);

export default useMenuShortcuts;
