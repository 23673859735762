import React, { useCallback } from 'react';
import messages from '@atlassian/jira-common-components-inline-edit/src/messages';
import { useIntl } from '@atlassian/jira-intl';
import { FieldDescription } from '@atlassian/jira-issue-field-description/src/ui/index.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading';
import {
	FieldHeadingTitle,
	FieldWrapper,
	SideBySideField,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { ReadViewContainer } from '@atlassian/jira-issue-field-inline-edit/src/styled.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin';
import TagView from '@atlassian/jira-issue-view-internal-tagview/src/tag-view';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import CheckboxFieldView, { type CheckboxValueItemShape } from './checkbox-field-view';
import { InlineEditContainer } from './checkbox-inline-edit-view.styled';

type Props = {
	isEditable?: boolean;
	isEditing?: boolean;
	isMobile?: boolean;
	issueKey?: IssueKey; // TODO - make issueKey mandatory in BENTO-11149,
	options?: CheckboxValueItemShape[];
	value?: CheckboxValueItemShape[];
	label?: string;
	tagAppearance?: string;
	placeholder?: string;
	noValueText?: string;
	invalidMessage?: string;
	fieldId: string;
	showPinButton?: boolean;
	onChange: (arg1: CheckboxValueItemShape[]) => void;
	onEditRequest: () => void;
	onConfirm: () => void;
	onCancel: () => void;
};

export const CheckboxInlineEditView = ({
	isEditable = false,
	isEditing = false,
	isMobile = false,
	options = [],
	value = [],
	label = '',
	placeholder,
	noValueText,
	tagAppearance = 'default',
	invalidMessage,
	issueKey,
	fieldId,
	showPinButton,
	onChange,
	onEditRequest,
	onConfirm,
	onCancel,
}: Props) => {
	const { formatMessage } = useIntl();

	const renderReadView = useCallback(
		() => (
			<TagView
				value={value.map(({ label: lLabel, value: lValue }) => ({
					content: lLabel,
					value: lValue,
				}))}
				noValueText={noValueText}
				tagAppearance={tagAppearance}
				shouldHideLinks={isMobile}
			/>
		),
		[isMobile, noValueText, tagAppearance, value],
	);

	const renderEditView = useCallback(
		() => (
			<CheckboxFieldView
				value={value}
				options={options}
				onChange={onChange}
				placeholder={placeholder}
				invalidMessage={invalidMessage}
			/>
		),
		[value, onChange, options, placeholder, invalidMessage],
	);

	return (
		<FieldWrapper data-testid={`issue.views.field.checkbox-inline-edit.${fieldId}`}>
			<FieldHeading fieldId={fieldId}>
				<FieldHeadingTitle>{label}</FieldHeadingTitle>
				{issueKey !== undefined && fieldId !== undefined && (
					<FieldDescription issueKey={issueKey} fieldKey={fieldId} label={label} />
				)}
				{showPinButton === true && <FieldPin fieldId={fieldId} label={label} />}
			</FieldHeading>
			<SideBySideField isEditing={isEditing}>
				<InlineEditContainer isEditable={isEditable} hasValue={value && value.length !== 0}>
					<FieldInlineEditStateLess
						isLabelHidden
						label={label}
						readView={<ReadViewContainer>{renderReadView()}</ReadViewContainer>}
						testId={`issue.views.field.checkbox-inline-edit.${fieldId}`}
						editView={isEditable ? renderEditView() : null}
						// @ts-expect-error - TS2322 - Property 'disableEditViewFieldBase' does not exist on type 'IntrinsicAttributes & FieldInlineEditStateLessProps<unknown>'.
						disableEditViewFieldBase
						areActionButtonsHidden
						isEditing={isEditing}
						onConfirm={onConfirm}
						onCancel={onCancel}
						onEscape={onCancel}
						onEditRequested={onEditRequest}
						isFitContainerWidthReadView={!isMobile}
						editButtonLabel={formatMessage(messages.editButtonLabel, {
							fieldName: label,
						})}
						confirmButtonLabel={formatMessage(messages.confirmButtonLabel, {
							fieldName: label,
						})}
						cancelButtonLabel={formatMessage(messages.cancelButtonLabel, {
							fieldName: label,
						})}
						isEditable={isEditable}
					/>
				</InlineEditContainer>
			</SideBySideField>
		</FieldWrapper>
	);
};

export default CheckboxInlineEditView;
