import React from 'react';
import type { PreloadedQuery } from 'react-relay/hooks';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type { signatureCrFetchComponentQuery } from '@atlassian/jira-relay/src/__generated__/signatureCrFetchComponentQuery.graphql';
import { SignatureCrFetchComponent } from './signature-cr-fetch-component';

export const TEAM_NAME = 'jsm-nebula';
export const PACKAGE_NAME = 'cannedResponses';
export const PACKAGE_ID = 'cannedResponsesissueView';

export interface SignatureCrFetchWrapperProps {
	setSignatureCR: (signature: string | null) => void;
	queryReference: PreloadedQuery<signatureCrFetchComponentQuery>;
}

export const SignatureCrFetchWrapper = ({
	queryReference,
	setSignatureCR,
}: SignatureCrFetchWrapperProps) => {
	const onError = () => {
		setSignatureCR(null);
	};

	return (
		<JSErrorBoundary
			teamName={TEAM_NAME}
			packageName={PACKAGE_NAME}
			id={PACKAGE_ID}
			onError={onError}
			fallback="unmount"
		>
			<Placeholder name="canned-response-container" fallback={null}>
				<SignatureCrFetchComponent
					queryReference={queryReference}
					setSignatureCR={setSignatureCR}
				/>
			</Placeholder>
		</JSErrorBoundary>
	);
};
