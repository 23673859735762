import React, { useMemo } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { fg } from '@atlassian/jira-feature-gating';
import { withMetrics } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import { CONTEXT_TOKEN_TYPE_JIRA, ISSUE_PANEL_MODULE } from '@atlassian/jira-forge-ui-constants';
import {
	isCustomUIExtension,
	isNativeUiExtension,
} from '@atlassian/jira-forge-ui-utils-internal/src/utils/extension';
import type { IssuePanel as IssuePanelType } from '@atlassian/jira-forge-ui/src/ui/components/issue-panel';
import Placeholder from '@atlassian/jira-placeholder';
import { LazyIssueViewForgeExtension } from '../../common/ui/extension/issue-view';
import { LazyIssueViewIframe } from '../../common/ui/iframe/issue-view';
import { Skeleton } from '../../common/ui/skeleton/main.tsx';
import type { LazyComponentProps, LazyRenderer } from '../../types';

const skeletonConfig = [{ width: 100 }, { width: 75 }];

export const LazyIssuePanelRenderer: LazyRenderer<typeof IssuePanelType> = withMetrics(
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyForPaint<LazyRenderer<typeof IssuePanelType>>(
		() =>
			import(
				/* webpackChunkName: "async-forge-ui-issue-panel" */ '@atlassian/jira-forge-ui/src/ui/components/issue-panel'
			),
		{ ssr: false },
	),
);

export const LazyIssuePanel = (props: LazyComponentProps<typeof IssuePanelType>) => {
	const { extension, extensionData, setCustomActions, ...restProps } = props;

	const loadingComponent = useMemo(() => <Skeleton skeletonConfig={skeletonConfig} />, []);
	const extensionDataValue = useMemo(
		() => ({
			...extensionData,
			type: ISSUE_PANEL_MODULE,
		}),
		[extensionData],
	);
	const extraProps = useMemo(
		() => ({
			loadingComponent,
			module: ISSUE_PANEL_MODULE,
			extensionData: extensionDataValue,
			extension,
			contextToken: CONTEXT_TOKEN_TYPE_JIRA,
		}),
		[extension, extensionDataValue, loadingComponent],
	);

	if (
		(isNativeUiExtension(extension) || isCustomUIExtension(extension)) &&
		// https://switcheroo.atlassian.com/ui/gates/cd39682c-bc5b-4f4b-af87-f25672cfc326
		fg('jira_new_ui_kit_renderer')
	) {
		return (
			<Placeholder name="issue-panel" fallback={loadingComponent}>
				<LazyIssueViewForgeExtension {...restProps} {...extraProps} />
			</Placeholder>
		);
	}

	return (
		<Placeholder name="issue-panel" fallback={loadingComponent}>
			{isCustomUIExtension(extension) ? (
				<LazyIssueViewIframe {...restProps} {...extraProps} />
			) : (
				<LazyIssuePanelRenderer {...props} {...extraProps} />
			)}
		</Placeholder>
	);
};

export const AsyncIssuePanel = LazyIssuePanel;
