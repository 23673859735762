// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addToParentLevel: {
		id: 'issue.add-to-parent-level',
		defaultMessage: 'Add parent',
		description: 'Meatball option to add a child to a parent level',
	},
	changeParentLevel: {
		id: 'issue.change-parent-level',
		defaultMessage: 'Change parent',
		description: 'Meatball option to change a child parent level',
	},
});
