// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	remoteLinkAuthText: {
		id: 'issues.issue-links.app-link-option.auth-required-nonfinal',
		defaultMessage: 'Authenticate to search',
		description:
			'Text for application links that require authentication before we can link to their resources',
	},
});
