import { useCallback } from 'react';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import { useAnalyticsEvents, type CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import defaultOptions from '@atlassian/jira-common-constants/src/fetch-default-options';
import performance from '@atlassian/jira-common-performance/src/performance.tsx';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-legacy
import { getTenantContext_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-common-util-get-tenant-context';
import logger from '@atlassian/jira-common-util-logging/src/log.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import {
	fireSuggestionsRequestSuccessEvent,
	fireSuggestionsRequestFailedEvent,
} from '@atlassian/jira-issue-analytics/src/services/suggestions/index.tsx';
import { COMPONENTS_TYPE } from '@atlassian/jira-platform-field-config';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	RECOMMENDATIONS_LOCATION,
	RECOMMENDATIONS_TIMEOUT,
	RECOMMENDATIONS_URL,
} from '../common/constants';
import type {
	CollaborationGraphResponse,
	FieldWithRecommendation,
	SmartsKeyValue,
	UseFieldRankerProps,
} from './types';
import { executeWithTimeout, fetchRecommendations } from './utils';

const fetchRankedFields = (
	fieldId: string,
	values: FieldWithRecommendation[],
	issueId?: string | number,
	projectId?: string | number,
	sessionId?: string,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
): Promise<FieldWithRecommendation[]> => {
	const { atlassianAccountId, cloudId } = getTenantContext_DEPRECATED_DO_NOT_USE();
	const valuesMap = keyBy(values, 'id');

	// JIV-15235: Pass experience as argument
	const experience = fieldId === COMPONENTS_TYPE ? 'JiraComponents' : 'JiraFields';
	const startTime = performance.now();

	return fetchJson<CollaborationGraphResponse>(RECOMMENDATIONS_URL, {
		...defaultOptions,
		method: 'POST',
		body: JSON.stringify({
			context: {
				fieldId,
				product: 'jira',
				containerId: projectId,
				objectId: issueId,
				userId: atlassianAccountId,
				tenantId: cloudId,
				additionalContextList: values.map(({ id, name }) => ({ key: id, value: name })),
			},
			modelRequestParams: {
				experience,
				caller: 'jira',
			},
			requestingUserId: atlassianAccountId,
			sessionId,
		}),
	})
		.then(({ recommendedEntities }) => {
			fireSuggestionsRequestSuccessEvent(fieldId, startTime, createAnalyticsEvent, {
				sessionId,
			});

			// Collaboration Graph returns entities by the order it was passed, so we need to sort them by score
			// NOTE: This is not type-safe, no way to check if 'score' actually exists
			const sortedRecommendations = orderBy(recommendedEntities, 'score', 'desc');
			return sortedRecommendations.map(({ id }) => valuesMap[id]);
		})
		.catch((e) => {
			fireSuggestionsRequestFailedEvent(fieldId, startTime, createAnalyticsEvent, {
				sessionId,
			});
			logger.safeErrorWithoutCustomerData(
				RECOMMENDATIONS_LOCATION,
				`error while ranking ${fieldId} via CG`,
				e,
			);
			return values;
		});
};

/**
 * Rank list of field values by calling Field recommendations service
 *
 * @deprecated Use `useFieldRanker` instead
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	fieldId: string,
	values: FieldWithRecommendation[],
	issueId?: string | number,
	projectId?: string | number,
	sessionId?: string,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
): Promise<FieldWithRecommendation[]> => {
	// empty list or single element list
	// no need to call server as no need to rank
	if (!values || values.length <= 1) {
		return Promise.resolve(values);
	}
	return executeWithTimeout(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(): Promise<any> | Promise<FieldWithRecommendation[]> =>
			fetchRankedFields(fieldId, values, issueId, projectId, sessionId, createAnalyticsEvent),
		values,
		RECOMMENDATIONS_TIMEOUT,
		fieldId,
		sessionId,
		createAnalyticsEvent,
	);
};

/**
 * Hook that returns a function used to rank a list of field values using the Collaboration Graph API.
 *
 * @param props - Context passed to the Collaboration Graph API
 *
 * @returns An object containing the function that ranks field options
 */
export const useFieldRanker = (props: UseFieldRankerProps) => {
	const { experience, fieldId, issueId, projectId, sessionId } = props;

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const accountId = useAccountId();
	const cloudId = useCloudId();

	/**
	 * Function that ranks the field options using the Collaboration Graph API.
	 */
	const rankFieldOptions = useCallback(
		/**
		 * Calls `fetchRecommendations()` with the field options to be ranked.
		 *
		 * @typeParam Option - The type of the option to be transformed as `SmartsKeyValue`s
		 * @param {Option[]} options - List of options to get recommendations for.
		 * @param {Function} getSmartsKeyValue - Function that transforms `Option` to `SmartsKeyValue` shape required by the Collaboration Graph API.
		 *
		 * @returns Either the sorted options based on the recommendation scores, or the same options passed if the request fails.
		 */
		async <Option,>(
			options: Option[],
			getSmartsKeyValue: (option: Option) => SmartsKeyValue,
		): Promise<Option[]> => {
			if (!options || options.length <= 1) {
				return Promise.resolve(options);
			}

			const contextList: SmartsKeyValue[] = [];

			/**
			 * Create a map of the options based on the `key` value from `getSmartsKeyValue`.
			 * This will make it easier to sort the recommended results.
			 */
			const optionsMap: Record<string, Option> = {};

			options.forEach((option) => {
				const smartsKeyValue = getSmartsKeyValue(option);

				contextList.push(smartsKeyValue);
				optionsMap[smartsKeyValue.key] = option;
			});

			const context = {
				cloudId,
				contextList,
				fieldId,
				issueId,
				projectId,
				userId: accountId ?? '',
			};

			return executeWithTimeout(
				async () => {
					const rankedEntities = await fetchRecommendations(
						context,
						experience,
						sessionId,
						createAnalyticsEvent,
					);

					return rankedEntities.map(({ key }) => optionsMap[key]);
				},
				options,
				RECOMMENDATIONS_TIMEOUT,
				fieldId,
				sessionId,
				createAnalyticsEvent,
			);
		},
		[accountId, cloudId, createAnalyticsEvent, experience, fieldId, issueId, projectId, sessionId],
	);

	return { rankFieldOptions };
};
