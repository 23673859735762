import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { ViewFragment } from '@atlassian/jira-polaris-remote-legacy-project/src/services/project-config/get';
import type { GetViewResponse } from '@atlassian/jira-polaris-remote-view/src/services/polaris-api/fetch-view/index.tsx';
import { fireOperationalAnalyticsDeferred } from '@atlassian/jira-product-analytics-bridge';
import { UFOLoggerLink } from '@atlassian/ufo-apollo-log/link';

// Initialize Apollo Client with an in-memory cache
const client = new ApolloClient({
	cache: new InMemoryCache({}),
	link: ApolloLink.from([UFOLoggerLink]),
});

export function validateViewPayload(
	event: GetViewResponse,
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	onError?: (err: unknown, event: GetViewResponse) => void,
	fragment = ViewFragment,
): boolean {
	try {
		client.writeFragment({
			fragmentName: 'EdgeView',
			fragment,
			data: event,
			id: `PolarisView:${event.id}`,
			variables: {
				isShareViewDialogEnabled: ff('polaris.sharing-enabled'),
				skipDescription: false,
			},
		});
		const result = client.cache.readFragment({
			fragmentName: 'EdgeView',
			fragment,
			id: `PolarisView:${event.id}`,
			variables: {
				isShareViewDialogEnabled: ff('polaris.sharing-enabled'),
				skipDescription: false,
			},
		});
		fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'realtimeViewPayload validated', {
			viewId: event.id,
			viewKeys: Object.keys(event).join(', '),
			hasResult: result !== null,
		});
		return result !== null;
	} catch (err) {
		onError?.(err, event);
		return false;
	} finally {
		client.cache.restore({});
	}
}

export function enrichViewPayload(view: GetViewResponse): GetViewResponse {
	return {
		...view,
		// we don't receive this field from the server
		lastCommentsViewedTimestamp: null,
	};
}

export const onValidationError = (error: unknown, event: GetViewResponse) => {
	log.safeErrorWithoutCustomerData(
		'polaris.view-realtime-handler',
		'View payload validation failed',
		{
			error: error instanceof Error ? error.toString() : undefined,
			viewId: event.id,
			viewKeys: Object.keys(event).join(', '),
		},
	);
};
