import { useCallback, useEffect, useState } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { useIntl } from '@atlassian/jira-intl/src/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useCsatComment } from '../use-csat-comment';
import messages from './messages';

type RequestPanelMetadata = {
	channel?: string;
	requestUrl?: string;
	csatComment?: string;
};

// Backend returns untranslated prop so we need to translate it here
// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter. | TS7006 - Parameter 'intl' implicitly has an 'any' type.
const translateChannel = (channelType?: string, intl) =>
	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ unknown: MessageDescriptorV2; anonymousportal: MessageDescriptorV2; deployment: MessageDescriptorV2; jira: MessageDescriptorV2; api: MessageDescriptorV2; email: MessageDescriptorV2; portal: MessageDescriptorV2; }'.
	channelType !== undefined && messages[channelType]
		? // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ unknown: MessageDescriptorV2; anonymousportal: MessageDescriptorV2; deployment: MessageDescriptorV2; jira: MessageDescriptorV2; api: MessageDescriptorV2; email: MessageDescriptorV2; portal: MessageDescriptorV2; }'.
			intl.formatMessage(messages[channelType])
		: undefined;

// @ts-expect-error - TS7006 - Parameter 'intl' implicitly has an 'any' type.
const transformResponse = (response: RequestPanelMetadata, intl) => ({
	channel: translateChannel(response.channel, intl),
	requestUrl: response.requestUrl,
	csatComment: response.csatComment,
});

const handleFailure = (error: Error) => {
	log.safeErrorWithoutCustomerData('issue.request-panel-metadata.fetch', error.message, error);
};

export const useRequestPanelMetadata = () => {
	const [data, setData] = useState<unknown>();
	const [error, setError] = useState<Error>();
	const [loading, setLoading] = useState<boolean>(true);
	const issueKey = useIssueKey();
	const baseUrl = '';
	const intl = useIntl();
	const [, { setCsatComment }] = useCsatComment();

	const fetchMetaDataUrl = useCallback(async () => {
		try {
			setLoading(true);
			const url = `${baseUrl}/rest/servicedesk/1/servicedesk/request/${issueKey}/request-panel-metadata`;
			const response = await fetchJson(url, { method: 'GET' });
			if (response.errors) {
				const err = new Error(response.message);
				// @ts-expect-error - preserve nested errors
				err.errors = response.errors;
				handleFailure(err);
				setError(err);
			} else {
				const transformedResponse = transformResponse(response, intl);
				setData(transformedResponse);

				transformedResponse.csatComment !== undefined
					? setCsatComment(transformedResponse.csatComment)
					: setCsatComment('');
			}
			setLoading(false);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			handleFailure(err);
			setError(err);
			setLoading(false);
		}
	}, [baseUrl, intl, issueKey, setCsatComment]);

	useEffect(() => {
		fetchMetaDataUrl();
	}, [fetchMetaDataUrl]);

	return [data, error, loading] as const;
};
