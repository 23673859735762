import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useSearchCustomFieldKeys } from '@atlassian/jira-issue-field-base/src/services/custom-field-key-service/index.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { REPORTER_TYPE, ORGANIZATIONS_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import JiraRelayEnvironmentProvider from '@atlassian/jira-relay-environment-provider';
import { Customer } from './customer';
import { Entitlement } from './entitlement';
import { Organizations } from './organizations';

export const CustomerServiceIssueViewPanel = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const issueKey = useIssueKey();

	const [reporterFieldValue] = useFieldValue({
		issueKey,
		fieldKey: REPORTER_TYPE,
	});

	const [organizationsFieldId] = useSearchCustomFieldKeys(issueKey, ORGANIZATIONS_CF_TYPE);
	const [organizationsFieldValue] = useFieldValue({
		issueKey,
		fieldKey: organizationsFieldId,
	});

	const orgCount = organizationsFieldValue ? organizationsFieldValue.length : 0;
	useEffect(() => {
		fireTrackAnalytics(createAnalyticsEvent({}), 'issueViewCustomerContext loaded', {
			hasReporter: !!reporterFieldValue,
			orgCount,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<JiraRelayEnvironmentProvider>
			<Container>
				<Customer fieldValue={reporterFieldValue} orgCount={orgCount} />
				<Organizations fieldValue={organizationsFieldValue} />
				<Entitlement />
			</Container>
		</JiraRelayEnvironmentProvider>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	padding: `${token('space.025', '2px')} 0 ${token('space.050', '4px')}`,
	gap: token('space.025', '2px'),
});
