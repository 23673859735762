import { useCallback, useEffect, useMemo, useRef } from 'react';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { InlineEditReturn, InlineEditOptions } from './types';

const actionSubject = 'inlineEdit';

/**
 * Utility hook to allow simplified composition of UI elements necessary for inline editing. The data returned from this
 * hook can be passed down to managed inline edit components (or used manually for custom UI elements) to enable core
 * interactions required for inline editing, e.g. entering the edit view, cancelling or confirming changes.
 *
 * Managed inline edit components will accept the `context` object as a prop and associated ref from the `refs` object
 * where provided.
 */
export const useInlineEdit = ({
	isEditing,
	onCancel: onCancelOption,
	onConfirm: onConfirmOption,
	onEdit: onEditOption,
}: InlineEditOptions): InlineEditReturn => {
	const editButtonRef = useRef<HTMLButtonElement | null>(null);
	const preventFocusOnEditButtonRef = useRef(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const prevIsEditing = usePrevious(isEditing);
	useEffect(() => {
		/**
		 * This logic puts the focus on the edit button after confirming using
		 * the confirm button or using the keyboard to confirm, but not when
		 * it is confirmed by wrapper blur
		 */
		if (prevIsEditing && !isEditing) {
			if (preventFocusOnEditButtonRef.current) {
				preventFocusOnEditButtonRef.current = false;
			} else {
				editButtonRef.current?.focus();
			}
		}
	}, [prevIsEditing, isEditing]);

	const onCancel = useCallback(
		(preventFocusReturn = true) => {
			preventFocusOnEditButtonRef.current = preventFocusReturn;
			const analyticsEvent = createAnalyticsEvent({
				action: 'canceled',
				actionSubject,
			});
			onCancelOption(analyticsEvent);
		},
		[createAnalyticsEvent, onCancelOption],
	);

	const onConfirm = useCallback(
		(preventFocusReturn = true) => {
			preventFocusOnEditButtonRef.current = preventFocusReturn;
			const analyticsEvent = createAnalyticsEvent({
				action: 'confirmed',
				actionSubject,
			});
			onConfirmOption(analyticsEvent);
		},
		[createAnalyticsEvent, onConfirmOption],
	);

	const onEdit = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'focused',
			actionSubject,
		});
		onEditOption(analyticsEvent);
	}, [createAnalyticsEvent, onEditOption]);

	const context = useMemo(
		() => ({
			onCancel,
			onConfirm,
			onEdit,
		}),
		[onCancel, onConfirm, onEdit],
	);
	const refs = useMemo(() => ({ editButton: editButtonRef }), []);

	return useMemo(
		() => ({
			context,
			refs,
		}),
		[context, refs],
	);
};
