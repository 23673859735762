import { CUSTOM_FIELD_MODULE } from '@atlassian/jira-forge-ui-constants';
import type { Source } from '@atlassian/jira-forge-ui-types/src/common/types/analytics.tsx';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import { getEventId } from '../../common/utils';
import { fireOperationalEvent } from '../../common/utils/consumers';
import { logError, CUSTOM_FIELD_OBJECT_TYPE } from '../../common/utils/logger';
import { MODULE_TYPE_EXTENSION, OPERATIONAL_ACTIONS } from '../../constants';

const moduleEventId = getEventId({
	module: CUSTOM_FIELD_MODULE,
	moduleType: MODULE_TYPE_EXTENSION,
});

// This method will generate the same metric key as error boundary
export const fireOperationalFailedEvent = (source?: Source, attributes?: Attributes) =>
	fireOperationalEvent(OPERATIONAL_ACTIONS.FAILED, {
		id: moduleEventId,
		source,
		attributes,
	});

// It is a separate event which is excluded from our SLO
// For all other errors the fireOperationalFailedEvent one should be used instead
export const fireOperationalLayoutIssueEvent = (source?: Source, attributes?: Attributes) =>
	fireOperationalEvent('layout issue', {
		id: moduleEventId,
		source,
		attributes,
	});

export const logCustomFieldObjectTypeError = (message: string) =>
	logError(CUSTOM_FIELD_OBJECT_TYPE, message);
