import { connect } from '@atlassian/jira-issue-view-react-redux';
import { sweetStateFieldUpdated } from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-save-actions';
import DateTimeField from './main';

type DispatchProps = {
	onUpdate?: <T>(arg1: T) => void;
};

const ConnectedDateTimeField = connect(
	null,
	(dispatch, ownProps): DispatchProps => ({
		onUpdate: (value: unknown) =>
			// @ts-expect-error - TS2339 - Property 'fieldId' does not exist on type '{}'.
			dispatch(sweetStateFieldUpdated(ownProps.fieldId, value)),
	}),
)(DateTimeField);

export default ConnectedDateTimeField;

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { DateTimeFieldWrapper } from './main';
