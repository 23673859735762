import { useCallback, useMemo } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useAssignToMeMutation } from './assign-to-me-mutation';
import { useAssignToMeMutationData } from './assign-to-me-mutation-data';

export const useAssignToMeOrUnassign = () => {
	const { getData } = useAssignToMeMutationData();
	const { onAssignToMeOrUnassign } = useAssignToMeMutation();

	const handleAssignToMeOrUnassign = useCallback(async () => {
		try {
			const data = await getData();
			return onAssignToMeOrUnassign(data);
		} catch (error) {
			fireErrorAnalytics({
				meta: {
					id: 'issue-view-keyboard-shortcuts.services.assign-to-me-or-unassign.assign-to-me-mutation.error',
					packageName: 'jiraIssueViewKeyboardShortcuts',
				},
				// Skipping sentry since there is no action for us to do.
				skipSentry: true,
			});
		}
	}, [getData, onAssignToMeOrUnassign]);

	return useMemo(() => ({ handleAssignToMeOrUnassign }), [handleAssignToMeOrUnassign]);
};
