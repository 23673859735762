/* eslint-disable @atlassian/relay/query-restriction */
import React, { useCallback, type SyntheticEvent } from 'react';
import flow from 'lodash/flow';
import { graphql, useFragment } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-track-event.tsx';
import { log } from '@atlassian/jira-common-util-logging';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { TOGGLE_WATCHING_ERROR } from '@atlassian/jira-issue-view-common-constants';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import type { actionButton_issueViewWatchers_ActionButtonWithRelay$key } from '@atlassian/jira-relay/src/__generated__/actionButton_issueViewWatchers_ActionButtonWithRelay.graphql';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import { useOnShowFlag } from '../../../controllers/watchers-context/index.tsx';
import { useWatchesMutation } from '../../../services/use-watches-mutation/index.tsx';
import type { State } from '../../model/types.tsx';
import { toggleWatchingRequest } from '../../state/actions';
import {
	getIsWatching,
	getWatchersCount,
	getCanManageWatchers,
	getCanViewWatchers,
	getIsKeyboardShortcutEnabled,
} from '../../state/selectors';
import { BUTTON_SOURCE } from '../../state/types';
import WatchersButtonRefresh, { type WatchersButtonProps } from '../watchers-button-refresh';
import { ActionButton } from './view';

const ConditionalWatchersButton = componentWithCondition(
	isVisualRefreshEnabled,
	WatchersButtonRefresh,
	ActionButton,
);

const ActionButtonWithRelay = ({
	watches,
	...props
}: WatchersButtonProps & { watches: actionButton_issueViewWatchers_ActionButtonWithRelay$key }) => {
	const data = useFragment<actionButton_issueViewWatchers_ActionButtonWithRelay$key>(
		graphql`
			fragment actionButton_issueViewWatchers_ActionButtonWithRelay on JiraWatchesField {
				id
				type
				fieldId
				watch {
					isWatching
					count
					...useWatchesMutation_Mutation_Updatable
				}
			}
		`,
		watches,
	);
	const { onUpdate } = useWatchesMutation();

	const { onShowFlag } = useOnShowFlag();
	const onToggleWatching = useCallback(
		(_clickEvent: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			const isWatching = !data?.watch?.isWatching;
			const source = BUTTON_SOURCE;

			fireTrackAnalytics(analyticsEvent, {
				actionSubjectId: 'watchersToggleWatching',
				action: isWatching ? 'startWatching' : 'stopWatching',
				attributes: {
					source,
				},
			});

			const handleError = (error?: Error) => {
				onShowFlag?.(TOGGLE_WATCHING_ERROR);
				const err = error instanceof Error ? error : new Error('Error updating watchers');

				log.safeErrorWithoutCustomerData(
					isWatching ? 'issue.watchers.watch' : 'issue.watchers.unwatch',
					err.message,
					err,
				);
			};

			onUpdate({
				id: data?.id,
				isWatching,
				onError: handleError,
				watch: data.watch,
				field: {
					type: data.type,
					fieldId: data.fieldId,
					watch: {
						count: data.watch?.count,
						isWatching: data.watch?.isWatching,
					},
				},
			});
		},
		[data, onShowFlag, onUpdate],
	);

	return (
		<ConditionalWatchersButton
			{...props}
			isWatching={data?.watch?.isWatching || false}
			watchersCount={data?.watch?.count || 0}
			onToggleWatching={onToggleWatching}
		/>
	);
};

export const ActionButtonNew = flow(
	withFireUiAnalytics({
		onOpenDropdownAnalytics: (watchersCount: number) => ({
			name: 'watchersOpenDropdown',
			watchersCount,
		}),
	}),
	ComponentWithAnalytics('watchers', {
		onToggleWatching: 'onToggleWatching',
	}),
	connect((state: State) => ({
		canViewDropdown: getCanViewWatchers(state) || getCanManageWatchers(state),
		isKeyboardShortcutEnabled: getIsKeyboardShortcutEnabled(state),
	})),
)(ActionButtonWithRelay);

export const ActionButtonOld = flow(
	withFireUiAnalytics({
		onOpenDropdownAnalytics: (watchersCount: number) => ({
			name: 'watchersOpenDropdown',
			watchersCount,
		}),
	}),
	ComponentWithAnalytics('watchers', {
		onToggleWatching: 'onToggleWatching',
	}),
	connect(
		(state: State) => ({
			isWatching: getIsWatching(state),
			watchersCount: getWatchersCount(state),
			canViewDropdown: getCanViewWatchers(state) || getCanManageWatchers(state),
			isKeyboardShortcutEnabled: getIsKeyboardShortcutEnabled(state),
		}),
		{
			onToggleWatching: (_clickEvent: unknown, analyticsEvent: UIAnalyticsEvent) =>
				toggleWatchingRequest({
					analyticsEvent,
					source: BUTTON_SOURCE,
				}),
		},
	),
)(ConditionalWatchersButton);

export default componentWithCondition(
	() => ff('relay-migration-issue-fields-watchers_iqhn4'),
	ActionButtonNew,
	ActionButtonOld,
);
