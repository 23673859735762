import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import { useDateTimeFormatter } from '@atlassian/jira-issue-format-date/src/main.tsx';
import type { dateTime_issueFieldDateTimeReadviewFull_DateTimeReadView$key as DateTimeReadViewFragment } from '@atlassian/jira-relay/src/__generated__/dateTime_issueFieldDateTimeReadviewFull_DateTimeReadView.graphql';
import type { Props } from './types';

/**
 * DateTimeReadView component lets you view the date and time of a timestammp in the timeZone the current user is in.
 * In Case timeZone is passed as a prop in it adhears to prop timeZone
 *
 * We later need to fireUIAnalytics when an error occurs while formatting the date time
 * also discussions for removing timeZone is available here https://atlassian.slack.com/archives/CFG7SUPJM/p1665391419261039
 *
 * @param props {@link DateTimeReadView}
 * */

export const DateTimeReadView = (props: Props) => {
	const { fragmentRef, timeZone: overrideTimeZone } = props;
	const date = useFragment<DateTimeReadViewFragment>(
		graphql`
			fragment dateTime_issueFieldDateTimeReadviewFull_DateTimeReadView on JiraDateTimePickerField {
				dateTime
			}
		`,
		fragmentRef,
	);
	const { dateTime } = date || {};
	const { formatDateTime } = useDateTimeFormatter(overrideTimeZone);
	if (dateTime)
		try {
			return <>{formatDateTime(dateTime)}</>;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			return <></>;
		}
	return <EmptyFieldText />;
};
