import React from 'react';
import { styled } from '@compiled/react';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { Box, xcss } from '@atlaskit/primitives';
import { R500, Y300, N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import type { Props } from './types';

const assertUnreachable = (_x: never): never => {
	throw new Error("Didn't expect to get here");
};

const getIcon = (type: 'error' | 'warning') => {
	switch (type) {
		case 'error': {
			return <ErrorIcon label="" primaryColor={token('color.icon.danger', R500)} />;
		}
		case 'warning': {
			return <WarningIcon label="" primaryColor={token('color.icon.warning', Y300)} />;
		}
		default: {
			assertUnreachable(type);
		}
	}
};

const PopupContent = ({ className, id, message, onFocus, onBlur, testId, title, type }: Props) => (
	<Content
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		className={className}
		role="alert"
		id={id}
		hasTitle={!!title}
		onFocus={onFocus}
		onBlur={onBlur}
		data-testid={testId}
	>
		{getIcon(type)}
		{title ? (
			<Box>
				<Box paddingBlockEnd="space.075" xcss={titleStyles}>
					{title}
				</Box>
				{message}
			</Box>
		) : (
			message
		)}
	</Content>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled.div<{ hasTitle: boolean }>(
	{
		display: 'flex',
		padding: token('space.200', '16px'),
		boxSizing: 'border-box',
		maxWidth: '368px',
		backgroundColor: token('elevation.surface.overlay', N0),
		borderRadius: token('border.radius', '3px'),
		boxShadow: token('elevation.shadow.overlay', '0px 8px 12px #091e423f, 0px 0px 1px #091e424f'),
		width: 'fit-content',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.hasTitle
			? {
					gap: token('space.200', '16px'),
				}
			: {
					alignItems: 'center',
					gap: token('space.075', '6px'),
				},
);

const titleStyles = xcss({
	color: 'color.text.subtle',
	fontWeight: token('font.weight.semibold'),
	lineHeight: '24px',
});

export default PopupContent;
