import {
	categoryIdForStatusCategory,
	DONE,
} from '@atlassian/jira-common-constants/src/status-categories';
import type { IssueFieldConfigurationType } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import type { LayoutContainerTemplateItem } from '@atlassian/jira-issue-layout-common-constants';
import { ISSUE_FIELD_SPOTLIGHT_PREFIX } from '@atlassian/jira-issue-view-common-constants/src/layout.tsx';
import type { LayoutItems } from '@atlassian/jira-issue-view-layout-templates-services/src/services/context/visible-hidden/visible-hidden-items/index.tsx';
import { fieldTypeHandler } from '@atlassian/jira-issue-view-layout-templates-utils';
import { getLayoutItemId } from '@atlassian/jira-issue-view-layout/src/services/utils.tsx';
import {
	DESCRIPTION_TYPE,
	REQUEST_FEEDBACK_CF_TYPE,
	SELECT_CF_TYPE,
	SERVICE_ENTITY_CF_TYPE,
	SENTIMENT_CF_TYPE,
} from '@atlassian/jira-platform-field-config';

// We can't directly identify `Affected services`, `Change risk` or `Impact`, but we can
// minimise the number of extra targets by wrapping only 'select' type fields since the above
// all fit that criteria. `Description` fortunately has a custom type to identify it
const fieldsWithGlobalTargets = [SELECT_CF_TYPE, DESCRIPTION_TYPE, SERVICE_ENTITY_CF_TYPE];

export const transformCSAT = (
	isNextGen: boolean,
	items: LayoutItems | undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	status: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	issueFieldsConfig: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	issueFieldsValues: any,
) => {
	if (!items) {
		return undefined;
	}

	if (!items.visibleItems || !issueFieldsConfig || !issueFieldsValues || !status) {
		return items;
	}

	if (!isNextGen) {
		return items;
	}

	const CsatField = items.visibleItems.find((item) => {
		const fieldConfig = issueFieldsConfig[getLayoutItemId(item)];
		return fieldConfig?.schema?.custom === REQUEST_FEEDBACK_CF_TYPE;
	});

	if (CsatField === undefined) {
		return items;
	}

	const itemsWithoutCsatField = items.visibleItems.filter((item) => {
		const fieldConfig = issueFieldsConfig[getLayoutItemId(item)];
		return fieldConfig?.schema?.custom !== REQUEST_FEEDBACK_CF_TYPE;
	});
	const hasValue =
		issueFieldsValues[getLayoutItemId(CsatField)] !== undefined &&
		issueFieldsValues[getLayoutItemId(CsatField)] !== null;

	if (status.statusCategory.id !== categoryIdForStatusCategory(DONE) || !hasValue) {
		return {
			...items,
			visibleItems: itemsWithoutCsatField,
		};
	}

	return {
		...items,
		visibleItems: [CsatField, ...itemsWithoutCsatField],
	};
};

/**
 * This takes the passed layout items and compares them to fieldsWithGlobalTargets, seeing if it should add a global reference or not.
 *
 * @param items An array of relevant LayoutItems to check to see if it should add a 'globalRef'
 * @param issueFieldsConfig The field configuration of the issue
 * @returns An optionally decorated array of the passed items with
 */
export const addRefsToArrayOfItems = <T extends LayoutContainerTemplateItem>(
	items: T[] | undefined,
	issueFieldsConfig?: IssueFieldConfigurationType['value'],
): (T & { globalRef?: string })[] => {
	if (!items || !issueFieldsConfig) {
		return [];
	}

	return items.map((item) => {
		const { type, id } = fieldTypeHandler(issueFieldsConfig[getLayoutItemId(item)], item);
		// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type '"description" | "com.atlassian.jira.plugin.system.customfieldtypes:select" | "com.atlassian.jira.plugins.service-entity:service-entity-field-cftype"'.
		if (fieldsWithGlobalTargets.includes(type)) {
			return {
				...item,
				globalRef: `${ISSUE_FIELD_SPOTLIGHT_PREFIX}${id}`,
			};
		}
		return item;
	});
};

/**
 * Layout items need a global reference for spotlight. This adds it.
 *
 * @param items All the relevant layoutitems to add a reference to
 * @param issueFieldsConfig The field configuration for the issue
 * @returns The passed layout item object, optionally enriched with global references for spotlight
 */
export const addGlobalRefs = (
	items?: LayoutItems,
	issueFieldsConfig?: IssueFieldConfigurationType['value'],
) => ({
	hiddenItems: addRefsToArrayOfItems(items?.hiddenItems, issueFieldsConfig),
	pinnedFields: addRefsToArrayOfItems(items?.pinnedFields, issueFieldsConfig),
	visibleItems: addRefsToArrayOfItems(items?.visibleItems, issueFieldsConfig),
	apps: {
		contextPanels: addRefsToArrayOfItems(items?.apps?.contextPanels ?? [], issueFieldsConfig),
	},
});

export const isSentimentCustomFieldShown = (
	items: LayoutItems | undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	issueFieldsConfig: any,
): boolean => {
	const isVisible =
		items?.visibleItems.some((item) => {
			const fieldConfig = issueFieldsConfig && issueFieldsConfig[getLayoutItemId(item)];
			return fieldConfig?.schema?.custom === SENTIMENT_CF_TYPE;
		}) || false;
	const isHidden =
		items?.hiddenItems.some((item) => {
			const fieldConfig = issueFieldsConfig && issueFieldsConfig[getLayoutItemId(item)];
			return fieldConfig?.schema?.custom === SENTIMENT_CF_TYPE;
		}) || false;
	const isPinned =
		items?.pinnedFields.some((item) => {
			const fieldConfig = issueFieldsConfig && issueFieldsConfig[getLayoutItemId(item)];
			return fieldConfig?.schema?.custom === SENTIMENT_CF_TYPE;
		}) || false;

	return isVisible || isHidden || isPinned;
};
