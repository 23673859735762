import React, { memo } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { FieldDescription } from '@atlassian/jira-issue-field-description/src/ui/index.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading';
import {
	FieldHeadingTitle,
	SideBySideField,
	FieldWrapper,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { useParentField } from '@atlassian/jira-issue-field-parent/src/services/parent-field/index.tsx';
import { Changeboarding as ParentChangeboarding } from '@atlassian/jira-issue-field-parent/src/ui/changeboarding/index.tsx';
import { ParentField } from '@atlassian/jira-issue-field-parent/src/ui/main.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import { isChangeboardingForParentEnabled } from '../../feature-flags';
import type { Props } from './types';

const Parent = ({ fieldId: fieldKey, label, area, onConfirm }: Props) => {
	const { formatMessage } = useIntl();
	const issueKey = useIssueKey();
	const showPinButton = getShowPinButton(area);
	const [{ fieldConfig }] = useParentField({ issueKey, fieldKey });
	const fieldLabel = fieldConfig?.title || label;

	return (
		<FieldWrapper>
			<FieldHeading>
				<FieldHeadingTitle>{fieldLabel}</FieldHeadingTitle>
				{issueKey !== undefined && fieldKey !== undefined && (
					// @ts-expect-error label is required, please pass it through when working on this code.
					<FieldDescription issueKey={issueKey} fieldKey={fieldKey} />
				)}
				{isChangeboardingForParentEnabled() && <ParentChangeboarding />}
				{showPinButton === true && <FieldPin fieldId={fieldKey} label={fieldLabel} />}
			</FieldHeading>
			<SideBySideField>
				<ParentField
					isFitContainerWidthReadView
					issueKey={issueKey}
					fieldKey={fieldKey}
					noValueText={formatMessage(genericMessages.noValue)}
					onConfirm={onConfirm}
					isExistingIssue
				/>
			</SideBySideField>
		</FieldWrapper>
	);
};

export default memo<Props>(Parent);
