import React, { type ReactNode, memo, useRef } from 'react';
import { styled } from '@compiled/react';
import type { ResizerProps, ChildrenType } from '../common/types';
import { clampValue, getChildrenStyle } from '../common/utils';
import Handle from './handle';
import { RESIZE_HANDLE_COMPONENT_SELECTOR } from './handle/styled';

type Props = ResizerProps & {
	children: ChildrenType | ReactNode;
};

export default memo<Props>((props: Props) => {
	const { children, ...resizerProps } = props;

	const {
		minRatio = 0,
		maxRatio = 1,
		minWidth,
		maxWidth,
		initialRatio,
		defaultRatio,
		extraWidth,
		position = 'left',
		resizeHandleAsOverlay,
	} = resizerProps;

	// Ensure the ratio which is initially set to the flex-basis style is bounded between the min/max ratios
	// The css properties handles max-width and min-width
	const ratio = useRef(
		clampValue({ value: initialRatio ?? defaultRatio, minValue: minRatio, maxValue: maxRatio }),
	);
	const container = useRef<HTMLElement | null>(null);

	const renderHandle = () => (
		<Handle
			{...resizerProps}
			{...(initialRatio !== undefined
				? {
						initialRatio: ratio.current,
						defaultRatio: clampValue({
							value: defaultRatio,
							minValue: minRatio,
							maxValue: maxRatio,
						}),
					}
				: { defaultRatio: ratio.current })}
			childrenContainerRef={container}
		/>
	);

	const renderResizer = () => (
		<>
			{position === 'left' && renderHandle()}
			{typeof children === 'function'
				? children({
						ref: (r: HTMLElement | null) => {
							container.current = r;
						},
						style: getChildrenStyle(ratio.current, minWidth, maxWidth, extraWidth),
					})
				: children}
			{position === 'right' && renderHandle()}
		</>
	);

	return resizeHandleAsOverlay ? (
		<ResizeWrapper
			// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			style={getChildrenStyle(ratio.current, minWidth, maxWidth, extraWidth)}
			ref={container}
			data-testid="flex-resizer.ui.resize-wrapper"
		>
			{renderResizer()}
		</ResizeWrapper>
	) : (
		renderResizer()
	);
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ResizeWrapper = styled.div<any>({
	position: 'relative',
	display: 'flex',
	pointerEvents: 'auto',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`& > :not(${RESIZE_HANDLE_COMPONENT_SELECTOR})`]: {
		flex: 1,
	},
});
