import MultiSelectInlineEditView from '@atlassian/jira-issue-internal-field-select/src/multi-select-inline-edit';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field';
import { fetchSuggestionsFactory } from '../rest/fetch';
import messages from './messages';
import { transformFromStateValue, transformToStateValue } from './transformer';

export default connectField((stateOnMount, ownPropsOnMount) => ({
	fieldId: ownPropsOnMount.fieldId,
	transformFromStateValue,
	transformToStateValue,
	additionalProps: (state, intl) => ({
		fetchSuggestions: fetchSuggestionsFactory(),
		placeholder: intl.formatMessage(messages.placeholder),
		noValueText: intl.formatMessage(genericMessages.noValue),
		showPinButton: getShowPinButton(ownPropsOnMount.area),
	}),
}))(MultiSelectInlineEditView);
