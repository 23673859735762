import React from 'react';
import TextWithIcon from '@atlassian/jira-common-components-text-with-icon/src/view.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { Emphasise } from '@atlassian/jira-issue-view-activity-common/src/styles';
import Change from '../../../../common/ui/change';
import GroupedChange from '../../../../common/ui/grouped-change';
import HistoryItem from '../../../../common/ui/history-item';
import type { PriorityChangedHistoryItem } from '../../../../types';
import messages from './messages';

type Props = {
	isGrouped?: Boolean;
	historyItem: PriorityChangedHistoryItem;
};

const PriorityHistoryItem = ({ historyItem, isGrouped = false }: Props) => {
	const { formatMessage } = useIntl();
	const { actor, timestamp, fromPriority, toPriority } = historyItem;
	return (
		<FormattedMessage
			{...messages.changed}
			values={{
				priority: (
					<Emphasise key="priority">
						{formatMessage(
							expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false)
								? messages.priorityLowercase
								: messages.priority,
						)}
					</Emphasise>
				),
			}}
		>
			{(...action) =>
				isGrouped && expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false) ? (
					<GroupedChange
						field={formatMessage(messages.priority)}
						from={<TextWithIcon iconUrl={fromPriority.iconUrl}>{fromPriority.name}</TextWithIcon>}
						to={<TextWithIcon iconUrl={toPriority.iconUrl}>{toPriority.name}</TextWithIcon>}
					/>
				) : (
					<HistoryItem
						// eslint-disable-next-line jira/integration/test-id-by-folder-structure
						testId="issue-history.ui.history-items.priority-history-item.history-item"
						actor={actor}
						action={action}
						timestamp={timestamp}
						change={
							<Change
								from={
									<TextWithIcon iconUrl={fromPriority.iconUrl}>{fromPriority.name}</TextWithIcon>
								}
								to={<TextWithIcon iconUrl={toPriority.iconUrl}>{toPriority.name}</TextWithIcon>}
							/>
						}
					/>
				)
			}
		</FormattedMessage>
	);
};

export default PriorityHistoryItem;
