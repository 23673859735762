import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { getSiteAri } from '../../../utils';
import type { InviteApiSuccessResponse } from '../types';
import { INVITE_USERS_ENDPOINT, INVITE_USERS_WITHIN_ORG_ENDPOINT } from './constants';
import type { InviteParams } from './types';

export const inviteUsers = async ({
	users,
	continueUrl,
	recaptchaToken,
	tenantId,
	product,
}: InviteParams): Promise<Array<InviteApiSuccessResponse>> => {
	const resources = [getSiteAri(tenantId, product)];
	const response = await performPostRequest<Array<InviteApiSuccessResponse>>(
		INVITE_USERS_ENDPOINT,
		{
			headers: {
				'Content-Type': 'application/json',
				...(recaptchaToken ? { 'G-Recaptcha-Token': recaptchaToken } : {}),
			},
			body: JSON.stringify({
				continueUrl,
				resources,
				users: users.map(({ email }) => ({
					email,
				})),
			}),
		},
	);
	return response;
};

export const inviteWithinOrg = async ({
	users,
	continueUrl,
	tenantId,
	product,
}: InviteParams): Promise<Array<InviteApiSuccessResponse>> => {
	const resources = [getSiteAri(tenantId, product)];
	const response = await performPostRequest<Array<InviteApiSuccessResponse>>(
		INVITE_USERS_WITHIN_ORG_ENDPOINT,
		{
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				continueUrl,
				resources,
				userIds: users.map(({ id }) => id),
				cloudId: tenantId,
				experience: 'invite-on-link-insert',
			}),
		},
	);
	return response;
};
