import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import {
	hideCreateWebLinkForm,
	createWebLinkRequest,
} from '@atlassian/jira-issue-view-store/src/actions/web-links-actions';
import {
	webLinksCountSelector,
	createWebLinkClickedCountSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/web-links-selector';
import { toHref, type Href } from '@atlassian/jira-shared-types/src/general.tsx';
import WebLinkCreateLinkView from './view';

const analyticsProps = {
	onCancel: 'webLinkCancel',
};

export default flowWithSafeComponent(
	ComponentWithAnalytics('button', {
		onSubmit: 'clicked',
		onCancel: 'clicked',
	}),
	withFireUiAnalytics(analyticsProps),
	// @ts-expect-error - Argument of type 'InferableComponentEnhancerWithProps<{ linkedPagesCount: number; createLinkClickedCount: number; } & { onCancel: () => { type: "HIDE_CREATE_WEB_LINK_FORM"; }; onSubmit: (link: string, linkText: string, iconUrl: string) => { ...; }; }, {}>' is not assignable to parameter of type 'FlowStep<WithUIAnalyticsEvent<PropsWithoutRef<Omit<{ [K in keyof Props]: K extends "onSubmit" | "onCancel" ? (...args: [...Parameters<Props[K]>, UIAnalyticsEvent]) => ReturnType<Props[K]> : Props[K]; }, keyof WithAnalyticsEventsProps>>, { ...; }>, ConnectedComponent<...>>'.
	connect(
		(state) => ({
			linkedPagesCount: webLinksCountSelector(state),
			createLinkClickedCount: createWebLinkClickedCountSelector(state),
		}),
		(dispatch) => ({
			onCancel: () => dispatch(hideCreateWebLinkForm()),
			onSubmit: (link: string, linkText: string, iconUrl: Href) =>
				dispatch(createWebLinkRequest(toHref(link), linkText, iconUrl, undefined)),
		}),
	),
)(WebLinkCreateLinkView);
