import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { INVITE_USERS_ENDPOINT } from './constants';
import type { InviteUsersApiResponse, InviteUsersApiRequest } from './types';

export const postInviteUsers = async ({
	resources,
	users,
	continueUrl,
	recaptchaToken,
}: InviteUsersApiRequest): Promise<InviteUsersApiResponse> =>
	performPostRequest(INVITE_USERS_ENDPOINT, {
		headers: {
			'Content-Type': 'application/json',
			...(recaptchaToken ? { 'G-Recaptcha-Token': recaptchaToken } : {}),
		},
		body: JSON.stringify({
			users,
			resources,
			continueUrl,
		}),
	});
