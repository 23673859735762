import React, { useState, useEffect, useCallback } from 'react';
import { css, styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
// eslint-disable-next-line import/order
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler';

import { fontSize, gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { FieldDescription } from '@atlassian/jira-issue-field-description/src/ui/index.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading';
import {
	FieldHeadingTitle,
	SideBySideField,
	FieldWrapper,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin';
import { CASCADING_SELECT_CF_TYPE } from '@atlassian/jira-platform-field-config';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Option, ParentChildOptionMap, Props, SelectedOption } from '../common/types';
import {
	transformToOptionValue,
	transformToOptions,
	transformToParentChildOptionMap,
	transformToServerValue,
} from '../common/utils';
import { CascadingSelectView } from './cascading-select-view';
import messages from './messages';

const getReadViewValue = (option: SelectedOption | null, intl: ReturnType<typeof useIntl>) => {
	if (option && 'child' in option && option.child) {
		return `${option.label} - ${option.child.label}`;
	}
	if (option) {
		return option.label;
	}
	return intl.formatMessage(messages.noOptionsSelected);
};

const CascadingSelectInlineEditView = (props: Props) => {
	const {
		label,
		isEditable,
		isMobile,
		allowedValues,
		areOptionsOnSameRow,
		showPinButton,
		onCloseMenuOnScroll,
		isDropdownMenuFixedAndLayered,
		...fieldOptions
	} = props;

	const intl = useIntl();
	const [{ value, error }, { saveValue }] = useEditField(fieldOptions);
	const [selectedOption, setSelectedOption] = useState<SelectedOption | null>(
		transformToOptionValue(value),
	);
	const [savedSelectedOption, setSavedSelectedOption] = useState<SelectedOption | null>(
		transformToOptionValue(value),
	);

	const [isEditing, setIsEditing] = useState(false);
	const [readView, setReadView] = useState(getReadViewValue(selectedOption, intl));

	const [transformedParentValues, setTransformedParentValues] = useState<Option[]>([]);
	const [transformedChildValues, setTransformedChildValues] = useState<ParentChildOptionMap>({});

	const updateReadViewWith = useCallback(
		(option: SelectedOption | null) => {
			setReadView(getReadViewValue(option, intl));
		},
		[intl],
	);

	const onEditRequested = useCallback(() => {
		setTransformedParentValues(transformToOptions(allowedValues));
		setTransformedChildValues(transformToParentChildOptionMap(allowedValues));
		setIsEditing(true);
	}, [allowedValues]);

	const onConfirm = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			saveValue(transformToServerValue(selectedOption), null, analyticsEvent);
			setIsEditing(false);
			updateReadViewWith(selectedOption);
			fireUIAnalytics(analyticsEvent, 'cascadingSelect', {
				hasChild: !!(
					selectedOption &&
					// @ts-expect-error - TS2339 - Property 'child' does not exist on type 'Option | ParentChildOption'.
					selectedOption.child &&
					// @ts-expect-error - TS2339 - Property 'child' does not exist on type 'Option | ParentChildOption'.
					selectedOption.child != null
				),
				isEmpty: !selectedOption,
			});
			setSavedSelectedOption(selectedOption);
		},
		[saveValue, selectedOption, updateReadViewWith],
	);

	const onCancel = useCallback(() => {
		setIsEditing(false);
		setSelectedOption(savedSelectedOption);
	}, [savedSelectedOption]);

	// We're using the ViewProps type and it requires an onChange prop that returns a promise
	const updateSelectedOptions = useCallback(async (option: SelectedOption): Promise<void> => {
		setSelectedOption(option);
	}, []);

	const renderReadView = useCallback(() => {
		let child;
		if (!selectedOption) {
			child = <Text color="color.text.subtlest">{readView}</Text>;
		} else {
			child = readView;
		}
		return (
			<ReadViewContainer data-component-selector={readViewContainerSelectorName}>
				{child}
			</ReadViewContainer>
		);
	}, [readView, selectedOption]);

	const renderEditView = useCallback(
		() => (
			<EditViewContainer>
				<CascadingSelectView
					value={selectedOption}
					onCloseMenuOnScroll={onCloseMenuOnScroll}
					isDropdownMenuFixedAndLayered={isDropdownMenuFixedAndLayered}
					allowedValues={transformedParentValues}
					allowedChildValues={transformedChildValues}
					onChange={updateSelectedOptions}
					isInvalid={!!error}
					areOptionsOnSameRow={areOptionsOnSameRow}
					isEditable={isEditable}
				/>
			</EditViewContainer>
		),
		[
			areOptionsOnSameRow,
			error,
			isDropdownMenuFixedAndLayered,
			isEditable,
			onCloseMenuOnScroll,
			selectedOption,
			transformedChildValues,
			transformedParentValues,
			updateSelectedOptions,
		],
	);

	useEffect(() => {
		updateReadViewWith(selectedOption);
	}, [selectedOption, updateReadViewWith]);

	useEffect(() => {
		if (error) {
			setIsEditing(true);
		}
	}, [error]);

	useEffect(() => {
		setSelectedOption(transformToOptionValue(value));
	}, [value]);

	return (
		<FieldWrapper>
			<FieldHeading>
				<FieldHeadingTitle>{label}</FieldHeadingTitle>
				{props.issueKey !== undefined && fieldOptions.fieldKey !== undefined && (
					<FieldDescription
						issueKey={props.issueKey}
						fieldKey={fieldOptions.fieldKey}
						label={label}
					/>
				)}
				{showPinButton === true && <FieldPin fieldId={fieldOptions.fieldKey} label={label} />}
			</FieldHeading>
			<SideBySideField
				isEditing={isEditing}
				data-testid="issue-field-cascading-select.ui.issue-field-cascading-select"
			>
				<EnterEscapeHandler onEscape={onCancel}>
					<InlineEditContainer isEditable={isEditable}>
						<FieldInlineEditStateLess
							defaultValue=""
							readView={renderReadView}
							editView={renderEditView}
							isEditable={isEditable}
							onCancel={onCancel}
							onConfirm={onConfirm}
							onEdit={onEditRequested}
							isEditing={isEditing}
							readViewFitContainerWidth={!isMobile}
						/>
					</InlineEditContainer>
				</EnterEscapeHandler>
			</SideBySideField>
		</FieldWrapper>
	);
};

CascadingSelectInlineEditView.defaultProps = {
	initialValue: null,
	isMobile: false,
	areOptionsOnSameRow: false,
	fieldType: CASCADING_SELECT_CF_TYPE,
};

export default CascadingSelectInlineEditView;

export const readViewContainerSelectorName =
	'jira-issue-field-cascading-select-read-view-container';
const READ_VIEW_CONTAINER_COMPONENT_SELECTOR = `[data-component-selector="${readViewContainerSelectorName}"]`;

// The save/close buttons have an z-index of 200, so the dropdown needs to exist above that
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditViewContainer = styled.div({
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled.div({
	display: 'flex',
	flex: '1 1 auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	lineHeight: (gridSize * 2.5) / fontSize,
	maxWidth: '100%',
	wordBreak: 'break-word',
	position: 'relative',
});

const nonEditableStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[READ_VIEW_CONTAINER_COMPONENT_SELECTOR]: {
		marginLeft: token('space.075', '6px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineEditContainer = styled.div<{ isEditable: boolean }>(
	{
		marginLeft: token('space.negative.100', '-8px'),
		marginTop: token('space.negative.100', '-8px'),
		width: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& div[data-read-view-fit-container-width]': {
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			minHeight: '32px',
			paddingTop: 0,
			paddingBottom: 0,
			paddingLeft: token('space.075', '6px'),
			paddingRight: 0,
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditable }) => (!isEditable ? nonEditableStyles : undefined),
);
