import type { ForgeCustomFieldValue } from '@atlassian/jira-forge-ui-types/src/common/types/contexts/custom-field.tsx';
import {
	type Action,
	FORGE_CUSTOM_FIELD_EDIT_CANCEL,
	FORGE_CUSTOM_FIELD_EDIT_INITIALIZATION,
	FORGE_CUSTOM_FIELD_EDIT_RENDERED,
	FORGE_CUSTOM_FIELD_EDIT_SET_VALUE,
	FORGE_CUSTOM_FIELD_EDIT_FAILED,
} from './actions';

export type CustomFieldEditState = {
	isEditing: boolean;
	isLoading: boolean;
	editValue: ForgeCustomFieldValue;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: CustomFieldEditState, action: Action) => {
	switch (action.type) {
		case FORGE_CUSTOM_FIELD_EDIT_INITIALIZATION:
			return {
				...state,
				isEditing: true,
				isLoading: true,
			};
		case FORGE_CUSTOM_FIELD_EDIT_RENDERED:
			return {
				...state,
				isLoading: false,
			};
		case FORGE_CUSTOM_FIELD_EDIT_CANCEL:
			return {
				...state,
				isEditing: false,
			};
		case FORGE_CUSTOM_FIELD_EDIT_SET_VALUE:
			return {
				...state,
				isEditing: false,
				editValue: action.payload,
			};
		case FORGE_CUSTOM_FIELD_EDIT_FAILED:
			return {
				...state,
				isEditing: false,
				isLoading: false,
			};
		default:
			return state;
	}
};
