import { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type { Option } from '@atlassian/jira-issue-field-labels-editview-full/src/ui/labels/types.tsx';
import type {
	ClientEventValidator,
	ClientValidator,
} from '@atlassian/jira-issue-field-validation-handler/src/controllers/client-validation-handler/types.tsx';
import { MAXIMUM_LABEL_LENGTH, MAXIMUM_NUMBER_OF_LABELS } from './constants';
import messages from './messages';

export const useLabelsValidator = (): ClientEventValidator<Option[]> => {
	const { formatMessage } = useIntl();
	const change: ClientValidator<Option[]> = useCallback(
		(newValues: Option[]) => {
			for (let i = newValues.length - 1; i >= 0; i -= 1) {
				const { __isNew__, value } = newValues[i];
				if (__isNew__ === true) {
					if (value.includes(' ')) {
						return {
							type: 'error',
							message: formatMessage(messages.newLabelContainsSpacesError),
						};
					}
					if (value.length > MAXIMUM_LABEL_LENGTH) {
						return {
							type: 'error',
							message: formatMessage(messages.newLabelTooLongError, {
								maxLength: MAXIMUM_LABEL_LENGTH,
							}),
						};
					}
				}
			}

			if (newValues.length > MAXIMUM_NUMBER_OF_LABELS) {
				return {
					type: 'warning',
					message: formatMessage(messages.maximumNumberOfLabelsExceededError, {
						maxNumberOfLabels: MAXIMUM_NUMBER_OF_LABELS,
					}),
				};
			}

			return null;
		},
		[formatMessage],
	);

	return { change };
};
