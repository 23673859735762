import React from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { bambooDeploymentData_softwareReleasesVersionDetailIssueList$key } from '@atlassian/jira-relay/src/__generated__/bambooDeploymentData_softwareReleasesVersionDetailIssueList.graphql';
import messages from './messages';

const MAX_DISPLAYED_ENVIRONMENTS = 3;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});

type Props = {
	jiraIssueDevSummary: bambooDeploymentData_softwareReleasesVersionDetailIssueList$key;
	onDeploymentClicked: () => void;
};

export const BambooDeploymentData = ({ jiraIssueDevSummary, onDeploymentClicked }: Props) => {
	const { formatMessage } = useIntl();
	const data = useFragment(
		graphql`
			fragment bambooDeploymentData_softwareReleasesVersionDetailIssueList on JiraIssueDevSummary {
				deploymentEnvironments @required(action: THROW) {
					overall @required(action: THROW) {
						topEnvironments @required(action: THROW) {
							status @required(action: THROW)
							title
						}
					}
				}
			}
		`,
		jiraIssueDevSummary,
	);

	const deployedBuilds = (data.deploymentEnvironments.overall.topEnvironments ?? []).filter(
		(deployment) => deployment.status === 'DEPLOYED',
	);
	const environments = deployedBuilds
		.filter((environment) => environment)
		.map((environment) => environment.title);
	const displayedEnvironments = environments.slice(0, MAX_DISPLAYED_ENVIRONMENTS).join(', ');
	const remainingEnvironments =
		environments.length > MAX_DISPLAYED_ENVIRONMENTS
			? deployedBuilds.length - MAX_DISPLAYED_ENVIRONMENTS
			: null;

	return deployedBuilds.length > 0 ? (
		<IconWrapper>
			<Button
				spacing="compact"
				appearance="subtle-link"
				onClick={(_, analyticsEvent: UIAnalyticsEvent) => {
					onDeploymentClicked();
					fireUIAnalytics(analyticsEvent, 'bambooSummaryCell');
				}}
			>
				<Tooltip
					content={formatMessage(messages.deployedEnvironments, {
						hasHiddenEnvironments: !!remainingEnvironments,
						displayedEnvironments,
						othersCount: remainingEnvironments ?? 0,
					})}
					onShow={(analyticsEvent: UIAnalyticsEvent) => {
						fireUIAnalytics(analyticsEvent, 'bambooSummaryTooltip');
					}}
					position="bottom"
				>
					<CheckCircleIcon
						size="medium"
						label={formatMessage(messages.bambooDeploymentIcon)}
						primaryColor={token('color.icon.success', colors.G300)}
					/>
				</Tooltip>
			</Button>
		</IconWrapper>
	) : null;
};
