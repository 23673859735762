import React from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { ActionButtonOld } from '../action-button';
import { AddWatchersOld } from './add-watchers';
import ChangeWatchState from './change-watch-state';
import { Dropdown, DropdownOld as DropdownOldView } from './view';
import { WatchersListOld } from './watchers-list';

const DropdownOld = () => (
	<DropdownOldView
		Button={ActionButtonOld}
		ChangeWatchState={ChangeWatchState}
		WatchersList={WatchersListOld}
		AddWatchers={AddWatchersOld}
	/>
);

export default componentWithCondition(
	() => ff('relay-migration-issue-fields-watchers_iqhn4'),
	Dropdown,
	DropdownOld,
);
