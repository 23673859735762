import React, { useEffect, useState, useRef } from 'react';
import { styled } from '@compiled/react';
import { useQueryLoader } from 'react-relay';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import PanelViewCustomerQuery, {
	type panelViewCustomerQuery,
} from '@atlassian/jira-relay/src/__generated__/panelViewCustomerQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { ReporterValue } from '../../common/types';
import { Header } from '../../common/ui/details';
import { ExpandPanel } from '../../common/ui/expand-panel';
import { useIsAgentOrProjectAdmin } from '../../common/utils';
import { CustomerField } from './customer-field';
import messages from './messages';
import { PanelView } from './panel-view';

export const Customer = ({
	fieldValue,
	orgCount,
}: {
	fieldValue: ReporterValue | null;
	orgCount?: number;
}) => {
	const cloudId = useCloudId();
	const issueId = useIssueId() || '';
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const accountIdRef = useRef<string | undefined>(fieldValue?.accountId);
	const [queryReference, loadQuery, disposeQuery] =
		useQueryLoader<panelViewCustomerQuery>(PanelViewCustomerQuery);
	const hasQueryReference = !!queryReference;
	const isAgentOrProjectAdmin = useIsAgentOrProjectAdmin();

	// If user changes reporter, we want the details field to close (to match orgs behaviour)
	useEffect(() => {
		if (isOpen && accountIdRef.current !== fieldValue?.accountId) {
			setIsOpen(false);
		}

		if (!isOpen && hasQueryReference) {
			disposeQuery();
		}

		accountIdRef.current = fieldValue?.accountId;
	}, [cloudId, disposeQuery, fieldValue, hasQueryReference, isOpen, loadQuery]);

	const onToggle = () => {
		setIsOpen((prev) => {
			if (!prev && fieldValue?.accountId) {
				loadQuery({
					cloudId,
					accountId: fieldValue.accountId,
					useReporterOrganizations: orgCount === 0,
					filter: fg('jcs_detail_permissions')
						? { context: { type: 'ISSUE', issueId } }
						: { context: { type: 'ISSUE' } },
				});
			}

			return !prev;
		});
	};

	if (!fieldValue) {
		return (
			(!fg('jcs_detail_permissions') || isAgentOrProjectAdmin) && (
				<EmptyStateContainer data-testid="servicedesk-customer-service-issue-view-panel.ui.customer.empty-state-container">
					{formatMessage(messages.emptyState)}
				</EmptyStateContainer>
			)
		);
	}

	return (
		<ContextualAnalyticsData sourceName="issueViewPanelCustomerDetails" sourceType={SCREEN}>
			<Header>{formatMessage(messages.customer)}</Header>
			<ExpandPanel
				id="customer"
				onToggle={onToggle}
				field={<CustomerField value={fieldValue} />}
				isOpen={isOpen}
				source="REPORTER_FIELD"
			>
				{isOpen && queryReference && (
					<div data-testid="servicedesk-customer-service-issue-view-panel.ui.customer.div">
						<PanelView queryReference={queryReference} orgCount={orgCount} />
					</div>
				)}
			</ExpandPanel>
		</ContextualAnalyticsData>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyStateContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N300),
	margin: `${token('space.300', '24px')} 0`,
});
