// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'issue.epic.text-for-edit-view-when-no-value-is-provided',
		defaultMessage: 'Select Epic',
		description: '',
	},
	recent: {
		id: 'issue.epic.text-for-heading-in-recent-group',
		defaultMessage: 'Recent epics',
		description: 'Label for the group with the suggested recently picked epics',
	},
	allEpics: {
		id: 'issue.epic.text-for-heading-in-all-epics-group',
		defaultMessage: 'All epics',
		description: 'Label for the group containing all of the epics',
	},
});
