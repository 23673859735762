import { connect } from '@atlassian/jira-issue-view-react-redux';
import { isPreviewSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import AttachmentContent from './main';
import type { StateProps } from './types';

export default connect(
	(state): StateProps => ({
		isPreview: isPreviewSelector(state),
	}),
	{},
)(AttachmentContent);
