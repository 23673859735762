import { useCallback, useEffect, useRef, useState } from 'react';
import noop from 'lodash/noop';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics';
import { ff } from '@atlassian/jira-feature-flagging';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { useIntl } from '@atlassian/jira-intl/src/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import {
	useAnalyticsEvents,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { transformSla, EMPTY_TIMEZONE } from './utils';

const PREFIX = 'issue.sla-panel.sla';
const LOG_LOCATION = 'sla-panel.fetch.sla';
const TWO_MINUTES_POLLING_INTERVAL = 2 * 60 * 1000;

const getDuration = (startTime: number) => Date.now() - startTime;

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const checkIfBrowserTabFocused = () => document && document.visibilityState === 'visible';

const toError = (e: Error | string): Error => (e instanceof Error ? e : new Error(e));

export const getStatusGroupCode = (error?: Error): string => {
	if (error instanceof FetchError) {
		const { statusCode } = error;
		let statusCodeGroup;

		if (statusCode >= 300 && statusCode < 400) {
			statusCodeGroup = '3xx';
		} else if (statusCode >= 400 && statusCode < 500) {
			statusCodeGroup = '4xx';
		} else if (statusCode >= 500 && statusCode < 600) {
			statusCodeGroup = '5xx';
		} else {
			statusCodeGroup = 'unknown';
		}
		return statusCodeGroup;
	}
	return 'missing-info';
};

const handleFailureWithAnalytics = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error: any,
	startTime: number,
	isFirstLoad: boolean,
	// @ts-expect-error - TS2304 - Cannot find name 'IssueKey'.
	issueKey: IssueKey,
	wasTabFocusedBeforeApiCall: boolean,
) => {
	const analyticsFailurePayload = {
		issueKey,
		duration: getDuration(startTime),
		isFirstLoad,
	};
	fireErrorAnalytics({
		meta: {
			id: 'sla-issue-view-fetch',
			teamName: 'bumblebee',
		},
		error: toError(error),
		attributes: {
			...analyticsFailurePayload,
			statusCodeGroup: getStatusGroupCode(error),
		},
		sendToPrivacyUnsafeSplunk: true,
	});

	log.safeErrorWithoutCustomerData(
		LOG_LOCATION,
		'[FD-4458] SLA_FETCH_REQUEST: Failed to fetch sla data',
		{
			...error,
			isFirstLoad,
			tabFocusBeforeApiCall: wasTabFocusedBeforeApiCall,
			tabFocusAfterApiCall: checkIfBrowserTabFocused(),
		},
	);
};

const handleSuccessWithAnalytics = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	sla: any,
	startTime: number,
	isFirstLoad: boolean,
	// @ts-expect-error - TS2304 - Cannot find name 'IssueKey'.
	issueKey: IssueKey,
	wasTabFocusedBeforeApiCall: boolean,
	// @ts-expect-error - TS2304 - Cannot find name 'AnalyticsEventPayload'. | TS2304 - Cannot find name 'UIAnalyticsEventInterface'.
	createAnalyticsEvent: (payload: AnalyticsEventPayload) => UIAnalyticsEvent,
) => {
	const analyticsPayload = {
		issueKey,
		numberOfSla: sla.goalViews.length,
		duration: getDuration(startTime),
		isFirstLoad,
	};
	log.safeInfoWithoutCustomerData(LOG_LOCATION, 'sla-issue-view-fetch-success', {
		isFirstLoad,
		tabFocusBeforeApiCall: wasTabFocusedBeforeApiCall,
		tabFocusAfterApiCall: checkIfBrowserTabFocused(),
	});
	fireOperationalAnalytics(createAnalyticsEvent({}), 'sla-issue-view-fetch success', PREFIX, {
		...analyticsPayload,
	});
};

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
const useInterval = (callback: () => void, delay: number | null) => {
	const savedCallback = useRef<() => void>(callback);

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		const tick = () => {
			savedCallback.current();
		};
		if (delay !== null) {
			const id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
		return noop;
	}, [delay]);
};

export const getSlaUrl = (issueKey: string) =>
	`/rest/servicedesk/1/servicedesk/sla/issue/${issueKey}`;

export const useSlaDetails = () => {
	const [data, setData] = useState<unknown>();
	const [error, setError] = useState<unknown>();
	const [loading, setLoading] = useState<boolean>(true);
	const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const issueKey = useIssueKey();
	const intl = useIntl();
	const userData = useCurrentUser();
	const userTimezone = useRef<string>(EMPTY_TIMEZONE);
	const locale = useLocale();

	const fetchSlaDetails = useCallback(async () => {
		const startTime = Date.now();
		const isTabFocusedBeforeApiCall = checkIfBrowserTabFocused();
		try {
			setLoading(true);
			const url = getSlaUrl(issueKey);

			const response = await fetchJson(url, { method: 'GET' });
			if (response.errors) {
				handleFailureWithAnalytics(
					response,
					startTime,
					isFirstLoad,
					issueKey,
					isTabFocusedBeforeApiCall,
				);
				setError(JSON.stringify(response));
			} else {
				handleSuccessWithAnalytics(
					response,
					startTime,
					isFirstLoad,
					issueKey,
					isTabFocusedBeforeApiCall,
					createAnalyticsEvent,
				);
				setData(transformSla(response, userTimezone.current, intl, locale));
				setIsFirstLoad(false);
			}
			setLoading(false);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			handleFailureWithAnalytics(err, startTime, isFirstLoad, issueKey, isTabFocusedBeforeApiCall);
			setError(err);
			setLoading(false);
		}
		// If isFirstLoad is a dependency it ends up running the fetch an extra time when it is set to false
		// The alternative is extracting it outside this method but we need to wait for it to complete
		// before updating
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [issueKey]);

	useEffect(() => {
		fetchSlaDetails();
	}, [fetchSlaDetails]);

	useEffect(() => {
		// @ts-expect-error - TS2339 - Property 'timeZone' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'.
		const newTimezoneValue: string = userData?.data?.user?.timeZone || EMPTY_TIMEZONE;
		if (newTimezoneValue !== EMPTY_TIMEZONE && newTimezoneValue !== userTimezone.current) {
			userTimezone.current = newTimezoneValue; // The user timezone value has changed
			if (ff('SLA-530-user-Timezone-Bug-FF')) {
				fetchSlaDetails();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData]);

	// Fetch polls every 2 mins to give live updating. Stop polling if there is an error
	useInterval(
		() => {
			checkIfBrowserTabFocused() && fetchSlaDetails();
		},
		error == null ? TWO_MINUTES_POLLING_INTERVAL : null,
	);

	return [data, error, loading, isFirstLoad] as const;
};
