import { lazyForPaint, lazy } from 'react-loosely-lazy';
import { withMetrics } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';

const getModule = () =>
	import(
		/* webpackChunkName: "async-forge-ui-iframe-issue-view" */ '@atlassian/jira-forge-ui/src/common/ui/iframe/components/issue-view'
	);

export const LazyIssueViewIframe = withMetrics(
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyForPaint(() => getModule().then((module) => module.IssueViewIframeRenderer), {
		ssr: false,
	}),
);

export const LazyIssueViewModalIframe = withMetrics(
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazy(() => getModule().then((module) => module.IssueViewModalIframeRenderer)),
);
