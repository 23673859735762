/*
 * DO NOT USE *
 * To fetch preferences for new components, use useUserPreferencesValue hook instead.
 *
 * This selector was added to fetch userPreferences from redux store for existing class
 * components without needing to introduce an extra layer of wrapper or HOC.
 */

import { createSelector } from 'reselect';
import { SIDEBAR_RESIZE_RATIO } from '@atlassian/jira-issue-shared-types/src/common/types/user-preferences-type.tsx';
import { entitiesSelector } from './issue-selector';

export const myPreferencesSelector = createSelector(entitiesSelector, (entities) =>
	entities ? entities.myPreferences : null,
);

export const issueSidebarRatiosSelector = createSelector(myPreferencesSelector, (myPreferences) =>
	myPreferences ? myPreferences[SIDEBAR_RESIZE_RATIO] : null,
);
