// eslint-disable-next-line jira/restricted/react-component-props
import React, { Component, type ElementRef, type ReactElement, type ComponentProps } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import type CheckMarkIcon from '@atlaskit/icon/core/migration/check-mark--check';
import type FlagFilledIcon from '@atlaskit/icon/core/migration/flag-filled';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
// eslint-disable-next-line import/order
import AKTooltip from '@atlaskit/tooltip';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';

export const itemTruncateWidth: number = gridSize * 25;

type Props = {
	name: string;

	icon: ReactElement<ComponentProps<typeof FlagFilledIcon | typeof CheckMarkIcon>>;
	onClick?: () => void;
};
type State = {
	hasOverflow: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
export default class AltStatusField extends Component<Props, State> {
	static defaultProps = {
		onClick: noop,
	};

	state = {
		hasOverflow: false,
	};

	componentDidMount() {
		this.updateOverflow();
	}

	UNSAFE_componentWillReceiveProps() {
		// Reset the state
		this.setState({ hasOverflow: false });
	}

	componentDidUpdate() {
		this.updateOverflow();
	}

	// @ts-expect-error - TS2322 - Type 'undefined' is not assignable to type 'HTMLSpanElement | Component<ClassAttributes<HTMLSpanElement> & HTMLAttributes<HTMLSpanElement> & StyledProps, any, any>'.
	altStatusLabel: ElementRef<typeof AltStatusLabel> = undefined;

	updateOverflow() {
		if (!this.altStatusLabel) return false;

		// @ts-expect-error - TS2339 - Property 'clientWidth' does not exist on type 'Component<any, any, any>'.
		const overflow = this.altStatusLabel.clientWidth >= itemTruncateWidth;
		if (overflow !== this.state.hasOverflow) {
			this.setState({ hasOverflow: overflow });
		}

		return overflow;
	}

	renderAltStatus = () => (
		<AltStatusLabel
			data-testid="issue-view-foundation.status.alt-status-field.label"
			ref={(el) => {
				// @ts-expect-error - TS2322: Type 'HTMLSpanElement | null' is not assignable to type 'HTMLSpanElement | Component<ClassAttributes<HTMLSpanElement> & HTMLAttributes<HTMLSpanElement> & StyledProps, any, any>'.
				this.altStatusLabel = el;
			}}
		>
			{this.props.name}
		</AltStatusLabel>
	);

	renderAltStatusWithTooltip = () => (
		<AKTooltip
			testId="issue-view-foundation.status.alt-status-field.label-with-tooltip"
			content={this.props.name}
			position="bottom"
		>
			{this.renderAltStatus()}
		</AKTooltip>
	);

	render() {
		return (
			<Container
				onClick={this.props.onClick}
				// eslint-disable-next-line jira/integration/test-id-by-folder-structure
				data-testid="issue.views.issue-base.foundation.status.alt-view"
			>
				{this.props.icon}
				{
					// eslint-disable-next-line no-nested-ternary
					fg('platform-component-visual-refresh')
						? null
						: this.state.hasOverflow
							? this.renderAltStatusWithTooltip()
							: this.renderAltStatus()
				}
			</Container>
		);
	}
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	marginTop: token('space.050', '4px'),
	display: 'inline-flex',
	verticalAlign: 'baseline',
	marginLeft: token('space.negative.025', '-2px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 3}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AltStatusLabel = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
	display: 'inline-block',
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	lineHeight: `${gridSize * 3}px`,
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${itemTruncateWidth}px`,
});
