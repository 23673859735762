// eslint-disable-next-line jira/restricted/react-component-props
import React, { useEffect, useRef, type ComponentProps } from 'react';
import IssueStatusField from '@atlassian/jira-issue-field-status/src/ui/main.tsx';
import { STATUS_SPOTLIGHT } from '@atlassian/jira-issue-view-common-constants/src/view-constants';
import { SHORTCUT_TRIGGER_ID_CHANGE_STATUS } from '@atlassian/jira-issue-view-keyboard-shortcuts/src/constant.tsx';
import { useStatusKeyboardShortcutsStore } from '@atlassian/jira-issue-view-keyboard-shortcuts/src/services/store.tsx';
// eslint-disable-next-line jira/restricted/@atlassian+jira-servicedesk-common
import GlobalSpotlightTargetDeprecated from '@atlassian/jira-servicedesk-common/src/ui/global-spotlight-target';

export type Props = JSX.LibraryManagedAttributes<
	typeof IssueStatusField,
	ComponentProps<typeof IssueStatusField>
>;

const StatusWithWrappers = (props: Props) => {
	const shortcutTriggerRef = useRef<number>(0);
	const statusButtonRef = useRef<unknown>(null);

	const [statusDropdownTriggerCount, { resetComponent }] = useStatusKeyboardShortcutsStore();

	// Allow the status field to be opened externally via keyboard shortcuts.
	// using ref until the decomposition stream project solution is defined and implemented
	useEffect(() => {
		const shouldStatusShortCutTrigger =
			statusDropdownTriggerCount > 0 &&
			Number(shortcutTriggerRef.current) < statusDropdownTriggerCount;

		if (shouldStatusShortCutTrigger && statusButtonRef?.current) {
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			statusButtonRef.current.scrollIntoView?.({ block: 'center' });

			/**
			 * ! This is not a recommended pattern and should be avoided.
			 * ! The only reason it is used here is because atlaskit StatusPopup
			 * !   does not have a way to open the list through a props
			 * !   Design system ticket: https://product-fabric.atlassian.net/browse/DSP-836
			 */
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			statusButtonRef.current?.click();

			shortcutTriggerRef.current = statusDropdownTriggerCount;
		}
	}, [statusDropdownTriggerCount]);

	// clean up - reset trigger when we unmount
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => () => resetComponent(SHORTCUT_TRIGGER_ID_CHANGE_STATUS), []);

	return (
		<GlobalSpotlightTargetDeprecated id={STATUS_SPOTLIGHT}>
			{/* @ts-expect-error - TS2322 - Type 'MutableRefObject<unknown>' is not assignable to type 'RefObject<HTMLElement>'. */}
			<IssueStatusField {...props} statusButtonRef={statusButtonRef} />
		</GlobalSpotlightTargetDeprecated>
	);
};

export default StatusWithWrappers;
