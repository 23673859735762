/**
 * @generated SignedSource<<ee784896387aab8805f59ffc795022ce>>
 * @relayHash a01b68213bbbc7b0c8ddb1a4b41de84a
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 21bde05c27ed23a329bc5f548e7408778099eac9b94f2c84f10f83d977b86add

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type AccountStatus = "active" | "closed" | "inactive" | "%future added value";
export type JiraCmdbAttributeType = "BITBUCKET_REPO" | "CONFLUENCE" | "DEFAULT" | "GROUP" | "OPSGENIE_TEAM" | "PROJECT" | "REFERENCED_OBJECT" | "STATUS" | "USER" | "VERSION" | "%future added value";
export type JiraGoalStatus = "ARCHIVED" | "AT_RISK" | "CANCELLED" | "COMPLETED" | "DONE" | "OFF_TRACK" | "ON_TRACK" | "PAUSED" | "PENDING" | "%future added value";
export type JiraIssueLinkDirection = "INWARD" | "OUTWARD" | "%future added value";
export type JiraIssueViewActivityFeedSortOrder = "NEWEST_FIRST" | "OLDEST_FIRST" | "%future added value";
export type JiraIssueViewAttachmentPanelViewMode = "LIST_VIEW" | "STRIP_VIEW" | "%future added value";
export type JiraIssueViewTimestampDisplayMode = "ABSOLUTE" | "RELATIVE" | "%future added value";
export type JiraProjectStatus = "ACTIVE" | "ARCHIVED" | "DELETED" | "%future added value";
export type JiraProjectStyle = "COMPANY_MANAGED_PROJECT" | "TEAM_MANAGED_PROJECT" | "%future added value";
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
export type JiraServiceManagementApprovalDecisionResponseType = "approved" | "declined" | "pending" | "%future added value";
export type JiraServiceManagementApprovalState = "INSUFFICIENT_APPROVERS" | "OK" | "%future added value";
export type JiraServiceManagementCommentVisibility = "INTERNAL" | "VISIBLE_TO_HELPSEEKER" | "%future added value";
export type JiraServiceManagementMajorIncident = "MAJOR_INCIDENT" | "%future added value";
export type JiraSprintState = "ACTIVE" | "CLOSED" | "FUTURE" | "%future added value";
export type JiraStatusCategoryColor = "BLUE_GRAY" | "BROWN" | "GREEN" | "MEDIUM_GRAY" | "WARM_RED" | "YELLOW" | "%future added value";
export type JiraTimeFormat = "DAYS" | "HOURS" | "PRETTY" | "%future added value";
export type JiraTimeUnit = "DAY" | "HOUR" | "MINUTE" | "WEEK" | "%future added value";
export type JiraVersionStatus = "ARCHIVED" | "RELEASED" | "UNRELEASED" | "%future added value";
export type mainIssueAggQuery$variables = {
  cloudId: string;
  commentTargetId?: string | null | undefined;
  giraAGGMigrationAttachmentsFlag: boolean;
  giraAGGMigrationCommentsFlag: boolean;
  giraAGGMigrationIsArchivedFlag: boolean;
  isCMDBAttributeIdRemoved: boolean;
  isCompassComponentsEnabled: boolean;
  isJPDDeliveryLinkTypeFilteringEnabled: boolean;
  isJPDPanelEnabled: boolean;
  isPinFieldConfigurabilityEnabled: boolean;
  isRelayReleaseVersionFieldIssueViewIntegrationEnabled: boolean;
  issueKey: string;
  issueViewRelayAssigneeFieldFlag: boolean;
  issueViewRelayAttachmentsFlag: boolean;
  issueViewRelayCascadingSelectFlag: boolean;
  issueViewRelayColorFieldFlag: boolean;
  issueViewRelayDateFieldFlag: boolean;
  issueViewRelayDateTimeFieldFlag: boolean;
  issueViewRelayGoalsFlag: boolean;
  issueViewRelayHeaders: boolean;
  issueViewRelayMetadataFieldFlag: boolean;
  issueViewRelayNumberFieldFlag: boolean;
  issueViewRelayParentFieldFlag: boolean;
  issueViewRelayPeopleAndApproversFieldFlag: boolean;
  issueViewRelayPriorityFieldFlag: boolean;
  issueViewRelayProjectFieldFlag: boolean;
  issueViewRelaySingleLineTextFieldFlag: boolean;
  issueViewRelaySprintFieldFlag: boolean;
  issueViewRelaySummaryFieldFlag: boolean;
  issueViewRelayUrlFieldFlag: boolean;
  jsmSentimentCustomFieldFlag: boolean;
  parentInIssueViewFlag: boolean;
  projectKey: string;
};
export type mainIssueAggQuery$data = {
  readonly jira: {
    readonly childIssuesLimit: AGG$Long | null | undefined;
    readonly epicLinkFieldKey: string | null | undefined;
    readonly globalTimeTrackingSettings: {
      readonly defaultFormat: JiraTimeFormat | null | undefined;
      readonly defaultUnit: JiraTimeUnit | null | undefined;
      readonly isJiraConfiguredTimeTrackingEnabled: boolean | null | undefined;
      readonly workingDaysPerWeek: number | null | undefined;
      readonly workingHoursPerDay: number | null | undefined;
    } | null | undefined;
    readonly isSubtasksEnabled: boolean | null | undefined;
    readonly issueByKey: {
      readonly attachments?: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly __typename: "JiraPlatformAttachment";
            readonly attachmentId: string;
            readonly author: {
              readonly accountId: string;
            } | null | undefined;
            readonly created: AGG$DateTime;
            readonly fileName: string | null | undefined;
            readonly fileSize: AGG$Long | null | undefined;
            readonly hasRestrictedParent: boolean | null | undefined;
            readonly mediaApiFileId: string | null | undefined;
            readonly mimeType: string | null | undefined;
            readonly parentId: string | null | undefined;
            readonly parentName: string | null | undefined;
          } | {
            // This will never be '%other', but we need some
            // value in case none of the concrete values match.
            readonly __typename: "%other";
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly childIssues: {
        readonly __typename: "JiraChildIssuesExceedingLimit";
        readonly search: string | null | undefined;
      } | {
        readonly __typename: "JiraChildIssuesWithinLimit";
        readonly issues: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly fieldsById: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly __typename: "JiraDateTimePickerField";
                    readonly dateTime: AGG$DateTime | null | undefined;
                    readonly fieldId: string;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraIssueTypeField";
                    readonly fieldId: string;
                    readonly issueType: {
                      readonly avatar: {
                        readonly medium: string;
                      } | null | undefined;
                      readonly hierarchy: {
                        readonly level: number;
                      };
                      readonly issueTypeId: string;
                      readonly name: string;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraPriorityField";
                    readonly fieldId: string;
                    readonly priority: {
                      readonly iconUrl: AGG$URL | null | undefined;
                      readonly name: string | null | undefined;
                      readonly priorityId: string;
                      readonly sequence: number | null | undefined;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraSingleLineTextField";
                    readonly fieldId: string;
                    readonly text: string | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraSingleSelectUserPickerField";
                    readonly fieldId: string;
                    readonly type: string;
                    readonly user: {
                      readonly __typename: string;
                      readonly accountId: string;
                      readonly accountStatus: AccountStatus;
                      readonly name: string;
                      readonly picture: AGG$URL;
                    } | null | undefined;
                  } | {
                    readonly __typename: "JiraStatusField";
                    readonly fieldId: string;
                    readonly status: {
                      readonly name: string;
                      readonly statusCategory: {
                        readonly statusCategoryId: string;
                      };
                      readonly statusId: string;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraTimeTrackingField";
                    readonly fieldId: string;
                    readonly originalEstimate: {
                      readonly timeInSeconds: AGG$Long | null | undefined;
                    } | null | undefined;
                    readonly remainingEstimate: {
                      readonly timeInSeconds: AGG$Long | null | undefined;
                    } | null | undefined;
                    readonly timeSpent: {
                      readonly timeInSeconds: AGG$Long | null | undefined;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    // This will never be '%other', but we need some
                    // value in case none of the concrete values match.
                    readonly __typename: "%other";
                  } | null | undefined;
                } | null | undefined> | null | undefined;
              } | null | undefined;
              readonly isResolved: boolean | null | undefined;
              readonly issueId: string;
              readonly key: string;
              readonly storyPointEstimateField: {
                readonly __typename: "JiraNumberField";
                readonly fieldId: string;
                readonly isStoryPointField: boolean | null | undefined;
                readonly number: number | null | undefined;
                readonly type: string;
              } | null | undefined;
              readonly storyPointsField: {
                readonly __typename: "JiraNumberField";
                readonly fieldId: string;
                readonly isStoryPointField: boolean | null | undefined;
                readonly number: number | null | undefined;
                readonly type: string;
              } | null | undefined;
              readonly webUrl: AGG$URL | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      } | null | undefined;
      readonly comments?: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly __typename: string;
            readonly author: {
              readonly accountId: string;
              readonly name: string;
              readonly picture: AGG$URL;
            } | null | undefined;
            readonly authorCanSeeRequest?: boolean | null | undefined;
            readonly commentId: string;
            readonly created: AGG$DateTime;
            readonly eventOccurredAt?: AGG$DateTime | null | undefined;
            readonly jsdIncidentActivityViewHidden?: boolean | null | undefined;
            readonly permissionLevel: {
              readonly group: {
                readonly groupId: string;
                readonly id: string;
                readonly name: string;
              } | null | undefined;
              readonly role: {
                readonly id: string;
                readonly name: string | null | undefined;
              } | null | undefined;
            } | null | undefined;
            readonly richText: {
              readonly adfValue: {
                readonly json: AGG$JSON | null | undefined;
              } | null | undefined;
            } | null | undefined;
            readonly updateAuthor: {
              readonly accountId: string;
              readonly name: string;
              readonly picture: AGG$URL;
            } | null | undefined;
            readonly updated: AGG$DateTime | null | undefined;
            readonly visibility?: JiraServiceManagementCommentVisibility | null | undefined;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly errorRetrievingData: boolean;
      readonly fields: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly __typename: "JiraAffectedServicesField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedAffectedServicesConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly name: string | null | undefined;
                  readonly serviceId: string;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraAssetField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedAssetsConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly appKey: string | null | undefined;
                  readonly originId: string | null | undefined;
                  readonly serializedOrigin: string | null | undefined;
                  readonly value: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraBooleanField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly type: string;
            readonly value: boolean | null | undefined;
          } | {
            readonly __typename: "JiraCMDBField";
            readonly cmdbFieldConfig: {
              readonly attributesDisplayedOnIssue: {
                readonly edges: ReadonlyArray<{
                  readonly node: string | null | undefined;
                } | null | undefined> | null | undefined;
              } | null | undefined;
              readonly attributesIncludedInAutoCompleteSearch: {
                readonly edges: ReadonlyArray<{
                  readonly node: string | null | undefined;
                } | null | undefined> | null | undefined;
              } | null | undefined;
              readonly multiple: boolean | null | undefined;
              readonly objectSchemaId: string;
            } | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly isInsightAvailable: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedCmdbObjectsConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly attributes: {
                    readonly edges: ReadonlyArray<{
                      readonly node: {
                        readonly attributeId?: string | null | undefined;
                        readonly objectAttributeValues: {
                          readonly edges: ReadonlyArray<{
                            readonly node: {
                              readonly bitbucketRepo: {
                                readonly avatarUrl: AGG$URL | null | undefined;
                                readonly name: string | null | undefined;
                                readonly url: AGG$URL | null | undefined;
                                readonly uuid: string | null | undefined;
                              } | null | undefined;
                              readonly displayValue: string | null | undefined;
                              readonly group: {
                                readonly name: string;
                              } | null | undefined;
                              readonly opsgenieTeam: {
                                readonly id: string;
                                readonly name: string | null | undefined;
                                readonly url: string | null | undefined;
                              } | null | undefined;
                              readonly project: {
                                readonly avatar: {
                                  readonly large: string | null | undefined;
                                } | null | undefined;
                                readonly id: string;
                                readonly key: string;
                                readonly name: string;
                              } | null | undefined;
                              readonly referencedObject: {
                                readonly avatar: {
                                  readonly mediaClientConfig: {
                                    readonly clientId: string | null | undefined;
                                    readonly fileId: string | null | undefined;
                                    readonly issuer: string | null | undefined;
                                    readonly mediaBaseUrl: AGG$URL | null | undefined;
                                    readonly mediaJwtToken: string | null | undefined;
                                  } | null | undefined;
                                  readonly url48: string | null | undefined;
                                } | null | undefined;
                                readonly label: string | null | undefined;
                                readonly objectGlobalId: string | null | undefined;
                                readonly objectId: string | null | undefined;
                                readonly objectKey: string | null | undefined;
                                readonly workspaceId: string | null | undefined;
                              } | null | undefined;
                              readonly searchValue: string | null | undefined;
                              readonly status: {
                                readonly category: number | null | undefined;
                                readonly id: string | null | undefined;
                                readonly name: string | null | undefined;
                              } | null | undefined;
                              readonly user: {
                                readonly accountId: string;
                                readonly name: string;
                                readonly picture: AGG$URL;
                              } | null | undefined;
                              readonly value: string | null | undefined;
                            } | null | undefined;
                          } | null | undefined> | null | undefined;
                        } | null | undefined;
                        readonly objectTypeAttribute: {
                          readonly defaultType: {
                            readonly id: string | null | undefined;
                            readonly name: string | null | undefined;
                          } | null | undefined;
                          readonly label: boolean | null | undefined;
                          readonly name: string | null | undefined;
                          readonly type: JiraCmdbAttributeType | null | undefined;
                        };
                        readonly objectTypeAttributeId: string | null | undefined;
                      } | null | undefined;
                    } | null | undefined> | null | undefined;
                  } | null | undefined;
                  readonly avatar: {
                    readonly mediaClientConfig: {
                      readonly clientId: string | null | undefined;
                      readonly fileId: string | null | undefined;
                      readonly issuer: string | null | undefined;
                      readonly mediaBaseUrl: AGG$URL | null | undefined;
                      readonly mediaJwtToken: string | null | undefined;
                    } | null | undefined;
                    readonly url48: string;
                  } | null | undefined;
                  readonly label: string | null | undefined;
                  readonly objectGlobalId: string | null | undefined;
                  readonly objectId: string | null | undefined;
                  readonly objectKey: string | null | undefined;
                  readonly objectType: {
                    readonly icon: {
                      readonly id: string;
                      readonly name: string | null | undefined;
                      readonly url16: string | null | undefined;
                      readonly url48: string | null | undefined;
                    };
                    readonly name: string | null | undefined;
                    readonly objectSchemaId: string | null | undefined;
                    readonly objectTypeId: string;
                  };
                  readonly webUrl: string | null | undefined;
                  readonly workspaceId: string;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
            readonly wasInsightRequestSuccessful: boolean | null | undefined;
          } | {
            readonly __typename: "JiraCascadingSelectField";
            readonly cascadingOption: {
              readonly childOptionValue: {
                readonly id: string;
                readonly isDisabled: boolean | null | undefined;
                readonly optionId: string;
                readonly value: string | null | undefined;
              } | null | undefined;
              readonly parentOptionValue: {
                readonly id: string;
                readonly isDisabled: boolean | null | undefined;
                readonly optionId: string;
                readonly value: string | null | undefined;
              } | null | undefined;
            } | null | undefined;
            readonly cascadingOptions?: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly childOptionValues: ReadonlyArray<{
                    readonly id: string;
                    readonly isDisabled: boolean | null | undefined;
                    readonly optionId: string;
                    readonly value: string | null | undefined;
                  } | null | undefined> | null | undefined;
                  readonly parentOptionValue: {
                    readonly id: string;
                    readonly isDisabled: boolean | null | undefined;
                    readonly optionId: string;
                    readonly value: string | null | undefined;
                  } | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly type: string;
          } | {
            readonly __typename: "JiraCheckboxesField";
            readonly checkboxesFieldOptions: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly isDisabled: boolean | null | undefined;
                  readonly optionId: string;
                  readonly value: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly selectedOptions: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly isDisabled: boolean | null | undefined;
                  readonly optionId: string;
                  readonly value: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraColorField";
            readonly color: {
              readonly colorKey: string | null | undefined;
              readonly id: string | null | undefined;
            } | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly type: string;
          } | {
            readonly __typename: "JiraComponentsField";
            readonly components: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly ari?: string | null | undefined;
                  readonly componentId: string;
                  readonly description: string | null | undefined;
                  readonly id: string;
                  readonly metadata?: AGG$JSON | null | undefined;
                  readonly name: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly selectedComponentsConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly ari?: string | null | undefined;
                  readonly componentId: string;
                  readonly description: string | null | undefined;
                  readonly id: string;
                  readonly metadata?: AGG$JSON | null | undefined;
                  readonly name: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraConnectDateTimeField";
            readonly dateTime: AGG$DateTime | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly type: string;
          } | {
            readonly __typename: "JiraConnectMultipleSelectField";
            readonly connectMultiSelectFieldOptions: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly isDisabled: boolean | null | undefined;
                  readonly optionId: string;
                  readonly value: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly selectedOptions: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly isDisabled: boolean | null | undefined;
                  readonly optionId: string;
                  readonly value: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraConnectNumberField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly number: number | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraConnectRichTextField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly renderer: string | null | undefined;
            readonly richText: {
              readonly adfValue: {
                readonly json: AGG$JSON | null | undefined;
              } | null | undefined;
              readonly plainText: string | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraConnectSingleSelectField";
            readonly connectSingleSelectFieldOptions: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly isDisabled: boolean | null | undefined;
                  readonly optionId: string;
                  readonly value: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly fieldOption: {
              readonly id: string;
              readonly isDisabled: boolean | null | undefined;
              readonly optionId: string;
              readonly value: string | null | undefined;
            } | null | undefined;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraConnectTextField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly text: string | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraDataClassificationField";
            readonly classificationLevel: {
              readonly color: {
                readonly colorKey: string | null | undefined;
              } | null | undefined;
              readonly guidelines: string | null | undefined;
              readonly name: string | null | undefined;
            } | null | undefined;
            readonly fieldConfig: {
              readonly isEditable: boolean | null | undefined;
            } | null | undefined;
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"editViewPopup_issueClassificationBadge_ClassificationEditViewPopup">;
          } | {
            readonly __typename: "JiraDatePickerField";
            readonly date: AGG$Date | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly type: string;
          } | {
            readonly __typename: "JiraDateTimePickerField";
            readonly dateTime: AGG$DateTime | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly type: string;
          } | {
            readonly __typename: "JiraEpicLinkField";
            readonly description: string | null | undefined;
            readonly epic: {
              readonly color: string | null | undefined;
              readonly done: boolean | null | undefined;
              readonly id: string;
              readonly issueId: string;
              readonly key: string | null | undefined;
              readonly name: string | null | undefined;
              readonly summary: string | null | undefined;
            } | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraFlagField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly flag: {
              readonly isFlagged: boolean | null | undefined;
            } | null | undefined;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly type: string;
          } | {
            readonly __typename: "JiraForgeDateField";
            readonly date: AGG$Date | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly renderer: string | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraForgeDatetimeField";
            readonly dateTime: AGG$DateTime | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly renderer: string | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraForgeGroupField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly renderer: string | null | undefined;
            readonly selectedGroup: {
              readonly groupId: string;
              readonly id: string;
              readonly name: string;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraForgeGroupsField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly renderer: string | null | undefined;
            readonly selectedGroupsConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly groupId: string;
                  readonly id: string;
                  readonly name: string;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraForgeNumberField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly number: number | null | undefined;
            readonly renderer: string | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraForgeObjectField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly object: string | null | undefined;
            readonly renderer: string | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraForgeStringField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly renderer: string | null | undefined;
            readonly text: string | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraForgeStringsField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly renderer: string | null | undefined;
            readonly searchUrl: string | null | undefined;
            readonly selectedLabelsConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly labelId: string | null | undefined;
                  readonly name: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraForgeUserField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly renderer: string | null | undefined;
            readonly searchUrl: string | null | undefined;
            readonly type: string;
            readonly user: {
              readonly __typename: string;
              readonly accountId: string;
              readonly accountStatus: AccountStatus;
              readonly email?: string | null | undefined;
              readonly locale?: string | null | undefined;
              readonly name: string;
              readonly picture: AGG$URL;
              readonly zoneinfo?: string | null | undefined;
            } | null | undefined;
          } | {
            readonly __typename: "JiraForgeUsersField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly renderer: string | null | undefined;
            readonly searchUrl: string | null | undefined;
            readonly selectedUsersConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly __typename: string;
                  readonly accountId: string;
                  readonly accountStatus: AccountStatus;
                  readonly email?: string | null | undefined;
                  readonly locale?: string | null | undefined;
                  readonly name: string;
                  readonly picture: AGG$URL;
                  readonly zoneinfo?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraGoalsField";
            readonly __typename: "JiraGoalsField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly selectedGoals?: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly name: string | null | undefined;
                  readonly status: JiraGoalStatus | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
              readonly totalCount: number | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraIssueRestrictionField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedRolesConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly roleId: string;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraIssueTypeField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly issueType: {
              readonly avatar: {
                readonly large: string | null | undefined;
                readonly medium: string;
                readonly small: string | null | undefined;
                readonly xsmall: string | null | undefined;
              } | null | undefined;
              readonly description: string | null | undefined;
              readonly hierarchy: {
                readonly level: number;
                readonly name: string;
              };
              readonly id: string;
              readonly issueTypeId: string;
              readonly name: string;
            } | null | undefined;
            readonly name: string;
            readonly type: string;
          } | {
            readonly __typename: "JiraLabelsField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedLabelsConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly labelId: string | null | undefined;
                  readonly name: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraMultipleGroupPickerField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedGroupsConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly groupId: string;
                  readonly name: string;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraMultipleSelectField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedOptions: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly isDisabled: boolean | null | undefined;
                  readonly optionId: string;
                  readonly value: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraMultipleSelectUserPickerField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedUsersConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly __typename: string;
                  readonly accountId: string;
                  readonly accountStatus: AccountStatus;
                  readonly email?: string | null | undefined;
                  readonly locale?: string | null | undefined;
                  readonly name: string;
                  readonly picture: AGG$URL;
                  readonly zoneinfo?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraMultipleVersionPickerField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly multiPickerVersions?: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly description: string | null | undefined;
                  readonly id: string;
                  readonly name: string | null | undefined;
                  readonly status: JiraVersionStatus | null | undefined;
                  readonly versionId: string;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly name: string;
            readonly selectedVersionsConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly description: string | null | undefined;
                  readonly id: string;
                  readonly name: string | null | undefined;
                  readonly status: JiraVersionStatus | null | undefined;
                  readonly versionId: string;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraNumberField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly isStoryPointField: boolean | null | undefined;
            readonly name: string;
            readonly number: number | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraOriginalTimeEstimateField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isEditable: boolean | null | undefined;
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly originalEstimate: {
              readonly timeInSeconds: AGG$Long | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraParentIssueField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly parentIssue: {
              readonly fieldsById: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly __typename: "JiraIssueTypeField";
                    readonly description: string | null | undefined;
                    readonly fieldId: string;
                    readonly issueType: {
                      readonly avatar: {
                        readonly large: string | null | undefined;
                        readonly medium: string;
                        readonly small: string | null | undefined;
                        readonly xsmall: string | null | undefined;
                      } | null | undefined;
                      readonly description: string | null | undefined;
                      readonly hierarchy: {
                        readonly level: number;
                        readonly name: string;
                      };
                      readonly id: string;
                      readonly issueTypeId: string;
                      readonly name: string;
                    } | null | undefined;
                    readonly name: string;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraProjectField";
                    readonly description: string | null | undefined;
                    readonly fieldId: string;
                    readonly name: string;
                    readonly project: {
                      readonly avatar: {
                        readonly large: string;
                        readonly medium: string | null | undefined;
                      };
                      readonly canSetIssueRestriction: boolean | null | undefined;
                      readonly key: string;
                      readonly name: string;
                      readonly navigationMetadata: {
                        readonly boardId?: string;
                      } | null | undefined;
                      readonly projectId: string;
                      readonly projectStyle: JiraProjectStyle | null | undefined;
                      readonly projectType: JiraProjectType | null | undefined;
                      readonly similarIssues: {
                        readonly featureEnabled: boolean;
                      } | null | undefined;
                      readonly status: JiraProjectStatus | null | undefined;
                    } | null | undefined;
                    readonly searchUrl: string | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraSingleLineTextField";
                    readonly description: string | null | undefined;
                    readonly fieldId: string;
                    readonly name: string;
                    readonly text: string | null | undefined;
                    readonly type: string;
                  } | {
                    // This will never be '%other', but we need some
                    // value in case none of the concrete values match.
                    readonly __typename: "%other";
                  } | null | undefined;
                } | null | undefined> | null | undefined;
              } | null | undefined;
              readonly id: string;
              readonly issueColorField?: {
                readonly color: {
                  readonly colorKey: string | null | undefined;
                } | null | undefined;
              } | null | undefined;
              readonly issueId: string;
              readonly key: string;
              readonly webUrl: AGG$URL | null | undefined;
            } | null | undefined;
            readonly parentVisibility: {
              readonly canUseParentLinkField: boolean | null | undefined;
              readonly hasEpicLinkFieldDependency: boolean | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraPeopleField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly isMulti: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedUsersConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly __typename: string;
                  readonly accountId: string;
                  readonly accountStatus: AccountStatus;
                  readonly email?: string | null | undefined;
                  readonly locale?: string | null | undefined;
                  readonly name: string;
                  readonly picture: AGG$URL;
                  readonly zoneinfo?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraPriorityField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly priorities: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly color: string | null | undefined;
                  readonly iconUrl: AGG$URL | null | undefined;
                  readonly name: string | null | undefined;
                  readonly priorityId: string;
                  readonly sequence: number | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly priority: {
              readonly color: string | null | undefined;
              readonly iconUrl: AGG$URL | null | undefined;
              readonly name: string | null | undefined;
              readonly priorityId: string;
              readonly sequence: number | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraProformaFormsField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly proformaForms: {
              readonly hasIssueForms: boolean | null | undefined;
              readonly hasProjectForms: boolean | null | undefined;
              readonly isHarmonisationEnabled: boolean | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraProjectField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly project: {
              readonly avatar: {
                readonly large: string;
                readonly medium: string | null | undefined;
              };
              readonly canSetIssueRestriction: boolean | null | undefined;
              readonly key: string;
              readonly name: string;
              readonly navigationMetadata: {
                readonly boardId?: string;
              } | null | undefined;
              readonly projectId: string;
              readonly projectStyle: JiraProjectStyle | null | undefined;
              readonly projectType: JiraProjectType | null | undefined;
              readonly similarIssues: {
                readonly featureEnabled: boolean;
              } | null | undefined;
              readonly status: JiraProjectStatus | null | undefined;
            } | null | undefined;
            readonly searchUrl: string | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraRadioSelectField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly radioSelectFieldOptions: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly isDisabled: boolean | null | undefined;
                  readonly optionId: string;
                  readonly value: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly selectedOption: {
              readonly id: string;
              readonly isDisabled: boolean | null | undefined;
              readonly optionId: string;
              readonly value: string | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraResolutionField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly resolution: {
              readonly id: string;
              readonly name: string | null | undefined;
              readonly resolutionId: string;
            } | null | undefined;
            readonly resolutions: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly name: string | null | undefined;
                  readonly resolutionId: string;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraRichTextField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly renderer: string | null | undefined;
            readonly richText: {
              readonly adfValue: {
                readonly json: AGG$JSON | null | undefined;
              } | null | undefined;
              readonly plainText: string | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraSecurityLevelField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly securityLevel: {
              readonly description: string | null | undefined;
              readonly name: string | null | undefined;
              readonly securityId: string;
            } | null | undefined;
            readonly securityLevels: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly description: string | null | undefined;
                  readonly name: string | null | undefined;
                  readonly securityId: string;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraServiceManagementApprovalField";
            readonly activeApproval: {
              readonly approvalState: JiraServiceManagementApprovalState | null | undefined;
              readonly approvedStatus: {
                readonly categoryId: string | null | undefined;
                readonly name: string | null | undefined;
              } | null | undefined;
              readonly approverPrincipals: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly __typename: "JiraServiceManagementGroupApproverPrincipal";
                    readonly approvedCount: number;
                    readonly groupId: string;
                    readonly memberCount: number;
                    readonly name: string;
                  } | {
                    readonly __typename: "JiraServiceManagementUserApproverPrincipal";
                    readonly jiraRest: AGG$URL;
                    readonly user: {
                      readonly accountId: string;
                      readonly name: string;
                      readonly picture: AGG$URL;
                    };
                  } | {
                    // This will never be '%other', but we need some
                    // value in case none of the concrete values match.
                    readonly __typename: "%other";
                  } | null | undefined;
                } | null | undefined> | null | undefined;
              } | null | undefined;
              readonly approvers: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly approver: {
                      readonly accountId: string;
                      readonly accountStatus: AccountStatus;
                      readonly email?: string | null | undefined;
                      readonly name: string;
                      readonly picture: AGG$URL;
                      readonly zoneinfo?: string | null | undefined;
                    };
                    readonly approverDecision: JiraServiceManagementApprovalDecisionResponseType;
                  } | null | undefined;
                } | null | undefined> | null | undefined;
              } | null | undefined;
              readonly canAnswerApproval: boolean;
              readonly configurations: ReadonlyArray<{
                readonly approversConfigurations: ReadonlyArray<{
                  readonly fieldId: string;
                  readonly fieldName: string;
                  readonly type: string;
                } | null | undefined>;
                readonly condition: {
                  readonly type: string;
                  readonly value: string;
                };
              } | null | undefined>;
              readonly createdDate: AGG$DateTime | null | undefined;
              readonly decisions: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly approver: {
                      readonly accountId: string;
                      readonly accountStatus: AccountStatus;
                      readonly email?: string | null | undefined;
                      readonly name: string;
                      readonly picture: AGG$URL;
                      readonly zoneinfo?: string | null | undefined;
                    };
                    readonly approverDecision: JiraServiceManagementApprovalDecisionResponseType;
                  } | null | undefined;
                } | null | undefined> | null | undefined;
              } | null | undefined;
              readonly excludedApprovers: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly accountId: string;
                    readonly accountStatus: AccountStatus;
                    readonly email?: string | null | undefined;
                    readonly name: string;
                    readonly picture: AGG$URL;
                    readonly zoneinfo?: string | null | undefined;
                  } | null | undefined;
                } | null | undefined> | null | undefined;
              } | null | undefined;
              readonly finalDecision: JiraServiceManagementApprovalDecisionResponseType;
              readonly id: string;
              readonly name: string;
              readonly pendingApprovalCount: number;
              readonly status: {
                readonly categoryId: string | null | undefined;
                readonly id: string | null | undefined;
                readonly name: string | null | undefined;
              } | null | undefined;
            } | null | undefined;
            readonly completedApprovalsConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly approvers: {
                    readonly edges: ReadonlyArray<{
                      readonly node: {
                        readonly approver: {
                          readonly accountId: string;
                          readonly accountStatus: AccountStatus;
                          readonly email?: string | null | undefined;
                          readonly name: string;
                          readonly picture: AGG$URL;
                          readonly zoneinfo?: string | null | undefined;
                        };
                        readonly approverDecision: JiraServiceManagementApprovalDecisionResponseType;
                      } | null | undefined;
                    } | null | undefined> | null | undefined;
                  } | null | undefined;
                  readonly completedDate: AGG$DateTime;
                  readonly createdDate: AGG$DateTime | null | undefined;
                  readonly finalDecision: JiraServiceManagementApprovalDecisionResponseType;
                  readonly id: string;
                  readonly name: string;
                  readonly status: {
                    readonly categoryId: string | null | undefined;
                    readonly name: string | null | undefined;
                  } | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly type: string;
          } | {
            readonly __typename: "JiraServiceManagementEntitlementField";
            readonly __id: string;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly selectedEntitlement: {
              readonly entity: {
                readonly __typename: "JiraServiceManagementEntitlementCustomer";
                readonly id: string;
              } | {
                readonly __typename: "JiraServiceManagementEntitlementOrganization";
                readonly id: string;
              } | {
                // This will never be '%other', but we need some
                // value in case none of the concrete values match.
                readonly __typename: "%other";
              } | null | undefined;
              readonly id: string;
              readonly product: {
                readonly id: string;
                readonly name: string | null | undefined;
              } | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraServiceManagementMajorIncidentField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly majorIncident: JiraServiceManagementMajorIncident | null | undefined;
            readonly name: string;
            readonly type: string;
          } | {
            readonly __typename: "JiraServiceManagementOrganizationField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedOrganizationsConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly domain: string | null | undefined;
                  readonly organizationId: string | null | undefined;
                  readonly organizationName: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraServiceManagementPeopleField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedUsersConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly __typename: string;
                  readonly accountId: string;
                  readonly accountStatus: AccountStatus;
                  readonly email?: string | null | undefined;
                  readonly locale?: string | null | undefined;
                  readonly name: string;
                  readonly picture: AGG$URL;
                  readonly zoneinfo?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraServiceManagementRequestFeedbackField";
            readonly description: string | null | undefined;
            readonly feedback: {
              readonly rating: number | null | undefined;
            } | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly type: string;
          } | {
            readonly __typename: "JiraServiceManagementRequestLanguageField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly language: {
              readonly displayName: string | null | undefined;
              readonly languageCode: string | null | undefined;
            } | null | undefined;
            readonly languages: ReadonlyArray<{
              readonly displayName: string | null | undefined;
              readonly languageCode: string | null | undefined;
            } | null | undefined> | null | undefined;
            readonly name: string;
            readonly type: string;
          } | {
            readonly __typename: "JiraServiceManagementRequestTypeField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly requestType: {
              readonly avatar: {
                readonly large: string | null | undefined;
                readonly medium: string | null | undefined;
                readonly small: string | null | undefined;
                readonly xsmall: string | null | undefined;
              } | null | undefined;
              readonly id: string;
              readonly issueType: {
                readonly id: string;
                readonly issueTypeId: string | null | undefined;
              } | null | undefined;
              readonly name: string | null | undefined;
              readonly requestTypeId: string;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraServiceManagementRespondersField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly respondersConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly __typename: "JiraServiceManagementTeamResponder";
                  readonly teamId: string | null | undefined;
                  readonly teamName: string | null | undefined;
                } | {
                  readonly __typename: "JiraServiceManagementUserResponder";
                  readonly user: {
                    readonly canonicalAccountId: string;
                    readonly name: string;
                    readonly picture: AGG$URL;
                  } | null | undefined;
                } | {
                  // This will never be '%other', but we need some
                  // value in case none of the concrete values match.
                  readonly __typename: "%other";
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly searchUrl: string | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraServiceManagementSentimentField";
            readonly __id: string;
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly sentiment: {
              readonly name: string | null | undefined;
              readonly sentimentId: string | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraSingleGroupPickerField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedGroup: {
              readonly groupId: string;
              readonly id: string;
              readonly name: string;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraSingleLineTextField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly text: string | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraSingleSelectField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly fieldOption: {
              readonly id: string;
              readonly isDisabled: boolean | null | undefined;
              readonly optionId: string;
              readonly value: string | null | undefined;
            } | null | undefined;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraSingleSelectUserPickerField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly type: string;
            readonly user: {
              readonly __typename: string;
              readonly accountId: string;
              readonly accountStatus: AccountStatus;
              readonly locale?: string | null | undefined;
              readonly name: string;
              readonly picture: AGG$URL;
              readonly zoneinfo?: string | null | undefined;
            } | null | undefined;
          } | {
            readonly __typename: "JiraSingleVersionPickerField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly singlePickerVersions: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly description: string | null | undefined;
                  readonly iconUrl: AGG$URL | null | undefined;
                  readonly id: string;
                  readonly name: string | null | undefined;
                  readonly releaseDate: AGG$DateTime | null | undefined;
                  readonly startDate: AGG$DateTime | null | undefined;
                  readonly status: JiraVersionStatus | null | undefined;
                  readonly versionId: string;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
            readonly version: {
              readonly description: string | null | undefined;
              readonly iconUrl: AGG$URL | null | undefined;
              readonly id: string;
              readonly name: string | null | undefined;
              readonly releaseDate: AGG$DateTime | null | undefined;
              readonly startDate: AGG$DateTime | null | undefined;
              readonly status: JiraVersionStatus | null | undefined;
              readonly versionId: string;
            } | null | undefined;
          } | {
            readonly __typename: "JiraSprintField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedSprintsConnection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly boardName: string | null | undefined;
                  readonly completionDate: AGG$DateTime | null | undefined;
                  readonly endDate: AGG$DateTime | null | undefined;
                  readonly goal: string | null | undefined;
                  readonly id: string;
                  readonly name: string;
                  readonly sprintId: string;
                  readonly startDate: AGG$DateTime | null | undefined;
                  readonly state: JiraSprintState | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraStatusField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly status: {
              readonly description: string | null | undefined;
              readonly id: string;
              readonly name: string;
              readonly statusCategory: {
                readonly id: string;
                readonly statusCategoryId: string;
              };
              readonly statusId: string;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraTeamField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly selectedTeam: {
              readonly avatar: {
                readonly medium: string | null | undefined;
              } | null | undefined;
              readonly isShared: boolean | null | undefined;
              readonly name: string | null | undefined;
              readonly teamId: string;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraTeamViewField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly searchUrl: string | null | undefined;
            readonly selectedTeam: {
              readonly jiraSuppliedAvatar: {
                readonly large: string | null | undefined;
                readonly medium: string | null | undefined;
                readonly small: string | null | undefined;
                readonly xsmall: string | null | undefined;
              } | null | undefined;
              readonly jiraSuppliedId: string;
              readonly jiraSuppliedName: string | null | undefined;
              readonly jiraSuppliedTeamId: string;
              readonly jiraSuppliedVisibility: boolean | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraTimeTrackingField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly originalEstimate: {
              readonly timeInSeconds: AGG$Long | null | undefined;
            } | null | undefined;
            readonly remainingEstimate: {
              readonly timeInSeconds: AGG$Long | null | undefined;
            } | null | undefined;
            readonly timeSpent: {
              readonly timeInSeconds: AGG$Long | null | undefined;
            } | null | undefined;
            readonly type: string;
          } | {
            readonly __typename: "JiraUrlField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly type: string;
            readonly uri: string | null | undefined;
          } | {
            readonly __typename: "JiraVotesField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly type: string;
            readonly vote: {
              readonly count: AGG$Long | null | undefined;
              readonly hasVoted: boolean | null | undefined;
            } | null | undefined;
          } | {
            readonly __typename: "JiraWatchesField";
            readonly description: string | null | undefined;
            readonly fieldConfig?: {
              readonly isRequired: boolean | null | undefined;
            } | null | undefined;
            readonly fieldId: string;
            readonly id?: string;
            readonly isEditableInIssueView?: boolean | null | undefined;
            readonly name: string;
            readonly type: string;
            readonly watch: {
              readonly count: AGG$Long | null | undefined;
              readonly isWatching: boolean | null | undefined;
            } | null | undefined;
          } | {
            // This will never be '%other', but we need some
            // value in case none of the concrete values match.
            readonly __typename: "%other";
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly hierarchyLevelAbove: {
        readonly level: number;
        readonly name: string;
      } | null | undefined;
      readonly hierarchyLevelBelow: {
        readonly level: number;
        readonly name: string;
      } | null | undefined;
      readonly isArchived?: boolean | null | undefined;
      readonly issueColorField?: {
        readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutColorField_IssueViewColorField">;
      } | null | undefined;
      readonly issueId: string;
      readonly issueLinks: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly direction: JiraIssueLinkDirection;
            readonly id: string | null | undefined;
            readonly issue: {
              readonly fieldsById: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly __typename: "JiraIssueTypeField";
                    readonly fieldId: string;
                    readonly issueType: {
                      readonly avatar: {
                        readonly large: string | null | undefined;
                        readonly medium: string;
                        readonly small: string | null | undefined;
                        readonly xsmall: string | null | undefined;
                      } | null | undefined;
                      readonly hierarchy: {
                        readonly level: number;
                        readonly name: string;
                      };
                      readonly id: string;
                      readonly issueTypeId: string;
                      readonly name: string;
                    } | null | undefined;
                    readonly name: string;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraPriorityField";
                    readonly fieldId: string;
                    readonly name: string;
                    readonly priority: {
                      readonly color: string | null | undefined;
                      readonly iconUrl: AGG$URL | null | undefined;
                      readonly name: string | null | undefined;
                      readonly priorityId: string;
                      readonly sequence: number | null | undefined;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraProjectField";
                    readonly description: string | null | undefined;
                    readonly fieldId: string;
                    readonly name: string;
                    readonly project: {
                      readonly avatar: {
                        readonly large: string;
                        readonly medium: string | null | undefined;
                      };
                      readonly canSetIssueRestriction: boolean | null | undefined;
                      readonly key: string;
                      readonly name: string;
                      readonly navigationMetadata: {
                        readonly boardId?: string;
                      } | null | undefined;
                      readonly projectId: string;
                      readonly projectStyle: JiraProjectStyle | null | undefined;
                      readonly projectType: JiraProjectType | null | undefined;
                      readonly similarIssues: {
                        readonly featureEnabled: boolean;
                      } | null | undefined;
                      readonly status: JiraProjectStatus | null | undefined;
                    } | null | undefined;
                    readonly searchUrl: string | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraSingleLineTextField";
                    readonly fieldId: string;
                    readonly name: string;
                    readonly text: string | null | undefined;
                    readonly type: string;
                  } | {
                    readonly __typename: "JiraSingleSelectUserPickerField";
                    readonly fieldId: string;
                    readonly name: string;
                    readonly type: string;
                    readonly user: {
                      readonly __typename: string;
                      readonly accountId: string;
                      readonly accountStatus: AccountStatus;
                      readonly locale?: string | null | undefined;
                      readonly name: string;
                      readonly picture: AGG$URL;
                      readonly zoneinfo?: string | null | undefined;
                    } | null | undefined;
                  } | {
                    readonly __typename: "JiraStatusField";
                    readonly fieldId: string;
                    readonly name: string;
                    readonly status: {
                      readonly id: string;
                      readonly name: string;
                      readonly statusCategory: {
                        readonly colorName: JiraStatusCategoryColor | null | undefined;
                        readonly id: string;
                        readonly key: string | null | undefined;
                        readonly name: string;
                        readonly statusCategoryId: string;
                      };
                      readonly statusId: string;
                    } | null | undefined;
                    readonly type: string;
                  } | {
                    // This will never be '%other', but we need some
                    // value in case none of the concrete values match.
                    readonly __typename: "%other";
                  } | null | undefined;
                } | null | undefined> | null | undefined;
              } | null | undefined;
              readonly id: string;
              readonly isResolved: boolean | null | undefined;
              readonly issueId: string;
              readonly key: string;
              readonly webUrl: AGG$URL | null | undefined;
            } | null | undefined;
            readonly issueLinkId: string;
            readonly relationName: string;
            readonly type: {
              readonly linkTypeId: string;
            };
          } | null | undefined;
        } | null | undefined> | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewJpdIdeas_JPDIdeasPanel">;
      } | null | undefined;
      readonly issueTypesForHierarchyAbove: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly avatar: {
              readonly large: string | null | undefined;
              readonly medium: string;
              readonly small: string | null | undefined;
              readonly xsmall: string | null | undefined;
            } | null | undefined;
            readonly description: string | null | undefined;
            readonly hierarchy: {
              readonly level: number;
              readonly name: string;
            };
            readonly id: string;
            readonly issueTypeId: string;
            readonly name: string;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly issueTypesForHierarchyBelow: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly avatar: {
              readonly large: string | null | undefined;
              readonly medium: string;
              readonly small: string | null | undefined;
              readonly xsmall: string | null | undefined;
            } | null | undefined;
            readonly description: string | null | undefined;
            readonly hierarchy: {
              readonly level: number;
              readonly name: string;
            };
            readonly id: string;
            readonly issueTypeId: string;
            readonly name: string;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly issueTypesForHierarchySame: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly avatar: {
              readonly large: string | null | undefined;
              readonly medium: string;
              readonly small: string | null | undefined;
              readonly xsmall: string | null | undefined;
            } | null | undefined;
            readonly description: string | null | undefined;
            readonly hierarchy: {
              readonly level: number;
              readonly name: string;
            };
            readonly id: string;
            readonly issueTypeId: string;
            readonly name: string;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly key: string;
      readonly mediaReadToken?: {
        readonly clientId: string | null | undefined;
        readonly endpointUrl: string | null | undefined;
        readonly tokenLifespanInSeconds: AGG$Long | null | undefined;
        readonly tokensWithFiles: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly token: string | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly mediaUploadToken?: {
        readonly clientId?: string | null | undefined;
        readonly endpointUrl?: AGG$URL | null | undefined;
        readonly errorId?: string | null | undefined;
        readonly errorMessage?: string | null | undefined;
        readonly targetCollection?: string | null | undefined;
        readonly token?: string | null | undefined;
        readonly tokenDurationInMin?: number | null | undefined;
      } | null | undefined;
      readonly screenId: AGG$Long | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"headerActions_issueViewFoundation_HeaderActionsView" | "headerBreadcrumbs_issueViewFoundation_HeaderBreadcrumbsView" | "header_issueViewFoundation_HeaderItemWithoutCompactMode" | "prefetchFieldSuggestions_issueView_MaybePrefetchFieldSuggestions" | "src_issueViewLayoutTemplatesItemList_ItemList" | "ui_issueFieldDataClassification_IssueViewDataClassification" | "ui_issueViewLayoutMetaContainer_IssueViewMetaContainer" | "ui_issueViewLayoutSummaryField_IssueViewSummaryField" | "view_issueViewFoundation_StatusView">;
    } | null | undefined;
    readonly issueLinkTypes: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly inwards: string | null | undefined;
          readonly linkTypeId: string | null | undefined;
          readonly outwards: string | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly jpdDeliveryIssueLinkTypeId?: string | null | undefined;
    readonly userPreferences: {
      readonly isIssueViewAttachmentReferenceFlagDismissed: boolean | null | undefined;
      readonly isIssueViewChildIssuesLimitBestPracticeFlagDismissed: boolean | null | undefined;
      readonly isIssueViewHideDoneChildIssuesFilterEnabled: boolean | null | undefined;
      readonly isIssueViewPinnedFieldsBannerDismissed: boolean | null | undefined;
      readonly issueViewActivityFeedSortOrder: JiraIssueViewActivityFeedSortOrder | null | undefined;
      readonly issueViewAttachmentPanelViewMode: JiraIssueViewAttachmentPanelViewMode | null | undefined;
      readonly issueViewDefaultPinnedFieldsBannerProject?: string | null | undefined;
      readonly issueViewPinnedFields: string | null | undefined;
      readonly issueViewPinnedFieldsBannerLastInteracted: AGG$DateTime | null | undefined;
      readonly issueViewSidebarResizeRatio: string | null | undefined;
      readonly issueViewTimestampDisplayMode: JiraIssueViewTimestampDisplayMode | null | undefined;
    } | null | undefined;
  };
};
export type mainIssueAggQuery = {
  response: mainIssueAggQuery$data;
  variables: mainIssueAggQuery$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent": issueViewRelayParentFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldstimetrackingrelayprovider": issueViewRelayTimeTrackingFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscheckboxselectrelayprovider": issueViewRelayCheckboxSelectFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscomponentsrelayprovider": issueViewRelayComponentsFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsoriginalestimaterelayprovider": issueViewRelayOriginalEstimateFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserrelayprovider": issueViewRelayMultiUserFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsgrouppickerrelayprovider": issueViewRelayGroupPickerFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldspeoplerelayprovider": issueViewRelayPeopleFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsapproversrelayprovider": issueViewRelayApproversFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssinglelinetextmessagerelayprovider": issueViewRelaySingleLineTextMessageFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsassigneerelayprovider": issueViewRelayAssigneeFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsreporterrelayprovider": issueViewRelayReporterFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsuserrelayprovider": issueViewRelayUserFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsheaderactionsrelayprovider": issueViewRelayHeaderActionsFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldswatchersrelayprovider": issueViewRelayWatchersFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssecuritylevelrelayprovider": issueViewRelaySecurityLevelFieldFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuetypeswitcherrelayprovider": issueViewRelayIssueTypeSwitcherFlag_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsresolutionrelayprovider": issueViewRelayResolutionFieldFlag_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsapproversrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsassigneerelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscheckboxselectrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscomponentsrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsgrouppickerrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsheaderactionsrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuetypeswitcherrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsoriginalestimaterelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldspeoplerelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsreporterrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsresolutionrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssecuritylevelrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssinglelinetextmessagerelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldstimetrackingrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsuserrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldswatchersrelayprovider: {
    readonly get: () => boolean;
  };
});

import issueViewRelayApproversFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-approvers.relayprovider';
import issueViewRelayAssigneeFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-assignee.relayprovider';
import issueViewRelayCheckboxSelectFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-checkbox-select.relayprovider';
import issueViewRelayComponentsFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-components.relayprovider';
import issueViewRelayGroupPickerFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-group-picker.relayprovider';
import issueViewRelayHeaderActionsFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-header-actions.relayprovider';
import issueViewRelayIssueTypeSwitcherFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-issue-type-switcher.relayprovider';
import issueViewRelayMultiUserFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-multi-user.relayprovider';
import issueViewRelayOriginalEstimateFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-original-estimate.relayprovider';
import issueViewRelayParentFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-parent';
import issueViewRelayPeopleFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-people.relayprovider';
import issueViewRelayReporterFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-reporter.relayprovider';
import issueViewRelayResolutionFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-resolution.relayprovider';
import issueViewRelaySecurityLevelFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-security-level.relayprovider';
import issueViewRelaySingleLineTextMessageFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-single-line-text-message.relayprovider';
import issueViewRelayTimeTrackingFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-time-tracking.relayprovider';
import issueViewRelayUserFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-user.relayprovider';
import issueViewRelayWatchersFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-watchers.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "commentTargetId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "giraAGGMigrationAttachmentsFlag"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "giraAGGMigrationCommentsFlag"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "giraAGGMigrationIsArchivedFlag"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isCMDBAttributeIdRemoved"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isCompassComponentsEnabled"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isJPDDeliveryLinkTypeFilteringEnabled"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isJPDPanelEnabled"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isPinFieldConfigurabilityEnabled"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isRelayReleaseVersionFieldIssueViewIntegrationEnabled"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueKey"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayAssigneeFieldFlag"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayAttachmentsFlag"
},
v14 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayCascadingSelectFlag"
},
v15 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayColorFieldFlag"
},
v16 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayDateFieldFlag"
},
v17 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayDateTimeFieldFlag"
},
v18 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayGoalsFlag"
},
v19 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayHeaders"
},
v20 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayMetadataFieldFlag"
},
v21 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayNumberFieldFlag"
},
v22 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayParentFieldFlag"
},
v23 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayPeopleAndApproversFieldFlag"
},
v24 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayPriorityFieldFlag"
},
v25 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayProjectFieldFlag"
},
v26 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelaySingleLineTextFieldFlag"
},
v27 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelaySprintFieldFlag"
},
v28 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelaySummaryFieldFlag"
},
v29 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueViewRelayUrlFieldFlag"
},
v30 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jsmSentimentCustomFieldFlag"
},
v31 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "parentInIssueViewFlag"
},
v32 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v33 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v34 = [
  (v33/*: any*/)
],
v35 = {
  "args": (v34/*: any*/),
  "kind": "ScalarField",
  "name": "childIssuesLimit"
},
v36 = {
  "kind": "ScalarField",
  "name": "epicLinkFieldKey"
},
v37 = {
  "args": (v34/*: any*/),
  "kind": "ScalarField",
  "name": "isSubtasksEnabled"
},
v38 = {
  "kind": "ScalarField",
  "name": "isJiraConfiguredTimeTrackingEnabled"
},
v39 = {
  "kind": "ScalarField",
  "name": "workingHoursPerDay"
},
v40 = {
  "kind": "ScalarField",
  "name": "workingDaysPerWeek"
},
v41 = {
  "kind": "ScalarField",
  "name": "defaultFormat"
},
v42 = {
  "kind": "ScalarField",
  "name": "defaultUnit"
},
v43 = {
  "args": (v34/*: any*/),
  "concreteType": "JiraTimeTrackingSettings",
  "kind": "LinkedField",
  "name": "globalTimeTrackingSettings",
  "plural": false,
  "selections": [
    (v38/*: any*/),
    (v39/*: any*/),
    (v40/*: any*/),
    (v41/*: any*/),
    (v42/*: any*/)
  ]
},
v44 = [
  {
    "kind": "Variable",
    "name": "projectKey",
    "variableName": "projectKey"
  }
],
v45 = {
  "args": (v34/*: any*/),
  "concreteType": "JiraUserPreferences",
  "kind": "LinkedField",
  "name": "userPreferences",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isIssueViewAttachmentReferenceFlagDismissed"
    },
    {
      "kind": "ScalarField",
      "name": "isIssueViewChildIssuesLimitBestPracticeFlagDismissed"
    },
    {
      "kind": "ScalarField",
      "name": "isIssueViewHideDoneChildIssuesFilterEnabled"
    },
    {
      "kind": "ScalarField",
      "name": "isIssueViewPinnedFieldsBannerDismissed"
    },
    {
      "kind": "ScalarField",
      "name": "issueViewActivityFeedSortOrder"
    },
    {
      "kind": "ScalarField",
      "name": "issueViewAttachmentPanelViewMode"
    },
    {
      "args": (v44/*: any*/),
      "kind": "ScalarField",
      "name": "issueViewPinnedFields"
    },
    {
      "kind": "ScalarField",
      "name": "issueViewPinnedFieldsBannerLastInteracted"
    },
    {
      "kind": "ScalarField",
      "name": "issueViewSidebarResizeRatio"
    },
    {
      "kind": "ScalarField",
      "name": "issueViewTimestampDisplayMode"
    },
    {
      "condition": "isPinFieldConfigurabilityEnabled",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": (v44/*: any*/),
          "kind": "ScalarField",
          "name": "issueViewDefaultPinnedFieldsBannerProject"
        }
      ]
    }
  ]
},
v46 = {
  "kind": "ScalarField",
  "name": "linkTypeId"
},
v47 = {
  "kind": "ScalarField",
  "name": "inwards"
},
v48 = {
  "kind": "ScalarField",
  "name": "outwards"
},
v49 = [
  (v33/*: any*/),
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "issueKey"
  }
],
v50 = {
  "kind": "ScalarField",
  "name": "screenId"
},
v51 = {
  "kind": "ScalarField",
  "name": "errorRetrievingData"
},
v52 = {
  "kind": "ScalarField",
  "name": "issueId"
},
v53 = {
  "kind": "ScalarField",
  "name": "key"
},
v54 = {
  "kind": "ScalarField",
  "name": "level"
},
v55 = {
  "kind": "ScalarField",
  "name": "name"
},
v56 = {
  "kind": "ScalarField",
  "name": "id"
},
v57 = {
  "kind": "ScalarField",
  "name": "issueTypeId"
},
v58 = {
  "kind": "ScalarField",
  "name": "description"
},
v59 = {
  "kind": "ScalarField",
  "name": "xsmall"
},
v60 = {
  "kind": "ScalarField",
  "name": "small"
},
v61 = {
  "kind": "ScalarField",
  "name": "medium"
},
v62 = {
  "kind": "ScalarField",
  "name": "large"
},
v63 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v64 = {
  "kind": "ScalarField",
  "name": "colorKey"
},
v65 = {
  "concreteType": "JiraColor",
  "kind": "LinkedField",
  "name": "color",
  "plural": false,
  "selections": [
    (v64/*: any*/)
  ]
},
v66 = {
  "kind": "ScalarField",
  "name": "guidelines"
},
v67 = {
  "kind": "ScalarField",
  "name": "isEditable"
},
v68 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    (v67/*: any*/)
  ]
},
v69 = {
  "kind": "ScalarField",
  "name": "isEditableInIssueView"
},
v70 = {
  "kind": "ScalarField",
  "name": "isRequired"
},
v71 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        (v70/*: any*/)
      ]
    }
  ],
  "type": "JiraIssueFieldConfiguration",
  "abstractKey": "__isJiraIssueFieldConfiguration"
},
v72 = {
  "kind": "InlineFragment",
  "selections": [
    (v69/*: any*/),
    (v71/*: any*/),
    (v56/*: any*/)
  ],
  "type": "JiraIssueField",
  "abstractKey": "__isJiraIssueField"
},
v73 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v74 = {
  "kind": "ScalarField",
  "name": "type"
},
v75 = {
  "kind": "ScalarField",
  "name": "serviceId"
},
v76 = {
  "kind": "ScalarField",
  "name": "searchUrl"
},
v77 = {
  "kind": "ScalarField",
  "name": "value"
},
v78 = {
  "concreteType": "JiraAssetConnection",
  "kind": "LinkedField",
  "name": "selectedAssetsConnection",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraAssetEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraAsset",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "appKey"
            },
            {
              "kind": "ScalarField",
              "name": "originId"
            },
            {
              "kind": "ScalarField",
              "name": "serializedOrigin"
            },
            (v77/*: any*/)
          ]
        }
      ]
    }
  ]
},
v79 = {
  "kind": "ScalarField",
  "name": "isInsightAvailable"
},
v80 = {
  "kind": "ScalarField",
  "name": "wasInsightRequestSuccessful"
},
v81 = [
  {
    "concreteType": "JiraCmdbConfigAttributeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "node"
      }
    ]
  }
],
v82 = {
  "kind": "ScalarField",
  "name": "objectSchemaId"
},
v83 = {
  "concreteType": "JiraCmdbFieldConfig",
  "kind": "LinkedField",
  "name": "cmdbFieldConfig",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraCmdbConfigAttributeConnection",
      "kind": "LinkedField",
      "name": "attributesDisplayedOnIssue",
      "plural": false,
      "selections": (v81/*: any*/)
    },
    {
      "concreteType": "JiraCmdbConfigAttributeConnection",
      "kind": "LinkedField",
      "name": "attributesIncludedInAutoCompleteSearch",
      "plural": false,
      "selections": (v81/*: any*/)
    },
    (v82/*: any*/),
    {
      "kind": "ScalarField",
      "name": "multiple"
    }
  ]
},
v84 = {
  "kind": "ScalarField",
  "name": "label"
},
v85 = {
  "kind": "ScalarField",
  "name": "objectGlobalId"
},
v86 = {
  "kind": "ScalarField",
  "name": "objectId"
},
v87 = {
  "kind": "ScalarField",
  "name": "objectKey"
},
v88 = {
  "kind": "ScalarField",
  "name": "workspaceId"
},
v89 = {
  "kind": "ScalarField",
  "name": "webUrl"
},
v90 = {
  "kind": "ScalarField",
  "name": "clientId"
},
v91 = {
  "concreteType": "JiraCmdbMediaClientConfig",
  "kind": "LinkedField",
  "name": "mediaClientConfig",
  "plural": false,
  "selections": [
    (v90/*: any*/),
    {
      "kind": "ScalarField",
      "name": "fileId"
    },
    {
      "kind": "ScalarField",
      "name": "issuer"
    },
    {
      "kind": "ScalarField",
      "name": "mediaBaseUrl"
    },
    {
      "kind": "ScalarField",
      "name": "mediaJwtToken"
    }
  ]
},
v92 = {
  "kind": "ScalarField",
  "name": "url48"
},
v93 = {
  "kind": "ScalarField",
  "name": "objectTypeId"
},
v94 = {
  "concreteType": "JiraCmdbIcon",
  "kind": "LinkedField",
  "name": "icon",
  "plural": false,
  "selections": [
    (v56/*: any*/),
    (v55/*: any*/),
    {
      "kind": "ScalarField",
      "name": "url16"
    },
    (v92/*: any*/)
  ]
},
v95 = {
  "condition": "isCMDBAttributeIdRemoved",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "attributeId"
    }
  ]
},
v96 = {
  "kind": "ScalarField",
  "name": "objectTypeAttributeId"
},
v97 = [
  (v56/*: any*/),
  (v55/*: any*/)
],
v98 = {
  "concreteType": "JiraCmdbObjectTypeAttribute",
  "kind": "LinkedField",
  "name": "objectTypeAttribute",
  "plural": false,
  "selections": [
    (v84/*: any*/),
    (v55/*: any*/),
    {
      "concreteType": "JiraCmdbDefaultType",
      "kind": "LinkedField",
      "name": "defaultType",
      "plural": false,
      "selections": (v97/*: any*/)
    },
    (v74/*: any*/)
  ]
},
v99 = {
  "kind": "ScalarField",
  "name": "displayValue"
},
v100 = {
  "kind": "ScalarField",
  "name": "searchValue"
},
v101 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v102 = {
  "kind": "ScalarField",
  "name": "picture"
},
v103 = {
  "concreteType": "JiraCmdbStatusType",
  "kind": "LinkedField",
  "name": "status",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "category"
    },
    (v56/*: any*/),
    (v55/*: any*/)
  ]
},
v104 = [
  (v55/*: any*/)
],
v105 = {
  "concreteType": "JiraProject",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": [
    (v56/*: any*/),
    (v55/*: any*/),
    (v53/*: any*/),
    {
      "concreteType": "JiraAvatar",
      "kind": "LinkedField",
      "name": "avatar",
      "plural": false,
      "selections": [
        (v62/*: any*/)
      ]
    }
  ]
},
v106 = {
  "kind": "ScalarField",
  "name": "url"
},
v107 = {
  "concreteType": "JiraCmdbBitbucketRepository",
  "kind": "LinkedField",
  "name": "bitbucketRepo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "uuid"
    },
    (v55/*: any*/),
    (v106/*: any*/),
    {
      "kind": "ScalarField",
      "name": "avatarUrl"
    }
  ]
},
v108 = {
  "concreteType": "JiraOpsgenieTeam",
  "kind": "LinkedField",
  "name": "opsgenieTeam",
  "plural": false,
  "selections": [
    (v55/*: any*/),
    (v56/*: any*/),
    (v106/*: any*/)
  ]
},
v109 = {
  "concreteType": "JiraCmdbAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    (v91/*: any*/),
    (v92/*: any*/)
  ]
},
v110 = {
  "concreteType": "JiraCmdbObject",
  "kind": "LinkedField",
  "name": "referencedObject",
  "plural": false,
  "selections": [
    (v85/*: any*/),
    (v88/*: any*/),
    (v87/*: any*/),
    (v84/*: any*/),
    (v109/*: any*/),
    (v86/*: any*/)
  ]
},
v111 = {
  "kind": "ScalarField",
  "name": "optionId"
},
v112 = {
  "kind": "ScalarField",
  "name": "isDisabled"
},
v113 = [
  (v56/*: any*/),
  (v111/*: any*/),
  (v77/*: any*/),
  (v112/*: any*/)
],
v114 = {
  "concreteType": "JiraOption",
  "kind": "LinkedField",
  "name": "parentOptionValue",
  "plural": false,
  "selections": (v113/*: any*/)
},
v115 = {
  "concreteType": "JiraCascadingOption",
  "kind": "LinkedField",
  "name": "cascadingOption",
  "plural": false,
  "selections": [
    (v114/*: any*/),
    {
      "concreteType": "JiraOption",
      "kind": "LinkedField",
      "name": "childOptionValue",
      "plural": false,
      "selections": (v113/*: any*/)
    }
  ]
},
v116 = {
  "condition": "issueViewRelayCascadingSelectFlag",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "concreteType": "JiraCascadingOptionsConnection",
      "kind": "LinkedField",
      "name": "cascadingOptions",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraCascadingOptionsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraCascadingOptions",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v114/*: any*/),
                {
                  "concreteType": "JiraOption",
                  "kind": "LinkedField",
                  "name": "childOptionValues",
                  "plural": true,
                  "selections": (v113/*: any*/)
                }
              ]
            }
          ]
        }
      ]
    }
  ]
},
v117 = [
  {
    "concreteType": "JiraOptionEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "JiraOption",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v113/*: any*/)
      }
    ]
  }
],
v118 = {
  "concreteType": "JiraOptionConnection",
  "kind": "LinkedField",
  "name": "selectedOptions",
  "plural": false,
  "selections": (v117/*: any*/)
},
v119 = {
  "alias": "checkboxesFieldOptions",
  "concreteType": "JiraOptionConnection",
  "kind": "LinkedField",
  "name": "fieldOptions",
  "plural": false,
  "selections": (v117/*: any*/)
},
v120 = {
  "concreteType": "JiraColor",
  "kind": "LinkedField",
  "name": "color",
  "plural": false,
  "selections": [
    (v56/*: any*/),
    (v64/*: any*/)
  ]
},
v121 = {
  "kind": "ScalarField",
  "name": "componentId"
},
v122 = {
  "kind": "ScalarField",
  "name": "ari"
},
v123 = {
  "kind": "ScalarField",
  "name": "metadata"
},
v124 = [
  {
    "concreteType": "JiraComponentEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "JiraComponent",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v56/*: any*/),
          (v121/*: any*/),
          (v55/*: any*/),
          (v58/*: any*/),
          {
            "condition": "isCompassComponentsEnabled",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              (v122/*: any*/),
              (v123/*: any*/)
            ]
          }
        ]
      }
    ]
  }
],
v125 = {
  "concreteType": "JiraComponentConnection",
  "kind": "LinkedField",
  "name": "selectedComponentsConnection",
  "plural": false,
  "selections": (v124/*: any*/)
},
v126 = {
  "concreteType": "JiraComponentConnection",
  "kind": "LinkedField",
  "name": "components",
  "plural": false,
  "selections": (v124/*: any*/)
},
v127 = {
  "kind": "ScalarField",
  "name": "dateTime"
},
v128 = [
  (v72/*: any*/),
  (v73/*: any*/),
  (v55/*: any*/),
  (v74/*: any*/),
  (v58/*: any*/),
  (v127/*: any*/)
],
v129 = {
  "alias": "connectMultiSelectFieldOptions",
  "concreteType": "JiraOptionConnection",
  "kind": "LinkedField",
  "name": "fieldOptions",
  "plural": false,
  "selections": (v117/*: any*/)
},
v130 = {
  "kind": "ScalarField",
  "name": "number"
},
v131 = {
  "concreteType": "JiraADF",
  "kind": "LinkedField",
  "name": "adfValue",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "json"
    }
  ]
},
v132 = {
  "concreteType": "JiraRichText",
  "kind": "LinkedField",
  "name": "richText",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "plainText"
    },
    (v131/*: any*/)
  ]
},
v133 = {
  "kind": "ScalarField",
  "name": "renderer"
},
v134 = [
  (v72/*: any*/),
  (v73/*: any*/),
  (v55/*: any*/),
  (v74/*: any*/),
  (v58/*: any*/),
  (v132/*: any*/),
  (v133/*: any*/)
],
v135 = {
  "concreteType": "JiraOption",
  "kind": "LinkedField",
  "name": "fieldOption",
  "plural": false,
  "selections": (v113/*: any*/)
},
v136 = {
  "alias": "connectSingleSelectFieldOptions",
  "concreteType": "JiraOptionConnection",
  "kind": "LinkedField",
  "name": "fieldOptions",
  "plural": false,
  "selections": (v117/*: any*/)
},
v137 = {
  "kind": "ScalarField",
  "name": "text"
},
v138 = [
  (v72/*: any*/),
  (v73/*: any*/),
  (v55/*: any*/),
  (v74/*: any*/),
  (v58/*: any*/),
  (v137/*: any*/)
],
v139 = {
  "kind": "ScalarField",
  "name": "date"
},
v140 = {
  "kind": "ScalarField",
  "name": "color"
},
v141 = {
  "concreteType": "JiraEpic",
  "kind": "LinkedField",
  "name": "epic",
  "plural": false,
  "selections": [
    (v56/*: any*/),
    (v52/*: any*/),
    (v55/*: any*/),
    (v53/*: any*/),
    {
      "kind": "ScalarField",
      "name": "summary"
    },
    (v140/*: any*/),
    {
      "kind": "ScalarField",
      "name": "done"
    }
  ]
},
v142 = {
  "concreteType": "JiraFlag",
  "kind": "LinkedField",
  "name": "flag",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isFlagged"
    }
  ]
},
v143 = {
  "kind": "ScalarField",
  "name": "groupId"
},
v144 = [
  (v56/*: any*/),
  (v143/*: any*/),
  (v55/*: any*/)
],
v145 = {
  "concreteType": "JiraGroup",
  "kind": "LinkedField",
  "name": "selectedGroup",
  "plural": false,
  "selections": (v144/*: any*/)
},
v146 = {
  "concreteType": "JiraGroupConnection",
  "kind": "LinkedField",
  "name": "selectedGroupsConnection",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraGroupEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraGroup",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v144/*: any*/)
        }
      ]
    }
  ]
},
v147 = {
  "kind": "ScalarField",
  "name": "object"
},
v148 = {
  "concreteType": "JiraLabelConnection",
  "kind": "LinkedField",
  "name": "selectedLabelsConnection",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraLabelEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraLabel",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "labelId"
            },
            (v55/*: any*/)
          ]
        }
      ]
    }
  ]
},
v149 = {
  "kind": "ScalarField",
  "name": "accountStatus"
},
v150 = {
  "kind": "ScalarField",
  "name": "email"
},
v151 = {
  "kind": "ScalarField",
  "name": "zoneinfo"
},
v152 = {
  "kind": "ScalarField",
  "name": "locale"
},
v153 = [
  (v150/*: any*/),
  (v151/*: any*/),
  (v152/*: any*/)
],
v154 = {
  "kind": "InlineFragment",
  "selections": (v153/*: any*/),
  "type": "AtlassianAccountUser"
},
v155 = {
  "kind": "InlineFragment",
  "selections": (v153/*: any*/),
  "type": "CustomerUser"
},
v156 = [
  (v63/*: any*/),
  (v101/*: any*/),
  (v149/*: any*/),
  (v55/*: any*/),
  (v102/*: any*/),
  (v154/*: any*/),
  (v155/*: any*/)
],
v157 = {
  "concreteType": "JiraUserConnection",
  "kind": "LinkedField",
  "name": "selectedUsersConnection",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraUserEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v156/*: any*/)
        }
      ]
    }
  ]
},
v158 = {
  "concreteType": "JiraRoleConnection",
  "kind": "LinkedField",
  "name": "selectedRolesConnection",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraRoleEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraRole",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v56/*: any*/),
            {
              "kind": "ScalarField",
              "name": "roleId"
            }
          ]
        }
      ]
    }
  ]
},
v159 = [
  (v72/*: any*/),
  (v73/*: any*/),
  (v55/*: any*/),
  (v74/*: any*/),
  (v58/*: any*/),
  (v157/*: any*/),
  (v76/*: any*/)
],
v160 = {
  "kind": "ScalarField",
  "name": "versionId"
},
v161 = {
  "kind": "ScalarField",
  "name": "status"
},
v162 = [
  {
    "concreteType": "JiraVersionEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "JiraVersion",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v56/*: any*/),
          (v160/*: any*/),
          (v55/*: any*/),
          (v161/*: any*/),
          (v58/*: any*/)
        ]
      }
    ]
  }
],
v163 = {
  "concreteType": "JiraVersionConnection",
  "kind": "LinkedField",
  "name": "selectedVersionsConnection",
  "plural": false,
  "selections": (v162/*: any*/)
},
v164 = {
  "condition": "isRelayReleaseVersionFieldIssueViewIntegrationEnabled",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "alias": "multiPickerVersions",
      "concreteType": "JiraVersionConnection",
      "kind": "LinkedField",
      "name": "versions",
      "plural": false,
      "selections": (v162/*: any*/)
    }
  ]
},
v165 = {
  "kind": "ScalarField",
  "name": "isStoryPointField"
},
v166 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    (v70/*: any*/),
    (v67/*: any*/)
  ]
},
v167 = {
  "kind": "InlineFragment",
  "selections": [
    (v166/*: any*/)
  ],
  "type": "JiraIssueFieldConfiguration",
  "abstractKey": "__isJiraIssueFieldConfiguration"
},
v168 = [
  {
    "kind": "ScalarField",
    "name": "timeInSeconds"
  }
],
v169 = {
  "concreteType": "JiraEstimate",
  "kind": "LinkedField",
  "name": "originalEstimate",
  "plural": false,
  "selections": (v168/*: any*/)
},
v170 = {
  "concreteType": "JiraParentVisibility",
  "kind": "LinkedField",
  "name": "parentVisibility",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "hasEpicLinkFieldDependency"
    },
    {
      "kind": "ScalarField",
      "name": "canUseParentLinkField"
    }
  ]
},
v171 = [
  {
    "kind": "Literal",
    "name": "ids",
    "value": [
      "issuetype",
      "project",
      "summary"
    ]
  }
],
v172 = {
  "kind": "ScalarField",
  "name": "projectId"
},
v173 = {
  "kind": "ScalarField",
  "name": "canSetIssueRestriction"
},
v174 = {
  "kind": "ScalarField",
  "name": "projectStyle"
},
v175 = {
  "kind": "ScalarField",
  "name": "projectType"
},
v176 = {
  "concreteType": "JiraSimilarIssues",
  "kind": "LinkedField",
  "name": "similarIssues",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "featureEnabled"
    }
  ]
},
v177 = {
  "kind": "ScalarField",
  "name": "boardId"
},
v178 = {
  "kind": "LinkedField",
  "name": "navigationMetadata",
  "plural": false,
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v177/*: any*/)
      ],
      "type": "JiraSoftwareProjectNavigationMetadata"
    }
  ]
},
v179 = {
  "kind": "InlineFragment",
  "selections": [
    (v73/*: any*/),
    (v55/*: any*/),
    (v74/*: any*/),
    (v58/*: any*/),
    (v137/*: any*/)
  ],
  "type": "JiraSingleLineTextField"
},
v180 = {
  "kind": "ScalarField",
  "name": "isMulti"
},
v181 = {
  "kind": "ScalarField",
  "name": "priorityId"
},
v182 = {
  "kind": "ScalarField",
  "name": "iconUrl"
},
v183 = {
  "kind": "ScalarField",
  "name": "sequence"
},
v184 = [
  (v181/*: any*/),
  (v55/*: any*/),
  (v182/*: any*/),
  (v183/*: any*/),
  (v140/*: any*/)
],
v185 = {
  "concreteType": "JiraProformaForms",
  "kind": "LinkedField",
  "name": "proformaForms",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "hasProjectForms"
    },
    {
      "kind": "ScalarField",
      "name": "hasIssueForms"
    },
    {
      "kind": "ScalarField",
      "name": "isHarmonisationEnabled"
    }
  ]
},
v186 = {
  "concreteType": "JiraOption",
  "kind": "LinkedField",
  "name": "selectedOption",
  "plural": false,
  "selections": (v113/*: any*/)
},
v187 = {
  "alias": "radioSelectFieldOptions",
  "concreteType": "JiraOptionConnection",
  "kind": "LinkedField",
  "name": "fieldOptions",
  "plural": false,
  "selections": (v117/*: any*/)
},
v188 = {
  "kind": "ScalarField",
  "name": "resolutionId"
},
v189 = [
  (v56/*: any*/),
  (v188/*: any*/),
  (v55/*: any*/)
],
v190 = {
  "concreteType": "JiraResolution",
  "kind": "LinkedField",
  "name": "resolution",
  "plural": false,
  "selections": (v189/*: any*/)
},
v191 = {
  "concreteType": "JiraResolutionConnection",
  "kind": "LinkedField",
  "name": "resolutions",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraResolutionEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraResolution",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v189/*: any*/)
        }
      ]
    }
  ]
},
v192 = {
  "kind": "ScalarField",
  "name": "securityId"
},
v193 = [
  (v55/*: any*/),
  (v192/*: any*/),
  (v58/*: any*/)
],
v194 = {
  "kind": "ScalarField",
  "name": "approvalState"
},
v195 = {
  "kind": "ScalarField",
  "name": "categoryId"
},
v196 = {
  "concreteType": "JiraServiceManagementApprovalStatus",
  "kind": "LinkedField",
  "name": "approvedStatus",
  "plural": false,
  "selections": [
    (v195/*: any*/),
    (v55/*: any*/)
  ]
},
v197 = {
  "kind": "ScalarField",
  "name": "jiraRest"
},
v198 = {
  "kind": "ScalarField",
  "name": "memberCount"
},
v199 = {
  "kind": "ScalarField",
  "name": "approvedCount"
},
v200 = [
  (v150/*: any*/),
  (v151/*: any*/)
],
v201 = {
  "kind": "InlineFragment",
  "selections": (v200/*: any*/),
  "type": "AtlassianAccountUser"
},
v202 = {
  "kind": "InlineFragment",
  "selections": (v200/*: any*/),
  "type": "CustomerUser"
},
v203 = {
  "kind": "ScalarField",
  "name": "approverDecision"
},
v204 = {
  "kind": "ScalarField",
  "name": "canAnswerApproval"
},
v205 = {
  "kind": "ScalarField",
  "name": "fieldName"
},
v206 = {
  "kind": "ScalarField",
  "name": "createdDate"
},
v207 = {
  "kind": "ScalarField",
  "name": "finalDecision"
},
v208 = {
  "kind": "ScalarField",
  "name": "pendingApprovalCount"
},
v209 = {
  "concreteType": "JiraServiceManagementApprovalStatus",
  "kind": "LinkedField",
  "name": "status",
  "plural": false,
  "selections": [
    (v56/*: any*/),
    (v55/*: any*/),
    (v195/*: any*/)
  ]
},
v210 = {
  "kind": "ScalarField",
  "name": "completedDate"
},
v211 = {
  "concreteType": "JiraServiceManagementApprovalStatus",
  "kind": "LinkedField",
  "name": "status",
  "plural": false,
  "selections": [
    (v55/*: any*/),
    (v195/*: any*/)
  ]
},
v212 = {
  "kind": "ScalarField",
  "name": "majorIncident"
},
v213 = {
  "kind": "ScalarField",
  "name": "organizationId"
},
v214 = {
  "kind": "ScalarField",
  "name": "organizationName"
},
v215 = {
  "kind": "ScalarField",
  "name": "domain"
},
v216 = {
  "concreteType": "JiraServiceManagementFeedback",
  "kind": "LinkedField",
  "name": "feedback",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "rating"
    }
  ]
},
v217 = [
  {
    "kind": "ScalarField",
    "name": "languageCode"
  },
  {
    "kind": "ScalarField",
    "name": "displayName"
  }
],
v218 = {
  "concreteType": "JiraServiceManagementLanguage",
  "kind": "LinkedField",
  "name": "language",
  "plural": false,
  "selections": (v217/*: any*/)
},
v219 = {
  "concreteType": "JiraServiceManagementLanguage",
  "kind": "LinkedField",
  "name": "languages",
  "plural": true,
  "selections": (v217/*: any*/)
},
v220 = [
  (v59/*: any*/),
  (v60/*: any*/),
  (v61/*: any*/),
  (v62/*: any*/)
],
v221 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": (v220/*: any*/)
},
v222 = {
  "concreteType": "JiraServiceManagementRequestType",
  "kind": "LinkedField",
  "name": "requestType",
  "plural": false,
  "selections": [
    (v56/*: any*/),
    {
      "kind": "ScalarField",
      "name": "requestTypeId"
    },
    (v55/*: any*/),
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": [
        (v56/*: any*/),
        (v57/*: any*/)
      ]
    },
    (v221/*: any*/)
  ]
},
v223 = {
  "kind": "ScalarField",
  "name": "canonicalAccountId"
},
v224 = {
  "kind": "ScalarField",
  "name": "teamId"
},
v225 = {
  "kind": "InlineFragment",
  "selections": [
    (v224/*: any*/),
    {
      "kind": "ScalarField",
      "name": "teamName"
    }
  ],
  "type": "JiraServiceManagementTeamResponder"
},
v226 = [
  (v151/*: any*/),
  (v152/*: any*/)
],
v227 = {
  "kind": "InlineFragment",
  "selections": (v226/*: any*/),
  "type": "AtlassianAccountUser"
},
v228 = {
  "kind": "InlineFragment",
  "selections": (v226/*: any*/),
  "type": "CustomerUser"
},
v229 = {
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v63/*: any*/),
    (v101/*: any*/),
    (v149/*: any*/),
    (v55/*: any*/),
    (v102/*: any*/),
    (v227/*: any*/),
    (v228/*: any*/)
  ]
},
v230 = {
  "kind": "ScalarField",
  "name": "startDate"
},
v231 = [
  (v56/*: any*/),
  (v160/*: any*/),
  (v55/*: any*/),
  (v182/*: any*/),
  (v161/*: any*/),
  (v58/*: any*/),
  (v230/*: any*/),
  {
    "kind": "ScalarField",
    "name": "releaseDate"
  }
],
v232 = {
  "concreteType": "JiraVersion",
  "kind": "LinkedField",
  "name": "version",
  "plural": false,
  "selections": (v231/*: any*/)
},
v233 = {
  "alias": "singlePickerVersions",
  "concreteType": "JiraVersionConnection",
  "kind": "LinkedField",
  "name": "versions",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraVersionEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraVersion",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v231/*: any*/)
        }
      ]
    }
  ]
},
v234 = {
  "kind": "ScalarField",
  "name": "sprintId"
},
v235 = {
  "kind": "ScalarField",
  "name": "state"
},
v236 = {
  "kind": "ScalarField",
  "name": "boardName"
},
v237 = {
  "kind": "ScalarField",
  "name": "endDate"
},
v238 = {
  "kind": "ScalarField",
  "name": "completionDate"
},
v239 = {
  "kind": "ScalarField",
  "name": "goal"
},
v240 = {
  "kind": "ScalarField",
  "name": "statusId"
},
v241 = {
  "kind": "ScalarField",
  "name": "statusCategoryId"
},
v242 = {
  "concreteType": "JiraStatusCategory",
  "kind": "LinkedField",
  "name": "statusCategory",
  "plural": false,
  "selections": [
    (v56/*: any*/),
    (v241/*: any*/)
  ]
},
v243 = {
  "kind": "ScalarField",
  "name": "isShared"
},
v244 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    (v61/*: any*/)
  ]
},
v245 = {
  "concreteType": "JiraTeamView",
  "kind": "LinkedField",
  "name": "selectedTeam",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "jiraSuppliedVisibility"
    },
    {
      "kind": "ScalarField",
      "name": "jiraSuppliedName"
    },
    {
      "kind": "ScalarField",
      "name": "jiraSuppliedId"
    },
    {
      "kind": "ScalarField",
      "name": "jiraSuppliedTeamId"
    },
    {
      "concreteType": "JiraAvatar",
      "kind": "LinkedField",
      "name": "jiraSuppliedAvatar",
      "plural": false,
      "selections": (v220/*: any*/)
    }
  ]
},
v246 = {
  "concreteType": "JiraEstimate",
  "kind": "LinkedField",
  "name": "remainingEstimate",
  "plural": false,
  "selections": (v168/*: any*/)
},
v247 = {
  "concreteType": "JiraEstimate",
  "kind": "LinkedField",
  "name": "timeSpent",
  "plural": false,
  "selections": (v168/*: any*/)
},
v248 = {
  "kind": "ScalarField",
  "name": "uri"
},
v249 = {
  "kind": "ScalarField",
  "name": "count"
},
v250 = {
  "concreteType": "JiraVote",
  "kind": "LinkedField",
  "name": "vote",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "hasVoted"
    },
    (v249/*: any*/)
  ]
},
v251 = {
  "kind": "ScalarField",
  "name": "isWatching"
},
v252 = {
  "concreteType": "JiraWatch",
  "kind": "LinkedField",
  "name": "watch",
  "plural": false,
  "selections": [
    (v251/*: any*/),
    (v249/*: any*/)
  ]
},
v253 = [
  (v56/*: any*/)
],
v254 = {
  "concreteType": "JiraServiceManagementEntitlement",
  "kind": "LinkedField",
  "name": "selectedEntitlement",
  "plural": false,
  "selections": [
    (v56/*: any*/),
    {
      "concreteType": "JiraServiceManagementProduct",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": (v97/*: any*/)
    },
    {
      "kind": "LinkedField",
      "name": "entity",
      "plural": false,
      "selections": [
        (v63/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": (v253/*: any*/),
          "type": "JiraServiceManagementEntitlementCustomer"
        },
        {
          "kind": "InlineFragment",
          "selections": (v253/*: any*/),
          "type": "JiraServiceManagementEntitlementOrganization"
        }
      ]
    }
  ]
},
v255 = {
  "kind": "InlineFragment",
  "selections": [
    (v69/*: any*/),
    (v71/*: any*/)
  ],
  "type": "JiraIssueField",
  "abstractKey": "__isJiraIssueField"
},
v256 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v257 = {
  "concreteType": "JiraServiceManagementSentiment",
  "kind": "LinkedField",
  "name": "sentiment",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "sentimentId"
    },
    (v55/*: any*/)
  ]
},
v258 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v259 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v260 = {
  "condition": "issueViewRelayGoalsFlag",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "args": (v258/*: any*/),
      "concreteType": "JiraGoalConnection",
      "kind": "LinkedField",
      "name": "selectedGoals",
      "plural": false,
      "selections": [
        (v259/*: any*/),
        {
          "concreteType": "JiraGoalEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraGoal",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v56/*: any*/),
                (v55/*: any*/),
                (v161/*: any*/)
              ]
            }
          ]
        }
      ],
      "storageKey": "selectedGoals(first:1)"
    }
  ]
},
v261 = [
  {
    "kind": "Literal",
    "name": "activeProjectsOnly",
    "value": true
  }
],
v262 = {
  "kind": "ScalarField",
  "name": "isResolved"
},
v263 = [
  (v63/*: any*/),
  (v73/*: any*/),
  (v74/*: any*/),
  (v130/*: any*/),
  (v165/*: any*/)
],
v264 = [
  {
    "kind": "Literal",
    "name": "ids",
    "value": [
      "assignee",
      "created",
      "issuetype",
      "priority",
      "status",
      "summary",
      "timetracking",
      "updated"
    ]
  }
],
v265 = {
  "kind": "InlineFragment",
  "selections": [
    (v73/*: any*/),
    (v74/*: any*/),
    (v137/*: any*/)
  ],
  "type": "JiraSingleLineTextField"
},
v266 = {
  "kind": "InlineFragment",
  "selections": [
    (v73/*: any*/),
    (v74/*: any*/),
    (v169/*: any*/),
    (v246/*: any*/),
    (v247/*: any*/)
  ],
  "type": "JiraTimeTrackingField"
},
v267 = {
  "kind": "InlineFragment",
  "selections": [
    (v73/*: any*/),
    (v74/*: any*/),
    (v127/*: any*/)
  ],
  "type": "JiraDateTimePickerField"
},
v268 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "search"
    }
  ],
  "type": "JiraChildIssuesExceedingLimit"
},
v269 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v270 = {
  "kind": "ScalarField",
  "name": "issueLinkId"
},
v271 = {
  "kind": "ScalarField",
  "name": "direction"
},
v272 = {
  "kind": "ScalarField",
  "name": "relationName"
},
v273 = [
  {
    "kind": "Literal",
    "name": "ids",
    "value": [
      "assignee",
      "issuetype",
      "priority",
      "status",
      "summary",
      "project"
    ]
  }
],
v274 = {
  "kind": "ScalarField",
  "name": "colorName"
},
v275 = {
  "kind": "InlineFragment",
  "selections": [
    (v73/*: any*/),
    (v55/*: any*/),
    (v74/*: any*/),
    (v137/*: any*/)
  ],
  "type": "JiraSingleLineTextField"
},
v276 = {
  "kind": "ScalarField",
  "name": "endpointUrl"
},
v277 = {
  "kind": "ScalarField",
  "name": "token"
},
v278 = {
  "args": [
    {
      "kind": "Literal",
      "name": "maxTokenLength",
      "value": 65536
    }
  ],
  "concreteType": "JiraMediaReadToken",
  "kind": "LinkedField",
  "name": "mediaReadToken",
  "plural": false,
  "selections": [
    (v90/*: any*/),
    (v276/*: any*/),
    {
      "kind": "ScalarField",
      "name": "tokenLifespanInSeconds"
    },
    {
      "concreteType": "JiraMediaTokenWithFilesConnection",
      "kind": "LinkedField",
      "name": "tokensWithFiles",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraMediaTokenWithFilesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraMediaTokenWithFiles",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v277/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ],
  "storageKey": "mediaReadToken(maxTokenLength:65536)"
},
v279 = {
  "kind": "InlineFragment",
  "selections": [
    (v276/*: any*/),
    (v90/*: any*/),
    {
      "kind": "ScalarField",
      "name": "targetCollection"
    },
    (v277/*: any*/),
    {
      "kind": "ScalarField",
      "name": "tokenDurationInMin"
    }
  ],
  "type": "JiraMediaUploadToken"
},
v280 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": "errorId",
      "kind": "ScalarField",
      "name": "identifier"
    },
    {
      "alias": "errorMessage",
      "kind": "ScalarField",
      "name": "message"
    }
  ],
  "type": "QueryError"
},
v281 = {
  "condition": "giraAGGMigrationIsArchivedFlag",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isArchived"
    }
  ]
},
v282 = {
  "kind": "Literal",
  "name": "sortBy",
  "value": {
    "field": "CREATED",
    "order": "DESC"
  }
},
v283 = {
  "kind": "ScalarField",
  "name": "attachmentId"
},
v284 = {
  "kind": "ScalarField",
  "name": "fileName"
},
v285 = {
  "kind": "ScalarField",
  "name": "mimeType"
},
v286 = {
  "kind": "ScalarField",
  "name": "mediaApiFileId"
},
v287 = {
  "kind": "ScalarField",
  "name": "created"
},
v288 = {
  "kind": "ScalarField",
  "name": "fileSize"
},
v289 = {
  "kind": "ScalarField",
  "name": "parentName"
},
v290 = {
  "kind": "ScalarField",
  "name": "parentId"
},
v291 = {
  "kind": "ScalarField",
  "name": "hasRestrictedParent"
},
v292 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v293 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "endCursor"
    },
    {
      "kind": "ScalarField",
      "name": "hasNextPage"
    }
  ]
},
v294 = [
  {
    "kind": "Literal",
    "name": "afterTarget",
    "value": 5
  },
  {
    "kind": "Literal",
    "name": "beforeTarget",
    "value": 5
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 5
  },
  (v282/*: any*/),
  {
    "kind": "Variable",
    "name": "targetId",
    "variableName": "commentTargetId"
  }
],
v295 = {
  "kind": "ScalarField",
  "name": "commentId"
},
v296 = {
  "kind": "ScalarField",
  "name": "updated"
},
v297 = [
  (v55/*: any*/),
  (v101/*: any*/),
  (v102/*: any*/)
],
v298 = {
  "concreteType": "JiraRichText",
  "kind": "LinkedField",
  "name": "richText",
  "plural": false,
  "selections": [
    (v131/*: any*/)
  ]
},
v299 = [
  (v56/*: any*/),
  (v55/*: any*/),
  (v143/*: any*/)
],
v300 = {
  "concreteType": "JiraPermissionLevel",
  "kind": "LinkedField",
  "name": "permissionLevel",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraGroup",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": (v299/*: any*/)
    },
    {
      "concreteType": "JiraRole",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": (v97/*: any*/)
    }
  ]
},
v301 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "authorCanSeeRequest"
    },
    {
      "kind": "ScalarField",
      "name": "eventOccurredAt"
    },
    {
      "kind": "ScalarField",
      "name": "visibility"
    },
    {
      "kind": "ScalarField",
      "name": "jsdIncidentActivityViewHidden"
    }
  ],
  "type": "JiraServiceManagementComment"
},
v302 = {
  "condition": "isJPDDeliveryLinkTypeFilteringEnabled",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "args": (v34/*: any*/),
      "kind": "ScalarField",
      "name": "jpdDeliveryIssueLinkTypeId"
    }
  ]
},
v303 = [
  (v54/*: any*/),
  (v55/*: any*/)
],
v304 = {
  "concreteType": "JiraIssueTypeHierarchyLevel",
  "kind": "LinkedField",
  "name": "hierarchy",
  "plural": false,
  "selections": (v303/*: any*/)
},
v305 = [
  (v56/*: any*/),
  (v57/*: any*/),
  (v55/*: any*/),
  (v58/*: any*/),
  (v221/*: any*/),
  (v304/*: any*/)
],
v306 = [
  {
    "concreteType": "JiraIssueTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "JiraIssueType",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v305/*: any*/)
      }
    ]
  }
],
v307 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    (v67/*: any*/),
    (v70/*: any*/)
  ]
},
v308 = {
  "concreteType": "JiraIssue",
  "kind": "LinkedField",
  "name": "issue",
  "plural": false,
  "selections": [
    (v53/*: any*/),
    (v56/*: any*/)
  ]
},
v309 = {
  "kind": "InlineFragment",
  "selections": [
    (v150/*: any*/)
  ],
  "type": "AtlassianAccountUser"
},
v310 = [
  (v58/*: any*/)
],
v311 = {
  "kind": "InlineFragment",
  "selections": (v310/*: any*/),
  "type": "JiraIssueField",
  "abstractKey": "__isJiraIssueField"
},
v312 = [
  (v55/*: any*/),
  {
    "concreteType": "JiraUserConnection",
    "kind": "LinkedField",
    "name": "selectedUsersConnection",
    "plural": false,
    "selections": [
      {
        "concreteType": "JiraUserEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v63/*: any*/),
              (v101/*: any*/),
              (v56/*: any*/),
              (v149/*: any*/),
              (v55/*: any*/),
              (v102/*: any*/),
              (v309/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  (v68/*: any*/),
  (v311/*: any*/)
],
v313 = [
  {
    "concreteType": "JiraComponentEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "JiraComponent",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v56/*: any*/),
          (v121/*: any*/),
          (v55/*: any*/),
          (v58/*: any*/),
          (v122/*: any*/),
          (v123/*: any*/)
        ]
      }
    ]
  }
],
v314 = [
  (v55/*: any*/),
  {
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      (v63/*: any*/),
      (v55/*: any*/),
      (v102/*: any*/),
      (v149/*: any*/),
      (v56/*: any*/),
      (v101/*: any*/)
    ]
  },
  (v166/*: any*/),
  (v311/*: any*/)
],
v315 = [
  (v77/*: any*/),
  (v56/*: any*/),
  (v112/*: any*/)
],
v316 = {
  "concreteType": "JiraColor",
  "kind": "LinkedField",
  "name": "color",
  "plural": false,
  "selections": [
    (v64/*: any*/),
    (v56/*: any*/)
  ]
},
v317 = {
  "concreteType": "JiraColorField",
  "kind": "LinkedField",
  "name": "issueColorField",
  "plural": false,
  "selections": [
    (v316/*: any*/),
    (v56/*: any*/)
  ]
},
v318 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    (v59/*: any*/)
  ]
},
v319 = {
  "concreteType": "JiraSingleLineTextField",
  "kind": "LinkedField",
  "name": "summaryField",
  "plural": false,
  "selections": [
    (v137/*: any*/),
    (v56/*: any*/)
  ]
},
v320 = {
  "concreteType": "JiraIssueTypeHierarchyLevel",
  "kind": "LinkedField",
  "name": "hierarchy",
  "plural": false,
  "selections": [
    (v54/*: any*/)
  ]
},
v321 = {
  "concreteType": "JiraStatusCategory",
  "kind": "LinkedField",
  "name": "statusCategory",
  "plural": false,
  "selections": [
    (v241/*: any*/),
    (v56/*: any*/)
  ]
},
v322 = {
  "concreteType": "JiraStatus",
  "kind": "LinkedField",
  "name": "status",
  "plural": false,
  "selections": [
    (v321/*: any*/),
    (v56/*: any*/)
  ]
},
v323 = [
  (v63/*: any*/),
  (v101/*: any*/),
  (v56/*: any*/)
],
v324 = [
  {
    "concreteType": "JiraIssueFieldEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v63/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": (v323/*: any*/)
              }
            ],
            "type": "JiraSingleSelectUserPickerField"
          },
          (v56/*: any*/)
        ]
      }
    ]
  }
],
v325 = {
  "kind": "ScalarField",
  "name": "definition"
},
v326 = [
  (v56/*: any*/),
  (v55/*: any*/),
  (v316/*: any*/),
  (v66/*: any*/),
  (v325/*: any*/)
],
v327 = {
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v63/*: any*/),
    (v101/*: any*/),
    (v55/*: any*/),
    (v102/*: any*/),
    (v56/*: any*/)
  ]
},
v328 = [
  (v73/*: any*/),
  (v55/*: any*/),
  (v74/*: any*/),
  (v58/*: any*/),
  (v127/*: any*/),
  (v255/*: any*/)
],
v329 = [
  (v73/*: any*/),
  (v55/*: any*/),
  (v74/*: any*/),
  (v58/*: any*/),
  (v132/*: any*/),
  (v133/*: any*/),
  (v255/*: any*/)
],
v330 = [
  (v73/*: any*/),
  (v55/*: any*/),
  (v74/*: any*/),
  (v58/*: any*/),
  (v137/*: any*/),
  (v255/*: any*/)
],
v331 = [
  (v63/*: any*/),
  (v101/*: any*/),
  (v149/*: any*/),
  (v55/*: any*/),
  (v102/*: any*/),
  (v154/*: any*/),
  (v155/*: any*/),
  (v56/*: any*/)
],
v332 = {
  "concreteType": "JiraUserConnection",
  "kind": "LinkedField",
  "name": "selectedUsersConnection",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraUserEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v331/*: any*/)
        }
      ]
    }
  ]
},
v333 = {
  "concreteType": "JiraIssueType",
  "kind": "LinkedField",
  "name": "issueType",
  "plural": false,
  "selections": (v305/*: any*/)
},
v334 = [
  (v73/*: any*/),
  (v55/*: any*/),
  (v74/*: any*/),
  (v58/*: any*/),
  (v332/*: any*/),
  (v76/*: any*/),
  (v255/*: any*/)
],
v335 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    (v61/*: any*/),
    (v62/*: any*/)
  ]
},
v336 = {
  "kind": "LinkedField",
  "name": "navigationMetadata",
  "plural": false,
  "selections": [
    (v63/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        (v177/*: any*/),
        (v56/*: any*/)
      ],
      "type": "JiraSoftwareProjectNavigationMetadata"
    }
  ]
},
v337 = {
  "concreteType": "JiraProject",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": [
    (v53/*: any*/),
    (v55/*: any*/),
    (v172/*: any*/),
    (v173/*: any*/),
    (v174/*: any*/),
    (v175/*: any*/),
    (v161/*: any*/),
    (v335/*: any*/),
    (v176/*: any*/),
    (v336/*: any*/),
    (v56/*: any*/)
  ]
},
v338 = [
  (v181/*: any*/),
  (v55/*: any*/),
  (v182/*: any*/),
  (v183/*: any*/),
  (v140/*: any*/),
  (v56/*: any*/)
],
v339 = [
  (v55/*: any*/),
  (v192/*: any*/),
  (v58/*: any*/),
  (v56/*: any*/)
],
v340 = [
  (v63/*: any*/),
  (v101/*: any*/),
  (v149/*: any*/),
  (v55/*: any*/),
  (v102/*: any*/),
  (v201/*: any*/),
  (v202/*: any*/),
  (v56/*: any*/)
],
v341 = [
  {
    "kind": "LinkedField",
    "name": "approver",
    "plural": false,
    "selections": (v340/*: any*/)
  },
  (v203/*: any*/)
],
v342 = {
  "concreteType": "JiraServiceManagementApproverConnection",
  "kind": "LinkedField",
  "name": "approvers",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraServiceManagementApproverEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraServiceManagementApprover",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v341/*: any*/)
        }
      ]
    }
  ]
},
v343 = {
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v63/*: any*/),
    (v101/*: any*/),
    (v149/*: any*/),
    (v55/*: any*/),
    (v102/*: any*/),
    (v227/*: any*/),
    (v228/*: any*/),
    (v56/*: any*/)
  ]
},
v344 = [
  (v63/*: any*/),
  (v73/*: any*/),
  (v74/*: any*/),
  (v130/*: any*/),
  (v165/*: any*/),
  (v56/*: any*/)
],
v345 = [
  (v127/*: any*/),
  (v56/*: any*/)
],
v346 = {
  "kind": "Literal",
  "name": "ignoreMissingField",
  "value": true
},
v347 = [
  (v56/*: any*/),
  (v192/*: any*/),
  (v55/*: any*/),
  (v58/*: any*/)
],
v348 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssecuritylevelrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "idOrAlias",
          "value": "security"
        },
        (v346/*: any*/)
      ],
      "kind": "LinkedField",
      "name": "fieldByIdOrAlias",
      "plural": false,
      "selections": [
        (v63/*: any*/),
        (v56/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v55/*: any*/),
            {
              "concreteType": "JiraSecurityLevel",
              "kind": "LinkedField",
              "name": "securityLevel",
              "plural": false,
              "selections": (v347/*: any*/)
            },
            {
              "concreteType": "JiraSecurityLevelConnection",
              "kind": "LinkedField",
              "name": "securityLevels",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraSecurityLevelEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "concreteType": "JiraSecurityLevel",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": (v347/*: any*/)
                    }
                  ]
                }
              ]
            },
            (v69/*: any*/),
            (v73/*: any*/),
            (v74/*: any*/)
          ],
          "type": "JiraSecurityLevelField"
        }
      ],
      "storageKey": "fieldByIdOrAlias(idOrAlias:\"security\",ignoreMissingField:true)"
    }
  ]
},
v349 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldswatchersrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": "watchesField",
      "args": [
        {
          "kind": "Literal",
          "name": "idOrAlias",
          "value": "watches"
        },
        (v346/*: any*/)
      ],
      "kind": "LinkedField",
      "name": "fieldByIdOrAlias",
      "plural": false,
      "selections": [
        (v63/*: any*/),
        (v56/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "concreteType": "JiraWatch",
              "kind": "LinkedField",
              "name": "watch",
              "plural": false,
              "selections": [
                (v251/*: any*/),
                (v249/*: any*/),
                (v63/*: any*/)
              ]
            },
            (v74/*: any*/),
            (v73/*: any*/)
          ],
          "type": "JiraWatchesField"
        }
      ],
      "storageKey": "fieldByIdOrAlias(idOrAlias:\"watches\",ignoreMissingField:true)"
    }
  ]
},
v350 = [
  {
    "kind": "Literal",
    "name": "ids",
    "value": [
      "parent",
      "project"
    ]
  }
],
v351 = [
  {
    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "concreteType": "JiraIssueTypeField",
        "kind": "LinkedField",
        "name": "issueTypeField",
        "plural": false,
        "selections": [
          {
            "concreteType": "JiraIssueType",
            "kind": "LinkedField",
            "name": "issueType",
            "plural": false,
            "selections": [
              (v55/*: any*/),
              (v57/*: any*/),
              {
                "concreteType": "JiraAvatar",
                "kind": "LinkedField",
                "name": "avatar",
                "plural": false,
                "selections": [
                  (v61/*: any*/),
                  (v59/*: any*/)
                ]
              },
              (v320/*: any*/),
              (v56/*: any*/)
            ]
          },
          (v56/*: any*/),
          (v69/*: any*/),
          {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuetypeswitcherrelayprovider",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              (v73/*: any*/),
              (v74/*: any*/),
              (v63/*: any*/),
              {
                "concreteType": "JiraIssue",
                "kind": "LinkedField",
                "name": "issue",
                "plural": false,
                "selections": [
                  (v52/*: any*/),
                  (v56/*: any*/)
                ]
              },
              {
                "concreteType": "JiraIssueTypeConnection",
                "kind": "LinkedField",
                "name": "issueTypes",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssueType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v56/*: any*/),
                          (v57/*: any*/),
                          (v55/*: any*/),
                          (v318/*: any*/),
                          (v320/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "args": (v350/*: any*/),
        "concreteType": "JiraIssueFieldConnection",
        "kind": "LinkedField",
        "name": "fieldsById",
        "plural": false,
        "selections": [
          {
            "concreteType": "JiraIssueFieldEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v63/*: any*/),
                  (v56/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v73/*: any*/),
                      (v74/*: any*/),
                      (v55/*: any*/),
                      {
                        "concreteType": "JiraIssue",
                        "kind": "LinkedField",
                        "name": "parentIssue",
                        "plural": false,
                        "selections": [
                          (v56/*: any*/),
                          (v52/*: any*/)
                        ]
                      }
                    ],
                    "type": "JiraParentIssueField"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v55/*: any*/),
                      {
                        "concreteType": "JiraProject",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": [
                          (v172/*: any*/),
                          (v174/*: any*/),
                          (v56/*: any*/)
                        ]
                      }
                    ],
                    "type": "JiraProjectField"
                  }
                ]
              }
            ]
          }
        ],
        "storageKey": "fieldsById(ids:[\"parent\",\"project\"])"
      },
      (v89/*: any*/),
      (v319/*: any*/),
      {
        "args": (v350/*: any*/),
        "concreteType": "JiraIssueFieldConnection",
        "kind": "LinkedField",
        "name": "fieldsById",
        "plural": false,
        "selections": [
          {
            "concreteType": "JiraIssueFieldEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v63/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v69/*: any*/),
                      {
                        "concreteType": "JiraIssue",
                        "kind": "LinkedField",
                        "name": "parentIssue",
                        "plural": false,
                        "selections": [
                          (v53/*: any*/),
                          (v319/*: any*/),
                          (v89/*: any*/),
                          {
                            "concreteType": "JiraIssueTypeField",
                            "kind": "LinkedField",
                            "name": "issueTypeField",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueType",
                                "kind": "LinkedField",
                                "name": "issueType",
                                "plural": false,
                                "selections": [
                                  (v55/*: any*/),
                                  (v318/*: any*/),
                                  (v56/*: any*/)
                                ]
                              },
                              (v56/*: any*/)
                            ]
                          },
                          (v56/*: any*/)
                        ]
                      }
                    ],
                    "type": "JiraParentIssueField"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v55/*: any*/),
                      (v69/*: any*/),
                      {
                        "concreteType": "JiraProject",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": [
                          (v55/*: any*/),
                          (v89/*: any*/),
                          (v174/*: any*/),
                          (v244/*: any*/),
                          (v56/*: any*/)
                        ]
                      }
                    ],
                    "type": "JiraProjectField"
                  },
                  (v56/*: any*/)
                ]
              }
            ]
          }
        ],
        "storageKey": "fieldsById(ids:[\"parent\",\"project\"])"
      }
    ]
  }
],
v352 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 12
  },
  (v282/*: any*/)
],
v353 = {
  "kind": "InlineFragment",
  "selections": (v253/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
},
v354 = [
  (v63/*: any*/),
  (v55/*: any*/),
  (v101/*: any*/),
  (v102/*: any*/),
  (v56/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      (v17/*: any*/),
      (v18/*: any*/),
      (v19/*: any*/),
      (v20/*: any*/),
      (v21/*: any*/),
      (v22/*: any*/),
      (v23/*: any*/),
      (v24/*: any*/),
      (v25/*: any*/),
      (v26/*: any*/),
      (v27/*: any*/),
      (v28/*: any*/),
      (v29/*: any*/),
      (v30/*: any*/),
      (v31/*: any*/),
      (v32/*: any*/)
    ],
    "kind": "Fragment",
    "name": "mainIssueAggQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            (v35/*: any*/),
            (v36/*: any*/),
            (v37/*: any*/),
            (v43/*: any*/),
            (v45/*: any*/),
            {
              "args": (v34/*: any*/),
              "concreteType": "JiraIssueLinkTypeConnection",
              "kind": "LinkedField",
              "name": "issueLinkTypes",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssueLinkTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "concreteType": "JiraIssueLinkType",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v46/*: any*/),
                        (v47/*: any*/),
                        (v48/*: any*/)
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "args": (v49/*: any*/),
              "concreteType": "JiraIssue",
              "kind": "LinkedField",
              "name": "issueByKey",
              "plural": false,
              "selections": [
                (v50/*: any*/),
                {
                  "kind": "RequiredField",
                  "field": (v51/*: any*/),
                  "action": "LOG",
                  "path": "jira.issueByKey.errorRetrievingData"
                },
                (v52/*: any*/),
                (v53/*: any*/),
                {
                  "concreteType": "JiraIssueTypeHierarchyLevel",
                  "kind": "LinkedField",
                  "name": "hierarchyLevelAbove",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": (v54/*: any*/),
                      "action": "LOG",
                      "path": "jira.issueByKey.hierarchyLevelAbove.level"
                    },
                    {
                      "kind": "RequiredField",
                      "field": (v55/*: any*/),
                      "action": "LOG",
                      "path": "jira.issueByKey.hierarchyLevelAbove.name"
                    }
                  ]
                },
                {
                  "concreteType": "JiraIssueTypeHierarchyLevel",
                  "kind": "LinkedField",
                  "name": "hierarchyLevelBelow",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": (v54/*: any*/),
                      "action": "LOG",
                      "path": "jira.issueByKey.hierarchyLevelBelow.level"
                    },
                    {
                      "kind": "RequiredField",
                      "field": (v55/*: any*/),
                      "action": "LOG",
                      "path": "jira.issueByKey.hierarchyLevelBelow.name"
                    }
                  ]
                },
                {
                  "concreteType": "JiraIssueTypeConnection",
                  "kind": "LinkedField",
                  "name": "issueTypesForHierarchyAbove",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraIssueTypeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "concreteType": "JiraIssueType",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v56/*: any*/),
                            {
                              "kind": "RequiredField",
                              "field": (v57/*: any*/),
                              "action": "LOG",
                              "path": "jira.issueByKey.issueTypesForHierarchyAbove.edges.node.issueTypeId"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v55/*: any*/),
                              "action": "LOG",
                              "path": "jira.issueByKey.issueTypesForHierarchyAbove.edges.node.name"
                            },
                            (v58/*: any*/),
                            {
                              "concreteType": "JiraAvatar",
                              "kind": "LinkedField",
                              "name": "avatar",
                              "plural": false,
                              "selections": [
                                (v59/*: any*/),
                                (v60/*: any*/),
                                {
                                  "kind": "RequiredField",
                                  "field": (v61/*: any*/),
                                  "action": "LOG",
                                  "path": "jira.issueByKey.issueTypesForHierarchyAbove.edges.node.avatar.medium"
                                },
                                (v62/*: any*/)
                              ]
                            },
                            {
                              "kind": "RequiredField",
                              "field": {
                                "concreteType": "JiraIssueTypeHierarchyLevel",
                                "kind": "LinkedField",
                                "name": "hierarchy",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "RequiredField",
                                    "field": (v54/*: any*/),
                                    "action": "LOG",
                                    "path": "jira.issueByKey.issueTypesForHierarchyAbove.edges.node.hierarchy.level"
                                  },
                                  {
                                    "kind": "RequiredField",
                                    "field": (v55/*: any*/),
                                    "action": "LOG",
                                    "path": "jira.issueByKey.issueTypesForHierarchyAbove.edges.node.hierarchy.name"
                                  }
                                ]
                              },
                              "action": "LOG",
                              "path": "jira.issueByKey.issueTypesForHierarchyAbove.edges.node.hierarchy"
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "concreteType": "JiraIssueTypeConnection",
                  "kind": "LinkedField",
                  "name": "issueTypesForHierarchyBelow",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraIssueTypeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "concreteType": "JiraIssueType",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v56/*: any*/),
                            {
                              "kind": "RequiredField",
                              "field": (v57/*: any*/),
                              "action": "LOG",
                              "path": "jira.issueByKey.issueTypesForHierarchyBelow.edges.node.issueTypeId"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v55/*: any*/),
                              "action": "LOG",
                              "path": "jira.issueByKey.issueTypesForHierarchyBelow.edges.node.name"
                            },
                            (v58/*: any*/),
                            {
                              "concreteType": "JiraAvatar",
                              "kind": "LinkedField",
                              "name": "avatar",
                              "plural": false,
                              "selections": [
                                (v59/*: any*/),
                                (v60/*: any*/),
                                {
                                  "kind": "RequiredField",
                                  "field": (v61/*: any*/),
                                  "action": "LOG",
                                  "path": "jira.issueByKey.issueTypesForHierarchyBelow.edges.node.avatar.medium"
                                },
                                (v62/*: any*/)
                              ]
                            },
                            {
                              "kind": "RequiredField",
                              "field": {
                                "concreteType": "JiraIssueTypeHierarchyLevel",
                                "kind": "LinkedField",
                                "name": "hierarchy",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "RequiredField",
                                    "field": (v54/*: any*/),
                                    "action": "LOG",
                                    "path": "jira.issueByKey.issueTypesForHierarchyBelow.edges.node.hierarchy.level"
                                  },
                                  {
                                    "kind": "RequiredField",
                                    "field": (v55/*: any*/),
                                    "action": "LOG",
                                    "path": "jira.issueByKey.issueTypesForHierarchyBelow.edges.node.hierarchy.name"
                                  }
                                ]
                              },
                              "action": "LOG",
                              "path": "jira.issueByKey.issueTypesForHierarchyBelow.edges.node.hierarchy"
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "concreteType": "JiraIssueTypeConnection",
                  "kind": "LinkedField",
                  "name": "issueTypesForHierarchySame",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraIssueTypeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "concreteType": "JiraIssueType",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v56/*: any*/),
                            {
                              "kind": "RequiredField",
                              "field": (v57/*: any*/),
                              "action": "LOG",
                              "path": "jira.issueByKey.issueTypesForHierarchySame.edges.node.issueTypeId"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v55/*: any*/),
                              "action": "LOG",
                              "path": "jira.issueByKey.issueTypesForHierarchySame.edges.node.name"
                            },
                            (v58/*: any*/),
                            {
                              "concreteType": "JiraAvatar",
                              "kind": "LinkedField",
                              "name": "avatar",
                              "plural": false,
                              "selections": [
                                (v59/*: any*/),
                                (v60/*: any*/),
                                {
                                  "kind": "RequiredField",
                                  "field": (v61/*: any*/),
                                  "action": "LOG",
                                  "path": "jira.issueByKey.issueTypesForHierarchySame.edges.node.avatar.medium"
                                },
                                (v62/*: any*/)
                              ]
                            },
                            {
                              "kind": "RequiredField",
                              "field": {
                                "concreteType": "JiraIssueTypeHierarchyLevel",
                                "kind": "LinkedField",
                                "name": "hierarchy",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "RequiredField",
                                    "field": (v54/*: any*/),
                                    "action": "LOG",
                                    "path": "jira.issueByKey.issueTypesForHierarchySame.edges.node.hierarchy.level"
                                  },
                                  {
                                    "kind": "RequiredField",
                                    "field": (v55/*: any*/),
                                    "action": "LOG",
                                    "path": "jira.issueByKey.issueTypesForHierarchySame.edges.node.hierarchy.name"
                                  }
                                ]
                              },
                              "action": "LOG",
                              "path": "jira.issueByKey.issueTypesForHierarchySame.edges.node.hierarchy"
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "concreteType": "JiraIssueFieldConnection",
                  "kind": "LinkedField",
                  "name": "fields",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraIssueFieldEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v63/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "concreteType": "JiraClassificationLevel",
                                  "kind": "LinkedField",
                                  "name": "classificationLevel",
                                  "plural": false,
                                  "selections": [
                                    (v55/*: any*/),
                                    (v65/*: any*/),
                                    (v66/*: any*/)
                                  ]
                                },
                                {
                                  "kind": "FragmentSpread",
                                  "name": "editViewPopup_issueClassificationBadge_ClassificationEditViewPopup"
                                },
                                (v56/*: any*/),
                                (v68/*: any*/)
                              ],
                              "type": "JiraDataClassificationField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                {
                                  "concreteType": "JiraAffectedServiceConnection",
                                  "kind": "LinkedField",
                                  "name": "selectedAffectedServicesConnection",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "concreteType": "JiraAffectedServiceEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "concreteType": "JiraAffectedService",
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": [
                                            {
                                              "kind": "RequiredField",
                                              "field": (v75/*: any*/),
                                              "action": "LOG",
                                              "path": "jira.issueByKey.fields.edges.node.selectedAffectedServicesConnection.edges.node.serviceId"
                                            },
                                            (v55/*: any*/)
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                },
                                (v76/*: any*/)
                              ],
                              "type": "JiraAffectedServicesField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v78/*: any*/),
                                (v76/*: any*/)
                              ],
                              "type": "JiraAssetField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v77/*: any*/)
                              ],
                              "type": "JiraBooleanField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v76/*: any*/),
                                (v79/*: any*/),
                                (v80/*: any*/),
                                (v83/*: any*/),
                                {
                                  "concreteType": "JiraCmdbObjectConnection",
                                  "kind": "LinkedField",
                                  "name": "selectedCmdbObjectsConnection",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "concreteType": "JiraCmdbObjectEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "concreteType": "JiraCmdbObject",
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": [
                                            (v84/*: any*/),
                                            (v85/*: any*/),
                                            (v86/*: any*/),
                                            (v87/*: any*/),
                                            {
                                              "kind": "RequiredField",
                                              "field": (v88/*: any*/),
                                              "action": "LOG",
                                              "path": "jira.issueByKey.fields.edges.node.selectedCmdbObjectsConnection.edges.node.workspaceId"
                                            },
                                            (v89/*: any*/),
                                            {
                                              "concreteType": "JiraCmdbAvatar",
                                              "kind": "LinkedField",
                                              "name": "avatar",
                                              "plural": false,
                                              "selections": [
                                                (v91/*: any*/),
                                                {
                                                  "kind": "RequiredField",
                                                  "field": (v92/*: any*/),
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.selectedCmdbObjectsConnection.edges.node.avatar.url48"
                                                }
                                              ]
                                            },
                                            {
                                              "kind": "RequiredField",
                                              "field": {
                                                "concreteType": "JiraCmdbObjectType",
                                                "kind": "LinkedField",
                                                "name": "objectType",
                                                "plural": false,
                                                "selections": [
                                                  (v93/*: any*/),
                                                  (v55/*: any*/),
                                                  (v82/*: any*/),
                                                  {
                                                    "kind": "RequiredField",
                                                    "field": (v94/*: any*/),
                                                    "action": "LOG",
                                                    "path": "jira.issueByKey.fields.edges.node.selectedCmdbObjectsConnection.edges.node.objectType.icon"
                                                  }
                                                ]
                                              },
                                              "action": "LOG",
                                              "path": "jira.issueByKey.fields.edges.node.selectedCmdbObjectsConnection.edges.node.objectType"
                                            },
                                            {
                                              "concreteType": "JiraCmdbAttributeConnection",
                                              "kind": "LinkedField",
                                              "name": "attributes",
                                              "plural": false,
                                              "selections": [
                                                {
                                                  "concreteType": "JiraCmdbAttributeEdge",
                                                  "kind": "LinkedField",
                                                  "name": "edges",
                                                  "plural": true,
                                                  "selections": [
                                                    {
                                                      "concreteType": "JiraCmdbAttribute",
                                                      "kind": "LinkedField",
                                                      "name": "node",
                                                      "plural": false,
                                                      "selections": [
                                                        (v95/*: any*/),
                                                        (v96/*: any*/),
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": (v98/*: any*/),
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.fields.edges.node.selectedCmdbObjectsConnection.edges.node.attributes.edges.node.objectTypeAttribute"
                                                        },
                                                        {
                                                          "concreteType": "JiraCmdbObjectAttributeValueConnection",
                                                          "kind": "LinkedField",
                                                          "name": "objectAttributeValues",
                                                          "plural": false,
                                                          "selections": [
                                                            {
                                                              "concreteType": "JiraCmdbObjectAttributeValueEdge",
                                                              "kind": "LinkedField",
                                                              "name": "edges",
                                                              "plural": true,
                                                              "selections": [
                                                                {
                                                                  "concreteType": "JiraCmdbObjectAttributeValue",
                                                                  "kind": "LinkedField",
                                                                  "name": "node",
                                                                  "plural": false,
                                                                  "selections": [
                                                                    (v99/*: any*/),
                                                                    (v100/*: any*/),
                                                                    (v77/*: any*/),
                                                                    {
                                                                      "kind": "LinkedField",
                                                                      "name": "user",
                                                                      "plural": false,
                                                                      "selections": [
                                                                        (v101/*: any*/),
                                                                        (v55/*: any*/),
                                                                        (v102/*: any*/)
                                                                      ]
                                                                    },
                                                                    (v103/*: any*/),
                                                                    {
                                                                      "concreteType": "JiraGroup",
                                                                      "kind": "LinkedField",
                                                                      "name": "group",
                                                                      "plural": false,
                                                                      "selections": (v104/*: any*/)
                                                                    },
                                                                    (v105/*: any*/),
                                                                    (v107/*: any*/),
                                                                    (v108/*: any*/),
                                                                    (v110/*: any*/)
                                                                  ]
                                                                }
                                                              ]
                                                            }
                                                          ]
                                                        }
                                                      ]
                                                    }
                                                  ]
                                                }
                                              ]
                                            }
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ],
                              "type": "JiraCMDBField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v115/*: any*/),
                                (v116/*: any*/)
                              ],
                              "type": "JiraCascadingSelectField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v118/*: any*/),
                                (v119/*: any*/)
                              ],
                              "type": "JiraCheckboxesField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v120/*: any*/)
                              ],
                              "type": "JiraColorField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v125/*: any*/),
                                (v126/*: any*/)
                              ],
                              "type": "JiraComponentsField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": (v128/*: any*/),
                              "type": "JiraConnectDateTimeField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v118/*: any*/),
                                (v129/*: any*/)
                              ],
                              "type": "JiraConnectMultipleSelectField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v130/*: any*/)
                              ],
                              "type": "JiraConnectNumberField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": (v134/*: any*/),
                              "type": "JiraConnectRichTextField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v135/*: any*/),
                                (v136/*: any*/),
                                (v76/*: any*/)
                              ],
                              "type": "JiraConnectSingleSelectField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": (v138/*: any*/),
                              "type": "JiraConnectTextField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v139/*: any*/)
                              ],
                              "type": "JiraDatePickerField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": (v128/*: any*/),
                              "type": "JiraDateTimePickerField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v141/*: any*/),
                                (v76/*: any*/)
                              ],
                              "type": "JiraEpicLinkField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v142/*: any*/)
                              ],
                              "type": "JiraFlagField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v145/*: any*/),
                                (v133/*: any*/)
                              ],
                              "type": "JiraForgeGroupField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v146/*: any*/),
                                (v133/*: any*/)
                              ],
                              "type": "JiraForgeGroupsField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v130/*: any*/),
                                (v133/*: any*/)
                              ],
                              "type": "JiraForgeNumberField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v147/*: any*/),
                                (v133/*: any*/)
                              ],
                              "type": "JiraForgeObjectField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v137/*: any*/),
                                (v133/*: any*/)
                              ],
                              "type": "JiraForgeStringField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v148/*: any*/),
                                (v133/*: any*/),
                                (v76/*: any*/)
                              ],
                              "type": "JiraForgeStringsField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                {
                                  "kind": "LinkedField",
                                  "name": "user",
                                  "plural": false,
                                  "selections": (v156/*: any*/)
                                },
                                (v133/*: any*/),
                                (v76/*: any*/)
                              ],
                              "type": "JiraForgeUserField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v157/*: any*/),
                                (v133/*: any*/),
                                (v76/*: any*/)
                              ],
                              "type": "JiraForgeUsersField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v158/*: any*/),
                                (v76/*: any*/)
                              ],
                              "type": "JiraIssueRestrictionField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                {
                                  "concreteType": "JiraIssueType",
                                  "kind": "LinkedField",
                                  "name": "issueType",
                                  "plural": false,
                                  "selections": [
                                    (v56/*: any*/),
                                    {
                                      "kind": "RequiredField",
                                      "field": (v57/*: any*/),
                                      "action": "LOG",
                                      "path": "jira.issueByKey.fields.edges.node.issueType.issueTypeId"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v55/*: any*/),
                                      "action": "LOG",
                                      "path": "jira.issueByKey.fields.edges.node.issueType.name"
                                    },
                                    (v58/*: any*/),
                                    {
                                      "concreteType": "JiraAvatar",
                                      "kind": "LinkedField",
                                      "name": "avatar",
                                      "plural": false,
                                      "selections": [
                                        (v59/*: any*/),
                                        (v60/*: any*/),
                                        {
                                          "kind": "RequiredField",
                                          "field": (v61/*: any*/),
                                          "action": "LOG",
                                          "path": "jira.issueByKey.fields.edges.node.issueType.avatar.medium"
                                        },
                                        (v62/*: any*/)
                                      ]
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": {
                                        "concreteType": "JiraIssueTypeHierarchyLevel",
                                        "kind": "LinkedField",
                                        "name": "hierarchy",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "RequiredField",
                                            "field": (v54/*: any*/),
                                            "action": "LOG",
                                            "path": "jira.issueByKey.fields.edges.node.issueType.hierarchy.level"
                                          },
                                          {
                                            "kind": "RequiredField",
                                            "field": (v55/*: any*/),
                                            "action": "LOG",
                                            "path": "jira.issueByKey.fields.edges.node.issueType.hierarchy.name"
                                          }
                                        ]
                                      },
                                      "action": "LOG",
                                      "path": "jira.issueByKey.fields.edges.node.issueType.hierarchy"
                                    }
                                  ]
                                }
                              ],
                              "type": "JiraIssueTypeField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v148/*: any*/),
                                (v76/*: any*/)
                              ],
                              "type": "JiraLabelsField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                {
                                  "concreteType": "JiraGroupConnection",
                                  "kind": "LinkedField",
                                  "name": "selectedGroupsConnection",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "concreteType": "JiraGroupEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "concreteType": "JiraGroup",
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": [
                                            (v143/*: any*/),
                                            (v55/*: any*/)
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                },
                                (v76/*: any*/)
                              ],
                              "type": "JiraMultipleGroupPickerField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v118/*: any*/),
                                (v76/*: any*/)
                              ],
                              "type": "JiraMultipleSelectField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": (v159/*: any*/),
                              "type": "JiraMultipleSelectUserPickerField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v163/*: any*/),
                                (v164/*: any*/)
                              ],
                              "type": "JiraMultipleVersionPickerField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v130/*: any*/),
                                (v165/*: any*/)
                              ],
                              "type": "JiraNumberField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v69/*: any*/),
                                    (v167/*: any*/),
                                    (v56/*: any*/)
                                  ],
                                  "type": "JiraIssueField",
                                  "abstractKey": "__isJiraIssueField"
                                },
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v169/*: any*/)
                              ],
                              "type": "JiraOriginalTimeEstimateField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v170/*: any*/),
                                {
                                  "concreteType": "JiraIssue",
                                  "kind": "LinkedField",
                                  "name": "parentIssue",
                                  "plural": false,
                                  "selections": [
                                    (v56/*: any*/),
                                    (v52/*: any*/),
                                    (v53/*: any*/),
                                    (v89/*: any*/),
                                    {
                                      "condition": "parentInIssueViewFlag",
                                      "kind": "Condition",
                                      "passingValue": true,
                                      "selections": [
                                        {
                                          "concreteType": "JiraColorField",
                                          "kind": "LinkedField",
                                          "name": "issueColorField",
                                          "plural": false,
                                          "selections": [
                                            (v65/*: any*/)
                                          ]
                                        }
                                      ]
                                    },
                                    {
                                      "args": (v171/*: any*/),
                                      "concreteType": "JiraIssueFieldConnection",
                                      "kind": "LinkedField",
                                      "name": "fieldsById",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "concreteType": "JiraIssueFieldEdge",
                                          "kind": "LinkedField",
                                          "name": "edges",
                                          "plural": true,
                                          "selections": [
                                            {
                                              "kind": "LinkedField",
                                              "name": "node",
                                              "plural": false,
                                              "selections": [
                                                (v63/*: any*/),
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    (v73/*: any*/),
                                                    (v55/*: any*/),
                                                    (v74/*: any*/),
                                                    (v58/*: any*/),
                                                    {
                                                      "concreteType": "JiraIssueType",
                                                      "kind": "LinkedField",
                                                      "name": "issueType",
                                                      "plural": false,
                                                      "selections": [
                                                        (v56/*: any*/),
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": (v57/*: any*/),
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.fields.edges.node.parentIssue.fieldsById.edges.node.issueType.issueTypeId"
                                                        },
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": (v55/*: any*/),
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.fields.edges.node.parentIssue.fieldsById.edges.node.issueType.name"
                                                        },
                                                        (v58/*: any*/),
                                                        {
                                                          "concreteType": "JiraAvatar",
                                                          "kind": "LinkedField",
                                                          "name": "avatar",
                                                          "plural": false,
                                                          "selections": [
                                                            (v59/*: any*/),
                                                            (v60/*: any*/),
                                                            {
                                                              "kind": "RequiredField",
                                                              "field": (v61/*: any*/),
                                                              "action": "LOG",
                                                              "path": "jira.issueByKey.fields.edges.node.parentIssue.fieldsById.edges.node.issueType.avatar.medium"
                                                            },
                                                            (v62/*: any*/)
                                                          ]
                                                        },
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": {
                                                            "concreteType": "JiraIssueTypeHierarchyLevel",
                                                            "kind": "LinkedField",
                                                            "name": "hierarchy",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "kind": "RequiredField",
                                                                "field": (v54/*: any*/),
                                                                "action": "LOG",
                                                                "path": "jira.issueByKey.fields.edges.node.parentIssue.fieldsById.edges.node.issueType.hierarchy.level"
                                                              },
                                                              {
                                                                "kind": "RequiredField",
                                                                "field": (v55/*: any*/),
                                                                "action": "LOG",
                                                                "path": "jira.issueByKey.fields.edges.node.parentIssue.fieldsById.edges.node.issueType.hierarchy.name"
                                                              }
                                                            ]
                                                          },
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.fields.edges.node.parentIssue.fieldsById.edges.node.issueType.hierarchy"
                                                        }
                                                      ]
                                                    }
                                                  ],
                                                  "type": "JiraIssueTypeField"
                                                },
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    (v73/*: any*/),
                                                    (v55/*: any*/),
                                                    (v74/*: any*/),
                                                    (v58/*: any*/),
                                                    (v76/*: any*/),
                                                    {
                                                      "concreteType": "JiraProject",
                                                      "kind": "LinkedField",
                                                      "name": "project",
                                                      "plural": false,
                                                      "selections": [
                                                        (v53/*: any*/),
                                                        (v55/*: any*/),
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": (v172/*: any*/),
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.fields.edges.node.parentIssue.fieldsById.edges.node.project.projectId"
                                                        },
                                                        (v173/*: any*/),
                                                        (v174/*: any*/),
                                                        (v175/*: any*/),
                                                        (v161/*: any*/),
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": {
                                                            "concreteType": "JiraAvatar",
                                                            "kind": "LinkedField",
                                                            "name": "avatar",
                                                            "plural": false,
                                                            "selections": [
                                                              (v61/*: any*/),
                                                              {
                                                                "kind": "RequiredField",
                                                                "field": (v62/*: any*/),
                                                                "action": "LOG",
                                                                "path": "jira.issueByKey.fields.edges.node.parentIssue.fieldsById.edges.node.project.avatar.large"
                                                              }
                                                            ]
                                                          },
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.fields.edges.node.parentIssue.fieldsById.edges.node.project.avatar"
                                                        },
                                                        (v176/*: any*/),
                                                        (v178/*: any*/)
                                                      ]
                                                    }
                                                  ],
                                                  "type": "JiraProjectField"
                                                },
                                                (v179/*: any*/)
                                              ]
                                            }
                                          ]
                                        }
                                      ],
                                      "storageKey": "fieldsById(ids:[\"issuetype\",\"project\",\"summary\"])"
                                    }
                                  ]
                                }
                              ],
                              "type": "JiraParentIssueField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v157/*: any*/),
                                (v180/*: any*/),
                                (v76/*: any*/)
                              ],
                              "type": "JiraPeopleField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                {
                                  "concreteType": "JiraPriority",
                                  "kind": "LinkedField",
                                  "name": "priority",
                                  "plural": false,
                                  "selections": (v184/*: any*/)
                                },
                                {
                                  "concreteType": "JiraPriorityConnection",
                                  "kind": "LinkedField",
                                  "name": "priorities",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "concreteType": "JiraPriorityEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "concreteType": "JiraPriority",
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": (v184/*: any*/)
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ],
                              "type": "JiraPriorityField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v185/*: any*/)
                              ],
                              "type": "JiraProformaFormsField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v76/*: any*/),
                                {
                                  "concreteType": "JiraProject",
                                  "kind": "LinkedField",
                                  "name": "project",
                                  "plural": false,
                                  "selections": [
                                    (v53/*: any*/),
                                    (v55/*: any*/),
                                    {
                                      "kind": "RequiredField",
                                      "field": (v172/*: any*/),
                                      "action": "LOG",
                                      "path": "jira.issueByKey.fields.edges.node.project.projectId"
                                    },
                                    (v173/*: any*/),
                                    (v174/*: any*/),
                                    (v175/*: any*/),
                                    (v161/*: any*/),
                                    {
                                      "kind": "RequiredField",
                                      "field": {
                                        "concreteType": "JiraAvatar",
                                        "kind": "LinkedField",
                                        "name": "avatar",
                                        "plural": false,
                                        "selections": [
                                          (v61/*: any*/),
                                          {
                                            "kind": "RequiredField",
                                            "field": (v62/*: any*/),
                                            "action": "LOG",
                                            "path": "jira.issueByKey.fields.edges.node.project.avatar.large"
                                          }
                                        ]
                                      },
                                      "action": "LOG",
                                      "path": "jira.issueByKey.fields.edges.node.project.avatar"
                                    },
                                    (v176/*: any*/),
                                    (v178/*: any*/)
                                  ]
                                }
                              ],
                              "type": "JiraProjectField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v186/*: any*/),
                                (v187/*: any*/)
                              ],
                              "type": "JiraRadioSelectField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v190/*: any*/),
                                (v191/*: any*/)
                              ],
                              "type": "JiraResolutionField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": (v134/*: any*/),
                              "type": "JiraRichTextField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                {
                                  "concreteType": "JiraSecurityLevel",
                                  "kind": "LinkedField",
                                  "name": "securityLevel",
                                  "plural": false,
                                  "selections": (v193/*: any*/)
                                },
                                {
                                  "concreteType": "JiraSecurityLevelConnection",
                                  "kind": "LinkedField",
                                  "name": "securityLevels",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "concreteType": "JiraSecurityLevelEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "concreteType": "JiraSecurityLevel",
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": (v193/*: any*/)
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ],
                              "type": "JiraSecurityLevelField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                {
                                  "concreteType": "JiraServiceManagementActiveApproval",
                                  "kind": "LinkedField",
                                  "name": "activeApproval",
                                  "plural": false,
                                  "selections": [
                                    (v56/*: any*/),
                                    (v194/*: any*/),
                                    (v196/*: any*/),
                                    {
                                      "concreteType": "JiraServiceManagementApproverPrincipalConnection",
                                      "kind": "LinkedField",
                                      "name": "approverPrincipals",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "concreteType": "JiraServiceManagementApproverPrincipalEdge",
                                          "kind": "LinkedField",
                                          "name": "edges",
                                          "plural": true,
                                          "selections": [
                                            {
                                              "kind": "LinkedField",
                                              "name": "node",
                                              "plural": false,
                                              "selections": [
                                                (v63/*: any*/),
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": {
                                                        "kind": "LinkedField",
                                                        "name": "user",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "kind": "RequiredField",
                                                            "field": (v101/*: any*/),
                                                            "action": "LOG",
                                                            "path": "jira.issueByKey.fields.edges.node.activeApproval.approverPrincipals.edges.node.user.accountId"
                                                          },
                                                          {
                                                            "kind": "RequiredField",
                                                            "field": (v55/*: any*/),
                                                            "action": "LOG",
                                                            "path": "jira.issueByKey.fields.edges.node.activeApproval.approverPrincipals.edges.node.user.name"
                                                          },
                                                          {
                                                            "kind": "RequiredField",
                                                            "field": (v102/*: any*/),
                                                            "action": "LOG",
                                                            "path": "jira.issueByKey.fields.edges.node.activeApproval.approverPrincipals.edges.node.user.picture"
                                                          }
                                                        ]
                                                      },
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.fields.edges.node.activeApproval.approverPrincipals.edges.node.user"
                                                    },
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": (v197/*: any*/),
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.fields.edges.node.activeApproval.approverPrincipals.edges.node.jiraRest"
                                                    }
                                                  ],
                                                  "type": "JiraServiceManagementUserApproverPrincipal"
                                                },
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": (v143/*: any*/),
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.fields.edges.node.activeApproval.approverPrincipals.edges.node.groupId"
                                                    },
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": (v55/*: any*/),
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.fields.edges.node.activeApproval.approverPrincipals.edges.node.name"
                                                    },
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": (v198/*: any*/),
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.fields.edges.node.activeApproval.approverPrincipals.edges.node.memberCount"
                                                    },
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": (v199/*: any*/),
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.fields.edges.node.activeApproval.approverPrincipals.edges.node.approvedCount"
                                                    }
                                                  ],
                                                  "type": "JiraServiceManagementGroupApproverPrincipal"
                                                }
                                              ]
                                            }
                                          ]
                                        }
                                      ]
                                    },
                                    {
                                      "concreteType": "JiraServiceManagementApproverConnection",
                                      "kind": "LinkedField",
                                      "name": "approvers",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "concreteType": "JiraServiceManagementApproverEdge",
                                          "kind": "LinkedField",
                                          "name": "edges",
                                          "plural": true,
                                          "selections": [
                                            {
                                              "concreteType": "JiraServiceManagementApprover",
                                              "kind": "LinkedField",
                                              "name": "node",
                                              "plural": false,
                                              "selections": [
                                                {
                                                  "kind": "RequiredField",
                                                  "field": {
                                                    "kind": "LinkedField",
                                                    "name": "approver",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "kind": "RequiredField",
                                                        "field": (v101/*: any*/),
                                                        "action": "LOG",
                                                        "path": "jira.issueByKey.fields.edges.node.activeApproval.approvers.edges.node.approver.accountId"
                                                      },
                                                      {
                                                        "kind": "RequiredField",
                                                        "field": (v149/*: any*/),
                                                        "action": "LOG",
                                                        "path": "jira.issueByKey.fields.edges.node.activeApproval.approvers.edges.node.approver.accountStatus"
                                                      },
                                                      {
                                                        "kind": "RequiredField",
                                                        "field": (v55/*: any*/),
                                                        "action": "LOG",
                                                        "path": "jira.issueByKey.fields.edges.node.activeApproval.approvers.edges.node.approver.name"
                                                      },
                                                      {
                                                        "kind": "RequiredField",
                                                        "field": (v102/*: any*/),
                                                        "action": "LOG",
                                                        "path": "jira.issueByKey.fields.edges.node.activeApproval.approvers.edges.node.approver.picture"
                                                      },
                                                      (v201/*: any*/),
                                                      (v202/*: any*/)
                                                    ]
                                                  },
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.approvers.edges.node.approver"
                                                },
                                                {
                                                  "kind": "RequiredField",
                                                  "field": (v203/*: any*/),
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.approvers.edges.node.approverDecision"
                                                }
                                              ]
                                            }
                                          ]
                                        }
                                      ]
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v204/*: any*/),
                                      "action": "LOG",
                                      "path": "jira.issueByKey.fields.edges.node.activeApproval.canAnswerApproval"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": {
                                        "concreteType": "JiraServiceManagementApprovalConfiguration",
                                        "kind": "LinkedField",
                                        "name": "configurations",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "RequiredField",
                                            "field": {
                                              "concreteType": "JiraServiceManagementApproversConfiguration",
                                              "kind": "LinkedField",
                                              "name": "approversConfigurations",
                                              "plural": true,
                                              "selections": [
                                                {
                                                  "kind": "RequiredField",
                                                  "field": (v74/*: any*/),
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.configurations.approversConfigurations.type"
                                                },
                                                {
                                                  "kind": "RequiredField",
                                                  "field": (v205/*: any*/),
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.configurations.approversConfigurations.fieldName"
                                                },
                                                {
                                                  "kind": "RequiredField",
                                                  "field": (v73/*: any*/),
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.configurations.approversConfigurations.fieldId"
                                                }
                                              ]
                                            },
                                            "action": "LOG",
                                            "path": "jira.issueByKey.fields.edges.node.activeApproval.configurations.approversConfigurations"
                                          },
                                          {
                                            "kind": "RequiredField",
                                            "field": {
                                              "concreteType": "JiraServiceManagementApprovalCondition",
                                              "kind": "LinkedField",
                                              "name": "condition",
                                              "plural": false,
                                              "selections": [
                                                {
                                                  "kind": "RequiredField",
                                                  "field": (v74/*: any*/),
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.configurations.condition.type"
                                                },
                                                {
                                                  "kind": "RequiredField",
                                                  "field": (v77/*: any*/),
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.configurations.condition.value"
                                                }
                                              ]
                                            },
                                            "action": "LOG",
                                            "path": "jira.issueByKey.fields.edges.node.activeApproval.configurations.condition"
                                          }
                                        ]
                                      },
                                      "action": "LOG",
                                      "path": "jira.issueByKey.fields.edges.node.activeApproval.configurations"
                                    },
                                    (v206/*: any*/),
                                    {
                                      "concreteType": "JiraServiceManagementDecisionConnection",
                                      "kind": "LinkedField",
                                      "name": "decisions",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "concreteType": "JiraServiceManagementDecisionEdge",
                                          "kind": "LinkedField",
                                          "name": "edges",
                                          "plural": true,
                                          "selections": [
                                            {
                                              "concreteType": "JiraServiceManagementDecision",
                                              "kind": "LinkedField",
                                              "name": "node",
                                              "plural": false,
                                              "selections": [
                                                {
                                                  "kind": "RequiredField",
                                                  "field": {
                                                    "kind": "LinkedField",
                                                    "name": "approver",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "kind": "RequiredField",
                                                        "field": (v101/*: any*/),
                                                        "action": "LOG",
                                                        "path": "jira.issueByKey.fields.edges.node.activeApproval.decisions.edges.node.approver.accountId"
                                                      },
                                                      {
                                                        "kind": "RequiredField",
                                                        "field": (v149/*: any*/),
                                                        "action": "LOG",
                                                        "path": "jira.issueByKey.fields.edges.node.activeApproval.decisions.edges.node.approver.accountStatus"
                                                      },
                                                      {
                                                        "kind": "RequiredField",
                                                        "field": (v55/*: any*/),
                                                        "action": "LOG",
                                                        "path": "jira.issueByKey.fields.edges.node.activeApproval.decisions.edges.node.approver.name"
                                                      },
                                                      {
                                                        "kind": "RequiredField",
                                                        "field": (v102/*: any*/),
                                                        "action": "LOG",
                                                        "path": "jira.issueByKey.fields.edges.node.activeApproval.decisions.edges.node.approver.picture"
                                                      },
                                                      (v201/*: any*/),
                                                      (v202/*: any*/)
                                                    ]
                                                  },
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.decisions.edges.node.approver"
                                                },
                                                {
                                                  "kind": "RequiredField",
                                                  "field": (v203/*: any*/),
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.decisions.edges.node.approverDecision"
                                                }
                                              ]
                                            }
                                          ]
                                        }
                                      ]
                                    },
                                    {
                                      "concreteType": "JiraUserConnection",
                                      "kind": "LinkedField",
                                      "name": "excludedApprovers",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "concreteType": "JiraUserEdge",
                                          "kind": "LinkedField",
                                          "name": "edges",
                                          "plural": true,
                                          "selections": [
                                            {
                                              "kind": "LinkedField",
                                              "name": "node",
                                              "plural": false,
                                              "selections": [
                                                {
                                                  "kind": "RequiredField",
                                                  "field": (v101/*: any*/),
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.excludedApprovers.edges.node.accountId"
                                                },
                                                {
                                                  "kind": "RequiredField",
                                                  "field": (v149/*: any*/),
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.excludedApprovers.edges.node.accountStatus"
                                                },
                                                {
                                                  "kind": "RequiredField",
                                                  "field": (v55/*: any*/),
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.excludedApprovers.edges.node.name"
                                                },
                                                {
                                                  "kind": "RequiredField",
                                                  "field": (v102/*: any*/),
                                                  "action": "LOG",
                                                  "path": "jira.issueByKey.fields.edges.node.activeApproval.excludedApprovers.edges.node.picture"
                                                },
                                                (v201/*: any*/),
                                                (v202/*: any*/)
                                              ]
                                            }
                                          ]
                                        }
                                      ]
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v207/*: any*/),
                                      "action": "LOG",
                                      "path": "jira.issueByKey.fields.edges.node.activeApproval.finalDecision"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v55/*: any*/),
                                      "action": "LOG",
                                      "path": "jira.issueByKey.fields.edges.node.activeApproval.name"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v208/*: any*/),
                                      "action": "LOG",
                                      "path": "jira.issueByKey.fields.edges.node.activeApproval.pendingApprovalCount"
                                    },
                                    (v209/*: any*/)
                                  ]
                                },
                                {
                                  "concreteType": "JiraServiceManagementCompletedApprovalConnection",
                                  "kind": "LinkedField",
                                  "name": "completedApprovalsConnection",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "concreteType": "JiraServiceManagementCompletedApprovalEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "concreteType": "JiraServiceManagementCompletedApproval",
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": [
                                            (v56/*: any*/),
                                            {
                                              "kind": "RequiredField",
                                              "field": (v55/*: any*/),
                                              "action": "LOG",
                                              "path": "jira.issueByKey.fields.edges.node.completedApprovalsConnection.edges.node.name"
                                            },
                                            {
                                              "kind": "RequiredField",
                                              "field": (v207/*: any*/),
                                              "action": "LOG",
                                              "path": "jira.issueByKey.fields.edges.node.completedApprovalsConnection.edges.node.finalDecision"
                                            },
                                            {
                                              "concreteType": "JiraServiceManagementApproverConnection",
                                              "kind": "LinkedField",
                                              "name": "approvers",
                                              "plural": false,
                                              "selections": [
                                                {
                                                  "concreteType": "JiraServiceManagementApproverEdge",
                                                  "kind": "LinkedField",
                                                  "name": "edges",
                                                  "plural": true,
                                                  "selections": [
                                                    {
                                                      "concreteType": "JiraServiceManagementApprover",
                                                      "kind": "LinkedField",
                                                      "name": "node",
                                                      "plural": false,
                                                      "selections": [
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": {
                                                            "kind": "LinkedField",
                                                            "name": "approver",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "kind": "RequiredField",
                                                                "field": (v101/*: any*/),
                                                                "action": "LOG",
                                                                "path": "jira.issueByKey.fields.edges.node.completedApprovalsConnection.edges.node.approvers.edges.node.approver.accountId"
                                                              },
                                                              {
                                                                "kind": "RequiredField",
                                                                "field": (v149/*: any*/),
                                                                "action": "LOG",
                                                                "path": "jira.issueByKey.fields.edges.node.completedApprovalsConnection.edges.node.approvers.edges.node.approver.accountStatus"
                                                              },
                                                              {
                                                                "kind": "RequiredField",
                                                                "field": (v55/*: any*/),
                                                                "action": "LOG",
                                                                "path": "jira.issueByKey.fields.edges.node.completedApprovalsConnection.edges.node.approvers.edges.node.approver.name"
                                                              },
                                                              {
                                                                "kind": "RequiredField",
                                                                "field": (v102/*: any*/),
                                                                "action": "LOG",
                                                                "path": "jira.issueByKey.fields.edges.node.completedApprovalsConnection.edges.node.approvers.edges.node.approver.picture"
                                                              },
                                                              (v201/*: any*/),
                                                              (v202/*: any*/)
                                                            ]
                                                          },
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.fields.edges.node.completedApprovalsConnection.edges.node.approvers.edges.node.approver"
                                                        },
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": (v203/*: any*/),
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.fields.edges.node.completedApprovalsConnection.edges.node.approvers.edges.node.approverDecision"
                                                        }
                                                      ]
                                                    }
                                                  ]
                                                }
                                              ]
                                            },
                                            (v206/*: any*/),
                                            {
                                              "kind": "RequiredField",
                                              "field": (v210/*: any*/),
                                              "action": "LOG",
                                              "path": "jira.issueByKey.fields.edges.node.completedApprovalsConnection.edges.node.completedDate"
                                            },
                                            (v211/*: any*/)
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ],
                              "type": "JiraServiceManagementApprovalField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v212/*: any*/)
                              ],
                              "type": "JiraServiceManagementMajorIncidentField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                {
                                  "concreteType": "JiraServiceManagementOrganizationConnection",
                                  "kind": "LinkedField",
                                  "name": "selectedOrganizationsConnection",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "concreteType": "JiraServiceManagementOrganizationEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "concreteType": "JiraServiceManagementOrganization",
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": [
                                            (v213/*: any*/),
                                            (v214/*: any*/),
                                            (v215/*: any*/)
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                },
                                (v76/*: any*/)
                              ],
                              "type": "JiraServiceManagementOrganizationField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": (v159/*: any*/),
                              "type": "JiraServiceManagementPeopleField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v216/*: any*/)
                              ],
                              "type": "JiraServiceManagementRequestFeedbackField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v218/*: any*/),
                                (v219/*: any*/)
                              ],
                              "type": "JiraServiceManagementRequestLanguageField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v222/*: any*/)
                              ],
                              "type": "JiraServiceManagementRequestTypeField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v76/*: any*/),
                                {
                                  "concreteType": "JiraServiceManagementResponderConnection",
                                  "kind": "LinkedField",
                                  "name": "respondersConnection",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "concreteType": "JiraServiceManagementResponderEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": [
                                            (v63/*: any*/),
                                            {
                                              "kind": "InlineFragment",
                                              "selections": [
                                                {
                                                  "kind": "LinkedField",
                                                  "name": "user",
                                                  "plural": false,
                                                  "selections": [
                                                    (v223/*: any*/),
                                                    (v102/*: any*/),
                                                    (v55/*: any*/)
                                                  ]
                                                }
                                              ],
                                              "type": "JiraServiceManagementUserResponder"
                                            },
                                            (v225/*: any*/)
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ],
                              "type": "JiraServiceManagementRespondersField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v145/*: any*/),
                                (v76/*: any*/)
                              ],
                              "type": "JiraSingleGroupPickerField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": (v138/*: any*/),
                              "type": "JiraSingleLineTextField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v135/*: any*/),
                                (v76/*: any*/)
                              ],
                              "type": "JiraSingleSelectField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v229/*: any*/),
                                (v76/*: any*/)
                              ],
                              "type": "JiraSingleSelectUserPickerField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v232/*: any*/),
                                (v233/*: any*/)
                              ],
                              "type": "JiraSingleVersionPickerField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                {
                                  "concreteType": "JiraSprintConnection",
                                  "kind": "LinkedField",
                                  "name": "selectedSprintsConnection",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "concreteType": "JiraSprintEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "concreteType": "JiraSprint",
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": [
                                            (v56/*: any*/),
                                            {
                                              "kind": "RequiredField",
                                              "field": (v234/*: any*/),
                                              "action": "LOG",
                                              "path": "jira.issueByKey.fields.edges.node.selectedSprintsConnection.edges.node.sprintId"
                                            },
                                            {
                                              "kind": "RequiredField",
                                              "field": (v55/*: any*/),
                                              "action": "LOG",
                                              "path": "jira.issueByKey.fields.edges.node.selectedSprintsConnection.edges.node.name"
                                            },
                                            (v235/*: any*/),
                                            (v236/*: any*/),
                                            (v230/*: any*/),
                                            (v237/*: any*/),
                                            (v238/*: any*/),
                                            (v239/*: any*/)
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                },
                                (v76/*: any*/)
                              ],
                              "type": "JiraSprintField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                {
                                  "concreteType": "JiraStatus",
                                  "kind": "LinkedField",
                                  "name": "status",
                                  "plural": false,
                                  "selections": [
                                    (v56/*: any*/),
                                    {
                                      "kind": "RequiredField",
                                      "field": (v55/*: any*/),
                                      "action": "LOG",
                                      "path": "jira.issueByKey.fields.edges.node.status.name"
                                    },
                                    (v58/*: any*/),
                                    (v240/*: any*/),
                                    {
                                      "kind": "RequiredField",
                                      "field": (v242/*: any*/),
                                      "action": "LOG",
                                      "path": "jira.issueByKey.fields.edges.node.status.statusCategory"
                                    }
                                  ]
                                }
                              ],
                              "type": "JiraStatusField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                {
                                  "concreteType": "JiraTeam",
                                  "kind": "LinkedField",
                                  "name": "selectedTeam",
                                  "plural": false,
                                  "selections": [
                                    (v224/*: any*/),
                                    (v55/*: any*/),
                                    (v243/*: any*/),
                                    (v244/*: any*/)
                                  ]
                                }
                              ],
                              "type": "JiraTeamField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v245/*: any*/),
                                (v76/*: any*/)
                              ],
                              "type": "JiraTeamViewField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v169/*: any*/),
                                (v246/*: any*/),
                                (v247/*: any*/)
                              ],
                              "type": "JiraTimeTrackingField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v248/*: any*/)
                              ],
                              "type": "JiraUrlField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v250/*: any*/)
                              ],
                              "type": "JiraVotesField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v252/*: any*/)
                              ],
                              "type": "JiraWatchesField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v127/*: any*/),
                                (v133/*: any*/)
                              ],
                              "type": "JiraForgeDatetimeField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v72/*: any*/),
                                (v139/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v58/*: any*/),
                                (v133/*: any*/)
                              ],
                              "type": "JiraForgeDateField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v56/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v73/*: any*/),
                                (v254/*: any*/),
                                (v255/*: any*/),
                                (v256/*: any*/)
                              ],
                              "type": "JiraServiceManagementEntitlementField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v56/*: any*/),
                                (v55/*: any*/),
                                (v74/*: any*/),
                                (v73/*: any*/),
                                (v58/*: any*/),
                                (v257/*: any*/),
                                (v255/*: any*/),
                                (v256/*: any*/)
                              ],
                              "type": "JiraServiceManagementSentimentField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v74/*: any*/),
                                (v73/*: any*/),
                                (v55/*: any*/),
                                (v58/*: any*/),
                                (v72/*: any*/),
                                (v260/*: any*/)
                              ],
                              "type": "JiraGoalsField"
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "name": "childIssues",
                  "plural": false,
                  "selections": [
                    (v63/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "args": (v261/*: any*/),
                          "concreteType": "JiraIssueConnection",
                          "kind": "LinkedField",
                          "name": "issues",
                          "plural": false,
                          "selections": [
                            {
                              "concreteType": "JiraIssueEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "concreteType": "JiraIssue",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    (v53/*: any*/),
                                    (v52/*: any*/),
                                    (v89/*: any*/),
                                    (v262/*: any*/),
                                    {
                                      "concreteType": "JiraNumberField",
                                      "kind": "LinkedField",
                                      "name": "storyPointEstimateField",
                                      "plural": false,
                                      "selections": (v263/*: any*/)
                                    },
                                    {
                                      "concreteType": "JiraNumberField",
                                      "kind": "LinkedField",
                                      "name": "storyPointsField",
                                      "plural": false,
                                      "selections": (v263/*: any*/)
                                    },
                                    {
                                      "args": (v264/*: any*/),
                                      "concreteType": "JiraIssueFieldConnection",
                                      "kind": "LinkedField",
                                      "name": "fieldsById",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "concreteType": "JiraIssueFieldEdge",
                                          "kind": "LinkedField",
                                          "name": "edges",
                                          "plural": true,
                                          "selections": [
                                            {
                                              "kind": "LinkedField",
                                              "name": "node",
                                              "plural": false,
                                              "selections": [
                                                (v63/*: any*/),
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    (v73/*: any*/),
                                                    (v74/*: any*/),
                                                    {
                                                      "concreteType": "JiraIssueType",
                                                      "kind": "LinkedField",
                                                      "name": "issueType",
                                                      "plural": false,
                                                      "selections": [
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": (v57/*: any*/),
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.childIssues.issues.edges.node.fieldsById.edges.node.issueType.issueTypeId"
                                                        },
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": (v55/*: any*/),
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.childIssues.issues.edges.node.fieldsById.edges.node.issueType.name"
                                                        },
                                                        {
                                                          "concreteType": "JiraAvatar",
                                                          "kind": "LinkedField",
                                                          "name": "avatar",
                                                          "plural": false,
                                                          "selections": [
                                                            {
                                                              "kind": "RequiredField",
                                                              "field": (v61/*: any*/),
                                                              "action": "LOG",
                                                              "path": "jira.issueByKey.childIssues.issues.edges.node.fieldsById.edges.node.issueType.avatar.medium"
                                                            }
                                                          ]
                                                        },
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": {
                                                            "concreteType": "JiraIssueTypeHierarchyLevel",
                                                            "kind": "LinkedField",
                                                            "name": "hierarchy",
                                                            "plural": false,
                                                            "selections": [
                                                              {
                                                                "kind": "RequiredField",
                                                                "field": (v54/*: any*/),
                                                                "action": "LOG",
                                                                "path": "jira.issueByKey.childIssues.issues.edges.node.fieldsById.edges.node.issueType.hierarchy.level"
                                                              }
                                                            ]
                                                          },
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.childIssues.issues.edges.node.fieldsById.edges.node.issueType.hierarchy"
                                                        }
                                                      ]
                                                    }
                                                  ],
                                                  "type": "JiraIssueTypeField"
                                                },
                                                (v265/*: any*/),
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    (v73/*: any*/),
                                                    (v74/*: any*/),
                                                    {
                                                      "concreteType": "JiraPriority",
                                                      "kind": "LinkedField",
                                                      "name": "priority",
                                                      "plural": false,
                                                      "selections": [
                                                        (v181/*: any*/),
                                                        (v55/*: any*/),
                                                        (v183/*: any*/),
                                                        (v182/*: any*/)
                                                      ]
                                                    }
                                                  ],
                                                  "type": "JiraPriorityField"
                                                },
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    (v73/*: any*/),
                                                    (v74/*: any*/),
                                                    {
                                                      "concreteType": "JiraStatus",
                                                      "kind": "LinkedField",
                                                      "name": "status",
                                                      "plural": false,
                                                      "selections": [
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": (v55/*: any*/),
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.childIssues.issues.edges.node.fieldsById.edges.node.status.name"
                                                        },
                                                        (v240/*: any*/),
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": {
                                                            "concreteType": "JiraStatusCategory",
                                                            "kind": "LinkedField",
                                                            "name": "statusCategory",
                                                            "plural": false,
                                                            "selections": [
                                                              (v241/*: any*/)
                                                            ]
                                                          },
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.childIssues.issues.edges.node.fieldsById.edges.node.status.statusCategory"
                                                        }
                                                      ]
                                                    }
                                                  ],
                                                  "type": "JiraStatusField"
                                                },
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    (v73/*: any*/),
                                                    (v74/*: any*/),
                                                    {
                                                      "kind": "LinkedField",
                                                      "name": "user",
                                                      "plural": false,
                                                      "selections": [
                                                        (v63/*: any*/),
                                                        (v101/*: any*/),
                                                        (v149/*: any*/),
                                                        (v55/*: any*/),
                                                        (v102/*: any*/)
                                                      ]
                                                    }
                                                  ],
                                                  "type": "JiraSingleSelectUserPickerField"
                                                },
                                                (v266/*: any*/),
                                                (v267/*: any*/)
                                              ]
                                            }
                                          ]
                                        }
                                      ],
                                      "storageKey": "fieldsById(ids:[\"assignee\",\"created\",\"issuetype\",\"priority\",\"status\",\"summary\",\"timetracking\",\"updated\"])"
                                    }
                                  ]
                                }
                              ]
                            }
                          ],
                          "storageKey": "issues(activeProjectsOnly:true)"
                        }
                      ],
                      "type": "JiraChildIssuesWithinLimit"
                    },
                    (v268/*: any*/)
                  ]
                },
                {
                  "args": (v269/*: any*/),
                  "concreteType": "JiraIssueLinkConnection",
                  "kind": "LinkedField",
                  "name": "issueLinks",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraIssueLinkEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "concreteType": "JiraIssueLink",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v56/*: any*/),
                            {
                              "kind": "RequiredField",
                              "field": (v270/*: any*/),
                              "action": "LOG",
                              "path": "jira.issueByKey.issueLinks.edges.node.issueLinkId"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v271/*: any*/),
                              "action": "LOG",
                              "path": "jira.issueByKey.issueLinks.edges.node.direction"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v272/*: any*/),
                              "action": "LOG",
                              "path": "jira.issueByKey.issueLinks.edges.node.relationName"
                            },
                            {
                              "kind": "RequiredField",
                              "field": {
                                "concreteType": "JiraIssueLinkType",
                                "kind": "LinkedField",
                                "name": "type",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "RequiredField",
                                    "field": (v46/*: any*/),
                                    "action": "LOG",
                                    "path": "jira.issueByKey.issueLinks.edges.node.type.linkTypeId"
                                  }
                                ]
                              },
                              "action": "LOG",
                              "path": "jira.issueByKey.issueLinks.edges.node.type"
                            },
                            {
                              "concreteType": "JiraIssue",
                              "kind": "LinkedField",
                              "name": "issue",
                              "plural": false,
                              "selections": [
                                (v56/*: any*/),
                                (v52/*: any*/),
                                (v53/*: any*/),
                                (v89/*: any*/),
                                (v262/*: any*/),
                                {
                                  "args": (v273/*: any*/),
                                  "concreteType": "JiraIssueFieldConnection",
                                  "kind": "LinkedField",
                                  "name": "fieldsById",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "concreteType": "JiraIssueFieldEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "kind": "LinkedField",
                                          "name": "node",
                                          "plural": false,
                                          "selections": [
                                            (v63/*: any*/),
                                            {
                                              "kind": "InlineFragment",
                                              "selections": [
                                                (v73/*: any*/),
                                                (v55/*: any*/),
                                                (v74/*: any*/),
                                                {
                                                  "concreteType": "JiraStatus",
                                                  "kind": "LinkedField",
                                                  "name": "status",
                                                  "plural": false,
                                                  "selections": [
                                                    (v56/*: any*/),
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": (v55/*: any*/),
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.issueLinks.edges.node.issue.fieldsById.edges.node.status.name"
                                                    },
                                                    (v240/*: any*/),
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": {
                                                        "concreteType": "JiraStatusCategory",
                                                        "kind": "LinkedField",
                                                        "name": "statusCategory",
                                                        "plural": false,
                                                        "selections": [
                                                          (v56/*: any*/),
                                                          (v241/*: any*/),
                                                          (v53/*: any*/),
                                                          {
                                                            "kind": "RequiredField",
                                                            "field": (v55/*: any*/),
                                                            "action": "LOG",
                                                            "path": "jira.issueByKey.issueLinks.edges.node.issue.fieldsById.edges.node.status.statusCategory.name"
                                                          },
                                                          (v274/*: any*/)
                                                        ]
                                                      },
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.issueLinks.edges.node.issue.fieldsById.edges.node.status.statusCategory"
                                                    }
                                                  ]
                                                }
                                              ],
                                              "type": "JiraStatusField"
                                            },
                                            {
                                              "kind": "InlineFragment",
                                              "selections": [
                                                (v73/*: any*/),
                                                (v55/*: any*/),
                                                (v74/*: any*/),
                                                {
                                                  "concreteType": "JiraPriority",
                                                  "kind": "LinkedField",
                                                  "name": "priority",
                                                  "plural": false,
                                                  "selections": [
                                                    (v181/*: any*/),
                                                    (v55/*: any*/),
                                                    (v183/*: any*/),
                                                    (v182/*: any*/),
                                                    (v140/*: any*/)
                                                  ]
                                                }
                                              ],
                                              "type": "JiraPriorityField"
                                            },
                                            {
                                              "kind": "InlineFragment",
                                              "selections": [
                                                (v73/*: any*/),
                                                (v55/*: any*/),
                                                (v74/*: any*/),
                                                {
                                                  "concreteType": "JiraIssueType",
                                                  "kind": "LinkedField",
                                                  "name": "issueType",
                                                  "plural": false,
                                                  "selections": [
                                                    (v56/*: any*/),
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": (v57/*: any*/),
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.issueLinks.edges.node.issue.fieldsById.edges.node.issueType.issueTypeId"
                                                    },
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": (v55/*: any*/),
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.issueLinks.edges.node.issue.fieldsById.edges.node.issueType.name"
                                                    },
                                                    {
                                                      "concreteType": "JiraAvatar",
                                                      "kind": "LinkedField",
                                                      "name": "avatar",
                                                      "plural": false,
                                                      "selections": [
                                                        (v59/*: any*/),
                                                        (v60/*: any*/),
                                                        {
                                                          "kind": "RequiredField",
                                                          "field": (v61/*: any*/),
                                                          "action": "LOG",
                                                          "path": "jira.issueByKey.issueLinks.edges.node.issue.fieldsById.edges.node.issueType.avatar.medium"
                                                        },
                                                        (v62/*: any*/)
                                                      ]
                                                    },
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": {
                                                        "concreteType": "JiraIssueTypeHierarchyLevel",
                                                        "kind": "LinkedField",
                                                        "name": "hierarchy",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "kind": "RequiredField",
                                                            "field": (v54/*: any*/),
                                                            "action": "LOG",
                                                            "path": "jira.issueByKey.issueLinks.edges.node.issue.fieldsById.edges.node.issueType.hierarchy.level"
                                                          },
                                                          {
                                                            "kind": "RequiredField",
                                                            "field": (v55/*: any*/),
                                                            "action": "LOG",
                                                            "path": "jira.issueByKey.issueLinks.edges.node.issue.fieldsById.edges.node.issueType.hierarchy.name"
                                                          }
                                                        ]
                                                      },
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.issueLinks.edges.node.issue.fieldsById.edges.node.issueType.hierarchy"
                                                    }
                                                  ]
                                                }
                                              ],
                                              "type": "JiraIssueTypeField"
                                            },
                                            (v275/*: any*/),
                                            {
                                              "kind": "InlineFragment",
                                              "selections": [
                                                (v73/*: any*/),
                                                (v55/*: any*/),
                                                (v74/*: any*/),
                                                (v229/*: any*/)
                                              ],
                                              "type": "JiraSingleSelectUserPickerField"
                                            },
                                            {
                                              "kind": "InlineFragment",
                                              "selections": [
                                                (v73/*: any*/),
                                                (v55/*: any*/),
                                                (v74/*: any*/),
                                                (v58/*: any*/),
                                                (v76/*: any*/),
                                                {
                                                  "concreteType": "JiraProject",
                                                  "kind": "LinkedField",
                                                  "name": "project",
                                                  "plural": false,
                                                  "selections": [
                                                    (v53/*: any*/),
                                                    (v55/*: any*/),
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": (v172/*: any*/),
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.issueLinks.edges.node.issue.fieldsById.edges.node.project.projectId"
                                                    },
                                                    (v174/*: any*/),
                                                    (v173/*: any*/),
                                                    (v175/*: any*/),
                                                    (v161/*: any*/),
                                                    (v176/*: any*/),
                                                    {
                                                      "kind": "RequiredField",
                                                      "field": {
                                                        "concreteType": "JiraAvatar",
                                                        "kind": "LinkedField",
                                                        "name": "avatar",
                                                        "plural": false,
                                                        "selections": [
                                                          (v61/*: any*/),
                                                          {
                                                            "kind": "RequiredField",
                                                            "field": (v62/*: any*/),
                                                            "action": "LOG",
                                                            "path": "jira.issueByKey.issueLinks.edges.node.issue.fieldsById.edges.node.project.avatar.large"
                                                          }
                                                        ]
                                                      },
                                                      "action": "LOG",
                                                      "path": "jira.issueByKey.issueLinks.edges.node.issue.fieldsById.edges.node.project.avatar"
                                                    },
                                                    (v178/*: any*/)
                                                  ]
                                                }
                                              ],
                                              "type": "JiraProjectField"
                                            }
                                          ]
                                        }
                                      ]
                                    }
                                  ],
                                  "storageKey": "fieldsById(ids:[\"assignee\",\"issuetype\",\"priority\",\"status\",\"summary\",\"project\"])"
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "condition": "isJPDPanelEnabled",
                      "kind": "Condition",
                      "passingValue": true,
                      "selections": [
                        {
                          "kind": "FragmentSpread",
                          "name": "ui_issueViewJpdIdeas_JPDIdeasPanel"
                        }
                      ]
                    }
                  ],
                  "storageKey": "issueLinks(first:1000)"
                },
                {
                  "condition": "issueViewRelayAttachmentsFlag",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    (v278/*: any*/),
                    {
                      "kind": "LinkedField",
                      "name": "mediaUploadToken",
                      "plural": false,
                      "selections": [
                        (v279/*: any*/),
                        (v280/*: any*/)
                      ]
                    }
                  ]
                },
                (v281/*: any*/),
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayAssigneeFieldFlag",
                      "variableName": "issueViewRelayAssigneeFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayCascadingSelectFlag",
                      "variableName": "issueViewRelayCascadingSelectFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayDateFieldFlag",
                      "variableName": "issueViewRelayDateFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayDateTimeFieldFlag",
                      "variableName": "issueViewRelayDateTimeFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayGoalsFlag",
                      "variableName": "issueViewRelayGoalsFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayNumberFieldFlag",
                      "variableName": "issueViewRelayNumberFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayPeopleAndApproversFieldFlag",
                      "variableName": "issueViewRelayPeopleAndApproversFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayPriorityFieldFlag",
                      "variableName": "issueViewRelayPriorityFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayProjectFieldFlag",
                      "variableName": "issueViewRelayProjectFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelaySingleLineTextFieldFlag",
                      "variableName": "issueViewRelaySingleLineTextFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelaySprintFieldFlag",
                      "variableName": "issueViewRelaySprintFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayUrlFieldFlag",
                      "variableName": "issueViewRelayUrlFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "jsmSentimentCustomFieldFlag",
                      "variableName": "jsmSentimentCustomFieldFlag"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "src_issueViewLayoutTemplatesItemList_ItemList"
                },
                {
                  "kind": "FragmentSpread",
                  "name": "prefetchFieldSuggestions_issueView_MaybePrefetchFieldSuggestions"
                },
                {
                  "kind": "FragmentSpread",
                  "name": "ui_issueFieldDataClassification_IssueViewDataClassification"
                },
                {
                  "condition": "issueViewRelayMetadataFieldFlag",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ui_issueViewLayoutMetaContainer_IssueViewMetaContainer"
                    }
                  ]
                },
                {
                  "condition": "issueViewRelayHeaders",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "header_issueViewFoundation_HeaderItemWithoutCompactMode"
                    },
                    {
                      "kind": "FragmentSpread",
                      "name": "headerActions_issueViewFoundation_HeaderActionsView"
                    }
                  ]
                },
                {
                  "condition": "issueViewRelayParentFieldFlag",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "headerBreadcrumbs_issueViewFoundation_HeaderBreadcrumbsView"
                    }
                  ]
                },
                {
                  "condition": "issueViewRelaySummaryFieldFlag",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "ui_issueViewLayoutSummaryField_IssueViewSummaryField"
                    }
                  ]
                },
                {
                  "condition": "issueViewRelayColorFieldFlag",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "concreteType": "JiraColorField",
                      "kind": "LinkedField",
                      "name": "issueColorField",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "FragmentSpread",
                          "name": "ui_issueViewLayoutColorField_IssueViewColorField"
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "FragmentSpread",
                  "name": "view_issueViewFoundation_StatusView"
                },
                {
                  "condition": "giraAGGMigrationAttachmentsFlag",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": "attachments",
                      "args": [
                        (v282/*: any*/)
                      ],
                      "concreteType": "JiraAttachmentConnection",
                      "kind": "LinkedField",
                      "name": "__issue_attachments_connection",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraAttachmentEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v63/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v283/*: any*/),
                                    (v284/*: any*/),
                                    (v285/*: any*/),
                                    (v286/*: any*/),
                                    (v287/*: any*/),
                                    (v288/*: any*/),
                                    (v289/*: any*/),
                                    {
                                      "kind": "LinkedField",
                                      "name": "author",
                                      "plural": false,
                                      "selections": [
                                        (v101/*: any*/)
                                      ]
                                    },
                                    (v290/*: any*/),
                                    (v291/*: any*/)
                                  ],
                                  "type": "JiraPlatformAttachment"
                                }
                              ]
                            },
                            (v292/*: any*/)
                          ]
                        },
                        (v293/*: any*/)
                      ],
                      "storageKey": "__issue_attachments_connection(sortBy:{\"field\":\"CREATED\",\"order\":\"DESC\"})"
                    }
                  ]
                },
                {
                  "condition": "giraAGGMigrationCommentsFlag",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "args": (v294/*: any*/),
                      "concreteType": "JiraCommentConnection",
                      "kind": "LinkedField",
                      "name": "comments",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraCommentEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v63/*: any*/),
                                (v295/*: any*/),
                                (v287/*: any*/),
                                (v296/*: any*/),
                                {
                                  "kind": "LinkedField",
                                  "name": "author",
                                  "plural": false,
                                  "selections": (v297/*: any*/)
                                },
                                {
                                  "kind": "LinkedField",
                                  "name": "updateAuthor",
                                  "plural": false,
                                  "selections": (v297/*: any*/)
                                },
                                (v298/*: any*/),
                                (v300/*: any*/),
                                (v301/*: any*/)
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            (v302/*: any*/)
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v11/*: any*/),
      (v32/*: any*/),
      (v0/*: any*/),
      (v14/*: any*/),
      (v4/*: any*/),
      (v31/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/),
      (v10/*: any*/),
      (v18/*: any*/),
      (v5/*: any*/),
      (v30/*: any*/),
      (v8/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v12/*: any*/),
      (v21/*: any*/),
      (v24/*: any*/),
      (v27/*: any*/),
      (v13/*: any*/),
      (v26/*: any*/),
      (v16/*: any*/),
      (v25/*: any*/),
      (v17/*: any*/),
      (v20/*: any*/),
      (v7/*: any*/),
      (v29/*: any*/),
      (v19/*: any*/),
      (v22/*: any*/),
      (v28/*: any*/),
      (v23/*: any*/),
      (v9/*: any*/),
      (v15/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldstimetrackingrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscheckboxselectrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscomponentsrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsoriginalestimaterelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsgrouppickerrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldspeoplerelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsapproversrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssinglelinetextmessagerelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsassigneerelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsreporterrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsuserrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsheaderactionsrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldswatchersrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssecuritylevelrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuetypeswitcherrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsresolutionrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "mainIssueAggQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          (v35/*: any*/),
          (v36/*: any*/),
          (v37/*: any*/),
          (v43/*: any*/),
          (v45/*: any*/),
          {
            "args": (v34/*: any*/),
            "concreteType": "JiraIssueLinkTypeConnection",
            "kind": "LinkedField",
            "name": "issueLinkTypes",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueLinkTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraIssueLinkType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v46/*: any*/),
                      (v47/*: any*/),
                      (v48/*: any*/),
                      (v56/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          {
            "args": (v49/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              (v50/*: any*/),
              (v51/*: any*/),
              (v52/*: any*/),
              (v53/*: any*/),
              {
                "concreteType": "JiraIssueTypeHierarchyLevel",
                "kind": "LinkedField",
                "name": "hierarchyLevelAbove",
                "plural": false,
                "selections": (v303/*: any*/)
              },
              {
                "concreteType": "JiraIssueTypeHierarchyLevel",
                "kind": "LinkedField",
                "name": "hierarchyLevelBelow",
                "plural": false,
                "selections": (v303/*: any*/)
              },
              {
                "concreteType": "JiraIssueTypeConnection",
                "kind": "LinkedField",
                "name": "issueTypesForHierarchyAbove",
                "plural": false,
                "selections": (v306/*: any*/)
              },
              {
                "concreteType": "JiraIssueTypeConnection",
                "kind": "LinkedField",
                "name": "issueTypesForHierarchyBelow",
                "plural": false,
                "selections": (v306/*: any*/)
              },
              {
                "concreteType": "JiraIssueTypeConnection",
                "kind": "LinkedField",
                "name": "issueTypesForHierarchySame",
                "plural": false,
                "selections": (v306/*: any*/)
              },
              {
                "alias": "fieldFragments",
                "concreteType": "JiraIssueFieldConnection",
                "kind": "LinkedField",
                "name": "fields",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueFieldEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v63/*: any*/),
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isJiraIssueField"
                          },
                          (v73/*: any*/),
                          (v74/*: any*/),
                          (v56/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsgrouppickerrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v55/*: any*/),
                                      (v58/*: any*/),
                                      (v307/*: any*/),
                                      (v308/*: any*/),
                                      {
                                        "concreteType": "JiraGroup",
                                        "kind": "LinkedField",
                                        "name": "selectedGroup",
                                        "plural": false,
                                        "selections": (v299/*: any*/)
                                      }
                                    ],
                                    "type": "JiraSingleGroupPickerField"
                                  }
                                ]
                              },
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserrelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": [
                                          (v55/*: any*/),
                                          (v58/*: any*/),
                                          (v68/*: any*/),
                                          (v308/*: any*/),
                                          {
                                            "concreteType": "JiraUserConnection",
                                            "kind": "LinkedField",
                                            "name": "selectedUsersConnection",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraUserEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      (v63/*: any*/),
                                                      (v101/*: any*/),
                                                      (v56/*: any*/),
                                                      (v55/*: any*/),
                                                      (v102/*: any*/),
                                                      (v309/*: any*/)
                                                    ]
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ],
                                    "type": "JiraMultipleSelectUserPickerField"
                                  }
                                ]
                              },
                              {
                                "condition": "issueViewRelayPeopleAndApproversFieldFlag",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldspeoplerelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": (v312/*: any*/)
                                      },
                                      {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsapproversrelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": (v312/*: any*/)
                                      }
                                    ],
                                    "type": "JiraPeopleField"
                                  }
                                ]
                              },
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldstimetrackingrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v55/*: any*/),
                                      {
                                        "concreteType": "JiraTimeTrackingSettings",
                                        "kind": "LinkedField",
                                        "name": "timeTrackingSettings",
                                        "plural": false,
                                        "selections": [
                                          (v38/*: any*/),
                                          (v40/*: any*/),
                                          (v39/*: any*/),
                                          (v42/*: any*/),
                                          (v41/*: any*/)
                                        ]
                                      }
                                    ],
                                    "type": "JiraTimeTrackingField"
                                  }
                                ]
                              },
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscheckboxselectrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v55/*: any*/),
                                      (v58/*: any*/),
                                      (v68/*: any*/),
                                      {
                                        "concreteType": "JiraOptionConnection",
                                        "kind": "LinkedField",
                                        "name": "selectedOptions",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "JiraOptionEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "concreteType": "JiraOption",
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                  (v56/*: any*/),
                                                  (v111/*: any*/),
                                                  (v77/*: any*/)
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      },
                                      {
                                        "concreteType": "JiraOptionConnection",
                                        "kind": "LinkedField",
                                        "name": "fieldOptions",
                                        "plural": false,
                                        "selections": (v117/*: any*/)
                                      }
                                    ],
                                    "type": "JiraCheckboxesField"
                                  }
                                ]
                              },
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscomponentsrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v55/*: any*/),
                                      (v58/*: any*/),
                                      (v68/*: any*/),
                                      (v308/*: any*/),
                                      {
                                        "concreteType": "JiraComponentConnection",
                                        "kind": "LinkedField",
                                        "name": "selectedComponentsConnection",
                                        "plural": false,
                                        "selections": (v313/*: any*/)
                                      },
                                      {
                                        "concreteType": "JiraComponentConnection",
                                        "kind": "LinkedField",
                                        "name": "components",
                                        "plural": false,
                                        "selections": (v313/*: any*/)
                                      }
                                    ],
                                    "type": "JiraComponentsField"
                                  }
                                ]
                              },
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsoriginalestimaterelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v169/*: any*/),
                                      {
                                        "concreteType": "JiraTimeTrackingSettings",
                                        "kind": "LinkedField",
                                        "name": "timeTrackingSettings",
                                        "plural": false,
                                        "selections": [
                                          (v39/*: any*/),
                                          (v40/*: any*/),
                                          (v41/*: any*/),
                                          (v42/*: any*/)
                                        ]
                                      },
                                      (v55/*: any*/),
                                      (v68/*: any*/),
                                      (v311/*: any*/)
                                    ],
                                    "type": "JiraTimeTrackingField"
                                  }
                                ]
                              },
                              {
                                "condition": "issueViewRelayProjectFieldFlag",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v55/*: any*/),
                                      {
                                        "concreteType": "JiraProject",
                                        "kind": "LinkedField",
                                        "name": "project",
                                        "plural": false,
                                        "selections": [
                                          (v53/*: any*/),
                                          (v55/*: any*/),
                                          {
                                            "concreteType": "JiraAvatar",
                                            "kind": "LinkedField",
                                            "name": "avatar",
                                            "plural": false,
                                            "selections": [
                                              (v60/*: any*/),
                                              (v62/*: any*/)
                                            ]
                                          },
                                          (v56/*: any*/),
                                          (v172/*: any*/)
                                        ]
                                      },
                                      (v68/*: any*/),
                                      (v311/*: any*/)
                                    ],
                                    "type": "JiraProjectField"
                                  }
                                ]
                              },
                              {
                                "condition": "issueViewRelayUrlFieldFlag",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v55/*: any*/),
                                      (v248/*: any*/),
                                      (v68/*: any*/),
                                      (v311/*: any*/)
                                    ],
                                    "type": "JiraUrlField"
                                  }
                                ]
                              },
                              {
                                "condition": "issueViewRelaySingleLineTextFieldFlag",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v137/*: any*/),
                                      (v55/*: any*/),
                                      (v68/*: any*/),
                                      {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssinglelinetextmessagerelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": (v310/*: any*/)
                                      },
                                      (v311/*: any*/)
                                    ],
                                    "type": "JiraSingleLineTextField"
                                  }
                                ]
                              },
                              {
                                "condition": "issueViewRelayAssigneeFieldFlag",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsassigneerelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": (v314/*: any*/)
                                      },
                                      {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsreporterrelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": (v314/*: any*/)
                                      },
                                      {
                                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsuserrelayprovider",
                                        "kind": "Condition",
                                        "passingValue": true,
                                        "selections": (v314/*: any*/)
                                      }
                                    ],
                                    "type": "JiraSingleSelectUserPickerField"
                                  }
                                ]
                              },
                              {
                                "condition": "issueViewRelayDateFieldFlag",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v139/*: any*/),
                                      (v55/*: any*/),
                                      (v166/*: any*/),
                                      (v311/*: any*/)
                                    ],
                                    "type": "JiraDatePickerField"
                                  }
                                ]
                              },
                              {
                                "condition": "issueViewRelayDateTimeFieldFlag",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v127/*: any*/),
                                      (v68/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v55/*: any*/),
                                          (v58/*: any*/)
                                        ],
                                        "type": "JiraIssueField",
                                        "abstractKey": "__isJiraIssueField"
                                      }
                                    ],
                                    "type": "JiraDateTimePickerField"
                                  }
                                ]
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "args": (v269/*: any*/),
                                    "concreteType": "JiraLabelConnection",
                                    "kind": "LinkedField",
                                    "name": "selectedLabelsConnection",
                                    "plural": false,
                                    "selections": [
                                      (v259/*: any*/),
                                      {
                                        "concreteType": "JiraLabelEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "concreteType": "JiraLabel",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": (v104/*: any*/)
                                          }
                                        ]
                                      }
                                    ],
                                    "storageKey": "selectedLabelsConnection(first:1000)"
                                  },
                                  (v55/*: any*/),
                                  (v68/*: any*/),
                                  (v311/*: any*/)
                                ],
                                "type": "JiraLabelsField"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v55/*: any*/),
                                  {
                                    "concreteType": "JiraOption",
                                    "kind": "LinkedField",
                                    "name": "fieldOption",
                                    "plural": false,
                                    "selections": [
                                      (v77/*: any*/),
                                      (v56/*: any*/),
                                      (v111/*: any*/)
                                    ]
                                  },
                                  (v68/*: any*/),
                                  (v311/*: any*/)
                                ],
                                "type": "JiraSingleSelectField"
                              },
                              {
                                "condition": "jsmSentimentCustomFieldFlag",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v257/*: any*/),
                                      (v55/*: any*/),
                                      (v68/*: any*/),
                                      (v311/*: any*/)
                                    ],
                                    "type": "JiraServiceManagementSentimentField"
                                  }
                                ]
                              },
                              {
                                "condition": "issueViewRelayNumberFieldFlag",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v130/*: any*/),
                                      (v55/*: any*/),
                                      (v68/*: any*/),
                                      (v69/*: any*/),
                                      (v165/*: any*/),
                                      (v311/*: any*/)
                                    ],
                                    "type": "JiraNumberField"
                                  }
                                ]
                              },
                              {
                                "condition": "issueViewRelayCascadingSelectFlag",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v55/*: any*/),
                                      {
                                        "concreteType": "JiraCascadingOption",
                                        "kind": "LinkedField",
                                        "name": "cascadingOption",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "JiraOption",
                                            "kind": "LinkedField",
                                            "name": "parentOptionValue",
                                            "plural": false,
                                            "selections": (v315/*: any*/)
                                          },
                                          {
                                            "concreteType": "JiraOption",
                                            "kind": "LinkedField",
                                            "name": "childOptionValue",
                                            "plural": false,
                                            "selections": (v315/*: any*/)
                                          }
                                        ]
                                      },
                                      (v68/*: any*/),
                                      (v311/*: any*/)
                                    ],
                                    "type": "JiraCascadingSelectField"
                                  }
                                ]
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v55/*: any*/),
                                  (v254/*: any*/)
                                ],
                                "type": "JiraServiceManagementEntitlementField"
                              },
                              {
                                "condition": "issueViewRelayGoalsFlag",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v55/*: any*/),
                                      (v68/*: any*/),
                                      {
                                        "args": (v258/*: any*/),
                                        "concreteType": "JiraGoalConnection",
                                        "kind": "LinkedField",
                                        "name": "selectedGoals",
                                        "plural": false,
                                        "selections": [
                                          (v259/*: any*/),
                                          {
                                            "concreteType": "JiraGoalEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "concreteType": "JiraGoal",
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                  (v56/*: any*/),
                                                  (v55/*: any*/),
                                                  (v161/*: any*/),
                                                  (v63/*: any*/)
                                                ]
                                              },
                                              (v292/*: any*/)
                                            ]
                                          },
                                          (v293/*: any*/)
                                        ],
                                        "storageKey": "selectedGoals(first:1)"
                                      },
                                      {
                                        "args": (v258/*: any*/),
                                        "handle": "connection",
                                        "key": "issue_view_goals_field_selectedGoals",
                                        "kind": "LinkedHandle",
                                        "name": "selectedGoals"
                                      },
                                      (v311/*: any*/)
                                    ],
                                    "type": "JiraGoalsField"
                                  }
                                ]
                              },
                              {
                                "condition": "issueViewRelayPriorityFieldFlag",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "concreteType": "JiraPriority",
                                        "kind": "LinkedField",
                                        "name": "priority",
                                        "plural": false,
                                        "selections": [
                                          (v55/*: any*/),
                                          (v182/*: any*/),
                                          (v56/*: any*/)
                                        ]
                                      },
                                      (v55/*: any*/),
                                      (v68/*: any*/),
                                      (v311/*: any*/)
                                    ],
                                    "type": "JiraPriorityField"
                                  }
                                ]
                              },
                              {
                                "condition": "issueViewRelaySprintFieldFlag",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "concreteType": "JiraSprintConnection",
                                        "kind": "LinkedField",
                                        "name": "selectedSprintsConnection",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "JiraSprintEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "concreteType": "JiraSprint",
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                  (v56/*: any*/),
                                                  (v234/*: any*/),
                                                  (v55/*: any*/),
                                                  (v235/*: any*/),
                                                  (v237/*: any*/)
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      },
                                      (v55/*: any*/),
                                      (v68/*: any*/),
                                      (v311/*: any*/)
                                    ],
                                    "type": "JiraSprintField"
                                  }
                                ]
                              },
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssue",
                                        "kind": "LinkedField",
                                        "name": "parentIssue",
                                        "plural": false,
                                        "selections": [
                                          (v53/*: any*/),
                                          (v317/*: any*/),
                                          {
                                            "concreteType": "JiraIssueTypeField",
                                            "kind": "LinkedField",
                                            "name": "issueTypeField",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssueType",
                                                "kind": "LinkedField",
                                                "name": "issueType",
                                                "plural": false,
                                                "selections": [
                                                  (v318/*: any*/),
                                                  (v55/*: any*/),
                                                  (v56/*: any*/)
                                                ]
                                              },
                                              (v56/*: any*/)
                                            ]
                                          },
                                          (v319/*: any*/),
                                          (v56/*: any*/),
                                          (v52/*: any*/),
                                          (v89/*: any*/),
                                          {
                                            "args": [
                                              {
                                                "kind": "Literal",
                                                "name": "ids",
                                                "value": [
                                                  "summary",
                                                  "issuetype",
                                                  "status"
                                                ]
                                              }
                                            ],
                                            "concreteType": "JiraIssueFieldConnection",
                                            "kind": "LinkedField",
                                            "name": "fieldsById",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssueFieldEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      (v63/*: any*/),
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v73/*: any*/),
                                                          (v137/*: any*/)
                                                        ],
                                                        "type": "JiraSingleLineTextField"
                                                      },
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v73/*: any*/),
                                                          {
                                                            "concreteType": "JiraIssueType",
                                                            "kind": "LinkedField",
                                                            "name": "issueType",
                                                            "plural": false,
                                                            "selections": [
                                                              (v57/*: any*/),
                                                              (v55/*: any*/),
                                                              (v318/*: any*/),
                                                              (v320/*: any*/),
                                                              (v56/*: any*/)
                                                            ]
                                                          }
                                                        ],
                                                        "type": "JiraIssueTypeField"
                                                      },
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v73/*: any*/),
                                                          (v322/*: any*/)
                                                        ],
                                                        "type": "JiraStatusField"
                                                      },
                                                      (v56/*: any*/)
                                                    ]
                                                  }
                                                ]
                                              }
                                            ],
                                            "storageKey": "fieldsById(ids:[\"summary\",\"issuetype\",\"status\"])"
                                          }
                                        ]
                                      },
                                      (v55/*: any*/),
                                      (v68/*: any*/)
                                    ],
                                    "type": "JiraParentIssueField"
                                  }
                                ]
                              }
                            ],
                            "type": "Node",
                            "abstractKey": "__isNode"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "alias": "status",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "ids",
                    "value": [
                      "status"
                    ]
                  }
                ],
                "concreteType": "JiraIssueFieldConnection",
                "kind": "LinkedField",
                "name": "fieldsById",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueFieldEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v63/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v322/*: any*/)
                            ],
                            "type": "JiraStatusField"
                          },
                          (v56/*: any*/)
                        ]
                      }
                    ]
                  }
                ],
                "storageKey": "fieldsById(ids:[\"status\"])"
              },
              {
                "alias": "reporter",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "ids",
                    "value": [
                      "reporter"
                    ]
                  }
                ],
                "concreteType": "JiraIssueFieldConnection",
                "kind": "LinkedField",
                "name": "fieldsById",
                "plural": false,
                "selections": (v324/*: any*/),
                "storageKey": "fieldsById(ids:[\"reporter\"])"
              },
              {
                "alias": "assignee",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "ids",
                    "value": [
                      "assignee"
                    ]
                  }
                ],
                "concreteType": "JiraIssueFieldConnection",
                "kind": "LinkedField",
                "name": "fieldsById",
                "plural": false,
                "selections": (v324/*: any*/),
                "storageKey": "fieldsById(ids:[\"assignee\"])"
              },
              {
                "concreteType": "JiraIssueFieldConnection",
                "kind": "LinkedField",
                "name": "fields",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueFieldEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v63/*: any*/),
                          (v56/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraClassificationLevel",
                                "kind": "LinkedField",
                                "name": "classificationLevel",
                                "plural": false,
                                "selections": [
                                  (v55/*: any*/),
                                  (v316/*: any*/),
                                  (v66/*: any*/),
                                  (v56/*: any*/),
                                  (v325/*: any*/)
                                ]
                              },
                              {
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "filterByCriteria",
                                    "value": {
                                      "filterByStatus": [
                                        "PUBLISHED"
                                      ],
                                      "filterByType": [
                                        "USER"
                                      ]
                                    }
                                  },
                                  {
                                    "kind": "Literal",
                                    "name": "first",
                                    "value": 10
                                  }
                                ],
                                "concreteType": "JiraClassificationLevelConnection",
                                "kind": "LinkedField",
                                "name": "classificationLevels",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraClassificationLevelEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraClassificationLevel",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": (v326/*: any*/)
                                      }
                                    ]
                                  }
                                ],
                                "storageKey": "classificationLevels(filterByCriteria:{\"filterByStatus\":[\"PUBLISHED\"],\"filterByType\":[\"USER\"]},first:10)"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "classificationLevelSource"
                              },
                              {
                                "concreteType": "JiraClassificationLevel",
                                "kind": "LinkedField",
                                "name": "defaultClassificationLevel",
                                "plural": false,
                                "selections": (v326/*: any*/)
                              },
                              (v68/*: any*/)
                            ],
                            "type": "JiraDataClassificationField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              {
                                "concreteType": "JiraAffectedServiceConnection",
                                "kind": "LinkedField",
                                "name": "selectedAffectedServicesConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraAffectedServiceEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraAffectedService",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v75/*: any*/),
                                          (v55/*: any*/),
                                          (v56/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v76/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraAffectedServicesField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v78/*: any*/),
                              (v76/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraAssetField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v77/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraBooleanField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v76/*: any*/),
                              (v79/*: any*/),
                              (v80/*: any*/),
                              (v83/*: any*/),
                              {
                                "concreteType": "JiraCmdbObjectConnection",
                                "kind": "LinkedField",
                                "name": "selectedCmdbObjectsConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraCmdbObjectEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraCmdbObject",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v84/*: any*/),
                                          (v85/*: any*/),
                                          (v86/*: any*/),
                                          (v87/*: any*/),
                                          (v88/*: any*/),
                                          (v89/*: any*/),
                                          (v109/*: any*/),
                                          {
                                            "concreteType": "JiraCmdbObjectType",
                                            "kind": "LinkedField",
                                            "name": "objectType",
                                            "plural": false,
                                            "selections": [
                                              (v93/*: any*/),
                                              (v55/*: any*/),
                                              (v82/*: any*/),
                                              (v94/*: any*/)
                                            ]
                                          },
                                          {
                                            "concreteType": "JiraCmdbAttributeConnection",
                                            "kind": "LinkedField",
                                            "name": "attributes",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraCmdbAttributeEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraCmdbAttribute",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      (v95/*: any*/),
                                                      (v96/*: any*/),
                                                      (v98/*: any*/),
                                                      {
                                                        "concreteType": "JiraCmdbObjectAttributeValueConnection",
                                                        "kind": "LinkedField",
                                                        "name": "objectAttributeValues",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "concreteType": "JiraCmdbObjectAttributeValueEdge",
                                                            "kind": "LinkedField",
                                                            "name": "edges",
                                                            "plural": true,
                                                            "selections": [
                                                              {
                                                                "concreteType": "JiraCmdbObjectAttributeValue",
                                                                "kind": "LinkedField",
                                                                "name": "node",
                                                                "plural": false,
                                                                "selections": [
                                                                  (v99/*: any*/),
                                                                  (v100/*: any*/),
                                                                  (v77/*: any*/),
                                                                  (v327/*: any*/),
                                                                  (v103/*: any*/),
                                                                  {
                                                                    "concreteType": "JiraGroup",
                                                                    "kind": "LinkedField",
                                                                    "name": "group",
                                                                    "plural": false,
                                                                    "selections": [
                                                                      (v55/*: any*/),
                                                                      (v56/*: any*/)
                                                                    ]
                                                                  },
                                                                  (v105/*: any*/),
                                                                  (v107/*: any*/),
                                                                  (v108/*: any*/),
                                                                  (v110/*: any*/)
                                                                ]
                                                              }
                                                            ]
                                                          }
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v255/*: any*/)
                            ],
                            "type": "JiraCMDBField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v115/*: any*/),
                              (v255/*: any*/),
                              (v116/*: any*/)
                            ],
                            "type": "JiraCascadingSelectField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v118/*: any*/),
                              (v119/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraCheckboxesField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v120/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraColorField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v125/*: any*/),
                              (v126/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraComponentsField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v328/*: any*/),
                            "type": "JiraConnectDateTimeField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v118/*: any*/),
                              (v129/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraConnectMultipleSelectField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v130/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraConnectNumberField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v329/*: any*/),
                            "type": "JiraConnectRichTextField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v135/*: any*/),
                              (v136/*: any*/),
                              (v76/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraConnectSingleSelectField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v330/*: any*/),
                            "type": "JiraConnectTextField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v139/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraDatePickerField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v328/*: any*/),
                            "type": "JiraDateTimePickerField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v141/*: any*/),
                              (v76/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraEpicLinkField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v142/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraFlagField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v145/*: any*/),
                              (v133/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraForgeGroupField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v146/*: any*/),
                              (v133/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraForgeGroupsField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v130/*: any*/),
                              (v133/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraForgeNumberField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v147/*: any*/),
                              (v133/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraForgeObjectField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v137/*: any*/),
                              (v133/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraForgeStringField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v148/*: any*/),
                              (v133/*: any*/),
                              (v76/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraForgeStringsField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              {
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": (v331/*: any*/)
                              },
                              (v133/*: any*/),
                              (v76/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraForgeUserField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v332/*: any*/),
                              (v133/*: any*/),
                              (v76/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraForgeUsersField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v158/*: any*/),
                              (v76/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraIssueRestrictionField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v333/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraIssueTypeField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v148/*: any*/),
                              (v76/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraLabelsField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              {
                                "concreteType": "JiraGroupConnection",
                                "kind": "LinkedField",
                                "name": "selectedGroupsConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraGroupEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraGroup",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v143/*: any*/),
                                          (v55/*: any*/),
                                          (v56/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v76/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraMultipleGroupPickerField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v118/*: any*/),
                              (v76/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraMultipleSelectField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v334/*: any*/),
                            "type": "JiraMultipleSelectUserPickerField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v163/*: any*/),
                              (v255/*: any*/),
                              (v164/*: any*/)
                            ],
                            "type": "JiraMultipleVersionPickerField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v130/*: any*/),
                              (v165/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraNumberField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v169/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v69/*: any*/),
                                  (v167/*: any*/)
                                ],
                                "type": "JiraIssueField",
                                "abstractKey": "__isJiraIssueField"
                              }
                            ],
                            "type": "JiraOriginalTimeEstimateField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v170/*: any*/),
                              {
                                "concreteType": "JiraIssue",
                                "kind": "LinkedField",
                                "name": "parentIssue",
                                "plural": false,
                                "selections": [
                                  (v56/*: any*/),
                                  (v52/*: any*/),
                                  (v53/*: any*/),
                                  (v89/*: any*/),
                                  {
                                    "condition": "parentInIssueViewFlag",
                                    "kind": "Condition",
                                    "passingValue": true,
                                    "selections": [
                                      (v317/*: any*/)
                                    ]
                                  },
                                  {
                                    "args": (v171/*: any*/),
                                    "concreteType": "JiraIssueFieldConnection",
                                    "kind": "LinkedField",
                                    "name": "fieldsById",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v63/*: any*/),
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v73/*: any*/),
                                                  (v55/*: any*/),
                                                  (v74/*: any*/),
                                                  (v58/*: any*/),
                                                  (v333/*: any*/)
                                                ],
                                                "type": "JiraIssueTypeField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v73/*: any*/),
                                                  (v55/*: any*/),
                                                  (v74/*: any*/),
                                                  (v58/*: any*/),
                                                  (v76/*: any*/),
                                                  (v337/*: any*/)
                                                ],
                                                "type": "JiraProjectField"
                                              },
                                              (v179/*: any*/),
                                              (v56/*: any*/)
                                            ]
                                          }
                                        ]
                                      }
                                    ],
                                    "storageKey": "fieldsById(ids:[\"issuetype\",\"project\",\"summary\"])"
                                  }
                                ]
                              },
                              (v255/*: any*/)
                            ],
                            "type": "JiraParentIssueField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v332/*: any*/),
                              (v180/*: any*/),
                              (v76/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraPeopleField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              {
                                "concreteType": "JiraPriority",
                                "kind": "LinkedField",
                                "name": "priority",
                                "plural": false,
                                "selections": (v338/*: any*/)
                              },
                              {
                                "concreteType": "JiraPriorityConnection",
                                "kind": "LinkedField",
                                "name": "priorities",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraPriorityEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraPriority",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": (v338/*: any*/)
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v255/*: any*/)
                            ],
                            "type": "JiraPriorityField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v185/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraProformaFormsField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v76/*: any*/),
                              (v337/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraProjectField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v186/*: any*/),
                              (v187/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraRadioSelectField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v190/*: any*/),
                              (v191/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraResolutionField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v329/*: any*/),
                            "type": "JiraRichTextField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              {
                                "concreteType": "JiraSecurityLevel",
                                "kind": "LinkedField",
                                "name": "securityLevel",
                                "plural": false,
                                "selections": (v339/*: any*/)
                              },
                              {
                                "concreteType": "JiraSecurityLevelConnection",
                                "kind": "LinkedField",
                                "name": "securityLevels",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraSecurityLevelEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraSecurityLevel",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": (v339/*: any*/)
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v255/*: any*/)
                            ],
                            "type": "JiraSecurityLevelField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              {
                                "concreteType": "JiraServiceManagementActiveApproval",
                                "kind": "LinkedField",
                                "name": "activeApproval",
                                "plural": false,
                                "selections": [
                                  (v56/*: any*/),
                                  (v194/*: any*/),
                                  (v196/*: any*/),
                                  {
                                    "concreteType": "JiraServiceManagementApproverPrincipalConnection",
                                    "kind": "LinkedField",
                                    "name": "approverPrincipals",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraServiceManagementApproverPrincipalEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v63/*: any*/),
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v327/*: any*/),
                                                  (v197/*: any*/)
                                                ],
                                                "type": "JiraServiceManagementUserApproverPrincipal"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v143/*: any*/),
                                                  (v55/*: any*/),
                                                  (v198/*: any*/),
                                                  (v199/*: any*/)
                                                ],
                                                "type": "JiraServiceManagementGroupApproverPrincipal"
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  (v342/*: any*/),
                                  (v204/*: any*/),
                                  {
                                    "concreteType": "JiraServiceManagementApprovalConfiguration",
                                    "kind": "LinkedField",
                                    "name": "configurations",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraServiceManagementApproversConfiguration",
                                        "kind": "LinkedField",
                                        "name": "approversConfigurations",
                                        "plural": true,
                                        "selections": [
                                          (v74/*: any*/),
                                          (v205/*: any*/),
                                          (v73/*: any*/)
                                        ]
                                      },
                                      {
                                        "concreteType": "JiraServiceManagementApprovalCondition",
                                        "kind": "LinkedField",
                                        "name": "condition",
                                        "plural": false,
                                        "selections": [
                                          (v74/*: any*/),
                                          (v77/*: any*/)
                                        ]
                                      }
                                    ]
                                  },
                                  (v206/*: any*/),
                                  {
                                    "concreteType": "JiraServiceManagementDecisionConnection",
                                    "kind": "LinkedField",
                                    "name": "decisions",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraServiceManagementDecisionEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "concreteType": "JiraServiceManagementDecision",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": (v341/*: any*/)
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  {
                                    "concreteType": "JiraUserConnection",
                                    "kind": "LinkedField",
                                    "name": "excludedApprovers",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraUserEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": (v340/*: any*/)
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  (v207/*: any*/),
                                  (v55/*: any*/),
                                  (v208/*: any*/),
                                  (v209/*: any*/)
                                ]
                              },
                              {
                                "concreteType": "JiraServiceManagementCompletedApprovalConnection",
                                "kind": "LinkedField",
                                "name": "completedApprovalsConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraServiceManagementCompletedApprovalEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraServiceManagementCompletedApproval",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v56/*: any*/),
                                          (v55/*: any*/),
                                          (v207/*: any*/),
                                          (v342/*: any*/),
                                          (v206/*: any*/),
                                          (v210/*: any*/),
                                          (v211/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v255/*: any*/)
                            ],
                            "type": "JiraServiceManagementApprovalField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v212/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraServiceManagementMajorIncidentField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              {
                                "concreteType": "JiraServiceManagementOrganizationConnection",
                                "kind": "LinkedField",
                                "name": "selectedOrganizationsConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraServiceManagementOrganizationEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraServiceManagementOrganization",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v213/*: any*/),
                                          (v214/*: any*/),
                                          (v215/*: any*/),
                                          (v56/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v76/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraServiceManagementOrganizationField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v334/*: any*/),
                            "type": "JiraServiceManagementPeopleField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v216/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraServiceManagementRequestFeedbackField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v218/*: any*/),
                              (v219/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraServiceManagementRequestLanguageField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v222/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraServiceManagementRequestTypeField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v76/*: any*/),
                              {
                                "concreteType": "JiraServiceManagementResponderConnection",
                                "kind": "LinkedField",
                                "name": "respondersConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraServiceManagementResponderEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v63/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "kind": "LinkedField",
                                                "name": "user",
                                                "plural": false,
                                                "selections": [
                                                  (v63/*: any*/),
                                                  (v223/*: any*/),
                                                  (v102/*: any*/),
                                                  (v55/*: any*/),
                                                  (v56/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraServiceManagementUserResponder"
                                          },
                                          (v225/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v255/*: any*/)
                            ],
                            "type": "JiraServiceManagementRespondersField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v145/*: any*/),
                              (v76/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraSingleGroupPickerField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v330/*: any*/),
                            "type": "JiraSingleLineTextField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v135/*: any*/),
                              (v76/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraSingleSelectField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v343/*: any*/),
                              (v76/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraSingleSelectUserPickerField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v232/*: any*/),
                              (v233/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraSingleVersionPickerField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              {
                                "concreteType": "JiraSprintConnection",
                                "kind": "LinkedField",
                                "name": "selectedSprintsConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraSprintEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraSprint",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v56/*: any*/),
                                          (v234/*: any*/),
                                          (v55/*: any*/),
                                          (v235/*: any*/),
                                          (v236/*: any*/),
                                          (v230/*: any*/),
                                          (v237/*: any*/),
                                          (v238/*: any*/),
                                          (v239/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v76/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraSprintField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              {
                                "concreteType": "JiraStatus",
                                "kind": "LinkedField",
                                "name": "status",
                                "plural": false,
                                "selections": [
                                  (v56/*: any*/),
                                  (v55/*: any*/),
                                  (v58/*: any*/),
                                  (v240/*: any*/),
                                  (v242/*: any*/)
                                ]
                              },
                              (v255/*: any*/)
                            ],
                            "type": "JiraStatusField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              {
                                "concreteType": "JiraTeam",
                                "kind": "LinkedField",
                                "name": "selectedTeam",
                                "plural": false,
                                "selections": [
                                  (v224/*: any*/),
                                  (v55/*: any*/),
                                  (v243/*: any*/),
                                  (v244/*: any*/),
                                  (v56/*: any*/)
                                ]
                              },
                              (v255/*: any*/)
                            ],
                            "type": "JiraTeamField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v245/*: any*/),
                              (v76/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraTeamViewField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v169/*: any*/),
                              (v246/*: any*/),
                              (v247/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraTimeTrackingField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v248/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraUrlField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v250/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraVotesField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v252/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraWatchesField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v127/*: any*/),
                              (v133/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraForgeDatetimeField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v139/*: any*/),
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v58/*: any*/),
                              (v133/*: any*/),
                              (v255/*: any*/)
                            ],
                            "type": "JiraForgeDateField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v73/*: any*/),
                              (v254/*: any*/),
                              (v255/*: any*/),
                              (v256/*: any*/)
                            ],
                            "type": "JiraServiceManagementEntitlementField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v55/*: any*/),
                              (v74/*: any*/),
                              (v73/*: any*/),
                              (v58/*: any*/),
                              (v257/*: any*/),
                              (v255/*: any*/),
                              (v256/*: any*/)
                            ],
                            "type": "JiraServiceManagementSentimentField"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v74/*: any*/),
                              (v73/*: any*/),
                              (v55/*: any*/),
                              (v58/*: any*/),
                              (v255/*: any*/),
                              (v260/*: any*/)
                            ],
                            "type": "JiraGoalsField"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "name": "childIssues",
                "plural": false,
                "selections": [
                  (v63/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "args": (v261/*: any*/),
                        "concreteType": "JiraIssueConnection",
                        "kind": "LinkedField",
                        "name": "issues",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraIssueEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "JiraIssue",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v53/*: any*/),
                                  (v52/*: any*/),
                                  (v89/*: any*/),
                                  (v262/*: any*/),
                                  {
                                    "concreteType": "JiraNumberField",
                                    "kind": "LinkedField",
                                    "name": "storyPointEstimateField",
                                    "plural": false,
                                    "selections": (v344/*: any*/)
                                  },
                                  {
                                    "concreteType": "JiraNumberField",
                                    "kind": "LinkedField",
                                    "name": "storyPointsField",
                                    "plural": false,
                                    "selections": (v344/*: any*/)
                                  },
                                  {
                                    "args": (v264/*: any*/),
                                    "concreteType": "JiraIssueFieldConnection",
                                    "kind": "LinkedField",
                                    "name": "fieldsById",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v63/*: any*/),
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v73/*: any*/),
                                                  (v74/*: any*/),
                                                  {
                                                    "concreteType": "JiraIssueType",
                                                    "kind": "LinkedField",
                                                    "name": "issueType",
                                                    "plural": false,
                                                    "selections": [
                                                      (v57/*: any*/),
                                                      (v55/*: any*/),
                                                      (v244/*: any*/),
                                                      (v320/*: any*/),
                                                      (v56/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraIssueTypeField"
                                              },
                                              (v265/*: any*/),
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v73/*: any*/),
                                                  (v74/*: any*/),
                                                  {
                                                    "concreteType": "JiraPriority",
                                                    "kind": "LinkedField",
                                                    "name": "priority",
                                                    "plural": false,
                                                    "selections": [
                                                      (v181/*: any*/),
                                                      (v55/*: any*/),
                                                      (v183/*: any*/),
                                                      (v182/*: any*/),
                                                      (v56/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraPriorityField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v73/*: any*/),
                                                  (v74/*: any*/),
                                                  {
                                                    "concreteType": "JiraStatus",
                                                    "kind": "LinkedField",
                                                    "name": "status",
                                                    "plural": false,
                                                    "selections": [
                                                      (v55/*: any*/),
                                                      (v240/*: any*/),
                                                      (v321/*: any*/),
                                                      (v56/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraStatusField"
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v73/*: any*/),
                                                  (v74/*: any*/),
                                                  {
                                                    "kind": "LinkedField",
                                                    "name": "user",
                                                    "plural": false,
                                                    "selections": [
                                                      (v63/*: any*/),
                                                      (v101/*: any*/),
                                                      (v149/*: any*/),
                                                      (v55/*: any*/),
                                                      (v102/*: any*/),
                                                      (v56/*: any*/)
                                                    ]
                                                  }
                                                ],
                                                "type": "JiraSingleSelectUserPickerField"
                                              },
                                              (v266/*: any*/),
                                              (v267/*: any*/),
                                              (v56/*: any*/)
                                            ]
                                          }
                                        ]
                                      }
                                    ],
                                    "storageKey": "fieldsById(ids:[\"assignee\",\"created\",\"issuetype\",\"priority\",\"status\",\"summary\",\"timetracking\",\"updated\"])"
                                  },
                                  (v56/*: any*/)
                                ]
                              }
                            ]
                          }
                        ],
                        "storageKey": "issues(activeProjectsOnly:true)"
                      }
                    ],
                    "type": "JiraChildIssuesWithinLimit"
                  },
                  (v268/*: any*/)
                ]
              },
              {
                "args": (v269/*: any*/),
                "concreteType": "JiraIssueLinkConnection",
                "kind": "LinkedField",
                "name": "issueLinks",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueLinkEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssueLink",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v56/*: any*/),
                          (v270/*: any*/),
                          (v271/*: any*/),
                          (v272/*: any*/),
                          {
                            "concreteType": "JiraIssueLinkType",
                            "kind": "LinkedField",
                            "name": "type",
                            "plural": false,
                            "selections": [
                              (v46/*: any*/),
                              (v56/*: any*/)
                            ]
                          },
                          {
                            "concreteType": "JiraIssue",
                            "kind": "LinkedField",
                            "name": "issue",
                            "plural": false,
                            "selections": [
                              (v56/*: any*/),
                              (v52/*: any*/),
                              (v53/*: any*/),
                              (v89/*: any*/),
                              (v262/*: any*/),
                              {
                                "args": (v273/*: any*/),
                                "concreteType": "JiraIssueFieldConnection",
                                "kind": "LinkedField",
                                "name": "fieldsById",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueFieldEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v63/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v73/*: any*/),
                                              (v55/*: any*/),
                                              (v74/*: any*/),
                                              {
                                                "concreteType": "JiraStatus",
                                                "kind": "LinkedField",
                                                "name": "status",
                                                "plural": false,
                                                "selections": [
                                                  (v56/*: any*/),
                                                  (v55/*: any*/),
                                                  (v240/*: any*/),
                                                  {
                                                    "concreteType": "JiraStatusCategory",
                                                    "kind": "LinkedField",
                                                    "name": "statusCategory",
                                                    "plural": false,
                                                    "selections": [
                                                      (v56/*: any*/),
                                                      (v241/*: any*/),
                                                      (v53/*: any*/),
                                                      (v55/*: any*/),
                                                      (v274/*: any*/)
                                                    ]
                                                  }
                                                ]
                                              }
                                            ],
                                            "type": "JiraStatusField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v73/*: any*/),
                                              (v55/*: any*/),
                                              (v74/*: any*/),
                                              {
                                                "concreteType": "JiraPriority",
                                                "kind": "LinkedField",
                                                "name": "priority",
                                                "plural": false,
                                                "selections": [
                                                  (v181/*: any*/),
                                                  (v55/*: any*/),
                                                  (v183/*: any*/),
                                                  (v182/*: any*/),
                                                  (v140/*: any*/),
                                                  (v56/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraPriorityField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v73/*: any*/),
                                              (v55/*: any*/),
                                              (v74/*: any*/),
                                              {
                                                "concreteType": "JiraIssueType",
                                                "kind": "LinkedField",
                                                "name": "issueType",
                                                "plural": false,
                                                "selections": [
                                                  (v56/*: any*/),
                                                  (v57/*: any*/),
                                                  (v55/*: any*/),
                                                  (v221/*: any*/),
                                                  (v304/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraIssueTypeField"
                                          },
                                          (v275/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v73/*: any*/),
                                              (v55/*: any*/),
                                              (v74/*: any*/),
                                              (v343/*: any*/)
                                            ],
                                            "type": "JiraSingleSelectUserPickerField"
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v73/*: any*/),
                                              (v55/*: any*/),
                                              (v74/*: any*/),
                                              (v58/*: any*/),
                                              (v76/*: any*/),
                                              {
                                                "concreteType": "JiraProject",
                                                "kind": "LinkedField",
                                                "name": "project",
                                                "plural": false,
                                                "selections": [
                                                  (v53/*: any*/),
                                                  (v55/*: any*/),
                                                  (v172/*: any*/),
                                                  (v174/*: any*/),
                                                  (v173/*: any*/),
                                                  (v175/*: any*/),
                                                  (v161/*: any*/),
                                                  (v176/*: any*/),
                                                  (v335/*: any*/),
                                                  (v336/*: any*/),
                                                  (v56/*: any*/)
                                                ]
                                              }
                                            ],
                                            "type": "JiraProjectField"
                                          },
                                          (v56/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ],
                                "storageKey": "fieldsById(ids:[\"assignee\",\"issuetype\",\"priority\",\"status\",\"summary\",\"project\"])"
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "condition": "isJPDPanelEnabled",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      (v256/*: any*/)
                    ]
                  }
                ],
                "storageKey": "issueLinks(first:1000)"
              },
              (v56/*: any*/),
              {
                "condition": "issueViewRelayAttachmentsFlag",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  (v278/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "mediaUploadToken",
                    "plural": false,
                    "selections": [
                      (v63/*: any*/),
                      (v279/*: any*/),
                      (v280/*: any*/)
                    ]
                  }
                ]
              },
              (v281/*: any*/),
              {
                "condition": "issueViewRelayMetadataFieldFlag",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "concreteType": "JiraDateTimePickerField",
                    "kind": "LinkedField",
                    "name": "createdField",
                    "plural": false,
                    "selections": (v345/*: any*/)
                  },
                  {
                    "concreteType": "JiraDateTimePickerField",
                    "kind": "LinkedField",
                    "name": "updatedField",
                    "plural": false,
                    "selections": (v345/*: any*/)
                  },
                  {
                    "concreteType": "JiraDateTimePickerField",
                    "kind": "LinkedField",
                    "name": "resolutionDateField",
                    "plural": false,
                    "selections": (v345/*: any*/)
                  }
                ]
              },
              {
                "condition": "issueViewRelayHeaders",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsheaderactionsrelayprovider",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      (v348/*: any*/),
                      (v349/*: any*/)
                    ]
                  },
                  {
                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": (v351/*: any*/)
                  },
                  (v348/*: any*/),
                  (v349/*: any*/)
                ]
              },
              {
                "condition": "issueViewRelayParentFieldFlag",
                "kind": "Condition",
                "passingValue": true,
                "selections": (v351/*: any*/)
              },
              {
                "condition": "issueViewRelaySummaryFieldFlag",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "concreteType": "JiraSingleLineTextField",
                    "kind": "LinkedField",
                    "name": "summaryField",
                    "plural": false,
                    "selections": [
                      (v56/*: any*/),
                      (v55/*: any*/),
                      (v73/*: any*/),
                      (v74/*: any*/),
                      (v137/*: any*/),
                      (v307/*: any*/),
                      (v63/*: any*/)
                    ]
                  }
                ]
              },
              {
                "condition": "issueViewRelayColorFieldFlag",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "concreteType": "JiraColorField",
                    "kind": "LinkedField",
                    "name": "issueColorField",
                    "plural": false,
                    "selections": [
                      (v56/*: any*/),
                      (v73/*: any*/),
                      (v74/*: any*/),
                      (v55/*: any*/),
                      (v58/*: any*/),
                      (v63/*: any*/),
                      (v68/*: any*/),
                      (v316/*: any*/)
                    ]
                  }
                ]
              },
              {
                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsresolutionrelayprovider",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "concreteType": "JiraResolutionField",
                    "kind": "LinkedField",
                    "name": "resolutionField",
                    "plural": false,
                    "selections": [
                      (v63/*: any*/),
                      (v56/*: any*/),
                      (v73/*: any*/),
                      (v74/*: any*/),
                      {
                        "concreteType": "JiraIssue",
                        "kind": "LinkedField",
                        "name": "issue",
                        "plural": false,
                        "selections": [
                          (v56/*: any*/),
                          (v53/*: any*/)
                        ]
                      },
                      (v68/*: any*/),
                      {
                        "concreteType": "JiraResolution",
                        "kind": "LinkedField",
                        "name": "resolution",
                        "plural": false,
                        "selections": [
                          (v56/*: any*/),
                          (v55/*: any*/),
                          (v63/*: any*/)
                        ]
                      },
                      {
                        "concreteType": "JiraResolutionConnection",
                        "kind": "LinkedField",
                        "name": "resolutions",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraResolutionEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "JiraResolution",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v56/*: any*/),
                                  (v55/*: any*/),
                                  (v188/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "concreteType": "JiraStatusField",
                    "kind": "LinkedField",
                    "name": "statusField",
                    "plural": false,
                    "selections": [
                      (v322/*: any*/),
                      (v56/*: any*/)
                    ]
                  }
                ]
              },
              {
                "condition": "giraAGGMigrationAttachmentsFlag",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "args": (v352/*: any*/),
                    "concreteType": "JiraAttachmentConnection",
                    "kind": "LinkedField",
                    "name": "attachments",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraAttachmentEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v63/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v283/*: any*/),
                                  (v284/*: any*/),
                                  (v285/*: any*/),
                                  (v286/*: any*/),
                                  (v287/*: any*/),
                                  (v288/*: any*/),
                                  (v289/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "name": "author",
                                    "plural": false,
                                    "selections": (v323/*: any*/)
                                  },
                                  (v290/*: any*/),
                                  (v291/*: any*/)
                                ],
                                "type": "JiraPlatformAttachment"
                              },
                              (v353/*: any*/)
                            ]
                          },
                          (v292/*: any*/)
                        ]
                      },
                      (v293/*: any*/)
                    ],
                    "storageKey": "attachments(first:12,sortBy:{\"field\":\"CREATED\",\"order\":\"DESC\"})"
                  },
                  {
                    "args": (v352/*: any*/),
                    "filters": [
                      "sortBy"
                    ],
                    "handle": "connection",
                    "key": "issue_attachments",
                    "kind": "LinkedHandle",
                    "name": "attachments"
                  }
                ]
              },
              {
                "condition": "giraAGGMigrationCommentsFlag",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "args": (v294/*: any*/),
                    "concreteType": "JiraCommentConnection",
                    "kind": "LinkedField",
                    "name": "comments",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraCommentEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v63/*: any*/),
                              (v295/*: any*/),
                              (v287/*: any*/),
                              (v296/*: any*/),
                              {
                                "kind": "LinkedField",
                                "name": "author",
                                "plural": false,
                                "selections": (v354/*: any*/)
                              },
                              {
                                "kind": "LinkedField",
                                "name": "updateAuthor",
                                "plural": false,
                                "selections": (v354/*: any*/)
                              },
                              (v298/*: any*/),
                              (v300/*: any*/),
                              (v301/*: any*/),
                              (v353/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v302/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "21bde05c27ed23a329bc5f548e7408778099eac9b94f2c84f10f83d977b86add",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "jira",
            "issueByKey",
            "attachments"
          ]
        }
      ]
    },
    "name": "mainIssueAggQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent": issueViewRelayParentFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldstimetrackingrelayprovider": issueViewRelayTimeTrackingFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscheckboxselectrelayprovider": issueViewRelayCheckboxSelectFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscomponentsrelayprovider": issueViewRelayComponentsFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsoriginalestimaterelayprovider": issueViewRelayOriginalEstimateFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserrelayprovider": issueViewRelayMultiUserFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsgrouppickerrelayprovider": issueViewRelayGroupPickerFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldspeoplerelayprovider": issueViewRelayPeopleFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsapproversrelayprovider": issueViewRelayApproversFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssinglelinetextmessagerelayprovider": issueViewRelaySingleLineTextMessageFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsassigneerelayprovider": issueViewRelayAssigneeFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsreporterrelayprovider": issueViewRelayReporterFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsuserrelayprovider": issueViewRelayUserFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsheaderactionsrelayprovider": issueViewRelayHeaderActionsFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldswatchersrelayprovider": issueViewRelayWatchersFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssecuritylevelrelayprovider": issueViewRelaySecurityLevelFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuetypeswitcherrelayprovider": issueViewRelayIssueTypeSwitcherFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsresolutionrelayprovider": issueViewRelayResolutionFieldFlag_provider
    }
  }
};
})();

(node as any).hash = "9763d06e4fe4f9d82daa8f246414a77b";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;
