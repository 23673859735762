import React, { useCallback } from 'react';
import {
	FULL_ISSUE,
	EMBED_ISSUE,
	type AnalyticsSource,
} from '@atlassian/jira-common-constants/src/analytics-sources';
import { getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import { extractProjectKey } from '@atlassian/jira-issue-fetch-services-common/src/common/utils/extract-project-key.tsx';
import { ISSUE_CRITICAL_DATA_GIRA_SUSPENSE } from '@atlassian/jira-issue-view-common-constants/src/mark-types';
import { useWaitForPrefetchData } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources';
import type { IssueAppConfiguration } from '@atlassian/jira-issue-view-configurations';
import { useIsEmbedMode } from '@atlassian/jira-issue-view-embed-mode';
import type { ChangeEvent } from '@atlassian/jira-issue-view-model/src/change-type';
import { blockedOnBackendEntryLog } from '@atlassian/jira-issue-view/src/common/metrics/reporters/backend-blocking-time-reporter';
import { IssueAppWithRouteResources } from '@atlassian/jira-issue-view/src/views/issue-details/issue-app';
import { getIssueContainerMaxWidth } from '@atlassian/jira-issue-view/src/views/issue-details/issue-layout/constants';
import { useEcClient } from '@atlassian/jira-jsis-ec-client/src/services/ec-components/index.tsx';
import IssueMutation from '@atlassian/jira-jsis-ec-client/src/services/issue-mutation/index.tsx';
import { MutationSource } from '@atlassian/jira-jsis-ec-client/src/services/storage/constants.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useIssueViewRelayRouteResource } from '@atlassian/jira-router-resources-issue-view/src/services/issue-agg-data/index.tsx';
import { type IssueKey, toBaseUrl, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useSpaStateTransition } from '@atlassian/jira-spa-state-controller/src/components/transition-state/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';

import { useRouterActions, usePathParam } from '@atlassian/react-resource-router';

import { FullPageIssueContainer } from '../common/styles';
import { getLegacyIssueViewUrl } from '../common/utils';

const isEcClientEnabled = () => {
	const experiences: string[] = getFeatureFlagValue<string>(
		'odin.ec.client.integration.issueview',
		'',
	).split(',');

	return Array.isArray(experiences) && experiences.includes('standalone-issue-view');
};

type OnIssueKeyChangeParams = {
	fromIssueKey: IssueKey;
	toIssueKey: IssueKey;
	meta: {
		location: string;
	};
};

type Props = {
	configuration?: IssueAppConfiguration;
	rapidViewId?: number;
};

export const MainIssueView = ({ configuration, rapidViewId }: Props) => {
	const tenantContext = useTenantContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [paramIssueKey] = usePathParam('issueKey');

	const { push } = useRouterActions();
	const [{ isInitialRender }] = useSpaStateTransition();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let saveIssueMutationToCache: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let onChange: any = {};
	if (isEcClientEnabled()) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		saveIssueMutationToCache = useEcClient().saveIssueMutationToCache;
		onChange = {
			onChange: (event: ChangeEvent) => {
				const analyticsEventObj = createAnalyticsEvent({});

				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				if ((configWithoutAppKey as { onChange: Function }).onChange && event)
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					(configWithoutAppKey as { onChange: Function }).onChange(event);

				const analyticsDataKey = {
					analyticsEventObj,
					analyticsMetadata: {
						scenario: 'standalone-issue-view',
					},
				};

				if (event?.type === 'FIELD_CHANGED') {
					saveIssueMutationToCache(
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						new IssueMutation(event?.issueId as string, MutationSource.UPDATE),
						analyticsDataKey,
						{
							errorMsg: 'FAILED_ISSUEVIEW',
							error: true,
						},
					);
				}
			},
		};
	}
	/*
	 * Both useIssueViewRelayRouteResource and useWaitForPrefetchData are backed by route resources which:
	 *  - Are all `isBrowserOnly: true` resources (they never run in SSR)
	 *  - in an initial page load: the network request is kicked off from `src/entry/vendorless-entries/issue-app/index.tsx`
	 *  - in an SPA transition: the network request is kicked off normally via route resources' `getData` (or `getDataLoader`) methods triggered by the router itself when the result of `getKey` changes
	 */
	const { queryReference } = useIssueViewRelayRouteResource({ suspendWhenLoading: true }); // Suspends until an async bundle is fetched, does not block on data returning
	const { loading, promise } = useWaitForPrefetchData();
	blockedOnBackendEntryLog.initialiseBlockedOnBackendStateForUpdatedKey(paramIssueKey);
	if (isInitialRender) {
		blockedOnBackendEntryLog.markStartBackendBlockingTimestamp(ISSUE_CRITICAL_DATA_GIRA_SUSPENSE); // before we potentially suspend for gira data, lets mark that we're about to start blocking on the data. Suspense woun't resolve until after usePreloadedQuery in AppProvider though, so don't fire the data-available mark until then
		// IMPORTANT - for initial-load suspend until early-data is loaded, for transitions and preload errors just render
		const waitCondition = !__SERVER__ && loading;
		if (waitCondition) {
			// throw a promise for suspense boundary to catch
			throw promise;
		}
		blockedOnBackendEntryLog.markEndBackendBlockingTimestamp(ISSUE_CRITICAL_DATA_GIRA_SUSPENSE); // If we reach this point, either we never suspended (and the blocking duration will be effectively 0) or we did and we will capture the blocking time
	}

	const issueKeyFromRouter = toIssueKey(paramIssueKey || '');
	const projectKey = extractProjectKey(issueKeyFromRouter);
	const issueKey = toIssueKey(issueKeyFromRouter);
	let issueId: string | undefined;

	const { baseUrl, locale } = tenantContext;
	const { applicationKey, ...configWithoutAppKey } = configuration || {};

	const onIssueKeyChange = useCallback(
		(param: OnIssueKeyChangeParams) => {
			push(`${baseUrl}/browse/${param.toIssueKey}`);
		},
		[push, baseUrl],
	);

	const onIssueDelete = useCallback(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.location.assign(`${baseUrl}/browse/${projectKey}`);
	}, [projectKey, baseUrl]);

	let analyticsSource: AnalyticsSource = FULL_ISSUE;

	const isEmbedMode = useIsEmbedMode();
	if (isEmbedMode) {
		analyticsSource = EMBED_ISSUE;
	}

	const issueViewContainer = (
		<FullPageIssueContainer>
			<IssueAppWithRouteResources
				{...configWithoutAppKey}
				{...onChange}
				issueKey={issueKey}
				issueId={issueId}
				baseUrl={toBaseUrl(baseUrl)}
				locale={locale}
				analyticsSource={analyticsSource}
				isSpaEnabled
				rapidViewId={rapidViewId}
				history={null}
				onIssueKeyChange={onIssueKeyChange}
				oldViewUrl={getLegacyIssueViewUrl()}
				onIssueDeleteSuccess={onIssueDelete}
				shouldSetInitialFocus
				shouldShowRootProjectsBreadcrumb
				shouldShowProjectLevelBreadcrumb
				isStickyHeaderOffset
				issueMaxWidth={getIssueContainerMaxWidth()}
				preloadedQuery={queryReference}
			/>
		</FullPageIssueContainer>
	);

	return <UFOSegment name="issue-view">{issueViewContainer}</UFOSegment>;
};
