import React, { type ReactNode } from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import FallbackAddCommentEditor from './fallback-editor';

export const IssueAddCommentEditorLoading = FallbackAddCommentEditor;

export type IssueAddCommentEditorBoundaryProps = {
	children: ReactNode;
	fallback: ReactNode;
	packageName: string;
};

export const IssueAddCommentEditorBoundary = ({
	children,
	fallback,
	packageName,
}: IssueAddCommentEditorBoundaryProps) => (
	<ErrorBoundary
		id="issue-add-comment-editor"
		packageName={packageName}
		render={() => <>{fallback}</>}
	>
		<Placeholder name="issue-add-comment-editor" fallback={fallback}>
			{children}
		</Placeholder>
	</ErrorBoundary>
);
