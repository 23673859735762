import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { isIssueDropzoneAndClipboardUploadEnabled } from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/attachment-quick-upload-selector';
import { CommonClipboardMediaPicker, type Props } from './view';

const ConnectedCommonClipboardMediaPicker: React.FC<Omit<Props, 'isEnabled'>> = connect(
	(state: State) => ({
		isEnabled: isIssueDropzoneAndClipboardUploadEnabled(state),
	}),
)(CommonClipboardMediaPicker);

export default ConnectedCommonClipboardMediaPicker;
