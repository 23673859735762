import React from 'react';
import { useProjectKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type {
	ServiceDetails,
	ValueType,
} from '@atlassian/jira-issue-field-service-entity/src/common/types/index.tsx';
import {
	AsyncServiceEntityField,
	IssueServiceEntityFieldBoundary,
} from '@atlassian/jira-issue-field-service-entity/src/ui/async.tsx';
import type {
	CONTENT_AREA,
	CONTENT_REQUEST_AREA,
	CONTEXT_AREA,
} from '@atlassian/jira-issue-layout-common-constants';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field';

type Props = {
	fieldId: string;
	area: typeof CONTEXT_AREA | typeof CONTENT_AREA | typeof CONTENT_REQUEST_AREA;
	onChange: (values: ValueType<ServiceDetails>[] | null) => void;
	onConfirm: () => void;
};

const ServiceEntity = (props: Props) => {
	const { fieldId, area, onChange, onConfirm } = props;
	const projectKey = useProjectKey();
	return (
		<IssueServiceEntityFieldBoundary packageName="issue-service-entity-field">
			<AsyncServiceEntityField
				area={area}
				fieldId={fieldId}
				projectKey={projectKey}
				onChange={onChange}
				onConfirm={onConfirm}
			/>
		</IssueServiceEntityFieldBoundary>
	);
};

export const ConnectedServiceEntity = connectField((stateOnMount, ownPropsOnMount) => ({
	area: ownPropsOnMount.area,
	fieldId: ownPropsOnMount.fieldId,
}))(ServiceEntity);

export default ServiceEntity;
