import React, { Component } from 'react';
import { styled } from '@compiled/react';
import Select from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import type {
	ActionMeta,
	AkSelectStyles,
} from '@atlassian/jira-common-components-picker/src/model';
import { defaultSelectProps } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field';
import { defaultSelectStyles } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/styled.tsx';
import { mergeReactSelectStyles } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/utils';
import type { ChildIssueType } from '@atlassian/jira-issue-view-common-types/src/child-issue-type';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages';

export type Props = {
	issueTypes: ChildIssueType[];
	isDisabled?: boolean;
} & Intl & {
		selectedIssueType: ChildIssueType;
		onChange: (arg1: ChildIssueType) => void;
		defaultMenuIsOpen?: boolean;
	};

type Option = {
	id: string;
	name: string;
	iconUrl: string;
	hasRequiredField: boolean;
	hasEpicLinkField: boolean;
	requiredFields: string[];
};

const selectStyles: AkSelectStyles = {
	singleValue: (baseStyles) => ({
		...baseStyles,
		// item is already vertically aligned because of `align-items: center` in parent. With `position: absolute` select does not have width == content width
		position: 'static',
		transform: 'none',
	}),
};

const getOptionLabel = (option: Option) => option.name;
const getOptionValue = (option: Option) => option.id;

const formatOptionLabel = (option: Option) => (
	<OptionLabelAndIconContainer>
		<AsyncIcon url={option.iconUrl} title={option.name} />
		<OptionLabelContainer>{option.name}</OptionLabelContainer>
	</OptionLabelAndIconContainer>
);

// eslint-disable-next-line jira/react/no-class-components
export default class ChildTypesSelect extends Component<Props> {
	static defaultProps = {
		issueTypes: [],
	};

	onChange = (selectedOption: Option | null, actionMeta: ActionMeta<Option>) => {
		if (actionMeta.action === 'select-option' && selectedOption != null) {
			this.props.onChange(selectedOption);
		}
	};

	render() {
		const {
			intl: { formatMessage },
			issueTypes,
			selectedIssueType,
			isDisabled = false,
			defaultMenuIsOpen,
		} = this.props;

		return (
			// @ts-expect-error  Type '{ styles: AkSelectStyles; options: ChildIssueType[]; value: ChildIssueType; getOptionLabel: (option: Option) => string; getOptionValue: (option: Option) => string; ... 5 more ...; filterOption: (option: OptionToFilter, inputValue: string) => boolean; }' is not assignable to type 'IntrinsicAttributes & (IntrinsicClassAttributes<{ components: Partial<SelectComponents<ChildIssueType, false, GroupBase<ChildIssueType>>>; ... 23 more ...; UNSAFE_componentWillUpdate?(nextProps: Readonly<...>, nextState: Readonly<...>, nextContext: any): void; }> & (Readonly<...> & Readonly<...>))
			<Select
				{...defaultSelectProps}
				isDisabled={isDisabled}
				styles={mergeReactSelectStyles(defaultSelectStyles, selectStyles)}
				options={issueTypes}
				value={selectedIssueType}
				getOptionLabel={getOptionLabel}
				getOptionValue={getOptionValue}
				formatOptionLabel={formatOptionLabel}
				onChange={this.onChange}
				placeholder={formatMessage(messages.typesPlaceholder)}
				defaultMenuIsOpen={defaultMenuIsOpen}
				aria-label={formatMessage(messages.issueTypeSelectorLabel)}
			/>
		);
	}
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionLabelAndIconContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionLabelContainer = styled.span({
	paddingLeft: token('space.100', '8px'),
	display: 'inline-block',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
