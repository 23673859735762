import React, { useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { useUnarchiveIssueModalActions } from '@atlassian/jira-unarchive-modal/src/controllers/main.tsx';
import type { Props } from './types';

const UnarchiveIssue = ({ onClick, label, itemKey }: Props) => {
	const issueKey = useIssueKey();
	const [issueType] = useFieldValue({ issueKey, fieldKey: 'issuetype' });
	const { openUnarchiveIssuesModal } = useUnarchiveIssueModalActions();

	const handleClick = useCallback(() => {
		openUnarchiveIssuesModal({
			selectedIssuesDetails: [
				{
					issueKey,
					issueId: issueType?.id,
					hierarchyLevel: issueType?.hierarchyLevel,
				},
			],
			onSuccessCallback: () => {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.location.reload();
			},
		});

		onClick?.(itemKey);
	}, [
		openUnarchiveIssuesModal,
		issueKey,
		issueType?.id,
		issueType?.hierarchyLevel,
		onClick,
		itemKey,
	]);

	return (
		<DropdownItem key="unarchive" onClick={handleClick}>
			{label}
		</DropdownItem>
	);
};

export default UnarchiveIssue;
