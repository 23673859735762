// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentType, type ComponentProps } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import type { ForgeUiIssueData } from '@atlassian/jira-forge-ui-types/src/common/types/extension-data.tsx';
import type AllActivityType from '@atlassian/jira-issue-all-activity';
import GroupedActivityEntry from '@atlassian/jira-issue-all-activity/src/ui/grouped-activity-entry/main.tsx';
import type HistoryType from '@atlassian/jira-issue-history';
import type { ActivitySortOrderType } from '@atlassian/jira-issue-shared-types/src/common/types/activity-sort-order-type.tsx';
import FeedSkeleton from '@atlassian/jira-issue-view-activity-common/src/component/feed-skeleton/view.tsx';
import { FeedContainer } from '@atlassian/jira-issue-view-activity-common/src/styles';
import Comments from '@atlassian/jira-issue-view-activity/src/comments-view';
import type WorklogType from '@atlassian/jira-issue-view-activity/src/worklog';
import {
	ALL_ACTIVITY,
	APPROVALS,
	COMMENTS,
	CONNECT,
	HISTORY,
	WORKLOG,
} from '@atlassian/jira-issue-view-common-constants/src/activity-items.tsx';
import { FORGE_ENTITY_TYPE as FORGE } from '@atlassian/jira-issue-view-common-constants/src/ecosystem-constants.tsx';
import type { ActivityItem } from '@atlassian/jira-issue-view-common-types/src/activity-item-type';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import type { MediaContext } from '@atlassian/jira-issue-view-common-types/src/media-context-type';
import ConnectModule from '@atlassian/jira-issue-view-ecosystem/src/ecosystem-module-view';
import ForgeIssueActivityRenderer from '@atlassian/jira-issue-view-forge-issue-activity/src/renderer-view';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import {
	analyticsSourceSelector,
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { mediaContextSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/media-context-selector';
import { forgeIssueDataSelector } from '@atlassian/jira-issue-view-store/src/ecosystem/forge/forge-extensions-selector';
import Placeholder from '@atlassian/jira-placeholder';
import { toBaseUrl, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';

type OwnProps = {
	selectedItem: ActivityItem | null;
	selectedSortOrder: ActivitySortOrderType;
};

type StateProps = {
	issueKey: string;
	source: string | undefined;
	baseUrl: string;
	timeZone?: string;
	mediaContext: MediaContext;
	extensionData: ForgeUiIssueData;
};

type Props = OwnProps & StateProps;

const withActivityFeedItemBoundary =
	(id: string) =>
	<C, T extends ComponentType<C> = ComponentType<C>>(WrappedComponent: T): ComponentType<C> =>
	(props: C) => (
		<ErrorBoundary id={id} packageName="issue">
			<Placeholder
				name="activity-feed-item-boundary"
				fallback={
					<FeedContainer>
						<FeedSkeleton />
					</FeedContainer>
				}
			>
				{/* @ts-expect-error - TS2322 - Type 'C' is not assignable to type 'IntrinsicAttributes & LibraryManagedAttributes>'. */}
				<WrappedComponent {...props} />
			</Placeholder>
		</ErrorBoundary>
	);

const withActivityFeedItemBoundaryNew =
	(id: string) =>
	<C, T extends ComponentType<C> = ComponentType<C>>(WrappedComponent: T): ComponentType<C> =>
	(props: C) => (
		<JSErrorBoundary id={id} packageName="issue" teamName="bento" fallback="unmount">
			<Placeholder
				name="activity-feed-item-boundary"
				fallback={
					<FeedContainer>
						<FeedSkeleton />
					</FeedContainer>
				}
			>
				{/* @ts-expect-error - TS2322 - Type 'C' is not assignable to type 'IntrinsicAttributes & LibraryManagedAttributes>'. */}
				<WrappedComponent {...props} />
			</Placeholder>
		</JSErrorBoundary>
	);

const AllActivity = withActivityFeedItemBoundary('activity-feed-all-activity')<
	JSX.LibraryManagedAttributes<typeof AllActivityType, ComponentProps<typeof AllActivityType>>
>(
	lazyForPaint<typeof AllActivityType>(
		() =>
			import(
				/* webpackChunkName: "async-activity-feed-item-all-activity" */ '@atlassian/jira-issue-all-activity'
			),
	),
);
AllActivity.displayName = 'AllActivity';

const HistoryWithActivityFeedItemBoundary = withActivityFeedItemBoundaryNew(
	'activity-feed-item-history',
)<JSX.LibraryManagedAttributes<typeof HistoryType, ComponentProps<typeof HistoryType>>>(
	lazyForPaint<typeof HistoryType>(
		() =>
			import(
				/* webpackChunkName: "async-activity-feed-item-history" */ '@atlassian/jira-issue-history'
			),
	),
);
HistoryWithActivityFeedItemBoundary.displayName = 'History';

const History = (
	props: JSX.LibraryManagedAttributes<typeof HistoryType, ComponentProps<typeof HistoryType>>,
) => (
	<UFOSegment name="issue-history">
		<HistoryWithActivityFeedItemBoundary {...props} />
	</UFOSegment>
);

const Worklog = withActivityFeedItemBoundary('activity-feed-item-worklog')<
	JSX.LibraryManagedAttributes<typeof WorklogType, ComponentProps<typeof WorklogType>>
>(
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyForPaint<typeof WorklogType>(
		() =>
			import(
				/* webpackChunkName: "async-activity-feed-item-worklog" */ '@atlassian/jira-issue-view-activity/src/worklog'
			),
		{ ssr: false },
	),
);
Worklog.displayName = 'Worklog';

type ApprovalsType = (props: {
	issueKey: string;
	sortOrder: ActivitySortOrderType;
}) => JSX.Element | null;

const Approvals = withActivityFeedItemBoundary('activity-feed-item-approvals')<
	JSX.LibraryManagedAttributes<ApprovalsType, ComponentProps<ApprovalsType>>
>(
	lazyForPaint<ApprovalsType>(
		() =>
			import(
				/* webpackChunkName: "async-activity-feed-item-approvals" */ '@atlassian/jira-issue-approval-activity'
			),
	),
);
Approvals.displayName = 'Approvals';

export const ActivityFeedItem = ({
	selectedItem,
	baseUrl,
	issueKey,
	timeZone,
	selectedSortOrder,
	extensionData,
	source,
	mediaContext,
}: Props) => {
	if (!selectedItem) return null;
	switch (selectedItem.type) {
		case ALL_ACTIVITY:
			return <AllActivity sortOrder={selectedSortOrder} mediaContext={mediaContext} />;
		case COMMENTS:
			return <Comments />;
		case CONNECT:
			return (
				<ConnectModule
					appKey={selectedItem.appKey}
					moduleKey={selectedItem.moduleKey}
					options={selectedItem.options}
					appName={selectedItem.name}
					location="issueActionsActivityFeed"
				/>
			);
		case FORGE:
			return (
				<ForgeIssueActivityRenderer
					key={selectedItem.extension.id}
					source={source}
					extensionData={extensionData}
					extension={selectedItem.extension}
				/>
			);
		case HISTORY:
			return (
				<History
					baseUrl={toBaseUrl(baseUrl)}
					issueKey={toIssueKey(issueKey)}
					timeZone={timeZone}
					sortOrder={selectedSortOrder}
					GroupedActivityEntryComponent={GroupedActivityEntry}
				/>
			);
		case WORKLOG:
			return <Worklog timeZone={timeZone} />;
		case APPROVALS:
			return <Approvals issueKey={issueKey} sortOrder={selectedSortOrder} />;
		default:
			return null;
	}
};

const ConnectedComponent = connect(
	(state: State): StateProps => ({
		baseUrl: baseUrlSelector(state),
		issueKey: issueKeySelector(state),
		timeZone: undefined,
		extensionData: forgeIssueDataSelector(state),
		source: analyticsSourceSelector(state),
		mediaContext: mediaContextSelector(state),
	}),
	{},
)(ActivityFeedItem);

export default ConnectedComponent;
