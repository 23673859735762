import React from 'react';
import Button, { IconButton } from '@atlaskit/button/new';
import LikeIcon from '@atlaskit/icon/glyph/like';
import { Text } from '@atlaskit/primitives';
import { B400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import TransitiveNumber from '@atlassian/react-transitive-number';
import { FIELD_KEY } from '../types';

type VoteButtonProps = {
	testId: string;
	onClick?: (e: React.MouseEvent<HTMLElement>) => void;
	isSelected: boolean;
	label: string;
	ariaExpanded?: boolean;
	isDisabled?: boolean;
};

const VoteButtonRefresh = ({
	testId,
	isSelected,
	isDisabled,
	label,
	ariaExpanded,
	onClick,
}: VoteButtonProps) => {
	const issueKey = useIssueKey();
	const [{ hasVoted, votes }] = useFieldValue({
		issueKey,
		fieldKey: FIELD_KEY,
	});

	return votes !== 0 ? (
		<Button
			testId={testId}
			isSelected={hasVoted}
			appearance="default"
			spacing="default"
			iconBefore={() => (
				<LikeIcon
					testId="issue-field-voters.common.vote-button-refresh.like-icon"
					label=""
					size="medium"
					{...(hasVoted ? { primaryColor: token('color.icon.selected', B400) } : {})}
				/>
			)}
			aria-label={label}
			aria-expanded={ariaExpanded}
			isDisabled={isDisabled}
			onClick={onClick}
		>
			<Text
				testId="issue-field-voters.common.vote-button-refresh.counter"
				{...(hasVoted ? { color: 'color.text.selected' } : {})}
			>
				<TransitiveNumber>{votes !== 0 ? votes : ''}</TransitiveNumber>
			</Text>
		</Button>
	) : (
		<IconButton
			testId={testId}
			icon={() => (
				<LikeIcon
					testId="issue-field-voters.common.vote-button-refresh.like-icon"
					label={label}
					size="medium"
				/>
			)}
			isSelected={isSelected}
			label={label}
			aria-expanded={ariaExpanded}
			isDisabled={isDisabled}
			onClick={onClick}
		/>
	);
};

export default VoteButtonRefresh;
