import React, { useCallback } from 'react';
import { useFragment, graphql } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { ProjectInlineEditView } from '@atlassian/jira-issue-field-project-inline-edit-full/src/ui/project/index.tsx';
import type { AggJiraProject } from '@atlassian/jira-issue-field-project-inline-edit-full/src/ui/project/types.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import { IssueViewFieldHeading } from '@atlassian/jira-issue-view-layout-field-heading/src/ui/index.tsx';
import type { ui_issueViewLayoutProjectField_IssueViewProjectField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutProjectField_IssueViewProjectField.graphql';

export interface IssueViewProjectFieldProps {
	area?: Area;
	fragmentKey: ui_issueViewLayoutProjectField_IssueViewProjectField$key;
}

export const IssueViewProjectField = ({ area, fragmentKey }: IssueViewProjectFieldProps) => {
	const issueId = useIssueId();
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();

	const data = useFragment<ui_issueViewLayoutProjectField_IssueViewProjectField$key>(
		graphql`
			fragment ui_issueViewLayoutProjectField_IssueViewProjectField on JiraProjectField {
				...ui_issueViewLayoutFieldHeading_IssueViewFieldHeading
				...project_issueFieldProjectInlineEditFull_ProjectInlineEditView_fragmentRef
				fieldId
				type
				__typename
			}
		`,
		fragmentKey,
	);

	const onSubmit = useCallback(
		(value: AggJiraProject) => {
			issueId &&
				fieldChangeRequested(issueId, data.fieldId, value, undefined, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChangeRequested, issueId],
	);

	const onSubmitSucceeded = useCallback(
		(value: AggJiraProject) => {
			issueId &&
				fieldChanged(issueId, data.fieldId, value, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChanged, issueId],
	);

	const onSubmitFailed = useCallback(
		() => issueId && fieldChangeFailed(issueId, data.fieldId),
		[data.fieldId, fieldChangeFailed, issueId],
	);

	return (
		<IssueViewFieldHeading
			area={area}
			fragmentKey={data}
			testId={`issue.issue-view-layout.issue-view-project-field.${data.fieldId}`}
		>
			<Box xcss={fieldWrapperStyles}>
				<ProjectInlineEditView
					fragmentRef={data}
					onSubmit={onSubmit}
					onSubmitSucceeded={onSubmitSucceeded}
					onSubmitFailed={onSubmitFailed}
				/>
			</Box>
		</IssueViewFieldHeading>
	);
};

const fieldWrapperStyles = xcss({
	marginLeft: 'space.negative.100',
	marginRight: 'space.100',
});
