import { createSelector } from 'reselect';
import { entitiesSelector } from '../common/state/selectors/issue-selector';

const confluencePagesSelector = createSelector(
	entitiesSelector,
	(entities) => entities.confluencePages,
);

const confluenceWhiteboardsSelector = createSelector(
	entitiesSelector,
	(entities) => entities.confluenceWhiteboards,
);

const createLinkedPageSelector = createSelector(
	confluencePagesSelector,
	(confluencePages) => confluencePages.createLinkedPage,
);

const createLinkedWhiteboardSelector = createSelector(
	confluenceWhiteboardsSelector,
	(confluenceWhiteboards) => confluenceWhiteboards.createLinkedWhiteboard,
);

export const confluencePageAISuggestionIsOpenSelector = createSelector(
	confluencePagesSelector,
	(confluencePages) => confluencePages.isAISuggestionsOpen,
);

export const createLinkedPageErrorMessageSelector = createSelector(
	createLinkedPageSelector,
	(createLinkedPage) => createLinkedPage.errorMessage,
);

export const createLinkedPageRepairLinkSelector = createSelector(
	createLinkedPageSelector,
	(createLinkedPage) => createLinkedPage.repairLink,
);

export const isCreateLinkedPageInvalidSelector = createSelector(
	createLinkedPageSelector,
	(createLinkedPage) => createLinkedPage.isInvalid,
);

export const isCreateLinkedPageLoadingSelector = createSelector(
	createLinkedPageSelector,
	(createLinkedPage) => createLinkedPage.isLoading,
);

export const isCreateLinkedPageOpenedSelector = createSelector(
	createLinkedPageSelector,
	(createLinkedPage) => createLinkedPage.clickedCount > 0,
);

export const createLinkedPageClickedCountSelector = createSelector(
	createLinkedPageSelector,
	(createLinkedPage) => createLinkedPage.clickedCount,
);

const linkedConfluencePagesSelector = createSelector(
	confluencePagesSelector,
	(confluencePages) => confluencePages && confluencePages.linkedPages,
);

const linkedConfluenceWhiteboardsSelector = createSelector(
	confluenceWhiteboardsSelector,
	(confluenceWhiteboards) => confluenceWhiteboards?.linkedWhiteboards,
);

const mentionedConfluencePagesSelector = createSelector(
	confluencePagesSelector,
	(confluencePages) => confluencePages && confluencePages.mentionedPages,
);

export const isConfluencePageLinksEnabledSelector = createSelector(
	confluencePagesSelector,
	(confluencePages) => !!(confluencePages && confluencePages.isLinkingEnabled),
);

export const linkedConfluencePagesPagesSelector = createSelector(
	linkedConfluencePagesSelector,
	(linkedConfluencePages) => linkedConfluencePages && linkedConfluencePages.pages,
);

export const linkedConfluenceWhiteboardsWhiteboardsSelector = createSelector(
	linkedConfluenceWhiteboardsSelector,
	(linkedConfluenceWhiteboards) => linkedConfluenceWhiteboards?.whiteboards,
);

export const mentionedConfluencePagesPagesSelector = createSelector(
	mentionedConfluencePagesSelector,
	(mentionedConfluencePages) => mentionedConfluencePages && mentionedConfluencePages.pages,
);

export const linkedConfluencePagesCountSelector = createSelector(
	linkedConfluencePagesSelector,
	(linkedConfluencePages) => linkedConfluencePages && linkedConfluencePages.pageCount,
);

export const linkedConfluenceWhiteboardsCountSelector = createSelector(
	linkedConfluenceWhiteboardsSelector,
	(linkedConfluenceWhiteboards) => linkedConfluenceWhiteboards?.whiteboardCount,
);

export const isCreateLinkedWhiteboardLoadingSelector = createSelector(
	createLinkedWhiteboardSelector,
	(createLinkedWhiteboard) => createLinkedWhiteboard?.isLoading,
);

export const mentionedConfluencePagesCountSelector = createSelector(
	mentionedConfluencePagesSelector,
	(mentionedConfluencePages) => mentionedConfluencePages && mentionedConfluencePages.pageCount,
);
