import { colors } from '@atlaskit/theme';
import messages from './messages';

type ColorRange = { start: number; end: number; color: string };

export const ScorecardStatusThreshold = {
	FAILING: 0,
	NEEDS_ATTENTION: 50,
	PASSING: 100,
} as const;

export type ScorecardStatusThreshold =
	(typeof ScorecardStatusThreshold)[keyof typeof ScorecardStatusThreshold];

const COLOR_RANGES: ColorRange[] = [
	{
		start: ScorecardStatusThreshold.FAILING,
		end: ScorecardStatusThreshold.NEEDS_ATTENTION - 1,
		color: colors.R300,
	},
	{
		start: ScorecardStatusThreshold.NEEDS_ATTENTION,
		end: ScorecardStatusThreshold.PASSING - 1,
		color: colors.Y300,
	},
	{
		start: ScorecardStatusThreshold.PASSING,
		end: ScorecardStatusThreshold.PASSING,
		color: colors.G300,
	},
];

export const getColorForProgress = (progress: number) => {
	for (const { start, end, color } of COLOR_RANGES) {
		if (progress >= start && progress <= end) {
			return color;
		}
	}

	// Default.
	return colors.R300;
};

export type ScorecardHealthCount = {
	total: number;
	passing: number;
	needsAttention: number;
};

// To be replaced by generated GraphQL type.
export type ScorecardScore = {
	totalScore: number;
	maxTotalScore: number;
};

export const PASSING_THRESHOLD = 90;
export const NEEDS_ATTENTION_THRESHOLD = 50;

// gets the count of failing and needs attention scorecards
export const getScorecardHealthCountsFromScores = (
	scorecardScores: ScorecardScore[],
): ScorecardHealthCount => {
	let failingScorecards = 0;
	let needsAttentionScorecards = 0;
	let totalScorecards = 0;
	scorecardScores.forEach((scorecardScore) => {
		const totalScore = scorecardScore?.totalScore ?? 0;
		totalScorecards += 1;
		if (totalScore < NEEDS_ATTENTION_THRESHOLD) {
			failingScorecards += 1;
		} else if (totalScore >= NEEDS_ATTENTION_THRESHOLD && totalScore < PASSING_THRESHOLD) {
			needsAttentionScorecards += 1;
		}
	});

	return {
		total: totalScorecards,
		passing: totalScorecards - failingScorecards - needsAttentionScorecards,
		needsAttention: needsAttentionScorecards,
	};
};

export const getSummaryText = (
	scorecardHealthCount: ScorecardHealthCount,
	totalScorecardsCount: number,

	formatMessage: Function,
): string => {
	const { passing } = scorecardHealthCount;
	const { needsAttention } = scorecardHealthCount;
	let numerator = 0;
	let message;

	if (passing === 0 && totalScorecardsCount === 0) {
		message = messages.noScorecards;
	} else if (passing + needsAttention < totalScorecardsCount) {
		numerator = Math.floor(totalScorecardsCount) - Math.floor(passing) - Math.floor(needsAttention);
		message = messages.summaryTextForFailing;
	} else if (needsAttention > 0) {
		numerator = Math.floor(needsAttention);
		message = messages.summaryTextForNeedsAttention;
	} else {
		numerator = Math.floor(totalScorecardsCount);
		message = messages.summaryTextForHealthy;
	}

	return formatMessage(message, {
		numerator,
	});
};

// Mocked type - will be replaced with generated GraphQL type.
export type Scorecard = {
	id: string;
	name: string;
	scorecardScore: ScorecardScore;
};

export const getScorecardsSortedByHealth = (scorecards: Scorecard[]): Scorecard[] =>
	[...scorecards].sort((a, b) => {
		const aTotalScore = a?.scorecardScore?.totalScore ?? 0;
		const bTotalScore = b?.scorecardScore?.totalScore ?? 0;

		return aTotalScore - bTotalScore;
	});

export function getIdFromAri(ari: string) {
	const regex = /\/([\w-]+)$/; // Matches the id at the end of the string
	const match = ari.match(regex);
	return match ? match[1] : '';
}
