import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type DevPanel from './view';
import type { Props } from './view/types';

const LazyDevPanel = lazyForPaint<typeof DevPanel>(
	() => import(/* webpackChunkName: "async-dev-panel" */ './view/index'),
);

export const AsyncDevPanel = (props: Props) => (
	<Placeholder name="async-dev-panel" fallback={<></>}>
		<LazyDevPanel {...props} />
	</Placeholder>
);
