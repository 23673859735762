// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	readOnlyLabel: {
		id: 'issue.views.issue-base.foundation.read-only-label',
		defaultMessage: 'Read-only Color, {color} selected',
		description: 'Field label. Appears when a user focuses on the field.',
	},
	label: {
		id: 'issue.views.issue-base.foundation.label',
		defaultMessage: 'edit epic color',
		description: 'Field label. Appears when a user hovers the field.',
	},
	purple: {
		id: 'issue.views.issue-base.foundation.purple',
		defaultMessage: 'purple',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	darkPurple: {
		id: 'issue.views.issue-base.foundation.dark-purple',
		defaultMessage: 'dark purple',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	blue: {
		id: 'issue.views.issue-base.foundation.blue',
		defaultMessage: 'blue',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	darkBlue: {
		id: 'issue.views.issue-base.foundation.dark-blue',
		defaultMessage: 'dark blue',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	green: {
		id: 'issue.views.issue-base.foundation.green',
		defaultMessage: 'green',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	darkGreen: {
		id: 'issue.views.issue-base.foundation.dark-green',
		defaultMessage: 'dark green',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	teal: {
		id: 'issue.views.issue-base.foundation.teal',
		defaultMessage: 'teal',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	darkTeal: {
		id: 'issue.views.issue-base.foundation.dark-teal',
		defaultMessage: 'dark teal',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	yellow: {
		id: 'issue.views.issue-base.foundation.yellow',
		defaultMessage: 'yellow',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	darkYellow: {
		id: 'issue.views.issue-base.foundation.dark-yellow',
		defaultMessage: 'dark yellow',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	orange: {
		id: 'issue.views.issue-base.foundation.orange',
		defaultMessage: 'orange',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	darkOrange: {
		id: 'issue.views.issue-base.foundation.dark-orange',
		defaultMessage: 'dark orange',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	grey: {
		id: 'issue.views.issue-base.foundation.grey',
		defaultMessage: 'grey',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
	darkGrey: {
		id: 'issue.views.issue-base.foundation.dark-grey',
		defaultMessage: 'dark grey',
		description: 'Color field option label. Appears when a user hovers the option.',
	},
});
