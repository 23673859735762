import { defineMessages } from '@atlassian/jira-intl';
// eslint-disable-next-line jira/import/no-restricted-import
import { ENTITY_LIMIT_TYPE as COLLECTION_ENTITY_LIMIT_TYPE } from '@atlassian/jira-polaris-domain-collection/src/constants';
// eslint-disable-next-line jira/import/no-restricted-import
import { ENTITY_LIMIT_TYPE as PROJECT_ENTITY_LIMIT_TYPE } from '@atlassian/jira-polaris-domain-project/src/constants.tsx';

export default defineMessages({
	title: {
		id: 'polaris-lib-limits.common.title',
		defaultMessage: 'Unavailable',
		description: 'Entity limit warning title',
	},
	/* eslint-disable jira/i18n/id-named-by-package */
	[PROJECT_ENTITY_LIMIT_TYPE.COMMENTS_PER_VIEW]: {
		id: 'polaris-lib-limits.common.comments-per-view',
		defaultMessage:
			'This view has reached its maximum of {limit, plural, one {# comment} other {# comments}}.',
		description: 'Entity limit warning description for comments per view',
	},
	[PROJECT_ENTITY_LIMIT_TYPE.FIELDS_PER_VIEW]: {
		id: 'polaris-lib-limits.common.fields-per-view',
		defaultMessage:
			'This view has reached its maximum of {limit, plural, one {# field} other {# fields}}.',
		description: 'Entity limit warning description for fields per view',
	},
	[PROJECT_ENTITY_LIMIT_TYPE.FIELDS_PER_PROJECT]: {
		id: 'polaris-lib-limits.common.fields-per-project',
		defaultMessage:
			'This project has reached its maximum of {limit, plural, one {# field} other {# fields}}.',
		description: 'Entity limit warning description for fields per project',
	},
	[PROJECT_ENTITY_LIMIT_TYPE.IDEAS_SEARCH_MAX_RESULTS]: {
		id: 'polaris-lib-limits.common.ideas-search-max-results',
		defaultMessage:
			'This search has reached its maximum of {limit, plural, one {# result} other {# results}}.',
		description: 'Entity limit warning description for ideas search max results',
	},
	[PROJECT_ENTITY_LIMIT_TYPE.INSIGHTS_PER_IDEA]: {
		id: 'polaris-lib-limits.common.insights-per-idea',
		defaultMessage:
			'This idea has reached its maximum of {limit, plural, one {# insight} other {# insights}}.',
		description: 'Entity limit warning description for insights per idea',
	},
	[PROJECT_ENTITY_LIMIT_TYPE.INSIGHTS_PER_PROJECT]: {
		id: 'polaris-lib-limits.common.insights-per-project',
		defaultMessage:
			'This project has reached its maximum of {limit, plural, one {# insight} other {# insights}}.',
		description: 'Entity limit warning description for insights per project',
	},
	[PROJECT_ENTITY_LIMIT_TYPE.LABELS_PER_SNIPPET]: {
		id: 'polaris-lib-limits.common.labels-per-snippet',
		defaultMessage:
			'This snippet has reached its maximum of {limit, plural, one {# label} other {# labels}}.',
		description: 'Entity limit warning description for labels per snippet',
	},
	[PROJECT_ENTITY_LIMIT_TYPE.OPTIONS_PER_FIELD]: {
		id: 'polaris-lib-limits.common.options-per-field',
		defaultMessage:
			'This field has reached its maximum of {limit, plural, one {# option} other {# options}}.',
		description: 'Entity limit warning description for options per field',
	},
	[PROJECT_ENTITY_LIMIT_TYPE.PLAY_CONTRIBUTIONS_PER_PLAY]: {
		id: 'polaris-lib-limits.common.play-contributions-per-play',
		defaultMessage:
			'This vote has reached its maximum of {limit, plural, one {# vote contribution} other {# vote contributions}}.',
		description: 'Entity limit warning description for play contributions per play',
	},
	[PROJECT_ENTITY_LIMIT_TYPE.PLAY_CONTRIBUTIONS_PER_PROJECT]: {
		id: 'polaris-lib-limits.common.play-contributions-per-project',
		defaultMessage:
			'This project has reached its maximum of {limit, plural, one {# vote contribution} other {# vote contributions}}.',
		description: 'Entity limit warning description for play contributions per project',
	},
	[PROJECT_ENTITY_LIMIT_TYPE.PLAYS_PER_PROJECT]: {
		id: 'polaris-lib-limits.common.plays-per-project',
		defaultMessage:
			'This project has reached its maximum of {limit, plural, one {# vote} other {# votes}}.',
		description: 'Entity limit warning description for plays per project',
	},
	[PROJECT_ENTITY_LIMIT_TYPE.SNIPPETS_PER_INSIGHT]: {
		id: 'polaris-lib-limits.common.snippets-per-insight',
		defaultMessage:
			'This insight has reached its maximum of {limit, plural, one {# snippet} other {# snippets}}.',
		description: 'Entity limit warning description for snippets per insight',
	},
	[PROJECT_ENTITY_LIMIT_TYPE.VIEWS_PER_PROJECT]: {
		id: 'polaris-lib-limits.common.views-per-project',
		defaultMessage:
			'This project has reached its maximum of {limit, plural, one {# view} other {# views}}.',
		description: 'Entity limit warning description for views per project',
	},
	[COLLECTION_ENTITY_LIMIT_TYPE.VIEWS_PER_COLLECTION]: {
		id: 'polaris-lib-limits.common.views-per-collection',
		defaultMessage:
			'This roadmap has reached its maximum of {limit, plural, one {# view} other {# views}}.',
		description: 'Entity limit warning description for views per roadmap',
	},
	[PROJECT_ENTITY_LIMIT_TYPE.VIEWSETS_PER_PROJECT]: {
		id: 'polaris-lib-limits.common.viewsets-per-project',
		defaultMessage:
			'This project has reached its maximum of {limit, plural, one {# section} other {# sections}}.',
		description: 'Entity limit warning description for viewsets per project',
	},
	[COLLECTION_ENTITY_LIMIT_TYPE.COLLECTIONS_PER_SITE]: {
		id: 'polaris-lib-limits.common.collections-per-site',
		defaultMessage:
			'This site has reached its maximum of {limit, plural, one {# roadmap} other {# roadmaps}}.',
		description: 'Entity limit warning description for roadmaps per site',
	},
	/* eslint-enable jira/i18n/id-named-by-package */
});
