import React, { useState, useRef, useEffect } from 'react';
import { styled } from '@compiled/react';
import { xcss, Box, Pressable, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { FormattedMessage } from '@atlassian/jira-intl';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { Emphasise } from '@atlassian/jira-issue-view-activity-common/src/styles';
import messages from '../../../../common/messages';
import changeMessages from '../../../../common/ui/change/messages';
import GroupedChange from '../../../../common/ui/grouped-change';
import HistoryItem from '../../../../common/ui/history-item';
import type { RichTextFieldChangedHistoryItem } from '../../../../types';
import richTextHistoryItemMessages from './messages';

type Props = {
	isGrouped?: Boolean;
	historyItem: RichTextFieldChangedHistoryItem;
};

const RichTextHistoryItem = ({ historyItem, isGrouped = false }: Props) => {
	const { actor, field, fieldType, timestamp, toValue } = historyItem;
	const normalizedField = fieldType === 'jira' ? field?.toLocaleLowerCase() : field;
	const [isExpanded, setIsExpanded] = useState<Boolean>(false);
	const contentRef = useRef<HTMLDivElement>(null);
	const [isClamped, setClamped] = useState<Boolean>(false);

	const intl = useIntl();

	useEffect(() => {
		if (contentRef && contentRef.current) {
			setClamped(contentRef.current.scrollHeight > contentRef.current.clientHeight);
		}
	}, []);

	return (
		<FormattedMessage
			{...messages.updatedField}
			values={{
				field: <Emphasise key="field">{normalizedField}</Emphasise>,
			}}
		>
			{(...action) =>
				isGrouped ? (
					<GroupedChange
						isRichTextField
						field={field}
						to={
							<Box xcss={containerStyles}>
								<Box ref={contentRef} xcss={isExpanded ? textStyles : textStylesTruncated}>
									{toValue || <Subtle>{intl.formatMessage(changeMessages.none)}</Subtle>}
								</Box>
								{isClamped && (
									<Pressable
										xcss={pressableStyles}
										padding="space.0"
										onClick={() => setIsExpanded((prevIsExpanded) => !prevIsExpanded)}
									>
										<Text size="small" weight="semibold" color="color.text.subtle">
											<FormattedMessage
												{...(isExpanded
													? richTextHistoryItemMessages.showLess
													: richTextHistoryItemMessages.showMore)}
											/>
										</Text>
									</Pressable>
								)}
							</Box>
						}
					/>
				) : (
					<HistoryItem
						testId="issue-history.ui.history-items.history-item.rich-text-history-item.history-item"
						actor={actor}
						action={action}
						timestamp={timestamp}
						change={
							<Box xcss={containerStyles}>
								<Box ref={contentRef} xcss={isExpanded ? textStyles : textStylesTruncated}>
									{toValue || <Subtle>{intl.formatMessage(changeMessages.none)}</Subtle>}
								</Box>
								{isClamped && (
									<Pressable
										xcss={pressableStyles}
										padding="space.0"
										onClick={() => setIsExpanded((prevIsExpanded) => !prevIsExpanded)}
									>
										<Text size="small" weight="semibold" color="color.text.subtle">
											<FormattedMessage
												{...(isExpanded
													? richTextHistoryItemMessages.showLess
													: richTextHistoryItemMessages.showMore)}
											/>
										</Text>
									</Pressable>
								)}
							</Box>
						}
					/>
				)
			}
		</FormattedMessage>
	);
};

const textStyles = xcss({
	overflowX: 'auto',
});

const textStylesTruncated = xcss({
	overflowX: 'auto',
	display: '-webkit-box',
	WebkitLineClamp: '3',
	WebkitBoxOrient: 'vertical',
	overflow: 'hidden',
});

const containerStyles = xcss({
	marginTop: 'space.100',
	color: 'color.text.subtle',
	overflowWrap: 'anywhere',
});

const pressableStyles = xcss({
	marginTop: 'space.050',
	backgroundColor: 'color.background.neutral.subtle',
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Subtle = styled.span({
	color: token('color.text.subtlest', '#626F86'),
});

Subtle.displayName = 'Subtle';

export default RichTextHistoryItem;
