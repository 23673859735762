import { combineReducers } from 'redux';
import app from './app';
import details from './details';
import ui from './ui';

export default combineReducers({
	app,
	ui,
	details,
});
