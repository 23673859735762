import { createSelector } from 'reselect';
import {
	fieldSelector,
	isFieldEditableSelector,
} from '../../../common/state/selectors/field-selector';
import { isCompletedLoadingSelector } from '../../../common/state/selectors/issue-selector';
import { permissionsSelector } from '../../../common/state/selectors/permissions-selector';

export const closedSprintsSelector = (fieldId: string) =>
	createSelector(fieldSelector(fieldId), (sprints) =>
		sprints && sprints.value
			? sprints.value.filter(
					// @ts-expect-error - TS7006 - Parameter 'sprint' implicitly has an 'any' type.
					(sprint) => sprint.state === 'closed' || sprint.state === 'CLOSED',
				)
			: [],
	);

export const canChangeSprintSelector = (fieldId: string) =>
	createSelector(
		permissionsSelector,
		isCompletedLoadingSelector,
		isFieldEditableSelector(fieldId),
		(permissions, isCompletedLoading, isFieldEditable) =>
			permissions.canScheduleIssues && isCompletedLoading && isFieldEditable,
	);
