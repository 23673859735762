// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'issue.content.ecosystem.forge.panel.actions.title',
		defaultMessage: 'Actions',
		description:
			'A title of the action dropdown, displayed when meatball icon of the Forge panel is clicked.',
	},
	remove: {
		id: 'issue.content.ecosystem.forge.panel.actions.remove',
		defaultMessage: 'Remove',
		description:
			'A label for the dropdown item, can be accessed via Forge panel actions. When clicked the Forge panel gets permanently removed from the issue view.',
	},
	collapse: {
		id: 'issue.content.ecosystem.forge.panel.actions.collapse',
		defaultMessage: 'Collapse',
		description:
			'A label for the dropdown item, can be accessed via Forge panel actions. When clicked previously expanded Forge panel content gets collapsed.',
	},
	expand: {
		id: 'issue.content.ecosystem.forge.panel.actions.expand',
		defaultMessage: 'Expand',
		description:
			'A label for the dropdown item, can be accessed via Forge panel actions. When clicked previously collapsed the Forge panel content gets expanded.',
	},
});
