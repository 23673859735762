import React from 'react';
import { styled } from '@compiled/react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { FormattedMessage } from '@atlassian/jira-intl';
import { Emphasise } from '@atlassian/jira-issue-view-activity-common/src/styles';
import messages from '../../../../common/messages';
import Change from '../../../../common/ui/change';
import GroupedChange from '../../../../common/ui/grouped-change';
import HistoryItem from '../../../../common/ui/history-item';
import type { FieldChangedHistoryItem } from '../../../../types';

type Props = {
	isGrouped?: Boolean;
	historyItem: FieldChangedHistoryItem;
};

const GenericHistoryItem = ({ historyItem, isGrouped = false }: Props) => {
	const { actor, field, fieldType, timestamp, fromValue, toValue } = historyItem;
	const normalizedField = fieldType === 'jira' ? field?.toLocaleLowerCase() : field;

	return (
		<FormattedMessage
			{...messages.updatedField}
			values={{
				field: <Emphasise key="field">{normalizedField}</Emphasise>,
			}}
		>
			{(...action) =>
				isGrouped && expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false) ? (
					<GroupedChange
						field={field}
						from={fromValue != null && fromValue !== '' ? <Value>{fromValue}</Value> : null}
						to={toValue != null && toValue !== '' ? <Value>{toValue}</Value> : null}
					/>
				) : (
					<HistoryItem
						// eslint-disable-next-line jira/integration/test-id-by-folder-structure
						testId="issue-history.ui.history-items.generic-history-item.history-item"
						actor={actor}
						action={action}
						timestamp={timestamp}
						change={
							<Change
								from={fromValue != null && fromValue !== '' ? <Value>{fromValue}</Value> : null}
								to={toValue != null && toValue !== '' ? <Value>{toValue}</Value> : null}
							/>
						}
					/>
				)
			}
		</FormattedMessage>
	);
};

export default GenericHistoryItem;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Value = styled.span({
	overflowX: 'auto',
});

Value.displayName = 'Value';
