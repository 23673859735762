import { FieldValueLookupError } from '../errors/field-value-lookup-error.tsx';
import { baseScopesRequiredPerField } from '../fields-scopes';
import type { LookupUser, LookupUsers } from '../types/lookup.tsx';
import { isNotEmptyString } from '../utils/is-not-empty-string';
import { singleValueLookup } from '../utils/single-value-lookup';

export type AssigneeInternalValue = null | {
	accountId: string;
	displayName: string;
	avatarUrls: {
		['48x48']?: string;
		[key: string]: string | undefined;
	};
};

export type AssigneePublicSetValue = null | string;

export type AssigneePublicGetValue = null | {
	accountId: string;
};

export const assigneeSetValueValidator = (value: unknown): value is AssigneePublicSetValue =>
	value === null || isNotEmptyString(value);

export const assigneeSetValueTransformer = (
	value: AssigneePublicSetValue,
	lookupValues?: LookupUsers,
): AssigneeInternalValue => {
	if (value === null) {
		return null;
	}

	const foundValue = singleValueLookup<LookupUser>(value, lookupValues, 'accountId');

	if (!foundValue) {
		throw new FieldValueLookupError();
	}

	const { accountId, name: displayName, picture } = foundValue;

	return {
		accountId,
		displayName,
		avatarUrls: { '48x48': picture },
	};
};

// Sometimes the assignee value contains extra properties that we want to filter out
export const assigneeGetValueTransformer = (
	value: AssigneeInternalValue,
): AssigneePublicGetValue => {
	if (value === null) {
		return null;
	}
	const { accountId } = value;
	return { accountId };
};

export const assigneeScopes = {
	...baseScopesRequiredPerField,
	granular: [...baseScopesRequiredPerField.granular, 'read:user:jira'],
	coarse: [...baseScopesRequiredPerField.coarse, 'read:jira-user'],
};
