// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	actions: {
		id: 'issue.views.common.child-issues-panel.heading.meatballs.actions',
		defaultMessage: 'Actions',
		description: 'Heading for the dropdown menu group containing the actions for children-issues',
	},
	filter: {
		id: 'issue.views.common.child-issues-panel.heading.meatballs.filter',
		defaultMessage: 'Filter',
		description: 'Heading for the dropdown menu group containing the filters for children-issues',
	},
	summaryDisplay: {
		id: 'issue.views.common.child-issues-panel.heading.meatballs.summary-display',
		defaultMessage: 'Summary display',
		description:
			'Heading for the dropdown menu group containing the options for displaying the summary of children-issues',
	},
	bulkEdit: {
		id: 'issue.views.common.child-issues-panel.heading.meatballs.bulk-edit',
		defaultMessage: 'Bulk edit',
		description: 'An action link to go begin the bulk edit process for these issues',
	},
	giveFeedback: {
		id: 'issue.views.common.child-issues-panel.heading.meatballs.give-feedback',
		defaultMessage: 'Give feedback',
		description: 'An action link to give feedback on the child issues panel',
	},
	hideDone: {
		id: 'issue.views.common.child-issues-panel.heading.meatballs.hide-done',
		defaultMessage: 'Hide done',
		description: 'A filter option to hide children-issues which are in the DONE category',
	},
	search: {
		id: 'issue.views.common.child-issues-panel.heading.meatballs.search',
		defaultMessage: 'View in search',
		description: 'An action link to view the list of visible children-issues in Jira search',
	},
	showStoryPoints: {
		id: 'issue.views.common.child-issues-panel.heading.meatballs.show-story-points',
		defaultMessage: 'Show total story points',
		description: 'A summary display option to show story points',
	},
	tooltip: {
		id: 'issue.views.common.child-issues-panel.heading.meatballs.tooltip',
		defaultMessage: 'Issue actions',
		description: 'A tooltip message for the dropdown menu containing children-issue actions',
	},
	disabledHideDone: {
		id: 'issue.views.common.child-issues-panel.heading.meatballs.disabled-hide-done',
		defaultMessage: 'To filter more than 100 child issues, select ... -> View in Search',
		description: 'A disabled message for hide done button when over the child issues limit',
	},
	disabledShowStoryPoints: {
		id: 'issue.views.common.child-issues-panel.heading.meatballs.disabled-show-story-points',
		defaultMessage:
			'Add some story point values to your children issues to view story point progress',
		description:
			'A disabled message for show story points button when users have no issues with story point value',
	},
});
