import React, { type ReactNode, type ChangeEvent } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line import/order, jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import SingleLineTextField from '@atlassian/jira-issue-internal-field-single-line-text';
import { originalEstimateEditTestId } from '../../common/constants';

type Props = {
	isInvalidInput: boolean;
	isCompact?: boolean;
	invalidTimeFormatMessage: undefined | ReactNode;
	placeholder: string;
	testId?: string;
	value: string;
	onChange: (arg1: ChangeEvent<HTMLInputElement>) => void;
};

export const OriginalEstimateEdit = ({
	testId = originalEstimateEditTestId,
	isInvalidInput,
	invalidTimeFormatMessage,
	placeholder,
	value,
	onChange,
	isCompact = false,
}: Props) => (
	<Container data-testid={testId} isCompact={isCompact}>
		<SingleLineTextField
			compact
			type="text"
			value={value}
			placeholder={placeholder}
			onChange={onChange}
			invalidMessage={isInvalidInput ? invalidTimeFormatMessage : undefined}
		/>
	</Container>
);

export default OriginalEstimateEdit;

export const compactWidthPixels = gridSize * 14.5;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const ContainerControl = styled.div<{ isCompact: boolean }>(({ isCompact }) => ({
	cursor: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: isCompact ? `${compactWidthPixels}px` : '100%',
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles
const ContainerExperiment = styled2.div<{ isCompact: boolean }>(({ isCompact }) => ({
	cursor: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: isCompact ? `${compactWidthPixels}px` : '100%',
}));

const Container = styledComponentWithCondition(
	() => ff('compiled-react-migration-issue-view_u9myz'),
	ContainerExperiment,
	ContainerControl,
);
