import { createSelector } from 'reselect';
import every from 'lodash/every';
import isFunction from 'lodash/isFunction';
import some from 'lodash/some';
import values from 'lodash/values';
import { ff, getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey, Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import {
	TEXT_FIELD_FILTER,
	type Filter,
	type FilterOperator,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { ISSUES_PAGE_LIMIT } from '../constants';
import type { Props, State, BasicIssue } from '../types';
import type { FieldMapping } from '../utils/field-mapping/types';
import { getFields, getAllFieldsByKey, getFieldMappings, getFieldMappingsAsList } from './fields';
import { getRankedIssueIds, getIssuesWithBasicPropertiesMap } from './issue-ids';
import { createSpecificallyMemoizedDataSelector, getIssueIdsInCreation } from './properties';

const EMPTY_FILTER: Array<Filter> = [];

export type IssueFilterFunction = (
	arg1: State,
	arg2: Props | undefined,
	arg3: LocalIssueId,
) => boolean;

type NumericGroupValue = {
	numericValue: number | undefined;
	operator: FilterOperator;
};

type ActiveFilterWithGroupValues = {
	field: Field;
	groupValues: (string | NumericGroupValue | undefined)[];
};

const getCurrentFilter = createSelector(
	getFields,
	(_state: State, props?: Props) => props?.filter,
	(fields, filter?: Filter[]): Filter[] => {
		if (!filter) {
			return EMPTY_FILTER;
		}
		return filter.filter((filterValue) => {
			// text filters do not have fields
			if (filterValue.type === TEXT_FIELD_FILTER) {
				return true;
			}

			return fields.some((field) => field.key === filterValue.field);
		});
	},
);

export const getActiveFiltersGroupValues = createSelector(
	getCurrentFilter,
	getAllFieldsByKey,
	(filters, fieldsByKey) => {
		const retVal: Array<ActiveFilterWithGroupValues> = [];
		filters.forEach((filter) => {
			if (filter.type === 'FIELD' && fieldsByKey[filter.field]) {
				retVal.push({
					field: fieldsByKey[filter.field],
					groupValues: filter.values.map(({ stringValue }) => stringValue),
				});
			} else if (filter.type === 'NUMBER' && fieldsByKey[filter.field]) {
				retVal.push({
					field: fieldsByKey[filter.field],
					groupValues: filter.values,
				});
			}
		});
		return retVal;
	},
);

const getFilterFunction = createSelector(
	getCurrentFilter,
	getFieldMappingsAsList,
	(filters: Filter[], fieldMappings: FieldMapping<unknown>[]) => {
		const conjunctFilters: Array<(state: State, props: Props, id: LocalIssueId) => boolean> = [];
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const disjunctFilters: Record<string, any> = {};
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const quickFilters: Record<string, any> = {};
		filters.forEach((filter) => {
			fieldMappings.forEach((mapping: FieldMapping<unknown>) => {
				const filterValueFunc = mapping.getFilter(filter);
				if (filterValueFunc !== undefined) {
					if (isFunction(filterValueFunc)) {
						const filterFunc = (state: State, props: Props, id: LocalIssueId) => {
							const value = mapping.valueAccessor(state, props, id);
							return filterValueFunc(value, state, props, id);
						};
						conjunctFilters.push(filterFunc);
					} else if (filterValueFunc.disjunctionIdentifier !== undefined) {
						const filterFunc = (state: State, props: Props, id: LocalIssueId) => {
							const value = mapping.valueAccessor(state, props, id);
							return filterValueFunc.filterFunction(value, state, props, id);
						};
						if (!('localId' in filter) || filter.localId !== 'quick') {
							disjunctFilters[filterValueFunc.disjunctionIdentifier] = [
								...(disjunctFilters[filterValueFunc.disjunctionIdentifier] || []),
								filterFunc,
							];
						} else {
							quickFilters[filterValueFunc.disjunctionIdentifier] = [
								...(quickFilters[filterValueFunc.disjunctionIdentifier] || []),
								filterFunc,
							];
						}
					}
				}
			});
		});

		const resolvedQuickFilters = values(quickFilters).map(
			(filtersGroup) => (state: State, props: Props | undefined, id: LocalIssueId) =>
				some(filtersGroup, (func: IssueFilterFunction) => func(state, props, id)),
		);

		const resolvedDisjunctFilters = values(disjunctFilters).map(
			(disjunctionGroup) => (state: State, props: Props | undefined, id: LocalIssueId) =>
				some(disjunctionGroup, (func: IssueFilterFunction) => func(state, props, id)),
		);

		return (state: State, props: Props | undefined, id: LocalIssueId) =>
			every(
				[...conjunctFilters, ...resolvedDisjunctFilters, ...resolvedQuickFilters],
				(func: IssueFilterFunction) => func(state, props, id),
			);
	},
);

const getFilterRelevantFieldMappings = createSelector(
	getCurrentFilter,
	getFieldMappingsAsList,
	(filters: Filter[], fieldMappings: FieldMapping<unknown>[]): FieldMapping<unknown>[] => {
		const mappings: Array<FieldMapping<unknown>> = [];
		filters.forEach((filter) => {
			fieldMappings.forEach((mapping) => {
				const filterValueFunc = mapping.getFilter(filter);
				if (filterValueFunc !== undefined) {
					mappings.push(mapping);
				}
			});
		});
		return mappings;
	},
);

const getFilterRelevantProperties = createSpecificallyMemoizedDataSelector(
	getFilterRelevantFieldMappings,
);

export const createIsInFilterResult = (
	fieldKey: FieldKey,
	filter: Filter | undefined,
	id: LocalIssueId,
) =>
	createSelector(
		getFieldMappings,
		(state) => state,
		(state: State, props: Props | undefined) => props,
		(fieldMappings, state, props) => {
			if (filter !== undefined) {
				const fieldMapping: FieldMapping<unknown> | undefined = fieldMappings[fieldKey];
				if (fieldMapping !== undefined) {
					const filterValueFunc = fieldMapping.getFilter(filter);
					if (filterValueFunc !== undefined) {
						if (isFunction(filterValueFunc)) {
							const value = fieldMapping.valueAccessor(state, props, id);
							return filterValueFunc(value, state, props, id);
						}

						if (filterValueFunc.disjunctionIdentifier !== undefined) {
							const value = fieldMapping.valueAccessor(state, props, id);
							return filterValueFunc.filterFunction(value, state, props, id);
						}
					}
				}
			}
			return false;
		},
	);

export const createGetFilterTypeForField = (fieldKey: FieldKey) =>
	createSelector(getAllFieldsByKey, (fieldsByKey) => {
		const field = fieldsByKey[fieldKey];
		if (field !== undefined) {
			switch (field.type) {
				case FIELD_TYPES.SUMMARY:
					return 'TEXT';
				case FIELD_TYPES.INTERVAL:
					return 'INTERVAL';
				case FIELD_TYPES.NUMBER:
				case FIELD_TYPES.SLIDER:
				case FIELD_TYPES.RATING:
				case FIELD_TYPES.FORMULA:
				case FIELD_TYPES.LINKED_ISSUES:
				case FIELD_TYPES.CHECKBOX:
				case FIELD_TYPES.INSIGHTS:
					return 'NUMBER';
				case FIELD_TYPES.ASSIGNEE:
				case FIELD_TYPES.CREATOR:
				case FIELD_TYPES.REPORTER:
				case FIELD_TYPES.PEOPLE:
				case FIELD_TYPES.JSW_PEOPLE:
				case FIELD_TYPES.SINGLE_SELECT:
				case FIELD_TYPES.MULTI_SELECT:
				case FIELD_TYPES.JSW_MULTI_SELECT:
				case FIELD_TYPES.LABELS:
				case FIELD_TYPES.CUSTOM_LABELS:
				case FIELD_TYPES.STATUS:
				case FIELD_TYPES.ATLAS_GOAL:
				case FIELD_TYPES.ATLAS_PROJECT:
				case FIELD_TYPES.REACTIONS:
					return 'FIELD';
				default:
					return undefined;
			}
		}
		return undefined;
	});

const filterOutArchivedEdge = (
	issueIds: LocalIssueId[],
	basicIssuesMap: Record<LocalIssueId, BasicIssue>,
	containsArchived: boolean,
) => {
	const newIssueIds = issueIds.filter(
		(issueId) =>
			(containsArchived && basicIssuesMap[issueId].isArchived) ||
			(!containsArchived && !basicIssuesMap[issueId].isArchived),
	);

	if (!containsArchived && ff('polaris.split-archived-issue-loading')) {
		return newIssueIds.slice(
			0,
			getFeatureFlagValue('polaris.issues-loading-page-limit', ISSUES_PAGE_LIMIT),
		);
	}

	return newIssueIds;
};

export const getIssueIdsConsideringArchived = createSelector(
	getRankedIssueIds,
	getIssuesWithBasicPropertiesMap,
	(_, props) => props?.containsArchived || false,
	filterOutArchivedEdge,
);

export const getFilteredIssueIds = createSelector(
	getIssueIdsConsideringArchived,
	getFilterRelevantProperties,
	getFilterFunction,
	(issueIds: LocalIssueId[], [state, props], filter: IssueFilterFunction) =>
		issueIds.filter((id) => filter(state, props, id)),
);

export const getFilteredIssueCount = createSelector(
	getFilteredIssueIds,
	getIssueIdsInCreation,
	(issueIds: LocalIssueId[], issueIdsInCreation: LocalIssueId[]) =>
		issueIds.filter((id) => !issueIdsInCreation.includes(id)).length,
);
