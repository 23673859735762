import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { VIEW_ACCESS_LEVELS } from '@atlassian/jira-polaris-domain-view/src/view-access/constants.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import {
	isAccessForbiddenError,
	isNotFoundError,
} from '@atlassian/jira-polaris-lib-errors/src/controllers/utils';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Action } from '@atlassian/react-sweet-state';
import { fetchViewPermissionsConfig } from '../../../services/jpd-views-service';
import type { State } from '../../types';

let controller: AbortController | undefined;

export const loadViewPermissionsConfig =
	({
		viewUUID,
		viewId,
		createAnalyticsEvent,
	}: {
		viewUUID: string;
		viewId?: string;
		createAnalyticsEvent: CreateUIAnalyticsEvent;
	}): Action<State> =>
	async ({ setState, getState }) => {
		setState({
			config: {
				...getState().config,
				error: undefined,
			},
		});

		experience.viewPermissions.loadViewAccess.start();

		try {
			// cancel previous ongoing requests if any
			controller?.abort();
			controller = new AbortController();
			const viewAccess = await fetchViewPermissionsConfig(viewUUID, controller.signal);

			setState({
				...viewAccess,
				config: {
					...getState().config,
					error: undefined,
				},
			});

			// https://data-portal.internal.atlassian.com/analytics/registry/69637
			fireTrackAnalytics(createAnalyticsEvent({}), 'viewAccessData loaded', {
				viewUuid: viewUUID,
				viewId,
				accessLevel: viewAccess.accessLevel,
				userCount: viewAccess.additionalAccess.profiles.length,
				groupCount: viewAccess.additionalAccess.groups.length,
				hidden:
					viewAccess.accessLevel === VIEW_ACCESS_LEVELS.RESTRICTED &&
					viewAccess.additionalAccess.profiles.length === 1 &&
					viewAccess.additionalAccess.groups.length === 0,
			});

			sendPendoTrackEvent({
				actionSubjectAndAction: 'viewAccessData loaded',
				actionSubjectId: 'viewAccessData',
				attributes: {
					viewUuid: viewUUID,
					viewId: viewId || '',
					accessLevel: viewAccess.accessLevel,
					userCount: viewAccess.additionalAccess.profiles.length,
					groupCount: viewAccess.additionalAccess.groups.length,
					hidden:
						viewAccess.accessLevel === VIEW_ACCESS_LEVELS.RESTRICTED &&
						viewAccess.additionalAccess.profiles.length === 1 &&
						viewAccess.additionalAccess.groups.length === 0,
				},
			});

			experience.viewPermissions.loadViewAccess.success();

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (error instanceof DOMException && error.name === 'AbortError') {
				experience.viewPermissions.loadViewAccess.abort(error);
				return;
			}

			if (isClientFetchError(error) || isAccessForbiddenError(error) || isNotFoundError(error)) {
				experience.viewPermissions.loadViewAccess.abort(error);
			} else {
				experience.viewPermissions.loadViewAccess.failure(error);
			}

			fireErrorAnalytics(createErrorAnalytics('polaris.view-access.load-view-access', error));

			setState({
				config: {
					...getState().config,
					error,
				},
			});
		}
	};
