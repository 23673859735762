import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from 'styled-components';
import { Flex, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
// eslint-disable-next-line import/order
import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import Timestamp from '@atlassian/jira-issue-timestamp/src/main.tsx';

type Props = {
	date: string;
	text: ReactNode;
	testId?: string;
};

const MetaDate = (props: Props) => {
	const { text, date, testId } = props;
	if (date === undefined || date === null) {
		return null;
	}
	return (
		<Flex testId={testId} xcss={containerStyles}>
			<TextSpan>
				{text}
				&nbsp;
				{/* @ts-expect-error - TS2322 - Type '{ readonly whenDisplaySettingSwitchable: InterpolationValue[]; }' is not assignable to type 'TimestampStyles'. */}
				<Timestamp value={date} extraStyles={TimestampStyles} tooltipPosition="right" />
			</TextSpan>
		</Flex>
	);
};

export default MetaDate;

const containerStyles = xcss({
	marginTop: 'space.050',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const TextSpan = styled.small({
	color: token('color.text.subtle', '#44546F'),
	paddingTop: token('space.025', '2px'),
	whiteSpace: 'nowrap',
	marginTop: 0,
	font: token('font.body.UNSAFE_small'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	lineHeight: gridSize / 6,
});

TextSpan.displayName = 'TextSpan';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TimestampStyles = {
	whenDisplaySettingSwitchable: css({
		'&:hover': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values  -- Ignored via go/DSP-18766
			color: token('color.text.subtle', colors.N500),
		},
	}),
} as const;
