import type { Ari } from '@atlassian/jira-platform-ari';
import type { Locale } from '@atlassian/jira-platform-utils-date-fns/src/types';
import type { PanelDataTypes } from '../../../model/types';
import type { AppState } from '../../reducers/app/types';
import type { State } from '../../reducers/types';

const getApp = (state: State): AppState => state.app;

export const getBaseUrl = (state: State): string => getApp(state).baseUrl;
export const getIssueId = (state: State): string => getApp(state).issueId;
export const getIssueKey = (state: State): string => getApp(state).issueKey;
export const getLocale = (state: State): Locale => getApp(state).locale;
export const getSelectedTab = (state: State): PanelDataTypes => getApp(state).selectedTab;
export const getProjectAri = (state: State): Ari => getApp(state).projectAri;
export const getAccountId = (state: State): string => getApp(state).accountId;
