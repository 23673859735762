import React, { type FC, type PropsWithChildren, useEffect } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { HighlightActionsErrorBoundary } from '../common/ui/error-boundary';
import {
	HighlightActionsContainer,
	useIsHighlightEnabled,
	// useTriggerPosition,
} from '../controllers/use-highlight-actions';
import { TargetContainer } from './popup-container';
import { asyncTargetEntryPoint } from './popup-target/entrypoint';

type Props = {
	scope: string;
};

const EMPTY_OBJECT = {};
export const PopupTarget: FC = () => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		asyncTargetEntryPoint,
		EMPTY_OBJECT,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			id="jira.highlight.actions.popup.target"
			packageName="jiraHighlightActions"
			entryPointReferenceSubject={entryPointReferenceSubject}
			fallback={<></>}
			runtimeProps={EMPTY_OBJECT}
		/>
	);
};

export const HighlightActionsTarget: FC = () => {
	const { highlightEnabled } = useIsHighlightEnabled();
	// FIXME: eager loading of PopupTarget may reduce TTI with feature never being used
	// suppress loading based on performed action.
	// this should be based on "begining" of the action, not the end, so the code will be mostly ready when needed
	// const triggerPosition = Boolean(useTriggerPosition());

	return (
		<HighlightActionsErrorBoundary id="highlight-actions-popup-target-async">
			{highlightEnabled /* && triggerPosition */ ? <PopupTarget /> : null}
		</HighlightActionsErrorBoundary>
	);
};

export const HighlightActionsProvider: FC<PropsWithChildren<Props>> = ({ scope, children }) => (
	<HighlightActionsContainer scope={scope}>
		{children}
		<TargetContainer scope={scope}>
			<HighlightActionsTarget />
		</TargetContainer>
	</HighlightActionsContainer>
);
