import {
	type ProjectType,
	CORE_PROJECT,
	SOFTWARE_PROJECT,
	SERVICE_DESK_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	useProjectType,
	useProjectKey,
} from '@atlassian/jira-project-context-service/src/main.tsx';

export const AiMateXProduct = {
	JSM: 'jsm',
	JSW: 'jira-software',
	JWM: 'jira-core',
	UNKNOWN: 'unknown',
} as const;

export type AiMateXProductType = (typeof AiMateXProduct)[keyof typeof AiMateXProduct];

export const getAiMateXProduct = (product: ProjectType | undefined): AiMateXProductType => {
	switch (product) {
		case SERVICE_DESK_PROJECT:
			return AiMateXProduct.JSM;
		case CORE_PROJECT:
			return AiMateXProduct.JWM;
		case SOFTWARE_PROJECT:
			return AiMateXProduct.JSW;
		default:
			return AiMateXProduct.UNKNOWN;
	}
};

export const useAiMateProductType = () => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const productType = useProjectType(projectKey);

	return getAiMateXProduct(productType);
};
