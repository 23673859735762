/**
 * @generated SignedSource<<b1a85a85e16b0c4f7960a505353b62f0>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type view_issueViewFoundation_StatusView$data = {
  readonly resolutionField?: {
    readonly fieldConfig: {
      readonly isEditable: boolean | null | undefined;
    } | null | undefined;
    readonly resolution: {
      readonly __typename: "JiraResolution";
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutResolutionField_IssueViewResolutionField">;
  } | null | undefined;
  readonly statusField?: {
    readonly status: {
      readonly statusCategory: {
        readonly statusCategoryId: string;
      } | null | undefined;
    };
  } | null | undefined;
  readonly " $fragmentType": "view_issueViewFoundation_StatusView";
};
export type view_issueViewFoundation_StatusView$key = {
  readonly " $data"?: view_issueViewFoundation_StatusView$data;
  readonly " $fragmentSpreads": FragmentRefs<"view_issueViewFoundation_StatusView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsresolutionrelayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "view_issueViewFoundation_StatusView",
  "selections": [
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsresolutionrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "concreteType": "JiraResolutionField",
          "kind": "LinkedField",
          "name": "resolutionField",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "ui_issueViewLayoutResolutionField_IssueViewResolutionField"
            },
            {
              "concreteType": "JiraFieldConfig",
              "kind": "LinkedField",
              "name": "fieldConfig",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "isEditable"
                }
              ]
            },
            {
              "concreteType": "JiraResolution",
              "kind": "LinkedField",
              "name": "resolution",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "__typename"
                }
              ]
            }
          ]
        },
        {
          "concreteType": "JiraStatusField",
          "kind": "LinkedField",
          "name": "statusField",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraStatus",
              "kind": "LinkedField",
              "name": "status",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraStatusCategory",
                  "kind": "LinkedField",
                  "name": "statusCategory",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "statusCategoryId"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "96f48038d74acafdb65ec82f6167d876";

export default node;
