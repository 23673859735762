import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { isHttpClientErrorResponse } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { REMOVE_WATCHER_ERROR } from '@atlassian/jira-issue-view-common-constants/src/flags.tsx';
import type { OnShowFlag, State } from '../../model/types';
import { unwatch } from '../../rest/update-user-watching-state';
import {
	type Action,
	REMOVE_WATCHER_REQUEST,
	removeWatcherSuccess,
	removeWatcherFailure,
} from '../../state/actions';
import { getIssueKey, getBaseUrl } from '../../state/selectors';

export const createEpic =
	(onShowFlag: OnShowFlag) => (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
		action$.ofType(REMOVE_WATCHER_REQUEST).mergeMap((action) => {
			const state = store.getState();

			const issueKey = getIssueKey(state);
			const baseUrl = getBaseUrl(state);

			const {
				user: { id: accountId },
				onSuccess,
				onError,
			} = action.payload;

			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return unwatch({ baseUrl, issueKey, accountId })
				.map(() => {
					onSuccess?.();
					return removeWatcherSuccess(action.payload);
				})
				.catch((error: Error | FetchError) => {
					onShowFlag(REMOVE_WATCHER_ERROR);
					log.safeErrorWithoutCustomerData('issue.watchers.delete.watcher', error.message, error);
					if (!(error instanceof FetchError && isHttpClientErrorResponse(error))) {
						onError?.();
					}
					return Observable.of(removeWatcherFailure(action.payload));
				}) as Observable<Action>;
		});
