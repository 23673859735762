import { defineMessages } from '@atlassian/jira-intl';

// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
export default defineMessages({
	heading: {
		id: 'issue-view-layout-templates-views.context.visible-hidden.context-group.change-boarding.heading',
		defaultMessage: 'Show or hide apps',
		description:
			'Spotlight heading that a user can understand that apps will not be in glances anymore',
	},
	body: {
		id: 'issue-view-layout-templates-views.context.visible-hidden.context-group.change-boarding.body',
		defaultMessage:
			'Use your apps without hiding other fields. Some apps may not be in groups yet.',
		description:
			'Spotlight message body that a user can understand that apps will not be in glances anymore',
	},
	showMe: {
		id: 'issue-view-layout-templates-views.context.visible-hidden.context-group.change-boarding.show-me',
		defaultMessage: 'Show me',
		description: 'Show user how to show or hide an app',
	},
	close: {
		id: 'issue-view-layout-templates-views.context.visible-hidden.context-group.change-boarding.close',
		defaultMessage: 'Close',
		description: 'Close the spotlight message',
	},
});
