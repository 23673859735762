import React, { useCallback } from 'react';
import { ButtonItem } from '@atlaskit/menu';
import { ff } from '@atlassian/jira-feature-flagging';
import { useOptionallyControlledEditingState } from '@atlassian/jira-issue-field-optional-editing-state-manager';
import { DATA_CLASSIFICATION_TYPE } from '@atlassian/jira-platform-field-config';
import type { Props } from './types';

export const ClassifyIssueButtonItem = ({
	onClick,
	label,
	setDropdownItemRef,
	itemKey,
	isSelected = false,
}: Props) => {
	/**
	 * Todo: Replace the constant `DATA_CLASSIFICATION_EDITING_STATE_KEY` with the destructured value from relay response
	 */
	const [, setIsIssueClassified] = useOptionallyControlledEditingState(
		false,
		DATA_CLASSIFICATION_TYPE,
	);
	const handleClick = useCallback(() => {
		if (ff('issue-view-classification-level-editability_88zal')) {
			setIsIssueClassified(true);
		}
		onClick?.(itemKey);
	}, [setIsIssueClassified, onClick, itemKey]);

	return (
		<ButtonItem
			key="classifyIssue"
			isSelected={isSelected}
			ref={setDropdownItemRef}
			role="menuitem"
			onClick={handleClick}
		>
			{label}
		</ButtonItem>
	);
};
