import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { UserValue } from '../../common/types';

export const saveAssigneeField = async (
	issueKey: IssueKey,
	fieldKey: string,
	newValue: UserValue,
	_baseUrl: string,
) => {
	const userValue: UserValue = newValue;

	return performPutRequest(`/rest/api/3/issue/${issueKey}/assignee`, {
		// the accountId field set to null will unassign the user from the issue as a fallback
		body: JSON.stringify({ accountId: userValue?.accountId || null }),
	});
};
