/**
 * @generated SignedSource<<e3d46b2dc8532e757b8bef6a790811be>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutFieldHeading_IssueViewFieldHeading$data = {
  readonly description: string | null | undefined;
  readonly fieldId: string;
  readonly name: string;
  readonly " $fragmentType": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading";
};
export type ui_issueViewLayoutFieldHeading_IssueViewFieldHeading$key = {
  readonly " $data"?: ui_issueViewLayoutFieldHeading_IssueViewFieldHeading$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutFieldHeading_IssueViewFieldHeading">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "description"
    }
  ],
  "type": "JiraIssueField",
  "abstractKey": "__isJiraIssueField"
};

(node as any).hash = "acd038434eb522bc268efa7ab13a2bef";

export default node;
