import { createSelector } from 'reselect';
import type { Tabs } from '@atlassian/jira-issue-shared-types/src/common/types/layout-item-type.tsx';
import type { GraphQlIssueState } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { entitiesIssueSelector } from '../common/state/selectors/issue-selector';

export const tabsSelector = createSelector(
	entitiesIssueSelector,
	(issue: GraphQlIssueState | null) => (issue && issue.tabs) || {},
);

export const isFieldInTabSelector = (fieldId: string) =>
	createSelector(tabsSelector, (tabs: Tabs) =>
		Boolean(
			Object.keys(tabs).find((tabId) => tabs[tabId].items.find((item) => item.id === fieldId)),
		),
	);
