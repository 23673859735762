import React, { Component, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import debounce from 'lodash/debounce';
import { AsyncSelect, components } from '@atlaskit/select';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { defaultSelectStyles } from '@atlassian/jira-issue-field-select-base/src/ui/react-select-styles/styled.tsx';
import type { ParentFieldType } from '../types';
import messages from './messages';

const MenuList = (props: { children: ReactNode }) => {
	const intl = useIntl();
	return (
		// @ts-expect-error - TS2740 - Type '{ children: (Element | ReactNode)[]; }' is missing the following properties from type 'CommonProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>': clearValue, cx, getStyles, getValue, and 8 more.
		<components.MenuList {...props}>
			<Container>{intl.formatMessage(messages.menuHeader)}</Container>
			{props.children}
		</components.MenuList>
	);
};

const Option = (props: { data: ParentFieldType }) => {
	const optionValue = props.data.data;
	if (optionValue) {
		const { issueType, summary, key } = optionValue;
		const { iconUrl } = issueType;

		return (
			// @ts-expect-error - TS2740 - Type '{ children: Element; data: ParentFieldType; }' is missing the following properties from type 'CommonProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>': clearValue, cx, getStyles, getValue, and 8 more.
			<components.Option {...props}>
				<OptionWrapper>
					<Icon src={iconUrl} />{' '}
					<OptionText>
						{key} {summary}
					</OptionText>
				</OptionWrapper>
			</components.Option>
		);
	}
	return null;
};

const SingleValue = (props: { data: ParentFieldType }) => {
	const intl = useIntl();
	const { data } = props.data;
	const label = data ? (
		data.label
	) : (
		<Placeholder>{intl.formatMessage(messages.placeholder)}</Placeholder>
	);

	// @ts-expect-error - TS2740 - Type '{ children: string | Element; data: ParentFieldType; }' is missing the following properties from type 'CommonProps<ParentFieldType, false, GroupTypeBase<ParentFieldType>>': clearValue, cx, getStyles, getValue, and 8 more.
	return <components.SingleValue {...props}>{label}</components.SingleValue>;
};

export type Props = {
	value: ParentFieldType;
	// eslint-disable-next-line jira/react/handler-naming
	loadOptions: (inputValue: string) => Promise<ParentFieldType[]>;
	portalElement?: HTMLElement;
	menuPlacement?: string;
	onChange: (value: ParentFieldType | null) => void;
	onConfirm: (value: ParentFieldType | null) => void;
	intl: IntlShape;
};

// eslint-disable-next-line jira/react/no-class-components
export default class EditableParentLinkField extends Component<Props> {
	onChange = (value?: ParentFieldType) => {
		const { hasEpicLinkFieldDependency, showField } = this.props.value;

		const selectedData = value != null ? value.data : null;

		const selectedValue: ParentFieldType = {
			data: selectedData,
			value: selectedData ? selectedData.key : null,
			...{ hasEpicLinkFieldDependency },
			...{ showField },
		};
		this.props.onChange(selectedValue);
		this.props.onConfirm(selectedValue);
	};

	debouncedLoadOptions = debounce(
		(searchText: string, callback: (arg1: ParentFieldType[]) => void) => {
			this.props.loadOptions(searchText).then(callback, () => {
				callback([]);
			});
		},
		300,
	);

	render() {
		const {
			value,
			portalElement,
			menuPlacement,
			intl: { formatMessage },
		} = this.props;

		return (
			<AsyncSelect
				value={value}
				autoFocus
				defaultOptions
				components={{ MenuList, Option, SingleValue }}
				menuPortalTarget={portalElement}
				// @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'MenuPlacement | undefined'.
				menuPlacement={menuPlacement}
				// @ts-expect-error - TS2322 - Type '(value?: ParentFieldType | undefined) => void' is not assignable to type '(value: ParentFieldType | null, action: ActionMeta<ParentFieldType>) => void'.
				onChange={this.onChange}
				loadOptions={this.debouncedLoadOptions}
				defaultMenuIsOpen
				isClearable={value.data !== null}
				noOptionsMessage={() => formatMessage(messages.noMatches)}
				styles={defaultSelectStyles}
				cacheOptions
			/>
		);
	}
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Icon = styled.img({
	marginRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionWrapper = styled.div({
	display: 'flex',
	flexDirection: 'row',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionText = styled.div({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	padding: `${token('space.100', '8px')} ${token('space.200', '16px')} ${token(
		'space.200',
		'16px',
	)}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Placeholder = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N90),
});
