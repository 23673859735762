import { useLocalAssociatedIssuesContext } from '@atlassian/jira-associated-issues-context-service/src/context.tsx';
import { statusCategoryForId } from '@atlassian/jira-common-constants/src/status-categories';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	useFieldValue,
	useFieldValuesForIssues,
	useMultiFieldValuesForIssues,
} from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { ServerChildIssue } from '@atlassian/jira-issue-shared-types/src/common/types/children-issues-type.tsx';
import type { ChildIssue } from '@atlassian/jira-issue-view-common-types/src/children-issues-type';
import {
	ASSIGNEE,
	CHILDREN_ISSUES,
	CREATED,
	EPIC_ISSUES,
	ISSUE_TYPE,
	PRIORITY,
	STATUS,
	SUMMARY,
	TIME_TRACKING,
	UPDATED,
} from '@atlassian/jira-issue-view-configurations';

type ChildIssueAccumulatorType = {
	allChildrenIssuesRefs: { key: string; id: string }[];
	allChildrenIssuesKeys: string[];
	estimateFieldId: string;
};

const isEpicOrTMPChildIssues = (childrenIssuesFieldKey: string) =>
	childrenIssuesFieldKey === EPIC_ISSUES || childrenIssuesFieldKey === CHILDREN_ISSUES;

export const useChildrenIssuesWithDataOld = (childrenIssuesFieldKey: string): ChildIssue[] => {
	const issueKey = useIssueKey();

	const [childrenIssues] = useFieldValue({
		issueKey,
		fieldKey: childrenIssuesFieldKey,
	});
	// @ts-expect-error - TS7006 - Parameter 'issue' implicitly has an 'any' type.
	const childrenIssuesRefs = (childrenIssues || []).map((issue) => ({
		key: issue.key,
		id: issue.id,
	}));
	// @ts-expect-error - TS7006 - Parameter 'data' implicitly has an 'any' type.
	const allChildrenIssuesKeys = childrenIssuesRefs.map((data) => data.key);

	const [allSummaryData] = useFieldValuesForIssues({
		issueKeys: allChildrenIssuesKeys,
		fieldKey: SUMMARY,
	});
	const [allTimeTrackingData] = useFieldValuesForIssues({
		issueKeys: allChildrenIssuesKeys,
		fieldKey: TIME_TRACKING,
	});
	const [allAssigneeData] = useFieldValuesForIssues({
		issueKeys: allChildrenIssuesKeys,
		fieldKey: ASSIGNEE,
	});
	const [allPriorityData] = useFieldValuesForIssues({
		issueKeys: allChildrenIssuesKeys,
		fieldKey: PRIORITY,
	});
	const [allIssueTypeData] = useFieldValuesForIssues({
		issueKeys: allChildrenIssuesKeys,
		fieldKey: ISSUE_TYPE,
	});
	const [allStatusData] = useFieldValuesForIssues({
		issueKeys: allChildrenIssuesKeys,
		fieldKey: STATUS,
	});
	const [allCreatedTimeData] = useFieldValuesForIssues({
		issueKeys: allChildrenIssuesKeys,
		fieldKey: CREATED,
	});
	const [allUpdatedTimeData] = useFieldValuesForIssues({
		issueKeys: allChildrenIssuesKeys,
		fieldKey: UPDATED,
	});

	// @ts-expect-error - TS7006 - Parameter 'data' implicitly has an 'any' type. | TS7006 - Parameter 'idx' implicitly has an 'any' type.
	return childrenIssuesRefs.map((data, idx) => ({
		assigneeDisplayName: allAssigneeData[idx]?.displayName,
		assigneeUrl: allAssigneeData[idx]?.avatarUrls?.['48x48'],
		created: allCreatedTimeData[idx] && new Date(allCreatedTimeData[idx]),
		id: data.id,
		isLoading: false,
		issueKey: data.key,
		issueLink: `/browse/${data.key}`,
		issuePriorityName: allPriorityData[idx]?.name,
		issuePriorityUrl: allPriorityData[idx]?.iconUrl,
		issueSummary: allSummaryData[idx],
		issueTimeTracking: allTimeTrackingData[idx],
		issueTypeIconUrl: allIssueTypeData[idx]?.iconUrl,
		issueTypeName: allIssueTypeData[idx]?.name,
		statusCategory: statusCategoryForId(allStatusData[idx]?.statusCategory?.id),
		statusCategoryId: allStatusData[idx]?.statusCategory?.id,
		statusId: allStatusData[idx]?.id,
		statusName: allStatusData[idx]?.name,
		updated: allUpdatedTimeData[idx] && new Date(allUpdatedTimeData[idx]),
	}));
};

export const useChildrenIssuesWithData = (childrenIssuesFieldKey: string): ChildIssue[] => {
	const issueKey = useIssueKey();
	const [associatedIssuesContextMap] = useLocalAssociatedIssuesContext();

	const [childrenIssues] = useFieldValue({
		issueKey,
		fieldKey: childrenIssuesFieldKey,
	});

	const { allChildrenIssuesRefs, allChildrenIssuesKeys, estimateFieldId } = (
		childrenIssues || []
	).reduce(
		(accumulator: ChildIssueAccumulatorType, issue: ServerChildIssue) => {
			if (
				accumulator.estimateFieldId === '' &&
				associatedIssuesContextMap[issue.key]?.estimateFieldId
			) {
				accumulator.estimateFieldId = associatedIssuesContextMap[issue.key].estimateFieldId || '';
			}

			accumulator.allChildrenIssuesRefs.push({ key: issue.key, id: issue.id });
			accumulator.allChildrenIssuesKeys.push(issue.key);

			return accumulator;
		},
		{ allChildrenIssuesRefs: [], allChildrenIssuesKeys: [], estimateFieldId: '' },
	);

	const [fieldValues] = useMultiFieldValuesForIssues({
		issueKeys: allChildrenIssuesKeys,
		fieldKeys: [
			SUMMARY,
			TIME_TRACKING,
			PRIORITY,
			ISSUE_TYPE,
			STATUS,
			CREATED,
			UPDATED,
			ASSIGNEE,
			estimateFieldId,
		],
	});

	return allChildrenIssuesRefs.flatMap(
		({ key: childIssueKey, id }: { key: string; id: string }) => {
			const childIssueFieldValue = fieldValues[childIssueKey];

			if (childIssueFieldValue == null || childIssueFieldValue[SUMMARY] == null) {
				return [];
			}

			return {
				assigneeDisplayName: childIssueFieldValue[ASSIGNEE]?.displayName,
				assigneeUrl: childIssueFieldValue[ASSIGNEE]?.avatarUrls?.['48x48'],
				created: childIssueFieldValue[CREATED] && new Date(childIssueFieldValue[CREATED]),
				id,
				isLoading: false,
				issueKey: childIssueKey,
				issueLink: `/browse/${childIssueKey}`,
				issuePriorityName: childIssueFieldValue[PRIORITY]?.name,
				issuePriorityUrl: childIssueFieldValue[PRIORITY]?.iconUrl,
				issuePrioritySequence: childIssueFieldValue[PRIORITY]?.sequence,
				issueSummary: childIssueFieldValue[SUMMARY],
				issueTimeTracking: childIssueFieldValue[TIME_TRACKING],
				issueTypeIconUrl: childIssueFieldValue[ISSUE_TYPE]?.iconUrl,
				issueTypeName: childIssueFieldValue[ISSUE_TYPE]?.name,
				statusCategory: statusCategoryForId(childIssueFieldValue[STATUS]?.statusCategory?.id),
				statusCategoryId: childIssueFieldValue[STATUS]?.statusCategory?.id,
				statusId: childIssueFieldValue[STATUS]?.id,
				statusName: childIssueFieldValue[STATUS]?.name,
				updated: childIssueFieldValue[UPDATED] && new Date(childIssueFieldValue[UPDATED]),
				isResolved: associatedIssuesContextMap[childIssueKey]?.isResolved,
				hasError: associatedIssuesContextMap[childIssueKey]?.hasError,
				errorType: associatedIssuesContextMap[childIssueKey]?.errorType,
				...(isEpicOrTMPChildIssues(childrenIssuesFieldKey)
					? {
							estimateFieldId: associatedIssuesContextMap[childIssueKey]?.estimateFieldId,
							estimateValue: childIssueFieldValue[estimateFieldId],
						}
					: {}),
			};
		},
	);
};
