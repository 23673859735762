import head from 'lodash/head';
import indexOf from 'lodash/indexOf';
import { fg } from '@atlassian/jira-feature-gating';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type {
	FieldValueFilter,
	NumericFieldFilter,
	Filter,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { filterHasFieldProperty } from '@atlassian/jira-polaris-domain-view/src/filter/utils.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import {
	createGetFieldFilter,
	createGetNumericFilter,
	getQuickSearchFilter,
	getCurrentViewPermanentFilter,
	getCurrentViewTemporaryFilter,
} from '../../selectors/filters';
import { isCurrentViewAutosaveEnabled } from '../../selectors/view/autosave/index.tsx';
import type { State, Props } from '../../types';
import { saveViewWithAutoSave } from '../save';
import { fireViewUpdatedEvent } from '../utils/analytics';
import { updateViewState } from '../utils/state';
import { currentViewFilter } from '../utils/views';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { updateIntervalFilter } from './interval';

export const clearAllFilters =
	(onSuccess?: () => void, onError?: (error: Error) => void): Action<State, Props> =>
	async ({ getState, setState, dispatch }, props) => {
		const state = getState();
		const { currentViewSlug, createAnalyticsEvent } = props;
		const viewMutation = (view: View): View => {
			const isAutosaveEnabled = isCurrentViewAutosaveEnabled(state, props);

			return {
				...view,
				filter: isAutosaveEnabled ? [] : view.filter,
				modified: isAutosaveEnabled,
				draft: {
					...view.draft,
					filter: [],
				},
			};
		};

		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			viewMutation,
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(
				saveViewWithAutoSave(changedView.id, (view: View | undefined) => {
					if (view && view.saveError) {
						onError?.(view.saveError);
					} else if (view && !view.saveError) {
						onSuccess?.();
					}
				}),
			);

			fireViewUpdatedEvent(createAnalyticsEvent, changedView, {
				updatedItems: [{ name: 'filter' }],
			});
		}
	};

export const clearAllTemporaryFilters =
	(): Action<State, Props> =>
	async ({ getState, setState }, props) => {
		const { currentViewSlug } = props;

		const viewMutation = (view: View): View => ({
			...view,
			temporaryData: {
				...view.temporaryData,
				filter: [],
			},
		});

		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			viewMutation,
		);

		if (changedView) {
			setState({ viewSets });
		}
	};

export const updateQuickSearchFilter =
	(
		values: {
			stringValue: string | undefined;
		}[],
	): Action<State, Props> =>
	async ({ getState, setState }, props) => {
		const state = getState();
		const isAutosaveEnabled = isCurrentViewAutosaveEnabled(state, props);

		const viewMutation = (view: View): View => {
			const quickSearchFilter = getQuickSearchFilter(state, props);
			const currentFilter = getCurrentViewPermanentFilter(state, props);
			const index = indexOf(currentFilter, quickSearchFilter);

			const newValue = head(values.map(({ stringValue }) => stringValue)) ?? '';
			const currentValue =
				head(quickSearchFilter.values.map(({ stringValue }) => stringValue)) ?? '';

			if (currentValue === newValue) {
				return view;
			}

			if (index === -1) {
				const newFilters = [...currentFilter, { ...quickSearchFilter, values }];
				return {
					...view,
					filter: isAutosaveEnabled ? newFilters : view.filter,
					draft: {
						...view.draft,
						filter: newFilters,
					},
				};
			}
			const newFilters = [...currentFilter];
			if (newValue !== '') {
				newFilters.splice(index, 1, { ...quickSearchFilter, values });
			} else {
				newFilters.splice(index, 1);
			}

			return {
				...view,
				filter: isAutosaveEnabled ? newFilters : view.filter,
				draft: {
					...view.draft,
					filter: newFilters,
				},
			};
		};

		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(props.currentViewSlug),
			viewMutation,
		);

		if (changedView && viewSets !== getState().viewSets) {
			setState({ viewSets });
		}
	};

export const updateFieldFilter =
	(
		filter: FieldValueFilter,
		onSuccess?: () => void,
		onError?: (error: Error) => void,
	): Action<State, Props> =>
	async ({ getState, setState, dispatch }, props) => {
		const state = getState();
		const isAutosaveEnabled = isCurrentViewAutosaveEnabled(state, props);

		const getFieldFilter = createGetFieldFilter(filter.field, { isTemporary: filter.isTemporary });
		const fieldFilter = getFieldFilter(state, props);
		const currentPermanentFilter = getCurrentViewPermanentFilter(state, props);
		const currentTemporaryFilter = getCurrentViewTemporaryFilter(state, props);
		const currentFilter = filter.isTemporary ? currentTemporaryFilter : currentPermanentFilter;

		const newFilters =
			fieldFilter === undefined
				? [...currentFilter, filter]
				: currentFilter.map((f) => (f.type === 'FIELD' && f.field === filter.field ? filter : f));

		const viewMutation = (view: View): View => {
			if (filter.isTemporary) {
				return {
					...view,
					temporaryData: {
						...view.temporaryData,
						filter: newFilters,
					},
				};
			}

			return {
				...view,
				filter: isAutosaveEnabled ? newFilters : view.filter,
				modified: isAutosaveEnabled,
				draft: {
					...view.draft,
					filter: newFilters,
				},
				...(fg('polaris_just-for-you') && {
					temporaryData: {
						...view.temporaryData,
						filter: currentTemporaryFilter.filter(
							(f) => filterHasFieldProperty(f) && f.field !== filter.field,
						),
					},
				}),
			};
		};

		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(props.currentViewSlug),
			viewMutation,
		);

		if (changedView) {
			setState({ viewSets });

			if (!filter.isTemporary) {
				dispatch(
					saveViewWithAutoSave(changedView.id, (view: View | undefined) => {
						if (view && view.saveError) {
							onError?.(view.saveError);
						} else if (view && !view.saveError) {
							onSuccess?.();
						}
					}),
				);

				fireViewUpdatedEvent(props.createAnalyticsEvent, changedView, {
					updatedItems: [{ name: 'filter' }],
				});
			}
		}
	};

export const clearFieldFilter =
	(
		field: FieldKey,
		isTemporary?: boolean,
		onSuccess?: () => void,
		onError?: (error: Error) => void,
	): Action<State, Props> =>
	async ({ getState, setState, dispatch }, props) => {
		const state = getState();
		const isAutosaveEnabled = isCurrentViewAutosaveEnabled(state, props);

		const getFieldFilter = createGetFieldFilter(field, { isTemporary });
		const fieldFilter = getFieldFilter(state, props);

		if (fieldFilter === undefined) {
			return;
		}

		const currentFilter = isTemporary
			? getCurrentViewTemporaryFilter(state, props)
			: getCurrentViewPermanentFilter(state, props);
		const newFilters = currentFilter.filter((f) => f.type !== 'FIELD' || f.field !== field);

		const viewMutation = (view: View): View => {
			if (isTemporary) {
				return {
					...view,
					temporaryData: {
						...view.temporaryData,
						filter: newFilters,
					},
				};
			}

			return {
				...view,
				filter: isAutosaveEnabled ? newFilters : view.filter,
				modified: isAutosaveEnabled,
				draft: {
					...view.draft,
					filter: newFilters,
				},
			};
		};

		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(props.currentViewSlug),
			viewMutation,
		);

		if (changedView) {
			setState({ viewSets });

			if (!isTemporary) {
				dispatch(
					saveViewWithAutoSave(changedView.id, (view: View | undefined) => {
						if (view && view.saveError) {
							onError?.(view.saveError);
						} else if (view && !view.saveError) {
							onSuccess?.();
						}
					}),
				);

				fireViewUpdatedEvent(props.createAnalyticsEvent, changedView, {
					updatedItems: [{ name: 'filter' }],
				});
			}
		}
	};

export const clearFieldOrNumericFilter =
	(
		field: FieldKey,
		isTemporary?: boolean,
		onSuccess?: () => void,
		onError?: (error: Error) => void,
	): Action<State, Props> =>
	async ({ getState, setState, dispatch }, props) => {
		const state = getState();
		const isAutosaveEnabled = isCurrentViewAutosaveEnabled(state, props);

		const currentFilter = isTemporary
			? getCurrentViewTemporaryFilter(state, props)
			: getCurrentViewPermanentFilter(state, props);
		const newFilters = currentFilter.filter(
			(f) =>
				(f.type !== 'FIELD' && f.type !== 'NUMBER' && f.type !== 'INTERVAL') || f.field !== field,
		);

		const viewMutation = (view: View): View => {
			if (isTemporary) {
				return {
					...view,
					temporaryData: {
						...view.temporaryData,
						filter: newFilters,
					},
				};
			}

			return {
				...view,
				filter: isAutosaveEnabled ? newFilters : view.filter,
				modified: isAutosaveEnabled,
				draft: {
					...view.draft,
					filter: newFilters,
				},
			};
		};

		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(props.currentViewSlug),
			viewMutation,
		);

		if (changedView) {
			setState({ viewSets });

			if (!isTemporary) {
				dispatch(
					saveViewWithAutoSave(changedView.id, (view: View | undefined) => {
						if (view && view.saveError) {
							onError?.(view.saveError);
						} else if (view && !view.saveError) {
							onSuccess?.();
						}
					}),
				);

				fireViewUpdatedEvent(props.createAnalyticsEvent, changedView, {
					updatedItems: [{ name: 'filter' }],
				});
			}
		}
	};

export const updateNumericFilter =
	(
		filter: NumericFieldFilter,
		onSuccess?: () => void,
		onError?: (error: Error) => void,
	): Action<State, Props> =>
	async ({ getState, setState, dispatch }, props) => {
		const state = getState();
		const isAutosaveEnabled = isCurrentViewAutosaveEnabled(state, props);

		const getNumericFilter = createGetNumericFilter(filter.field, {
			isTemporary: filter.isTemporary,
		});
		const numericFilter = getNumericFilter(state, props);
		const currentPermanentFilter = getCurrentViewPermanentFilter(state, props);
		const currentTemporaryFilter = getCurrentViewTemporaryFilter(state, props);
		const currentFilter = filter.isTemporary ? currentTemporaryFilter : currentPermanentFilter;
		const index = indexOf(currentFilter, numericFilter);

		const newFilters = [...currentFilter];
		if (index === -1) {
			newFilters.push(filter);
		} else {
			newFilters.splice(index, 1, filter);
		}

		const viewMutation = (view: View): View => {
			if (filter.isTemporary) {
				return {
					...view,
					temporaryData: {
						...view.temporaryData,
						filter: newFilters,
					},
				};
			}

			return {
				...view,
				filter: isAutosaveEnabled ? newFilters : view.filter,
				modified: isAutosaveEnabled,
				draft: {
					...view.draft,
					filter: newFilters,
				},
				...(fg('polaris_just-for-you') && {
					temporaryData: {
						...view.temporaryData,
						filter: currentTemporaryFilter.filter(
							(f) => filterHasFieldProperty(f) && f.field !== filter.field,
						),
					},
				}),
			};
		};

		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(props.currentViewSlug),
			viewMutation,
		);

		if (changedView) {
			setState({ viewSets });

			if (!filter.isTemporary) {
				dispatch(
					saveViewWithAutoSave(changedView.id, (view: View | undefined) => {
						if (view && view.saveError) {
							onError?.(view.saveError);
						} else if (view && !view.saveError) {
							onSuccess?.();
						}
					}),
				);
			}
		}
	};

export const updateFilters =
	(
		filters: Filter[],
		isTemporary?: boolean,
		onSuccess?: () => void,
		onError?: (error: Error) => void,
	): Action<State, Props> =>
	async ({ getState, setState, dispatch }, props) => {
		const state = getState();
		const isAutosaveEnabled = isCurrentViewAutosaveEnabled(state, props);

		const currentPermanentFilter = getCurrentViewPermanentFilter(state, props);
		const currentTemporaryFilter = getCurrentViewTemporaryFilter(state, props);
		const currentFilter = isTemporary ? currentTemporaryFilter : currentPermanentFilter;
		let newFilters = [...currentFilter];

		if (!filters.some((f) => f.values.length > 0) && fg('polaris_just-for-you')) {
			newFilters = [];
		} else {
			filters.forEach((updatedFilter) => {
				const found = currentFilter.find(
					(f) =>
						(f.type === updatedFilter.type && f.type === 'TEXT') ||
						(f.type !== 'TEXT' && f.type === updatedFilter.type && f.field === updatedFilter.field),
				);
				const index = indexOf(currentFilter, found);

				if (index === -1) {
					newFilters.push(updatedFilter);
				}
				newFilters.splice(index, 1, updatedFilter);
			});
		}

		const viewMutation = (view: View): View => {
			if (isTemporary) {
				return {
					...view,
					temporaryData: {
						...view.temporaryData,
						filter: newFilters,
					},
				};
			}

			return {
				...view,
				filter: isAutosaveEnabled ? newFilters : view.filter,
				modified: isAutosaveEnabled,
				draft: {
					...view.draft,
					filter: newFilters,
				},
				...(fg('polaris_just-for-you') && {
					temporaryData: {
						...view.temporaryData,
						filter: currentTemporaryFilter.filter(
							(f) =>
								filterHasFieldProperty(f) &&
								!filters.some((item) => filterHasFieldProperty(item) && item.field === f.field),
						),
					},
				}),
			};
		};

		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(props.currentViewSlug),
			viewMutation,
		);

		if (changedView) {
			setState({ viewSets });

			if (!isTemporary) {
				dispatch(
					saveViewWithAutoSave(changedView.id, (view: View | undefined) => {
						if (view && view.saveError) {
							onError?.(view.saveError);
						} else if (view && !view.saveError) {
							onSuccess?.();
						}
					}),
				);
			}
		}
	};
