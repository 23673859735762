import type { User as WorklogUser } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/worklogs.tsx';
import type { User } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import type {
	UserOption,
	EmptyOption,
	UserOptionValue,
} from '@atlassian/jira-issue-user-picker/src/types.tsx';
import type { User as ActorUser } from './types';

export type OptionalAccount = {
	accountId?: string;
	value?: string;
	id?: string;
};

export type ExternalUser =
	| WorklogUser
	| User
	| UserOption
	| EmptyOption
	| UserOptionValue
	| ActorUser
	| undefined
	| null;

export const mapExternalUserToAccountId = (userAccount: OptionalAccount): string | undefined =>
	userAccount && (userAccount.accountId || userAccount.id || userAccount.value);
