import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import { LoadIfNeeded } from '../../common/ui/load-if-needed';
import type { Props } from './types';
import type SuggestResourcesQuickAction from './index';

const SuggestResourcesQuickActionAsync = lazyForPaint<typeof SuggestResourcesQuickAction>(
	() => import(/* webpackChunkName: "async-incident-suggested-resources-quick-action" */ './index'),
);

export const AsyncSuggestResourcesQuickAction = (props: Omit<Props, 'containerProps'>) => (
	<ErrorBoundary
		id="suggestedResourcesQuickActionButton"
		packageName="jiraAiopsSuggestedResources"
		teamName="itsm-aiops-grogu"
	>
		<LoadIfNeeded>
			{(containerProps) => (
				<Placeholder name="suggested-resources-quick-action-button" fallback={null}>
					<SuggestResourcesQuickActionAsync {...props} containerProps={containerProps} />
				</Placeholder>
			)}
		</LoadIfNeeded>
	</ErrorBoundary>
);
