import { trackFetchErrors } from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { isValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { Options, FetchProjectsResponse } from '../../../common/types';
import { mapDataToOptions } from '../../../common/utils';

export const fetchProjects = async (
	inputValue: string,
	autoCompleteUrl: string,
): Promise<Options> =>
	performGetRequest(`${autoCompleteUrl}${inputValue}`)
		.then((response: FetchProjectsResponse) => mapDataToOptions(response.values))
		.catch((error) => {
			trackFetchErrors({
				error,
				id: 'fetchProjects',
				packageName: 'jiraIssueFieldProject',
				logToSentry: !isValidationError(error),
				sendToPrivacyUnsafeSplunk: true,
			});
			throw error;
		});
