import React from 'react';
import { Checkbox } from '@atlaskit/checkbox';
import type { ReadViewProps } from '../../types';

export const BooleanFieldReadView = ({
	value,
	render,
	getValueContainer,
	inInlineEdit,
}: ReadViewProps) => {
	const ValueContainer = getValueContainer();
	return (
		<ValueContainer
			render={render || (() => <Checkbox isChecked={value === 'true'} />)}
			noBorder={inInlineEdit}
		/>
	);
};
