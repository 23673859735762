import React, { useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { ff } from '@atlassian/jira-feature-flagging';
import { useOptionallyControlledEditingState } from '@atlassian/jira-issue-field-optional-editing-state-manager';
import { DATA_CLASSIFICATION_TYPE } from '@atlassian/jira-platform-field-config';
import type { Props } from './types';

export const ClassifyIssue = ({ onClick, label, itemKey }: Props) => {
	/**
	 * Todo: Replace the constant `DATA_CLASSIFICATION_EDITING_STATE_KEY` with the destructured value from relay response
	 */
	const [, setIsIssueClassified] = useOptionallyControlledEditingState(
		false,
		DATA_CLASSIFICATION_TYPE,
	);

	const handleClick = useCallback(() => {
		if (ff('issue-view-classification-level-editability_88zal')) {
			setIsIssueClassified(true);
		}
		onClick?.(itemKey);
	}, [setIsIssueClassified, onClick, itemKey]);

	return (
		<DropdownItem key="clone" onClick={handleClick}>
			{label}
		</DropdownItem>
	);
};
