import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import WithValidationOnBlur from '../../../../with-validation-on-blur/view.tsx';
import TimeLogTextField from '../../time-log-text-field/view.tsx';
import messages from '../messages';

export type Props = {
	isDisabled: boolean;
	isValid: boolean;
	value: string;
	onChange: (arg1: string) => void;
	onBlur: () => void;
};

const ValidatedLogTimeInput = (props: Props) => {
	const { formatMessage } = useIntl();
	const { isValid, value, onChange, onBlur, isDisabled } = props;
	return (
		<WithValidationOnBlur isValid={isValid}>
			{(shouldAppearInvalid) => (
				<TimeLogTextField
					value={value}
					isInvalid={shouldAppearInvalid}
					invalidMessage={formatMessage(messages.timeInvalidMessage)}
					onChange={onChange}
					onBlur={onBlur}
					isDisabled={isDisabled}
					label={formatMessage(messages.timeSpent)}
					name={messages.timeSpent.id}
					autoFocus
				/>
			)}
		</WithValidationOnBlur>
	);
};

export default ValidatedLogTimeInput;
