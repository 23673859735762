import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { styled } from '@compiled/react';
import { triggerPostMoveFlash } from '@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash';
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { reorder } from '@atlaskit/pragmatic-drag-and-drop/reorder';
import type { DropTargetRecord } from '@atlaskit/pragmatic-drag-and-drop/types';
import { token } from '@atlaskit/tokens';
import { AnnouncerV2 } from '@atlassian/jira-accessibility/src/ui/announcer-v2/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useItemRegistry } from '@atlassian/jira-issue-sortable/src/controllers/use-item-registry';
import {
	isSortableItemData,
	useSortableItem,
} from '@atlassian/jira-issue-sortable/src/controllers/use-sortable-item';
import { DEV_SUMMARY_TYPE } from '@atlassian/jira-platform-field-config';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { SORTABLE_ITEM_TEST_ID } from './constants';
import { ReorderMenu } from './reorder-menu';
import messages from './reorder-menu/messages';
import { SortableItemContainer, type SortableItemPositionVariant } from './sortable-item-container';
import type { ItemEntityId, SortableItemListProps, SortableItemProps } from './types';

export const SortableItem = ({
	children,
	draggableId,
	instanceId,
	index,
	reorderItem,
	numItems,
	fieldName,
	registerItem,
}: SortableItemProps) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const { dragState } = useSortableItem({
		draggableId,
		index,
		ref,
		isReorderEnabled: true,
		instanceId,
	});

	useEffect(() => {
		const element = ref.current;
		if (!element) {
			return;
		}

		return registerItem({
			draggableId,
			element,
		});
	}, [draggableId, registerItem]);

	let positionVariant: SortableItemPositionVariant = 'standard';
	if (index === 0) {
		positionVariant = 'first-item';
	} else if (index === numItems - 1) {
		positionVariant = 'last-item';
	}

	return (
		<SortableItemContainer
			ref={ref}
			testId="issue-view-layout-templates-item-list.ui.sortable-item-list.draggable-container"
			dragState={dragState}
			fieldName={fieldName}
			positionVariant={positionVariant}
		>
			<ReorderMenu
				itemIndex={index}
				onReorderItem={reorderItem}
				numItems={numItems}
				fieldName={fieldName}
			/>
			<FieldContainer data-testid={`${SORTABLE_ITEM_TEST_ID}${draggableId}`}>
				{children}
			</FieldContainer>
		</SortableItemContainer>
	);
};

type SortableItemListAction = {
	type: 'reorder';
	draggableId: string;
	fieldName: string;
	startPosition: number;
	endPosition: number;
	numberOfItems: number;
};

export const SortableItemList = ({
	droppableId: instanceId,
	items,
	onSortItems,
}: SortableItemListProps) => {
	const itemEntities = items.reduce(
		(acc, curr) => {
			acc.ids.push(curr.id);
			acc.entities[curr.id] = curr.element;
			acc.fieldNames[curr.id] = curr.fieldName;
			return acc;
		},
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		{
			ids: [],
			entities: {},
			fieldNames: {},
		} as ItemEntityId,
	);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [prevIds, setPrevIds] = useState(itemEntities.ids);
	const [sortedIds, setSortedIds] = useState(itemEntities.ids);

	const encodedIds = itemEntities.ids.join(',');
	const encodedPrevIds = prevIds.join(',');

	useEffect(() => {
		if (encodedIds !== encodedPrevIds) {
			const ids = encodedIds.split(',');
			setPrevIds(ids);
			setSortedIds(ids);
		}
	}, [encodedIds, encodedPrevIds]);

	const intl = useIntl();

	const { itemRegistry, registerItem } = useItemRegistry();

	/**
	 * Information about the last action is used for:
	 *
	 * 1. Triggering a post-move flash
	 * 2. Screen reader announcements
	 */
	const [lastAction, setLastAction] = useState<SortableItemListAction | null>(null);

	useEffect(() => {
		if (lastAction === null) {
			return;
		}

		if (lastAction.type === 'reorder') {
			const element = itemRegistry.get(lastAction.draggableId);
			if (!element) {
				return;
			}

			triggerPostMoveFlash(element);
		}
	}, [itemRegistry, lastAction]);

	/**
	 * Common reorder handler used for both:
	 *
	 * - Drops through `pragmatic-drag-and-drop`
	 * - Reorder menu interactions
	 */
	const reorderItem = useCallback(
		({ indexSource, indexDestination }: { indexSource: number; indexDestination: number }) => {
			if (indexDestination === indexSource) return;

			const draggableId = sortedIds[indexSource];

			// Payload for Analytics
			// Added 1 to the indexSource and Destination as analytics requirements
			// required the positions starting from 1 (not 0)
			const payload = {
				initialPinPosition: indexSource + 1,
				newPinPosition: indexDestination + 1,
				fieldName: itemEntities.fieldNames[draggableId],
			};

			const event = createAnalyticsEvent({
				action: 'dropped',
				actionSubject: 'field',
				actionSubjectId: 'pinnedFields',
			});

			fireUIAnalytics(event, 'pinnedFields', payload);

			const modifiedIds = reorder({
				list: sortedIds,
				startIndex: indexSource,
				finishIndex: indexDestination,
			});

			setSortedIds(modifiedIds);

			setLastAction({
				type: 'reorder',
				draggableId,
				fieldName: itemEntities.fieldNames[draggableId],
				startPosition: indexSource + 1,
				endPosition: indexDestination + 1,
				numberOfItems: sortedIds.length,
			});

			if (onSortItems) {
				onSortItems(
					sortedIds[indexSource],
					sortedIds[indexDestination],
					indexSource > indexDestination,
				);
			}
		},
		[createAnalyticsEvent, itemEntities.fieldNames, onSortItems, sortedIds],
	);

	const onDragEnd = useCallback(
		({
			source,
			destination,
		}: {
			source: { data: Record<string, unknown> };
			destination: DropTargetRecord | null;
		}) => {
			if (!destination) {
				return;
			}

			if (!isSortableItemData(source.data) || !isSortableItemData(destination.data)) {
				return;
			}

			const indexSource = source.data.index;
			const indexDestination = getReorderDestinationIndex({
				startIndex: indexSource,
				closestEdgeOfTarget: extractClosestEdge(destination.data),
				indexOfTarget: destination.data.index,
				axis: 'vertical',
			});

			reorderItem({ indexSource, indexDestination });
		},
		[reorderItem],
	);

	const onDragStart = useCallback(({ draggableId }: { draggableId: string }) => {
		// When drag starts from the mouse drag (not keyboard) we want to exit the field's edit mode - such as closing the dropdowns, selects etc.
		// The mode will be 'FLUID' if the drag is via the mouse, and the mode is 'SNAP' when dragging is started via keyboard

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		if (document.activeElement) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, jira/jira-ssr/no-unchecked-globals-usage
			(document.activeElement as HTMLElement).blur();

			if (DEV_SUMMARY_TYPE === draggableId) {
				// Simulate a click to close the development field popup
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, jira/jira-ssr/no-unchecked-globals-usage
				(document.activeElement as HTMLElement).click();
			}
		}
	}, []);

	useEffect(
		() =>
			monitorForElements({
				canMonitor({ source }) {
					return isSortableItemData(source.data) && source.data.instanceId === instanceId;
				},
				onDragStart({ source }) {
					if (!isSortableItemData(source.data)) {
						return;
					}

					onDragStart({ draggableId: source.data.draggableId });
				},
				onDrop({ location, source }) {
					const { dropTargets } = location.current;
					const destination = dropTargets.at(0) ?? null;

					onDragEnd({
						source,
						destination,
					});
				},
			}),
		[instanceId, onDragEnd, onDragStart],
	);

	const liveRegion = useMemo(() => {
		if (lastAction) {
			if (lastAction.type === 'reorder') {
				return {
					shouldAnnounce: true,
					message: intl.formatMessage(messages.postMoveAnnouncement, lastAction),
				};
			}
		}

		return { shouldAnnounce: false, message: '' };
	}, [intl, lastAction]);

	return (
		<>
			<SortableItemListContainer data-testid="issue-view-layout-templates-item-list.ui.sortable-item-list.droppable-container">
				{sortedIds.map((id, index) => (
					<SortableItem
						key={id}
						draggableId={id}
						instanceId={instanceId}
						index={index}
						reorderItem={reorderItem}
						numItems={sortedIds.length}
						fieldName={itemEntities.fieldNames[id]}
						registerItem={registerItem}
					>
						{itemEntities.entities[id]}
					</SortableItem>
				))}
			</SortableItemListContainer>
			<AnnouncerV2
				shouldAnnounce={liveRegion.shouldAnnounce}
				message={liveRegion.message}
				liveMode="assertive"
			/>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldContainer = styled.div({
	paddingBottom: token('space.050', '4px'),
	flex: 1,
	/**
	 * Adding fixed width to parent container of issue fields to stop any fields
	 * that use flex from overflowing out of the sidebar.
	 */
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SortableItemListContainer = styled.div({
	/*
    SortableContainer occupies the entire Sidebar Area, so its set margin has to be removed from InnerBodyPadding src/packages/issue/issue-view-layout-templates/components/layout-group/src/common/ui/styled.tsx
    and added back to this container
    */
	marginTop: token('space.negative.100', '-8px'),
	marginBottom: token('space.negative.100', '-8px'),
	marginLeft: token('space.negative.150', '-12px'),
	marginRight: token('space.negative.150', '-12px'),
	paddingTop: token('space.100', '8px'),
	paddingBottom: token('space.100', '8px'),

	/**
	 * Sortable items should have the reorder flash flush with the container edges.
	 * They have internal padding to compensate the lack of padding on the container.
	 */
	paddingInline: token('space.0', '0px'),

	display: 'flex',
	flexDirection: 'column',
	gap: token('space.100', '8px'),
});
