import React from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { realTimeIssueEventInvokeTime } from '@atlassian/jira-forge-ui-analytics/src/common/utils/performance-analytics/main.tsx';
import { emitIssueChangedEvent as emitConnectIssueChangedEvent } from '@atlassian/jira-issue-ecosystem-connect/src/utils/realtime-updates/index.tsx';
import { emitIssueChangedEvent as emitForgeIssueChangedEvent } from '@atlassian/jira-issue-ecosystem-forge/src/utils/realtime-updates/index.tsx';
import type { ChangeEvent } from '@atlassian/jira-issue-ecosystem/src/types.tsx';
import { RealtimeUpdates } from '@atlassian/jira-issue-ecosystem/src/ui/realtime-updates/index.tsx';
import { isSynthetic } from '@atlassian/jira-issue-ecosystem/src/utils/analytics';
import { isInSample } from '@atlassian/jira-issue-sample-utils/src/common/utils/index.tsx';
import { REALTIME_EVENTS_ANALYTICS_SAMPLE_RATE } from '@atlassian/jira-issue-view-common-constants/src/realtime-support.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';

const IntegrationType = {
	FORGE: 'forge',
	CONNECT: 'connect',
} as const;

type IntegrationType = (typeof IntegrationType)[keyof typeof IntegrationType];

const safeInvokeWithReporting =
	(fn: (eventDetails: ChangeEvent) => void, type: IntegrationType) =>
	(eventDetails: ChangeEvent, analyticsEvent: UIAnalyticsEvent) => {
		try {
			fn(eventDetails);

			return true;
		} catch (error) {
			realTimeIssueEventInvokeTime.failure();
			// Unlike success events, errors ones are reported separately
			// So in case both emits fail we have two error Objects logged
			fireOperationalAnalytics(analyticsEvent, 'issueRealtimeEventEmit failed', {
				type,
				error,
			});
			log.safeErrorWithoutCustomerData(
				'issue-view.components.ecosystem.realtime-updates',
				`Failed to emit realtime issue event for integration: ${type}`,
				error instanceof Error ? error : undefined,
			);
			return false;
		}
	};

const onEvent = (eventDetails: ChangeEvent, analyticsEvent: UIAnalyticsEvent) => {
	const inSample = isInSample(REALTIME_EVENTS_ANALYTICS_SAMPLE_RATE);

	if (inSample) realTimeIssueEventInvokeTime.start();

	const analyticsAttributes = {
		isSynthetic: isSynthetic(),
		[IntegrationType.CONNECT]:
			safeInvokeWithReporting(emitConnectIssueChangedEvent, IntegrationType.CONNECT)(
				eventDetails,
				analyticsEvent,
			) === true,
		[IntegrationType.FORGE]:
			safeInvokeWithReporting(emitForgeIssueChangedEvent, IntegrationType.FORGE)(
				eventDetails,
				analyticsEvent,
			) === true,
	};

	// Report only when at least a single integration emit is successful
	// We are going to send a single event to reduce amount of data stored since there will be millions of events daily
	if (!analyticsAttributes.forge && !analyticsAttributes.connect) return;

	if (inSample) {
		fireOperationalAnalytics(analyticsEvent, 'issueRealtimeEventEmit success', analyticsAttributes);

		realTimeIssueEventInvokeTime.success();
	}
};

export const EcosystemRealtimeUpdatesView = () => (
	<JSErrorBoundary
		id="EcosystemRealtimeUpdates"
		packageName="jiraIssueView"
		teamName="bento"
		fallback="unmount"
		sendToPrivacyUnsafeSplunk
	>
		<RealtimeUpdates onEvent={onEvent} />
	</JSErrorBoundary>
);
