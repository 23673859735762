import React, { useCallback, useEffect, type ReactNode } from 'react';
import { SpotlightPulse } from '@atlaskit/onboarding';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { Content, isMacOs } from './content';
import { useSpotlightControl } from './utils';

export type IssueActionsSpotlightProps = {
	children: ReactNode;
};

const IssueActionsSpotlightInner = ({ children }: { children: ReactNode }) => {
	const { show, dismiss } = useSpotlightControl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		const dismissSpotlight = (ev: KeyboardEvent) => {
			if (ev.key !== 'k') return;
			const isMac = isMacOs();
			if (isMac) {
				if (!ev.metaKey) return;
			} else if (!ev.ctrlKey) return;
			fireUIAnalytics(
				createAnalyticsEvent({
					actionSubject: 'compalShortcut',
					action: 'triggered',
					actionSubjectId: 'compalSpotlightDismiss',
				}),
			);
			dismiss();
		};

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('keydown', dismissSpotlight);
		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener('keydown', dismissSpotlight);
		};
	}, [createAnalyticsEvent, dismiss]);

	// this event will never fire since the focus is trapped within spotlight card
	// however eslint requires a keydown event on div
	const onKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLDivElement>) => {
			if (e.key === 'Enter') {
				dismiss();
			}
		},
		[dismiss],
	);

	return show ? (
		<Popup
			boundary="clippingParents"
			isOpen
			onClose={dismiss}
			placement="bottom-end"
			zIndex={layers.modal}
			content={() => <Content onDismiss={dismiss} />}
			trigger={({ ref }: TriggerProps) => (
				// To close spotlight before click propagates to children
				// eslint-disable-next-line jsx-a11y/no-static-element-interactions
				<div ref={ref} onKeyDown={onKeyDown} onClick={dismiss}>
					<SpotlightPulse radius={3} pulse>
						{children}
					</SpotlightPulse>
				</div>
			)}
		/>
	) : (
		children
	);
};

export const IssueActionsSpotlight = ({ children }: { children: ReactNode }) => (
	<JSErrorBoundary
		id="compal-issue-actions-spotlight"
		packageName="jiraCommandPaletteDiscoverability"
		fallback={() => children}
		teamName="deliveroo"
	>
		<IssueActionsSpotlightInner>{children}</IssueActionsSpotlightInner>
	</JSErrorBoundary>
);
