import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { graphql, useFragment } from 'react-relay';
import type { FragmentRefs } from 'relay-runtime';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { VERSION_DETAILS } from '@atlassian/jira-development-board-common/src/common/constants.tsx';
import type { Location } from '@atlassian/jira-development-board-common/src/common/types.tsx';
import { DevInfoTypes } from '@atlassian/jira-development-board-common/src/types.tsx';
import DevInfoIcon from '@atlassian/jira-development-board-dev-info-icon/src/main.tsx';
import type {
	DeploymentEnvironment,
	DeploymentState,
} from '@atlassian/jira-development-board-dev-info-icon/src/types.tsx';
import type { LegacyData } from '@atlassian/jira-development-details-loader/src/common/types/index.tsx';
import {
	BUILD_PANEL_DATA_TYPE,
	DEPLOYMENT_PANEL_DATA_TYPE,
} from '@atlassian/jira-development-details/src/common/model/constants';
import type { PanelDataTypes } from '@atlassian/jira-development-details/src/main/model/types';
import { fg } from '@atlassian/jira-feature-gating';
import type {
	delivery_softwareReleasesVersionDetailIssueList$data,
	delivery_softwareReleasesVersionDetailIssueList$key,
} from '@atlassian/jira-relay/src/__generated__/delivery_softwareReleasesVersionDetailIssueList.graphql';
import { ISSUE_CARD_CLICK_IGNORE_TARGET } from '@atlassian/jira-software-releases-version-detail-issue-card-click-prevent-controller/src/common/constants.tsx';
import { DEV_DETAILS_DIALOG_SCOPE_ID, VIEWPORT_WIDTH_LARGE } from '../../../../common/constants';
import { ErrorBoundary } from '../../../../common/utils/non-critical-error-boundary';
import { BambooDeploymentData } from './bamboo-deployment-data';
import { BuildData } from './build-data';
import { DeploymentData } from './deployment-data';

type Props = {
	jiraIssue: delivery_softwareReleasesVersionDetailIssueList$key;
	onClick: (dataType: PanelDataTypes, reviewData?: LegacyData) => void;
};

type DeliveryFragment = delivery_softwareReleasesVersionDetailIssueList$data;

type DevOpsSummary = DeliveryFragment['devOpsSummarisedEntities'] & {
	summarisedDeployments: {
		count: number | null;
		deploymentEnvironment: {
			category: DeploymentEnvironment | null;
		} | null;
		deploymentState: DeploymentState | null;
	} | null;
	summarisedBuilds: {
		' $fragmentSpreads': FragmentRefs<'buildData_softwareReleasesVersionDetailIssueList_devOpsSummarisedBuilds'>;
		count: number | null;
	} | null;
};

const getDeploymentDetails = (devOpsSummarisedEntities: DevOpsSummary) => {
	const summaries = devOpsSummarisedEntities?.summarisedDeployments;
	if (!summaries) {
		return undefined;
	}

	const { deploymentState, deploymentEnvironment } = summaries;
	return {
		deploymentState: deploymentState ?? undefined,
		deploymentEnvironment: deploymentEnvironment?.category ?? undefined,
	};
};

export const DeliveryInfo = ({
	issueId,
	scopeId,
	customTrigger,
	devSummaryCache,
	devOpsSummarisedEntities,
	zIndexForPopup,
	location,
	onClick = noop,
}: {
	issueId: DeliveryFragment['issueId'];
	scopeId?: string;
	customTrigger?: ReactNode;
	devSummaryCache?: DeliveryFragment['devSummaryCache'];
	devOpsSummarisedEntities?: DevOpsSummary;
	zIndexForPopup?: number;
	location: Location;
	onClick?: Props['onClick'];
}) => {
	const devSummary = devSummaryCache?.devSummary;
	const hasBambooDeployments =
		(devSummary?.deploymentEnvironments?.overall?.topEnvironments ?? []).filter(
			(deployment) => deployment.status === 'DEPLOYED',
		).length > 0;
	const hasBuilds =
		Number(devSummary?.build?.overall?.count) > 0 ||
		Number(devOpsSummarisedEntities?.summarisedBuilds?.count) > 0;
	const deploymentCount = devOpsSummarisedEntities?.summarisedDeployments?.count ?? 0;
	const hasDeployments = deploymentCount > 0;

	if (devOpsSummarisedEntities != null && hasDeployments) {
		const deploymentDetails = getDeploymentDetails(devOpsSummarisedEntities);

		return (
			<DevInfoIcon
				devInfoType={DevInfoTypes.DEPLOYMENT}
				issueId={Number(issueId)}
				customTrigger={customTrigger}
				scopeId={scopeId}
				deploymentDetails={deploymentDetails}
				zIndex={zIndexForPopup}
				location={location}
			/>
		);
	}

	if (devSummary != null && hasBambooDeployments) {
		return (
			<BambooDeploymentData
				jiraIssueDevSummary={devSummary}
				onDeploymentClicked={() => onClick(DEPLOYMENT_PANEL_DATA_TYPE)}
			/>
		);
	}

	if (devSummary != null && hasBuilds) {
		return (
			<BuildData
				devOpsSummarisedBuilds={devOpsSummarisedEntities?.summarisedBuilds ?? undefined}
				jiraIssueDevSummary={devSummary}
				onBuildClicked={() => onClick(BUILD_PANEL_DATA_TYPE)}
			/>
		);
	}

	return null;
};

export const Delivery = ({ jiraIssue, onClick }: Props) => {
	const {
		issueId,
		devSummaryCache,
		devOpsSummarisedEntities,
		summarisedDeployments,
		summarisedBuilds,
	} = useFragment(
		graphql`
			fragment delivery_softwareReleasesVersionDetailIssueList on JiraIssue {
				devOpsSummarisedEntities {
					summarisedDeployments @skip(if: $isIssueSummarisedDeploymentsMigratedToAgs) {
						count
					}
					summarisedBuilds @skip(if: $isIssueSummarisedDeploymentsMigratedToAgs) {
						count
						...buildData_softwareReleasesVersionDetailIssueList_devOpsSummarisedBuilds
					}
					...deploymentData_softwareReleasesVersionDetailIssueList
				}
				summarisedDeployments @include(if: $shouldIncludeDevOpsFieldsViaAgs) {
					count
				}
				summarisedBuilds @include(if: $shouldIncludeDevOpsFieldsViaAgs) {
					...buildData_softwareReleasesVersionDetailIssueList_devOpsSummarisedBuilds
					count
				}
				devSummaryCache {
					devSummary {
						build {
							overall {
								count
							}
						}
						deploymentEnvironments {
							overall {
								topEnvironments {
									status
								}
							}
						}
						...bambooDeploymentData_softwareReleasesVersionDetailIssueList
						...buildData_softwareReleasesVersionDetailIssueList_jiraIssueDevSummary
					}
				}
				issueId
			}
		`,
		jiraIssue,
	);

	const devSummary = devSummaryCache?.devSummary;
	const hasBambooDeployments =
		(devSummary?.deploymentEnvironments?.overall?.topEnvironments ?? []).filter(
			(deployment) => deployment.status === 'DEPLOYED',
		).length > 0;
	const hasBuilds =
		Number(devSummary?.build?.overall?.count) > 0 ||
		(fg('solaris_migrate_summurized_deployments_to_ags_re')
			? Number(summarisedBuilds?.count) > 0
			: Number(devOpsSummarisedEntities?.summarisedBuilds?.count) > 0);
	const deploymentCount = fg('solaris_migrate_summurized_deployments_to_ags_re')
		? summarisedDeployments?.count ?? 0
		: devOpsSummarisedEntities?.summarisedDeployments?.count ?? 0;
	const hasDeployments = deploymentCount > 0;

	if (devOpsSummarisedEntities != null && hasDeployments) {
		return (
			<DeliveryContainer
				data-version-details-issue-click-ignore-target={ISSUE_CARD_CLICK_IGNORE_TARGET}
			>
				<ErrorBoundary id="issuesListDeploymentData">
					<DevInfoIcon
						devInfoType={DevInfoTypes.DEPLOYMENT}
						issueId={Number(issueId)}
						customTrigger={
							<DeploymentData
								jiraIssueDevOpsSummarisedEntities={devOpsSummarisedEntities}
								onDeploymentClicked={() => onClick(DEPLOYMENT_PANEL_DATA_TYPE)}
							/>
						}
						scopeId={DEV_DETAILS_DIALOG_SCOPE_ID}
						isStopPropagationOnClick={false}
						location={VERSION_DETAILS}
					/>
				</ErrorBoundary>
			</DeliveryContainer>
		);
	}

	if (devSummary != null && hasBambooDeployments) {
		return (
			<DeliveryContainer
				data-version-details-issue-click-ignore-target={ISSUE_CARD_CLICK_IGNORE_TARGET}
			>
				<ErrorBoundary id="issuesListBambooDeploymentData">
					<BambooDeploymentData
						jiraIssueDevSummary={devSummary}
						onDeploymentClicked={() => onClick(DEPLOYMENT_PANEL_DATA_TYPE)}
					/>
				</ErrorBoundary>
			</DeliveryContainer>
		);
	}

	if (devSummary != null && hasBuilds) {
		return (
			<DeliveryContainer
				data-version-details-issue-click-ignore-target={ISSUE_CARD_CLICK_IGNORE_TARGET}
			>
				<ErrorBoundary fallback="flag" id="issuesListBuildData">
					<BuildData
						devOpsSummarisedBuilds={
							(fg('solaris_migrate_summurized_deployments_to_ags_re')
								? summarisedBuilds
								: devOpsSummarisedEntities?.summarisedBuilds) ?? undefined
						}
						jiraIssueDevSummary={devSummary}
						onBuildClicked={() => onClick(BUILD_PANEL_DATA_TYPE)}
					/>
				</ErrorBoundary>
			</DeliveryContainer>
		);
	}

	return <DeliveryContainer />;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DeliveryContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`@media screen and (min-width: ${VIEWPORT_WIDTH_LARGE}px)`]: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		width: `${gridSize * 20}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		minWidth: `${gridSize * 20}px`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`@media screen and (max-width: ${VIEWPORT_WIDTH_LARGE}px)`]: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		width: `${gridSize * 3}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		minWidth: `${gridSize * 3}px`,
	},
});
