import React, { useCallback, useEffect, useState } from 'react';
import { useFragment, graphql } from 'react-relay';
import { useIntl } from '@atlassian/jira-intl';
import { SingleLineTextEditView } from '@atlassian/jira-issue-field-single-line-text-editview-full/src/ui/single-line-text/index.tsx';
import { parseTimeString, isValidTimeString } from '@atlassian/jira-issue-format-time';
import type { TimeTrackingConfig } from '@atlassian/jira-issue-shared-types/src/common/types/jira-settings-type.tsx';
import useDebouncedCallback from '@atlassian/jira-platform-use-debounce/src/utils/use-debounce-callback/index.tsx';
import type { originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView$key as TimeTrackingSettingsFragment } from '@atlassian/jira-relay/src/__generated__/originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView.graphql';
import { timeTrackingFormatter } from '@atlassian/jira-time-tracking-formatter/src/main.tsx';
import type { TimeFormat, TimeUnit } from '@atlassian/jira-time-tracking-formatter/src/types.tsx';
import messages from './messages';
import { OriginalEstimateEditContainer } from './styled';
import type { OriginalEstimateEditViewProps } from './types';

const DEBOUCE_DELAY_MS = 500;

export const OriginalEstimateEditView = ({
	autoFocus = true,
	onChange,
	initialTimeInSeconds,
	isDisabled = false,
	isInvalid = false,
	placeholder = '',
	compact = false,
	onChangeInvalidMessage,
	invalidMessage,
	timeTrackingSettingsfragmentRef,
	onFocus,
}: OriginalEstimateEditViewProps) => {
	const [inputValue, setInputValue] = useState('');
	const timeTrackingSettingsData = useFragment<TimeTrackingSettingsFragment>(
		graphql`
			fragment originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView on JiraTimeTrackingSettings {
				workingHoursPerDay
				workingDaysPerWeek
				defaultFormat
				defaultUnit
			}
		`,
		timeTrackingSettingsfragmentRef,
	);

	const intl = useIntl();
	const { formatMessage } = useIntl();

	useEffect(() => {
		if (initialTimeInSeconds && !Number.isNaN(initialTimeInSeconds)) {
			const formattedInputValue = timeTrackingFormatter(
				initialTimeInSeconds,
				{
					workingHoursPerDay: timeTrackingSettingsData?.workingHoursPerDay || 8,
					workingDaysPerWeek: timeTrackingSettingsData?.workingDaysPerWeek || 5,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					timeFormat: (timeTrackingSettingsData?.defaultFormat as TimeFormat) || 'pretty',
					defaultUnit:
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						(timeTrackingSettingsData?.defaultUnit?.toLowerCase() as TimeUnit) || 'minute',
				},
				intl,
			);
			setInputValue(formattedInputValue);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	/* This is not a controlled component, however, it takes a prop that accepts initial value.
	 * Want to perform this calculation only on the first render on initial value, so ignoring the exhaustive-deps
	 * Rest of the calculation and validation is done within the component and only when the validation passes, parent component's onChange is called.
	 */

	const [debouncedSetInputError, cancel] = useDebouncedCallback(
		onChangeInvalidMessage,
		DEBOUCE_DELAY_MS,
	);

	const validateString = useCallback(
		(timeString: string, skipDebounce = false) => {
			const timeTrackingSettings = {
				hoursPerDay: timeTrackingSettingsData?.workingHoursPerDay || 8,
				daysPerWeek: timeTrackingSettingsData?.workingDaysPerWeek || 5,
				format: timeTrackingSettingsData?.defaultFormat || 'pretty',
				defaultUnit: timeTrackingSettingsData?.defaultUnit?.toLowerCase() || 'minute',
				isTimeTrackingEnabled: true,
			};
			cancel();

			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			if (isValidTimeString(timeTrackingSettings as TimeTrackingConfig)(timeString)) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const newTimeValue = parseTimeString(timeTrackingSettings as TimeTrackingConfig)(
					timeString,
				);
				onChange(newTimeValue);
			} else {
				const errorMessage = formatMessage(messages.invalidInput);
				skipDebounce ? onChangeInvalidMessage(errorMessage) : debouncedSetInputError(errorMessage);
			}
		},
		[
			cancel,
			debouncedSetInputError,
			formatMessage,
			onChange,
			onChangeInvalidMessage,
			timeTrackingSettingsData?.defaultFormat,
			timeTrackingSettingsData?.defaultUnit,
			timeTrackingSettingsData?.workingDaysPerWeek,
			timeTrackingSettingsData?.workingHoursPerDay,
		],
	);

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			onChangeInvalidMessage(null);
			setInputValue(event.target.value);
			validateString(event.target.value);
		},
		[onChangeInvalidMessage, validateString],
	);

	const handleBlur = useCallback(() => {
		validateString(inputValue, true);
	}, [inputValue, validateString]);

	return (
		<OriginalEstimateEditContainer
			data-testid="issue-field-original-estimate-editview-full.ui.original-estimate.edit"
			isCompact={compact}
		>
			<SingleLineTextEditView
				isDisabled={isDisabled}
				isInvalid={isInvalid}
				onBlur={handleBlur}
				autoFocus={autoFocus}
				onFocus={onFocus}
				spacing={compact ? 'compact' : 'default'}
				value={inputValue}
				placeholder={placeholder}
				onChange={handleChange}
				invalidMessage={invalidMessage || undefined}
				ariaLabel={formatMessage(messages.label)}
			/>
		</OriginalEstimateEditContainer>
	);
};
