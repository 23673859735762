import { createSelector } from 'reselect';
import flatMap from 'lodash/fp/flatMap';
import getOr from 'lodash/fp/getOr';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import { DEVELOPMENT, PRODUCTION, STAGING, TESTING, UNMAPPED } from '../../../../model/constants';
import type {
	Deployment,
	DeploymentProvider,
	DeploymentsByEnvironment,
	EnvironmentType,
} from '../../../../model/deployments';
import type { State } from '../../../index';
import type { DetailsState } from '../../../reducers/details/types';
import { getDetailsState } from '../index';

const environmentTypeScore: Record<EnvironmentType, number> = {
	[PRODUCTION]: 1,
	[STAGING]: 2,
	[TESTING]: 3,
	[DEVELOPMENT]: 4,
	[UNMAPPED]: 5,
};

const getDeploymentProviders = createSelector(
	[getDetailsState],
	(detailsState: DetailsState): DeploymentProvider[] => detailsState.details.deploymentProviders,
);

const getDeployments = createSelector([getDeploymentProviders], flatMap(getOr([], 'deployments')));

const getTypeScore = (deploymentsByEnvironment: DeploymentsByEnvironment) =>
	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<EnvironmentType, number>>'.
	environmentTypeScore[deploymentsByEnvironment.type];

const sortDeployments = (deployments: Array<Deployment>) =>
	orderBy(deployments, 'lastUpdated', 'desc');

const groupByEnvironment = (allDeployments: Array<Deployment>) =>
	map(
		groupBy(allDeployments, (deployment) => deployment.environment.type),
		(deployments, type) => ({
			type,
			deployments: sortDeployments(deployments),
		}),
	);

export const getDeploymentsByEnvironmentTypes = createSelector([getDeployments], (allDeployments) =>
	sortBy(groupByEnvironment(allDeployments), getTypeScore),
);

export const getShowDeploymentTypeHeader = createSelector(
	[getDeploymentsByEnvironmentTypes],
	(allDeploymentTypes) =>
		allDeploymentTypes.length !== 1 || allDeploymentTypes[0].type !== 'UNMAPPED',
);

export const getDeploymentsCount: (arg1: State) => number = (state) => getDeployments(state).length;

export const hasDeployments: (arg1: State) => boolean = (state) => getDeploymentsCount(state) > 0;

export const getLatestDeployment = createSelector(
	[getDeploymentsByEnvironmentTypes],
	(allDeploymentTypes): Deployment | null => allDeploymentTypes?.[0]?.deployments?.[0] ?? null,
);
