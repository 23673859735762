import type { FieldId } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/types/field.tsx';
import type { AppRegisteredFields, ChangeId, RegisteredFieldsMap } from '../../types';

export const fieldWasRegisteredDeprecated = (
	registeredFields: AppRegisteredFields,
	changeId: ChangeId,
	fieldId: FieldId,
) => registeredFields[changeId]?.includes(fieldId);

export const fieldWasRegistered = (
	registeredFields: RegisteredFieldsMap,
	changeId: ChangeId,
	fieldId: FieldId,
	appId: string,
) => !!registeredFields[appId]?.[changeId]?.includes(fieldId);
