import React, { forwardRef, useContext, useEffect, useMemo } from 'react';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { issueSmartRequestSummaryTriggerEntryPoint } from '@atlassian/jira-issue-smart-request-summary-trigger/entrypoint';
import { IssueSmartRequestSummaryEntryPointContext } from '@atlassian/jira-issue-view-smart-request-summary-entrypoint';
import useMergeRefs from '@atlassian/jira-merge-refs';

const EMPTY_ENTRY_POINT_PARAMS = {};

export const IssueSmartRequestSummaryTrigger = forwardRef<
	HTMLButtonElement,
	{ isCompact: boolean; totalComments?: number; hasComments?: boolean }
>(({ isCompact, totalComments, hasComments }, ref) => {
	// The button is the trigger for loading the JsmSmartRequestSummary panel through this entrypoint context
	const issueSmartRequestSummaryEntryPoint = useContext(IssueSmartRequestSummaryEntryPointContext);

	const entryPointTriggerRef = useEntryPointButtonTrigger(
		issueSmartRequestSummaryEntryPoint.entryPointActions,
	);

	const triggerRef = useMergeRefs(ref, entryPointTriggerRef);

	// Button entrypoint - we load this in asynchronously
	const { entryPointReferenceSubject, entryPointActions } = useEntryPoint(
		issueSmartRequestSummaryTriggerEntryPoint,
		EMPTY_ENTRY_POINT_PARAMS,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	const runtimeProps = useMemo(
		() => ({ totalComments, hasComments, triggerRef, isCompact }),
		[totalComments, hasComments, isCompact, triggerRef],
	);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="issueSmartRequestSummaryTrigger"
			packageName="issueActivityFeed"
			teamName="Bento"
			fallback={null}
			errorFallback="flag"
			runtimeProps={runtimeProps}
		/>
	);
});
