import React, { type FC } from 'react';
import { IconButton } from '@atlaskit/button/new';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { SmartCardProvider } from '@atlaskit/link-provider';
import { Box, Text, xcss } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import { token } from '@atlaskit/tokens';
import { StreamingStatusBar } from '@atlassian/jira-ai-work-breakdown/src/ui/issue-breakdown-draft-list/streaming-status-bar';
import AIContainer from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-container';
import { AiFooter } from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-footer';
import { useIntl } from '@atlassian/jira-intl';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import {
	linkConfluencePageAISuggestionsClosed,
	createConfluencePageLinkSuccess,
} from '@atlassian/jira-issue-view-store/src/actions/confluence-pages-actions';
import {
	issueIdSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import {
	linkedConfluencePagesPagesSelector,
	mentionedConfluencePagesPagesSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/confluence-content-selector';
import {
	JiraIssueRelatedResourcesContainer,
	useRelatedResources,
} from '../controllers/related-resources-context';
import messages from './messages';
import { RelatedResourcesDraftList } from './related-resources-draft-list';

export const SuggestRelatedResourcesModal: FC = () => {
	const { formatMessage } = useIntl();
	const [{ isLoadingSuggestions, resourceSuggestions, errorMessage }, actions] =
		useRelatedResources();

	return (
		<SmartCardProvider>
			<AIContainer
				hasNewBorderExperience
				shouldFitContainer
				isShadow
				isLoading={isLoadingSuggestions}
				spacing="none"
				testId="ai-related-resources.ui.ai-container"
			>
				<Box
					testId="ai-related-resources.ui.header"
					xcss={[headerContainerStyles, hoverableHeaderStyles, hoverableStyles]}
				>
					<Box testId="ai-related-resources.ui.button" xcss={headerButtonStyles}>
						<Box as="span" xcss={headerPromptStyles}>
							<Text>{formatMessage(messages.suggestConfluencePages)}</Text>
						</Box>
					</Box>
					<IconButton
						testId="ai-related-resources.ui.cross-button"
						appearance="subtle"
						icon={CrossIcon}
						label={formatMessage(messages.discardSuggestions)}
						onClick={actions.discardAllSuggestions}
						isTooltipDisabled={false}
						tooltip={{ position: 'left' }}
					/>
				</Box>
				{isLoadingSuggestions && (
					<StreamingStatusBar message={formatMessage(messages.generatingPageSuggestions)} />
				)}
				{((!isLoadingSuggestions && resourceSuggestions && resourceSuggestions.length === 0) ||
					errorMessage != null) && (
					<SectionMessage appearance="warning">
						{formatMessage(messages.pageSuggestionsCouldNotBeGenerated)}
					</SectionMessage>
				)}
				<RelatedResourcesDraftList />
				<AiFooter isBeta={false} />
			</AIContainer>
		</SmartCardProvider>
	);
};

const ReduxConnectedRelatedResourcesSweetStateContainer = connect(
	(state) => ({
		issueId: issueIdSelector(state),
		issueKey: issueKeySelector(state),
		linkedConfluencePages: linkedConfluencePagesPagesSelector(state),
		mentionedConfluencePagesPages: mentionedConfluencePagesPagesSelector(state),
	}),
	{
		onCloseAISuggestions: linkConfluencePageAISuggestionsClosed,
		onCreateConfluencePageLinkSuccess: createConfluencePageLinkSuccess,
	},
)(JiraIssueRelatedResourcesContainer);

export const SuggestRelatedResourcesModalContainer = () => (
	<ReduxConnectedRelatedResourcesSweetStateContainer>
		<SuggestRelatedResourcesModal />
	</ReduxConnectedRelatedResourcesSweetStateContainer>
);

const headerButtonStyles = xcss({
	background: 'none',
	width: '100%',
	display: 'flex',
	justifyContent: 'baseline',
	alignItems: 'center',
	padding: 'space.200',
});

const headerPromptStyles = xcss({
	borderRadius: token('border.radius.100', '3px'),
	padding: 'space.025',
	paddingTop: '0',
	paddingBottom: '0',
	height: 'space.0',
	width: 'space.100',
	color: 'color.text.subtle',
	background: token('color.background.neutral', '#091E420F'),
	fontWeight: token('font.weight.medium', '400'),
	marginRight: 'space.100',
});

const headerContainerStyles = xcss({
	paddingRight: 'space.150',
	display: 'flex',
	borderBottom: `1px solid ${token('color.background.accent.gray.subtler', '#EBECF0')}`,
	alignItems: 'center',
});

const hoverableStyles = xcss({
	cursor: 'pointer',
});

const hoverableHeaderStyles = xcss({
	':hover': {
		background: token('color.background.neutral.subtle.hovered', '#EBECF0'),
		textDecoration: 'none',
	},
});
