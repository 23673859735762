import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { getFieldType } from '@atlassian/jira-issue-view-common-utils/src/fields/index.tsx';
import { AFFECTS_VERSIONS_TYPE } from '@atlassian/jira-platform-field-config';
import type { VersionFieldProps, VersionFieldValue, VersionResponse, VersionSaveId } from './types';

export const useVersionsFields = ({
	issueKey,
	fieldKey = AFFECTS_VERSIONS_TYPE,
	onSuccess,
	onFailure,
}: VersionFieldProps) => {
	const [fieldConfig] = useFieldConfig(issueKey, fieldKey);
	const fieldType = getFieldType(fieldConfig.value) || 'array';
	const [{ value, loading, error }, { saveValue, saveById, resetError }] = useEditField<
		VersionFieldValue,
		null,
		VersionResponse,
		VersionSaveId
	>({
		fieldKey,
		issueKey,
		fieldType,
		initialValue: null,
		onSuccess,
		onFailure,
	});

	return [
		{ value, loading, error, fieldConfig },
		{ saveValue, saveById, resetError },
	] as const;
};
