import React from 'react';
import { DeliveryInfo } from '@atlassian/jira-software-releases-version-detail-issue-list/src/ui/issues/issue-card/delivery';
import { DEV_DETAILS_DIALOG_SCOPE_ID } from '../../common/constants';
import {
	useIssueDevOpsSummary,
	useIssueDevOpsSummaryError,
	useIssueDevOpsSummaryLoading,
	useAnalyticsLocation,
} from '../../services/context';
import {
	DevOpsSummaryExperienceFail,
	DevOpsSummaryExperienceSuccess,
} from '../../services/experience-tracking';
import type { Props } from './types';

export const DeliverySummary = ({ issueId, zIndexForPopup }: Props) => {
	const [devOpsSummary] = useIssueDevOpsSummary(issueId);
	const [error] = useIssueDevOpsSummaryError();
	const [loading] = useIssueDevOpsSummaryLoading();
	const [analyticsLocation] = useAnalyticsLocation();

	if (devOpsSummary && analyticsLocation) {
		return (
			<>
				<DeliveryInfo
					issueId={issueId}
					scopeId={DEV_DETAILS_DIALOG_SCOPE_ID}
					devOpsSummarisedEntities={devOpsSummary}
					location={analyticsLocation}
					zIndexForPopup={zIndexForPopup}
				/>
				<DevOpsSummaryExperienceSuccess />
			</>
		);
	}

	if (error) {
		return <DevOpsSummaryExperienceFail location="deliverySummaryIcon" error={error} />;
	}

	if (loading) {
		return null;
	}

	// No DevOps summary data for this issue
	return <DevOpsSummaryExperienceSuccess />;
};
