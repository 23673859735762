import React, { useMemo } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { Field } from '@atlaskit/form';
import { useIntl } from '@atlassian/jira-intl';
import { StickyHeaderTrackerContainer } from '@atlassian/jira-issue-header-tracker-service/src/services/sticky-header/index.tsx';
import type { MediaContext } from '@atlassian/jira-issue-view-common-types/src/media-context-type';
import type IssueEditorType from '../../../editor';
import { IssueEditorReadonly, IssueEditorBoundary } from '../../../editor/async';
import messages from './messages';

export type Props = {
	isDisabled: boolean;
	externalId: string;
	defaultValue: ADF;
	portalElement?: HTMLElement | null;
	mediaContext: MediaContext;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mentionProvider: Record<any, any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mentionEncoder: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	activityProvider: any;
	onChange: (adf: ADF) => void;
	onChangeFailure: () => void;
};

// TODO reevaluate phase - forPaint is simply for initial parity
export const IssueEditor = lazyForPaint<typeof IssueEditorType>(
	() => import(/* webpackChunkName: "async-editor-view" */ '../../../editor'),
);

export const WorkDescription = ({
	portalElement = undefined,
	isDisabled,
	externalId,
	defaultValue,
	mediaContext,
	mentionProvider,
	mentionEncoder,
	activityProvider,
	onChange,
	onChangeFailure,
}: Props) => {
	const { formatMessage } = useIntl();

	const fallback = useMemo(
		() => <IssueEditorReadonly mediaContext={mediaContext} defaultValue={defaultValue} />,
		[defaultValue, mediaContext],
	);

	return (
		<Field name="work-description" label={formatMessage(messages.label)}>
			{() => (
				<IssueEditorBoundary packageName="issue" fallback={fallback}>
					<StickyHeaderTrackerContainer scope="work-description">
						<IssueEditor
							isExpanded
							externalId={externalId}
							isDisabled={isDisabled}
							defaultValue={defaultValue}
							portalElement={portalElement}
							onChange={onChange}
							onChangeFailure={onChangeFailure}
							mediaContext={mediaContext}
							// @ts-expect-error - TS2740 - Type 'Record<any, any>' is missing the following properties from type 'MentionProvider': filter, recordMentionSelection, shouldHighlightMention, isFiltering, and 2 more.
							mentionProvider={mentionProvider}
							mentionEncoder={mentionEncoder}
							activityProvider={activityProvider}
							useStickyToolbar
						/>
					</StickyHeaderTrackerContainer>
				</IssueEditorBoundary>
			)}
		</Field>
	);
};
