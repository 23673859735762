import React from 'react';

import type { ToolbarUIComponentFactory } from '@atlaskit/editor-common/types';
import { WithPluginState } from '@atlaskit/editor-common/with-plugin-state';

import type { AIButtonPluginState } from '../pm-plugins/ai-button/ai-button-plugin';
import { aiButtonPluginKey } from '../pm-plugins/ai-button/ai-button-plugin-key';
import { aiSpellingGrammarPluginKey } from '../pm-plugins/ai-spelling-grammar/ai-spelling-grammar-plugin-key';
import { type AISpellingGrammarPluginState } from '../pm-plugins/ai-spelling-grammar/states';
import { createOpenAIModalCommand } from '../pm-plugins/decoration/actions';
import { type AIGlobalOptIn, type EditorPluginAIProvider } from '../types';
import { AtlassianIntelligenceToolbarButton } from '../ui/components/AtlassianIntelligenceToolbarButton/AtlassianIntelligenceToolbarButton';
import { SpellingAndGrammarToolbarButton } from '../ui/components/SpellingAndGrammarToolbarButton/SpellingAndGrammarToolbarButton';

export const getPrimaryToolbarLegacyComponent =
	({
		editorPluginAIProvider,
		aiGlobalOptIn,
		isProactiveAISupported,
	}: {
		editorPluginAIProvider: EditorPluginAIProvider;
		aiGlobalOptIn: AIGlobalOptIn;
		isProactiveAISupported: boolean;
	}): ToolbarUIComponentFactory =>
	({ editorView }) => {
		return (
			<WithPluginState
				plugins={{
					pluginState: aiButtonPluginKey,
					spellingAndGrammar: aiSpellingGrammarPluginKey,
				}}
				render={({ pluginState, spellingAndGrammar }) => {
					const aiButtonPluginState: AIButtonPluginState = pluginState;
					const onClick = () => {
						const { state, dispatch } = editorView;
						const configItem = state.selection.empty
							? editorPluginAIProvider.emptyConfig.baseGenerate
							: editorPluginAIProvider.rangeConfig.baseGenerate;
						const openAIModalCommand = createOpenAIModalCommand({
							state,
							configItem,
							lastTriggeredFrom: 'mainToolbar',
							aiGlobalOptIn,
							//TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
							triggeredFor: aiButtonPluginState.triggeredFor,
						});

						openAIModalCommand(state, dispatch);
					};

					return (
						<>
							{isProactiveAISupported && (
								<SpellingAndGrammarToolbarButton
									editorView={editorView}
									aiSpellingGrammarPluginState={
										spellingAndGrammar as AISpellingGrammarPluginState | undefined
									}
									ButtonWrapper={editorPluginAIProvider?.proactiveAIToolbarButtonWrapper}
								/>
							)}
							<AtlassianIntelligenceToolbarButton
								ButtonWrapper={editorPluginAIProvider?.AIButtonWrapper}
								onClick={onClick}
								disabled={!aiButtonPluginState?.isSelectionValidForAIExperience}
								view={editorView}
							/>
						</>
					);
				}}
			/>
		);
	};
