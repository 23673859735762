import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import type { Dispatch } from 'redux';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import Heading from '@atlaskit/heading';
import { B400, N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { ConnectIframe } from '@atlassian/connect-module-core';

import DefaultConnectIFrameProvider from '@atlassian/jira-common-ecosystem';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import type { ACJSHost } from '@atlassian/jira-connect';
import { EcosystemTimeoutViewAsync } from '@atlassian/jira-connect-utils-async';
import { withConnectHost } from '@atlassian/jira-connect-utils/src/common/utils/ecosystem-connect-host.tsx';
import { defineMessages, useIntl } from '@atlassian/jira-intl';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useOnDemandIssueRefreshTime } from '@atlassian/jira-issue-refresh-service/src/services/main.tsx';
import { ellipsis } from '@atlassian/jira-issue-view-common-styles/src/issue-value.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { removeTimestamp } from '@atlassian/jira-issue-view-common-utils/src/ecosystem/module-key-helper';
import { smoothScrollIntoCenterIfNeeded } from '@atlassian/jira-issue-view-common-utils/src/scroll/index.tsx';
import { SectionHeading } from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view';
import {
	useIsEcosystemDataLoadedFor,
	useEcosystemContentPanel,
} from '@atlassian/jira-issue-view-ecosystem-service/src/services/main.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import {
	getCollapsedPanels,
	setCollapsedPanel,
	unsetCollapsedPanel,
} from '@atlassian/jira-issue-view-services/src/issue/ecosystem-local-storage';
import {
	contentPanelUnselect,
	removeContentPanelRequest,
} from '@atlassian/jira-issue-view-store/src/actions/ecosystem-content-panel-actions';
import { selectedContentPanelSelector } from '@atlassian/jira-issue-view-store/src/ecosystem/ecosystem-extensions-selector';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { MeatBallMenu } from '../common/meatball-menu-view';

// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
const messages = defineMessages({
	actions: {
		id: 'issue.content.ecosystem-panels.meatballs.actions',
		defaultMessage: 'Actions',
		description: '',
	},
	hide: {
		id: 'issue.content.ecosystem-panels.meatballs.hide',
		defaultMessage: 'Hide {appName}',
		description: '',
	},
	collapse: {
		id: 'issue.content.ecosystem-panels.collapse-content',
		defaultMessage: 'hide',
		description:
			'Helper message indicating that when users click on this element, the content panel will be collapsed',
	},
	expand: {
		id: 'issue.content.ecosystem-panels.expand-content',
		defaultMessage: 'show',
		description:
			'Helper message indicating that when users click on this element, the content panel will be expanded',
	},
});

export type ContentPanel = {
	appKey: string;
	moduleKey: string;
	name: string;
	showByDefault: boolean;
	type: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	options: any;
};

type OwnProps = {
	name: string;
	appKey: string;
	moduleKey: string;
};

type StateProps = {
	isSelected?: boolean;
	refreshedOn?: number;
};

type DispatchProps = {
	// eslint-disable-next-line jira/react/handler-naming
	unSelectContentPanel?: (arg1: string) => void;
	// eslint-disable-next-line jira/react/handler-naming
	removeContentPanel?: (arg1: string) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const EMPTY_PANEL: ContentPanel = {
	appKey: '',
	moduleKey: '',
	name: '',
	showByDefault: false,
	type: '',
	options: {},
};

export const ConnectContentPanel = ({
	isSelected = false,
	unSelectContentPanel = noop,
	removeContentPanel = noop,
	name,
	appKey,
	moduleKey,
}: Props) => {
	const { formatMessage } = useIntl();
	const contentPanelContainer = useRef<HTMLDivElement | null>(null);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const refreshedOn = useOnDemandIssueRefreshTime();

	const contentPanel = useEcosystemContentPanel(appKey, moduleKey);

	const setupConnectHost = (host: ACJSHost) => {
		if (host) {
			host.onIframeUnload(({ extension }) => {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				host.destroy(extension.id!);
			});
		}
	};

	const [connectHost, setConnectHost] = useState(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		setupConnectHost(window.connectHost);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		return window.connectHost;
	});
	const [isCollapsed, setIsCollapsed] = useState(
		getCollapsedPanels().includes(removeTimestamp(`${appKey}_${moduleKey}`, 10)),
	);

	useEffect(() => {
		if (!connectHost) {
			withConnectHost((host) => {
				setupConnectHost(host);
				setConnectHost(host);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isSelected) {
			smoothScrollIntoCenterIfNeeded(contentPanelContainer.current);
			unSelectContentPanel(`${appKey}_${moduleKey}`);
		}
	}, [appKey, isSelected, moduleKey, unSelectContentPanel]);

	const handleRemoveContentPanel = useCallback(() => {
		removeContentPanel(`${appKey}_${moduleKey}`);
	}, [appKey, moduleKey, removeContentPanel]);

	const toggleCollapsedState = useCallback(() => {
		setIsCollapsed((collapsedVal) => {
			const contentPanelKey = removeTimestamp(`${appKey}_${moduleKey}`, 10);

			if (collapsedVal) {
				unsetCollapsedPanel(contentPanelKey);
			} else {
				setCollapsedPanel(contentPanelKey);
			}

			return !collapsedVal;
		});
	}, [appKey, moduleKey]);

	const meatBallMenu = useMemo(
		() => (
			<MeatBallMenu testId="issue-view-ecosystem.connect.meat-ball-menu">
				<DropdownItemGroup title={formatMessage(messages.actions)}>
					<DropdownItem
						onClick={handleRemoveContentPanel}
						testId="issue-view-ecosystem.connect.dropdown-item-hide"
					>
						{formatMessage(messages.hide, { appName: name })}
					</DropdownItem>
				</DropdownItemGroup>
			</MeatBallMenu>
		),
		[formatMessage, handleRemoveContentPanel, name],
	);

	const {
		options,
		options: { url, origin },
		showByDefault,
	} =
		// during issue transition "backwards" Redux store is restored "instantly" creating a possibility for panel data not being yet in sweet-state
		// we default to an "empty object" to prevent errors
		/// FIXME: use sweet-state for higher selection or bailout early in the parent component
		contentPanel || EMPTY_PANEL;

	const header = useMemo(() => {
		if (!showByDefault) {
			return (
				<SectionHeading>
					<LeftAligned data-component-selector={leftAlignedSelectorName}>
						<Heading size="xsmall" as="h2">
							<TitleH2>{name}</TitleH2>
						</Heading>
					</LeftAligned>
					<RightAligned>{meatBallMenu}</RightAligned>
				</SectionHeading>
			);
		}

		return (
			<SectionHeading>
				<LeftAligned
					data-component-selector={leftAlignedSelectorName}
					clickable
					onClick={toggleCollapsedState}
				>
					<Heading size="xsmall" as="h2">
						<TitleH2>{name}</TitleH2>
					</Heading>
					<Hint alwaysShow={isCollapsed}>
						{isCollapsed ? formatMessage(messages.expand) : formatMessage(messages.collapse)}
					</Hint>
				</LeftAligned>
				{showByDefault ? null : <RightAligned>{meatBallMenu}</RightAligned>}
			</SectionHeading>
		);
	}, [showByDefault, formatMessage, isCollapsed, meatBallMenu, name, toggleCollapsedState]);

	const connectIframeProvider = new DefaultConnectIFrameProvider({
		isAutoresizeBugfixEnabled: true,
	});

	// If url contains the JWT, valid the JWT before loading the Iframe
	const isJwtExpired =
		url && connectHost && connectHost.hasJwt?.(url) && connectHost.isJwtExpired?.(url);

	useEffect(() => {
		if (isJwtExpired) {
			fireTrackAnalytics(createAnalyticsEvent({}), 'ConnectIframe expired', {
				location: 'ContentPanel',
			});
		}
	}, [isJwtExpired, createAnalyticsEvent]);
	const issueId = useIssueId();
	const issueKey = useIssueKey();
	const hasCorrectIssueInformation = useIsEcosystemDataLoadedFor(issueKey);

	const connectKeyIssueId = `connect-iFrame-${origin}-${issueId}-${refreshedOn}`;
	const connectKeyIssueKey = `connect-iFrame-${origin}-${issueKey}-${refreshedOn}`;

	useEffect(() => {
		fireTrackAnalytics(createAnalyticsEvent({}), 'ConnectContentPanel loaded');
	}, [createAnalyticsEvent]);

	useEffect(() => {
		fireTrackAnalytics(createAnalyticsEvent({}), 'ConnectContentPanel updated');
	}, [connectKeyIssueKey, createAnalyticsEvent]);

	// there is a situation when during SPA transition:
	// - issueId is yet undefined
	// - contentPanel is different between states, including not yet being present
	const renderUnblocked =
		contentPanel && connectHost && Boolean(issueId) && hasCorrectIssueInformation;

	return (
		<div
			ref={contentPanelContainer}
			data-testid={`issue-view-ecosystem.connect.content-panel.${appKey}__${moduleKey}`}
		>
			{header}
			<Container isCollapsed={isCollapsed && showByDefault}>
				{renderUnblocked && (
					<ConnectIframe
						// @TODO JIV-17989 replace connectKeyIssueId with connectKeyIssueKey
						key={connectKeyIssueId}
						connectHost={connectHost}
						appKey={appKey}
						moduleKey={moduleKey}
						url={url}
						options={connectIframeProvider.convertConnectOptions(options)}
						connectIframeProvider={connectIframeProvider}
						width="100%"
						height="100%"
						// @ts-expect-error - TS2322 - Type '() => JSX.Element' is not assignable to type 'new () => PureComponent<{ failedCallback: Function; }, {}, any>'.
						timeoutIndicator={() => (
							<EcosystemTimeoutViewAsync appName={contentPanel.name} appKey={appKey} />
						)}
					/>
				)}
			</Container>
		</div>
	);
};

export default connect<StateProps, DispatchProps, OwnProps, State>(
	(state, ownProps): StateProps => ({
		isSelected: selectedContentPanelSelector(ownProps.appKey, ownProps.moduleKey)(state),
	}),
	(dispatch: Dispatch): DispatchProps => ({
		unSelectContentPanel: (addonModuleKey) => {
			dispatch(contentPanelUnselect(addonModuleKey));
		},
		removeContentPanel: (addonModuleKey) => {
			dispatch(removeContentPanelRequest(addonModuleKey));
		},
	}),
)(ConnectContentPanel);

const leftAlignedSelectorName = 'jira-issue-view-issue-base-ecosystem-left-aligned';
const LEFT_ALIGNED_COMPONENT_SELECTOR = `[data-component-selector="${leftAlignedSelectorName}"]`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Hint = styled.span<{ alwaysShow?: any }>({
	color: token('color.link', B400),
	marginLeft: token('space.075', '6px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: (props) => (props.alwaysShow ? 'inline' : 'none'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[`${LEFT_ALIGNED_COMPONENT_SELECTOR}:hover &`]: {
		display: 'inline',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ isCollapsed?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxHeight: (props) => (props.isCollapsed ? '0' : '1500px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	overflowY: (props) => (props.isCollapsed ? 'hidden' : 'auto'),
	overflowX: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&::-webkit-scrollbar': {
		width: '8px',
		background: 'transparent',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&::-webkit-scrollbar-thumb': {
		// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
		background: '#c1c1c1',
		borderRadius: '10px',
	},
	transition: 'max-height 1s ease-out',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleH2 = styled.span(
	{
		flex: 1,
		color: token('color.text', N800),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	ellipsis,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LeftAligned = styled.div<{ clickable?: boolean }>({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 4}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: (props) => (props.clickable ? 'pointer' : 'auto'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RightAligned = styled.div({
	alignItems: 'center',
	display: 'flex',
	flexFlow: 'row-reverse wrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > * + *': {
		marginLeft: token('space.100', '8px'),
	},
});
