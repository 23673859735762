import React from 'react';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import { AsyncMajorIncidentField } from '@atlassian/jira-major-incident/src/async.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ fieldId, area }: { fieldId: string; area: Area }) => {
	const issueKey = useIssueKey();

	return (
		<AsyncMajorIncidentField
			issueKey={issueKey}
			showPinButton={getShowPinButton(area)}
			fieldId={fieldId}
		/>
	);
};
