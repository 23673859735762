import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	moreFieldsText: {
		id: 'issue-view-layout-templates-views.context.visible-hidden.more-fields-text',
		defaultMessage: 'More fields',
		description: 'Show more fields in context',
	},
	pinnedFieldsTitle: {
		id: 'issue-view-layout-templates-views.context.visible-hidden.pinned-fields-title',
		defaultMessage: 'Your pinned fields',
		description: 'Show pinned fields in context',
	},
	pinnedFieldsTitleForPinnedFieldAwarenessExperiment: {
		id: 'issue-view-layout-templates-views.context.visible-hidden.pinned-fields-title-for-pinned-field-awareness-experiment',
		defaultMessage: 'My pinned fields',
		description: 'Show pinned fields in context',
	},
	detailsText: {
		id: 'issue-view-layout-templates-views.context.visible-hidden.details-text',
		defaultMessage: 'Details',
		description: 'Details Group in Context',
	},
	configureFieldsButtonLabel: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'issue-view-layout-templates-views.context.visible-hidden.configure-fields',
		defaultMessage: 'Configure fields',
		description: 'Configure fields in context',
	},
	createField: {
		id: 'issue-view-layout-templates-views.context.visible-hidden.create-field',
		defaultMessage: 'Create field',
		description: 'Create field button in dropdown menu that opens field configuration',
	},
	manageFields: {
		id: 'issue-view-layout-templates-views.context.visible-hidden.manage-fields',
		defaultMessage: 'Manage fields',
		description: 'Manage fields button in dropdown menu that opens field configuration',
	},
	categoryCheckValue: {
		id: 'issue-view-layout-templates-views.context.visible-hidden.category-check-value',
		defaultMessage: 'Category',
		description: 'Check if value is equals to category',
	},
});
