import { useCallback } from 'react';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { Invitees } from '../../common/types';
import { useCurrentProduct } from '../../utils';
import { getProjectAssignees } from './get-assignees';
import { getUsersWithoutProductAccess } from './permission-check';

export const useInvitees = (createIssueProjectKey?: string) => {
	const issueKey = useIssueKey();
	const currentProjectKey = useProjectKey(issueKey);
	const projectKey = createIssueProjectKey || currentProjectKey;
	const currentProduct = useCurrentProduct();
	const cloudId = useCloudId();

	return useCallback(
		(tenantId: string, destinationProduct: string, inviteesLimit = 10) =>
			getInvitees(tenantId, destinationProduct, currentProduct, cloudId, projectKey, inviteesLimit),
		[cloudId, currentProduct, projectKey],
	);
};

export const getInvitees = async (
	tenantId: string,
	destinationProduct: string,
	currentProduct: string,
	cloudId: string,
	projectKey: string,
	inviteesLimit = 10,
): Promise<Invitees> => {
	const isInvitingToCurrentProduct = tenantId === cloudId && destinationProduct === currentProduct;
	if (isInvitingToCurrentProduct) return [];

	const projectAssignees = await getProjectAssignees(projectKey);
	if (projectAssignees.length === 0) return [];

	const accountIds = projectAssignees.map(({ id }) => id);
	const [usersWithoutAccessToCurrentProduct, usersWithoutAccessToDestinationProduct] =
		await Promise.all([
			getUsersWithoutProductAccess(cloudId, currentProduct, accountIds),
			getUsersWithoutProductAccess(tenantId, destinationProduct, accountIds),
		]);
	const invitees = projectAssignees
		.filter(({ id }) => !usersWithoutAccessToCurrentProduct.includes(id))
		.filter(({ id }) => usersWithoutAccessToDestinationProduct.includes(id));

	return invitees.slice(0, inviteesLimit);
};
