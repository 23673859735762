/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { bindAll } from 'bind-event-listener';
import debounce from 'lodash/debounce';
import { useIntl } from 'react-intl-next';

import type { EditorAppearance } from '@atlaskit/editor-common/types';
import { type JSONDocNode } from '@atlaskit/editor-json-transformer';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';
import { token } from '@atlaskit/tokens';
import VisuallyHidden from '@atlaskit/visually-hidden';
import { RovoLogo } from '@atlassian/conversation-assistant-ui-components';
import { checkAssistanceServiceFreeGenerateFg } from '@atlassian/editor-ai-common/utils/check-assistance-service-fg';

import { type EditorAgent } from '../../../utils/agents';
import { AI_MODAL_SCREEN_CLASS } from '../../../utils/constants';
import { FloatingContainer } from '../../components/FloatingContainer/FloatingContainer';
import { scrollMarginStyles } from '../../components/FloatingContainer/styles';
import type { PromptEditor } from '../../components/PromptEditorWrapper/PromptEditorWrapper';
import { PromptForm } from '../../components/PromptForm/PromptForm';
import type {
	FocusInputMutableRefObject,
	FocusInputRefType as FocusInputRef,
} from '../../components/PromptForm/useSetInputRef';
import { RefinementTag } from '../../components/RefinementTag/RefinementTag';
import { Suggestions } from '../../components/Suggestions/Suggestions';
import type { Suggestion } from '../../components/Suggestions/types';
import { useAgentRefinementTagProps } from '../../hooks/use-agent-refinement-tag-props';
import { useElementBreakpoints } from '../../hooks/useElementBreakpoints';

import { getNextSuggestion } from './getNextSuggestion';
import messages from './messages';
import { reduceSuggestionsIntoNestedSuggestions } from './reduceSuggestionsIntoNestedSuggestions';
import {
	columnLayout,
	floatingContainerStyles,
	footerSectionStyles,
	footerTextStyles,
} from './styles';

export type { Suggestion } from '../../components/Suggestions/types';
export type {
	PromptEditor,
	PromptEditorProps,
} from '../../components/PromptEditorWrapper/PromptEditorWrapper';

export type FocusInputRefType = FocusInputRef;

/**
 * maxHeight for command palette (especially when there are lots of user input)
 * It is a reasonable number for tickets EDF-1452, EDF-1572, EDF-1566
 * If you update this value, please also update the maxHeight value in Suggestions.tsx and test file editor-plugin-ai/src/__tests__/playwright/suggestions/scrollbar.spec.ts
 */
const floatingContainerHeightStyles = css({
	maxHeight: '493px',
});

const PromptHeaderWrapperStyles = xcss({
	padding: 'space.150',
	// Use min-height to prevent the prompt header from growing out of the parent.
	minHeight: '0',
});

const promptPaddingStyles = xcss({
	padding: 'space.150',
});

type Props = {
	appearance: EditorAppearance;
	/**
	 * Label for the selected partial prompt.
	 */
	presetPromptLabel?: string;
	/**
	 * The current value of the user text input.
	 */
	inputValue: string;

	inputADFValue?: JSONDocNode;
	/**
	 * Updates the value of the user text input.
	 */
	onInputUpdate?: (value: string) => void;
	/**
	 * Updates the value of the user text input.
	 */
	onADFUpdate?: (value: JSONDocNode) => void;
	/**
	 * Submits the user input text and partial prompt (if any) to the AI.
	 */
	onSubmit: () => void;
	/**
	 * Handles the behaviour when the user clicks on the cancel button.
	 */
	onCancel: () => void;
	/**
	 * Handles the behaviour when the user presses the escape key.
	 */
	onEscapeKeyPressed: () => void;
	/**
	 * Handles when the user clicks the cross on the refinement tag.
	 */
	onRefinementTagRemove: () => void;
	/**
	 * Handles the behaviour when the user clicks the back button.
	 */
	onBack?: () => void;
	/**
	 * The placeholder text if there is no user text input
	 */
	placeholder: string;
	/**
	 * Retrieves the suggestions based on the user text input.
	 *
	 * Does not get called when the user has selected a partial prompt.
	 */
	getSuggestions: (inputValue: string, agent?: EditorAgent) => Suggestion[];
	/**
	 * Heading for the suggestion list
	 */
	suggestionsHeading?: string;
	/**
	 * Reference to the text input which we use to set focus
	 */
	focusInputRef: FocusInputMutableRefObject;
	/**
	 * Editor to be used as input in prompt.
	 */
	PromptEditor?: PromptEditor;
	/**
	 * The agent selected for this experience.
	 */
	agent?: EditorAgent;
	/**
	 * Whether to hide the legacy submit and cancel buttons.
	 */
	hideLegacySubmitCancelButtons?: boolean;
};

/**
 * This screen is used when the user is interacting with the AI command palette.
 *
 * ---
 *
 * If there is no preset prompt label, show the suggestions.
 * Otherwise, show the preset prompt label and no suggestions.
 */
export const UserInputCommandPalette = ({
	appearance,
	inputValue,
	inputADFValue,
	onInputUpdate,
	onADFUpdate,
	presetPromptLabel,
	onSubmit,
	onCancel,
	onEscapeKeyPressed,
	onRefinementTagRemove,
	onBack,
	placeholder,
	getSuggestions,
	suggestionsHeading,
	focusInputRef,
	PromptEditor,
	agent,
	hideLegacySubmitCancelButtons,
}: Props) => {
	const [setBreakpointsElement, { breakpoint }] = useElementBreakpoints();
	const promptWithSuggestionsRef = useRef<HTMLDivElement>(null);
	const { formatMessage } = useIntl();

	// There is a situation when using the arrow keys to navigate through the suggestions
	// where Prosemirror will move the caret to the end of the prompt input's placeholder
	// as the placeholder is done via a content-editable bit of text. This solution allows
	// the caret to not jump around while pressing 'down' while not having to edit the core
	// functionality of Prosemirror.
	const [isCaretHidden, setIsCaretHidden] = useState(false);

	const [isShowingMoreSuggestions, setIsShowingMoreSuggestions] = useState(false);

	/**
	 * Scroll prompt into view as suggestions are not always fully visible
	 * when PromptInput is autofocused.
	 *
	 * Note: We use useLayoutEffect here vs useEffect as it is synchronous and operates
	 * after the DOM has been updated with all mutations etc. This is important as
	 * Safari has a bug where it can not correctly calculate scroll positions and glitches
	 * out causing all content to disappear without it.
	 *
	 * Performance implications should be minimal as we are not affecting the rendering
	 * of content, but rather using it for calculations and triggering scroll events.
	 *
	 * https://kentcdodds.com/blog/useeffect-vs-uselayouteffect
	 *
	 * ᕕ( ᐛ )ᕗ
	 */
	useLayoutEffect(() => {
		// Add event listener to handle escape key press
		const { current: element } = promptWithSuggestionsRef;

		// If the element is not mounted, do nothing
		if (!element) {
			return;
		}

		element.scrollIntoView({ block: 'nearest', behavior: 'auto' });

		/**
		 * KeyDown (as opposed to KeyPress) event is used to handle the escape key so that
		 * other escape key handlers will not override this behaviour.
		 * Additionally this aligns with other escape listeners e.g. for closing typeahead popup.
		 */
		const handleEscapeKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				event.preventDefault();
				event.stopPropagation();
				onEscapeKeyPressed();
			}
		};

		/**
		 * Handle KeyUp event to prevent unexpected escape behaviours when
		 * there are other escape handlers outside of component (e.g. in product)
		 */
		const handleEscapeKeyUp = (event: KeyboardEvent) => {
			if (event.key !== 'Escape') {
				return;
			}
			event.preventDefault();
			event.stopPropagation();
		};

		return bindAll(element, [
			{
				type: 'keydown',
				listener: handleEscapeKeyDown,
			},
			{
				type: 'keyup',
				listener: handleEscapeKeyUp,
			},
		]);
	}, [promptWithSuggestionsRef, onEscapeKeyPressed]);

	let suggestions: Suggestion[] = useMemo(() => {
		/**
		 * If there is an agent, show the suggestions for that agent.
		 * The suggestions in this scenario are the agents conversation starters.
		 * And we also want to hide the group heading in this case.
		 */
		if (agent) {
			return getSuggestions(inputValue, agent);
		}

		/*
		 * If there is no refinement tag, show the suggestions.
		 * Otherwise, show the refinement tag and no suggestions.
		 */
		let nestedSuggestions = getSuggestions(inputValue) ?? [];

		// If no input provided, provide the grouped results, excluding children
		if (
			!inputValue.length &&
			editorExperiment('platform_editor_ai_command_palate_improvement', 'test')
		) {
			nestedSuggestions = nestedSuggestions.reduce(reduceSuggestionsIntoNestedSuggestions, []);

			// If a prompt label exists, as well as children, show the children
			// otherwise use the default behaviour of showing all matching options.
			if (presetPromptLabel) {
				nestedSuggestions =
					nestedSuggestions.find((s) => s.nestingConfig?.parentTitle === presetPromptLabel)
						?.childSuggestions ?? [];
			}
		}

		if (editorExperiment('platform_editor_ai_command_palate_improvement', 'test')) {
			const showMoreSuggestionIndex = nestedSuggestions.findIndex(
				(suggestion) => suggestion.value === 'show-more',
			);

			if (showMoreSuggestionIndex !== -1) {
				const originalOnSelect = nestedSuggestions[showMoreSuggestionIndex].onSelect;
				nestedSuggestions[showMoreSuggestionIndex].onSelect = () => {
					originalOnSelect();
					setIsShowingMoreSuggestions(true);
				};
			}
		}

		return nestedSuggestions;
	}, [presetPromptLabel, getSuggestions, inputValue, agent]);

	const hideNestedTag = useMemo(() => {
		if (editorExperiment('platform_editor_ai_command_palette_post_ga', 'test')) {
			return presetPromptLabel && !!suggestions.length;
		}

		return false;
	}, [suggestions, presetPromptLabel]);

	const agentRefinementTagProps = useAgentRefinementTagProps(agent);
	const refinementTagRef = useRef<HTMLDivElement>(null);

	const tag: React.ReactNode | undefined = useMemo(() => {
		return presetPromptLabel === undefined ? undefined : (
			<RefinementTag
				{...agentRefinementTagProps}
				label={presetPromptLabel}
				ref={refinementTagRef}
				onClick={onRefinementTagRemove}
			/>
		);
	}, [agentRefinementTagProps, presetPromptLabel, onRefinementTagRemove, refinementTagRef]);

	const suggestionValues = useMemo(() => {
		return (suggestions || []).map((suggestion) => suggestion.value);
	}, [suggestions]);
	const [currentSuggestion, _setCurrentSuggestion] = useState<string>(
		suggestionValues.length > 0 ? suggestionValues[0] : '',
	);

	/**
	 * Handles the behaviour for when a user changes the suggestion.
	 *
	 * At the moment this is used to highlight the entire editor document for
	 * relevant suggestions.
	 */
	const handleSuggestionChanged = useCallback(
		(suggestion: string) => {
			const suggestionObject = suggestions.find((s) => s.value === suggestion);
			suggestionObject?.onSuggestionFocused();
		},
		[suggestions],
	);

	const setCurrentSuggestion = useCallback(
		(newValue: string) => {
			handleSuggestionChanged(newValue);
			_setCurrentSuggestion(newValue);
		},
		[_setCurrentSuggestion, handleSuggestionChanged],
	);

	// Select first option by default
	useEffect(() => {
		if (suggestionValues.includes(currentSuggestion) || suggestionValues.length === 0) {
			return;
		}

		setCurrentSuggestion(suggestionValues[0]);
	}, [currentSuggestion, suggestionValues, setCurrentSuggestion]);

	// Calculate visible suggestions list
	const visibleSuggestionValues = useMemo(() => {
		const allSuggestions = suggestionValues.filter((suggestion) => suggestion !== 'show-more');

		if (!editorExperiment('platform_editor_ai_command_palate_improvement', 'test')) {
			return allSuggestions;
		}

		if (isShowingMoreSuggestions || inputValue.length > 0) {
			return allSuggestions;
		}

		const agentSuggestions = suggestionValues.filter((suggestion) =>
			suggestion.startsWith('agent:'),
		);

		const minNumOfGeneralSuggestions = 4;
		const maximumResultCount = 9;

		if (
			agentSuggestions.length === 0 ||
			allSuggestions.length <= maximumResultCount ||
			// If we only have the min. number of suggestions,
			// we return the full list because we don't need to show the show more button
			allSuggestions.length - agentSuggestions.length <= minNumOfGeneralSuggestions
		) {
			return allSuggestions;
		}

		// The maximum number of suggestions is 8 when agents are present
		// We take the extra 1 so that we can put the show more button in place
		// of the last expected suggestion.
		let remainingSuggestions = maximumResultCount - agentSuggestions.length - 1;

		// If the user favourites enough agents, the resulting count will be negative and
		// force the entire list to show. We want to encourage it to limit the slicing to 1
		// item in this case.
		if (remainingSuggestions < minNumOfGeneralSuggestions) {
			remainingSuggestions = minNumOfGeneralSuggestions;
		}

		return [...suggestionValues.slice(0, remainingSuggestions), 'show-more', ...agentSuggestions];
	}, [inputValue.length, isShowingMoreSuggestions, suggestionValues]);

	const visibleSuggestions = useMemo(() => {
		const output = suggestions.filter((suggestion) =>
			visibleSuggestionValues.includes(suggestion.value),
		);

		return output;
	}, [suggestions, visibleSuggestionValues]);

	const onArrowKeyDown = useCallback(
		(event: React.KeyboardEvent): void => {
			// Ignore if any key modifiers are held
			if (event.metaKey || event.ctrlKey || event.altKey || event.shiftKey) {
				return;
			}

			// If theres one or fewer suggestions, no need to navigate and allow normal
			// multiline text traversal
			if (visibleSuggestionValues.length <= 1) {
				return;
			}

			switch (event.key) {
				case 'ArrowUp':
				case 'ArrowDown': {
					setIsCaretHidden(true);

					const nextSuggestion = getNextSuggestion(
						visibleSuggestionValues,
						currentSuggestion,
						event.key,
					);
					setCurrentSuggestion(nextSuggestion);

					event.preventDefault();
					event.stopPropagation();
					break;
				}
				default: {
					return;
				}
			}
		},
		[currentSuggestion, setCurrentSuggestion, visibleSuggestionValues],
	);

	const debouncedSetIsCaretHidden = useMemo(
		() =>
			debounce(() => {
				setIsCaretHidden(false);
			}, 300),
		[],
	);

	const onArrowKeyUp = useCallback(() => {
		debouncedSetIsCaretHidden();
	}, [debouncedSetIsCaretHidden]);

	const setSuggestion = useCallback(
		(suggestion: Suggestion): void => {
			setCurrentSuggestion(suggestion.value);
		},
		[setCurrentSuggestion],
	);

	const suggestionDiv = (
		<Suggestions
			appearance={appearance}
			suggestions={visibleSuggestions}
			suggestionsHeading={suggestionsHeading}
			value={currentSuggestion}
			setSuggestion={setSuggestion}
			hideGroupHeading={!!agent}
		/>
	);

	const onFormSubmit = useCallback(() => {
		if (currentSuggestion === '' || suggestions?.length === 0) {
			onSubmit();
			return;
		}

		const suggestion = (suggestions || []).find(
			(suggestion) => suggestion.value === currentSuggestion,
		);

		if (suggestion) {
			suggestion.onSelect();
		}

		setCurrentSuggestion('');
	}, [currentSuggestion, suggestions, setCurrentSuggestion, onSubmit]);

	// if a refinement tag like brainstorm was selected, we continue showing these legacy buttons
	const showLegacySubmitCancelButtons =
		!hideLegacySubmitCancelButtons && Boolean(presetPromptLabel && !agent);

	const enableLinksInPromptEditor = checkAssistanceServiceFreeGenerateFg();
	const promptForm = (
		<PromptForm
			showButtons={showLegacySubmitCancelButtons}
			placeholder={agent ? formatMessage(messages.rovoAgentsPalettePlaceholder) : placeholder}
			value={inputValue}
			adfValue={inputADFValue}
			onFormSubmit={onFormSubmit}
			onCancel={onCancel}
			onInputChange={onInputUpdate}
			onADFChange={onADFUpdate}
			tag={hideNestedTag ? undefined : tag}
			focusInputRef={focusInputRef}
			showLogo={false}
			onInputKeyDown={onArrowKeyDown}
			onInputKeyUp={onArrowKeyUp}
			PromptEditor={PromptEditor}
			enableLinks={enableLinksInPromptEditor}
			showBack={!!presetPromptLabel}
			onBack={onBack}
			refinementTagRef={refinementTagRef}
		/>
	);

	return (
		// Need div for css composition
		// https://atlassian.design/components/eslint-plugin-design-system/usage#prefer-primitives
		// eslint-disable-next-line
		<div
			ref={promptWithSuggestionsRef}
			// TODO: assert this remains stable
			data-testid="user-input-command-palette-screen"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={`user-input-command-palette-screen ${AI_MODAL_SCREEN_CLASS}`}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			css={[columnLayout, scrollMarginStyles, isCaretHidden && { caretColor: 'transparent' }]}
		>
			<VisuallyHidden>{formatMessage(messages.commandPaletteAriaName)}</VisuallyHidden>
			<FloatingContainer
				ref={setBreakpointsElement}
				css={[
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					floatingContainerStyles,
					editorExperiment('platform_editor_ai_command_palette_post_ga', 'test') &&
						floatingContainerHeightStyles,
				]}
				data-testid="prompt-form-screen"
				rainbowBorder={!editorExperiment('platform_editor_ai_command_palette_post_ga', 'test')}
			>
				{editorExperiment('platform_editor_ai_command_palette_post_ga', 'test') ? (
					<Flex testId="prompt-header-wrapper" xcss={PromptHeaderWrapperStyles}>
						{promptForm}
					</Flex>
				) : (
					<Box testId="prompt-header-wrapper" xcss={promptPaddingStyles}>
						{promptForm}
					</Box>
				)}
				{suggestions || inputValue ? suggestionDiv : null}
				{agent && !editorExperiment('platform_editor_ai_command_palette_post_ga', 'test') && (
					/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */
					<div data-testid="command-palette-footer" css={footerSectionStyles}>
						<RovoLogo
							label={formatMessage(messages.rovoProductLogoAltText)}
							primaryColor={token('color.icon.subtle')}
							size="large"
							testId="rovo-product-logo"
						/>

						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
						<div data-testid="footer-brand-text" css={footerTextStyles(breakpoint)}>
							{formatMessage(messages.rovoProductLabel)}
						</div>
					</div>
				)}
			</FloatingContainer>
		</div>
	);
};
