import find from 'lodash/find';
import { ff } from '@atlassian/jira-feature-flagging';
import { associatedIssueErrorTypes as errorTypes } from '@atlassian/jira-issue-shared-types/src/common/types/associated-issue-type.tsx';
import {
	SET_ENTITIES,
	UPDATE_ISSUE,
	CREATE_CHILD_REQUEST,
	CREATE_CHILD_SUCCESS,
	CREATE_CHILD_FAILURE,
	LINK_PARENT_FAILURE,
	CREATE_CHILD_RETRY,
	CANCEL_OPTIMISTIC_CHILD,
	FETCH_SINGLE_CHILD_SUCCESS,
	FETCH_GRAPHQL_ISSUE_SUCCESS,
	FETCH_DETAILS_FOR_ISSUES_SUCCESS,
	REORDER_CHILDREN_REQUEST,
	REORDER_CHILDREN_SUCCESS,
	REORDER_CHILDREN_FAILED,
	LINKING_ISSUE_FAILURE,
	LINKING_ISSUE_RETRY,
	LINKING_ISSUE_RETRY_FAILURE,
	ADD_EXISTING_CHILD_ISSUE_REQUEST,
	type SetEntitiesAction,
	type UpdateIssueAction,
	type CreateChildRequestAction,
	type CreateChildSuccessAction,
	type CreateChildFailureAction,
	type LinkParentFailureAction,
	type CreateChildRetryAction,
	type CancelOptimisticChildAction,
	type FetchSingleChildSuccessAction,
	type FetchGraphqlIssueSuccessAction,
	type FetchDetailsForIssuesSuccessAction,
	type ReorderChildrenRequestAction,
	type ReorderChildrenSuccessAction,
	type ReorderChildrenFailedAction,
	type LinkingIssueFailureAction,
	type LinkingIssueRetryAction,
	type LinkingIssueRetryFailureAction,
	type AddExistingChildIssueRequestAction,
} from './actions';
import type { EntitiesState } from './types';

type Action =
	| SetEntitiesAction
	| UpdateIssueAction
	| CreateChildRequestAction
	| CreateChildSuccessAction
	| FetchSingleChildSuccessAction
	| FetchGraphqlIssueSuccessAction
	| CreateChildFailureAction
	| LinkParentFailureAction
	| CancelOptimisticChildAction
	| CreateChildRetryAction
	| FetchDetailsForIssuesSuccessAction
	| ReorderChildrenRequestAction
	| ReorderChildrenSuccessAction
	| ReorderChildrenFailedAction
	| LinkingIssueFailureAction
	| LinkingIssueRetryAction
	| LinkingIssueRetryFailureAction
	| AddExistingChildIssueRequestAction;

const defaultState = {
	issues: [],
	issueTypes: [],
	issueHierarchyLevel: undefined,
	recentlyCreatedIssue: undefined,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: EntitiesState = defaultState, action: Action): EntitiesState => {
	switch (action.type) {
		case SET_ENTITIES: {
			const { issueTypes, issues, issueHierarchyLevel } = action.payload;
			return {
				...state,
				issues,
				issueTypes,
				issueHierarchyLevel,
			};
		}
		case UPDATE_ISSUE: {
			return state;
		}
		case CREATE_CHILD_REQUEST: {
			const optimisticChild = {
				...action.payload.optimisticUiChildIssue,
				isLoading: true,
				errorType: undefined,
			};
			return {
				...state,
				issues: state.issues
					.filter((issue) => issue.id !== optimisticChild.id)
					.concat(optimisticChild),
			};
		}
		case CREATE_CHILD_SUCCESS: {
			const { optimisticId, createdChild } = action.payload;
			return ff('configurable-child-issues-panel_3ht06')
				? {
						...state,
						recentlyCreatedIssue: {
							issueId: createdChild.id || createdChild.issueId || '',
							issueKey: createdChild.issueKey || '',
						},
						issues: state.issues.filter((issue) => issue.id !== optimisticId).concat(createdChild),
					}
				: {
						...state,
						issues: state.issues.filter((issue) => issue.id !== optimisticId).concat(createdChild),
					};
		}
		case FETCH_SINGLE_CHILD_SUCCESS: {
			const { childIssue } = action.payload;
			return {
				...state,
				issues: state.issues.map((issue) => (issue.id === childIssue.id ? childIssue : issue)),
			};
		}
		case FETCH_GRAPHQL_ISSUE_SUCCESS: {
			const { childIssue } = action.payload;
			return {
				...state,
				issues: state.issues.map((issue) => (issue.id === childIssue.id ? childIssue : issue)),
			};
		}
		case CREATE_CHILD_FAILURE: {
			const { optimisticId, error } = action.payload;
			return {
				...state,
				issues: state.issues.map((issue) => {
					if (issue.id === optimisticId) {
						return {
							...issue,
							error,
							errorType: errorTypes.createChild,
							isLoading: false,
						};
					}
					return issue;
				}),
			};
		}

		case LINKING_ISSUE_FAILURE: {
			const { optimisticId, createdChild } = action.payload;
			return {
				...state,
				issues: state.issues
					.filter((issue) => issue.id !== optimisticId)
					.concat({
						...createdChild,
						errorType: errorTypes.linkChild,
						isLoading: false,
					}),
			};
		}
		case CREATE_CHILD_RETRY: {
			const { optimisticId } = action.payload;
			return {
				...state,
				issues: state.issues.map((issue) => {
					if (issue.id === optimisticId) {
						return {
							...issue,
							errorType: undefined,
							isLoading: true,
						};
					}
					return issue;
				}),
			};
		}
		case LINKING_ISSUE_RETRY: {
			const { issueId } = action.payload;
			return {
				...state,
				issues: state.issues.map((issue) => {
					if (issue.id === issueId) {
						return {
							...issue,
							errorType: undefined,
							isLoading: true,
						};
					}
					return issue;
				}),
			};
		}
		case LINKING_ISSUE_RETRY_FAILURE: {
			const { issueId } = action.payload;
			return {
				...state,
				issues: state.issues.map((issue) => {
					if (issue.id === issueId) {
						return {
							...issue,
							errorType: errorTypes.linkChild,
							isLoading: false,
						};
					}
					return issue;
				}),
			};
		}
		// For LINK_PARENT_FAILURE: Can't easily offer retry to link,
		// so remove child row from issues list (will pop up a flag instead)
		case LINK_PARENT_FAILURE:
		case CANCEL_OPTIMISTIC_CHILD: {
			const { optimisticId } = action.payload;
			return {
				...state,
				issues: state.issues.filter((issue) => issue.id !== optimisticId),
			};
		}
		case FETCH_DETAILS_FOR_ISSUES_SUCCESS: {
			const { issues } = action.payload;
			return {
				...state,
				issues: state.issues.map((issue) => ({
					...issue,
					...find(issues, (issueWithDetails) => issueWithDetails.id === issue.id),
				})),
			};
		}
		case REORDER_CHILDREN_REQUEST:
		case REORDER_CHILDREN_SUCCESS: {
			const { newOrder } = action.payload;
			return {
				...state,
				issues: newOrder,
			};
		}
		case REORDER_CHILDREN_FAILED: {
			const { originalOrder } = action.payload;
			return {
				...state,
				issues: originalOrder,
			};
		}
		case ADD_EXISTING_CHILD_ISSUE_REQUEST: {
			const child = {
				id: action.payload.issueId,
				issueKey: action.payload.issueKey,
				issueSummary: action.payload.issueSummary,
				issueTypeIconUrl: action.payload.issueTypeIconUrl,
				assigneeUrl: null,
				assigneeDisplayName: null,
				isLoading: true,
				errorType: undefined,
			};
			return {
				...state,
				// The intention is to show the new added issue at the bottom of the list if it's already a child of the current issue.
				issues: state.issues.filter((issue) => issue.id !== child.id).concat(child),
			};
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
