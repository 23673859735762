import React, { useEffect, useState, useCallback } from 'react';
import {
	getMobileSectionMsgCache,
	setMobileSectionMsgCache,
} from '@atlassian/jira-issue-view-activity-comment/src/comment-add/utils';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import SectionMessageComponent from './view';

export const SectionMessage = () => {
	const [showMessage, setShowMessage] = useState(false);

	const cloudId = useCloudId();
	const getMobileMessageKeyFromStorage = getMobileSectionMsgCache(cloudId);
	const { registerBlock } = useRouterActions();

	useEffect(() => {
		setShowMessage(getMobileMessageKeyFromStorage === 'false');
	}, [getMobileMessageKeyFromStorage]);

	useEffect(() => {
		const unregisterBlock = registerBlock(setToMobileSectionMsgCache);
		return unregisterBlock;
	});

	const setToMobileSectionMsgCache = useCallback(() => {
		if (showMessage) {
			setMobileSectionMsgCache(cloudId, JSON.stringify(true));
			setShowMessage(false);
		}
	}, [cloudId, showMessage]);

	return showMessage ? <SectionMessageComponent /> : null;
};
