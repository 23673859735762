import { ff } from '@atlassian/jira-feature-flagging';

// <mklus>
// </mklus>

// <ghostbusters-team>
// </ghostbusters-team>

// <flux-team>
// </flux-team>

// <perFORMers>
export const isProformaHarmonisationFFEnabled = () =>
	ff('proforma-harmonisation-main_ca6ff', false);
// <perFORMers>
