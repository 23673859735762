import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import fetchHasValidatorsByIssueType from '../../rest/fetch-has-validators-by-issue-type';
import {
	requiresFullCreateCheckByIssueTypeSuccess,
	type RequiresFullCreateCheckSuccessAction,
	type RequiresFullCreateCheckFailedAction,
	requiresFullCreateCheckFailed,
} from '../../state/context/actions';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	projectId: ProjectId,
): Observable<RequiresFullCreateCheckSuccessAction | RequiresFullCreateCheckFailedAction> =>
	Observable.from(fetchHasValidatorsByIssueType(projectId))
		.map((result) => requiresFullCreateCheckByIssueTypeSuccess(result))
		.catch((error) => {
			trackOrLogClientError(
				'issue.views.common.child-issues-panel.has-validators-on-issue-types',
				'Failed to check if project has validators by issue type',
				error,
			);
			return Observable.of(requiresFullCreateCheckFailed());
		});
