import { createSelector } from 'reselect';
import {
	isServiceDeskSelector,
	isAnonymousSelector,
} from '../common/state/selectors/context-selector';
import { isEpicSelector } from '../common/state/selectors/issue-selector';
import { supportsChildCreationSelector } from '../issue-field/state/selectors/hierarchy-level-selector';
import { canCreateAttachmentsSelector } from './attachment-selector';
import { canCreateIssuesInEpicSelector } from './classic-project-epic-selector';
import { canLinkConfluencePageSelector } from './confluence-app-links-selector';
import { canEditIssuesSelector } from './issue-actions-permissions-selector';
import { canAddIssueLinksSelector } from './issue-links-type-selector';
import { canCreateSubtaskSelector } from './subtasks-selector';
import { canAddWebLinksSelector } from './web-links-selector';

export const quickAddItemsPermissionsSelector = createSelector(
	canCreateSubtaskSelector,
	canCreateIssuesInEpicSelector,
	supportsChildCreationSelector,
	isEpicSelector,
	canCreateAttachmentsSelector,
	canAddIssueLinksSelector,
	isServiceDeskSelector,
	isAnonymousSelector,
	canLinkConfluencePageSelector,
	canAddWebLinksSelector,
	canEditIssuesSelector,
	(
		canCreateSubtask,
		canCreateIssuesInEpic,
		supportsChildCreation,
		isEpic,
		canCreateAttachment,
		canCreateIssueLink,
		isServiceDesk,
		isAnonymous,
		canLinkConfluencePage,
		canAddWebLinks,
		canEditIssues,
	) => {
		const shouldShowIssueInEpicButton = canCreateIssuesInEpic && !supportsChildCreation && isEpic;

		const shouldShowCreateSubtaskButton =
			canCreateSubtask && !supportsChildCreation && !shouldShowIssueInEpicButton;

		return {
			canCreateAttachment,
			shouldShowCreateSubtaskButton,
			shouldShowIssueInEpicButton,
			supportsChildCreation,
			canCreateIssueLink,
			isServiceDesk,
			isAnonymousUser: isAnonymous,
			canLinkConfluencePage,
			canAddWebLinks,
			canEditIssues,
		};
	},
);
