// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyStateHeader: {
		id: 'issue.context.tab-panel.empty-state.header',
		defaultMessage: 'No fields to show',
		description:
			'The error message displayed when a user opens a field tab and no fields are present',
	},
	emptyStateMessage: {
		id: 'issue.context.tab-panel.empty-state.message',
		defaultMessage:
			'If you expect to see fields here, ask your Jira admin to check permissions and field configuration.',
		description:
			'The detailed message displayed when a user opens a field tab and no fields are present along with a call to action',
	},
	emptyStateMessageAdmin: {
		id: 'issue.context.tab-panel.empty-state.message.admin',
		defaultMessage:
			'If you expect to see fields here, check your permission and field configuration settings.',
		description:
			'The detailed message displayed when a project admin opens a field tab and no fields are present along with a call to action',
	},
});
