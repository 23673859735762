import React, { useCallback, useContext, useState } from 'react';
import type { Dispatch } from 'redux';
import { useRelayEnvironment } from 'react-relay';
import Button from '@atlaskit/button';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import Popup from '@atlaskit/popup';
import { Box, Flex, xcss, Text, Inline } from '@atlaskit/primitives';
import AkToolTip from '@atlaskit/tooltip';
import { eventHub, findEditorId } from '@atlassian/editor-plugin-ai/EventHub';
import {
	useIssueBreakdown,
	steps,
	IssueBreakdownAiContainer,
} from '@atlassian/jira-ai-work-breakdown/src/controllers/context';
import { AiIssueBreakdownEntryPointContext } from '@atlassian/jira-ai-work-breakdown/src/controllers/context-provider';
import { AiIcon } from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-icon';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger';
import type { EntryPointActions } from '@atlassian/jira-entry-point/src/controllers/utils/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useAiMateProductType } from '@atlassian/jira-issue-hooks/src/services/use-ai-mate-product-type';
import { useActions as useSmartSummaryActions } from '@atlassian/jira-issue-smart-request-summary-state/src/controllers/state/index.tsx';
import { SummaryStateContainer } from '@atlassian/jira-issue-smart-request-summary-state/src/ui/index.tsx';
import type { Action } from '@atlassian/jira-issue-view-actions';
import { ISSUE_DESCRIPTION_EDITOR_ID } from '@atlassian/jira-issue-view-common-constants';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { useChildPanelRenderTrigger } from '@atlassian/jira-issue-view-common-views/src/child-issues-panel/hooks/child-panel-render-trigger';
import {
	CLASSIC_SUBTASKS,
	CHILDREN_ISSUES_PANEL,
	CLASSIC_PROJECT_EPIC_CHILDREN,
} from '@atlassian/jira-issue-view-common-views/src/child-issues-panel/model/types';
import { IssueSmartRequestSummaryEntryPointContext } from '@atlassian/jira-issue-view-smart-request-summary-entrypoint';
import { linkConfluencePageClickedWithAISuggestions } from '@atlassian/jira-issue-view-store/src/actions/confluence-pages-actions';
import { improveIssueDropdownPermissionsSelector } from '@atlassian/jira-issue-view-store/src/selectors/improve-issue-dropdown-selector';
import {
	useProjectKey,
	useEdition,
	useApplication,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { connect } from '@atlassian/jira-react-redux';
import { JIRA_CORE, JIRA_SOFTWARE } from '@atlassian/jira-shared-types/src/application.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { getAiActions } from './get-ai-actions';
import { messages } from './messages';
import { useImproveIssueStore } from './store';
import type { AiActionReturn, Props, ChildPanel } from './types';

/*
	Escape hatch for testing purpose
*/
export const defaultPopupState = () => false;

export const ItemView = (itemToRender: AiActionReturn) => {
	const { id, label, icon, onClick, testId, ref, shouldRender, disabledMessage } = itemToRender;
	const [isPopupOpen, setIsPopupOpen] = useState(defaultPopupState());

	return shouldRender ? (
		<DropdownItem key={id} onClick={onClick} ref={ref} testId={testId} elemBefore={icon}>
			{label}
		</DropdownItem>
	) : (
		<Popup
			key={id}
			isOpen={isPopupOpen}
			onClose={() => setIsPopupOpen(false)}
			placement="right-start"
			content={() => <Box xcss={popupContentStyles}>{disabledMessage ?? ''}</Box>}
			trigger={(triggerProps) => (
				<DropdownItem
					{...triggerProps}
					onClick={() => setIsPopupOpen(!isPopupOpen)}
					testId={testId}
					elemBefore={icon}
				>
					{label}
				</DropdownItem>
			)}
		/>
	);
};

export const useAiIssueBreakdownEntryPointActions = (): EntryPointActions =>
	useContext(AiIssueBreakdownEntryPointContext).entryPointActions;

export const useAiSmartSummaryEntryPointActions = (): EntryPointActions =>
	useContext(IssueSmartRequestSummaryEntryPointContext).entryPointActions;

const editorSelector = `[data-editor-container-id="${ISSUE_DESCRIPTION_EDITOR_ID}"]`;

export const triggerEditorStreamHubEvent = () => {
	const targetEditorId = findEditorId(editorSelector);
	if (targetEditorId) {
		eventHub.publishToEditor({
			event: 'start prompt',
			data: {
				prompt: 'issue reformatter',
				targetEditorId,
				analyticSourceId: 'jiraIssueReformatterExternalButton',
			},
		});
		// Indicates that the event was published successfully
		return true;
	}
	// Indicates that the event couldn't be published
	return false;
};

export const ImproveIssueQuickAddItem = ({
	supportsChildCreation,
	shouldShowCreateSubtaskButton,
	shouldShowIssueInEpicButton,
	onLinkConfluencePageClickWithAISuggestions,
	scope,
	visibleCommentIds,
	hasIssueDescription,
	canLinkConfluencePage,
	shouldBeCompact,
}: Props) => {
	const { formatMessage } = useIntl();

	const [open, setOpen] = useState(false);

	const cloudId = useCloudId();
	const environment = useRelayEnvironment();
	const issueKey = useIssueKey();
	const aiMateXProduct = useAiMateProductType();
	const projectKey = useProjectKey(issueKey);
	const application = useApplication(projectKey, true);
	const edition = useEdition(projectKey, true);
	const [{ canEditIssues }] = useProjectPermissions(projectKey);
	const [{ onEditAction }, { setOnEditorReady, resetOnEditorReady }] = useImproveIssueStore();

	const toggleMenuOpen = useCallback(({ isOpen }: { isOpen: boolean }) => {
		setOpen(isOpen);
	}, []);

	const [, { setIssueBreakdownStep, updateStreamingStatus }] = useIssueBreakdown();
	const [_, { setWhichChildPanelRenderTriggered }] = useChildPanelRenderTrigger();

	const { loadSummary } = useSmartSummaryActions();

	const issueBreakdownEntryPointActions = useAiIssueBreakdownEntryPointActions();

	const smartSummaryEntrypointActions = useAiSmartSummaryEntryPointActions();

	const issueBreakdownRef = useEntryPointButtonTrigger(issueBreakdownEntryPointActions);

	const smartSummaryRef = useEntryPointButtonTrigger(smartSummaryEntrypointActions);

	const onIssueBreakdownClick = useCallback(() => {
		setWhichChildPanelRenderTriggered(scope);
		setIssueBreakdownStep(steps.draftListStep);
		updateStreamingStatus(true);
	}, [scope, setWhichChildPanelRenderTriggered, setIssueBreakdownStep, updateStreamingStatus]);

	const onSmartSummaryClick = useCallback(() => {
		loadSummary(cloudId, environment, issueKey, aiMateXProduct, application, edition);
	}, [loadSummary, cloudId, environment, issueKey, aiMateXProduct, application, edition]);

	const onImproveDescriptionClick = useCallback(() => {
		const isPublished = triggerEditorStreamHubEvent();
		if (!isPublished) {
			// Event isn't published. The editor wasn't ready. Hence we schedule an event instead
			setOnEditorReady(() => {
				triggerEditorStreamHubEvent();
				// Reset the onEditorReady callback to ensure it's not called again
				resetOnEditorReady();
			});
		}
		onEditAction?.();
	}, [onEditAction, setOnEditorReady, resetOnEditorReady]);

	/**
	 * The following checks are used to determine conditions of the AI actions
	 */
	const isIssueBreakdownEnabled =
		supportsChildCreation || shouldShowCreateSubtaskButton || shouldShowIssueInEpicButton;

	const isSmartSummaryEnabled = Number(visibleCommentIds?.length) > 0;

	/**
	 * Business decision to only enable for JSW and not JWM
	 */
	const isImproveDescriptionEnabled =
		hasIssueDescription && canEditIssues && application === JIRA_SOFTWARE;

	const isRelatedConfluencePagesEnabled =
		canLinkConfluencePage && fg('jira_ai_powered_issue_related_confluence_resources');

	const aiActions = getAiActions({
		application,
		formatMessage,
		issueBreakdownRef,
		onIssueBreakdownClick,
		isIssueBreakdownEnabled,
		onSmartSummaryClick,
		smartSummaryRef,
		onLinkConfluencePageClickWithAISuggestions,
		isSmartSummaryEnabled,
		isImproveDescriptionEnabled,
		onImproveDescriptionClick,
		isRelatedConfluencePagesEnabled,
	});

	const createItemView = useCallback(ItemView, []);

	const mappedAiActions = aiActions.map(createItemView);

	return (
		<Flex xcss={flexContainerStyles} testId="issue-improve-issue-dropdown.wrapper">
			<DropdownMenu
				trigger={({ triggerRef, ...rest }) => (
					<AkToolTip content={formatMessage(messages.buttonImproveIssue)}>
						{shouldBeCompact ? (
							<Button
								{...rest}
								label={formatMessage(messages.buttonImproveIssue)}
								ref={triggerRef}
								iconAfter={
									<Inline xcss={iconButtonStyles}>
										<AiIcon label="" size="small" isDisabled={!open} />
									</Inline>
								}
							/>
						) : (
							<Button
								{...rest}
								label={formatMessage(messages.buttonImproveIssue)}
								ref={triggerRef}
								iconBefore={<AiIcon label="" size="small" isDisabled={!open} />}
							>
								{!shouldBeCompact && formatMessage(messages.buttonImproveIssue)}
							</Button>
						)}
					</AkToolTip>
				)}
				testId="issue-improve-issue-dropdown.improve-issue-dropdown"
				shouldRenderToParent
				onOpenChange={toggleMenuOpen}
				isOpen={open}
			>
				<Box paddingInline="space.150" paddingBlockStart="space.200">
					<Text size="small" weight="semibold" color="color.text.subtlest">
						{formatMessage(messages.atlassianIntelligence).toLocaleUpperCase()}
					</Text>
				</Box>
				<DropdownItemGroup>{mappedAiActions}</DropdownItemGroup>
			</DropdownMenu>
		</Flex>
	);
};

/**
 * This scopeSelector function is a business decision to determine the correct childPanelType to use
 * as the scope for the ImproveIssueQuickAddItem component.
 * The decision is that the default button on an issue that adds child issues should open the same panel
 * as the AIWB button on the imrpove issue dropdown
 */
const scopeSelector = ({
	supportsChildCreation,
	shouldShowCreateSubtaskButton,
	shouldShowIssueInEpicButton,
}: ChildPanel) => {
	if (supportsChildCreation) {
		return CHILDREN_ISSUES_PANEL;
	}
	if (shouldShowCreateSubtaskButton) {
		return CLASSIC_SUBTASKS;
	}
	if (shouldShowIssueInEpicButton) {
		return CLASSIC_PROJECT_EPIC_CHILDREN;
	}
	return CHILDREN_ISSUES_PANEL;
};

export const ImproveIssueQuickAddItemWithStores = (props: Omit<Props, 'scope'>) => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const application = useApplication(projectKey, true);

	const { supportsChildCreation, shouldShowCreateSubtaskButton, shouldShowIssueInEpicButton } =
		props;

	const scope = scopeSelector({
		supportsChildCreation,
		shouldShowCreateSubtaskButton,
		shouldShowIssueInEpicButton,
	});

	if (application !== JIRA_CORE && application !== JIRA_SOFTWARE) {
		return null;
	}

	return (
		<SummaryStateContainer>
			<IssueBreakdownAiContainer scope={`${issueKey}-${scope}-issue-breakdown`}>
				<ImproveIssueQuickAddItem {...props} scope={scope} />
			</IssueBreakdownAiContainer>
		</SummaryStateContainer>
	);
};

export const ConnectedImproveIssueQuickAddItemWithStores = connect(
	(state: State) => ({
		...improveIssueDropdownPermissionsSelector(state),
	}),
	(dispatch: Dispatch<Action>): { onLinkConfluencePageClickWithAISuggestions: () => void } => ({
		onLinkConfluencePageClickWithAISuggestions: () => {
			dispatch(linkConfluencePageClickedWithAISuggestions());
		},
	}),
)(ImproveIssueQuickAddItemWithStores);

const flexContainerStyles = xcss({
	marginRight: 'space.100',
});

const iconButtonStyles = xcss({
	paddingInline: 'space.050',
});

const popupContentStyles = xcss({
	maxWidth: '300px',
	paddingInline: 'space.300',
	paddingBlock: 'space.200',
});
