import React, { type ReactElement } from 'react';
// eslint-disable-next-line import/order
import { styled } from '@compiled/react';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { TASK_FAIL, TASK_SUCCESS } from '@atlassian/jira-experience-tracker/src/common/constants';
import type { IssueViewRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { CONTEXT_AREA, type ContextPanelItem } from '@atlassian/jira-issue-layout-common-constants';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import { CONTEXT_GROUP_INITIAL_OPENED } from '@atlassian/jira-issue-view-common-constants/src/layout';
import { ItemList } from '@atlassian/jira-issue-view-layout-templates-item-list';
import { ChangeBoarding } from '@atlassian/jira-issue-view-layout-templates-views-context-group/src/context/change-boarding/index.tsx';
import { MountEvent } from '@atlassian/jira-product-analytics-bridge';
import type { Application } from '@atlassian/jira-shared-types/src/application.tsx';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import { ContextPanelGroup } from './main';
// Required as per JIV-13665 to smooth out loading state with forge
// The numbers below match the loadingComponent height in LazyIssueContext
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LoadingContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minHeight: `${3 * gridSize * 2 + 5 * gridSize}px`,
	display: 'flex',
});

/**
 * Utility Helper that takes an Array of Ecosystem Context Items and generates a new array of components
 * where each ContextItem is wrapped in its own ecosystem context group. We do this to ensure all
 * context items have their own 'expanding' component.
 *
 * @param givenContextItems the context items to provide. Each will be wrapped in their own group
 * @param layoutItemsDataFragment relay fragment that is passed down
 */
export const generateContextPanels = (
	givenContextItems: ContextPanelItem[],
	layoutItemsDataFragment: IssueViewRelayFragment | null,
	application?: Application | undefined,
	edition?: ApplicationEdition | undefined,
): ReactElement[] => {
	if (!givenContextItems || !Array.isArray(givenContextItems) || !givenContextItems.length) {
		return [];
	}

	return givenContextItems.map((contextItem, index) => {
		const { name: title, icon, status, label: subtitle } = contextItem.payload;

		const groupItem = (
			<ErrorBoundary
				id="issue-view.context.ecosystem-context-panel"
				onError={(location, error) => {
					sendExperienceAnalytics({
						experience: 'issueViewContextGroupRender',
						analyticsSource: 'issueViewContextGroupView',
						application: application ?? null,
						edition: edition ?? null,
						wasExperienceSuccesful: false,
						action: TASK_FAIL,
						additionalAttributes: {
							location,
							errorMessage: error.message,
						},
					});
				}}
			>
				<ContextPanelGroup
					key={`context-item-${contextItem.id}`}
					groupId={`context-group-${contextItem.id}`}
					title={title}
					icon={icon}
					status={status}
					subtitle={subtitle}
					initialOpened={CONTEXT_GROUP_INITIAL_OPENED}
					extension={contextItem.payload.extension}
				>
					<LoadingContainer>
						{/* We're only sending it one item as each item is in its own group */}
						<ItemList
							items={[contextItem]}
							area={CONTEXT_AREA}
							layoutItemsDataFragment={layoutItemsDataFragment ?? null}
						/>
					</LoadingContainer>
				</ContextPanelGroup>
				<MountEvent
					onMount={() =>
						sendExperienceAnalytics({
							experience: 'issueViewContextGroupRender',
							analyticsSource: 'issueViewContextGroupView',
							application: application ?? null,
							edition: edition ?? null,
							wasExperienceSuccesful: true,
							action: TASK_SUCCESS,
						})
					}
				/>
			</ErrorBoundary>
		);

		if (index === 0) {
			return <ChangeBoarding key="context-item-change-boarding">{groupItem}</ChangeBoarding>;
		}
		return groupItem;
	});
};
