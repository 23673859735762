import React, { useCallback, type ComponentType } from 'react';
import ArrowLeftIcon from '@atlaskit/icon/core/migration/arrow-left';
import { ErrorBoundaryFlag as ErrorBoundary } from '@atlassian/jira-error-boundary-flag-renderer';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { TAB_ITEM } from '@atlassian/jira-issue-shared-types/src/common/types/layout-item-type.tsx';
import {
	useIssueLayoutContextPanel,
	useIssueLayoutActions,
} from '@atlassian/jira-issue-view-layout/src/services/main.tsx';
import getLayoutItemEntryMap from '../../../get-layout-item-map';
import type { LayoutItemMap } from '../../../types';
import { HeaderBreadcrumb } from '../../side-panel/styled';
import type { RenderStyles, PassedDownRefs } from '../../side-panel/types';
import SidePanel from '../../side-panel/view.tsx';
import TabPanel from '../../tab-panel';
import { HeaderContainer, HeaderContentContainer, RoundButton } from './styled';

export type Props = {
	refs: PassedDownRefs;
};

export const ContextPanel = (props: Props) => {
	const { refs } = props;

	const issueKey = useIssueKey();
	const [contextPanel] = useIssueLayoutContextPanel(issueKey);
	const [, { setIssueViewLayoutContextPanel }] = useIssueLayoutActions();

	const layoutItemEntryMap: LayoutItemMap = getLayoutItemEntryMap();

	const isOpen = contextPanel !== undefined;
	const onHidePanel = useCallback(() => {
		setIssueViewLayoutContextPanel(issueKey, undefined);
	}, [issueKey, setIssueViewLayoutContextPanel]);

	let PanelComponent: ComponentType<unknown> | null | undefined = null;

	if (contextPanel) {
		const layoutItemEntry = layoutItemEntryMap[contextPanel.type];
		PanelComponent =
			// tabs cannot be in the map because they need the map to determine what items
			// to render, which would create a circular dependency
			contextPanel.type === TAB_ITEM ? TabPanel : layoutItemEntry && layoutItemEntry.panel;
	}

	return (
		<ErrorBoundary id="issue.issue-view.issue-base.context.context-panel">
			<SidePanel refs={refs} isOpen={isOpen}>
				{(styles: RenderStyles) => (
					<>
						<HeaderContainer
							// eslint-disable-next-line jira/integration/test-id-by-folder-structure
							data-testid="issue.views.issue-base.context.context-panel.back"
						>
							<HeaderContentContainer
								maxWidth={styles.headerWidth}
								onClick={onHidePanel}
								tabIndex={0}
							>
								<RoundButton>
									<ArrowLeftIcon
										color="currentColor"
										spacing="spacious"
										LEGACY_size="medium"
										label="Return"
									/>
								</RoundButton>
								<HeaderBreadcrumb>{contextPanel && contextPanel.title}</HeaderBreadcrumb>
							</HeaderContentContainer>
						</HeaderContainer>
						{PanelComponent && contextPanel ? (
							<PanelComponent
								// @ts-expect-error - Property 'contentWidth' does not exist on type ComponentType<unknown>
								contentWidth={styles.contentWidth}
								type={contextPanel.type}
								panelKey={contextPanel.key}
								payload={contextPanel.payload}
								headerWidth={styles.headerWidth}
							/>
						) : null}
					</>
				)}
			</SidePanel>
		</ErrorBoundary>
	);
};

ContextPanel.displayName = 'ContextPanel';

export default ContextPanel;
