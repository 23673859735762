import { useCallback } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import type { ApolloQueryResult } from 'apollo-client';
import gqlTagPolaris from 'graphql-tag';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { ff } from '@atlassian/jira-feature-flagging';
import type { Ari } from '@atlassian/jira-platform-ari';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
	type Attributes,
} from '@atlassian/jira-product-analytics-bridge';
import type { ProjectConfigFetcher } from '../types';
import { createAnalyticsEventPayload } from '../utils';
import type { GetPolarisProjectQuery, ProjectTypeGQL } from './types';

export const ViewFragment = gqlTagPolaris`
  fragment EdgeView on PolarisView {
    __typename
    id
    xid
    name
    emoji
    description @skip(if: $skipDescription)
    uuid @include(if: $isShareViewDialogEnabled)
    containsArchived
    immutable
    visualizationType
    lastCommentsViewedTimestamp
    sortMode
    hideEmptyGroups
    hideEmptyColumns
    groupBy {
        key: jiraFieldKey
    }
    verticalGroupBy {
        key: jiraFieldKey
    }
    groupValues {
        ...EdgeGroupValue
    }
    verticalGroupValues {
        ...EdgeGroupValue
    }
    sort {
        ...EdgeSorted
    }
    fields {
        key: jiraFieldKey
    }
    hidden {
        key: jiraFieldKey
    }
    filter {
        ...EdgeFilter
    }
    tableColumnSizes {
        ...EdgeColumnSizes
    }
    fieldRollups {
        ...EdgeFieldRollups
    }
    matrixConfig {
        axes {
            dimension
            field {
                key: jiraFieldKey
            }
            fieldOptions {
                ...EdgeGroupValue
            }
            reversed
        }
    }
    timelineConfig {
        summaryCardField {
            key: jiraFieldKey
        }
        startDateField {
            key: jiraFieldKey
        }
        dueDateField {
            key: jiraFieldKey
        }
        mode
        startTimestamp
        endTimestamp
    }
    rank
    viewSetId
    enabledAutoSave
    layoutType
    createdAt
    updatedAt
  }


  fragment EdgeColumnSizes on PolarisViewTableColumnSize {
      field {
          key: jiraFieldKey
      }
      size
  }

  fragment EdgeFieldRollups on PolarisViewFieldRollup {
      field {
          key: jiraFieldKey
      }
      rollup
  }

  fragment EdgeSorted on PolarisSortField {
      order
      field {
          key: jiraFieldKey
      }
    }

  fragment EdgeFilter on PolarisViewFilter {
      type: kind
      field {
          key: jiraFieldKey
      }
      values {
          ...EdgeFilterValue
      }
  }

  fragment EdgeFilterValue on PolarisViewFilterValue {
      stringValue
      numericValue
      operator
  }

  fragment EdgeGroupValue on PolarisGroupValue {
      __typename
      label
      id
  }
`;

const PROJECT_CONFIG_QUERY = gqlTagPolaris`
  query jira_polaris_ProjectQuery_reduced(
    $ari: ID!,
    $isShareViewDialogEnabled: Boolean!,
    $skipDescription: Boolean!,
  ) {
    polarisProject(id: $ari) {
      ...EdgeProjectConfigReducedGQL
    }
  }

  fragment EdgeProjectConfigReducedGQL on PolarisProject {
      __typename
      id
      name
      key
      rankField
      onboarded
      selectedDeliveryProject
      viewsets {
        id
        name
        type
        views {
          ...EdgeView
        }
        viewsets {
          id
          name
          type
          rank
          views {
            ...EdgeView
          }
        }
      }
      ideaTypes {
        __typename
        id
        name
        iconUrl
      }
      issueLinkType {
        merge
        delivery
        datapoint
      }
      statusCategories {
        id
        colorName
        key
        name
      }
      arjHierarchyConfiguration {
        title
        issueTypes
      }
      avatarUrls {
        x16
        x24
        x32
        x48
      }
      onboardTemplate
      onboardedAt
  }
  ${ViewFragment}
`;

export const fetchProject = async (
	apolloClient: PolarisApolloClient,
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	projectAri: Ari,
	noCache?: boolean,
	fireAnalyticsEvent?: boolean,
): Promise<ProjectTypeGQL> => {
	const fetchStart = new Date();

	const result: ApolloQueryResult<GetPolarisProjectQuery> =
		await apolloClient.query<GetPolarisProjectQuery>({
			query: PROJECT_CONFIG_QUERY,
			variables: {
				ari: projectAri,
				isShareViewDialogEnabled: ff('polaris.sharing-enabled'),
				skipDescription: true,
			},
			fetchPolicy: noCache ? 'network-only' : 'cache-first',
		});

	if (result.errors !== undefined) {
		throw new Error(`project-config.fetch-error:${result.errors.map((e) => e.message).join(', ')}`);
	}

	if (fireAnalyticsEvent === true) {
		const analyticsData: Attributes = createAnalyticsEventPayload({
			projectAri,
			durationMs: new Date().getTime() - fetchStart.getTime(),
			data: result.data,
		});

		fireOperationalAnalytics(
			createAnalyticsEvent({}),
			'JpdFetchProjectConfig succeeded',
			analyticsData,
		);
	}

	if (!result.data?.polarisProject) {
		throw new Error('project-config.fetch-error: no data or no data node');
	}

	return result.data.polarisProject;
};

export const useProjectFetcher = (): [ProjectConfigFetcher] => {
	const client = useApolloClient();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fetcher = useCallback(
		async (ari: Ari, noCache?: boolean, fireAnalyticsEvent?: boolean): Promise<ProjectTypeGQL> =>
			fetchProject(client, createAnalyticsEvent, ari, noCache, fireAnalyticsEvent),
		[client, createAnalyticsEvent],
	);

	return [fetcher];
};
