import { expValEquals, UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { ff, getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';

export const getPerformanceTrackingConfiguration = () =>
	getFeatureFlagValue('issue.details.editor.performance-tracking', {});

export const isEditorUFOEnabled = () => ff('issue.details.editor.ufo', false);

export const isMediaInlineEnabled = () => ff('issue.details.media-inline', false);

export const isInviteOnLinkInsertV2Enrolled = () => {
	const [expConfig] = UNSAFE_noExposureExp('groot-aioli-v5');
	const cohort = expConfig.get('cohort', 'not-enrolled');
	return cohort !== 'not-enrolled';
};

export const STATSIG_GUARD_THIRD_PARTY_NUDGE = 'statsig-guard-third-party-nudge';
const isThirdPartyNudgeStatsigGuardEnabled = () => ff(STATSIG_GUARD_THIRD_PARTY_NUDGE, false);

export const isThirdPartyNudgeEnrolled = () => {
	const isThirdPartyNudgeExperimentEnrolled = () => {
		if (!fg('statsig-guard-third-party-nudge')) return false;

		const [expConfig] = UNSAFE_noExposureExp('third_party_app_nudge_on_after_jira_comment');
		const cohort = expConfig.get('cohort', 'not-enrolled');

		return ['cohort', 'variation'].includes(cohort);
	};

	return isThirdPartyNudgeStatsigGuardEnabled() && isThirdPartyNudgeExperimentEnrolled();
};

export const isDevOpsAppRecommenderEnabled = () =>
	ff('devops_app_recommendation_on_link_paste', false) &&
	expValEquals('devops_app_recommendation_on_link_paste', 'cohort', 'variation');
