import React, { type ReactNode } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { TASK_FAIL } from '@atlassian/jira-experience-tracker/src/common/constants';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import type { Application } from '@atlassian/jira-shared-types/src/application.tsx';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';

type RelayLayoutItemErrorBoundaryProps = {
	children?: ReactNode;
	fieldType: string | undefined;
	application: Application | undefined;
	edition: ApplicationEdition | undefined;
};

export const RelayLayoutItemErrorBoundary = ({
	children,
	fieldType,
	application,
	edition,
}: RelayLayoutItemErrorBoundaryProps) => (
	<JSErrorBoundary
		id="relay-layout-item"
		packageName="jiraIssueViewLayoutTemplatesLayoutItem"
		teamName="bento"
		fallback="unmount"
		attributes={{ fieldType }}
		onError={(location, error) =>
			sendExperienceAnalytics({
				experience: 'issueViewRelayFieldRender',
				analyticsSource: 'issueViewRelayFieldView',
				action: TASK_FAIL,
				wasExperienceSuccesful: false,
				application: application ?? null,
				edition: edition ?? null,
				additionalAttributes: {
					location,
					errorMessage: error.message,
					fieldType: fieldType || 'unknown',
				},
			})
		}
	>
		{children}
	</JSErrorBoundary>
);
