import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

export const useGoalsCellAriaLabel = ({
	goalCount,
	showPlaceholder,
}: {
	goalCount: number;
	showPlaceholder?: boolean;
}) => {
	const { formatMessage } = useIntl();

	if (showPlaceholder) {
		return undefined;
	}

	if (goalCount === 0) {
		return formatMessage(messages.addGoal);
	}

	return formatMessage(messages.linkGoals);
};
