import React, { type ReactNode, useCallback, useMemo } from 'react';
import { HighlightActionsProvider } from '@atlassian/jira-highlight-actions/src/ui/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { SIDEBAR_RESIZE_RATIO } from '@atlassian/jira-issue-shared-types/src/common/types/user-preferences-type.tsx';
import { useUserPreferencesValue } from '@atlassian/jira-issue-user-preference-services/src/main.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { defaultRatioRightColumn, getIssueContainerMaxWidth } from './constants';

export const LayoutStyles = {
	modal: 'modal',
	embedded: 'embedded',
	full: 'full',
} as const;

export type LayoutStyle = keyof typeof LayoutStyles;

export const getLayoutStyle = (isModal?: boolean, issueMaxWidth?: number): LayoutStyle => {
	if (isModal === true) {
		return LayoutStyles.modal;
	}
	if (issueMaxWidth !== getIssueContainerMaxWidth()) {
		return LayoutStyles.embedded;
	}
	return LayoutStyles.full;
};

const getStoredValues = (str: string | null) => {
	if (str === null || str === '') {
		return {};
	}
	try {
		return JSON.parse(str);
	} catch {
		return {};
	}
};

export const getSidebarRatio = (layoutStyle: LayoutStyle, storedValue: string | null) => {
	const storedValues = getStoredValues(storedValue);
	const r = storedValues[layoutStyle] ?? defaultRatioRightColumn;
	return !Number.isNaN(r) && Number.isFinite(r) && r >= 0 && r <= 1 ? r : defaultRatioRightColumn;
};

export const useSidebarResize = (layoutStyle: LayoutStyle) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [sidebarRatio, { setValue }] = useUserPreferencesValue(SIDEBAR_RESIZE_RATIO);

	const ratio = useMemo(
		() => getSidebarRatio(layoutStyle, sidebarRatio),
		[sidebarRatio, layoutStyle],
	);

	const onSaveRatio = useCallback(
		(newRatio: number, isReset = false) => {
			const analyticsEvent = createAnalyticsEvent({});
			fireUIAnalytics(analyticsEvent, `sidebar ${isReset ? 'reset' : 'dragged'}`, 'issueDetails', {
				sidebarRatio: newRatio,
			});
			const storedValues = getStoredValues(sidebarRatio);
			setValue(
				JSON.stringify({
					...storedValues,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					[layoutStyle as string]: newRatio,
				}),
				// eslint-disable-next-line @typescript-eslint/no-empty-function
			).catch(() => {}); // Ignore if server-side resize state update fails, this state is not critical;
		},
		[setValue, createAnalyticsEvent, layoutStyle, sidebarRatio],
	);

	const onResetRatio = useCallback(
		(newRatio: number) => {
			onSaveRatio(newRatio, true);
		},
		[onSaveRatio],
	);

	return [ratio, onSaveRatio, onResetRatio] as const;
};

export const HighlightActionsWrapper = ({
	children,
	scope,
}: {
	children: ReactNode;
	scope: string;
}) => {
	const issueKey = useIssueKey();

	return (
		<HighlightActionsProvider scope={`${scope}.${issueKey}`}>{children}</HighlightActionsProvider>
	);
};
