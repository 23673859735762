import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/filter';
import type { ActionsObservable } from 'redux-observable';
import { COMMIT_PANEL_DATA_TYPE } from '../../../common/model/constants';
import type { State } from '../../state';
import type { Action } from '../../state/actions/app';
import {
	fireDetailDataFetchedEvent,
	fireBitbucketDiffstatAnalyticsEvent,
	SET_DETAIL_DATA,
} from '../../state/actions/details';
import { CHANGE_TAB } from '../../state/actions/ui';
import { isDiffstatAnalyticsFired } from '../../state/selectors/details';
import { hasBitbucketCommits } from '../../state/selectors/details/commits';
import { getActiveTab } from '../../state/selectors/ui';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const diffstatAnalyticEventOrNull = (state: any) => {
	const shouldFireDiffstatAnalytics =
		!isDiffstatAnalyticsFired(state) &&
		getActiveTab(state) === COMMIT_PANEL_DATA_TYPE &&
		hasBitbucketCommits(state);
	return shouldFireDiffstatAnalytics ? fireBitbucketDiffstatAnalyticsEvent() : null;
};

const eventsMappers = {
	// @ts-expect-error - TS7006 - Parameter 'action' implicitly has an 'any' type. | TS7006 - Parameter 'state' implicitly has an 'any' type.
	[SET_DETAIL_DATA]: (action, state) => [
		fireDetailDataFetchedEvent(action.payload),
		diffstatAnalyticEventOrNull(state),
	],
	// @ts-expect-error - TS7006 - Parameter 'action' implicitly has an 'any' type. | TS7006 - Parameter 'state' implicitly has an 'any' type.
	[CHANGE_TAB]: (action, state) => [diffstatAnalyticEventOrNull(state)],
} as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getNewActions = (action: any, store: State) =>
	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ readonly "state.actions.details.SET_DETAIL_DATA": (action: any, state: any) => (FireDetailDataFetchedEventAction | FireBitbucketDiffstatAnalyticsEventAction | null)[]; readonly "state.actions.ui.CHANGE_TAB": (action: any, state: any) => (FireBitbucketDiffstatAnalyticsEventAction | null)[]; }'. | TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ readonly "state.actions.details.SET_DETAIL_DATA": (action: any, state: any) => (FireDetailDataFetchedEventAction | FireBitbucketDiffstatAnalyticsEventAction | null)[]; readonly "state.actions.ui.CHANGE_TAB": (action: any, state: any) => (FireBitbucketDiffstatAnalyticsEventAction | null)[]; }'.
	(eventsMappers[action.type] && eventsMappers[action.type](action, store)) || [];

/**
 * This epic is intended to fire new actions which will fire analytic events after state
 * be updated by current actions
 */
export const epic = (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$
		.flatMap((action) => getNewActions(action, store.getState()))
		.filter((newAction) => !!newAction);
