import { useMemo, useCallback } from 'react';
import { getAnalyticsAttributesFromExtension } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-analytics-attributes-from-extension';
import {
	fireOperationalFailedEvent,
	fireOperationalLayoutIssueEvent,
} from '@atlassian/jira-forge-ui-analytics/src/services/custom-field';
import type {
	CustomField,
	CustomFieldType,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { useAnalyticsSource } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	useForgeDataComplete,
	useForgeLoadingFailed,
	useIsForgeDataEmpty,
} from '@atlassian/jira-issue-view-forge-service/src/services/main.tsx';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import getComponent from '../../fields/get-forge-custom-fields';
import { ISSUE_VIEW_RENDER_CONTEXT } from '../wrapper';

export const useReportFailure = (
	extension: CustomField | CustomFieldType | null,
	fieldId: string,
	fieldExistsInJira: boolean | undefined,
	isFilteredOut: boolean | undefined,
	isCustomFieldDataAvailable: boolean,
): { reportFailure: () => void; shouldReportFailure: boolean } => {
	const ForgeField = getComponent(extension);
	const [isForgeInitiallyLoaded] = useForgeDataComplete();
	const isForgeLoadingFailed = useForgeLoadingFailed();
	const isForgeDataEmpty = useIsForgeDataEmpty();
	const source = useAnalyticsSource();

	/*
        The error should be reported when:
        * there is all required data fetched
        * there was no error occurred while fetching data
        * Forge data is not empty (fetch modules returns empty data for excluded errors)
        * extensions is not filtered out
        * there is no extension or Forge component
     */
	const shouldReportFailure = useMemo(
		() =>
			isCustomFieldDataAvailable &&
			isForgeInitiallyLoaded &&
			!isForgeLoadingFailed &&
			!isForgeDataEmpty &&
			!isFilteredOut &&
			!(extension && ForgeField),
		[
			isForgeInitiallyLoaded,
			isForgeLoadingFailed,
			isForgeDataEmpty,
			isFilteredOut,
			extension,
			ForgeField,
			isCustomFieldDataAvailable,
		],
	);

	const analyticsAttributes = useMemo(
		() =>
			extension
				? {
						source,
						fieldId,
						renderContext: ISSUE_VIEW_RENDER_CONTEXT,
						module: extension?.type,
						fieldType: extension?.properties.type,
						extensionProperties: extension?.properties,
						...(extension && getAnalyticsAttributesFromExtension(extension)),
					}
				: {
						source,
						fieldId,
						renderContext: ISSUE_VIEW_RENDER_CONTEXT,
					},
		[extension, source, fieldId],
	);

	const report = useCallback(
		(reporter: (source?: string, attributes?: Attributes) => Promise<void>, errorMessage: string) =>
			reporter(source, {
				...analyticsAttributes,
				error: Error(errorMessage),
			}),
		[analyticsAttributes, source],
	);

	const reportFailure = useCallback(() => {
		// Unsupported type
		if (extension && !ForgeField) {
			report(
				fireOperationalFailedEvent,
				`Forge custom field ${extension.properties.type} type is not supported`,
			);
			return;
		}

		// The field doesn't exist in Jira, but Jira wants to render it (as per issue layout config).
		// See https://hello.atlassian.net/wiki/spaces/SMITE/pages/1496973741/CustomField+extension+breaching#UPD-3
		if (!fieldExistsInJira) {
			report(
				fireOperationalLayoutIssueEvent,
				"ForgeCustomField component can't be rendered as Jira isn't aware of that field (layout issue)",
			);
			return;
		}

		// There is no corresponding Forge extension
		report(
			fireOperationalFailedEvent,
			'Forge extension passed to ForgeCustomField component is not found',
		);
	}, [extension, ForgeField, fieldExistsInJira, report]);

	return useMemo(
		() => ({
			shouldReportFailure,
			reportFailure,
		}),
		[shouldReportFailure, reportFailure],
	);
};
