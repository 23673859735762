import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { IssueAdjustmentsState } from '../../types';

type InitializeScreenTabsPayload = {
	tabs: Readonly<Array<{ id: string }>>;
	selected?: number;
};

/**
 * Initialize screen tabs store data
 * @param {InitializeScreenTabsPayload} payload - contains screen tabs common props and current selected tab index
 */
export const initializeScreenTabs =
	({ tabs, selected }: InitializeScreenTabsPayload) =>
	({ getState, setState }: StoreActionApi<IssueAdjustmentsState>) => {
		const { screenTabs: internalScreenTabs } = getState();
		const previouslySelected = internalScreenTabs?.findIndex((tab) => tab.isActive);

		setState({
			screenTabs: tabs.map(({ id }, index) => ({
				id,
				isVisible: true,
				isActive: index === selected || index === previouslySelected,
			})),
		});
	};
