import React from 'react';
import SingleSelectInlineEditView, {
	type Props as SingleSelectInlineEditViewProps,
} from '@atlassian/jira-issue-internal-field-select/src/single-select-inline-edit';

export type Props = {
	shouldHide: boolean;
} & SingleSelectInlineEditViewProps;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => {
	const { shouldHide, ...selectProps } = props;

	if (shouldHide) {
		return null;
	}

	return <SingleSelectInlineEditView {...selectProps} />;
};
