import defer from 'lodash/defer';
import difference from 'lodash/difference';
import indexOf from 'lodash/indexOf';
import isEqual from 'lodash/isEqual';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import throttle from 'lodash/throttle';
import { ff } from '@atlassian/jira-feature-flagging';
import type {
	LocalIssueId,
	ExternalIssueId,
} from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { asyncSwitchAction } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/actions/index.tsx';
import type {
	RemoteIssue,
	FetchResponse,
} from '@atlassian/jira-polaris-remote-issue/src/controllers/crud/types.tsx';
import { getMetaFromJiraSearchIssue } from '@atlassian/jira-polaris-remote-issue/src/controllers/util/index.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { ISSUES_PAGE_LIMIT } from '../../constants';
import { getFieldMappings, getFieldKeys } from '../../selectors/fields';
import {
	getLocalIssueIdsByJiraIssueId,
	getExternalIssueIdsByJiraIssueId,
} from '../../selectors/issue-ids';
import { getJiraIssueIdProperties } from '../../selectors/properties';
import type { ExternalIssueData, State, Props } from '../../types';

import { generateLocalIssueId } from '../../utils/local-id.tsx';
import { sortIssuesByRank, sortIdsByRank } from '../common/issue-rank';
import { loadDeliveryProgress } from '../load-delivery-data';
import type { RefreshIssuesArgs } from './types';

const throttleFetchIssuesRanksInterval = 5 * 60 * 1000;

export const handleRefreshForExternalIssue = (
	state: State,
	props: Props,
	remoteIssuesByLocalIssueId: Record<LocalIssueId, RemoteIssue>,
): State => {
	// @ts-expect-error - TS2554 - Expected 1 arguments, but got 2.
	const externalIdMap = getExternalIssueIdsByJiraIssueId(state, props);

	const externalIssueDataNew: Record<ExternalIssueId, ExternalIssueData> = {};
	Object.values(remoteIssuesByLocalIssueId).forEach((remoteIssue) => {
		const externalId = externalIdMap[remoteIssue.id];
		if (externalId !== undefined) {
			externalIssueDataNew[externalId] = {
				...state.externalIssueData[externalId],
				status: remoteIssue.fields.status,
			};
		}
	});

	if (Object.keys(externalIssueDataNew).length > 0) {
		// this is a known external issue. update it!
		return {
			...state,
			externalIssueData: {
				...state.externalIssueData,
				...externalIssueDataNew,
			},
		};
	}

	// unknown external issue. if it isn't linked to a polaris idea, we don't care about it
	return state;
};

// trigger once in interval
const throttledFetchRanks = throttle(
	(
		{ getState, setState }: StoreActionApi<State>,
		props: Props,
		idMap: Record<IssueId, LocalIssueId>,
	): Promise<void> => {
		const { projectId, ideaTypes, rankField, hasNoProjectPermissions } = props;
		// Bail early if we don't yet have all the information needed to load issues
		// or if the rank has not changed, unless it's a newly created issue
		if (!rankField || !projectId || !ideaTypes || hasNoProjectPermissions) {
			return Promise.resolve();
		}
		// a rank change has happened in the backend. refresh ranks
		return props.issuesRemote
			.fetchRanks(
				ff('polaris.split-archived-issue-loading')
					? {
							rankField,
							issueTypeIds: ideaTypes.map((type) => type.jiraIssueTypeId),
							archivedFilter: 'ACTIVE_ONLY',
							startAt: 0,
							maxResults: ISSUES_PAGE_LIMIT,
						}
					: {
							rankField,
							issueTypeIds: ideaTypes.map((type) => type.jiraIssueTypeId),
						},
			)
			.then((response) => {
				const localIdMap = getLocalIssueIdsByJiraIssueId(getState(), props);

				// ensure local id map contains the idea in question (create case)
				const completeLocalIdMap: Record<number, string> = {
					...localIdMap,
					...idMap,
				};
				const issues = sortIssuesByRank({ issues: response.issues, rankField });
				const remoteSortedIds = issues
					.map((issue) => completeLocalIdMap[+issue.id])
					.filter(Boolean);
				const newIds = sortBy(getState().ids, (localIssueId) =>
					indexOf(remoteSortedIds, localIssueId),
				);
				if (!isEqual(newIds, getState().ids)) {
					setState({
						ids: newIds,
					});
				}
			});
	},
	throttleFetchIssuesRanksInterval,
);

const isRankChanged = (
	state: State,
	props: Props,
	remoteIssuesByLocalIssueId: Record<LocalIssueId, RemoteIssue>,
) => {
	const { rankField } = props;

	return Object.entries(remoteIssuesByLocalIssueId).some(([idForUpdate, remoteIssue]) => {
		const lexoRank = rankField ? remoteIssue.fields[rankField] : undefined;
		return state.properties.lexoRank[idForUpdate] !== lexoRank;
	});
};

const calculateRefreshedIdeaState = (
	state: State,
	props: Props,
	remoteIssuesByLocalIssueId: Record<LocalIssueId, RemoteIssue>,
): State => {
	const { ideaTypes, projectId, rankField, isSharedView } = props;
	const ideaTypeIds = (ideaTypes || []).map(({ jiraIssueTypeId }) => jiraIssueTypeId);
	const localIdMap = getLocalIssueIdsByJiraIssueId(state, props);
	const fieldMappings = getFieldMappings(state, props);

	let localState = state;

	Object.entries(remoteIssuesByLocalIssueId).forEach(([idForUpdate, remoteIssue]) => {
		const isValidType = ideaTypeIds.includes(remoteIssue.fields.issuetype.id);

		const localId = localIdMap[remoteIssue.id];

		if (
			isSharedView ||
			(String(projectId) === String(remoteIssue.fields.project.id) && isValidType)
		) {
			const newProperties = reduce(
				fieldMappings,
				(result, mapping) => {
					const fieldValue = mapping.getValueFromJiraIssue(remoteIssue);
					return mapping.setImmutable(
						result,
						idForUpdate,
						fieldValue !== null ? fieldValue : undefined,
					);
				},
				{ ...localState.properties },
			);

			const lexoRank = rankField !== undefined ? remoteIssue.fields[rankField] : undefined;
			if (lexoRank && rankField) {
				newProperties.lexoRank = {
					...newProperties.lexoRank,
					[idForUpdate]: lexoRank,
				};
			}

			newProperties.issueMetadata = {
				...localState.properties.issueMetadata,
				[idForUpdate]: getMetaFromJiraSearchIssue(
					remoteIssue,
					props.polarisIssueLinkType,
					props.hiddenIssueLinkTypes,
				),
			};

			// distinguish update and create cases
			const idsToRank = localId ? localState.ids : [...localState.ids, idForUpdate];
			let ids = idsToRank;
			if (rankField) {
				ids = sortIdsByRank({
					ids: idsToRank,
					lexoRankProperties: newProperties.lexoRank,
				});
			}
			localState = {
				...localState,
				ids,
				properties: newProperties,
			};
		} else if (localId) {
			// not a valid polaris idea, but part of the local store. remove it (type or project has changed)
			localState = {
				...localState,
				ids: difference(localState.ids, [localId]),
			};
		}
	});

	// no idea issue affected, return state unchanged
	return localState;
};

const hasNewlyCreatedIssue = (state: State, props: Props, remoteIssues: RemoteIssue[]) => {
	const localIdMap = getLocalIssueIdsByJiraIssueId(state, props);
	return remoteIssues.some(({ id }) => localIdMap[id] === undefined);
};

const fetchIssuesRanks =
	(idMap: Record<IssueId, LocalIssueId>) => (storeApi: StoreActionApi<State>, props: Props) => {
		throttledFetchRanks(storeApi, props, idMap);
	};

const getRemoteIssuesByLocalIssueId = (
	localIdMap: Record<IssueId, LocalIssueId>,
	remoteIssues: RemoteIssue[],
): Record<LocalIssueId, RemoteIssue> =>
	remoteIssues.reduce<Record<LocalIssueId, RemoteIssue>>((acc, remoteIssue) => {
		const localId = localIdMap[remoteIssue.id];
		const idForUpdate: LocalIssueId = localId === undefined ? generateLocalIssueId() : localId;
		acc[idForUpdate] = remoteIssue;
		return acc;
	}, {});

export const refreshIssues = asyncSwitchAction<
	State,
	Props,
	RefreshIssuesArgs,
	FetchResponse | undefined
>(
	({ jiraIssueIds, refreshAllLoaded }, { getState }, props) => {
		const { issuesRemote, hasNoProjectPermissions, isSharedView } = props;

		// Disable real-time refresh issue for Shared view
		if (hasNoProjectPermissions || isSharedView) {
			return Promise.resolve(undefined);
		}

		const fields = getFieldKeys(getState(), props);
		const issueIds = refreshAllLoaded
			? Object.values(getJiraIssueIdProperties(getState())).map((id) => id.toString())
			: jiraIssueIds;

		return issuesRemote.fetch({
			issueIdsOrKeys: issueIds,
			fields,
		});
	},
	(response, { callback }, { dispatch, getState, setState }, props) => {
		// Bail early if we don't yet have all the information needed to load issues
		if (!response) {
			return;
		}

		const state = getState();
		const localIdMap = getLocalIssueIdsByJiraIssueId(state, props);
		const remoteIssuesByLocalIssueId = getRemoteIssuesByLocalIssueId(localIdMap, response.issues);

		const newState = calculateRefreshedIdeaState(state, props, remoteIssuesByLocalIssueId);

		const stateWithExternalChanged = handleRefreshForExternalIssue(
			newState,
			props,
			remoteIssuesByLocalIssueId,
		);

		setState(stateWithExternalChanged);

		const idMap = Object.entries(remoteIssuesByLocalIssueId).reduce<Record<IssueId, LocalIssueId>>(
			(acc, [localIssueId, remoteIssue]) => {
				acc[remoteIssue.id] = localIssueId;
				return acc;
			},
			{},
		);

		if (
			hasNewlyCreatedIssue(state, props, response.issues) ||
			isRankChanged(state, props, remoteIssuesByLocalIssueId)
		) {
			defer(() => dispatch(fetchIssuesRanks(idMap)));
		}

		const jiraIssueIds = response.issues.map((issue) => issue.id);
		dispatch(loadDeliveryProgress(undefined, undefined, jiraIssueIds));

		// Execute callback after issue refresh
		defer(() => callback?.());
	},
	(error, _, __, { onIssueLoadingFailed }) => onIssueLoadingFailed(error),
);
