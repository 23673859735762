/**
 * @jsxRuntime classic
 * @jsx jsx
 */
/** @jsxfrag */
import React from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import LikeIcon from '@atlaskit/icon/glyph/like';
import Lozenge from '@atlaskit/lozenge';
import { Box, Inline } from '@atlaskit/primitives';
import { N100 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import VisuallyHidden from '@atlaskit/visually-hidden';

import messages from './messages';
import { buttonGroupStyles, dislikeButtonStyles } from './styles';

type Props = {
	onGoodResponseButtonClick: () => void;
	onBadResponseButtonClick: () => void;
	iconsOnly?: boolean;
	pressedIconsColor?: string;
};

type FeedbackLikeIconProps = { label: string; iconColor?: string };

const FeedbackLikeIcon = ({ label, iconColor }: FeedbackLikeIconProps) => {
	return <LikeIcon label={label} primaryColor={iconColor} />;
};

type IconButtonProps = {
	onClick: () => void;
	isPressed?: boolean;
	isDisabled: boolean;
	pressedColor?: string;
	iconColor?: string;
};

const hideBackgroundStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		backgroundColor: token('color.background.neutral.subtle'),
	},
});

const Announcer = ({ message, shouldAnnounce }: { message: string; shouldAnnounce: boolean }) => {
	return (
		<VisuallyHidden>
			<Box aria-atomic="true" aria-live="polite">
				{shouldAnnounce && message}
			</Box>
		</VisuallyHidden>
	);
};

const LikeIconButton = ({
	onClick,
	isPressed,
	isDisabled,
	pressedColor,
	iconColor,
}: IconButtonProps) => {
	const { formatMessage } = useIntl();
	const goodResponseLabel = formatMessage(messages.goodResponse);
	const Icon = (props: FeedbackLikeIconProps) => (
		<FeedbackLikeIcon {...props} iconColor={isPressed ? pressedColor : iconColor} />
	);

	return (
		<div css={[(isPressed || isDisabled) && hideBackgroundStyles]}>
			<Tooltip content={goodResponseLabel} position="top">
				<IconButton
					testId="good-response-feedback-button"
					type="button"
					isDisabled={isDisabled}
					onClick={onClick}
					icon={Icon}
					label={goodResponseLabel}
					appearance="subtle"
					spacing="compact"
				/>
			</Tooltip>
		</div>
	);
};

const DislikeIconButton = ({
	onClick,
	isPressed,
	isDisabled,
	pressedColor,
	iconColor,
}: IconButtonProps) => {
	const { formatMessage } = useIntl();
	const badResponseLabel = formatMessage(messages.badResponse);
	const Icon = (props: FeedbackLikeIconProps) => (
		<FeedbackLikeIcon {...props} iconColor={isPressed ? pressedColor : iconColor} />
	);

	return (
		// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		<div css={[dislikeButtonStyles, (isPressed || isDisabled) && hideBackgroundStyles]}>
			<Tooltip content={badResponseLabel} position="top">
				<IconButton
					testId="bad-response-feedback-button"
					type="button"
					isDisabled={isDisabled}
					onClick={onClick}
					icon={Icon}
					label={badResponseLabel}
					appearance="subtle"
					spacing="compact"
				/>
			</Tooltip>
		</div>
	);
};

type Feedback = 'good' | 'bad';

export const FeedbackForm = ({
	onGoodResponseButtonClick,
	onBadResponseButtonClick,
	iconsOnly = false,
	pressedIconsColor,
}: Props) => {
	const pressedColor = pressedIconsColor ?? token('color.interaction.pressed');
	const { formatMessage } = useIntl();
	const feedbackWasSentLabel = formatMessage(messages.feedbackSent);
	const [feedbackSelected, setFeedbackSelected] = React.useState<Feedback>();
	const handleOnClick = React.useCallback(
		(onClick: () => void, feedback: Feedback) => () => {
			onClick();
			setFeedbackSelected(feedback);
		},
		[setFeedbackSelected],
	);

	if (iconsOnly) {
		return (
			<React.Fragment>
				<Announcer message={feedbackWasSentLabel} shouldAnnounce={Boolean(feedbackSelected)} />
				<LikeIconButton
					isPressed={feedbackSelected === 'good'}
					isDisabled={Boolean(feedbackSelected)}
					pressedColor={pressedColor}
					onClick={handleOnClick(onGoodResponseButtonClick, 'good')}
				/>
				<DislikeIconButton
					isPressed={feedbackSelected === 'bad'}
					isDisabled={Boolean(feedbackSelected)}
					pressedColor={pressedColor}
					onClick={handleOnClick(onBadResponseButtonClick, 'bad')}
				/>
			</React.Fragment>
		);
	}

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
		<div css={buttonGroupStyles}>
			{feedbackSelected && (
				<p aria-atomic="false" aria-live="polite">
					<Lozenge appearance="success" testId="feedback-success">
						{feedbackWasSentLabel}
					</Lozenge>
				</p>
			)}
			<Inline space="space.100">
				<LikeIconButton
					iconColor={token('color.icon', N100)}
					isDisabled={Boolean(feedbackSelected)}
					onClick={handleOnClick(onGoodResponseButtonClick, 'good')}
				/>
				<DislikeIconButton
					iconColor={token('color.icon', N100)}
					isDisabled={Boolean(feedbackSelected)}
					onClick={handleOnClick(onBadResponseButtonClick, 'bad')}
				/>
			</Inline>
		</div>
	);
};
