import { isValidEmail, type User as UserOptions } from '@atlaskit/user-picker';
import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import emailIcon from './assets/email.very-specific-illu.png'; // very specific usage and technical limitations, discussed in ENGHEALTH-7631
import { INVITE_AND_ASSIGN_ID, potentialOption, validOption } from './constants';
import messages from './messages';

const getInviteAndAssignOption = (inputValue: string, suggestedEmailDomain?: string) => {
	const isEmail = inputValue && [validOption, potentialOption].includes(isValidEmail(inputValue));
	const value =
		isEmail || !suggestedEmailDomain
			? inputValue
			: `${inputValue.replace(/\s+/g, '.').toLocaleLowerCase()}@${suggestedEmailDomain}`;
	return value;
};

export const isValidEmailAddress = (inputValue: string) =>
	inputValue && isValidEmail(inputValue) === validOption;

export const getInviteAndAssignPeopleOption = (
	formatMessage: FormatMessage,
	input: string,
	suggestedEmailDomain?: string,
): UserOptions => {
	const possibleEmail = getInviteAndAssignOption(input, suggestedEmailDomain);
	return {
		id: INVITE_AND_ASSIGN_ID,
		name: possibleEmail,
		avatarUrl: emailIcon,
		byline: formatMessage(messages.inviteAndAssign),
		isDisabled: isValidEmail(possibleEmail) !== 'VALID',
	};
};
