import React, { useCallback } from 'react';
import { Text } from '@atlaskit/primitives';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { createAttributes } from '@atlassian/jira-forge-ui-analytics/src/common/utils/create-attributes';
import { type MessageDescriptor, useIntl } from '@atlassian/jira-intl';
import messages from './messages';

type DismissalHandler = () => readonly [dismissed: boolean, onDismiss: () => void];
export type Props = {
	dismissalHandler: DismissalHandler;
	getHasBlockedExtensions: () => boolean;
	getMainMessage: () => MessageDescriptor;
	getViewBlockedAppsLink: () => string;
};

export const BaseBlockedAppsInfoBannerUnsafe = (props: Props) => {
	const { formatMessage } = useIntl();
	const hasBlockedExtensions = props.getHasBlockedExtensions();
	const link = props.getViewBlockedAppsLink();
	const [dismissed, onDismiss] = props.dismissalHandler();
	const mainMessage = props.getMainMessage();
	if (!hasBlockedExtensions || dismissed) {
		return null;
	}

	return (
		<SectionMessage
			appearance="information"
			actions={[
				<SectionMessageAction key={messages.learnMore.id} href={link}>
					{formatMessage(messages.learnMore)}
				</SectionMessageAction>,
				<SectionMessageAction key={messages.dontShowAgain.id} onClick={onDismiss}>
					{formatMessage(messages.dontShowAgain)}
				</SectionMessageAction>,
			]}
		>
			{fg('ditto-fcf_enghealth_typography_fix') ? (
				<Text as="p">{formatMessage(mainMessage)}</Text>
			) : (
				<p>{formatMessage(mainMessage)}</p>
			)}
		</SectionMessage>
	);
};

export const BaseBlockedAppsInfoBanner = (props: Props) => {
	const getAttributes = useCallback((error: Error) => createAttributes({ error }), []);
	return (
		<JSErrorBoundary
			id="ecosystem-blocked-apps-info-banner"
			packageName="jiraCommonComponentsBlockedAppsInfoBanner"
			teamName="smite"
			fallback="unmount"
			attributes={getAttributes}
		>
			<BaseBlockedAppsInfoBannerUnsafe {...props} />
		</JSErrorBoundary>
	);
};
