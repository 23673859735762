import React, { useEffect } from 'react';
import UserAvatarCircleIcon from '@atlaskit/icon/core/migration/person-avatar--user-avatar-circle';
import { getCommandPaletteIssueActionsPriority } from '@atlassian/jira-command-palette-common/src/common/utils/get-command-palette-issue-actions-priority/index.tsx';
import { getCommandPaletteIssueActionsHeader } from '@atlassian/jira-command-palette-common/src/common/utils/index.tsx';
import { useCommandKeywords } from '@atlassian/jira-command-palette-common/src/controllers/use-command-keywords/index.tsx';
import { CommandFieldHeader } from '@atlassian/jira-command-palette-issue-actions-common/src/common/ui/command-field-header';
import { COMMAND_PALETTE_REGISTRY_IDS } from '@atlassian/jira-command-palette-registry/src/common/constants/registry/index.tsx';
import { useSubscribeCommands } from '@atlassian/jira-command-palette-registry/src/controllers/use-subscribe-commands/index.tsx';
import {
	type Command,
	CommandActionType,
} from '@atlassian/jira-command-palette/src/common/types/commands/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { AssigneeListBody } from './assignee-list-body';
import { messages } from './messages';
import type { CommandPaletteAssigneeListProps } from './types';

export const CommandPaletteAssigneeList = (props: CommandPaletteAssigneeListProps) => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const [{ canAssignIssues }] = useProjectPermissions(projectKey);
	const subscribeCP = useSubscribeCommands();
	const { formatMessage } = useIntl();
	const { getKeywords } = useCommandKeywords();

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		if (!canAssignIssues) return () => {};
		const sectionId = getCommandPaletteIssueActionsHeader(issueKey);
		const commands: Command[] = [
			{
				id: 'issue.issue-view.issue-base.assignee',
				priority: getCommandPaletteIssueActionsPriority('OLD_ISSUE_FIELDS'),
				name: formatMessage(messages.assignIssue),
				keywords: getKeywords('assignIssueSynonyms'),
				placeholder: formatMessage(messages.searchPeople),
				shortcut: 'a',
				section: sectionId,
				components: {
					LeftIcon: () => (
						<UserAvatarCircleIcon
							color="currentColor"
							label={formatMessage(messages.assignIssue)}
						/>
					),
				},
				primaryAction: {
					type: CommandActionType.COMMAND_LIST,
					components: {
						Body: (base) => <AssigneeListBody {...base} {...props} />,
						Header: (base) => <CommandFieldHeader {...base} issueKey={issueKey} />,
					},
					fallback: {
						onNoResults: {
							label: formatMessage(messages.fallbackNoResults),
						},
						onError: {
							label: formatMessage(messages.fallbackOnError),
						},
					},
				},
				analytics: {
					action: 'updateAssigneeMenu',
				},
			},
		];
		const unsubscribe = subscribeCP(COMMAND_PALETTE_REGISTRY_IDS.ISSUE_FIELD_ASSIGN, commands);

		return () => {
			unsubscribe();
		};
	}, [subscribeCP, formatMessage, issueKey, canAssignIssues, getKeywords, props]);

	return null;
};
