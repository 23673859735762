import React, { type ReactNode } from 'react';
import { NudgeSpotlight } from '@atlassiansox/nudge-tooltip';
import { Text } from '@atlaskit/primitives';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { usePulse } from './hooks';
import messages from './messages';

interface PropType {
	children: ReactNode;
	onClick: () => void;
	issueType: string;
	experimentKey: string;
	pulseLimit?: number;
	embeddedConfluenceSource?: string;
}

export const IssueViewNudgeExperiment = ({
	children,
	onClick,
	issueType,
	experimentKey,
	pulseLimit,
	embeddedConfluenceSource,
}: PropType) => {
	const { pulseEnabled, suppressPulse, onHover, onOk, onDismiss } = usePulse(
		experimentKey,
		pulseLimit,
		embeddedConfluenceSource,
	);
	const { formatMessage } = useIntl();

	return (
		<ErrorBoundary render={() => <>{children}</>}>
			<NudgeSpotlight
				key="create-confluence-content-button"
				hidden={!pulseEnabled}
				hideNudgeOnClick
				// Replace with lodash/noop
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				setHidden={() => {}}
				heading={formatMessage(messages.cardHeading)}
				onShow={onHover}
				onOutsideClick={() => {
					suppressPulse();
					onDismiss();
				}}
				actions={[
					{
						text: formatMessage(messages.ok),
						onClick: () => {
							suppressPulse();
							onClick();
							onOk();
						},
					},
				]}
				content={<Text as="p">{formatMessage(messageTypeSwitch(issueType))}</Text>}
				position="bottom-start"
				showSpotlightCardOnFirstRender={false}
				nudgeTestId="issue-view-foundation.quick-add.create-confluence-content-menu.issue-view-nudge-experiment.nudge"
				cardTestId="issue-view-foundation.quick-add.create-confluence-content-menu.issue-view-nudge-experiment.card"
			>
				{children}
			</NudgeSpotlight>
		</ErrorBoundary>
	);
};

const messageTypeSwitch = (issueType: string) => {
	switch (issueType) {
		case 'Story':
			return messages.messageStory;
		case 'Epic':
			return messages.messageEpic;
		case 'Bug':
			return messages.messageBug;
		case 'Task':
			return messages.messageTask;
		case 'Subtask':
			return messages.messageSubTask;
		default:
			return messages.messageEpic;
	}
};
