// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	configure: {
		id: 'issue.configure',
		defaultMessage: 'Configure',
		description: 'Label for the issue action item that links to the classic screen config page',
	},
	swiftBacklink: {
		id: 'issue.swift-backlink-title',
		defaultMessage: 'Back to {issueKey}',
		description:
			'Text for a link which will return the user to this page, from the Issue Type config page',
	},
});
