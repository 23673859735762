import React, {
	type Dispatch,
	type SetStateAction,
	type ReactNode,
	type SyntheticEvent,
	useState,
} from 'react';
import Button, { LoadingButton } from '@atlaskit/button';
import { Checkbox } from '@atlaskit/checkbox';
import Heading from '@atlaskit/heading';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { Box, Inline, Stack, xcss, Text } from '@atlaskit/primitives';
import TextArea from '@atlaskit/textarea';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { FeedbackData } from '../../../types';
import messages from './messages';

type Props = {
	/**
	 * When `true` the feedback popup will appear more concise as an error report, without any form inputs.
	 */
	isErrorReport?: boolean;
	isPositive: boolean;
	onClose: () => void;
	onSubmit: (data: FeedbackData, isPositive: boolean) => Promise<void>;
	setInitialFocusRef?: Dispatch<SetStateAction<HTMLElement | null>>;
};

const FEEDBACK_TEXT_FIELD = 'feedback-text';
const SHARE_PROMPT_CHECKBOX = 'share-prompt';
const CONTACT_CONSENT_PROMPT_CHECKBOX = 'contact-consent-prompt';
const RESEARCH_CONSENT_PROMT_CHECKBOX = 'research-consent-prompt';
// The height of the text area should be 160px - there is a 12px combined padding and here we have 148px height
const MAX_TEXTAREA_HEIGHT = '148px';
const MIN_TEXTAREA_ROWS = 7;

export const FeedbackPopupContent = ({
	isErrorReport,
	isPositive,
	onClose,
	onSubmit,
	setInitialFocusRef,
}: Props) => {
	const { formatMessage } = useIntl();

	const [submitting, setSubmitting] = useState<boolean>(false);

	const handleOnSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (!submitting) {
			setSubmitting(true);
			if (isErrorReport) {
				await onSubmit(
					{
						feedbackText: '',
						sharePrompt: true,
						contactConsentPrompt: false,
						researchConsentPrompt: false,
					},
					isPositive,
				);
			} else {
				const data = new FormData(event.currentTarget);
				const feedbackText = data.get(FEEDBACK_TEXT_FIELD)?.toString() ?? '';
				const sharePrompt = data.get(SHARE_PROMPT_CHECKBOX) === SHARE_PROMPT_CHECKBOX;

				const contactConsentPrompt =
					data.get(CONTACT_CONSENT_PROMPT_CHECKBOX) === CONTACT_CONSENT_PROMPT_CHECKBOX;
				const researchConsentPrompt =
					data.get(RESEARCH_CONSENT_PROMT_CHECKBOX) === RESEARCH_CONSENT_PROMT_CHECKBOX;
				await onSubmit(
					{ feedbackText, sharePrompt, contactConsentPrompt, researchConsentPrompt },
					isPositive,
				);
			}
			setSubmitting(false);
			onClose();
		}
	};

	return (
		<Box
			padding="space.300"
			xcss={containerStyles}
			testId="atlassian-intelligence.common.ui.feedback-button.feedback-popup-content.feedback-popup"
		>
			<form onSubmit={handleOnSubmit}>
				<Stack space="space.200">
					<Box>
						<Inline alignBlock="center" spread="space-between">
							<Heading size="medium">{formatMessage(messages.heading)}</Heading>
							{!isErrorReport && (
								<Button
									aria-label={formatMessage(messages.close)}
									appearance="subtle"
									iconAfter={<CrossIcon size="medium" label={formatMessage(messages.close)} />}
									onClick={onClose}
									testId="atlassian-intelligence.common.ui.feedback-button.feedback-popup-content.close-button"
								/>
							)}
						</Inline>
					</Box>
					{isErrorReport ? (
						<Box>{formatMessage(messages.errorReportDescription)}</Box>
					) : (
						<Box>
							<Box paddingBlockEnd="space.050">
								<Heading level="h200">{formatMessage(messages.textAreaHeader)}</Heading>
							</Box>

							<Box xcss={textAreaContainerStyles}>
								<TextArea
									name={FEEDBACK_TEXT_FIELD}
									appearance="none"
									minimumRows={MIN_TEXTAREA_ROWS}
									maxHeight={
										MAX_TEXTAREA_HEIGHT // This keeps the text area at a constant height alongside min rows
									}
									aria-label={formatMessage(messages.textAreaHeader)}
									testId="atlassian-intelligence.common.ui.feedback-button.feedback-popup-content.text-area"
									ref={setInitialFocusRef}
								/>
							</Box>
							<Checkbox
								name={SHARE_PROMPT_CHECKBOX}
								value={SHARE_PROMPT_CHECKBOX}
								label={
									<Text size="medium" color="color.text.subtle">
										{formatMessage(messages.shareQueryPromptJnlis612)}
									</Text>
								}
								testId="atlassian-intelligence.common.ui.feedback-button.feedback-popup-content.share-prompt-checkbox"
							/>
							<Checkbox
								name={CONTACT_CONSENT_PROMPT_CHECKBOX}
								value={CONTACT_CONSENT_PROMPT_CHECKBOX}
								label={
									<Text size="medium" color="color.text.subtle">
										{formatMessage(messages.contactConsentPromptJnlis612, {
											link: (chunks: ReactNode[]) => (
												<a
													href="https://www.atlassian.com/legal/privacy-policy#what-this-policy-covers"
													target="_blank"
												>
													{chunks}
												</a>
											),
										})}
									</Text>
								}
								testId="atlassian-intelligence.common.ui.feedback-button.feedback-popup-content.contact-consent-prompt-checkbox"
							/>
							<Checkbox
								name={RESEARCH_CONSENT_PROMT_CHECKBOX}
								value={RESEARCH_CONSENT_PROMT_CHECKBOX}
								label={
									<Text size="medium" color="color.text.subtle">
										{formatMessage(messages.researchConsentPromptJnlis612)}
									</Text>
								}
								testId="atlassian-intelligence.common.ui.feedback-button.feedback-popup-content.research-consent-prompt-checkbox"
							/>
						</Box>
					)}
					<Inline space="space.100" alignInline="end">
						{isErrorReport && (
							<Button appearance="subtle" type="button" onClick={onClose}>
								{formatMessage(messages.cancel)}
							</Button>
						)}
						<LoadingButton
							type="submit"
							appearance="primary"
							isLoading={submitting}
							testId="atlassian-intelligence.common.ui.feedback-button.feedback-popup-content.submit-button"
						>
							{formatMessage(isErrorReport ? messages.shareQuery : messages.sendFeedback)}
						</LoadingButton>
					</Inline>
				</Stack>
			</form>
		</Box>
	);
};

const containerStyles = xcss({
	width: '448px',
	borderRadius: 'border.radius',
});

const textAreaContainerStyles = xcss({
	backgroundColor: 'color.background.input',
	borderRadius: 'border.radius.100',
	padding: 'space.075',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	border: `2px solid ${token('color.border.input', colors.N30)}`,
	marginBottom: 'space.200',
});
