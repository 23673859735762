import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Action } from '@atlassian/react-sweet-state';
import type { State, ContainerProps } from '../../types';
import type { GetLimitsResponse } from './types';

export const getLimits =
	(): Action<State, ContainerProps> =>
	async ({ setState }, { cloudId, analyticsEvent, issueId }) => {
		if (!issueId) {
			setState({
				stakeholders: {
					error: 'Missing issueId',
				},
			});
			return;
		}

		try {
			const limits: GetLimitsResponse = await fetchJson(
				`/gateway/api/jsm/stakeholders/cloudId/${cloudId}/v1/stakeholders/feature-gating`,
			);

			setState({ limits });
			// https://data-portal.internal.atlassian.com/analytics/registry/59456
			fireOperationalAnalytics(analyticsEvent, 'getLimits succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			// https://data-portal.internal.atlassian.com/analytics/registry/59457
			fireErrorAnalytics({
				event: analyticsEvent,
				meta: {
					id: 'getLimits',
					packageName: 'jiraJsmStakeholderUpdates',
					teamName: 'itsm-agni',
				},
				error: e,
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	};
