import React from 'react';
import { ReadPopup } from '@atlassian/data-classification-level';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages';

type ReadViewProps = {
	name?: string;
	guideline?: string;
	onChangeLevelClick?: () => void;
};

export const ClassificationReadViewPopup = ({
	name,
	guideline,
	onChangeLevelClick,
}: ReadViewProps) => {
	const { formatMessage } = useIntl();

	return (
		<ReadPopup
			name={name}
			guideline={guideline || formatMessage(messages.noGuidelines)}
			onChangeLevelClick={onChangeLevelClick}
		/>
	);
};
