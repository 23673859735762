import React from 'react';
import { styled } from '@compiled/react';
import { N200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import timeMessages from '../../../../../messages/time';

const TimeTrackingHelperText = () => {
	const { formatMessage } = useIntl();
	return (
		<HelperContainer>
			<p>
				{formatMessage(timeMessages.exampleFormat, {
					formatExample: '2w 4d 6h 45m',
				})}
			</p>
			<HelperList>
				<li>{formatMessage(timeMessages.weeks, { w: 'w' })}</li>
				<li>{formatMessage(timeMessages.days, { d: 'd' })}</li>
				<li>{formatMessage(timeMessages.hours, { h: 'h' })}</li>
				<li>{formatMessage(timeMessages.minutes, { m: 'm' })}</li>
			</HelperList>
		</HelperContainer>
	);
};

export default TimeTrackingHelperText;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HelperContainer = styled.div({
	padding: `${token('space.075', '6px')} ${token('space.025', '2px')}`,
	color: token('color.background.neutral.bold', N200),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HelperList = styled.ul({
	listStyle: 'inside',
	marginTop: token('space.100', '8px'),
	paddingLeft: token('space.050', '4px'),
	paddingBottom: token('space.050', '4px'),
});
