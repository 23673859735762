import React, { createContext, type ReactNode, useContext } from 'react';

import { di } from 'react-magnetic-di';

import { type DocNode } from '@atlaskit/adf-schema';
import { fg } from '@atlaskit/platform-feature-flags';

import { setRovoChatProduct } from '../../common/utils/product-config';

import { type AgentAction } from './agent-actions/types';
import { type MessageActionPayload, type MessageActionResponse } from './message-actions/types';
import { readJsonStream } from './read-json-stream';
import {
	type Agent,
	type AgentDetails,
	type AgentFileUploadToken,
	type AgentKnowledgeConfigurationResponse,
	type AssistanceService,
	type AssistanceServiceConfig,
	type AssistanceServiceProduct,
	type Chat,
	type ChatMessage,
	type ConversationChannel,
	type Dialogues,
	type Features,
	type GeneratedConversationStarters,
	isHumanMessage,
	type PostAgentPayload,
	type RawChat,
	type SendMessageBrowserContext,
	type SendMessageEditorContext,
	type StreamConversationStarter,
	type StreamMessage,
	type UserFeedbackPayload,
} from './types';
import { addPath, processResponse } from './utils/client';
import { generateConversationName } from './utils/conversation';
import { toStreamResponse } from './utils/data';
import { fetchFn } from './utils/fetch';

type FetchOptions = {
	path: string;
	options: RequestInit;
	throwResponseOnError?: boolean;
};

const PRODUCT_HEADER = 'x-product';
const EXPERIENCE_ID_HEADER = 'x-experience-id';

export class AssistanceServiceImpl implements AssistanceService {
	private config: Required<AssistanceServiceConfig>;

	public getConfig = () => {
		return {
			...this.config,
		};
	};

	constructor(props: AssistanceServiceConfig) {
		const defaultConfig = {
			baseUrl: '/gateway/api/assist',
			headers: {},
		};

		this.config = {
			...props,
			baseUrl: props.baseUrl || defaultConfig.baseUrl,
			headers: {
				...(props.headers || defaultConfig.headers),
			},
		};
	}

	private createHeaders(init?: HeadersInit): Headers {
		return new Headers({
			[PRODUCT_HEADER]: this.config.product,
			[EXPERIENCE_ID_HEADER]: this.config.experienceId,
			...(this.config.headers || {}),
			...(init || {}),
		});
	}

	private async fetch({ path, options, throwResponseOnError = false }: FetchOptions) {
		const { baseUrl } = this.config;
		const requestUrl = addPath(baseUrl, path);
		const response = await fetchFn(requestUrl, options);

		return processResponse({ response, throwResponseOnError });
	}

	private async fetchJson<T>({ path, options, throwResponseOnError }: FetchOptions): Promise<T> {
		const response = await this.fetch({ path, options, throwResponseOnError });
		const json = await response.json();
		return json;
	}

	private async fetchStream<T>({ path, options, throwResponseOnError }: FetchOptions) {
		const response = await this.fetch({ path, options, throwResponseOnError });
		return readJsonStream<T>(response);
	}

	public async sendMessageStream({
		agentNamedId,
		agentId,
		message,
		conversationId,
		controller,
		storeMessage = false,
		citationsEnabled = true,
		editorContext,
		browserContext,
		additionalContext,
	}: {
		conversationId: string;
		message: string | DocNode;
		agentNamedId: string;
		agentId?: string;
		controller?: AbortController;
		storeMessage?: boolean;
		citationsEnabled?: boolean;
		editorContext?: SendMessageEditorContext;
		browserContext?: SendMessageBrowserContext;
		additionalContext?: Record<string, unknown>;
	}): Promise<AsyncGenerator<StreamMessage>> {
		const headers = this.createHeaders({
			'content-type': 'application/json;charset=UTF-8',
		});
		// If browserContext received and context is undefined, assume we're unable to retrieve the context of the page in focus
		const url = browserContext ? browserContext.context?.browserUrl : window?.location?.href;
		const body = {
			content: message,
			context: {
				browser_url: url,
				...(editorContext ? { editor: editorContext } : {}),
				// If browserUrl is inaccessable, fallback to htmlBody or canvasText if they exist
				...(browserContext
					? {
							browser: {
								htmlBody: browserContext.context?.htmlBody,
								canvasText: browserContext.context?.canvasText,
							},
						}
					: {}),
				...additionalContext,
			},
			recipient_agent_named_id: agentNamedId,
			agent_id: agentId,
			mimeType: typeof message === 'string' ? 'text/markdown' : 'text/adf',
			// don't allow store message if feature flag is off
			store_message: fg('ai_mate_show_store_messages') ? storeMessage : false,
			citations_enabled: citationsEnabled,
		};
		const options: RequestInit = {
			method: 'POST',
			headers: headers,
			body: JSON.stringify(body),
			signal: controller?.signal,
		};

		return this.fetchStream<StreamMessage>({
			path: `/chat/v1/channel/${conversationId}/message/stream`,
			options,
		});
	}

	public async getChat(conversationId: string): Promise<Chat> {
		const headers = this.createHeaders();
		const options = {
			method: 'GET',
			headers: headers,
		};

		const rawChat = await this.fetchJson<RawChat>({
			path: `/chat/v1/channel/${conversationId}/messages`,
			options,
		});

		return rawChat.map(
			(message): ChatMessage =>
				// Must be converted into StreamResponse
				isHumanMessage(message) ? message : toStreamResponse(message),
		);
	}

	public async deleteChat(conversationId: string, controller?: AbortController): Promise<void> {
		const headers = this.createHeaders();
		const options = {
			method: 'DELETE',
			headers: headers,
			signal: controller?.signal,
		};
		await this.fetch({ path: `/chat/v1/channel/${conversationId}`, options });
	}

	public async getFeatures(controller?: AbortController) {
		const headers = this.createHeaders();
		const options = {
			method: 'GET',
			headers: headers,
			signal: controller?.signal,
		};
		return this.fetchJson<Features>({ path: 'features', options });
	}

	public async getAgents(controller?: AbortController): Promise<Agent[]> {
		const headers = this.createHeaders();
		const options = {
			method: 'GET',
			headers: headers,
			signal: controller?.signal,
		};
		return this.fetchJson<Agent[]>({ path: 'agents/v1', options });
	}

	public async getAgentDetails(id: string, controller?: AbortController): Promise<AgentDetails> {
		const headers = this.createHeaders();
		const options = {
			method: 'GET',
			headers: headers,
			signal: controller?.signal,
		};
		return this.fetchJson<AgentDetails>({ path: `agents/v1/${id}`, options });
	}

	public async getAgentDetailsByIdentityAccountId(
		identityAccountId: string,
		controller?: AbortController,
	): Promise<AgentDetails> {
		const headers = this.createHeaders();
		const options = {
			method: 'GET',
			headers: headers,
			signal: controller?.signal,
		};
		return this.fetchJson<AgentDetails>({
			path: `agents/v1/accountid/${identityAccountId}`,
			options,
		});
	}

	public async getAgentFileUploadToken(
		controller?: AbortController,
	): Promise<AgentFileUploadToken> {
		const headers = this.createHeaders();
		const options = {
			method: 'GET',
			headers: headers,
			signal: controller?.signal,
		};

		return this.fetchJson<AgentFileUploadToken>({
			path: 'agents/v1/media/upload/credentials',
			options,
		});
	}

	public async createAgent(
		payload: PostAgentPayload,
		controller?: AbortController,
	): Promise<Agent> {
		const headers = this.createHeaders({
			'content-type': 'application/json;charset=UTF-8',
		});
		const options = {
			method: 'POST',
			headers: headers,
			body: JSON.stringify(payload),
			signal: controller?.signal,
		};
		return this.fetchJson<Agent>({
			path: '/agents/v1',
			options,
		});
	}

	public async updateAgent(
		id: string,
		payload: PostAgentPayload,
		controller?: AbortController,
	): Promise<Agent> {
		const headers = this.createHeaders({
			'content-type': 'application/json;charset=UTF-8',
		});
		const options = {
			method: 'PUT',
			headers: headers,
			body: JSON.stringify(payload),
			signal: controller?.signal,
		};
		const response = await this.fetchJson<Agent>({
			path: `/agents/v1/${id}`,
			options,
			throwResponseOnError: true,
		});

		return response;
	}

	public async deleteAgent(id: string, controller?: AbortController): Promise<void> {
		const headers = this.createHeaders();
		const options = {
			method: 'DELETE',
			headers: headers,
			signal: controller?.signal,
		};
		return this.fetchJson<void>({
			path: `/agents/v1/${id}`,
			options,
			throwResponseOnError: true,
		});
	}

	public async favouriteAgent(id: string, controller?: AbortController): Promise<void> {
		const headers = this.createHeaders();
		const options = {
			method: 'POST',
			headers: headers,
			signal: controller?.signal,
		};
		this.fetch({
			path: `/agents/v1/${id}/favourite`,
			options,
			throwResponseOnError: true,
		});
	}

	public async unfavouriteAgent(id: string, controller?: AbortController): Promise<void> {
		const headers = this.createHeaders();
		const options = {
			method: 'DELETE',
			headers: headers,
			signal: controller?.signal,
		};
		this.fetch({
			path: `/agents/v1/${id}/favourite`,
			options,
			throwResponseOnError: true,
		});
	}

	public async createConversationChannel(options?: { name?: string; dialogues?: Dialogues[] }) {
		return this.fetchJson<Required<ConversationChannel>>({
			path: '/chat/v1/channel',
			options: {
				method: 'POST',
				headers: this.createHeaders({
					'content-type': 'application/json;charset=UTF-8',
				}),
				body: JSON.stringify({
					name: options?.name ?? generateConversationName(),
					dialogues: options?.dialogues ?? undefined,
				}),
			},
		});
	}

	public async getConversationChannels(): Promise<ConversationChannel[]> {
		return this.fetchJson<ConversationChannel[]>({
			path: '/chat/v1/channels',
			options: {
				method: 'GET',
				headers: this.createHeaders(),
			},
		});
	}

	public updateConversationChannel(id: string, payload: Partial<ConversationChannel>) {
		return this.fetchJson<ConversationChannel>({
			path: `/chat/v1/channel/${id}`,
			options: {
				method: 'PUT',
				headers: this.createHeaders({
					'content-type': 'application/json;charset=UTF-8',
				}),
				body: JSON.stringify(payload),
			},
		});
	}

	public async resolveConversationAction(id: string, payload: MessageActionPayload) {
		return this.fetchJson<MessageActionResponse>({
			path: `/chat/v1/channel/${id}/action`,
			options: {
				method: 'POST',
				headers: this.createHeaders({
					'content-type': 'application/json;charset=UTF-8',
				}),
				body: JSON.stringify({
					...payload,
					context: {
						...payload.context,
						browser_url: window.location.href,
					},
				}),
			},
		});
	}

	public async speechToText(blob: Blob) {
		const formData = new FormData();
		formData.append('audio_recording', blob);

		const response = await this.fetchJson<{
			transcription: string;
		}>({
			path: '/speech/inference',
			options: {
				method: 'POST',
				headers: this.createHeaders(),
				body: formData,
			},
		});

		return response.transcription;
	}

	public async generateConversationStarters(payload: {
		name?: string;
		description?: string;
		instructions?: string;
	}): Promise<GeneratedConversationStarters> {
		const headers = this.createHeaders({
			'content-type': 'application/json;charset=UTF-8',
		});
		const options = {
			method: 'POST',
			headers,
			body: JSON.stringify(payload),
		};
		return this.fetchJson<GeneratedConversationStarters>({
			path: '/agents/v1/suggest-conversation-starter',
			options,
		});
	}

	public async generateContextualConversationStarters(payload: {
		recipient_agent_named_id: string;
		context: {
			browser_url: string;
		};
	}): Promise<AsyncGenerator<StreamConversationStarter>> {
		const headers = this.createHeaders({
			'content-type': 'application/json;charset=UTF-8',
		});
		const options = {
			method: 'POST',
			headers,
			body: JSON.stringify(payload),
		};
		return this.fetchStream<StreamConversationStarter>({
			path: '/agents/v1/conversation-starter',
			options,
		});
	}

	public getAgentActions = async (): Promise<AgentAction[]> => {
		const response = await this.fetchJson<{
			actions: AgentAction[];
		}>({
			path: '/agents/configuration/v1/actions',
			options: {
				method: 'GET',
				headers: this.createHeaders(),
			},
		});

		return response.actions;
	};

	public getAgentKnowledgeConfiguration = (): Promise<AgentKnowledgeConfigurationResponse> => {
		return this.fetchJson<AgentKnowledgeConfigurationResponse>({
			path: '/agents/configuration/v1/knowledge',
			options: {
				method: 'GET',
				headers: this.createHeaders(),
			},
		});
	};

	public async submitUserFeedback(
		payload: UserFeedbackPayload,
		controller?: AbortController,
	): Promise<void> {
		const headers = this.createHeaders({
			'content-type': 'application/json;charset=UTF-8',
		});
		const { conversation_channel_id, message_id } = payload;
		const options = {
			method: 'PUT',
			headers: headers,
			body: JSON.stringify(payload),
			signal: controller?.signal,
		};
		await this.fetch({
			path: `/chat/v1/channel/${conversation_channel_id}/message/${message_id}/feedback`,
			options,
		});
	}
}

const AssistanceServiceContext = createContext<AssistanceService>(
	new AssistanceServiceImpl({
		baseUrl: '',
		product: '',
		experienceId: '',
	}),
);

export const AssistanceServiceProvider = ({
	value,
	children,
}: {
	value: AssistanceService;
	children: ReactNode;
}) => (
	<AssistanceServiceContext.Provider value={value}>{children}</AssistanceServiceContext.Provider>
);

export type UseAssistanceServiceParams = {
	/** If a parent AssistanceServiceProvider is not rendered, this field MUST be provided in order for agents to work */
	product?: AssistanceServiceProduct;
	/** Defaults to `ai-mate` */
	experienceId?: string;
};

/** If these props are provided and one of them are populated, they will take precedence over the `AssistanceServiceProvider` with the missing props filled in */
export const useAssistanceService = (props?: UseAssistanceServiceParams) => {
	di(useContext, AssistanceServiceImpl);

	const service = useContext(AssistanceServiceContext);

	setRovoChatProduct(props?.product ?? service.getConfig().product);

	if (!props || (!props.experienceId && !props.product)) {
		return service;
	}

	const product = props.product ?? service.getConfig().product;

	return new AssistanceServiceImpl({
		...service.getConfig(),
		product,
		experienceId: props.experienceId ?? service.getConfig().experienceId,
	});
};
