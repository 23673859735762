import React from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { ConnectedProformaFormsView } from './ui';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyProformaForms = lazyAfterPaint<typeof ConnectedProformaFormsView>(
	() =>
		import(/* webpackChunkName: "async-jira-proforma-panel-in-issue-view" */ './index').then(
			(module) => module.ConnectedProformaFormsView,
		),
	{ ssr: false },
);

type Props = {
	isProjectAdmin: boolean;
	canEditIssues: boolean;
	panelAddFormButtonClickedCount: number;
};

export const ProformaFormsViewLazy = (props: Props) => (
	<Placeholder name="jira-proforma-panel-in-issue-view" fallback={null}>
		<LazyProformaForms {...props} />
	</Placeholder>
);
