import { getUserProperty, setUserProperty } from '../../rest/user-properties';

const OPT_OUT_DEPLOYMENTS_DISCOVERY = 'open-toolchain-opt-user-out-deployments-ddd-discovery';

export const getOptOutDeploymentsDiscovery = (accountId: string): Promise<boolean> =>
	getUserProperty<boolean>({
		accountId,
		propertyKey: OPT_OUT_DEPLOYMENTS_DISCOVERY,
		defaultValue: false,
	}).then((property) => property.value);

export const setOptOutDeploymentsDiscovery = (accountId: string) =>
	setUserProperty<boolean>({
		accountId,
		propertyValue: true,
		propertyKey: OPT_OUT_DEPLOYMENTS_DISCOVERY,
	});
