import { createSelector } from 'reselect';
import flatten from 'lodash/fp/flatten';
import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';
import uniq from 'lodash/fp/uniq';
import { REMOTE_LINK_TYPES } from '../../../../model/remote-links';
import { getDetailsState } from '../index';

export const getRemoteLinkState = createSelector(getDetailsState, (details) =>
	details.details.remoteLinkInformation
		? details.details.remoteLinkInformation
		: { numProviders: 0, remoteLinksByType: {} },
);

export const isEmpty = createSelector(
	getRemoteLinkState,
	(remoteLinksInfo) => Object.keys(remoteLinksInfo.remoteLinksByType).length === 0,
);

// @ts-expect-error - TS2739 - Type '{}' is missing the following properties from type 'RemoteLinkInformation': numProviders, remoteLinksByType
export const getRemoteLinksByType = createSelector(getRemoteLinkState, (remoteLinkInfo = {}) => {
	const { remoteLinksByType } = remoteLinkInfo;
	return Object.keys(remoteLinksByType)
		.reduce(
			(remoteLinks, type) => {
				if (!remoteLinksByType[type]) {
					return remoteLinks;
				}
				// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type '"other" | "security" | "alert" | "document" | "test" | "logFile" | "prototype" | "coverage" | "bugReport"'.
				if (REMOTE_LINK_TYPES.includes(type)) {
					remoteLinks.push({
						remoteLinkType: type,
						// @ts-expect-error - TS2322 - Type 'RemoteLink[]' is not assignable to type 'never[]'.
						remoteLinks: remoteLinksByType[type],
					});
				} else {
					// it's unknown - add it to the object with all unknowns
					// @ts-expect-error - TS2345 - Argument of type 'RemoteLink' is not assignable to parameter of type 'never'.
					remoteLinks[0].remoteLinks.push(...remoteLinksByType[type]);
				}

				return remoteLinks;
			},
			[
				{
					remoteLinkType: 'unknown',
					remoteLinks: [],
				},
			],
		)
		.filter((remoteLinks) => remoteLinks.remoteLinks.length > 0);
});

export const getRemoteLinkProviders = createSelector(
	getRemoteLinkState,
	pipe(
		(remoteLinkInfo) => remoteLinkInfo.remoteLinksByType,
		map('remoteLinks'),
		flatten,
		map('provider.id'),
		uniq,
	),
);

export const getRemoteLinkProvidersCount = createSelector(
	getRemoteLinkState,
	(remoteLinkInfo) => remoteLinkInfo.numProviders,
);

export const getRemoteLinksTotalCount = createSelector(getRemoteLinkState, (remoteLinkInfo) =>
	Object.keys(remoteLinkInfo.remoteLinksByType).reduce(
		(acc, remoteLinkType) => acc + remoteLinkInfo.remoteLinksByType[remoteLinkType].length,
		0,
	),
);

export const getRemoteLinksSummary = createSelector(getRemoteLinkState, (remoteLinkInfo) => {
	const links: Record<string, string>[] = [];
	Object.keys(remoteLinkInfo.remoteLinksByType).forEach((type) => {
		remoteLinkInfo.remoteLinksByType[type].forEach((link) => {
			links.push({
				RemoteLinkId: link.id,
				RemoteLinkProviderId: link.provider.id,
				RemoteLinkProviderName: link.provider.name,
				RemoteLinkType: link.type,
				RemoteLinkStatus: link.status ? link.status.label : '',
				RemoteLinkDescription: link.description ? link.description : '',
			});
		});
	});
	return links;
});
