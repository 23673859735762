// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'issue.forge.labels.text-for-edit-view-when-no-value-is-provided',
		defaultMessage: 'Select label',
		description:
			'Label placeholder displayed in a custom field when this field is in edit mode and no values are provided',
	},
	createNewItem: {
		id: 'issue.forge.labels.create-new-item-message',
		defaultMessage: '{value} (New label)',
		description:
			'List element displayed in a custom field when user entered value and no autocomplete values exist',
	},
});
