import { fg } from '@atlaskit/platform-feature-flags';
import { getExperimentCohort } from '@atlassian/generative-ai-modal/utils/experiments';

import type { EditorPluginAIProvider } from '../../types';

export const getConfiguration = (editorPluginAIProvider: EditorPluginAIProvider) => {
	// NOTE: We need to ensure the feature gate is evaluated last. This is beacuse if the product hasn't enabled
	// the feature then we don't want to hit the gate check since it's not possible for that product to have
	// the feature.
	let isProactiveAISupported =
		!!editorPluginAIProvider.proactiveAIConfig?.apiUrl &&
		!!editorPluginAIProvider.proactiveAIConfig?.enabled &&
		!!fg('platform_editor_ai_proactive_spelling_and_grammar');

	let proactiveAIConfig = editorPluginAIProvider.proactiveAIConfig;

	// We should only eval the experiment if the previous feature gate passed
	if (isProactiveAISupported) {
		const variation = getExperimentCohort('editor_ai_-_proactive_ai_spelling_and_grammar');

		if (variation === 'control' || variation === 'not-enrolled') {
			isProactiveAISupported = false;
		} else {
			proactiveAIConfig = {
				...proactiveAIConfig,
				defaultToggledState: variation === 'default-on',
				documentSGChecker: proactiveAIConfig?.documentSGChecker
					? {
							...proactiveAIConfig?.documentSGChecker,
							enabled: variation === 'default-off',
						}
					: undefined,
			};
		}
	}

	return {
		isProactiveAISupported,
		proactiveAIConfig,
	};
};
