// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'issue.customer-request-type.text-for-edit-view-when-no-value-is-provided',
		defaultMessage: 'Select Request Type',
		description: 'Empty state text when no request type is provided',
	},
	incompatibleRequestTypeWithHelpLink: {
		id: 'issue.customer-request-type.incompatible-request-type-with-help-link',
		defaultMessage:
			'Select a new request type from the dropdown. Request types must have the same issue type as the issue. {learnMore}',
		description: 'Incompatible request type inline popup message with help link',
	},
	incompatibleRequestTypeErrorIconLabel: {
		id: 'issue.customer-request-type.incompatible-request-type-error-icon-label',
		defaultMessage:
			'Select a new request type from the dropdown. Request types must have the same issue type as the issue.',
		description:
			'Incompatible request type error message to display - this is used in aria-label in ErrorIcon for accesiblity',
	},
	learnMoreAboutIncompatibleRequestType: {
		id: 'issue.customer-request-type.learn-more-about-incompatible-request-type',
		defaultMessage: 'Learn more about the connection between issue types and request types.',
		description: 'Link text to learn more about incompatible request type',
	},
	noOptions: {
		id: 'issue.customer-request-type.no-options',
		defaultMessage: 'No matches found. Create a new request type with the {issueType} issue type.',
		description:
			'Text to show in the request type field dropdown when there are no options to select',
	},
});
