import { useCallback, useEffect, useRef } from 'react';

import { bind } from 'bind-event-listener';
import { createHook, createSelector, createStore } from 'react-sweet-state';

import { type ApiError, mapErrorToApiError } from '../api-error';

type CacheEntry<T = any> = {
	data: T | null;
	error: ApiError | null;
	isFetching: boolean;
};

type QueryState = { cache: Record<string, CacheEntry> };

const store = createStore({
	initialState: {
		cache: {},
	} as QueryState,
	actions: {
		updateCacheEntry:
			(key: string, value: Partial<CacheEntry>) =>
			({ setState, getState }) => {
				const state = getState();

				const currentCacheEntry = state.cache[key] ?? {
					data: null,
					error: null,
					isFetching: false,
				};
				const newCacheEntry = { ...currentCacheEntry, ...value };

				setState({
					cache: {
						...state.cache,
						[key]: newCacheEntry,
					},
				});
			},
		getCacheEntry:
			(key: string) =>
			({ getState }) => {
				const state = getState();
				return state.cache[key];
			},
	},
});

const selector = createSelector(
	(state: QueryState) => state.cache,
	(_, key: string) => key,
	(cache, key) => cache[key] ?? { data: null, error: null, isFetching: false },
);

const useCacheEntry = createHook(store, {
	selector,
});

type UseQueryProps<TResponse> = {
	/**
	 * Requests are cached based on the stringified queryKey.
	 * E.g ["fetchAgents", "agent-1"]
	 */
	queryKey: (string | number)[];
	queryFn: () => Promise<TResponse>;
	onSuccess?: (response: TResponse) => void;
	onError?: (error: ApiError) => void;
	/** Set to false to disable automatically fetching */
	enabled?: boolean;
	refetchOnWindowFocus?: boolean;
};

export const useQuery = <TResponse>({
	queryKey: queryKeyParts,
	queryFn,
	onSuccess,
	onError,
	enabled = true,
	refetchOnWindowFocus = false,
}: UseQueryProps<TResponse>): {
	data: TResponse | null;
	error: ApiError | null;
	isFetching: boolean;
	refetch: () => Promise<void>;
} => {
	const queryKey = queryKeyParts.map((x) => x?.toString()).join('_');
	const queryFnRef = useRef(queryFn);
	queryFnRef.current = queryFn;

	const onSuccessRef = useRef(onSuccess);
	onSuccessRef.current = onSuccess;

	const onErrorRef = useRef(onError);
	onErrorRef.current = onError;
	const [cache, { updateCacheEntry, getCacheEntry }] = useCacheEntry(queryKey);

	const fetchData = useCallback(async () => {
		updateCacheEntry(queryKey, { isFetching: true });

		try {
			const response = await queryFnRef.current();
			updateCacheEntry(queryKey, { data: response });

			onSuccessRef.current?.(response);
		} catch (error: any) {
			const apiError = mapErrorToApiError(error);
			updateCacheEntry(queryKey, { error: apiError });
			onErrorRef.current?.(apiError);
		} finally {
			updateCacheEntry(queryKey, { isFetching: false });
		}
	}, [updateCacheEntry, queryKey]);

	useEffect(() => {
		if (enabled && refetchOnWindowFocus) {
			const unbind = bind(window, {
				type: 'focus',
				listener: () => {
					if (enabled && refetchOnWindowFocus && !getCacheEntry(queryKey)?.isFetching) {
						fetchData();
					}
				},
			});

			return unbind;
		}
	}, [enabled, fetchData, getCacheEntry, queryKey, refetchOnWindowFocus]);

	useEffect(() => {
		if (!getCacheEntry(queryKey) && enabled) {
			fetchData();
		}
	}, [queryKey, fetchData, enabled, getCacheEntry]);

	return { data: cache.data, error: cache.error, isFetching: cache.isFetching, refetch: fetchData };
};
