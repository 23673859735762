import React, { useMemo } from 'react';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ForgeScreenEvent } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { DateField } from '@atlassian/jira-issue-field-date/src/ui/main.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading';
import {
	FieldHeadingTitle,
	SideBySideField,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages';
import { DateTimeFieldWrapper } from '../../../../../date-time/main.tsx';
import { useAnalyticsAttributesContext } from '../../analytics/atrributes-context';
import type { CustomFieldProps } from '../../types';

export type Props = CustomFieldProps<string>;

export const View = ({
	label,
	showPinButton,
	fieldId,
	customReadView,
	onEditRequest,
	isEditing,
	onCancel,
	value,
	shouldFireScreenEvent,
	fieldContentWrapper,
	titleView = label,
	extension,
	renderAppEditView,
}: Props) => {
	const issueKey = useIssueKey();
	const { formatMessage } = useIntl();

	// If app does not specifies edit view than we go with native edit view which will rely on inner state of DateField
	const isControlledEditing = extension.properties.edit ? isEditing : undefined;

	const analyticsAttributes = useAnalyticsAttributesContext();
	const attributes = useMemo(
		() => ({ ...analyticsAttributes, renderMode: 'default' }),
		[analyticsAttributes],
	);

	return (
		<DateTimeFieldWrapper>
			<FieldHeading>
				<FieldHeadingTitle>{titleView}</FieldHeadingTitle>
				{showPinButton && <FieldPin fieldId={fieldId} label={label} />}
			</FieldHeading>
			<SideBySideField isEditing={isEditing}>
				<ContentWrapper fieldContentWrapper={fieldContentWrapper}>
					{shouldFireScreenEvent && <ForgeScreenEvent attributes={attributes} />}
					<DateField
						isFitContainerWidthReadView
						readView={customReadView ? customReadView(value) : undefined}
						onEdit={onEditRequest}
						noValueText={formatMessage(genericMessages.noValue)}
						issueKey={issueKey}
						fieldKey={fieldId}
						onCancel={onCancel}
						isEditing={isControlledEditing}
						{...(fg('forge_cf_inline_edit_on_issue_view') ? { renderAppEditView } : {})}
					/>
				</ContentWrapper>
			</SideBySideField>
		</DateTimeFieldWrapper>
	);
};

export const ContentWrapper = (
	props: React.PropsWithChildren<Pick<Props, 'fieldContentWrapper'>>,
) => {
	const { fieldContentWrapper: FieldContentWrapper } = props;

	if (FieldContentWrapper) {
		return <FieldContentWrapper topMargin={0.75 * gridSize}>{props.children}</FieldContentWrapper>;
	}

	return <>{props.children}</>;
};

export default View;
