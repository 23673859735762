import {
	toAnalyticsProjectType,
	toProjectType,
} from '@atlassian/jira-common-constants/src/project-types';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import {
	NO_PRACTICE,
	practiceKeyJqlNameMapping,
} from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';

export async function getLinkedIssue(issueId: string): Promise<LinkedIssue | undefined> {
	try {
		const WORK_CATEGORY_FIELD_ID_URL = '/rest/internal/1/work-category/id';
		const workCategoryFieldId = await performGetRequest<{ id?: number }>(
			WORK_CATEGORY_FIELD_ID_URL,
		);
		if (workCategoryFieldId === undefined) return undefined;

		const linkedIssue = await performGetRequest<LinkedIssue>(
			`/rest/api/3/issue/${issueId}?fields=project,customfield_${workCategoryFieldId.id || ''}`,
		);

		return linkedIssue;
	} catch (error: unknown) {
		return undefined;
	}
}

export function getProjectType(linkedIssue: LinkedIssue | undefined): string | undefined {
	const fieldsMap = new Map(Object.entries(linkedIssue?.fields || {}));
	const project = fieldsMap.get('project');
	if (typeof project === 'object') {
		return toAnalyticsProjectType(toProjectType(project.projectTypeKey));
	}
	return undefined;
}

export function getItsmPractice(linkedIssue: LinkedIssue | undefined): string | undefined {
	const fieldsMap = new Map(Object.entries(linkedIssue?.fields || {}));
	for (const [key, fieldValue] of fieldsMap) {
		if (key.startsWith('customfield_')) {
			if (typeof fieldValue === 'string') {
				for (const [itsmPractice, itsmPracticeJqlName] of Object.entries(
					practiceKeyJqlNameMapping,
				)) {
					if (itsmPracticeJqlName === fieldValue) return itsmPractice;
				}
				return NO_PRACTICE;
			}
		}
	}
	return undefined;
}

type LinkedIssue = {
	fields?: object;
};
