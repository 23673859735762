import { defineMessages } from 'react-intl-next';

export default defineMessages({
	logoAltText: {
		id: 'fabric.editor.ai.screens.prompt.logoAltText',
		defaultMessage: 'Atlassian Intelligence Logo',
		description: 'Atlassian Intelligence logo alt text of the Prompt screen',
	},
	logoText: {
		id: 'fabric.editor.ai.screens.prompt.logoText',
		defaultMessage: 'Atlassian Intelligence',
		description: 'Atlassian Intelligence Logo text of the Prompt screen',
	},
	betaLabel: {
		id: 'fabric.editor.ai.screens.preview.betaLabel',
		defaultMessage: 'Beta',
		description: 'The label describing the beta status of the Atlassian Intelligence feature',
	},
	cancelLabel: {
		id: 'fabric.editor.ai.screens.preview.cancelLabel',
		defaultMessage: 'Cancel',
		description: 'The action text for the cancel button to discard the previewed generated content',
	},
	discardLabel: {
		id: 'fabric.editor.ai.screens.preview.discardLabel',
		defaultMessage: 'Discard',
		description: 'The action text for the discard button to cancel the previewed generated content',
	},
	defaultInputPlaceholder: {
		id: 'fabric.editor.ai.screens.preview.defaultAIPlaceholder',
		defaultMessage: 'Tell AI what to do next',
		description:
			'The default placeholder text for the prompt input field within the Preview screen',
	},
	placeholderWithAgent: {
		id: 'fabric.editor.ai.screens.preview.placeholderWithAgent',
		defaultMessage: 'Ask {agentName} what to do next',
		description:
			'The placeholder text for the prompt input field within the Preview screen when an agent is selected',
	},
	disclaimer: {
		id: 'fabric.editor.ai.screens.preview.disclaimer',
		defaultMessage: 'Information quality may vary.',
		description: 'The text for the generated content disclaimer',
	},
	disclaimerLink: {
		id: 'fabric.editor.ai.screens.preview.disclaimerLink',
		defaultMessage: 'Learn why',
		description: 'The text for the generated content disclaimer link',
	},
	previewScreenLabel: {
		id: 'fabric.editor.ai.experience.previewScreenLabel',
		defaultMessage: 'Preview',
		description: 'Accessible label for the AI response preview screen of the AI modal',
	},
});
