import React, { useEffect } from 'react';
import { ConfluenceFeatureKeys } from '@atlassian/jira-confluence-integration-controls/src/constants.tsx';
import { useIsCrossSellEnabled } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-is-cross-sell-enabled/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { JIRA_ISSUE_LINKED_MENTIONED_PAGES } from '@atlassian/jira-issue-create-confluence-content/src/common/constants/embedded-confluence-source.tsx';
import { linkedMentionedContentPreviewEntrypoint } from '@atlassian/jira-issue-create-confluence-content/src/ui/linked-mentioned-content-preview/entrypoint';

interface LinkedMentionedContentPreviewProps {
	contentCount: number;
}

const entryPointParams = {};
export const LinkedMentionedContentPreview = ({
	contentCount,
}: LinkedMentionedContentPreviewProps) => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		linkedMentionedContentPreviewEntrypoint,
		entryPointParams,
	);

	const isCrossSellEnabled = useIsCrossSellEnabled(
		ConfluenceFeatureKeys.CREATE_MENU_CONFLUENCE_CONTENT,
	);

	const [embedConfluenceBentoConfig, embedConfluenceBentofireExposureEvent] = UNSAFE_noExposureExp(
		'platform_embed-conf_bento_linked_crossjoin_exp',
	);
	const embedConfluenceBentoCohort = embedConfluenceBentoConfig.get('cohort', 'not-enrolled');

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		// Fire exposure events
		if (ff('platform.embedded-confluence.jira-issue.linked-page-cross-join')) {
			embedConfluenceBentofireExposureEvent();
		}

		// If enrolled in experiment cohort, display the content preview
		if (
			ff('platform.embedded-confluence.jira-issue.linked-page-cross-join') &&
			embedConfluenceBentoCohort === 'experiment' &&
			isCrossSellEnabled
		) {
			entryPointActions.load();
		}
	}, [
		embedConfluenceBentoCohort,
		embedConfluenceBentofireExposureEvent,
		entryPointActions,
		isCrossSellEnabled,
	]);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="linkedMentionedContentPreviewEntrypointContainer"
			packageName="@atlassian/jira-issue-create-confluence-content"
			teamName="confluence-better-together"
			runtimeProps={{
				contentCount,
				embeddedConfluenceSource: JIRA_ISSUE_LINKED_MENTIONED_PAGES,
			}}
		/>
	);
};
