/* eslint-disable @atlassian/relay/unused-fields */
import React, { useCallback, type ComponentPropsWithoutRef } from 'react';
import { graphql, useMutation, useFragment } from 'react-relay';
import { MOBILE_ISSUE } from '@atlassian/jira-common-constants/src/analytics-sources';
import { ff } from '@atlassian/jira-feature-flagging';
import { useAnalyticsSource } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { SelectValueShape } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';
import messages from '@atlassian/jira-issue-view-base/src/context/components/messages.tsx';
import type { ServerSuggestions } from '@atlassian/jira-issue-view-base/src/context/components/types';
import { View } from '@atlassian/jira-issue-view-base/src/context/components/view';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import { getDisplayName } from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field';
import {
	useConnectRelayField,
	type ConnectedLayoutProps,
	type PropsCallback,
} from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-relay-field';
import { ConnectedRelayFieldWrapper } from '@atlassian/jira-issue-view-common-views/src/connect-field/relay-field/field-wrapper';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import {
	useCanAdministerJira,
	useCanAdministerProject,
} from '@atlassian/jira-project-permissions-service/src/main.tsx';
import type { ui_issueViewComponentsField_Mutation } from '@atlassian/jira-relay/src/__generated__/ui_issueViewComponentsField_Mutation.graphql';
import type {
	ui_issueViewLayoutComponentsField_IssueViewComponentsField$key as ComponentsInlineEditFragment,
	ui_issueViewLayoutComponentsField_IssueViewComponentsField$data as ComponentsInlineEditFragmentData,
} from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutComponentsField_IssueViewComponentsField.graphql';

export type FetchSuggestions = (
	query: string,
	sessionId: string | undefined,
) => Promise<ServerSuggestions>;

type ComponentValueShape = SelectValueShape[] | undefined;
type AggValueShape = Pick<
	ComponentsInlineEditFragmentData,
	'selectedComponentsConnection' | 'components'
>;

export type AggItemValue = {
	readonly ari: string | null | undefined;
	readonly componentId: string;
	readonly description: string | null | undefined;
	readonly id: string;
	readonly metadata:
		| {
				readonly typeId: string;
				readonly compassComponentVersion: string;
		  }
		| null
		| undefined;
	readonly name: string | null | undefined;
};

const toComponentValueShape = (fieldData: AggItemValue): SelectValueShape => ({
	content: fieldData.name ?? '',
	value: fieldData.id ?? '',
	// TODO: Create a href util for the component
	// href: ,
	...(ff('compass-components-in-jira-components_xw42u', false)
		? {
				ari: fieldData.ari ?? '',
				metadata: fieldData.metadata?.typeId ? { typeId: fieldData.metadata.typeId } : undefined,
			}
		: {}),
});

function transformComponentValueToAggShape(
	componentValue: ComponentValueShape,
	components: ComponentsInlineEditFragmentData['components'],
): AggValueShape {
	return {
		selectedComponentsConnection: {
			edges:
				componentValue?.map((option) => ({
					node: components?.edges?.find((fieldOption) => fieldOption?.node?.id === option.value)
						?.node,
				})) ?? [],
		},
		components,
	};
}

export type IssueViewComponentsFieldProps = ConnectedLayoutProps<ComponentsInlineEditFragment>;

type AdditionalProps = Pick<
	ComponentPropsWithoutRef<typeof View>,
	| 'projectKey'
	| 'fetchSuggestions'
	| 'getDataFromCache'
	| 'placeholder'
	| 'noValueText'
	| 'shouldShowFooter'
	| 'showPinButton'
	| 'isGlobalComponentsEnabled'
	| 'globalComponentsPropertyLoading'
>;

export const IssueViewComponentsField = (props: IssueViewComponentsFieldProps) => {
	const data = useFragment<ComponentsInlineEditFragment>(
		graphql`
			fragment ui_issueViewLayoutComponentsField_IssueViewComponentsField on JiraComponentsField {
				id
				fieldId
				type
				name
				description
				__typename

				fieldConfig {
					isEditable
				}
				issue {
					key
				}
				selectedComponentsConnection {
					edges {
						node {
							id
							componentId
							name
							description
							ari
							metadata
						}
					}
				}
				components {
					edges {
						node {
							id
							componentId
							name
							description
							ari
							metadata
						}
					}
				}
			}
		`,
		props.fragmentKey,
	);

	const issueKey = data?.issue?.key ?? '';

	// #region not relayble dependencies
	const projectKey = useProjectKey(issueKey);
	const isJiraAdmin = useCanAdministerJira(projectKey);
	// project perms are available in AGG but not isJiraAdmin
	const isProjectAdmin = useCanAdministerProject(projectKey);
	const source = useAnalyticsSource();
	// #endregion

	const [commit] = useMutation<ui_issueViewComponentsField_Mutation>(graphql`
		mutation ui_issueViewComponentsField_Mutation($input: JiraUpdateComponentsFieldInput!)
		@raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateComponentsField(input: $input) {
					success
					errors {
						message
					}
					field {
						selectedComponentsConnection {
							edges {
								node {
									id
									componentId
									name
									description
									ari
									metadata
								}
							}
						}
						components {
							edges {
								node {
									id
									componentId
									name
									description
									ari
									metadata
								}
							}
						}
					}
				}
			}
		}
	`);

	const getComponentProps = useCallback<
		PropsCallback<
			ComponentsInlineEditFragment,
			ComponentsInlineEditFragmentData,
			ComponentValueShape,
			AggValueShape,
			AdditionalProps
		>
	>(
		({ intl, softRefreshCallbacks }) => {
			const options = mapNodes(data.components).map((option) => toComponentValueShape(option));

			// TODO: Implement getSuggestions via Relay replicating legacy logic (caching & recommendation service) or simplifying it if possible
			const getSuggestions = () =>
				Promise.resolve([
					{
						heading: intl.formatMessage(messages.recent),
						items: [],
					},
					{ heading: intl.formatMessage(messages.all), items: options },
				]);

			return {
				jiraIssueField: data,

				value: mapNodes(data.selectedComponentsConnection).map((option) =>
					toComponentValueShape(option),
				),

				onValueConfirm(componentValue) {
					const newAggValue = transformComponentValueToAggShape(componentValue, data.components);
					softRefreshCallbacks.onSubmit(newAggValue);

					commit({
						variables: {
							input: {
								id: data.id,
								operations: [
									{
										operation: 'SET',
										ids: componentValue?.map(({ value }) => value.toString()) ?? [],
									},
								],
							},
						},
						onCompleted: (mutationData) => {
							if (mutationData.jira?.updateComponentsField?.success) {
								softRefreshCallbacks.onSubmitSucceeded(newAggValue);
							} else {
								softRefreshCallbacks.onSubmitFailed();
							}
						},
						onError() {
							softRefreshCallbacks.onSubmitFailed();
						},
						optimisticResponse: {
							jira: {
								updateComponentsField: {
									success: true,
									errors: [],
									field: {
										id: data.id,
										...newAggValue,
									},
								},
							},
						},
					});
				},

				additionalProps: {
					projectKey,
					fetchSuggestions: getSuggestions,
					getDataFromCache: getSuggestions,
					placeholder: intl.formatMessage(messages.placeholder),
					noValueText: intl.formatMessage(genericMessages.noValue),
					shouldShowFooter: (isProjectAdmin || isJiraAdmin) && source !== MOBILE_ISSUE,
					showPinButton: getShowPinButton(props.area),

					isGlobalComponentsEnabled: false,
					globalComponentsPropertyLoading: false,
				},
			};
		},
		[commit, data, isJiraAdmin, isProjectAdmin, projectKey, props.area, source],
	);

	const connectField = useConnectRelayField(props, data, getComponentProps);
	const componentName = getDisplayName(View);

	return (
		<ConnectedRelayFieldWrapper componentName={componentName}>
			{/* @ts-expect-error: Property 'validate' is missing from additionalProps */}
			<View {...connectField.componentProps} />
		</ConnectedRelayFieldWrapper>
	);
};

type Nullable<T> = T | null | undefined;

type Connection<TNode> = {
	readonly edges?: Nullable<
		ReadonlyArray<
			Nullable<{
				readonly node?: Nullable<TNode>;
			}>
		>
	>;
} | null;

function mapNodes<TNode>(conn: Nullable<Connection<TNode>>): TNode[] {
	const nodes: TNode[] = [];

	return (
		conn?.edges?.reduce((acc, edge) => {
			if (edge?.node) {
				acc.push(edge.node);
			}

			return acc;
		}, nodes) ?? []
	);
}
