import React, { useCallback, useMemo, type ReactElement } from 'react';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import { ForgeScreenEvent } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIssueFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import type { SelectValueShape } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';
import type { MultiSelectValueItemShape } from '@atlassian/jira-issue-internal-field-select/src/multi-select-inline-edit';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages';
import type { TransformerContext } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import type { Label } from '@atlassian/jira-shared-types/src/rest/jira/label.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { getMemoizedSuggestionsFactory } from '../../../../../labels/fetch-suggestions';
import { transformFromStateValue, transformToStateValue } from '../../../../../labels/transformer';
import LabelsView from '../../../../../labels/view';
import { useAnalyticsAttributesContext } from '../../analytics/atrributes-context';
import type { CustomFieldProps } from '../../types';
import messages from './messages';
import { validate as forgeValidate } from './validation';

const validateFn = (values: SelectValueShape[]) => {
	const valuesToValidate: MultiSelectValueItemShape[] = values.map(
		(element: SelectValueShape): MultiSelectValueItemShape => ({
			value: String(element.value),
			content: element.content,
			href: element.href || '',
		}),
	);
	return forgeValidate(valuesToValidate);
};

export const View = ({
	fieldId,
	value,
	onChange,
	shouldFireScreenEvent,
	extension,
	customReadView,
	...restProps
}: CustomFieldProps<string[], Label[]>) => {
	const { formatMessage } = useIntl();

	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const [{ value: fieldConfig }] = useIssueFieldConfig(issueKey);
	const fieldAutoCompleteUrl = (fieldConfig && fieldConfig[fieldId].autoCompleteUrl) ?? null;
	const { baseUrl } = useTenantContext();

	const fetchSuggestions = getMemoizedSuggestionsFactory(
		fieldAutoCompleteUrl,
		fieldId,
		undefined,
		undefined,
		formatMessage,
	);

	const formatCreateLabel = useCallback(
		(inputValue: string) => <>{formatMessage(messages.createNewItem, { value: inputValue })}</>,
		[formatMessage],
	);

	const transformedValue = useMemo(() => {
		const transformerContext: TransformerContext = {
			baseUrl,
			projectKey,
			issueKey,
			fieldId,
		};

		const transValue: SelectValueShape[] = transformFromStateValue(value, transformerContext).map(
			(element) => ({
				value: String(element.value),
				content: element.content,
				href: element.href || '',
			}),
		);

		return transValue;
	}, [baseUrl, projectKey, issueKey, fieldId, value]);

	const onChangeWithTransform = useCallback(
		(newValue: SelectValueShape[]) => {
			const transValue = transformToStateValue(
				newValue.map((element) => ({
					value: String(element.value),
					content: element.content,
					href: element.href || '',
				})),
			);
			onChange(transValue);
		},
		[onChange],
	);

	const customReadViewWithTransformation: () => ReactElement = useCallback(
		() => (!isNil(customReadView) ? customReadView(value) : <></>),
		[value, customReadView],
	);

	const optionalCustomReadView = !isNil(customReadView)
		? customReadViewWithTransformation
		: undefined;

	const analyticsAttributes = useAnalyticsAttributesContext();
	const attributes = useMemo(
		() => ({ ...analyticsAttributes, renderMode: 'default' }),
		[analyticsAttributes],
	);

	return (
		<>
			{shouldFireScreenEvent && <ForgeScreenEvent attributes={attributes} />}
			<LabelsView
				{...restProps}
				issueKey={issueKey}
				fieldId={fieldId}
				formatMessage={formatMessage}
				fetchSuggestions={fetchSuggestions}
				placeholder={formatMessage(messages.placeholder)}
				noValueText={formatMessage(genericMessages.noValue)}
				customReadView={optionalCustomReadView}
				canCreateNewItem
				allowEmptyValue
				validate={validateFn}
				formatCreateLabel={formatCreateLabel}
				value={transformedValue}
				onChange={onChangeWithTransform}
				initFieldOptions={noop}
			/>
		</>
	);
};

export default View;
