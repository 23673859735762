import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import partition from 'lodash/partition';
import { AnalyticsSubject } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import { HIDE_DONE_SUBTASKS_FILTER } from '@atlassian/jira-issue-shared-types/src/common/types/user-preferences-type.tsx';
import { useUserPreferencesValue } from '@atlassian/jira-issue-user-preference-services/src/main.tsx';
import type { ChildIssue } from '@atlassian/jira-issue-view-common-types/src/children-issues-type';
import { useSortableIssueIds } from '../../hooks/main.tsx';
import {
	SORT_OPTION_ASSIGNEE,
	SORT_OPTION_CREATED,
	SORT_OPTION_KEY,
	SORT_OPTION_PRIORITY,
	SORT_OPTION_RANKING,
	SORT_OPTION_STATUS,
	type SortingOption,
} from '../../model/types';
import { Issues, type Props } from './list';
import {
	sortIssuesByAssignee,
	sortIssuesByCreated,
	sortIssuesByKey,
	sortIssuesByPriority,
	sortIssuesByStatus,
} from './utils';

export type WrapperProps = Props & {
	sortBy: SortingOption | null;
};

export const SortedIssues = ({
	allIssues,
	incompleteIssues,
	sortBy,
	hasExceededChildIssuesLimitOnLoadOrAfter,
	isSimplifiedProject,
	isClassicSubtaskPanel,
	...props
}: WrapperProps) => {
	const [hideDonePreference] = useUserPreferencesValue(HIDE_DONE_SUBTASKS_FILTER);

	const [sortableIssueIds] = useSortableIssueIds();
	const [oldIssuesFromAll, newIssuesFromAll] = useMemo(
		() => partition(allIssues, (issue) => sortableIssueIds.includes(issue.id)),
		[allIssues, sortableIssueIds],
	);
	const [oldIssuesFromIncomplete, newIssuesFromIncomplete] = useMemo(
		() => partition(incompleteIssues, (issue) => sortableIssueIds.includes(issue.id)),
		[incompleteIssues, sortableIssueIds],
	);

	const sortingFn = useMemo(() => {
		switch (sortBy) {
			case SORT_OPTION_CREATED:
				return sortIssuesByCreated;
			case SORT_OPTION_KEY:
				return sortIssuesByKey;
			case SORT_OPTION_STATUS:
				return sortIssuesByStatus;
			case SORT_OPTION_ASSIGNEE:
				return sortIssuesByAssignee;
			case SORT_OPTION_PRIORITY:
				return sortIssuesByPriority;
			case SORT_OPTION_RANKING:
			default:
				return (issues: Array<ChildIssue>) => issues;
		}
	}, [sortBy]);
	const sortedAllIssues = useMemo(() => sortingFn(oldIssuesFromAll), [oldIssuesFromAll, sortingFn]);
	const sortedIncompleteIssues = useMemo(
		() => sortingFn(oldIssuesFromIncomplete),
		[oldIssuesFromIncomplete, sortingFn],
	);

	// Company Managed Projects do not currently support reranking as
	// the rank custom field is specially always loaded for TMP but not for CMP.
	// Without the rank custom field id we cannot rerank CMP issues
	const restrictReordering =
		(!isSimplifiedProject && !isClassicSubtaskPanel) ||
		hasExceededChildIssuesLimitOnLoadOrAfter ||
		(sortBy !== null && sortBy !== SORT_OPTION_RANKING);

	//  show hidden done issues if all child issues/subtasks are done
	//  otherwise we'll have an empty child issues panel
	const hasIncompleteIssues = !isEmpty(incompleteIssues);
	const shouldHideDone = hasIncompleteIssues && hideDonePreference === 'true';

	return (
		<Issues
			{...props}
			isSimplifiedProject={isSimplifiedProject}
			isClassicSubtaskPanel={isClassicSubtaskPanel}
			allIssues={[...sortedAllIssues, ...newIssuesFromAll]}
			incompleteIssues={[...sortedIncompleteIssues, ...newIssuesFromIncomplete]}
			restrictReordering={restrictReordering}
			hasExceededChildIssuesLimitOnLoadOrAfter={hasExceededChildIssuesLimitOnLoadOrAfter}
			hideDone={shouldHideDone}
		/>
	);
};

SortedIssues.defaultProps = {
	isClassicSubtaskPanel: false,
	isChildrenIssuesPanel: false,
	shouldDisplayWorklogGraph: false,
	allIssues: [],
	incompleteIssues: [],
	projectType: null,
	onReorderChildren: noop,
	onEditIssue: noop,
	hasExceededChildIssuesLimitOnLoadOrAfter: false,
};

export const IssuesList = AnalyticsSubject('issue')(SortedIssues);
