import React, { type PropsWithChildren, type ReactElement, useMemo } from 'react';
import { useFieldOverridesStore } from '@atlassian/jira-issue-field-base/src/services/field-config-service/context';
import { InlineEditFieldInjectionsContext } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import type { InlineEditFieldInjectionsContextValue } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/types.tsx';
import { ItemBusyWrapper } from '@atlassian/jira-issue-view-common/src/component/item-busy-wrapper';

type Props = PropsWithChildren<{
	issueKey: string;
	fieldId: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children: ReactElement<any, any>;
}>;

export const UiModificationsRelayFieldDecorator = ({ fieldId, children, issueKey }: Props) => {
	const [overrides] = useFieldOverridesStore({ issueKey, fieldKey: fieldId });
	const isBusy = overrides?.isBusy ?? false;

	const fieldInjectionsContextValue = useMemo<InlineEditFieldInjectionsContextValue>(
		() => ({
			overriding: {
				overrideIsEditable: (isEditable) => overrides?.isEditable ?? isEditable,
				overrideDescription: (description) => overrides?.description ?? description,
				overrideLabel: (label) => overrides?.title ?? label,
				overrideFieldOptionsFilter: (filter) => overrides?.optionsFilter ?? filter,
			},
		}),
		[overrides?.description, overrides?.isEditable, overrides?.optionsFilter, overrides?.title],
	);

	return (
		<InlineEditFieldInjectionsContext.Provider value={fieldInjectionsContextValue}>
			<ItemBusyWrapper isBusy={isBusy}>{children}</ItemBusyWrapper>
		</InlineEditFieldInjectionsContext.Provider>
	);
};
