import React, { useState, useRef, useEffect } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { ErrorMessage } from '@atlaskit/form';
// eslint-disable-next-line import/order
import Select from '@atlaskit/select';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { defaultSelectStyles } from '@atlassian/jira-issue-field-select-base/src/ui/react-select-styles/styled.tsx';
import type { Option } from '../../common/types';
import messages from './messages';
import type { Props } from './types';
import { splitParentChildOptions, getIsChildAutoFocus } from './utils';

export const CascadingSelectView = (props: Props) => {
	const {
		value,
		allowedValues,
		allowedChildValues,
		onChange,
		isInvalid = false,
		areOptionsOnSameRow = false,
		autoFocus = true,
		onCloseMenuOnScroll,
		isDropdownMenuFixedAndLayered,
		isEditable = true,
		isClearable = true,
		...fieldProps
	} = props;

	const intl = useIntl();
	const [defaultParentOption, defaultChildOption] = splitParentChildOptions(value);

	const [parentOption, setParentOption] = useState<Option | null>(defaultParentOption);
	const [childOption, setChildOption] = useState<Option | null>(defaultChildOption);
	const [childOptions, setChildOptions] = useState<Option[]>(
		(defaultParentOption && allowedChildValues[defaultParentOption.value]) || [],
	);

	const [isChildAutoFocus, setIsChildAutoFocus] = useState<boolean>(
		getIsChildAutoFocus(value, childOptions),
	);
	const childRef = useRef<unknown>(null);

	useEffect(() => {
		if (ff('uim-cascading-select-field-support-gic_h1cda')) {
			setParentOption(
				defaultParentOption?.value
					? {
							label: defaultParentOption.label,
							value: defaultParentOption.value,
						}
					: null,
			);

			setChildOption(
				defaultChildOption?.value
					? {
							label: defaultChildOption.label,
							value: defaultChildOption.value,
						}
					: null,
			);

			setChildOptions(
				defaultParentOption?.value ? allowedChildValues[defaultParentOption.value] : [],
			);
		}
	}, [
		defaultParentOption?.value,
		defaultParentOption?.label,
		defaultChildOption?.value,
		defaultChildOption?.label,
		allowedChildValues,
	]);

	const onSelectParentOption = (
		selectedParentOption: Option,
		// @ts-expect-error - TS7031 - Binding element 'action' implicitly has an 'any' type.
		{ action },
		analyticsEvent: UIAnalyticsEvent,
	) => {
		setChildOption(null);

		switch (action) {
			case 'select-option': {
				onChange(selectedParentOption, null, analyticsEvent);
				setParentOption(selectedParentOption);
				setChildOptions(allowedChildValues[selectedParentOption.value] || []);
				setIsChildAutoFocus(getIsChildAutoFocus(value, childOptions));
				// @ts-expect-error - TS2571 - Object is of type 'unknown'.
				childRef && childRef.current && childRef.current.focus();
				break;
			}
			case 'clear': {
				onChange(null, null, analyticsEvent);
				setParentOption(null);
				setChildOptions([]);
				break;
			}
			default:
				break;
		}
	};

	// @ts-expect-error - TS7031 - Binding element 'action' implicitly has an 'any' type.
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onSelectChildOption = (selectedChildOption: Option, { action }, analyticsEvent: any) => {
		switch (action) {
			case 'select-option': {
				onChange(
					// @ts-expect-error - TS2345 - Argument of type '{ child: Option; value?: string | undefined; label?: string | undefined; }' is not assignable to parameter of type 'SelectedOption'.
					{
						...parentOption,
						child: selectedChildOption,
					},
					null,
					analyticsEvent,
				);
				setChildOption(selectedChildOption);
				break;
			}
			case 'clear': {
				onChange(parentOption, null, analyticsEvent);
				setChildOption(null);
				break;
			}
			default:
				break;
		}
	};

	return (
		<>
			{isInvalid && <ErrorMessage>{intl.formatMessage(messages.errorMessage)}</ErrorMessage>}
			<Container areOptionsOnSameRow={areOptionsOnSameRow}>
				<Item>
					<Select
						{...fieldProps}
						defaultValue={defaultParentOption}
						value={parentOption}
						options={allowedValues}
						// @ts-expect-error - TS2322 - Type '(selectedParentOption: Option, { action }: { action: any; }, analyticsEvent: UIAnalyticsEventInterface) => void' is not assignable to type '(value: Option | (Option & { child: Option | undefined; }) | null, action: ActionMeta<Option | (Option & { child: Option | undefined; })>) => void'.
						onChange={onSelectParentOption}
						isClearable={isClearable}
						autoFocus={autoFocus}
						openMenuOnFocus
						placeholder={intl.formatMessage(messages.noOptionsSelected)}
						// @ts-expect-error - TS2322 - Type '((e: SyntheticEvent<HTMLElement, Event>) => boolean) | undefined' is not assignable to type 'boolean | EventListener | undefined'.
						closeMenuOnScroll={onCloseMenuOnScroll}
						menuPosition={isDropdownMenuFixedAndLayered === true ? 'fixed' : undefined}
						styles={defaultSelectStyles}
						isDisabled={!isEditable}
						testId="issue-field-cascading-select.ui.cascading-select-view.select-parent"
						spacing="compact"
					/>
				</Item>
				{childOptions?.length > 0 && (
					<ChildItem areOptionsOnSameRow={areOptionsOnSameRow}>
						<Select
							{...fieldProps}
							defaultValue={defaultChildOption}
							value={childOption}
							testId="issue-field-cascading-select.ui.cascading-select-view.select-child"
							options={childOptions}
							// @ts-expect-error - TS2322 - Type '(selectedChildOption: Option, { action }: { action: any; }, analyticsEvent: any) => void' is not assignable to type '(value: Option | null, action: ActionMeta<Option>) => void'.
							onChange={onSelectChildOption}
							isClearable={isClearable}
							autoFocus={isChildAutoFocus}
							openMenuOnFocus
							placeholder={intl.formatMessage(messages.noOptionsSelected)}
							// @ts-expect-error - TS2322 - Type 'MutableRefObject<unknown>' is not assignable to type '((string | ((instance: { components: Partial<SelectComponents<Option, false, GroupTypeBase<Option>>>; select: StateManager<Option, false, GroupTypeBase<...>, Select<...>> | null; ... 22 more ...; UNSAFE_componentWillUpdate?(nextProps: Readonly<...>, nextState: Readonly<...>, nextContext: any): void; } | null) => voi...'.
							ref={childRef}
							// @ts-expect-error - TS2322 - Type '((e: SyntheticEvent<HTMLElement, Event>) => boolean) | undefined' is not assignable to type 'boolean | EventListener | undefined'.
							closeMenuOnScroll={onCloseMenuOnScroll}
							isDisabled={
								ff('uim-cascading-select-field-support-gic_h1cda') ? !isEditable : undefined
							}
							menuPosition={isDropdownMenuFixedAndLayered === true ? 'fixed' : undefined}
							styles={defaultSelectStyles}
							spacing="compact"
						/>
					</ChildItem>
				)}
			</Container>
		</>
	);
};

export const CascadingSelectViewEntryPointContent = ({ props }: { props: Props }) => (
	<CascadingSelectView {...props} />
);

export default CascadingSelectView;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ areOptionsOnSameRow?: boolean }>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	flexDirection: (props) => (props.areOptionsOnSameRow ? 'row' : 'column'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Item = styled.div({
	flexBasis: 0,
	flexGrow: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ChildItem = styled(Item)<{ areOptionsOnSameRow?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	marginTop: (props) => (props.areOptionsOnSameRow ? 0 : `${gridSize / 2}px`),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	marginLeft: (props) => (props.areOptionsOnSameRow ? `${gridSize / 2}px` : 0),
});
