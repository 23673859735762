import { useMemo } from 'react';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { layoutContainerItemTypes } from '@atlassian/jira-issue-layout-common-constants';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useIssueLayoutContainers, useLayoutContainerByType } from './services/main.tsx';
import { getLayoutItemId } from './services/utils.tsx';

/**
 * useIssueLayoutFields hook
 * This hook will traverse the current issue's layout sections and extract a
 * list of all the fields that appear in the layout.
 * Fields can appear in a section or in a tab.
 */
export const useIssueLayoutFields = () => {
	const issueKey = useIssueKey();
	const [issueLayoutContainers] = useIssueLayoutContainers(issueKey);

	const issueLayoutFields = useMemo(
		() =>
			issueLayoutContainers.reduce<string[]>((acc, container) => {
				if (!Array.isArray(container?.items?.nodes) || container.items.nodes.length === 0) {
					return acc;
				}

				const { nodes } = container.items;
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const allItemsFromContainer = nodes.reduce<Array<any>>((allItems, item) => {
					if (item.type === layoutContainerItemTypes.field) {
						return allItems.concat(getLayoutItemId(item));
					}
					if (item.type === layoutContainerItemTypes.tab) {
						return allItems.concat(
							item.items.nodes
								.filter((tabItem) => tabItem.type === layoutContainerItemTypes.field)
								.map((tabItem) => getLayoutItemId(tabItem)),
						);
					}
					return allItems;
				}, []);

				return acc.concat(allItemsFromContainer);
			}, []),
		[issueLayoutContainers],
	);
	return [{ issueLayoutFields }] as const;
};

/**
 * This hook is used to get all fields that are selected to be hidden when empty
 *
 * @param issueKey The issue key to obtain the secondary item ids from
 *
 * @returns the ids of all secondary items
 */

export const useSecondaryItemIds = (issueKey: IssueKey): string[] => {
	const [secondaryFields] = useLayoutContainerByType(issueKey, 'SECONDARY');

	const secondaryItemsIds = useMemo(() => secondaryFields.map(getLayoutItemId), [secondaryFields]);

	return secondaryItemsIds;
};
