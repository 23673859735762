import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import messages from '../../../common/messages';
import ActionButtons from './buttons';
import type { Props } from './types';

/**
 * Submit and cancel buttons to be rendered within the form. When `hideActionButtons` is `true` this will render a
 * hidden submit button to allow form submission via the `enter` key.
 */
export const FormControls = ({
	cancelButtonLabel,
	confirmButtonLabel,
	context: { onCancel },
	hideActionButtons,
}: Props) => {
	const { formatMessage } = useIntl();
	return !hideActionButtons ? (
		<ActionButtons
			cancelButtonLabel={cancelButtonLabel ?? formatMessage(messages.cancelButtonLabel)}
			confirmButtonLabel={confirmButtonLabel ?? formatMessage(messages.confirmButtonLabel)}
			onCancelClick={() => onCancel(false)}
		/>
	) : (
		/** This is to allow Ctrl + Enter to submit without action buttons */
		<button hidden type="submit" aria-label={formatMessage(messages.submitButtonLabel)} />
	);
};
