import React, { useCallback, useEffect, useRef, useLayoutEffect } from 'react';
import flow from 'lodash/flow';
import memoize from 'lodash/memoize';
import memoizeOne from 'memoize-one';
import { withAnalyticsEvents, type CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { SpotlightTarget } from '@atlaskit/onboarding';
import type { Value } from '@atlaskit/user-picker';
import { useUserEmailDomain } from '@atlassian/jira-atlassian-account/src/controllers/index.tsx';
import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types';
import performance from '@atlassian/jira-common-performance/src/performance.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { defineMessages } from '@atlassian/jira-intl';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import inviteAndAssignMessages from '@atlassian/jira-invite-and-assign/src/common/messages.tsx';
import { useInviteAndAssign } from '@atlassian/jira-invite-and-assign/src/controllers/invite-and-assign/index.tsx';
import { useShouldApplyInviteAndAssign } from '@atlassian/jira-invite-and-assign/src/controllers/use-should-apply-invite-and-assign/index.tsx';
import {
	triggerCacheHitEvent,
	triggerCacheMissEvent,
} from '@atlassian/jira-issue-analytics/src/services/prefetch/index.tsx';
import {
	fireSuggestionsRequestSuccessEvent,
	fireSuggestionsRequestFailedEvent,
} from '@atlassian/jira-issue-analytics/src/services/suggestions/index.tsx';
import { useProjectKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import useAssigneeField from '@atlassian/jira-issue-field-assignee/src/services/use-assignee-field/index.tsx';
import { AssignToMeButton } from '@atlassian/jira-issue-field-assignee/src/ui/inline/assign-to-me-button/index.tsx';
import UserInlineEditView, {
	type Props as UserInlineEditViewProps,
} from '@atlassian/jira-issue-internal-fields/src/user/view';
import { EDIT_FIELD } from '@atlassian/jira-issue-view-common-constants/src/view-constants';
import type { SaveFieldArguments } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import type { LoggedInUser } from '@atlassian/jira-issue-view-common-types/src/user-type';
import { getIssueModalAkDropdownPortal } from '@atlassian/jira-issue-view-common-utils/src/get-element/index.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import { smoothScrollIntoCenterIfNeeded } from '@atlassian/jira-issue-view-common-utils/src/scroll/index.tsx';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field';
import { ASSIGNEE } from '@atlassian/jira-issue-view-configurations';
import { withEditExperienceTracker } from '@atlassian/jira-issue-view-experience-tracking/src/edit-experience/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import {
	assignIssue,
	currentAssignee,
} from '@atlassian/jira-issue-view-services/src/issue/assign-issue-server';
import {
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import {
	loggedInUserDetailsSelector,
	projectTypeSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import {
	sweetStateFieldUpdated,
	sweetStateFieldSaveFailure,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-save-actions';
import { isFieldInTabSelector } from '@atlassian/jira-issue-view-store/src/selectors/tab-selector';
import { ASSIGNEE_TYPE } from '@atlassian/jira-platform-field-config';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import type { IssueKey, FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	getFieldLoadedValueSubscriber,
	type CachedFieldShape,
} from '@atlassian/jira-smart-field-prefetch/src/context.tsx';
import type { AssigneePayload } from '@atlassian/jira-smart-field-prefetch/src/types.tsx';
import { transformFromStateValue, transformToStateValue } from './assignee-transformer';
import { CommandPaletteAssigneeList } from './command-palette-assignee';

// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
const messages = defineMessages({
	assignToMe: {
		id: 'issue.assignee.text-for-logged-in-user',
		defaultMessage: 'Assign to me',
		description: '',
	},
	unassignedOption: {
		id: 'issue.assignee.text-when-no-value-is-provided',
		defaultMessage: 'Unassigned',
		description: '',
	},
	automaticOption: {
		id: 'issue.assignee.text-for-automatic-assignee',
		defaultMessage: 'Automatic',
		description: '',
	},
	inviteAndAssign: {
		id: 'issue.assignee.invite-and-assign-non-final',
		defaultMessage: 'Invite and assign',
		description: 'Is shown to user when they enter an email address that is not a Jira user',
	},
});

const analyticsAttributeIdRetriever = (value: { accountId: string }): string =>
	value && value.accountId;

export const getEmptyOption = memoizeOne((formatMessage: FormatMessage) => ({
	avatarUrl: '',
	name: formatMessage(messages.unassignedOption),
}));

const getAssignToMeOption = memoizeOne(
	(loggedInUser: LoggedInUser, formatMessage: FormatMessage) => {
		if (!loggedInUser) {
			return [];
		}
		return [
			{
				avatarUrl: loggedInUser.avatarUrl,
				id: loggedInUser.id,
				name: loggedInUser.displayName,
				displayName: formatMessage(messages.assignToMe),
			},
		] as const;
	},
);

const getAutomaticOption = memoizeOne(
	(loggedInUser: LoggedInUser, formatMessage: FormatMessage) => {
		if (!loggedInUser) {
			return [];
		}

		return [
			{
				avatarUrl: '',
				id: -1,
				name: formatMessage(messages.automaticOption),
				displayName: formatMessage(messages.automaticOption),
			},
		] as const;
	},
);

const getUnassignedOption = memoizeOne(
	(loggedInUser: LoggedInUser, formatMessage: FormatMessage) => {
		if (!loggedInUser) {
			return [];
		}

		return [
			{
				avatarUrl: '',
				id: null,
				name: formatMessage(messages.unassignedOption),
				displayName: formatMessage(messages.unassignedOption),
			},
		] as const;
	},
);

const getInitialOptions = memoizeOne((loggedInUser, initialValue, formatMessage, canBeAssigned) => {
	const isLoggedInUser = Boolean(
		initialValue && loggedInUser && loggedInUser.id === initialValue.accountId,
	);

	const assignToMeOption =
		!isLoggedInUser && canBeAssigned ? getAssignToMeOption(loggedInUser, formatMessage) : [];
	const unassignedOption = initialValue ? getUnassignedOption(loggedInUser, formatMessage) : [];

	const automaticOption = getAutomaticOption(loggedInUser, formatMessage);
	return [...assignToMeOption, ...unassignedOption, ...automaticOption] as const;
});

const isMatchingDefaultOption = (query: string, option: string) =>
	!!query && option.toLowerCase().startsWith(query.toLowerCase());

// NOTE: temporarily exported for unit testing purposes only
export const getAssigneeDefaultOptionsFactory = (
	loggedInUser: LoggedInUser,
	formatMessage: FormatMessage,
) =>
	memoizeOne((query: string) => {
		const isMatchingAssignToMe = isMatchingDefaultOption(query, formatMessage(messages.assignToMe));

		const isMatchingUnassigned = isMatchingDefaultOption(
			query,
			formatMessage(messages.unassignedOption),
		);

		const maybeNoAssigneeOption = isMatchingUnassigned
			? getUnassignedOption(loggedInUser, formatMessage)
			: [];
		const maybeAssignToMeOption = isMatchingAssignToMe
			? getAssignToMeOption(loggedInUser, formatMessage)
			: [];

		const isMatchingAutomatic = isMatchingDefaultOption(
			query,
			formatMessage(messages.automaticOption),
		);
		const mayBeAutomaticOption = isMatchingAutomatic
			? getAutomaticOption(loggedInUser, formatMessage)
			: [];
		return [...maybeNoAssigneeOption, ...maybeAssignToMeOption, ...mayBeAutomaticOption] as const;
	});

export const getMemoizedAssigneeDefaultOptionsFactory = memoizeOne(
	(loggedInUser: LoggedInUser, formatMessage: FormatMessage) =>
		memoizeOne((query: string) => {
			const isMatchingAssignToMe = isMatchingDefaultOption(
				query,
				formatMessage(messages.assignToMe),
			);

			const isMatchingUnassigned = isMatchingDefaultOption(
				query,
				formatMessage(messages.unassignedOption),
			);

			const maybeNoAssigneeOption = isMatchingUnassigned
				? getUnassignedOption(loggedInUser, formatMessage)
				: [];
			const maybeAssignToMeOption = isMatchingAssignToMe
				? getAssignToMeOption(loggedInUser, formatMessage)
				: [];

			const isMatchingAutomatic = isMatchingDefaultOption(
				query,
				formatMessage(messages.automaticOption),
			);
			const mayBeAutomaticOption = isMatchingAutomatic
				? getAutomaticOption(loggedInUser, formatMessage)
				: [];

			return [...maybeNoAssigneeOption, ...maybeAssignToMeOption, ...mayBeAutomaticOption] as const;
		}),
);

export const fetchSuggestionsFactory = memoizeOne(
	(
		autoCompleteUrl: string,
		loggedInUser: LoggedInUser,
		formatMessage: FormatMessage,
		createAnalyticsEvent?: CreateUIAnalyticsEvent,
	) =>
		(query: string, sessionId?: string) => {
			if (!autoCompleteUrl) {
				return Promise.resolve([]);
			}

			const getAssigneeDefaultOptions = getAssigneeDefaultOptionsFactory(
				loggedInUser,
				formatMessage,
			);
			const defaultSuggestions = getAssigneeDefaultOptions(query);

			const queryString = sessionId != null ? `&sessionId=${sessionId}` : '';

			const startTime = performance.now();
			const queryLength = query.length;

			return fetchJson(`${autoCompleteUrl}${query}${queryString}`)
				.then((response) => {
					fireSuggestionsRequestSuccessEvent(ASSIGNEE, startTime, createAnalyticsEvent, {
						sessionId,
						queryLength,
					});

					const fetchedSuggestions = response.map(transformFromStateValue).filter(Boolean);
					return [...defaultSuggestions, ...fetchedSuggestions] as const;
				})
				.catch((error) => {
					fireSuggestionsRequestFailedEvent(ASSIGNEE, startTime, createAnalyticsEvent, {
						sessionId,
					});

					throw error;
				});
		},
);

export const saveField = async ({
	baseUrl,
	issueKey,
	value,
	saveFieldData,
}: SaveFieldArguments<string>): Promise<null | {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	updatedFieldValue: any;
}> => {
	const accountId = value == null ? null : value.accountId;
	await assignIssue(baseUrl, issueKey, accountId, saveFieldData);
	if (accountId === -1) {
		try {
			const response = await currentAssignee(baseUrl, issueKey, saveFieldData);
			const { assignee = null } = response.fields || {};
			if (assignee !== null) {
				const transformedState = transformFromStateValue(response.fields.assignee);
				return {
					updatedFieldValue: transformToStateValue(transformedState),
				};
			}
			// Issue is unassigned as result of automatic
			return {
				updatedFieldValue: null,
			};
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics({
				meta: {
					id: 'currentAssigneeFetch',

					packageName: 'jiraIssueView',
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
		}
		return null;
	}
	return null;
};

export type Props = {
	issueKey: IssueKey;
} & UserInlineEditViewProps & {
		initFieldOptions: () => void;
		loggedInUser: LoggedInUser;
		getDefaultOptions: (query: string) => [];
		createAnalyticsEvent?: CreateUIAnalyticsEvent;
		projectType?: ProjectType | null;
	};

export const prefetchSuggestionsFactory =
	(prefetchResult: CachedFieldShape<AssigneePayload[]>, viewProps: Props) =>
	(query: string, assignSessionId?: string) => {
		if (!query && prefetchResult !== undefined && prefetchResult !== null) {
			const { prefetchSessionId, prefetched, fieldValuePromise, sessionId } = prefetchResult;
			const { createAnalyticsEvent } = viewProps;
			if (prefetched && fieldValuePromise !== null && sessionId !== null) {
				const defaultSuggestions = viewProps.getDefaultOptions(query);
				triggerCacheHitEvent(
					createAnalyticsEvent,
					prefetchSessionId,
					assignSessionId || '',
					sessionId,
					ASSIGNEE,
				);

				const startTime = performance.now();

				return fieldValuePromise
					.then((response) => {
						// query length from prefetch should be 0
						fireSuggestionsRequestSuccessEvent(ASSIGNEE, startTime, createAnalyticsEvent, {
							sessionId: assignSessionId,
							isPrefetched: true,
							queryLength: 0,
						});
						const fetchedSuggestions = response.map(transformFromStateValue).filter(Boolean);
						return [...defaultSuggestions, ...fetchedSuggestions] as const;
					})
					.catch((error) => {
						fireSuggestionsRequestFailedEvent(ASSIGNEE, startTime, createAnalyticsEvent, {
							sessionId: assignSessionId,
							isPrefetched: true,
						});

						throw error;
					});
			}
			triggerCacheMissEvent(
				createAnalyticsEvent,
				prefetchSessionId,
				assignSessionId || '',
				ASSIGNEE,
			);
			return viewProps.fetchSuggestions(query, assignSessionId);
		}
		return viewProps.fetchSuggestions(query, assignSessionId);
	};

const getMemoizedPrefetchSuggestionsFactory = memoize(
	prefetchSuggestionsFactory,
	(prefetchResult, viewProps) =>
		`${(prefetchResult && prefetchResult.prefetchSessionId) || ''}_${viewProps.issueKey}`,
);

type ConnectedAssignToMeDispatchProps = {
	onUpdate: (value: unknown) => void;
	onFailure: (error: Error) => void;
};

const ConnectedAssignToMeButton = connect(
	null,
	(dispatch): ConnectedAssignToMeDispatchProps => ({
		onUpdate: (onUpdateValue: unknown) =>
			dispatch(sweetStateFieldUpdated(ASSIGNEE_TYPE, onUpdateValue)),
		onFailure: (error: Error) =>
			dispatch(
				sweetStateFieldSaveFailure(ASSIGNEE_TYPE, 'The value could not be saved', error, {
					isRichTextField: false,
					isOptimistic: false,
					shouldSaveDraft: false,
					canContainMediaContent: false,
					saveField,
					shouldDiscardChangeOnFailure: true,
					onSaveFailureFlagType: null,
					onSaveSuccessFlagType: null,
				}),
			),
	}),
)(AssignToMeButton);

export const AssigneeView = (props: Props) => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useLayoutEffect(() => props.initFieldOptions(), []);
	const assigneeContainerRef = useRef<unknown>(null);

	useEffect(() => {
		if (assigneeContainerRef.current && props.isEditing) {
			// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'HTMLElement | null'.
			smoothScrollIntoCenterIfNeeded(assigneeContainerRef.current);
		}
	}, [props.isEditing]);

	const [{ value, fieldConfig }] = useAssigneeField({ issueKey: props.issueKey });

	const projectKey = useProjectKey();

	const [{ canBeAssignedToIssues }] = useProjectPermissions(projectKey);

	const isEditable = !!fieldConfig?.isEditable;

	const { formatMessage } = useIntl();
	const initialOptions = getInitialOptions(
		props.loggedInUser,
		value,
		formatMessage,
		!!canBeAssignedToIssues,
	);

	const triggerInviteAndAssign = useInviteAndAssign({
		issueKey: props.issueKey,
		analyticsSource: 'assigneeViewField',
	});

	const shouldApplyInviteAndAssignIssueView = useShouldApplyInviteAndAssign();

	const domain = useUserEmailDomain(shouldApplyInviteAndAssignIssueView);

	const loggedInEmailDomain =
		shouldApplyInviteAndAssignIssueView && domain?.data ? domain?.data : undefined;

	const handleOnCreateOption = useCallback(
		async (newValue: Value) => {
			if (Array.isArray(newValue) || !newValue?.id) {
				fireErrorAnalytics({
					meta: {
						id: 'handleOnCreateOption',

						packageName: 'jiraIssueView',

						teamName: 'jlove-makkuro',
					},
					error: new Error('Unsupported value type'),
				});
				return;
			}
			triggerInviteAndAssign(newValue.name);
		},
		[triggerInviteAndAssign],
	);

	const renderAssignToMeButton = useCallback(() => {
		if (!props.issueKey) return null;
		const isAssignedToLoggedInUser = Boolean(
			value && props.loggedInUser && props.loggedInUser.id === value.accountId,
		);

		const showAssignToMeButton =
			!!props.loggedInUser && isEditable && !!canBeAssignedToIssues && !isAssignedToLoggedInUser;

		if (!showAssignToMeButton) return null;

		return <ConnectedAssignToMeButton issueKey={props.issueKey} />;
	}, [canBeAssignedToIssues, isEditable, props.issueKey, props.loggedInUser, value]);

	const renderWithCachedValue = useCallback(() => {
		const FieldLoadedValueSubscriber = getFieldLoadedValueSubscriber<AssigneePayload>(
			props.issueKey,
			ASSIGNEE,
		);

		return (
			<FieldLoadedValueSubscriber fieldId={ASSIGNEE}>
				{/* @ts-expect-error - TS7006 - Parameter 'prefetchResult' implicitly has an 'any' type. */}
				{(prefetchResult) => {
					const fetchSuggestions = getMemoizedPrefetchSuggestionsFactory(prefetchResult, props);

					const {
						getDefaultOptions,
						initFieldOptions,
						issueKey,
						loggedInUser,
						...userInlineEditViewProps
					} = props;

					const transformedValue = transformFromStateValue(value);

					return (
						<>
							<UserInlineEditView
								{...userInlineEditViewProps}
								// @ts-expect-error - TS2322 - Type '(query: string, assignSessionId?: string | undefined) => Promise<readonly (UserOption | null)[]>' is not assignable to type '(query: string, sessionId?: string | undefined) => Promise<UserOption[]>'.
								fetchSuggestions={fetchSuggestions}
								fieldId={ASSIGNEE}
								issueKey={issueKey}
								value={transformedValue}
								// @ts-expect-error - TS2322 - Type 'readonly never[] | readonly { readonly avatarUrl: ""; readonly id: null; readonly name: string; readonly displayName: string; }[] | readonly [...never[], { readonly avatarUrl: ""; readonly id: -1; readonly name: string; readonly displayName: string; }] | ... 4 more ... | readonly [...]' is not assignable to type 'UserOption[] | undefined'.
								initialOptions={initialOptions}
								isEditable={isEditable}
								emptyOption={getEmptyOption(formatMessage)}
								belowFieldView={renderAssignToMeButton()}
								emailLabel={formatMessage(inviteAndAssignMessages.inviteAndAssign)}
								suggestEmailsForDomain={loggedInEmailDomain}
								allowEmail={shouldApplyInviteAndAssignIssueView}
								onCreateOption={handleOnCreateOption}
							/>
							<CommandPaletteAssigneeList
								fetchSuggestions={fetchSuggestions}
								prefetchResult={prefetchResult}
								initialOptions={initialOptions}
								value={transformedValue}
							/>
						</>
					);
				}}
			</FieldLoadedValueSubscriber>
		);
	}, [
		props,
		value,
		initialOptions,
		isEditable,
		formatMessage,
		renderAssignToMeButton,
		loggedInEmailDomain,
		shouldApplyInviteAndAssignIssueView,
		handleOnCreateOption,
	]);

	return (
		<SpotlightTarget name={EDIT_FIELD}>
			<div
				ref={(ref) => {
					assigneeContainerRef.current = ref;
				}}
			>
				{renderWithCachedValue()}
			</div>
		</SpotlightTarget>
	);
};

AssigneeView.displayName = 'AssigneeView';

const getSuggestionUrl = (baseUrl: string, issueKey: IssueKey) =>
	`${baseUrl}/rest/api/3/user/assignable/search?issueKey=${issueKey}&recommend=true&query=`;

// @ts-expect-error - TS7006 - Parameter 'ownPropsOnMount' implicitly has an 'any' type.
const getConnectedProps = (ownPropsOnMount) => ({
	fieldId: ASSIGNEE,
	transformFromStateValue,
	transformToStateValue,
	saveField,
	// @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type. | TS7006 - Parameter 'intl' implicitly has an 'any' type.
	additionalProps: (state, intl) => {
		const loggedInUser = loggedInUserDetailsSelector(state);
		const projectType = projectTypeSelector(state);

		return {
			portalElement: isFieldInTabSelector(ownPropsOnMount.fieldId)(state)
				? // Using a portal element here because otherwise dropdowns would get cut off
					// See: https://jdog.jira-dev.com/browse/BENTO-4101

					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					getIssueModalAkDropdownPortal() || document.body
				: undefined,
			fetchSuggestions: fetchSuggestionsFactory(
				getSuggestionUrl(baseUrlSelector(state), issueKeySelector(state)),
				loggedInUser,
				intl.formatMessage,
				ownPropsOnMount.createAnalyticsEvent,
			),
			issueKey: issueKeySelector(state),
			getDefaultOptions: getMemoizedAssigneeDefaultOptionsFactory(loggedInUser, intl.formatMessage),
			loggedInUser,
			showPinButton: getShowPinButton(ownPropsOnMount.area),
			analyticsAttributeIdRetriever,
			enablePeopleInvite: true,
			projectType,
		};
	},
});

export default flow(
	withAnalyticsEvents(),
	withEditExperienceTracker(ASSIGNEE),
	connectField((stateOnMount, ownPropsOnMount) => getConnectedProps(ownPropsOnMount)),
)(AssigneeView);
