import React, { type ReactNode } from 'react';
import Spinner from '@atlaskit/spinner';
import { ForgeFieldContainer, ForgeFieldSpinnerContainer, ForgeFieldWrapper } from './styled';

type Props = {
	isLoading: boolean;
	isCustomReadView?: boolean;
	children: ReactNode;
	topMargin?: number | undefined;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ children, topMargin = 0, isCustomReadView = false, isLoading }: Props) => (
	<ForgeFieldWrapper>
		<ForgeFieldContainer isLoading={isLoading}>{children}</ForgeFieldContainer>
		{isLoading && (
			<ForgeFieldSpinnerContainer topMargin={topMargin} isCustomReadView={isCustomReadView}>
				<Spinner size="small" />
			</ForgeFieldSpinnerContainer>
		)}
	</ForgeFieldWrapper>
);
