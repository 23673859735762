// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addAttachmentTooltip: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.add-attachment-label',
		defaultMessage: 'Add attachment',
		description:
			'The tooltip to be used on the quick add button for adding an attachment to an issue',
	},
	createSubtaskTooltip: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.create-subtask-label',
		defaultMessage: 'Create subtask',
		description: 'The tooltip to be used on the quick add button for adding a subtask for an issue',
	},
	addIssueLinkTooltip: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.add-issue-link-label',
		defaultMessage: 'Link issue',
		description: 'The tooltip to be used on the quick add button for creating an issue link',
	},
	createChildTooltip: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.create-child-label',
		defaultMessage: 'Add a child issue',
		description:
			'The tooltip to be used on the quick add button for creating a child in an agility issue',
	},
	linkConfluencePageTooltip: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.link-confluence-page-label',
		defaultMessage: 'Link a Confluence page',
		description: 'The tooltip to be used on the quick add button for linking a Confluence page',
	},
	linkConfluenceContentTooltip: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.link-confluence-content-label',
		defaultMessage: 'Link a Confluence content',
		description: 'The tooltip to be used on the quick add button for linking Confluence content',
	},
	addAttachmentLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.add-attachment-button-label',
		defaultMessage: 'Attach',
		description:
			'The label to be used on the quick add button for adding an attachment to an issue',
	},
	attachmentLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.attachment-button-label',
		defaultMessage: 'Attachment',
		description:
			'The label to be used on the quick add button for adding an attachment to an issue',
	},
	createSubtaskLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.create-subtask-button-label',
		defaultMessage: 'Create subtask',
		description: 'The label to be used on the quick add button for adding a subtask for an issue',
	},
	subtaskLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.subtask-button-label',
		defaultMessage: 'Subtask',
		description: 'The label to be used on the quick add button for adding a subtask for an issue',
	},
	addIssueLinkLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.add-issue-link-button-label',
		defaultMessage: 'Link issue',
		description: 'The label to be used on the quick add button for creating an issue link',
	},
	addLinkedIssueLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.add-linked-issue-button-label',
		defaultMessage: 'Linked issue',
		description: 'The label to be used on the quick add button for creating an issue link',
	},
	createChildLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.create-child-button-label',
		defaultMessage: 'Add a child issue',
		description:
			'The label to be used on the quick add button for creating a child in an agility issue',
	},
	childIssueLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.child-issue-button-label',
		defaultMessage: 'Child issue',
		description:
			'The label to be used on the quick add button for creating a child in an agility issue',
	},
	linkConfluencePageLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.link-confluence-page-button-label',
		defaultMessage: 'Link Confluence page',
		description: 'The label to be used on the quick add button for linking a Confluence page',
	},
	existingConfluencePageLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.existing-confluence-page-button-label',
		defaultMessage: 'Existing Confluence page',
		description: 'The label to be used on the quick add button for linking a Confluence page',
	},
	linkConfluenceContentLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.link-confluence-content-button-label',
		defaultMessage: 'Link Confluence content',
		description: 'The label to be used on the quick add button for linking Confluence content',
	},
	linkAlertsLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.link-alerts-label',
		defaultMessage: 'Link alerts',
		description:
			'The label to be used on the quick add button for linking Opsgenie alerts with JSM incidents',
	},
	linkAlertsTooltip: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.link-alerts-tooltip',
		defaultMessage: 'Link alerts',
		description:
			'The tooltip to be used on the quick add button for linking Opsgenie alerts with JSM incidents',
	},
	investigateIncidentLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.investigate-incident-label',
		defaultMessage: 'Investigate',
		description:
			'The label to be used on the quick add button for investigating incidents with JSM requests',
	},
	investigateIncidentTooltip: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.investigate-incident-tooltip',
		defaultMessage: 'Investigate deployments and add them as potential causes to this incident',
		description:
			'The tooltip to be used on the quick add button for investigating incidents with JSM requests',
	},
	addWebLinkLabel: {
		id: 'issue.foundation.quick-add.quick-add-items.add-web-link-label',
		defaultMessage: 'Add web link',
		description: 'The tooltip to be used on the quick add button for adding web link',
	},
	webLinkLabel: {
		id: 'issue.foundation.quick-add.quick-add-items.web-link-label',
		defaultMessage: 'Web link',
		description: 'The tooltip to be used on the quick add button for adding web link',
	},
	addWebLinkTooltip: {
		id: 'issue.foundation.quick-add.quick-add-items.add-web-link-tooltip',
		defaultMessage: 'Link web pages and more',
		description: 'The tooltip to be used on the quick add button for adding web link',
	},
	linkDropdownButtonLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.web-link-dropdown-label',
		defaultMessage: 'Link web pages and more',
		description: 'The label to be used on the dropdown button for showing link options',
	},
	linkDropdownButtonCompactLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.web-link-dropdown-compact-label',
		defaultMessage: 'Link issues, web pages, and more',
		description:
			'The label to be used on the dropdown button for showing link options in compact mode',
	},
});
