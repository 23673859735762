import { useCallback } from 'react';
import OriginTracer from '@atlassiansox/origin-tracing';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import {
	useAnalyticsEvents,
	fireOperationalAnalytics,
	fireTrackAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { getSiteAri } from '../../utils';
import { inviteWithinOrg } from './invite-users';
import type { BulkInviteSuccessResponse, InviteUsersRequest } from './types';
import { handleInviteUsersSuccessResponse, getOriginProduct } from './utils';

const RECAPTCHA_VALIDATION_ERROR = 'ValidationError';

export const useInviteUsers = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return useCallback(
		async ({
			users,
			continueUrl,
			tenantId,
			product,
			integration,
		}: InviteUsersRequest): Promise<BulkInviteSuccessResponse> => {
			try {
				const origin = new OriginTracer({ product: getOriginProduct(product) });
				const continueUrlWithOrigin = origin.addToUrl(continueUrl);
				const originAttributes = origin.toAnalyticsAttributes({ hasGeneratedId: true });

				const inviteApiResponse = await inviteWithinOrg({
					users,
					continueUrl: continueUrlWithOrigin,
					tenantId,
					product,
				});
				const response = handleInviteUsersSuccessResponse(inviteApiResponse);

				[...response.invited, ...response.accessRequested].forEach((invited) => {
					const resourceAri = getSiteAri(tenantId, product);
					const result = invited.results[resourceAri];
					const userId = invited.id;
					const analyticsAttributes = {
						...originAttributes,
						userId,
						products: [product],
						inviteResult: [
							{
								resourceAri,
								status: result,
							},
						],
						integration,
						isInviteWithinOrg: true,
					};
					if (result === 'INVITED') {
						fireTrackAnalytics(
							createAnalyticsEvent({}),
							'user invited',
							userId,
							analyticsAttributes,
						);
					} else if (result === 'INVITED_PENDING_APPROVAL') {
						fireTrackAnalytics(
							createAnalyticsEvent({}),
							'productAccess requested',
							userId,
							analyticsAttributes,
						);
					}
				});

				fireOperationalAnalytics(createAnalyticsEvent({}), 'promptOnLinkInvite succeeded', {
					invited: response.invited.length,
					requested: response.accessRequested.length,
					errored: response.error.length,
					integration,
				});

				return response;
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				fireErrorAnalytics({
					meta: {
						id:
							error.statusCode === 400 && error.name === RECAPTCHA_VALIDATION_ERROR
								? 'useInviteUsers-recaptcha'
								: 'useInviteUsers',
						packageName: 'jiraInvitePromptOnLinkInsert',
					},
					error,
				});
				throw error;
			}
		},
		[createAnalyticsEvent],
	);
};
