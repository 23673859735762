import { useEffect, useCallback, useRef } from 'react';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import type { Position } from './types';

// Total size of resize handle (width + border)
export const RESIZE_HANDLE_WIDTH = 3 * gridSize + 2;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAnimationFrameThrottle = <TCallback extends (...args: any[]) => void>(
	callback: TCallback,
): [(...args: Parameters<TCallback>) => void, () => void] => {
	const token = useRef<number | null>(null);
	const lastArgs = useRef<Parameters<TCallback> | null>(null);
	const hasTriggered = useRef(false);

	const handleFrame = useCallback(() => {
		lastArgs.current = null;
		hasTriggered.current = false;
	}, []);

	const throttle = useCallback(
		(...args: Parameters<TCallback>) => {
			lastArgs.current = args;
			if (hasTriggered.current) return;
			callback(...args);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			token.current = window.requestAnimationFrame(handleFrame);
			hasTriggered.current = true;
		},
		[handleFrame, callback],
	);

	const cancel = useCallback(() => {
		if (token.current) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.cancelAnimationFrame(token.current);
		}
		if (!hasTriggered.current && lastArgs.current !== null) {
			callback(...lastArgs.current);
		}
		token.current = null;
		lastArgs.current = null;
		hasTriggered.current = false;
	}, [callback]);

	useEffect(() => () => cancel(), [callback, cancel]);

	return [throttle, cancel];
};

export const useDoubleClick = (callback: () => void) => {
	const lastClickTime = useRef(0);

	const handleClick = useCallback(() => {
		const now = Date.now();
		if (now - lastClickTime.current < 500) {
			lastClickTime.current = 0;
			callback();
			return true;
		}
		lastClickTime.current = now;
		return false;
	}, [callback]);

	return handleClick;
};

export const calculatePadding = (element: HTMLElement) => {
	const style = getComputedStyle(element);
	const paddingX = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
	return paddingX;
};

type NewRatioInput = {
	parentWidth: number;
	currentWidth: number;
	deltaX: number;
	minWidth: number;
	minRatio: number;
	maxWidth: number;
	maxRatio: number;
	baseWidth: number;
	position: Position;
};

type ClampValueInput = {
	value: number;
	minValue: number;
	maxValue: number;
};

export const clampValue = ({ value, minValue, maxValue }: ClampValueInput) =>
	Math.min(Math.max(value, minValue), maxValue);

export const calculateNewRatio = ({
	parentWidth,
	currentWidth,
	deltaX,
	minWidth,
	minRatio,
	maxWidth,
	maxRatio,
	baseWidth,
	position,
}: NewRatioInput) => {
	const targetWidth = Math.min(
		Math.max(currentWidth - baseWidth + (position === 'left' ? deltaX : deltaX * -1), minWidth),
		maxWidth,
	);
	return clampValue({ value: targetWidth / parentWidth, minValue: minRatio, maxValue: maxRatio });
};

export const getChildrenStyle = (
	ratio: number,
	minWidth?: number | null,
	maxWidth?: number | null,
	extraWidth = 0,
) => ({
	boxSizing: 'border-box',
	flexGrow: 0,
	flexShrink: 1,
	flexBasis: extraWidth > 0 ? `calc(${ratio * 100}% + ${extraWidth}px)` : `${ratio * 100}%`,
	minWidth: minWidth !== undefined ? minWidth : null,
	maxWidth: maxWidth !== undefined ? maxWidth : null,
});
