import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import clamp from 'lodash/clamp';
import flow from 'lodash/flow';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import { ErrorBoundaryFlag as ErrorBoundary } from '@atlassian/jira-error-boundary-flag-renderer';
import { useFlagsService, type FlagId, toFlagId } from '@atlassian/jira-flags';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { useHardLimitsContactAdminModal } from '@atlassian/jira-issue-hard-limit-exceed-modal/src/controllers/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import {
	deleteOptimisticWebLink,
	showCreateWebLinkForm,
	createWebLinkRetry,
} from '@atlassian/jira-issue-view-store/src/actions/web-links-actions';
import {
	webLinksWithApplicationIdSelector,
	canAddWebLinksSelector,
	isCreateWebLinkOpenedSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/web-links-selector';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { WebLinkId } from '@atlassian/jira-shared-types/src/general.tsx';
import { messages } from './messages';
import type { OwnProps, StateProps, DispatchProps } from './types';
import { WebLinksView } from './view';

type ReduxStateProps = Flow.Diff<
	StateProps,
	{
		nextPageCount: StateProps['nextPageCount'];
	}
>;

type ReduxDispatchProps = Flow.Diff<
	DispatchProps,
	{
		onShowMore: DispatchProps['onShowMore'];
	}
>;

type ReduxProps = OwnProps & ReduxStateProps & ReduxDispatchProps;

export const INITIAL_PAGE_SIZE = 10;
export const SHOW_MORE_PAGE_SIZE = 50;

export const PaginationAwareView = (props: ReduxProps) => {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [currentPage, setCurrentPage] = useState(0);
	const [_, { openModal: openHardLimitsModal, setFieldType: setHardLimitsFieldType }] =
		useHardLimitsContactAdminModal();
	const webLinkAddedFlagId = 'webLinkAddedFlag';

	const { links, onDeleteOptimisticWebLink, onCreateWebLinkRetry } = props;

	const onShowMore = useCallback(() => {
		setCurrentPage(currentPage + 1);

		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
		});
		fireUIAnalytics(analyticsEvent, 'webLinksShowMore', {
			currentPage,
		});
	}, [createAnalyticsEvent, currentPage]);

	const maxResults = INITIAL_PAGE_SIZE + currentPage * SHOW_MORE_PAGE_SIZE;
	const slicedLinks = useMemo(() => links.slice(0, maxResults), [links, maxResults]);
	const nextPageCount = useMemo(
		() => clamp(links.length - maxResults, 0, SHOW_MORE_PAGE_SIZE),
		[links.length, maxResults],
	);

	const persistedLinksCount = links.filter(
		// @ts-expect-error - TS2339 - Property 'isCreating' does not exist on type 'WebLink'. | TS2339 - Property 'isCreating' does not exist on type 'WebLink'.
		(link) => link.isCreating == null || !link.isCreating,
	).length;
	const linksLengthRef = useRef(persistedLinksCount);

	const { showFlag, dismissFlag, hasFlag } = useFlagsService();

	/**
	 * When the limit of links per issue is exceeded, we should remove any optimistic feedback, the
	 * optimistic link, and show correct flag.
	 */
	const showErrorLimitExceededFlag = useCallback(
		(linkId: WebLinkId) => {
			dismissFlag(webLinkAddedFlagId);
			onDeleteOptimisticWebLink(linkId);
			const flagId: FlagId = toFlagId(`${linkId}-linksPerIssueLimitExceeded`);
			if (hasFlag && hasFlag(flagId)) {
				return;
			}
			showFlag({
				id: flagId,
				title: messages.linksPerIssueLimitExceededTitle,
				description: messages.linksPerIssueLimitExceededDescription,
				icon: <WarningIcon label="warning" color={token('color.icon.warning', colors.Y300)} />,
				type: 'warning',
				actions: [
					{
						content: messages.linksPerIssueLimitExceededNotifyAdminLink,
						onClick: () => {
							openHardLimitsModal();
							setHardLimitsFieldType('remoteIssueLinks');
							dismissFlag(flagId);
						},
					},
				],
			});
		},
		[
			showFlag,
			hasFlag,
			openHardLimitsModal,
			setHardLimitsFieldType,
			dismissFlag,
			onDeleteOptimisticWebLink,
		],
	);

	/**
	 * Failure to create the optimistic link should offer retry and cancel.
	 */
	const showErrorCreatingFlag = useCallback(
		(linkId: WebLinkId) => {
			dismissFlag(webLinkAddedFlagId);
			const flagId: FlagId = toFlagId(`${linkId}-failedCreating`);
			if (hasFlag && hasFlag(flagId)) {
				return;
			}
			showFlag({
				id: flagId,
				title: messages.createErrorHeading,
				description: messages.createErrorBody,
				type: 'warning',
				actions: [
					{
						content: messages.retryAction,
						onClick: () => {
							onCreateWebLinkRetry(linkId);
							dismissFlag(flagId);
						},
					},
					{
						content: messages.cancelAction,
						onClick: () => {
							onDeleteOptimisticWebLink(linkId);
							dismissFlag(flagId);
						},
					},
				],
				onDismissed: () => {
					onDeleteOptimisticWebLink(linkId);
				},
			});
		},
		[
			showFlag,
			hasFlag,
			dismissFlag,
			onDeleteOptimisticWebLink,
			onCreateWebLinkRetry,
			webLinkAddedFlagId,
		],
	);

	useEffect(() => {
		// Retried link creation should still not dispaly
		// the optimistic "we've added a link" flag
		let shouldDispatchOptimisticFeedback = true;
		links.forEach((link) => {
			if ('isCreateFailedLimitExceeded' in link && link.isCreateFailedLimitExceeded) {
				showErrorLimitExceededFlag(link.id);
				shouldDispatchOptimisticFeedback = false;
			}
			if ('isCreateFailed' in link && link.isCreateFailed) {
				showErrorCreatingFlag(link.id);
				shouldDispatchOptimisticFeedback = false;
			}
		});
		if (
			linksLengthRef.current > 0 &&
			persistedLinksCount > INITIAL_PAGE_SIZE &&
			persistedLinksCount > linksLengthRef.current
		) {
			if (!shouldDispatchOptimisticFeedback) {
				return;
			}
			showFlag({
				id: webLinkAddedFlagId,
				type: 'success',
				title: messages.webLinkAddedFlagTitle,
				description: messages.webLinkAddedFlagDescription,
			});
		}
		linksLengthRef.current = persistedLinksCount;
	}, [
		persistedLinksCount,
		showFlag,
		webLinkAddedFlagId,
		links,
		showErrorLimitExceededFlag,
		showErrorCreatingFlag,
	]);

	return (
		<WebLinksView
			{...props}
			intl={intl}
			links={slicedLinks}
			nextPageCount={nextPageCount}
			onShowMore={onShowMore}
		/>
	);
};

export const ReduxConnectedView = flow(
	withFireUiAnalytics({
		onAddButtonClicked: 'addWebLink',
	}),
	connect(
		(state, props: { applicationId: string }): ReduxStateProps => {
			const { applicationId } = props;
			return {
				isCreateWebLinkOpened: isCreateWebLinkOpenedSelector(state),
				links: webLinksWithApplicationIdSelector(state, applicationId),
				canAddWebLinks: canAddWebLinksSelector(state),
			};
		},
		{
			onAddButtonClicked: showCreateWebLinkForm,
			onDeleteOptimisticWebLink: deleteOptimisticWebLink,
			onCreateWebLinkRetry: createWebLinkRetry,
		},
	),
)(PaginationAwareView);

export const ConnectedView = (props: OwnProps) => (
	<ErrorBoundary id="^web-links-general-container">
		<ReduxConnectedView {...props} />
	</ErrorBoundary>
);
