import React from 'react';
import AsyncApprovalPanel from '@atlassian/jira-business-approvals-issue-view-panel/src/ui/async.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { SERVICEDESK_APPROVAL_TYPE } from '@atlassian/jira-platform-field-config';
import type { PanelProps } from '../types';
import useApprovalField from '../use-approval-fields';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: PanelProps) => {
	const { setRefreshIssue } = props;
	const issueKey = useIssueKey();
	const approvalFieldId =
		(props.payload && props.payload.approvalFieldId) || SERVICEDESK_APPROVAL_TYPE;

	const { approval, isEditable, saveLinkedFieldValue } = useApprovalField({
		issueKey,
		approvalFieldId,
	});

	// No need to render ApprovalPanel if there are no active approvals
	if (approval == null) {
		return null;
	}

	return (
		<AsyncApprovalPanel
			approval={approval}
			hasLinkedFieldEditPermissions={Boolean(isEditable)}
			// A key is used to destroy and recreate the approval panel component when the approval step changes
			// This ensures the internal state is reset when using consecutive approval steps
			key={approval.id}
			onSaveLinkedFieldValue={saveLinkedFieldValue}
			setRefreshIssue={setRefreshIssue}
		/>
	);
};
