import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import {
	REQUIRED,
	STRING_INVALID,
	MAX_STRING_SIZE,
	URL_INVALID,
} from '@atlassian/jira-issue-form-field-common/src/constants.tsx';
import { convertWikiToAdf } from '@atlassian/jira-platform-convert-wiki-to-adf/src/main.tsx';
import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import { LEARN_MORE_MESSAGES } from './constants';
import messages from './messages';

export const getLearnMoreMessage = (fieldHelpTextUrl: string): MessageDescriptor => {
	// fieldHelpTextUrl is used as a unique key. Extracting a suffix just for making the key shorter
	const urlSuffix = fieldHelpTextUrl.substring(fieldHelpTextUrl.lastIndexOf('#'));

	if (urlSuffix in LEARN_MORE_MESSAGES) {
		return LEARN_MORE_MESSAGES[urlSuffix];
	}
	return messages.fieldHelpTextLabel;
};

export const getHelpText = (fieldHelpTextUrl: string, formatMessage: FormatMessage): string => {
	// fieldHelpTextUrl is used as a unique key. Extracting a suffix just for making the key shorter
	const helperMessage = getLearnMoreMessage(fieldHelpTextUrl) || messages.fieldHelpTextLabel;

	return `[${formatMessage(helperMessage)}|${fieldHelpTextUrl}]`;
};

export const adfDocument = ({ description }: { description?: string | null }) =>
	convertWikiToAdf(description);

export const getErrorMessage = (
	validationError: string | boolean,
	fieldName: string,
	formatMessage: FormatMessage,
	formatNumber: (number: number | bigint) => string,
): string | boolean => {
	switch (validationError) {
		case REQUIRED: {
			return formatMessage(messages.requiredErrorMessage, { fieldName });
		}
		case STRING_INVALID: {
			return formatMessage(messages.stringInvalidErrorMessage, {
				fieldName,
				MAX_STRING_SIZE: formatNumber(MAX_STRING_SIZE),
			});
		}
		case URL_INVALID: {
			return formatMessage(messages.urlInvalidErrorMessage, {
				fieldName,
			});
		}
		default: {
			return validationError;
		}
	}
};
