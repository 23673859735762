import React, { useEffect, useMemo } from 'react';
import type { CommandPaletteIssueActionsProps } from '@atlassian/jira-command-palette-issue-actions-common/src/common/types/index.tsx';
import { asyncIssueActionsEntryPoint } from '@atlassian/jira-command-palette-issue-actions-core/entrypoint';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';

type CommandPaletteIssueActions = {
	issueKey: string;
};

const STABLE_EMPTY_OBJECT = {};
export const CommandPaletteIssueActions = ({ issueKey }: CommandPaletteIssueActions) => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		asyncIssueActionsEntryPoint,
		STABLE_EMPTY_OBJECT,
	);
	const issueId = useIssueId();
	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	const runtimeProps: CommandPaletteIssueActionsProps = useMemo(
		() => ({ issueKey, issueId, trigger: 'issue-view' }),
		[issueKey, issueId],
	);
	return (
		<JiraEntryPointContainer
			id="jira.issue-view.command-palette.issue-actions-core"
			packageName="issue-view"
			entryPointReferenceSubject={entryPointReferenceSubject}
			fallback={<></>}
			runtimeProps={runtimeProps}
		/>
	);
};
