import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { PRINCIPAL_TYPES } from '@atlassian/jira-polaris-domain-view/src/view-access/constants.tsx';
import type { Account, Group } from '@atlassian/jira-polaris-domain-view/src/view-access/types';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import {
	isAccessForbiddenError,
	isNotFoundError,
} from '@atlassian/jira-polaris-lib-errors/src/controllers/utils';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Action } from '@atlassian/react-sweet-state';
import { applyRemoveViewAccessPrincipals } from '../../../services/jpd-views-service';
import type { State } from '../../types';

export const removeViewAccessPrincipals =
	({
		viewUUID,
		accounts,
		groups,
		viewId,
		createAnalyticsEvent,
		onActionFailed,
	}: {
		viewUUID: string;
		accounts: string[];
		groups: string[];
		viewId?: string;
		createAnalyticsEvent: CreateUIAnalyticsEvent;
		onActionFailed?: (err: Error) => void;
	}): Action<State> =>
	async ({ setState, getState }) => {
		const state = getState();
		const accessLevel = state.accessLevel;

		const filterOutDeletedAccounts = (item: Account) => !accounts.includes(item.accountId);
		const filterOutDeletedGroups = (item: Group) => !groups.includes(item.groupId);

		const newAdditionalAccess = {
			profiles: state.additionalAccess.profiles.filter(filterOutDeletedAccounts),
			groups: state.additionalAccess.groups.filter(filterOutDeletedGroups),
		};

		setState({
			additionalAccess: newAdditionalAccess,
			config: {
				...state.config,
				skippedAccountFailures: state.config.skippedAccountFailures.filter(
					({ accountId }) => !accounts.includes(accountId),
				),
			},
		});

		try {
			experience.viewPermissions.removePrincipal.start();

			await applyRemoveViewAccessPrincipals(viewUUID, accounts, groups);

			// https://data-portal.internal.atlassian.com/analytics/registry/69641
			fireTrackAnalytics(createAnalyticsEvent({}), 'viewAccessData principalRemoved', {
				viewUuid: viewUUID,
				viewId,
				accessLevel,
				principalType: accounts.length > 0 ? PRINCIPAL_TYPES.PROFILE : PRINCIPAL_TYPES.GROUP,
			});

			sendPendoTrackEvent({
				actionSubjectAndAction: 'viewAccessData principalRemoved',
				actionSubjectId: 'viewAccessData',
				attributes: {
					viewUuid: viewUUID,
					viewId: viewId || '',
					accessLevel,
					principalType: accounts.length > 0 ? PRINCIPAL_TYPES.PROFILE : PRINCIPAL_TYPES.GROUP,
				},
			});

			experience.viewPermissions.removePrincipal.success();

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (isClientFetchError(error) || isAccessForbiddenError(error) || isNotFoundError(error)) {
				experience.viewPermissions.removePrincipal.abort(error);
			} else {
				experience.viewPermissions.removePrincipal.failure(error);
			}

			fireErrorAnalytics(
				createErrorAnalytics('polaris.view-access.remove-view-access-principals', error),
			);

			const filterOutNonDeletedAccounts = (item: Account) => accounts.includes(item.accountId);
			const filterOutNonDeletedGroups = (item: Group) => groups.includes(item.groupId);

			const currentState = getState();
			setState({
				additionalAccess: {
					profiles: [
						...currentState.additionalAccess.profiles.filter(filterOutDeletedAccounts),
						...state.additionalAccess.profiles.filter(filterOutNonDeletedAccounts),
					],
					groups: [
						...currentState.additionalAccess.groups.filter(filterOutDeletedGroups),
						...state.additionalAccess.groups.filter(filterOutNonDeletedGroups),
					],
				},
			});

			onActionFailed?.(error);
		}
	};
