import { performGetRequest, performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import { API_PATH } from './constants';

const getApplicationPropertyUrl = (baseUrl: BaseUrl, propertyKey: string): string =>
	`${baseUrl}${API_PATH}?key=${propertyKey}`;

const getApplicationPropertiesUrl = (propertyKeyFilter: string): string =>
	`${API_PATH}?keyFilter=${propertyKeyFilter}`;

const setApplicationPropertiesUrl = (baseUrl: BaseUrl, propertyKey: string): string =>
	`${baseUrl}${API_PATH}/${propertyKey}`;

export const getApplicationProperty = <T,>(baseUrl: BaseUrl, propertyKey: string): Promise<T> =>
	performGetRequest(getApplicationPropertyUrl(baseUrl, propertyKey));

// Allows for retrieving a list of properties matching the key filter (e.g. jira.admin.confluence.*)
// See https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-jira-settings/#api-rest-api-3-application-properties-get
export const getApplicationProperties = <T,>(propertyKeyFilter: string): Promise<T[]> =>
	performGetRequest(getApplicationPropertiesUrl(propertyKeyFilter));

export const setApplicationProperties = async <T,>(
	baseUrl: BaseUrl,
	propertyKey: string,
	data: string,
): Promise<T> =>
	performPutRequest(setApplicationPropertiesUrl(baseUrl, propertyKey), {
		body: data,
	});
