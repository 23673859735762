import React, { useCallback, useMemo, useState } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';
import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';
import ProjectEditViewEntryPoint from '@atlassian/jira-issue-field-project-editview-full/src/entrypoint';
import type {
	Option,
	ProjectEditViewProps,
} from '@atlassian/jira-issue-field-project-editview-full/src/ui/project/types.tsx';
import { ProjectReadView } from '@atlassian/jira-issue-field-project-readview-full/src/ui/project/index.tsx';
import type { project_issueFieldProject_ProjectField_Mutation as ProjectMutation } from '@atlassian/jira-relay/src/__generated__/project_issueFieldProject_ProjectField_Mutation.graphql';
import type { project_issueFieldProjectInlineEditFull_ProjectInlineEditView_fragmentRef$key as ProjectFragment } from '@atlassian/jira-relay/src/__generated__/project_issueFieldProjectInlineEditFull_ProjectInlineEditView_fragmentRef.graphql';
import type { project_issueFieldProjectInlineEditFull_ProjectInlineEditViewWithIsEditable_fragmentRef$key as ProjectWithIsEditableFragment } from '@atlassian/jira-relay/src/__generated__/project_issueFieldProjectInlineEditFull_ProjectInlineEditViewWithIsEditable_fragmentRef.graphql';
import type {
	ProjectInlineEditViewProps,
	ProjectInlineEditViewWithIsEditableProps,
	AggJiraProject,
} from './types';
import { isEqualOption, tranformOptionToValue } from './utils';

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components. This variant allows
 * consumers to define their own value to determine whether the field is editable.
 *
 * In most cases consumers should use `ProjectInlineEditView` which will enforce that the user has permission to
 * edit the field within the issue view. However, this component can be used for experiences that have differing
 * permissions or want finer control for how this data is retrieved, e.g. lazy loading editability.
 *
 * @param props [ProjectInlineEditViewWithIsEditableProps](./types.tsx)
 */
export const ProjectInlineEditViewWithIsEditable = ({
	editViewPopup,
	editViewPopupAlignBlock,
	fragmentRef,
	isEditable,
	isEditing: startWithEditViewOpen = false,
	menuPosition,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	readViewFitContainerHeight,
	spacing = 'compact',
}: ProjectInlineEditViewWithIsEditableProps) => {
	// #region Relay

	const data = useFragment<ProjectWithIsEditableFragment>(
		graphql`
			fragment project_issueFieldProjectInlineEditFull_ProjectInlineEditViewWithIsEditable_fragmentRef on JiraProjectField {
				id
				name
				type
				...project_issueFieldProjectReadviewFull_ProjectReadView
				project {
					id
					name
					# projectId and avatar.large are only needed to satisfy field data requirements (@required) set by mainIssueAggQuery
					projectId
					avatar {
						small
						large
					}
					key
				}
			}
		`,
		fragmentRef,
	);

	const [commit] = useMutation<ProjectMutation>(graphql`
		mutation project_issueFieldProject_ProjectField_Mutation($input: JiraUpdateProjectFieldInput!)
		@raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateProjectField(input: $input) {
					success
					errors {
						message
					}
					field {
						...project_issueFieldProjectInlineEditFull_ProjectInlineEditViewWithIsEditable_fragmentRef
					}
				}
			}
		}
	`);
	// #endregion

	// #region Common state
	const { overriding } = useInlineEditFieldInjections();
	const { id: uniqueFieldId, name, type: fieldType, project } = data;

	const isFieldEditable = overriding.overrideIsEditable(isEditable);
	const fieldName = overriding.overrideLabel(name);

	const [updatedValue, setUpdatedValue] = useState<AggJiraProject>(project);

	const handleSubmit = useCallback(
		(value: AggJiraProject, { onSuccess, onFail }: OnSubmitCallbacks) => {
			onSubmit?.(value);
			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operation: {
							operation: 'SET',
							id: value?.id,
						},
					},
				},
				onCompleted(mutationData) {
					if (mutationData.jira?.updateProjectField?.success) {
						onSuccess();
					} else {
						onFail();
					}
				},
				onError(error: Error) {
					onFail(error);
				},
				optimisticResponse: {
					jira: {
						updateProjectField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								name: fieldName,
								type: fieldType,
								project: value,
							},
						},
					},
				},
			});
		},
		[commit, fieldName, fieldType, onSubmit, uniqueFieldId],
	);

	const {
		invalidMessage,
		isEditing,
		hasServerValidationError,
		handleCancel,
		handleEdit,
		handleConfirm,
		handleChangeAndConfirm,
	} = useFieldInlineEditActions({
		fieldId: uniqueFieldId,
		fieldName,
		fieldType,
		initialValue: project,
		isValueEqual: isEqualOption,
		onSubmit: handleSubmit,
		onSubmitFailed,
		onSubmitSucceeded,
		onUpdateValue: setUpdatedValue,
		startWithEditViewOpen,
		updatedValue,
	});

	// #region Read view
	const renderReadView = () => <ProjectReadView fragmentRef={data} />;
	// #endregion

	const transformValueToOption = useCallback(
		(value: AggJiraProject): Option | null =>
			value
				? {
						label: value.name,
						value: value.id,
						projectId: value.projectId,
						iconUrl: value.avatar
							? { small: value.avatar.small, large: value.avatar.large }
							: undefined,
						key: value.key,
					}
				: null,
		[],
	);

	// #region Edit view
	const getEditViewProps = (fieldProps: ValidationFieldProps): ProjectEditViewProps => ({
		...fieldProps,
		autoFocus: true,
		fieldId: uniqueFieldId,
		fieldName,
		value: transformValueToOption(updatedValue),
		menuPosition,
		onChange: (value: Option | null) => handleChangeAndConfirm(tranformOptionToValue(value)),
		openMenuOnFocus: true,
		isClearable: true,
		spacing,
	});
	const editViewEntryPointParams = useMemo(() => ({}), []);

	// #endregion

	return (
		<FieldInlineEditLiteWithEntryPoint
			editViewPopup={editViewPopup}
			editViewPopupAlignBlock={editViewPopupAlignBlock}
			editViewEntryPoint={ProjectEditViewEntryPoint}
			editViewEntryPointParams={editViewEntryPointParams}
			getEditViewProps={getEditViewProps}
			fieldName={fieldName}
			hasUnsubmittedChanges={hasServerValidationError}
			invalidMessage={invalidMessage}
			isEditing={isEditing}
			isEditable={isFieldEditable}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			onEdit={handleEdit}
			readViewFitContainerHeight={readViewFitContainerHeight}
			readView={renderReadView}
			hideActionButtons
		/>
	);
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components.
 *
 * @param props [ProjectInlineEditViewProps](./types.tsx)
 */
export const ProjectInlineEditView = ({ fragmentRef, ...props }: ProjectInlineEditViewProps) => {
	// #region Relay
	const data = useFragment<ProjectFragment>(
		graphql`
			fragment project_issueFieldProjectInlineEditFull_ProjectInlineEditView_fragmentRef on JiraProjectField {
				...project_issueFieldProjectInlineEditFull_ProjectInlineEditViewWithIsEditable_fragmentRef
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);
	// #endregion

	return (
		<ProjectInlineEditViewWithIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data.fieldConfig?.isEditable ?? false}
		/>
	);
};
