import React from 'react';

import { token } from '@atlaskit/tokens';

import { RainbowBorder } from './RainbowBorder';
import type { IconProps } from './types';

export const Icon = (props: IconProps) => {
	const { shownAt } = props;

	return (
		<RainbowBorder shownAt={shownAt}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				role="presentation"
			>
				<path
					d="M18.636 15H7C6.46957 15 5.96086 15.2107 5.58579 15.5858C5.21071 15.9609 5 16.4696 5 17C5 17.5304 5.21071 18.0391 5.58579 18.4142C5.96086 18.7893 6.46957 19 7 19H19"
					stroke={token('color.icon', '#44546F')}
					strokeWidth="2"
				/>
				<path
					d="M19 15V5H7C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V17"
					stroke={token('color.icon', '#44546F')}
					strokeWidth="2"
					strokeLinejoin="round"
				/>
				<path
					d="M19 19C18.4696 19 17.9609 18.7893 17.5858 18.4142C17.2107 18.0391 17 17.5304 17 17C17 16.4696 17.2107 15.9609 17.5858 15.5858C17.9609 15.2107 18.4696 15 19 15"
					stroke={token('color.icon', '#44546F')}
					strokeWidth="2"
					strokeLinecap="round"
				/>
			</svg>
		</RainbowBorder>
	);
};
