import { FieldValueLookupError } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/errors/field-value-lookup-error.tsx';
import {
	peopleScopes,
	type PeoplePublicGetValue,
	type PeoplePublicSetValue,
} from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/fields/people';
import type {
	LookupUser,
	LookupUsers,
} from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/types/lookup';
import { getValueLookupValuesDiff } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/utils/get-value-lookup-values-diff';
import { multiValueLookup } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/utils/multi-value-lookup';
import { commonIssueViewGranularScopes } from './fields-scopes';

type InternalGetValue = {
	accountId: string;
	accountType: string;
	active: boolean;
	avatarUrl: string;
	avatarUrls: {
		[key: string]: string | undefined;
	};
	displayName: string;
	locale: string;
};

type InternalSetValue = {
	accountId: string;
	avatarUrls: {
		['24x24']: string;
	};
	displayName: string;
	id: string;
};

type PeopleInternalGetValue = InternalGetValue[];
type PeopleInternalSetValue = InternalSetValue[];

export const transformPeopleSetValue = (
	values: PeoplePublicSetValue,
	lookupValues?: LookupUsers,
): PeopleInternalSetValue => {
	if (!values.length) {
		return [];
	}

	const filteredLookupValues = multiValueLookup<LookupUser>(values, lookupValues, 'accountId');

	if (values.length !== filteredLookupValues.length) {
		const notFoundValues = getValueLookupValuesDiff(values, filteredLookupValues, 'accountId');

		throw new FieldValueLookupError(notFoundValues.join());
	}

	return filteredLookupValues.map(({ accountId, name, picture }) => ({
		accountId,
		displayName: name,
		avatarUrls: {
			'24x24': picture,
		},
		id: accountId,
	}));
};

export const transformPeopleGetValue = (value: PeopleInternalGetValue): PeoplePublicGetValue => {
	if (!value) {
		return [];
	}

	return value.map(({ accountId }) => ({ accountId }));
};

export const peopleIssueViewScopes = {
	...peopleScopes,
	granular: [...peopleScopes.granular, ...commonIssueViewGranularScopes],
};
