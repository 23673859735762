import React, { useCallback, useMemo, useRef, type ReactNode, useEffect } from 'react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { PopupUserPicker, type ActionTypes, type Value } from '@atlaskit/user-picker';
// eslint-disable-next-line import/order
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { INVITE_AND_ASSIGN_ID } from '@atlassian/jira-invite-and-assign/src/common/constants.tsx';
import { useInvitePeopleDrawer } from '@atlassian/jira-invite-people-drawer/src/controllers';
import {
	fireOperationalAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';
import { INVITE_PEOPLE_ID, UNASSIGNED_ID } from '../../../common/constants';
import type { UserOption, UserValue } from '../../../common/types';
import { AssigneePickerView } from '../../../common/ui/read-view/popover/index.tsx';
import { getUserFromUserOption } from '../../../common/utils';
import messages from '../../../messages';
import { useAssigneeOptions } from '../../../services/assignee-options';
import useUsersQuery from '../../../services/users-query';
import type { AssigneePickerEditProps } from './types';

const DEFAULT_SEARCH_DEBOUCE_TIMEOUT = 300;
const ACTION_SUBJECT = 'assigneePicker';

const AssigneeFieldOptionsLoadExperience = new UFOExperience('assignee-picker.field-options-load', {
	type: ExperienceTypes.Operation,
	performanceType: ExperiencePerformanceTypes.Custom,
	category: 'assignee-picker.field-options-load',
});

const AssigneePickerEdit = ({
	actionSubject,
	shouldPreloadAssignToMe = false,
	autoCompleteUrl,
	value = null,
	onFocus,
	onBlur,
	onChange,
	searchDebounceTimeout = DEFAULT_SEARCH_DEBOUCE_TIMEOUT,
	enablePeopleInvite = true,
	popupPlacement = 'auto',
	isEligibleForInviteAndAssign,
	suggestedEmailDomain,
	onInviteAndAssignOption,
	onOpen,
	onClose,
}: AssigneePickerEditProps) => {
	const intl = useIntl();
	const self = useRef<{
		previousQuery?: string;
	}>({ previousQuery: undefined }).current;
	const [{ data: users, loading, error }, fetchUsers] = useUsersQuery(autoCompleteUrl || '');
	const [assigneeValue, assigneeOptions] = useAssigneeOptions(
		value,
		users,
		intl,
		shouldPreloadAssignToMe,
		self.previousQuery,
		enablePeopleInvite,
		true,
		suggestedEmailDomain,
		isEligibleForInviteAndAssign,
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [, { openDrawer }] = useInvitePeopleDrawer();

	useEffect(() => {
		if (loading) {
			fireOperationalAnalytics(
				createAnalyticsEvent({
					actionSubject: actionSubject ?? ACTION_SUBJECT,
					action: 'loading',
				}),
				'assigneeOptions loading',
			);
			AssigneeFieldOptionsLoadExperience.start();
		}
		if (error) {
			fireOperationalAnalytics(
				createAnalyticsEvent({
					actionSubject: actionSubject ?? ACTION_SUBJECT,
					action: 'failed',
				}),
				'assigneeOptions failed',
			);
			AssigneeFieldOptionsLoadExperience.failure();
		}
		if (users.length) {
			fireOperationalAnalytics(
				createAnalyticsEvent({
					actionSubject: actionSubject ?? ACTION_SUBJECT,
					action: 'loaded',
				}),
				'assigneeOptions loaded',
			);
			AssigneeFieldOptionsLoadExperience.success();
		}
	}, [loading, error, users, createAnalyticsEvent, actionSubject]);

	const handleSearch: (query?: string) => void = useMemo(
		() =>
			debounce((query?: string) => {
				query !== self.previousQuery && fetchUsers(query);
				self.previousQuery = query;
				fireUIAnalytics(
					createAnalyticsEvent({
						actionSubject: actionSubject ?? ACTION_SUBJECT,
						action: 'searched',
					}),
				);
			}, searchDebounceTimeout),
		// go/jfe-eslint
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[fetchUsers, searchDebounceTimeout, self.previousQuery],
	);

	const handleOnFocus = useCallback(
		(sessionId?: string) => {
			onFocus?.(sessionId);
			fireUIAnalytics(
				createAnalyticsEvent({
					actionSubject: actionSubject ?? ACTION_SUBJECT,
					action: 'focused',
				}),
			);

			if (!users || !users.length) {
				fetchUsers();
			}
		},
		[onFocus, users, createAnalyticsEvent, actionSubject, fetchUsers],
	);

	const handleOnBlur = useCallback(
		(sessionId?: string) => {
			onBlur?.(sessionId);
			fireUIAnalytics(
				createAnalyticsEvent({
					actionSubject: actionSubject ?? ACTION_SUBJECT,
					action: 'blurred',
				}),
			);

			self.previousQuery = undefined;
		},
		[actionSubject, createAnalyticsEvent, onBlur, self],
	);

	const handleOnChange = useCallback(
		(updatedValue: Value, action: ActionTypes) => {
			if (!updatedValue || Array.isArray(updatedValue)) {
				return;
			}

			if (isEligibleForInviteAndAssign && updatedValue.id === INVITE_AND_ASSIGN_ID) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				onInviteAndAssignOption?.(updatedValue as UserOption, action);
				return;
			}
			if (
				enablePeopleInvite &&
				updatedValue.id === INVITE_PEOPLE_ID &&
				expValEquals('invite.from.assign', 'cohort', 'variation')
			) {
				openDrawer(createAnalyticsEvent ?? null, { inviteFlow: 'assignee' });
				onChange?.(value, action);
				return;
			}

			const newValue: UserValue =
				updatedValue.id === UNASSIGNED_ID
					? null
					: users.find(({ accountId }) => accountId === updatedValue.id) ||
						getUserFromUserOption(updatedValue);

			onChange?.(newValue, action);
			fireUIAnalytics(
				createAnalyticsEvent({
					actionSubject: actionSubject ?? ACTION_SUBJECT,
					action: 'changed',
				}),
			);
		},
		[
			isEligibleForInviteAndAssign,
			enablePeopleInvite,
			users,
			onChange,
			createAnalyticsEvent,
			actionSubject,
			onInviteAndAssignOption,
			openDrawer,
			value,
		],
	);

	return (
		<PopupUserPicker
			fieldId={null}
			placeholder={intl.formatMessage(messages.searchForAssignee)}
			isLoading={loading}
			options={assigneeOptions}
			target={({ ref, isOpen }) => (
				/*
                    onClick is being used here as a workaround. onOpen should be being passed to the PopupUserPicker
                    but there is a bug with the atlaskit component, where the onOpen function is being called when the
                    popup is closed.
                */
				<AvatarButton
					innerRef={ref}
					active={isOpen}
					onClick={onOpen}
					data-testid="issue-field-assignee.ui.popover.edit-view.test"
				>
					<AssigneePickerView value={value} />
				</AvatarButton>
			)}
			value={assigneeValue}
			onFocus={handleOnFocus}
			onBlur={handleOnBlur}
			onChange={handleOnChange}
			onInputChange={handleSearch}
			placement={popupPlacement}
			onClose={onClose}
		/>
	);
};

export default AssigneePickerEdit;

type AvatarButtonProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	innerRef: any;
	active: boolean;
	children: ReactNode;
};

// color taken from the old AK avatar component where hover over effect is still supported (without dummy href or onClick)
const AVATAR_SHADE_COLOR = 'rgba(9, 30, 66, 0.36)'; // This variable is only used as a fallback to a token so there is no need to convert it to a token

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const AvatarButtonOld = styled.button<AvatarButtonProps>((props) => ({
	/* button css reset */
	display: 'block',
	background: 'transparent',
	border: 0,
	outline: 0,
	padding: 0,
	margin: 0,
	borderRadius: '50%',
	boxShadow: '0 0 0 2px transparent',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		cursor: 'pointer',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'span:first-of-type::after': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		opacity: props.active ? 1 : 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		backgroundColor: props.active
			? token('color.interaction.pressed', AVATAR_SHADE_COLOR)
			: 'transparent',
	},
	'&:active': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
		boxShadow: '0 0 0 2px transparent !important',
	},
	'&:focus, &:focus-within': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		boxShadow: `0 0 0 2px ${token('color.border.focused', colors.B200)}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:hover span:first-of-type::after': {
		opacity: 1,
		backgroundColor: token('color.interaction.hovered', AVATAR_SHADE_COLOR),
	},
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const AvatarButtonNew = styled.button<AvatarButtonProps>((props) => ({
	/* button css reset */
	display: 'block',
	background: 'transparent',
	border: 0,
	outline: 0,
	padding: 0,
	margin: 0,
	borderRadius: '50%',
	boxShadow: '0 0 0 2px transparent',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		cursor: 'pointer',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'span:first-of-type::after': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		opacity: props.active ? 1 : 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		backgroundColor: props.active
			? token('color.interaction.pressed', AVATAR_SHADE_COLOR)
			: 'transparent',
	},
	'&:active': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
		boxShadow: '0 0 0 2px transparent !important',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:hover span:first-of-type::after': {
		opacity: 1,
		backgroundColor: token('color.interaction.hovered', AVATAR_SHADE_COLOR),
	},
}));

const AvatarButton = componentWithCondition(
	isVisualRefreshEnabled,
	AvatarButtonNew,
	AvatarButtonOld,
);
