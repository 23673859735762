import {
	ARCHIVED_BY_FIELD_TYPE,
	ARCHIVED_FIELD_TYPE,
	ARCHIVED_ON_FIELD_TYPE,
	ASSIGNEE_FIELD_TYPE,
	ATLAS_GOAL_FIELD_TYPE,
	ATLAS_PROJECT_FIELD_TYPE,
	ATLAS_PROJECT_STATUS_FIELD_TYPE,
	CF_DEVELOPMENT_FIELD_TYPE,
	CHECKBOX_FIELD_TYPE,
	CREATED_FIELD_TYPE,
	CREATOR_FIELD_TYPE,
	CUSTOM_LABELS_FIELD_TYPE,
	DATE_FIELD_TYPE,
	DATE_TIME_FIELD_TYPE,
	DELIVERY_PROGRESS_FIELD_TYPE,
	DELIVERY_STATUS_FIELD_TYPE,
	DESCRIPTION_FIELD_TYPE,
	EXTERNAL_REFERENCE_FIELD_TYPE,
	FORMULA_FIELD_TYPE,
	HYPERLINK_FIELD_TYPE,
	INSIGHTS_FIELD_TYPE,
	INTERVAL_FIELD_TYPE,
	ISSUE_COMMENTS_FIELD_TYPE,
	ISSUE_ID_FIELD_TYPE,
	ISSUE_KEY_FIELD_TYPE,
	ISSUE_LINKS_FIELD_TYPE,
	ISSUE_TYPE_FIELD_TYPE,
	JSW_MULTI_SELECT_FIELD_TYPE,
	LABELS_FIELD_TYPE,
	LINKED_ISSUES_FIELD_TYPE,
	MULTI_SELECT_FIELD_TYPE,
	NUMBER_FIELD_TYPE,
	PEOPLE_FIELD_TYPE,
	JSW_PEOPLE_FIELD_TYPE,
	PRIORITY_FIELD_TYPE,
	PROJECT_FIELD_TYPE,
	RANK_FIELD_TYPE,
	RATING_FIELD_TYPE,
	REACTIONS_FIELD_TYPE,
	REPORTER_FIELD_TYPE,
	SHORT_TEXT_FIELD_TYPE,
	SINGLE_SELECT_FIELD_TYPE,
	SLIDER_FIELD_TYPE,
	STATUS_FIELD_TYPE,
	SUMMARY_FIELD_TYPE,
	UPDATED_FIELD_TYPE,
	VOTES_FIELD_TYPE,
	CONNECTION_FIELD_TYPE,
} from './constants';
import type {
	GlobalFieldTypeMap,
	SharedFieldTypeMap,
	JiraApiFieldTypeMap,
	FieldTypeMap,
	FieldType,
} from './types';

export const isDeliveryFieldType = (fieldType?: FieldType): boolean =>
	fieldType === FIELD_TYPES.DELIVERY_PROGRESS || fieldType === FIELD_TYPES.DELIVERY_STATUS;

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { FieldType, JiraApiFieldType, StandardFieldType, GlobalFieldType } from './types';

export const FIELD_TYPES_CATEGORIES: {
	ARCHIVED: FieldType[];
	ATLAS: FieldType[];
	USER: FieldType[];
} = {
	ARCHIVED: [ARCHIVED_BY_FIELD_TYPE, ARCHIVED_FIELD_TYPE, ARCHIVED_ON_FIELD_TYPE],
	ATLAS: [ATLAS_GOAL_FIELD_TYPE, ATLAS_PROJECT_FIELD_TYPE, ATLAS_PROJECT_STATUS_FIELD_TYPE],
	USER: [ASSIGNEE_FIELD_TYPE, CREATOR_FIELD_TYPE, REPORTER_FIELD_TYPE],
};

export const GLOBAL_FIELD_TYPES: GlobalFieldTypeMap = {
	CHECKBOX: CHECKBOX_FIELD_TYPE,
	FORMULA: FORMULA_FIELD_TYPE,
	INTERVAL: INTERVAL_FIELD_TYPE,
	HYPERLINK: HYPERLINK_FIELD_TYPE,
	MULTI_SELECT: MULTI_SELECT_FIELD_TYPE,
	JSW_MULTI_SELECT: JSW_MULTI_SELECT_FIELD_TYPE,
	NUMBER: NUMBER_FIELD_TYPE,
	RATING: RATING_FIELD_TYPE,
	REACTIONS: REACTIONS_FIELD_TYPE,
	SHORT_TEXT: SHORT_TEXT_FIELD_TYPE,
	SINGLE_SELECT: SINGLE_SELECT_FIELD_TYPE,
	SLIDER: SLIDER_FIELD_TYPE,
	JSW_PEOPLE: JSW_PEOPLE_FIELD_TYPE,
};

const SHARED_FIELD_TYPES: SharedFieldTypeMap = {
	...GLOBAL_FIELD_TYPES,
	ASSIGNEE: ASSIGNEE_FIELD_TYPE,
	CF_DEVELOPMENT: CF_DEVELOPMENT_FIELD_TYPE,
	CREATED: CREATED_FIELD_TYPE,
	CREATOR: CREATOR_FIELD_TYPE,
	CUSTOM_LABELS: CUSTOM_LABELS_FIELD_TYPE,
	DATE: DATE_FIELD_TYPE,
	DESCRIPTION: DESCRIPTION_FIELD_TYPE,
	FORMULA: FORMULA_FIELD_TYPE,
	ISSUE_ID: ISSUE_ID_FIELD_TYPE,
	ISSUE_KEY: ISSUE_KEY_FIELD_TYPE,
	ISSUE_LINKS: ISSUE_LINKS_FIELD_TYPE,
	ISSUE_TYPE: ISSUE_TYPE_FIELD_TYPE,
	LABELS: LABELS_FIELD_TYPE,
	PEOPLE: PEOPLE_FIELD_TYPE,
	PRIORITY: PRIORITY_FIELD_TYPE,
	PROJECT: PROJECT_FIELD_TYPE,
	RANK: RANK_FIELD_TYPE,
	REPORTER: REPORTER_FIELD_TYPE,
	STATUS: STATUS_FIELD_TYPE,
	SUMMARY: SUMMARY_FIELD_TYPE,
	UPDATED: UPDATED_FIELD_TYPE,
	VOTES: VOTES_FIELD_TYPE,
	CONNECTION: CONNECTION_FIELD_TYPE,
};

// types comming from jira API
export const JIRA_API_FIELD_TYPES: JiraApiFieldTypeMap = {
	...SHARED_FIELD_TYPES,
	EXTERNAL_REFERENCE: EXTERNAL_REFERENCE_FIELD_TYPE,
	DATE_TIME: DATE_TIME_FIELD_TYPE,
};

// types used on the Frontend
export const FIELD_TYPES: FieldTypeMap = {
	...SHARED_FIELD_TYPES,
	ARCHIVED_BY: ARCHIVED_BY_FIELD_TYPE,
	ARCHIVED_ON: ARCHIVED_ON_FIELD_TYPE,
	ARCHIVED: ARCHIVED_FIELD_TYPE,
	ATLAS_GOAL: ATLAS_GOAL_FIELD_TYPE,
	ATLAS_PROJECT_STATUS: ATLAS_PROJECT_STATUS_FIELD_TYPE,
	ATLAS_PROJECT: ATLAS_PROJECT_FIELD_TYPE,
	DELIVERY_PROGRESS: DELIVERY_PROGRESS_FIELD_TYPE,
	DELIVERY_STATUS: DELIVERY_STATUS_FIELD_TYPE,
	INSIGHTS: INSIGHTS_FIELD_TYPE,
	ISSUE_COMMENTS: ISSUE_COMMENTS_FIELD_TYPE,
	LINKED_ISSUES: LINKED_ISSUES_FIELD_TYPE,
};

const JPD_SYSTEM_FIELDS: Set<FieldType> = new Set([
	FIELD_TYPES.ASSIGNEE,
	FIELD_TYPES.CREATOR,
	FIELD_TYPES.CREATED,
	FIELD_TYPES.DESCRIPTION,
	FIELD_TYPES.ISSUE_ID,
	FIELD_TYPES.ISSUE_KEY,
	FIELD_TYPES.ISSUE_TYPE,
	FIELD_TYPES.LABELS,
	FIELD_TYPES.UPDATED,
	FIELD_TYPES.PROJECT,
	FIELD_TYPES.REPORTER,
	FIELD_TYPES.STATUS,
	FIELD_TYPES.SUMMARY,

	FIELD_TYPES.ARCHIVED,
	FIELD_TYPES.ARCHIVED_BY,
	FIELD_TYPES.ARCHIVED_ON,
	FIELD_TYPES.ATLAS_GOAL,
	FIELD_TYPES.ATLAS_PROJECT,
	FIELD_TYPES.ATLAS_PROJECT_STATUS,
	FIELD_TYPES.DELIVERY_PROGRESS,
	FIELD_TYPES.DELIVERY_STATUS,
	FIELD_TYPES.INSIGHTS,
	FIELD_TYPES.ISSUE_COMMENTS,
	FIELD_TYPES.LINKED_ISSUES,
]);

export const isSystemFieldType = (fieldType?: FieldType): boolean => {
	if (!fieldType) {
		return false;
	}

	return JPD_SYSTEM_FIELDS.has(fieldType);
};
