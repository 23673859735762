import { fetchIssueForgeData } from '@atlassian/jira-issue-fetch-services/src/services/issue-forge-data/index.tsx';
import type { ForgeResponse } from '@atlassian/jira-issue-fetch-services/src/types';
import type { CloudId, IssueKey, ActivationId } from '@atlassian/jira-shared-types/src/general.tsx';

export const fetchAllForgeData = async (
	cloudId: CloudId,
	issueKey: IssueKey,
	activationId: ActivationId,
	prefetchedResource?: Promise<ForgeResponse> | null,
	locale?: string,
): Promise<ForgeResponse> =>
	prefetchedResource || fetchIssueForgeData(cloudId, issueKey, activationId, locale);
