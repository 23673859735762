import AnalyticsData from '@atlassian/jira-analytics-web-react/src/components/analytics-data.tsx';
import {
	type TabItem,
	TAB_ITEM,
} from '@atlassian/jira-issue-shared-types/src/common/types/layout-item-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { fieldsSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector';
import { canAdministerProjectPermissionsSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/permissions-selector';
import renderTabItems from './renderer';
import TabPanel from './view';

export type TabPanelPayload = {
	tabItems: TabItem[];
};

type PropsWithPayload = {
	type: string;
	panelKey: string;
	payload: TabPanelPayload;
};

// FIXME: JIV-17455 should be fully typed
export default flowWithSafeComponent(
	// @ts-expect-error - Argument of type '<C extends ComponentType<P>, P = ComponentProps<C>>(WrappedComponent: C) => ContextResult<C, P>' is not assignable to parameter of type 'FlowStep<C, ComponentType<Matching<{ renderedItems: TabNode[]; tabItems: TabItem[]; type: string; panelKey: string; isProjectAdmin: boolean; } & {}, PropsWithoutRef<LibraryManagedAttributes<C, P & WithContextProps>> & RefAttributes<...>>>>'.
	AnalyticsData({
		attributes: {
			contextPanelType: TAB_ITEM,
		},
	}),
	// @ts-expect-error - Type instantiation is excessively deep and possibly infinite.
	connect(
		(state: State, { type, panelKey, payload: { tabItems } }: PropsWithPayload) => ({
			renderedItems: renderTabItems(tabItems, fieldsSelector(state)),
			tabItems,
			type,
			panelKey,
			isProjectAdmin: canAdministerProjectPermissionsSelector(state),
		}),
		{},
	),
)(TabPanel);
