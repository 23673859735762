import React from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import useMoveIssue from './hook';
import type { Props } from './types';

const MoveIssue = ({ onClick, label, itemKey }: Props) => {
	const handleClick = useMoveIssue(itemKey, onClick);
	return (
		<DropdownItem key="move" onClick={handleClick}>
			{label}
		</DropdownItem>
	);
};

export default MoveIssue;
