import { styled, css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { READ_VIEW_CONTAINER_COMPONENT_SELECTOR } from './read-view-container';

const nonEditableStyles = css({
	/* NonEditableMargin overrides */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		marginTop: token('space.150', '12px'),
		marginBottom: 0,
	},
	/**
	 * Override side-by-side field style-hacks that don't take into account
	 * non-editable states.
	 */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[READ_VIEW_CONTAINER_COMPONENT_SELECTOR]: {
		height: 'auto',
		lineHeight: 1,
		paddingTop: 0,
		paddingBottom: token('space.025', '2px'),
		paddingLeft: token('space.075', '6px'),
		paddingRight: token('space.075', '6px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export default styled.div<{ isEditable: boolean }>(
	{
		width: '100%',
		marginLeft: token('space.negative.100', '-8px'),
		marginTop: token('space.negative.100', '-8px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& form > div > div > div': {
			height: token('space.400', '32px'),
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditable }) => !isEditable && nonEditableStyles,
);
