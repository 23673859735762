import { createSelector } from 'reselect';
import { WATCHES } from '@atlassian/jira-issue-view-configurations';
import { fieldSelector } from '../../../common/state/selectors/field-selector';

const watchesSelector = fieldSelector(WATCHES);

// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
const watchesValueSelector = createSelector(watchesSelector, (watches) => watches.value);

export const isUserWatchingSelector = createSelector(
	watchesValueSelector,
	(watchesValue) => watchesValue.isWatching,
);

export const watchersCountSelector = createSelector(
	watchesValueSelector,
	(watchesValue) => watchesValue.watchCount,
);
