/**
 * @generated SignedSource<<b6805a790678d38f4846da1322c6fdf2>>
 * @relayHash 2b43d8085ffa5aad2385939631c6f064
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ce1beb28f7e0d87679402b9a82b0adb0d110216f4ff56accb177aef2ea208329

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraMultiValueFieldOperations = "ADD" | "REMOVE" | "SET" | "%future added value";
export type JiraUpdateComponentsFieldInput = {
  id: string;
  operations: ReadonlyArray<JiraComponentFieldOperationInput>;
};
export type JiraComponentFieldOperationInput = {
  ids: ReadonlyArray<string>;
  operation: JiraMultiValueFieldOperations;
};
export type ui_issueViewComponentsField_Mutation$variables = {
  input: JiraUpdateComponentsFieldInput;
};
export type ui_issueViewComponentsField_Mutation$data = {
  readonly jira: {
    readonly updateComponentsField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly components: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly ari: string | null | undefined;
              readonly componentId: string;
              readonly description: string | null | undefined;
              readonly id: string;
              readonly metadata: AGG$JSON | null | undefined;
              readonly name: string | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
        readonly selectedComponentsConnection: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly ari: string | null | undefined;
              readonly componentId: string;
              readonly description: string | null | undefined;
              readonly id: string;
              readonly metadata: AGG$JSON | null | undefined;
              readonly name: string | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueViewComponentsField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateComponentsField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly components: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly ari: string | null | undefined;
              readonly componentId: string;
              readonly description: string | null | undefined;
              readonly id: string;
              readonly metadata: AGG$JSON | null | undefined;
              readonly name: string | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
        readonly id: string;
        readonly selectedComponentsConnection: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly ari: string | null | undefined;
              readonly componentId: string;
              readonly description: string | null | undefined;
              readonly id: string;
              readonly metadata: AGG$JSON | null | undefined;
              readonly name: string | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueViewComponentsField_Mutation = {
  rawResponse: ui_issueViewComponentsField_Mutation$rawResponse;
  response: ui_issueViewComponentsField_Mutation$data;
  variables: ui_issueViewComponentsField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = [
  {
    "concreteType": "JiraComponentEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "JiraComponent",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "ScalarField",
            "name": "componentId"
          },
          {
            "kind": "ScalarField",
            "name": "name"
          },
          {
            "kind": "ScalarField",
            "name": "description"
          },
          {
            "kind": "ScalarField",
            "name": "ari"
          },
          {
            "kind": "ScalarField",
            "name": "metadata"
          }
        ]
      }
    ]
  }
],
v6 = {
  "concreteType": "JiraComponentConnection",
  "kind": "LinkedField",
  "name": "selectedComponentsConnection",
  "plural": false,
  "selections": (v5/*: any*/)
},
v7 = {
  "concreteType": "JiraComponentConnection",
  "kind": "LinkedField",
  "name": "components",
  "plural": false,
  "selections": (v5/*: any*/)
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "ui_issueViewComponentsField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraComponentsFieldPayload",
            "kind": "LinkedField",
            "name": "updateComponentsField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraComponentsField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ui_issueViewComponentsField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraComponentsFieldPayload",
            "kind": "LinkedField",
            "name": "updateComponentsField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraComponentsField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v4/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "ce1beb28f7e0d87679402b9a82b0adb0d110216f4ff56accb177aef2ea208329",
    "metadata": {},
    "name": "ui_issueViewComponentsField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "5f762d0a06e04630d55b387bd48efcb2";

export default node;
