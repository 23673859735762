import { useCallback } from 'react';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { getUsersPersonalSpaceInfo as getCurrentUsersSpace } from '../../services/confluence-rest-api';
import type { getUserPersonalSpaceResponse } from '../../services/confluence-rest-api/types';
import { NULL_SPACE_OBJECT, type SpaceInfo } from '../types';

export const useUsersPersonalSpaceInfo = () => {
	const cloudId = useCloudId();
	const getUsersPersonalSpaceInfo = useCallback(async (): Promise<SpaceInfo> => {
		const userSpaceInfoResponse = await getCurrentUsersSpace(cloudId);
		if (!userSpaceInfoResponse.success) return NULL_SPACE_OBJECT;

		const spaceInfo: getUserPersonalSpaceResponse = userSpaceInfoResponse.result;
		if (!spaceInfo?.personalSpace || spaceInfo.personalSpace.status === 'archived')
			return NULL_SPACE_OBJECT;

		return {
			spaceKey: spaceInfo.personalSpace.key,
			spaceId: spaceInfo.personalSpace.id,
			spaceName: spaceInfo.personalSpace.spaceName,
		};
	}, [cloudId]);

	return {
		getUsersPersonalSpaceInfo,
	};
};
