import memoizeOne from 'memoize-one';
import { isFedRamp } from '@atlassian/atl-context';
import { AssetsGlance } from '@atlassian/jira-asset-tracking-assets-glance/src/async';
import GoalsInlineEditViewField from '@atlassian/jira-business-goals-issue-view-field/src/ui/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import ConnectContextPanel from '@atlassian/jira-issue-connect-context-panel/src/ui/index.tsx';
import { ForgeIssueContext } from '@atlassian/jira-issue-ecosystem-forge/src/ui/issue-context';
import { ApprovalPanel, ApprovalGlance } from '@atlassian/jira-issue-view-context-approval/src';
import { StakeholdersGlancePanel } from '@atlassian/jira-jsm-stakeholder-updates/src/ui/glance/async.tsx';
import { getLayoutFieldTypes } from '@atlassian/jira-platform-field-config';
import type {
	CheckedLegacyFieldTypeDefinitions,
	IgnoredFieldCheck,
	CheckFieldTypeDefinitions,
} from '@atlassian/jira-platform-field-config/src/common/types/checked-fields.tsx';
import SlaPanel from '@atlassian/jira-servicedesk-sla-panel/src/async';
import DescriptionField from './common/description';
import MultiLineField from './common/switching-multiline-field';
import AllDevInfoPanel from './context/all-dev-info-panel/all-dev-panel-view';
import AssigneeField from './context/assignee/assignee-view';
import CascadingSelectFieldView from './context/cascading-select';
import ChatChannelField from './context/chat-channel';
import CheckboxField from './context/checkbox-select/checkbox-select-view';
import CmdbObjectView from './context/cmdb-object';
import ComponentsField from './context/components';
import ConferenceCallField from './context/conference-call';
import CustomerRequestTypeField from './context/customer-request-type';
import DateField from './context/date';
import DateTimeField from './context/date-time';
import ConnectGlanceView from './context/ecosystem/connect/connect-glance-view.tsx';
import ForgeCustomField from './context/ecosystem/forge/custom-field/index.tsx';
import ForgeGlanceView from './context/ecosystem/forge/issue-glance/view.tsx';
import EntitlementField from './context/entitlement';
import EpicField from './context/epic-link';
import MultiGroupPicker from './context/group-picker/multi';
import GroupPicker from './context/group-picker/single';
import KbGlancePanel from './context/knowledge-base-glance/kb-glance-panel.tsx';
import KbNestedPanel from './context/knowledge-base-glance/kb-nested-panel.tsx';
import LabelsField from './context/labels';
import LinkedAlertsField from './context/linked-alerts';
import MajorIncidentField from './context/major-incident';
import { StakeholdersGlanceComponent } from './context/manage-stakeholders';
import MessageField from './context/message-cf';
import MsTeamsChannelField from './context/msteams-channel';
import MultiSelectField from './context/multi-select';
import { MultiSelectFieldWithRelayEditView } from './context/multi-select-with-relay-edit-view';
import MultiUserField from './context/multi-user';
import NumberField from './context/number';
import NumberEstimate from './context/number-estimate';
import OrganizationsField from './context/organizations';
import OriginalEstimateField from './context/original-estimate';
import ParentField from './context/parent-field/main.tsx';
import ParentLinkField from './context/parent/parent-view';
import PeopleField from './context/people';
import PriorityField from './context/priority';
import ProjectField from './context/project';
import RadioField from './context/radio-select/radio-select-view';
import ReadOnlyField from './context/read-only/read-only-view';
import ReporterField from './context/reporter/reporter-view';
import RequestFeedbackField from './context/request-feedback/async';
import RequestLanguageField from './context/request-language';
import RequestParticipantsField from './context/request-participants';
import RespondersGlancePanel from './context/responders/responders-glance-panel/index.tsx';
import RespondersGlance from './context/responders/responders-glance/index.tsx';
import SentimentPanel from './context/sentiment/sentiment-dropdown.tsx';
import { ConnectedServiceEntity } from './context/service-entity';
import SingleLineTextField from './context/single-line-text/single-line-text-view';
import SingleSelectField from './context/single-select/single-select-view';
import SprintField from './context/sprint';
import { StoryPointEstimate } from './context/story-point-estimate';
import { TeamFieldNext } from './context/team-next';
import TeamField from './context/team/team-view';
import TimeTrackingField from './context/time-tracking';
import UrlField from './context/url/url-view';
import UserField from './context/user/user-view';
import VersionCustomField from './context/version';
import MultiVersionPicker from './context/version-multi';
import { VersionsFieldWithRelayEditView } from './context/versions-with-relay-edit-view';
import VersionsField from './context/versions/versions-view';
import ZoomMeetingField from './context/zoom-meeting';
import {
	isHideEpicLinkFromIssueViewForEpicRenamingBetaEnabled,
	isRemoveParentLinkFromIssueViewEnabled,
	isParentInIssueViewEnabled,
} from './feature-flags';
import type { LayoutItemMap, LayoutItemMapEntry } from './types';
import { AssetsOnboarding, AssetsPanel } from './utils';

// Assert that every 'checked' issue field has either been explicitly flagged
// ignored or otherwise has been implemented. In this way authors of new
// fields are made aware of every field context which must be supported.
//
// See the doc/playbooks/issue-fields.md for more information.
//
// Fields declared ignored suggest that it does not have a JSON definition and
// will instead be rendered using the backend-generated field HTML. Any new
// fields added here should be done so temporarily and an issue created to
// promote them to BUILTIN_COLUMN_CONFIGURATIONS.
type IgnoredFieldChecks<T> = {
	['com.atlassian.jconnect.jconnect-plugin:location']: IgnoredFieldCheck<T>;
	['com.atlassian.jconnect.jconnect-plugin:uuid']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.ext.charting:firstresponsedate']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.ext.charting:timeinstatus']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugin.system.customfieldtypes:flagged']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.plugins.jira-development-integration-plugin:devsummarycf']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:LastCommentDate']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:assigneedomain']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:attachments']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:comments']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:dayslastcommented']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:lastupdateorcommenter']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:lastusercommented']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:reporterdomain']: IgnoredFieldCheck<T>;
	['com.atlassian.jira.toolkit:userproperty']: IgnoredFieldCheck<T>;
	['com.atlassian.servicedesk.approvals-plugin:sd-approvals']: IgnoredFieldCheck<T>;
	['com.atlassian.servicedesk:sd-request-feedback-date']: IgnoredFieldCheck<T>;
	['com.atlassian.servicedesk:sd-sla-field']: IgnoredFieldCheck<T>;
	['com.pyxis.greenhopper.jira:gh-epic-color']: IgnoredFieldCheck<T>;
	['com.pyxis.greenhopper.jira:gh-lexo-rank']: IgnoredFieldCheck<T>;
	aggregateprogress: IgnoredFieldCheck<T>;
	aggregatetimeestimate: IgnoredFieldCheck<T>;
	aggregatetimeoriginalestimate: IgnoredFieldCheck<T>;
	attachment: IgnoredFieldCheck<T>;
	comment: IgnoredFieldCheck<T>;
	created: IgnoredFieldCheck<T>;
	creator: IgnoredFieldCheck<T>;
	issuekey: IgnoredFieldCheck<T>;
	issuelinks: IgnoredFieldCheck<T>;
	issuerestriction: IgnoredFieldCheck<T>;
	issuetype: IgnoredFieldCheck<T>;
	lastViewed: IgnoredFieldCheck<T>;
	progress: IgnoredFieldCheck<T>;
	project: IgnoredFieldCheck<T>;
	resolution: IgnoredFieldCheck<T>;
	resolutiondate: IgnoredFieldCheck<T>;
	security: IgnoredFieldCheck<T>;
	status: IgnoredFieldCheck<T>;
	statuscategorychangedate: IgnoredFieldCheck<T>;
	subtasks: IgnoredFieldCheck<T>;
	summary: IgnoredFieldCheck<T>;
	timeestimate: IgnoredFieldCheck<T>;
	timespent: IgnoredFieldCheck<T>;
	updated: IgnoredFieldCheck<T>;
	votes: IgnoredFieldCheck<T>;
	watches: IgnoredFieldCheck<T>;
	worklog: IgnoredFieldCheck<T>;
	workratio: IgnoredFieldCheck<T>;
};

/*
    The reasons why we memoize this function and not declare it as an object are:
      * Cannot declare as simple object - in case there is a FF in getLayoutItemMap() (which happens when we add a new field), because we cannot get FF value in SSR.
      * memoization is needed because we call getLayoutItemMap many times throughout renders. Returning a different object each time would cause huge
        render performance issues.
      * If we declare layout map as a simple object - there are issues with tests.
        Because some fields, like description, are being instantiated too early, making it being saved in the object as undefined.
        But with memoisation we instantiate it at the first time we call the function.
        It is probably not worth making this an object until we understand how this failing test affects prod
        @atlassian/jira-issue-view/src/views/issue-details/parameterised-integration-tests.js
*/
const getLayoutItemMap = (): LayoutItemMap => {
	const layoutFieldKeys = getLayoutFieldTypes();

	const baseLayoutItems: CheckFieldTypeDefinitions<
		CheckedLegacyFieldTypeDefinitions<LayoutItemMapEntry>,
		IgnoredFieldChecks<LayoutItemMapEntry>
	> = {
		// The system types
		[layoutFieldKeys.ASSIGNEE_TYPE]: {
			item: AssigneeField,
		},
		[layoutFieldKeys.DUE_DATE_TYPE]: { item: DateField },
		[layoutFieldKeys.PRIORITY_TYPE]: { item: PriorityField },
		[layoutFieldKeys.REPORTER_TYPE]: { item: ReporterField },
		[layoutFieldKeys.DESCRIPTION_TYPE]: { item: DescriptionField },
		[layoutFieldKeys.LABELS_TYPE]: { item: LabelsField },
		[layoutFieldKeys.COMPONENTS_TYPE]: { item: ComponentsField },
		[layoutFieldKeys.SPRINT_TYPE]: { item: SprintField },
		[layoutFieldKeys.STORY_POINTS_TYPE]: {
			item: StoryPointEstimate,
		},
		[layoutFieldKeys.TEAMS_PLATFORM_CF_TYPE]: {
			item: TeamFieldNext,
		},
		[layoutFieldKeys.EPIC_TYPE]: {
			item: isHideEpicLinkFromIssueViewForEpicRenamingBetaEnabled() ? () => null : EpicField,
		},
		[layoutFieldKeys.FIX_VERSIONS_TYPE]: {
			item: ff('issue.details.relay-release-version-field-issue-view-integration')
				? VersionsFieldWithRelayEditView
				: VersionsField,
		},
		[layoutFieldKeys.AFFECTS_VERSIONS_TYPE]: {
			item: ff('issue.details.relay-release-version-field-issue-view-integration')
				? VersionsFieldWithRelayEditView
				: VersionsField,
		},
		[layoutFieldKeys.TIME_ESTIMATE_TYPE]: {
			item: OriginalEstimateField,
		},
		[layoutFieldKeys.TIME_TRACKING_TYPE]: {
			item: TimeTrackingField,
		},
		// This field is shown only in Content area in Classic Software (Mar 2020)
		[layoutFieldKeys.ENVIRONMENT_TYPE]: { item: MultiLineField },

		// panels
		[layoutFieldKeys.DEV_SUMMARY_TYPE]: {
			item: AllDevInfoPanel,
		},
		// @ts-expect-error - TS2322 - Type '{ assignee: { item: any; }; duedate: { item: ComponentClass<Omit<{ fieldId: string; area: Area; label: string; } & { isEditing?: boolean | undefined; issueKey: string; fieldKey: string; } & Flow.Diff<Props, { ...; }> & { ...; } & { ...; }, keyof DispatchProps>> & { ...; }; }; ... 79 more ...; "com.pyxis.greenhopper....' is not assignable to type 'CheckFieldTypeDefinitions<CheckedLegacyFieldTypeDefinitions<LayoutItemMapEntry>, IgnoredFieldChecks<LayoutItemMapEntry>>'.
		[layoutFieldKeys.APPROVALS_PANEL_TYPE]: { item: ApprovalPanel, panel: ApprovalGlance },
		[layoutFieldKeys.KNOWLEDGE_BASE_TYPE]: {
			item: KbNestedPanel,
			panel: KbGlancePanel,
		},
		[layoutFieldKeys.SLA_PANEL_TYPE]: { item: SlaPanel },
		[layoutFieldKeys.LINKED_ALERTS_TYPE]: { item: LinkedAlertsField },
		[layoutFieldKeys.CHAT_CHANNEL_TYPE]: {
			item: isFedRamp() ? () => null : ChatChannelField,
		},
		[layoutFieldKeys.MSTEAMS_CHANNEL_TYPE]: {
			item: isFedRamp() ? () => null : MsTeamsChannelField,
		},
		[layoutFieldKeys.ZOOM_MEETING_TYPE]: {
			item: isFedRamp() ? () => null : ZoomMeetingField,
		},
		[layoutFieldKeys.MANAGE_STAKEHOLDERS_TYPE]: {
			item: StakeholdersGlanceComponent,
			panel: StakeholdersGlancePanel,
		},
		[layoutFieldKeys.CONFERENCE_CALL_TYPE]: {
			item: ff('hide-icc-for-all-users_avyh5') ? () => null : ConferenceCallField,
		},
		[layoutFieldKeys.ASSETS_CF_TYPE]: { item: AssetsPanel, panel: AssetsGlance },
		[layoutFieldKeys.RESPONDERS_CF_TYPE]: {
			item: RespondersGlance,
			panel: RespondersGlancePanel,
		},
		[layoutFieldKeys.MAJOR_INCIDENT_CF_TYPE]: {
			item: MajorIncidentField,
		},

		// Epic color field is rendered in the base layout, so it must be excluded from context items list
		[layoutFieldKeys.COLOR_CF_TYPE]: { item: () => null },
		[layoutFieldKeys.EPIC_COLOR_TYPE]: { item: () => null },
		[layoutFieldKeys.RELEASES_TYPE]: { item: () => null },

		// The custom types
		[layoutFieldKeys.PARENT_CF_TYPE]: {
			item: isRemoveParentLinkFromIssueViewEnabled() ? () => null : ParentLinkField,
		},
		[layoutFieldKeys.BASELINE_START_CF_TYPE]: { item: DateField },
		[layoutFieldKeys.BASELINE_END_CF_TYPE]: { item: DateField },
		[layoutFieldKeys.LABELS_CF_TYPE]: { item: LabelsField },
		[layoutFieldKeys.TEXT_CF_TYPE]: { item: SingleLineTextField },
		[layoutFieldKeys.MULTI_USER_CF_TYPE]: { item: MultiUserField },
		[layoutFieldKeys.PARTICIPANTS_CF_TYPE]: {
			item: MultiUserField,
		},
		[layoutFieldKeys.URL_CF_TYPE]: { item: UrlField },
		[layoutFieldKeys.NUMBER_CF_TYPE]: {
			item: NumberField,
		},
		[layoutFieldKeys.STORY_POINT_ESTIMATE_CF_TYPE]: {
			item: NumberEstimate,
		},
		[layoutFieldKeys.SELECT_CF_TYPE]: { item: SingleSelectField },
		[layoutFieldKeys.EPIC_STATUS_TYPE]: {
			item: SingleSelectField,
		},
		[layoutFieldKeys.MULTI_SELECT_CF_TYPE]: {
			item: MultiSelectFieldWithRelayEditView,
		},
		[layoutFieldKeys.ECOSYSTEM_GLANCE_TYPE]: { item: ConnectGlanceView },
		[layoutFieldKeys.FORGE_CUSTOM_FIELD_TYPE]: {
			item: ForgeCustomField,
		},
		[layoutFieldKeys.ISSUE_FIELD_USER_CF_TYPE]: { item: UserField },
		[layoutFieldKeys.ISSUE_FIELD_GROUPS_CF_TYPE]: { item: MultiGroupPicker },
		[layoutFieldKeys.ISSUE_FIELD_STRINGS_CF_TYPE]: { item: LabelsField },
		[layoutFieldKeys.FORGE_GLANCE_TYPE]: { item: ForgeGlanceView },
		// This field is shown only in Content area in Classic and Next-gen Software (Mar 2020)
		[layoutFieldKeys.TEXT_AREA_CF_TYPE]: { item: MultiLineField },
		[layoutFieldKeys.MULTI_VERSION_CF_TYPE]: {
			item: ff('issue.details.relay-release-version-field-issue-view-integration')
				? VersionsFieldWithRelayEditView
				: MultiVersionPicker,
		},
		[layoutFieldKeys.VERSION_CF_TYPE]: { item: VersionCustomField },
		[layoutFieldKeys.DATE_CF_TYPE]: { item: DateField },
		[layoutFieldKeys.GROUP_CF_TYPE]: { item: GroupPicker },
		[layoutFieldKeys.MULTI_GROUP_CF_TYPE]: { item: MultiGroupPicker },
		[layoutFieldKeys.USER_CF_TYPE]: { item: UserField },
		[layoutFieldKeys.DATETIME_CF_TYPE]: { item: DateTimeField },
		[layoutFieldKeys.MULTI_CHECKBOXES_CF_TYPE]: { item: CheckboxField },
		[layoutFieldKeys.RADIO_BUTTONS_CF_TYPE]: { item: RadioField },
		[layoutFieldKeys.TEAM_CF_TYPE]: { item: TeamField },
		[layoutFieldKeys.ISSUE_FIELD_NUMBER_CF_TYPE]: {
			item: NumberField,
		},
		[layoutFieldKeys.ISSUE_FIELD_DATETIME_CF_TYPE]: { item: DateTimeField },
		[layoutFieldKeys.ISSUE_FIELD_STRING_CF_TYPE]: { item: SingleLineTextField },
		[layoutFieldKeys.ISSUE_FIELD_SINGLE_SELECT_CF_TYPE]: { item: SingleSelectField },
		[layoutFieldKeys.ISSUE_FIELD_MULTI_SELECT_CF_TYPE]: { item: MultiSelectField },
		[layoutFieldKeys.REQUEST_TYPE_CF_TYPE]: { item: CustomerRequestTypeField },
		[layoutFieldKeys.SERVICE_ENTITY_CF_TYPE]: { item: ConnectedServiceEntity },
		[layoutFieldKeys.REQUEST_PARTICIPANTS_CF_TYPE]: { item: RequestParticipantsField },
		[layoutFieldKeys.ORGANIZATIONS_CF_TYPE]: { item: OrganizationsField },
		[layoutFieldKeys.REQUEST_FEEDBACK_CF_TYPE]: { item: RequestFeedbackField },
		[layoutFieldKeys.PEOPLE_CF_TYPE]: { item: PeopleField },
		[layoutFieldKeys.REQUEST_LANGUAGE_CF_TYPE]: { item: RequestLanguageField },
		[layoutFieldKeys.ASSET_ONBOARDING_TYPE]: { item: AssetsOnboarding },
		[layoutFieldKeys.APPROVERS_LIST_CF_TYPE]: { item: PeopleField },
		[layoutFieldKeys.CASCADING_SELECT_CF_TYPE]: { item: CascadingSelectFieldView },
		[layoutFieldKeys.PROJECT_PICKER_CF_TYPE]: { item: ProjectField },
		[layoutFieldKeys.CMDB_OBJECT_CF_TYPE]: { item: CmdbObjectView },
		[layoutFieldKeys.READ_ONLY_CF_TYPE]: { item: ReadOnlyField },
		[layoutFieldKeys.MESSAGE_VIEW_CF_TYPE]: { item: MessageField },
		[layoutFieldKeys.MESSAGE_EDIT_CF_TYPE]: { item: MessageField },
		[layoutFieldKeys.EPIC_NAME_TYPE]: {
			item: SingleLineTextField,
		},
		[layoutFieldKeys.ECOSYSTEM_CONTEXT_TYPE]: {
			item: ConnectContextPanel,
		},
		[layoutFieldKeys.CATEGORY_TYPE]: { item: SingleSelectField },
		[layoutFieldKeys.ENTITLEMENT_CF_TYPE]: { item: EntitlementField },
		[layoutFieldKeys.GOALS_CF_TYPE]: {
			item: expVal('native_goals_on_issue_view', 'isGoalsFieldEnabled', false)
				? GoalsInlineEditViewField
				: () => null,
		},
	};

	if (layoutFieldKeys.FORGE_CONTEXT_TYPE) {
		Object.assign(baseLayoutItems, {
			[layoutFieldKeys.FORGE_CONTEXT_TYPE]: { item: ForgeIssueContext },
		});
	}

	if (isParentInIssueViewEnabled() && layoutFieldKeys.PARENT_TYPE) {
		Object.assign(baseLayoutItems, {
			[layoutFieldKeys.PARENT_TYPE]: { item: ParentField },
		});
	}

	if (layoutFieldKeys.SENTIMENT_TYPE && fg('jsm-sentiment-analysis')) {
		Object.assign(baseLayoutItems, {
			[layoutFieldKeys.SENTIMENT_TYPE]: { item: SentimentPanel },
		});
	}
	if (ff('sentiment-custom-field') && layoutFieldKeys.SENTIMENT_CF_TYPE) {
		Object.assign(baseLayoutItems, {
			[layoutFieldKeys.SENTIMENT_CF_TYPE]: { item: SentimentPanel },
		});
	}

	// Moved to an indexed type to facilitate Object.assign()
	const layoutItems: LayoutItemMap = baseLayoutItems;

	return layoutItems;
};

export default memoizeOne(getLayoutItemMap);
