import React, { useMemo, useCallback } from 'react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { PRETTY } from '@atlassian/jira-common-constants/src/jira-settings.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type {
	TimeTracking,
	TimeTrackingConfig,
	OptionalTime,
} from '@atlassian/jira-issue-shared-types/src/common/types/jira-settings-type.tsx';
import { timeTrackingFormatter } from '@atlassian/jira-time-tracking-formatter/src/main.tsx';
import messages from './messages';
import Segment from './progress-bar/progress-bar-segment/view.tsx';
import ProgressBar from './progress-bar/view.tsx';

export type Props = {
	isCompact?: boolean;
	isDone?: boolean;
	shouldHighlightOnHover?: boolean;
	value?: TimeTracking;
	estimatedTime?: OptionalTime;
	config: TimeTrackingConfig;
};

// Get the total time represented by the full width of the progress tracker by taking the higher
// of the estimated time, or the logged time + remaining time.
export const getTotalTime = (value: TimeTracking, estimatedTime = 0) => {
	const { loggedTime = 0, remainingTime = 0 } = value;
	return Math.max(estimatedTime, loggedTime + remainingTime);
};

export const getTimeOverOriginalEstimate = (value: TimeTracking, estimatedTime: OptionalTime) => {
	const { loggedTime, remainingTime } = value;

	// If there is no logged time, then we do not want to show the progress bar.
	// If we have no estimated time, then we can't calculate the value.
	if (!loggedTime || estimatedTime === undefined) {
		return 0;
	}

	return loggedTime + (remainingTime || 0) - estimatedTime;
};

export const ProgressTracker = ({
	isCompact = true,
	isDone = false,
	shouldHighlightOnHover = false,
	value = { loggedTime: 0 },
	config,
	estimatedTime,
}: Props) => {
	const intl = useIntl();

	const secondsToTimeString = useCallback(
		(seconds: number) =>
			timeTrackingFormatter(
				seconds,
				{
					workingHoursPerDay: config.hoursPerDay,
					workingDaysPerWeek: config.daysPerWeek,
					timeFormat: config.format || PRETTY,
					defaultUnit: config.defaultUnit,
				},
				intl,
			),
		[config.daysPerWeek, config.defaultUnit, config.format, config.hoursPerDay, intl],
	);

	const toolTip = useMemo(() => {
		const timeOverOriginalEstimate = getTimeOverOriginalEstimate(value, estimatedTime);

		if (timeOverOriginalEstimate === 0) {
			return null;
		}

		return timeOverOriginalEstimate > 0
			? intl.formatMessage(messages.overOriginalEstimate, {
					time: secondsToTimeString(timeOverOriginalEstimate),
				})
			: intl.formatMessage(messages.underOriginalEstimate, {
					time: secondsToTimeString(-timeOverOriginalEstimate),
				});
	}, [estimatedTime, intl, secondsToTimeString, value]);

	const { loggedTime = 0 } = value;

	const totalTime = getTotalTime(value, estimatedTime);
	const timeOver = Math.max(getTimeOverOriginalEstimate(value, estimatedTime), 0);

	const proportionCompleted = totalTime ? (loggedTime - timeOver) / totalTime : 0;
	const proportionRemain =
		totalTime && totalTime - loggedTime > 0 ? (totalTime - loggedTime) / totalTime : 0;
	const proportionOver = totalTime ? timeOver / totalTime : 0;

	return (
		<ProgressBar
			tooltip={toolTip}
			isCompact={isCompact}
			shouldHighlightOnHover={shouldHighlightOnHover}
		>
			<Segment
				color={
					isDone
						? token('color.border.success', colors.G300)
						: token('color.border.information', colors.B200)
				}
				proportion={proportionCompleted}
			/>
			<Segment color={token('color.border.warning', colors.Y300)} proportion={proportionOver} />
			<Segment color={token('color.border', colors.N30)} proportion={proportionRemain} />
		</ProgressBar>
	);
};
