// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	logWork: {
		id: 'issue-field.log-time-modal.command-palette.log-work',
		defaultMessage: 'Log work',
		description: 'Command name in the command palette to log work to an issue',
	},
});
