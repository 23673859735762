import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { getTitle, getChildTitle } from '../../../state/context/selectors';
import type { State } from '../../../state/types';
import { Title } from './view';

export default flowWithSafeComponent(
	connect(
		(state: State) => ({
			title: getTitle(state),
			titleChildIssues: getChildTitle(state),
		}),
		{},
	),
)(Title);
