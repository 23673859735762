// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	convertToSubtask: {
		id: 'issue.convert-to-subtask',
		defaultMessage: 'Convert to Subtask',
		description: 'Meatball option to change an issue to a subtask',
	},
});
