// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	jiraInstanceSelectorAriaLabel: {
		id: 'issues.issue-links.app-link-select.jira-instance-selector-aria-label',
		defaultMessage: 'Select Jira instance',
		description: 'Aria-label text for Jira AppLinkSelect',
	},
	confluenceInstanceSelectorAriaLabel: {
		id: 'issues.issue-links.app-link-select.confluence-instance-selector-aria-label',
		defaultMessage: 'Select Confluence instance',
		description: 'Aria-label text for Confluence AppLinkSelect',
	},
	placeholderText: {
		id: 'issues.issue-links.app-link-select.placeholder',
		defaultMessage: 'Select application link',
		description: 'Placeholder text for AppLinkSelect when no applink is chosen',
	},
});
