import React, { useCallback, useEffect } from 'react';
import type { Dispatch } from 'redux';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import memoizeOne from 'memoize-one';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { FieldDescription } from '@atlassian/jira-issue-field-description/src/ui/index.tsx';
import { useCustomFieldsConfigurationExperiment } from '@atlassian/jira-issue-field-edit-icon/src/controllers/useCustomFieldsConfigurationExperiment';
import { FieldEditIcon } from '@atlassian/jira-issue-field-edit-icon/src/FieldEditIcon';
import {
	FieldHeadingIconsContainer,
	MultilineFieldHeading,
	MultilineFieldHeadingTitle,
	MultilineFieldHeadingView,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin';
import RichTextInlineEditView, {
	type Props as RichTextInlineEditProps,
} from '@atlassian/jira-issue-internal-fields/src/rich-text/rich-text-inline-edit-view';
import { RICH_CONTENT_FIELD_CONFIRM_ERROR } from '@atlassian/jira-issue-view-common-constants/src/flags.tsx';
import {
	type Area,
	CONTENT,
	CONTEXT,
} from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import { getIssueModalEditorDropdownPortal } from '@atlassian/jira-issue-view-common-utils/src/get-element/index.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import withContainerWidth from '@atlassian/jira-issue-view-common-utils/src/with-container-width';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field';
import {
	SectionHeading,
	SectionHeadingTitle,
	DraftIndicator,
	HeadingWithDraft,
} from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view';
import draftMessages from '@atlassian/jira-issue-view-common/src/messages/drafts';
import {
	useIssueLayout,
	MIN_EDITOR_SIDEBAR_CONTAINER_WIDTH,
} from '@atlassian/jira-issue-view-services/src/issue-layout-service/context';
import { mentionProviderSelector } from '@atlassian/jira-issue-view-services/src/mentions/mention-provider-selector.tsx';
import {
	editorExpandedFailure,
	editorSaveFailure,
	editorChangeFailure,
} from '@atlassian/jira-issue-view-store/src/actions/editor-actions';
import { activityProviderSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/activity-provider-selector';
import { baseUrlSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import {
	fieldEditingAdfValueSelector,
	fieldHtmlValueSelector,
	fieldInvalidSelector,
	fieldInvalidMessageSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector';
import { mediaContextSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/media-context-selector';
import { fieldHasDraftSelector } from '@atlassian/jira-issue-view-store/src/selectors/drafts/selectors';
import { isFieldInTabSelector } from '@atlassian/jira-issue-view-store/src/selectors/tab-selector';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { messages } from './messages';
// @ts-expect-error - TS2304 - Cannot find name 'BaseUrl'.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const performPut = (baseUrl: BaseUrl, issueKey: IssueKey, fieldId: string, value: any) => {
	const url = `${baseUrl}/rest/api/3/issue/${issueKey}`;

	return performPutRequest(url, {
		body: JSON.stringify({
			fields: {
				[fieldId]: value,
			},
		}),
	});
};

type Props = RichTextInlineEditProps & {
	area: Area;
	hasDraft: ADF | undefined;
	label: string;
	containerWidth: number;
	issueKey: IssueKey;
};

export const MultilineField = (props: Props) => {
	const { label, area, hasDraft, isEditing, containerWidth, issueKey, ...otherProps } = props;
	const intl = useIntl();
	const shouldShowDraftIndicator = hasDraft && !isEditing;
	const MultilineFieldHeadingWrapper = fg('issue_view_field_config_edit')
		? MultilineFieldHeadingView
		: MultilineFieldHeading;
	const HeadingWrapper = area === CONTENT ? SectionHeading : MultilineFieldHeadingWrapper;
	const HeadingTitle = area === CONTENT ? SectionHeadingTitle : MultilineFieldHeadingTitle;
	const showPinButton = getShowPinButton(area);
	const [, { updateSidebarMinWidth, resetSidebarMinWidth }] = useIssueLayout();

	let isCustomFieldConfigurationEnabled = false;

	// To be updated in JGFENG-540
	let handleClickFieldEdit = useCallback(noop, []);
	if (fg('issue_view_field_config_edit')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isCustomFieldConfigurationEnabled = useCustomFieldsConfigurationExperiment();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		handleClickFieldEdit = useCallback(noop, []);
	}

	useEffect(() => {
		if (area === CONTEXT) {
			if (isEditing === true) {
				updateSidebarMinWidth(MIN_EDITOR_SIDEBAR_CONTAINER_WIDTH);
				return;
			}
			resetSidebarMinWidth();
		}
	}, [isEditing, area, updateSidebarMinWidth, resetSidebarMinWidth]);

	return (
		<TextareaWrapperWithoutMargin>
			<HeadingWrapper>
				<HeadingWithDraft>
					<HeadingTitle data-testid={`issue.views.issue-base.common.${props.fieldId}.label`}>
						{label}
					</HeadingTitle>
					{shouldShowDraftIndicator === true ? (
						<DraftIndicator>
							{`• ${intl.formatMessage(draftMessages.draftIndicator)}`}
						</DraftIndicator>
					) : null}
					<FieldHeadingIconsContainer
						isCustomFieldConfigurationEnabled={isCustomFieldConfigurationEnabled}
					>
						{issueKey !== undefined && props.fieldId !== undefined && (
							<FieldDescription issueKey={issueKey} fieldKey={props.fieldId} label={label} />
						)}
						{showPinButton === true && <FieldPin fieldId={props.fieldId} label={label} />}
						{isCustomFieldConfigurationEnabled && <FieldEditIcon onClick={handleClickFieldEdit} />}
					</FieldHeadingIconsContainer>
				</HeadingWithDraft>
			</HeadingWrapper>
			<RichTextInlineEditView isEditing={isEditing} label={label} {...otherProps} />
		</TextareaWrapperWithoutMargin>
	);
};

const additionalCallbacksMemo = memoizeOne((dispatch: Dispatch) => ({
	onExpandedFailure: () => dispatch(editorExpandedFailure()),
	onSaveFailure: () => dispatch(editorSaveFailure()),
	onChangeFailure: () => dispatch(editorChangeFailure()),
}));

export default connectField((stateOnMount, ownPropsOnMount) => ({
	fieldId: ownPropsOnMount.fieldId,
	shouldSaveDraft: true,
	// See: https://jdog.jira-dev.com/browse/BENTO-631
	isOptimistic: true,
	saveField: ({ baseUrl, issueKey, value }) =>
		performPut(baseUrl, issueKey, ownPropsOnMount.fieldId, value),
	canContainMediaContent: true,
	onSaveFailureFlagType: RICH_CONTENT_FIELD_CONFIRM_ERROR,
	// @ts-expect-error - TS2322 - Type '(state: Readonly<{ agile: Agile; context: ContextState; entities: Readonly<{ applicationRoles?: ApplicationRole[] | undefined; cardCover: CardCover; childrenIssues: ChildrenIssuesState; ... 29 more ...; myPreferences?: Partial<...> | undefined; }>; ... 5 more ...; validators: Validators; }>, intl: IntlShapeV2) => { ...' is not assignable to type 'AdditionalProps<unknown>'.
	additionalProps: (state, intl) => {
		const baseUrl = baseUrlSelector(state);
		return {
			baseUrl,
			portalElement: isFieldInTabSelector(ownPropsOnMount.fieldId)(state)
				? // Using a portal element here because otherwise dropdowns would get cut off
					// See: https://jdog.jira-dev.com/browse/BENTO-4100

					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					getIssueModalEditorDropdownPortal() || document.body
				: undefined,
			adfValue: fieldEditingAdfValueSelector(ownPropsOnMount.fieldId)(state),
			hasDraft: fieldHasDraftSelector(ownPropsOnMount.fieldId)(state),
			htmlValue: fieldHtmlValueSelector(ownPropsOnMount.fieldId)(state),
			isInvalid: fieldInvalidSelector(ownPropsOnMount.fieldId)(state),
			invalidMessage: fieldInvalidMessageSelector(ownPropsOnMount.fieldId)(state),
			mediaContext: mediaContextSelector(state),
			mentionProvider: mentionProviderSelector(state),
			activityProvider: activityProviderSelector(state),
			externalId: `issue.${ownPropsOnMount.fieldId}`,
			noValueText: intl.formatMessage(messages.noValueText),
			area: ownPropsOnMount.area,
		};
	},
	additionalCallbacks: additionalCallbacksMemo,
}))(withContainerWidth(MultilineField));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TextareaWrapperWithoutMargin = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"[class*='CompactWrapper']": {
		marginBottom: token('space.200', '16px'),
	},
});
