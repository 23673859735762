import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import type { FieldConfiguration } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { JiraDateTimePickerValue } from '../../common/types';

export type UseDateTimeFieldOptions = {
	issueKey: IssueKey;
	fieldKey: string;
	initialValue?: JiraDateTimePickerValue;
	onSuccess?: (newValue: JiraDateTimePickerValue) => void;
	onFailure?: (error: Error, failedValue: JiraDateTimePickerValue) => void;
};

export const useDateTimeField = ({
	issueKey,
	fieldKey,
	initialValue,
	onSuccess,
	onFailure,
}: UseDateTimeFieldOptions): [
	{
		loading: boolean;
		value: JiraDateTimePickerValue;
		error: Error | null;
		fieldConfig: FieldConfiguration<JiraDateTimePickerValue> | null;
	},
	{
		saveValue: (
			value: JiraDateTimePickerValue,
			meta: null,
			analyticsEvent: UIAnalyticsEvent,
		) => Promise<void>;
		resetError: () => void;
	},
] => {
	// useFieldConfig now returns an object { value, loading } instead of just the fieldConfig value
	// if possible when touching this, try and refactor to pass the new object instead of just the value
	const [{ value: fieldConfig }] = useFieldConfig(issueKey, fieldKey);
	const fieldType = fieldConfig?.schema.type ?? 'datetime';

	const [{ loading, value, error }, { saveValue, resetError }] = useEditField<
		JiraDateTimePickerValue,
		null
	>({
		fieldKey,
		issueKey,
		fieldType,
		initialValue: initialValue || null,
		onSuccess,
		onFailure,
	});

	return [
		{ loading, value, error, fieldConfig },
		{ saveValue, resetError },
	];
};
