import React from 'react';
import { styled } from '@compiled/react';
import type { IssueViewRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { STATUS_AND_APPROVAL_SPOTLIGHT } from '@atlassian/jira-issue-view-common-constants/src/layout.tsx';
import StatusField from '@atlassian/jira-issue-view-foundation/src/status/view.tsx';
// eslint-disable-next-line jira/restricted/@atlassian+jira-servicedesk-common
import GlobalSpotlightTargetDeprecated from '@atlassian/jira-servicedesk-common/src/ui/global-spotlight-target';
import ApprovalPanelWrapper from './approval-panel-wrapper';

const FoundationContext = ({
	issueViewRelayFragment,
}: {
	// TODO Decomp JIV-12514 - add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment?: IssueViewRelayFragment | null;
}) => (
	<GlobalSpotlightTargetDeprecated id={STATUS_AND_APPROVAL_SPOTLIGHT}>
		<FoundationContextWrapper
			// eslint-disable-next-line jira/integration/test-id-by-folder-structure
			data-testid="issue.views.issue-base.context.status-and-approvals-wrapper.status-and-approval"
		>
			<StatusField key="WRAPPED_STATUS" fragmentKey={issueViewRelayFragment} />
			<ApprovalPanelWrapper />
		</FoundationContextWrapper>
	</GlobalSpotlightTargetDeprecated>
);

export default FoundationContext;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FoundationContextWrapper = styled.div({
	marginBottom: 0,
});
