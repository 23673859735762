import React, { type ReactNode, useCallback, type MouseEvent, type ReactElement } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { ForgeScreenEvent } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import { getAnalyticsAttributesFromExtension } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-analytics-attributes-from-extension';
import { fireUiTriggerClickedEvent } from '@atlassian/jira-forge-ui-analytics/src/services/issue-activity';
import { TriggerAnalyticsWrapper } from '@atlassian/jira-forge-ui-analytics/src/ui/issue-activity';
import { ExtensionTitle } from '@atlassian/jira-forge-ui-extension-title/src/ui/index.tsx';
import type { ForgeActivityItem } from '@atlassian/jira-issue-view-common-types/src/activity-item-type';

type ComponentProps = {
	isSelected: boolean;
	children: ReactNode;
	testId?: string;
	onClick: (event: MouseEvent, analyticsEvent: UIAnalyticsEvent) => void;
};

type Props = {
	isSelected?: boolean;
	// eslint-disable-next-line jira/react/handler-naming
	component: (props: ComponentProps) => ReactElement;
	item: ForgeActivityItem;
	onClick: (event: MouseEvent, analyticsEvent: UIAnalyticsEvent) => void;
};

export const ForgeIssueActivityTrigger = ({
	item,
	component,
}: {
	item: ForgeActivityItem;
	component: (props: { children: ReactNode }) => ReactElement;
}) => {
	const Component = component;

	return (
		<Component>
			<ExtensionTitle
				extensionName={item.name}
				extensionEnvType={item.extension.environmentType}
				extensionEnvKey={item.extension.environmentKey}
			/>
		</Component>
	);
};

export const ForgeIssueActivityView = ({ component, item, onClick, isSelected }: Props) => {
	const Component = component;

	const handleOnClick = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'event' implicitly has an 'any' type. | TS7006 - Parameter 'analyticsEvent' implicitly has an 'any' type.
		(event, analyticsEvent) => {
			fireUiTriggerClickedEvent(item.extension.id);
			onClick(event, analyticsEvent);
		},
		[item.extension.id, onClick],
	);

	return (
		<Component onClick={handleOnClick} isSelected={!!isSelected}>
			<ExtensionTitle
				extensionName={item.name}
				extensionEnvType={item.extension.environmentType}
				extensionEnvKey={item.extension.environmentKey}
			/>
			<ForgeScreenEvent
				attributes={{
					...getAnalyticsAttributesFromExtension(item.extension),
				}}
			/>
		</Component>
	);
};

export const ForgeIssueActivityItem = (props: Props) => (
	<TriggerAnalyticsWrapper attributes={getAnalyticsAttributesFromExtension(props.item.extension)}>
		<ForgeIssueActivityView {...props} />
	</TriggerAnalyticsWrapper>
);
