import React from 'react';

import { token } from '@atlaskit/tokens';

import { RainbowBorder } from './RainbowBorder';
import type { IconProps } from './types';

export const Icon = (props: IconProps) => {
	const { shownAt } = props;

	return (
		<RainbowBorder shownAt={shownAt}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill={token('color.icon', '#44546F')}
				xmlns="http://www.w3.org/2000/svg"
				role="presentation"
			>
				<path d="M11 13H7C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H11C11.5523 15 12 14.5523 12 14C12 13.4477 11.5523 13 11 13Z" />
				<path d="M17 9H7C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H17C17.5523 11 18 10.5523 18 10C18 9.44772 17.5523 9 17 9Z" />
			</svg>
		</RainbowBorder>
	);
};
