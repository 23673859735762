import React from 'react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { css } from 'styled-components';
import Badge from '@atlaskit/badge';
import { colors } from '@atlaskit/theme';
// eslint-disable-next-line import/order
import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import {
	originalEstimateViewTestId,
	READ_VIEW_CONTAINER_SELECTOR_NAME,
	HOVER_CONTAINER_SELECTOR_NAME,
} from '../../common/constants';

type Props = {
	isEditable?: boolean;
	testId?: string;
	value: string;
};

export const OriginalEstimateView = ({
	testId = originalEstimateViewTestId,
	isEditable = false,
	value,
}: Props) => {
	const badge = <Badge testId={`${testId}.badge`}>{value}</Badge>;

	return (
		<BadgeWrapper data-testid={testId} data-component-selector={READ_VIEW_CONTAINER_SELECTOR_NAME}>
			{isEditable ? (
				<HoverContainer
					data-testid={`${testId}.hover-container`}
					data-component-selector={HOVER_CONTAINER_SELECTOR_NAME}
				>
					{badge}
				</HoverContainer>
			) : (
				badge
			)}
		</BadgeWrapper>
	);
};

export default OriginalEstimateView;

// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HoverContainer = styled.div<any>(
	{
		cursor: 'pointer',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'&:hover > span': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			color: `${token('color.text', colors.N800)} !important`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			backgroundColor: `${token('color.background.neutral.hovered', colors.N90)} !important`,
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	() =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css({
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			lineHeight: `${gridSize * 2.5}px`,
		}),
);

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line  @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BadgeWrapper = styled.div`
	display: inline-block;
	vertical-align: top;
	position: relative;
	margin-top: ${token('space.negative.075', '-6px')};
	margin-bottom: ${token('space.negative.075', '-6px')};
	${'' /* PX values as these are to match the focus outline offset (implemented as a 2px border) */}
	margin-right: -2px;
	margin-left: 2px;
`;
