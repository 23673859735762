import React, { useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { ReporterInlineEditView } from '@atlassian/jira-issue-field-reporter-inline-edit-full/src/ui/reporter/index.tsx';
import type { AggUser } from '@atlassian/jira-issue-user-picker-edit-view/src/common/types.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import { IssueViewFieldHeading } from '@atlassian/jira-issue-view-layout-field-heading/src/ui/index.tsx';
import type { ui_issueViewLayoutReporterField_IssueViewReporterField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutReporterField_IssueViewReporterField.graphql';

export interface IssueViewReporterFieldProps {
	area?: Area;
	fragmentKey: ui_issueViewLayoutReporterField_IssueViewReporterField$key;
}

export const IssueViewReporterField = ({ area, fragmentKey }: IssueViewReporterFieldProps) => {
	const issueId = useIssueId();
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();

	const data = useFragment<ui_issueViewLayoutReporterField_IssueViewReporterField$key>(
		graphql`
			fragment ui_issueViewLayoutReporterField_IssueViewReporterField on JiraSingleSelectUserPickerField {
				...ui_issueViewLayoutFieldHeading_IssueViewFieldHeading
				...reporter_issueFieldReporterInlineEditFull_ReporterInlineEditView_fragmentRef
				fieldId
				type
				__typename
			}
		`,
		fragmentKey,
	);

	const onSubmit = useCallback(
		(value: AggUser | null) => {
			issueId &&
				fieldChangeRequested(issueId, data.fieldId, value, undefined, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChangeRequested, issueId],
	);

	const onSubmitSucceeded = useCallback(
		(value: AggUser | null) => {
			issueId &&
				fieldChanged(issueId, data.fieldId, value, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChanged, issueId],
	);

	const onSubmitFailed = useCallback(() => {
		issueId && fieldChangeFailed(issueId, data.fieldId);
	}, [data.fieldId, fieldChangeFailed, issueId]);
	return (
		<IssueViewFieldHeading
			area={area}
			fragmentKey={data}
			testId={`issue.issue-view-layout.issue-view-reporter-field.${data.fieldId}`}
		>
			{/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */}
			<Box xcss={fieldWrapperStyles} testId="issue.views.field.user.reporter">
				<ReporterInlineEditView
					fragmentRef={data}
					onSubmit={onSubmit}
					onSubmitSucceeded={onSubmitSucceeded}
					onSubmitFailed={onSubmitFailed}
				/>
			</Box>
		</IssueViewFieldHeading>
	);
};

const fieldWrapperStyles = xcss({
	marginLeft: 'space.negative.100',
	marginRight: 'space.100',
});
