import React, { useCallback, useMemo } from 'react';
import isNil from 'lodash/isNil';
import memoizeOne from 'memoize-one';
import { ForgeScreenEvent } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIssueFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import type { SelectValueShape } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types';
import SingleSelectInlineEditView from '@atlassian/jira-issue-internal-field-select/src/single-select-inline-edit';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages';
import { fetchSuggestionsFactory } from '../../../../../../group-picker/rest/fetch';
import { useAnalyticsAttributesContext } from '../../../analytics/atrributes-context';
import type { CustomFieldProps } from '../../../types';
import type { Group } from '../types';
import messages from './messages';

const fromSelectOption: (option: SelectValueShape | null | undefined) => Group | null = memoizeOne(
	(option: SelectValueShape | null | undefined) =>
		!option ? null : { name: String(option.value) },
);

const toSelectOption: (value: Group | null) => SelectValueShape | null = memoizeOne(
	(value: Group | null) =>
		!value ? null : { value: String(value.name), content: String(value.name) },
);

export const View = ({
	fieldId,
	shouldFireScreenEvent,
	value,
	customReadView,
	onChange,
	...restProps
}: CustomFieldProps<Group>) => {
	const issueKey = useIssueKey();
	const [{ value: fieldConfig }] = useIssueFieldConfig(issueKey);
	const allowEmptyValue = (fieldConfig && !fieldConfig[fieldId]?.isRequired) ?? false;
	const fetchSuggestions = fetchSuggestionsFactory();

	const { formatMessage } = useIntl();

	const onChangeWithTransformation = useCallback(
		(newValue: SelectValueShape | null | undefined) => onChange(fromSelectOption(newValue)),
		[onChange],
	);

	const customReadViewWithTransformation = useCallback(
		() => (!isNil(customReadView) ? customReadView(value) : <></>),
		[value, customReadView],
	);

	const optionalCustomReadView = !isNil(customReadView)
		? customReadViewWithTransformation
		: undefined;

	const analyticsAttributes = useAnalyticsAttributesContext();
	const attributes = useMemo(
		() => ({ ...analyticsAttributes, renderMode: 'default' }),
		[analyticsAttributes],
	);

	return (
		<>
			{shouldFireScreenEvent && <ForgeScreenEvent attributes={attributes} />}
			<SingleSelectInlineEditView
				{...restProps}
				fieldId={fieldId}
				allowEmptyValue={allowEmptyValue}
				fetchSuggestions={fetchSuggestions}
				renderReadView={optionalCustomReadView}
				value={toSelectOption(value)}
				placeholder={formatMessage(messages.placeholder)}
				noValueText={formatMessage(genericMessages.noValue)}
				onChange={onChangeWithTransformation}
				hasAutocomplete
			/>
		</>
	);
};

export default View;
