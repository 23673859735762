/**
 * @generated SignedSource<<5a812e0b765a4404f3dae4935bf96c0e>>
 * @relayHash 3959bcf806cf53279ca744d23036cc94
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4d6273aac46a5f681e459d762b527ae4e2f8024cf83e381dfe87ebe4719bd451

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraFieldOptionIdsFilterOperation = "ALLOW" | "EXCLUDE" | "%future added value";
export type JiraFieldOptionIdsFilterInput = {
  operation: JiraFieldOptionIdsFilterOperation;
  optionIds: ReadonlyArray<string>;
};
export type cascadingSelect_cascadingSelectSearchRefetchQuery$variables = {
  after?: string | null | undefined;
  childOptionsFilterById?: JiraFieldOptionIdsFilterInput | null | undefined;
  filterById?: JiraFieldOptionIdsFilterInput | null | undefined;
  first?: number | null | undefined;
  id: string;
  isSelectedParentIdSet: boolean;
  searchBy?: string | null | undefined;
  selectedParentId: string;
};
export type cascadingSelect_cascadingSelectSearchRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"cascadingSelect_issueFieldCascadingSelectEditviewFull_CascadingSelectEditViewWithFieldOptionsFragment">;
};
export type cascadingSelect_cascadingSelectSearchRefetchQuery = {
  response: cascadingSelect_cascadingSelectSearchRefetchQuery$data;
  variables: cascadingSelect_cascadingSelectSearchRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "childOptionsFilterById"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterById"
  },
  {
    "defaultValue": 50,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isSelectedParentIdSet"
  },
  {
    "defaultValue": "",
    "kind": "LocalArgument",
    "name": "searchBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "selectedParentId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "filterById",
  "variableName": "filterById"
},
v3 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v4 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "id"
},
v5 = {
  "kind": "Variable",
  "name": "searchBy",
  "variableName": "searchBy"
},
v6 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v7 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v5/*: any*/)
],
v8 = {
  "kind": "ScalarField",
  "name": "id"
},
v9 = {
  "kind": "ScalarField",
  "name": "value"
},
v10 = {
  "kind": "ScalarField",
  "name": "isDisabled"
},
v11 = {
  "args": [
    {
      "kind": "Variable",
      "name": "filterById",
      "variableName": "childOptionsFilterById"
    }
  ],
  "concreteType": "JiraOptionConnection",
  "kind": "LinkedField",
  "name": "childOptions",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraOptionEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraOption",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/)
          ]
        }
      ]
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "cascadingSelect_cascadingSelectSearchRefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "childOptionsFilterById",
            "variableName": "childOptionsFilterById"
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "Variable",
            "name": "isSelectedParentIdSet",
            "variableName": "isSelectedParentIdSet"
          },
          (v5/*: any*/),
          {
            "kind": "Variable",
            "name": "selectedParentId",
            "variableName": "selectedParentId"
          }
        ],
        "kind": "FragmentSpread",
        "name": "cascadingSelect_issueFieldCascadingSelectEditviewFull_CascadingSelectEditViewWithFieldOptionsFragment"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "cascadingSelect_cascadingSelectSearchRefetchQuery",
    "selections": [
      {
        "args": [
          (v4/*: any*/)
        ],
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v7/*: any*/),
                "concreteType": "JiraParentOptionConnection",
                "kind": "LinkedField",
                "name": "parentOptions",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraParentOptionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraParentOption",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v6/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "cursor"
                      }
                    ]
                  },
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                      }
                    ]
                  }
                ]
              },
              {
                "args": (v7/*: any*/),
                "filters": [
                  "searchBy",
                  "filterById"
                ],
                "handle": "connection",
                "key": "cascadingSelect_issueFieldCascadingSelectEditviewFull_parentOptions",
                "kind": "LinkedHandle",
                "name": "parentOptions"
              }
            ],
            "type": "JiraCascadingSelectField"
          },
          (v8/*: any*/)
        ]
      },
      {
        "condition": "isSelectedParentIdSet",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "selectedParentNode",
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "selectedParentId"
              }
            ],
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v8/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "type": "JiraParentOption"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "4d6273aac46a5f681e459d762b527ae4e2f8024cf83e381dfe87ebe4719bd451",
    "metadata": {},
    "name": "cascadingSelect_cascadingSelectSearchRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "31b620f2a662bc839e12d27fde55fb31";

export default node;
