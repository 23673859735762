import React, { forwardRef, type HTMLProps } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { COMMAND_CONTAINER_ROW_TEST_ID, COMPAL_MENU_ITEM_ID } from '../constants';

export type ListItemProps = {
	id?: string;
	active?: boolean;
};

export const CommandPaletteSearchResultListItem = forwardRef<
	HTMLDivElement,
	HTMLProps<HTMLDivElement> & ListItemProps
>(({ id, active, ...rest }, ref) => (
	<div
		ref={ref}
		data-testid={COMMAND_CONTAINER_ROW_TEST_ID}
		{...(fg('compal_listbox_to_menu')
			? {
					'aria-current': active,
					role: 'menuitem',
				}
			: {
					'aria-selected': active,
					role: 'option',
				})}
		id={`${COMPAL_MENU_ITEM_ID}-${id}`}
		{...rest}
	/>
));
