import React, { type ReactNode, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Avatar from '@atlaskit/avatar';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import type { ReporterValue } from '../../../common/types';
import { getProfileUrl } from '../../../common/ui/details/utils.tsx';
import { useIsAgentOrProjectAdmin } from '../../../common/utils';
import messages from './messages';

export const CustomerField = ({ value }: { value?: ReporterValue }) => {
	const { formatMessage } = useIntl();
	const { push } = useRouterActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const profileURL = getProfileUrl(projectKey, 'CUSTOMER', value?.accountId || '');
	const isAgentOrProjectAdmin = useIsAgentOrProjectAdmin();

	const onClick = (e: SyntheticEvent) => {
		e.preventDefault();
		push(profileURL);
		fireUIAnalytics(createAnalyticsEvent({}), 'link clicked', 'viewCustomerContextProfile', {
			context: 'CUSTOMER',
		});
	};

	let displayName: ReactNode;
	if (value) {
		displayName =
			!fg('jcs_detail_permissions') || isAgentOrProjectAdmin ? (
				<a onClick={onClick} href={profileURL}>
					{value.displayName}
				</a>
			) : (
				value.displayName
			);
	} else {
		displayName = formatMessage(messages.anonymous);
	}

	return (
		<FieldContainer data-testid="servicedesk-customer-service-issue-view-panel.ui.customer.customer-field.field-container">
			<AvatarContainer>
				<Avatar
					borderColor="transparent"
					src={value?.avatarUrls && value?.avatarUrls['48x48']}
					size="small"
				/>
			</AvatarContainer>
			<NameWrapper>{displayName}</NameWrapper>
		</FieldContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldContainer = styled.div({
	height: `${token('space.400', '32px')}`,
	alignItems: 'center',
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NameWrapper = styled.span({
	display: 'flex',
	flexWrap: 'wrap',
	lineHeight: 1,
	wordBreak: 'break-word',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarContainer = styled.div({
	alignItems: 'center',
	// Avatar component has built in 2px margin, since it's not recommended to override the component css directly...
	marginRight: `${token('space.075', '6px')}`,
	marginLeft: `${token('space.negative.025', '-2px')}`,
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"& span[role='img']": {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: `${token('color.background.neutral', colors.N30)} !important`,
	},
});
