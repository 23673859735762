/**
 * @jsxRuntime classic
 * @jsx jsx
 */
/** @jsxFrag */
import React, { useCallback, useMemo, useRef, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import kebabCase from 'lodash/kebabCase';
import { useIntl } from 'react-intl-next';

import { IconButton, type IconButtonProps } from '@atlaskit/button/new';
import LegacyButton from '@atlaskit/button/standard-button';
import type { Keymap } from '@atlaskit/editor-common/keymaps';
import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import { type JSONDocNode } from '@atlaskit/editor-json-transformer';
import type { Schema } from '@atlaskit/editor-prosemirror/model';
import ChevronLeftIcon from '@atlaskit/icon/utility/chevron-left';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { fg } from '@atlaskit/platform-feature-flags';
import { Inline, Stack, xcss } from '@atlaskit/primitives';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import VisuallyHidden from '@atlaskit/visually-hidden';
import type { MultiPromptType } from '@atlassian/editor-ai-common/src/utils/multiPrompts';

import type { EditorAgent } from '../../../utils/agents';
import { AI_MODAL_SCREEN_CLASS } from '../../../utils/constants';
import { ButtonWithShortcut } from '../../components/ButtonWithShortcut/ButtonWithShortcut';
import { CopyIconButton } from '../../components/CopyIconButton/CopyIconButton';
import { FeedbackForm } from '../../components/FeedbackForm/FeedbackForm';
import { FloatingContainer } from '../../components/FloatingContainer/FloatingContainer';
import { defaultPaddingStyles } from '../../components/FloatingContainer/styles';
import { Footer } from '../../components/Footer/Footer';
import { FooterOld } from '../../components/Footer/FooterOld';
import { LatestPromptHeader } from '../../components/LatestPromptHeader/LatestPromptHeader';
import type { PromptEditor } from '../../components/PromptEditorWrapper/PromptEditorWrapper';
import { PromptForm } from '../../components/PromptForm/PromptForm';
import type {
	ClearInputMutableRefObject,
	FocusInputRefType,
} from '../../components/PromptForm/useSetInputRef';
import { RetryIconButton } from '../../components/RetryIconButton/RetryIconButton';
import { useElementBreakpoints } from '../../hooks/useElementBreakpoints';
import { buttonAutoFocusRingFix, forceFocusRingStyles } from '../../styles/focus';
import { layoutBorderStyles } from '../../styles/renderer';

import { ActionButtonsLayout } from './components/ActionButtonsLayout';
import messages from './messages';
import {
	actionsButtonGroup,
	actionsContainer,
	actionsContainerNoPaddingTop,
	arrowContainer,
	columnLayout,
	compactActionButtonsStyles,
	containerLayout,
	interrogationWrapper,
	multipromptContent,
	previewContainer,
	previewContainerWithMultiPrompt,
	previewScreenWithMultiPrompt,
} from './styles';

const compactSecondaryButtonGroupSmall = xcss({ paddingTop: 'space.100' });

export const CompactActionsBreakpoint = 480;

// Copied from packages/editor/editor-plugin-ai/src/config-items/config-items.tsx
export type ActionAppearance = 'primary' | 'secondary' | 'cancel';

/**
 * Could be either:
 * - userInput only (free form generate / interrogate)
 * - presetTitle only (summarize)
 * - both presetTitle and userInput (brainstorm)
 */
type LatestPrompt =
	| {
			presetTitle?: never;
			userInput: string;
			userADFInput?: JSONDocNode;
	  }
	| {
			presetTitle: string;
			userInput?: string;
			userADFInput?: JSONDocNode;
	  };

export type PreviewButton = {
	label: string;
	onClick: () => void;
	appearance: ActionAppearance;
	/** Optional shortcut keymap for the action. */
	shortcut?: Keymap;
};

export type PreviewSecondaryActionButton = {
	label: string;
	onClick: () => void;
	appearance: ActionAppearance;
	/** Optional shortcut keymap for the action. */
	shortcut?: Keymap;
	icon: IconButtonProps['icon'];
	analyticsTitle?: string;
};

type Disclaimer = {
	text: string;
	tooltip: string;
	url?: string;
};

const mapActionAppearanceToButtonAppearance = {
	primary: 'primary',
	secondary: 'default',
	cancel: 'subtle',
} as const;

type Props = {
	/**
	 * Label for the parent if the user selects a submenu via a parent (`Change tone` -> `Neutral`)
	 * If the user selects a submenu directly, this will be empty (search and select `Change tone to Neutral`)
	 */
	parentPresetPromptLabel?: string;
	/**
	 * Short title for the submenu if the user selects a submenu via a parent (`Change tone` -> `Neutral`)
	 * If the user selects a submenu directly, this will be empty (search and select `Change tone to Neutral`)
	 */
	childShortTitle?: string;
	/**
	 * Label for the selected partial prompt.
	 */
	presetPromptLabel?: string;
	latestPrompt?: LatestPrompt;
	preview: React.ReactElement;
	disableInterrogation?: boolean;
	/**
	 * Disables user followup actions
	 *
	 * *copy, retry, insert, replace, interrogate*
	 */
	disableActions: boolean;
	onSubmit: (inputValue: string) => void;
	onCancel: () => void;
	onCopy: () => void;
	onRetry?: () => void;
	onInputFocus: () => void;
	onInputBlur: () => void;
	onInputChange?: (value: string) => void;
	onADFChange?: (value: JSONDocNode) => void;
	onGoodResponseButtonClick: () => void;
	onBadResponseButtonClick: () => void;
	onEditPromptClick?: () => void;
	onBack?: () => void;
	/* Applications are buttons that "apply" or include the generated content in the document.
  These may include options for where the user can insert or replace the content. **/
	applications: PreviewButton[];
	secondaryActions?: PreviewSecondaryActionButton[];
	isInputActive: boolean;
	inputValue: string;
	inputADFValue?: JSONDocNode;
	placeholder?: string;
	disclaimer?: Disclaimer;
	/**
	 * Editor to be used as input in prompt.
	 */
	PromptEditor?: PromptEditor;
	clearInputRef?: ClearInputMutableRefObject;
	/**
	 * Additional footer text that will appear after the disclaimer links
	 */
	additionalFooterText?: string;
	providerFactory: ProviderFactory;
	schema: Schema;
	// For now, Rovo chat (agent mode) is what utilises the 'compact' layout
	actionButtonsLayoutMode?: 'compact' | 'standard';
	// For overriding the content that appears in the right of the footer (e.g. to set custom logo text
	// and icons). For now, used by Rovo chat (agent mode).
	footerRight?: React.ReactNode;

	agent?: EditorAgent;

	//TODO: multiPrompts experiment cleanup - editor_ai_-_multi_prompts
	multiPrompts?: MultiPromptType[];
	onMultiPromptClick?: (index: number) => void;
	multiPromptPresetTitle?: string;
	multiPromptIndex?: number;
	isMultiPromptLoading?: boolean;
};

export const PreviewScreen = ({
	parentPresetPromptLabel,
	childShortTitle,
	presetPromptLabel,
	latestPrompt,
	preview,
	disableActions = false,
	disableInterrogation = false,
	onSubmit,
	onCancel,
	onCopy,
	onRetry,
	onInputFocus,
	onInputBlur,
	onInputChange,
	onADFChange,
	onGoodResponseButtonClick,
	onBadResponseButtonClick,
	onEditPromptClick,
	onBack,
	applications,
	secondaryActions = [],
	isInputActive,
	inputValue,
	inputADFValue,
	PromptEditor,
	clearInputRef,
	additionalFooterText,
	providerFactory,
	schema,
	actionButtonsLayoutMode = 'standard',
	footerRight,
	disclaimer,
	agent,
	multiPrompts,
	onMultiPromptClick,
	multiPromptPresetTitle,
	multiPromptIndex,
	isMultiPromptLoading,
}: Props) => {
	const [setBreakpointsElement, { isSmall }] = useElementBreakpoints(CompactActionsBreakpoint);

	const { formatMessage } = useIntl();
	const [forceFocusRing, setForceFocusRing] = useState(true);

	const firePrimaryApplication = () => {
		if (!editorExperiment('platform_editor_ai_command_palette_post_ga', 'test') || !applications) {
			return;
		}
		setForceFocusRing(false);
		applications[applications.length - 1].onClick();
	};

	const applyButtonFocusStyles = useCallback(
		(index: number) => {
			return editorExperiment('platform_editor_ai_command_palette_post_ga', 'test') &&
				index === applications.length - 1 &&
				forceFocusRing
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
					forceFocusRingStyles
				: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
					buttonAutoFocusRingFix;
		},
		[forceFocusRing, applications.length],
	);

	const focusInputRef = useRef<FocusInputRefType>(undefined);

	const initialPlaceholder = agent
		? formatMessage(messages.placeholderWithAgent, { agentName: agent.name })
		: formatMessage(messages.defaultInputPlaceholder);

	const enableLinksInPromptEditor = !!fg('platform_editor_ai_interrogate_with_convo_ai');

	let actionButtons = useMemo(() => {
		if (!editorExperiment('platform_editor_ai_command_palate_improvement', 'test')) {
			return null;
		}

		const secondaryButtons = !disableActions && (
			<>
				{actionButtonsLayoutMode === 'compact' && (
					<FeedbackForm
						onBadResponseButtonClick={onBadResponseButtonClick}
						onGoodResponseButtonClick={onGoodResponseButtonClick}
						iconsOnly
						pressedIconsColor={token('color.link.pressed')}
					/>
				)}

				{onRetry && (
					<RetryIconButton
						spacing={actionButtonsLayoutMode === 'compact' ? 'compact' : undefined}
						appearance={actionButtonsLayoutMode === 'compact' ? 'subtle' : undefined}
						onClick={onRetry}
					/>
				)}
				{
					<CopyIconButton
						spacing={actionButtonsLayoutMode === 'compact' ? 'compact' : undefined}
						appearance={actionButtonsLayoutMode === 'compact' ? 'subtle' : undefined}
						onClick={onCopy}
					/>
				}

				{actionButtonsLayoutMode === 'compact' &&
					secondaryActions.map((action) => {
						const id = kebabCase(action.analyticsTitle ?? action.label);
						return (
							<Tooltip key={id} content={action.label} position="top">
								<IconButton
									testId={id}
									type="button"
									onClick={action.onClick}
									icon={action.icon}
									label={action.label}
									appearance="subtle"
									spacing="compact"
								/>
							</Tooltip>
						);
					})}
			</>
		);

		const cancelButton = (
			<LegacyButton
				testId={actionButtonsLayoutMode === 'compact' ? 'discard-button' : 'cancel-button'}
				onClick={onCancel}
				type="button"
				appearance={disableActions ? 'primary' : 'subtle'}
				autoFocus={disableActions || applications.length === 0}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/no-unsafe-style-overrides -- Ignored via go/DSP-18766
				css={buttonAutoFocusRingFix}
			>
				{actionButtonsLayoutMode === 'compact'
					? formatMessage(messages.discardLabel)
					: formatMessage(messages.cancelLabel)}
			</LegacyButton>
		);

		const primaryButtons =
			!disableActions &&
			applications.map(({ onClick, label, appearance, shortcut }, index) => {
				return (
					<ButtonWithShortcut
						key={label}
						onClick={onClick}
						type="button"
						shortcut={shortcut}
						autoFocus={appearance === 'primary'}
						css={applyButtonFocusStyles(index)}
						appearance={mapActionAppearanceToButtonAppearance[appearance]}
					>
						{label}
					</ButtonWithShortcut>
				);
			});

		return (
			<ActionButtonsLayout
				mode={actionButtonsLayoutMode}
				cancelButton={cancelButton}
				secondaryButtons={secondaryButtons}
				primaryButtons={primaryButtons}
			/>
		);
	}, [
		actionButtonsLayoutMode,
		applyButtonFocusStyles,
		applications,
		disableActions,
		formatMessage,
		onBadResponseButtonClick,
		onCancel,
		onCopy,
		onGoodResponseButtonClick,
		onRetry,
		secondaryActions,
	]);

	if (!editorExperiment('platform_editor_ai_command_palate_improvement', 'test')) {
		const legacyCancelButton = (
			<LegacyButton
				testId="cancel-button"
				onClick={onCancel}
				type="button"
				appearance={disableActions ? 'primary' : 'subtle'}
				autoFocus={disableActions || applications.length === 0}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/no-unsafe-style-overrides -- Ignored via go/DSP-18766
				css={buttonAutoFocusRingFix}
			>
				{formatMessage(messages.cancelLabel)}
			</LegacyButton>
		);

		if (actionButtonsLayoutMode === 'standard') {
			actionButtons = (
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				<div css={actionsContainer}>
					{legacyCancelButton}
					{disableActions ? null : (
						// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						<div css={actionsButtonGroup}>
							{onRetry && <RetryIconButton onClick={onRetry} />}
							<CopyIconButton onClick={onCopy} />
							{applications.map(({ onClick, label, appearance }, index) => {
								return (
									<LegacyButton
										key={label}
										testId="insert-response-button"
										onClick={onClick}
										type="button"
										autoFocus={appearance === 'primary'}
										// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
										css={applyButtonFocusStyles(index)}
										appearance={mapActionAppearanceToButtonAppearance[appearance]}
									>
										{label}
									</LegacyButton>
								);
							})}
						</div>
					)}
				</div>
			);
		}

		if (actionButtonsLayoutMode === 'compact') {
			const secondaryButtons = (
				<Inline
					alignBlock="center"
					alignInline={isSmall ? 'end' : undefined}
					space="space.100"
					xcss={[isSmall && compactSecondaryButtonGroupSmall]}
				>
					<FeedbackForm
						onBadResponseButtonClick={onBadResponseButtonClick}
						onGoodResponseButtonClick={onGoodResponseButtonClick}
						iconsOnly
						pressedIconsColor={token('color.link.pressed')}
					/>
					{onRetry && <RetryIconButton spacing="compact" appearance="subtle" onClick={onRetry} />}
					<CopyIconButton spacing="compact" appearance="subtle" onClick={onCopy} />
					{secondaryActions.map((action) => {
						const id = kebabCase(action.analyticsTitle ?? action.label);
						return (
							<Tooltip key={id} content={action.label} position="top">
								<IconButton
									testId={id}
									type="button"
									onClick={action.onClick}
									icon={action.icon}
									label={action.label}
									appearance="subtle"
									spacing="compact"
								/>
							</Tooltip>
						);
					})}
				</Inline>
			);
			const primaryButtons = (
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				<div css={[actionsContainer, actionsContainerNoPaddingTop]}>
					<LegacyButton
						testId="discard-button"
						onClick={onCancel}
						type="button"
						appearance="subtle"
						autoFocus
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/no-unsafe-style-overrides -- Ignored via go/DSP-18766
						css={buttonAutoFocusRingFix}
					>
						{formatMessage(messages.discardLabel)}
					</LegacyButton>
					{disableActions ? null : (
						// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						<div css={actionsButtonGroup}>
							{applications.map(({ onClick, label, appearance }, index) => {
								return (
									<LegacyButton
										key={label}
										testId="insert-response-button"
										onClick={onClick}
										type="button"
										autoFocus={appearance === 'primary'}
										// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
										css={applyButtonFocusStyles(index)}
										appearance={mapActionAppearanceToButtonAppearance[appearance]}
									>
										{label}
									</LegacyButton>
								);
							})}
						</div>
					)}
				</div>
			);
			actionButtons = (
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				<div css={compactActionButtonsStyles} ref={setBreakpointsElement}>
					{isSmall ? (
						<>
							{primaryButtons}
							{secondaryButtons}
						</>
					) : (
						<Inline alignBlock="center" spread="space-between">
							{secondaryButtons}
							{primaryButtons}
						</Inline>
					)}
				</div>
			);
		}
	}

	let footerRightContent;

	if (footerRight) {
		footerRightContent = footerRight;
	} else {
		footerRightContent =
			actionButtonsLayoutMode === 'standard' ? (
				<FeedbackForm
					onGoodResponseButtonClick={onGoodResponseButtonClick}
					onBadResponseButtonClick={onBadResponseButtonClick}
				/>
			) : null;
	}

	return (
		<div
			/* eslint-disable @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */
			css={[
				columnLayout,
				editorExperiment('platform_editor_ai_ai_button_block_elements', 'test') &&
					layoutBorderStyles,
			]}
			className="preview-screen"
			/* eslint-enable */
		>
			<FloatingContainer
				disablePadding
				data-testid="preview-screen"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={`preview-screen ${AI_MODAL_SCREEN_CLASS}`}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css={containerLayout}
				rainbowBorder={!editorExperiment('platform_editor_ai_command_palette_post_ga', 'test')}
			>
				<VisuallyHidden>{formatMessage(messages.previewScreenLabel)}</VisuallyHidden>
				<LatestPromptHeader
					parentPresetPromptLabel={parentPresetPromptLabel}
					childShortTitle={childShortTitle}
					prompt={latestPrompt?.userInput}
					promptADF={latestPrompt?.userADFInput}
					presetTitle={multiPromptPresetTitle ?? latestPrompt?.presetTitle}
					agent={agent}
					providerFactory={providerFactory}
					schema={schema}
					onEditPromptClick={onEditPromptClick}
					showBack={true}
					onBack={onBack}
				/>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
				{multiPrompts ? (
					// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values
					<div css={[previewContainerWithMultiPrompt, defaultPaddingStyles]}>
						{/*eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values*/}
						<div css={[previewScreenWithMultiPrompt]}>
							{/*eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values*/}
							<div css={arrowContainer}>
								<IconButton
									type="button"
									onClick={onMultiPromptClick ? () => onMultiPromptClick(-1) : undefined}
									icon={ChevronLeftIcon}
									label={'left'}
									appearance="subtle"
									spacing="compact"
									isDisabled={isMultiPromptLoading}
								/>
							</div>
							{/* eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values */}
							<div css={[multipromptContent]}>
								<Stack
									{...(actionButtonsLayoutMode === 'compact' && {
										space: 'space.200',
									})}
								>
									{multiPrompts[multiPromptIndex!].content}
								</Stack>
							</div>
							{/*eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values*/}
							<div css={arrowContainer}>
								<IconButton
									type="button"
									onClick={onMultiPromptClick ? () => onMultiPromptClick(1) : undefined}
									icon={ChevronRightIcon}
									label={'right'}
									appearance="subtle"
									spacing="compact"
									isLoading={isMultiPromptLoading}
								/>
							</div>
						</div>
						<div>{actionButtons}</div>
					</div>
				) : (
					// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values
					<div css={[previewContainer, defaultPaddingStyles]}>
						<Stack {...(actionButtonsLayoutMode === 'compact' && { space: 'space.200' })}>
							{preview}
							{actionButtons}
						</Stack>
					</div>
				)}
				{disableActions || disableInterrogation ? null : (
					<div
						css={[
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
							defaultPaddingStyles,
							(editorExperiment('platform_editor_ai_command_palette_post_ga', 'test') ||
								fg('platform_editor_ai_command_palette_post_ga_jira')) &&
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
								interrogationWrapper,
						]}
					>
						<PromptForm
							showButtons={isInputActive}
							autoFocus={false}
							placeholder={initialPlaceholder}
							ariaLabel={initialPlaceholder}
							value={inputValue}
							adfValue={inputADFValue}
							onFormSubmit={onSubmit}
							onCancel={onCancel}
							onInputChange={onInputChange}
							onADFChange={onADFChange}
							onInputFocus={onInputFocus}
							onInputBlur={onInputBlur}
							focusInputRef={focusInputRef}
							clearInputRef={clearInputRef}
							PromptEditor={PromptEditor}
							enableLinks={enableLinksInPromptEditor}
							fireParentActionButton={firePrimaryApplication}
							focusParentActionButton={setForceFocusRing}
						/>
					</div>
				)}
				{editorExperiment('platform_editor_ai_command_palette_post_ga', 'test') ? (
					<Footer
						brand={agent ? 'rovo' : 'atlassian-intelligence'}
						disclaimer={disclaimer}
						showDisclaimer={true}
					/>
				) : (
					<FooterOld
						footerRightContent={footerRightContent}
						disclaimer={disclaimer}
						additionalFooterText={additionalFooterText}
					/>
				)}
			</FloatingContainer>
		</div>
	);
};
