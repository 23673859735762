import head from 'lodash/head';
import { AnyAri } from '@atlassian/ari/any-ari';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types';
import type { ProjectMetadata } from '@atlassian/jira-polaris-domain-project/src/types.tsx';
import {
	getNextgenSettingsIssueTypesUrl,
	getNextgenSettingsUrl,
} from '@atlassian/jira-project-settings-apps-common/src/urls';
import { createHook, createStateHook } from '@atlassian/react-sweet-state';
import { ProjectStore } from '../main';

export const useIsProjectAvailable = createHook(ProjectStore, {
	selector: (state) => !!state.project.key,
});

/**
 * Returns the Jira project name for this project.
 */
export const useProjectName = createHook(ProjectStore, {
	selector: (state) => state.project?.name,
});

/**
 * Returns the Jira project key for this project.
 */
export const useProjectKey = createHook(ProjectStore, {
	selector: (state) => state.project?.key,
});

export const useIsProjectOnboarded = createHook(ProjectStore, {
	selector: (state) => state.project?.onboarded,
});

export const useProjectOnboardedAtTimestamp = createStateHook(ProjectStore, {
	selector: (state) => state.project?.onboardedAtTimestamp,
});

export const useProjectTemplateVersion = createStateHook(ProjectStore, {
	selector: (state) => state.project?.templateVersion,
});

export const useSelectedDeliveryProject = createHook(ProjectStore, {
	selector: (state) => state.project?.selectedDeliveryProject,
});

export const useError = createHook(ProjectStore, {
	selector: (state) => state.meta.project.error,
});

export const useIsProjectLoading = createHook(ProjectStore, {
	selector: (state) => state.meta.project.loading,
});

export const useIssueLinkType = createHook(ProjectStore, {
	selector: (state) => state.project?.issueLinkType,
});

/**
 * @deprecated use useStatusCategories from '@atlassian/jira-polaris-component-environment-tenant' instead
 */
export const useStatusCategories = createHook(ProjectStore, {
	selector: (state) => state.project?.statusCategories || [],
});

/**
 * Returns array of IssueTypeId which represent `Ideas` in this Polaris project.
 */
export const useIdeaIssueTypes = createHook(ProjectStore, {
	selector: (state) => state.project?.ideaIssueTypes,
});

/**
 * Returns the singular issue type id for "Idea" issue types
 */
export const useIdeaIssueType = createHook(ProjectStore, {
	selector: (state) => head(state.project?.ideaIssueTypes)?.jiraIssueTypeId,
});

/**
 * Returns fieldKey suitable for making calls to rank issues in this project.
 */
export const useJiraRankFieldKey = createHook(ProjectStore, {
	selector: (state) => state.project?.jiraRankFieldKey,
});

/**
 * Returns a url where the user can add a new field to the Idea issue type.
 */
export const useAddFieldUrl = createHook(ProjectStore, {
	selector: ({ project }) =>
		project.key &&
		getNextgenSettingsIssueTypesUrl(
			SOFTWARE_PROJECT,
			project.key,
			project.ideaIssueTypes[0].jiraIssueTypeId,
		),
});

/**
 * Returns the url for the project settings page.
 */
export const useProjectSettingsUrl = createHook(ProjectStore, {
	selector: ({ project }) => project.key && getNextgenSettingsUrl(SOFTWARE_PROJECT, project.key),
});

/**
 * Returns the Jira project name for this project.
 */
export const useProjectAvatarUrls = createHook(ProjectStore, {
	selector: (state) => state.project?.avatarUrls,
});

export const useProjectArjHierarchyConfiguration = createHook(ProjectStore, {
	selector: (state) => state.project?.arjHierarchyConfiguration || [],
});

export const useProjectMetadata = createStateHook(ProjectStore, {
	selector: (state): ProjectMetadata | undefined => {
		if (!state.project || state.project.id === undefined) {
			return undefined;
		}

		const id = AnyAri.parse(state.project.id).resourceId;

		if (
			id === undefined ||
			state.project.key === undefined ||
			state.project.name === undefined ||
			state.project.avatarUrls === undefined
		) {
			return undefined;
		}

		return {
			id,
			key: state.project.key,
			name: state.project.name,
			avatarUrls: state.project.avatarUrls,
		};
	},
});
