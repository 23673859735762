import React from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { Box, xcss } from '@atlaskit/primitives';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import Placeholder from '@atlassian/jira-placeholder';
import { useUFOComponentExperience } from '@atlassian/ufo';
import { TabsSkeleton } from './common/ui/tabs-skeleton';
import { summaryTabLoadExperience } from './experiences';
import { PanelWrapper } from './styled';
import type RiskInsightsPanelType from './ui';
import type { Props } from './ui/types';

export const AsyncRiskInsightsPanel = lazyAfterPaint<typeof RiskInsightsPanelType>(
	() => import(/* webpackChunkName: "async-risk-insights-panel" */ './ui'),
);

const LazyRiskInsightsPanelOld = ({ issueKey }: Props) => (
	<JSErrorBoundary
		id="riskInsightsPanel"
		packageName="jiraServiceDeskRiskInsightsPanel"
		fallback="unmount"
		teamName="jenga"
		sendToPrivacyUnsafeSplunk
	>
		<PanelWrapper>
			<Placeholder name="risk-insights-panel" fallback={<Skeleton width="100%" height="60px" />}>
				<AsyncRiskInsightsPanel issueKey={issueKey} />
			</Placeholder>
		</PanelWrapper>
	</JSErrorBoundary>
);

const LazyRiskInsightsPanelNew = ({ issueKey }: Props) => {
	useUFOComponentExperience(summaryTabLoadExperience);

	return (
		<JSErrorBoundary
			id="riskInsightsPanel"
			packageName="jiraServiceDeskRiskInsightsPanel"
			fallback="unmount"
			teamName="jenga"
			sendToPrivacyUnsafeSplunk
		>
			<Box xcss={panelStyles}>
				<Placeholder name="risk-insights-panel" fallback={<TabsSkeleton />}>
					<AsyncRiskInsightsPanel issueKey={issueKey} />
				</Placeholder>
			</Box>
		</JSErrorBoundary>
	);
};
const panelStyles = xcss({
	marginBottom: 'space.300',
});

export default componentWithCondition(
	() => ff('enable-risk-insights-platformization_o9hel'),
	LazyRiskInsightsPanelNew,
	LazyRiskInsightsPanelOld,
);
