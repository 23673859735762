import memoizeOne from 'memoize-one';
import SingleSelectInlineEditView from '@atlassian/jira-issue-internal-field-select/src/single-select-inline-edit';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field';
import {
	fieldAllowedValuesSelector,
	isFieldRequiredSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector';
import { projectIdSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import { transformSuggestionsFromServerAndCache } from '../versions/versions-transformer';
import messages from './messages';
import { transformFromStateValue, transformToStateValue } from './transformer';

const fetchSuggestionsFactory = memoizeOne(
	(suggestions, intl, projectId) => () =>
		Promise.resolve(suggestions).then((resolvedSuggestions) =>
			transformSuggestionsFromServerAndCache(resolvedSuggestions, [], intl, projectId),
		),
);

export default connectField((stateOnMount, ownPropsOnMount) => ({
	fieldId: ownPropsOnMount.fieldId,
	transformFromStateValue,
	transformToStateValue,
	additionalProps: (state, intl) => ({
		fetchSuggestions: fetchSuggestionsFactory(
			fieldAllowedValuesSelector(ownPropsOnMount.fieldId)(state),
			intl,
			projectIdSelector(state),
		),
		placeholder: intl.formatMessage(messages.placeholder),
		noValueText: intl.formatMessage(genericMessages.noValue),
		allowEmptyValue: !isFieldRequiredSelector(ownPropsOnMount.fieldId)(state),
		hasAutocomplete: true,
		showPinButton: getShowPinButton(ownPropsOnMount.area),
	}),
}))(SingleSelectInlineEditView);
