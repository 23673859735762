import { useCallback, useEffect, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { COMMAND_PALETTE_DEBOUNCE_DEFAULT } from '../../common/constants.tsx';
import { useCommandPaletteSearch } from '../command-palette';
import type { UseDebounceSearchCallback } from './types';

export const useDebouncedLoadChildren = (callback: UseDebounceSearchCallback) => {
	const [{ search }, { setLoadingList }] = useCommandPaletteSearch();

	const loadChildCommands: UseDebounceSearchCallback = useCallback(
		async (searchTerm, obj) => {
			await callback(searchTerm, obj);
			if (!obj.isMounted) return;
			setLoadingList(false);
		},
		[callback, setLoadingList],
	);
	const executeDebouncer = useMemo(
		() => debounce(loadChildCommands, COMMAND_PALETTE_DEBOUNCE_DEFAULT),
		[loadChildCommands],
	);

	useEffect(() => () => executeDebouncer?.cancel?.(), [executeDebouncer]);

	useEffect(() => {
		const obj = {
			isMounted: true,
		};

		setLoadingList(true);
		executeDebouncer(search, obj);

		return () => {
			obj.isMounted = false;
		};
	}, [search, executeDebouncer, setLoadingList]);
};
