import memoizeOne from 'memoize-one';
import {
	performPutRequest,
	performPostRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import TeamField from '@atlassian/jira-issue-internal-fields/src/team/view.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages';
import { getIssueModalAkDropdownPortal } from '@atlassian/jira-issue-view-common-utils/src/get-element/index.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field';
import { getIssueApiUrl } from '@atlassian/jira-issue-view-services/src/issue/issue-urls';
import { baseUrlSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { isSubtaskSelector } from '@atlassian/jira-issue-view-store/src/selectors/subtasks-selector';
import { isFieldInTabSelector } from '@atlassian/jira-issue-view-store/src/selectors/tab-selector';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	transformFromStateValue,
	transformToStateValue,
	transformSuggestions,
} from './team-transformer';

const fetchSuggestionsFactory = memoizeOne(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(baseUrl) => (query: any) =>
		performPostRequest(`${baseUrl}/rest/teams/1.0/teams/find`, {
			body: JSON.stringify({
				query,
				excludedIds: [],
				maxResults: 100,
			}),
		}).then((suggestion) => transformSuggestions(suggestion, baseUrl)),
);

type SaveFieldArg = {
	baseUrl: BaseUrl;
	issueKey: IssueKey;
	fieldMetaKey: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const saveField = ({ baseUrl, issueKey, fieldMetaKey, value }: SaveFieldArg): Promise<any> => {
	const teamId = value && value.id ? value.id.toString() : null;
	return performPutRequest(getIssueApiUrl(baseUrl, issueKey), {
		body: JSON.stringify({
			fields: {
				[fieldMetaKey]: teamId,
			},
		}),
	});
};

export default connectField((stateOnMount, ownPropsOnMount) => ({
	fieldId: ownPropsOnMount.fieldId,
	transformFromStateValue,
	transformToStateValue,
	additionalProps: (state, intl) => ({
		fetchSuggestions: fetchSuggestionsFactory(baseUrlSelector(state)),
		noValueText: intl.formatMessage(genericMessages.noValue),
		portalElement: isFieldInTabSelector(ownPropsOnMount.fieldId)(state) // Using portalElement because otherwise dropdowns would get cut off by the container borders. See BENTO-4101
			? // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				getIssueModalAkDropdownPortal() || document.body
			: undefined,
		isSubTask: isSubtaskSelector(state),
		showPinButton: getShowPinButton(ownPropsOnMount.area),
	}),
	saveField,
}))(TeamField);
