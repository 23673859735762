import { createSelector } from 'reselect';
import type { User } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { WatcherPermissions, WatchersList, State, TenantContext } from '../model/types';

export const tenantContextSelector = (state: State): TenantContext => state.tenantContext;

export const getBaseUrl = createSelector(tenantContextSelector, (context) => context.baseUrl);

export const getLocale = createSelector(tenantContextSelector, (context) => context.locale);

export const getLoggedInUserDetails = (state: State): User | null => state.loggedInUserDetails;

export const getIssueKey = (state: State): IssueKey => state.issueKey;

export const getIsWatching = (state: State): boolean => state.isWatching;

export const getWatchersCount = (state: State): number => state.watchersCount;

export const getIsKeyboardShortcutEnabled = (state: State): boolean =>
	state.isKeyboardShortcutEnabled;

const permissionsSelector = (state: State): WatcherPermissions => state.permissions;

export const getCanViewWatchers = createSelector(
	permissionsSelector,
	(permissions) => permissions.canViewWatchers,
);

export const getCanManageWatchers = createSelector(
	permissionsSelector,
	(permissions) => permissions.canManageWatchers,
);

const watchersListSelector = (state: State): WatchersList => state.watchersList;

export const getIsWatchersListLoading = createSelector(
	watchersListSelector,
	(watchersList) => watchersList.isLoading,
);

export const getWatchersListUsers = createSelector(
	watchersListSelector,
	(watchersList) => watchersList.users,
);
