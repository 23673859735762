import type { TimeTrackingConfig } from '@atlassian/jira-issue-shared-types/src/common/types/jira-settings-type.tsx';
import isTimeStringValid from '@atlassian/jira-issue-view-common-utils/src/time-string/is-time-string-valid/index.tsx';
import secondsToTimeString from '@atlassian/jira-issue-view-common-utils/src/time-string/seconds-to-time-string/index.tsx';
import timeStringToSeconds from '@atlassian/jira-issue-view-common-utils/src/time-string/time-string-to-seconds/index.tsx';
import { isNumeric } from '@atlassian/jira-issue-view-common-utils/src/type-check/index.tsx';
import { getDefaultTimeTrackingUnit } from './common/utils/default-time-tracking-unit';

export const formatTimeString =
	(config: TimeTrackingConfig) =>
	(timeValue: number | null): string =>
		secondsToTimeString(config.daysPerWeek, config.hoursPerDay)(timeValue || 0);

export const appendTimeUnitIfMissing = (config: TimeTrackingConfig) => (value: string) => {
	let trimmedValue = value.trim();

	// If user doesn't type the valid format (m, h, d, or w), use default unit format set in the configuration.
	const isValidNumber = isNumeric(trimmedValue.replaceAll(',', '.'));
	if (isValidNumber) {
		const { defaultUnit } = config;
		trimmedValue = `${trimmedValue}${getDefaultTimeTrackingUnit(defaultUnit)}`;
	}

	return trimmedValue;
};

export const isValidTimeString = (config: TimeTrackingConfig) => (value: string) => {
	const timeString = appendTimeUnitIfMissing(config)(value);
	return timeString === '' || isTimeStringValid(timeString);
};

export const parseTimeString =
	(config: TimeTrackingConfig) =>
	(value: string): number | null => {
		const timeString = appendTimeUnitIfMissing(config)(value);

		if (!isValidTimeString(config)(timeString)) {
			return 0;
		}

		return timeStringToSeconds(config.daysPerWeek, config.hoursPerDay)(timeString);
	};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { secondsToWholeMinuteTimeString } from './common/utils/seconds-to-whole-minute-time-string/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { TimeTrackingConfig };
