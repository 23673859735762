import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/ignoreElements';
import type { ActionsObservable } from 'redux-observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { toIssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { ChangeEventTypes } from '../../model/on-change-callback';
import { CLASSIC_SUBTASKS, CHILDREN_ISSUES_PANEL } from '../../model/types';
import { getOnChangeCallback, getChildIssuesPanelType } from '../../state/context/selectors';
import {
	type LinkChildSuccessAction,
	type ReorderChildrenRequestAction,
	type ReorderChildrenFailedAction,
	type FetchGraphqlIssueSuccessAction,
	type UpdateIssueAction,
	LINK_CHILD_SUCCESS,
	REORDER_CHILDREN_REQUEST,
	REORDER_CHILDREN_FAILED,
	FETCH_GRAPHQL_ISSUE_SUCCESS,
	UPDATE_ISSUE,
} from '../../state/entities/actions';
import type { State } from '../../state/types';

type Action =
	| FetchGraphqlIssueSuccessAction
	| LinkChildSuccessAction
	| ReorderChildrenRequestAction
	| ReorderChildrenFailedAction
	| UpdateIssueAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(
			LINK_CHILD_SUCCESS,
			REORDER_CHILDREN_REQUEST,
			REORDER_CHILDREN_FAILED,
			FETCH_GRAPHQL_ISSUE_SUCCESS,
			UPDATE_ISSUE,
		)
		.do((action: Action) => {
			try {
				const state = store.getState();
				const onChange = getOnChangeCallback(state);
				const panelType = getChildIssuesPanelType(state);

				switch (action.type) {
					case FETCH_GRAPHQL_ISSUE_SUCCESS: {
						onChange({
							type: ChangeEventTypes.CHILD_ISSUE_ADDED,
							meta: {
								childIssue: action.payload.childIssue,
								fieldsData: action.payload.fieldsData,
							},
						});

						break;
					}

					case LINK_CHILD_SUCCESS: {
						onChange({
							type: ChangeEventTypes.CHILD_ISSUE_ADDED,
							meta: {
								childIssue: action.payload.linkedChild,
							},
						});

						break;
					}

					case REORDER_CHILDREN_REQUEST: {
						const {
							currentOrder,
							newOrder: optimisticOrder,
							currentIndex,
							newIndex,
						} = action.payload;

						const { id: issueId } = currentOrder[currentIndex];
						const issueIds = issueId ? [toIssueId(issueId)] : [];
						const isRankAfter = newIndex > currentIndex;
						const rankId = Number(
							(optimisticOrder[isRankAfter ? newIndex - 1 : newIndex + 1] || {}).id,
						);

						if (panelType === CHILDREN_ISSUES_PANEL || panelType === CLASSIC_SUBTASKS) {
							onChange({
								type: ChangeEventTypes.ISSUE_CHILDREN_ORDER_CHANGED,
								meta: { issueIds, rankId, isRankAfter },
							});
						}
						break;
					}

					case REORDER_CHILDREN_FAILED: {
						const {
							originalOrder: currentOrder,
							originalIndex: currentIndex,
							newIndex,
						} = action.payload;

						const { id: issueId } = currentOrder[currentIndex];
						const issueIds = issueId ? [toIssueId(issueId)] : [];
						const isRankAfter = currentIndex > newIndex;
						const rankId = Number(
							(currentOrder[isRankAfter ? currentIndex - 1 : currentIndex + 1] || {}).id,
						);

						if ((panelType === CHILDREN_ISSUES_PANEL || panelType === CLASSIC_SUBTASKS) && rankId) {
							onChange({
								type: ChangeEventTypes.ISSUE_CHILDREN_ORDER_CHANGED,
								meta: { issueIds, rankId, isRankAfter },
							});
						}
						break;
					}

					case UPDATE_ISSUE: {
						if (panelType === CLASSIC_SUBTASKS) {
							onChange({
								type: ChangeEventTypes.CHILD_ISSUE_FIELD_UPDATED,
								meta: {
									childIssueId: action.payload.id,
								},
							});
						}
						break;
					}

					default:
						break;
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				log.safeErrorWithoutCustomerData(
					'issue.views.common.child-issues-panel.change-notification',
					'Failed to call onChange callback',
					error,
				);
			}
		})
		.ignoreElements();
