import React from 'react';
import { styled } from '@compiled/react';
import ShareIcon from '@atlaskit/icon/glyph/share';
import { Box, xcss, Text } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
// eslint-disable-next-line import/order
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import Team from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/other/components/team';
import defaultAvatarImg from '../assets/default-avatar.svg';
import type { TeamFieldType } from '../types';

type Props = {
	value: TeamFieldType;
	noValueText: string;
};

const TeamViewField = (props: Props) => {
	const { value, noValueText } = props;
	const isCentralIllustrationsEnabled = fg('move_issue_view_assets_into_central_location');

	if (value) {
		const { label, isShared, avatarUrl } = value;
		const shareIcon = isShared ? (
			<SharedIconWrapper>
				<ShareIcon label="" size="small" />
			</SharedIconWrapper>
		) : null;
		return (
			<Container>
				{isCentralIllustrationsEnabled && !avatarUrl ? (
					<Box xcss={imageWrapperStyles}>
						<Team alt="" width={24} height={24} />
					</Box>
				) : (
					<Avatar src={avatarUrl || defaultAvatarImg} alt="" />
				)}

				<Title>{label}</Title>
				{shareIcon}
			</Container>
		);
	}
	return <Text color="color.text.subtlest">{noValueText}</Text>;
};

export default TeamViewField;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	flexDirection: 'row',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	alignItems: 'center',
	justifyContent: 'start',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.div({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	marginRight: token('space.100', '8px'),
	display: 'inline-block',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Avatar = styled.img({
	verticalAlign: 'middle',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 3}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 3}px`,
	marginRight: token('space.100', '8px'),
	display: 'inline-block',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SharedIconWrapper = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.danger', colors.R500),
});

const imageWrapperStyles = xcss({
	marginRight: 'space.100',
});
