import type { EntryPointReferenceSubject } from '@atlassian/jira-entry-point/src/controllers/utils/use-entry-point-load-manager/index.tsx';
import type { IdeaViewBentoSidebarEntryPoint } from '@atlassian/jira-polaris-component-idea-view-bento-sidebar/entrypoint';
import { createStore, createActionsHook, createHook } from '@atlassian/react-sweet-state';

type State = {
	entryPointReferenceSubject: EntryPointReferenceSubject<
		typeof IdeaViewBentoSidebarEntryPoint
	> | null;
};

const initialState: State = {
	entryPointReferenceSubject: null,
};

const actions = {
	setEntryPointReferenceSubject:
		(
			entryPointReferenceSubject: EntryPointReferenceSubject<
				typeof IdeaViewBentoSidebarEntryPoint
			> | null,
		) =>
		({ setState }: { setState: (state: State) => void }) => {
			setState({ entryPointReferenceSubject });
		},
};

const Store = createStore<State, typeof actions>({
	initialState,
	actions,
	name: 'IssueViewJPDIdeasSelectedIdeaStore',
});

export const useActiveEntryPointSubject = createHook(Store, {
	selector: (state) => state.entryPointReferenceSubject,
});

export const useActiveEntryPointSubjectActions = createActionsHook(Store);
