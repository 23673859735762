import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { CardAction } from '@atlaskit/smart-card';
import { HoverCard } from '@atlaskit/smart-card/hover-card';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { shouldDisplayStrikethrough } from '../common/utils';
import messages from './messages';
import type { Props } from './types';

export const IssueKey = memo<Props>(
	({
		issueKey,
		issueLink,
		statusCategory,
		hideTooltipOnMouseDown = false,
		showHoverPreviewEnabled,
		isResolved,
		tooltipContent,
		label,
		testId,
		onClick,
	}: Props) => {
		const { formatMessage } = useIntl();

		const getDefaultLabel = () =>
			isResolved ? formatMessage(messages.label, { issueKey }) : undefined;

		const renderKeyContent = () => (
			<Key
				data-testid={testId}
				href={issueLink}
				shouldDisplayStrikethrough={shouldDisplayStrikethrough(isResolved, statusCategory)}
				onClick={onClick}
				aria-label={label ?? getDefaultLabel()}
				role="link"
				/**
				 * Disabling dragging so drags happen on the parent instead of this link.
				 */
				draggable={false}
			>
				{issueKey}
			</Key>
		);

		if (showHoverPreviewEnabled) {
			const normalizedIssueLink = issueLink?.startsWith('/')
				? // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					`${window.location.protocol}//${window.location.hostname}${issueLink}`
				: issueLink;

			return (
				<HoverCard
					url={normalizedIssueLink || ''}
					closeOnChildClick
					actionOptions={{
						hide: false,
						exclude: [CardAction.ChangeStatusAction],
					}}
				>
					{renderKeyContent()}
				</HoverCard>
			);
		}

		return (
			<Tooltip
				content={tooltipContent}
				hideTooltipOnMouseDown={hideTooltipOnMouseDown}
				position="bottom"
			>
				{renderKeyContent()}
			</Tooltip>
		);
	},
);

export default IssueKey;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Key = styled.a<{ shouldDisplayStrikethrough?: boolean }>({
	outline: 'none',
	marginLeft: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.link', colors.B400),
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.medium'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	textDecoration: (props) => (props.shouldDisplayStrikethrough ? 'line-through' : 'none'),
	whiteSpace: 'nowrap',
});
