/* The relay/unused-fields rule is disabled as this file is only responsible for fetching from AGG.
 * When we decompose this query and move to Relay fragments this will be removed.
 */
/* eslint-disable @atlassian/relay/unused-fields */

import { fetchQuery, graphql } from 'react-relay';
import type { IEnvironment, PayloadError } from 'relay-runtime';
import { metrics } from '@atlassian/browser-metrics';
import { isInSample } from '@atlassian/jira-issue-sample-utils/src/common/utils/index.tsx';
import { startCaptureGraphQlErrors, stopCaptureGraphQlErrors } from '@atlassian/jira-relay-errors';
import associatedIssueDataQuery, {
	type associatedIssueDataQuery as associatedIssuesDataQueryType,
	type associatedIssueDataQuery$data as associatedIssuesDataQueryResponse,
} from '@atlassian/jira-relay/src/__generated__/associatedIssueDataQuery.graphql';
import type { IssueKey, CloudId } from '@atlassian/jira-shared-types/src/general.tsx';

export type AssociatedIssueData = {
	data?: associatedIssuesDataQueryResponse;
	errors?: PayloadError[];
};

export const ASSOCIATED_ISSUE_DATA_OPERATION_NAME = associatedIssueDataQuery.params.name;

export const associatedIssuesDataQueryTag = graphql`
	query associatedIssueDataQuery($issueKey: String!, $cloudId: ID!) {
		jira @required(action: THROW) {
			issueByKey(key: $issueKey, cloudId: $cloudId) {
				issueId
				key
				isResolved
			}
		}
	}
`;

const ASSOCIATED_ISSUE_DATA = 'associated-issue-data';

// visible for testing
export const timeToFetchAssociatedIssueDataMetric = metrics.custom({
	key: ASSOCIATED_ISSUE_DATA,
});

export const captureIssueViewAggErrors = (key: string) => {
	const errors = stopCaptureGraphQlErrors(key)
		.filter(
			(errorWrapper) => errorWrapper.meta.operationName === ASSOCIATED_ISSUE_DATA_OPERATION_NAME,
		)
		.flatMap((errorWrapper) => errorWrapper.errors);
	return errors;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default async (
	issueKey: IssueKey,
	cloudId: CloudId,
	environment: IEnvironment,
): Promise<AssociatedIssueData> => {
	if (isInSample()) {
		timeToFetchAssociatedIssueDataMetric.start();
	}

	const id = startCaptureGraphQlErrors();
	const data = await fetchQuery<associatedIssuesDataQueryType>(
		environment,
		associatedIssuesDataQueryTag,
		{
			cloudId,
			issueKey,
		},
	)
		.toPromise()
		.catch((error) => {
			// Cancel the metric and re-throw the error
			timeToFetchAssociatedIssueDataMetric?.cancel();
			throw error;
		});
	const errors = captureIssueViewAggErrors(id);

	// Best-effort attempt to provide `issueId` and is not guaranteed
	const issueId = data?.jira.issueByKey?.issueId;

	timeToFetchAssociatedIssueDataMetric.stop({
		customData: issueId
			? {
					issueId,
				}
			: null,
	});

	return { data, errors };
};
