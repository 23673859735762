import { EmojiResource, type EmojiProvider } from '@atlaskit/emoji';
import type { AccountId, CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	getServiceProvider,
	EMOJI_SERVICEID,
	EMOJI_WITH_CUSTOM_SERVICEID,
	EMOJI_WITH_CUSTOM_UPLOAD_DISABLED_SERVICEID,
} from './service-repo';

const baseProviders = (serviceBaseUrl: string) => [
	{ url: `${serviceBaseUrl}/emoji/standard` },
	{ url: `${serviceBaseUrl}/emoji/atlassian` },
];

// Note: NextGen Project Settings Issue Type page still uses `getEmojiProvider` for multiline field default values
// Not allowing customEmoji there as creating issue still uses wikimarkup which does not support custom emojis.
// File: src/packages/project-settings/issue-type-page/src/view/field-associated/field-configuration/multiline/index.js
export const getEmojiProvider = (): Promise<EmojiProvider> | null =>
	getServiceProvider(EMOJI_SERVICEID, (serviceBaseUrl) => {
		const emojiResource = new EmojiResource({
			providers: baseProviders(serviceBaseUrl),
		});

		return emojiResource.getEmojiProvider();
	});

export const getEmojiProviderWithCustomEmoji = (
	cloudId?: CloudId | null,
	accountId?: AccountId | null,
): Promise<EmojiProvider> | null =>
	getServiceProvider(EMOJI_WITH_CUSTOM_SERVICEID, (serviceBaseUrl) => {
		const providers = cloudId
			? [...baseProviders(serviceBaseUrl), { url: `${serviceBaseUrl}/emoji/${cloudId}/site` }]
			: baseProviders(serviceBaseUrl);
		const emojiResource = new EmojiResource({
			providers,
			allowUpload: true,
			// @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string'.
			currentUser: { id: accountId },
		});

		return emojiResource.getEmojiProvider();
	});

export const getEmojiProviderWithCustomEmojiUploadDisabled = (
	cloudId: CloudId,
	accountId: AccountId | null,
): Promise<EmojiProvider> | null =>
	getServiceProvider(EMOJI_WITH_CUSTOM_UPLOAD_DISABLED_SERVICEID, (serviceBaseUrl) => {
		const providers = cloudId
			? [...baseProviders(serviceBaseUrl), { url: `${serviceBaseUrl}/emoji/${cloudId}/site` }]
			: baseProviders(serviceBaseUrl);

		const emojiResource = new EmojiResource({
			providers,
			allowUpload: false,
			// @ts-expect-error - TS2322 - Type 'string | null' is not assignable to type 'string'.
			currentUser: { id: accountId },
		});

		return emojiResource.getEmojiProvider();
	});
