import React, { type PropsWithChildren } from 'react';
import { graphql, useFragment } from 'react-relay';
import { ff } from '@atlassian/jira-feature-flagging';
import { FieldHeading } from '@atlassian/jira-issue-field-heading';
import {
	FieldHeadingTitle,
	FieldWrapper,
	SideBySideField,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { Changeboarding as ParentChangeboarding } from '@atlassian/jira-issue-field-parent/src/ui/changeboarding/index.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import type { heading_issueViewLayoutParentField_ParentFieldHeading$key as IssueViewFieldHeadingFragment } from '@atlassian/jira-relay/src/__generated__/heading_issueViewLayoutParentField_ParentFieldHeading.graphql';

export type ParentFieldHeadingProps = PropsWithChildren<{
	testId?: string;
	area?: string;
	field: IssueViewFieldHeadingFragment;
}>;

export const ParentFieldHeading = ({ children, area, field, testId }: ParentFieldHeadingProps) => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const showPinButton = getShowPinButton(area as Area);
	const { overriding } = useInlineEditFieldInjections();

	const data = useFragment<IssueViewFieldHeadingFragment>(
		graphql`
			fragment heading_issueViewLayoutParentField_ParentFieldHeading on JiraIssueField {
				name
				fieldId
			}
		`,
		field,
	);

	const label = overriding.overrideLabel(data.name);

	return (
		<FieldWrapper data-testid={testId}>
			<FieldHeading fieldId={data.fieldId}>
				<FieldHeadingTitle>{label}</FieldHeadingTitle>
				{ff('parent-field-changeboarding_4ljdy', false) && <ParentChangeboarding />}
				{showPinButton && <FieldPin fieldId={data.fieldId} label={label} />}
			</FieldHeading>
			<SideBySideField>{children}</SideBySideField>
		</FieldWrapper>
	);
};
