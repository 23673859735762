import { R500, R200, Y300, G300, G500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import messages from './messages';

export const MODAL_WIDTH = 1100;
export const MODAL_TABLE_HEIGHT = 520;
export const PACKAGE_NAME = '@atlassian/jira-servicedesk-incident-management-common';

export const P1 = 'P1' as const;
export const P2 = 'P2' as const;
export const P3 = 'P3' as const;
export const P4 = 'P4' as const;
export const P5 = 'P5' as const;

export const PRIORITIES = {
	P1,
	P2,
	P3,
	P4,
	P5,
} as const;

export type PriorityValue =
	| typeof PRIORITIES.P1
	| typeof PRIORITIES.P2
	| typeof PRIORITIES.P3
	| typeof PRIORITIES.P4
	| typeof PRIORITIES.P5;

export const IncidentCommandCenterExtend = 'icc' as const;

const PRIORITY_COLORS = {
	[P1]: token('color.background.accent.red.bolder', R500),
	[P2]: token('color.background.accent.red.subtle', R200),
	[P3]: token('color.background.accent.yellow.subtle', Y300),
	[P4]: token('color.background.accent.green.subtle', G300),
	[P5]: token('color.background.accent.green.bolder', G500),
} as const;

const PRIORITY_COLORS_CONSOLIDATION = {
	[P1]: token('color.background.danger.bold', '#CA3521'),
	[P2]: token('color.background.accent.red.subtle', '#F87462'),
	[P3]: token('color.background.accent.orange.subtle', '#FAA53D'),
	[P4]: token('color.background.accent.green.subtle', '#4BCE97'),
	[P5]: token('color.background.accent.green.bolder', '#1F845A'),
} as const;

export const OG_PRIORITY_MAP = [
	{ value: PRIORITIES.P1, label: messages.criticalAlertPriority },
	{ value: PRIORITIES.P2, label: messages.highAlertPriority },
	{ value: PRIORITIES.P3, label: messages.moderateAlertPriority },
	{ value: PRIORITIES.P4, label: messages.lowAlertPriority },
	{ value: PRIORITIES.P5, label: messages.informationalAlertPriority },
] as const;

export const priorityColor = (p: PriorityValue, consolidated = false) =>
	consolidated ? PRIORITY_COLORS_CONSOLIDATION[p] : PRIORITY_COLORS[p];
