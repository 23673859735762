import React from 'react';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { createUseEntryPointContext } from '@atlassian/jira-entry-point/src/controllers/utils/create-use-entry-point-context/index.tsx';
import { entryPointPremiumUpgradeModal } from '@atlassian/jira-premium-upgrade-modal/src/entrypoint';

export const AIUpsellPremiumTrialEntryPointContext =
	createUseEntryPointContext<typeof entryPointPremiumUpgradeModal>();

type Props = {
	children: React.ReactNode;
};

export const AIUpsellPremiumTrialEntryPointContextProvider = ({ children }: Props) => {
	const entryPointParams = {};
	const entryPointResult = useEntryPoint(entryPointPremiumUpgradeModal, entryPointParams);

	return (
		<AIUpsellPremiumTrialEntryPointContext.Provider value={entryPointResult}>
			{children}
		</AIUpsellPremiumTrialEntryPointContext.Provider>
	);
};
