import { useHasFinishedLoading } from '../../adjustments-context/hooks/use-enabled-status';
import { useAdjustmentsEnabled } from '../../adjustments-context/index.tsx';
import { useAnalyticsData } from '../../issue-adjustments/main.tsx';

type Props = {
	forgeFinishedLoading: boolean;
	forgeModulesCount: number;
};

export const useUimAnalytics = ({ forgeFinishedLoading, forgeModulesCount }: Props) => {
	const [{ uiModificationsCount, appsCount }] = useAnalyticsData();
	const hasIssueAdjustmentFinishedLoading = useHasFinishedLoading();
	const adjustmentsAreEnabled = useAdjustmentsEnabled();

	const isIssueAdjustmentDisabled =
		forgeFinishedLoading &&
		(forgeModulesCount === 0 || (hasIssueAdjustmentFinishedLoading && !adjustmentsAreEnabled));

	const isIssueAdjustmentEnabled =
		forgeFinishedLoading &&
		forgeModulesCount > 0 &&
		hasIssueAdjustmentFinishedLoading &&
		adjustmentsAreEnabled;

	return {
		isUimFinishedLoading: isIssueAdjustmentDisabled || isIssueAdjustmentEnabled,
		appsCount: appsCount ?? 0,
		uiModificationsCount: uiModificationsCount ?? 0,
	};
};
