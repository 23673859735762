import React, { type ComponentType } from 'react';
import { styled } from '@compiled/react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import Placeholder from '@atlassian/jira-placeholder';
import { PermalinkButton as PermalinkButtonSync, type Props } from './view';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyPermalinkButton: ComponentType<Props> = lazyAfterPaint(
	() =>
		import(/* webpackChunkName: "async-issue-view-permalink-button" */ './view').then(
			(exportedModule) => exportedModule.PermalinkButton,
		),
	{
		ssr: false,
	},
);

const PermButton = componentWithFG(
	'change_permalink_button_to_sync',
	PermalinkButtonSync,
	LazyPermalinkButton,
);
export const PermalinkButton = (props: Props) => (
	<ErrorBoundary id="issue-view-lazy-permalink" packageName="jiraIssueView">
		<Placeholder name="issue-view-lazy-permalink" fallback={<EmptyState />}>
			<PermButton {...props} />
		</Placeholder>
	</ErrorBoundary>
);

PermalinkButton.displayName = 'PermalinkButton';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyState = styled.div({
	paddingTop: token('space.025', '2px'),
	display: 'inline-block',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 2}px`,
});
