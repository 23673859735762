import React, { useCallback, useMemo, useState, useRef } from 'react';
import { styled } from '@compiled/react';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import memoizeOne from 'memoize-one';
import type { MediaClientConfig } from '@atlaskit/media-core';
import { MediaTable } from '@atlaskit/media-table';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { WidthObserver } from '@atlaskit/width-detector';
import { ScreenReaderText } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { useIssueAttachments } from '@atlassian/jira-issue-attachments-base/src/services/attachments-service/main.tsx';
import { listView } from '@atlassian/jira-issue-attachments-base/src/ui/types.tsx';
import withIssueAttachmentsDelete from '@atlassian/jira-issue-attachments-base/src/ui/with-issue-attachments-delete/main.tsx';
import type { InjectedProps as AttachmentsDeleteInjectedProps } from '@atlassian/jira-issue-attachments-base/src/ui/with-issue-attachments-delete/types.tsx';
import type { OrderDirection } from '@atlassian/jira-issue-attachments-store/src/common/types';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { ITEMS_PER_PAGE, TABLE_ROW_CLASSNAME, ROW_HIGHLIGHT_CLASSNAME } from './constants';
import NoAttachments from './empty';
import AttachmentsError from './error';
import messages from './messages';
import AttachmentsTableSkeleton from './skeleton';
import type { ListViewColumnKey } from './types';
import {
	getColumns,
	getColumnKeyFromOrderField,
	getOrderFieldFromColumnKey,
	transformAttachments,
} from './utils';

export type Props = {
	isInModal?: boolean;
	shouldHideErrorImage: boolean;
	viewMediaClientConfig: MediaClientConfig;
	containerWidth?: number;
	onPreviewOpen?: () => void;
	onPreviewClose?: () => void;
	onScrollToComments?: () => void;
	onScrollToWorklogs?: () => void;
};

type DecoratedProps = Props & AttachmentsDeleteInjectedProps;

export const useWidth = () => {
	const [width, setWidth] = useState<number | undefined>(undefined);
	const debouncedSetWidth = useMemo(() => debounce(setWidth, 100), []);

	return [width, debouncedSetWidth] as const;
};

const IssueAttachmentsTable = (props: DecoratedProps) => {
	const {
		viewMediaClientConfig,
		onPreviewOpen = noop,
		onPreviewClose = noop,
		onDeleteAttachment,
		canDeleteAttachmentMap,
		onScrollToComments = noop,
		onScrollToWorklogs = noop,
		isInModal = false,
		shouldHideErrorImage = false,
	} = props;

	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const hasReloadedAttachments = useRef(false);

	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);

	const [{ error, loading, meta, value }, actions] = useIssueAttachments(issueKey);

	const { orderDirection, orderField, startAt } = meta;
	const { attachments, totalCount } = value;
	const { refreshAttachments, setOrderDirection, setOrderField } = actions;

	const debouncedRefreshAttachments = memoizeOne(debounce(refreshAttachments, 50));

	// Calculate using startAt instead of manually setting it to prevent data mismatch
	const pageNumber = useMemo<number>(() => Math.ceil((startAt + 1) / ITEMS_PER_PAGE), [startAt]);

	// Use separately from pageNumber since fetching can fail
	const [previewPageNumber, setPreviewPageNumber] = useState<number>(pageNumber);

	const [width, setWidth] = useWidth();

	const onClick = useCallback(() => {
		refreshAttachments(issueKey, {
			orderDirection,
			orderField,
			startAt: ITEMS_PER_PAGE * (pageNumber - 1),
		});
	}, [issueKey, orderDirection, orderField, pageNumber, refreshAttachments]);

	const onSetPage = useCallback(
		(newPageNumber: number) => {
			const analyticsEvent = createAnalyticsEvent({
				action: 'setPage',
				actionSubject: 'dynamicTable',
			});
			fireUIAnalytics(analyticsEvent, 'issueAttachments', {
				currentPageNumber: previewPageNumber,
				targetPageNumber: newPageNumber,
			});

			hasReloadedAttachments.current = true;
			setPreviewPageNumber(newPageNumber);
			debouncedRefreshAttachments(issueKey, {
				orderDirection,
				orderField,
				startAt: ITEMS_PER_PAGE * (newPageNumber - 1),
			});
		},
		[
			createAnalyticsEvent,
			debouncedRefreshAttachments,
			issueKey,
			orderDirection,
			orderField,
			previewPageNumber,
		],
	);

	const onSort = useCallback(
		(columnKey: ListViewColumnKey, newOrderDirection: OrderDirection) => {
			hasReloadedAttachments.current = true;
			const newOrderField = getOrderFieldFromColumnKey(columnKey);
			const analyticsEvent = createAnalyticsEvent({
				action: 'sorted',
				actionSubject: 'dynamicTable',
			});
			setOrderField(newOrderField);
			setOrderDirection(newOrderDirection);
			fireUIAnalytics(analyticsEvent, 'issueAttachments', {
				key: columnKey,
			});
			debouncedRefreshAttachments(issueKey, {
				orderDirection: newOrderDirection,
				orderField: newOrderField,
			});
		},
		[createAnalyticsEvent, debouncedRefreshAttachments, issueKey, setOrderDirection, setOrderField],
	);

	const locale = useLocale();

	if (error) {
		return <AttachmentsError shouldHideImage={shouldHideErrorImage} onClick={onClick} />;
	}

	if (totalCount === 0) {
		return <NoAttachments />;
	}

	const items = transformAttachments(
		attachments || [],
		totalCount,
		width,
		locale,
		onDeleteAttachment,
		canDeleteAttachmentMap,
		onScrollToWorklogs,
		onScrollToComments,
		isInModal,
		issueKey,
		intl,
		projectType === SERVICE_DESK_PROJECT,
	);

	const columns = getColumns(totalCount, width, intl);
	const sortKey = getColumnKeyFromOrderField(orderField);

	return (
		<>
			<WidthObserver offscreen setWidth={setWidth} />

			{loading && (
				<>
					{hasReloadedAttachments.current && (
						<ScreenReaderText aria-live="assertive">
							{intl.formatMessage(messages.loadingAriaLabel)}
						</ScreenReaderText>
					)}
					<AttachmentsTableSkeleton pageNumber={previewPageNumber} totalCount={totalCount} />
				</>
			)}

			{!loading && (
				<>
					{hasReloadedAttachments.current && (
						<ScreenReaderText aria-live="assertive">
							{intl.formatMessage(messages.loadedAriaLabel)}
						</ScreenReaderText>
					)}

					{
						<RowHighlightWrapper>
							{}
							<MediaTable
								items={items}
								mediaClientConfig={viewMediaClientConfig}
								columns={columns}
								itemsPerPage={ITEMS_PER_PAGE}
								totalItems={totalCount || 0}
								pageNumber={pageNumber}
								onSetPage={onSetPage}
								// @ts-expect-error - TS2769 - No overload matches this call.
								onSort={onSort}
								sortKey={sortKey}
								sortOrder={orderDirection}
								onPreviewOpen={onPreviewOpen}
								onPreviewClose={onPreviewClose}
								rowProps={{
									className: TABLE_ROW_CLASSNAME,
								}}
							/>
						</RowHighlightWrapper>
					}
				</>
			)}
		</>
	);
};

export default componentWithFG(
	'convert_with_issue_attachments_delete_modal',
	(props: Props) => (
		<IssueAttachmentsTable {...props} canDeleteAttachmentMap={{}} onDeleteAttachment={noop} />
	),
	withIssueAttachmentsDelete<Props>(IssueAttachmentsTable, listView),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RowHighlightWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`.${ROW_HIGHLIGHT_CLASSNAME}`]: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.warning', colors.Y50),
		'&:hover': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			backgroundColor: token('color.background.warning.hovered', colors.Y75),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'button:hover': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			background: token('color.background.warning.bold.hovered', colors.Y100),
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			svg: {
				color: token('color.icon.warning.inverse', 'inherit'),
			},
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		svg: {
			color: token('color.icon.warning', 'inherit'),
		},
	},
});
