import { ApiError } from '../../../common/utils/api-error';

export const addPath = (baseUrl: string, path: string) => {
	const urlWithTrailingSlash = baseUrl.endsWith('/') ? baseUrl : `${baseUrl}/`;
	const pathWithoutLeadingSlash = !path.startsWith('/') ? path : path.substring(1, path.length);
	const url = [urlWithTrailingSlash, pathWithoutLeadingSlash].join('');
	return url;
};

type ErrorResponseJson = {
	error_code?: string;
	error_message?: string;
	message_template?: string;
	errors?: string[];
	errorMessage?: string;
};

const processErrorResponse = async ({
	response,
	throwResponseOnError,
}: {
	response: Response;
	throwResponseOnError?: boolean;
}): Promise<Response> => {
	if (throwResponseOnError) {
		const result = await response.json();
		throw result;
	}
	let errorCode: string | undefined;
	let outputErrorMessage: string;
	const responseText = await response.text();
	let rawErrorMessage: string | undefined;
	try {
		const errorBodyJson: ErrorResponseJson = JSON.parse(responseText);
		const { error_code, error_message, message_template, errors, errorMessage } = errorBodyJson;
		rawErrorMessage = errorMessage ?? error_message;

		if (errors?.length) {
			// Use a single error code for now
			errorCode = errors[0];
		} else if (error_code) {
			errorCode = error_code;
		} else {
			errorCode = message_template;
		}

		if (!errorCode || !rawErrorMessage) {
			outputErrorMessage = responseText;
		} else {
			outputErrorMessage = `${errorCode} - ${rawErrorMessage}`;
		}
	} catch (err) {
		outputErrorMessage = responseText;
	}

	throw new ApiError(`[Code ${response.status}] ${outputErrorMessage}`, {
		errorCode,
		statusCode: response.status,
		rawErrorMessage,
	});
};

export const processResponse = async ({
	response,
	throwResponseOnError,
}: {
	response: Response;
	throwResponseOnError?: boolean;
}): Promise<Response> => {
	if (!response.ok || response.status >= 400) {
		await processErrorResponse({ response, throwResponseOnError });
	}
	return response;
};
