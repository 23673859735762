import { ATTACHMENT_PARENT_ISSUE } from '@atlassian/jira-issue-attachments-store/src/common/constants';
import type { GetCanDeleteAttachmentMapArg, CanDeleteAttachmentMap } from './types';

export const getCanDeleteAttachmentMap = ({
	attachments,
	canDeleteAllAttachments,
	canDeleteOwnAttachments,
	accountIdLoggedInUser,
}: GetCanDeleteAttachmentMapArg): CanDeleteAttachmentMap =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	attachments.reduce<Record<string, any>>(
		(acc, attachment) => ({
			// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
			...acc,
			[attachment.id]:
				attachment.parentName === ATTACHMENT_PARENT_ISSUE &&
				(canDeleteAllAttachments ||
					(accountIdLoggedInUser !== null &&
						attachment.author !== null &&
						canDeleteOwnAttachments &&
						attachment.author.accountId === accountIdLoggedInUser)),
		}),
		{},
	);
