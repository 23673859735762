/**
 * @generated SignedSource<<66a349610236985b855e6061eff80185>>
 * @relayHash 5553381b932ca3e23888ffe5dd63daf7
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 0e70a1c4e1d2418e95d0aa6241912ead6197d35f29bb8a923039d57fba1c1c29

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type versionsWithRelayEditViewFieldOptionsPrefetchIntentQuery$variables = {
  first?: number | null | undefined;
  id: string;
};
export type versionsWithRelayEditViewFieldOptionsPrefetchIntentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment">;
};
export type versionsWithRelayEditViewFieldOptionsPrefetchIntentQuery = {
  response: versionsWithRelayEditViewFieldOptionsPrefetchIntentQuery$data;
  variables: versionsWithRelayEditViewFieldOptionsPrefetchIntentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "id"
},
v4 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v5 = {
  "kind": "ScalarField",
  "name": "id"
},
v6 = [
  (v2/*: any*/),
  {
    "kind": "Literal",
    "name": "searchBy",
    "value": ""
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "versionsWithRelayEditViewFieldOptionsPrefetchIntentQuery",
    "selections": [
      {
        "args": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "versionsWithRelayEditViewFieldOptionsPrefetchIntentQuery",
    "selections": [
      {
        "args": [
          (v3/*: any*/)
        ],
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "ScalarField",
                "name": "type"
              }
            ],
            "type": "JiraIssueField",
            "abstractKey": "__isJiraIssueField"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v6/*: any*/),
                "concreteType": "JiraSelectableValueConnection",
                "kind": "LinkedField",
                "name": "selectableValueOptions",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraSelectableValueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "selectableLabel"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "selectableGroupKey"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "selectableIconUrl"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "isDisabled"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "optionId"
                              }
                            ],
                            "type": "JiraOption"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "versionId"
                              }
                            ],
                            "type": "JiraVersion"
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "cursor"
                      }
                    ]
                  },
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                      }
                    ]
                  }
                ]
              },
              {
                "args": (v6/*: any*/),
                "filters": [
                  "searchBy",
                  "filterById"
                ],
                "handle": "connection",
                "key": "selectableValue_issueFieldEditviewFull_fieldOptions__selectableValueOptions",
                "kind": "LinkedHandle",
                "name": "selectableValueOptions"
              }
            ],
            "type": "JiraHasSelectableValueOptions",
            "abstractKey": "__isJiraHasSelectableValueOptions"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "0e70a1c4e1d2418e95d0aa6241912ead6197d35f29bb8a923039d57fba1c1c29",
    "metadata": {},
    "name": "versionsWithRelayEditViewFieldOptionsPrefetchIntentQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "96f8ce12aa6c099994ab33fce88d7dd1";

export default node;
