import isEqual from 'lodash/isEqual';
import { fireOperationalAnalyticsWithoutContext } from '@atlassian/jira-ui-modifications-analytics/src/common/utils/operational-analytics/index.tsx';
import type { FieldMapInternalMetadata } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/types/field-internal-metadata.tsx';
import type { FieldId } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/types/field.tsx';
import type { FormDataPublic } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/types/fields/form-field-data.tsx';
import type { FieldType } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/views/types';
import type { ViewType } from '@atlassian/jira-ui-modifications-types/src/common/types/context.tsx';
import { mapFieldValueFromInternalToPublic } from '../map-field-value-from-internal-to-public';

export const calculateNextFormValue = ({
	internalFormMetadata,
	formData,
	fieldId,
	fieldValue,
	fieldType,
	viewType,
}: {
	fieldId: FieldId;
	fieldValue: unknown;
	fieldType: FieldType;
	internalFormMetadata: FieldMapInternalMetadata;
	formData: FormDataPublic | null;
	viewType: ViewType;
}) => {
	if (formData === null) {
		return null;
	}

	let newPublicValue;

	try {
		newPublicValue = mapFieldValueFromInternalToPublic({
			fieldMetadata: internalFormMetadata[fieldId],
			internalFieldValue: fieldValue,
			fieldType,
			viewType,
		});
	} catch (e) {
		fireOperationalAnalyticsWithoutContext(
			'internalToPublicValueTransformer',
			'fieldMetadataUndefined',
			viewType,
			{
				fieldType,
			},
		);

		return null;
	}

	const currentPublicValue = formData[fieldId]?.value;
	if (isEqual(newPublicValue, currentPublicValue)) {
		return null;
	}

	return {
		...formData,
		[fieldId]: {
			...formData[fieldId],
			value: newPublicValue,
		},
	};
};
