import React, { createContext, useContext } from 'react';
import type { OnShowFlag } from '../../watchers/model/types';

type WatchersStore = {
	onShowFlag?: OnShowFlag;
};
const WatchersContext = createContext<WatchersStore>({});

export const WatchersProvider = ({
	store,
	children,
}: {
	store: WatchersStore;
	children: React.ReactNode;
}) => {
	return <WatchersContext.Provider value={store}>{children}</WatchersContext.Provider>;
};

export const useOnShowFlag = () => {
	const { onShowFlag } = useContext(WatchersContext);
	return { onShowFlag };
};
