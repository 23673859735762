import { createSelector } from 'reselect';
import flatten from 'lodash/flatten';
import flatMap from 'lodash/fp/flatMapDeep';
import getOr from 'lodash/fp/getOr';
import pipe from 'lodash/fp/pipe';
import keyBy from 'lodash/keyBy';
import type { Branch, InstanceTypeDetail } from '../../../../model';
import type { State } from '../../../index';
import {
	getSelectedInstanceTypeDetails,
	getAllInstanceTypeDetails,
	getInstancesForPRDiscovery,
} from '../index';

const mapInstanceTypeDetailsToBranchesList = (instanceTypeDetails: InstanceTypeDetail[]) =>
	instanceTypeDetails
		? flatten(
				instanceTypeDetails.map((instanceTypeDetail) =>
					flatten(instanceTypeDetail.repositories.map((repo) => repo.branches)),
				),
			)
		: [];

/**
 * Returns branches for selected instance type details
 */
export const getBranches = createSelector(
	[getSelectedInstanceTypeDetails],
	mapInstanceTypeDetailsToBranchesList,
);

/**
 * Returns branches for all instance type details
 */
export const getAllBranches = createSelector(
	[getAllInstanceTypeDetails],
	mapInstanceTypeDetailsToBranchesList,
);

const getBranchById = createSelector([getBranches], (branches) =>
	keyBy(branches, (branch) => branch.id),
);

const getMultiInstanceBranchById = createSelector([getAllBranches], (branches) =>
	keyBy(branches, (branch) => branch.id),
);

export const getBranchForId = (state: State, id: string): Branch => getBranchById(state)[id];
export const getMultiInstanceBranchForId = (state: State, id: string): Branch =>
	getMultiInstanceBranchById(state)[id];
export const hasBranches = (state: State): boolean => getBranches(state).length > 0;
export const multiInstanceHasBranches = (state: State): boolean => getAllBranches(state).length > 0;
const flattenEligibleBranches: (arg1: InstanceTypeDetail[]) => Branch[] = pipe(
	flatMap(getOr([], 'repositories')),
	flatMap(getOr([], 'branches')),
);

export const getDiscoveryBranchesWithoutPRs = createSelector(
	[getInstancesForPRDiscovery],
	flattenEligibleBranches,
);
