import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { IssueAdjustmentsState } from '../../types';
import type { NotificationState } from './types';

export const setNotification =
	(notificationProperties: NotificationState) =>
	({ setState }: StoreActionApi<IssueAdjustmentsState>) => {
		setState({
			notification: {
				type: notificationProperties.type,
			},
		});
	};

export const resetNotification =
	() =>
	({ setState }: StoreActionApi<IssueAdjustmentsState>) => {
		setState({
			notification: null,
		});
	};
