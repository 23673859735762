import type { ComponentType } from 'react';
import { compose } from 'redux';
import memoizeOne from 'memoize-one';
import { defineMessages } from '@atlassian/jira-intl';
import SingleSelectInlineEditView from '@atlassian/jira-issue-internal-field-select/src/single-select-inline-edit';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages';
import { getIssueModalAkDropdownPortal } from '@atlassian/jira-issue-view-common-utils/src/get-element/index.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field';
import { withExtraOwnProps } from '@atlassian/jira-issue-view-common-views/src/with-extra-own-props';
import {
	fieldAllowedValuesSelector,
	fieldNameSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector';
import { isFieldInTabSelector } from '@atlassian/jira-issue-view-store/src/selectors/tab-selector';
import { filterAllowedValues } from '../../common/utils/filter-select-allowed-values';
import {
	transformFromStateValue,
	transformToStateValue,
	transformSuggestions,
	type StateValue,
} from './single-select-transformer';

// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
const messages = defineMessages({
	placeholder: {
		id: 'issue.single-select.text-for-dropdown-when-nothing-selected',
		defaultMessage: 'Select {fieldName}',
		description: '',
	},
});

const getMemoizedSuggestionsFactoryV2 = memoizeOne(
	(suggestions, allowedValuesOverrides) => () =>
		Promise.resolve(filterAllowedValues<StateValue>(suggestions, allowedValuesOverrides)).then(
			transformSuggestions,
		),
	// Check if memoize keeps the same object between transitions (recent issue)
	// pay more attention to non-pollinator performance dashboard: especially transitions
);

const SingleSelect = compose(
	withExtraOwnProps,
	connectField((stateOnMount, ownPropsOnMount) => ({
		fieldId: ownPropsOnMount.fieldId,
		transformFromStateValue,
		transformToStateValue,
		additionalProps: (state, intl, fieldOptions, extraOwnProps) => ({
			fetchSuggestions: getMemoizedSuggestionsFactoryV2(
				fieldAllowedValuesSelector(ownPropsOnMount.fieldId)(state),
				extraOwnProps?.allowedValuesOverrides,
			),
			placeholder: intl.formatMessage(messages.placeholder, {
				fieldName: fieldNameSelector(ownPropsOnMount.fieldId)(state),
			}),
			noValueText: intl.formatMessage(genericMessages.noValue),
			allowEmptyValue: true,
			hasAutocomplete: true,
			portalElement: isFieldInTabSelector(ownPropsOnMount.fieldId)(state)
				? // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					getIssueModalAkDropdownPortal() || document.body
				: undefined,
			showPinButton: getShowPinButton(ownPropsOnMount.area),
		}),
	})),
)(SingleSelectInlineEditView);

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
export default SingleSelect as ComponentType<any>;
