import React, { useEffect, useState } from 'react';
import { useQueryLoader } from 'react-relay';
import { fg } from '@atlassian/jira-feature-gating';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main';
import PanelViewCustomerQuery, {
	type panelViewOrganizationQuery,
} from '@atlassian/jira-relay/src/__generated__/panelViewOrganizationQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { OrganizationOption, PanelSource } from '../../types';
import { ExpandPanel } from '../expand-panel';
import { OrganizationField } from './organization-field';
import { PanelView } from './panel-view';

export const Organization = ({
	panelSource,
	organization,
	isLast,
}: {
	panelSource: PanelSource;
	organization: OrganizationOption;
	isLast?: boolean;
}) => {
	const cloudId = useCloudId();
	const issueId = useIssueId() || '';
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [queryReference, loadQuery, disposeQuery] =
		useQueryLoader<panelViewOrganizationQuery>(PanelViewCustomerQuery);
	const hasQueryReference = !!queryReference;

	useEffect(() => {
		if (!isOpen && hasQueryReference) {
			disposeQuery();
		}
	}, [disposeQuery, hasQueryReference, isOpen]);

	const onToggle = () => {
		setIsOpen((prev) => {
			if (!prev && organization?.id) {
				loadQuery({
					cloudId,
					organizationId: organization.id,
					filter: fg('jcs_detail_permissions')
						? { context: { type: 'ISSUE', issueId } }
						: { context: { type: 'ISSUE' } },
				});
			}

			return !prev;
		});
	};

	return (
		<ExpandPanel
			onToggle={onToggle}
			field={<OrganizationField value={organization} />}
			isOpen={isOpen}
			id={`organization-${organization.id}`}
			source={panelSource}
		>
			{isOpen && queryReference && (
				<div data-testid="servicedesk-customer-service-issue-view-panel.common.ui.organization.div">
					<PanelView queryReference={queryReference} isLast={isLast} />
				</div>
			)}
		</ExpandPanel>
	);
};
