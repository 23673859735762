/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { forwardRef } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/standard-button';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';

import { AI_MODAL_SCREEN_CLASS } from '../../../utils/constants';
import { FloatingContainer } from '../../components/FloatingContainer/FloatingContainer';
import type { Breakpoint } from '../../hooks/useElementBreakpoints';
import { buttonAutoFocusRingFix } from '../../styles/focus';

import messages from './messages';
import {
	buttonContainerStyles,
	getContainerStyles,
	getIconStyles,
	getTextContainerStyles,
	iconColor,
	textStyles,
} from './styles';

export function ErrorMessage({
	children,
	breakpoint,
}: {
	children: React.ReactNode;
	breakpoint: Breakpoint;
}) {
	const { formatMessage } = useIntl();
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
		<div css={getTextContainerStyles(breakpoint)}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
			<span css={getIconStyles(breakpoint)}>
				<WarningIcon label={formatMessage(messages.warningIconLabel)} primaryColor={iconColor} />
			</span>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
			<p css={textStyles}>{children}</p>
		</div>
	);
}

export function ErrorButton({
	children,
	onClick,
	appearance,
	testId,
	autoFocus,
	disabled = false,
}: {
	testId?: string;
	children: React.ReactNode;
	onClick: () => void;
	appearance: 'subtle' | 'primary';
	disabled?: boolean;
	autoFocus?: boolean;
}) {
	return (
		<Button
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			css={buttonAutoFocusRingFix}
			autoFocus={autoFocus}
			testId={testId}
			spacing="compact"
			onClick={onClick}
			type="button"
			appearance={appearance}
			isDisabled={disabled}
		>
			{children}
		</Button>
	);
}

export function ErrorButtonContainer({ children }: { children: React.ReactNode }) {
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
	return <div css={buttonContainerStyles}>{children}</div>;
}

// Need to use forwardRef because functional component cannot be passed a ref
// as a prop.
export const ErrorFloatingContainer = forwardRef(
	(
		{
			children,
			breakpoint,
		}: {
			breakpoint: Breakpoint;
			children: React.ReactNode;
		},
		ref: React.Ref<HTMLDivElement>,
	) => {
		return (
			<FloatingContainer
				ref={ref}
				data-testid="error-screen"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={`error-screen ${AI_MODAL_SCREEN_CLASS}`}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css={getContainerStyles(breakpoint)}
				paddingAdjustments={{ top: 1, bottom: 1 }}
				rainbowBorder={!editorExperiment('platform_editor_ai_command_palette_post_ga', 'test')}
			>
				{children}
			</FloatingContainer>
		);
	},
);
