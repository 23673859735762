import uniq from 'lodash/uniq';
import {
	createStore,
	createActionsHook,
	createSelector,
	createStateHook,
} from '@atlassian/react-sweet-state';
import type { CollectionsFilters } from '../../common/types';
import * as actions from './actions';
import type { State } from './types';
import {
	filterCollectionByOwnerIds,
	filterCollectionByText,
	filterCollectionByProjectKeys,
} from './utils';

const initialState: State = {
	collections: {},
	isLoading: true,
	error: undefined,
	descriptions: {},
};

const Store = createStore<State, typeof actions>({
	initialState,
	actions,
	name: 'PolarisCollections',
});

export const useCollectionsActions = createActionsHook(Store);

const getCollections = (state: State) => state.collections;
const getCollectionsList = createSelector(getCollections, (collections) =>
	Object.values(collections)
		.map((c) => c?.data)
		.filter(Boolean),
);
const getCollectionIds = createSelector(getCollections, (collections) => Object.keys(collections));
const getCollectionsLoading = (state: State) => state.isLoading;
const getCollectionsError = (state: State) => state.error;
const getCollectionsHasError = createSelector(getCollectionsError, (error) => !!error);

const getCollectionsOwnersIds = createSelector(getCollectionsList, (collections) =>
	uniq(collections.map(({ ownerId }) => ownerId).filter(Boolean)),
);

const getFilteredCollections = createSelector(
	(state: State) => getCollectionsList(state),
	(_, { text }: CollectionsFilters) => text,
	(_, { ownerIds }: CollectionsFilters) => ownerIds,
	(_, { projects }: CollectionsFilters) => projects,
	(collections, text, ownerIds, projects) =>
		collections
			? collections.filter(
					(collection) =>
						filterCollectionByText(text)(collection) &&
						filterCollectionByOwnerIds(ownerIds)(collection) &&
						filterCollectionByProjectKeys(projects)(collection),
				)
			: [],
);

const getCollectionsCount = createSelector(getCollectionsList, (collections) => collections.length);

export const useCollectionsOwnersIds = createStateHook(Store, {
	selector: getCollectionsOwnersIds,
});
export const useFilteredCollections = createStateHook(Store, { selector: getFilteredCollections });
export const useCollections = createStateHook(Store, { selector: getCollections });
export const useCollectionsLoading = createStateHook(Store, { selector: getCollectionsLoading });
export const useCollectionsHasError = createStateHook(Store, { selector: getCollectionsHasError });
export const useCollectionsCount = createStateHook(Store, { selector: getCollectionsCount });
export const useCollectionIds = createStateHook(Store, { selector: getCollectionIds });

const getCollectionWithMeta = createSelector(
	getCollections,
	(_, { collectionUUID }: { collectionUUID: string | undefined }) => collectionUUID,
	(collections, collectionUUID) => (collectionUUID ? collections[collectionUUID] : undefined),
);
const getCollection = createSelector(getCollectionWithMeta, (collection) => collection?.data);
const getCollectionName = createSelector(getCollection, (collection) => collection?.name);
const getCollectionEmoji = createSelector(getCollection, (collection) => collection?.emoji);
const getCollectionJql = createSelector(getCollection, (collection) => collection?.jql);
const getCollectionViews = createSelector(getCollection, (collection) => collection?.views || []);
const hasFetchedCollectionViews = createSelector(getCollection, (collection) =>
	Array.isArray(collection?.views),
);
const getIsCollectionLoading = createSelector(
	getCollectionWithMeta,
	(collection) => !!collection?.isLoading && !collection?.data?.views,
);
const getCollectionProjectKeys = createSelector(
	getCollection,
	(collection) => collection?.projectKeys,
);
const getProjectCollectionsCount = createSelector(getCollections, (collections) =>
	Object.values(collections)
		.flatMap((collection) => collection?.data?.projectKeys)
		.filter((key): key is string => !!key)
		.reduce((acc: Record<string, number>, projectKey) => {
			acc[projectKey] = (acc[projectKey] ?? 0) + 1;
			return acc;
		}, {}),
);
const getError = createSelector(getCollectionWithMeta, (collection) => collection?.error);
const hasError = createSelector(getError, (error) => !!error);
const getCollectionViewsOwnersIds = createSelector(getCollectionViews, (views) =>
	uniq(views.map(({ ownerId }) => ownerId).filter(Boolean)),
);
const getCollectionView = createSelector(
	getCollectionViews,
	(_, { viewUUID }: { viewUUID: string | undefined; collectionUUID: string | undefined }) =>
		viewUUID,
	(views, viewUUID) => (viewUUID ? views.find((view) => view.uuid === viewUUID) : undefined),
);
const getCollectionViewTitle = createSelector(getCollectionView, (view) => view?.title);
const getCollectionViewsCount = createSelector(
	getCollection,
	(collection) => collection?.views?.length || collection?.viewsCount,
);
const getCollectionOwnerId = createSelector(getCollection, (collection) => collection?.ownerId);

const getDescriptions = (state: State) => state.descriptions;
const getCollectionDescriptionWithMeta = createSelector(
	getDescriptions,
	(_, { collectionUUID }: { collectionUUID: string | undefined }) => collectionUUID,
	(descriptions, collectionUUID) => (collectionUUID ? descriptions[collectionUUID] : undefined),
);
const getCollectionDescription = createSelector(
	getCollectionDescriptionWithMeta,
	(descriptionWithMeta) => descriptionWithMeta?.description,
);
const getIsCollectionDescriptionLoading = createSelector(
	getCollectionDescriptionWithMeta,
	(descriptionWithMeta) => !!descriptionWithMeta?.isLoading,
);
const getCollectionDescriptionHasError = createSelector(
	getCollectionDescriptionWithMeta,
	(descriptionWithMeta) => !!descriptionWithMeta?.error,
);

export const useCollection = createStateHook(Store, { selector: getCollection });
export const useCollectionName = createStateHook(Store, { selector: getCollectionName });
export const useCollectionEmoji = createStateHook(Store, { selector: getCollectionEmoji });
export const useCollectionJql = createStateHook(Store, { selector: getCollectionJql });
export const useCollectionViews = createStateHook(Store, { selector: getCollectionViews });
export const useCollectionLoading = createStateHook(Store, { selector: getIsCollectionLoading });
export const useCollectionError = createStateHook(Store, { selector: getError });
export const useCollectionHasError = createStateHook(Store, { selector: hasError });
export const useCollectionViewsOwnersIds = createStateHook(Store, {
	selector: getCollectionViewsOwnersIds,
});
export const useCollectionView = createStateHook(Store, { selector: getCollectionView });
export const useCollectionViewTitle = createStateHook(Store, { selector: getCollectionViewTitle });
export const useCollectionViewsCount = createStateHook(Store, {
	selector: getCollectionViewsCount,
});
export const useHasFetchedCollectionViews = createStateHook(Store, {
	selector: hasFetchedCollectionViews,
});
export const useCollectionOwnerId = createStateHook(Store, { selector: getCollectionOwnerId });
export const useCollectionDescription = createStateHook(Store, {
	selector: getCollectionDescription,
});
export const useCollectionProjectKeys = createStateHook(Store, {
	selector: getCollectionProjectKeys,
});
export const useProjectCollectionsCount = createStateHook(Store, {
	selector: getProjectCollectionsCount,
});
export const useIsCollectionDescriptionLoading = createStateHook(Store, {
	selector: getIsCollectionDescriptionLoading,
});
export const useCollectionDescriptionHasError = createStateHook(Store, {
	selector: getCollectionDescriptionHasError,
});
