import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { traverse } from '@atlaskit/adf-utils/traverse';
import type { ADFEntity } from '@atlaskit/adf-utils/types';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { JIRA_ISSUE_AUTO_LINK_CONTENT_ON_LINK_PASTE } from '@atlassian/jira-issue-create-confluence-content/src/common/constants/embedded-confluence-source.tsx';
import type { ConfluencePage } from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import type { FailedRemoteLink } from '@atlassian/jira-issue-shared-types/src/common/types/remote-link-error-type.tsx';
import type { ConfluenceAppLink } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import type { TrackEmbeddedConfluence } from '../../common/types/types';
import { createConfluencePageLinkRequest } from '../../services/create-confluence-page-link';

type LinkedConfluenceContent = ConfluencePage;
type LinkedContent = LinkedConfluenceContent | FailedRemoteLink;

const PAGE_URL_PART = '/pages/';
const BLOG_URL_PART = '/blog/';

const linkedConfluenceContentValidator = (
	content: LinkedContent,
): content is LinkedConfluenceContent => {
	return 'href' in content;
};

export const findUniqueLinkUrls = (doc: ADF): string[] => {
	const urls = new Set<string>();
	const addFoundLinkUrl = (node: ADFEntity) => {
		const url = node.attrs?.url || node.attrs?.href;
		url && urls.add(url);
	};

	traverse(doc, {
		text: (node) => {
			node.marks && node.marks.map((mark) => mark.type === 'link' && addFoundLinkUrl(mark));
		},
		inlineCard: addFoundLinkUrl,
		blockCard: addFoundLinkUrl,
		embedCard: addFoundLinkUrl,
	});

	return [...urls];
};

export const filterLinkableConfluenceUrls = (
	urls: string[],
	confluenceAppLinks: ConfluenceAppLink[],
): string[] => {
	// Fitler URLs for Confluence pages, and blogs
	// from Confluence sites linked to Jira by app links
	const res = urls.filter((url) =>
		confluenceAppLinks.some(
			(appLink) =>
				url.includes(appLink.baseUrl) &&
				(url.includes(PAGE_URL_PART) || url.includes(BLOG_URL_PART)),
		),
	);

	return res;
};

export const filterNewConfluenceLinks = (
	urls: string[],
	currentLinkedContent: (ConfluencePage | FailedRemoteLink)[],
): string[] => {
	// Filter out URLs that are already linked to Jira issue
	if (!currentLinkedContent.length) {
		return urls;
	}
	const currentLinkedContentUrls = currentLinkedContent.reduce((currentUrls: string[], content) => {
		if (linkedConfluenceContentValidator(content)) {
			currentUrls.push(content.href);
		}
		return currentUrls;
	}, []);

	return urls.filter((url) => !currentLinkedContentUrls.includes(url));
};

export const batchCreateConfluenceContentLinks = async ({
	issueId,
	urls,
	onAutoLinkPagesRequest,
	onAutoLinkPagesComplete,
	fireAnalyticsEvent,
}: {
	issueId: string;
	urls: string[];
	onAutoLinkPagesRequest: () => void;
	onAutoLinkPagesComplete: (confluencePageLink: (ConfluencePage | FailedRemoteLink)[]) => void;
	fireAnalyticsEvent: TrackEmbeddedConfluence;
}) => {
	onAutoLinkPagesRequest();
	const createLinkBatch = urls.map((url: string) =>
		createConfluencePageLinkRequest({ issueId, pageHref: url })
			.then((confluencePage) => {
				if (linkedConfluenceContentValidator(confluencePage)) {
					fireAnalyticsEvent({
						action: 'created',
						actionSubject: 'autolink',
						embeddedConfluenceSource: JIRA_ISSUE_AUTO_LINK_CONTENT_ON_LINK_PASTE,
						eventName: 'autolink created',
						eventType: 'track',
						linkObjectType: confluencePage.type,
					});
				}
				return confluencePage;
			})
			.catch((error) => {
				fireErrorAnalytics({
					meta: {
						id: 'batchCreateConfluenceContentLinks',
						teamName: 'confluence-better-together',
					},
					attributes: {
						embeddedConfluenceSource: JIRA_ISSUE_AUTO_LINK_CONTENT_ON_LINK_PASTE,
					},
					error: new Error(`Failed to auto link confluence content: ${error}`),
				});
			}),
	);
	await Promise.all(createLinkBatch).then((confluencePages) => {
		const linkedPages: (ConfluencePage | FailedRemoteLink)[] =
			confluencePages.filter(
				(confluencePage): confluencePage is ConfluencePage | FailedRemoteLink => !!confluencePage,
			) || [];
		onAutoLinkPagesComplete(linkedPages);
	});
};
