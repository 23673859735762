import React from 'react';
import SortDescendingIcon from '@atlaskit/icon/core/sort-descending';
import ArrowDownLegacy from '@atlassian/jira-icons/src/ui/sorting-down-icon';

export const ArrowDownIcon = ({ label }: { label: string }) => (
	<SortDescendingIcon
		spacing="none"
		color="currentColor"
		label={label}
		LEGACY_size="small"
		LEGACY_fallbackIcon={ArrowDownLegacy}
	/>
);
