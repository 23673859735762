import React from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { singleLineClamp } from '@atlassian/jira-common-styles/src/mixins';
import { OptionWrapper } from '../../select-inline-edit/select-field/styled';
import type { Option } from '../../select-inline-edit/select-field/types';

type Props = {
	option: Option;
};

export const RichOptionView = ({ option }: Props) => (
	<OptionWrapper>
		{option.elemBefore != null ? option.elemBefore : null}
		<OptionTextContainer>
			<OptionLabel>{option.label}</OptionLabel>
			{option.description != null ? (
				<OptionDescription>{option.description}</OptionDescription>
			) : null}
		</OptionTextContainer>
	</OptionWrapper>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OptionTextContainer = styled.span({
	display: 'flex',
	flexDirection: 'column',
	margin: `0 ${token('space.100', '8px')}`,
	padding: `${token('space.025', '2px')} 0`,
	overflow: 'hidden',
	flexGrow: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export const OptionLabel = styled.span(singleLineClamp, {
	flexGrow: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionDescription = styled.span({
	display: 'inline-block',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N300),
	font: token('font.body.UNSAFE_small'),
	marginTop: token('space.050', '4px'),
});
