import type { LatestPrompt } from '../../experience-application/get-ai-experience-service';

import { supportedTranslationLanguagesPopularOrder } from './types';

export const getSortedSupportedTranslationLanguages = () => {
	return supportedTranslationLanguagesPopularOrder;
};

export const isTranslationPrompt = (latestPrompt?: LatestPrompt): boolean =>
	typeof latestPrompt?.presetTitle?.title?.defaultMessage === 'string' &&
	latestPrompt?.presetTitle?.title?.defaultMessage?.includes('Translate to');
