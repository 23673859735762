export const POLARIS_SUB_TYPES = {
	ARCHIVED: 'ARCHIVED',
	ARCHIVED_BY: 'ARCHIVED_BY',
	ARCHIVED_ON: 'ARCHIVED_ON',
	DELIVERY_PROGRESS: 'DELIVERY_PROGRESS',
	DELIVERY_STATUS: 'DELIVERY_STATUS',
	ISSUE_COMMENTS: 'ISSUE_COMMENTS',
	LINKED_ISSUES: 'LINKED_ISSUES',
	NUM_DATA_POINTS: 'NUM_DATA_POINTS',
	ATLAS_GOAL: 'ATLAS_GOAL',
	ATLAS_PROJECT: 'ATLAS_PROJECT',
	ATLAS_PROJECT_STATUS: 'ATLAS_PROJECT_STATUS',
} as const;

// builtin
export const ISSUE_KEY_FIELD_TYPE = 'key'; // issuekey also works.
export const DESCRIPTION_FIELD_TYPE = 'description';
export const ASSIGNEE_FIELD_TYPE = 'assignee';
export const CREATED_FIELD_TYPE = 'created';
export const CREATOR_FIELD_TYPE = 'creator';
export const STATUS_FIELD_TYPE = 'status';
export const SUMMARY_FIELD_TYPE = 'summary';
export const UPDATED_FIELD_TYPE = 'updated';
export const LABELS_FIELD_TYPE = 'labels';
export const ISSUE_TYPE_FIELD_TYPE = 'issuetype';
export const REPORTER_FIELD_TYPE = 'reporter';
export const ISSUE_LINKS_FIELD_TYPE = 'issuelinks';
export const PRIORITY_FIELD_TYPE = 'priority';
export const COMMENT_FIELD_TYPE = 'comment'; // issue comments
export const PROJECT_FIELD_TYPE = 'project';

// jira custom field types
export const SHORT_TEXT_FIELD_TYPE = 'com.atlassian.jira.plugin.system.customfieldtypes:textfield';
export const NUMBER_FIELD_TYPE = 'com.atlassian.jira.plugin.system.customfieldtypes:float';
export const DATE_FIELD_TYPE = 'com.atlassian.jira.plugin.system.customfieldtypes:datepicker';
export const HYPERLINK_FIELD_TYPE = 'com.atlassian.jira.plugin.system.customfieldtypes:url';
export const CUSTOM_LABELS_FIELD_TYPE = 'com.atlassian.jira.plugin.system.customfieldtypes:labels';
export const SINGLE_SELECT_FIELD_TYPE = 'com.atlassian.jira.plugin.system.customfieldtypes:select';
export const MULTI_SELECT_FIELD_TYPE =
	'com.atlassian.jira.plugin.system.customfieldtypes:multicheckboxes';
export const JSW_MULTI_SELECT_FIELD_TYPE =
	'com.atlassian.jira.plugin.system.customfieldtypes:multiselect';
export const PEOPLE_FIELD_TYPE = 'com.atlassian.jira.plugin.system.customfieldtypes:people';
export const JSW_PEOPLE_FIELD_TYPE =
	'com.atlassian.jira.plugin.system.customfieldtypes:multiuserpicker';
export const CF_DEVELOPMENT_FIELD_TYPE =
	'com.atlassian.jira.plugins.jira-development-integration-plugin:devsummarycf';

// jira api only field types
export const EXTERNAL_REFERENCE_FIELD_TYPE = 'jira.polaris:external-reference';
export const DATE_TIME_FIELD_TYPE = 'com.atlassian.jira.plugin.system.customfieldtypes:datetime';

// polaris only types
export const RATING_FIELD_TYPE = 'jira.polaris:rating';
export const SLIDER_FIELD_TYPE = 'jira.polaris:slider';
export const FORMULA_FIELD_TYPE = 'jira.polaris:formula';
export const CHECKBOX_FIELD_TYPE = 'jira.polaris:boolean';
export const INTERVAL_FIELD_TYPE = 'jira.polaris:interval';
export const REACTIONS_FIELD_TYPE = 'jira.polaris:reactions';
export const VOTES_FIELD_TYPE = 'jira.polaris:votes';
export const CONNECTION_FIELD_TYPE = 'jira.polaris:connection';

// Virtual Fields
export const ISSUE_ID_FIELD_TYPE = 'issueid';

// Frontend only: Jira missing type (formula, external-reference, archive...).
export const ATLAS_PROJECT_FIELD_TYPE = 'atlasProject';
export const ATLAS_GOAL_FIELD_TYPE = 'atlasGoal';
export const ATLAS_PROJECT_STATUS_FIELD_TYPE = 'atlasProjectStatus';
export const DELIVERY_PROGRESS_FIELD_TYPE = 'deliveryProgress';
export const LINKED_ISSUES_FIELD_TYPE = 'linkedIssues';
export const ARCHIVED_BY_FIELD_TYPE = 'archivedBy';
export const ARCHIVED_ON_FIELD_TYPE = 'archivedOn';
export const ARCHIVED_FIELD_TYPE = 'archived';
export const ISSUE_COMMENTS_FIELD_TYPE = 'issueComments'; // issue comments count
export const INSIGHTS_FIELD_TYPE = 'numDataPoints';
export const DELIVERY_STATUS_FIELD_TYPE = 'deliveryStatus';

// others
export const RANK_FIELD_TYPE = 'com.pyxis.greenhopper.jira:gh-lexo-rank';
