import type { SupportedFieldProperty } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/types';

const SET_NAME_METHOD = 'setName';
const SET_DESCRIPTION_METHOD = 'setDescription';
const SET_VISIBLE_METHOD = 'setVisible';
const SET_READ_ONLY_METHOD = 'setReadOnly';
const SET_VALUE_METHOD = 'setValue';
const SET_REQUIRED_METHOD = 'setRequired';
const SET_OPTIONS_VISIBILITY_METHOD = 'setOptionsVisibility';

export const propertyToMethodMap = {
	fieldName: SET_NAME_METHOD,
	description: SET_DESCRIPTION_METHOD,
	isVisible: SET_VISIBLE_METHOD,
	isReadOnly: SET_READ_ONLY_METHOD,
	value: SET_VALUE_METHOD,
	isRequired: SET_REQUIRED_METHOD,
	optionsVisibility: SET_OPTIONS_VISIBILITY_METHOD,
} as const;

export const mapFieldPropertyToApiMethod = (fieldProperty: SupportedFieldProperty) => {
	const method = propertyToMethodMap[fieldProperty];

	if (!method) {
		throw new Error(`No method found for field property: ${fieldProperty}`);
	}

	return method;
};
