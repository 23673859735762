import React, { useCallback } from 'react';
import { useFragment, graphql } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { ParentInlineEditView } from '@atlassian/jira-issue-field-parent-inline-edit-full/src/ui/parent/index.tsx';
import type { AggJiraParent } from '@atlassian/jira-issue-field-parent-inline-edit-full/src/ui/parent/types.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import type { ui_issueViewLayoutParentField_IssueViewParentField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutParentField_IssueViewParentField.graphql';
import { ParentFieldHeading } from './heading';

const INLINE_EDIT_CONTAINER_TEST_ID = 'issue-field-parent.ui.inline-edit-field--container';
const CONTAINER_TEST_ID = 'issue-field-parent.ui.read-view-container';

/**
 * Props for `<\{@link IssueViewParentField}/>`
 */
export interface IssueViewParentFieldProps {
	/** Defines which area of the Issue View the field will be displayed */
	area?: Area;
	/** This is a reference to the relay fragment of GraphQL type JiraParentIssueField */
	fragmentKey: ui_issueViewLayoutParentField_IssueViewParentField$key;
}

export const IssueViewParentField = ({ area, fragmentKey }: IssueViewParentFieldProps) => {
	const issueId = useIssueId();
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();

	const data = useFragment<ui_issueViewLayoutParentField_IssueViewParentField$key>(
		graphql`
			fragment ui_issueViewLayoutParentField_IssueViewParentField on JiraParentIssueField {
				...heading_issueViewLayoutParentField_ParentFieldHeading
				...parent_issueFieldParentInlineEditFull_ParentInlineEditView_fragmentRef
				fieldId
				type
				__typename
			}
		`,
		fragmentKey,
	);

	const onSubmit = useCallback(
		(value: AggJiraParent | null) => {
			issueId &&
				fieldChangeRequested(issueId, data.fieldId, value, undefined, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChangeRequested, issueId],
	);

	const onSubmitSucceeded = useCallback(
		(value: AggJiraParent | null) => {
			issueId &&
				fieldChanged(issueId, data.fieldId, value, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChanged, issueId],
	);

	const onSubmitFailed = useCallback(
		() => issueId && fieldChangeFailed(issueId, data.fieldId),
		[data.fieldId, fieldChangeFailed, issueId],
	);

	return (
		<Box testId={INLINE_EDIT_CONTAINER_TEST_ID}>
			<Box testId={CONTAINER_TEST_ID}>
				<ParentFieldHeading
					area={area}
					field={data}
					testId={`issue.issue-view-layout.issue-view-parent-field.${data.fieldId}`}
				>
					<Box xcss={fieldWrapperStyles}>
						<ParentInlineEditView
							fragmentRef={data}
							onSubmit={onSubmit}
							onSubmitSucceeded={onSubmitSucceeded}
							onSubmitFailed={onSubmitFailed}
						/>
					</Box>
				</ParentFieldHeading>
			</Box>
		</Box>
	);
};

const fieldWrapperStyles = xcss({
	marginLeft: 'space.negative.100',
	marginRight: 'space.100',
});
