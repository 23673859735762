import type { ProjectType } from '@atlassian/jira-common-constants';
import { fireIAErrorAnalytics } from '@atlassian/jira-ui-modifications-analytics/src/common/utils/error-analytics/index.tsx';
import type {
	ProjectContext,
	ViewType,
} from '@atlassian/jira-ui-modifications-types/src/common/types/context.tsx';
import { getViewConfiguration } from './views';
import type { FieldType } from './views/types.tsx';

const isKeyOf = <T extends Record<string, unknown>>(key: keyof T, object: T): key is keyof T =>
	object[key] !== undefined;

const getConfigurationByViewType = ({ viewType }: { viewType: ViewType }) => {
	const viewConfiguration = getViewConfiguration(viewType);

	if (!viewConfiguration) {
		throw new Error(`Unsupported view type ${viewType} in getConfigurationByViewType.`);
	}

	return viewConfiguration;
};

export function getSupportedFieldConfiguration(viewType: ViewType, fieldType: FieldType) {
	const fieldsConfiguration = getConfigurationByViewType({
		viewType,
	}).getSupportedFieldsConfiguration();

	if (!isKeyOf(fieldType, fieldsConfiguration)) {
		fireIAErrorAnalytics({
			error: new Error('Unsupported field type.'),
			id: 'getSupportedFieldConfiguration',
			viewType,
			attributes: {
				fieldType,
			},
		});

		return undefined;
	}

	return fieldsConfiguration[fieldType];
}

export const getSupportedFieldTypes = (viewType: ViewType) =>
	getConfigurationByViewType({ viewType }).getSupportedFieldTypes();

export const getSupportedProjectTypes = (viewType: ViewType): ProjectType[] =>
	getConfigurationByViewType({ viewType }).getSupportedProjectTypes();

export function isFieldTypeSupported(
	viewType: ViewType,
	fieldType: FieldType | undefined,
): fieldType is FieldType {
	return (
		fieldType !== undefined &&
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		(getSupportedFieldTypes(viewType) as string[]).includes(fieldType)
	);
}

export function getConsentMessage(viewType: ViewType) {
	return getConfigurationByViewType({ viewType }).getConsentMessage();
}

export function isProjectTypeSupported(project: ProjectContext, viewType: ViewType) {
	return getSupportedProjectTypes(viewType).includes(project.type);
}

export function getAnalytics(viewType: ViewType) {
	return getConfigurationByViewType({ viewType }).getAnalytics();
}

export function getSupportedTriggerPointsConfiguration(viewType: ViewType): Set<string> {
	return getConfigurationByViewType({ viewType }).getSupportedTriggerPointsConfiguration();
}

export const isTriggerPointSupported = (
	viewType: ViewType,
	triggerPoint: string | undefined,
): boolean =>
	triggerPoint !== undefined && getSupportedTriggerPointsConfiguration(viewType).has(triggerPoint);

export const isScreenTabsSupportEnabled = (viewType: ViewType): boolean =>
	getConfigurationByViewType({ viewType }).isScreenTabsSupportEnabled();
