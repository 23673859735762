/** @jsx jsx */
import React from 'react';
import { jsx } from '@compiled/react';
import Button from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import Image from '@atlaskit/image';
import { Box, Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import illustrationDark from '../assets/illustration-dark.svg';
import illustration from '../assets/illustration.svg';
import { messages } from './messages';

const PinFieldDiscoverabilityBanner = ({
	onButtonClick,
	onBannerDismiss,
}: {
	onButtonClick: () => void;
	onBannerDismiss: () => void;
}) => {
	const { formatMessage } = useIntl();
	return (
		<Box xcss={pinFieldDiscoverabilityButton} tabIndex={0}>
			<Stack space="space.150">
				<Image
					src={illustration}
					srcDark={illustrationDark}
					alt={formatMessage(messages.altText)}
				/>
				<Stack space="space.050">
					<Heading size="xsmall">{formatMessage(messages.heading)}</Heading>
					<Text size="medium">{formatMessage(messages.content)}</Text>
					<Inline xcss={mainStyles} space="space.050" alignInline="end">
						<Button
							appearance="subtle"
							onClick={onBannerDismiss}
							aria-label={formatMessage(messages.dismiss)}
						>
							{formatMessage(messages.dismiss)}
						</Button>
						<Button onClick={onButtonClick} aria-label={formatMessage(messages.pinFields)}>
							{formatMessage(messages.pinFields)}
						</Button>
					</Inline>
				</Stack>
			</Stack>
		</Box>
	);
};
export default PinFieldDiscoverabilityBanner;

const pinFieldDiscoverabilityButton = xcss({
	paddingBottom: 'space.050',
	paddingTop: 'space.050',
});

const mainStyles = xcss({
	marginTop: 'space.100',
});
