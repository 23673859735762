import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { isIssueDropzoneAndClipboardUploadEnabled } from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/attachment-quick-upload-selector';
import { CommonDropzoneMediaPicker } from './view';

export default connect(
	(state: State) => ({
		isEnabled: isIssueDropzoneAndClipboardUploadEnabled(state),
	}),
	{},
)(CommonDropzoneMediaPicker);
