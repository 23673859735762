import React, { type ChangeEvent, useCallback, useState, useRef, useMemo } from 'react';
import { css, styled } from '@compiled/react';
import { useFragment, graphql, useMutation } from 'react-relay';
import { useAnalyticsEvents, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import { fontSize, gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { SingleLineTextEditView as UrlEditView } from '@atlassian/jira-issue-field-single-line-text-editview-full/src/ui/single-line-text/index.tsx';
import { UrlReadView } from '@atlassian/jira-issue-field-url-readview-full/src/ui/url/index.tsx';
import { fireTrackAnalytics, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type {
	url_issueFieldUrl_UrlField_Mutation as UrlMutation,
	url_issueFieldUrl_UrlField_Mutation$data as UrlMutationData,
	url_issueFieldUrl_UrlField_Mutation$rawResponse as UrlMutationResponse,
} from '@atlassian/jira-relay/src/__generated__/url_issueFieldUrl_UrlField_Mutation.graphql';
import type { url_issueFieldUrlInlineEditFull_UrlInlineEditView$key as UrlFragment } from '@atlassian/jira-relay/src/__generated__/url_issueFieldUrlInlineEditFull_UrlInlineEditView.graphql';
import messages from './messages';
import type { ErrorsType, UrlInlineEditViewProps } from './types';
import { validationForErrors, isAllowed } from './utils';

export const UrlInlineEditView = ({
	fragmentRef,
	isEditing: startWithEditViewOpen = false,
	readViewFitContainerWidth = true,
	onCancel,
	onEdit,
	onEscape,
	onConfirm,
	onEnter,
	onUpdate,
	onUpdateFailed,
}: UrlInlineEditViewProps) => {
	const { formatMessage } = useIntl();

	// #region Relay
	const data = useFragment<UrlFragment>(
		graphql`
			fragment url_issueFieldUrlInlineEditFull_UrlInlineEditView on JiraUrlField {
				id
				name
				fieldId
				type
				uri
				...url_issueFieldUrlReadviewFull_UrlReadView
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);

	const { id: uniqueFieldId, uri, fieldConfig, name, fieldId, type } = data;

	const [commit] = useMutation<UrlMutation>(graphql`
		mutation url_issueFieldUrl_UrlField_Mutation($input: JiraUpdateUrlFieldInput!)
		@raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateUrlField(input: $input) {
					success
					errors {
						message
					}
					field {
						...url_issueFieldUrlInlineEditFull_UrlInlineEditView
					}
				}
			}
		}
	`);
	// #endregion

	// #region Common state
	const { overriding } = useInlineEditFieldInjections();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const editFieldRef = useRef<HTMLInputElement>(null);
	const [isEditing, setIsEditing] = useState<boolean>(startWithEditViewOpen);
	const [updatedValue, setUpdatedValue] = useState<string | null>(uri || '');
	const [errors, setErrors] = useState<ErrorsType>(null);
	const [invalidMessage, setInvalidMessage] = useState<string | null>(null);
	const [showValidationMessage, setShowValidationMessage] = useState(false);

	const isFieldEditable = useMemo(
		() => overriding.overrideIsEditable(fieldConfig?.isEditable || false),
		[fieldConfig, overriding],
	);

	const analyticsData = useMemo(() => ({ fieldKey: fieldId, fieldType: type }), [fieldId, type]);
	// #endregion

	// #region Handle new value
	const save = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			/**
			 * This will execute the GraphQl mutation.
			 */
			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operation: {
							uri: updatedValue || '',
							operation: 'SET',
						},
					},
				},
				onCompleted(mutationData: UrlMutationData) {
					if (mutationData.jira?.updateUrlField) {
						const { success, errors: responseErrors } = mutationData.jira?.updateUrlField;
						if (success) {
							onUpdate?.(updatedValue || '');
							// Field updated event is a core action used to track MCU as defined in https://hello.atlassian.net/wiki/spaces/ANALYTICS/pages/3767029088/Monthly+Core+User+MCU+Definition
							fireTrackAnalytics(createAnalyticsEvent({}), 'field updated', analyticsData);
							return;
						}
						setErrors(responseErrors);
					} else {
						setErrors([]);
					}
					setIsEditing(true);
				},
				onError(error) {
					setErrors([{ message: error.message }]);
					onUpdateFailed?.(updatedValue || '');
					setIsEditing(true);
				},
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				optimisticResponse: {
					jira: {
						updateUrlField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								uri: updatedValue,
								fieldConfig: {
									isEditable: true,
								},
							},
						},
					},
				} as UrlMutationResponse,
			});
			setIsEditing(false);
			fireUIAnalytics(analyticsEvent);
		},
		[
			commit,
			uniqueFieldId,
			updatedValue,
			onUpdate,
			onUpdateFailed,
			analyticsData,
			createAnalyticsEvent,
		],
	);
	// #endregion

	// #region Common callbacks
	const cancel = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setErrors(null);
			setIsEditing(false);
			// @ts-expect-error - Argument of type 'string | null | undefined' is not assignable to parameter of type 'SetStateAction<string | null>'.
			setUpdatedValue(uri);
			fireUIAnalytics(analyticsEvent);
		},
		[setErrors, uri],
	);

	const onCancelRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setShowValidationMessage(false);
			setInvalidMessage(null);
			cancel(analyticsEvent);
			onCancel && onCancel(analyticsEvent);
		},
		[onCancel, cancel],
	);

	const onConfirmRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			if (invalidMessage) {
				setShowValidationMessage(true);
				editFieldRef.current?.focus();
			} else {
				save(analyticsEvent);
				onConfirm && onConfirm(analyticsEvent, updatedValue);
			}
		},
		[onConfirm, save, invalidMessage, editFieldRef, updatedValue],
	);

	const onEditRequested = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setIsEditing(true);
			fireUIAnalytics(analyticsEvent);
			onEdit && onEdit(analyticsEvent);
		},
		[onEdit],
	);

	const onEscapeRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setShowValidationMessage(false);
			setInvalidMessage(null);
			cancel(analyticsEvent);
			onEscape && onEscape(analyticsEvent);
		},
		[onEscape, cancel],
	);

	const onEnterRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent);
			onEnter && onEnter(analyticsEvent);
		},
		[onEnter],
	);

	const onChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			setShowValidationMessage(false);
			let { validationMessage } = event.target;
			const typedValue = event.target.value === '' ? null : event.target.value;
			if (!isAllowed(typedValue)) {
				validationMessage = formatMessage(messages.validUrl);
			}
			setInvalidMessage(validationMessage);
			setErrors(null);
			setUpdatedValue(typedValue);
		},
		[setErrors, setUpdatedValue, formatMessage],
	);
	// #endregion

	// #region Read view
	const renderReadView = () => (
		<ReadViewContainer data-testid="issue-field-url-inline-edit-full.ui.url.read-view">
			<UrlReadView fragmentRef={data} />
		</ReadViewContainer>
	);
	// #endregion

	// #region Edit view
	const renderEditView = () => (
		<>
			<EditViewContainer data-testid="issue-field-url-inline-edit-full.ui.url.edit-view">
				<UrlEditView
					ariaLabel={name}
					autoFocus
					type="url"
					ref={editFieldRef}
					value={updatedValue}
					onChange={onChange}
					invalidMessage={validationForErrors(
						showValidationMessage,
						invalidMessage,
						// @ts-expect-error - Argument of type 'readonly { readonly message: string | null | undefined; }[] | null | undefined' is not assignable to parameter of type 'typeOfErrors | undefined'.
						errors,
					)}
				/>
			</EditViewContainer>
		</>
	);
	// #endregion

	return (
		<InlineEditContainer isEditable={isFieldEditable}>
			<FieldInlineEditStateLess
				editView={renderEditView}
				isEditable={isFieldEditable}
				isEditing={isEditing}
				isLabelHidden
				readView={renderReadView}
				readViewFitContainerWidth={readViewFitContainerWidth}
				testId="issue-field-url-inline-edit-full.ui.url.field-inline-edit-state-less"
				onCancel={onCancelRequest}
				onConfirm={onConfirmRequest}
				onEdit={onEditRequested}
				onEnter={onEnterRequest}
				onEscape={onEscapeRequest}
				defaultValue={updatedValue}
			/>
		</InlineEditContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditViewContainer = styled.div({
	zIndex: 300,
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled.div({
	display: 'flex',
	flex: '1 1 auto',
	wordBreak: 'break-word',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	lineHeight: (gridSize * 2.5) / fontSize,
	paddingLeft: token('space.075', '6px'),
	width: '100%',
});

const nonEditableStyles = css({
	display: 'flex',
	flex: '1 1 auto',
	wordBreak: 'break-word',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	lineHeight: (gridSize * 2.5) / fontSize,
	paddingLeft: token('space.075', '6px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineEditContainer = styled.div<{ isEditable: boolean }>(
	{
		width: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& div[data-read-view-fit-container-width]': {
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			minHeight: '32px',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditable }) => (!isEditable ? nonEditableStyles : undefined),
);
