import { toQuotedString } from '@atlassian/jira-common-jql/src/helpers';
import type { IntlShapeV2 as Intl } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { ServerSuggestions } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types';
import type {
	LabelOptionDeprecated,
	LabelSuggestion,
	LabelsValue,
} from '@atlassian/jira-issue-shared-types/src/common/types/labels-type.tsx';
import type { TransformerContext } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import type {
	Label,
	LabelsSuggestionList,
} from '@atlassian/jira-shared-types/src/rest/jira/label.tsx';
import messages from './messages';

export const getJqlFieldId = (fieldId: string): string => {
	const customFieldPrefix = 'customfield_';
	if (fieldId.startsWith(customFieldPrefix)) {
		const customId = fieldId.substring(customFieldPrefix.length, fieldId.length);
		return `cf[${customId}]`;
	}
	return fieldId;
};

export const transformFromStateValue = (
	value: LabelsValue,
	{ baseUrl, fieldId }: TransformerContext,
): LabelOptionDeprecated[] => {
	// Label custom fields can have a null value
	if (!value) {
		return [];
	}
	return value.map((item) => {
		const jqlEncodedValue = encodeURIComponent(
			`${getJqlFieldId(fieldId)} = ${toQuotedString(item)}`,
		);
		return {
			content: item,
			value: item,
			href: `${baseUrl}/issues/?jql=${jqlEncodedValue}`,
		};
	});
};

export const transformToStateValue = (value: LabelOptionDeprecated[]): Label[] =>
	value.map((item) => item.value);

const transformItem = (suggestion: LabelSuggestion) => ({
	content: suggestion.label,
	value: suggestion.label,
});

export const transformToCacheItem = (label: string): LabelSuggestion => ({
	label,
});

const MAX_RESULTS = 5;

export const transformCacheEntries = (cache: LabelSuggestion[]): LabelSuggestion[] =>
	cache.slice(-1 * MAX_RESULTS).reverse();

export const transformSuggestionsFromServerAndCache = (
	response: LabelsSuggestionList,
	cache: LabelSuggestion[] = [],
	formatMessage: Intl['formatMessage'],
): ServerSuggestions => {
	const serverItems = response.suggestions || [];
	const cacheLists = transformCacheEntries(cache);

	return [
		{
			heading: formatMessage(messages.recent),
			items: cacheLists.map(transformItem),
		},
		{
			heading: formatMessage(messages.all),
			items: serverItems.map(transformItem),
		},
	];
};
