/**
 * @generated SignedSource<<0e5e2f5aac518fb2be7b59870e1af464>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutComponentsField_IssueViewComponentsField$data = {
  readonly __typename: "JiraComponentsField";
  readonly components: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly ari: string | null | undefined;
        readonly componentId: string;
        readonly description: string | null | undefined;
        readonly id: string;
        readonly metadata: AGG$JSON | null | undefined;
        readonly name: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly description: string | null | undefined;
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly fieldId: string;
  readonly id: string;
  readonly issue: {
    readonly key: string;
  } | null | undefined;
  readonly name: string;
  readonly selectedComponentsConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly ari: string | null | undefined;
        readonly componentId: string;
        readonly description: string | null | undefined;
        readonly id: string;
        readonly metadata: AGG$JSON | null | undefined;
        readonly name: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentType": "ui_issueViewLayoutComponentsField_IssueViewComponentsField";
};
export type ui_issueViewLayoutComponentsField_IssueViewComponentsField$key = {
  readonly " $data"?: ui_issueViewLayoutComponentsField_IssueViewComponentsField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutComponentsField_IssueViewComponentsField">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "name"
},
v2 = {
  "kind": "ScalarField",
  "name": "description"
},
v3 = [
  {
    "concreteType": "JiraComponentEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "JiraComponent",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "ScalarField",
            "name": "componentId"
          },
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "name": "ari"
          },
          {
            "kind": "ScalarField",
            "name": "metadata"
          }
        ]
      }
    ]
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutComponentsField_IssueViewComponentsField",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    },
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "issue",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "key"
        }
      ]
    },
    {
      "concreteType": "JiraComponentConnection",
      "kind": "LinkedField",
      "name": "selectedComponentsConnection",
      "plural": false,
      "selections": (v3/*: any*/)
    },
    {
      "concreteType": "JiraComponentConnection",
      "kind": "LinkedField",
      "name": "components",
      "plural": false,
      "selections": (v3/*: any*/)
    }
  ],
  "type": "JiraComponentsField"
};
})();

(node as any).hash = "2239cfda869e117e03a9b3414fcd5373";

export default node;
