import React, { forwardRef, type Ref } from 'react';
import type { EntryPointProps } from 'react-relay';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import TextField from '@atlaskit/textfield';
import { Y300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { NUMBER_WARNING_ICON_TEST_ID } from '../../common/constants';
import { Container, WarningWrapper } from './styled';
import type { NumberEditViewProps } from './types';

export const NumberEditView = forwardRef(
	(props: NumberEditViewProps, ref: Ref<HTMLInputElement>) => {
		const {
			defaultValue,
			isInvalid = false,
			autoFocus = true,
			isDisabled = false,
			onWheel,
			onChange,
			onBlur,
			ariaLabelledBy,
			label,
			spacing,
		} = props;

		const value = defaultValue == null || defaultValue === '' ? '' : Number(defaultValue);

		return (
			<Container>
				<TextField
					ref={ref}
					aria-labelledby={ariaLabelledBy}
					aria-label={label}
					autoFocus={autoFocus}
					isCompact={spacing === 'compact'}
					isDisabled={isDisabled}
					isInvalid={isInvalid}
					step="any"
					type="number"
					value={value}
					onWheel={onWheel}
					elemAfterInput={
						isInvalid === true ? (
							<WarningWrapper data-testid={NUMBER_WARNING_ICON_TEST_ID}>
								<WarningIcon
									label=""
									primaryColor={token('color.icon.warning', Y300)}
									size="medium"
								/>
							</WarningWrapper>
						) : null
					}
					onChange={onChange}
					onBlur={fg('uim_its_post_blitz_minor_changes') ? onBlur : undefined}
				/>
			</Container>
		);
	},
);

const NumberEditViewEntryPoint = ({ props }: EntryPointProps<{}, {}, NumberEditViewProps, {}>) => (
	<NumberEditView {...props} />
);

export default NumberEditViewEntryPoint;
