import { useState, useCallback, useMemo } from 'react';
import debounce from 'lodash/debounce';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { ServerResponse, FilteredSpaces, Props } from './types';
import { filteredResponse, getSpaceUrl, transformSpaces } from './utils';

export const DEBOUNCE_TIME = 300;

const useConfluenceSpaceService = (props: Props) => {
	const { renderOptionsLimit = 25, appId } = props;

	const [loading, setLoading] = useState<boolean>(false);
	const [spaces, setSpaces] = useState<FilteredSpaces>({
		filtered: [],
		overall: [],
	});
	const [error, setError] = useState<Error | null>(null);
	const [isFilteredResult, setIsFilteredResult] = useState<boolean>(false);

	const fetchSpaces = useCallback(async () => {
		try {
			setLoading(true);
			const response: ServerResponse = await performGetRequest(getSpaceUrl(appId));
			if (response.spaces) {
				const transformedSpaces = transformSpaces(response.spaces);
				setSpaces(filteredResponse(transformedSpaces, renderOptionsLimit));
			}
			setLoading(false);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			setLoading(false);
			fireErrorAnalytics({
				error: err,
				meta: {
					id: 'getConfluenceSpaceService',

					packageName: 'jiraIssueView',
					teamName: 'bento',
				},
				sendToPrivacyUnsafeSplunk: true,
			});
			setError(err);
		}
	}, [appId, renderOptionsLimit]);

	const filterSpaces = useMemo(
		() =>
			debounce(
				(query: string) => {
					const { overall } = spaces;
					setIsFilteredResult(query !== '');
					setSpaces(filteredResponse(overall, renderOptionsLimit, query));
				},
				DEBOUNCE_TIME,
				{ leading: false },
			),
		[renderOptionsLimit, spaces],
	);

	return [
		{ loading, spaces, error, isFilteredResult },
		{ fetchSpaces, filterSpaces },
	] as const;
};

export default useConfluenceSpaceService;
