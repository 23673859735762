import React, { useCallback } from 'react';
import { ButtonItem } from '@atlaskit/menu';
import type { Props } from './types';

const LogWorkButtonItem = ({
	onLogTimeClick,
	onClick,
	label,
	setDropdownItemRef,
	itemKey,
	isSelected = false,
}: Props) => {
	const handleClick = useCallback(() => {
		onLogTimeClick();
		onClick?.(itemKey);
	}, [onLogTimeClick, onClick, itemKey]);

	return (
		<ButtonItem
			key="LogTimeActionView"
			onClick={handleClick}
			isSelected={isSelected}
			ref={setDropdownItemRef}
			role="menuitem"
		>
			{label}
		</ButtonItem>
	);
};

export default LogWorkButtonItem;
