import { type ReactNode, createElement } from 'react';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { JiraAppLink } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import {
	getAllIssuesHistoryUrl,
	getUnresolvedIssueHistoryUrlForLinking,
	getRemoteUnresolvedIssueHistoryUrlForLinking,
	getRemoteAllIssuesHistoryUrl,
} from '@atlassian/jira-issue-view-services/src/issue/issue-urls';
import {
	toIssueKey,
	type BaseUrl,
	type IssueKey,
	toBaseUrl,
} from '@atlassian/jira-shared-types/src/general.tsx';

const JIRA_ISSUE_KEY_REGEX = /^([A-Z][A-Z0-9]+)-([0-9]+)$/i;

const looksLikeIssueKey = (query: string) => {
	if (query) {
		const parsedGroups = JIRA_ISSUE_KEY_REGEX.exec(query);
		return !!(parsedGroups && parsedGroups.length === 3);
	}
	return false;
};

export type IssueLinkSuggestion = {
	content: string;
	id: string;
	value: string;
	elemBefore: ReactNode;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fullObject: any;
	filterValues: string[];
	iconUrl: string;
	appLinkId: string | undefined;
};

type Issue = {
	id: number;
	key: string;
	keyHtml: string;
	img: string;
	summary: string;
	summaryText: string;
};

type Section = {
	id: string;
	issues: Issue[];
	label?: string;
	msg?: string;
	sub?: string;
};

type IssueHistorySearchResults = {
	sections: Section[];
};

const transformIssueSuggestionItems =
	(filterValue: string, remoteAppLink?: JiraAppLink) =>
	// @ts-expect-error - TS2741 - Property 'sections' is missing in type '{}' but required in type 'IssueHistorySearchResults'.
	({ sections = [] }: IssueHistorySearchResults = {}): IssueLinkSuggestion[] => {
		const { baseUrl = toBaseUrl(''), id: appLinkId } = remoteAppLink || {};
		return sections
			.map((section) => section.issues)
			.filter((issues) => !!issues) // eslint-disable-next-line @typescript-eslint/no-explicit-any
			.reduce<Array<any>>(
				(flatten, current) =>
					flatten.concat(
						current.map((issue) => ({
							content: `${issue.key} ${issue.summaryText}`,
							value: issue.key,
							id: issue.id ? issue.id.toString() : '',
							elemBefore: createElement('img', {
								src: `${baseUrl}${issue.img}`,
								width: 16,
								height: 16,
							}),
							fullObject: issue,
							// Allows us to force all items returned by the endpoint to be displayed.
							filterValues: [filterValue],
							iconUrl: `${baseUrl}${issue.img}`,
							appLinkId,
						})),
					),
				[],
			) // eslint-disable-next-line @typescript-eslint/no-explicit-any
			.reduce<Array<any>>(
				(uniq, current) =>
					uniq.findIndex((item) => item.value === current.value) < 0
						? // eslint-disable-next-line jira/js/no-reduce-accumulator-spread
							[...uniq, current]
						: uniq,
				[],
			);
	};

const fetchMatchingIssues = (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	queryIssueKey: IssueKey,
	remoteAppLink?: JiraAppLink,
): Observable<IssueHistorySearchResults> => {
	const queryUrl =
		remoteAppLink === undefined
			? getAllIssuesHistoryUrl(baseUrl, issueKey, queryIssueKey)
			: getRemoteAllIssuesHistoryUrl(baseUrl, queryIssueKey, remoteAppLink.id);
	return fetchJson$(queryUrl);
};

export const fetchPossibleIssues = (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	queryIssueKey: string,
	remoteAppLink?: JiraAppLink,
): Observable<IssueLinkSuggestion[]> => {
	if (looksLikeIssueKey(queryIssueKey)) {
		return fetchMatchingIssues(baseUrl, issueKey, toIssueKey(queryIssueKey), remoteAppLink)
			.map(transformIssueSuggestionItems(queryIssueKey, remoteAppLink))
			.catch(() => Observable.of([]));
	}
	return Observable.of([]);
};

export const fetchIssueSuggestions = (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	query: string,
	remoteAppLink?: JiraAppLink,
): Observable<IssueLinkSuggestion[]> => {
	const queryUrl =
		remoteAppLink === undefined
			? getUnresolvedIssueHistoryUrlForLinking(baseUrl, issueKey, query)
			: getRemoteUnresolvedIssueHistoryUrlForLinking(baseUrl, query, remoteAppLink.id);

	return fetchJson$<IssueHistorySearchResults>(queryUrl).map(
		transformIssueSuggestionItems(query, remoteAppLink),
	);
};
