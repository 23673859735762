import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import type { singleSelect_issueFieldSingleSelectReadviewFull_SingleSelectReadView$key as SingleSelectReadViewFragment } from '@atlassian/jira-relay/src/__generated__/singleSelect_issueFieldSingleSelectReadviewFull_SingleSelectReadView.graphql';
import type { SingleSelectReadViewProps } from './types';

/**
 * The SingleSelectReadView will show a read only view of SingleSelect field.
 * @param props {@link SingleSelectReadViewProps}
 */
export const SingleSelectReadView = (props: SingleSelectReadViewProps) => {
	const { fragmentRef } = props;

	const data = useFragment<SingleSelectReadViewFragment>(
		graphql`
			fragment singleSelect_issueFieldSingleSelectReadviewFull_SingleSelectReadView on JiraSingleSelectField {
				fieldOption {
					value
				}
			}
		`,
		fragmentRef,
	);

	if (!data.fieldOption?.value) {
		return <EmptyFieldText />;
	}

	return <>{data.fieldOption.value}</>;
};
