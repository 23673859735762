import React, { useCallback } from 'react';
import flow from 'lodash/flow';
import { useFragment, graphql } from 'react-relay';
import { useAnalyticsEvents, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-track-event.tsx';
import { log } from '@atlassian/jira-common-util-logging';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import {
	START_WATCHING_SUCCESS,
	STOP_WATCHING_SUCCESS,
	TOGGLE_WATCHING_ERROR,
} from '@atlassian/jira-issue-view-common-constants';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import type { keyboardShortcuts_issueViewWatchers_KeyboardShortcutsRelay$key } from '@atlassian/jira-relay/src/__generated__/keyboardShortcuts_issueViewWatchers_KeyboardShortcutsRelay.graphql';
import { useOnShowFlag } from '../../../controllers/watchers-context/index.tsx';
import { useWatchesMutation } from '../../../services/use-watches-mutation/index.tsx';
import type { State } from '../../model/types.tsx';
import { toggleWatchingRequest } from '../../state/actions';
import { getIsWatching } from '../../state/selectors';
import { SHORTCUT_SOURCE } from '../../state/types';
import { KeyboardShortcuts } from './view';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { TOGGLE_WATCHING_SHORTCUT } from '@atlassian/jira-issue-view-common-constants/src/watchers.tsx';
type DispatchProps = {
	onToggleWatching: (
		onSuccess: (source: string, experience: string) => void,
		onError: (source: string, experience: string) => void,
		analyticsEvent: UIAnalyticsEvent,
	) => void;
};

export const KeyboardShortcutsOld = flow(
	ComponentWithAnalytics('watchers', {
		onToggleWatching: 'onToggleWatching',
	}),
	connect(
		(state: State) => ({
			isWatching: getIsWatching(state),
		}),
		(dispatch): DispatchProps => ({
			onToggleWatching: (
				onSuccess: (source: string, experience: string) => void,
				onError: (source: string, experience: string) => void,
				analyticsEvent: UIAnalyticsEvent,
			) =>
				dispatch(
					toggleWatchingRequest({
						source: SHORTCUT_SOURCE,
						analyticsEvent,
						onSuccess,
						onError,
					}),
				),
		}),
	),
)(KeyboardShortcuts);

type Props = {
	watches: keyboardShortcuts_issueViewWatchers_KeyboardShortcutsRelay$key;
};
const KeyboardShortcutsRelay = ({ watches }: Props) => {
	// eslint-disable-next-line @atlassian/relay/query-restriction
	const data = useFragment<keyboardShortcuts_issueViewWatchers_KeyboardShortcutsRelay$key>(
		graphql`
			fragment keyboardShortcuts_issueViewWatchers_KeyboardShortcutsRelay on JiraWatchesField {
				id
				type
				fieldId
				watch {
					isWatching
					count
					...useWatchesMutation_Mutation_Updatable
				}
			}
		`,
		watches,
	);

	const { onUpdate } = useWatchesMutation();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { onShowFlag } = useOnShowFlag();

	const onToggleWatching = useCallback(
		(
			onSuccess: (source: string, experience: string) => void,
			onError: (source: string, experience: string) => void,
		) => {
			const isWatching = !data?.watch?.isWatching;
			const source = SHORTCUT_SOURCE;

			fireTrackAnalytics(
				createAnalyticsEvent({
					actionSubject: 'watchers',
				}),
				{
					actionSubjectId: 'watchersToggleWatching',
					action: isWatching ? 'startWatching' : 'stopWatching',
					attributes: {
						source,
					},
				},
			);

			const handleError = (error?: Error) => {
				onShowFlag?.(TOGGLE_WATCHING_ERROR);
				const err = error instanceof Error ? error : new Error('Error updating watchers');

				log.safeErrorWithoutCustomerData(
					isWatching ? 'issue.watchers.watch' : 'issue.watchers.unwatch',
					err.message,
					err,
				);
				onError?.(source, isWatching ? 'startWatching' : 'stopWatching');
			};

			const handleSuccess = () => {
				onSuccess?.(source, 'success');
				isWatching ? onShowFlag?.(START_WATCHING_SUCCESS) : onShowFlag?.(STOP_WATCHING_SUCCESS);
			};
			onUpdate({
				id: data?.id,
				isWatching,
				onSuccess: handleSuccess,
				onError: handleError,
				watch: data.watch,
				field: {
					type: data.type,
					fieldId: data.fieldId,
					watch: {
						count: data.watch?.count,
						isWatching: data.watch?.isWatching,
					},
				},
			});
		},
		[createAnalyticsEvent, data, onShowFlag, onUpdate],
	);
	return (
		<KeyboardShortcuts
			isWatching={data?.watch?.isWatching || false}
			onToggleWatching={onToggleWatching}
		/>
	);
};

const KeyboardShortcutsNew = flow(
	ComponentWithAnalytics('watchers', {
		onToggleWatching: 'onToggleWatching',
	}),
)(KeyboardShortcutsRelay);

const KeyboardShortcutConnected: (props: Props) => React.ReactNode = componentWithCondition(
	() => ff('relay-migration-issue-fields-watchers_iqhn4'),
	KeyboardShortcutsNew,
	KeyboardShortcutsOld,
);

export default KeyboardShortcutConnected;
