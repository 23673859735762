import React from 'react';

import { bind } from 'bind-event-listener';

type Props = {
	userInput?: string;
	onCancel: () => void;
	onDiscard: () => void;
	modalRef: React.RefObject<HTMLDivElement>;
};

/**
 * If the user clicks outside of the modal
 * - if they have not entered any text we end the experience
 * - if they have entered text we move them to the discard screen
 * - if they click on the feedback form we do nothing
 */
export const useOutsideClickHandler = ({ userInput, onCancel, onDiscard, modalRef }: Props) => {
	React.useEffect(() => {
		if (!modalRef) {
			return;
		}

		const handleOutsideClick = (event: MouseEvent) => {
			// If the click is not inside the editor, do nothing
			// This is to prevent the user from accidentally closing the modal when they are trying to interact with other modals
			// or other elements on the page
			const editor = (event.target as HTMLElement).closest('.akEditor');
			if (!editor) {
				return;
			}

			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				if (!userInput) {
					onCancel();
				} else {
					onDiscard();
				}
			}
		};

		const unbind = bind(document, {
			type: 'mousedown',
			listener: handleOutsideClick,
		});

		return unbind;
	}, [userInput, onCancel, onDiscard, modalRef]);
};
