import React, { Component } from 'react';
import type { Dispatch } from 'redux';
import flow from 'lodash/flow';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import OpTrigger from '@atlassian/jira-common-util-op-triggers/src/on-mount-and-update';
import { FlagServiceSubscriber as FlagServiceSubscriberComponent } from '@atlassian/jira-flags';
import type { FlagDetails } from '@atlassian/jira-issue-view-common-types/src/flag-type';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { safeComponent } from '@atlassian/jira-issue-view-common-utils/src/safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { dismissFlag } from '@atlassian/jira-issue-view-store/src/actions/flag-actions';
import { deleteIssueRequest } from '@atlassian/jira-issue-view-store/src/actions/issue-delete-actions';
import { issueKeySelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { flagsSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { flagMessages } from './messages';

type Props = {
	flags: FlagDetails[];
	retryIssueDelete?: () => void;
	deleteFailureIssueKey?: IssueId;
	FlagServiceSubscriber: typeof FlagServiceSubscriberComponent;
	externalId?: string;
	onFlagDismissed: (id: number) => void;
	openComment: (flag: FlagDetails) => void;
};

// eslint-disable-next-line jira/react/no-class-components
export class UnsafeFlags extends Component<Props> {
	static displayName = 'Flags';

	onFlagDismissed = (flagId: number) => {
		const { onFlagDismissed } = this.props;
		onFlagDismissed(flagId);
	};

	render() {
		const {
			onFlagDismissed,
			flags,
			retryIssueDelete,
			deleteFailureIssueKey,
			FlagServiceSubscriber,
			openComment,
		} = this.props;

		return (
			<FlagServiceSubscriber>
				{({ showFlag }) =>
					// @ts-expect-error - TS2322 - Type 'Element[]' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'.
					flags.map((flag) => {
						const flagDetails = flagMessages[flag.type];
						const title = flag.titleI18nValues
							? [flagDetails.messages.title, flag.titleI18nValues]
							: flagDetails.messages.title;

						const description =
							flag.descriptionI18nValues && flagDetails.messages.description
								? [flagDetails.messages.description, flag.descriptionI18nValues]
								: flagDetails.messages.description;

						const actions = (flagDetails.actions || []).map(({ messages, onClick }) => ({
							content: messages.content,
							onClick: () =>
								onClick({
									closeFlag: () => onFlagDismissed(flag.id),
									retryIssueDelete,
									deleteFailureIssueKey,
									openComment: () => openComment(flag),
								}),
						}));

						const flagArgs = {
							/*
							 * We can't provide Bento's legacy flag ids as they are strings,
							 * and the flag app expects numbers. By not providing an id, the
							 * flags app will automatically generate one and handle it for us.
							 */
							title,
							...(description && { description }),
							type: flagDetails.type,
							actions,
							isAutoDismiss: flagDetails.shouldAutoDismiss,
							testId: flagDetails.testId,
						};

						// @ts-expect-error - TS2769 - No overload matches this call.
						return <OpTrigger key={flag.id} args={[flagArgs]} op={showFlag} />;
					})
				}
			</FlagServiceSubscriber>
		);
	}
}

export default flow(
	ComponentWithAnalytics('deleteIssueRetry', {
		retryIssueDelete: 'deleteIssueRetry',
	}),
	connect(
		(state: State) => ({
			flags: flagsSelector(state),
			deleteFailureIssueKey: issueKeySelector(state),
			FlagServiceSubscriber: FlagServiceSubscriberComponent,
		}),
		(dispatch: Dispatch) => ({
			onFlagDismissed: (id: number) => dispatch(dismissFlag(id)),
			retryIssueDelete: (issueKey: string, analyticsEvent: UIAnalyticsEvent) => {
				dispatch(deleteIssueRequest(issueKey, analyticsEvent, undefined, undefined));
			},
			openComment: (flag: FlagDetails) => {
				const url = `${flag.fullIssueUrl}?focusedCommentId=${flag.commentId}`;

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.open(url, '_blank')?.focus();
			},
		}),
	),
	safeComponent(),
)(UnsafeFlags);
