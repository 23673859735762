import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { PolarisTimelineMode } from '@atlassian/jira-polaris-domain-view/src/timeline/types.tsx';
import type {
	View,
	TimelineConfigDraft,
} from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { State, Props } from '../../types';
import { saveViewWithAutoSave } from '../save';
import { fireViewUpdatedEvent } from '../utils/analytics';
import { updateViewState } from '../utils/state';
import { currentViewFilter } from '../utils/views';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	refreshArrangementInformation,
	updateArrangementInformation,
	setArrangementInformation,
} from './arrangement-information';

const defaultDraftTimelineConfig: TimelineConfigDraft = {
	dueDateField: undefined,
	endTimestamp: undefined,
	startDateField: undefined,
	startTimestamp: undefined,
	mode: PolarisTimelineMode.QUARTERS,
	summaryCardField: undefined,
};

export const setTimelineMode =
	(mode: PolarisTimelineMode): Action<State, Props> =>
	async ({ getState, setState, dispatch }, { currentViewSlug, createAnalyticsEvent }) => {
		const viewMutation = (view: View): View => {
			if (!view.timelineConfig) {
				return view;
			}

			return {
				...view,
				modified: view.timelineConfig?.mode !== mode && view.isAutosaveEnabled,
				timelineConfig: {
					...view.timelineConfig,
					mode: view.isAutosaveEnabled ? mode : view.timelineConfig.mode,
				},
				draft: {
					...view.draft,
					timelineConfig: {
						...(view.draft.timelineConfig || defaultDraftTimelineConfig),
						mode,
					},
				},
			};
		};

		const { changedView, currentView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			viewMutation,
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveViewWithAutoSave(changedView.id));

			fireViewUpdatedEvent(createAnalyticsEvent, changedView, {
				updatedItems: [
					{
						name: 'timelineConfig.mode',
						oldVal: currentView?.timelineConfig?.mode,
						newVal: changedView.timelineConfig?.mode,
					},
				],
			});
		}
	};

export const setStartDateField =
	(fieldKey: FieldKey): Action<State, Props> =>
	async ({ getState, setState, dispatch }, { currentViewSlug, fields, createAnalyticsEvent }) => {
		const startDateField = fields[fieldKey];
		const viewMutation = (view: View): View => {
			if (!view.timelineConfig) {
				return view;
			}

			return {
				...view,
				modified: view.timelineConfig?.startDateField !== startDateField && view.isAutosaveEnabled,
				timelineConfig: {
					...view.timelineConfig,
					startDateField: view.isAutosaveEnabled
						? startDateField
						: view.timelineConfig.startDateField,
				},
				draft: {
					...view.draft,
					timelineConfig: {
						...(view.draft.timelineConfig || defaultDraftTimelineConfig),
						startDateField,
					},
				},
			};
		};
		const { changedView, currentView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			viewMutation,
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveViewWithAutoSave(changedView.id));

			fireViewUpdatedEvent(createAnalyticsEvent, changedView, {
				updatedItems: [
					{
						name: 'timelineConfig.startDateField',
						oldVal: currentView?.timelineConfig?.startDateField?.key,
						newVal: changedView.timelineConfig?.startDateField?.key,
					},
				],
			});
		}
	};

export const setEndDateField =
	(fieldKey: FieldKey): Action<State, Props> =>
	async ({ getState, setState, dispatch }, { currentViewSlug, fields, createAnalyticsEvent }) => {
		const dueDateField = fields[fieldKey];
		const viewMutation = (view: View): View => {
			if (!view.timelineConfig) {
				return view;
			}

			return {
				...view,
				modified: view.timelineConfig?.dueDateField !== dueDateField && view.isAutosaveEnabled,
				timelineConfig: {
					...view.timelineConfig,
					dueDateField: view.isAutosaveEnabled ? dueDateField : view.timelineConfig.dueDateField,
				},
				draft: {
					...view.draft,
					timelineConfig: {
						...(view.draft.timelineConfig || defaultDraftTimelineConfig),
						dueDateField,
					},
				},
			};
		};
		const { changedView, currentView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			viewMutation,
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveViewWithAutoSave(changedView.id));

			fireViewUpdatedEvent(createAnalyticsEvent, changedView, {
				updatedItems: [
					{
						name: 'timelineConfig.dueDateField',
						oldVal: currentView?.timelineConfig?.dueDateField?.key,
						newVal: changedView.timelineConfig?.dueDateField?.key,
					},
				],
			});
		}
	};

export const setStartTimelineIntervalDate =
	(startTimestamp: string): Action<State, Props> =>
	async ({ getState, setState, dispatch }, { currentViewSlug, createAnalyticsEvent }) => {
		const viewMutation = (view: View): View => {
			if (!view.timelineConfig) {
				return view;
			}

			return {
				...view,
				modified: view.timelineConfig?.startTimestamp !== startTimestamp && view.isAutosaveEnabled,
				timelineConfig: {
					...view.timelineConfig,
					startTimestamp: view.isAutosaveEnabled
						? startTimestamp
						: view.timelineConfig.startTimestamp,
				},
				draft: {
					...view.draft,
					timelineConfig: {
						...(view.draft.timelineConfig || defaultDraftTimelineConfig),
						startTimestamp,
					},
				},
			};
		};
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			viewMutation,
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveViewWithAutoSave(changedView.id));

			fireViewUpdatedEvent(createAnalyticsEvent, changedView, {
				updatedItems: [{ name: 'timelineConfig.startTimestamp' }],
			});
		}
	};

export const setEndTimelineIntervalDate =
	(endTimestamp: string): Action<State, Props> =>
	async ({ getState, setState, dispatch }, { currentViewSlug, createAnalyticsEvent }) => {
		const viewMutation = (view: View): View => {
			if (!view.timelineConfig) {
				return view;
			}

			return {
				...view,
				modified: view.timelineConfig?.endTimestamp !== endTimestamp && view.isAutosaveEnabled,
				timelineConfig: {
					...view.timelineConfig,
					endTimestamp: view.isAutosaveEnabled ? endTimestamp : view.timelineConfig.endTimestamp,
				},
				draft: {
					...view.draft,
					timelineConfig: {
						...(view.draft.timelineConfig || defaultDraftTimelineConfig),
						endTimestamp,
					},
				},
			};
		};
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			viewMutation,
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveViewWithAutoSave(changedView.id));

			fireViewUpdatedEvent(createAnalyticsEvent, changedView, {
				updatedItems: [{ name: 'timelineConfig.endTimestamp' }],
			});
		}
	};

export const updateSummaryCardFieldInView = (
	view: View,
	summaryCardField: Field | undefined,
): View => {
	if (!view.timelineConfig) {
		return view;
	}

	return {
		...view,
		modified:
			view.modified ||
			(view.timelineConfig?.summaryCardField?.key !== summaryCardField?.key &&
				view.isAutosaveEnabled),
		timelineConfig: {
			...view.timelineConfig,
			summaryCardField: view.isAutosaveEnabled
				? summaryCardField
				: view.timelineConfig.summaryCardField,
		},
		draft: {
			...view.draft,
			timelineConfig: {
				...(view.draft.timelineConfig || defaultDraftTimelineConfig),
				summaryCardField,
			},
		},
	};
};

export const setSummaryCardField =
	(fieldKey: FieldKey | undefined): Action<State, Props> =>
	async ({ getState, setState, dispatch }, { currentViewSlug, createAnalyticsEvent, fields }) => {
		const summaryCardField = fieldKey ? fields[fieldKey] : undefined;
		const { changedView, currentView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			(view) => updateSummaryCardFieldInView(view, summaryCardField),
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveViewWithAutoSave(changedView.id));

			fireViewUpdatedEvent(createAnalyticsEvent, changedView, {
				updatedItems: [
					{
						name: 'timelineConfig.summaryCardField',
						oldVal: currentView?.timelineConfig?.summaryCardField,
						newVal: changedView.timelineConfig?.summaryCardField,
					},
				],
			});
		}
	};
