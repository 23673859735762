import React from 'react';
import { lazy } from 'react-loosely-lazy';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type { IssueViewData } from '@atlassian/jira-canned-response-common/src/types.tsx';
import Placeholder from '@atlassian/jira-placeholder';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const CannedResponseModalDI = lazy(
	() =>
		import(
			/* webpackChunkName: "canned-response-modal" */ '@atlassian/jira-canned-response-issue-view-modal/src/ui/canned-response-modal/index.tsx'
		),
	{
		ssr: false,
	},
);

const CannedResponseLazyLoadedModal = (props: {
	onClose: () => void;
	intlLocale: string;
	isOpen: boolean;
	onInsert: (content: ADF, templateVariableData: IssueViewData) => void;
}) => (
	<Placeholder name="canned-response-modal" fallback={null}>
		<CannedResponseModalDI {...props} />
	</Placeholder>
);

export default CannedResponseLazyLoadedModal;
