import React from 'react';
import { styled } from '@compiled/react';
import memoizeOne from 'memoize-one';
import Avatar from '@atlaskit/avatar';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { singleLineClamp } from '@atlassian/jira-common-styles/src/mixins';
import { useIntl } from '@atlassian/jira-intl/src/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { REPORTER_TYPE } from '@atlassian/jira-platform-field-config';
import messages from './messages';

type Props = {
	channel: string | undefined;
};

const getAnonymousReporter = memoizeOne((intl) => ({
	avatarUrl: '',
	name: intl.formatMessage(messages.noReporter),
}));

const getReporter = memoizeOne((intl, value) => {
	const { displayName, avatarUrls } = value;
	return {
		/* 48x48 is the avatar size returned from Issue express. We pick this so we could see the actual avatar during
        issue preview mode. */
		avatarUrl: avatarUrls && avatarUrls['48x48'] ? avatarUrls['48x48'] : '',
		name: displayName || intl.formatMessage(messages.noReporter),
	};
});

export const ReporterAndChannel = ({ channel }: Props) => {
	const intl = useIntl();
	const issueKey = useIssueKey();
	const [reporter] = useFieldValue({ issueKey, fieldKey: REPORTER_TYPE });

	let reporterDetails = getAnonymousReporter(intl);

	if (reporter) reporterDetails = getReporter(intl, reporter);
	const { name, avatarUrl } = reporterDetails;

	const isChannelNamePresent = channel !== undefined && channel !== '';
	return (
		<Container data-testId="servicedesk-request-details-panel.ui.reporter-and-channel">
			<AvatarContainer>
				<Avatar
					src={avatarUrl}
					size="small"
					testId="servicedesk-request-details-panel.ui.reporter-and-channel.avatar"
				/>
			</AvatarContainer>
			<ReporterNameContainer>{name}</ReporterNameContainer>
			<RequestRaisedContainer>{intl.formatMessage(messages.requestRaised)}</RequestRaisedContainer>
			{isChannelNamePresent && (
				<>
					<Box xcss={viaContainerStyles}>{intl.formatMessage(messages.via)}</Box>
					<ChannelNameContainer>{channel}</ChannelNameContainer>
				</>
			)}
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarContainer = styled.div({
	display: 'flex',
	paddingRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	alignItems: 'center',
	minWidth: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReporterNameContainer = styled.div({
	fontWeight: 600,
	paddingRight: token('space.050', '4px'),
	flexShrink: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ChannelNameContainer = styled.div({
	fontWeight: 600,
	paddingLeft: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const RequestRaisedContainer = styled.div(singleLineClamp, {
	minWidth: 0,
});

const viaContainerStyles = xcss({
	paddingLeft: 'space.050',
});
