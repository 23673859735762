import React, { useEffect, useState, useCallback, memo, type ChangeEvent } from 'react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { css } from 'styled-components';
import get from 'lodash/get';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { B200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { ErrorFlag } from '@atlassian/jira-issue-error-flag';
import {
	ReadViewContainer,
	// eslint-disable-next-line jira/styled/no-styled-import-alias
	InlineEditContainer as InlineEditContainerStyled,
} from '@atlassian/jira-issue-field-inline-edit/src/styled.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { NUMBER_TEST_ID } from '../common/constants';
import { type NumberValue, LOZENGE, INLINE, CHILD_ISSUE_LOZENGE } from '../common/types';
import { useNumberField } from '../services';
import { NumberEdit } from './edit';
import messages from './messages';
import type { Props, EditableProps } from './types';
import { NumberView } from './view';

export const actionSubject = 'numberInlineEdit';

export default memo<Props>((props: Props) => {
	const {
		fieldKey,
		issueKey,
		onCancel,
		onEdit,
		onEscape,
		onConfirm,
		onEnter,
		onUpdate,
		onSubmit,
		onFailure,
		readView,
		editView,
		noValueText,
		fieldFocusStyle,
		fieldEditStyle,
		saveField,
		...rest
	} = props;
	const [isEditing, setIsEditing] = useState<boolean>(false);

	const onSuccess = (newValue: NumberValue) => {
		onUpdate && onUpdate(newValue);
		setIsEditing(false);
	};

	const [{ value, error, fieldConfig }, { saveValue, resetError }] = useNumberField({
		issueKey: toIssueKey(issueKey),
		fieldKey,
		onSuccess,
		onSubmit,
		onFailure,
		saveField,
	});
	const fieldType = get(fieldConfig, 'schema.type', 'number');
	const [updatedValue, setUpdatedValue] = useState<NumberValue>(value);
	const isFieldEditable = fieldConfig ? fieldConfig.isEditable : false;

	useEffect(() => {
		if (error) {
			setIsEditing(true);
		}
		if (!isEditing) {
			setUpdatedValue(value);
		}
	}, [error, isEditing, value]);

	const save = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			if (updatedValue !== value) {
				saveValue(updatedValue, null, analyticsEvent);
			} else {
				setIsEditing(false);
			}
			fireUIAnalytics(analyticsEvent);
		},
		[saveValue, updatedValue, value],
	);

	const cancel = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			resetError();
			setIsEditing(false);
			setUpdatedValue(value);
			fireUIAnalytics(analyticsEvent);
		},
		[resetError, value],
	);

	const onCancelRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			cancel(analyticsEvent);
			onCancel && onCancel(analyticsEvent);
		},
		[onCancel, cancel],
	);

	const onConfirmRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			save(analyticsEvent);
			onConfirm && onConfirm(analyticsEvent);
		},
		[onConfirm, save],
	);

	const onEditRequested = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setIsEditing(true);
			fireUIAnalytics(analyticsEvent);
			onEdit && onEdit(analyticsEvent);
		},
		[onEdit],
	);

	const onEscapeRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			cancel(analyticsEvent);
			onEscape && onEscape(analyticsEvent);
		},
		[onEscape, cancel],
	);

	const onEnterRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent);
			onEnter && onEnter(analyticsEvent);
		},
		[onEnter],
	);

	const onChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			resetError();
			const typedValue =
				event.target.value === '' ? null : parseFloat(Number(event.target.value).toFixed(3));
			setUpdatedValue(typedValue);
		},
		[resetError, setUpdatedValue],
	);

	const renderEditView = () =>
		editView !== undefined ? (
			editView
		) : (
			<NumberEdit
				defaultValue={updatedValue}
				onChange={onChange}
				type={fieldType}
				isInvalid={!!error}
			/>
		);

	const renderReadView = () =>
		readView !== undefined ? (
			readView
		) : (
			<NumberView isEditable={isFieldEditable} value={value} setIsEditing={setIsEditing} />
		);

	let InlineEditContainer = NumberInlineEditContainer;
	if (fieldFocusStyle === LOZENGE) InlineEditContainer = LozengeNumberFieldContainer;
	if (fieldFocusStyle === CHILD_ISSUE_LOZENGE)
		InlineEditContainer = LozengeChildNumberFieldContainer;
	if (isEditing) {
		if (fieldEditStyle === INLINE) InlineEditContainer = InlineWidthContainer;
	}

	const numberInlineEdit = (
		<>
			{error && (
				<ErrorFlag error={error} title={messages.errorTitle} description={messages.errorMessage} />
			)}
			<InlineEditContainer isEditable={isFieldEditable}>
				<FieldInlineEditStateLess
					testId={NUMBER_TEST_ID}
					actionSubject={actionSubject}
					isEditable={isFieldEditable}
					isEditing={isEditing}
					readView={<ReadViewContainer>{renderReadView()}</ReadViewContainer>}
					editView={isFieldEditable ? renderEditView() : null}
					onCancel={onCancelRequest}
					onConfirm={onConfirmRequest}
					onEdit={onEditRequested}
					onEscape={onEscapeRequest}
					onEnter={onEnterRequest}
					{...rest}
				/>
			</InlineEditContainer>
		</>
	);

	if (fieldFocusStyle !== CHILD_ISSUE_LOZENGE) {
		return numberInlineEdit;
	}

	return isEditing ? (
		numberInlineEdit
	) : (
		// @ts-expect-error - TS2741 - Property 'hasValue' is missing in type '{ children: Element; }' but required in type 'Readonly<ThemedOuterStyledProps<ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement> & { hasValue: boolean; }, any>>'.
		<InlineEditContainer>
			<ReadViewContainer>{renderReadView()}</ReadViewContainer>
		</InlineEditContainer>
	);
});

// @ts-expect-error - TS2345: Argument of type 'StyledComponentClass'
// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeNumberFieldContainer = styled<EditableProps>(InlineEditContainerStyled)`
	padding-right: ${token('space.075', '6px')};
	& div[data-read-view-fit-container-width] {
		background: transparent !important;
		border: none !important;
		vertical-align: top;
	}
	& button[aria-label='Edit']:focus + div > div:first-child {
		border: 2px solid ${token('color.border.focused', B200)};
		border-radius: ${token('border.radius.300', '12px')};
		${
			'' /* PX values as these are to negate the focus outline offset (implemented as a 2px border) */
		}
		margin-left: -2px;
		margin-right: -6px;
	}
`;

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LozengeChildNumberFieldContainer = styled(LozengeNumberFieldContainer)`
	padding-top: ${token('space.100', '8px')};
`;

// @ts-expect-error - TS2345: Argument of type 'StyledComponentClass'
// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineWidthContainer = styled<EditableProps>(InlineEditContainerStyled)`
	padding-right: ${token('space.075', '6px')};
	width: 68px;
	margin-right: ${token('space.negative.075', '-6px')};
	& [data-ds--text-field--container] {
		border-color: ${token('color.border.focused', B200)};
	}
`;

// @ts-expect-error - TS2345: Argument of type 'StyledComponentClass'
// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NumberInlineEditContainer = styled<EditableProps>(InlineEditContainerStyled)`
	${({ isEditable }) =>
		!isEditable
			? // eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression
				css`
					left: 0;
					padding: ${token('space.075', '6px')} 0;

					${'' /* NonEditableMargin overrides */}
					& > div {
						margin: 11px 0 3px ${token('space.025', '2px')};
					}

					${ReadViewContainer} {
						margin-left: 0;
						line-height: 1;
					}
				`
			: null}
`;
