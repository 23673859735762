import { createSelector } from 'reselect';
import flatMapDeep from 'lodash/fp/flatMapDeep';
import getOr from 'lodash/fp/getOr';
import map from 'lodash/fp/map';
import over from 'lodash/fp/over';
import pipe from 'lodash/fp/pipe';
import sortBy from 'lodash/fp/sortBy';
import keyBy from 'lodash/keyBy';
import type { PullRequest, PullRequestStatus, InstanceTypeDetail } from '../../../../model';
import { DECLINED, OPEN, MERGED } from '../../../../model/constants';
import type { State } from '../../../index';
import { getAllInstanceTypeDetails, getSelectedInstanceTypeDetails } from '../index';

const getDanglingPullRequestOrNothing = getOr([], 'danglingPullRequests');

const getReposPullRequests = pipe(getOr([], 'repositories'), map(getOr([], 'pullRequests')));

const statusScore: Partial<Record<PullRequestStatus, number>> = {
	[OPEN]: 1,
	[MERGED]: 2,
	[DECLINED]: 3,
};

const getStatusScore = (pullRequest: PullRequest): number | undefined =>
	statusScore[pullRequest.status];

const getInstanceTypeDetailsPullRequests = pipe(
	flatMapDeep(over([getReposPullRequests, getDanglingPullRequestOrNothing])),
	sortBy(getStatusScore),
);

/**
 * Returns pull requests for selected instance type details
 */
export const getPullRequests = createSelector(
	getSelectedInstanceTypeDetails,
	getInstanceTypeDetailsPullRequests,
);

/**
 * Returns pull requests for all instance type details
 */
export const getAllPullRequests = createSelector(
	getAllInstanceTypeDetails,
	getInstanceTypeDetailsPullRequests,
);

export const getMultiInstanceTypePullRequestsGroupByRepoName = createSelector(
	[getAllInstanceTypeDetails],
	(allInstanceTypeDetails) =>
		allInstanceTypeDetails.reduce(
			(
				acc: {
					[key: string]: {
						instanceName: string;
						repositoryId?: string;
						repositoryName?: string;
						repositoryUrl?: string;
						repositoryAvatarUrl?: string;
						pullRequests: PullRequest[];
						providerId: string;
					};
				},
				instanceDetail: InstanceTypeDetail,
			) => {
				const pullRequests = getInstanceTypeDetailsPullRequests([instanceDetail]);
				pullRequests.forEach((pullrequest) => {
					const key = `${pullrequest.repositoryName}-${instanceDetail.typeName}`;
					if (!acc[key]) {
						acc[key] = {
							providerId: instanceDetail.id,
							instanceName: instanceDetail.typeName,
							repositoryId: pullrequest.repositoryId,
							repositoryName: pullrequest.repositoryName,
							repositoryUrl: pullrequest.repositoryUrl,
							repositoryAvatarUrl: pullrequest.repositoryAvatarUrl,
							pullRequests: [],
						};
					}
					acc[key].pullRequests.push(pullrequest);
				});
				return acc;
			},
			{},
		),
);

const getPullRequestById = createSelector([getPullRequests], (pullRequests) =>
	keyBy(pullRequests, (pullRequest) => pullRequest.uid),
);

const getMultiInstancePullRequestById = createSelector([getAllPullRequests], (pullRequests) =>
	keyBy(pullRequests, (pullRequest) => pullRequest.uid),
);

export const getPullRequestForId = (state: State, id: string): PullRequest =>
	getPullRequestById(state)[id];

export const getMultiInstancePullRequestForId = (state: State, id: string): PullRequest =>
	getMultiInstancePullRequestById(state)[id];

export const hasPullRequests = (state: State): boolean => getPullRequests(state).length > 0;
export const multiInstanceHasPullRequests = (state: State): boolean =>
	getAllPullRequests(state).length > 0;
