import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { lazyForPaint } from 'react-loosely-lazy';
import Badge from '@atlaskit/badge';
import { Text } from '@atlaskit/primitives';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { FieldDescription } from '@atlassian/jira-issue-field-description/src/ui/index.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading';
import {
	FieldHeadingTitle,
	FieldWrapper,
	SideBySideField,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { useNumberField } from '@atlassian/jira-issue-field-number/src/services/index.tsx';
import { IssueNumberFieldBoundary } from '@atlassian/jira-issue-field-number/src/ui/async.tsx';
import type IssueNumberFieldType from '@atlassian/jira-issue-field-number/src/ui/index.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin';
import { messages } from './messages';
import type { Props } from './types';

// TODO reevaluate phase - forPaint is simply for initial parity
export const IssueNumberField = lazyForPaint<typeof IssueNumberFieldType>(
	() =>
		import(/* webpackChunkName: "async-issue-field-number" */ '@atlassian/jira-issue-field-number'),
);

const NumberView = ({
	issueKey,
	fieldId: fieldKey,
	label,
	noValueText,
	onUpdate,
	showPinButton,
}: Props) => {
	const [{ value }] = useNumberField({
		issueKey,
		fieldKey,
	});

	const { formatMessage, formatNumber } = useIntl();

	const readView =
		// @ts-expect-error - TS2367 - This condition will always return 'false' since the types 'number' and 'string' have no overlap.
		value === null || value === '' ? (
			<Text color="color.text.subtlest">{noValueText}</Text>
		) : (
			<Badge>{formatNumber(value)}</Badge>
		);

	const editButtonLabel = formatMessage(messages.extendedEditButtonLabel, {
		inputFieldValue: value !== null ? value : noValueText,
		fieldName: label,
	});

	return (
		<FieldWrapper>
			<FieldHeading>
				<FieldHeadingTitle>{label}</FieldHeadingTitle>
				{issueKey !== undefined && fieldKey !== undefined && (
					// @ts-expect-error label cannot be undefined, please pass it through when working on this code. If there is really no need then pass through an empty string :) */}
					<FieldDescription issueKey={issueKey} fieldKey={fieldKey} label={label} />
				)}
				{showPinButton === true && <FieldPin fieldId={fieldKey} label={label} />}
			</FieldHeading>
			<NumberFieldWrapper>
				<IssueNumberFieldBoundary packageName="issue" fallback={readView}>
					<IssueNumberField
						label={label}
						issueKey={issueKey}
						fieldKey={fieldKey}
						readView={readView}
						isFitContainerWidthReadView
						onUpdate={onUpdate}
						editButtonLabel={editButtonLabel}
					/>
				</IssueNumberFieldBoundary>
			</NumberFieldWrapper>
		</FieldWrapper>
	);
};

export default memo<Props>(NumberView);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NumberFieldWrapper = styled(SideBySideField)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div > div > div > div > div > div': {
		width: '100%',
	},
});
