import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { trackFetchErrors } from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getEscalatorBaseUrl } from '@atlassian/jira-servicedesk-incident-management-common/src/utils';
import type { IssueId, CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { UPDATE_SYSTEM_INCIDENT_ERROR } from '../../constants';
import type { State } from '../../types';

export const provisionIncidentCapabalities =
	(issueId: IssueId, cloudId: CloudId, analyticsEvent?: UIAnalyticsEvent) =>
	async ({ getState, setState }: StoreActionApi<State>) => {
		const { provisioningIncidentCapabalities } = getState();

		if (provisioningIncidentCapabalities.isLoading || !issueId) {
			return;
		}

		setState({
			provisioningIncidentCapabalities: {
				hasUpdateCompleted: false,
				isLoading: true,
				error: undefined,
			},
		});

		try {
			await performPostRequest(
				`${getEscalatorBaseUrl(cloudId)}/issue/${issueId}/incident/provision`,
			);

			/* provisioningIncidentCapabalities state is not updated here as <PeriodicExecutor /> will
            fire once isLoading is true, will be set to updateComplete true and isLoading false once
            async task is finished. Look inside fetch-incident-capabilities-provision-state for more details. */

			analyticsEvent &&
				fireOperationalAnalytics(analyticsEvent, 'provisionIncidentCapabilities succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			trackFetchErrors({
				event: analyticsEvent,
				error: e,
				id: 'provisionIncidentCapabilities',
				sendToPrivacyUnsafeSplunk: true,
				packageName: 'jiraServicedeskSystemIncidentStatus',
			});

			setState({
				provisioningIncidentCapabalities: {
					hasUpdateCompleted: false,
					isLoading: false,
					error: { value: e.message, type: UPDATE_SYSTEM_INCIDENT_ERROR },
				},
			});
		}
	};
