import { ASSIGNEE } from '@atlassian/jira-issue-view-configurations';
import { ALWAYS_PRIMARY_AND_VISIBLE } from '@atlassian/jira-issue-view-layout-templates-constants';
import { useVisibleHiddenItems } from '@atlassian/jira-issue-view-layout-templates-services/src/services/context/visible-hidden/visible-hidden-items/index.tsx';
import { useTemplateRendererKey } from '@atlassian/jira-issue-view-layout-templates/src/services/index.tsx';
import { getLayoutItemId } from '@atlassian/jira-issue-view-layout/src/services/utils.tsx';

/**
 * Checks if assignee is in the 'visibleItems' of the field layout and if there are any hidden items present
 *
 * @returns [true if assignee is visible, true if has hidden items]
 */
export const useChangeboardingTourProps = () => {
	// Because this can be rendered in different 'locations' we need to ensure that the correct template key is used
	// otherwise we may not display the appropriate always primary and visible fields.
	const templateKey = useTemplateRendererKey();

	const [{ visibleItems, hiddenItems }] = useVisibleHiddenItems(
		ALWAYS_PRIMARY_AND_VISIBLE[templateKey],
	);

	const hasVisibleAssignee = visibleItems.map(getLayoutItemId).some((id) => id === ASSIGNEE);
	const hasHiddenItems = hiddenItems.length > 0;

	return [hasVisibleAssignee, hasHiddenItems];
};
