import React, { type ComponentPropsWithoutRef, type ReactNode } from 'react';
import EditIcon from '@atlaskit/icon/core/edit';
import { Box, xcss } from '@atlaskit/primitives';
import Pressable from '@atlaskit/primitives/pressable';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { fieldEditButtonSelectorName } from './constants';
import messages from './messages';

export const FieldEditIcon = ({
	onClick,
}: {
	onClick: React.ComponentProps<typeof Pressable>['onClick'];
}) => {
	const { formatMessage } = useIntl();

	return (
		<FieldEditButton>
			<Tooltip content={formatMessage(messages.tooltip)}>
				<Pressable
					onClick={onClick}
					xcss={pressableStyles}
					aria-label={formatMessage(messages.tooltip)}
				>
					<EditIcon
						color="currentColor"
						label={formatMessage(messages.label)}
						LEGACY_size="small"
					/>
				</Pressable>
			</Tooltip>
		</FieldEditButton>
	);
};

const FieldEditButton = (props: ComponentPropsWithoutRef<typeof FieldEditButtonComponent>) => (
	<FieldEditButtonComponent data-component-selector={fieldEditButtonSelectorName} {...props} />
);

const FieldEditButtonComponent = ({ children, ...props }: { children: ReactNode }) => (
	// eslint-disable-next-line react/jsx-props-no-spreading
	<Box xcss={fieldEditButtonComponentStyles} {...props}>
		{children}
	</Box>
);

const pressableStyles = xcss({
	width: '16px',
	height: '16px',
	borderRadius: '4px',
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: 'color.background.neutral.subtle',
});

const fieldEditButtonComponentStyles = xcss({
	paddingTop: 'space.025',
	paddingRight: 'space.025',
});
