import React, { useEffect, useRef } from 'react';
import { AnalyticsContext } from '@atlaskit/analytics-next';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import type { JSErrorBoundaryProps } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { getErrorType } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-error-type';
import {
	isProjectTypeSupported,
	isTriggerPointSupported,
} from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils';
import { useAdjustmentsContextActions } from '../../controllers/adjustments-context';
import type { EnabledIssueAdjustmentsProps } from './enabled-issue-adjustments';
import { issueAdjustmentsEntryPoint } from './entrypoint';
import { IssueAdjustmentsErrorHandler } from './error-handler';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { EnabledIssueAdjustmentsProps } from './enabled-issue-adjustments';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { IssueAdjustmentsErrorHandler } from './error-handler';

export type IssueAdjustmentsAsyncProps = {
	containerId: string;
	containerPackageName: string;
	runtimeProps: EnabledIssueAdjustmentsProps;
	triggerPointKey?: string;
	errorFallback?: JSErrorBoundaryProps['fallback'];
};

export const useDefaultErrorFallback = () => () => <IssueAdjustmentsErrorHandler />;

const STABLE_EMPTY_OBJECT = {};
export const IssueAdjustmentsAsync = ({
	containerId,
	containerPackageName,
	runtimeProps,
	triggerPointKey,
	errorFallback,
}: IssueAdjustmentsAsyncProps) => {
	const { issueAdjustmentsContext, modules, viewType } = runtimeProps;
	const { entryPointReferenceSubject, entryPointActions } = useEntryPoint(
		issueAdjustmentsEntryPoint,
		STABLE_EMPTY_OBJECT,
	);
	const { setShouldUiModificationsLoad } = useAdjustmentsContextActions();
	const defaultErrorFallback = useDefaultErrorFallback();
	const hasUiModificationsLoaded = useRef(false);

	// NOTE: This killswitch flag can be enabled in an incident to prevent UI modifications from loading
	const shouldUiModificationsLoad =
		// NOTE: this is configured as a permanent flag that defaults to "false" for all users. It will never be "rolled out".
		// In an incident the default rule in Launch Darkly can be set to "true" to skip mounting the IssueAdjustmentsAsync component.
		// This will stop calls to the internal UI modifications endpoint, reducing load on services like cs-extensions
		// https://app.launchdarkly.com/jira/production/features/killswitch-turn-off-forge-ui-modifications-in-jira/targeting
		!ff('killswitch-turn-off-forge-ui-modifications-in-jira', false) &&
		modules?.length > 0 &&
		isTriggerPointSupported(viewType, triggerPointKey) &&
		isProjectTypeSupported(issueAdjustmentsContext.project, viewType);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		const hasProjectAndIssueTypeFinishedLoading = issueAdjustmentsContext !== null;
		// issueAdjustmentContext starts out as null so we need to wait until it is populated
		// to know if UI modifications should load or not in this project type.
		// issueAdjustmentContext needs to be a dependency not hasProjectAndIssueTypeFinishedLoading
		// otherwise we don't set the supported status when changing issue types only
		if (hasProjectAndIssueTypeFinishedLoading) {
			setShouldUiModificationsLoad(shouldUiModificationsLoad ? 'yes' : 'no');
		}
		if (shouldUiModificationsLoad && !hasUiModificationsLoaded.current) {
			entryPointActions.load();
			hasUiModificationsLoaded.current = true;
		}
	}, [
		issueAdjustmentsContext,
		shouldUiModificationsLoad,
		setShouldUiModificationsLoad,
		entryPointActions,
	]);

	if (shouldUiModificationsLoad) {
		return (
			<AnalyticsContext
				data={{
					attributes: {
						triggerPointKey,
					},
				}}
			>
				<JiraEntryPointContainer
					entryPointReferenceSubject={entryPointReferenceSubject}
					id={containerId}
					packageName={containerPackageName}
					fallback={<></>} // Placeholder fallback
					errorFallback={errorFallback || defaultErrorFallback}
					errorAttributes={(error: Error) => ({
						errorType: getErrorType(error),
						viewType: runtimeProps.viewType,
					})}
					runtimeProps={runtimeProps}
				/>
			</AnalyticsContext>
		);
	}
	return null;
};
