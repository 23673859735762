import AsyncIncidentInvestigationPanel from '@atlassian/jira-incident-investigation-view/src/async';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { DESCRIPTION, ISSUE_SUMMARY } from '@atlassian/jira-issue-view-configurations';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { investigateIncidentModalClosed } from '@atlassian/jira-issue-view-store/src/actions/investigate-incident-actions';
import {
	issueKeySelector,
	issueIdSelector,
	projectKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { fieldEditingAdfValueSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector';
import { projectIdSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import { isInvestigateIncidentModalOpenedSelector } from '@atlassian/jira-issue-view-store/src/selectors/investigate-incident-selector';
import { serviceEntitySelector } from '@atlassian/jira-issue-view-store/src/selectors/service-entity-field-selector';

export const IncidentInvestigationPanel = flowWithSafeComponent(
	connect(
		(state: State) => ({
			issueSummary: fieldEditingAdfValueSelector(ISSUE_SUMMARY)(state),
			issueDescription: fieldEditingAdfValueSelector(DESCRIPTION)(state),
			impactedServices: serviceEntitySelector(state),
			issueKey: issueKeySelector(state),
			// FIXME: JIV-17455. This field can be null
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			issueId: issueIdSelector(state)!,
			projectKey: projectKeySelector(state),
			projectId: projectIdSelector(state),
			isIncidentInvestigationModalVisible: isInvestigateIncidentModalOpenedSelector(state),
		}),
		{
			onCloseIncidentInvestigationModal: investigateIncidentModalClosed,
		},
	),
)(AsyncIncidentInvestigationPanel);
