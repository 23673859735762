import type { CheckboxValueItemShape } from '@atlassian/jira-issue-internal-fields/src/checkbox-select/checkbox-field-view';

export type StateValue = {
	id: string;
	value: string;
};

const toItem = (fieldData: StateValue): CheckboxValueItemShape => ({
	label: fieldData.value,
	value: fieldData.id,
});

export const transformFromStateValue = (value: StateValue[] | null): CheckboxValueItemShape[] =>
	value ? value.map(toItem) : [];

export const transformToStateValue = (
	value: CheckboxValueItemShape[] | null,
): StateValue[] | null =>
	value &&
	value.map((item) => ({
		id: item.value,
		value: item.label,
	}));

export const transformSuggestions = (suggestions: StateValue[] | null): CheckboxValueItemShape[] =>
	(suggestions && suggestions.map(toItem)) || [];
