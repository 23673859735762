import type { ChildIssueType } from '@atlassian/jira-issue-view-common-types/src/child-issue-type';
import type { FieldMeta } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import type { ChildIssueTypeWithFields } from './types';
// for the creation of an issue, but are considered as 'provided' since
// the data for these fields is supplied by Jira.
const providedRequiredFields = ['summary', 'issuetype', 'parent', 'project'];

const isRequiredFieldProvided = (fieldName: string) =>
	providedRequiredFields.find((providedFieldName) => fieldName === providedFieldName);

const listUnsupportedRequiredFields = (fields: { [key: string]: FieldMeta }) => {
	const unsupportedRequiredFields: string[] = [];
	Object.keys(fields).forEach((fieldName) => {
		if (
			!isRequiredFieldProvided(fieldName) &&
			!fields[fieldName].hasDefaultValue &&
			fields[fieldName].required
		) {
			unsupportedRequiredFields.push(fieldName);
		}
	});
	return unsupportedRequiredFields;
};

export const transformIssueType = (
	issueType: ChildIssueTypeWithFields,
	epicLinkFieldKey: string | null,
): ChildIssueType => {
	const { fields, ...otherProps } = issueType;
	const requiredFields = fields ? listUnsupportedRequiredFields(fields) : [];

	// @ts-expect-error - TS2345 - Argument of type 'string | null' is not assignable to parameter of type 'string'.
	const hasEpicLinkField = fields ? Object.keys(fields).includes(epicLinkFieldKey) : false;
	return {
		...otherProps,
		hasRequiredField: requiredFields.length > 0,
		hasEpicLinkField,
		requiredFields,
	};
};
