import {
	MINUTE,
	DEFAULT_TO_NEW_ISSUE_VIEW,
} from '@atlassian/jira-common-constants/src/jira-settings.tsx';
import { createStore, createHook } from '@atlassian/react-sweet-state';
import * as actions from './actions';
import type { State } from './types';

export type Actions = typeof actions;

export const initialState: State = {
	newIssueViewTransitionSettings: {
		newIssueViewTransitionLockingStatus: DEFAULT_TO_NEW_ISSUE_VIEW,
		shouldDelayChangeboarding: false,
	},
	issueLinkTypes: [],
	subtasksConfiguration: {
		enabled: true,
	},
	timeTrackingConfiguration: {
		isTimeTrackingEnabled: false,
		daysPerWeek: 0,
		hoursPerDay: 0,
		// Possible values for defaultUnit can be: minute, hour, day, and week.
		defaultUnit: MINUTE,
	},
};

export const store = createStore<State, Actions>({
	name: 'jiraSettings',
	initialState,
	actions,
});

// remove undefined when we remove ff issue.details.admin-options-phase-2
const getTimeTracking = (state: State) => state.timeTrackingConfiguration;
// @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
const getNewIssueViewTransition = (state) => state.newIssueViewTransitionSettings || undefined;
const getSubtaskConfiguration = (state: State) => state.subtasksConfiguration;
const getIssueLinkType = (state: State) => state.issueLinkTypes;

export const useJiraSettingStoreTimeTracking = createHook(store, {
	selector: getTimeTracking,
});

// remove void when we remove ff issue.details.admin-options-phase-2
export const useJiraSettingStoreNewIssueViewTransition = createHook(store, {
	selector: getNewIssueViewTransition,
});

export const useJiraSettingStoreSubtaskConfiguration = createHook(store, {
	selector: getSubtaskConfiguration,
});

export const useJiraSettingStoreIssueLinkType = createHook(store, {
	selector: getIssueLinkType,
});

export const useSettingsActions = createHook(store, {
	selector: null,
});
