import type { NullableOption } from '@atlassian/jira-issue-field-priority-editview-full/src/ui/priority/types.tsx';
import type { AggJiraPriority } from './types';

export const transformOptionToAgg = (option: NullableOption): AggJiraPriority =>
	option
		? {
				id: option.value,
				iconUrl: option.iconUrl,
				name: option.label,
				priorityId: option.value?.split('/')?.pop() || option.value,
			}
		: option;
