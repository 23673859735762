import memoizeOne from 'memoize-one';
import MultiSelectInlineEditView from '@atlassian/jira-issue-internal-field-select/src/multi-select-inline-edit';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field';
import { fieldAllowedValuesSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector';
import { projectIdSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import {
	transformFromStateValue,
	transformToStateValue,
	transformSuggestionsFromServerAndCache,
} from '../versions/versions-transformer';

const fetchSuggestionsFactory = memoizeOne(
	(suggestions, intl, projectId) => () =>
		Promise.resolve(suggestions).then((resolvedSuggestions) =>
			transformSuggestionsFromServerAndCache(resolvedSuggestions, [], intl, projectId),
		),
);

export default connectField((stateOnMount, ownPropsOnMount) => ({
	fieldId: ownPropsOnMount.fieldId,
	transformFromStateValue,
	transformToStateValue,
	additionalProps: (state, intl) => ({
		fetchSuggestions: fetchSuggestionsFactory(
			fieldAllowedValuesSelector(ownPropsOnMount.fieldId)(state),
			intl,
			projectIdSelector(state),
		),
		placeholder: intl.formatMessage(genericMessages.version_no_value_placeholder),
		noValueText: intl.formatMessage(genericMessages.noValue),
		showPinButton: getShowPinButton(ownPropsOnMount.area),
	}),
}))(MultiSelectInlineEditView);
