import React, { Component, type ReactNode, type ChangeEvent } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import Button from '@atlaskit/button/standard-button';
import { ErrorMessage } from '@atlaskit/form';
import Popup from '@atlaskit/popup';
import { Text } from '@atlaskit/primitives';
import TextField from '@atlaskit/textfield';
import { N0, N50A, N60A, N500 } from '@atlaskit/theme/colors';
// eslint-disable-next-line import/order
import { token } from '@atlaskit/tokens';
import { layers, gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import InfoIcon from '@atlassian/jira-issue-field-description/src/ui/assets/info-icon';
import {
	FieldHeadingTitle,
	FieldWrapper,
	CustomFieldHeadingWithBottomMargin,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';

type Props = {
	isInvalid: boolean;
	isDisabled: boolean;
	isRequired: boolean;
	value: string;
	autoFocus: boolean;
	label: string;
	triggerBtnAriaLabel?: string;
	name: string;
	description?: string | null;
	invalidMessage: ReactNode;
	onChange: (arg1: string) => void;
	onBlur: () => void;
};

// eslint-disable-next-line jira/react/no-class-components
export default class TimeLogTextField extends Component<Props> {
	static defaultProps = {
		isInvalid: false,
		isRequired: false,
		isDisabled: false,
		autoFocus: false,
		name: 'text-field',
		onBlur: noop,
	};

	state = {
		shouldShowDetails: false,
	};

	onToggle = () =>
		this.setState({
			shouldShowDetails: !this.state.shouldShowDetails,
		});

	onChange = (event: ChangeEvent<HTMLInputElement>) => {
		this.props.onChange(event.target.value);
	};

	render() {
		return (
			<FieldWrapper>
				<CustomFieldHeadingWithBottomMargin>
					<FieldHeadingTitle>{this.props.label}</FieldHeadingTitle>
					{this.props.description && (
						<Popup
							testId="issue.component.log-time-modal.modal.time-log-text-field.ak-popup"
							zIndex={layers.modal}
							onClose={() => {
								this.setState({ shouldShowDetails: false });
							}}
							placement="right-start"
							content={() => (
								<Container>
									<Text as="p">{this.props.description}</Text>
								</Container>
							)}
							isOpen={this.state.shouldShowDetails}
							trigger={(triggerProps) => (
								<CustomTimeLogPopupButton
									{...triggerProps}
									spacing="none"
									appearance="subtle"
									isSelected={this.state.shouldShowDetails}
									onClick={this.onToggle}
									aria-label={this.props.triggerBtnAriaLabel}
								>
									<InfoIcon />
								</CustomTimeLogPopupButton>
							)}
						/>
					)}
				</CustomFieldHeadingWithBottomMargin>
				<TextField
					autoFocus={this.props.autoFocus}
					isInvalid={this.props.isInvalid}
					isDisabled={this.props.isDisabled}
					isRequired={this.props.isRequired}
					onChange={this.onChange}
					value={this.props.value}
					onBlur={this.props.onBlur}
					aria-label={this.props.label}
				/>

				{this.props.isInvalid && <ErrorMessage>{this.props.invalidMessage}</ErrorMessage>}
			</FieldWrapper>
		);
	}
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 32}px`,
	marginLeft: token('space.025', '2px'),
	width: 'auto',
	padding: `${token('space.200', '16px')} ${token('space.200', '16px')} ${token(
		'space.200',
		'16px',
	)} ${token('space.300', '24px')}`,
	overflow: 'auto',
	backgroundColor: token('elevation.surface.raised', N0),
	boxShadow: token(
		'elevation.shadow.raised',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		`0 ${gridSize * 0.5}px ${gridSize}px ${gridSize * -0.25}px ${N50A}, 0 0 ${
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			gridSize * 0.125
		}px ${N60A}`,
	),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CustomTimeLogPopupButton = styled(Button)({
	marginLeft: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':focus, :hover': {
		background: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	svg: {
		color: `${token('color.background.neutral.bold.hovered', N500)}`,
	},
	background: 'none',
});
