import { useMemo } from 'react';
import type { MediaContext } from '@atlassian/jira-issue-media-provider/src/common/types.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useMediaContextStoreActions, useMediaContextStore } from './context';

export const useMediaContextUnsafe = (issueKey: IssueKey): MediaContext => {
	const [mediaContext] = useMediaContextStore(issueKey);
	return mediaContext;
};

export const useMediaContext = (issueKey: IssueKey): MediaContext | undefined => {
	const [mediaContext] = useMediaContextStore(issueKey);
	return mediaContext;
};

export const useMediaContextActions = () => {
	const [, { setViewContext, setUploadContext, setUserAuthContext }] =
		useMediaContextStoreActions();
	const actions = useMemo(
		() => ({
			setViewContext,
			setUploadContext,
			setUserAuthContext,
		}),
		[setViewContext, setUploadContext, setUserAuthContext],
	);
	return [null, actions] as const;
};
