import React from 'react';
import { useFragment, graphql } from 'react-relay';
import Heading from '@atlaskit/heading';
import type { singleLineTextHeading_issueFieldSingleLineTextReadviewHeading_SingleLineTextHeadingReadView$key as SingleLineTextHeadingFragment } from '@atlassian/jira-relay/src/__generated__/singleLineTextHeading_issueFieldSingleLineTextReadviewHeading_SingleLineTextHeadingReadView.graphql';
import type { SingleLineTextHeadingReadViewProps } from './types';
/**
 * The SingleLineTextHeadingReadView will show a read only view of SingleLineTextHeading field.
 * @param props [SingleLineTextHeadingReadViewProps](./types.tsx)
 */
export const SingleLineTextHeadingReadView = ({
	fragmentRef,
	readViewHeadingTestId,
}: SingleLineTextHeadingReadViewProps) => {
	const { text } = useFragment<SingleLineTextHeadingFragment>(
		graphql`
			fragment singleLineTextHeading_issueFieldSingleLineTextReadviewHeading_SingleLineTextHeadingReadView on JiraSingleLineTextField {
				text
			}
		`,
		fragmentRef,
	);

	const displayValue = text || '';

	return (
		<Heading testId={readViewHeadingTestId} size="large" as="h1">
			{displayValue}
		</Heading>
	);
};
