import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	archived: {
		id: 'issue-history.history-items.history-item.archived-history-item.archived',
		defaultMessage: 'archived the {issue}.',
		description: `Describes that a user has archived the issue.
            Part of the heading in History feed, appears after the display name of the user who made the change.`,
	},
	unarchived: {
		id: 'issue-history.history-items.history-item.archived-history-item.unarchived',
		defaultMessage: 'unarchived the {issue}.',
		description: `Describes that a user has unarchived the issue.
            Part of the heading in History feed, appears after the display name of the user who made the change.`,
	},
	issue: {
		id: 'issue-history.history-items.history-item.archived-history-item.issue',
		defaultMessage: 'Issue',
		description: "(noun) Value to display as label for the 'Issue' field",
	},
});
