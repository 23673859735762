import React, { type PropsWithChildren, useCallback } from 'react';
import noop from 'lodash/noop';
import { graphql, useFragment } from 'react-relay';
import { fg } from '@atlassian/jira-feature-gating';
import { FieldDescriptionIcon } from '@atlassian/jira-issue-field-description-icon/src/ui/index.tsx';
import { useCustomFieldsConfigurationExperiment } from '@atlassian/jira-issue-field-edit-icon/src/controllers/useCustomFieldsConfigurationExperiment';
import { FieldEditIcon } from '@atlassian/jira-issue-field-edit-icon/src/FieldEditIcon';
import { FieldHeading } from '@atlassian/jira-issue-field-heading';
import {
	FieldHeadingIconsContainer,
	FieldHeadingTitle,
	FieldWrapper,
	SideBySideField,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import type { ui_issueViewLayoutFieldHeading_IssueViewFieldHeading$key as IssueViewFieldHeadingFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutFieldHeading_IssueViewFieldHeading.graphql';

export type IssueViewFieldHeadingProps = PropsWithChildren<{
	testId?: string;
	area?: string;
	fragmentKey: IssueViewFieldHeadingFragment;
}>;

export const IssueViewFieldHeading = ({
	children,
	area,
	fragmentKey,
	testId,
}: IssueViewFieldHeadingProps) => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const showPinButton = getShowPinButton(area as Area);
	const { overriding } = useInlineEditFieldInjections();

	let isCustomFieldConfigurationEnabled = false;

	// To be updated in JGFENG-540
	let handleClickFieldEdit = useCallback(noop, []);
	if (fg('issue_view_field_config_edit')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isCustomFieldConfigurationEnabled = useCustomFieldsConfigurationExperiment();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		handleClickFieldEdit = useCallback(noop, []);
	}

	const data = useFragment<IssueViewFieldHeadingFragment>(
		graphql`
			fragment ui_issueViewLayoutFieldHeading_IssueViewFieldHeading on JiraIssueField {
				name
				fieldId
				description
			}
		`,
		fragmentKey,
	);

	const label = overriding.overrideLabel(data.name);
	const description = overriding.overrideDescription(data.description);

	return (
		<FieldWrapper data-testid={testId}>
			<FieldHeading fieldId={data.fieldId}>
				<FieldHeadingTitle>{label}</FieldHeadingTitle>
				<FieldHeadingIconsContainer
					isCustomFieldConfigurationEnabled={isCustomFieldConfigurationEnabled}
				>
					{description && <FieldDescriptionIcon description={description} />}
					{showPinButton && <FieldPin fieldId={data.fieldId} label={label} />}
					{isCustomFieldConfigurationEnabled && <FieldEditIcon onClick={handleClickFieldEdit} />}
				</FieldHeadingIconsContainer>
			</FieldHeading>
			<SideBySideField>{children}</SideBySideField>
		</FieldWrapper>
	);
};
