// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	menuHeader: {
		id: 'issue.views.field.parent.edit.menuHeader',
		defaultMessage: 'To set an epic as a parent, use the epic link instead.',
		description:
			'Text message to inform customers to use the Epic Link field instead of Parent Link.',
	},
	noMatches: {
		id: 'issue.views.field.parent.edit.noMatches',
		defaultMessage: 'No Matches',
		description: 'Label to display when there is no matches in the search result.',
	},
	placeholder: {
		id: 'issue.views.field.parent.edit.placeholder',
		defaultMessage: 'Choose a parent to assign this issue to.',
		description: 'Placeholder text on Parent Link custom field.',
	},
});
