import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	agentsDropdownTitle: {
		id: 'rovo.agents.dropdown.title',
		defaultMessage: 'Agents',
		description: 'Title for the agents dropdown in the editor toolbar',
	},
	agentsViewAgentsLabel: {
		id: 'rovo.agents.dropdown.viewAgents.label',
		defaultMessage: 'View all agents',
		description: 'Label for the dropdown option that allows a user to View all available Agents',
	},
});
