import { trackFetchErrors } from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performDeleteRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getEscalatorBaseUrl } from '@atlassian/jira-servicedesk-incident-management-common/src/utils';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { UnlinkAlertArgs } from '../../../common/ui/alerts-table/table-fields/types';
import type { State } from '../../types';
import { fetchLinkedAlerts } from '../fetch-linked-alerts';
import messages from './messages';

export const unlinkAlert =
	({ cloudId, issueId, alertId, hasOpsgenieAccount = false, analyticsEvent }: UnlinkAlertArgs) =>
	async ({ dispatch }: StoreActionApi<State>) => {
		const { showFlag } = useFlagsService();
		try {
			await performDeleteRequest(
				`${getEscalatorBaseUrl(cloudId)}/issue/${issueId}/incident/associated-alerts`,
				{
					body: JSON.stringify([alertId]),
				},
			);

			dispatch(
				fetchLinkedAlerts({
					issueId,
					cloudId,
					hasOpsgenieAccount,
					analyticsEvent,
				}),
			);

			showFlag({
				type: 'success',
				isAutoDismiss: true,
				title: messages.successTitle,
				description: messages.successDescription,
				testId: 'linked-alerts.services.actions.unlink-alert.success-flag',
			});

			analyticsEvent && fireOperationalAnalytics(analyticsEvent, 'UnlinkAlert succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			showFlag({
				type: 'error',
				isAutoDismiss: true,
				title: messages.errorTitle,
				description: messages.errorDescription,
			});

			trackFetchErrors({
				event: analyticsEvent,
				error: e,
				id: 'unlinkAlert',
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	};
