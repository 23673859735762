import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useDateTimeFormatter } from '@atlassian/jira-issue-format-date/src/main.tsx';
import type { TimeZone } from '@atlassian/jira-shared-types/src/general.tsx';
import type { JiraDateTimePickerValue, FormatDateTimeFn } from '../../common/types';
import messages from './messages';
import { EmptyValue } from './styled';

export type DateTimeFieldReadViewProps = {
	timeZone?: TimeZone | null;
	value: JiraDateTimePickerValue;
	noValueText: string | null | undefined;
	onFormatDateTime?: FormatDateTimeFn;
};

export const DateTimeFieldReadView = ({
	timeZone: overrideTimeZone,
	value,
	noValueText,
	onFormatDateTime,
}: DateTimeFieldReadViewProps) => {
	const { formatMessage } = useIntl();
	const { formatDateTime } = useDateTimeFormatter(overrideTimeZone);

	if (value == null || value === '') {
		return <EmptyValue>{noValueText ?? formatMessage(messages.defaultNoValueText)}</EmptyValue>;
	}

	try {
		return onFormatDateTime ? <>{onFormatDateTime(value)}</> : <>{formatDateTime(value)}</>;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		return null;
	}
};
