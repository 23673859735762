import type { RadioValueItemShape } from '@atlassian/jira-issue-internal-fields/src/radio-select/radio-field-view';

export type RadioOption = {
	id: string;
	value: string;
};

const toItem = (fieldData: RadioOption): RadioValueItemShape => ({
	label: fieldData.value,
	value: fieldData.id,
});

export const transformFromStateValue = (value: RadioOption | null): RadioValueItemShape | null =>
	value ? toItem(value) : null;

export const transformToStateValue = (value: RadioValueItemShape | null): RadioOption | null => {
	if (!value || value.value === undefined) {
		return null;
	}
	return {
		id: value.value,
		value: value.label,
	};
};

export const transformSuggestions = (suggestions: RadioOption[]): RadioValueItemShape[] =>
	(suggestions && suggestions.map(toItem)) || [];
