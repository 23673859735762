import React from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { Emphasise } from '@atlassian/jira-issue-view-activity-common/src/styles';
import GroupedChange from '../../../../common/ui/grouped-change';
import HistoryItem from '../../../../common/ui/history-item';
import { type FlagChangedHistoryItem, FLAG_ADDED } from '../../../../types';
import messages from './messages';

type Props = {
	isGrouped?: Boolean;
	historyItem: FlagChangedHistoryItem;
};

const FlagHistoryItem = ({ historyItem, isGrouped = false }: Props) => {
	const { formatMessage } = useIntl();
	const { actor, timestamp, action } = historyItem;
	const flagMessage = action === FLAG_ADDED ? messages.added : messages.removed;
	return (
		<FormattedMessage
			{...flagMessage}
			values={{
				flag: (
					<Emphasise key="flag">
						{formatMessage(
							expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false)
								? messages.flagLowercase
								: messages.flag,
						)}
					</Emphasise>
				),
			}}
		>
			{(...actionText) =>
				isGrouped && expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false) ? (
					<GroupedChange field={formatMessage(messages.flag)} from={null} to={null} />
				) : (
					<HistoryItem
						testId="issue-history.ui.history-items.history-item.flag-history-item.history-item"
						actor={actor}
						action={actionText}
						timestamp={timestamp}
						change={null}
					/>
				)
			}
		</FormattedMessage>
	);
};

export default FlagHistoryItem;
