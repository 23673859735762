import type { ReactNode } from 'react';
import {
	FULL_ISSUE,
	SERVICEDESK_QUEUES_ISSUE,
	SERVICEDESK_REPORTS_ISSUE,
} from '@atlassian/jira-common-constants/src/analytics-sources';

export type FeedbackRenderer = (
	applicationKey: string,
	isProjectSimplified: boolean,
) => ReactNode | undefined;

export const clickthroughAllowedSources = [
	FULL_ISSUE,
	SERVICEDESK_QUEUES_ISSUE,
	SERVICEDESK_REPORTS_ISSUE,
];
