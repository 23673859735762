import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../types';
import { getSelectableAlertIds } from '../../utils/alert';

export const onSelectAllChange =
	(shouldSelectAll: boolean) =>
	({ getState, setState }: StoreActionApi<State>) => {
		const { linkModalAlerts } = getState();
		const { data } = linkModalAlerts;

		if (data?.results == null) return;

		// no need to merge with previous search results ids, since shouldSelectAll is true only if selectedAlertsIds.length is 0
		const newSelectedAlertsToLinkIds = shouldSelectAll ? getSelectableAlertIds(data.results) : [];

		setState({
			selectedAlertsToLinkIds: newSelectedAlertsToLinkIds,
		});
	};
