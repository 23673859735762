import memoizeOne from 'memoize-one';
import { createFilter } from '@atlaskit/select';
import type { Option } from '../../common/types';

// This is the shape of the option object that the filter function receives.
// The `Option` objects passed into the Select component's `options` prop will be in the `data` field on the internal Option objects. But `label`is copied to the top level.
type InternalOptionShape = {
	label: Option['label'];
	data?: Partial<Option>;
};

export const matchesProperty = (val: string, inputValueIgnoredCase: string) =>
	// label can be Object for new option in CreatableSelect
	typeof val === 'string' &&
	val
		// no need to .trim() since input is already trimmed and we search for .includes() so it does not matter if field has leading or trailing spaces
		.toLowerCase()
		.includes(inputValueIgnoredCase);

const stringifyLabelAndDataFilterValues = <TInternalOption extends InternalOptionShape>(
	option: TInternalOption,
) => {
	const { label, data } = option || {};
	const { filterValues } = data || {};

	const labelString = typeof label === 'string' ? label : '';
	const filterValuesString = (filterValues || []).join(' ');

	return `${labelString} ${filterValuesString}`;
};

/**
 * By default react-select matches by both option.label and option.value: https://github.com/JedWatson/react-select/blob/b7868d23ccd0b9e623b277f971e89020d426fbdb/packages/react-select/src/filters.js#L14.
 * This leads to undesired UX, when filter result includes options without displayed text including the query (which is very confusing for the user): BENTO-5735, BENTO-5655.
 * That's why we need this function.
 */

const filterOptionByLabelAndFilterValuesInternal = memoizeOne(
	() =>
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		createFilter({
			// Take an option object and convert it to a string that needs to partially match the user's query
			stringify: stringifyLabelAndDataFilterValues,
		}) as <TInternalOption extends InternalOptionShape>(
			option: TInternalOption,
			inputValue: string,
		) => boolean,
);

export const filterOptionByLabelAndFilterValues = <TInternalOption extends InternalOptionShape>(
	option: TInternalOption,
	inputValue: string,
) => filterOptionByLabelAndFilterValuesInternal()<TInternalOption>(option, inputValue);
