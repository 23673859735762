import React from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { Global } from '@emotion/react';

import { EVENT_TYPE } from '@atlaskit/editor-common/analytics';
import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import { SafePlugin } from '@atlaskit/editor-common/safe-plugin';
import type {
	EditorPlugin,
	NextEditorPlugin,
	OptionalPlugin,
	PMPluginFactoryParams,
	QuickInsertHandler,
} from '@atlaskit/editor-common/types';
import { WithPluginState } from '@atlaskit/editor-common/with-plugin-state';
import type { AnalyticsPlugin } from '@atlaskit/editor-plugin-analytics';
import type { EditorViewModePlugin } from '@atlaskit/editor-plugin-editor-viewmode';
import type { PrimaryToolbarPlugin } from '@atlaskit/editor-plugin-primary-toolbar';
import type { SelectionMarkerPlugin } from '@atlaskit/editor-plugin-selection-marker';
import type { TypeAheadPlugin } from '@atlaskit/editor-plugin-type-ahead';
import type { WidthPlugin } from '@atlaskit/editor-plugin-width';
import type { PluginKey } from '@atlaskit/editor-prosemirror/state';
import { DecorationSet, type EditorView } from '@atlaskit/editor-prosemirror/view';
import { isResolvingMentionProvider, type MentionProvider } from '@atlaskit/mention/resource';
import { isPromise } from '@atlaskit/mention/types';
import { fg } from '@atlaskit/platform-feature-flags';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';
import type { IDMap } from '@atlassian/ai-model-io/convert-prosemirror-to-markdown/serializer';
import { isFedRamp } from '@atlassian/atl-context';
import { useAIThemeColor } from '@atlassian/generative-ai-modal/styles/theme';
import { getExperimentCohort } from '@atlassian/generative-ai-modal/utils/experiments';

import {
	convertExtension,
	findActionItems,
	getAIPanelContext,
	regenerateExtension,
	summarisePage,
	triggerAIPanelFeedbackCollector,
} from './actions/actions';
import type { EditorPluginAIActions, ExtensionKeys } from './actions/types';
import { aiActorCache } from './ai-actor-cache';
import { type EditorPluginAiInitAEP } from './analytics/types';
import { getAIFeatureFlags } from './analytics/utils';
import { mapConfigItemsToQuickInsertItems } from './config-items/map-config-items-to-quick-insert-items';
import { ExperienceApplication } from './experience-application/ExperienceApplication';
import type { AIManifest } from './extensions/ai-panels/types';
import { isAIPanelsEnabled } from './extensions/ai-panels/utils';
import { getFloatingToolbarConfig } from './floating-toolbar/floating-toolbar';
import { createAIButtonPlugin } from './pm-plugins/ai-button/ai-button-plugin';
import { createAIEventHubPlugin } from './pm-plugins/ai-event-hub/ai-event-hub-plugin';
import { createAIPanelsPlugin } from './pm-plugins/ai-panels/ai-panels-plugin';
import { createAIPlaceholderPlugin } from './pm-plugins/ai-placeholder/ai-placeholder-plugin';
import { createAISpellingGrammarPlugin } from './pm-plugins/ai-spelling-grammar/ai-spelling-grammar-plugin';
import { getConfiguration as getProactiveAIConfig } from './pm-plugins/ai-spelling-grammar/configuration';
import { createEndAIExperienceCommand } from './pm-plugins/decoration/actions';
import { createDecorationPlugin } from './pm-plugins/decoration/decoration-plugin';
import { getPluginState } from './pm-plugins/decoration/decoration-plugin-factory';
import { aiExperienceDecorationPluginKey } from './pm-plugins/decoration/decoration-plugin-key';
import type { AIDecorationExperiencePluginState } from './pm-plugins/decoration/reducer';
import { hasGeneratedContentDecorations } from './pm-plugins/decoration/utils/hasGeneratedContentDecorations';
import {
	getAIExperienceButton,
	getAIExperienceDropdowns,
	getImproveWritingAIButton,
} from './pm-plugins/floating-toolbar-button/floating-toolbar-button';
import { messages as selectionToolbarMessages } from './pm-plugins/floating-toolbar-button/selection-toolbar-messages';
import { createGetEditorViewPlugin } from './pm-plugins/get-editor-view/get-editor-view-plugin';
import { getAgentsDropdown } from './pm-plugins/rovo-agents/agents-dropdown';
import { getRovoAgentsPluginState } from './pm-plugins/rovo-agents/plugin-factory';
import { rovoAgentsPluginKey } from './pm-plugins/rovo-agents/plugin-key';
import { createRovoAgentsPlugin } from './pm-plugins/rovo-agents/rovo-agents-plugin';
import { createSelectionPreviewPlugin } from './pm-plugins/selection-preview/selection-preview-plugin';
import {
	getAIPrimaryToolbarComponent,
	getSpellingAndGrammarPrimaryToolbarComponent,
} from './primary-toolbar/primary-toolbar';
import { getPrimaryToolbarLegacyComponent } from './primary-toolbar/primary-toolbar-legacy';
import type { AIGlobalOptIn, EditorPluginAIProvider, EndExperience } from './types';
import { AgentFetcher } from './ui/components/AgentFetcher/AgentFetcher';
import { LoadableBrowseRovoAgentsModal } from './ui/components/BrowseRovoAgentsModal/BrowseRovoAgentsModal';
import { ModalRegionErrorBoundary } from './ui/components/ExperienceApplicationErrorBoundary/ExperienceApplicationErrorBoundary';
import { PublishToRovo } from './ui/components/PublishToRovo/PublishToRovo';
import { RovoEnabled } from './ui/components/RovoEnabled/RovoEnabled';
import { SubscribeToRovo } from './ui/components/SubscribeToRovo/SubscribeToRovo';
import { ModalRegion } from './ui/modal/modal';
import { globalStyles } from './ui/modal/styles';

export type AIPlugin = NextEditorPlugin<
	'aiExperience',
	{
		pluginConfiguration: {
			editorPluginAIProvider: EditorPluginAIProvider;
			// TODO: make this a required prop once this version is implemented in confluence
			aiGlobalOptIn?: AIGlobalOptIn;
		};
		dependencies: [
			OptionalPlugin<AnalyticsPlugin>,
			OptionalPlugin<EditorViewModePlugin>,
			OptionalPlugin<TypeAheadPlugin>,
			OptionalPlugin<SelectionMarkerPlugin>,
			OptionalPlugin<WidthPlugin>,
			OptionalPlugin<PrimaryToolbarPlugin>,
		];

		actions: EditorPluginAIActions;
	}
>;

const GlobalWrapper = ({ children }: { children: React.ReactNode }) => {
	const aiThemeColor = useAIThemeColor();

	return (
		<>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */}
			<Global styles={globalStyles(aiThemeColor)} />
			{children}
		</>
	);
};

/**
 * This is an {@link EditorPlugin}.
 * [Editor Plugins](https://product-fabric.atlassian.net/wiki/spaces/E/pages/56115413/New+Editor+Architecture#NewEditorArchitecture-Implementation)
 * are the mechanism for extending the editor with additional functionality such as nodes, marks, UI components etc.
 *
 * This EditorPlugin is responsible for adding such functionality for the generative ai experiences in the Editor.
 *
 * It is setup with an {@link EditorPluginAIProvider} which provides the configuration for the plugin including;
 * - the api to use for generating responses to prompts
 * - and the list of prompts that should be available in the editor grouped by category (empty, range)
 * @see {@link EditorPluginAIProvider} for more details.
 */
export class EditorPluginAI implements EditorPlugin {
	name = 'aiExperience' as const;
	editorPluginAIProvider: EditorPluginAIProvider;

	// Facade EditorPlugin methods
	pmPlugins?: EditorPlugin['pmPlugins'];
	pluginsOptions?: EditorPlugin['pluginsOptions'];
	primaryToolbarComponent?: EditorPlugin['primaryToolbarComponent'];
	contentComponent?: EditorPlugin['contentComponent'];

	private nextPlugin?: ReturnType<AIPlugin>;

	constructor({
		editorPluginAIProvider,
		aiGlobalOptIn,
	}: {
		editorPluginAIProvider: EditorPluginAIProvider;
		aiGlobalOptIn: AIGlobalOptIn;
	}) {
		this.editorPluginAIProvider = editorPluginAIProvider;

		/**
		 * WARNING: Do not remove the isFedRamp check.
		 * This is to ensure that AI functionality is not enabled in FedRamp environments.
		 */
		if (aiGlobalOptIn.status === 'disabled' || isFedRamp()) {
			return;
		}

		this.nextPlugin = NextEditorPluginAI({
			config: {
				editorPluginAIProvider,
				aiGlobalOptIn,
			},
		});

		this.pluginsOptions = this.nextPlugin.pluginsOptions;
		this.pmPlugins = this.nextPlugin.pmPlugins;
		this.primaryToolbarComponent = this.nextPlugin.primaryToolbarComponent;
		this.contentComponent = this.nextPlugin.contentComponent;
	}
}

/**
 * This is exported out to unblock the eventual deprecation of
 * `dangerouslyAppendPlugins`, when plugins can be supplied/added to presets in
 * EditorNext - there should be no functional differences between a
 * `NextEditorPluginAI` & `EditorPluginAI`.
 */
export const NextEditorPluginAI: AIPlugin = ({
	config: { editorPluginAIProvider, aiGlobalOptIn },
	api,
}) => {
	let editorView: EditorView | undefined;
	const setEditorView = (newEditorView: EditorView) => {
		editorView = newEditorView;
	};

	let providerFactory: ProviderFactory;
	const setProviderFactory = (newProviderFactory: ProviderFactory) => {
		providerFactory = newProviderFactory;
	};
	let newIDMap = {};
	const updateIdMap = ({
		idMap,
		selectionType,
	}: {
		idMap: IDMap;
		selectionType: 'range' | 'empty';
	}) => {
		newIDMap = idMap;
	};

	let mentionProvider: MentionProvider | undefined;

	/**
	 * Helper function to retrieve the name details for a mention from the mentionProvider
	 */
	const getMentionNameDetails = async (id: string) => {
		if (!mentionProvider || !isResolvingMentionProvider(mentionProvider)) {
			return;
		}

		const nameDetails = mentionProvider.resolveMentionName(id);

		if (isPromise(nameDetails)) {
			const mention = await nameDetails;
			return mention;
		}

		return nameDetails;
	};

	const setMentionProvider = (providerFactory: ProviderFactory) => {
		const handler = (_name: string, providerPromise?: Promise<MentionProvider>) => {
			if (providerPromise) {
				providerPromise.then((provider) => {
					mentionProvider = provider;
					providerFactory.unsubscribe('mentionProvider', handler);
				});
			}
		};
		providerFactory.subscribe('mentionProvider', handler);
	};

	// TODO: clean up once this version is implemented in confluence and aiGlobalOptIn is made mandatory
	const aiOptIn = aiGlobalOptIn || {
		status: 'enabled',
		triggerOptInFlow: () => {},
	};

	const isFullPageExperimentsEnabled = !!editorPluginAIProvider.isFullPageExperimentsEnabled;
	const { isProactiveAISupported, proactiveAIConfig } =
		getProactiveAIConfig(editorPluginAIProvider);

	const quickInsert: QuickInsertHandler = (intl) => {
		const configItems = [];
		const { baseGenerate, getSuggestions, disableQuickInsert } = editorPluginAIProvider.emptyConfig;

		if (!disableQuickInsert) {
			configItems.push(baseGenerate, ...getSuggestions({}));
		}

		const quickInsertItems = mapConfigItemsToQuickInsertItems({
			configItems,
			formatMessage: intl.formatMessage,
			aiGlobalOptIn: aiOptIn,
			// The editor view will always be available when quick insert items are being created
			editorView: editorView!,
		});

		return quickInsertItems;
	};

	quickInsert.disableMemo = true;

	api?.primaryToolbar?.actions.registerComponent({
		name: 'aiExperience',
		component: getAIPrimaryToolbarComponent({
			api,
			editorPluginAIProvider,
			aiGlobalOptIn: aiOptIn,
		}),
	});
	if (isProactiveAISupported) {
		api?.primaryToolbar?.actions.registerComponent({
			name: 'spellCheck',
			component: getSpellingAndGrammarPrimaryToolbarComponent({
				editorPluginAIProvider,
			}),
		});
	}

	return {
		name: 'aiExperience',
		pmPlugins: () => {
			const plugins = [
				{
					name: 'ai-experience-actor',
					plugin: () =>
						new SafePlugin({
							view: (editorView) => {
								// This sets up an [actor](https://xstate.js.org/docs/guides/actors.html#actor-api) using
								// the editor-plugin-ai machine and starts it up.
								const editorPluginAIActor = aiActorCache.getActor(editorView);
								return {
									destroy: () => {
										// This cleansup any listeners from the actor -- to ensure we don't leak memory.
										editorPluginAIActor?.stop();
										aiActorCache.delete(editorView);
									},
								};
							},
						}),
				},
				{
					name: 'aiExperience',
					plugin: (options: PMPluginFactoryParams) => {
						const payload: EditorPluginAiInitAEP = {
							action: 'init',
							actionSubject: 'editor',
							actionSubjectId: 'editorPluginAI',
							eventType: EVENT_TYPE.TRACK,
							attributes: {
								aiGlobalOptIn: aiOptIn?.status,
								aiPluginFeatureFlags: getAIFeatureFlags(),
							},
						};
						// @ts-expect-error - This prevent type errors when trying to pass EditorPluginAI payload to AnalyticsEventPayload
						options.dispatchAnalyticsEvent(payload);

						return createDecorationPlugin({
							dispatchAnalyticsEvent: options.dispatchAnalyticsEvent,
							dispatch: options.dispatch,
							api,
						});
					},
				},
				{
					name: 'ai-experience-get-editor-view-and-provider-factory',
					plugin: (options: PMPluginFactoryParams) => {
						setProviderFactory(options.providerFactory);
						setMentionProvider(options.providerFactory);
						return createGetEditorViewPlugin({
							setEditorView: setEditorView,
						});
					},
				},
				{
					name: 'ai-experience-ai-button',
					plugin: (options: PMPluginFactoryParams) =>
						createAIButtonPlugin({ dispatch: options.dispatch }),
				},
				{
					name: 'ai-experience-selection-preview',
					plugin: () => createSelectionPreviewPlugin(),
				},
			];

			if (isProactiveAISupported) {
				plugins.push({
					name: 'ai-spelling-grammar-plugin',
					plugin: ({ dispatch, getIntl }: PMPluginFactoryParams) => {
						return createAISpellingGrammarPlugin({
							dispatch,
							getIntl,
							proactiveAIConfig,
						});
					},
				});
			}

			if (fg('platform_editor_ai_event_hub')) {
				plugins.push({
					name: 'ai-event-hub',
					plugin: (_options: PMPluginFactoryParams) =>
						createAIEventHubPlugin({
							aiGlobalOptIn: aiOptIn,
							editorPluginAIProvider,
						}),
				});
			}

			if (isAIPanelsEnabled()) {
				plugins.push({
					name: 'ai-panels',
					plugin: () => createAIPanelsPlugin(),
				});
			}

			const persistentPlaceholderCohort = getExperimentCohort(
				'editor_ai_-_persistent_placeholder_experiment',
			);

			if (
				isFullPageExperimentsEnabled &&
				persistentPlaceholderCohort === 'persistent-placeholder' &&
				aiOptIn.status === 'enabled'
			) {
				plugins.push({
					name: 'ai-patch-editor-placeholder-plugin',
					plugin: (options: PMPluginFactoryParams) =>
						createAIPlaceholderPlugin({
							getIntl: options.getIntl,
							api,
						}),
				});
			}

			if (editorPluginAIProvider.isRovoEnabled) {
				plugins.push({
					name: 'editor-ai-rovo-agents',
					plugin: (options: PMPluginFactoryParams) => createRovoAgentsPlugin(options),
				});
			}

			return plugins;
		},

		actions: {
			triggerAIPanelFeedbackCollector: (extensionKey, markdown, locale, fireAnalyticsEvent) => {
				return triggerAIPanelFeedbackCollector({
					extensionKey,
					markdown,
					locale,
					editorPluginAIProvider,
					fireAnalyticsEvent,
				});
			},
			getInitialAIPanelContext: ({ intl, configItem, intentSchemaId }) => {
				return getAIPanelContext({
					configItem,
					intentSchemaId,
					editorView,
					providerFactory,
					idMap: newIDMap,
					intl,
					updateIdMap,
				});
			},
			summariseDocument: (callback, intl, initialContext, analyticsContext) => {
				summarisePage({
					analyticsContext,
					editorView,
					generativeAIApiUrl: editorPluginAIProvider.generativeAIApiUrl,
					product: editorPluginAIProvider.product,
					callback,
					intl,
					initialContext,
				});
			},
			findActionItems: (callback, intl, initialContext, analyticsContext) => {
				findActionItems({
					analyticsContext,
					editorView,
					generativeAIApiUrl: editorPluginAIProvider.generativeAIApiUrl,
					product: editorPluginAIProvider.product,
					callback,
					intl,
					initialContext,
				});
			},
			convertExtension: (
				api: AIManifest['api'],
				localId: string,
				extensionKey: ExtensionKeys,
				addToHistory?: boolean,
			) => {
				convertExtension({
					api,
					editorView,
					localId,
					extensionKey,
					idMap: newIDMap,
					addToHistory,
				});
			},
			regenerateExtension: (
				api: AIManifest['api'],
				localId: string,
				extensionKey: ExtensionKeys,
			) => {
				regenerateExtension({
					api,
					editorView,
					localId,
					extensionKey,
					idMap: newIDMap,
				});
			},
		},
		pluginsOptions: {
			selectionToolbar: (state, intl) => {
				if (editorPluginAIProvider.disableAISelectionToolbar) {
					return;
				}

				let items = [];

				switch (true) {
					case editorExperiment('platform_editor_ai_floating_toolbar_v2', 'leading', {
						exposure: true,
					}):
						items = [
							getAIExperienceButton({
								intl,
								rangeBaseGenerate: editorPluginAIProvider.rangeConfig.baseGenerate,
								hideTitle: true,
								aiGlobalOptIn: aiOptIn,
								title: selectionToolbarMessages.tryAIToolbarIconTitle,
								tooltip: selectionToolbarMessages.tryAIToolbarIconTooltip,
							}),
							getImproveWritingAIButton({
								intl,
								hideTitle: false,
								aiGlobalOptIn: aiOptIn,
							}),
						];
						break;

					case editorExperiment('platform_editor_ai_floating_toolbar_v2', 'trailing', {
						exposure: true,
					}):
						items = [
							getImproveWritingAIButton({
								intl,
								hideTitle: false,
								aiGlobalOptIn: aiOptIn,
							}),
							getAIExperienceButton({
								intl,
								rangeBaseGenerate: editorPluginAIProvider.rangeConfig.baseGenerate,
								hideTitle: true,
								aiGlobalOptIn: aiOptIn,
								title: selectionToolbarMessages.tryAIToolbarIconTitle,
								tooltip: selectionToolbarMessages.tryAIToolbarIconTooltip,
							}),
						];
						break;

					case editorExperiment('platform_editor_ai_floating_toolbar_v2', 'control', {
						exposure: true,
					}):
					default:
						const rovoAgentsPluginState = getRovoAgentsPluginState(state);

						const aiDropdowns = getAIExperienceDropdowns({
							intl,
							rangeConfig: editorPluginAIProvider.rangeConfig,
							aiGlobalOptIn: aiOptIn,
						});

						const aiIconButton = getAIExperienceButton({
							intl,
							rangeBaseGenerate: editorPluginAIProvider.rangeConfig.baseGenerate,
							hideTitle: !!aiDropdowns.length,
							aiGlobalOptIn: aiOptIn,
						});

						items.push(aiIconButton, ...aiDropdowns);

						if (state && rovoAgentsPluginState?.enabled) {
							const rovoAgentsState = rovoAgentsPluginKey.getState(state);
							const agents = rovoAgentsState?.agents;

							const agentsDropdown = getAgentsDropdown({
								intl,
								agents: agents?.slice(0, 3),
								aiGlobalOptIn: aiOptIn,
								publish: rovoAgentsPluginState.publish,
								editorPluginAIProvider,
							});

							items.push(...agentsDropdown);
						}
						break;
				}

				return {
					// make sure the AI button is after the comment button in Confluence
					rank: -10,
					isToolbarAbove: true,
					items,
				};
			},
			quickInsert: quickInsert,
			floatingToolbar: getFloatingToolbarConfig({
				aiGlobalOptIn: aiOptIn,
				editorPluginAIProvider,
			}),
		},

		contentComponent: ({ editorView, wrapperElement, appearance }) => {
			const endExperience: EndExperience = (options) => {
				// TODO POSTEAP
				// When the full experience is moved to the state machine -- the intention is
				// to have the modal experience ended via state transitions like "action taken"
				// or "modal dismissed" and have side effects (actions) which run as part of
				// those transition to do any clean up.
				// Also to note -- this is not currently the end of the AI experience
				// following this being called -- we decorate the document with a highlight following
				// actions taken.

				const state = getPluginState(editorView.state);

				const decoSet =
					hasGeneratedContentDecorations(state) || options?.preserveEditorSelectionOnComplete
						? state.modalDecorationSet
						: undefined;

				const endAIExperienceCommand = createEndAIExperienceCommand(
					decoSet,
					options?.preserveEditorSelectionOnComplete,
				);
				endAIExperienceCommand(editorView.state, editorView.dispatch);

				editorView.focus();
			};

			// We use this point to render the modal if needed instead of decorators
			// The reason this is preferrable is it has access to mount points
			// where decorators are rendered with a mount point within the Editor content
			// and longer modals get clipped as a result.
			// TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
			const renderModalRegionAIButton = (
				aiExperienceDecorationPluginState?: AIDecorationExperiencePluginState,
			) => {
				// If there are no active decorations -- then we don't want to render the ai experience.
				if (
					!aiExperienceDecorationPluginState ||
					aiExperienceDecorationPluginState.modalDecorationSet === DecorationSet.empty
				) {
					return null;
				}

				if (hasGeneratedContentDecorations(aiExperienceDecorationPluginState)) {
					return null;
				}

				const modalDecorationElement =
					editorView?.dom.querySelector<HTMLElement>('.ai-modal-end-widget') ||
					editorView?.dom.querySelector<HTMLElement>('.ai-selection-node');

				// This protects against cases where there is an active decoration
				// but the document has not been updated to include it
				if (!modalDecorationElement) {
					return null;
				}

				// Prioritise getting position from inlineDecoration,
				// otherwise get decoration position from start or end widget
				let positions: [number, number];
				let decoration =
					aiExperienceDecorationPluginState.modalDecorationSet
						.find(undefined, undefined, (spec) => spec.key === 'inlineDecoration')
						.shift() ||
					aiExperienceDecorationPluginState.modalDecorationSet
						.find(
							undefined,
							undefined,
							(spec) => spec.key === 'startWidgetDecoration' || spec.key === 'endWidgetDecoration',
						)
						.shift();

				if (decoration) {
					positions = [decoration.from, decoration.to];
				} else {
					const nodeDecorations = aiExperienceDecorationPluginState.modalDecorationSet.find(
						undefined,
						undefined,
						(spec) => spec.key === 'ai-nodeDecoration',
					);
					if (nodeDecorations.length > 0) {
						const startPos = Math.min(...nodeDecorations.map((decoration) => decoration.from));
						const endPos = Math.max(...nodeDecorations.map((decoration) => decoration.to));
						positions = [startPos, endPos];
					}
					decoration = nodeDecorations.shift();
				}

				if (!decoration) {
					return null;
				}

				return (
					<ModalRegion
						lastTriggeredFrom={aiExperienceDecorationPluginState.lastTriggeredFrom}
						key={aiExperienceDecorationPluginState.modalMountedTimeStamp}
						decoration={decoration}
						modalDecorationElement={modalDecorationElement}
						editorView={editorView}
						editorRelativeWrapper={wrapperElement?.offsetParent}
						endExperience={endExperience}
						appearance={appearance}
					>
						{aiExperienceDecorationPluginState.configItem && editorView && (
							<ExperienceApplication
								configItem={aiExperienceDecorationPluginState.configItem}
								editorPluginAIProvider={editorPluginAIProvider}
								endExperience={endExperience}
								positions={positions!}
								editorView={editorView}
								providerFactory={providerFactory}
								// TODO POSTEAP
								// This prop is being used for analytics but is temporary.
								// Plugin re-architecture will change this.
								// https://product-fabric.atlassian.net/wiki/spaces/EUXQ/pages/3573548013/Introducing+AI+Dev+Documentation+TODO+AIFOLLOWUP+audit#Plugin-re-architecture
								lastTriggeredFrom={aiExperienceDecorationPluginState.lastTriggeredFrom}
								triggerMethod={aiExperienceDecorationPluginState.triggerMethod}
								appearance={appearance}
								initialPrompt={aiExperienceDecorationPluginState?.initialPrompt}
								getMentionNameDetails={getMentionNameDetails}
								triggeredFor={aiExperienceDecorationPluginState.triggeredFor}
							/>
						)}
					</ModalRegion>
				);
			};

			const renderModalRegionNonAIButton = (
				aiExperienceDecorationPluginState?: AIDecorationExperiencePluginState,
			) => {
				// If there are no active decorations -- then we don't want to render the ai experience.
				if (
					!aiExperienceDecorationPluginState ||
					aiExperienceDecorationPluginState.modalDecorationSet === DecorationSet.empty
				) {
					return null;
				}

				if (hasGeneratedContentDecorations(aiExperienceDecorationPluginState)) {
					return null;
				}

				const modalDecorationElement =
					editorView?.dom.querySelector<HTMLElement>('.ai-modal-end-widget');

				// This protects against cases where there is an active decoration
				// but the document has not been updated to include it
				if (!modalDecorationElement) {
					return null;
				}

				// Prioritise getting position from inlineDecoration,
				// otherwise get decoration position from start or end widget
				const decoration =
					aiExperienceDecorationPluginState.modalDecorationSet
						.find(undefined, undefined, (spec) => spec.key === 'inlineDecoration')
						.shift() ||
					aiExperienceDecorationPluginState.modalDecorationSet
						.find(
							undefined,
							undefined,
							(spec) => spec.key === 'startWidgetDecoration' || spec.key === 'endWidgetDecoration',
						)
						.shift();

				if (!decoration) {
					return null;
				}
				const positions: [number, number] = [decoration.from, decoration.to];

				return (
					<ModalRegion
						lastTriggeredFrom={aiExperienceDecorationPluginState.lastTriggeredFrom}
						key={aiExperienceDecorationPluginState.modalMountedTimeStamp}
						decoration={decoration}
						modalDecorationElement={modalDecorationElement}
						editorView={editorView}
						editorRelativeWrapper={wrapperElement?.offsetParent}
						endExperience={endExperience}
						appearance={appearance}
					>
						{aiExperienceDecorationPluginState.configItem && editorView && (
							<ExperienceApplication
								configItem={aiExperienceDecorationPluginState.configItem}
								editorPluginAIProvider={editorPluginAIProvider}
								endExperience={endExperience}
								positions={positions}
								editorView={editorView}
								providerFactory={providerFactory}
								// TODO POSTEAP
								// This prop is being used for analytics but is temporary.
								// Plugin re-architecture will change this.
								// https://product-fabric.atlassian.net/wiki/spaces/EUXQ/pages/3573548013/Introducing+AI+Dev+Documentation+TODO+AIFOLLOWUP+audit#Plugin-re-architecture
								lastTriggeredFrom={aiExperienceDecorationPluginState.lastTriggeredFrom}
								triggerMethod={aiExperienceDecorationPluginState.triggerMethod}
								appearance={appearance}
								initialPrompt={aiExperienceDecorationPluginState?.initialPrompt}
								getMentionNameDetails={getMentionNameDetails}
							/>
						)}
					</ModalRegion>
				);
			};

			// TODO: AI Button experiment cleanup - platform_editor_ai_ai_button_block_elements
			const renderModalRegion = editorExperiment(
				'platform_editor_ai_ai_button_block_elements',
				'test',
			)
				? renderModalRegionAIButton
				: renderModalRegionNonAIButton;

			let pluginsToTrack: Record<string, PluginKey> = {
				aiExperienceDecorationPluginState: aiExperienceDecorationPluginKey,
				rovoAgentsPluginState: rovoAgentsPluginKey,
			};

			return (
				<GlobalWrapper>
					<RovoEnabled editorView={editorView} editorPluginAIProvider={editorPluginAIProvider}>
						<PublishToRovo
							editorView={editorView}
							getMentionNameDetails={getMentionNameDetails}
							editorPluginAIProvider={editorPluginAIProvider}
						/>
						<AgentFetcher editorPluginAIProvider={editorPluginAIProvider} editorView={editorView} />
						<SubscribeToRovo
							editorView={editorView}
							product={editorPluginAIProvider.product}
							onDocChangeByAgent={editorPluginAIProvider.onDocChangeByAgent}
						/>
					</RovoEnabled>
					<WithPluginState
						plugins={pluginsToTrack}
						render={({ aiExperienceDecorationPluginState, rovoAgentsPluginState }) => {
							// The WithPluginState function does not support typing the plugin states it returns
							// so we need to manually assert this.
							const modalRegion = renderModalRegion(aiExperienceDecorationPluginState);

							// TODO POSTEAP
							// This error boundary will be unexpectedly positioned
							// as it's not wrapped in the ModalRegion.
							// In the case the modal region throws an exception
							// we should come up with an alternative positioning
							// strategy for error feedback.
							return (
								<ModalRegionErrorBoundary onCloseFallback={endExperience}>
									{modalRegion ?? null}
									{rovoAgentsPluginState?.isBrowseModalOpen && fg('rovo-chat-browse-agents') && (
										<LoadableBrowseRovoAgentsModal
											editorView={editorView}
											aiGlobalOptIn={aiOptIn}
											editorPluginAIProvider={editorPluginAIProvider}
											startingConfig={aiExperienceDecorationPluginState.configItem}
											positions={rovoAgentsPluginState?.positions}
										/>
									)}
								</ModalRegionErrorBoundary>
							);
						}}
					/>
				</GlobalWrapper>
			);
		},

		primaryToolbarComponent: !api?.primaryToolbar
			? getPrimaryToolbarLegacyComponent({
					editorPluginAIProvider,
					aiGlobalOptIn: aiOptIn,
					isProactiveAISupported,
				})
			: undefined,
	};
};
