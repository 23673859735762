import React, { useMemo, type FunctionComponent } from 'react';
import { styled } from '@compiled/react';
import Heading from '@atlaskit/heading';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { AdControlProvider } from '@atlassian/ad-control-toolkit';
import { SuggestRelatedResourcesModalContainer } from '@atlassian/jira-ai-related-resources/src/ui';
import { SuggestRelatedResourcesButton } from '@atlassian/jira-ai-related-resources/src/ui/suggest-related-resources-button';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types';
import { LICENSED_PRODUCTS } from '@atlassian/jira-common-util-get-tenant-context';
import { ConfluenceFeatureKeys } from '@atlassian/jira-confluence-integration-controls/src/constants.tsx';
import { EmbeddedConfluenceSidepanelContainer } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-embedded-confluence-side-panel/index.tsx';
import { useIsCrossSellEnabled } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-is-cross-sell-enabled/index.tsx';
import { useUserHasConfluenceAccess } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-user-has-confluence-access/index.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';
import {
	useIsAiOptInEnabled,
	useIsJiraIssue,
} from '@atlassian/jira-issue-context-service/src/main';
import { JIRA_ISSUE_LINKED_MENTIONED_PAGES } from '@atlassian/jira-issue-create-confluence-content/src/common/constants/embedded-confluence-source.tsx';
import type {
	ConfluencePage,
	ConfluenceWhiteboard,
} from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import type { IssueType } from '@atlassian/jira-issue-shared-types/src/common/types/issue-hierarchy-type.tsx';
import type { FailedRemoteLink } from '@atlassian/jira-issue-shared-types/src/common/types/remote-link-error-type.tsx';
import { ConfluencePageLineCardGroup } from '@atlassian/jira-issue-view-common-views/src/confluence-content-line-card/ui/confluence-page-line-card-group-view/confluence-page-line-card-group-view.tsx';
import { ConfluenceWhiteboardLineCardGroup } from '@atlassian/jira-issue-view-common-views/src/confluence-content-line-card/ui/confluence-whiteboard-line-card-group-view/confluence-whiteboard-line-card-group-view.tsx';
import LineCardSkeleton from '@atlassian/jira-issue-view-common-views/src/skeleton/item-line-card-group';
import { Container } from '@atlassian/jira-issue-view-common-views/src/smart-link-content/styled';
import {
	SectionHeading,
	SectionHeadingTitle,
	SectionHeadingIcons,
} from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view';
import {
	isCrossJoinContentPreviewEnabled,
	isWhiteboardStandaloneEnabled,
} from '@atlassian/jira-issue-view-feature-flags';
import Placeholder from '@atlassian/jira-placeholder';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import type { Href, RemoteLinkGlobalId } from '@atlassian/jira-shared-types/src/general.tsx';
import { isConfluencePageDummyLinkExperiment } from '../../feature-flags';
import { ELIGIBLE_ISSUE_TYPES } from '../confluence-placeholder-template-experiment/common/constants';
import { ConfluenceDummyLinkProvider } from '../confluence-placeholder-template-experiment/controllers/confluence-dummy-link-context';
import AddButton from './add-button';
import ConfluencePageCreateLinkView from './create-link';
import DummyConfluenceLinkCoUseLazy from './dummy-confluence-link-co-use-lazy';
import DummyConfluenceLinkViewLazy from './dummy-confluence-link-view-lazy';
import { LinkedMentionedContentPreview } from './linked-mentioned-content-preview';

const LINKED_PAGES_GROUP_ID = 'linked-confluence-pages';
const LINKED_WHITEBOARDS_GROUP_ID = 'linked-confluence-whiteboards';
const MENTIONED_PAGES_GROUP_ID = 'mentioned-confluence-pages';

interface ConfluenceContentWrapperProps {
	shouldWrapInSidePanelContainer: boolean;
	children: React.ReactNode;
}
const ConfluenceContentWrapper = ({
	shouldWrapInSidePanelContainer,
	children,
}: ConfluenceContentWrapperProps) =>
	shouldWrapInSidePanelContainer ? (
		<EmbeddedConfluenceSidepanelContainer>{children}</EmbeddedConfluenceSidepanelContainer>
	) : (
		<>{children}</>
	);

export type ConfluenceContentProps = {
	isCreateLinkedPageOpened: boolean;
	isConfluencePageLinksEnabled?: boolean;
	canLinkConfluencePage: boolean;
	linkedPages: (ConfluencePage | FailedRemoteLink)[] | null | undefined;
	linkedPagesCachedCount?: number;
	linkedWhiteboards: ConfluenceWhiteboard[] | null | undefined;
	linkedWhiteboardsCachedCount?: number;
	systemConfluenceAppLinkUrl: string | undefined;
	licensedProducts?: { [key: string]: boolean };
	mentionedPages?: (ConfluencePage | FailedRemoteLink)[];
	mentionedPagesCachedCount?: number;
	onLinkedPageClicked?: () => void;
	onLinkedWhiteboardClicked?: () => void;
	onMentionedPageClicked?: () => void;
	onAuthenticateApplink: (href: Href) => void;
	onDeleteConfluencePageLink: (id: RemoteLinkGlobalId) => void;
	onAddButtonClicked: () => void;
	onShowAISuggestionsClicked?: () => void;
	projectType?: string;
	issueType?: IssueType;
	projectName?: string;
	hasShownIntentToXflowToConfluence?: boolean;
	hasActivatedConfluenceWithinFourteenDays?: boolean;
	isAISuggestionsOpen?: boolean;
};

const ConfluenceContent: FunctionComponent<ConfluenceContentProps> = ({
	isCreateLinkedPageOpened,
	isConfluencePageLinksEnabled = false,
	canLinkConfluencePage,
	linkedPages,
	linkedPagesCachedCount = 0,
	linkedWhiteboards,
	linkedWhiteboardsCachedCount = 0,
	systemConfluenceAppLinkUrl,
	licensedProducts,
	mentionedPages,
	mentionedPagesCachedCount = 0,
	onLinkedPageClicked,
	onLinkedWhiteboardClicked,
	onMentionedPageClicked,
	onAuthenticateApplink,
	onDeleteConfluencePageLink,
	onAddButtonClicked,
	onShowAISuggestionsClicked,
	projectType,
	issueType,
	projectName,
	hasShownIntentToXflowToConfluence,
	hasActivatedConfluenceWithinFourteenDays = false,
	isAISuggestionsOpen = false,
}: ConfluenceContentProps) => {
	const isIssueTypeEligibleForPlaceholderTemplate = useMemo(
		() => (issueType ? ELIGIBLE_ISSUE_TYPES.includes(issueType.name.toLowerCase()) : false),
		[issueType],
	);
	const { hasAccess: hasConfluenceAccess } = useUserHasConfluenceAccess(
		JIRA_ISSUE_LINKED_MENTIONED_PAGES,
	);
	const hasLinkedPages = useMemo(() => linkedPages && linkedPages.length > 0, [linkedPages]);

	const hasMentionedPages = useMemo(
		() => mentionedPages && mentionedPages.length > 0,
		[mentionedPages],
	);

	const hasLinkedWhiteboards = useMemo(
		() => linkedWhiteboards && linkedWhiteboards.length > 0,
		[linkedWhiteboards],
	);
	const hasContent = useMemo(
		() =>
			hasLinkedPages ||
			hasMentionedPages ||
			(isWhiteboardStandaloneEnabled() && hasLinkedWhiteboards),
		[hasLinkedPages, hasLinkedWhiteboards, hasMentionedPages],
	);

	const shouldShowConfluenceContent = useMemo(
		() => isCreateLinkedPageOpened || hasContent,
		[hasContent, isCreateLinkedPageOpened],
	);

	const isAiOptInEnabled = fg('jira_ai_powered_issue_related_confluence_resources')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsAiOptInEnabled()
		: false;

	const isJiraIssue: boolean | null = fg('jira_ai_powered_issue_related_confluence_resources')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsJiraIssue()
		: null;

	const canShowAISuggestions = fg('jira_ai_powered_issue_related_confluence_resources')
		? isAiOptInEnabled && canLinkConfluencePage && isJiraIssue
		: false;

	const totalContentCount = useMemo(() => {
		/*
        In order to display a preview for linked & mentioned confluence
        content, we need to exclude the count of app linked content if
        any. When the user dosn't have access to confluence in the current
        site, but has access to an applinked site, then we still want to show
        the applinked content to the user. The links to be displayed are
        contained with the 'pages' or the 'whiteboards' array selector. The
        length of these arrays should be subtracted from the cached count,
        because cached count would have the total number of all links,
        including the applinked ones.

        For a scenario where these arrays are empty, we display the cached
        count as is. (Unless there are any config errors with the applinks, the
        array must have links to the app-linked content).
        */
		const linkedPagesCount = linkedPages?.length ?? 0;
		const mentionedPagesCount = mentionedPages?.length ?? 0;
		const linkedWhiteboardsCount = linkedWhiteboards?.length ?? 0;

		const totalCount =
			linkedPagesCachedCount -
			linkedPagesCount +
			(mentionedPagesCachedCount - mentionedPagesCount) +
			(linkedWhiteboardsCachedCount - linkedWhiteboardsCount);

		return totalCount < 0 ? 0 : totalCount;
	}, [
		linkedPages?.length,
		linkedPagesCachedCount,
		linkedWhiteboards?.length,
		linkedWhiteboardsCachedCount,
		mentionedPages?.length,
		mentionedPagesCachedCount,
	]);

	const [linkedContentCrossJoinExpConfig] = UNSAFE_noExposureExp(
		'platform_embed-conf_bento_linked_crossjoin_exp',
	);
	const linkedContentCrossJoinExpCohort = linkedContentCrossJoinExpConfig.get(
		'cohort',
		'not-enrolled',
	);

	const isCrossSellEnabled = useIsCrossSellEnabled(
		ConfluenceFeatureKeys.CREATE_MENU_CONFLUENCE_CONTENT,
	);

	const shouldWrapInSidePanelContainer = useMemo(() => {
		const [embedConfluenceSidepanelConfig] = UNSAFE_noExposureExp(
			'jira_issue_view_side_by_side_modeless_ep_exp',
		);
		const isInEmbedConfSidepanelExperiment =
			embedConfluenceSidepanelConfig.get('cohort', 'not-enrolled') === 'experiment';

		return isInEmbedConfSidepanelExperiment;
	}, []);

	const confluenceContentPreviewDetails = useMemo(() => {
		const tenantHasConfluence = !!licensedProducts?.[LICENSED_PRODUCTS.CONFLUENCE];
		const canCrossJoin = tenantHasConfluence && !hasConfluenceAccess && totalContentCount > 0;
		const eligibleForLinkedPageCrossJoinExperiment =
			isCrossJoinContentPreviewEnabled() && canCrossJoin;
		const showContentPreview =
			eligibleForLinkedPageCrossJoinExperiment &&
			linkedContentCrossJoinExpCohort === 'experiment' &&
			isCrossSellEnabled;

		return {
			showContentPreview,
			eligibleForLinkedPageCrossJoinExperiment,
		};
	}, [
		linkedContentCrossJoinExpCohort,
		licensedProducts,
		totalContentCount,
		isCrossSellEnabled,
		hasConfluenceAccess,
	]);

	const hasAnyLinkedPageLoaded = useMemo(
		() => linkedPages && linkedPages.length > 0,
		[linkedPages],
	);

	const hasAnyMentionedPageLoaded = useMemo(
		() => mentionedPages && mentionedPages.length > 0,
		[mentionedPages],
	);

	const { showContentPreview, eligibleForLinkedPageCrossJoinExperiment } =
		confluenceContentPreviewDetails;

	if (
		isConfluencePageLinksEnabled &&
		(shouldShowConfluenceContent || eligibleForLinkedPageCrossJoinExperiment)
	) {
		const shouldShowHeading = shouldShowConfluenceContent || showContentPreview;
		const shouldShowCoUseExperiment = !hasContent;

		return (
			<ConfluenceContentWrapper shouldWrapInSidePanelContainer={shouldWrapInSidePanelContainer}>
				{shouldShowHeading && (
					<StyledSectionHeading>
						<SectionHeadingTitle>
							{isWhiteboardStandaloneEnabled() ? (
								<FormattedMessage
									id="issue.details.confluence-content.heading"
									defaultMessage="Confluence content"
									description="Heading for linked Confluence content section."
								/>
							) : (
								<FormattedMessage
									id="issue.details.confluence-pages.heading"
									defaultMessage="Confluence pages"
									description="Heading for linked Confluence pages section."
								/>
							)}
						</SectionHeadingTitle>
						<SectionHeadingIcons>
							{canLinkConfluencePage && hasLinkedPages && (
								<AddButton onClick={onAddButtonClicked} />
							)}
							{fg('jira_ai_powered_issue_related_confluence_resources') ? (
								canShowAISuggestions &&
								onShowAISuggestionsClicked && (
									<SuggestRelatedResourcesButton
										onShowAISuggestionsClicked={onShowAISuggestionsClicked}
									/>
								)
							) : (
								<></>
							)}
						</SectionHeadingIcons>
					</StyledSectionHeading>
				)}
				{!isWhiteboardStandaloneEnabled() && hasLinkedPages && (
					<GroupContainer>
						{linkedPages ? (
							<Container>
								{hasAnyLinkedPageLoaded && linkedPages && (
									<ConfluencePageLineCardGroup
										groupId={LINKED_PAGES_GROUP_ID}
										pages={linkedPages}
										onClick={onLinkedPageClicked}
										onAuthenticateApplink={onAuthenticateApplink}
										onDeleteConfluencePageLink={onDeleteConfluencePageLink}
										systemConfluenceAppLinkUrl={systemConfluenceAppLinkUrl}
									/>
								)}
							</Container>
						) : (
							<LineCardSkeleton itemCount={linkedPagesCachedCount} />
						)}
					</GroupContainer>
				)}
				{isWhiteboardStandaloneEnabled() && (hasLinkedPages || hasLinkedWhiteboards) && (
					<GroupContainer>
						{(linkedPages || linkedWhiteboards) && (
							<Container>
								{hasAnyLinkedPageLoaded && linkedPages && (
									<ConfluencePageLineCardGroup
										groupId={LINKED_PAGES_GROUP_ID}
										pages={linkedPages}
										onClick={onLinkedPageClicked}
										onAuthenticateApplink={onAuthenticateApplink}
										onDeleteConfluencePageLink={onDeleteConfluencePageLink}
										systemConfluenceAppLinkUrl={systemConfluenceAppLinkUrl}
									/>
								)}
								{hasLinkedWhiteboards && (
									<ConfluenceWhiteboardLineCardGroup
										groupId={LINKED_WHITEBOARDS_GROUP_ID}
										whiteboards={linkedWhiteboards ?? []}
										onClick={onLinkedWhiteboardClicked}
										systemConfluenceAppLinkUrl={systemConfluenceAppLinkUrl}
									/>
								)}
							</Container>
						)}
						{!linkedPages && <LineCardSkeleton itemCount={linkedPagesCachedCount} />}
						{!linkedWhiteboards && <LineCardSkeleton itemCount={linkedWhiteboardsCachedCount} />}
					</GroupContainer>
				)}
				{hasMentionedPages && (
					<GroupContainer>
						<Heading as="h3" level="h200">
							<FormattedMessage
								id="issue.details.confluence-pages.mentioned-on"
								defaultMessage="mentioned on"
							/>
						</Heading>
						{!mentionedPages && <LineCardSkeleton itemCount={mentionedPagesCachedCount} />}
						{mentionedPages && (
							<Box xcss={containerWrapperStyles}>
								<Container>
									{hasAnyMentionedPageLoaded && mentionedPages && (
										<ConfluencePageLineCardGroup
											groupId={MENTIONED_PAGES_GROUP_ID}
											pages={mentionedPages}
											onClick={onMentionedPageClicked}
											onAuthenticateApplink={onAuthenticateApplink}
											onDeleteConfluencePageLink={onDeleteConfluencePageLink}
											systemConfluenceAppLinkUrl={systemConfluenceAppLinkUrl}
										/>
									)}
								</Container>
							</Box>
						)}
					</GroupContainer>
				)}
				{eligibleForLinkedPageCrossJoinExperiment && (
					<LinkedMentionedContentPreview contentCount={totalContentCount} />
				)}
				{ff('confluence-template-placeholder_2bhgf') && shouldShowCoUseExperiment ? (
					<AdControlProvider>
						<DummyConfluenceLinkCoUseLazy
							issueType={issueType}
							projectName={projectName}
							onAddButtonClicked={onAddButtonClicked}
							shouldShowHeading={!shouldShowHeading}
							hasActivatedConfluenceWithinFourteenDays={hasActivatedConfluenceWithinFourteenDays}
						/>
					</AdControlProvider>
				) : null}

				{fg('jira_ai_powered_issue_related_confluence_resources') ? (
					canShowAISuggestions && isAISuggestionsOpen && <SuggestRelatedResourcesModalContainer />
				) : (
					<></>
				)}

				{fg('jira_ai_powered_issue_related_confluence_resources')
					? isCreateLinkedPageOpened &&
						!(canShowAISuggestions && isAISuggestionsOpen) && <ConfluencePageCreateLinkView />
					: isCreateLinkedPageOpened && <ConfluencePageCreateLinkView />}
			</ConfluenceContentWrapper>
		);
	}

	if (projectType === SOFTWARE_PROJECT && isIssueTypeEligibleForPlaceholderTemplate) {
		// the following 2 components can never be rendered at the same time
		return (
			<>
				{/* Confluence Page Dummy Link Experiment
				(this component can only be rendered for tenants that DO NOT have confluence) */}
				{isConfluencePageDummyLinkExperiment() ? (
					<ContextualAnalyticsData
						sourceName="confluencePlaceholderTemplate"
						sourceType={SCREEN}
						attributes={{ experiment: 'placeholderTemplateContextual' }}
					>
						<Placeholder name="confluence-dummy-link-experiment" fallback={null}>
							<ConfluenceDummyLinkProvider>
								<AdControlProvider>
									<DummyConfluenceLinkViewLazy
										issueType={issueType}
										tenantHasConfluenceAppLinks={canLinkConfluencePage}
										projectName={projectName}
										hasShownIntentToXflowToConfluence={hasShownIntentToXflowToConfluence}
									/>
								</AdControlProvider>
							</ConfluenceDummyLinkProvider>
						</Placeholder>
					</ContextualAnalyticsData>
				) : null}
				{/* Confluence Placeholder Co-Use Experiment
				(this component can only be rendered for tenants that HAVE confluence) */}
				{ff('confluence-template-placeholder_2bhgf', false) ? (
					<AdControlProvider>
						<DummyConfluenceLinkCoUseLazy
							issueType={issueType}
							projectName={projectName}
							onAddButtonClicked={onAddButtonClicked}
							shouldShowHeading
							hasActivatedConfluenceWithinFourteenDays={hasActivatedConfluenceWithinFourteenDays}
						/>
					</AdControlProvider>
				) : null}
			</>
		);
	}

	return null;
};

ConfluenceContent.defaultProps = {
	isConfluencePageLinksEnabled: false,
	linkedPagesCachedCount: 0,
	linkedWhiteboardsCachedCount: 0,
	mentionedPagesCachedCount: 0,
};

export default ConfluenceContent;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const StyledSectionHeading = styled(SectionHeading)<{}>({
	paddingBottom: token('space.100', '8px'),
});

const containerWrapperStyles = xcss({
	marginTop: 'space.100',
});

// 1px padding to the right to be aligned with the add comment box because of the shadow around it
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const GroupContainer = styled.div({
	margin: `${token('space.100', '8px')} 0`,
	paddingRight: token('space.025', '2px'),
});
