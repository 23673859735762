// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'attachments.heading.title',
		defaultMessage: 'Attachments {totalCount}',
		description: 'The title with number of total attachments',
	},
	addAttachmentAriaLabel: {
		id: 'attachments.heading.addAttachmentLabel',
		defaultMessage: 'Add Attachment',
		description: 'The label for the add attachment button',
	},
	attachmentsAriaLabel: {
		id: 'attachments.heading.attachmentsLabel',
		defaultMessage: 'More options for attachments',
		description: 'The label for the attachments meatball menu',
	},
});
