import { createAri } from '@atlassian/jira-platform-ari';
import { SOFTWARE } from '@atlassian/jira-shared-types/src/application-key.tsx';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';

export const getJiraSoftwareAri = (cloudId: CloudId): string =>
	createAri({
		resourceOwner: SOFTWARE,
		resourceType: 'site',
		resourceId: cloudId,
	});
