import { isClientFetchError } from '../../common/utils/is-error';
import { getRovoChatProduct } from '../../common/utils/product-config';

import { normaliseUrl } from './utils';

// importing local file instead of from '@sentry/browser' otherwise there will be typescript errors
const importSentryAsync = async () =>
	import(
		/* webpackChunkName: "@atlaskit-internal_conversation-assistant-sentry" */
		'@sentry/browser'
	);

type SentryType = Awaited<ReturnType<typeof importSentryAsync>>;

let sentryClient: SentryType | undefined;

// copied from platform/packages/people-and-teams/teams-client/src/services/sentry/utils/initialise-sentry.ts
const DISABLED_INTEGRATIONS = [
	'onerror',
	'onunhandledrejection',
	// In Jira this integration is being initialised twice, resulting in no events being sent to Sentry
	'Dedupe',
];

const getEnvironment = (): 'staging' | 'production' | undefined => {
	if (process.env.NODE_ENV !== 'production') {
		return;
	}

	if (process.env.CLOUD_ENV === 'staging') {
		return 'staging';
	}

	return 'production';
};

export const setupSentryClient = async ({
	customSentryDsn,
	packageName,
	product: productOverride,
}: {
	customSentryDsn?: string;
	packageName?: string;
	product?: string;
} = {}) => {
	let accountId = 'UNKNOWN_AAID';
	let product = productOverride ?? getRovoChatProduct();
	let profileData;

	if (sentryClient) {
		try {
			const response = await fetch('/gateway/api/me');
			profileData = await response.json();

			if (profileData?.account_id) {
				accountId = profileData.account_id;
			}
		} catch (_) {
			// do nothing
		} finally {
			// if sentry is trying to be initialized again with new product or account id
			// update the user and tags
			sentryClient.setTag('product', product);
			sentryClient.setUser({ id: accountId });
		}

		// do not initialise sentry again, only update the existing one
		return;
	}

	let importedSentry;
	try {
		importedSentry = await importSentryAsync();
	} catch (e) {
		console.warn('Failed to load conversation-assistant sentry', e); // eslint-disable-line no-console
		return;
	}

	// https://atlassian-2y.sentry.io/projects/ai-mate-conversation-assistant/getting-started/
	const defaultSentryDsn =
		'https://0d679a1c0fe3816e2b2e34d4ca85d5da@o55978.ingest.sentry.io/4506794008903680';

	importedSentry.init({
		dsn: customSentryDsn || defaultSentryDsn,
		environment: getEnvironment(),
		integrations: (integrations) => {
			return integrations.filter((integration) => {
				return DISABLED_INTEGRATIONS.indexOf(integration.name) === -1;
			});
		},
		initialScope: {
			user: {
				id: accountId,
			},
			tags: {
				packageName: packageName || '@atlassian/conversation-assistant',
				product,
			},
		},
		// referencing jira/src/packages/platform/observability/errors-handling/src/utils/sentry/handling/normalise-sentry-event.tsx
		beforeSend: (event, hint) => {
			const exception = hint?.originalException;
			// Ignore network-like errors
			if (isClientFetchError(exception)) {
				return null;
			}

			if (!event.tags?.name) {
				// we only want to capture errors that has `name` tag from `logException` function below
				// since the `DISABLED_INTEGRATIONS` filter above is not enough to prevent unhandled errors
				return null;
			}

			let updatedEvent = event;
			if (updatedEvent.request?.url) {
				// Ignore events from localhost and staging
				if (
					updatedEvent.request.url.includes('localhost') ||
					updatedEvent.request.url.includes('jira-dev.com')
				) {
					return null;
				}

				updatedEvent.request.url = normaliseUrl(updatedEvent.request.url);
			}

			return updatedEvent;
		},
		attachStacktrace: true,
	});

	sentryClient = importedSentry;
};

const getSentryClient = async () => {
	if (!sentryClient) {
		// this only happens if something using sentry before the initialisation in root app
		await setupSentryClient();
	}

	return sentryClient;
};

export const logException = async ({
	exception,
	name,
	tags,
}: {
	exception: Error | unknown;
	name: string;
	tags?: { [key: string]: string };
}) => {
	const sentryClient = await getSentryClient();
	if (!sentryClient || typeof sentryClient.captureException !== 'function') {
		return;
	}

	sentryClient.withScope((scope) => {
		scope.setTag('name', name);
		if (tags) {
			scope.setTags(tags);
		}

		sentryClient.captureException(exception, scope);
	});
};
