// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'issue.organizations.text-for-edit-view-when-no-value-is-provided',
		defaultMessage: 'Select the Organizations',
		description: 'Empty state text when no organizations is provided.',
	},
	organizationIconLabel: {
		id: 'issue.organizations.organization-icon-label',
		defaultMessage: 'Organization icon',
		description: 'Organization icon label',
	},
});
