// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	delete: {
		id: 'attachments.delete-attachment-from-attachment-panel',
		defaultMessage: 'Delete',
		description: '',
	},
	goto: {
		id: 'attachments.goto-attachment-from-attachment-panel',
		defaultMessage: 'GoTo',
		description: '',
	},
	show: {
		id: 'attachments.show-on-card',
		defaultMessage: 'Show on card',
		description: '',
	},
	hide: {
		id: 'attachments.hide-on-card',
		defaultMessage: 'Hide on card',
		description: '',
	},
	confirmationHeader: {
		id: 'attachments.confirm-delete-attachment-modal-header',
		defaultMessage: 'Delete this attachment?',
		description: '',
	},
	confirmationContent: {
		id: 'attachments.confirm-delete-attachment-modal-text',
		defaultMessage: "Once you delete, it's gone for good.",
		description: '',
	},
});
