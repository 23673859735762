import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { PeopleEditView } from '@atlassian/jira-issue-field-people-editview-full/src/ui/people/index.tsx';
import type { AggJiraPeopleConnection } from '@atlassian/jira-issue-field-people-editview-full/src/ui/people/types';
import { JiraIssueFieldFormComponent } from '@atlassian/jira-issue-form-field-base/src/ui/index.tsx';
import { WidthWrapper } from '@atlassian/jira-issue-form-field-common';
import type {
	UserOption,
	AggUser,
} from '@atlassian/jira-issue-user-picker-edit-view/src/common/types';
import { transformAggToUserOption } from '@atlassian/jira-issue-user-picker-edit-view/src/utils/transform-agg-to-user-option/index.tsx';
import type { peopleField_issueFieldPeopleFormField_PeopleFormField$key as PeopleFormFieldFragment } from '@atlassian/jira-relay/src/__generated__/peopleField_issueFieldPeopleFormField_PeopleFormField.graphql';
import { validatePeopleField } from '../../utils/validate-people-field/index.tsx';
import type { PeopleFormFieldProps } from './types';

export const transformToSuggestions = (users: AggJiraPeopleConnection): AggUser[] =>
	users?.edges
		?.map((edge) => edge?.node ?? null)
		.filter(Boolean)
		.map(
			(edge): AggUser => ({
				accountId: edge.accountId,
				accountStatus: edge.accountStatus,
				name: edge.name,
				picture: edge.picture,
				id: edge.id,
				email: edge.email ?? undefined,
			}),
		) || [];

export const transformAggToOption = (users: AggUser[] | null): UserOption[] =>
	(users || []).map((edge): UserOption => transformAggToUserOption(edge)) || [];

export const PeopleFormField = ({ fragmentRef }: PeopleFormFieldProps) => {
	const data = useFragment<PeopleFormFieldFragment>(
		graphql`
			fragment peopleField_issueFieldPeopleFormField_PeopleFormField on JiraPeopleField {
				selectedUsersConnection {
					edges {
						node {
							accountId
							id
							accountStatus
							name
							picture
							... on AtlassianAccountUser {
								email
							}
						}
					}
				}
				id
				...ui_issueFormFieldBase_JiraIssueFieldFormComponent
			}
		`,
		fragmentRef,
	);

	const defaultValue = transformToSuggestions(data.selectedUsersConnection);
	const fieldId = data?.id ?? '';

	return (
		<JiraIssueFieldFormComponent
			issueFieldFragment={data}
			defaultValue={defaultValue}
			onValidate={validatePeopleField}
		>
			{({ value, onChange, isDisabled, id }) => (
				<WidthWrapper isMini>
					<PeopleEditView
						onChange={onChange}
						menuPosition="fixed"
						value={transformAggToOption(value)}
						inputId={id}
						fieldId={fieldId}
						isDisabled={isDisabled}
					/>
				</WidthWrapper>
			)}
		</JiraIssueFieldFormComponent>
	);
};
