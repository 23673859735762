/**
 * This component is a wrapper for the @atlaskit/select component that adds these props:
 * - `footer`
 * - `shouldShowFooter`
 * - `onFooterSelect`
 * by overriding the following props:
 * - `formatCreateLabel`
 * - `isValidNewOption`
 * - `onCreateOption`
 * Replace usages of this with the official AtlasKit footer prop when it is implemented: AK-6503
 */
import React, { Component, type ReactNode, type ReactElement } from 'react';
import memoizeOne from 'memoize-one';
import { CreatableSelect } from '@atlaskit/select';
import type {
	OptionToFilter,
	Props as AkSelectProps,
} from '@atlassian/jira-common-components-picker/src/model';
import UFOLabel from '@atlassian/react-ufo/label';
import { flattenOptions, flattenSelectedOptions } from '../select-with-analytics/utils';
import type { Option, Options, Group } from '../types';

export type SelectWithFooterProps = {
	shouldShowFooter?: boolean;
	// eslint-disable-next-line jira/react/handler-naming
	footer?: ReactNode | ((value: string) => ReactElement);
	onFooterSelect?: (value: string) => void;
	spacing?: 'default' | 'compact';
	onMenuScrollToBottom?: () => void;
	'aria-label'?: string;
};

export type Props = AkSelectProps<Option | Group> &
	SelectWithFooterProps & {
		options: Options;
		filterOption: (option: OptionToFilter, query: string) => boolean;
	};

const EMPTY_USER_INPUT = '';

const getLoadingMessageOption = memoizeOne((loadingMessage) => ({
	label: loadingMessage(EMPTY_USER_INPUT),
	isDisabled: true,
	isAkSelectOption: true,
}));

const getNoOptionsMessageOption = memoizeOne((noOptionsMessage) => ({
	label: noOptionsMessage(EMPTY_USER_INPUT),
	isDisabled: true,
	isAkSelectOption: true,
}));

const getSelectWithFooterOptions = memoizeOne(
	(shouldShowFooter, options, noOptionsMessageOption, loadingMessageOption) =>
		shouldShowFooter === true
			? [...options, noOptionsMessageOption, loadingMessageOption]
			: options,
);

/**
 * NOTE: there was an issue with converting this component to functional one (even without memoization). isLoading inside of filterOption was not updated from true to false and therefore "Searching..." text was shown instead of search results.
 */
// when removing 'issue.details.relay-release-version-field-issue-view-integration' see if this component can be removed
// eslint-disable-next-line jira/react/no-class-components
export default class SelectWithFooter extends Component<Props> {
	static defaultProps = {
		shouldShowFooter: true,
	};

	formatCreateLabel = (inputValue: string) => {
		const { footer, formatCreateLabel } = this.props;
		if (footer === undefined) {
			// in case `footer` was not passed - behave as normal
			return formatCreateLabel ? formatCreateLabel(inputValue) : inputValue;
		}

		if (typeof footer === 'function') {
			return footer(inputValue);
		}

		return footer;
	};

	getShouldShowFooter = () => {
		const { footer, shouldShowFooter } = this.props;
		const hasCreateItemFooter = !!footer;
		return hasCreateItemFooter && shouldShowFooter;
	};

	isOptionShownInDropdown = (option: Option, inputValue: string) => {
		const { filterOption, noOptionsMessage, loadingMessage, value: selectedOptions } = this.props;

		return (
			// will not be filtered out by ak/select based on user input (even if there is no refetch)
			filterOption(option, inputValue) &&
			// not a manually added noOptionsMessageOption
			option.label !== noOptionsMessage(EMPTY_USER_INPUT) &&
			option.label !== loadingMessage(EMPTY_USER_INPUT) &&
			// not in selected values
			!flattenSelectedOptions(selectedOptions).find(
				(selectedOption) => selectedOption.value === option.value,
			)
		);
	};

	/**
	 * react-select does not show No options message and create new option label together.
	 * That's why we need this function
	 */
	filterOptionWithFooterAndShowNoOptionsMessage = (option: OptionToFilter, inputValue: string) => {
		const { filterOption, options, isLoading, noOptionsMessage, loadingMessage } = this.props;

		if (option.label === noOptionsMessage(EMPTY_USER_INPUT)) {
			// render noOptionsMessage only if there're no other options in the dropdown
			return (
				isLoading !== true &&
				!flattenOptions(options).some((opt) => this.isOptionShownInDropdown(opt, inputValue))
			);
		}
		if (option.label === loadingMessage(EMPTY_USER_INPUT)) {
			return (
				isLoading === true &&
				!flattenOptions(options).some((opt) => this.isOptionShownInDropdown(opt, inputValue))
			);
		}

		return filterOption(option, inputValue); // apply default filtering function
	};

	render() {
		const {
			footer,
			onFooterSelect,
			options,
			noOptionsMessage,
			loadingMessage,
			filterOption,
			spacing,
			onMenuScrollToBottom,
			...otherProps
		} = this.props;

		const shouldShowFooter = this.getShouldShowFooter();

		// need to add noOptionsMessage since ak/select does not show it if Create label is shown
		const noOptionsMessageOption = getNoOptionsMessageOption(noOptionsMessage);
		const loadingMessageOption = getLoadingMessageOption(loadingMessage); // need this not to have flickr between loading and noOptions states

		const selectWithFooterOptions = getSelectWithFooterOptions(
			shouldShowFooter,
			options,
			noOptionsMessageOption,
			loadingMessageOption,
		);

		return (
			<UFOLabel name="select-with-footer">
				<CreatableSelect
					{...otherProps}
					spacing={spacing}
					options={selectWithFooterOptions}
					formatCreateLabel={this.formatCreateLabel}
					isValidNewOption={this.getShouldShowFooter}
					onCreateOption={onFooterSelect}
					filterOption={
						shouldShowFooter === true
							? this.filterOptionWithFooterAndShowNoOptionsMessage
							: filterOption
					}
					// @ts-expect-error - Type '(inputValue: string) => string | null' is not assignable to type '(obj: { inputValue: string; }) => ReactNode'. Types of parameters 'inputValue' and 'obj' are incompatible. Type '{ inputValue: string; }' is not assignable to type 'string'.
					loadingMessage={loadingMessage}
					// @ts-expect-error - Type '(inputValue: string) => string | null' is not assignable to type '(obj: { inputValue: string; }) => ReactNode'. Types of parameters 'inputValue' and 'obj' are incompatible. Type '{ inputValue: string; }' is not assignable to type 'string'.
					noOptionsMessage={noOptionsMessage}
					allowCreateWhileLoading
					onMenuScrollToBottom={onMenuScrollToBottom}
					captureMenuScroll
				/>
			</UFOLabel>
		);
	}
}
