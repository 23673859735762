import React, { Component, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import { lazyForPaint } from 'react-loosely-lazy';
import { withAnalyticsEvents, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import { AnalyticsSubject } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import type { ProjectType } from '@atlassian/jira-common-constants';
import {
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
	CORE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';
import withCommentVisibilities from '@atlassian/jira-issue-comment-base/src/ui/comment/comment-visibility/state/components/with-comment-visibilities';
import {
	type ActivitySortOrderType,
	NEWEST_FIRST,
	OLDEST_FIRST,
} from '@atlassian/jira-issue-shared-types/src/common/types/activity-sort-order-type.tsx';
import type CommentReplies from '@atlassian/jira-issue-view-activity-comment/src/comment-replies';
import Comment from '@atlassian/jira-issue-view-activity-comment/src/comment-view';
import CommentListItem from '@atlassian/jira-issue-view-activity-common/src/component/comment-list-item/view.tsx';
import LoadMoreButton from '@atlassian/jira-issue-view-activity-common/src/component/load-more-button/view.tsx';
import {
	NUM_PAGED_ITEMS_TO_LOAD,
	LOAD_NEWER_BUTTON,
	LOAD_OLDER_BUTTON,
} from '@atlassian/jira-issue-view-common-constants/src/activity-feed';
import { COMMENTS } from '@atlassian/jira-issue-view-common-constants/src/activity-items.tsx';
import type { LoadingStage } from '@atlassian/jira-issue-view-common-types/src/comment-type';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { calculateNumberOfCommentsToLoad } from '@atlassian/jira-issue-view-common-utils/src/epics/comment-fetch-epic';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import {
	fetchOlderCommentsRequest,
	fetchNewerCommentsRequest,
} from '@atlassian/jira-issue-view-store/src/actions/comment-actions';
import {
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { projectTypeSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import { getSelectedActivitySortOrder } from '@atlassian/jira-issue-view-store/src/selectors/activity-feed-selector';
import { fullIssueUrlSelector } from '@atlassian/jira-issue-view-store/src/selectors/breadcrumbs-selector';
import {
	canAddCommentsSelector,
	isCommentVisibilityRestrictionSupportedSelector,
	loadedCommentsSelector,
	isLoadingMoreCommentsSelector,
	totalCommentsSelector,
	startIndexCommentsSelector,
	sortedVisibleCommentIdsSelector,
	loadingStageSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/comment-selector';
import Placeholder from '@atlassian/jira-placeholder';
import { getPermalinkStatus } from '@atlassian/jira-platform-issue-permalinks/src';
import {
	fireUIAnalytics,
	ContextualAnalyticsData,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { SectionMessage } from './section-message';
import { JsmSmartRequestSummaryEntryPointContainer } from './servicedesk/smart-request-summary';
import { IssueSmartRequestSummaryEntryPointContainer } from './smart-request-summary';

// eslint-disable-next-line jira/styled/styled-component-order, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CommentsList = styled.div({
	margin: `${token('space.300', '24px')} 0 ${token('space.150', '12px')} 0`,
});

type OwnProps = {
	// eslint-disable-next-line jira/react/handler-naming
	fetchCommentVisibilities?: () => void;
	// go/jfe-eslint
	createAnalyticsEvent: (arg1: { action: string }) => UIAnalyticsEvent;
};

type StateProps = {
	isLoadingMoreComments: boolean;
	// go/jfe-eslint
	issueKey: IssueKey;
	// go/jfe-eslint
	shouldFetchCommentVisibilities: boolean;
	canAddComments: boolean;
	commentIds: string[];
	totalComments: number;
	startIndex: number;
	loadedComments: number;
	fullIssueUrl: string | null;
	loadingStage: LoadingStage;
	selectedSortOrder: ActivitySortOrderType;
	// go/jfe-eslint
	baseUrl: BaseUrl;
	projectType: ProjectType | null;
};

type DispatchProps = {
	// eslint-disable-next-line jira/react/handler-naming
	fetchMoreOlderComments: () => void;
	// eslint-disable-next-line jira/react/handler-naming
	fetchMoreNewerComments: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

type CommentsState = {
	loadMoreButtonTypeClicked: typeof LOAD_NEWER_BUTTON | typeof LOAD_OLDER_BUTTON | undefined;
};

// eslint-disable-next-line jira/react/no-class-components
export class Comments extends Component<Props, CommentsState> {
	static displayName = COMMENTS;

	static defaultProps = {
		canAddComments: false,
	};

	state: CommentsState = {
		loadMoreButtonTypeClicked: undefined,
	};

	componentDidMount() {
		const { fetchCommentVisibilities } = this.props;

		if (ff('lower-comment-visibility-list-fetch') === false) {
			fetchCommentVisibilities && fetchCommentVisibilities();
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		// We fetch more comments when there are no comments left visible
		// but there are still comments available to be fetched (i.e. hidden)
		if (
			nextProps.loadedComments !== this.props.loadedComments &&
			nextProps.loadedComments === 0 &&
			nextProps.totalComments > 0
		) {
			if (nextProps.startIndex === 0) {
				nextProps.fetchMoreOlderComments();
			} else {
				nextProps.fetchMoreNewerComments();
			}
		}

		if (!nextProps.isLoadingMoreComments) {
			this.setState({
				loadMoreButtonTypeClicked: undefined,
			});
		}
	}

	onLoadOlderButtonClick = (event: MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
		const { fetchMoreOlderComments } = this.props;

		fireUIAnalytics(analyticsEvent, 'loadMoreButtonClicked', {
			loadMoreButtonType: LOAD_OLDER_BUTTON,
		});

		this.setState({
			loadMoreButtonTypeClicked: LOAD_OLDER_BUTTON,
		});

		fetchMoreOlderComments();
	};

	onLoadNewerButtonClick = (event: MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
		const { fetchMoreNewerComments } = this.props;

		fireUIAnalytics(analyticsEvent, 'loadMoreButtonClicked', {
			loadMoreButtonType: LOAD_NEWER_BUTTON,
		});

		this.setState({
			loadMoreButtonTypeClicked: LOAD_NEWER_BUTTON,
		});

		fetchMoreNewerComments();
	};

	renderLoadOlderButton(numOlderCommentsToLoad: number) {
		const { isLoadingMoreComments } = this.props;
		const { loadMoreButtonTypeClicked } = this.state;
		const { onLoadOlderButtonClick } = this;
		const isLoading = loadMoreButtonTypeClicked === LOAD_OLDER_BUTTON && isLoadingMoreComments;

		return (
			<LoadMoreButton
				onClick={onLoadOlderButtonClick}
				numberToLoad={numOlderCommentsToLoad}
				isDisabled={isLoadingMoreComments}
				isLoading={isLoading}
			>
				{numOlderCommentsToLoad < NUM_PAGED_ITEMS_TO_LOAD ? (
					<FormattedMessage
						id="issue.comments-view-sorted-older-remaining-comments-text-numberToLoad"
						defaultMessage="View {numberToLoad, plural, one {1 older comment} other {{numberToLoad} remaining older comments}}"
						description="Button to show older items in a list"
						values={{
							numberToLoad: numOlderCommentsToLoad,
						}}
					/>
				) : (
					<FormattedMessage
						id="issue.comments-view-sorted-older-comments-text"
						defaultMessage="View {numberToLoad, plural, one {1 older comment} other {{numberToLoad} older comments}}"
						description="Button to show older items in a list"
						values={{
							numberToLoad: numOlderCommentsToLoad,
						}}
					/>
				)}
			</LoadMoreButton>
		);
	}

	renderLoadNewerButton(numNewerCommentsToLoad: number) {
		const { isLoadingMoreComments } = this.props;
		const { loadMoreButtonTypeClicked } = this.state;
		const { onLoadNewerButtonClick } = this;
		const isLoading = loadMoreButtonTypeClicked === LOAD_NEWER_BUTTON && isLoadingMoreComments;

		return (
			<LoadMoreButton
				onClick={onLoadNewerButtonClick}
				numberToLoad={numNewerCommentsToLoad}
				isDisabled={isLoadingMoreComments}
				isLoading={isLoading}
			>
				{numNewerCommentsToLoad < NUM_PAGED_ITEMS_TO_LOAD ? (
					<FormattedMessage
						id="issue.comments-view-sorted-newer-remaining-comments-text-numberToLoad"
						defaultMessage="View {numberToLoad, plural, one {1 newer comment} other {{numberToLoad} remaining newer comments}}"
						description="Button to show newer items in a list"
						values={{
							numberToLoad: numNewerCommentsToLoad,
						}}
					/>
				) : (
					<FormattedMessage
						id="issue.comments-view-sorted-newer-comments-text"
						defaultMessage="View {numberToLoad, plural, one {1 newer comment} other {{numberToLoad} newer comments}}"
						description="Button to show newer items in a list"
						values={{
							numberToLoad: numNewerCommentsToLoad,
						}}
					/>
				)}
			</LoadMoreButton>
		);
	}

	render() {
		const {
			commentIds,
			canAddComments,
			totalComments,
			loadedComments,
			fullIssueUrl,
			startIndex,
			loadingStage,
			selectedSortOrder,
			projectType,
		} = this.props;

		if (!commentIds.length && !canAddComments) {
			return null;
		}

		const { numPrevCommentsToLoad, numNextCommentsToLoad } = calculateNumberOfCommentsToLoad(
			totalComments,
			loadedComments,
			startIndex,
		);

		const { hasPermalink, permalinkId } = getPermalinkStatus(COMMENTS);

		const isThreadedCommentsEnabled = (): boolean =>
			// eslint-disable-next-line jira/ff/no-preconditioning
			fg('jira_threaded_comment_fg') &&
			(projectType === SOFTWARE_PROJECT || projectType === CORE_PROJECT) &&
			expVal('jira_threaded_comment_experiment', 'is_enabled', false);

		const sortedList = commentIds.length ? (
			<>
				{selectedSortOrder === NEWEST_FIRST && this.renderLoadNewerButton(numPrevCommentsToLoad)}
				{selectedSortOrder === OLDEST_FIRST && this.renderLoadOlderButton(numNextCommentsToLoad)}
				{/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */}
				<CommentsList data-testid="issue.activity.comments-list">
					{fg('jira_issue_mobile_section_message') && <SectionMessage />}
					{commentIds.map((commentId) => (
						<Comment
							key={commentId}
							commentId={commentId}
							fullIssueUrl={fullIssueUrl}
							isHighlighted={hasPermalink && commentId === permalinkId}
						>
							{isThreadedCommentsEnabled() && (
								<Placeholder name="issue-comment-replies" fallback={<></>}>
									<LazyCommentReplies commentId={commentId} fullIssueUrl={fullIssueUrl} />
								</Placeholder>
							)}
						</Comment>
					))}
				</CommentsList>
				{selectedSortOrder === NEWEST_FIRST && this.renderLoadOlderButton(numNextCommentsToLoad)}
				{selectedSortOrder === OLDEST_FIRST && this.renderLoadNewerButton(numPrevCommentsToLoad)}
			</>
		) : null;

		const renderSmartRequestSummaryEntryPointContainer = () => {
			if (projectType === SERVICE_DESK_PROJECT) {
				return <JsmSmartRequestSummaryEntryPointContainer />;
			}
			if (projectType === SOFTWARE_PROJECT || projectType === CORE_PROJECT) {
				return <IssueSmartRequestSummaryEntryPointContainer />;
			}
			return null;
		};

		return (
			<>
				{renderSmartRequestSummaryEntryPointContainer()}
				<ContextualAnalyticsData sourceType={SCREEN} sourceName="commentsActivityFeed">
					<CommentListItem
						loadingStage={loadingStage}
						canAddComments={canAddComments}
						list={sortedList}
						numPrevCommentsToLoad={numPrevCommentsToLoad}
						selectedSortOrder={selectedSortOrder}
					/>
				</ContextualAnalyticsData>
			</>
		);
	}
}

const ConnectedComments = connect(
	(state: State) => ({
		commentIds: sortedVisibleCommentIdsSelector(state),
		canAddComments: canAddCommentsSelector(state),
		totalComments: totalCommentsSelector(state),
		startIndex: startIndexCommentsSelector(state),
		loadedComments: loadedCommentsSelector(state),
		isLoadingMoreComments: isLoadingMoreCommentsSelector(state),
		projectType: projectTypeSelector(state),
		// baseUrl, issueKey & shouldFetchCommentVisibilities are used in with-comment-visibilities.js
		baseUrl: baseUrlSelector(state),
		issueKey: issueKeySelector(state),
		shouldFetchCommentVisibilities: isCommentVisibilityRestrictionSupportedSelector(state),
		// fullIssueUrl for the comments permalink
		fullIssueUrl: fullIssueUrlSelector(state),
		loadingStage: loadingStageSelector(state),
		selectedSortOrder: getSelectedActivitySortOrder(state),
	}),
	(dispatch): DispatchProps => ({
		fetchMoreOlderComments: () => {
			dispatch(fetchOlderCommentsRequest());
		},
		fetchMoreNewerComments: () => {
			dispatch(fetchNewerCommentsRequest());
		},
	}),
)(withCommentVisibilities(Comments));

const ConnectedCommentsWithAnalyticsSubject = AnalyticsSubject('comment')(
	// @ts-expect-error - Argument of type 'ConnectedComponentClass<ComponentType<CommentVisibilitiesComponentProps<OwnProps & StateProps & DispatchProps>>, Omit<...>>' is not assignable to parameter of type 'JSXElementConstructor<WithAnalyticsEventsProps> & ComponentClass<Omit<CommentVisibilitiesComponentProps<OwnProps & StateProps & DispatchProps>, "baseUrl" | ... 12 more ... | "shouldFetchCommentVisibilities">, any> & { ...; }'.
	withAnalyticsEvents()(ConnectedComments),
);

const IssueViewComment = () => (
	<UFOSegment name="issue-view-comments">
		<JSErrorBoundary
			id="issue.issue-view.activity.comments"
			packageName="jiraIssueViewActivity"
			teamName="bento"
			fallback="unmount"
		>
			<ConnectedCommentsWithAnalyticsSubject />
		</JSErrorBoundary>
	</UFOSegment>
);

const LazyCommentReplies = lazyForPaint<typeof CommentReplies>(
	() =>
		import(
			/* webpackChunkName: "async-comment-replies" */ '@atlassian/jira-issue-view-activity-comment/src/comment-replies'
		),
);

export default IssueViewComment;
