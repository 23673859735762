import React, { useContext, useMemo } from 'react';
import { AiIssueBreakdownEntryPointContext } from '@atlassian/jira-ai-work-breakdown/src/controllers/context-provider.tsx';
import type { AiIssueBreakdownProps } from '@atlassian/jira-ai-work-breakdown/src/types.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';

export const AiIssueBreakdownEntryPointContainer = ({
	onCreate,
	childIssueTypes,
	isSubTaskCreationOpen,
	issueHierarchyLevel,
	onDiscard,
}: AiIssueBreakdownProps) => {
	const { entryPointReferenceSubject } = useContext(AiIssueBreakdownEntryPointContext);
	const runtimeProps = useMemo(
		() => ({ onCreate, childIssueTypes, isSubTaskCreationOpen, issueHierarchyLevel, onDiscard }),
		[onCreate, childIssueTypes, isSubTaskCreationOpen, issueHierarchyLevel, onDiscard],
	);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="jiraAIWorkBreakdownEntryPointContainer"
			packageName="jiraAIWorkBreakdown"
			teamName="crocs"
			fallback={null}
			runtimeProps={runtimeProps}
		/>
	);
};
