import type {
	ServerAssociatedIssue,
	ServerAssociatedIssueFields,
} from '@atlassian/jira-issue-shared-types/src/common/types/associated-issue-type.tsx';
import type {
	ChildIssue,
	ServerChildIssue,
} from '@atlassian/jira-issue-shared-types/src/common/types/children-issues-type.tsx';
import type {
	Field,
	FieldsState,
} from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import { ISSUE_TYPE_HIERARCHY_LEVEL } from '@atlassian/jira-issue-shared-types/src/common/types/issue-hierarchy-type.tsx';
import type { Subtask } from '@atlassian/jira-issue-shared-types/src/common/types/subtask-type.tsx';
import {
	ISSUE_TYPE,
	SUBTASKS_TYPE,
	CHILDREN_EXCEEDING_LIMIT_GQL_FIELD,
	CHILDREN_ISSUES,
	CHILDREN_ISSUES_CF_TYPE,
	CHILDREN_WITHIN_LIMIT_GQL_FIELD,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { AggJiraNumberFieldNode } from '../types/fields-type.tsx';
import type { AggResponseData } from '../types/issue-type.tsx';
import { transformAggFieldToLegacyField } from './fields-transformer';
import { transformAggNumberToLegacyField } from './number-field-transformer';
import { toTopLevelAssociatedIssue } from './to-top-level-associated-issue';
import { toTopLevelChildrenIssues } from './to-top-level-children-issues';

// Additional metadata for child issue transformers
type ChildIssueMeta = {
	[issueKey: string]: {
		isResolved: boolean;
	};
};

const defaultChildrenIssueField: Omit<Field, 'key' | 'title'> = {
	value: [],
	renderedValue: null,
	editable: false,
	required: false,
	autoCompleteUrl: null,
	allowedValues: [],
	schema: {
		type: '',
		custom: null,
		system: null,
		configuration: null,
		items: null,
		renderer: null,
	},
};

// The field title is hardcoded because we don't have a way to get it from the response and we don't use it in the UI.

const childIssueFieldDict: FieldsState = {
	[SUBTASKS_TYPE]: {
		...defaultChildrenIssueField,
		key: SUBTASKS_TYPE,
		title: 'Sub-tasks',
		schema: {
			...defaultChildrenIssueField.schema,
			system: SUBTASKS_TYPE,
			items: 'issuelinks',
			type: 'array',
		},
	},
	[CHILDREN_ISSUES]: {
		...defaultChildrenIssueField,
		key: CHILDREN_ISSUES,
		title: 'Children Issues',
		schema: {
			...defaultChildrenIssueField.schema,
			custom: CHILDREN_ISSUES_CF_TYPE,
			type: CHILDREN_ISSUES,
		},
	},
};

const generateChildIssueFieldState = (
	keys: (keyof typeof childIssueFieldDict)[],
	mapper?: (field: Field) => Field,
): FieldsState => {
	const childrenFieldState: FieldsState = {};

	keys.forEach((key) => {
		childrenFieldState[key] = mapper ? mapper(childIssueFieldDict[key]) : childIssueFieldDict[key];
	});

	return childrenFieldState;
};

/**
 * Transforms the children issues field from the agg schema to the legacy schema.
 * Also returns additional child issue metadata for further transformers, to avoid iterating through the child issues again.
 */
export const transformAggResponseToLegacyChildIssues = (
	data: AggResponseData,
	{
		issueHierarchyLevel,
		isNextGenProject,
	}: {
		issueHierarchyLevel: number;
		isNextGenProject: boolean;
	},
): { fieldsState: FieldsState; childIssueMeta: ChildIssueMeta } => {
	// Determines which legacy children issues fields will be generated
	let childrenFieldStateKeys: (keyof typeof childIssueFieldDict)[] = [];
	const childIssueMeta: ChildIssueMeta = {};

	if (isNextGenProject) {
		childrenFieldStateKeys = [CHILDREN_ISSUES];
	} else if (issueHierarchyLevel === ISSUE_TYPE_HIERARCHY_LEVEL.SUBTASK_LEVEL) {
		childrenFieldStateKeys = [];
	} else if (issueHierarchyLevel === ISSUE_TYPE_HIERARCHY_LEVEL.BASE_LEVEL) {
		childrenFieldStateKeys = [SUBTASKS_TYPE];
	} else {
		childrenFieldStateKeys = [CHILDREN_ISSUES, SUBTASKS_TYPE];
	}

	const childrenIssuesResponse = data.jira.issueByKey?.childIssues;

	// If the childIssues response is null, issue does not have any children issues,
	// and we still need to return the children issue field with an empty value.
	if (!childrenIssuesResponse) {
		return {
			fieldsState: generateChildIssueFieldState(childrenFieldStateKeys),
			childIssueMeta,
		};
	}

	if (childrenIssuesResponse.__typename === CHILDREN_EXCEEDING_LIMIT_GQL_FIELD) {
		if (isNextGenProject || issueHierarchyLevel > ISSUE_TYPE_HIERARCHY_LEVEL.BASE_LEVEL) {
			childrenFieldStateKeys = [CHILDREN_ISSUES];
			// CMP base level issues should show the subtasks panel if they are over the child issues limit
		} else if (issueHierarchyLevel === ISSUE_TYPE_HIERARCHY_LEVEL.BASE_LEVEL) {
			childrenFieldStateKeys = [SUBTASKS_TYPE];
		}

		return {
			fieldsState: generateChildIssueFieldState(childrenFieldStateKeys, (field) => ({
				...field,
				issueLimitUrl: childrenIssuesResponse.search ?? '',
			})),
			childIssueMeta,
		};
	}

	const subtasksValue: ServerAssociatedIssue[] = [];
	const childrenIssuesValue: ServerChildIssue[] = [];

	if (
		childrenIssuesResponse.__typename === CHILDREN_WITHIN_LIMIT_GQL_FIELD &&
		childrenIssuesResponse.issues?.edges != null
	) {
		/**
		 * The child issue type conditional is inside the `forEach` call
		 * because it's possible for an issue to have different child issue types:
		 *   - CMP Portfolio issues can have portfolio child issues (epics) and subtasks
		 *   - CMP Epics can have epic child issues (base-level issues) and subtasks
		 */
		childrenIssuesResponse.issues.edges.forEach((edge) => {
			const issue = edge?.node;

			if (issue == null || issue.fieldsById?.edges == null) return;

			childIssueMeta[issue.key] = { isResolved: issue.isResolved ?? false };

			// Fields shape used by CMP subtasks and portfolio child issues
			const fieldsValueMap: Partial<ServerAssociatedIssueFields> = {};

			// Fields shape used by TMP issues and CMP base-level issues
			const fields: Field[] = [];

			issue.fieldsById.edges.forEach((fieldEdge) => {
				const transformedField =
					fieldEdge?.node != null
						? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							(transformAggFieldToLegacyField(fieldEdge.node) as Field)
						: undefined;

				// It's cheaper to just consider both cases than to loop through the fields twice
				if (transformedField) {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					fieldsValueMap[transformedField.key as keyof ServerAssociatedIssueFields] =
						transformedField.value;
					fields.push(transformedField);
				}
			});

			const fieldValue = {
				id: issue.issueId,
				key: toIssueKey(issue.key),
				self: issue.webUrl ?? '',
			};

			const childIssueHierarchyLevel = fieldsValueMap[ISSUE_TYPE]?.hierarchyLevel;

			if (
				!isNextGenProject &&
				childIssueHierarchyLevel === ISSUE_TYPE_HIERARCHY_LEVEL.SUBTASK_LEVEL
			) {
				// Child issue is a CMP subtask
				subtasksValue.push({
					...fieldValue,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					fields: fieldsValueMap as ServerAssociatedIssueFields,
				});
			} else {
				let storyPointsField;

				// Child issue is a TMP issue
				if (isNextGenProject) {
					storyPointsField =
						issue.storyPointEstimateField &&
						transformAggNumberToLegacyField(
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							issue.storyPointEstimateField as AggJiraNumberFieldNode,
						);
				} else {
					// CMP base-level issue
					storyPointsField =
						issue.storyPointsField &&
						transformAggNumberToLegacyField(
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							issue.storyPointsField as AggJiraNumberFieldNode,
						);
				}

				if (storyPointsField != null) {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					fields.push(storyPointsField as Field);
				}

				childrenIssuesValue.push({
					...fieldValue,
					fields,
					/**
					 * Hard-code these values because
					 *   - AGG does not return them
					 *   - Child issues UI does not use them anyway
					 */
					agile: {},
					customFields: { textareaAdf: [] },
					systemFields: {},
				});
			}
		});
	}

	const fieldsState = generateChildIssueFieldState(childrenFieldStateKeys, (field) => {
		if (field.key === SUBTASKS_TYPE) {
			return {
				...field,
				value: subtasksValue,
			};
		}

		return {
			...field,
			value: childrenIssuesValue,
		};
	});

	return {
		fieldsState,
		childIssueMeta,
	};
};

export const transformServerChildIssueFieldsToAssociatedIssues = (
	fields: FieldsState,
	{
		isTeamManagedProject,
		childIssueMeta,
	}: {
		isTeamManagedProject: boolean;
		childIssueMeta: ChildIssueMeta;
	},
): { childrenIssues: ChildIssue[]; subtasks: Subtask[] } => {
	// There can only be one of these children issue fields
	const childrenIssueField = fields[CHILDREN_ISSUES];

	const childrenIssueFieldValue = childrenIssueField?.value ?? [];

	let childrenIssues: ChildIssue[] = [];

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	childrenIssues = (childrenIssueFieldValue as ServerChildIssue[]).map((childIssue) =>
		toTopLevelChildrenIssues(
			childIssue,
			childIssueMeta[childIssue.key]?.isResolved ?? false,
			isTeamManagedProject,
		),
	);

	// There can be a separate `subtasks` field in addition to `childrenIssues`
	const subtasks: Subtask[] = (fields[SUBTASKS_TYPE]?.value ?? []).map(
		(childIssue: ServerAssociatedIssue) =>
			toTopLevelAssociatedIssue(childIssue, childIssueMeta[childIssue.key]?.isResolved ?? false),
	);

	return { childrenIssues, subtasks };
};
