/**
 * @generated SignedSource<<54ec6438849af5e7134ed7c23f4668f9>>
 * @relayHash f00bb3b690c4680632ce8c3238f5a9f2
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 6d2147d739ca05d886d44d52c52bc5bc9df71029b4ec6a574d607191d656d461

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraFieldOptionIdsFilterOperation = "ALLOW" | "EXCLUDE" | "%future added value";
export type JiraFieldOptionIdsFilterInput = {
  operation: JiraFieldOptionIdsFilterOperation;
  optionIds: ReadonlyArray<string>;
};
export type selectableFieldSearchRefetchQuery$variables = {
  after?: string | null | undefined;
  filterById?: JiraFieldOptionIdsFilterInput | null | undefined;
  first?: number | null | undefined;
  id: string;
  searchBy?: string | null | undefined;
};
export type selectableFieldSearchRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment">;
};
export type selectableFieldSearchRefetchQuery = {
  response: selectableFieldSearchRefetchQuery$data;
  variables: selectableFieldSearchRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterById"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": "",
    "kind": "LocalArgument",
    "name": "searchBy"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "filterById",
  "variableName": "filterById"
},
v3 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v4 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "id"
},
v5 = {
  "kind": "Variable",
  "name": "searchBy",
  "variableName": "searchBy"
},
v6 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v7 = {
  "kind": "ScalarField",
  "name": "id"
},
v8 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "selectableFieldSearchRefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "selectableFieldSearchRefetchQuery",
    "selections": [
      {
        "args": [
          (v4/*: any*/)
        ],
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "ScalarField",
                "name": "type"
              }
            ],
            "type": "JiraIssueField",
            "abstractKey": "__isJiraIssueField"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v8/*: any*/),
                "concreteType": "JiraSelectableValueConnection",
                "kind": "LinkedField",
                "name": "selectableValueOptions",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraSelectableValueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "selectableLabel"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "selectableGroupKey"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "selectableIconUrl"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "isDisabled"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "optionId"
                              }
                            ],
                            "type": "JiraOption"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "versionId"
                              }
                            ],
                            "type": "JiraVersion"
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "cursor"
                      }
                    ]
                  },
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                      }
                    ]
                  }
                ]
              },
              {
                "args": (v8/*: any*/),
                "filters": [
                  "searchBy",
                  "filterById"
                ],
                "handle": "connection",
                "key": "selectableValue_issueFieldEditviewFull_fieldOptions__selectableValueOptions",
                "kind": "LinkedHandle",
                "name": "selectableValueOptions"
              }
            ],
            "type": "JiraHasSelectableValueOptions",
            "abstractKey": "__isJiraHasSelectableValueOptions"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "6d2147d739ca05d886d44d52c52bc5bc9df71029b4ec6a574d607191d656d461",
    "metadata": {},
    "name": "selectableFieldSearchRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "ac4a172103fc7e0aca36fb7490b375e6";

export default node;
