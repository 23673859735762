import React, { type ReactNode, useCallback } from 'react';
import noop from 'lodash/noop';
import { Box, xcss } from '@atlaskit/primitives';
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import CollapsedEditorView from './collapsed-editor-view';
import messages from './messages';
import type { CommentButtonActions } from './types';

export const INTERNAL_COMMENT_ID = 'internal' as const;
export const PUBLIC_COMMENT_ID = 'public' as const;

const TABS = {
	public: {
		id: PUBLIC_COMMENT_ID,
		label: <FormattedMessage {...messages.replyToCustomer} />,
		content: <div />,
	},
	internal: {
		id: INTERNAL_COMMENT_ID,
		label: <FormattedMessage {...messages.addInternalNote} />,
		content: <div />,
	},
} as const;

export type OwnProps = {
	isExpanded: boolean;
	children: ({ assistiveLabel }: { assistiveLabel: string }) => ReactNode;
	onExpanded?: () => void;
	commentButtonActions?: CommentButtonActions;
};

export type StateProps = {
	isInternal: boolean;
	isPublicCommentAllowed: boolean;
};

export type DispatchProps = {
	onCommentVisibilityChange: (isInternal: boolean) => void;
};

export type Props = OwnProps & StateProps & DispatchProps;

type ExpandViewProps = {
	isInternal: boolean;
	isPublicCommentAllowed: boolean;
	children: ({ assistiveLabel }: { assistiveLabel: string }) => ReactNode;
	onTabSelect: (selectedIndex: { id: string }) => void;
};

const ExpandedView = ({
	isInternal,
	isPublicCommentAllowed,
	children,
	onTabSelect,
}: ExpandViewProps) => {
	const { formatMessage } = useIntl();
	const selectedTabIndex = isPublicCommentAllowed && !isInternal ? TABS.public : TABS.internal;
	const tabs = isPublicCommentAllowed ? [TABS.internal, TABS.public] : [TABS.internal];

	const handleChange = useCallback(
		(index: number) => {
			onTabSelect({
				id: index === 0 ? INTERNAL_COMMENT_ID : PUBLIC_COMMENT_ID,
			});
		},
		[onTabSelect],
	);

	return (
		<>
			<Box xcss={tabsContainerStyles}>
				<Tabs
					selected={selectedTabIndex.id === INTERNAL_COMMENT_ID ? 0 : 1}
					onChange={handleChange}
					id="comment-editor-container-tabs"
					shouldUnmountTabPanelOnChange
				>
					<TabList>
						{tabs.map((tab, index) => (
							<Tab key={index}>{tab.label}</Tab>
						))}
					</TabList>
					{tabs.map((tab, index) => (
						<TabPanel key={index}>{tab.content}</TabPanel>
					))}
				</Tabs>
			</Box>
			{children({
				assistiveLabel: formatMessage(
					isInternal ? messages.addInternalNote : messages.replyToCustomer,
				),
			})}
		</>
	);
};

const CollapsedView = ({
	onReplyToCustomer,
	onAddInternalNote,
	isPublicCommentAllowed,
	replyToCustomerButtonActions,
	addInternalNoteButtonActions,
}: {
	onReplyToCustomer: () => void;
	onAddInternalNote: () => void;
	isPublicCommentAllowed: boolean;
	replyToCustomerButtonActions?: CommentButtonActions;
	addInternalNoteButtonActions?: CommentButtonActions;
}) => (
	<CollapsedEditorView
		onReplyToCustomer={onReplyToCustomer}
		onAddInternalNote={onAddInternalNote}
		isPublicCommentAllowed={isPublicCommentAllowed}
		replyToCustomerButtonActions={replyToCustomerButtonActions}
		addInternalNoteButtonActions={addInternalNoteButtonActions}
	/>
);

export const ServiceDeskCommentEditorContainer = ({
	isExpanded = false,
	isInternal = false,
	isPublicCommentAllowed = false,
	onExpanded = noop,
	onCommentVisibilityChange = noop,
	children,
	commentButtonActions,
}: Props) => {
	const expandEditor = useCallback(() => {
		if (!isExpanded) {
			onExpanded && onExpanded();
		}
	}, [isExpanded, onExpanded]);

	const onReplyToCustomer = useCallback(() => {
		onCommentVisibilityChange(false);
		expandEditor();
	}, [expandEditor, onCommentVisibilityChange]);

	const onAddInternalNote = useCallback(() => {
		onCommentVisibilityChange(true);
		expandEditor();
	}, [expandEditor, onCommentVisibilityChange]);

	const onTabSelect = useCallback(
		(selectedTab: { id: string }) => {
			onCommentVisibilityChange(selectedTab.id === INTERNAL_COMMENT_ID);
		},
		[onCommentVisibilityChange],
	);

	return isExpanded ? (
		<ExpandedView
			isInternal={isInternal}
			isPublicCommentAllowed={isPublicCommentAllowed}
			onTabSelect={onTabSelect}
		>
			{children}
		</ExpandedView>
	) : (
		<CollapsedView
			onReplyToCustomer={onReplyToCustomer}
			onAddInternalNote={onAddInternalNote}
			isPublicCommentAllowed={isPublicCommentAllowed}
			replyToCustomerButtonActions={commentButtonActions}
			addInternalNoteButtonActions={commentButtonActions}
		/>
	);
};

export default ServiceDeskCommentEditorContainer;

const tabsContainerStyles = xcss({
	marginBottom: 'space.200',
});
