import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';
import { saveView } from '../save';
import { updateViewState } from '../utils/state';
import { applyTemporaryDataToView } from '../utils/temporary-data';
import { currentViewFilter } from '../utils/views';

export const applyViewTemporaryData =
	(viewId?: string): Action<State, Props> =>
	async ({ getState, setState, dispatch }, { currentViewSlug }) => {
		const state = getState();

		const { changedView, viewSets } = updateViewState(
			state.viewSets,
			viewId ? (view) => view.viewId === viewId : currentViewFilter(currentViewSlug),
			(view: View): View => ({
				...view,
				...applyTemporaryDataToView(view),
				temporaryData: undefined,
				modified: true,
			}),
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveView(changedView.id));
		}
	};

export const clearViewTemporaryData =
	(viewId?: string): Action<State, Props> =>
	async ({ getState, setState }, { currentViewSlug }) => {
		const state = getState();
		const { changedView, viewSets } = updateViewState(
			state.viewSets,
			viewId ? (view) => view.viewId === viewId : currentViewFilter(currentViewSlug),
			(view: View): View => ({
				...view,
				temporaryData: undefined,
			}),
		);

		if (changedView) {
			setState({ viewSets });
		}
	};
