import React from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { Emphasise } from '@atlassian/jira-issue-view-activity-common/src/styles';
import Change from '../../../../common/ui/change';
import GroupedChange from '../../../../common/ui/grouped-change';
import HistoryItem from '../../../../common/ui/history-item';
import type { CommentChangedHistoryItem } from '../../../../types';
import messages from './messages';

type Props = {
	isGrouped?: Boolean;
	historyItem: CommentChangedHistoryItem;
};

const CommentHistoryItem = ({ historyItem, isGrouped = false }: Props) => {
	const { formatMessage } = useIntl();
	const { actor, timestamp } = historyItem;
	return (
		// TODO: Message is currently always "deleted a comment"
		// Do we need to handle other types of comment changes?
		<FormattedMessage
			{...messages.deleted}
			values={{
				comment: (
					<Emphasise key="comment">
						{formatMessage(
							expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false)
								? messages.commentLowercase
								: messages.comment,
						)}
					</Emphasise>
				),
			}}
		>
			{(...action) =>
				isGrouped && expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false) ? (
					<GroupedChange field={formatMessage(messages.comment)} from={null} to={null} />
				) : (
					<HistoryItem // eslint-disable-next-line jira/integration/test-id-by-folder-structure
						testId="issue-history.ui.history-items.comment-history-item.history-item"
						actor={actor}
						action={action}
						timestamp={timestamp}
						change={<Change from={null} to={null} />}
					/>
				)
			}
		</FormattedMessage>
	);
};

export default CommentHistoryItem;
