// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	filterBySpace: {
		id: 'issue.views.confluence-search-view.filter-space',
		defaultMessage: 'Filter by space',
		description: 'Message for filtering by confluence space',
	},
	allSpaces: {
		id: 'issue.views.confluence-search-view.all-space',
		defaultMessage: 'All spaces',
		description: 'Heading for showing all spaces',
	},
	matchingSpaces: {
		id: 'issue.views.confluence-search-view.matching-space',
		defaultMessage: 'Matching spaces',
		description: 'Heading for showing matching spaces',
	},
});
