import React from 'react';
import EditIcon from '@atlaskit/icon/core/migration/edit';
import { getCommandPaletteIssueActionsPriority } from '@atlassian/jira-command-palette-common/src/common/utils/get-command-palette-issue-actions-priority/index.tsx';
import { getCommandPaletteIssueActionsHeader } from '@atlassian/jira-command-palette-common/src/common/utils/index.tsx';
import { useCommandKeywords } from '@atlassian/jira-command-palette-common/src/controllers/use-command-keywords/index.tsx';
import { COMMAND_PALETTE_REGISTRY_IDS } from '@atlassian/jira-command-palette-registry/src/common/constants/registry/index.tsx';
import { useRegisterCommands } from '@atlassian/jira-command-palette-registry/src/controllers/use-register-commands/index.tsx';
import {
	CommandActionType,
	type Command,
} from '@atlassian/jira-command-palette/src/common/types/commands/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { messages } from './messages';
import type { UseRegisterInCommandPaletteProps } from './types';

export const useRegisterInCommandPalette = ({
	onEditRequest,
}: UseRegisterInCommandPaletteProps) => {
	const issueKey = useIssueKey();
	const { formatMessage } = useIntl();

	const projectKey = useProjectKey(issueKey);
	const [{ canEditIssues }] = useProjectPermissions(projectKey);

	const { getKeywords } = useCommandKeywords();

	const commands: Command[] = canEditIssues
		? [
				{
					id: `issue-edit-description-${issueKey}`,
					name: formatMessage(messages.editDescription),
					keywords: getKeywords('editDescriptionSynonyms'),
					primaryAction: {
						type: CommandActionType.PERFORM,
						perform: () => onEditRequest(),
					},
					section: getCommandPaletteIssueActionsHeader(issueKey),
					priority: getCommandPaletteIssueActionsPriority('OLD_ISSUE_FIELDS'),
					components: {
						LeftIcon: () => (
							<EditIcon color="currentColor" label={formatMessage(messages.editDescription)} />
						),
					},
					analytics: {
						action: 'updateDescription',
					},
				},
			]
		: [];

	useRegisterCommands(COMMAND_PALETTE_REGISTRY_IDS.DESCRIPTION, commands, [
		issueKey,
		canEditIssues,
	]);
};
