/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/standard-button';
import Tooltip from '@atlaskit/tooltip';

import Enter from '../../assets/Enter';

import messages from './messages';
import { submitButtonStyles } from './styles';

type Props = {
	disabled: boolean;
	tabIndex?: number;
};

export const SubmitButton = ({ disabled, tabIndex }: Props) => {
	const { formatMessage } = useIntl();
	return (
		<Tooltip content={formatMessage(messages.submitTooltip)} hideTooltipOnClick position="top">
			<Button
				aria-label={formatMessage(messages.submitLabel)}
				testId="submit-icon-button"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css={submitButtonStyles}
				type="submit"
				appearance="subtle"
				spacing="compact"
				isDisabled={disabled}
				iconBefore={<Enter />}
				tabIndex={tabIndex}
			></Button>
		</Tooltip>
	);
};
