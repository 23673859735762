import { createSelector } from 'reselect';
import type { Permissions } from '@atlassian/jira-issue-view-common-constants/src/permissions.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import { cloudIdSelector } from './context-selector';
import { baseAriSelector } from './issue-selector';
import { permissionsSelector } from './permissions-selector';

export const commentAriSelector: (arg1: string) => (arg1: State) => string | null = (
	commentId: string,
) =>
	createSelector(baseAriSelector, (baseAri: string | null) => {
		if (!baseAri || !commentId) {
			return null;
		}

		return `${baseAri}:comment/${commentId}`;
	});

export const shouldShowReactionsSelector = createSelector(
	permissionsSelector,
	cloudIdSelector,
	(permissions: Permissions, cloudId: CloudId) => !!(permissions.canAddComments && cloudId),
);
