// JIV-16397: Remove this entire file when cleaning up issue-view-remove-connect-operations-from-critical-fetch_vtk4w
// This component does nothing as of this PR:
// https://stash.atlassian.com/projects/JIRACLOUD/repos/jira-frontend/pull-requests/65914/diff#src%2Fpackages%2Fissue%2Fissue-view%2Fsrc%2Fviews%2Fissue-base%2Fecosystem%2Fecosystem-analytics-view.js
import { Component } from 'react';
import flow from 'lodash/flow';
import noop from 'lodash/noop';
// eslint-disable-next-line import/order
import type {
	ConnectActivity,
	EcosystemContentPanel,
	EcosystemOperation,
	Glance,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/ecosystem.tsx';

import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { safeComponent } from '@atlassian/jira-issue-view-common-utils/src/safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import {
	activityPanelsSelector,
	ecosystemAllPanelsSelector,
	ecosystemGlancesSelector,
	ecosystemOperationsSelector,
} from '@atlassian/jira-issue-view-store/src/ecosystem/ecosystem-extensions-selector';
import { ecosystemCustomFieldIdsSelector } from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/layout-items-selector';

type Props = {
	glances: [Glance];
	contentPanels: [EcosystemContentPanel];
	activityPanels: [ConnectActivity];
	operations: [EcosystemOperation];
	ecosystemCustomFieldIds: string[];
	// eslint-disable-next-line jira/react/handler-naming
	ecosystemLoadedAnalytics?: (
		arg1: [Glance],
		arg2: [EcosystemContentPanel],
		arg3: [ConnectActivity],
		arg4: [EcosystemOperation],
		arg5: number,
	) => void;
};

// eslint-disable-next-line jira/react/no-class-components
export class EcosystemAnalyticsView extends Component<Props> {
	static defaultProps = {
		ecosystemLoadedAnalytics: noop,
	};

	componentDidMount() {
		this.fireAnalytics();
	}

	componentDidUpdate() {
		this.fireAnalytics();
	}

	fireAnalytics = () => {
		const { glances, contentPanels, activityPanels, operations, ecosystemCustomFieldIds } =
			this.props;
		this.props.ecosystemLoadedAnalytics?.(
			glances,
			contentPanels,
			activityPanels,
			operations,
			ecosystemCustomFieldIds.length,
		);
	};

	render() {
		return null;
	}
}

export const EcosystemAnalyticsViewWithoutAnalytics = flow(
	connect(
		(state: State) => ({
			glances: ecosystemGlancesSelector(state),
			contentPanels: ecosystemAllPanelsSelector(state),
			activityPanels: activityPanelsSelector(state),
			operations: ecosystemOperationsSelector(state),
			ecosystemCustomFieldIds: ecosystemCustomFieldIdsSelector(state),
		}),
		{},
	),
	safeComponent(),
)(EcosystemAnalyticsView);

export default EcosystemAnalyticsViewWithoutAnalytics;
