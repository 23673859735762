import { useEffect, useState } from 'react';
import { getCompassPermissions } from './utils';

export function useGetCompassPermissions() {
	const [loading, setLoading] = useState<boolean>(true);
	const [hasCompassPermissions, setHasCompassPermissions] = useState<boolean>(false);
	const [error, setError] = useState<Error | undefined>(undefined);

	useEffect(() => {
		getCompassPermissions() // eslint-disable-next-line @typescript-eslint/no-explicit-any
			.then((result: any) => {
				if (result.statusCode) {
					setHasCompassPermissions(result.statusCode === 200);
				} else {
					setHasCompassPermissions(!!result.entitlements);
				}
			})
			.catch((e) => setError(e))
			.finally(() => setLoading(false));
	}, []);

	return {
		loading,
		hasCompassPermissions,
		error,
	};
}
