/**
 * @generated SignedSource<<13bc69ab7548f0ea227c3e745e16b1c4>>
 * @relayHash bf41549d84c0d682846ff1582862ab6d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 760dfd52332ab37b8da9fb1431c78eb8d4aa98ba0ccf76d739c0767eae49faa1

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { ui_DesignPanelQuery } from './ui_DesignPanelQuery.graphql';

const node: PreloadableConcreteRequest<ui_DesignPanelQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "760dfd52332ab37b8da9fb1431c78eb8d4aa98ba0ccf76d739c0767eae49faa1",
    "metadata": {},
    "name": "ui_DesignPanelQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
