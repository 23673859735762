import React, { useMemo, useCallback } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { makeModalThreeLOPromptForCustomUI } from '@atlassian/forge-ui';
import { fg } from '@atlassian/jira-feature-gating';
import { withMetrics } from '@atlassian/jira-forge-ui-analytics/src/common/ui';
import {
	CONTEXT_TOKEN_TYPE_JIRA,
	ISSUE_VIEW_BACKGROUND_SCRIPT_MODULE,
} from '@atlassian/jira-forge-ui-constants';
import { LazyIframe } from '@atlassian/jira-forge-ui-iframe-generic';
import type { ForgeUIExtension } from '@atlassian/jira-forge-ui-renderers/src/ui/forge-ui-extension';
import type { IssueViewBackgroundScriptExtensionData } from '@atlassian/jira-forge-ui-types/src/common/types/extension-data.tsx';
import type {
	ExtensionNextProps,
	IssueViewBackgroundScript,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import type { CustomUIComponentMap } from '@atlassian/jira-forge-ui-types/src/common/types/external.tsx';
import type { IssueViewBackgroundScriptModule } from '@atlassian/jira-forge-ui-types/src/common/types/module.tsx';
import { isCustomUIExtension } from '@atlassian/jira-forge-ui-utils-internal/src/utils/extension';
import Placeholder from '@atlassian/jira-placeholder';
import { InvisibleDiv } from '../../common/ui/invisible-div';
import type { LazyProps, LazyRenderer } from '../../types';

type Props = ExtensionNextProps<
	IssueViewBackgroundScriptModule,
	IssueViewBackgroundScript,
	IssueViewBackgroundScriptExtensionData
>;

type PropsWithTestId = Props & { testId?: string };

const getModule = () =>
	import(
		/* webpackChunkName: "async-forge-ui-extension" */ '@atlassian/jira-forge-ui-renderers/src/ui/forge-ui-extension'
	);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const LazyForgeUIExtension: LazyRenderer<typeof ForgeUIExtension> = withMetrics(
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyForPaint(() => getModule().then((module) => module.ForgeUIExtension), {
		ssr: false,
	}),
);

export const AsyncIssueViewBackgroundScript = (props: LazyProps<PropsWithTestId>) => {
	const extensionData = useMemo(
		() => ({
			...props.extensionData,
			type: ISSUE_VIEW_BACKGROUND_SCRIPT_MODULE,
		}),
		[props.extensionData],
	);

	const components = useCallback(
		() =>
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			({
				ThreeLOPrompt: makeModalThreeLOPromptForCustomUI({
					// Replace with lodash/noop
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					onClose: () => {},
					appName: props.extension.properties.title,
				}),
			}) as CustomUIComponentMap,
		[props.extension.properties.title],
	);

	if (!isCustomUIExtension(props.extension)) return null;

	// https://switcheroo.atlassian.com/ui/gates/cd39682c-bc5b-4f4b-af87-f25672cfc326
	if (fg('jira_new_ui_kit_renderer')) {
		return (
			<InvisibleDiv>
				<Placeholder name="issue-view-background-script" fallback={null}>
					<LazyForgeUIExtension
						{...props}
						module={ISSUE_VIEW_BACKGROUND_SCRIPT_MODULE}
						extensionData={extensionData}
						contextToken={CONTEXT_TOKEN_TYPE_JIRA}
					/>
				</Placeholder>
			</InvisibleDiv>
		);
	}

	return (
		<InvisibleDiv>
			<Placeholder name="issue-view-background-script" fallback={null}>
				<LazyIframe
					{...props}
					module={ISSUE_VIEW_BACKGROUND_SCRIPT_MODULE}
					extensionData={extensionData}
					components={components}
					contextToken={CONTEXT_TOKEN_TYPE_JIRA}
				/>
			</Placeholder>
		</InvisibleDiv>
	);
};
