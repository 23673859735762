import has from 'lodash/has';
import isArray from 'lodash/isArray';
import some from 'lodash/some';
import type { GraphQLError } from 'graphql';
import type { ErrorMetadata } from '../types';

export type CustomError = Error & {
	statusCode?: number;
	networkError?: CustomError;
	graphQLErrors?: GraphQLError[];
};

export const isPermissionError = (errorObject: CustomError): boolean => {
	if (has(errorObject, 'statusCode')) {
		// http response error, check status code
		return errorObject.statusCode === 401 || errorObject.statusCode === 403;
	}

	if (has(errorObject, 'graphQLErrors') && isArray(errorObject.graphQLErrors)) {
		// graphql response error, check polaris-specific payload
		return some(errorObject.graphQLErrors, (error) => {
			const safeMessage = error.message || '';

			return (
				error.extensions?.statusCode === 403 ||
				error.extensions?.statusCode === 401 ||
				safeMessage.includes('JIRA_ACCESS_NOT_PERMITTED') ||
				safeMessage.includes('RequestStatus: 401') ||
				safeMessage.includes('RequestStatus: 403') ||
				safeMessage.includes('status code 401') || // that's probably obsolete
				safeMessage.includes('status code 403') // that's probably obsolete
			);
		});
	}

	return false;
};

const checkRateLimitErrorMatch = (error: CustomError | GraphQLError) => {
	const safeMessage = error?.message || '';
	return (
		(error && 'statusCode' in error && error?.statusCode === 429) ||
		(error && 'extensions' in error && error.extensions?.statusCode === 429) ||
		safeMessage.includes('RequestStatus: 429') ||
		safeMessage.includes('status code 429')
	);
};

export const isRateLimitError = (errorObject: CustomError): boolean => {
	return (
		checkRateLimitErrorMatch(errorObject) ||
		(errorObject.networkError && checkRateLimitErrorMatch(errorObject.networkError)) ||
		errorObject?.graphQLErrors?.some((error) => checkRateLimitErrorMatch(error)) ||
		false
	);
};

export const isAccessForbiddenError = (errorObject: CustomError): boolean => {
	if (has(errorObject, 'statusCode')) {
		// http response error, check status code
		return errorObject.statusCode === 403;
	}

	return false;
};

export const isNotFoundError = (errorObject: CustomError): boolean => {
	if (has(errorObject, 'statusCode')) {
		// http response error, check status code
		return errorObject.statusCode === 404;
	}

	return false;
};

export const isBadRequest = (errorObject: CustomError): boolean => {
	if (errorObject?.statusCode) {
		// http response error, check status code
		return errorObject?.statusCode >= 400 && errorObject?.statusCode < 500;
	}

	return false;
};

export const isConfigError = (errorObject: CustomError): boolean => {
	if (has(errorObject, 'statusCode') && has(errorObject, 'message')) {
		return errorObject.statusCode === 500 && errorObject.message === 'Property is too long';
	}

	return false;
};

export const getMetadata = (errorObject: Error): ErrorMetadata => ({
	metadata: { error: errorObject.message },
});
