// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	completionPercentageText: {
		id: 'issue.views.common.child-issues-panel.progress-summary.completion-percentage-text',
		defaultMessage: '{completionPercentage}% Done',
		description:
			'Text to be displayed next to the child issues progress bar which indicates percentage of child issues completed. Please ensure translation for the word Done aligns with the status category translation.',
	},

	todoIssuesSegmentTooltipText: {
		id: 'issue.views.common.child-issues-panel.progress-summary.todo-issues-segment-tooltip-text',
		defaultMessage: 'To Do: {todoIssueCount} of {totalIssueCount} issues',
		description:
			'Tooltip for the To Do segment of the progress bar in child issues panels, i.e. not classic subtasks.',
	},

	todoSubtasksSegmentTooltipText: {
		id: 'issue.views.common.child-issues-panel.progress-summary.todo-subtasks-segment-tooltip-text',
		defaultMessage: 'To Do: {todoIssueCount} of {totalIssueCount} subtasks',
		description: 'Tooltip for the To Do segment of the progress bar in classic subtask panels.',
	},

	inProgressIssuesSegmentTooltipText: {
		id: 'issue.views.common.child-issues-panel.progress-summary.in-progress-issues-segment-tooltip-text',
		defaultMessage: 'In Progress: {inProgressIssueCount} of {totalIssueCount} issues',
		description:
			'Tooltip for the In Progress segment of the progress bar in child issues panels, i.e. not classic subtasks.',
	},

	inProgressSubtasksSegmentTooltipText: {
		id: 'issue.views.common.child-issues-panel.progress-summary.in-progress-subtasks-segment-tooltip-text',
		defaultMessage: 'In Progress: {inProgressIssueCount} of {totalIssueCount} subtasks',
		description:
			'Tooltip for the In Progress segment of the progress bar in classic subtask panels.',
	},

	completedIssuesSegmentTooltipText: {
		id: 'issue.views.common.child-issues-panel.progress-summary.completed-issues-segment-tooltip-text',
		defaultMessage: 'Done: {completedIssueCount} of {totalIssueCount} issues',
		description:
			'Tooltip for the Done segment of the progress bar in child issues panels, i.e. not classic subtasks.',
	},

	completedSubtasksSegmentTooltipText: {
		id: 'issue.views.common.child-issues-panel.progress-summary.completed-subtasks-segment-tooltip-text',
		defaultMessage: 'Done: {completedIssueCount} of {totalIssueCount} subtasks',
		description: 'Tooltip for the Done segment of the progress bar in classic subtask panels.',
	},
});
