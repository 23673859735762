import { createSessionExpirableStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/session-storage/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { createEventBus } from './event-bus';

const ONE_DAY = 24 * 60 * 60 * 1000; // 1 day
const storage = createSessionExpirableStorageProvider('jira-platform-persistent-cache');

export const getSessionStorageData = <T,>(key: string): T | undefined => {
	const data = storage.get(key);
	if (data) {
		try {
			return JSON.parse(data);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error('getSessionStorageData', e);
			return undefined;
		}
	}
	return undefined;
};
export const setSessionStorageData = (key: string, value: unknown) => {
	try {
		storage.set(key, JSON.stringify(value), Date.now() + ONE_DAY);
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error('setSessionStorageData', e);
	}
};

export const updateBus = createEventBus();

export const pendingRequests: Record<string, Promise<unknown> | undefined> = {};

/**
 * A broker used to handle a single version of a request at a single point of time
 * @param storageKey
 * @param request
 */
export const handleDataRequest = async (
	storageKey: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	request: () => any | Promise<any>,
) => {
	// If there's already a pending request for this storageKey, ignore this one
	if (pendingRequests[storageKey]) return pendingRequests[storageKey];

	// Otherwise, add this request to the list of pending requests

	try {
		const pending = request();
		pendingRequests[storageKey] = pending;
		// Wait for the request to complete
		const newValue = await pending;

		// update store and inform consumers
		setSessionStorageData(storageKey, newValue);
		updateBus.emit(storageKey, newValue);
	} catch (error) {
		if (error instanceof Error) {
			fireErrorAnalytics({
				error,
				meta: {
					id: 'handleDataRequest',
					packageName: 'jiraPlatformPersistentCache',
					teamName: 'crocs',
				},
			});
		} else {
			throw error;
		}
	} finally {
		// Remove this request from the list of pending requests
		delete pendingRequests[storageKey];
	}
};
