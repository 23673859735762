import { useMemo, useState, useCallback, useEffect } from 'react';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useQueryParam } from '@atlassian/react-resource-router';
import type { SortingOption } from '../model/types';
import { useSortableIssueIdsStore, useChildIssuesStoreSortBy } from './context';

export const useSortableIssueIds = () => {
	const [sortableIssueIds, { setSortableIssueIds }] = useSortableIssueIdsStore();

	const setter = useCallback(
		(issueIds: IssueId[]) => setSortableIssueIds(issueIds),
		[setSortableIssueIds],
	);

	const actions = useMemo(
		() => ({
			setSortableIssueIds: setter,
		}),
		[setter],
	);

	return [sortableIssueIds, actions] as const;
};

export const useChildIssuesSortBy = () => {
	const [sortBy, { setSortBy: setSortByStore }] = useChildIssuesStoreSortBy();

	const setSortBy = useCallback(
		(value: SortingOption) => {
			setSortByStore(value);
		},
		[setSortByStore],
	);

	const actions = useMemo(
		() => ({
			setSortBy,
		}),
		[setSortBy],
	);

	return [sortBy, actions] as const;
};

export const useShouldTriggerAiSuggestedIssues = (): boolean => {
	const [suggestIssue] = useQueryParam('suggestIssue');

	const [shouldShowSuggestedAiIssues, setShouldShowSuggestedAiIssues] = useState(false);

	// The `?suggestIssue=true` Query Param gets removed the moment `/ai-issue-breakdown/ui/issue-breakdown-button/index.tsx`
	// gets mounted, so we can't rely on it to stay in the URL.
	useEffect(() => {
		if (suggestIssue && suggestIssue === 'true') {
			setShouldShowSuggestedAiIssues(true);
		}
	}, [suggestIssue]);

	return shouldShowSuggestedAiIssues;
};
