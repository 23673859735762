import React, { useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import {
	type ClassificationOption,
	type Color,
	EditPopup,
} from '@atlassian/data-classification-level';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	DRAWER,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type {
	editViewPopup_issueClassificationBadge_ClassificationEditViewPopup$key as ClassificationEditViewPopupFragment,
	editViewPopup_issueClassificationBadge_ClassificationEditViewPopup$data as ClassificationEditViewPopupData,
} from '@atlassian/jira-relay/src/__generated__/editViewPopup_issueClassificationBadge_ClassificationEditViewPopup.graphql';
import { messages } from './messages';
import type { EditViewProps } from './types';
import { getProjectDefaultClassificationOption } from './utils';

const transformClassification = (
	classification:
		| ClassificationEditViewPopupData['classificationLevel' | 'defaultClassificationLevel']
		| null,
): ClassificationOption => {
	const color = classification?.color?.colorKey;
	// Type '{ id: string; name: string | undefined; color: Color; guideline: string | undefined; }' is not assignable to type 'Pick<{ label?: string | undefined; color?: Color | undefined; includeIcon?: boolean | undefined; classificationOverride?: ClassificationOption<{}> | undefined; } & Classification, "id" | ... 2 more ... | "classificationOverride"> & Required<...> & Partial<...>'.
	// Property 'definition' is missing in type '{ id: string; name: string | undefined; color: Color; guideline: string | undefined; }' but required in type 'Required<Pick<{ label?: string | undefined; color?: Color | undefined; includeIcon?: boolean | undefined; classificationOverride?: ClassificationOption<{}> | undefined; } & Classification, "definition">>'
	// Possible Solutions : Need to fix the type definition in data-classification-level component or pass in definition as a key in the object(this'll mean modifying the graphQL types as well i.e. ClassificationEditViewPopupData)
	// @ts-expect-error TS2322: Type '{ id: string; name: string | undefined; color: Color; guideline: string | undefined; }' is not assignable to type 'ClassificationOption<{}>'.
	return {
		id: classification?.id,
		name: classification?.name || undefined,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		color: color as Color,
		definition:
			(ff('dlp-field-selected-value-and-definition-changes_4jiut')
				? classification?.definition
				: classification?.guidelines) || undefined,
	};
};

const transformAGGtoSelectOption = (
	classificationLevels: ClassificationEditViewPopupData['classificationLevels'],
	defaultClassificationLevel: ClassificationEditViewPopupData['defaultClassificationLevel'],
	// @ts-expect-error TS7006: Parameter 'formatMessage' implicitly has an 'any' type.
	formatMessage,
): ClassificationOption[] => {
	const classificationValues =
		classificationLevels?.edges
			?.map((edge) => edge?.node ?? null)
			.filter(Boolean)
			.filter((classification) => classification && classification.name != null) || [];
	const classificationOptions: ClassificationOption[] = classificationValues.map(
		(classification) => ({
			id: classification.id,
			name: classification.name || '',
			definition:
				(ff('dlp-field-selected-value-and-definition-changes_4jiut')
					? classification.definition
					: classification.guidelines) || undefined,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			color: classification.color?.colorKey as Color,
		}),
	);

	if (defaultClassificationLevel) {
		// Project Default Classification option
		classificationOptions.push(
			getProjectDefaultClassificationOption(
				formatMessage(messages.projectDefaultClassficationName),
				defaultClassificationLevel,
			),
		);
	} else {
		// No Classification option
		classificationOptions.push({
			id: undefined,
			definition: formatMessage(messages.noClassificationGuideline),
			color: undefined,
			includeIcon: false,
		});
	}

	return classificationOptions;
};

export const ClassificationEditViewPopup = ({
	onEditCancel,
	onEditSubmit,
	editViewPopupFragment,
	customHeading,
}: EditViewProps) => {
	const {
		classificationLevel,
		classificationLevels,
		defaultClassificationLevel,
		classificationLevelSource,
	} = useFragment<ClassificationEditViewPopupFragment>(
		graphql`
			fragment editViewPopup_issueClassificationBadge_ClassificationEditViewPopup on JiraDataClassificationField {
				classificationLevel {
					id
					name
					color {
						colorKey
					}
					guidelines
					definition
				}
				classificationLevels(
					first: 10
					filterByCriteria: { filterByStatus: [PUBLISHED], filterByType: [USER] }
				) @optIn(to: "JiraDataClassificationFieldOptions") {
					edges {
						node {
							id
							name
							color {
								colorKey
							}
							guidelines
							definition
						}
					}
				}
				classificationLevelSource
				defaultClassificationLevel {
					id
					name
					color {
						colorKey
					}
					guidelines
					definition
				}
			}
		`,
		editViewPopupFragment,
	);

	const { formatMessage } = useIntl();
	const [showUpdateSpinner, setShowUpdateSpinner] = useState(false);
	const classificationOptions = transformAGGtoSelectOption(
		classificationLevels,
		defaultClassificationLevel,
		formatMessage,
	);
	const currentClassificationLevel =
		classificationLevel && transformClassification(classificationLevel);

	const noClassificationAsClassificationOption: ClassificationOption = {
		name: formatMessage(messages.noClassificationGuideline),

		color: undefined,
		includeIcon: false,
	};

	const getSelectedClassification = () => {
		if (
			ff('dlp-field-selected-value-and-definition-changes_4jiut') &&
			classificationLevelSource === 'PROJECT'
		) {
			return classificationOptions.slice(-1)[0] || [];
		}
		return currentClassificationLevel;
	};

	return (
		<ContextualAnalyticsData sourceType={DRAWER} sourceName="classificationEditViewPopup">
			<FireScreenAnalytics />
			<EditPopup
				customHeading={customHeading}
				options={classificationOptions}
				selectedOption={getSelectedClassification() || noClassificationAsClassificationOption}
				onEditCancel={onEditCancel}
				onEditSubmit={(classificationNew: ClassificationOption) => {
					setShowUpdateSpinner(true);
					onEditSubmit(classificationNew, setShowUpdateSpinner);
				}}
				loading={showUpdateSpinner}
			/>
		</ContextualAnalyticsData>
	);
};
