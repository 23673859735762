import versionsCache from '@atlassian/jira-cache/src/services/versions/index.tsx';
import type { ProjectType } from '@atlassian/jira-common-constants';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { toQuotedString, createIssuesUrl } from '@atlassian/jira-common-jql/src/helpers';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { Version } from '@atlassian/jira-issue-shared-types/src/common/types/issue-type.tsx';
import { AFFECTS_VERSIONS, FIX_VERSIONS } from '@atlassian/jira-issue-view-configurations';
import type { BaseUrl, ProjectKey, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages';

type CachedVersion = Version & {
	projectId: ProjectId;
};

type StateValue = {
	name: string;
	id: string;
	archived?: boolean;
	released?: boolean;
	fromCache?: boolean;
};
type TransformedCachedVersion = Version & {
	projectId: ProjectId;
	fromCache?: boolean;
};

type Item = {
	content: string;
	value: string;
	fromCache?: boolean;
	href?: string;
};

const toItem = (item: Version | TransformedCachedVersion): Item => ({
	content: item.name,
	value: item.id,
	// @ts-expect-error - TS2339 - Property 'fromCache' does not exist on type 'Version | TransformedCachedVersion'.
	fromCache: item.fromCache || false,
});

// JQL search keys don't actually match the field keys
const fieldKeyToJQLMap = {
	[AFFECTS_VERSIONS]: 'affectedVersion',
	[FIX_VERSIONS]: 'fixVersion',
} as const;
// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly versions: "affectedVersion"; readonly fixVersions: "fixVersion"; }'.
const getJqlKey = (fieldKey: string) => fieldKeyToJQLMap[fieldKey] || 'fixVersion';

const getVersionUrl = ({
	version,
	fieldId,
	projectKey,
	projectType,
}: {
	version: Version;
	fieldId: string;
	projectKey: ProjectKey;
	projectType?: ProjectType;
	baseUrl: BaseUrl;
}) => {
	if (
		// Version details page is only available in Software projects.
		projectType === SOFTWARE_PROJECT &&
		// Version details page only shows issues that are *fixed* in a version, not those that *affect* it.
		// Therefore it only makes sense to link to the version details page from the fix version field.
		fieldId === FIX_VERSIONS
	) {
		return `/projects/${projectKey}/versions/${version.id}/tab/release-report-all-issues`;
	}
	// Use an issue search URL as a fallback for cases where the version details page doesn't make sense.
	const jql = `project = ${toQuotedString(projectKey)} AND ${getJqlKey(
		fieldId,
	)} = ${toQuotedString(version.name)}`;
	return createIssuesUrl(jql);
};

export const transformFromStateValueWithVersionLink = (
	value: Version[],
	{
		baseUrl,
		projectKey,
		projectType,
		fieldId,
	}: {
		baseUrl: BaseUrl;
		projectKey: ProjectKey;
		projectType?: ProjectType;
		fieldId: string;
	},
): Item[] =>
	value
		? value.map((item) => ({
				href: getVersionUrl({ version: item, fieldId, projectKey, projectType, baseUrl }),
				content: item.name,
				value: item.id,
			}))
		: [];

export const transformFromStateValue = (value: Version[] | null): Item[] =>
	value ? value.map(toItem) : [];

export const transformToStateValue = (value: Item[]): StateValue[] =>
	value.map((item) => ({
		name: item.content,
		id: item.value,
		fromCache: item.fromCache,
	}));

export const validateCacheItems = (
	rest: Version[],
	cache: CachedVersion[],
	projectId: ProjectId,
): TransformedCachedVersion[] =>
	cache
		.filter((item) => item.projectId === projectId)
		.map((item) => {
			const duplicatedItem = rest.find((restItem) => restItem.id === item.id);
			if (duplicatedItem) {
				const newItem = { ...duplicatedItem, projectId };
				versionsCache.update(`${item.id}`, newItem);
				return { ...newItem, fromCache: true };
			}
			return { ...item, fromCache: true };
		});

const MAX_RESULTS = 5;

export const transformCacheEntries = (
	cache: TransformedCachedVersion[],
): TransformedCachedVersion[] => cache.slice(-1 * MAX_RESULTS).reverse();

export const transformSuggestionsFromServerAndCache = (
	suggestionsFromServer: Version[],
	suggestionsFromCache: CachedVersion[],
	intl: IntlShape,
	projectId: ProjectId,
): {
	heading: string;
	items: Item[];
}[] => {
	const activeSuggestions = suggestionsFromServer.filter((suggestion) => !suggestion.archived);

	const unreleasedItems = activeSuggestions
		.filter((suggestion) => !suggestion.released)
		.map(toItem);

	const releasedItems = activeSuggestions.filter((suggestion) => suggestion.released).map(toItem);

	const suggestionsFromServerIds = new Set(suggestionsFromServer.map(({ id }) => id));

	const cacheItems = validateCacheItems(suggestionsFromServer, suggestionsFromCache, projectId)
		.filter((v) => !v.archived)
		// the following filter fixes a bug where archived versions are still showing despite the server response not including them.
		// this is because the the archived version may still exist inside the browser cache with an 'unreleased' status.
		// to fix this, remove any version in the browser cache that isn't part of the server response.
		// https://hello.atlassian.net/wiki/spaces/~494408470/pages/1657964060/Recent+Versions+Bug
		.filter((v) => suggestionsFromServerIds.has(v.id));

	const cacheLists = transformCacheEntries(cacheItems);

	return [
		{
			heading: intl.formatMessage(messages.recent),
			items: cacheLists.map(toItem),
		},
		{
			heading: intl.formatMessage(messages.unreleasedHeading),
			items: unreleasedItems,
		},
		{
			heading: intl.formatMessage(messages.releasedHeading),
			items: releasedItems,
		},
	];
};
