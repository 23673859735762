import React, { memo, useEffect, useRef, useState } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useProjectKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { ErrorFlag } from '@atlassian/jira-issue-error-flag';
import { smoothScrollIntoCenterIfNeeded } from '@atlassian/jira-issue-view-common-utils/src/scroll/index.tsx';
import SquareAddIconButton from '@atlassian/jira-issue-view-common-views/src/button/square-add-icon-button';
import {
	SectionHeading,
	SectionHeadingIcons,
	SectionHeadingTitle,
} from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useForms } from '@atlassian/jira-proforma-panel-in-issue-view-services/src/services/forms-status-service/index.tsx';
import { ProformaFormsViewLazy } from '@atlassian/jira-proforma-panel-in-issue-view-ui/src/async.tsx';
import { useCanAdministerProject } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages';

export const actionSubject = 'summaryInlineEdit';

type Props = {
	issueKey: string;
};

export const ProformaFormsPanelView = ({ issueKey }: Props) => {
	const projectKey = useProjectKey();
	const isProjectAdmin = useCanAdministerProject(projectKey);
	const formsContainer = useRef<HTMLDivElement>(null);
	const [panelAddFormButtonClickedCount, setPanelAddFormButtonClickedCount] = useState(0);
	const { formatMessage } = useIntl();

	const [
		{
			value: { hasIssueForms, issueViewAddFormButtonClickedCount },
			fieldConfig,
			error,
		},
		{ resetAddFormButtonCount },
	] = useForms({
		issueKey: toIssueKey(issueKey),
	});

	const isFormsEditable = fieldConfig?.value?.isEditable || false;

	useEffect(() => {
		if (issueViewAddFormButtonClickedCount > 0) {
			smoothScrollIntoCenterIfNeeded(formsContainer.current);
		}
	}, [issueViewAddFormButtonClickedCount]);

	useEffect(() => {
		// close Add Form dropdown panel if issue key is changed
		resetAddFormButtonCount();
	}, [issueKey, resetAddFormButtonCount]);

	const shouldShowFormsPanel =
		issueViewAddFormButtonClickedCount > 0 ? true : hasIssueForms === true;

	const onClickAddIconButton = (_: React.MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
		setPanelAddFormButtonClickedCount(panelAddFormButtonClickedCount + 1);
		fireUIAnalytics(analyticsEvent, 'addFormIcon');
	};

	if (error) {
		return (
			<ErrorFlag
				error={error}
				title={messages.useFormsErrorTitle}
				description={messages.useFormsErrorMessage}
			/>
		);
	}

	return shouldShowFormsPanel ? (
		<div
			ref={formsContainer}
			/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
			data-testid="issue.views.issue-base.content.proforma-forms"
		>
			<SectionHeading>
				<SectionHeadingTitle>{formatMessage(messages.attachedFormsHeader)}</SectionHeadingTitle>
				<SectionHeadingIcons>
					{isFormsEditable && (
						<SquareAddIconButton
							label={formatMessage(messages.addFormIconLabel)}
							onClick={onClickAddIconButton}
						/>
					)}
				</SectionHeadingIcons>
			</SectionHeading>
			<ProformaFormsViewContainer>
				<ProformaFormsViewLazy
					canEditIssues={isFormsEditable}
					isProjectAdmin={isProjectAdmin}
					panelAddFormButtonClickedCount={
						issueViewAddFormButtonClickedCount + panelAddFormButtonClickedCount
					}
				/>
			</ProformaFormsViewContainer>
		</div>
	) : null;
};

export const ProformaFormsPanelViewErrorWrapper = ({ issueKey }: Props) => (
	/*
	 * We want any Proforma errors to be caught here and not crash the whole issue view page.
	 * JSErrorBoundary will show a generic "please refresh the page" message in the same place
	 * the Proforma forms normally would show.
	 * */
	<JSErrorBoundary
		id="ProformaFormsPanelViewBoundary"
		packageName="jiraIssueViewBase"
		teamName="jsm-request-intake" // jsm-request-intake, not Bento
		/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
		data-testid="issue.views.issue-base.content.proforma-forms.dependency-line"
	>
		<ProformaFormsPanelView issueKey={issueKey} />
	</JSErrorBoundary>
);

export default memo<Props>(ProformaFormsPanelViewErrorWrapper);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProformaFormsViewContainer = styled.div({
	paddingTop: `${token('space.100', '8px')}`,
	paddingBottom: `${token('space.500', '40px')}`,
});
