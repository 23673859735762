type VersionItem = {
	content: string;
	value: string;
};

type StateValue = {
	id: string;
	name: string;
};

export const transformToStateValue = (item: VersionItem | null): StateValue | null =>
	item && {
		name: item.content,
		id: item.value,
	};

export const transformFromStateValue = (stateValue: StateValue | null): VersionItem | null =>
	stateValue && {
		content: stateValue.name,
		value: stateValue.id,
	};
