import React from 'react';
import CommonClipboardMediaPicker from '../../common/clipboard';
import EventHandlersProvider from '../common/event-handlers-provider';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<EventHandlersProvider
		render={(eventHandlers) => <CommonClipboardMediaPicker eventHandlers={eventHandlers} />}
	/>
);
