import type {
	FieldId,
	FieldInternalValue,
} from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/types/field.tsx';
import type { FieldType } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/views/types.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { IssueAdjustmentsState, StoreContainerProps } from '../../types';
import { calculateNextFormValue } from '../../utils/calculate-next-form-value';

type SetFieldValuePayload = {
	fieldId: FieldId;
	fieldType: FieldType;
	fieldValue: FieldInternalValue;
};

/**
 * Sets new field value after intialization. Used for handling e.g. real-time refresh in the Issue View.
 *
 * Warning:
 * - If you want to handle the UI modification onChange, use `processChange` action instead.
 * - If you want to initialize the field, use `initializeField` action instead.
 */
export const setFieldValue =
	({ fieldId, fieldType, fieldValue }: SetFieldValuePayload) =>
	(
		{ getState, setState }: StoreActionApi<IssueAdjustmentsState>,
		{ viewType }: StoreContainerProps,
	) => {
		const { internalFormMetadata, formData } = getState();

		/**
		 * We can set new field value only if the field was initialized.
		 * This checks the field metedata that indicates the successfull initialization.
		 */
		if (!internalFormMetadata[fieldId]) {
			return;
		}

		const nextFormData = calculateNextFormValue({
			internalFormMetadata,
			formData,
			fieldType,
			fieldId,
			fieldValue,
			viewType,
		});

		if (nextFormData === null) {
			return;
		}

		setState({ formData: nextFormData });
	};
