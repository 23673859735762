import { Component, type ReactElement } from 'react';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import set from 'lodash/set';
import some from 'lodash/some';
import type {
	DocNode,
	MediaDefinition,
	MediaGroupDefinition,
	MediaSingleDefinition,
	DocNode as ADF,
} from '@atlaskit/adf-schema';
import { doc, media, mediaGroup, mediaSingle, p } from '@atlaskit/adf-utils/builders';
import type {
	MediaFile,
	UploadsStartEventPayload,
	UploadEndEventPayload,
	UploadPreviewUpdateEventPayload,
	UploadErrorEventPayload,
} from '@atlaskit/media-picker';
import type { ProjectType } from '@atlassian/jira-common-constants';
import log from '@atlassian/jira-common-util-logging/src/log';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import type { UploadContext } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/media-context.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import { commentAttachmentExperienceDescription } from '@atlassian/jira-issue-view-common-utils/src/experience-tracking/comment-experience-tracking';
import type { UFOExperience } from '@atlassian/ufo';
import { concurrentAttachmentUploadExperience } from '../../../../../../experiences';
import type { EventHandlers } from '../../../common/with-media-picker-props-provider';

export type Props = {
	uploadContext: UploadContext | null;
	setCommentAttachmentInProgress: (isInProgress: boolean) => void;
	render: (arg1: EventHandlers) => ReactElement;
	projectType?: ProjectType;
	onEditCommentUpdate: (arg1: ADF) => void;
};

type CommentAttachment = {
	file: MediaFile;
	isUploadFinished: boolean;
	ufoExperience: UFOExperience;
};

export const ERROR_LOCATION = 'issue.issue-base.content.attachment.picker.servicedesk';

const getAndStartUFOExperience = (fileId: string): UFOExperience => {
	const experience = concurrentAttachmentUploadExperience.getInstance(fileId);
	experience.start();
	return experience;
};

// eslint-disable-next-line jira/react/no-class-components
export default class EventHandlersProvider extends Component<Props> {
	onUploadsStart = ({ files }: UploadsStartEventPayload) => {
		this.props.setCommentAttachmentInProgress(true);
		this.commentAttachments = files.map((file) => ({
			file,
			isUploadFinished: false,
			ufoExperience: getAndStartUFOExperience(file.id),
		}));
	};

	onUploadFinished = ({ file }: UploadEndEventPayload | UploadPreviewUpdateEventPayload) => {
		this.commentAttachments
			?.filter((comment) => comment.file.id === file.id)
			.forEach((comment) => comment.ufoExperience.success());

		this.injectAttachmentsToCommentEditor(this.commentAttachments);
		this.markAttachmentAsUploadFinished(file.id);

		sendExperienceAnalytics(
			commentAttachmentExperienceDescription({
				wasSuccessful: true,
				action: 'ATTACH',
				analyticsSource: 'servicedeskAttachmentPicker',
				projectType: this.props.projectType,
			}),
		);
	};

	onUploadError = ({ fileId, error }: UploadErrorEventPayload) => {
		this.commentAttachments
			?.filter((comment) => comment.file.id === fileId)
			.forEach((comment) => comment.ufoExperience.failure());

		log.safeErrorWithoutCustomerData(
			ERROR_LOCATION,
			'Failed to upload an attachment.',
			error.rawError || new Error(error.description),
		);

		this.markAttachmentAsUploadFinished(fileId);

		const secondaryReason = error.description;

		const wasClientFetchError =
			secondaryReason === 'serverUnexpectedError' && error.rawError
				? isClientFetchError({
						name: 'Error',
						message: error.rawError?.message,
					})
				: false;

		if (!wasClientFetchError) {
			const primaryReason = error.name;

			const wasSuccessful = [
				'clientAbortedRequest',
				'serverUnauthorized',
				'serverForbidden',
				'serverNotFound',
				'serverRateLimited',
				'serverBadGateway',
				'failedAuthProvider',
				'tokenExpired',
				'invalidFileId',
				'zeroVersionFile',
			].includes(secondaryReason);

			sendExperienceAnalytics(
				commentAttachmentExperienceDescription({
					wasSuccessful,
					action: 'ATTACH',
					analyticsSource: 'servicedeskAttachmentPicker',
					projectType: this.props.projectType,
					errorMessage: wasSuccessful ? undefined : `${primaryReason} - ${secondaryReason}`,
				}),
			);
		}
	};

	getAttachmentsDocNode = (
		commentAttachments: CommentAttachment[],
		collection: string,
	): DocNode => {
		const attachments = this.separateImageAndNonImageAttachments(commentAttachments);

		const imageAttachmentNodes = this.getMediaSingleNodes(attachments.images, collection);
		const nonImageAttachmentGroup =
			attachments.nonImages.length > 0
				? this.getMediaGroupNode(attachments.nonImages, collection)
				: null;

		return nonImageAttachmentGroup
			? doc(nonImageAttachmentGroup, ...imageAttachmentNodes, p())
			: doc(...imageAttachmentNodes, p());
	};

	getMediaSingleNodes = (
		commentAttachments: CommentAttachment[],
		collection: string,
	): MediaSingleDefinition[] =>
		commentAttachments.map((commentAttachment) =>
			this.getMediaSingleNode(commentAttachment, collection),
		);

	getMediaSingleNode = (
		commentAttachment: CommentAttachment,
		collection: string,
	): MediaSingleDefinition => {
		const mediaNode = this.getMediaNode(commentAttachment, 200, 200, collection);

		return mediaSingle({
			layout: 'center',
		})(mediaNode);
	};

	getMediaGroupNode = (
		commentAttachments: CommentAttachment[],
		collection: string,
	): MediaGroupDefinition =>
		mediaGroup(
			...commentAttachments.map((commentAttachment) =>
				this.getMediaNode(commentAttachment, null, null, collection),
			),
		);

	getMediaNode = (
		{ file }: CommentAttachment,
		width: number | null,
		height: number | null,
		collection: string,
	): MediaDefinition =>
		media({
			id: file.id,
			collection,
			type: 'file',
			name: file.name,
			size: file.size,
			// @ts-expect-error - TS2322 - Type 'number | null' is not assignable to type 'number | undefined'.
			width,
			// @ts-expect-error - TS2322 - Type 'number | null' is not assignable to type 'number | undefined'.
			height,
			__key: file.id,
			__fileName: file.name,
			__fileSize: file.size,
			__fileMimeType: file.type,
			__displayType: null,
		});

	/**
	 * To prevent users from failing to save comments, we need to wait until all of the attachments have finished
	 * uploading before allowing users to save the comment. In the mean time the users will see a disabled comment
	 * editor with a spinner indicating the attachments are being uploaded.
	 *
	 * Due to the way how the `upload-end` event works, it is triggered on each attachment not all of them, we need to
	 * keep track of the status of each attachment individually.
	 */
	markAttachmentAsUploadFinished = (fileId: string) => {
		set(
			// @ts-expect-error - TS2769 - No overload matches this call.
			find(this.commentAttachments, (attachment) => attachment.file.id === fileId),
			'isUploadFinished',
			true,
		);
		if (!some(this.commentAttachments, { isUploadFinished: false })) {
			this.props.setCommentAttachmentInProgress(false);
			this.commentAttachments = [];
		}
	};

	// Use to keep track of the list of attachments being uploaded, with their uploading status.
	// @ts-expect-error - TS2564 - Property 'commentAttachments' has no initializer and is not definitely assigned in the constructor.
	commentAttachments: CommentAttachment[];

	separateImageAndNonImageAttachments = (
		commentAttachments: CommentAttachment[],
	): {
		images: CommentAttachment[];
		nonImages: CommentAttachment[];
	} =>
		reduce(
			commentAttachments,
			(result, commentAttachment) => {
				if (this.isImageAttachment(commentAttachment.file.type)) {
					// @ts-expect-error - TS2345 - Argument of type 'CommentAttachment' is not assignable to parameter of type 'never'.
					result.images.push(commentAttachment);
				} else {
					// @ts-expect-error - TS2345 - Argument of type 'CommentAttachment' is not assignable to parameter of type 'never'.
					result.nonImages.push(commentAttachment);
				}
				return result;
			},
			{
				images: [],
				nonImages: [],
			},
		);

	injectAttachmentsToCommentEditor = (commentAttachments: CommentAttachment[]) => {
		const { uploadContext } = this.props;

		if (!uploadContext) {
			log.safeErrorWithoutCustomerData(
				ERROR_LOCATION,
				'Failed to upload attachments. Upload context not available.',
				new Error('Failed to upload attachments. Upload context not available.'),
			);
			return;
		}

		const attachmentsDocNode = this.getAttachmentsDocNode(
			commentAttachments,
			uploadContext.collection,
		);

		this.props.onEditCommentUpdate(attachmentsDocNode);
	};

	isImageAttachment = (fileType?: string): boolean =>
		!!fileType && (fileType.indexOf('image/') > -1 || fileType.indexOf('video/') > -1);

	// Service Desk issue has a different way to attach files, every new attachments should go into a new comment.
	// @ts-expect-error - TS2741 - Property '['upload-preview-update']' is missing in type '{ 'uploads-start': ({ files }: UploadsStartEventPayload) => void; 'upload-end': ({ file }: UploadEndEventPayload | UploadPreviewUpdateEventPayload) => void; 'upload-error': ({ fileId, error }: UploadErrorEventPayload) => void; }' but required in type 'EventHandlers'.
	eventHandlers: EventHandlers = {
		'uploads-start': this.onUploadsStart,
		'upload-end': this.onUploadFinished,
		'upload-error': this.onUploadError,
	};

	render() {
		const { render } = this.props;
		return render(this.eventHandlers);
	}
}
