import React, { type ReactNode } from 'react';
import { lazy } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type {
	ServiceEntityMain as IssueAffectedServicesFieldType,
	Props as AsyncServiceEntityFieldType,
} from './main';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AsyncLazyServiceEntityField = lazy<typeof IssueAffectedServicesFieldType>(() =>
	import(/* webpackChunkName: "async-services-entity-inline-edit-view" */ './main').then(
		(module) => module.ServiceEntityMain,
	),
);

export const AsyncServiceEntityField = (props: AsyncServiceEntityFieldType) => (
	<ErrorBoundary id="field-service-entity" packageName="jira-issue-field-service-entity">
		<Placeholder name="service-entity-field" fallback={<></>}>
			<AsyncLazyServiceEntityField {...props} />
		</Placeholder>
	</ErrorBoundary>
);
export type IssueServiceEntityBoundaryProps = {
	children: ReactNode;
	packageName: string;
};

export const IssueServiceEntityFieldBoundary = ({
	children,
	packageName,
}: IssueServiceEntityBoundaryProps) => (
	<JSErrorBoundary id="issue-service-entity-field" packageName={packageName} fallback="flag">
		{children}
	</JSErrorBoundary>
);
