import { useEffect } from 'react';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { createHook, createStore } from '@atlassian/react-sweet-state';
import { PACKAGE_NAME } from '../../constants';
import { type Actions, actions as subscriberActions } from './actions';
import type { State } from './types';

export const initialState: State = {
	data: {
		hasOpsgenieAccount: undefined,
	},
	isLoading: false,
	error: undefined,
};

export const hasOpsgenieAccountStore = createStore<State, Actions>({
	name: 'hasOpsgenieAccount',
	initialState,
	actions: subscriberActions,
});

// NOTE: use useHasCurrentUserOpsgenieAccount instead, as it fetches state if it is undefined
export const useHasOpsgenieAccountInternal = createHook(hasOpsgenieAccountStore);

export const useHasCurrentUserOpsgenieAccount = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [state, { fetchHasOpgenieAccount }] = useHasOpsgenieAccountInternal();
	const userId = useCurrentUser().data.user.accountId;
	const { baseUrl, cloudId, isAnonymous } = useTenantContext();

	useEffect(() => {
		fetchHasOpgenieAccount({
			baseUrl,
			cloudId,
			userId,
			isAnonymousUser: isAnonymous,
			analyticsEvent: createAnalyticsEvent({}),
			packageName: PACKAGE_NAME,
		});
	}, [fetchHasOpgenieAccount, baseUrl, cloudId, userId, isAnonymous, createAnalyticsEvent]);

	return [state.data.hasOpsgenieAccount, {}] as const;
};
