/**
 * @generated SignedSource<<45cb127e82f06cd36cf36818912ebeaf>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueClassificationBadge_IssueClassificationBadgeNew_dataClassificationFragment$data = {
  readonly classificationLevel: {
    readonly color: {
      readonly colorKey: string | null | undefined;
    } | null | undefined;
    readonly guidelines: string | null | undefined;
    readonly name: string | null | undefined;
  } | null | undefined;
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"editViewPopup_issueClassificationBadge_ClassificationEditViewPopup">;
  readonly " $fragmentType": "ui_issueClassificationBadge_IssueClassificationBadgeNew_dataClassificationFragment";
};
export type ui_issueClassificationBadge_IssueClassificationBadgeNew_dataClassificationFragment$key = {
  readonly " $data"?: ui_issueClassificationBadge_IssueClassificationBadgeNew_dataClassificationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueClassificationBadge_IssueClassificationBadgeNew_dataClassificationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueClassificationBadge_IssueClassificationBadgeNew_dataClassificationFragment",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "editViewPopup_issueClassificationBadge_ClassificationEditViewPopup"
    },
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    },
    {
      "concreteType": "JiraClassificationLevel",
      "kind": "LinkedField",
      "name": "classificationLevel",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "name"
        },
        {
          "kind": "ScalarField",
          "name": "guidelines"
        },
        {
          "concreteType": "JiraColor",
          "kind": "LinkedField",
          "name": "color",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "colorKey"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraDataClassificationField"
};

(node as any).hash = "397698557a4977790816aba1e30b128d";

export default node;
