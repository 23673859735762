import { defineMessages } from '@atlassian/jira-intl';
import UserInlineEditView from '@atlassian/jira-issue-internal-fields/src/user/view';
import { getIssueModalAkDropdownPortal } from '@atlassian/jira-issue-view-common-utils/src/get-element/index.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field';
import { issueKeySelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { isFieldRequiredSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector';
import { isFieldInTabSelector } from '@atlassian/jira-issue-view-store/src/selectors/tab-selector';
import { transformFromStateValue, transformToStateValue } from '../assignee/assignee-transformer';
import fetchSuggestionsFactory, {
	getEmptyOption,
	getEmptyOptionArray,
} from './fetch-suggestions-factory';

// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
const messages = defineMessages({
	noReporter: {
		id: 'issue.reporter.text-when-no-value-is-provided',
		defaultMessage: 'Anonymous',
		description: 'Represents an anonymous user on this Jira instance',
	},
});

export default connectField((stateOnMount, ownPropsOnMount) => ({
	fieldId: ownPropsOnMount.fieldId,
	transformFromStateValue,
	transformToStateValue,
	additionalProps: (state, intl) => {
		const issueKey = issueKeySelector(state);
		const isRequired = isFieldRequiredSelector(ownPropsOnMount.fieldId)(state);
		const anonymousUser = intl.formatMessage(messages.noReporter);

		return {
			portalElement: isFieldInTabSelector(ownPropsOnMount.fieldId)(state)
				? // Using a portal element here because otherwise dropdowns would get cut off
					// See: https://jdog.jira-dev.com/browse/BENTO-4101

					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					getIssueModalAkDropdownPortal() || document.body
				: undefined,
			fetchSuggestions: fetchSuggestionsFactory(
				`/rest/api/3/user/recommend?${new URLSearchParams({
					context: 'Reporter',
					...(issueKey && { issueKey }),
					query: '',
				})}`,
				anonymousUser,
				isRequired,
			),
			emptyOption: isRequired ? undefined : getEmptyOption(anonymousUser),
			initialOptions: isRequired ? undefined : getEmptyOptionArray(anonymousUser),
			fieldId: 'reporter',
			showPinButton: getShowPinButton(ownPropsOnMount.area),
		};
	},
}))(UserInlineEditView);
