import React, { type FC } from 'react';
import { Box, xcss, Text } from '@atlaskit/primitives';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { useIntl } from '@atlassian/jira-intl';
import {
	isAttachmentNumberExceedLimit,
	ATTACHMENTS_LEARN_ABOUT_LINK,
} from '@atlassian/jira-issue-attachments-limit/src/common/utils.tsx';
import messages from './messages';

type Props = {
	totalCount: number;
};

export const ExceedWarning: FC<Props> = ({ totalCount }) => {
	const { formatMessage } = useIntl();
	if (isAttachmentNumberExceedLimit(totalCount)) {
		return (
			<Box xcss={wrapperStyles}>
				<SectionMessage
					appearance="warning"
					actions={
						<SectionMessageAction href={ATTACHMENTS_LEARN_ABOUT_LINK}>
							{formatMessage(messages.exceedWarningLinkLabel)}
						</SectionMessageAction>
					}
				>
					<Text as="p">{formatMessage(messages.exceedWarningLabel)}</Text>
				</SectionMessage>
			</Box>
		);
	}
	return null;
};

export default ExceedWarning;

const wrapperStyles = xcss({
	marginTop: 'space.150',
	marginBottom: 'space.100',
});
