import { issueAttachmentsStore } from '@atlassian/jira-issue-attachments-store';
import type { State as AttachmentsState } from '@atlassian/jira-issue-attachments-store/src/types';
import type { State as IssueState } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { issueViewStore } from '@atlassian/jira-issue-view-react-sweet-state';
import { defaultRegistry } from '@atlassian/react-sweet-state';

// A type for mapping a legacy store to a Relay Live Resolver
// TODO: Create the type in DefinitelyTyped and import it from relay package
// during cleanup 'platform.jira-frontend.enable-relay-resolvers' flag
export type LiveState<T> = {
	/**
	 * Returns the current value of the live state.
	 */
	read(): T;
	/**
	 * Subscribes to changes in the live state. The state provider should
	 * call the callback when the value of the live state changes.
	 */
	subscribe(cb: () => void): () => void;
};
type Selector<T> = (state: IssueState) => T;
type SelectorAttachments<T> = (state: AttachmentsState) => T;

// A utility for mapping a Redux selector applied to Issue View Redux store to a Relay Live Resolver
// LiveState value.
export function selectIssueViewState<T>(selector: Selector<T>): LiveState<T> {
	const store = defaultRegistry.getStore(issueViewStore)?.storeState?.getState()?.store;

	if (!store) {
		throw new Error('Issue view store is not available');
	}

	let currentValue = selector(store.getState());
	return {
		read: () => currentValue,
		subscribe: (cb: () => void) =>
			store.subscribe(() => {
				const newValue = selector(store.getState());
				if (newValue === currentValue) {
					return;
				}
				currentValue = newValue;
				cb();
			}),
	};
}

// A utility for mapping a Redux selector applied to Attachements React Sweet State to a Relay Live Resolver
// LiveState value.
export function selectAttachmentsSweetState<T>(selector: SelectorAttachments<T>): LiveState<T> {
	const store = defaultRegistry.getStore(issueAttachmentsStore)?.storeState;
	if (!store) {
		throw new Error('Issue view attachments store is not available');
	}

	let currentValue = selector(store.getState());
	return {
		read: () => currentValue,
		subscribe: (cb: () => void) =>
			store.subscribe(() => {
				const newValue = selector(store.getState());
				if (newValue === currentValue) {
					return;
				}
				currentValue = newValue;
				cb();
			}),
	};
}
