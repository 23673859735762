import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { FETCH_WATCHERS_ERROR } from '@atlassian/jira-issue-view-common-constants/src/flags.tsx';
import type { OnShowFlag, State } from '../../model/types';
import { fetchWatchers } from '../../rest/fetch-watchers';
import {
	type Action,
	FETCH_WATCHERS_REQUEST,
	fetchWatchersSuccess,
	fetchWatchersFailure,
} from '../../state/actions';
import { getIssueKey, getBaseUrl } from '../../state/selectors';

export const createEpic =
	(onShowFlag: OnShowFlag) => (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
		action$.ofType(FETCH_WATCHERS_REQUEST).mergeMap(() => {
			const state = store.getState();

			const issueKey = getIssueKey(state);
			const baseUrl = getBaseUrl(state);

			return fetchWatchers({ issueKey, baseUrl })
				.map((users) => fetchWatchersSuccess({ users }))
				.catch((error: Error) => {
					onShowFlag(FETCH_WATCHERS_ERROR);
					log.safeErrorWithoutCustomerData('issue.watchers.fetch.watchers', error.message, error);
					return Observable.of(fetchWatchersFailure());
				});
		});
