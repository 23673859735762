import { useEffect, useRef, useCallback } from 'react';
import throttle from 'lodash/throttle';
import { useAnalyticsEvents, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	useIssueRefreshData,
	useIssueRefreshServiceActions,
} from '@atlassian/jira-issue-refresh-service/src/services/main.tsx';
import {
	Reason,
	type RealtimeRefreshEventDetails,
} from '@atlassian/jira-issue-refresh-service/src/types.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { DELAY_BETWEEN_EVENTS } from '../../constants';
import type { ChangeEvent } from '../../types';
import { transformEvents } from '../../utils/transform-events';

type OnEvent = (eventDetails: ChangeEvent, analyticsEvent: UIAnalyticsEvent) => void;
type Props = {
	onEvent: OnEvent;
};

type ThrottleInput = {
	onEvent: OnEvent;
	events: RealtimeRefreshEventDetails[];
	issueId?: IssueId;
};

export const RealtimeUpdates = (props: Props) => {
	const [_, { setEventAsReported }] = useIssueRefreshServiceActions();
	const { REALTIME: reportableRefreshEventData } = useIssueRefreshData();
	const currentIssueId = useIssueId();
	const throttleInput = useRef<ThrottleInput>({
		issueId: currentIssueId,
		onEvent: props.onEvent,
		events: [],
	});

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({});

	// We throttle the callback execution in order to not spam apps with events.
	// In its turn apps won't spam Jira with requests.
	// This throttling works in the way that the first event is reported ASAP
	// and the following ones are batched and sent with a predefined delay (5s at the moment of writing)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const throttledEventHandler = useCallback(
		throttle(() => {
			const { issueId, onEvent, events } = throttleInput.current;
			if (!issueId) return;
			const payload = transformEvents({ events, issueId });
			payload && onEvent(payload, analyticsEvent);
			// Reset events after they are reported
			events.length = 0;
		}, DELAY_BETWEEN_EVENTS),
		[],
	);

	useEffect(() => {
		if (
			!reportableRefreshEventData ||
			reportableRefreshEventData.reported ||
			reportableRefreshEventData.details.issueId !== currentIssueId
		) {
			return;
		}
		throttleInput.current.events.push(reportableRefreshEventData.details);
		throttledEventHandler();
		// There are situations when the component remounts e.g. on Backlog screen, while navigating between issues.
		// To avoid reporting the same event more than once, we persist the info about it inside the event.
		setEventAsReported(Reason.Realtime);
	}, [currentIssueId, reportableRefreshEventData, setEventAsReported, throttledEventHandler]);

	useEffect(
		() => () => {
			throttledEventHandler.cancel();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	return null;
};
