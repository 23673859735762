import { createSelector } from 'reselect';
import flatMap from 'lodash/flatMap';
import flatten from 'lodash/flatten';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import sortBy from 'lodash/sortBy';
import type { Commit, InstanceTypeDetail, Repository } from '../../../../model';
import type { State } from '../../../index';
import {
	getSelectedInstanceTypeDetails,
	getInstanceTypeDetailsByType,
	getAllInstanceTypeDetails,
} from '../index';

const transformToRepositoriesArray = (instanceTypeDetails: InstanceTypeDetail[]): Repository[] =>
	instanceTypeDetails
		? flatten(
				instanceTypeDetails.map((instanceTypeDetail) => flatten(instanceTypeDetail.repositories)),
			)
		: [];

/**
 * Returns all repositories for all instance types
 */
export const getAllRepositories = createSelector(
	[getAllInstanceTypeDetails],
	transformToRepositoriesArray,
);

/**
 * Get repositories of the selected instance type
 */
export const getRepositories = createSelector(
	[getSelectedInstanceTypeDetails],
	transformToRepositoriesArray,
);

export const getBitbucketRepos = createSelector([getInstanceTypeDetailsByType], (detailsByType) => {
	const bitbucketDetails = detailsByType.bitbucket;
	return bitbucketDetails ? transformToRepositoriesArray([bitbucketDetails]) : [];
});

export const getRepositoryIds = createSelector([getRepositories], (repositories) =>
	sortBy(repositories, 'name').map((repo) => repo.id),
);
export const getMultiInstanceRepositoryIds = createSelector([getAllRepositories], (repositories) =>
	sortBy(repositories, 'name').map((repo) => repo.id),
);

const hasCommitsInRepos = (repositories: Array<Repository>) =>
	flatMap(repositories, (repo) => repo.commits).length > 0;

/**
 * Selector which returns if selected instance type has commits
 */
export const hasCommits = createSelector([getRepositories], hasCommitsInRepos);
export const multiInstanceHasCommits = createSelector([getAllRepositories], hasCommitsInRepos);

export const hasBitbucketCommits = createSelector([getBitbucketRepos], hasCommitsInRepos);

export const hasEmptyDiffstatForBitbucket = createSelector(
	[getBitbucketRepos],
	(repositories) =>
		flatMap(repositories, (repo) => repo.commits)
			.map((commit) => commit.fileCount)
			.filter((fileCount) => !fileCount).length > 0,
);

/**
 * Get commits count across all Instance Types
 */
export const getAllCommitsCount = createSelector(
	[getAllRepositories],
	(repositories) => flatMap(repositories, (repo) => repo.commits).length,
);

export const hasEmptyDiffstatForAnyRepositories = createSelector(
	[getAllRepositories],
	(repositories) =>
		repositories &&
		repositories.length > 0 &&
		repositories.some((repository) => repository.commits.some((commit) => !commit.fileCount)),
);

const getRepositoriesById = createSelector([getRepositories], (repositories) =>
	keyBy(repositories, (repository) => repository.id),
);
const getMultiInstanceRepositoriesById = createSelector([getAllRepositories], (repositories) =>
	keyBy(repositories, (repository) => repository.id),
);

export const getRepositoryForId = (state: State, id: string): Repository =>
	getRepositoriesById(state)[id];

export const getMultiInstanceRepositoriesForId = (state: State, id: string): Repository =>
	getMultiInstanceRepositoriesById(state)[id];

export const hasDiffstat = (state: State, id: string): boolean =>
	getRepositoryForId(state, id).commits.some((commit) => commit.fileCount);

export const multiInstanceHasDiffstat = (state: State, id: string): boolean =>
	getMultiInstanceRepositoriesForId(state, id).commits.some((commit) => commit.fileCount);

const getCommitsByRepositoriesById = createSelector([getRepositoriesById], (repositories) =>
	mapValues(repositories, ({ commits }) => keyBy(commits, (commit) => commit.id)),
);

const getMultiInstanceCommitsByRepositoriesById = createSelector(
	[getMultiInstanceRepositoriesById],
	(repositories) => mapValues(repositories, ({ commits }) => keyBy(commits, (commit) => commit.id)),
);

export const getCommitForRepositoryIdAndCommitId = (
	state: State,
	repositoryId: string,
	id: string,
): Commit => {
	const repository = getCommitsByRepositoriesById(state)[repositoryId];
	return (repository || {})[id];
};

export const getMultiInstanceCommitForRepositoryIdAndCommitId = (
	state: State,
	repositoryId: string,
	id: string,
): Commit => {
	const repository = getMultiInstanceCommitsByRepositoriesById(state)[repositoryId];
	return (repository || {})[id];
};
