import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@compiled/react';
import { graphql, usePaginationFragment, type RefetchFnDynamic } from 'react-relay';
import { Box } from '@atlaskit/primitives';
import { CreatableSelect } from '@atlaskit/select';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import type {
	OptionToFilter,
	ActionMeta,
} from '@atlassian/jira-common-components-picker/src/model';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	FRAGMENT_SELECTABLE_FIELD_OPTIONS_FIRST,
	SEARCH_DEBOUNCE_TIMEOUT,
	SELECTABLE_FIELD_PAGE_OPTIONS,
} from '@atlassian/jira-issue-field-constants';
import { useSuspenselessRefetch } from '@atlassian/jira-issue-hooks/src/services/use-suspenseless-refetch';
import { defaultSelectStyles } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/styled.tsx';
import type {
	Option,
	Group,
} from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types';
import useDebouncedCallback from '@atlassian/jira-platform-use-debounce/src/utils/use-debounce-callback/index.tsx';
import selectableFieldSearchRefetchQuery, {
	type selectableFieldSearchRefetchQuery as SelectableFieldRefetchQuery,
} from '@atlassian/jira-relay/src/__generated__/selectableFieldSearchRefetchQuery.graphql';
import type { ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment$key as SelectableFieldFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment.graphql';
import UFOLabel from '@atlassian/react-ufo/label';
import UFOLoadHold from '@atlassian/react-ufo/load-hold';
import usePressTracing from '@atlassian/react-ufo/use-press-tracing';
import messages from './messages';
import type {
	SelectableFieldEditViewWithFieldOptionsFragmentProps,
	SelectableValueOption,
} from './types';
import { UFOCustomDataFragment } from './ufo-data-fragment';
import { getGroupedOptions, flattenOptions, flattenSelectedOptions } from './utils';

const EMPTY_USER_INPUT = '';

const LoadingIndicator = () => (
	<Box padding="space.100">
		<Spinner size="small" interactionName="loading" />
	</Box>
);

/**
 * MultiSelectableFieldEditViewWithFieldOptionsFragment is a version of the edit view that allows
 * the passing of a fragment for experiences like GIC that might want to group fetch data on mount
 * instead of having a separate network request for it
 *
 * @param props [SelectableFieldEditViewWithFieldOptionsFragmentProps](./types.tsx)
 */
export const SelectableFieldEditViewWithFieldOptionsFragment = ({
	autoFocus = false,
	cachedOptions,
	classNamePrefix,
	closeMenuOnSelect = false,
	fieldId,
	fieldOptionsFragmentRef,
	filterOptionsById = null,
	footer,
	formatGroupLabel,
	hasInfiniteScroll = true,
	validationState = 'default',
	isMulti = false,
	isClearable,
	isDisabled: isFieldDisabled = false,
	isInvalid = false,
	onChange,
	onFooterSelect,
	required = false,
	inputId,
	menuPosition,
	styles,
	value,
	loadingMessage,
	noOptionsMessage,
	loadOptions,
	optionsFirstCount = FRAGMENT_SELECTABLE_FIELD_OPTIONS_FIRST,
	optionsPageCount = SELECTABLE_FIELD_PAGE_OPTIONS,
	spacing = 'default',
	sortOptions,
	placeholder,
	recentOptionsLabel,
	allOptionsLabel,
	ariaLabel,
	ariaLabelledBy,
}: SelectableFieldEditViewWithFieldOptionsFragmentProps) => {
	const { formatMessage } = useIntl();

	const loadOptionsTracing = usePressTracing('selectable-field-edit-view.load-options');

	const getPlaceholderMessage = useCallback(
		() => placeholder ?? formatMessage(messages.placeholder),
		[formatMessage, placeholder],
	);

	const getRecentOptionsLabel = useCallback(
		() => recentOptionsLabel ?? formatMessage(messages.recent),
		[formatMessage, recentOptionsLabel],
	);

	const getAllOptionsLabel = useCallback(
		() => allOptionsLabel ?? formatMessage(messages.all),
		[formatMessage, allOptionsLabel],
	);

	const {
		data: fieldOptionsSearchData,
		refetch,
		loadNext,
		hasNext,
		isLoadingNext,
	} = usePaginationFragment<SelectableFieldRefetchQuery, SelectableFieldFragment>(
		graphql`
			fragment ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment on Query
			@refetchable(queryName: "selectableFieldSearchRefetchQuery")
			@argumentDefinitions(
				id: { type: "ID!" }
				searchBy: { type: "String", defaultValue: "" }
				first: { type: "Int", defaultValue: 20 }
				after: { type: "String", defaultValue: null }
				filterById: { type: "JiraFieldOptionIdsFilterInput" }
			) {
				node(id: $id) {
					...ufoDataFragment_issueSelectableFieldEditView_UFOCustomDataFragment
					... on JiraHasSelectableValueOptions {
						selectableValueOptions(
							searchBy: $searchBy
							first: $first
							after: $after
							filterById: $filterById
						)
							@connection(
								key: "selectableValue_issueFieldEditviewFull_fieldOptions__selectableValueOptions"
							) {
							edges {
								node {
									id
									selectableLabel
									selectableGroupKey
									selectableIconUrl
									... on JiraOption {
										isDisabled
										optionId
									}
									... on JiraVersion {
										versionId
									}
								}
							}
						}
					}
				}
			}
		`,
		fieldOptionsFragmentRef,
	);

	// #region Common state (might be handy for other components)
	/** Determines if options are being loaded externally; used by the `loadOptions` callback */
	const [isLoadingOptions, setIsLoadingOptions] = useState<boolean>(false);

	/** The Relay options transformed to the AK Select `options` prop */
	const [loadedOptions, setLoadedOptions] = useState<SelectableValueOption[]>([]);

	const [searchByString, setSearchByString] = useState<string>('');
	// #endregion

	// #region Refetching suggestions
	const refetchSuggestions: RefetchFnDynamic<SelectableFieldRefetchQuery, SelectableFieldFragment> =
		useCallback(
			(variables, options = {}) =>
				refetch(variables, {
					...options,
					onComplete: () => {
						setIsLoadingOptions(true);
					},
				}),
			[refetch],
		);
	// #endregion

	// #region Debounced suspensless refetch helpers
	const [searchSuspenselessRefetch, isFetching, lastFetchError] = useSuspenselessRefetch<
		SelectableFieldRefetchQuery,
		SelectableFieldFragment
	>(selectableFieldSearchRefetchQuery, refetchSuggestions);

	const searchSuspenselessRefetchWithTracing = useCallback(
		(...args: Parameters<typeof searchSuspenselessRefetch>) => {
			// Traces both `selectableFieldSearchRefetchQuery` and the `loadOptions` callback (if provided)
			loadOptionsTracing();

			return searchSuspenselessRefetch(...args);
		},
		[loadOptionsTracing, searchSuspenselessRefetch],
	);

	const [searchDebouncedSuspenselessRefetch] = useDebouncedCallback(
		searchSuspenselessRefetchWithTracing,
		SEARCH_DEBOUNCE_TIMEOUT,
	);

	const [searchLeadingEdgeSuspenselessRefetch] = useDebouncedCallback(
		searchSuspenselessRefetchWithTracing,
		SEARCH_DEBOUNCE_TIMEOUT,
		{ leading: true },
	);
	// #endregion

	// #region Common callbacks for the selector
	const onFocus = useCallback(() => {
		searchLeadingEdgeSuspenselessRefetch({
			id: fieldId,
			searchBy: searchByString,
			filterById: filterOptionsById,
			first: optionsFirstCount,
		});
	}, [
		fieldId,
		searchByString,
		searchLeadingEdgeSuspenselessRefetch,
		filterOptionsById,
		optionsFirstCount,
	]);

	const onSearchByStringChangeFunction = useCallback(
		(newSearchByString: string): void => {
			setSearchByString(newSearchByString);
			searchDebouncedSuspenselessRefetch({
				id: fieldId,
				searchBy: newSearchByString,
				filterById: filterOptionsById,
				first: optionsFirstCount,
			});
		},
		[fieldId, searchDebouncedSuspenselessRefetch, filterOptionsById, optionsFirstCount],
	);

	const handleFilterOption = useCallback(
		(option: OptionToFilter, query = ''): boolean =>
			option.data?.__isNew__ || option.label.toLowerCase().includes(query.toLowerCase()),
		[],
	);
	// #endregion

	const defaultFailedOption = useMemo(
		() => ({
			label: formatMessage(messages.failedFetch),
			content: formatMessage(messages.failedFetch),
			value: '',
			isDisabled: true,
		}),
		[formatMessage],
	);

	const selectableValueOptions = useMemo(() => {
		const { edges } = fieldOptionsSearchData.node?.selectableValueOptions || {};

		return (
			edges
				?.map((edge) => edge?.node ?? null)
				.filter(Boolean)
				.filter((option) => option && option.selectableLabel != null) || []
		);
	}, [fieldOptionsSearchData.node?.selectableValueOptions]);

	const options = useMemo(() => {
		if (lastFetchError != null) {
			return [defaultFailedOption];
		}

		const sortedOptions = sortOptions != null ? sortOptions(loadedOptions) : loadedOptions;

		const groupedOptions = getGroupedOptions(
			sortedOptions,
			cachedOptions,
			getAllOptionsLabel(),
			getRecentOptionsLabel(),
			formatMessage(messages.other),
		);

		return groupedOptions;
	}, [
		loadedOptions,
		lastFetchError,
		defaultFailedOption,
		cachedOptions,
		formatMessage,
		getAllOptionsLabel,
		getRecentOptionsLabel,
		sortOptions,
	]);
	// #endregion

	const selectedValue: ReadonlyArray<Option> | null = useMemo(() => {
		if (!value) {
			return null;
		}

		const optionsArray = Array.isArray(value) ? value : [value];

		return optionsArray.map(
			(option): Option => ({
				ari: option.id,
				id: option.versionId || option.optionId,
				label: option.selectableLabel || '',
				value: option.selectableLabel || '',
				isDisabled: option.isDisabled || undefined,
				groupKey: option.selectableGroupKey,
				content: '',
				iconUrl: option.selectableIconUrl,
			}),
		);
	}, [value]);

	const getSelectableValueOption = useCallback(
		(selectedOption: Option): SelectableValueOption | undefined => {
			if (!selectedOption.ari) {
				return undefined;
			}

			const fieldOptionId =
				typeof selectedOption.id === 'number' ? String(selectedOption.id) : selectedOption.id;

			return {
				id: selectedOption.ari,
				selectableLabel: selectedOption.label,
				selectableGroupKey: selectedOption.groupKey,
				selectableIconUrl: selectedOption.iconUrl,
				isDisabled: selectedOption.isDisabled ?? false,
				optionId: fieldOptionId,
				versionId: fieldOptionId,
			};
		},
		[],
	);

	const handleOnChangeNew = (
		selectedOption: Group | Option | readonly (Group | Option)[] | null | undefined,
		actionMeta: ActionMeta<Option>,
	): void => {
		if (onChange) {
			if (!selectedOption) {
				onChange(null, { action: actionMeta.action });
				return;
			}

			const option: Option[] = Array.isArray(selectedOption) ? selectedOption : [selectedOption];

			const selectedOptions = option.map((opt) => getSelectableValueOption(opt)).filter(Boolean);

			onChange(selectedOptions, {
				action: actionMeta.action,
				option:
					actionMeta.option && 'value' in actionMeta.option
						? getSelectableValueOption(actionMeta.option)
						: undefined,
				removedValue:
					actionMeta.removedValue && 'value' in actionMeta.removedValue
						? getSelectableValueOption(actionMeta.removedValue)
						: undefined,
			});
		}
	};

	const getLoadingMessageNew: (obj: { inputValue: string }) => React.ReactNode = useMemo(() => {
		if (loadingMessage) {
			return ({ inputValue }: { inputValue: string }) => loadingMessage(inputValue);
		}

		return () => formatMessage(messages.loading);
	}, [loadingMessage, formatMessage]);

	const getNoOptionsMessageNew: (obj: { inputValue: string }) => React.ReactNode = useMemo(() => {
		if (noOptionsMessage) {
			return ({ inputValue }: { inputValue: string }) => noOptionsMessage(inputValue);
		}

		return () => formatMessage(messages.empty);
	}, [noOptionsMessage, formatMessage]);

	const selectValidationState = useMemo(() => {
		if (isInvalid) {
			return 'error';
		}

		return validationState;
	}, [isInvalid, validationState]);

	const setOrLoadOptions = useCallback(async () => {
		if (loadOptions == null) {
			setLoadedOptions(selectableValueOptions);
		} else if (isLoadingOptions) {
			/**
			 * Call only when `refetch` completes, via the `isLoadingOptions` prop.
			 *
			 * This prevents `loadOptions` from getting called twice on initial render
			 * if the fetch policy includes getting cached data.
			 */
			const result = await loadOptions(selectableValueOptions);
			setLoadedOptions(result);
		}

		setIsLoadingOptions(false);
	}, [isLoadingOptions, loadOptions, selectableValueOptions]);

	useEffect(() => {
		if (!isFetching) {
			setOrLoadOptions();
		}
	}, [isFetching, setOrLoadOptions]);

	const shouldShowFooter = !!footer;

	const noOptionLabel = getNoOptionsMessageNew({ inputValue: EMPTY_USER_INPUT });

	const loadingLabel = getLoadingMessageNew({ inputValue: EMPTY_USER_INPUT });

	const isOptionShownInDropdown = (option: Option, inputValue: string) =>
		// will not be filtered out by ak/select based on user input (even if there is no refetch)
		handleFilterOption(option, inputValue) &&
		// not a manually added noOptionsMessageOption
		option.label !== noOptionLabel &&
		option.label !== loadingLabel &&
		// not in selected values
		!flattenSelectedOptions(selectedValue).find(
			(selectedOption) => selectedOption.value === option.value,
		);

	const filterOptionWithFooterAndShowNoOptionsMessage = (
		option: OptionToFilter,
		inputValue: string,
	) => {
		const isLoading = isFetching || isLoadingOptions;
		if (option.label === noOptionLabel) {
			return (
				isLoading !== true &&
				!flattenOptions(options).some((opt) => isOptionShownInDropdown(opt, inputValue))
			);
		}
		if (option.label === loadingLabel) {
			return (
				isLoading === true &&
				!flattenOptions(options).some((opt) => isOptionShownInDropdown(opt, inputValue))
			);
		}

		return handleFilterOption(option, inputValue); // apply default filtering function
	};

	const selectWithFooterOptions =
		shouldShowFooter === true
			? [
					...options,
					...(noOptionsMessage
						? [
								{
									label: noOptionsMessage(EMPTY_USER_INPUT) || '',
									isDisabled: true,
									value: noOptionsMessage(EMPTY_USER_INPUT) || '',
									content: noOptionsMessage(EMPTY_USER_INPUT) || '',
								},
							]
						: []),
					...(loadingMessage
						? [
								{
									label: loadingMessage(EMPTY_USER_INPUT) || '',
									isDisabled: true,
									value: loadingMessage(EMPTY_USER_INPUT) || '',
									content: '',
								},
							]
						: []),
				]
			: options;

	const onMenuScrollToBottom = () => {
		if (hasNext) {
			loadNext(optionsPageCount);
		}
	};

	const formatCreateLabel = (inputValue: string) => {
		if (footer === undefined) {
			return inputValue;
		}

		if (typeof footer === 'function') {
			return footer(inputValue);
		}

		return footer;
	};

	const selectAriaLabel = ariaLabel || formatMessage(messages.selectLabel);
	return (
		<UFOLabel name="selectable-field-edit-view">
			<CreatableSelect
				aria-label={selectAriaLabel}
				autoFocus={autoFocus}
				classNamePrefix={classNamePrefix}
				components={{ LoadingIndicator }}
				filterOption={
					shouldShowFooter === true
						? filterOptionWithFooterAndShowNoOptionsMessage
						: handleFilterOption
				}
				footer={footer}
				{...(formatGroupLabel ? { formatGroupLabel } : {})}
				{...(SelectableOptions ? { formatOptionLabel: SelectableOptions } : {})}
				isDisabled={isFieldDisabled}
				isLoading={isFetching || isLoadingOptions || isLoadingNext}
				isMulti={isMulti}
				isClearable={isClearable}
				isRequired={required}
				loadingMessage={getLoadingMessageNew}
				noOptionsMessage={getNoOptionsMessageNew}
				onChange={handleOnChangeNew}
				onFocus={onFocus}
				onCreateOption={onFooterSelect}
				onFooterSelect={onFooterSelect}
				onInputChange={onSearchByStringChangeFunction}
				options={selectWithFooterOptions}
				placeholder={getPlaceholderMessage()}
				{...(hasInfiniteScroll && {
					onMenuScrollToBottom,
				})}
				shouldShowFooter={!!footer}
				styles={styles ?? defaultSelectStyles}
				validationState={selectValidationState}
				value={selectedValue}
				openMenuOnFocus
				closeMenuOnSelect={closeMenuOnSelect}
				spacing={spacing}
				inputId={inputId}
				menuPosition={menuPosition}
				formatCreateLabel={formatCreateLabel}
				isValidNewOption={() => !!footer}
				hideSelectedOptions
				allowCreateWhileLoading
				aria-labelledby={ariaLabelledBy}
			/>
			{fieldOptionsSearchData.node && (
				<UFOCustomDataFragment
					hasSearchByString={searchByString.length > 0}
					baseIssueFieldFragmentRef={fieldOptionsSearchData.node}
				/>
			)}

			{loadOptions != null && <UFOLoadHold name="load-options-callback" hold={isLoadingOptions} />}
		</UFOLabel>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LabelTextWrapper = styled.span({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

export const SelectableOptions = (option: Option) =>
	option.iconUrl ? (
		<OptionWrapper>
			<ImgSpan>
				<img
					src={option.iconUrl || ''}
					alt={option.label}
					width={2 * gridSize}
					height={2 * gridSize}
					role="none"
				/>
			</ImgSpan>
			<LabelTextWrapper>{option.label}</LabelTextWrapper>
		</OptionWrapper>
	) : (
		<>{option.label}</>
	);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const OptionWrapper = styled.span({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const ImgSpan = styled.span({
	display: 'flex',
	paddingRight: token('space.100', '8px'),
});
