import React from 'react';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { IssueViewRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { useIssueFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { useIsIssueOfPostIncidentReviewPractice } from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import {
	type CustomItemType,
	type LayoutContainerTemplateItem,
	CUSTOM_ITEM_TYPE,
} from '@atlassian/jira-issue-layout-common-constants';
import { ContentSectionView } from '@atlassian/jira-issue-view-layout-templates-views/src/ui/content/index.tsx';
import { useLayoutContainerByType } from '@atlassian/jira-issue-view-layout/src/services/main.tsx';
import { PROFORMA_FORMS, SERVICEDESK_PRACTICES_TYPE } from '@atlassian/jira-platform-field-config';
import RiskInsightsPanel from '@atlassian/jira-risk-insights-panel/src/async.tsx';
import { JsdGettingStartedPanelShouldRender } from '@atlassian/jira-servicedesk-getting-started-panel/src/async';
import { AsyncPrimaryIncident } from '@atlassian/jira-servicedesk-post-incident-review/src/ui/primary-incident/async.tsx';
import RequestDetailsPanel from '@atlassian/jira-servicedesk-request-details-panel/src/index.tsx';
import { CHANGES } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { isProformaHarmonisationFFEnabled } from '../../feature-flags';
import { addRefsToArrayOfItems, getContentItemsWithLooseItemsInFirstTab } from './utils';

export const JSM_REQUEST_PANEL_ITEM_ID = 'JSM_REQUEST_PANEL' as const;
export const JSM_RISK_IMPROVEMENT_ITEM_ID = 'JSM_RISK_IMPROVEMENT_ITEM_ID';
export const JSM_PRIMARY_INCIDENT_ITEM_ID = 'JSM_PRIMARY_INCIDENT_ITEM_ID' as const;

export const shouldRenderRiskInsightPanel = (itsmPractices?: unknown[]): boolean =>
	itsmPractices?.[0] === CHANGES;

const ContentTemplate = ({
	issueViewRelayFragment,
}: {
	// TODO Decomp BENTO-12514 - add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment?: IssueViewRelayFragment | null;
}) => {
	const issueKey = useIssueKey();
	const [contentItems] = useLayoutContainerByType(issueKey, 'CONTENT');
	const [requestPortalItems] = useLayoutContainerByType(issueKey, 'REQUEST_PORTAL');

	const [{ value: issueFieldsConfig }] = useIssueFieldConfig(issueKey);

	const requestPanelItems = addRefsToArrayOfItems(requestPortalItems, issueFieldsConfig);

	const requestPanel: CustomItemType[] =
		isProformaHarmonisationFFEnabled() &&
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useFieldValue({ issueKey, fieldKey: PROFORMA_FORMS })[0]?.isHarmonisationEnabled
			? []
			: [
					{
						id: JSM_REQUEST_PANEL_ITEM_ID,
						type: CUSTOM_ITEM_TYPE,
						item: (
							<RequestDetailsPanel
								issueViewRelayFragment={issueViewRelayFragment}
								requestPanelItems={requestPanelItems}
							/>
						),
					},
				];

	const [itsmPractices] = useFieldValue({ issueKey, fieldKey: SERVICEDESK_PRACTICES_TYPE });

	const riskImprovementPanel: CustomItemType[] = shouldRenderRiskInsightPanel(itsmPractices)
		? [
				{
					id: JSM_RISK_IMPROVEMENT_ITEM_ID,
					type: CUSTOM_ITEM_TYPE,
					item: <RiskInsightsPanel issueKey={issueKey} />,
				},
			]
		: [];

	const primaryIncidentPanel: CustomItemType[] = useIsIssueOfPostIncidentReviewPractice()
		? [
				{
					id: JSM_PRIMARY_INCIDENT_ITEM_ID,
					type: CUSTOM_ITEM_TYPE,
					item: <AsyncPrimaryIncident />,
				},
			]
		: [];

	/**
	 * For custom field types we wish to inject them into the first tab so that they are rendered correctly
	 * given that the tab view expects only tabs to be appearing in the content section.
	 */
	const items: LayoutContainerTemplateItem[] = [
		...requestPanel,
		...riskImprovementPanel,
		...[
			...getContentItemsWithLooseItemsInFirstTab(
				contentItems,
				requestPanel,
				riskImprovementPanel,
				primaryIncidentPanel,
			),
		],
		...primaryIncidentPanel,
	];

	return (
		<>
			<ContentSectionView items={items} issueViewRelayFragment={issueViewRelayFragment} />
			<JsdGettingStartedPanelShouldRender />
		</>
	);
};

export default ContentTemplate;
