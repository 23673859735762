import React from 'react';
import { styled } from '@compiled/react';
import { useFragment, graphql } from 'react-relay';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import type { url_issueFieldUrlReadviewFull_UrlReadView$key as UrlFragment } from '@atlassian/jira-relay/src/__generated__/url_issueFieldUrlReadviewFull_UrlReadView.graphql';
import type { UrlReadViewProps } from './types';
import { formatReadValue } from './utils';

export const UrlReadView = ({ fragmentRef }: UrlReadViewProps) => {
	const { uri: value } = useFragment<UrlFragment>(
		graphql`
			fragment url_issueFieldUrlReadviewFull_UrlReadView on JiraUrlField {
				uri
			}
		`,
		fragmentRef,
	);

	if (value == null || value === '') {
		return <EmptyFieldText />;
	}
	const lDisplayValue = formatReadValue(value);

	return <Ellipsis title={value}>{lDisplayValue}</Ellipsis>;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Ellipsis = styled.div({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
