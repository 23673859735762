/**
 * @generated SignedSource<<c5c31b4a5ee4493a1a251de03de2577e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField$data = {
  readonly __typename: "JiraTimeTrackingField";
  readonly fieldId: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditView" | "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading">;
  readonly " $fragmentType": "ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField";
};
export type ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField$key = {
  readonly " $data"?: ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading"
    },
    {
      "kind": "FragmentSpread",
      "name": "originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditView"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    }
  ],
  "type": "JiraTimeTrackingField"
};

(node as any).hash = "0b9042e2298eb154f454f81f623debf4";

export default node;
