import React, { memo, useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueAttachments } from '@atlassian/jira-issue-attachments-base/src/services/attachments-service/main.tsx';
import { isAttachmentNumberExceedLimit } from '@atlassian/jira-issue-attachments-limit/src/common/utils.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { ATTACHMENTS_VIEW } from '@atlassian/jira-issue-shared-types/src/common/types/user-preferences-type.tsx';
import { useUserPreferencesValue } from '@atlassian/jira-issue-user-preference-services/src/main.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { LIST_VIEW, STRIP_VIEW } from '../../../constants';
import messages from './messages';
import type { Props } from './types';

export const SwitchViewItem = memo<Props>(({ refreshAttachments }: Props) => {
	const issueKey = useIssueKey();
	const { formatMessage } = useIntl();
	const [value, { setValue }] = useUserPreferencesValue(ATTACHMENTS_VIEW);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const switchToNewView = useCallback(
		(view: string) => () => {
			setValue(view);
			refreshAttachments(issueKey);
			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'dropdownItem',
			});
			fireUIAnalytics(analyticsEvent, 'switchAttachmentsView', { view });
		},
		[createAnalyticsEvent, issueKey, refreshAttachments, setValue],
	);

	const [
		{
			value: { totalCount },
		},
	] = useIssueAttachments(issueKey);

	/**
	 * As per JIV-14783 we wish to hide the switch to strip view button
	 * When attachments exceed the limit
	 */
	if (isAttachmentNumberExceedLimit(totalCount)) {
		return null;
	}

	switch (value) {
		case LIST_VIEW: {
			return (
				<DropdownItem onClick={switchToNewView(STRIP_VIEW)}>
					{formatMessage(messages.switchToStripView)}
				</DropdownItem>
			);
		}
		case STRIP_VIEW:
		default:
			return (
				<DropdownItem onClick={switchToNewView(LIST_VIEW)}>
					{formatMessage(messages.switchToListView)}
				</DropdownItem>
			);
	}
});

export default SwitchViewItem;
