import get from 'lodash/get';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import type { LabelsValue } from '@atlassian/jira-issue-shared-types/src/common/types/labels-type.tsx';
import { LabelsKey } from '@atlassian/jira-providers-issue/src/model/issue-system-fields.tsx';
import type { Props, UseLabelsField } from './types';
import { useSaveLabelsField } from './use-save-labels-field';

export const useLabelsField: UseLabelsField = ({
	// `fieldKey` can be `labels` or `customfield_`
	fieldKey,
	issueId,
	issueKey,
	fieldType,
	onSuccess,
	onFailure,
	initialValue,
}: Props) => {
	const saveLabelsFieldField = useSaveLabelsField(issueId);
	const saveField = saveLabelsFieldField;
	const [{ value: fieldConfig }] = useFieldConfig(issueKey, fieldKey);
	const labelsFieldType = fieldType || get(fieldConfig, 'schema.type', 'array');
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	const [{ error, value }, { resetError, saveValue }] = useEditField<LabelsValue, null, void>({
		fieldType: labelsFieldType,
		issueId,
		issueKey,
		onSuccess,
		onFailure,
		initialValue: initialValue || null,
		fieldKey: fieldKey || LabelsKey,
		saveField,
	});

	return [
		{ error, value, fieldConfig },
		{ resetError, saveValue },
	];
};
