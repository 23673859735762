// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	timeRemaining: {
		id: 'issue.log-time.modal.time-remaining',
		defaultMessage: 'Time remaining',
		description: 'Label for the time remaining field',
	},
	timeRemainingInfo: {
		id: 'issue.log-time.modal.time-remaining.time-remaining-info',
		defaultMessage: "If time remaining isn't what you expect, manually update it here.",
		description: 'Info popup for time remaining',
	},
	timeSpent: {
		id: 'issue.log-time.modal.time-spent',
		defaultMessage: 'Time spent',
		description: 'Label for the time spent field',
	},
	dateStarted: {
		id: 'issue.log-time.modal.date-started',
		defaultMessage: 'Date started',
		description: 'Label for the date started field',
	},
	timeInvalidMessage: {
		id: 'issue.common.messages.time.time-invalid-message',
		defaultMessage: 'Check your entry matches the time format',
		description: 'The text shown when a user enters time that is in an invalid format',
	},
	timeRemainingTriggerBtnLabel: {
		id: 'issue-view-common.component.log-time-modal.modal.time-log-text-field.time-remainin-popup-label',
		defaultMessage: 'More information about Time remaining',
		description: 'Label for the timelog popup button',
	},
});
