import { TRACK_EVENT_TYPE } from '@atlassian/jira-product-analytics-bridge';
import type { State } from '../../state';
import type { Action } from '../../state/actions/app';
import {
	FIRE_DETAIL_DATA_FETCHED_EVENT,
	FIRE_BITBUCKET_DIFFSTAT_ANALYTICS_EVENT,
} from '../../state/actions/details';
import { getDetailsReceivedEventAttributes } from '../../state/selectors/details/analytics';
import { hasEmptyDiffstatForBitbucket } from '../../state/selectors/details/commits';
import { getActiveTab } from '../../state/selectors/ui';

export type AnalyticsActionsMap = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: (arg1: State, arg2: Action) => any;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default {
	[FIRE_DETAIL_DATA_FETCHED_EVENT]: (state) => ({
		payload: {
			action: 'fetched',
			actionSubject: 'devDetails',
			attributes: getDetailsReceivedEventAttributes(state),
			source: getActiveTab(state),
		},
		type: TRACK_EVENT_TYPE,
	}),
	[FIRE_BITBUCKET_DIFFSTAT_ANALYTICS_EVENT]: (state) => ({
		payload: {
			action: hasEmptyDiffstatForBitbucket(state) ? 'partiallyFetched' : 'fullyFetched',
			actionSubject: 'bitbucketDiffstat',
			source: getActiveTab(state),
		},
		type: TRACK_EVENT_TYPE,
	}),
} as AnalyticsActionsMap;
