import type { Dispatch } from 'redux';
import memoizeOne from 'memoize-one';
import sprintsCache from '@atlassian/jira-cache/src/services/sprints/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log';
import { functionWithFF } from '@atlassian/jira-feature-flagging-utils';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import SprintInlineEditView from '@atlassian/jira-issue-internal-fields/src/sprint/view';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field';
import { getIssueApiUrl } from '@atlassian/jira-issue-view-services/src/issue/issue-urls';
import { navigateToSprint } from '@atlassian/jira-issue-view-store/src/actions/issue-navigation-actions';
import {
	baseUrlSelector,
	projectKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import {
	canChangeSprintSelector,
	closedSprintsSelector,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/sprint-selector';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';
import {
	transformFromStateValue,
	transformToStateValue,
	transformSuggestionsFromServerAndCache,
	transformedClosedSprints,
	getClosedSprintsLabel,
} from './transformer';
import { findActiveSprint } from './utils';

export const fetchSuggestionsFactory = memoizeOne(
	(intl: IntlShape, baseUrl: string, projectKey: string) =>
		(query: string, restrictSprintSuggestion: boolean) => {
			const queryParams = new URLSearchParams({
				query,
				maxResults: '10',
				maxActiveSprints: '5',
			});

			let getCachedSprints = sprintsCache.getAll();

			if (restrictSprintSuggestion && projectKey) {
				queryParams.append('projectKey', projectKey);
				getCachedSprints = getCachedSprints.then((sprints) =>
					sprints.filter((sprint) => sprint.projectKeys?.includes(projectKey)),
				);
			}

			const url = `${baseUrl}/rest/greenhopper/1.0/sprint/picker?${queryParams.toString()}`;

			const dataFromServer = fetchJson(url);

			return Promise.all([dataFromServer, getCachedSprints]).then(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				([dataFromAPI, cache]: [any, any]) =>
					transformSuggestionsFromServerAndCache(dataFromAPI, cache, intl),
			);
		},
);

// @ts-expect-error - TS7006 - Parameter 'baseUrl' implicitly has an 'any' type. | TS7006 - Parameter 'intl' implicitly has an 'any' type.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const closedSprintFactory = (stateClosedSprints: any, baseUrl, intl) =>
	transformedClosedSprints(stateClosedSprints, baseUrl, intl);
const memoizedClosedSprintFactory = memoizeOne(closedSprintFactory);

// @ts-expect-error - TS7031 - Binding element 'baseUrl' implicitly has an 'any' type. | TS7031 - Binding element 'issueKey' implicitly has an 'any' type. | TS7031 - Binding element 'fieldMetaKey' implicitly has an 'any' type. | TS7031 - Binding element 'value' implicitly has an 'any' type. | TS2304 - Cannot find name 'State'. | TS7006 - Parameter 'ownPropsOnMount' implicitly has an 'any' type.
const saveField = ({ baseUrl, issueKey, fieldMetaKey, value }, _: State, ownPropsOnMount) => {
	const activeSprint = findActiveSprint(value);

	// @ts-expect-error - TS7006 - Parameter 'current' implicitly has an 'any' type.
	value.forEach((current) => {
		try {
			if (ownPropsOnMount.createAnalyticsEvent) {
				fireOperationalAnalytics(
					ownPropsOnMount.createAnalyticsEvent({}),
					'sprintPickerCache set',
					{
						isFromCache: current.fromCache,
					},
				);
			}
			current.id && sprintsCache.set(`${current.id}`, current);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			log.safeErrorWithoutCustomerData('issue-view.sprints.cache.set', error.message);
		}
	});

	return performPutRequest(getIssueApiUrl(baseUrl, issueKey), {
		body: JSON.stringify({
			fields: {
				[fieldMetaKey]: activeSprint ? activeSprint.id : null,
			},
		}),
	}).catch((error) => {
		// @ts-expect-error - TS7006 - Parameter 'current' implicitly has an 'any' type.
		value.forEach((current) => {
			try {
				if (ownPropsOnMount.createAnalyticsEvent) {
					fireOperationalAnalytics(
						ownPropsOnMount.createAnalyticsEvent({}),
						'sprintPickerCache removed',
					);
				}
				current.id && sprintsCache.remove(`${current.id}`);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				log.safeErrorWithoutCustomerData('issue-view.sprints.cache.remove', e.message);
			}
		});

		throw error;
	});
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getDataFromCache = (intl: any) =>
	sprintsCache
		.getAll()
		.then((sprints) => transformSuggestionsFromServerAndCache({}, sprints, intl));

const onSprintClickedFactory = memoizeOne(
	// @ts-expect-error - TS7006 - Parameter 'sprintId' implicitly has an 'any' type.
	(dispatch: Dispatch) => (sprintId) => dispatch(navigateToSprint(sprintId)),
);

const connectFieldOld = connectField((stateOnMount, ownPropsOnMount) => {
	const stateClosedSprints = closedSprintsSelector(ownPropsOnMount.fieldId)(stateOnMount);

	return {
		fieldId: ownPropsOnMount.fieldId,
		transformFromStateValue,
		transformToStateValue: transformToStateValue(stateClosedSprints),
		additionalProps: (state, intl) => ({
			fetchSuggestions: fetchSuggestionsFactory(
				intl,
				baseUrlSelector(state),
				projectKeySelector(state),
			),
			closedSprints: memoizedClosedSprintFactory(stateClosedSprints, baseUrlSelector(state), intl),
			closedSprintsLabel: getClosedSprintsLabel(stateClosedSprints, intl),
			placeholder: intl.formatMessage(messages.placeholder),
			noValueText: intl.formatMessage(genericMessages.noValue),
			allowEmptyValue: true,
			baseUrl: baseUrlSelector(state),
			isEditable: canChangeSprintSelector(ownPropsOnMount.fieldId)(state),
			showPinButton: getShowPinButton(ownPropsOnMount.area),
			getDataFromCache,
			projectKey: projectKeySelector(state),
		}),
		additionalCallbacks: (dispatch) => ({
			onSprintClicked: onSprintClickedFactory(dispatch),
		}),
		saveField: (...args) => saveField(...args, ownPropsOnMount),
	};
});

const connectFieldNew = connectField((stateOnMount, ownPropsOnMount) => {
	const getStateClosedSprints = closedSprintsSelector(ownPropsOnMount.fieldId);

	return {
		fieldId: ownPropsOnMount.fieldId,
		transformFromStateValue,
		transformToStateValue: transformToStateValue(getStateClosedSprints(stateOnMount)),
		additionalProps: (state, intl) => {
			const stateClosedSprints = getStateClosedSprints(state);

			return {
				fetchSuggestions: fetchSuggestionsFactory(
					intl,
					baseUrlSelector(state),
					projectKeySelector(state),
				),
				closedSprints: memoizedClosedSprintFactory(
					stateClosedSprints,
					baseUrlSelector(state),
					intl,
				),
				closedSprintsLabel: getClosedSprintsLabel(stateClosedSprints, intl),
				placeholder: intl.formatMessage(messages.placeholder),
				noValueText: intl.formatMessage(genericMessages.noValue),
				allowEmptyValue: true,
				baseUrl: baseUrlSelector(state),
				isEditable: canChangeSprintSelector(ownPropsOnMount.fieldId)(state),
				showPinButton: getShowPinButton(ownPropsOnMount.area),
				getDataFromCache,
				projectKey: projectKeySelector(state),
			};
		},
		additionalCallbacks: (dispatch) => ({
			onSprintClicked: onSprintClickedFactory(dispatch),
		}),
		saveField: (...args) => saveField(...args, ownPropsOnMount),
	};
});

const ConnectedSprintField = functionWithFF(
	'issue.details.fix-incorrect-state-closed-sprint-field_mpzbh',
	connectFieldNew,
	connectFieldOld,
)(SprintInlineEditView);

export default ConnectedSprintField;
