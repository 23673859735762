import { useCallback, useRef } from 'react';
import { useHelpPanelLink } from '@atlassian/jira-help-panel-button';
import { useIsModal, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	HREF,
	IN_PRODUCT_HELP,
	IN_PRODUCT_HELP_ARTICLE_IDS,
	WHAT_ARE_ISSUE_TYPES_URL,
} from '@atlassian/jira-issue-view-common-constants/src/child-issues.tsx';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import type { UseChildIssuesLimitHelpResult } from './types';

// @ts-expect-error - TS2322 - Type '() => { type: "HREF"; href: "https://support.atlassian.com/jira-cloud-administration/docs/what-are-issue-types/"; articleId?: undefined; openInProductHelp?: undefined; } | { type: "IN_PRODUCT_HELP"; articleId: string | null; href: "https://support.atlassian.com/jira-cloud-administration/docs/what-are-issue-types/"; ...' is not assignable to type '() => UseChildIssuesLimitHelpResult'.
export const useChildIssuesLimitHelp: () => UseChildIssuesLimitHelpResult = () => {
	// What's going on here?
	// The `useHelpPanelLink` returns a function that is not currently wrapped in
	// a `useCallback` this causes unnecessary re-renders.
	// I have notified the team that owns it and have put this `useRef` workaround
	// in place for the time being.
	const openInProductHelp = useRef(useHelpPanelLink());

	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);

	const isModal = useIsModal();

	const openInProductHelpCb = useCallback(() => {
		if (projectType !== undefined) {
			openInProductHelp.current?.(IN_PRODUCT_HELP_ARTICLE_IDS[projectType] || '');
		}
	}, [openInProductHelp, projectType]);

	const isInProductHelp = Boolean(
		openInProductHelp.current &&
			projectType !== undefined &&
			IN_PRODUCT_HELP_ARTICLE_IDS[projectType] &&
			!isModal,
	);

	if (!isInProductHelp) {
		return {
			type: HREF,
			href: WHAT_ARE_ISSUE_TYPES_URL,
		};
	}

	const articleId =
		projectType !== undefined && IN_PRODUCT_HELP_ARTICLE_IDS[projectType]
			? IN_PRODUCT_HELP_ARTICLE_IDS[projectType]
			: '';

	return {
		type: IN_PRODUCT_HELP,
		articleId,
		href: WHAT_ARE_ISSUE_TYPES_URL,
		openInProductHelp: openInProductHelpCb,
	};
};
