import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireTrackAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-track-event.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { isHttpClientErrorResponse } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import {
	TOGGLE_WATCHING_ERROR,
	START_WATCHING_SUCCESS,
	STOP_WATCHING_SUCCESS,
} from '@atlassian/jira-issue-view-common-constants/src/flags.tsx';
import type { BaseUrl, IssueKey, AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { OnShowFlag, State } from '../../model/types';
import { watch, unwatch } from '../../rest/update-user-watching-state';
import {
	type Action,
	type ToggleWatchingPayload,
	type ToggleWatchingRequestAction,
	TOGGLE_WATCHING_REQUEST,
	startWatchingSuccess,
	startWatchingFailure,
	stopWatchingSuccess,
	stopWatchingFailure,
} from '../../state/actions';
import {
	getBaseUrl,
	getLoggedInUserDetails,
	getIssueKey,
	getIsWatching,
} from '../../state/selectors';
import { SHORTCUT_SOURCE } from '../../state/types';

const fireAnalytics = (event: UIAnalyticsEvent | undefined, action: string, source: string) => {
	// @ts-expect-error Argument of type 'UIAnalyticsEvent | undefined' is not assignable to parameter of type 'UIAnalyticsEvent'.
	fireTrackAnalytics(event, {
		actionSubjectId: 'watchersToggleWatching',
		action,
		attributes: {
			source,
		},
	});
};

const startWatching = (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	accountId: null | AccountId,
	{ analyticsEvent, source, onSuccess, onError }: ToggleWatchingPayload,
	onShowFlag: OnShowFlag,
) =>
	watch({
		baseUrl,
		issueKey,
		accountId,
	})
		.map(() => {
			fireAnalytics(analyticsEvent, 'startWatching', source);
			onSuccess?.(source, 'startWatching');
			if (source === SHORTCUT_SOURCE) {
				onShowFlag(START_WATCHING_SUCCESS);
			}
			return startWatchingSuccess();
		})
		.catch((error: Error | FetchError) => {
			onShowFlag(TOGGLE_WATCHING_ERROR);
			log.safeErrorWithoutCustomerData('issue.watchers.watch', error.message, error);
			if (!(error instanceof FetchError && isHttpClientErrorResponse(error))) {
				onError?.(source, 'startWatching');
			}

			return Observable.of(startWatchingFailure());
		});

const stopWatching = (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	accountId: null | AccountId,
	{ analyticsEvent, source, onSuccess, onError }: ToggleWatchingPayload,
	onShowFlag: OnShowFlag,
) =>
	unwatch({
		baseUrl,
		issueKey,
		accountId,
	})
		.map(() => {
			fireAnalytics(analyticsEvent, 'stopWatching', source);
			onSuccess?.(source, 'stopWatching');
			if (source === SHORTCUT_SOURCE) {
				onShowFlag(STOP_WATCHING_SUCCESS);
			}
			return stopWatchingSuccess();
		})
		.catch((error: Error | FetchError) => {
			onShowFlag(TOGGLE_WATCHING_ERROR);
			log.safeErrorWithoutCustomerData('issue.watchers.unwatch', error.message, error);
			if (!(error instanceof FetchError && isHttpClientErrorResponse(error))) {
				onError?.(source, 'stopWatching');
			}
			return Observable.of(stopWatchingFailure());
		});

export const createEpic =
	(onShowFlag: OnShowFlag) => (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
		action$.ofType(TOGGLE_WATCHING_REQUEST).mergeMap(({ payload }: ToggleWatchingRequestAction) => {
			const state = store.getState();

			const isWatching = getIsWatching(state);
			const baseUrl = getBaseUrl(state);
			const issueKey = getIssueKey(state);

			// isWatching has already been optimistically updated in the reducer
			// so we want to fire an action to match our current state
			const loggedInUserDetails = getLoggedInUserDetails(state);
			if (!loggedInUserDetails) {
				return Observable.empty<never>();
			}

			return isWatching
				? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					(startWatching(
						baseUrl,
						issueKey,
						loggedInUserDetails.id,
						payload,
						onShowFlag,
					) as Observable<Action>)
				: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					(stopWatching(
						baseUrl,
						issueKey,
						loggedInUserDetails.id,
						payload,
						onShowFlag,
					) as Observable<Action>);
		});
