import React, { useCallback, useMemo } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { getErrorType } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-error-type';
import type { UiModificationsExtension } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { IssueAdjustmentsErrorHandler } from '@atlassian/jira-issue-adjustments/src/ui/uim-root-async/error-handler/index.tsx';
import {
	IssueAdjustmentsAsync,
	type IssueAdjustmentsAsyncProps,
} from '@atlassian/jira-issue-adjustments/src/ui/uim-root-async/index.tsx';
import {
	useAnalyticsSource,
	useIssueId,
	useIssueKey,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIssueFieldConfigWithoutUimOverrides } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { ISSUE_TYPE } from '@atlassian/jira-platform-field-config';
import { useProjectContext, useProjectKey } from '@atlassian/jira-project-context-service/src/main';
import { ISSUE_VIEW_VIEW_TYPE } from '../../common/constants';
import { ErrorFlags } from './error-flags';
import { FieldsProcessor } from './fields-processor';
import { NotificationFlags } from './notification-flags';
import { useOverridesStoreCleanup } from './overrides-store-cleanup';
import { useBackgroundRefresh } from './use-background-refresh';
import { getSupportedFieldsCount } from './utils';

export type Props = {
	modules: UiModificationsExtension[];
};

const UiModificationsEntryPointLoaded = ({ modules }: Props) => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const project = useProjectContext(projectKey);
	const analyticsSource = useAnalyticsSource();
	const [{ value: fieldsConfig }] = useIssueFieldConfigWithoutUimOverrides({ issueKey });
	const [issueType] = useFieldValue({ issueKey, fieldKey: ISSUE_TYPE });
	const issueId = useIssueId();

	useOverridesStoreCleanup({ issueType, issueKey });
	useBackgroundRefresh();

	const issueAdjustmentsContext = useMemo(() => {
		if (issueType && project && issueId) {
			return {
				project: {
					id: project.projectId,
					key: project.projectKey,
					type: project.projectType,
					isSimplified: project.isSimplified,
				},
				issueType: {
					id: issueType.id,
					name: issueType.name,
				},
				issue: {
					id: issueId,
					key: issueKey,
				},
			};
		}

		return null;
	}, [issueId, issueType, project, issueKey]);

	const errorFallback = useCallback(
		() => (
			<>
				<IssueAdjustmentsErrorHandler />
				<ErrorFlags />
			</>
		),
		[],
	);

	const runtimeProps = useMemo<IssueAdjustmentsAsyncProps['runtimeProps']>(
		() => ({
			// null check will be performed later in renderIssueAdjustments
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			issueAdjustmentsContext: issueAdjustmentsContext!,
			fieldsCount: getSupportedFieldsCount(fieldsConfig),
			viewType: ISSUE_VIEW_VIEW_TYPE,
			resetFormFieldsCounter: 0,
			modules,
		}),
		[issueAdjustmentsContext, fieldsConfig, modules],
	);

	const renderIssueAdjustments = () => {
		if (!issueAdjustmentsContext) {
			return null;
		}

		return (
			<IssueAdjustmentsAsync
				// We need to make sure that the context reset along with the issue type change
				// so the fields could re-initialize correctly.
				// Current issue is batched updates on sweet state hooks prevent unmounting FieldsProcessor on context change
				{...(fg('uim_add_key_property_to_issue_adjustment_component')
					? {
							key: `uim-context-${issueId}-${issueType.id}`,
						}
					: {})}
				containerId="uiModifications"
				containerPackageName="jiraUiModificationsViewIssueView"
				runtimeProps={runtimeProps}
				triggerPointKey={analyticsSource}
				errorFallback={errorFallback}
			/>
		);
	};

	return (
		<>
			{renderIssueAdjustments()}
			{issueId && (
				<FieldsProcessor issueKey={issueKey} fieldsConfig={fieldsConfig} issueId={issueId} />
			)}
			<ErrorFlags />
			{fg('ui_modifications_multiple_apps') && <NotificationFlags />}
		</>
	);
};

export const UiModificationsEntryPoint = (props: Props) => {
	if (ff('forge-ui-modifications-killswitch-for-issueview_v3wj9')) {
		return null;
	}

	return (
		<JSErrorBoundary
			id="uiModifications"
			packageName="jiraUiModificationsViewIssueView"
			fallback={() => (
				<>
					<IssueAdjustmentsErrorHandler />
					<ErrorFlags />
				</>
			)}
			attributes={(error: Error) => ({
				errorType: getErrorType(error),
				viewType: ISSUE_VIEW_VIEW_TYPE,
			})}
			sendToPrivacyUnsafeSplunk
		>
			<UiModificationsEntryPointLoaded {...props} />
		</JSErrorBoundary>
	);
};
