import React, { useCallback } from 'react';
import throttle from 'lodash/throttle';
import WatchIcon from '@atlaskit/icon/glyph/watch';
import { getCommandPaletteIssueActionsPriority } from '@atlassian/jira-command-palette-common/src/common/utils/get-command-palette-issue-actions-priority/index.tsx';
import { getCommandPaletteIssueActionsHeader } from '@atlassian/jira-command-palette-common/src/common/utils/index.tsx';
import { useCommandKeywords } from '@atlassian/jira-command-palette-common/src/controllers/use-command-keywords/index.tsx';
import { CommandShortcuts } from '@atlassian/jira-command-palette-common/src/ui/command-shortcuts/index.tsx';
import { TASK_FAIL, TASK_SUCCESS } from '@atlassian/jira-experience-tracker/src/common/constants';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import {
	THROTTLE_MS,
	TOGGLE_WATCHING_SHORTCUT,
} from '@atlassian/jira-issue-view-common-constants/src/watchers.tsx';
import {
	useProjectKey,
	useApplication,
	useEdition,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import messages from './messages';

type Props = {
	isWatching: boolean;
	onToggleWatching: (
		onSuccess: (source: string, experience: string) => void,
		onError: (source: string, experience: string) => void,
	) => void;
};

export const KeyboardShortcuts = (props: Props) => {
	const { isWatching } = props;

	const { formatMessage } = useIntl();

	const issueKey = useIssueKey();

	const { getKeywords } = useCommandKeywords();

	const projectKey = useProjectKey(issueKey);
	const application = useApplication(projectKey, true);
	const edition = useEdition(projectKey, true);

	const onSuccess = useCallback(
		(source: string, experience: string) => {
			sendExperienceAnalytics({
				wasExperienceSuccesful: true,
				action: TASK_SUCCESS,
				analyticsSource: source,
				experience,
				application: application ?? null,
				edition: edition ?? null,
			});
		},
		[application, edition],
	);

	const onError = useCallback(
		(source: string, experience: string) => {
			sendExperienceAnalytics({
				wasExperienceSuccesful: false,
				action: TASK_FAIL,
				analyticsSource: source,
				experience,
				application: application ?? null,
				edition: edition ?? null,
			});
		},
		[application, edition],
	);

	const onToggleWatching = throttle(
		() => {
			props.onToggleWatching(onSuccess, onError);
		},
		THROTTLE_MS,
		{ trailing: false },
	);

	const sectionId = getCommandPaletteIssueActionsHeader(issueKey);
	const keyMap = {
		[TOGGLE_WATCHING_SHORTCUT]: {
			callback: onToggleWatching,
			label: <FormattedMessage {...messages.toggleWatching} />,
			registerInCommandPalette: {
				id: `issue-view.watchers.keyboard-shortcuts.${issueKey}.${
					isWatching ? 'stop' : 'start'
				}-watching`,
				name: formatMessage(isWatching ? messages.stopWatching : messages.startWatching),
				keywords: getKeywords('startWatchingIssueSynonyms'),
				priority: getCommandPaletteIssueActionsPriority('ISSUE_HEADER_ITEMS'),
				section: sectionId,
				components: {
					LeftIcon: () => (
						<WatchIcon
							label={formatMessage(isWatching ? messages.stopWatching : messages.startWatching)}
						/>
					),
				},
				analytics: {
					action: isWatching ? 'stopWatchingIssue' : 'startWatchingIssue',
				},
			},
		},
	};

	return <CommandShortcuts keyMap={keyMap} />;
};

export default KeyboardShortcuts;
