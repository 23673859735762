import React, { type SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/standard-button';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';

type Props = {
	isContentExpanded: boolean;
	onExpand: () => void;
	onCollapse: () => void;
};

export const ContentSectionExpander = ({ isContentExpanded, onExpand, onCollapse }: Props) => {
	const { formatMessage } = useIntl();

	const handleExpand = (event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		onExpand && onExpand();
		fireUIAnalytics(analyticsEvent, 'showRequestDetails');
	};

	const handleCollapse = (event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		onCollapse && onCollapse();
		fireUIAnalytics(analyticsEvent, 'hideRequestDetails');
	};

	const renderShowDetailsButton = () => (
		// @ts-expect-error - TS2322 - Type '{ children: string; externalId: string; appearance: "link"; onClick: (event: SyntheticEvent<Element, Event>, analyticsEvent: UIAnalyticsEventInterface) => void; }' is not assignable to type 'IntrinsicAttributes & ButtonProps & RefAttributes<HTMLElement>'.
		<Button externalId="showdetailslink.expand" appearance="link" onClick={handleExpand}>
			{formatMessage(messages.showDetails)}
		</Button>
	);

	const renderHideDetailsButton = () => (
		// @ts-expect-error - TS2322 - Type '{ children: string; externalId: string; appearance: "link"; onClick: (event: SyntheticEvent<Element, Event>, analyticsEvent: UIAnalyticsEventInterface) => void; }' is not assignable to type 'IntrinsicAttributes & ButtonProps & RefAttributes<HTMLElement>'.
		<Button externalId="showdetailslink.collapse" appearance="link" onClick={handleCollapse}>
			{formatMessage(messages.hideDetails)}
		</Button>
	);

	return isContentExpanded ? renderHideDetailsButton() : renderShowDetailsButton();
};
