import { createSelector } from 'reselect';
import type { Issue } from '@atlassian/jira-assign-issue-parent-modal/src/model/types.tsx';
import { isSubtaskHierarchyLevel } from '@atlassian/jira-issue-type-hierarchies';
import type { ParentIssue } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { contextSelector } from '../common/state/selectors/context-selector';
import { uiSelector, parentIssueEntitySelector } from '../common/state/selectors/issue-selector';
import { issueTypeSelector } from './breadcrumbs-selector';

export const isAssignIssueParentModalOpen = createSelector(
	uiSelector,
	(ui) => ui.assignIssueParentModal.isOpen,
);

export const assignIssueParentModalIssuesSelector = createSelector(
	contextSelector,
	parentIssueEntitySelector,
	(context, parentIssue: ParentIssue | null): Issue[] => [
		{
			id: context.issueId,
			key: context.issueKey,
			parentId: parentIssue && parentIssue.id,
		},
	],
);

export const shouldHideUnassignOptionSelector = createSelector(issueTypeSelector, (issueType) => {
	// The issue type may be undefined while the Issue View Modal is still loading
	if (issueType === undefined) {
		return false;
	}
	return isSubtaskHierarchyLevel(issueType.hierarchyLevel);
});
