/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import { Box, Inline, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { RovoLogo } from '@atlassian/conversation-assistant-ui-components';

import { AtlassianIntelligenceLogo } from '../../AtlassianIntelligenceLogo/AtlassianIntelligenceLogo';
import messages from '../messages';
import { type AIBrand } from '../types';

export interface FooterAIBrandProps {
	/** The brand to display */
	brand: AIBrand;
	/** The appearance of main footer. */
	appearance: 'small' | 'medium' | 'large';
}

export function FooterAIBrand({ brand, appearance }: FooterAIBrandProps) {
	const { formatMessage } = useIntl();

	const shortDisclaimer =
		brand === 'rovo' ? messages.rovoCopyright : messages.atlassianIntelligenceCopyrightShort;
	const fullDisclaimer =
		brand === 'rovo' ? messages.rovoCopyright : messages.atlassianIntelligenceCopyright;

	return (
		<Inline>
			{appearance !== 'small' && (
				<Box>
					{appearance === 'medium' ? (
						<Text size="small" color="color.text.subtlest">
							{formatMessage(shortDisclaimer)}
						</Text>
					) : (
						<Text size="small" color="color.text.subtlest">
							{formatMessage(fullDisclaimer)}
						</Text>
					)}
				</Box>
			)}

			{brand === 'rovo' ? (
				<RovoLogo label={formatMessage(fullDisclaimer)} primaryColor={token('color.icon.subtle')} />
			) : (
				<AtlassianIntelligenceLogo
					label={formatMessage(fullDisclaimer)}
					primaryColor={token('color.icon.subtle')}
				/>
			)}
		</Inline>
	);
}
