import { useCallback } from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import {
	setCommentValue,
	saveCommentRequest,
} from '@atlassian/jira-issue-view-store/src/actions/comment-actions';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useDispatch, useStore } from '@atlassian/jira-react-redux';
import { useTasksUpdater } from '../use-tasks-updater';

export const useCommentTasksUpdater = (commentId: string) => {
	const store = useStore<State>();
	const dispatch = useDispatch();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const getAdfValue = useCallback(
		() => store.getState().entities.comments[commentId]?.bodyAdf,
		[commentId, store],
	);

	const changeAdfValue = useCallback(
		(updatedAdf: ADF) => {
			dispatch(setCommentValue(commentId, updatedAdf));
		},
		[commentId, dispatch],
	);

	const saveAdfValue = useCallback(() => {
		dispatch(
			saveCommentRequest(store.getState().entities.comments[commentId], createAnalyticsEvent({})),
		);
	}, [commentId, createAnalyticsEvent, dispatch, store]);

	const toggleTask = useTasksUpdater({
		getAdfValue,
		onChange: changeAdfValue,
		onSave: saveAdfValue,
	});

	return toggleTask;
};
