import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performGetRequestWithRetry } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { AtlassianAccount } from '../common/types';
import { GET_ME_URL } from './constants';

export const getAtlassianAccountWithRethrow = async ({
	source,
}: { source?: string } = {}): Promise<AtlassianAccount | null> => {
	try {
		const {
			extended_profile: { job_title: jobTitle, team_type: teamType },
			email,
			account_id: accountId,
		} = await performGetRequestWithRetry(GET_ME_URL);
		return {
			extendedProfile: {
				jobTitle,
				teamType,
			},
			email,
			accountId,
		};
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			meta: {
				id: 'getAtlassianAccountWithRethrow',
				packageName: 'jiraAtlassianAccount',
				teamName: 'jlove-makkuro',
			},
			attributes: {
				source,
			},
			error,
		});
		throw error;
	}
};
