import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	spotligthLearnMore: {
		id: 'issue-field-parent.changeboarding.spotligth-learn-more',
		defaultMessage: 'Information on the parent field',
		description: 'Link to extra field information',
	},
	infoMessage: {
		id: 'issue-field-parent.changeboarding.info-message',
		defaultMessage: 'Your issue type hierarchy determines the issues you can select here.',
		description: 'Link to extra field information',
	},
	infoLearnMore: {
		id: 'issue-field-parent.changeboarding.info-learn-more',
		defaultMessage: 'Learn more about parent issues',
		description: 'Link to extra info about issue hierarchy',
	},
});
