import type { ApolloQueryResult } from 'apollo-client';
import gqlTagPolaris from 'graphql-tag';
import type { Ari } from '@atlassian/jira-platform-ari';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { jira_polaris_GetCommentAndInsightTimestampsQuery as CommentTimestampAndInsightTimestampsQuery } from './__generated_apollo__/jira_polaris_GetCommentAndInsightTimestampsQuery';
import { useProjectFetcher } from './get';
import type { MetadataPerIssueAri, ProjectConfigFetcher } from './types';

export const useProjectConfig = (): [ProjectConfigFetcher] => useProjectFetcher();

export const LAST_VIEWED_QUERY = gqlTagPolaris`
query jira_polaris_GetCommentAndInsightTimestampsQuery($ari: ID!) {
    polarisProject(id: $ari, skipRefresh: true) {
      id
      ...ProjectIdeasExtendedConfigGQL
    }
  }

  fragment ProjectIdeasExtendedConfigGQL on PolarisProject {
      ideas {
        id
        lastCommentsViewedTimestamp
        lastInsightsViewedTimestamp
      }
    }
`;

const assertNoError = (result: ApolloQueryResult<CommentTimestampAndInsightTimestampsQuery>) => {
	if (result.errors !== undefined && result.errors !== null) {
		throw new Error(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			`project-config.fetch-error:${result.errors.map((e: any) => e.message).join(', ')}`,
		);
	}
};

export const fetchCommentsAndInsightsMetadata = (
	client: PolarisApolloClient,
	projectAri: Ari,
): Promise<MetadataPerIssueAri> =>
	client
		.query<CommentTimestampAndInsightTimestampsQuery>({
			query: LAST_VIEWED_QUERY,
			variables: {
				ari: projectAri,
			},
		})
		.then((result) => {
			assertNoError(result);
			if (
				!result.data ||
				result.data.polarisProject === undefined ||
				result.data.polarisProject === null
			) {
				throw new Error('project-config.fetch-error: no data or no data node');
			}

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const ariToCommentsTimestamp: Record<string, any> = {};
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const ariToInsightsTimestamp: Record<string, any> = {};
			result.data.polarisProject.ideas.forEach((issue) => {
				if (
					issue.lastCommentsViewedTimestamp !== undefined &&
					issue.lastCommentsViewedTimestamp !== null
				) {
					ariToCommentsTimestamp[issue.id] = {
						timestampCurrentUserSeenComments: new Date(issue.lastCommentsViewedTimestamp).getTime(),
					};
				}
				if (
					issue.lastInsightsViewedTimestamp !== undefined &&
					issue.lastInsightsViewedTimestamp !== null
				) {
					ariToInsightsTimestamp[issue.id] = {
						timestampCurrentUserSeenInsights: new Date(issue.lastInsightsViewedTimestamp).getTime(),
					};
				}
			});
			return {
				comments: ariToCommentsTimestamp,
				insights: ariToInsightsTimestamp,
			};
		});
