import get from 'lodash/get';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { StatusKey } from '@atlassian/jira-providers-issue/src/model/issue-system-fields.tsx';
import type { StatusValue } from '../../common/types';
import type { StatusFieldSaveByIdResponse, StatusSaveId, Props } from './types';
import { useStatusMutation } from './use-status-mutation';

/**
 * @deprecated useStatusField is using useEditField that uses sweet-state stores for data
 * use Relay store instead
 */
export const useStatusField = ({ issueKey, onSuccess, onFailure }: Props) => {
	const customSaveField = useStatusMutation();
	const [{ value: fieldConfig }] = useFieldConfig(issueKey, StatusKey);
	const fieldType = get(fieldConfig, 'scheme.type', StatusKey);
	const [{ error, value, loading }, { resetError, saveById }] = useEditField<
		StatusValue,
		null,
		StatusFieldSaveByIdResponse,
		StatusSaveId
	>({
		fieldKey: StatusKey,
		issueKey,
		fieldType,
		onSuccess,
		onFailure,
		initialValue: null,
		saveFieldById: customSaveField,
	});

	return [
		{ error, value, loading, fieldConfig },
		{ resetError, saveById },
	] as const;
};
