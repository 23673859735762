import React, { forwardRef } from 'react';
import Button from '@atlaskit/button';
import EditIcon from '@atlaskit/icon/glyph/editor/edit';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from '../../common/messages';
import type { Props } from './types';

/**
 * Edit button to be rendered within the read view of an inline edit component to allow the user to transition to the
 * edit view.
 */
export const EditButton = forwardRef<HTMLElement, Props>(
	({ context: { onEdit }, label, onBlur, onFocus, style }, ref) => {
		const { formatMessage } = useIntl();
		const finalEditButtonLabel = label ?? formatMessage(messages.editButtonLabel);
		return (
			<Button
				appearance="subtle"
				iconBefore={
					<EditIcon label={finalEditButtonLabel} primaryColor={token('color.icon', '#44546F')} />
				}
				onClick={onEdit}
				onBlur={onBlur}
				onFocus={onFocus}
				ref={ref}
				spacing="none"
				// In future providing CSS variables *may* be something that makes its way into the design system, but
				// for now this is an acceptable override to prevent managing additional state with JS.
				// More context here https://atlassian.slack.com/archives/C060GTVV988/p1703214250865779?thread_ts=1703212675.166419&cid=C060GTVV988
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, jira/react/no-style-attribute -- Ignored via go/DSP-18766
				style={style}
			/>
		);
	},
);
