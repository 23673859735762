import React, { memo, useCallback, useMemo } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import InlineEditStateless, { type InlineEditProps } from '@atlaskit/inline-edit';
import { token } from '@atlaskit/tokens';
// eslint-disable-next-line import/order
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	AnalyticsEventToProps,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { readOnlyViewContainerSelectorName } from '../styled';
import type { FieldInlineEditStateLessProps } from '../types';

export const EnterEscapeHandlerWithAnalytics = AnalyticsEventToProps('EnterEscapeHandler', {
	onEnter: 'entered',
	onEscape: 'escaped',
})(EnterEscapeHandler);

export const EscapeHandlerWithAnalytics = AnalyticsEventToProps('EscapeHandler', {
	onEscape: 'escaped',
})(EnterEscapeHandler);

export const EnterHandlerWithAnalytics = AnalyticsEventToProps('EnterHandler', {
	onEnter: 'entered',
})(EnterEscapeHandler);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldContainerControl = styled.div({
	/* @see: https://bento-project-backup.atlassian.net/browse/BENTO-10009 */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > form > div > div > button:focus': {
		outline: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const FieldContainerExperiment = styled2.div({
	/* @see: https://bento-project-backup.atlassian.net/browse/BENTO-10009 */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > form > div > div > button:focus': {
		outline: 'none',
	},
});

const FieldContainer = styledComponentWithCondition(
	() => ff('compiled-react-migration-issue-view_zilee'),
	FieldContainerExperiment,
	FieldContainerControl,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NonEditableMargin = styled2.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	margin: `${2 * gridSize - 2}px 0px ${token('space.075', '6px')} ${token('space.025', '2px')}`,
});

const useCallbackWithAnalyticsEvent = (
	callback: ((analyticsEvent: UIAnalyticsEvent) => void) | undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	payload: Record<any, any>,
) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	return useCallback(() => {
		if (typeof callback === 'function') {
			callback(createAnalyticsEvent(payload));
		}
	}, [callback, createAnalyticsEvent, payload]);
};

export const FieldInlineEditStateLess = memo(
	<FieldValue = string,>(props: FieldInlineEditStateLessProps<FieldValue>) => {
		const {
			actionSubject = 'fieldInlineEdit',
			editView,
			isEditable = false,
			isEditing = false,
			isLabelHidden = true,
			readView,
			testId,
			onCancel,
			onConfirm,
			onEdit,
			onEditRequested,
			onEnter,
			onEscape,
		} = props;

		const handleEnter = useCallbackWithAnalyticsEvent(onEnter, {
			action: 'entered',
			actionSubject,
			...props.componentAnalyticsData,
		});
		const handleEscape = useCallbackWithAnalyticsEvent(onEscape, {
			action: 'escaped',
			actionSubject,
			...props.componentAnalyticsData,
		});
		const handleEdit = useCallbackWithAnalyticsEvent(onEditRequested || onEdit, {
			action: 'focused',
			actionSubject,
			...props.componentAnalyticsData,
		});
		const handleCancel = useCallbackWithAnalyticsEvent(onCancel, {
			action: 'canceled',
			actionSubject,
			...props.componentAnalyticsData,
		});
		const handleConfirm: InlineEditProps<FieldValue>['onConfirm'] = useCallback(
			(_, event) => {
				if (typeof onConfirm === 'function') {
					onConfirm(
						event.update({
							action: 'confirmed',
							actionSubject,
							...props.componentAnalyticsData,
						}),
					);
				}
			},
			[actionSubject, onConfirm, props.componentAnalyticsData],
		);

		const renderReadView = typeof readView === 'function' ? readView : () => readView;
		const renderEditView = useMemo(() => {
			if (typeof editView === 'function') {
				return editView;
			}
			return () => editView;
		}, [editView]);

		const renderEditViewWithHandlers: InlineEditProps<FieldValue>['editView'] = useCallback(
			(editProps, ref) => {
				if (editView === null) return null;

				if (onEnter && onEscape) {
					return (
						<EnterEscapeHandlerWithAnalytics onEnter={handleEnter} onEscape={handleEscape}>
							{renderEditView(editProps, ref)}
						</EnterEscapeHandlerWithAnalytics>
					);
				}

				if (onEnter) {
					return (
						<EnterHandlerWithAnalytics onEnter={handleEnter}>
							{renderEditView(editProps, ref)}
						</EnterHandlerWithAnalytics>
					);
				}

				if (onEscape) {
					return (
						<EscapeHandlerWithAnalytics onEscape={handleEscape}>
							{renderEditView(editProps, ref)}
						</EscapeHandlerWithAnalytics>
					);
				}

				return renderEditView(editProps, ref);
			},
			[editView, onEnter, onEscape, handleEnter, handleEscape, renderEditView],
		);

		const inlineEditProps: InlineEditProps<FieldValue> = {
			defaultValue: 'defaultValue' in props ? props.defaultValue : props.value,
			onConfirm: handleConfirm,
			readView: renderReadView,
			editView: renderEditViewWithHandlers,
			isEditing,
			onCancel: handleCancel,
			onEdit: handleEdit,
			isRequired: props.isRequired,
			analyticsContext: props.analyticsContext,
			cancelButtonLabel: props.cancelButtonLabel,
			confirmButtonLabel: props.confirmButtonLabel,
			editButtonLabel: props.editButtonLabel,
			hideActionButtons:
				'hideActionButtons' in props ? props.hideActionButtons : props.areActionButtonsHidden,
			keepEditViewOpenOnBlur:
				'keepEditViewOpenOnBlur' in props
					? props.keepEditViewOpenOnBlur
					: props.isConfirmOnBlurDisabled,
			label: isLabelHidden === true ? undefined : props.label,
			readViewFitContainerWidth:
				'readViewFitContainerWidth' in props
					? props.readViewFitContainerWidth
					: props.isFitContainerWidthReadView,
			startWithEditViewOpen: props.startWithEditViewOpen,
			validate: props.validate,
		};

		return isEditable ? (
			<FieldContainer data-testid={testId != null ? `${testId}--container` : undefined}>
				<InlineEditStateless {...inlineEditProps} />
			</FieldContainer>
		) : (
			<NonEditableMargin
				data-component-selector={readOnlyViewContainerSelectorName}
				data-testid={testId != null ? `${testId}--read-only-container` : undefined}
			>
				{renderReadView()}
			</NonEditableMargin>
		);
	},
);

export default FieldInlineEditStateLess;
