import { VIEW_ACCESS_LEVELS } from '@atlassian/jira-polaris-domain-view/src/view-access/constants';
import {
	createActionsHook,
	createStateHook,
	createStore,
	type Selector,
} from '@atlassian/react-sweet-state';
import { addViewAccessPrincipals } from './actions/add-view-access-principals';
import { changeViewAccessPrincipalRole } from './actions/change-view-access-principal-role';
import { clearFailures } from './actions/clear-failures';
import { clearSkippedAccountFailures } from './actions/clear-skipped-account-failures';
import { loadCurrentUserViewAccess } from './actions/load-current-user-view-access';
import { loadViewPermissionsConfig } from './actions/load-view-permissions-config';
import { removeViewAccessPrincipals } from './actions/remove-view-access-principals';
import { setHasManageViewsPermission } from './actions/set-has-manage-views-permission';
import { updateViewAccessLevel } from './actions/update-view-access-level';
import type { State } from './types';

const actions = {
	addViewAccessPrincipals,
	changeViewAccessPrincipalRole,
	clearFailures,
	clearSkippedAccountFailures,
	loadCurrentUserViewAccess,
	loadViewPermissionsConfig,
	removeViewAccessPrincipals,
	setHasManageViewsPermission,
	updateViewAccessLevel,
};

const initialState: State = {
	config: {
		isAddingPrincipals: false,
		error: undefined,
		skippedAccountFailures: [],
	},
	accessLevel: VIEW_ACCESS_LEVELS.LIMITED,
	additionalAccess: {
		profiles: [],
		groups: [],
	},
	hasManageViewsPermission: false,
	viewAccessMap: {},
};

const Store = createStore({
	initialState,
	actions,
	name: 'PolarisViewAccessStore',
});

export const createViewAccessStateHook = <T,>(selector: Selector<State, void, T>) =>
	createStateHook<State, typeof actions, T>(Store, { selector });

export const useActions = createActionsHook(Store);
