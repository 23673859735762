import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages';

export const getInvalidMessage = (
	value: string | null,
	fieldName: string,
	formatMessage: FormatMessage,
	isRequired?: boolean | null,
	maxLength?: number,
): string | undefined => {
	if (isRequired && (value ?? '').trim().length === 0) {
		return formatMessage(messages.errorEmpty, { fieldName });
	}
	if (maxLength && maxLength > 0 && (value?.length ?? 0) > maxLength) {
		return formatMessage(messages.maxLengthErrorMessage, { maxLength });
	}
};
