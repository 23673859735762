import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { BaseUrl, CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../types';

type Args = {
	baseUrl: BaseUrl | undefined;
	cloudId: CloudId;
	userId: string | null;
	isAnonymousUser: boolean;
	analyticsEvent: UIAnalyticsEvent;
	packageName: string;
};

const isValidFetchState = ({
	data: { hasOpsgenieAccount },
	isLoading,
	error,
}: Readonly<State>): boolean =>
	hasOpsgenieAccount === undefined && !isLoading && error === undefined;

/**
 * NOTE: if you want to fetch data for multiple userIds - change state to store data for multiple ids
 *
 * "Opsgenie account" !== "Opsgenie license", as stakeholders don't have an OG licence but have an OG account and can use some JSM features.
 */
export const fetchHasOpgenieAccount =
	({
		baseUrl,
		cloudId,
		userId,
		isAnonymousUser,
		analyticsEvent,
		packageName,
	}: Args): Action<State> =>
	async ({ getState, setState }) => {
		if (!isValidFetchState(getState())) {
			return;
		}

		if (isAnonymousUser) {
			setState({
				data: {
					hasOpsgenieAccount: false,
				},
				isLoading: false,
				error: undefined,
			});
			return;
		}

		if (userId == null) {
			return;
		}

		setState({
			isLoading: true,
			error: undefined,
		});

		try {
			const response: boolean = await performGetRequest(
				`${
					baseUrl || ''
				}/gateway/api/jsd/escalator/${cloudId}/api/user-rights/has-opsgenie-access?accountId=${userId}`,
			);
			fireOperationalAnalytics(analyticsEvent, 'fetchHasOpgenieAccount succeeded');
			setState({
				data: {
					hasOpsgenieAccount: response,
				},
				isLoading: false,
				error: undefined,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics({
				error,
				meta: {
					id: 'fetchHasOpgenieAccount',
					packageName,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
			setState({
				isLoading: false,
				error,
			});
		}
	};
