import React from 'react';

import { type IntlShape } from 'react-intl-next';

import {
	type Command,
	type CommandDispatch,
	type FloatingToolbarDropdown,
	type FloatingToolbarItem,
} from '@atlaskit/editor-common/types';
import type { EditorState } from '@atlaskit/editor-prosemirror/state';
import { fg } from '@atlaskit/platform-feature-flags';
import { AgentAvatar } from '@atlaskit/rovo-agent-components';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';
import { RovoAgentIcon } from '@atlassian/conversation-assistant-ui-components';
import { type EditorAgent } from '@atlassian/generative-ai-modal/utils/agents';

import { createAgentConfigItem } from '../../prebuilt/config-item-agents/create-agent-config-item';
import { type AIGlobalOptIn, type EditorPluginAIProvider, type RovoPublish } from '../../types';
import { PreloadModalComponent } from '../../ui/components/BrowseRovoAgentsModal/BrowseRovoAgentsModal';
import { createOpenAIModalCommand } from '../decoration/actions';
import { floatingToolbarAIDropdownTestIDPrefix } from '../floating-toolbar-button/floating-toolbar-button';

import { openBrowseAgentsModal } from './commands';
import { messages } from './messages';

type Props = {
	intl: IntlShape;
	agents?: EditorAgent[];
	aiGlobalOptIn: AIGlobalOptIn;
	publish: RovoPublish;
	editorPluginAIProvider: EditorPluginAIProvider;
};

export const getAgentsDropdown = ({
	intl,
	agents,
	aiGlobalOptIn,
	publish,
	editorPluginAIProvider,
}: Props): FloatingToolbarItem<Command>[] => {
	const options: FloatingToolbarDropdown<Command>['options'] = (agents || []).map(
		(agent: EditorAgent, index: number) => {
			return {
				title: agent.name,
				testId: `${floatingToolbarAIDropdownTestIDPrefix}agent-${agent.id}`,
				icon: editorExperiment('platform_editor_ai_command_palate_improvement', 'test') ? (
					<AgentAvatar
						agentId={agent.id}
						agentNamedId={agent.externalConfigReference ?? agent.namedId}
						label={agent.name}
						agentIdentityAccountId={agent.identityAccountId}
						isForgeAgent={agent.creatorType === 'FORGE'}
						forgeAgentIconUrl={agent.icon}
					/>
				) : (
					<RovoAgentIcon label={agent.name} />
				),
				onClick: (state: EditorState, dispatch?: CommandDispatch) => {
					const openAIModalCommand = createOpenAIModalCommand({
						state,
						configItem: createAgentConfigItem('range', agent, editorPluginAIProvider),
						lastTriggeredFrom: 'floatingToolbarDropdown',
						// ED-18194 Added analytics for floating toolbar to determine
						// the % of users who click the button vs shift tab to it
						// triggerMethod: !didMouseEnter ? 'keyboard' : 'click',
						aiGlobalOptIn,
					});

					openAIModalCommand(state, dispatch);
					return false;
				},
			};
		},
	);

	options.push({
		// FIXME: It's should not be a part of icon! It should be redone to hook!!!
		icon: editorPluginAIProvider.isRovoEnabled && <PreloadModalComponent />,
		title: intl.formatMessage(messages.agentsViewAgentsLabel),
		testId: `${floatingToolbarAIDropdownTestIDPrefix}view-all-agents`,
		onClick: (state: EditorState, dispatch?: CommandDispatch) => {
			if (fg('rovo-chat-browse-agents')) {
				openBrowseAgentsModal(true)(state, dispatch);
			} else {
				publish({
					type: 'open-browse-agent-modal',
					source: 'editor',
				});
			}
			return false;
		},
	});

	return [
		{
			type: 'separator',
		},
		{
			type: 'dropdown',
			testId: `${floatingToolbarAIDropdownTestIDPrefix}agents`,
			title: intl.formatMessage(messages.agentsDropdownTitle),
			options,
		},
	];
};
