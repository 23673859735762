import { withAnalyticsEvents } from '@atlaskit/analytics-next';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { applinkAuthenticationRequest } from '@atlassian/jira-issue-view-store/src/actions/applink-authentication-actions';
import {
	deleteConfluencePageLinkRequest,
	linkConfluencePageClicked,
	linkConfluencePageClickedWithAISuggestions,
} from '@atlassian/jira-issue-view-store/src/actions/confluence-pages-actions';
import {
	licensedProductsSelector,
	hasShownIntentToXflowToConfluenceTraitSelector,
	hasActivatedConfluenceWithinFourteenDaysSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { projectTypeSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import { issueTypeSelector } from '@atlassian/jira-issue-view-store/src/selectors/breadcrumbs-selector';
import {
	canLinkConfluencePageSelector,
	systemConfluenceAppLinkSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/confluence-app-links-selector';
import {
	linkedConfluencePagesCountSelector,
	linkedConfluencePagesPagesSelector,
	linkedConfluenceWhiteboardsCountSelector,
	linkedConfluenceWhiteboardsWhiteboardsSelector,
	mentionedConfluencePagesCountSelector,
	mentionedConfluencePagesPagesSelector,
	isCreateLinkedPageOpenedSelector,
	isConfluencePageLinksEnabledSelector,
	confluencePageAISuggestionIsOpenSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/confluence-content-selector';
import { projectNameSelector } from '@atlassian/jira-issue-view-store/src/selectors/project-selector';
import { isConfluencePageDummyLinkExperiment } from '../../feature-flags';
import ConfluenceContentView from './confluence-content';

export default flowWithSafeComponent(
	withAnalyticsEvents(),
	ComponentWithAnalytics('ItemLineCardGroup', {
		onMentionedPageClicked: 'clicked',
		onLinkedPageClicked: 'clicked',
		onLinkedWhiteboardClicked: 'clicked',
	}),
	ComponentWithAnalytics('DeleteConfluencePage', {
		onDeleteConfluencePageLink: 'clicked',
	}),
	// @ts-expect-error - HoC types don't quite align with the latest React typings here.
	connect(
		(state: State) => ({
			isCreateLinkedPageOpened: isCreateLinkedPageOpenedSelector(state),
			linkedPages: linkedConfluencePagesPagesSelector(state),
			linkedPagesCachedCount: linkedConfluencePagesCountSelector(state),
			linkedWhiteboards: linkedConfluenceWhiteboardsWhiteboardsSelector(state),
			linkedWhiteboardsCachedCount: linkedConfluenceWhiteboardsCountSelector(state),
			licensedProducts: licensedProductsSelector(state),
			mentionedPages: mentionedConfluencePagesPagesSelector(state),
			mentionedPagesCachedCount: mentionedConfluencePagesCountSelector(state),
			isConfluencePageLinksEnabled: isConfluencePageLinksEnabledSelector(state),
			canLinkConfluencePage: canLinkConfluencePageSelector(state),
			systemConfluenceAppLinkUrl: systemConfluenceAppLinkSelector(state)?.baseUrl,
			...(isConfluencePageDummyLinkExperiment()
				? {
						projectType: projectTypeSelector(state),
						issueType: issueTypeSelector(state),
						projectName: projectNameSelector(state),
						hasShownIntentToXflowToConfluence:
							hasShownIntentToXflowToConfluenceTraitSelector(state),
					}
				: {}),
			...(ff('confluence-template-placeholder_2bhgf', false)
				? {
						hasActivatedConfluenceWithinFourteenDays:
							hasActivatedConfluenceWithinFourteenDaysSelector(state),
					}
				: {}),
			...(fg('jira_ai_powered_issue_related_confluence_resources')
				? { isAISuggestionsOpen: confluencePageAISuggestionIsOpenSelector(state) }
				: {}),
		}),
		{
			onAuthenticateApplink: applinkAuthenticationRequest,
			onDeleteConfluencePageLink: deleteConfluencePageLinkRequest,
			onAddButtonClicked: linkConfluencePageClicked,
			onShowAISuggestionsClicked: linkConfluencePageClickedWithAISuggestions,
		},
	),
)(ConfluenceContentView);
