import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { getChildIssuesPanelType } from '../../state/context/selectors';
import type { State } from '../../state/types';
import { ChildIssuesLimitPanel as View } from './main';

export const ChildIssuesLimitPanel = flowWithSafeComponent(
	connect((state: State, props: { hasExceededIssuesLimitInitialLoad: boolean }) => {
		// FIXME: JIV-17455 can be null
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const childIssuesPanelType = getChildIssuesPanelType(state)!;

		return {
			childIssuesPanelType,
			hasExceededIssuesLimitInitialLoad: props.hasExceededIssuesLimitInitialLoad,
		};
	}, {}),
)(View);
