import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { type ActionsObservable, combineEpics, type Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { AssociatedIssuesContextActions } from '@atlassian/jira-associated-issues-context-service/src/actions.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { FieldValueServiceActions } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { associatedIssueErrorTypes as errorTypes } from '@atlassian/jira-issue-shared-types/src/common/types/associated-issue-type.tsx';
import type { ChildIssue } from '@atlassian/jira-issue-view-common-types/src/children-issues-type';
import { CHILDREN_ISSUES } from '@atlassian/jira-issue-view-configurations';
import { CLASSIC_PROJECT_EPIC_CHILDREN, CHILDREN_ISSUES_PANEL } from '../../model/types';
import { classicEpicLinkIssue, nextGenLinkIssue } from '../../rest/link-issue';
import {
	getBaseUrl,
	getParentId,
	getChildIssuesPanelType,
	getParentKey,
} from '../../state/context/selectors';
import type { ContextState } from '../../state/context/types';
import {
	type Action,
	LINKING_ISSUE_RETRY,
	ADD_EXISTING_CHILD_ISSUE_REQUEST,
	linkingIssueRetryFailure,
	linkChildSuccess,
	type AddExistingChildIssueRequestAction,
	type LinkingIssueRetryAction,
} from '../../state/entities/actions';
import { getIssue } from '../../state/entities/selectors';
import type { EntitiesState } from '../../state/entities/types';
import type { State } from '../../state/types';
import type { UiState } from '../../state/ui/types';

const linkNextGenChildIssue = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	issueId: any,
	state: {
		readonly context: ContextState;
		readonly entities: EntitiesState;
		readonly ui: UiState;
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	analyticsEvent: any,
	fieldsValuesActions?: FieldValueServiceActions,
	associatedIssuesContextActions?: AssociatedIssuesContextActions,
) => {
	const baseUrl = getBaseUrl(state);
	const parentIssueId = getParentId(state);
	// @ts-expect-error - TS2345 - Type 'ChildIssue | undefined' is not assignable to type 'ChildIssue'.
	const issue: ChildIssue = getIssue(issueId)(state);
	if (!parentIssueId) {
		throw new Error('Attempted to link a next-gen issue to a parent without issue ID ');
	}

	if (issue?.issueKey) {
		const parentIssueKey = getParentKey(state);

		fieldsValuesActions?.setIssue(issue.issueKey, {
			summary: issue.issueSummary,
			issueType: { iconUrl: issue.issueTypeIconUrl, name: 'issuetype' },
		});

		const currentChildren =
			(parentIssueKey &&
				fieldsValuesActions
					?.getFieldValue<ChildIssue[]>(parentIssueKey, CHILDREN_ISSUES)
					.filter((existingIssue) => existingIssue.id !== issue.id)) ||
			[];

		parentIssueKey &&
			fieldsValuesActions?.mergeIssue(parentIssueKey, {
				[CHILDREN_ISSUES]: [
					...currentChildren,
					{
						id: issue.id,
						key: issue.issueKey,
					},
				],
			});
	}

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return Observable.from(nextGenLinkIssue(baseUrl, parentIssueId, issueId))
		.map(() => linkChildSuccess(issueId, issue, analyticsEvent))
		.catch((error) => {
			log.safeErrorWithoutCustomerData(
				'issue.views.common.child-issues-panel.add-existing-issue',
				'Failed to link a next-gen issue to a parent',
				error,
			);

			if (issue.issueKey) {
				associatedIssuesContextActions?.mergeSingleLocalAssociatedIssueContext(issue.issueKey, {
					hasError: true,
					errorType: errorTypes.linkChild,
				});
			}

			return Observable.of(linkingIssueRetryFailure(issueId));
		}) as Observable<Action>;
};

const addExistingChildEpic =
	(
		fieldsValuesActions?: FieldValueServiceActions,
		associatedIssuesContextActions?: AssociatedIssuesContextActions,
	) =>
	(
		action$: ActionsObservable<AddExistingChildIssueRequestAction>,
		store: MiddlewareAPI<State>,
	): Observable<Action> =>
		action$
			.ofType(ADD_EXISTING_CHILD_ISSUE_REQUEST)
			.mergeMap(({ payload: { issueId }, meta: { analyticsEvent } }) => {
				const state = store.getState();
				const panelType = getChildIssuesPanelType(state);

				switch (panelType) {
					case CHILDREN_ISSUES_PANEL:
						return linkNextGenChildIssue(
							issueId,
							state,
							analyticsEvent,
							fieldsValuesActions,
							associatedIssuesContextActions,
						);
					default:
						return Observable.empty<never>();
				}
			});

const linkingIssueRetryEpic =
	(
		fieldsValuesActions?: FieldValueServiceActions,
		associatedIssuesContextActions?: AssociatedIssuesContextActions,
	) =>
	(
		action$: ActionsObservable<LinkingIssueRetryAction>,
		store: MiddlewareAPI<State>,
	): Observable<Action> =>
		action$
			.ofType(LINKING_ISSUE_RETRY)
			.mergeMap(({ payload: { issueId }, meta: { analyticsEvent } }) => {
				const state = store.getState();
				const panelType = getChildIssuesPanelType(state);

				switch (panelType) {
					case CLASSIC_PROJECT_EPIC_CHILDREN: {
						const baseUrl = getBaseUrl(state);
						const parentIssueId = getParentId(state);
						// @ts-expect-error - TS2345 - Type 'ChildIssue | undefined' is not assignable to type 'ChildIssue'.
						const issue: ChildIssue = getIssue(issueId)(state);
						if (!parentIssueId) {
							throw new Error(
								'Attempted to link a classic issue to an epic without a parent issue ID',
							);
						}

						return Observable.from(classicEpicLinkIssue(baseUrl, parentIssueId, issueId))
							.map(() => linkChildSuccess(issueId, issue, analyticsEvent))
							.catch((error) => {
								log.safeErrorWithoutCustomerData(
									'issue.views.common.child-issues-panel.link-child-issue',
									'Failed to link a classic issue to a parent',
									error,
								);
								return Observable.of(linkingIssueRetryFailure(issueId));
							});
					}
					case CHILDREN_ISSUES_PANEL:
						return linkNextGenChildIssue(
							issueId,
							state,
							analyticsEvent,
							fieldsValuesActions,
							associatedIssuesContextActions,
						);
					default:
						return Observable.empty<Action>();
				}
			});

const epics = (
	fieldsValuesActions?: FieldValueServiceActions,
	associatedIssuesContextActions?: AssociatedIssuesContextActions,
) =>
	combineEpics<Action, State>(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		linkingIssueRetryEpic(fieldsValuesActions, associatedIssuesContextActions) as Epic<
			Action,
			State
		>,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		addExistingChildEpic(fieldsValuesActions, associatedIssuesContextActions) as Epic<
			Action,
			State
		>,
	);
export default epics;
