import React, { useCallback } from 'react';
import { styled, css } from '@compiled/react';
import ChevronRightIcon from '@atlaskit/icon/utility/migration/chevron-right';
import { Flex, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import { TruncateWithTooltipOnActive } from '../../../truncate-with-tooltip/truncate-with-tooltip-on-active/index.tsx';
import {
	COMMAND_BREADCRUMB_ITEM_SEPARATOR_TEST_ID,
	COMMAND_BREADCRUMB_ITEM_TEST_ID,
	COMMAND_BREADCRUMB_TEST_ID,
	COMMAND_NAME_TEST_ID,
} from './constants';
import type { RenderCommandNameProps } from './types';

export const RenderCommandName = ({ command, isActive }: RenderCommandNameProps) => {
	const renderCommandName = useCallback(() => {
		if (command.components?.Name) {
			return <command.components.Name isActive={isActive}>{command.name}</command.components.Name>;
		}

		return (
			<TruncateWithTooltipOnActive isActive={isActive}>{command.name}</TruncateWithTooltipOnActive>
		);
	}, [command, isActive]);

	return (
		<CommandNameContainer data-testid={COMMAND_NAME_TEST_ID} active={isActive}>
			{command.breadcrumb?.length ? (
				<CommandBreadcrumbContainer data-testid={COMMAND_BREADCRUMB_TEST_ID}>
					{command.breadcrumb.map((BreadCrumb, index, arr) => (
						<React.Fragment key={index}>
							<CommandBreadcrumbItem
								isParent={index !== arr.length - 1}
								data-testid={COMMAND_BREADCRUMB_ITEM_TEST_ID}
								active={isActive}
							>
								<BreadCrumb isActive={isActive} />
							</CommandBreadcrumbItem>
							{index !== arr.length - 1 &&
								(isVisualRefreshEnabled() ? (
									<Flex
										justifyContent="center"
										xcss={iconWrapperStyles}
										alignItems="center"
										testId={COMMAND_BREADCRUMB_ITEM_SEPARATOR_TEST_ID}
									>
										<ChevronRightIcon color="currentColor" label="separator" />
									</Flex>
								) : (
									<CommandChevronContainer data-testid={COMMAND_BREADCRUMB_ITEM_SEPARATOR_TEST_ID}>
										<ChevronRightIcon color="currentColor" label="separator" />
									</CommandChevronContainer>
								))}
						</React.Fragment>
					))}
				</CommandBreadcrumbContainer>
			) : (
				renderCommandName()
			)}
		</CommandNameContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommandNameContainer = styled.div<{ active?: boolean }>({
	flex: 1,
	minWidth: 0,
	lineHeight: '24px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ active }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		active ? token('color.text', colors.N400) : token('color.text.subtle', colors.N400),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommandBreadcrumbContainer = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommandBreadcrumbItem = styled.div<{ isParent?: boolean; active?: boolean }>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isParent, active }) =>
		!!isParent &&
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css({
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			color: active
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('color.text.subtle', colors.N400)
				: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('color.text.subtlest', colors.N200),
		}),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommandChevronContainer = styled.div({
	lineHeight: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.icon.subtle', colors.N300),
});

const iconWrapperStyles = xcss({
	height: '24px',
	width: '24px',
});
