import { useState, useEffect, useCallback } from 'react';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import {
	getHasSeenQAChangeboardingFromUserPreferences,
	setHasSeenQAChangeboardingInUserPreferences,
} from './utils';

export const useShowSpotlight = () => {
	const accountIdLoggedInUser = useAccountId();
	const [shouldShowSpotlight, setShouldShowSpotlight] = useState(false);

	const setShouldShowSpotlightToState = useCallback(async () => {
		const result = await getHasSeenQAChangeboardingFromUserPreferences(accountIdLoggedInUser);
		setShouldShowSpotlight(!result);
	}, [accountIdLoggedInUser]);

	useEffect(() => {
		setShouldShowSpotlightToState();
	}, [accountIdLoggedInUser, setShouldShowSpotlightToState]);

	const updateShouldShowSpotlight = useCallback(() => {
		setHasSeenQAChangeboardingInUserPreferences(true, accountIdLoggedInUser);
		setShouldShowSpotlight(false);
	}, [accountIdLoggedInUser, setShouldShowSpotlight]);

	return { shouldShowSpotlight, updateShouldShowSpotlight };
};
