// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	retry: {
		id: 'issue.issue-line-card-retry',
		defaultMessage: 'Try again',
		description: 'Text for retry button, to retry linking issue',
	},
	cancel: {
		id: 'issue.issue-line-card-cancel',
		defaultMessage: 'Cancel',
		description: 'Text for cancel button, to cancel linking issue',
	},
});
