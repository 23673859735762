import type { IssueConfiguration } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import { isFieldTypeSupported } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/index.tsx';
import { ISSUE_VIEW_VIEW_TYPE } from '../../../common/constants';

export const getSupportedFieldsCount = (fieldsConfig: IssueConfiguration | null): number => {
	if (!fieldsConfig) {
		return 0;
	}

	return Object.values(fieldsConfig).filter(({ schema }) => {
		const fieldType = schema.custom ?? schema.system;

		return isFieldTypeSupported(ISSUE_VIEW_VIEW_TYPE, fieldType);
	}).length;
};
