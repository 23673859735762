import React from 'react';

import Icon, { type CustomGlyphProps, type IconProps } from '@atlaskit/icon';
import { Box, xcss } from '@atlaskit/primitives';

const wrapperStyles = xcss({
	marginInline: 'space.050',
	height: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

type CustomAgentIconProps = {
	primaryColor?: string;
	excludeOverlay?: boolean;
	width?: string | number;
	height?: string | number;
};

const CustomGlyph = (props: CustomGlyphProps & CustomAgentIconProps) => {
	return (
		<svg width="23" height="26" viewBox="0 0 23 26" fill="none" {...props}>
			<path
				d="M10.512 1.06137C11.1247 0.713259 11.8753 0.713259 12.488 1.06137L21.488 6.17501C22.1136 6.53043 22.5 7.19446 22.5 7.91392V18.0861C22.5 18.8055 22.1136 19.4696 21.488 19.825L12.488 24.9386C11.8753 25.2867 11.1247 25.2867 10.512 24.9386L1.51198 19.825C0.886437 19.4696 0.5 18.8055 0.5 18.0861V7.91392C0.5 7.19446 0.886437 6.53043 1.51198 6.17501L10.512 1.06137Z"
				fill={props.primaryColor || '#0C66E4'}
			/>
			{!props.primaryColor && !props.excludeOverlay && (
				<>
					<path
						d="M15.3711 9.12845C15.7263 9.30173 16.1726 9.00581 16.3843 9.37104C16.1136 9.2564 16.1496 10.7306 16.5959 10.3814C16.6497 10.1521 16.7793 10.1801 16.551 10.1015C16.9344 10.0988 16.5779 9.84556 16.5907 9.80957C16.7998 9.80424 16.5266 9.68161 16.7254 9.72427C16.8408 9.70027 16.5035 9.39637 16.7318 9.47768C16.8049 9.14711 17.1615 9.14976 17.0819 8.94849C17.0858 9.08578 18.2708 9.11778 17.9425 8.59261C17.7347 8.60328 17.7617 8.36068 17.7309 8.67659C17.668 8.28471 17.5283 8.67658 17.4949 8.66725C17.4641 8.44731 17.4026 8.75122 17.4115 8.53662C17.3808 8.42066 17.1807 8.79121 17.2204 8.54861C16.5292 8.36067 17.096 8.15807 16.6972 7.75019C16.6574 7.56092 16.7549 6.94644 16.3278 7.00376C15.7661 7.17171 16.4214 8.22072 16.088 8.40333C15.9392 8.54195 15.7276 8.71656 15.4467 8.59793C15.3082 8.59527 15.13 9.1831 15.3698 9.12979L15.3711 9.12845Z"
						fill="#579DFF"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M15.3218 15.6931L14.5291 14.9004C12.9326 16.1142 11.4765 17.4318 10.1783 18.8662C8.3402 17.3566 6.68445 15.6005 5.18662 13.6303L11.4518 7.36515C12.9665 10.1168 15.0426 12.0855 17.5196 13.4953L15.3218 15.6931ZM10.7452 13.7518L10.7452 15.6016L10.7454 15.6016L11.6857 15.6016L11.6857 13.7518L13.4285 13.7518L11.2154 11.5387L9.00236 13.7518L10.7452 13.7518ZM14.5291 14.9007L15.3217 15.6934L11.2855 19.7296C10.9092 19.4518 10.5403 19.1639 10.1783 18.8665C11.4764 17.4323 12.9325 16.1146 14.5291 14.9007Z"
						fill="#CCE0FF"
					/>
				</>
			)}
		</svg>
	);
};

type CustomIconProps = Omit<IconProps, 'glyph'> & CustomAgentIconProps;

const CustomIcon = (props: CustomIconProps) => {
	return <Icon glyph={(glyphProps) => <CustomGlyph {...props} {...glyphProps} />} {...props} />;
};

export const RovoAgentIcon = (props: CustomIconProps) => {
	return (
		<Box xcss={wrapperStyles}>
			<CustomIcon {...props} />
		</Box>
	);
};
