// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	addAttachment: {
		id: 'issue-field-attachment.command-palette.add-attachment',
		defaultMessage: 'Add attachment',
		description: 'Command name in the command palette to add an attachment to an issue',
	},
	addAUrl: {
		id: 'issue-field-attachment.command-palette.add-a-url',
		defaultMessage: 'Add a URL',
		description: 'Command name in the command palette to add a web link to an issue',
	},
	addChild: {
		id: 'issue-field-attachment.command-palette.add-child',
		defaultMessage: 'Add child',
		description: 'Command name in the command palette to add a child issue to an issue',
	},
	addChildSynonyms: {
		id: 'issue-field-attachment.command-palette.add-child-synonyms',
		defaultMessage: 'Subtask Create',
		description:
			'Synonym words for the command "Add child" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Add child" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},
	linkConfluencePage: {
		id: 'issue-field-attachment.command-palette.link-confluence-page',
		defaultMessage: 'Link Confluence page',
		description: 'Command name in the command palette to link a Confluence page to an issue',
	},
});
