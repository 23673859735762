import React from 'react';
import { CheckboxSelect } from '@atlaskit/select';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import SelectWrapper from '@atlassian/jira-issue-view-common-views/src/select-validation-wrapper/select-validation-wrapper';

export type CheckboxValueItemShape = {
	label: string;
	value: string;
};

type Props = {
	value: CheckboxValueItemShape[];
	options: CheckboxValueItemShape[];
	placeholder: string | null;
	invalidMessage: string | null;
	onChange: (arg1: CheckboxValueItemShape[]) => void;
};

export default function CheckboxFieldView(props: Props) {
	const { invalidMessage, options, placeholder, onChange, value } = props;

	return (
		<SelectWrapper
			validationState={invalidMessage != null ? 'error' : null}
			validationMessage={invalidMessage}
		>
			<CheckboxSelect
				options={options}
				placeholder={placeholder}
				// @ts-expect-error - TS2322 - Type '(arg1: CheckboxValueItemShape[]) => void' is not assignable to type '(value: OptionsType<OptionType>, action: ActionMeta<OptionType>) => void'.
				onChange={onChange}
				value={value}
				tabSelectsValue={false}
				autoFocus
				styles={{
					menuPortal: (base) => ({ ...base, zIndex: layers.modal }),

					menu: (base) => ({ ...base, zIndex: layers.modal }),
				}}
				spacing="compact"
			/>
		</SelectWrapper>
	);
}

CheckboxFieldView.defaultProps = {
	value: [],
	options: [],
	placeholder: null,
	invalidMessage: null,
};

CheckboxFieldView.displayName = 'CheckboxFieldView';
