import uniqBy from 'lodash/uniqBy';
import type { RealtimeRefreshEventDetails } from '@atlassian/jira-issue-refresh-service/src/types.tsx';
import {
	ISSUE_COMMENTED_EVENT,
	ISSUE_UPDATED_EVENT,
} from '@atlassian/jira-realtime/src/common/constants/events.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ChangeDetails, ChangeTypeMap } from '../../types';

const changeTypeMap: ChangeTypeMap = {
	[ISSUE_UPDATED_EVENT]: 'updated',
	[ISSUE_COMMENTED_EVENT]: 'commented',
};

const toChangeDetails = ({
	updateType,
	atlassianId,
}: RealtimeRefreshEventDetails): ChangeDetails => ({
	changeType: changeTypeMap[updateType] || 'updated',
	atlassianId,
});

const getUniqueEvents = (events: RealtimeRefreshEventDetails[]): RealtimeRefreshEventDetails[] =>
	uniqBy(events, (event) => `aid:${event.atlassianId}__ct:${event.updateType}`);

export const transformEvents = ({
	events,
	issueId,
}: {
	events: RealtimeRefreshEventDetails[];
	issueId: IssueId;
}) => {
	const eventsForIssue = events.filter((event) => event.issueId === issueId);
	const uniqueEvents = getUniqueEvents(eventsForIssue);

	if (uniqueEvents.length) {
		const changes = uniqueEvents.map(toChangeDetails);
		const { projectId } = uniqueEvents[0];
		return {
			issueId,
			projectId,
			changes,
		};
	}

	return null;
};
