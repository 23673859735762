// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { Boundary } from '@atlassian/jira-issue-view-common-utils/src/boundary/index.tsx';
import type AssetsOnboardingType from './context/assets-onboarding/assets-onboarding-view';
import type AssetsPanelType from './context/assets-panel/assets-panel-view';

const LazyAssetsPanel = lazyForPaint<typeof AssetsPanelType>(
	() =>
		import(/* webpackChunkName: "async-assets-panel" */ './context/assets-panel/assets-panel-view'),
);

const LazyAssetsOnboarding = lazyForPaint<typeof AssetsOnboardingType>(
	() =>
		import(
			/* webpackChunkName: "async-assets-onboarding" */ './context/assets-onboarding/assets-onboarding-view'
		),
);

export const AssetsPanel = (
	props: JSX.LibraryManagedAttributes<
		typeof AssetsPanelType,
		ComponentProps<typeof AssetsPanelType>
	>,
) => (
	<Boundary id="assets-panel">
		<LazyAssetsPanel {...props} />
	</Boundary>
);

export const AssetsOnboarding = (
	props: JSX.LibraryManagedAttributes<
		typeof AssetsOnboardingType,
		ComponentProps<typeof AssetsOnboardingType>
	>,
) => (
	<Boundary id="assets-onboarding">
		<LazyAssetsOnboarding {...props} />
	</Boundary>
);
