import React, { useRef, useEffect, type ReactNode, useState } from 'react';
import { styled, css } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { useThemeObserver, token } from '@atlaskit/tokens';
import { gridSize, layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { AI_CONTAINER_BORDER_RADIUS, AI_CONTAINER_INNER_BORDER_RADIUS } from '../../constants';

let namespaceUUID = 0;

const newPalette = {
	dark: {
		blue: '#0468FF',
		teal: '#BF63F3',
		yellow: '#FFA900',
	},
	light: {
		blue: '#0468FF',
		teal: '#BF63F3',
		yellow: '#FFA900',
	},
};

const AFTER_LOAD_DURATION = 3000;

const AnimatedSvgContainer = ({
	isLoading,
	isBlurred,
	isShadow,
	isBorderGrey,
	hasNewBorderExperience,
}: {
	isLoading?: boolean;
	isBlurred?: boolean;
	isShadow?: boolean;
	isBorderGrey?: boolean;
	hasNewBorderExperience?: boolean;
}) => {
	const { colorMode } = useThemeObserver();
	const svgRef = useRef<SVGSVGElement>(null);
	const namespaceId = useRef<number>();
	const [showRainbowBorder, setShowRainbowBorder] = useState(false);
	const paletteForTheme = colorMode === 'dark' ? newPalette.dark : newPalette.light;

	if (namespaceId.current === undefined) {
		namespaceId.current = namespaceUUID;
		namespaceUUID += 1;
	}

	useEffect(() => {
		if (isLoading) {
			const svg = svgRef.current;
			hasNewBorderExperience && setShowRainbowBorder(true);
			// Schedule animation to begin before next browser paint
			const beginReq = requestAnimationFrame(() => {
				svg?.querySelectorAll('animate').forEach((node) => node.beginElement());
			});
			return () => {
				// Ensure any pending animation frame is cancelled as the element animation does not end properly if it
				// begins in the same event tick, i.e. isLoading synchronously changes to true then false.
				cancelAnimationFrame(beginReq);
				requestAnimationFrame(() => {
					svg?.querySelectorAll('animate').forEach((node) => node.endElement());
				});
			};
		}

		return undefined;
	}, [isLoading, hasNewBorderExperience]);

	useEffect(() => {
		// we keep the rainbow border without animations for at 3 seconds after load. After that we show the grey border
		if (hasNewBorderExperience && !isLoading && showRainbowBorder) {
			const timeoutId = setTimeout(() => setShowRainbowBorder(false), AFTER_LOAD_DURATION);
			return () => clearTimeout(timeoutId);
		}
	}, [hasNewBorderExperience, showRainbowBorder, isLoading]);

	return (
		<SvgContainer
			isBlurred={isBlurred}
			isShadow={isShadow}
			ref={svgRef}
			viewBox="0 0 24 24"
			preserveAspectRatio="none"
		>
			<defs>
				<linearGradient
					id={`${namespaceId.current}_lg1`}
					gradientUnits="userSpaceOnUse"
					x1="0%"
					y1="0"
					x2="400%"
					y2="0"
					spreadMethod="reflect"
				>
					<animate
						begin="indefinite"
						attributeName="x1"
						from="0%"
						to="400%"
						repeatCount="indefinite"
						dur="3s"
					/>
					<animate
						begin="indefinite"
						attributeName="x2"
						from="400%"
						to="800%"
						repeatCount="indefinite"
						dur="3s"
					/>
					<stop offset="0" stopColor={paletteForTheme.blue} />
					<stop offset="0.25" stopColor={paletteForTheme.teal} />
					<stop offset="0.5" stopColor={paletteForTheme.yellow} />
					<stop offset="0.75" stopColor={paletteForTheme.teal} />
					<stop offset="1" stopColor={paletteForTheme.blue} />
				</linearGradient>
				<linearGradient
					id={`${namespaceId.current}_lg2`}
					gradientUnits="userSpaceOnUse"
					x1="0"
					y1="-100%"
					x2="0"
					y2="300%"
					spreadMethod="reflect"
				>
					<animate
						begin="indefinite"
						attributeName="y1"
						from="-100%"
						to="300%"
						repeatCount="indefinite"
						dur="3s"
					/>
					<animate
						begin="indefinite"
						attributeName="y2"
						from="300%"
						to="700%"
						repeatCount="indefinite"
						dur="3s"
					/>
					<stop offset="0" stopColor={paletteForTheme.blue} />
					<stop offset="0.25" stopColor={paletteForTheme.teal} />
					<stop offset="0.5" stopColor={paletteForTheme.yellow} />
					<stop offset="0.75" stopColor={paletteForTheme.teal} />
					<stop offset="1" stopColor={paletteForTheme.blue} />
				</linearGradient>
				<linearGradient
					id={`${namespaceId.current}_lg3`}
					gradientUnits="userSpaceOnUse"
					x1="300%"
					y1="0"
					x2="700%"
					y2="0"
					spreadMethod="reflect"
				>
					<animate
						begin="indefinite"
						attributeName="x1"
						from="300%"
						to="-100%"
						repeatCount="indefinite"
						dur="3s"
					/>
					<animate
						begin="indefinite"
						attributeName="x2"
						from="700%"
						to="300%"
						repeatCount="indefinite"
						dur="3s"
					/>
					<>
						<stop offset="0" stopColor={paletteForTheme.blue} />
						<stop offset="0.25" stopColor={paletteForTheme.blue} />
						<stop offset="0.5" stopColor={paletteForTheme.yellow} />
						<stop offset="0.75" stopColor={paletteForTheme.blue} />
						<stop offset="1" stopColor={paletteForTheme.blue} />
					</>
				</linearGradient>

				<linearGradient
					id={`${namespaceId.current}_lg4`}
					gradientUnits="userSpaceOnUse"
					x1="0"
					y1="0"
					x2="0"
					y2="400%"
					spreadMethod="reflect"
				>
					<animate
						begin="indefinite"
						attributeName="y1"
						from="0%"
						to="-400%"
						repeatCount="indefinite"
						dur="3s"
					/>
					<animate
						begin="indefinite"
						attributeName="y2"
						from="400%"
						to="0%"
						repeatCount="indefinite"
						dur="3s"
					/>
					<stop offset="0" stopColor={paletteForTheme.blue} />
					<stop offset="0.25" stopColor={paletteForTheme.blue} />
					<stop offset="0.5" stopColor={paletteForTheme.yellow} />
					<stop offset="0.75" stopColor={paletteForTheme.teal} />
					<stop offset="1" stopColor={paletteForTheme.blue} />
				</linearGradient>
			</defs>

			{isBorderGrey || (hasNewBorderExperience && !showRainbowBorder && !isLoading) ? (
				<>
					<g strokeWidth="16">
						<path
							stroke={token('color.background.accent.gray.subtler', '#DFE1E6')}
							d="M0 0h24v24H0Z"
							vectorEffect="non-scaling-stroke"
						/>
					</g>
				</>
			) : (
				<>
					<g strokeWidth="16">
						<path
							stroke={`url(#${namespaceId.current}_lg1)`}
							d="M0 0h24"
							vectorEffect="non-scaling-stroke"
						/>
						<path
							stroke={`url(#${namespaceId.current}_lg2)`}
							d="M24 0v24"
							vectorEffect="non-scaling-stroke"
						/>
						<path
							stroke={`url(#${namespaceId.current}_lg3)`}
							d="M24 24H0"
							vectorEffect="non-scaling-stroke"
						/>
						<path
							stroke={`url(#${namespaceId.current}_lg4)`}
							d="M0 24V0"
							vectorEffect="non-scaling-stroke"
						/>
					</g>
				</>
			)}
		</SvgContainer>
	);
};

type Props = {
	children: ReactNode;
	isLoading?: boolean;
	isShadow?: boolean;
	shouldFitContainer?: boolean;
	spacing?: 'default' | 'none';
	testId?: string;
	isBorderGrey?: boolean;
	components?: {
		ContainerComponent?: React.ComponentType<React.ComponentProps<typeof Container>>;
		ContentComponent?: React.ComponentType<React.ComponentProps<typeof Content>>;
	};
	ref?: React.LegacyRef<HTMLDivElement> | undefined;
	hasNewBorderExperience?: boolean;
};

const AIContainer = ({
	children,
	isLoading,
	isShadow = false,
	shouldFitContainer = false,
	spacing = 'default',
	testId,
	isBorderGrey,
	components: { ContainerComponent = Container, ContentComponent = Content } = {},
	ref,
	hasNewBorderExperience = false,
}: Props) => (
	<ContainerComponent ref={ref} data-testid={testId} shouldFitContainer={shouldFitContainer}>
		<AnimatedSvgContainer
			isShadow={isShadow}
			isLoading={isLoading}
			isBorderGrey={isBorderGrey}
			hasNewBorderExperience={hasNewBorderExperience}
		/>
		{isLoading && <AnimatedSvgContainer isLoading={isLoading} isBlurred />}
		<ContentComponent spacing={spacing}>{children}</ContentComponent>
	</ContainerComponent>
);

export default AIContainer;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ shouldFitContainer: boolean }>({
	display: 'flex',
	position: 'relative',
	padding: token('space.025', '2px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props) => (props.shouldFitContainer ? 'auto' : 'fit-content'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: layers.card,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled.div<{ spacing: 'default' | 'none' }>(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		background: token('elevation.surface', colors.N0),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: `${AI_CONTAINER_INNER_BORDER_RADIUS}px`,
		flexGrow: 1,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		zIndex: layers.card,
		width: '100%',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.spacing === 'default'
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css({
					padding: token('space.100', '8px'),
				})
			: undefined,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const defaultComponents: Required<NonNullable<Props['components']>> = {
	ContainerComponent: Container,
	ContentComponent: Content,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SvgContainer = styled.svg<{
	isBlurred?: boolean;
	isShadow?: boolean;
}>(
	{
		position: 'absolute',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: `${AI_CONTAINER_BORDER_RADIUS}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		boxShadow: (props) =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			props.isShadow ? token('elevation.shadow.overflow', `0px 0px 8px ${colors.N40}`) : 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.isBlurred
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css({
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
					width: `calc(100% - ${gridSize * 2}px)`,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
					height: `calc(100% - ${gridSize * 2}px)`,
					top: token('space.100', '8px'),
					left: token('space.100', '8px'),
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
					filter: `blur(${gridSize * 2}px)`,
				})
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css({
					left: 0,
					top: 0,
					width: '100%',
					height: '100%',
				}),
);
