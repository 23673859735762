import type { View, ViewDescription } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';
import { saveView } from '../save';
import { fireViewUpdatedEvent } from '../utils/analytics';
import { updateViewState } from '../utils/state';
import { currentViewFilter } from '../utils/views';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { loadViewDescription } from './load-view-description';

export const changeViewDescription =
	(
		description: ViewDescription,
		onSuccess?: () => void,
		onError?: (error: Error) => void,
	): Action<State, Props> =>
	({ getState, setState, dispatch }, { currentViewSlug, createAnalyticsEvent }) => {
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			(view: View) => ({ ...view, description, isEditingTitle: false, modified: true }),
		);
		if (changedView) {
			setState({ viewSets });
			dispatch(
				saveView(changedView.id, (view: View | undefined) => {
					if (!view) {
						onError && onError(new Error('No such view'));
						return;
					}

					if (view.saveError && onError) {
						onError(view?.saveError);
						return;
					}

					if (onSuccess) {
						onSuccess();
					}

					fireViewUpdatedEvent(createAnalyticsEvent, changedView, {
						updatedItems: [{ name: 'description' }],
					});
				}),
			);
		}
	};
