import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Responder } from '../../common/types';
import { useRespondersConfig } from '../use-responders-config';

type Props = {
	issueKey: IssueKey;
	onSuccess?: (responders: Responder[] | null) => void;
	onFailure?: (error: Error, esponders: Responder[] | null) => void;
	onSubmit?: (
		oldValue: Responder[] | null,
		newValue: Responder[] | null,
		meta: null,
		event: UIAnalyticsEvent,
	) => void;
};

export const useRespondersField = ({
	issueKey,
	onSuccess,
	onFailure,
	onSubmit,
}: Props): [
	{
		value: Responder[] | null;
		error: Error | null;
	},
	{
		addResponders: (
			valuesToAdd: Responder[] | null,
			expectedValuesAfterAdding: Responder[] | null,
			meta: null,
			analyticsEvent: UIAnalyticsEvent,
		) => Promise<void>;
		removeResponders: (
			valuesToRemove: Responder[] | null,
			expectedValuesAfterRemoving: Responder[] | null,
			meta: null,
			analyticsEvent: UIAnalyticsEvent,
		) => Promise<void>;
		resetError: () => void;
	},
] => {
	const { respondersFieldIdKey: fieldKey, fieldType } = useRespondersConfig(issueKey);

	const [
		{ value, error },
		{ addValues: addResponders, removeValues: removeResponders, resetError },
	] = useEditField<Responder[] | null, null>({
		issueKey,
		fieldKey,
		fieldType,
		initialValue: null,
		onSuccess,
		onFailure,
		onSubmit,
	});

	return [
		{ value, error },
		{ addResponders, removeResponders, resetError },
	];
};
