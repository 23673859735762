import React from 'react';
import { DateWithUrgencyIndicator } from '@atlassian/jira-date-with-urgency-indicator';
import { useIntl } from '@atlassian/jira-intl';
import { useDateTimeFormatter } from '@atlassian/jira-issue-format-date/src/main.tsx';
import type { JiraDatePickerValue, FormatDateFn } from '../../common/types';
import messages from './messages';
import { EmptyValue } from './styled';

export type DateFieldReadViewProps = {
	value: JiraDatePickerValue;
	noValueText: string | null | undefined;
	onFormatDate?: FormatDateFn;
	showUrgencyIndicator?: boolean;
};

const DateFieldReadViewDefault = ({ value, noValueText, onFormatDate }: DateFieldReadViewProps) => {
	const { formatMessage } = useIntl();
	const { formatDateOnly } = useDateTimeFormatter();

	if (value == null || value === '') {
		return <EmptyValue>{noValueText ?? formatMessage(messages.defaultNoValueText)}</EmptyValue>;
	}

	if (onFormatDate) {
		return <>{onFormatDate(value)}</>;
	}

	return <>{formatDateOnly(value)}</>;
};

export const DateFieldReadView = ({
	showUrgencyIndicator,
	value,
	...otherProps
}: DateFieldReadViewProps) => {
	if (showUrgencyIndicator === true && value) {
		const formattedDate = otherProps.onFormatDate ? otherProps.onFormatDate(value) : undefined;
		return <DateWithUrgencyIndicator value={value} formattedDateString={formattedDate} />;
	}
	return <DateFieldReadViewDefault value={value} {...otherProps} />;
};
