import { connect } from '@atlassian/jira-issue-view-react-redux';
import {
	fieldConfirmNewField,
	fieldConfirmFailureNewField,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-save-actions';
import DateField from './main';
import type { Props as OwnProps } from './types';

export default connect(null, (dispatch, ownProps: OwnProps) => ({
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onConfirm: (value: any) => dispatch(fieldConfirmNewField(ownProps.fieldId, value)),
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onError: (value: any) => dispatch(fieldConfirmFailureNewField(ownProps.fieldId, value)),
}))(DateField);
