import React from 'react';
import { styled } from '@compiled/react';
import Badge from '@atlaskit/badge';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	SectionHeadingIcons,
	SectionHeadingTitle,
	SectionHeading,
} from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view';
import AddAttachments from './add-attachment';
import LimitWarning from './limit-warning';
import MeatBalls from './meatballs';
import messages from './messages';
import type { Props } from './types';

export const HeadingTitle = ({ totalCount }: { totalCount: number }) => {
	const { formatMessage } = useIntl();

	return (
		<SectionHeadingWithIcon>
			<SectionHeadingTitle
				/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
				data-testid="issue.views.issue-base.content.attachment.heading.section-heading-title"
			>
				{formatMessage(messages.title, {
					totalCount: '',
				})}
			</SectionHeadingTitle>
			<BadgeWrapper data-testid="issue-view-base.content.attachment.heading.badge">
				<Badge max={false}>{totalCount}</Badge>
			</BadgeWrapper>
			<LimitWarning totalCount={totalCount} />
		</SectionHeadingWithIcon>
	);
};

export const Heading = ({ totalCount, refreshAttachments }: Props) => (
	<SectionHeading>
		<HeadingTitle totalCount={totalCount} />
		<SectionHeadingIcons>
			<MeatBalls refreshAttachments={refreshAttachments} />
			<AddAttachments />
		</SectionHeadingIcons>
	</SectionHeading>
);

export default Heading;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BadgeWrapper = styled.div({
	paddingLeft: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SectionHeadingWithIcon = styled.div({
	display: 'flex',
	alignItems: 'center',
});
