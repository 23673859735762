import React from 'react';
import { styled } from '@compiled/react';
import CancelledIcon from './cancelled';
import FailedIcon from './failed';
import InProgressIcon from './in-progress';
import PendingIcon from './pending';
import RolledBackIcon from './rolled-back';
import SuccessNonProductionIcon from './success-non-production';
import SuccessIcon from './success-production';
import {
	type DeploymentState,
	type IconType,
	type IconSize,
	DeploymentStateOptions,
} from './types';
import UnknownIcon from './unknown';

export const getIcon = (state: DeploymentState): IconType => {
	switch (state) {
		case DeploymentStateOptions.PENDING:
			return PendingIcon;
		case DeploymentStateOptions.IN_PROGRESS:
			return InProgressIcon;
		case DeploymentStateOptions.SUCCESSFUL:
			return SuccessIcon;
		case DeploymentStateOptions.SUCCESSFULNONPRODUCTION:
			return SuccessNonProductionIcon;
		case DeploymentStateOptions.CANCELLED:
			return CancelledIcon;
		case DeploymentStateOptions.FAILED:
			return FailedIcon;
		case DeploymentStateOptions.ROLLED_BACK:
			return RolledBackIcon;
		case DeploymentStateOptions.UNKNOWN:
			return UnknownIcon;
		default:
			throw Error(`Unhandled deployment state - ${state}`);
	}
};
export const Medium = {
	width: '20',
	height: '20',
	padding: '2',
} as const;
export const Small = {
	width: '16',
	height: '16',
	padding: '0',
} as const;
export const getSize = (state: IconSize) => {
	switch (state) {
		case 'small':
			return Small;
		case 'medium':
			return Medium;
		default:
			return Small;
	}
};
type Props = {
	deploymentState: DeploymentState;
	size: IconSize;
};
export const DeploymentIcons = ({ deploymentState, size }: Props) => {
	const Icon = getIcon(deploymentState);
	const iconSize = getSize(size);
	return (
		<Wrapper
			data-testid={`software-deployments-icons-common.ui.wrapper-${deploymentState}`}
			width={iconSize.width}
			height={iconSize.height}
			padding={iconSize.padding}
		>
			<Icon />
		</Wrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div<{
	width: string | number;
	height: string | number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	padding: any;
}>({
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: `${(props: any) => props.width}px`,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: `${(props: any) => props.height}px`,
	margin: 'auto 0',
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: `${(props: any) => props.padding}px`,
});
