import { NOT_ENROLLED, CONTROL } from '@atlassian/jira-common-util-get-experiment-group';
import { ff, getMultivariateFeatureFlag } from '@atlassian/jira-feature-flagging';
import {
	VARIANT_CONTEXTUAL,
	VARIANT_CONTEXTUAL_NOT_ENROLLED,
} from '@atlassian/jira-issue-view-common-constants/src/experiment-constants';

export const isModernGlobalIssueCreateTriggerEnabled = () =>
	ff('project.config.pce.modern.global-issue-create.trigger', false);

export const isModernGlobalSubtaskIssueCreateTriggerEnabled = () =>
	ff('project.config.pce.modern.global-subtask-issue-create-trigger', false);

export const PlaceHolderTemplateFlags = {
	CROSS_FLOW: 'jira.issue-view.placeholder-template-cross-flow',
} as const;

export type PlaceHolderTemplateFlags =
	(typeof PlaceHolderTemplateFlags)[keyof typeof PlaceHolderTemplateFlags];

export const getPlaceHolderTemplateVariant = (experiment: PlaceHolderTemplateFlags): string =>
	experiment
		? getMultivariateFeatureFlag(experiment, NOT_ENROLLED, [
				VARIANT_CONTEXTUAL,
				VARIANT_CONTEXTUAL_NOT_ENROLLED,
				CONTROL,
				NOT_ENROLLED,
			])
		: NOT_ENROLLED;

// Whether the user is in the experiment for the dummy link (control or variant-contextual)
export const isConfluencePageDummyLinkExperiment = () =>
	[CONTROL, VARIANT_CONTEXTUAL].includes(
		getPlaceHolderTemplateVariant(PlaceHolderTemplateFlags.CROSS_FLOW),
	);

// Whether the user is in the dummy link variant
export const isConfluencePageDummyLinkEnabled = (experiment: PlaceHolderTemplateFlags): boolean =>
	getPlaceHolderTemplateVariant(experiment) === VARIANT_CONTEXTUAL;

export const isIssueViewEndUserRequestTrialEnabled = () =>
	ff('issue-view-placeholder-end-user-request-modal_myc7n', false);

export const isCreateLinkedIssueTriggerPointEnabled = () =>
	ff('create-linked-issues-trigger-point-issue-view', false);

export const isModernGICEnabledForSubtask = () =>
	isModernGlobalIssueCreateTriggerEnabled() && isModernGlobalSubtaskIssueCreateTriggerEnabled();

export const isCustomMessageForLinkingWithoutPermissionsEnabled = () =>
	ff('issue.details.custom-message-for-linking-with-no-permissions_k7qsn', false);

export const isSearchByEmailInMultiselectUserPickerEnabled = () =>
	ff('issue-view.enable-search-by-email-in-multiselect-user-picker-field', false);

export const isChangeboardingForParentEnabled = () =>
	ff('parent-field-changeboarding_4ljdy', false);

export const isRespondersFieldAvailableInAllProjectsEnabled = () =>
	ff('responders-field-in-all-projects-issues-ui', false);

export const isHideEpicLinkFromIssueViewForEpicRenamingBetaEnabled = () =>
	ff('hide-epic-link-from-issue-view-for-epic-renaming-beta_r1g77', false);

export const isRemoveParentLinkFromIssueViewEnabled = () =>
	ff('remove-parent-link-from-issue-view_4t7dy', false);

export const isParentInIssueViewEnabled = () => ff('parent-field-in-issue-view_x4zn4', false);

export const isMediaInlineEnabled = () => ff('issue.details.media-inline', false);

export const isSentimentCustomFieldEnabled = () => ff('sentiment-custom-field', false);

export const isItsmAgniAddedAttributeToLinkedIssueAddedEventEnabled = () =>
	ff('itsm-agni-added-attribute-to-linkedissue-added-event', false);
