import React, { Component, useState, useEffect, type PropsWithChildren, type FC } from 'react';
import { styled } from '@compiled/react';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { ACJSHost } from '@atlassian/jira-connect';
import {
	withConnectHost,
	getConnectHost,
} from '@atlassian/jira-connect-utils/src/common/utils/ecosystem-connect-host.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { useIssueKey, useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import ForgeBackgroundScripts from '@atlassian/jira-issue-ecosystem-forge/src/ui/background-scripts/index.tsx';
import type { EcosystemBackgroundScript } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/ecosystem.tsx';
import useConnectBackgroundScripts from '@atlassian/jira-issue-non-critical-gira-service/src/controllers/use-connect-background-scripts/index.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { ecosystemBackgroundScriptsSelector } from '@atlassian/jira-issue-view-store/src/ecosystem/ecosystem-extensions-selector';
import EcosystemModuleWithRefresh, { EcosystemModuleWithoutRefresh } from './ecosystem-module-view';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BackgroundScriptsContainer = styled.div({
	display: 'none',
});

type Props = {
	scripts: EcosystemBackgroundScript[];
};

type State = {
	connectHost: ACJSHost | null;
};

// eslint-disable-next-line jira/react/no-class-components
export class EcosystemBackgroundScriptsView extends Component<Props, State> {
	state = {
		connectHost: null,
	};

	componentDidMount() {
		withConnectHost((connectHost) => this.setState({ connectHost }));
	}

	render() {
		const { connectHost } = this.state;
		const { scripts } = this.props;

		return connectHost ? (
			<BackgroundScriptsContainer>
				{scripts.map(({ appKey, moduleKey, options, shouldReloadOnRefresh }) => {
					const EcosystemModule = shouldReloadOnRefresh
						? EcosystemModuleWithRefresh
						: EcosystemModuleWithoutRefresh;
					const key = `${appKey}-${moduleKey}`;
					return (
						<EcosystemModule
							key={key}
							appKey={appKey}
							moduleKey={moduleKey}
							options={options}
							appName={key}
							location="issueViewBackgroundScript"
						/>
					);
				})}
			</BackgroundScriptsContainer>
		) : null;
	}
}

const ConnectBackgroundScriptsWithProps = flowWithSafeComponent(
	connect(
		(state) => ({
			scripts: ecosystemBackgroundScriptsSelector(state),
		}),
		{},
	),
)(EcosystemBackgroundScriptsView);

// JIV-15751: Rename `FunctionalEcosystemBackgroundScriptsView` to `EcosystemBackgroundScriptsView` when cleaning up issue-view-remove-backgroundscripts-from-critical-fetch_6f6on
export const FunctionalEcosystemBackgroundScriptsView = () => {
	const issueKey = useIssueKey();
	const [{ value: scripts, isReady }] = useConnectBackgroundScripts(issueKey);

	const [connectHost, setConnectHost] = useState<ACJSHost>(getConnectHost());

	useEffect(() => {
		if (!connectHost) {
			log.safeInfoWithoutCustomerData(
				'issue.ecosystem.background.scripts',
				'Delayed initialization of ecosystem background scripts',
			);
			withConnectHost((host) => setConnectHost(host));
		}
	}, [connectHost]);

	return connectHost && isReady ? (
		<BackgroundScriptsContainer>
			{scripts.map(({ appKey, moduleKey, options, shouldReloadOnRefresh }) => {
				const EcosystemModule = shouldReloadOnRefresh
					? EcosystemModuleWithRefresh
					: EcosystemModuleWithoutRefresh;
				const key = `${appKey}-${moduleKey}`;
				return (
					<EcosystemModule
						key={key}
						appKey={appKey}
						moduleKey={moduleKey}
						options={options}
						appName={key}
						location="issueViewBackgroundScript"
					/>
				);
			})}
		</BackgroundScriptsContainer>
	) : null;
};

const EcosystemBackgroundScriptsViewWrapper = componentWithCondition(
	() => ff('issue-view-remove-backgroundscripts-from-critical-fetch_6f6on'),
	FunctionalEcosystemBackgroundScriptsView,
	ConnectBackgroundScriptsWithProps,
);

const PreventUnstableRender: FC<PropsWithChildren> = ({ children }) => {
	// TODO: reconsider placement of this check. It might be better to move it a level above to IssueView.isComplete
	const issueId = useIssueId(); // note: issueKey is stable during transition, but issueId is not
	// hold rendering until issueKey is set
	return issueId ? <>{children}</> : null;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<PreventUnstableRender>
		<ForgeBackgroundScripts />
		<EcosystemBackgroundScriptsViewWrapper />
	</PreventUnstableRender>
);
