import { ISSUE_GLANCE_MODULE } from '@atlassian/jira-forge-ui-constants';
import type { Source } from '@atlassian/jira-forge-ui-types/src/common/types/analytics.tsx';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import { getEventId } from '../../common/utils';
import { fireUiEvent } from '../../common/utils/consumers';
import { forgeModulesConcurrentLoadTime } from '../../common/utils/performance-analytics/main.tsx';
import { MODULE_TYPE_TRIGGER, UI_ACTIONS } from '../../constants';

const triggerEventId = getEventId({
	module: ISSUE_GLANCE_MODULE,
	moduleType: MODULE_TYPE_TRIGGER,
});

export const fireUiTriggerClickedEvent = (
	extensionId: string,
	source?: Source,
	attributes?: Attributes,
) => {
	forgeModulesConcurrentLoadTime(extensionId).start({ startTime: performance.now() });

	return fireUiEvent(UI_ACTIONS.CLICKED, {
		id: triggerEventId,
		source,
		attributes,
	});
};
