import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorEmpty: {
		id: 'issue-field-summary.error-empty',
		defaultMessage: 'You must specify a summary of the issue.',
		description: 'Error message when field is empty before saving.',
	},
	errorTitle: {
		id: 'issue-field-summary.error-title',
		defaultMessage: "We couldn't update the summary",
		description: 'Error message informing the user that updating the field failed',
	},
	errorMessage: {
		id: 'issue-field-summary.error-message',
		defaultMessage: 'You can try again in a few minutes, or refresh the page.',
		description: 'Error message when save fails',
	},
	editButtonLabel: {
		id: 'issue-field-summary.edit-button-label',
		defaultMessage: 'Edit summary',
		description:
			'An accessibility label for an edit button in an input field. Pressing the button allows the user to edit the contents of the input field.',
	},
	extendedEditButtonLabel: {
		id: 'issue-field-summary.extended-edit-button-label',
		defaultMessage: '{inputFieldValue}- edit summary',
		description:
			'An accessibility label for an edit button in an input field. Pressing the button allows the user to edit the contents of the input field.',
	},
});
