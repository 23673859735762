import React, { useCallback, useMemo, useState } from 'react';
import { getATLContextUrl, isFedRamp } from '@atlassian/atl-context';
import {
	SOFTWARE_PROJECT,
	CORE_PROJECT,
	SERVICE_DESK_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	useAIUpsellInEditorRequirements,
	useAIUpsellInEditorFree,
} from '@atlassian/jira-platform-react-hooks-use-ai-upsell-in-editor-free';
import type { ExperimentValue } from '@atlassian/jira-platform-react-hooks-use-ai-upsell-in-editor-free/src/types.tsx';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id';
import { PREMIUM_EDITION, STANDARD_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import type { AIGlobalOptIn, OptInStatus, Product, SharedEditorConfigProps } from './types';
import { OptInModal, OptInUpsellModal } from './ui';
import { AIUpsellPremiumTrialEntryPointContextProvider } from './ui/ai-opt-in-premium-upsell-modal/ai-upsell-premium-trial-entry-context';

const DISABLED = 'disabled';
const DISABLED_OPT_IN = 'disabled-opt-in';
const ENABLED = 'enabled';

/**
 * A hook used for check whether tenant is premium
 * @deprecated for usage only within Smart AI features
 */
export const useIsPremium = () => {
	const appEditions = useAppEditions();

	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);

	if (!appEditions) {
		return false;
	}

	// currently admin hub AI BE endpoint for JWM already defaults to JSW value
	// so we reflect same logic for JWM here
	if (fg('jwm-spork-ai-fix')) {
		switch (projectType) {
			case CORE_PROJECT:
			case SOFTWARE_PROJECT:
				return appEditions.software === PREMIUM_EDITION;
			case SERVICE_DESK_PROJECT:
				return appEditions.serviceDesk === PREMIUM_EDITION;
			default:
				return false;
		}
	}

	switch (projectType) {
		case CORE_PROJECT:
			return appEditions.core === PREMIUM_EDITION;
		case SOFTWARE_PROJECT:
			return appEditions.software === PREMIUM_EDITION;
		case SERVICE_DESK_PROJECT:
			return appEditions.serviceDesk === PREMIUM_EDITION;
		default:
			return false;
	}
};

/**
 * A hook used for check whether tenant is standard (includes standard trial)
 * @deprecated for usage only within Smart AI features
 */
export const useIsStandard = () => {
	// upsell us for standard but not free subscription admins
	const appEditions = useAppEditions();

	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);

	if (!appEditions) {
		return false;
	}

	switch (projectType) {
		case CORE_PROJECT:
			return appEditions.core === STANDARD_EDITION;
		case SOFTWARE_PROJECT:
			return appEditions.software === STANDARD_EDITION;
		case SERVICE_DESK_PROJECT:
			return appEditions.serviceDesk === STANDARD_EDITION;
		default:
			return false;
	}
};

/**
 * A hook used for check whether tenant can opt in to AI
 * Expect the tenant to be premium and org admin
 * @deprecated for usage only within Smart AI features
 */
export const useCanOptIn = () => {
	const isOrgAdmin = useIsSiteAdmin();
	const isPremium = useIsPremium();
	// ensure isSupportedENV is last in the condiction to follow rules for correct exposure - clean comment with jira_jet_admin_config_ai_upsell_isfedramp_check
	const isSupportedENV = fg('jira_jet_admin_config_ai_upsell_isfedramp_check')
		? !isFedRamp()
		: true;

	return isOrgAdmin && isPremium && isSupportedENV;
};

/**
 * A hook used for check whether tenant can be shown an upsell flow to opt in to AI
 * Expect the tenant to be standard and org admin
 * @deprecated for usage only within Smart AI features
 */
export const useCanStartTrial = () => {
	const isOrgAdmin = useIsSiteAdmin();
	const isStandard = useIsStandard();
	// ensure isSupportedENV is last in the condiction to follow rules for correct exposure - clean comment with jira_jet_admin_config_ai_upsell_isfedramp_check
	const isSupportedENV = fg('jira_jet_admin_config_ai_upsell_isfedramp_check')
		? !isFedRamp()
		: true;

	return isOrgAdmin && isStandard && isSupportedENV;
};

/**
 * A hook used for getting the opt-in status for AI.
 * @param { boolean } isAIOptedIn
 * @param { Product  } product
 * @returns { OptInStatus }
 */
export const useOptInStatus = (
	isAIOptedIn: boolean | undefined,
	product: Product | undefined | null,
): OptInStatus => {
	const canOptIn = useCanOptIn();
	const canStartPremiumTrial = useCanStartTrial();

	if (isFedRamp() && fg('jira_jet_admin_config_ai_upsell_isfedramp_check')) {
		return DISABLED;
	}

	// AI Upsell In Editor Experiment - implicitly checks product, fedramp, if edition is FREE and user is an admin
	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [config] = UNSAFE_noExposureExp('jira_free_ai_feature_gate');
	const { experimentValue: aiUpsellInEditorEnabled } =
		config.get<ExperimentValue>('experimentEnabled', undefined) !== undefined
			? // eslint-disable-next-line react-hooks/rules-of-hooks
				useAIUpsellInEditorRequirements(product)
			: { experimentValue: false };

	if (aiUpsellInEditorEnabled) {
		return DISABLED_OPT_IN;
	}

	const isValidForGlobalOptIn = [product !== 'JPD', canOptIn].every(
		(condition) => condition === true,
	);

	const isValidForPremiumTrial = canStartPremiumTrial && product !== 'JPD';

	const globalOptInEnabled =
		isValidForGlobalOptIn || isValidForPremiumTrial ? DISABLED_OPT_IN : DISABLED;

	return isAIOptedIn ? ENABLED : globalOptInEnabled;
};

/**
 * A hook for using AI opt-in.
 * @param { SharedEditorConfigProps }
 * @returns { AIGlobalOptIn }
 */
export const useAIOptIn = ({ editorPluginAIConfig }: SharedEditorConfigProps): AIGlobalOptIn => {
	const { isAiOptInEnabled, product } = editorPluginAIConfig || {};
	const optInStatus = useOptInStatus(isAiOptInEnabled, product);
	const isOrgAdmin = useIsSiteAdmin();
	const [showOptInModal, setShowOptInModal] = useState(false);
	const [showPremiumTrialModal, setShowPremiumTrialModal] = useState(false);
	const { data: orgId } = useOrgId();
	const canStartTrial = useCanStartTrial();

	// AI Upsell In Editor Experiment
	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [config] = UNSAFE_noExposureExp('jira_free_ai_feature_gate');
	const {
		isExperimentEnabled: isAIUpsellInEditorFreeEnabled,
		triggerUpsellFlow: triggerAIUpsellInEditorFreeFlow,
		UpsellModal: AIUpsellInEditorFreeModal,
	} = config.get<ExperimentValue>('experimentEnabled', undefined) !== undefined
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useAIUpsellInEditorFree(optInStatus, product)
		: {
				isExperimentEnabled: false,
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				triggerUpsellFlow: () => {},
				UpsellModal: null,
			};

	// Callback that will be called when AI buttons are clicked and opt in status is disabled-op-in
	const triggerOptInFlow = useCallback(() => {
		setShowOptInModal(true);
	}, [setShowOptInModal]);

	// alt callback for standard admins to trigger a premium upsell in order to opt in
	const triggerUpsellFlow = useCallback(() => {
		setShowPremiumTrialModal(true);
	}, [setShowPremiumTrialModal]);

	const aiOptInModal = useMemo(() => {
		const onCloseModal = () => setShowOptInModal(false);
		const adminLink = `${getATLContextUrl('admin')}/o/${orgId}/atlassian-intelligence`;
		return (
			<OptInModal
				showOptInModal={showOptInModal}
				isOrgAdmin={isOrgAdmin}
				onCloseModal={onCloseModal}
				adminLink={adminLink}
			/>
		);
	}, [orgId, isOrgAdmin, showOptInModal]);

	const aiUpsellModal = useMemo(() => {
		const onCloseModal = () => setShowPremiumTrialModal(false);
		return (
			<AIUpsellPremiumTrialEntryPointContextProvider>
				<OptInUpsellModal
					data-testid="platform-react-hooks-use-ai-opt-in.upsell-modal"
					showPremiumTrialModal={showPremiumTrialModal}
					onCloseModal={onCloseModal}
				/>
			</AIUpsellPremiumTrialEntryPointContextProvider>
		);
	}, [showPremiumTrialModal]);

	if (isFedRamp() && fg('jira_jet_admin_config_ai_upsell_isfedramp_check')) {
		return {
			optInStatus: DISABLED,
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			triggerOptInFlow: () => {},
			AIOptInModal: null,
		};
	}

	if (isAIUpsellInEditorFreeEnabled) {
		return {
			optInStatus,
			triggerOptInFlow: triggerAIUpsellInEditorFreeFlow,
			AIOptInModal: AIUpsellInEditorFreeModal,
		};
	}

	if (optInStatus === DISABLED || optInStatus === ENABLED) {
		return {
			optInStatus,
			triggerOptInFlow,
			AIOptInModal: null,
		};
	}

	if (canStartTrial && optInStatus === DISABLED_OPT_IN) {
		return {
			optInStatus,
			triggerOptInFlow: triggerUpsellFlow,
			AIOptInModal: aiUpsellModal,
		};
	}

	return { optInStatus, triggerOptInFlow, AIOptInModal: aiOptInModal };
};
