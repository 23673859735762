import React, { Fragment } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import type { Command, CommandActionCommandList, NameProps } from '../../types/commands/index.tsx';
import { CommandPaletteBody } from './body';
import { CommandRow } from './command-row';
import { Footer } from './footer';
import { Header } from './header';

type Components = NonNullable<
	Required<CommandActionCommandList['components'] & Command['components']>
>;

const NameComponent = ({ children, ...rest }: NameProps) => {
	if (fg('fix_compal_name_wrapper')) {
		return <>{children}</>;
	}
	return <Fragment {...rest}>{children}</Fragment>;
};

export const components: Components = {
	CommandRow,
	Name: NameComponent,
	LeftIcon: Fragment,
	Wrapper: Fragment,
	Footer,
	Body: CommandPaletteBody,
	Header,
};
