import { type EditorAnalyticsAPI, EVENT_TYPE } from '@atlaskit/editor-common/analytics';
import { findNodePosByLocalIds } from '@atlaskit/editor-common/utils';
import { Fragment } from '@atlaskit/editor-prosemirror/model';
import type { Transaction } from '@atlaskit/editor-prosemirror/state';

import { type EditorAIAnalyticEventPayload } from '../analytics/types';
import { addAnalytics } from '../analytics/utils';
import type { Positions } from '../config-items/config-items';
import { getPMFragmentWithFallback } from '../experience-application/get-pm-fragment-with-fallback';
import { getHasAcceptableUseWarning } from '../experience-application/screens-with-logic/utils/get-has-acceptable-use-warning';
import { findActionItemsConfigItem } from '../prebuilt/config-items-empty/find-action-items/find-action-items';
import { summarizePageConfigItem } from '../prebuilt/config-items-empty/summarize-page/summarize-page';
import type { EditorPluginAIPromptResponseMarkdown, EditorPluginAIProvider } from '../types';

import type { AIPanelDocumentProps, ConvertExtensionProps, GetInitialContextProps } from './types';

export const processStream = async (
	streaming: AsyncGenerator<EditorPluginAIPromptResponseMarkdown, any, unknown>,
	callback: (reason: string, state: string) => void,
) => {
	for await (const streamItem of streaming) {
		if (streamItem.state === 'failed') {
			if ('reason' in streamItem) {
				if (streamItem.reason === 'rate-limited') {
					callback('RATE_LIMIT', streamItem.state);
					continue;
				}
			}
			callback(
				('guard' in streamItem ? streamItem.guard : undefined) ?? 'API_FAILED',
				streamItem.state,
			);
			continue;
		} else if (streamItem.state === 'aup-violation') {
			callback('ACCEPTABLE_USE_VIOLATIONS', streamItem.state);
			continue;
		} else {
			callback(streamItem.data?.content, streamItem.state);
			continue;
		}
	}
};

export const getAIPanelContext = ({
	configItem,
	intentSchemaId,
	editorView,
	providerFactory,
	idMap,
	intl,
	updateIdMap,
}: GetInitialContextProps) => {
	if (!editorView) {
		return;
	}
	const positions: Positions = [
		editorView.state.selection.$from.pos,
		editorView.state.selection.$to.pos,
	];

	const initialContext = configItem.getInitialContext?.({
		editorView,
		positions,
		intl,
		updateIdMap,
		intentSchemaId,
	});

	return {
		schema: editorView.state.schema,
		providerFactory,
		idMap,
		initialContext,
	};
};

export const summarisePage = ({
	editorView,
	generativeAIApiUrl,
	product,
	callback,
	intl,
	initialContext,
	analyticsContext,
}: AIPanelDocumentProps) => {
	if (!editorView) {
		return;
	}

	const promptRequest = summarizePageConfigItem.triggerPromptRequest({
		analyticsContext,
		initialContext: initialContext ?? {
			userLocale: 'en',
			intentSchemaId: 'summarize_intent_schema.json',
			document: editorView.state.doc.toString(),
		},
		editorSchema: editorView.state.schema,
		formatMessage: intl.formatMessage,
		streamingListener: callback,
	});

	const streaming = promptRequest({
		generativeAIApiUrl,
		product,
		abortController: new AbortController(),
	});

	processStream(streaming, callback);
};

export const findActionItems = ({
	editorView,
	generativeAIApiUrl,
	product,
	callback,
	intl,
	initialContext,
	analyticsContext,
}: AIPanelDocumentProps) => {
	if (!editorView) {
		return;
	}

	const promptRequest = findActionItemsConfigItem.triggerPromptRequest({
		analyticsContext,
		initialContext: initialContext ?? {
			userLocale: 'en',
			intentSchemaId: 'action_items_intent_schema.json',
			document: editorView.state.doc.toString(),
		},
		editorSchema: editorView.state.schema,
		formatMessage: intl.formatMessage,
		streamingListener: callback,
	});

	const streaming = promptRequest({
		generativeAIApiUrl,
		product,
		abortController: new AbortController(),
	});

	processStream(streaming, callback);
};

export const regenerateExtension = ({
	api,
	editorView,
	localId,
	extensionKey,
	idMap,
}: ConvertExtensionProps) => {
	const tr = editorView?.state.tr;

	if (editorView && tr) {
		addAnalytics({
			editorState: editorView?.state,
			tr,
			payload: {
				action: 'click',
				actionSubject: 'editorPluginAI',
				actionSubjectId: 'AIPanels',
				eventType: EVENT_TYPE.UI,
				attributes: {
					extensionType: 'com.atlassian.ai-blocks',
					extensionKey: extensionKey,
					action: 'regenerate',
				},
			},
		});
	}

	convertExtension({
		api,
		editorView,
		localId,
		extensionKey,
		idMap,
		tr,
	});
};

export const convertExtension = ({
	api,
	editorView,
	localId,
	extensionKey,
	idMap,
	tr,
	addToHistory,
}: ConvertExtensionProps) => {
	if (!editorView) {
		return;
	}
	const nodes = findNodePosByLocalIds(editorView.state, [localId]);
	if (!tr) {
		tr = editorView.state.tr;
	}

	const nodeToReplace = nodes[0];

	if (!nodeToReplace) {
		return;
	}

	const { pmFragment } = getPMFragmentWithFallback({
		markdown: nodeToReplace.node.attrs.parameters.content,
		schema: editorView.state.schema,
		source: 'ai-blocks',
		fireAIAnalyticsEvent: () => {},
		idMap,
	});

	if (extensionKey.includes('BodiedExtension')) {
		tr = api?.extension?.actions.insertOrReplaceBodiedExtension({
			editorView,
			action: 'replace',
			attrs: {
				...nodeToReplace.node.attrs,
				extensionType: 'com.atlassian.ai-blocks',
				extensionKey,
			},
			content: pmFragment,
			position: nodeToReplace.pos,
			size: nodeToReplace.node.nodeSize,
			tr,
		});

		if (typeof addToHistory === 'boolean') {
			tr!.setMeta('addToHistory', addToHistory);
		}
		editorView.dispatch(tr!);
	} else {
		// We want to delete the original node first and then create the new node and insert it
		// This way if we call regenerate whilst on the extension view, the state will be updated correctly
		// and retrigger the regenerate
		let tr = editorView.state.tr.delete(
			nodeToReplace.pos,
			nodeToReplace.pos + nodeToReplace.node.nodeSize,
		);

		if (typeof addToHistory === 'boolean') {
			tr.setMeta('addToHistory', addToHistory);
		}
		editorView.dispatch(tr);

		tr = api?.extension?.actions.insertOrReplaceExtension({
			editorView,
			action: 'insert',
			attrs: {
				...nodeToReplace.node.attrs,
				extensionType: 'com.atlassian.ai-blocks',
				extensionKey,
				parameters: {
					...nodeToReplace.node.attrs.parameters,
					content: '',
				},
			},
			content: Fragment.from([]),
			position: nodeToReplace.pos,
			size: nodeToReplace.node.nodeSize,
			tr,
		}) as Transaction;

		editorView.dispatch(tr);

		if (typeof addToHistory === 'boolean') {
			tr.setMeta('addToHistory', addToHistory);
		}
		editorView.dispatch(tr);
	}
};

type FeedbackCollectorParams = {
	extensionKey: string;
	markdown: string;
	locale: string;
	editorPluginAIProvider?: EditorPluginAIProvider;
	fireAnalyticsEvent?: EditorAnalyticsAPI['fireAnalyticsEvent'];
};

const trackAnalyticsEvent = (
	fireAnalyticsEvent: EditorAnalyticsAPI['fireAnalyticsEvent'],
	product: EditorPluginAIProvider['product'],
	extensionKey: string,
	productSuccessStatus?: 'submitted' | 'failed' | 'discarded' | 'unknown',
	hasAcceptableUseWarning?: boolean,
) => {
	const payload: EditorAIAnalyticEventPayload = {
		action: 'click',
		actionSubject: 'editorPluginAI',
		actionSubjectId: 'AIPanelsFeedbackButton',
		attributes: {
			extensionKey,
			sentiment: 'unknown',
			hasAcceptableUseWarning,
			appearance: 'unknown',
			product,
			productSuccessStatus,
		},
		eventType: EVENT_TYPE.UI,
	};

	// @ts-ignore
	fireAnalyticsEvent(payload);
};

export const triggerAIPanelFeedbackCollector = async ({
	extensionKey,
	markdown,
	locale,
	editorPluginAIProvider,
	fireAnalyticsEvent,
}: FeedbackCollectorParams) => {
	const hasAcceptableUseWarning = getHasAcceptableUseWarning({
		markdown,
		locale,
	});

	if (!editorPluginAIProvider || !fireAnalyticsEvent) {
		return;
	}

	try {
		const productResponse = await editorPluginAIProvider.handleFeedbackSubmission?.({
			sentiment: 'unknown',
			getAIExperience: (hasUserConsent?: boolean) => ({
				hasAcceptableUseWarning,
				extensionKey,
				...(hasUserConsent ? { markdownResponse: markdown } : {}),
			}),
			editorAttributes: {
				appearance: 'unknown',
				product: editorPluginAIProvider.product,
			},
		});

		trackAnalyticsEvent(
			fireAnalyticsEvent,
			editorPluginAIProvider.product,
			extensionKey,
			productResponse?.status,
			hasAcceptableUseWarning,
		);
	} catch (err) {
		trackAnalyticsEvent(
			fireAnalyticsEvent,
			editorPluginAIProvider.product,
			extensionKey,
			'failed',
			hasAcceptableUseWarning,
		);
	}
};
