import React, { useContext } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import { IssueSmartRequestSummaryEntryPointContext } from '@atlassian/jira-issue-view-smart-request-summary-entrypoint';

const runtimeProps = {};
export const IssueSmartRequestSummaryEntryPointContainer = () => {
	const { entryPointReferenceSubject } = useContext(IssueSmartRequestSummaryEntryPointContext);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="issueSmartRequestSummary"
			packageName="issue"
			teamName="Bento"
			runtimeProps={runtimeProps}
			fallback={null}
			errorFallback="flag"
		/>
	);
};
