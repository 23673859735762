import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useUIModificationsConsentStatus,
	useUIModificationsConsentStatusForApps,
} from '../../adjustments-context/hooks/use-consent-status/index.tsx';
import { UIM_CONSENT_REQUIRED } from '../../adjustments-context/types';
import {
	useFieldIsProcessingOld,
	useFieldIsProcessingV2,
} from '../../issue-adjustments/hooks/use-field-is-processing/index.tsx';

export const useFieldIsBusyOld = (fieldId: string) => {
	const [isBusy] = useFieldIsProcessingOld(fieldId);
	const currentUIModificationsConsentStatus = useUIModificationsConsentStatus();

	if (currentUIModificationsConsentStatus === UIM_CONSENT_REQUIRED) {
		return false;
	}

	return isBusy;
};

/**
 * Hook covers the blocking (loading) UIM functionality:
 * - when UIM error occurred @see `useFieldIsProcessing` it returns false - fields are NOT blocked
 * - scenarios with consent and registering fields: see unit tests.
 */
export const useFieldIsBusyV2 = (fieldId: string) => {
	const [isBusyData] = useFieldIsProcessingV2(fieldId);
	const uimConsentMap = useUIModificationsConsentStatusForApps();
	const { isBusy, appsIds } = isBusyData;

	if (!isBusy) {
		return false;
	}

	return appsIds.some((appId) => uimConsentMap[appId] !== UIM_CONSENT_REQUIRED);
};

export const useFieldIsBusy = functionWithCondition(
	() => fg('ui_modifications_multiple_apps'),
	useFieldIsBusyV2,
	useFieldIsBusyOld,
);
