import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import ListWithPopup from '@atlassian/jira-list-with-popup/src/ui/main.tsx';
import {
	TagItem,
	TagItemPopupContentWrapper,
} from '@atlassian/jira-list-with-popup/src/ui/tag-item/index.tsx';
import { getLabelHref } from '../../../services/transform-strings/utils.tsx';
import messages from './messages';
import type { LabelsTruncatedReadViewProps } from './types';

/**
 * The LabelsTruncatedReadView will show a read only view of labels that is suitable for tight UI spaces.
 *
 * The truncated version displays the first label, plus a button with the number of extra labels, e.g. "[label-1] [+6]"
 *
 * The button triggers a popup with the full list of labels on click.
 *
 * @param props {@link LabelsTruncatedReadViewProps}
 */
export const LabelsTruncatedReadView = ({
	baseSearchUrl,
	fieldId,
	labelValues,
}: LabelsTruncatedReadViewProps) => {
	const { formatMessage } = useIntl();

	if (labelValues.length === 0) {
		return <EmptyFieldText />;
	}

	const items = labelValues.map(({ name }) => ({
		name,
		key: name,
		href: getLabelHref(fieldId, name, baseSearchUrl),
	}));

	return (
		<Box paddingBlock="space.050" xcss={containerStyles}>
			<ListWithPopup
				items={items}
				ItemComponent={TagItem}
				PopupContentWrapper={TagItemPopupContentWrapper}
				showMoreTooltip={formatMessage(messages.showMoreTooltip)}
			/>
		</Box>
	);
};

const containerStyles = xcss({
	maxWidth: '100%',
});
