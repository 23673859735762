import React from 'react';

import debounce from 'lodash/debounce';

import { Fragment, type Node } from '@atlaskit/editor-prosemirror/model';
import type { Selection } from '@atlaskit/editor-prosemirror/state';
import type { DecorationSet, EditorView } from '@atlaskit/editor-prosemirror/view';
import { type MentionNameDetails } from '@atlaskit/mention';
import { convertProsemirrorNodeToMarkdown } from '@atlassian/ai-model-io/convert-prosemirror-to-markdown';
import { usePublish } from '@atlassian/conversation-assistant-pubsub';

import { getPluginState } from '../../../pm-plugins/decoration/decoration-plugin-factory';
import { updatePublish } from '../../../pm-plugins/rovo-agents/commands';
import { type EditorPluginAIProvider } from '../../../types';
import { getMentionMap } from '../../../utils/mentions/get-mention-map';

// See https://developer.mozilla.org/en-US/docs/Web/API/Scheduler/
type Scheduler = {
	postTask: (
		cb: () => void,
		options: {
			priority: 'background';
			delay: number;
		},
	) => Promise<unknown>;
};

const getScheduler = (
	// Our TypeScript configuration isn't ready for the Scheduler API https://developer.mozilla.org/en-US/docs/Web/API/Scheduler/ (yet)
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	obj: any,
): Scheduler | null => {
	if (!obj) {
		return null;
	}

	if ('scheduler' in obj) {
		return obj.scheduler;
	}

	return null;
};

export const getSelectionContent = ({
	modalDecorationSet,
	doc,
}: {
	modalDecorationSet: DecorationSet;
	doc: Node;
}): Fragment | undefined => {
	const existingDecoration = modalDecorationSet
		.find(undefined, undefined, (spec) => spec.key === 'inlineDecoration')
		?.shift();
	const startWidget = modalDecorationSet
		.find(undefined, undefined, (spec) => spec.key === 'generatedStartWidget')
		?.shift()?.from;
	const endWidget = modalDecorationSet
		.find(undefined, undefined, (spec) => spec.key === 'generatedEndWidget')
		?.shift()?.to;

	// If an AI decoration exists, use the content within the decoration range instead
	if (existingDecoration) {
		return doc.slice(existingDecoration.from, existingDecoration.to, true).content;
	}

	if (startWidget && endWidget) {
		return doc.slice(startWidget, endWidget, true).content;
	}

	return;
};

type PublishArgs = {
	doc?: Node;
	selection?: Selection;
};

/**
 *  This component is required so we can call the usePublish hook
 *  and store the publish function in the rovo-agents plugin on mount.
 */
export const PublishToRovo = ({
	editorView,
	getMentionNameDetails,
	editorPluginAIProvider,
}: {
	editorView: EditorView;
	getMentionNameDetails?: (id: string) => Promise<MentionNameDetails | undefined>;
	editorPluginAIProvider: EditorPluginAIProvider;
}) => {
	const scheduler = getScheduler(window);
	const publish = usePublish('ai-mate');
	const { product } = editorPluginAIProvider;

	React.useEffect(() => {
		const publishDocAndSelection = async ({ doc, selection }: PublishArgs) => {
			if (!doc || !selection) {
				publish({
					type: 'editor-context-payload',
					source: 'editor',
					product,
					data: undefined,
				});
				return;
			}

			// Retrieve the modal decoration set from the plugin state to access the selection state,
			// since the editor's selection is cleared when the modal opens.
			const { modalDecorationSet } = getPluginState(editorView.state);

			// Initially, assume the selected content from editorView will be used
			let selectionContent = selection.content().content;

			const selectionContentFromDecorations = getSelectionContent({ modalDecorationSet, doc });

			if (selectionContentFromDecorations) {
				selectionContent = selectionContentFromDecorations;
			}

			let mentionMap = {};
			if (getMentionNameDetails) {
				mentionMap = await getMentionMap({ node: doc, getMentionNameDetails });
			}

			const { markdown: selectionMD } = convertProsemirrorNodeToMarkdown({
				node: Fragment.from(selectionContent),
				featureToggles: {
					markdownPlus: false,
				},
				mentionMap,
			});
			const { markdown: documentMD } = convertProsemirrorNodeToMarkdown({
				node: doc,
				featureToggles: {
					markdownPlus: false,
				},
				mentionMap,
			});

			publish({
				type: 'editor-context-payload',
				source: 'editor',
				product,
				data: {
					document: { type: 'text/markdown', content: documentMD },
					selection: { type: 'text/markdown', content: selectionMD },
				},
			});
		};

		const debouncedPublishDocAndSelection = debounce(async (args: PublishArgs) => {
			if (scheduler) {
				await scheduler.postTask(() => publishDocAndSelection(args), {
					priority: 'background',
					delay: 0,
				});
			} else {
				await publishDocAndSelection(args);
			}
		}, 100);

		// Initial publish on Editor init
		debouncedPublishDocAndSelection({
			doc: editorView.state.doc,
			selection: editorView.state.selection,
		});

		// Save the debounced function to the rovo-agents plugin state
		updatePublish(debouncedPublishDocAndSelection, publish)(editorView.state, editorView.dispatch);
	}, [publish, editorView, getMentionNameDetails, product, scheduler]);

	return <></>;
};
