import memoizeOne from 'memoize-one';
import epicsCache from '@atlassian/jira-cache/src/services/epics/index.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import EpicInlineEditView from '@atlassian/jira-issue-internal-fields/src/epic/view';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field';
import { EPIC_LINK_MAX_RESULTS } from '@atlassian/jira-issue-view-common-views/src/epic-link/constants';
import { getIssueApiUrl } from '@atlassian/jira-issue-view-services/src/issue/issue-urls';
import {
	baseUrlSelector,
	projectKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages';
import {
	transformFromStateValue,
	transformToStateValue,
	transformSuggestionsFromPublicAPIAndCache,
	transformCacheEntries,
} from './transformer';

export const fetchSuggestionsFactory = memoizeOne(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(baseUrl, projectKey, intl) => (query: any, excludeDone: any) => {
		const queryParams = new URLSearchParams({
			excludeDone,
			projectKey,
			maxResults: String(EPIC_LINK_MAX_RESULTS),
			query,
		});

		const url = `${baseUrl}/rest/agile/1.0/epic/search?${queryParams.toString()}`;

		const dataFromServer = fetchJson(url)
			.then((data) => data)
			.catch((error) => {
				throw error;
			});
		return Promise.all([dataFromServer, epicsCache.getAll()]).then(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			([dataFromAPI, cache]: [any, any]) => {
				const dataFromCache = transformCacheEntries(cache, excludeDone);
				return transformSuggestionsFromPublicAPIAndCache(dataFromAPI, dataFromCache, intl);
			},
		);
	},
);

// see https://github.com/facebook/flow/issues/235
type SaveFieldArg = {
	baseUrl: BaseUrl;
	issueKey: IssueKey;
	fieldMetaKey: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any;
};

export default connectField((stateOnMount, ownPropsOnMount) => {
	const getDataFromCache = (excludeDone: boolean) =>
		epicsCache
			.getAll()
			.then((epics) => transformCacheEntries(epics, excludeDone))
			.then((epics) =>
				transformSuggestionsFromPublicAPIAndCache(undefined, epics, ownPropsOnMount.intl),
			);

	return {
		fieldId: ownPropsOnMount.fieldId,
		transformFromStateValue,
		transformToStateValue,
		additionalProps: (state, intl) => ({
			fetchSuggestions: fetchSuggestionsFactory(
				baseUrlSelector(state),
				projectKeySelector(state),
				intl,
			),
			placeholder: intl.formatMessage(messages.placeholder),
			noValueText: intl.formatMessage(genericMessages.noValue),
			allowEmptyValue: true,
			getDataFromCache,
			showPinButton: getShowPinButton(ownPropsOnMount.area),
		}),
		saveField: ({ baseUrl, issueKey, fieldMetaKey, value }: SaveFieldArg) => {
			if (ownPropsOnMount.createAnalyticsEvent && value?.key) {
				fireOperationalAnalytics(ownPropsOnMount.createAnalyticsEvent({}), 'epicPickerCache set', {
					isFromCache: value.fromCache,
				});
				epicsCache.set(value.key, value);
			}
			return performPutRequest(getIssueApiUrl(baseUrl, issueKey), {
				body: JSON.stringify({
					fields: {
						[fieldMetaKey]: value ? value.key : null,
					},
				}),
			}).catch((error) => {
				if (ownPropsOnMount.createAnalyticsEvent && value?.key) {
					fireOperationalAnalytics(
						ownPropsOnMount.createAnalyticsEvent({}),
						'epicPickerCache removed',
					);
					value?.key && epicsCache.remove(value.key);
				}
				throw error;
			});
		},
	};
})(EpicInlineEditView);
