import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { ff } from '@atlassian/jira-feature-flagging';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import { IssueViewAssigneeField } from '@atlassian/jira-issue-view-layout-assignee-field/src/ui/index.tsx';
import { IssueViewReporterField } from '@atlassian/jira-issue-view-layout-reporter-field/src/ui/index.tsx';
import { IssueViewUserField } from '@atlassian/jira-issue-view-layout-user-field/src/ui/index.tsx';
import { ASSIGNEE_TYPE, REPORTER_TYPE, USER_CF_TYPE } from '@atlassian/jira-platform-field-config';
import type { ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField.graphql';

export interface IssueViewSingleSelectUserPickerFieldProps {
	area?: Area;
	fragmentKey: ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField$key;
}

/**
 * If you implement USER_CF_TYPE remember to update
 * getRelayLayoutExclusions in jira/src/packages/issue/issue-view-layout-templates/components/layout-item/src/index.tsx
 */
export const IssueViewSingleSelectUserPickerField = ({
	area,
	fragmentKey,
}: IssueViewSingleSelectUserPickerFieldProps) => {
	const data =
		useFragment<ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField$key>(
			graphql`
				fragment ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField on JiraSingleSelectUserPickerField
				@argumentDefinitions(
					issueViewRelayAssigneeFieldFlag: {
						type: "Boolean!"
						provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-assignee.relayprovider"
					}
					issueViewRelayReporterFieldFlag: {
						type: "Boolean!"
						provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-reporter.relayprovider"
					}
					issueViewRelayUserFieldFlag: {
						type: "Boolean!"
						provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-user.relayprovider"
					}
				) {
					...ui_issueViewLayoutAssigneeField_IssueViewAssigneeField
						@include(if: $issueViewRelayAssigneeFieldFlag)
					...ui_issueViewLayoutReporterField_IssueViewReporterField
						@include(if: $issueViewRelayReporterFieldFlag)
					...ui_issueViewLayoutUserField_IssueViewUserField
						@include(if: $issueViewRelayUserFieldFlag)
					type
				}
			`,
			fragmentKey,
		);

	switch (data.type) {
		case ASSIGNEE_TYPE:
			if (ff('relay-migration-issue-fields-assignee-ld')) {
				return <IssueViewAssigneeField area={area} fragmentKey={data} />;
			}
			return null;

		case REPORTER_TYPE:
			if (ff('relay-migration-issue-fields-reporter_jfzs2')) {
				return <IssueViewReporterField area={area} fragmentKey={data} />;
			}
			return null;

		case USER_CF_TYPE:
			if (ff('relay-migration-issue-fields-user')) {
				return <IssueViewUserField area={area} fragmentKey={data} />;
			}
			return null;
		default:
			return null;
	}
};
