import find from 'lodash/find';
import get from 'lodash/get';
import {
	INWARD_LINK_DIRECTION,
	OUTWARD_LINK_DIRECTION,
	type IssueLinks,
} from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import type {
	ServerCreatedLinkedIssue,
	NormalizedCreatedLinkedIssue,
} from '@atlassian/jira-issue-view-common-types/src/linked-issue-type';
import { getIssueHtmlUrl } from '@atlassian/jira-issue-view-services/src/issue/issue-urls';
import { type BaseUrl, toIssueId, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getLinkDirectionFromSourceIssue = (linkToSourceIssue: any) =>
	linkToSourceIssue.outwardIssue ? INWARD_LINK_DIRECTION : OUTWARD_LINK_DIRECTION;

const normalizeCreatedLinkedIssue = (
	createdLinkedIssue: ServerCreatedLinkedIssue,
	sourceIssueId: string,
): NormalizedCreatedLinkedIssue => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let linkDetails: Record<string, any> = {};

	// Check if there is actually a link between the created issue and the source issue (i.e current issue)
	const linkToSourceIssue = find(
		get(createdLinkedIssue, ['fields', 'issuelinks']),
		(issueLink) => get(issueLink.outwardIssue || issueLink.inwardIssue, 'id') === sourceIssueId,
	);
	if (linkToSourceIssue !== undefined) {
		// The Link direction returned from the endpoint is from the created linked issue's perspective, we need to flip
		// it to get the direction from the source issue's perspective.
		const linkDirectionFromSourceIssue = getLinkDirectionFromSourceIssue(linkToSourceIssue);
		linkDetails = {
			issueLink: {
				id: linkToSourceIssue.id,
				direction: linkDirectionFromSourceIssue,
				linkType: {
					id: get(linkToSourceIssue, ['type', 'id'], ''),
					label:
						linkDirectionFromSourceIssue === INWARD_LINK_DIRECTION
							? get(linkToSourceIssue, ['type', 'inward'], '')
							: get(linkToSourceIssue, ['type', 'outward'], ''),
				},
			},
		};
	}

	// @ts-expect-error - TS2741 - Property 'issueLink' is missing in type '{ id: string; key: string; summary: string; issueTypeIconUrl: string; }' but required in type 'NormalizedCreatedLinkedIssue'.
	return {
		id: toIssueId(createdLinkedIssue.id),
		key: toIssueKey(createdLinkedIssue.key),
		summary: get(createdLinkedIssue, ['fields', 'summary'], ''),
		issueTypeIconUrl: get(createdLinkedIssue, ['fields', 'issuetype', 'iconUrl'], ''),
		...linkDetails,
	};
};

// Transform the created linked issue from the data shape returned from backend, to the data shape which is compatible
// with the issue view's state
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	serverCreatedLinkedIssue: ServerCreatedLinkedIssue,
	sourceIssueId: string,
	baseUrl: BaseUrl,
): IssueLinks => {
	const createdLinkedIssue = normalizeCreatedLinkedIssue(serverCreatedLinkedIssue, sourceIssueId);

	// return empty when the newly created linked issue is not even linked to the current issue.
	if (createdLinkedIssue.issueLink === undefined) {
		return {
			issueLinks: {},
			linkedIssues: {},
		};
	}

	const { issueLink } = createdLinkedIssue;

	return {
		issueLinks: {
			[`${issueLink.id}`]: {
				id: issueLink.id,
				linkedIssueKey: createdLinkedIssue.key,
				direction: issueLink.direction,
				typeName: issueLink.linkType.label,
				typeId: issueLink.linkType.id,
			},
		},
		linkedIssues: {
			[`${createdLinkedIssue.key}`]: {
				id: createdLinkedIssue.id,
				issueKey: createdLinkedIssue.key,
				issueLink: getIssueHtmlUrl(baseUrl, createdLinkedIssue.key),
				issueSummary: createdLinkedIssue.summary,
				issueTypeIconUrl: createdLinkedIssue.issueTypeIconUrl,
			},
		},
	};
};
