import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type {
	AttachmentParent,
	Attachment,
} from '@atlassian/jira-issue-shared-types/src/common/types/attachments';
import { ATTACHMENT_PARENT } from '@atlassian/jira-issue-shared-types/src/common/types/attachments.tsx';
import type { AggResponseData } from '../types/issue-type.tsx';

const logError = (msg: string) =>
	log.safeErrorWithoutCustomerData('issue.agg-transformers.attachments', msg);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isValidParent = (value: any): value is AttachmentParent => value in ATTACHMENT_PARENT;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const checkAttachmentProperties = (node: any) => {
	const properties = ['fileName', 'mediaApiFileId', 'fileSize', 'parentId'];
	properties.forEach((property) => {
		if (!node[property]) {
			logError(
				`Attachment property ${property} was null or undefined for attachment ${node.attachmentId}`,
			);
		}
	});
};

export const transformAggAttachmentsToLegacyGira = (data: AggResponseData): Attachment[] => {
	const edges = data.jira.issueByKey?.attachments?.edges;
	if (!edges) {
		return [];
	}

	return edges.reduce((acc: Attachment[], edge) => {
		const { node } = edge ?? {};
		if (!node) {
			logError('Attachment was null or undefined');
			return acc;
		}

		if (node.__typename !== 'JiraPlatformAttachment') {
			logError(`Invalid Attachment type ${node.__typename}`);
			return acc;
		}

		if (!isValidParent(node.parentName)) {
			logError(`Invalid parent name ${node.parentName} for attachment ${node.attachmentId}`);
			return acc;
		}

		checkAttachmentProperties(node);

		const attachment: Attachment = {
			id: node.attachmentId,
			filename: node.fileName || '',
			mimetype: node.mimeType || undefined,
			mediaApiFileId: node.mediaApiFileId || '',
			createdDate: Math.floor(new Date(node.created).getTime() / 1000),
			filesize: node.fileSize || 0,
			parentName: node.parentName,
			parentId: node.parentId || '',
			author: node.author ? { accountId: node.author.accountId } : null,
			hasRestrictedParent: node.hasRestrictedParent || false,
			isParentCommentJsdInternal: node.hasRestrictedParent || false,
		};

		acc.push(attachment);
		return acc;
	}, []);
};
