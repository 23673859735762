/* eslint-disable @atlassian/relay/unused-fields */
import React, { type ComponentPropsWithoutRef, useCallback } from 'react';
import { useFragment, graphql, useMutation, fetchQuery, useRelayEnvironment } from 'react-relay';
import type { AggGroup } from '@atlassian/jira-issue-field-single-group-picker-editview-full/src/ui/single-group-picker/types';
import type {
	SelectValueShape,
	ServerSuggestions,
} from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types';
import SingleSelectInlineEditView from '@atlassian/jira-issue-internal-field-select/src/single-select-inline-edit';
import type { UserGroup } from '@atlassian/jira-issue-view-base/src/context/group-picker/model/types';
import { transformSuggestions } from '@atlassian/jira-issue-view-base/src/context/group-picker/rest/fetch';
import messages from '@atlassian/jira-issue-view-base/src/context/group-picker/single/messages';
import {
	transformFromStateValue,
	transformToStateValue,
} from '@atlassian/jira-issue-view-base/src/context/group-picker/single/transformer';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import { getDisplayName } from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field';
import {
	useConnectRelayField,
	type ConnectedLayoutProps,
	type PropsCallback,
} from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-relay-field';
import { ConnectedRelayFieldWrapper } from '@atlassian/jira-issue-view-common-views/src/connect-field/relay-field/field-wrapper';
import { mapNodes } from '@atlassian/jira-relay-utils/src/utils/map-nodes';
import type {
	ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField$key,
	ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField$data,
	ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField$key as ComponentsInlineEditFragment,
	ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField$data as ComponentsInlineEditFragmentData,
} from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField.graphql';
import type { ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField_Mutation } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField_Mutation.graphql';
import type { uiGroupPickerFieldSearchRefetchQuery as uiGroupPickerFieldSearchRefetchQueryType } from '@atlassian/jira-relay/src/__generated__/uiGroupPickerFieldSearchRefetchQuery.graphql';

type ComponentValueShape = SelectValueShape | null | undefined;
type AggValueShape = Pick<ComponentsInlineEditFragmentData, 'selectedGroup'>['selectedGroup'];

export type IssueViewGroupPickerFieldProps = ConnectedLayoutProps<ComponentsInlineEditFragment>;

type AdditionalProps = Pick<
	ComponentPropsWithoutRef<typeof SingleSelectInlineEditView>,
	| 'fetchSuggestions'
	| 'placeholder'
	| 'noValueText'
	| 'allowEmptyValue'
	| 'hasAutocomplete'
	| 'showPinButton'
>;

export const toComponentValueShape = (fieldData: AggGroup | null): UserGroup | null => {
	if (!fieldData) {
		return null;
	}

	return {
		name: fieldData.name,
		id: fieldData.id,
		groupId: fieldData.groupId,
	};
};

// TODO:
export const transformComponentValueToAggShape = (componentValue: ComponentValueShape): AggGroup =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	({
		id: componentValue?.id,
		name: componentValue?.value,
		groupId: componentValue?.groupKey,
	}) as AggGroup;

export function IssueViewGroupPickerField(props: IssueViewGroupPickerFieldProps) {
	const environment = useRelayEnvironment();
	const data = useFragment<ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField$key>(
		graphql`
			fragment ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField on JiraSingleGroupPickerField {
				id
				fieldId
				type
				name
				description
				__typename

				fieldConfig {
					isEditable
					isRequired
				}
				issue {
					key
				}
				selectedGroup {
					id
					name
					groupId
				}
			}
		`,
		props.fragmentKey,
	);

	const [commit] =
		useMutation<ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField_Mutation>(graphql`
			mutation ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField_Mutation(
				$input: JiraUpdateSingleGroupPickerFieldInput!
			) @raw_response_type {
				jira {
					updateSingleGroupPickerField(input: $input) @optIn(to: "JiraIssueFieldMutations") {
						success
						errors {
							message
						}
						field {
							id
							selectedGroup {
								id
								groupId
								name
							}
						}
					}
				}
			}
		`);

	const getComponentProps = useCallback<
		PropsCallback<
			ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField$key,
			ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField$data,
			ComponentValueShape,
			AggValueShape,
			AdditionalProps
		>
	>(
		({ intl, softRefreshCallbacks }) => {
			const fetchSuggestions = async (query: string): Promise<ServerSuggestions> => {
				const queryData = await fetchQuery<uiGroupPickerFieldSearchRefetchQueryType>(
					environment,
					graphql`
						query uiGroupPickerFieldSearchRefetchQuery(
							$id: ID!
							$searchBy: String!
							$first: Int!
							$after: String
						) {
							node(id: $id) {
								... on JiraSingleGroupPickerField {
									groups(searchBy: $searchBy, first: $first, after: $after)
										@connection(key: "groupPicker_issueFieldGroupPicker_groups") {
										edges {
											node {
												id
												name
												groupId
											}
										}
									}
								}
							}
						}
					`,
					{
						id: data.id,
						searchBy: query,
						first: 50,
						after: null,
					},
				).toPromise();

				return transformSuggestions({
					groups: mapNodes<AggGroup>(queryData?.node?.groups),
				});
			};

			return {
				jiraIssueField: data,

				value: transformFromStateValue(toComponentValueShape(data.selectedGroup ?? null)),

				onValueConfirm(componentValue) {
					const newAggValue = transformComponentValueToAggShape(componentValue);

					softRefreshCallbacks.onSubmit(newAggValue);

					commit({
						variables: {
							input: {
								id: data.id,
								operation: {
									id: newAggValue.id,
									operation: 'SET',
								},
							},
						},
						onCompleted: (mutationData) => {
							if (mutationData.jira?.updateSingleGroupPickerField?.success) {
								softRefreshCallbacks.onSubmitSucceeded(newAggValue);
							} else {
								softRefreshCallbacks.onSubmitFailed();
							}
						},
						onError() {
							softRefreshCallbacks.onSubmitFailed();
						},
						optimisticResponse: {
							jira: {
								updateSingleGroupPickerField: {
									success: true,
									errors: [],
									field: {
										id: data.id,
										selectedGroup: newAggValue,
									},
								},
							},
						},
					});
				},

				additionalProps: {
					fetchSuggestions,
					placeholder: intl.formatMessage(messages.placeholder),
					noValueText: intl.formatMessage(genericMessages.noValue),
					allowEmptyValue: data.fieldConfig?.isRequired ?? false,
					hasAutocomplete: true,
					showPinButton: getShowPinButton(props.area),
					transformToStateValue,
				},
			};
		},
		[environment, commit, data, props.area],
	);

	const connectField = useConnectRelayField(props, data, getComponentProps);
	const componentName = getDisplayName(SingleSelectInlineEditView);

	return (
		<ConnectedRelayFieldWrapper componentName={componentName}>
			<SingleSelectInlineEditView {...connectField.componentProps} />
		</ConnectedRelayFieldWrapper>
	);
}
