import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type { CustomerServiceCustomDetailsEntityType } from '@atlassian/jira-relay/src/__generated__/tableCustomDetailsQuery.graphql';
import { DetailsErrorState } from '@atlassian/jira-servicedesk-organizations-contacts-common/src/common/ui/details-error-state/index.tsx';
import messages from './messages';

const getDescription = (context: CustomerServiceCustomDetailsEntityType) => {
	switch (context) {
		case 'CUSTOMER':
			return messages.descriptionCustomer;
		case 'ORGANIZATION':
			return messages.descriptionOrganization;
		case 'ENTITLEMENT':
			return messages.descriptionEntitlement;
		default:
			return messages.descriptionCustomer;
	}
};

export const ErrorState = ({ context }: { context: CustomerServiceCustomDetailsEntityType }) => {
	const { formatMessage } = useIntl();

	return <DetailsErrorState description={formatMessage(getDescription(context))} />;
};
