import React, { useCallback, useMemo, useState } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay';
import { ff } from '@atlassian/jira-feature-flagging';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { OriginalEstimateEditView } from '@atlassian/jira-issue-field-original-estimate-editview-full/src/ui/original-estimate/index.tsx';
import { OriginalEstimateReadView } from '@atlassian/jira-issue-field-original-estimate-readview-full/src/ui/original-estimate/index.tsx';
import { type AnalyticsEvent, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type {
	originalEstimate_issueFieldOriginalEstimate_OriginalEstimateField_Mutation as OriginalEstimateMutation,
	originalEstimate_issueFieldOriginalEstimate_OriginalEstimateField_Mutation$data as OriginalEstimateMutationData,
	originalEstimate_issueFieldOriginalEstimate_OriginalEstimateField_Mutation$rawResponse as OriginalEstimateMutationResponse,
} from '@atlassian/jira-relay/src/__generated__/originalEstimate_issueFieldOriginalEstimate_OriginalEstimateField_Mutation.graphql';
import type { originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditView$key as OriginalEstimateFragment } from '@atlassian/jira-relay/src/__generated__/originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditView.graphql';
import { EditViewContainer, InlineEditContainer, ReadViewContainer } from './styled';
import type { ErrorsType, OriginalEstimateInlineEditViewProps } from './types';

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components.
 *
 * @param props [OriginalEstimateInlineEditViewProps](./types.tsx)
 */

export const OriginalEstimateInlineEditView = ({
	fragmentRef,
	isEditing: startWithEditViewOpen = false,
	readViewFitContainerWidth = true,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	onFocus,
	onEdit,
	onCancel,
	onEnter,
}: OriginalEstimateInlineEditViewProps) => {
	// #region Relay
	const data = useFragment<OriginalEstimateFragment>(
		graphql`
			fragment originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditView on JiraTimeTrackingField {
				...originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView
				id
				name
				originalEstimate {
					timeInSeconds
				}
				fieldConfig {
					isEditable
				}
				timeTrackingSettings {
					...originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView
				}
			}
		`,
		fragmentRef,
	);

	const { id: uniqueFieldId, name, fieldConfig, originalEstimate } = data;
	const [commit] = useMutation<OriginalEstimateMutation>(graphql`
		mutation originalEstimate_issueFieldOriginalEstimate_OriginalEstimateField_Mutation(
			$input: JiraUpdateTimeTrackingFieldInput!
		) @raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateTimeTrackingField(input: $input) {
					success
					errors {
						message
					}
					field {
						id
						originalEstimate {
							timeInSeconds
						}
					}
				}
			}
		}
	`);
	// #endregion

	// #region Common state
	const { overriding } = useInlineEditFieldInjections();
	const [isEditing, setIsEditing] = useState<boolean>(startWithEditViewOpen);
	const [newValue, setNewValue] = useState<number | null | undefined>(
		originalEstimate?.timeInSeconds,
	);
	const [invalidMessage, setInvalidMessage] = useState<string | null>(null);

	const isFieldEditable = ff('relay-migration-issue-fields-original-estimate_eb4wv')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useMemo(
				() => overriding.overrideIsEditable(fieldConfig?.isEditable || false),
				[fieldConfig?.isEditable, overriding],
			)
		: fieldConfig?.isEditable || false;

	const fieldName = ff('relay-migration-issue-fields-original-estimate_eb4wv')
		? overriding.overrideLabel(name)
		: name;
	// #endregion

	// #region Handle new value
	const onCancelRequest = useCallback(
		(analyticsEvent: AnalyticsEvent) => {
			setInvalidMessage(null);
			setIsEditing(false);
			fireUIAnalytics(analyticsEvent);
			onCancel && onCancel(analyticsEvent);
		},
		[onCancel],
	);

	const onEditRequest = useCallback(
		(analyticsEvent: AnalyticsEvent) => {
			setInvalidMessage(null);
			setIsEditing(true);
			fireUIAnalytics(analyticsEvent);
			onEdit && onEdit(analyticsEvent);
		},
		[onEdit],
	);

	const onEnterRequest = useCallback(
		(analyticsEvent: AnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent);
			onEnter && onEnter(analyticsEvent);
		},
		[onEnter],
	);

	const onCommitError = useCallback(
		(responseErrors: ErrorsType) => {
			setInvalidMessage(responseErrors?.[0]?.message ?? 'Commit error');
			setIsEditing(true);
			onSubmitFailed?.();
		},
		[onSubmitFailed],
	);

	const onCommitCompleted = useCallback(
		(response: OriginalEstimateMutationData, updatedValue?: number | null) => {
			if (!response.jira?.updateTimeTrackingField) {
				onCommitError([]);
				return;
			}

			const { success, errors: responseErrors, field } = response.jira.updateTimeTrackingField;
			if (!success) {
				onCommitError(responseErrors);
				return;
			}
			setNewValue(field?.originalEstimate?.timeInSeconds);
			onSubmitSucceeded?.(updatedValue);
		},
		[onSubmitSucceeded, onCommitError],
	);

	const handleNewValue = useCallback(
		(updatedValue: number | null, analyticsEvent: AnalyticsEvent) => {
			// Clear errors and exit editing mode
			setInvalidMessage(null);
			setIsEditing(false);
			fireUIAnalytics(analyticsEvent);
			onSubmit?.(updatedValue);
			/**
			 * This will execute the GraphQl mutation.
			 */
			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						originalEstimate: {
							timeInSeconds: updatedValue || 0,
						},
					},
				},
				onCompleted(res) {
					onCommitCompleted(res, updatedValue);
				},
				onError(error) {
					onCommitError([error]);
				},
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				optimisticResponse: {
					jira: {
						updateTimeTrackingField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								originalEstimate: {
									timeInSeconds: updatedValue,
								},
							},
						},
					},
				} as OriginalEstimateMutationResponse,
			});
		},
		[commit, onCommitCompleted, onCommitError, onSubmit, uniqueFieldId],
	);
	// #endregion

	// #region Common callbacks
	const onConfirmRequest = useCallback(
		(analyticsEvent: AnalyticsEvent) => {
			if (!invalidMessage) {
				handleNewValue(newValue || null, analyticsEvent);
			}
		},
		[invalidMessage, handleNewValue, newValue],
	);

	// #endregion

	// #region Read view
	const renderReadView = () => (
		<ReadViewContainer data-testid="issue-field-original-estimate-inline-edit-full.ui.original-estimate.read-view">
			<OriginalEstimateReadView fragmentRef={data} enableHover={isFieldEditable} />
		</ReadViewContainer>
	);
	// #endregion

	// #region Edit view
	const renderEditView = () => (
		<>
			<EditViewContainer data-testid="issue-field-original-estimate-inline-edit-full.ui.original-estimate.edit-view">
				<OriginalEstimateEditView
					initialTimeInSeconds={originalEstimate?.timeInSeconds}
					onChange={setNewValue}
					onFocus={onFocus}
					onChangeInvalidMessage={setInvalidMessage}
					invalidMessage={invalidMessage}
					timeTrackingSettingsfragmentRef={data.timeTrackingSettings || null}
				/>
			</EditViewContainer>
		</>
	);
	// #endregion

	return (
		<InlineEditContainer isEditable={isFieldEditable}>
			<FieldInlineEditStateLess
				defaultValue="0"
				editView={renderEditView}
				isEditable={isFieldEditable}
				isEditing={isEditing}
				isLabelHidden
				label={fieldName}
				readView={renderReadView}
				readViewFitContainerWidth={readViewFitContainerWidth}
				testId="issue-field-original-estimate-inline-edit-full.ui.original-estimate.field-inline-edit-state-less"
				onCancel={onCancelRequest}
				onConfirm={onConfirmRequest}
				onEdit={onEditRequest}
				onEnter={onEnterRequest}
			/>
		</InlineEditContainer>
	);
};
