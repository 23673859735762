// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'issue.single-version-picker.text-for-dropdown-when-nothing-selected',
		defaultMessage: 'Select version',
		description: '',
	},
});
