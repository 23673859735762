import React from 'react';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { FieldsState } from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import {
	type TabItem,
	FIELD_ITEM,
} from '@atlassian/jira-issue-shared-types/src/common/types/layout-item-type.tsx';
import { TAB_PANEL } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import type { TabNode } from '@atlassian/jira-issue-view-common-types/src/tab-type';
import { getFieldType } from '@atlassian/jira-issue-view-common-utils/src/fields/index.tsx';
import { getFieldHandlingSpecialFields } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector';
import getLayoutItemMap from '../../get-layout-item-map';

const logLocation = 'issue.views.issue-base.context.tab-panel';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (tabItems: TabItem[], fields: FieldsState): TabNode[] => {
	const layoutItemMap = getLayoutItemMap();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return tabItems.reduce<Array<any>>((renderedItems, tabItem) => {
		switch (tabItem.type) {
			case FIELD_ITEM: {
				const fieldType = getFieldType(getFieldHandlingSpecialFields(tabItem.id, fields));

				if (!fieldType || !Object.keys(layoutItemMap).includes(fieldType)) {
					log.safeErrorWithoutCustomerData(
						logLocation,
						`Could not find a matching component for field '${
							fieldType || '(unknown type)'
						}' with id '${tabItem.id}'`,
						new Error('Could not find a matching component'),
					);
					return renderedItems;
				}

				const ContextItemComponent = layoutItemMap[fieldType].item;
				const component = (
					<ContextItemComponent key={tabItem.id} fieldId={tabItem.id} area={TAB_PANEL} />
				);

				renderedItems.push({ fieldType, component });
				return renderedItems;
			}

			default:
				log.safeErrorWithoutCustomerData(
					logLocation,
					`Unknown tab item '${JSON.stringify(tabItem)}'`,
					new Error('Unknown tab item'),
				);
				return renderedItems;
		}
	}, []);
};
