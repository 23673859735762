// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholderMulti: {
		id: 'issue.people.text-for-edit-view-when-no-value-is-provided',
		defaultMessage: 'Choose people',
		description: 'This is the placeholder of the user picker in multi mode',
	},
	placeholderSingle: {
		id: 'issue.people.text-for-edit-view-when-no-value-is-provided-single-mode',
		defaultMessage: 'Choose a person',
		description: 'This is the placeholder of the user picker in single mode',
	},
	singleValueValidationError: {
		id: 'issue.people.single-value-validation-error',
		defaultMessage: 'You can only add one person to this field',
		description:
			'This error message will be shown if user tries to add multiple people to a field that allows only one person',
	},
});
