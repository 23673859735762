import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	none: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'issue-view-sentiment-read-view.none',
		defaultMessage: 'None',
		description: 'In case sentiment field value is null.',
	},
});
