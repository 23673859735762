import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { isCommentAttachmentInProgressSelector } from '@atlassian/jira-issue-view-store/src/selectors/comment-selector';
import { CollapsedEditorView, type StateProps } from './view';

const CollapsedEditor = flowWithSafeComponent(
	// @ts-expect-error - Argument of type '<T extends ComponentType<any>, Props = PropsWithoutRef<ComponentProps<T>>>(WrappedComponent: T) => WithUIAnalyticsEvent<Props, { onReplyToCustomer: string; onAddInternalNote: string; }>' is not assignable to parameter of type 'FlowStep<T, ComponentType<Matching<StateProps & {}, Props & { onReplyToCustomer?: ("onReplyToCustomer" extends keyof Props ? Props[keyof Props & "onReplyToCustomer"] : AnalyticsCallback) | undefined; onAddInternalNote?: ("onAddInternalNote" extends keyof Props ? Props[keyof Props & "onAddInternalNote"] : AnalyticsCa...'.
	withFireUiAnalytics({
		onReplyToCustomer: 'onReplyToCustomer',
		onAddInternalNote: 'onAddInternalNote',
	}),
	connect(
		(state: State): StateProps => ({
			isCommentAttachmentInProgress: isCommentAttachmentInProgressSelector(state),
		}),
		{},
	),
);

export default CollapsedEditor(CollapsedEditorView);
