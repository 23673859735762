// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	checkboxLabel: {
		id: 'issue.views.field.epic.checkbox.label',
		defaultMessage: 'Show done epics',
		description: 'Checkbox label. Allows to include done epics in search results.',
	},
	doneEpicLabel: {
		id: 'issue.views.field.epic.done.label',
		defaultMessage: 'Done',
		description: 'Label visible in epics list when epic is marked as done.',
	},
	replacedByParentField: {
		id: 'issue.views.field.epic.replaced.by.parent',
		defaultMessage: 'Replaced by <strong>Parent</strong> field',
		description:
			'Text to display on the Epic Link field indicating that the Epic Field is no longer used and the user should instead use the Parent field',
	},
});
