import React, { memo, useCallback, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import { CardAction } from '@atlaskit/smart-card';
import { HoverCard } from '@atlaskit/smart-card/hover-card';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { useIntl } from '@atlassian/jira-intl';
import IssueKeyWithResolution from '@atlassian/jira-issue-key-with-resolution/src/ui/index.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import { isLeftClickOnly, shouldDisplayStrikethrough } from '../../common/utils';
import messages from './messages';
import type { Props } from './types';

const DATA_TEST_ID = 'issue.issue-view.views.common.issue-line-card.issue-line-card-view.key';

export const IssueKeyOld = memo<Props>(
	({
		issueKey,
		issueLink,
		remoteServerHostname,
		statusCategory,
		hideTooltipOnMouseDown,
		showHoverPreviewEnabled,
		isResolved,
	}: Props) => {
		const { push } = useRouterActions();
		const intl = useIntl();
		const toolTipContent = remoteServerHostname === undefined ? null : remoteServerHostname;

		const handleLinkKeyClick = useCallback(
			(e: MouseEvent) => {
				e.preventDefault();
				if (issueLink != null && isLeftClickOnly(e)) {
					push(issueLink);
				} else {
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					window.open(issueLink, '_blank');
				}
			},
			[issueLink, push],
		);

		const keyContent = (
			<Key
				data-testid={DATA_TEST_ID}
				href={issueLink}
				shouldDisplayStrikethrough={shouldDisplayStrikethrough(isResolved, statusCategory)}
				onClick={handleLinkKeyClick}
				aria-label={intl.formatMessage(messages.issueKeyWithResolution, {
					issueKey,
					isResolved: !!isResolved,
				})}
				/**
				 * Disabling dragging so drags happen on the parent instead of this link.
				 */
				draggable={false}
			>
				{issueKey}
			</Key>
		);

		if (showHoverPreviewEnabled) {
			const normalizedIssueLink = issueLink?.startsWith('/')
				? // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					`${window.location.protocol}//${window.location.hostname}${issueLink}`
				: issueLink;

			return (
				<HoverCard
					url={normalizedIssueLink || ''}
					closeOnChildClick
					actionOptions={{
						hide: false,
						exclude: [CardAction.ChangeStatusAction],
					}}
				>
					{keyContent}
				</HoverCard>
			);
		}

		return (
			<Tooltip
				content={toolTipContent}
				hideTooltipOnMouseDown={hideTooltipOnMouseDown}
				position="bottom"
			>
				{keyContent}
			</Tooltip>
		);
	},
);

export const IssueKeyNew = memo<Props>(
	({
		issueKey,
		issueLink,
		remoteServerHostname,
		hideTooltipOnMouseDown,
		showHoverPreviewEnabled,
		isResolved,
		statusCategory,
	}: Props) => {
		const intl = useIntl();

		const { push } = useRouterActions();
		const tooltipContent = remoteServerHostname === undefined ? null : remoteServerHostname;

		const handleLinkKeyClick = useCallback(
			(e: MouseEvent) => {
				e.preventDefault();
				if (issueLink != null && isLeftClickOnly(e)) {
					push(issueLink);
				} else {
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					window.open(issueLink, '_blank');
				}
			},
			[issueLink, push],
		);

		return (
			<IssueKeyWithResolution
				issueKey={issueKey}
				issueLink={issueLink}
				tooltipContent={tooltipContent}
				hideTooltipOnMouseDown={hideTooltipOnMouseDown}
				showHoverPreviewEnabled={showHoverPreviewEnabled}
				isResolved={isResolved}
				statusCategory={statusCategory}
				onClick={handleLinkKeyClick}
				testId={DATA_TEST_ID}
				label={intl.formatMessage(messages.issueKeyWithResolution, { issueKey, isResolved })}
			/>
		);
	},
);

export const IssueKey = componentWithCondition(
	() => ff('issue-view-a11y-for-issue-key-strikethrough_3xavq'),
	IssueKeyNew,
	IssueKeyOld,
);

export default IssueKey;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Key = styled.a<{ shouldDisplayStrikethrough?: boolean }>({
	outline: 'none',
	marginLeft: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.link', colors.B400),
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.medium'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	textDecoration: (props) => (props.shouldDisplayStrikethrough ? 'line-through' : 'none'),
	whiteSpace: 'nowrap',
});
