import React, { useEffect, useState } from 'react';
import { styled } from '@compiled/react';
import Icon from '@atlaskit/icon';
import PageIcon from '@atlaskit/icon/core/page';
import { SpotlightPulse, SpotlightTarget } from '@atlaskit/onboarding';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { ADD_FORM_ID } from '@atlassian/jira-issue-view-common-constants/src/quick-add-constants';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useSeenStatus } from '@atlassian/jira-servicedesk-itsm-onboarding-common/src/controllers/seen-status/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import type { CreateItemViewType } from '../../types';
import { SPOTLIGHT_TARGET_ID } from '../add-form-spotlight/constants.tsx';
import { AddFormSpotlight } from '../add-form-spotlight/main.tsx';
import FormIcon from '../assets/form-icon';
import messages from './messages';

const PROFORMA_ISSUE_VIEW_ADD_FORM_SEEN_STATUS_USER_PROPERTY_KEY =
	'proforma-issue-view-add-form-seen-status';

export type AddFormScheduleChangeButtonViewProps = {
	createItemView: CreateItemViewType;
	onClick: () => void;
	hasIssueForms?: boolean;
	isQuickActionsListView?: boolean;
};

export const AddFormButton = ({
	createItemView,
	onClick,
	hasIssueForms,
	isQuickActionsListView,
}: AddFormScheduleChangeButtonViewProps) => {
	const { formatMessage } = useIntl();
	const testId = 'add-form-quick-add-item';
	const [{ isSeen: hasSeenSpotlight }, { markAsSeen: markSpotlightAsSeen }] = useSeenStatus({
		userPropertyKey: PROFORMA_ISSUE_VIEW_ADD_FORM_SEEN_STATUS_USER_PROPERTY_KEY,
		isSeenValue: true,
		packageName: 'jiraIssueView',
		teamName: 'jsm-transformers',
	});
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [shouldShowPulse, setShouldShowPulse] = useState(false);
	const [shouldShowSpotlight, setShouldShowSpotlight] = useState(false);

	const [hasSpotlightDisplayed, setHasSpotlightDisplayed] = useState(false);

	useEffect(() => {
		if (hasIssueForms === false && !hasSeenSpotlight && !hasSpotlightDisplayed) {
			setShouldShowPulse(!hasSeenSpotlight);
		}
	}, [hasIssueForms, hasSeenSpotlight, hasSpotlightDisplayed]);

	const onClickWithAnalytics = () => {
		onClick();
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'addFormButton');
	};

	const onDisplaySpotlight = () => {
		if (shouldShowPulse) {
			setShouldShowPulse(false);
			setShouldShowSpotlight(true);
		}
	};

	const onAcknowledgeSpotlight = () => {
		onClickWithAnalytics();
		markSpotlightAsSeen();
		setHasSpotlightDisplayed(true);
		setShouldShowSpotlight(false);
	};

	if (ff('issue_view_quick_actions_revamp_l8mbx') && isQuickActionsListView) {
		return createItemView({
			id: ADD_FORM_ID,
			tooltip: formatMessage(messages.tooltip),
			label: formatMessage(messages.label),
			icon: (
				<>
					{isVisualRefreshEnabled() ? (
						<PageIcon label="" color="currentColor" />
					) : (
						<Icon glyph={FormIcon} label="" size="medium" />
					)}
				</>
			),
			onClick: onClickWithAnalytics,
			itemIndex: 3,
			shouldRender: true,
			noWrapper: false,
			testId,
		});
	}

	return (
		<ButtonWrapper data-testid={testId}>
			<SpotlightTarget name={SPOTLIGHT_TARGET_ID} key="add-form-button">
				<SpotlightPulse radius={3} pulse={shouldShowPulse}>
					{createItemView({
						id: ADD_FORM_ID,
						tooltip: formatMessage(messages.tooltip),
						label: formatMessage(messages.label),
						icon: (
							<>
								{!isVisualRefreshEnabled() ? (
									<PageIcon label="" color="currentColor" />
								) : (
									<Icon glyph={FormIcon} label="" size="medium" />
								)}
							</>
						),
						onClick: hasIssueForms || hasSeenSpotlight ? onClickWithAnalytics : onDisplaySpotlight,
						itemIndex: 3,
						shouldRender: true,
						noWrapper: true,
						testId,
					})}
				</SpotlightPulse>
				{shouldShowSpotlight && <AddFormSpotlight onAcknowledge={onAcknowledgeSpotlight} />}
			</SpotlightTarget>
		</ButtonWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWrapper = styled.span({
	marginRight: token('space.100', '8px'),
});
