import type { UIAnalyticsEvent, AnalyticsEventPayload } from '@atlaskit/analytics-next';
import type { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { AssociatedIssue } from '@atlassian/jira-issue-shared-types/src/common/types/associated-issue-type.tsx';
import type { FieldsState } from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import type { ChildIssueType } from '@atlassian/jira-issue-view-common-types/src/child-issue-type';
import type { ChildIssue } from '@atlassian/jira-issue-view-common-types/src/children-issues-type';
import type { IssueId, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { OptimisticUiChildIssue } from '../../model/types';

// SET_ENTITIES
export const SET_ENTITIES = 'state.entities.SET_ENTITIES' as const;
type SetEntitiesPayload = {
	issues: ChildIssue[];
	issueTypes: ChildIssueType[];
	issueHierarchyLevel?: number | undefined;
};

export const setEntities = (payload: SetEntitiesPayload) => ({
	type: SET_ENTITIES,
	payload,
});

export type SetEntitiesAction = ReturnType<typeof setEntities>;

// UPDATE_ISSUE
export const UPDATE_ISSUE = 'state.entities.UPDATE_ISSUE' as const;

export const updateIssue = (payload: ChildIssue) => ({
	type: UPDATE_ISSUE,
	payload,
});

export type UpdateIssueAction = ReturnType<typeof updateIssue>;

// CREATE CHILD REQUEST
export const CREATE_CHILD_REQUEST = 'state.entities.CREATE_CHILD_REQUEST' as const;

export const createChildRequest = (
	optimisticUiChildIssue: OptimisticUiChildIssue,
	analyticsEvent: UIAnalyticsEvent,
) => ({
	type: CREATE_CHILD_REQUEST,
	payload: { optimisticUiChildIssue },
	meta: { analyticsEvent },
});

export type CreateChildRequestAction = ReturnType<typeof createChildRequest>;

// CREATE CHILD SUCCESS
export const CREATE_CHILD_SUCCESS = 'state.entities.CREATE_CHILD_SUCCESS' as const;

export const createChildSuccess = (
	optimisticId: string,
	createdChild: ChildIssue,
	analyticsEvent: UIAnalyticsEvent,
	usedGlobalCreateDialog = false,
	additionalAttributes: AnalyticsEventPayload['attributes'] = {},
) => ({
	type: CREATE_CHILD_SUCCESS,
	payload: { optimisticId, createdChild, usedGlobalCreateDialog },
	meta: { analyticsEvent, additionalAttributes },
});

export type CreateChildSuccessAction = ReturnType<typeof createChildSuccess>;

// CREATE CHILD FAILURE
export const CREATE_CHILD_FAILURE = 'state.entities.CREATE_CHILD_FAILURE' as const;

export const createChildFailure = (optimisticId: string, error: ValidationError | Error) => ({
	type: CREATE_CHILD_FAILURE,
	payload: { optimisticId, error },
});

export type CreateChildFailureAction = ReturnType<typeof createChildFailure>;

// LINK CHILD SUCCESS
export const LINK_CHILD_SUCCESS = 'state.entities.LINK_CHILD_SUCCESS' as const;

export const linkChildSuccess = (
	issueId: IssueId,
	linkedChild: ChildIssue,
	analyticsEvent: UIAnalyticsEvent,
) => ({
	type: LINK_CHILD_SUCCESS,
	payload: { issueId, linkedChild },
	meta: { analyticsEvent },
});

export type LinkChildSuccessAction = ReturnType<typeof linkChildSuccess>;

// LINKING ISSUE FAILURE
export const LINKING_ISSUE_FAILURE = 'state.entities.LINKING_ISSUE_FAILURE' as const;

export const linkingIssueFailure = (
	createdChild: ChildIssue,
	optimisticId: string,
	error: Error,
) => ({
	type: LINKING_ISSUE_FAILURE,
	payload: {
		createdChild,
		optimisticId,
		error,
	},
});

export type LinkingIssueFailureAction = ReturnType<typeof linkingIssueFailure>;

// LINKING ISSUE RETRY
export const LINKING_ISSUE_RETRY = 'state.entities.LINKING_ISSUE_RETRY' as const;

export const linkingIssueRetry = (issueId: IssueId, analyticsEvent: UIAnalyticsEvent) => ({
	type: LINKING_ISSUE_RETRY,
	payload: { issueId },
	meta: { analyticsEvent },
});

export type LinkingIssueRetryAction = ReturnType<typeof linkingIssueRetry>;

// LINKING ISSUE RETRY FAILURE
export const LINKING_ISSUE_RETRY_FAILURE = 'state.entities.LINKING_ISSUE_RETRY_FAILURE' as const;

export const linkingIssueRetryFailure = (issueId: IssueId) => ({
	type: LINKING_ISSUE_RETRY_FAILURE,
	payload: { issueId },
});

export type LinkingIssueRetryFailureAction = ReturnType<typeof linkingIssueRetryFailure>;

// CREATE CHILD RETRY
export const CREATE_CHILD_RETRY = 'state.entities.CREATE_CHILD_RETRY' as const;

export const createChildRetry = (optimisticId: string, analyticsEvent: UIAnalyticsEvent) => ({
	type: CREATE_CHILD_RETRY,
	payload: { optimisticId },
	meta: { analyticsEvent },
});

export type CreateChildRetryAction = ReturnType<typeof createChildRetry>;

// LINK PARENT FAILURE
export const LINK_PARENT_FAILURE = 'state.entities.LINK_PARENT_FAILURE' as const;

export const linkParentFailure = (optimisticId: string, error: Error) => ({
	type: LINK_PARENT_FAILURE,
	payload: { optimisticId, error },
});

export type LinkParentFailureAction = ReturnType<typeof linkParentFailure>;

// CHILD CREATED IN FULL DIALOG
export const CHILD_CREATED_IN_FULL_DIALOG = 'state.entities.CHILD_CREATED_IN_FULL_DIALOG' as const;

export const childCreatedInFullDialog = (
	optimisticId: string,
	createdChild: ChildIssue,
	analyticsEvent: UIAnalyticsEvent,
) => ({
	type: CHILD_CREATED_IN_FULL_DIALOG,
	payload: { optimisticId, createdChild },
	meta: { analyticsEvent },
});

export type ChildCreatedInFullDialogAction = ReturnType<typeof childCreatedInFullDialog>;

// FETCH SINGLE CHILD SUCCESS
export const FETCH_SINGLE_CHILD_SUCCESS = 'state.entities.FETCH_SINGLE_CHILD_SUCCESS' as const;

export const fetchSingleChildSuccess = (childIssue: ChildIssue) => ({
	type: FETCH_SINGLE_CHILD_SUCCESS,
	payload: { childIssue },
});

export type FetchSingleChildSuccessAction = ReturnType<typeof fetchSingleChildSuccess>;

// FETCH GRAPHQL ISSUE SUCCESS
export const FETCH_GRAPHQL_ISSUE_SUCCESS = 'state.entities.FETCH_GRAPHQL_ISSUE_SUCCESS' as const;
export type FetchGraphqlIssueSuccessPayload = {
	childIssue: ChildIssue;
	fieldsData: FieldsState;
};
export const fetchGraphqlIssueSuccess = (payload: FetchGraphqlIssueSuccessPayload) => ({
	type: FETCH_GRAPHQL_ISSUE_SUCCESS,
	payload,
});

export type FetchGraphqlIssueSuccessAction = ReturnType<typeof fetchGraphqlIssueSuccess>;

// CANCEL OPTIMISTIC CHILD
export const CANCEL_OPTIMISTIC_CHILD = 'state.entities.CANCEL_OPTIMISTIC_CHILD' as const;

export const cancelOptimisticChild = (optimisticId: string) => ({
	type: CANCEL_OPTIMISTIC_CHILD,
	payload: { optimisticId },
});

export type CancelOptimisticChildAction = ReturnType<typeof cancelOptimisticChild>;

// FETCH DETAILS FOR ISSUES REQUEST
export const FETCH_DETAILS_FOR_ISSUES_REQUEST =
	'state.entities.FETCH_DETAILS_FOR_ISSUES_REQUEST' as const;

export const fetchDetailsForIssuesRequest = () => ({
	type: FETCH_DETAILS_FOR_ISSUES_REQUEST,
});

export type FetchDetailsForIssuesRequestAction = ReturnType<typeof fetchDetailsForIssuesRequest>;

// FETCH DETAILS FOR ISSUES SUCCESS
export const FETCH_DETAILS_FOR_ISSUES_SUCCESS =
	'state.entities.FETCH_DETAILS_FOR_ISSUES_SUCCESS' as const;

export const fetchDetailsForIssuesSuccess = (issues: AssociatedIssue[]) => ({
	type: FETCH_DETAILS_FOR_ISSUES_SUCCESS,
	payload: { issues },
});

export type FetchDetailsForIssuesSuccessAction = ReturnType<typeof fetchDetailsForIssuesSuccess>;

// REORDER CHILDREN REQUEST
export const REORDER_CHILDREN_REQUEST = 'state.entities.REORDER_CHILDREN_REQUEST' as const;

export type ReorderChildrenRequestPayload = {
	currentOrder: ChildIssue[];
	newOrder: ChildIssue[];
	currentIndex: number;
	newIndex: number;
};

export const reorderChildrenRequest = (
	currentOrder: ChildIssue[],
	newOrder: ChildIssue[],
	currentIndex: number,
	newIndex: number,
	analyticsEvent: UIAnalyticsEvent,
) => ({
	type: REORDER_CHILDREN_REQUEST,
	payload: {
		currentOrder,
		newOrder,
		currentIndex,
		newIndex,
	},
	meta: { analyticsEvent },
});

export type ReorderChildrenRequestAction = ReturnType<typeof reorderChildrenRequest>;

// REORDER CHILDREN FAILED
export const REORDER_CHILDREN_FAILED = 'state.entities.REORDER_CHILDREN_FAILED' as const;

export const reorderChildrenFailed = (
	originalOrder: ChildIssue[],
	newOrder: ChildIssue[],
	originalIndex: number,
	newIndex: number,
) => ({
	type: REORDER_CHILDREN_FAILED,
	payload: {
		originalOrder,
		newOrder,
		originalIndex,
		newIndex,
	},
});

export type ReorderChildrenFailedAction = ReturnType<typeof reorderChildrenFailed>;

// REORDER CHILDREN SUCCESS
export const REORDER_CHILDREN_SUCCESS = 'state.entities.REORDER_CHILDREN_SUCCESS' as const;

export const reorderChildrenSuccess = (newOrder: ChildIssue[]) => ({
	type: REORDER_CHILDREN_SUCCESS,
	payload: {
		newOrder,
	},
});

export type ReorderChildrenSuccessAction = ReturnType<typeof reorderChildrenSuccess>;

// Add existing child issue
export const ADD_EXISTING_CHILD_ISSUE_REQUEST =
	'state.entities.ADD_EXISTING_CHILD_ISSUE_REQUEST' as const;

export const addExistingChildIssueRequest = (
	issue: {
		issueId: IssueId;
		issueKey: IssueKey;
		issueSummary: string;
		issueTypeIconUrl: string;
	},
	analyticsEvent: UIAnalyticsEvent,
) => ({
	type: ADD_EXISTING_CHILD_ISSUE_REQUEST,
	payload: issue,
	meta: { analyticsEvent },
});

export type AddExistingChildIssueRequestAction = ReturnType<typeof addExistingChildIssueRequest>;

export type Action =
	| SetEntitiesAction
	| UpdateIssueAction
	| CreateChildRequestAction
	| CreateChildSuccessAction
	| CreateChildFailureAction
	| LinkChildSuccessAction
	| LinkingIssueFailureAction
	| LinkingIssueRetryAction
	| LinkingIssueRetryFailureAction
	| CreateChildRetryAction
	| LinkParentFailureAction
	| ChildCreatedInFullDialogAction
	| FetchSingleChildSuccessAction
	| FetchGraphqlIssueSuccessAction
	| CancelOptimisticChildAction
	| FetchDetailsForIssuesRequestAction
	| FetchDetailsForIssuesSuccessAction
	| ReorderChildrenRequestAction
	| ReorderChildrenFailedAction
	| ReorderChildrenSuccessAction
	| AddExistingChildIssueRequestAction;
