import React from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import SquareAddIconButton from '@atlassian/jira-issue-view-common-views/src/button/square-add-icon-button';
import messages from './messages';

type Props = {
	onClick: () => void;
	intl: IntlShape;
};

export function AddIssueLinkButton(props: Props) {
	return (
		<RightSideContainer
			// eslint-disable-next-line jira/integration/test-id-by-folder-structure
			data-testid="issue.views.issue-base.content.issue-links.add.add-button.right-side-container"
		>
			<SquareAddIconButton
				label={props.intl.formatMessage(messages.label)}
				onClick={props.onClick}
			/>
		</RightSideContainer>
	);
}

AddIssueLinkButton.defaultProps = {
	onClick: noop,
};

export default injectIntl(AddIssueLinkButton);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RightSideContainer = styled.span({
	marginLeft: 'auto',
});
