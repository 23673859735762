import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type PrimaryIncident from './index';

const PrimaryIncidentAsync = lazyForPaint<typeof PrimaryIncident>(
	() => import(/* webpackChunkName: "async-pir-primary-incident" */ './index'),
);

export const AsyncPrimaryIncident = () => (
	<Placeholder name="pir-primary-incident" fallback={null}>
		<PrimaryIncidentAsync />
	</Placeholder>
);
