import React from 'react';
import type { ReadViewProps } from '../../types';

export const TextFieldReadView = ({
	value,
	render,
	getValueContainer,
	inInlineEdit,
}: ReadViewProps) => {
	const ValueContainer = getValueContainer();
	return <ValueContainer render={render || (() => value)} noBorder={inInlineEdit} />;
};
