/**
 * @generated SignedSource<<41e6f1c787deb115b93c1bd579131ec5>>
 * @relayHash d582faf9140d1c64886a34a76cad2636
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 1047274ccaf133028e08dd9784ea52fda07044e4cf4deb56d87a25b9dc4189fd

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type cascadingSelect_cascadingSelectSearchQuery$variables = {
  id: string;
  isSelectedParentIdSet: boolean;
  selectedParentId: string;
};
export type cascadingSelect_cascadingSelectSearchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"cascadingSelect_issueFieldCascadingSelectEditviewFull_CascadingSelectEditViewWithFieldOptionsFragment">;
};
export type cascadingSelect_cascadingSelectSearchQuery = {
  response: cascadingSelect_cascadingSelectSearchQuery$data;
  variables: cascadingSelect_cascadingSelectSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isSelectedParentIdSet"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "selectedParentId"
},
v3 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "id"
},
v4 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 50
  },
  {
    "kind": "Literal",
    "name": "searchBy",
    "value": ""
  }
],
v6 = {
  "kind": "ScalarField",
  "name": "id"
},
v7 = {
  "kind": "ScalarField",
  "name": "value"
},
v8 = {
  "kind": "ScalarField",
  "name": "isDisabled"
},
v9 = {
  "concreteType": "JiraOptionConnection",
  "kind": "LinkedField",
  "name": "childOptions",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraOptionEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraOption",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/)
          ]
        }
      ]
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "cascadingSelect_cascadingSelectSearchQuery",
    "selections": [
      {
        "args": [
          (v3/*: any*/),
          {
            "kind": "Variable",
            "name": "isSelectedParentIdSet",
            "variableName": "isSelectedParentIdSet"
          },
          {
            "kind": "Variable",
            "name": "selectedParentId",
            "variableName": "selectedParentId"
          }
        ],
        "kind": "FragmentSpread",
        "name": "cascadingSelect_issueFieldCascadingSelectEditviewFull_CascadingSelectEditViewWithFieldOptionsFragment"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "cascadingSelect_cascadingSelectSearchQuery",
    "selections": [
      {
        "args": [
          (v3/*: any*/)
        ],
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v5/*: any*/),
                "concreteType": "JiraParentOptionConnection",
                "kind": "LinkedField",
                "name": "parentOptions",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraParentOptionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraParentOption",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v4/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "cursor"
                      }
                    ]
                  },
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                      }
                    ]
                  }
                ],
                "storageKey": "parentOptions(first:50,searchBy:\"\")"
              },
              {
                "args": (v5/*: any*/),
                "filters": [
                  "searchBy",
                  "filterById"
                ],
                "handle": "connection",
                "key": "cascadingSelect_issueFieldCascadingSelectEditviewFull_parentOptions",
                "kind": "LinkedHandle",
                "name": "parentOptions"
              }
            ],
            "type": "JiraCascadingSelectField"
          },
          (v6/*: any*/)
        ]
      },
      {
        "condition": "isSelectedParentIdSet",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "selectedParentNode",
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "selectedParentId"
              }
            ],
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v6/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "type": "JiraParentOption"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "1047274ccaf133028e08dd9784ea52fda07044e4cf4deb56d87a25b9dc4189fd",
    "metadata": {},
    "name": "cascadingSelect_cascadingSelectSearchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "09926f10aa4a76f28717df980aab41de";

export default node;
