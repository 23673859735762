import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { resetState } from '../../main';
import type { State } from '../../types';

export const openViewModal =
	() =>
	({ getState, setState }: StoreActionApi<State>) => {
		resetState(
			{
				...getState(),
				isViewModalOpen: true,
			},
			setState,
		);
	};

export const closeViewModal =
	() =>
	({ getState, setState }: StoreActionApi<State>) => {
		resetState(
			{
				...getState(),
				isViewModalOpen: false,
			},
			setState,
		);
	};
