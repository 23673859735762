import type { AvailableSiteResponse, ConfluenceFetchResponse } from './types';
import { fetchWithRetry } from './index';

export const getAvailableSites = async (): Promise<
	ConfluenceFetchResponse<AvailableSiteResponse>
> =>
	fetchWithRetry<AvailableSiteResponse>({
		url: '/gateway/api/available-sites',
		options: {
			method: 'POST',
			credentials: 'include',
			headers: {
				Accept: 'application/json',
				'Cache-Control': 'no-cache',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				products: ['confluence.ondemand'],
			}),
		},
		shouldRetryOnApiError: false,
	});
