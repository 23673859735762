import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type { OrganizationOption } from '../../common/types';
import { Header } from '../../common/ui/details';
import { Organization } from '../../common/ui/organization';
import messages from './messages';

export const Organizations = ({ fieldValue }: { fieldValue: OrganizationOption[] | null }) => {
	const numOrganizations = fieldValue ? fieldValue.length : 0;
	const { formatMessage } = useIntl();
	return fieldValue && numOrganizations !== 0 ? (
		<>
			<Header>{formatMessage(messages.organizations)}</Header>
			{fieldValue.map((org: OrganizationOption, idx: number) => (
				<Organization
					organization={org}
					key={org.id}
					isLast={idx === numOrganizations - 1}
					panelSource="ORGANIZATIONS_FIELD"
				/>
			))}
		</>
	) : null;
};
