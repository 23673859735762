import React from 'react';
import { styled } from '@compiled/react';
import { Inline, Box, xcss } from '@atlaskit/primitives';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsAiOptInEnabled } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { IssueViewRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { useShouldBeCompact } from '@atlassian/jira-issue-hooks/src/services/use-should-be-compact';
import { ConnectedImproveIssueQuickAddItemWithStores } from '@atlassian/jira-issue-improve-issue-dropdown/src/index.tsx';
import withContainerWidth from '@atlassian/jira-issue-view-common-utils/src/with-container-width';
import StatusField from '@atlassian/jira-issue-view-foundation/src/status/view.tsx';
import { useIsPremium } from '@atlassian/jira-platform-react-hooks-use-ai-opt-in';

const OldFoundationContext = ({
	issueViewRelayFragment,
}: {
	// TODO Decomp BENTO-12514 - add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment?: IssueViewRelayFragment | null;
}) => (
	<>
		<FoundationContextWrapper
			// eslint-disable-next-line jira/integration/test-id-by-folder-structure
			data-testid="issue.views.issue-base.context.status-and-approvals-wrapper.status-and-approval"
		>
			<StatusField key="STATUS" fragmentKey={issueViewRelayFragment} />
		</FoundationContextWrapper>
	</>
);

const FoundationContext = ({
	containerWidth,
	issueViewRelayFragment,
}: {
	containerWidth?: number;
	// TODO Decomp BENTO-12514 - add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment?: IssueViewRelayFragment | null;
}) => {
	const isPremium = useIsPremium();
	const isAiOptInEnabled = useIsAiOptInEnabled();

	const isAiImproveIssuesEnabled =
		isPremium && isAiOptInEnabled && fg('jira-ai-issue-view-improve-issues-button');

	const { shouldBeCompact, setMeasuredRef } = useShouldBeCompact(containerWidth, 12);

	return (
		<Box>
			<Inline
				// eslint-disable-next-line jira/integration/test-id-by-folder-structure
				testId="issue.views.issue-base.context.status-and-approvals-wrapper.status-and-approval"
				space="space.100"
				alignBlock="center"
				grow="hug"
				xcss={inlineStyles}
				ref={setMeasuredRef('foundation-context')}
			>
				<StatusField key="STATUS" fragmentKey={issueViewRelayFragment} />
				{isAiImproveIssuesEnabled && (
					<ConnectedImproveIssueQuickAddItemWithStores shouldBeCompact={shouldBeCompact} />
				)}
			</Inline>
		</Box>
	);
};

const inlineStyles = xcss({ maxWidth: 'fit-content' });

const FoundationContextWithContainerWidth = withContainerWidth(FoundationContext);

export default componentWithFG(
	'jira-ai-issue-view-improve-issues-button',
	FoundationContextWithContainerWidth,
	OldFoundationContext,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FoundationContextWrapper = styled.div({
	marginBottom: 0,
});
FoundationContextWrapper.displayName = 'FoundationContextWrapper';
