import React, { Component, type KeyboardEvent } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { ButtonItem } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { User } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import { isKeyEnterEvent } from '@atlassian/jira-issue-view-common-utils/src/events/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import messages from './messages';

type Props = {
	canDelete: boolean;
	user: User;
	onDeleteUser: (arg1: User, arg2: () => void, arg3: () => void) => void;
	intl: IntlShape;
	onDeleteSuccess: () => void;
	onDeleteError: () => void;
};
type State = {
	mouseOver: boolean;
};

// We need to ignore the ESLint error here because we are removing a feature flag here and its giving us jira/react/no-class-components.
// eslint-disable-next-line jira/react/no-class-components
class UserItem extends Component<Props, State> {
	state = {
		mouseOver: false,
	};

	onMouseOver = () => {
		this.setState({ mouseOver: true });
	};

	onMouseOut = () => {
		this.setState({ mouseOver: false });
	};

	onDelete = () => {
		const { user, onDeleteUser, onDeleteError, onDeleteSuccess } = this.props;

		onDeleteUser(user, onDeleteSuccess, onDeleteError);
	};

	onDeleteKeyDown = (event: KeyboardEvent) => {
		if (isKeyEnterEvent(event)) {
			this.onDelete();
		}
	};

	render() {
		const {
			user: { avatarUrl, displayName },
			canDelete,
			intl,
		} = this.props;
		const { mouseOver } = this.state;

		const deleteIcon = canDelete ? (
			<DeleteIconStyle
				isHidden={!mouseOver}
				tabIndex={0}
				onClick={this.onDelete}
				onKeyDown={this.onDeleteKeyDown}
			>
				<CrossIcon
					label={intl.formatMessage(messages.removeWatcher)}
					LEGACY_size="small"
					color="currentColor"
				/>
			</DeleteIconStyle>
		) : null;

		return isVisualRefreshEnabled() ? (
			<DropdownItem
				elemBefore={<Avatar size="small" src={avatarUrl} borderColor="transparent" />}
				// @ts-expect-error - TS2322 - Type '{ children: string; iconBefore: Element; iconAfter: Element | null; onMouseEnter: () => void; onFocus: () => void; onMouseLeave: () => void; onBlur: () => void; }' is not assignable to type 'IntrinsicAttributes & ButtonItemProps & RefAttributes<HTMLElement>'.
				onMouseEnter={this.onMouseOver}
				onFocus={this.onMouseOver}
				onMouseLeave={this.onMouseOut}
				onBlur={this.onMouseOut}
				elemAfter={deleteIcon}
			>
				{displayName}
			</DropdownItem>
		) : (
			<ButtonItem
				iconBefore={<Avatar size="small" src={avatarUrl} borderColor="transparent" />}
				iconAfter={deleteIcon}
				// @ts-expect-error - TS2322 - Type '{ children: string; iconBefore: Element; iconAfter: Element | null; onMouseEnter: () => void; onFocus: () => void; onMouseLeave: () => void; onBlur: () => void; }' is not assignable to type 'IntrinsicAttributes & ButtonItemProps & RefAttributes<HTMLElement>'.
				onMouseEnter={this.onMouseOver}
				onFocus={this.onMouseOver}
				onMouseLeave={this.onMouseOut}
				onBlur={this.onMouseOut}
			>
				{displayName}
			</ButtonItem>
		);
	}
}

export default UserItem;

// We hide the icon using `opacity: 0` so it can still be tabbed into.

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeleteIconStyle = styled.div<{ isHidden?: boolean }>(
	{
		cursor: 'pointer',
		marginRight: token('space.075', '6px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => (props.isHidden ? 'opacity: 0;' : ''),
);
