import React from 'react';
import { styled } from '@compiled/react';
import { Inline, Box, xcss } from '@atlaskit/primitives';
import { CORE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useIsAiOptInEnabled,
	useIssueKey,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { IssueViewRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { useShouldBeCompact } from '@atlassian/jira-issue-hooks/src/services/use-should-be-compact';
import { ConnectedImproveIssueQuickAddItemWithStores } from '@atlassian/jira-issue-improve-issue-dropdown/src/index.tsx';
import { STATUS_AND_APPROVAL_SPOTLIGHT } from '@atlassian/jira-issue-view-common-constants/src/layout.tsx';
import withContainerWidth from '@atlassian/jira-issue-view-common-utils/src/with-container-width';
import StatusField from '@atlassian/jira-issue-view-foundation/src/status/view.tsx';
import { useIsPremium } from '@atlassian/jira-platform-react-hooks-use-ai-opt-in';
import {
	useProjectType,
	useProjectKey,
} from '@atlassian/jira-project-context-service/src/main.tsx';
// eslint-disable-next-line jira/restricted/@atlassian+jira-servicedesk-common
import GlobalSpotlightTargetDeprecated from '@atlassian/jira-servicedesk-common/src/ui/global-spotlight-target';
import ApprovalPanelWrapper from './approval-panel-wrapper';

const OldFoundationContext = ({
	issueViewRelayFragment,
}: {
	// TODO Decomp BENTO-12514 - add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment?: IssueViewRelayFragment | null;
}) => (
	<GlobalSpotlightTargetDeprecated id={STATUS_AND_APPROVAL_SPOTLIGHT}>
		<FoundationContextWrapper
			// eslint-disable-next-line jira/integration/test-id-by-folder-structure
			data-testid="issue.views.issue-base.context.status-and-approvals-wrapper.status-and-approval"
		>
			<StatusField key="WRAPPED_STATUS" fragmentKey={issueViewRelayFragment} />
			<ApprovalPanelWrapper />
		</FoundationContextWrapper>
	</GlobalSpotlightTargetDeprecated>
);

const FoundationContext = ({
	containerWidth,
	issueViewRelayFragment,
}: {
	containerWidth?: number;
	issueViewRelayFragment?: IssueViewRelayFragment | null;
}) => {
	const isPremium = useIsPremium();
	const isAiOptInEnabled = useIsAiOptInEnabled();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);

	const isAiImproveIssuesEnabled =
		isPremium &&
		isAiOptInEnabled &&
		projectType === CORE_PROJECT &&
		fg('jira-ai-issue-view-improve-issues-button');

	const { shouldBeCompact, setMeasuredRef } = useShouldBeCompact(containerWidth, 12);

	return (
		<GlobalSpotlightTargetDeprecated id={STATUS_AND_APPROVAL_SPOTLIGHT}>
			<Box>
				<Inline
					// eslint-disable-next-line jira/integration/test-id-by-folder-structure
					testId="issue.views.issue-base.context.status-and-approvals-wrapper.status-and-approval"
					space="space.100"
					alignBlock="center"
					grow="hug"
					xcss={inlineStyles}
					ref={setMeasuredRef('foundation-context')}
				>
					<StatusField key="WRAPPED_STATUS" fragmentKey={issueViewRelayFragment} />
					<ApprovalPanelWrapper />
					{isAiImproveIssuesEnabled && (
						<ConnectedImproveIssueQuickAddItemWithStores shouldBeCompact={shouldBeCompact} />
					)}
				</Inline>
			</Box>
		</GlobalSpotlightTargetDeprecated>
	);
};

const inlineStyles = xcss({ maxWidth: 'fit-content' });

const FoundationContextWithContainerWidth = withContainerWidth(FoundationContext);

export default componentWithFG(
	'jira-ai-issue-view-improve-issues-button',
	FoundationContextWithContainerWidth,
	OldFoundationContext,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FoundationContextWrapper = styled.div({
	marginBottom: 0,
});
