import { useMemo } from 'react';
import isNil from 'lodash/isNil';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { useForgeUiIssueData } from '@atlassian/jira-forge-controllers-extension-context';
import type { IssueViewStaticContext } from '@atlassian/jira-forge-ui-types/src/common/types/conditions/issue-view.tsx';
import type {
	CustomField,
	CustomFieldType,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { doesExtensionMeetConditions } from '@atlassian/jira-forge-ui-utils/src/utils/conditions';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIssueFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import type { IssueConfiguration } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import {
	useForgeCustomField,
	useBlockedExtensions,
} from '@atlassian/jira-issue-view-forge-service/src/services/main.tsx';
import {
	useProjectConfiguration,
	useProjectKey,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useIssueViewExtensionId } from './issue-view-extension-id';
import { useIssueViewStaticContext } from './issue-view-static-context';
import type { FetchIssueCustomFieldPayload, FetchIssueCustomFieldContext } from './types';

export const isForgeCustomFieldFilteredOut = (
	ecosystemEnabled: boolean,
	issueViewStaticContext: IssueViewStaticContext,
	extension: CustomField | CustomFieldType | null,
	extensionId: string,
	blockedExtensions: (CustomField | CustomFieldType | null)[],
): boolean => {
	if (!ecosystemEnabled) {
		return false;
	}

	if (extension) {
		return !doesExtensionMeetConditions(extension, issueViewStaticContext);
	}

	const blockedCustomFieldExtensionIds = blockedExtensions.map((e) => e?.id);
	return blockedCustomFieldExtensionIds.includes(extensionId);
};

export const shouldUseFormatter = (extension: CustomField | CustomFieldType | null): boolean => {
	if (!extension) {
		return false;
	}

	const hasRenderingFunction = !isNil(extension.properties.function);
	const hasNativeResource =
		!isNil(extension.properties.resource) && extension.properties.render === 'native';
	const hasFormatterDefined = !isNil(extension.properties.formatter);

	return !hasRenderingFunction && !hasNativeResource && hasFormatterDefined;
};

export const doesFieldExistsInJira = (
	fieldId: string,
	fieldsConfiguration: IssueConfiguration,
): boolean => !!fieldsConfiguration[fieldId];

export const getFieldName = (fieldId: string, fieldsConfiguration: IssueConfiguration): string => {
	if (fieldsConfiguration[fieldId]?.title) {
		return fieldsConfiguration[fieldId]?.title;
	}

	log.safeWarnWithoutCustomerData('issue.field.selector', `${fieldId} does not have a field title`);
	return '';
};

export const useFetchIssueCustomFieldData = ({
	fieldId,
}: FetchIssueCustomFieldContext): FetchIssueCustomFieldPayload => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const { ecosystemEnabled = false } = useProjectConfiguration(projectKey);
	const issueViewStaticContext = useIssueViewStaticContext();
	const { loading: extensionIdLoading, data: extensionId } = useIssueViewExtensionId(
		issueKey,
		fieldId,
	);
	const extensionData = useForgeUiIssueData();
	const extension = useForgeCustomField(extensionId || '') || null;
	const blockedExtensions = useBlockedExtensions();
	const [{ loading: fieldsConfigurationLoading, value: fieldsConfiguration }] =
		useIssueFieldConfig(issueKey);
	const blockedCustomFields = useMemo(
		() => [
			...(blockedExtensions ? blockedExtensions.customField : []),
			...(blockedExtensions ? blockedExtensions.customFieldType : []),
		],
		[blockedExtensions],
	);

	return useMemo(
		() => ({
			loading: extensionIdLoading || fieldsConfigurationLoading,
			data:
				extensionId && fieldsConfiguration && extensionData
					? {
							isFilteredOut: isForgeCustomFieldFilteredOut(
								ecosystemEnabled,
								issueViewStaticContext,
								extension,
								extensionId,
								blockedCustomFields,
							),
							shouldUseFormatter: shouldUseFormatter(extension),
							extension,
							extensionData,
							fieldExistsInJira: doesFieldExistsInJira(fieldId, fieldsConfiguration),
							fieldName: getFieldName(fieldId, fieldsConfiguration),
						}
					: undefined,
			error: undefined,
		}),
		[
			fieldId,
			ecosystemEnabled,
			issueViewStaticContext,
			extension,
			extensionIdLoading,
			extensionId,
			extensionData,
			fieldsConfigurationLoading,
			fieldsConfiguration,
			blockedCustomFields,
		],
	);
};
