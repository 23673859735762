import noop from 'lodash/noop';
import type { SelectedIssueDetailsType, StoreApi } from './types';

export const UnarchiveIssueModalActions = {
	openUnarchiveIssueModal:
		(issueKey: string) =>
		({ setState }: StoreApi) => {
			setState({ isUnarchiveIssueModalOpen: true, issueKey });
		},
	closeUnarchiveIssueModal:
		() =>
		({ setState }: StoreApi) => {
			setState({ isUnarchiveIssueModalOpen: false, issueKey: null });
		},
	openUnarchiveIssuesModal:
		({
			selectedIssuesDetails,
			onSuccessCallback,
			onErrorCallback,
			showViewIssueActionInSuccessFlag,
		}: {
			selectedIssuesDetails: SelectedIssueDetailsType[];
			onSuccessCallback?: () => void;
			onErrorCallback?: () => void;
			showViewIssueActionInSuccessFlag?: boolean;
		}) =>
		({ setState }: StoreApi) => {
			setState({
				isUnarchiveIssueModalOpen: true,
				selectedIssuesDetails,
				onSuccessCallback: onSuccessCallback || noop,
				onErrorCallback: onErrorCallback || noop,
				showViewIssueActionInSuccessFlag: showViewIssueActionInSuccessFlag || false,
			});
		},
	closeUnarchiveIssuesModal:
		() =>
		({ setState }: StoreApi) => {
			setState({
				isUnarchiveIssueModalOpen: false,
				selectedIssuesDetails: [],
				onSuccessCallback: noop,
				onErrorCallback: noop,
				showViewIssueActionInSuccessFlag: false,
			});
		},
};

export type Actions = typeof UnarchiveIssueModalActions;
