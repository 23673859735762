import type {
	LabelSuggestion,
	LabelOption as Option,
} from '@atlassian/jira-issue-shared-types/src/common/types/labels-type.tsx';
import { MAX_RESULTS } from '../../common/constants';

export const mapCachedItemsToLabelOptions = (items: LabelSuggestion[]): Option[] =>
	items.map(({ label }) => ({
		label,
		value: label,
	}));

export const transformCacheEntries = (items: LabelSuggestion[]): LabelSuggestion[] =>
	items.slice(-1 * MAX_RESULTS).reverse();
