import { useCommandPaletteIsLoaded } from '@atlassian/jira-command-palette/src/controllers/command-palette/index.tsx';
import { getShortcutModifierKey } from '@atlassian/jira-common-components-keyboard-shortcuts-dialog/src/utils';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import traceUFOPress from '@atlassian/react-ufo/trace-press';
import { COMMAND_PALETTE_ID, COMMAND_PALETTE_PRESSED_ANALYTICS } from '../../common/constants';
import type { UseCommandPaletteShortcutResult } from './types';

export const useCommandPaletteShortcut = (): UseCommandPaletteShortcutResult => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [{ isLoaded }, { open }] = useCommandPaletteIsLoaded();

	return {
		keyMap: {
			[`${getShortcutModifierKey()}+k`]: {
				callback: (ev?: KeyboardEvent) => {
					traceUFOPress('command-palette-opened');
					fireUIAnalytics(
						createAnalyticsEvent(COMMAND_PALETTE_PRESSED_ANALYTICS),
						COMMAND_PALETTE_ID,
						{
							key: 'k',
							isLoaded,
						},
					);

					ev?.preventDefault();
					open();
				},
			},
		},
	};
};
