// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	removeApproverError: {
		id: 'issue-field-approval-panel.approval-glance.remove-approver-error',
		defaultMessage: 'We couldn’t remove the approver',
		description: 'The title text to show in the error flag when adding the approver fails',
	},
	addApproverError: {
		id: 'issue-field-approval-panel.approval-glance.add-approver-error',
		defaultMessage: 'We couldn’t add the approver',
		description: 'The title text to show in the error flag when adding the approver fails',
	},
	saveSuccess: {
		id: 'issue-field-approval-panel.approval-panel.save-success',
		defaultMessage: "We've saved your response.",
		description:
			'The title text to show in the success flag when saving the approval answer is successful',
	},
	saveError: {
		id: 'issue-field-approval-panel.approval-panel.save-error',
		defaultMessage: 'We couldn’t save your approval response',
		description: 'The title text to show in the error flag when saving the approval answer fails',
	},
	saveConflictError: {
		id: 'issue-field-approval-panel.approval-panel.save-conflict-error',
		defaultMessage: 'An approval response has already been submitted for this issue',
		description:
			'The title text to show in the error flag when saving the approval answer fails with conflict 409 error',
	},
});
