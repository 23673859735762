import memoize from 'lodash/memoize';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { getLabelsCache } from '@atlassian/jira-cache/src/services/labels/index.tsx';
import performance from '@atlassian/jira-common-performance/src/performance.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { IntlShapeV2 as Intl } from '@atlassian/jira-intl/src/v2/types.tsx';
import {
	triggerCacheHitEvent,
	triggerCacheMissEvent,
} from '@atlassian/jira-issue-analytics/src/services/prefetch/index.tsx';
import {
	fireSuggestionsRequestSuccessEvent,
	fireSuggestionsRequestFailedEvent,
} from '@atlassian/jira-issue-analytics/src/services/suggestions/index.tsx';
import { LABELS } from '@atlassian/jira-issue-view-configurations';
import { LABELS_TYPE } from '@atlassian/jira-platform-field-config';
import type { LabelsSuggestionList } from '@atlassian/jira-shared-types/src/rest/jira/label.tsx';
import type { CachedFieldShape } from '@atlassian/jira-smart-field-prefetch/src/context.tsx';
import { transformSuggestionsFromServerAndCache } from './transformer';
import type { Props } from './types';

const labelsCache = getLabelsCache();

export const fetchSuggestionsFactory =
	(
		autoCompleteUrl: string | null,
		fieldId: string,
		fieldEditSessionId: string | null | undefined,
		createAnalyticsEvent: CreateUIAnalyticsEvent | null | undefined,
		formatMessage: Intl['formatMessage'],
	) =>
	(query: string, sessionId?: string) => {
		if (autoCompleteUrl == null) {
			return Promise.resolve([]);
		}

		let url = `${autoCompleteUrl}${encodeURIComponent(query)}`;

		if (sessionId != null) {
			url = `${url}&sessionId=${sessionId}`;
		}

		if (fieldEditSessionId != null) {
			url = `${url}&fieldEditSessionId=${fieldEditSessionId}`;
		}

		const startTime = performance.now();
		const queryLength = query.length;

		return Promise.all([fetchJson(url), labelsCache.getAll()]) // eslint-disable-next-line @typescript-eslint/no-explicit-any
			.then(([dataFromAPI, cache]: [any, any]) => {
				fieldId === LABELS_TYPE &&
					// @ts-expect-error - TS2345 - Argument of type 'CreateUIAnalyticsEvent | null | undefined' is not assignable to parameter of type 'CreateUIAnalyticsEvent | undefined'.
					fireSuggestionsRequestSuccessEvent(LABELS, startTime, createAnalyticsEvent, {
						sessionId,
						queryLength,
					});
				return transformSuggestionsFromServerAndCache(dataFromAPI, cache, formatMessage);
			})
			.catch((error) => {
				fieldId === LABELS_TYPE &&
					// @ts-expect-error - TS2345 - Argument of type 'CreateUIAnalyticsEvent | null | undefined' is not assignable to parameter of type 'CreateUIAnalyticsEvent | undefined'.
					fireSuggestionsRequestFailedEvent(LABELS, startTime, createAnalyticsEvent, {
						sessionId,
					});
				throw error;
			});
	};

export const getMemoizedSuggestionsFactory = memoize(
	fetchSuggestionsFactory,
	(url, session) => `${url}_${session}`,
);

export const prefetchSuggestionsFactory =
	(prefetchResult: CachedFieldShape<LabelsSuggestionList>, viewProps: Props) =>
	(query: string, assignSessionId?: string) => {
		if (!query && prefetchResult !== undefined && prefetchResult !== null) {
			const { prefetched, fieldValuePromise, sessionId, prefetchSessionId } = prefetchResult;
			const { createAnalyticsEvent } = viewProps;
			if (prefetched && fieldValuePromise !== null && sessionId !== null) {
				triggerCacheHitEvent(
					createAnalyticsEvent,
					prefetchSessionId,
					assignSessionId || '',
					sessionId,
					LABELS,
				);
				const startTime = performance.now();
				return Promise.all([fieldValuePromise, labelsCache.getAll()]) // eslint-disable-next-line @typescript-eslint/no-explicit-any
					.then(([dataFromAPI, cache]: [any, any]) => {
						// query length for prefetch should be 0
						fireSuggestionsRequestSuccessEvent(LABELS, startTime, createAnalyticsEvent, {
							sessionId: assignSessionId,
							isPrefetched: true,
							queryLength: 0,
						});

						return transformSuggestionsFromServerAndCache(
							dataFromAPI,
							cache,
							viewProps.formatMessage,
						);
					})
					.catch((error) => {
						fireSuggestionsRequestFailedEvent(LABELS, startTime, createAnalyticsEvent, {
							sessionId: assignSessionId,
							isPrefetched: true,
						});

						throw error;
					});
			}
			triggerCacheMissEvent(createAnalyticsEvent, prefetchSessionId, assignSessionId || '', LABELS);
		}
		return viewProps.fetchSuggestions(query, assignSessionId);
	};

export const getMemoizedPrefetchSuggestionsFactory = memoize(
	prefetchSuggestionsFactory,
	(prefetchResult, viewProps) =>
		`${(prefetchResult && prefetchResult.prefetchSessionId) || ''}_${viewProps.issueKey}`,
);
