import React, {
	useRef,
	useCallback,
	useEffect,
	useMemo,
	type PropsWithChildren,
	type Ref,
} from 'react';
import { styled } from '@compiled/react';
import memoizeOne from 'memoize-one';
import { Popper, Manager, Reference, type PopperChildrenProps } from '@atlaskit/popper';
import { layers } from '@atlaskit/theme/constants';
import { mergeRefs } from '@atlassian/jira-merge-refs';

type AlignBlock = 'start' | 'center' | 'end';

type MinWidthBreakpoint = 'small' | 'medium' | 'large';

type Rect = {
	width: number;
	height: number;
	x: number;
	y: number;
};

const getModifiers = (alignBlock: AlignBlock) => [
	{
		name: 'offset',
		enabled: alignBlock === 'center',
		options: {
			// Use negative offset of half the popper height to vertically center the content
			offset: ({ popper }: { popper: Rect }) => [0, -popper.height / 2],
		},
	},
	{
		name: 'flip',
		enabled: false,
	},
	{
		name: 'computeStyles',
		options: {
			// Prevent use of 3D transforms to avoid creating a new stacking context for fixed position children
			gpuAcceleration: false,
		},
	},
	{
		name: 'preventOverflow',
		options: {
			padding: 0,
			rootBoundary: 'viewport',
			// Allow the popup to remain within the clipping parent when overflowing the Y axis
			altAxis: true,
		},
	},
];

type Props = PropsWithChildren<{
	/**
	 * Vertically alignment of the popup. The consumer should ensure this matches whatever alignment is applied
	 * to the read view to minimise layout shifting. Defaults to `start`.
	 */
	alignBlock?: AlignBlock;
	/**
	 * Adjust the minimum width of the edit view popup according to predefined breakpoints. This defaults to `medium`.
	 */
	minWidth?: MinWidthBreakpoint;
}>;

/** Map the provided alignment to the appropriate popper placement. */
const toPopperPlacement = (alignBlock: AlignBlock) => {
	switch (alignBlock) {
		case 'end': {
			return 'top-start';
		}
		default: {
			return 'bottom-start';
		}
	}
};

/** Map the provided min width breakpoint to a pixel value. */
const toMinWidth = (minWidth: MinWidthBreakpoint) => {
	switch (minWidth) {
		case 'large':
			return '400px';
		case 'medium':
			return '200px';
		default:
			return '140px';
	}
};

/**
 * Render the edit view within a popup that can dynmically resize according to the max-content width of the underlying
 * edit view component. This is suitable for layouts where the inline edit component is tightly constrained (e.g. a
 * table cell) to allow more space for the user when editing a field.
 */
export const EditViewPopup = ({ alignBlock = 'start', minWidth = 'medium', children }: Props) => {
	const updateRef = useRef<() => void>();
	const resizeObserver = useRef<ResizeObserver>();

	// Cleanup the resize observer when the component is unmounted
	useEffect(() => () => resizeObserver.current?.disconnect(), []);

	const resizeRef = useCallback((el: HTMLElement | null) => {
		if (el) {
			// Update popup positioning whenever content is resized
			resizeObserver.current = new ResizeObserver(() => updateRef.current?.());
			resizeObserver.current.observe(el);
		}
	}, []);

	// Ensure a stable ref function is passed through to the popup content
	const memoizedMergeRefs = useMemo(() => memoizeOne(mergeRefs), []);
	const modifiers = useMemo(() => getModifiers(alignBlock), [alignBlock]);
	return (
		<Manager>
			<Reference>{({ ref }: { ref: Ref<HTMLDivElement> }) => <Target ref={ref} />}</Reference>
			<Popper placement={toPopperPlacement(alignBlock)} strategy="absolute" modifiers={modifiers}>
				{({ ref, style, update }: PopperChildrenProps) => {
					updateRef.current = update;
					return (
						<Content
							ref={memoizedMergeRefs(ref, resizeRef)}
							minWidth={minWidth}
							/* eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */
							style={style}
						>
							{children}
						</Content>
					);
				}}
			</Popper>
		</Manager>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Target = styled.div({
	// Width 0 to allow popup content to be correctly left aligned when using `placement: right*`
	width: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled.div<{ minWidth: MinWidthBreakpoint }>({
	// The edit view should occupy the largest of it's containing block or the defined breakpoint.
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	minWidth: ({ minWidth }: { minWidth: MinWidthBreakpoint }) =>
		`max(100%, ${toMinWidth(minWidth)})`,
	width: 'max-content',
	// Max width before the edit view should begin wrapping (arbitrary value which should comfortably accomodate most
	// fields). This is lower priority than the min width.
	maxWidth: '450px',
	zIndex: layers.modal(),
});
