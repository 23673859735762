import React, { useEffect, useMemo, useState } from 'react';
import { JiraIssueAri } from '@atlassian/ari/jira/issue';
import { JiraSiteAri } from '@atlassian/ari/jira/site';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { designPanelEntryPoint } from '@atlassian/jira-issue-design-section/entrypoint';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';

export const DesignPanel = () => {
	const [isReadyToLoad, setIsReadyToLoad] = useState(false);
	const cloudId = useCloudId();
	const issueId = useIssueId();

	const entryPointParams = useMemo(() => {
		if (!issueId) {
			return { siteAri: '', issueAri: '', cloudId: '' };
		}
		const issueAri = JiraIssueAri.create({ issueId, siteId: cloudId }).toString();
		const siteAri = JiraSiteAri.create({ siteId: cloudId }).toString();
		setIsReadyToLoad(true);
		return { siteAri, issueAri, cloudId };
	}, [issueId, cloudId]);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		designPanelEntryPoint,
		entryPointParams,
	);
	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (isReadyToLoad) {
			entryPointActions.load();
			setIsReadyToLoad(false);
		}
	}, [isReadyToLoad, entryPointActions]);

	const runtimeProps = useMemo(() => ({ isExpanded: true }), []);

	return (
		<UFOSegment name="jiraIssueDesignPanel">
			<JiraEntryPointContainer
				entryPointReferenceSubject={entryPointReferenceSubject}
				id="jiraIssueDesignPanel"
				packageName="jiraIssueViewBase"
				errorFallback="unmount"
				teamName="helios-headcoach"
				runtimeProps={runtimeProps}
			/>
		</UFOSegment>
	);
};
