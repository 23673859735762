import React from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { Inline } from '@atlaskit/primitives';
import { type SimpleTagProps, SimpleTag } from '@atlaskit/tag';
import { token } from '@atlaskit/tokens';
import Link from '../../common/ui/link';

type Props = {
	/** The tag name to be displayed to users for the item */
	name: string;
	/** URL to direct users to when they click on the item */
	href?: string;
	/** Optional icon to display next to the item */
	icon?: {
		url?: string;
	};
	/** Optional boolean to apply strikethrough style to the item */
	shouldDisplayStrikethrough?: boolean;
};

const StrikethroughTagLink: SimpleTagProps['linkComponent'] = (props) => (
	<Link shouldDisplayStrikethrough {...props} />
);

/**
 * An item formatted with an Atlaskit Tag wrapper.
 * It's recommended to use the TagItemPopupContentWrapper with this item component.
 */
export const TagItem = ({ name, href, icon, shouldDisplayStrikethrough }: Props) => (
	<TagMarginFix>
		<SimpleTag
			elemBefore={
				icon ? (
					<Avatar
						appearance="square"
						size="xsmall"
						src={icon.url}
						borderColor="transparent"
						testId="list-with-popup.ui.tag-item.avatar"
					/>
				) : undefined
			}
			text={name}
			href={href}
			linkComponent={shouldDisplayStrikethrough ? StrikethroughTagLink : Link}
		/>
	</TagMarginFix>
);

export const TagItemPopupContentWrapper = ({ children }: { children: React.ReactNode }) => (
	<Inline space="space.100" shouldWrap>
		{children}
	</Inline>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TagMarginFix = styled.span({
	display: 'inline-block',
	margin: token('space.negative.050', '-4px'),
	maxWidth: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'> span': {
		maxWidth: '100%',
	},
});
