import { SERVICEDESK_REPORTS_ISSUE } from '@atlassian/jira-common-constants/src/analytics-sources';
import type { BM3Metric } from '@atlassian/jira-providers-spa-apdex-analytics/src/submit-apdex-mark/types.tsx';
import { NO_CATEGORY } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { toItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/main.tsx';
import { JSMIssueViewLoad, JSMReportsIssueViewLoad } from '../../utils/performance-analytics';

export const getMetricKey = (practiceType?: string | null, analyticsSource?: string): string => {
	if (analyticsSource === SERVICEDESK_REPORTS_ISSUE) return 'servicedesk.reports.custom.issue';

	const practice = toItsmPractice(practiceType);
	return practice !== NO_CATEGORY
		? `servicedesk.queues.${practice}.issue`
		: 'servicedesk.queues.issue';
};

export const getMetric = (analyticsSource?: string): BM3Metric => {
	// @ts-expect-error - TS2322 - Type 'PageLoadMetric' is not assignable to type 'BM3Metric'.
	if (analyticsSource === SERVICEDESK_REPORTS_ISSUE) return JSMReportsIssueViewLoad;
	// @ts-expect-error - TS2322 - Type 'PageLoadMetric' is not assignable to type 'BM3Metric'.
	return JSMIssueViewLoad;
};
