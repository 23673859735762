import React from 'react';
import { TextFieldReadView } from '../../text-field/read-view';
import type { ReadViewProps } from '../../types';

export const NumberFieldReadView = ({
	render,
	value,
	getValueContainer,
	inInlineEdit,
}: ReadViewProps) => (
	<TextFieldReadView
		getValueContainer={getValueContainer}
		render={render}
		value={value}
		inInlineEdit={inInlineEdit}
	/>
);
