import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { useFragment, graphql } from 'react-relay';
import { token } from '@atlaskit/tokens';
import { fontSize, gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import isValidUrl from '@atlassian/jira-common-util-is-url';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import { LazySmartLink } from '@atlassian/jira-issue-field-smart-link/src/ui/smart-link/async';
import type { singleLineText_issueFieldSingleLineTextReadviewFull_SingleLineTextReadView$key as SingleLineTextFragment } from '@atlassian/jira-relay/src/__generated__/singleLineText_issueFieldSingleLineTextReadviewFull_SingleLineTextReadView.graphql';
import type { SingleLineTextReadViewProps } from './types';

/**
 * The SingleLineTextReadView will show a read only view of SingleLineText field.
 * @param props [SingleLineTextReadViewProps](./types.tsx)
 */
export const SingleLineTextReadView = ({
	fragmentRef,
	renderFieldContentWrapper: FieldContentWrapper,
	renderCustomReadView,
}: SingleLineTextReadViewProps) => {
	const { text = null, fieldId } = useFragment<SingleLineTextFragment>(
		graphql`
			fragment singleLineText_issueFieldSingleLineTextReadviewFull_SingleLineTextReadView on JiraSingleLineTextField {
				text
				fieldId
			}
		`,
		fragmentRef,
	);

	const getReadView = useCallback(() => {
		if (renderCustomReadView) {
			return renderCustomReadView(text);
		}

		if (!text) {
			return <EmptyFieldText />;
		}

		const testId = `issue.views.field.single-line-text.read-view.${fieldId}`;

		if (
			isValidUrl(text, true) &&
			expValEquals('jsw_perf_format-links-in-custom-fields', 'issue-view', true)
		) {
			return (
				<ReadViewContainer data-testid={testId}>
					<LazySmartLink appearance="inline" url={text} />
				</ReadViewContainer>
			);
		}

		return <ReadViewContainer data-testid={testId}>{text}</ReadViewContainer>;
	}, [renderCustomReadView, fieldId, text]);

	if (FieldContentWrapper) {
		return <FieldContentWrapper>{getReadView()}</FieldContentWrapper>;
	}
	return getReadView();
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled.div({
	display: 'flex',
	flex: '1 1 auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	lineHeight: (gridSize * 2.5) / fontSize,
	wordBreak: 'break-word',
	position: 'relative',
	width: '100%',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& .smart-link-title-wrapper': {
		lineHeight: token('space.300', '24px'),
	},
});
