import React, { useCallback } from 'react';
import { graphql, useFragment, useLazyLoadQuery, useMutation } from 'react-relay';
import { AssignToMe } from '@atlassian/jira-issue-field-assignee-assign-to-me/src/ui/assign-to-me/index.tsx';
import type { AssignToMeProps } from '@atlassian/jira-issue-field-assignee-assign-to-me/src/ui/assign-to-me/types';
import type { AssigneeInlineEditViewBaseProps } from '@atlassian/jira-issue-field-assignee-inline-edit-full/src/ui/assignee/types';
import { useFieldIsEditingActions } from '@atlassian/jira-issue-field-editing-store';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { useServerValidationHandler } from '@atlassian/jira-issue-field-validation-handler/src/controllers/server-validation-handler/index.tsx';
import type { AggUser } from '@atlassian/jira-issue-user-picker-edit-view/src/common/types.tsx';
import type { assignToMeInlineEdit_issueViewLayoutAssigneeField_AssignToMeInlineEditInner_fragmentKey$key as AssignToMeInnerFieldFragment } from '@atlassian/jira-relay/src/__generated__/assignToMeInlineEdit_issueViewLayoutAssigneeField_AssignToMeInlineEditInner_fragmentKey.graphql';
import type { assignToMeInlineEdit_issueViewLayoutAssigneeField_AssignToMeInlineEditInner_rootFragmentKey$key as AssignToMeInnerRootFragment } from '@atlassian/jira-relay/src/__generated__/assignToMeInlineEdit_issueViewLayoutAssigneeField_AssignToMeInlineEditInner_rootFragmentKey.graphql';
import type { assignToMeInlineEdit_issueViewLayoutAssigneeField_fragmentKey$key as AssignToMeFieldFragment } from '@atlassian/jira-relay/src/__generated__/assignToMeInlineEdit_issueViewLayoutAssigneeField_fragmentKey.graphql';
import type { assignToMeInlineEdit_Mutation as AssigneeMutation } from '@atlassian/jira-relay/src/__generated__/assignToMeInlineEdit_Mutation.graphql';
import type { assignToMeInlineEdit_rootQuery as AssignToMeQueryRootQuery } from '@atlassian/jira-relay/src/__generated__/assignToMeInlineEdit_rootQuery.graphql';

export type AssignToMeInlineEditBase = Omit<AssignToMeProps, 'onClick'> &
	Pick<AssigneeInlineEditViewBaseProps, 'onSubmit' | 'onSubmitFailed' | 'onSubmitSucceeded'>;

type AssignToMeInlineEditInnerProps = AssignToMeInlineEditBase & {
	fragmentKey: AssignToMeInnerFieldFragment;
	rootFragmentKey: AssignToMeInnerRootFragment;
};

export type AssignToMeInlineEditProps = AssignToMeInlineEditBase & {
	fragmentKey: AssignToMeFieldFragment;
};

export const AssignToMeInlineEditInner = ({
	fragmentKey,
	rootFragmentKey,
	projectKey,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	...rest
}: AssignToMeInlineEditInnerProps) => {
	const { overriding } = useInlineEditFieldInjections();

	const { setFieldEditingState } = useFieldIsEditingActions();
	const field = useFragment<AssignToMeInnerFieldFragment>(
		graphql`
			fragment assignToMeInlineEdit_issueViewLayoutAssigneeField_AssignToMeInlineEditInner_fragmentKey on JiraSingleSelectUserPickerField {
				id
				name
				fieldId
				...assignToMe_issueFieldAssigneeAssignToMe_fragmentRef
			}
		`,
		fragmentKey,
	);

	const root = useFragment<AssignToMeInnerRootFragment>(
		graphql`
			fragment assignToMeInlineEdit_issueViewLayoutAssigneeField_AssignToMeInlineEditInner_rootFragmentKey on Query {
				...assignToMe_issueFieldAssigneeAssignToMe_fragmentRefRoot
			}
		`,
		rootFragmentKey,
	);
	const setIsEditing = useCallback(() => {
		setFieldEditingState(field.fieldId, true);
	}, [field.fieldId, setFieldEditingState]);
	const [, { handleSubmitFailed }] = useServerValidationHandler(
		overriding.overrideLabel(field.name),
		setIsEditing,
	);

	const [commit] = useMutation<AssigneeMutation>(graphql`
		mutation assignToMeInlineEdit_Mutation($input: JiraUpdateSingleSelectUserPickerFieldInput!)
		@raw_response_type {
			jira {
				updateSingleSelectUserPickerField(input: $input) @optIn(to: "JiraIssueFieldMutations") {
					success
					errors {
						message
					}
					field {
						user {
							accountId
							name
							picture
							accountStatus
							id
						}
					}
				}
			}
		}
	`);
	// #endregion

	const handleSubmit = useCallback(
		(newUser: AggUser | null) => {
			onSubmit?.(newUser);
			commit({
				variables: {
					input: {
						id: field.id,
						operation: {
							operation: 'SET',
							id: newUser?.id ?? null,
						},
					},
				},
				onCompleted: (mutationData) => {
					if (mutationData.jira?.updateSingleSelectUserPickerField?.success) {
						onSubmitSucceeded?.(newUser);
					} else {
						onSubmitFailed?.();
					}
				},
				onError() {
					onSubmitFailed?.();
					handleSubmitFailed();
				},
				optimisticResponse: {
					jira: {
						updateSingleSelectUserPickerField: {
							success: true,
							errors: null,
							field: {
								id: field.id,
								user: newUser
									? {
											id: newUser.id,
											accountId: newUser.accountId,
											accountStatus: newUser.accountStatus,
											name: newUser.name,
											picture: newUser.picture,
											__typename: 'AtlassianAccountUser',
										}
									: null,
							},
						},
					},
				},
			});
		},
		[commit, field.id, handleSubmitFailed, onSubmit, onSubmitFailed, onSubmitSucceeded],
	);

	return (
		<AssignToMe
			onClick={handleSubmit}
			fragmentRefRoot={root}
			fragmentRef={field}
			projectKey={projectKey}
			{...rest}
		/>
	);
};

export const AssignToMeInlineEdit = ({ fragmentKey, ...props }: AssignToMeInlineEditProps) => {
	/**
	 * Rather than hook it all the way up to the root query, which involves a lot of changes
	 * we are loading this lazily. This fragment is just fetching the current user.
	 *
	 * We expect that by this point, the current user is already loaded into relay
	 * eg by the assignee edit view
	 * So we should be fetching from the relay store in most cases anyway.
	 *
	 * Ideally we would move this logic (canAssignToMe as a prop on the field) to the BE though
	 * and we wouldn't need this at all
	 */
	const data = useLazyLoadQuery<AssignToMeQueryRootQuery>(
		graphql`
			query assignToMeInlineEdit_rootQuery {
				...assignToMeInlineEdit_issueViewLayoutAssigneeField_AssignToMeInlineEditInner_rootFragmentKey
			}
		`,
		Object.freeze({}),
		{
			fetchPolicy: 'store-or-network',
		},
	);
	const fieldData = useFragment<AssignToMeFieldFragment>(
		graphql`
			fragment assignToMeInlineEdit_issueViewLayoutAssigneeField_fragmentKey on JiraSingleSelectUserPickerField {
				...assignToMeInlineEdit_issueViewLayoutAssigneeField_AssignToMeInlineEditInner_fragmentKey
			}
		`,
		fragmentKey,
	);

	return <AssignToMeInlineEditInner {...props} fragmentKey={fieldData} rootFragmentKey={data} />;
};
