import React from 'react';
import { ISSUE_CONTEXT_MODULE } from '@atlassian/jira-forge-ui-constants';
import { ForgeAnalyticsWrapper } from '../../common/ui';
import type {
	AnalyticsWrapperPropsTrigger,
	AnalyticsWrapperPropsView,
} from '../../common/ui/types';
import { MODULE_TYPE_TRIGGER } from '../../constants';

export const ViewAnalyticsWrapper = (props: AnalyticsWrapperPropsView) => (
	<ForgeAnalyticsWrapper module={ISSUE_CONTEXT_MODULE} {...props} />
);

export const TriggerAnalyticsWrapper = (props: AnalyticsWrapperPropsTrigger) => (
	<ForgeAnalyticsWrapper
		module={ISSUE_CONTEXT_MODULE}
		moduleType={MODULE_TYPE_TRIGGER}
		{...props}
	/>
);
