import { useCallback, useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import type { TimeZone } from '@atlassian/jira-shared-types/src/general.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { longDateTimeFormat, longDateOnlyFormat } from './common/constants';
import { standardizeLocale, verifyTimeZone } from './common/utils';

/**
 * Service that provides consistent, locale and timezone respecting formats of dates and date-times.
 *
 * @param timeZone {?string} Time-zone to override formatting with. If falsey uses user preference via `useCurrentUser` or undefined if nothing else.
 *
 * Depends on (via `react-magnetic-di`)
 * * `Intl.DateTimeFormat` (used mainly for Node tests which don't have full locale data)
 * * `useCurrentUser`
 * * `useLocale`
 */
export const useDateTimeFormatter = (overrideTimeZone?: TimeZone | null) => {
	di(Intl);
	const {
		data: {
			// @ts-expect-error - TS2339 - Property 'timeZone' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'.
			user: { timeZone: timeZonePreference = undefined },
		},
	} = useCurrentUser();

	const timeZone = overrideTimeZone ?? timeZonePreference;

	const jiraLocal = useLocale();

	const standardizedLocale = standardizeLocale(jiraLocal);
	const verifiedTimeZone = verifyTimeZone(timeZone);

	const dateTimeFormatter = useMemo(
		() =>
			new Intl.DateTimeFormat(standardizedLocale, {
				...longDateTimeFormat,
				timeZone: verifiedTimeZone,
			}),
		[standardizedLocale, verifiedTimeZone],
	);

	const dateOnlyFormatter = useMemo(
		() =>
			new Intl.DateTimeFormat(standardizedLocale, {
				...longDateOnlyFormat,
				timeZone: 'UTC',
			}),
		[standardizedLocale],
	);

	const formatDateTime = useCallback(
		(date: string | number | Date) => {
			const rawValue = typeof date === 'string' ? new Date(date) : date;
			return dateTimeFormatter.format(rawValue);
		},
		[dateTimeFormatter],
	);

	const formatDateOnly = useCallback(
		(date: string) => dateOnlyFormatter.format(new Date(date)),
		[dateOnlyFormatter],
	);

	return {
		/**
		 * Returns the long form of a date value with respect to the user's locale and timezone preferences
		 *
		 * @param {string|number|date} date Value as either an [ISO8601 string](https://www.w3.org/TR/NOTE-datetime) (eg `"1997-07-16T19:20:30.45+01:00"`), epoch number (eg `1615431020`), or vanilla `Date` object
		 *
		 * ## Examples
		 *
		 * ```js
		 * // returns Jan 22, 2021, 1:11 PM
		 * formatDateTime(1611281490857) // where user locale is "en-US"
		 *
		 * // returns 2021年1月22日 午後1:11
		 * formatDateTime(1611281490857) // where user locale is "ja-JP"
		 * ```
		 */
		formatDateTime,
		/**
		 * Returns the long form of a date without time value with respect to the user's locale. Timezones do not apply to these dates.
		 *
		 * @param {string} date Value as a 'YYYY-MM-DD' string (eg `"1997-07-16"`)
		 *
		 * ## Examples
		 *
		 * ```js
		 * // returns Jan 22, 2021
		 * formatDateOnly('2021-01-22') // where user locale is "en-US"
		 *
		 * // returns 2021年1月22日
		 * formatDateOnly('2021-01-22') // where user locale is "ja-JP"
		 * ```
		 */
		formatDateOnly,
	};
};
