import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types';
import type { ConnectionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/connection/types';
import type { OptionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/option/types';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types';
import type { RemoteIssue } from '@atlassian/jira-polaris-remote-issue/src/controllers/types';
import type { IssueType } from '../../../../project/types';
import { JPD_CONNECTION_ISSUE_LINK_TYPE } from '../../../constants';
import type { FieldMappings } from '../../../selectors/fields';
import type { FieldMapping } from '../../../utils/field-mapping/types';

type GetConnectionFieldValuesFromLinkedIssues = {
	issue: RemoteIssue;
	allIssues: RemoteIssue[];
	fieldKey: FieldKey;
	fieldMappings: FieldMappings<unknown>;
	issueTypes: IssueType[];
};

export const getConnectionFieldValuesFromLinkedIssues = ({
	issue,
	allIssues,
	fieldKey,
	fieldMappings,
	issueTypes,
}: GetConnectionFieldValuesFromLinkedIssues): ConnectionFieldValue[] => {
	if (!('meta' in issue)) {
		return [];
	}

	const issueLinks = issue.meta.issueLinks.links || [];

	// const issueFilters = fieldMappings[fieldKey]?.field?.configuration?.issueFilters || [];
	if (
		// issueFilters.length === 0 || // TODO: bring this check back once issueFilters in connection field configuration is fully implemented
		issueLinks.length === 0
	) {
		return [];
	}

	return issueLinks.reduce<ConnectionFieldValue[]>(
		(result, { inwardIssue, outwardIssue, typeName }) => {
			if (typeName !== JPD_CONNECTION_ISSUE_LINK_TYPE) {
				return result;
			}

			// issue link used for connection doesn't respect the direction of the link, so we need to check both
			const linkedIssue = issue.id === inwardIssue.id.toString() ? outwardIssue : inwardIssue;
			const issueId = linkedIssue.id.toString();
			const issueType = issueTypes.find(
				({ jiraIssueTypeId }) => jiraIssueTypeId === linkedIssue.issueTypeId,
			);

			const matchesFilter = isMatchingConnectionFieldFilter({
				fieldKey,
				allIssues,
				fieldMappings,
				issueType,
				issueId,
			});

			// making a list of unique linked issues that match the filter from connection field configuration
			if (matchesFilter && !result.find(({ id }) => id === issueId)) {
				result.push({
					id: issueId,
				});
			}

			return result;
		},
		[],
	);
};

type IsMatchingConnectionFieldFilter = {
	issueId: string;
	allIssues: RemoteIssue[];
	fieldKey: FieldKey;
	fieldMappings: FieldMappings<unknown>;
	issueType: IssueType | undefined; // TODO: make issueType required once issue types are implemented
};

const isMatchingConnectionFieldFilter = ({
	issueId,
	allIssues,
	fieldKey,
	fieldMappings,
	issueType,
}: IsMatchingConnectionFieldFilter) => {
	const issueFilters = fieldMappings[fieldKey].field?.configuration?.issueFilters || [];

	// TODO: Remove this once issueFilters in connection field configuration is fully implemented
	if (issueFilters.length === 0) {
		return true;
	}

	return issueFilters.some((filter) => {
		const filterFieldMapping = fieldMappings[filter.fieldKey];
		const filterField = filterFieldMapping?.field;

		if (!filterFieldMapping || !filterField) {
			return false;
		}

		if (filterField.type === FIELD_TYPES.SINGLE_SELECT) {
			const linkedIssue = allIssues.find(({ id }) => id === issueId);
			if (!linkedIssue) {
				return false;
			}
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const value = (filterFieldMapping as FieldMapping<OptionFieldValue>).getValueFromJiraIssue(
				linkedIssue,
			);

			const optionValue = filterField?.options?.find(
				(option) => option.jiraOptionId === value?.id,
			)?.value;

			return optionValue && filter.values.includes(optionValue);
		}

		if (filterField.type === FIELD_TYPES.ISSUE_TYPE) {
			return issueType && filter.values.includes(issueType.name);
		}

		// In case of unsupported field used in filter ignore it
		return false;
	});
};
