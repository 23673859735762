/** @jsx jsx */
import React, { type ComponentPropsWithoutRef, type ReactNode } from 'react';
import { styled, css, keyframes, jsx } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css as cssSC } from 'styled-components';
import { xcss, Box } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { fg } from '@atlassian/jira-feature-gating';
import {
	type FIELD_DESCRIPTION_WRAPPER_COMPONENT_SELECTOR,
	FieldDescriptionWrapperControl,
} from '@atlassian/jira-issue-field-description/src/ui/popup/index.tsx';
import { FIELD_EDIT_BUTTON_COMPONENT_SELECTOR } from '@atlassian/jira-issue-field-edit-icon/src/constants';
import { FIELD_INFO_BUTTON_COMPONENT_SELECTOR } from '@atlassian/jira-issue-field-parent/src/ui/changeboarding';
import { FieldPinButtonControl } from '@atlassian/jira-issue-field-pin/src/main';
import { FIELD_PIN_BUTTON_COMPONENT_SELECTOR } from '@atlassian/jira-issue-field-pin/src/utils';

export const labelColumnWidthForGrouping = 40.0;

// eslint-disable-next-line @compiled/no-exported-keyframes, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const animationFadeIn = keyframes({
	from: {
		opacity: 0,
	},
	to: {
		opacity: 1,
	},
});

// eslint-disable-next-line @compiled/no-exported-keyframes, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const animationFadeOut = keyframes({
	from: {
		opacity: 1,
	},
	to: {
		opacity: 0,
	},
});

const fieldWrapperSelectorName = 'jira-issue-field-heading-field-wrapper';
export const FIELD_WRAPPER_COMPONENT_SELECTOR = `[data-component-selector="${fieldWrapperSelectorName}"]`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldWrapperComponent = styled.div({
	display: 'flex',
	flexWrap: 'wrap',
	marginBottom: token('space.200', '16px'),
	wordBreak: 'break-word',
});

export const FieldWrapper = (props: ComponentPropsWithoutRef<typeof FieldWrapperComponent>) => {
	return fg('jira_pin_field_configurability') ? (
		<JiraPinFieldConfigurabilityFieldWrapper {...props} />
	) : (
		<FieldWrapperComponent data-component-selector={fieldWrapperSelectorName} {...props} />
	);
};

const JiraPinFieldConfigurabilityFieldWrapperOld = (
	props: ComponentPropsWithoutRef<typeof FieldWrapperComponent>,
) => (
	<div
		css={FieldWrapperComponentForPinFieldConfigurabilityOld}
		data-component-selector={fieldWrapperSelectorName}
		{...props}
	/>
);

const JiraPinFieldConfigurabilityFieldWrapperNew = (
	props: ComponentPropsWithoutRef<typeof FieldWrapperComponent>,
) => (
	<div
		css={FieldWrapperComponentForPinFieldConfigurabilityNew}
		data-component-selector={fieldWrapperSelectorName}
		{...props}
	/>
);

const JiraPinFieldConfigurabilityFieldWrapper = componentWithFG(
	'issue_view_field_config_edit',
	JiraPinFieldConfigurabilityFieldWrapperNew,
	JiraPinFieldConfigurabilityFieldWrapperOld,
);

/**
 * @deprecated `fieldHeadingIconsCss` is consumed by other styled-components (e.g. src/packages/servicedesk/post-incident-review/src/ui/primary-incident/styled.tsx )
 * TODO: Remove `fieldHeadingIconsCss` once its consumers are migrated to CompiledCSS
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const fieldHeadingIconsCss = cssSC({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[`${FieldPinButtonControl}, ${FieldDescriptionWrapperControl}`]: {
		animation: `${animationFadeOut} 0.15s linear forwards`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`:hover ${FieldPinButtonControl}, :hover ${FieldDescriptionWrapperControl}, :focus-within ${FieldPinButtonControl}, :focus-within ${FieldDescriptionWrapperControl}`]:
		{
			animation: `${animationFadeIn} 0.15s linear forwards`,
		},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SideBySideField = styled.div<{
	hasTooltip?: boolean;
	isEditing?: boolean;
	fixedHeight?: boolean;
}>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.hasTooltip
			? {
					'& > div > div > div > div > div > div > div > div': {
						height:
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
							!props.isEditing && props.fixedHeight === true ? `${gridSize * 4}px` : undefined,
					},
				}
			: {
					'& > div > div > div > div > div > div > div': {
						height:
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
							!props.isEditing && props.fixedHeight === true ? `${gridSize * 4}px` : undefined,
					},
				},
	{
		flexGrow: 1,
		width: `${100 - labelColumnWidthForGrouping}%`,
		boxSizing: 'border-box',
	},
);

// APP-649 Workaround the static evaluation error with a type assertion
const FIELD_DESCRIPTION_WRAPPER_COMPONENT_SELECTOR_WORKAROUND: typeof FIELD_DESCRIPTION_WRAPPER_COMPONENT_SELECTOR =
	'[data-component-selector="jira-issue-field-description-field-description-wrapper"]';

const fieldHeadingMaxWidthCss = cssSC({
	maxWidth: '170px',
});

// FIXME: type error in object syntax
// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @atlaskit/design-system/no-css-tagged-template-expression
const fieldHeadingCompactMaxWidthCss = cssSC<any>`
    ${({ isCompact }) => (isCompact ? undefined : fieldHeadingMaxWidthCss)}
`;
/**
 * @deprecated `fieldHeadingSideBySideCss` is consumed by other styled-components (e.g. src/packages/development/releases-glance/src/view/styled.tsx)
 * TODO: Remove `fieldHeadingSideBySideCss` once its consumers are migrated to CompiledCSS
 */
// FIXME: type error in object syntax from passing result of `css` to `css`
// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const fieldHeadingSideBySideCss = cssSC`
    ${fieldHeadingIconsCss}
    position: relative;
    flex-grow: 1;
    box-sizing: border-box;
    padding-right: ${token('space.300', '24px')};
    min-width: 120px;
    width: ${labelColumnWidthForGrouping}%;
    padding-top: ${token('space.100', '8px')};
    line-height: 1;
    ${fieldHeadingCompactMaxWidthCss}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const FieldHeadingIconsVisibilityOld = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[`${FIELD_DESCRIPTION_WRAPPER_COMPONENT_SELECTOR_WORKAROUND}, ${FIELD_PIN_BUTTON_COMPONENT_SELECTOR}`]:
		{
			animation: `${animationFadeOut} 0.15s linear forwards`,
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`:hover ${FIELD_DESCRIPTION_WRAPPER_COMPONENT_SELECTOR_WORKAROUND}, :hover ${FIELD_PIN_BUTTON_COMPONENT_SELECTOR},
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ' '}
     :focus-within ${FIELD_DESCRIPTION_WRAPPER_COMPONENT_SELECTOR_WORKAROUND}, :focus-within ${FIELD_PIN_BUTTON_COMPONENT_SELECTOR}`]:
		{
			animation: `${animationFadeIn} 0.15s linear forwards`,
		},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const FieldHeadingIconsVisibilityNew = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[`${FIELD_DESCRIPTION_WRAPPER_COMPONENT_SELECTOR_WORKAROUND}, ${FIELD_PIN_BUTTON_COMPONENT_SELECTOR}, ${FIELD_EDIT_BUTTON_COMPONENT_SELECTOR}, ${FIELD_INFO_BUTTON_COMPONENT_SELECTOR}`]:
		{
			animation: `${animationFadeOut} 0.15s linear forwards`,
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`:hover ${FIELD_DESCRIPTION_WRAPPER_COMPONENT_SELECTOR_WORKAROUND}, :hover ${FIELD_PIN_BUTTON_COMPONENT_SELECTOR}, :hover ${FIELD_EDIT_BUTTON_COMPONENT_SELECTOR}, :hover ${FIELD_INFO_BUTTON_COMPONENT_SELECTOR},
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ' '}
     :focus-within ${FIELD_DESCRIPTION_WRAPPER_COMPONENT_SELECTOR_WORKAROUND}, :focus-within ${FIELD_PIN_BUTTON_COMPONENT_SELECTOR}, :focus-within ${FIELD_EDIT_BUTTON_COMPONENT_SELECTOR}, :focus-within ${FIELD_INFO_BUTTON_COMPONENT_SELECTOR}`]:
		{
			animation: `${animationFadeIn} 0.15s linear forwards`,
		},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FieldHeadingIconsVisibility = componentWithFG(
	'issue_view_field_config_edit',
	FieldHeadingIconsVisibilityNew,
	FieldHeadingIconsVisibilityOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FieldHeadingSideBySide = styled(FieldHeadingIconsVisibility)<{
	isCompact?: boolean;
	isMobile?: boolean;
}>(
	{
		position: 'relative',
		flexGrow: 1,
		boxSizing: 'border-box',
		paddingRight: token('space.300', '24px'),
		minWidth: '120px',
		width: `${labelColumnWidthForGrouping}%`,
		paddingTop: token('space.100', '8px'),
		lineHeight: 1,
		/*
        Cannot destructure props in css util like in original source due to
        https://github.com/atlassian-labs/compiled/issues/1086
    */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		maxWidth: (props) => (props.isCompact ? undefined : '170px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isMobile, isCompact }) =>
		isMobile &&
		isCompact && {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			minHeight: `${gridSize * 4}px`,
			paddingBottom: token('space.050', '4px'),
		},
);

/*
    Unfoiling typography.h200 as @atlaskit/heading doesn't support
    extending of className prop making it complicated to apply ellipsis
    without wrapper components

    https://bitbucket.org/atlassian/atlassian-frontend/src/master/packages/design-system/theme/src/typography.tsx
*/
const h200Styles = css({
	font: token('font.body'),
	fontWeight: token('font.weight.medium'),
	lineHeight: 16 / 12,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
	marginTop: token('space.200', '16px'),
});

const FieldWrapperComponentForPinFieldConfigurabilityOld = css({
	display: 'flex',
	flexWrap: 'wrap',
	marginBottom: token('space.200', '16px'),
	wordBreak: 'break-word',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[`${FIELD_DESCRIPTION_WRAPPER_COMPONENT_SELECTOR_WORKAROUND}, ${FIELD_PIN_BUTTON_COMPONENT_SELECTOR}`]:
		{
			animation: `${animationFadeOut} 0.15s linear forwards`,
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`:hover ${FIELD_DESCRIPTION_WRAPPER_COMPONENT_SELECTOR_WORKAROUND}, :hover ${FIELD_PIN_BUTTON_COMPONENT_SELECTOR},
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ' '}
     :focus-within ${FIELD_DESCRIPTION_WRAPPER_COMPONENT_SELECTOR_WORKAROUND}, :focus ${FIELD_PIN_BUTTON_COMPONENT_SELECTOR}`]:
		{
			animation: `${animationFadeIn} 0.15s linear forwards`,
		},
});
const FieldWrapperComponentForPinFieldConfigurabilityNew = css({
	display: 'flex',
	flexWrap: 'wrap',
	marginBottom: token('space.200', '16px'),
	wordBreak: 'break-word',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[`${FIELD_DESCRIPTION_WRAPPER_COMPONENT_SELECTOR_WORKAROUND}, ${FIELD_PIN_BUTTON_COMPONENT_SELECTOR}, ${FIELD_EDIT_BUTTON_COMPONENT_SELECTOR}, ${FIELD_INFO_BUTTON_COMPONENT_SELECTOR}`]:
		{
			animation: `${animationFadeOut} 0.15s linear forwards`,
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`:hover ${FIELD_DESCRIPTION_WRAPPER_COMPONENT_SELECTOR_WORKAROUND}, :hover ${FIELD_PIN_BUTTON_COMPONENT_SELECTOR}, :hover ${FIELD_EDIT_BUTTON_COMPONENT_SELECTOR}, :hover ${FIELD_INFO_BUTTON_COMPONENT_SELECTOR},
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ' '}
     :focus-within ${FIELD_DESCRIPTION_WRAPPER_COMPONENT_SELECTOR_WORKAROUND}, :focus ${FIELD_PIN_BUTTON_COMPONENT_SELECTOR}, :focus ${FIELD_EDIT_BUTTON_COMPONENT_SELECTOR}, :focus ${FIELD_INFO_BUTTON_COMPONENT_SELECTOR}`]:
		{
			animation: `${animationFadeIn} 0.15s linear forwards`,
		},
});

const fieldHeadingTitleSelectorName = 'jira-issue-field-heading-field-heading-title';
export const FIELD_HEADING_TITLE_COMPONENT_SELECTOR = `[data-component-selector="${fieldHeadingTitleSelectorName}"]`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const FieldHeadingTitleComponent = styled.h2(h200Styles, {
	boxSizing: 'border-box',
	color: token('color.text.subtle', '#44546F'),
});

export const FieldHeadingTitle = (
	props: ComponentPropsWithoutRef<typeof FieldHeadingTitleComponent>,
) => (
	<FieldHeadingTitleComponent data-component-selector={fieldHeadingTitleSelectorName} {...props} />
);

const multilineFieldHeadingTitleSelectorName =
	'jira-issue-field-heading-multiline-field-heading-title';
export const MULTILINE_FIELD_HEADING_TITLE_COMPONENT_SELECTOR = `[data-component-selector="${multilineFieldHeadingTitleSelectorName}"]`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const MultilineFieldHeadingTitleComponent = styled.h2(h200Styles, {
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
});

export const MultilineFieldHeadingTitle = (
	props: ComponentPropsWithoutRef<typeof MultilineFieldHeadingTitleComponent>,
) => (
	<MultilineFieldHeadingTitleComponent
		data-component-selector={multilineFieldHeadingTitleSelectorName}
		{...props}
	/>
);

const fieldHeadingIconContainerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	gap: 'space.100',
});

export const FieldHeadingIconsContainer = ({
	children,
	isCustomFieldConfigurationEnabled,
}: {
	children: ReactNode;
	isCustomFieldConfigurationEnabled: boolean;
}) =>
	isCustomFieldConfigurationEnabled ? (
		<Box xcss={fieldHeadingIconContainerStyles}>{children}</Box>
	) : (
		children
	);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MultilineFieldHeading = styled(FieldHeadingIconsVisibility)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	marginBottom: `${token('space.050', '4px')} !important`,
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MultilineFieldHeadingView = styled(MultilineFieldHeading)({
	width: 'fit-content',
	borderRadius: '4px',
	padding: `${token('space.100')} ${token('space.050')}`,
	'&:hover': {
		backgroundColor: token('color.background.neutral.hovered'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledFieldHeading = styled(FieldHeadingSideBySide)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[`
      ${FIELD_DESCRIPTION_WRAPPER_COMPONENT_SELECTOR_WORKAROUND},
      ${FIELD_HEADING_TITLE_COMPONENT_SELECTOR},
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ' '}
      ${FIELD_PIN_BUTTON_COMPONENT_SELECTOR}
    `]: {
		display: 'inline-flex',
		verticalAlign: 'bottom',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& div': {
			display: 'inline-flex',
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BlankFieldHeading = styled(StyledFieldHeading)({
	padding: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CustomFieldHeadingWithBottomMargin = styled(StyledFieldHeading)({
	marginBottom: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DevPanelIconContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 3}px`,
	marginLeft: token('space.025', '2px'),
	marginRight: token('space.negative.025', '-2px'),
	marginTop: token('space.negative.050', '-4px'),
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FieldHeadingIcons = styled.div({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginRight: '10px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${1.5 * gridSize}px`,
});
