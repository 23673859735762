import React, { memo } from 'react';
import Spinner from '@atlaskit/spinner';
import { PaddedFlexContainer } from '../../common/ui/styled';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LoadingSpinner = memo<Record<any, any>>(() => (
	<PaddedFlexContainer>
		<Spinner size="small" />
	</PaddedFlexContainer>
));
