import type {
	SelectValueShape,
	Option as SelectOption,
	SelectValueShape as MultiSelectValueItemShape,
} from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';
import type { SelectableValueOption } from '@atlassian/jira-issue-selectable-field-edit-view/src/ui/types';
import type { SelectFieldOption } from '@atlassian/jira-issue-shared-types/src/common/types/select-field-option-type.tsx';

const toItem = (fieldData: SelectFieldOption): MultiSelectValueItemShape => ({
	value: fieldData.id,
	content: fieldData.value || '',
	ari: fieldData.ari,
});

export const transformFromStateValue = (
	value: SelectFieldOption[] | null,
): MultiSelectValueItemShape[] => (value ? value.map(toItem) : []);

export const transformToStateValue = (
	value: MultiSelectValueItemShape[] | null,
): SelectFieldOption[] | null =>
	value &&
	value.map((item) => ({
		id: String(item.value),
		value: item.content,
		ari: item.ari,
	}));

export const transformRelayToReduxValue = (option: SelectableValueOption): SelectOption => ({
	value: option.optionId ?? '',
	label: option.selectableLabel ?? '',
	content: option.selectableLabel ?? '',
	ari: option.id,
});

/**
 * Transforms a redux value to a relay selectable value.
 * @param option of `SelectValueShape`
 * @returns SelectableValueOption
 */
export const transformReduxToRelayValue = (option: SelectValueShape): SelectableValueOption => ({
	optionId: String(option.value),
	selectableLabel: option.content || '',
	selectableGroupKey: null,
	selectableIconUrl: null,
	id: option.ari || '',
});
