import React, { type ComponentType, type FC } from 'react';
import { lazy } from 'react-loosely-lazy';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type { Props } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/deprecations/no-rll-client-async-experiences
const LazyLoadFeedback = lazy<ComponentType<any>>(
	() => import(/* webpackChunkName: "async-issue-details-feedback-collector" */ './feedback'),
);

const FeedbackButtonRenderer: FC<Props> = (props) => (
	<ErrorBoundary id="issue.issue-view.feedback-button" packageName="issue" render={() => null}>
		<Placeholder name="feedback-button" fallback={null}>
			<LazyLoadFeedback {...props} />
		</Placeholder>
	</ErrorBoundary>
);

export default FeedbackButtonRenderer;
