import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { trackFetchErrors } from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getEscalatorBaseUrl } from '@atlassian/jira-servicedesk-incident-management-common/src/utils';
import type { IssueId, CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import commonMessages from '../../../common/messages';
import { FETCH_LINKED_ALERTS_ERROR } from '../../constants';
import type { EscalatorLinkedAlert, State } from '../../types';
import { transformEscalatorAlertResponse } from '../../utils/transform-alert';
import messages from './messages';

type Response = {
	results: EscalatorLinkedAlert[];
	startKey: number;
};

type Args = {
	issueId: IssueId | undefined;
	cloudId: CloudId;
	hasOpsgenieAccount: boolean | undefined;
	analyticsEvent: UIAnalyticsEvent;
	startIndex?: number | null;
};

export const fetchLinkedAlerts =
	({ issueId, cloudId, hasOpsgenieAccount = false, analyticsEvent, startIndex }: Args) =>
	async ({ getState, setState }: StoreActionApi<State>) => {
		const { linkedAlerts } = getState();
		const { showFlag } = useFlagsService();

		if (linkedAlerts.isLoading || !issueId || !hasOpsgenieAccount) {
			return;
		}

		// This is to avoid a refetch showing empty state when data exists
		const data = linkedAlerts.forIssueId === issueId ? linkedAlerts.data : undefined;
		setState({
			linkedAlerts: {
				forIssueId: issueId,
				data,
				error: undefined,
				isLoading: true,
				startIndex: null,
			},
		});

		try {
			const requestUri = startIndex
				? `/issue/${issueId}/incident/associated-alerts?size=25&startKey=${startIndex}`
				: `/issue/${issueId}/incident/associated-alerts?size=25`; // this param is hardcoded here for a reason, see: GHOST-5316
			const response: Response = await fetchJson(`${getEscalatorBaseUrl(cloudId)}${requestUri}`);

			const result = response.results?.map((alert: EscalatorLinkedAlert) =>
				transformEscalatorAlertResponse(alert),
			);

			const mergedData =
				linkedAlerts?.data && startIndex
					? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						[...(linkedAlerts?.data as []), ...result]
					: result;
			setState({
				linkedAlerts: {
					...linkedAlerts,
					forIssueId: issueId,
					isLoading: false,
					data: mergedData,
					startIndex: response.startKey,
				},
			});

			analyticsEvent && fireOperationalAnalytics(analyticsEvent, 'fetchLinkedAlerts succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			showFlag({
				type: 'error',
				title: messages.errorTitle,
				description: commonMessages.errorDescription,
			});

			trackFetchErrors({
				event: analyticsEvent,
				error: e,
				id: 'fetchLinkedAlerts',
				sendToPrivacyUnsafeSplunk: true,
			});

			setState({
				linkedAlerts: {
					...linkedAlerts,
					forIssueId: issueId,
					isLoading: false,
					error: { value: e.message, type: FETCH_LINKED_ALERTS_ERROR },
				},
			});
		}
	};
