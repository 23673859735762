import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { TransitionsQuery as OriginalTransitionsQuery } from './main';
import type { Props } from './types';

const WrappedTransitionsQuery = (props: Props) => (
	<ErrorBoundary id="issue.fields.status.transitions-query">
		<ReportErrors
			id="fields.status.transitions-query"
			packageName="jiraIssueFieldStatus"
			sendToPrivacyUnsafeSplunk
		>
			<OriginalTransitionsQuery {...props} />
		</ReportErrors>
	</ErrorBoundary>
);

export { WrappedTransitionsQuery as TransitionsQuery };
