import memoizeOne from 'memoize-one';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages';
import type {
	SaveFieldArguments,
	OwnProps,
} from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field';
import { updateRequestType } from '@atlassian/jira-issue-view-services/src/issue/customer-request-type-server';
import {
	baseUrlSelector,
	issueIdSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { issueTypeSelector } from '@atlassian/jira-issue-view-store/src/selectors/breadcrumbs-selector';
import { canEditIssuesSelector } from '@atlassian/jira-issue-view-store/src/selectors/issue-actions-permissions-selector';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages';
import {
	transformFromStateValue,
	transformToStateValue,
	transformSuggestions,
} from './transformer';
import { RequestTypeField } from './view';

const DEBOUNCE_FETCH_SUGGESTIONS_TIME = 300;

const getSuggestionUrl = (baseUrl: string, issueId?: IssueId) => {
	if (issueId) {
		return `${baseUrl}/rest/servicedesk/1/servicedesk/request/${issueId}/request-types?query=`;
	}
	return null;
};

export const fetchSuggestionsFactory = memoizeOne((autoCompleteUrl) => (query: string) => {
	if (!autoCompleteUrl) {
		return Promise.resolve([]);
	}
	return fetchJson(`${autoCompleteUrl}${query}`).then(transformSuggestions);
});

export const saveField = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	{ baseUrl, value }: SaveFieldArguments<any>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	state: any, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
	const { requestType } = value;
	const issueId = issueIdSelector(state);
	return updateRequestType(baseUrl, issueId, requestType.id);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const analyticsAttributeIdRetriever = (value: any): string =>
	value && value.requestType && value.requestType.id;

export default connectField((_, ownPropsOnMount: OwnProps) => ({
	fieldId: ownPropsOnMount.fieldId,
	transformFromStateValue,
	transformToStateValue,
	saveField,
	additionalProps: (state, intl) => ({
		fetchSuggestions: fetchSuggestionsFactory(
			getSuggestionUrl(baseUrlSelector(state), issueIdSelector(state)),
		),
		isEditable: canEditIssuesSelector(state),
		issueType: issueTypeSelector(state),
		hasAutocomplete: true,
		placeholder: intl.formatMessage(messages.placeholder),
		noValueText: intl.formatMessage(genericMessages.noValue),
		debounceFetchSuggestionsTime: DEBOUNCE_FETCH_SUGGESTIONS_TIME,
		analyticsAttributeIdRetriever,
		showPinButton: getShowPinButton(ownPropsOnMount.area),
	}),
}))(RequestTypeField);
