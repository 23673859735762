import React, { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import SquareAddIconButton from '@atlassian/jira-issue-view-common-views/src/button/square-add-icon-button';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { attachmentPickerPopupOpenSuccess } from '@atlassian/jira-issue-view-store/src/actions/attachment-popup-actions';
import { isServiceDeskSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { canCreateAttachmentsSelector } from '@atlassian/jira-issue-view-store/src/selectors/attachment-selector';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from '../messages';
import { ButtonWrapper } from '../styled';
import type { Props, StateProps } from './types';

export const AddAttachments = ({ canCreateAttachment, onClick }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	const handleClick = useCallback(() => {
		onClick();
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
		});
		fireUIAnalytics(analyticsEvent, 'addAttachment');
	}, [createAnalyticsEvent, onClick]);

	if (!canCreateAttachment) return null;

	return (
		<ButtonWrapper
			/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
			data-testid="issue.views.issue-base.content.attachment.heading.add-attachment.button-wrapper"
		>
			<SquareAddIconButton
				label={formatMessage(messages.addAttachmentAriaLabel)}
				onClick={handleClick}
			/>
		</ButtonWrapper>
	);
};

export default connect(
	(state: State): StateProps => ({
		canCreateAttachment: !isServiceDeskSelector(state) && canCreateAttachmentsSelector(state),
	}),
	{
		onClick: attachmentPickerPopupOpenSuccess,
	},
)(AddAttachments);
