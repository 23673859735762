import React from 'react';
import memoizeOne from 'memoize-one';
import {
	performPutRequest,
	performPostRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { Props as ParentProps } from '@atlassian/jira-issue-internal-fields/src/parent/view';
import ParentField from '@atlassian/jira-issue-internal-fields/src/parent/view.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages';
import { getIssueModalAkDropdownPortal } from '@atlassian/jira-issue-view-common-utils/src/get-element/index.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field';
import { getIssueApiUrl } from '@atlassian/jira-issue-view-services/src/issue/issue-urls';
import { baseUrlSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { fieldPendingValueSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector';
import { isParentTypeVisibleSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-visibility-selector';
import { projectIdSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import { epicSelector } from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/epic-selector';
import { issueTypeIdSelector } from '@atlassian/jira-issue-view-store/src/selectors/breadcrumbs-selector';
import { isFieldInTabSelector } from '@atlassian/jira-issue-view-store/src/selectors/tab-selector';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	transformFromStateValue,
	transformToStateValue,
	transformSuggestions,
} from './parent-transformer';

const fetchSuggestionsFactory = memoizeOne(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(baseUrl, projectId, issueTypeId, initialValue) => (query: any) =>
		issueTypeId
			? performPostRequest(`${baseUrl}/rest/jpo/1.0/parent/suggest`, {
					body: JSON.stringify({
						query,
						projectId,
						issueTypeId,
						maxResults: 100,
					}),
				}).then((suggestion) => transformSuggestions(suggestion, baseUrl, initialValue))
			: [],
);

type SaveFieldArg = {
	baseUrl: BaseUrl;
	issueKey: IssueKey;
	fieldMetaKey: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any;
};
type Props = {
	isVisible: boolean;
} & ParentProps & {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		epic: any;
	};

const ParentView = ({ epic, isVisible, ...otherProps }: Props) => {
	if (!isVisible) {
		return null;
	}

	const hasEpic = epic !== null && epic !== undefined;
	// @ts-expect-error - TS2783 - 'hasEpic' is specified more than once, so this usage will be overwritten.
	return <ParentField hasEpic={hasEpic} {...otherProps} />;
};

export default connectField((stateOnMount, ownPropsOnMount) => ({
	fieldId: ownPropsOnMount.fieldId,
	transformFromStateValue: (state, context) => transformFromStateValue(state, context),
	transformToStateValue,
	additionalProps: (state, intl) => ({
		fetchSuggestions: fetchSuggestionsFactory(
			baseUrlSelector(state),
			projectIdSelector(state),
			issueTypeIdSelector(state),
			fieldPendingValueSelector(ownPropsOnMount.fieldId)(state),
		),
		noValueText: intl.formatMessage(genericMessages.noValue),
		epic: epicSelector(state),
		isVisible: isParentTypeVisibleSelector(ownPropsOnMount.fieldId)(state),
		portalElement: isFieldInTabSelector(ownPropsOnMount.fieldId)(state) // Using portalElement because otherwise dropdowns would get cut off by the container borders. See BENTO-4101
			? // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				getIssueModalAkDropdownPortal() || document.body
			: undefined,
		showPinButton: getShowPinButton(ownPropsOnMount.area),
	}),

	saveField: ({ baseUrl, issueKey, fieldMetaKey, value }: SaveFieldArg) =>
		performPutRequest(getIssueApiUrl(baseUrl, issueKey), {
			body: JSON.stringify({
				fields: {
					[fieldMetaKey]: value && value.data ? value.data.key : null,
				},
			}),
		}),
}))(ParentView);
