import type { ProductType } from '@atlaskit/linking-common';
import { useSubProduct } from '@atlassian/jira-subproduct';

// converts the Jira subproduct into the product= prop that SmartCardProvider expects, also used by the SDK for analytics purposes
export const useJiraLoomSDKProductName = (): ProductType | undefined => {
	const subProduct = useSubProduct();
	switch (subProduct) {
		case 'software':
			return 'JSW';
		case 'core':
			return 'JWM';
		case 'polaris':
			return 'JPD';
		case 'serviceDesk':
			return 'JSM';
		default:
			return undefined;
	}
};

interface WaitUntilOptions<T> {
	fn: () => Promise<T>;
	condition: (result: T) => boolean;
	retryWait: number;
	maxTries: number;
}

interface WaitUntilResult<T> {
	result: T;
	ok: boolean;
	tries: number;
}

// Poll a callback until a condition is met, up to a fixed amount of times.
export const waitUntil = async <T,>({
	fn,
	condition,
	retryWait,
	maxTries,
}: WaitUntilOptions<T>): Promise<WaitUntilResult<T>> => {
	let tries = 0;
	// eslint-disable-next-line no-constant-condition
	while (true) {
		tries++;
		// eslint-disable-next-line no-await-in-loop
		const result = await fn();
		const ok = condition(result);
		if (tries === maxTries || ok) {
			return { ok, result, tries };
		}
		// eslint-disable-next-line no-await-in-loop
		await new Promise((resolve) => setTimeout(resolve, retryWait));
	}
};
