import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type { Props } from './view';
import type SyncRequestFeedback from './index';

const RequestFeedback = lazyForPaint<typeof SyncRequestFeedback>(
	() => import(/* webpackChunkName: "async-request-feedback" */ './index'),
);

const AsyncRequestFeedback = (props: Props) => (
	<ErrorBoundary id="jsmRequestFeedback" packageName="atlassian/jira-issue-view">
		<Placeholder name="async-request-feedback" fallback={null}>
			<RequestFeedback {...props} />
		</Placeholder>
	</ErrorBoundary>
);

export default AsyncRequestFeedback;
