import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getEscalatorBaseUrl } from '@atlassian/jira-servicedesk-incident-management-common/src/utils';
import type { Action } from '@atlassian/react-sweet-state';
import type { State, ContainerProps } from '../../types';
import messages from './messages';

type UpdateStakeholderPayload = {
	message: string;
	description: string;
};

export const updateStakeholder =
	(stakeholderPayload: UpdateStakeholderPayload): Action<State, ContainerProps> =>
	async (
		{ getState, setState },
		{ issueId, cloudId, analyticsEvent, onCloseUpdateStakeholderModal },
	) => {
		const { showFlag } = useFlagsService();
		const { message, description } = stakeholderPayload;
		const {
			informModal: { isUpdateStakeholderLoading, ...rest },
			issueVersion,
		} = getState();

		if (isUpdateStakeholderLoading) {
			return;
		}

		if (!issueId) {
			setState({
				stakeholders: {
					error: 'Missing issueId',
				},
			});
			return;
		}

		setState({
			informModal: {
				...rest,
				isUpdateStakeholderLoading: true,
			},
		});

		const url = issueVersion.isNew
			? `/gateway/api/jsm/stakeholders/cloudId/${cloudId}/v1/stakeholders/issue/${issueId}/status-update        `
			: `${getEscalatorBaseUrl(cloudId)}/issue/${issueId}/incident/communication/status-update`;

		try {
			await performPostRequest(url, { body: JSON.stringify({ message, description }) });
			setState({
				informModal: {
					...rest,
					isUpdateStakeholderLoading: false,
				},
			});
			showFlag({
				type: 'success',
				title: issueVersion.isNew ? messages.updateSuccess : messages.updateStakeholderSuccess,
			});
			// https://data-portal.internal.atlassian.com/analytics/registry/41055
			fireOperationalAnalytics(analyticsEvent, 'updateStakeholder succeeded');
			onCloseUpdateStakeholderModal?.();
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			// https://data-portal.internal.atlassian.com/analytics/registry/41054
			fireErrorAnalytics({
				event: analyticsEvent,
				meta: {
					id: 'updateStakeholder',
					packageName: 'jiraJsmStakeholderUpdates',
					teamName: 'itsm-agni',
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
			setState({
				informModal: {
					...rest,
					isUpdateStakeholderLoading: false,
				},
			});
			showFlag({
				type: 'error',
				title: issueVersion.isNew ? messages.updateError : messages.updateStakeholderError,
			});
		}
	};
