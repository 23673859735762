import React from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import useConvertToSubtask from './hook';
import type { Props } from './types';

const ConvertToSubtask = ({ onClick, label, itemKey }: Props) => {
	const handleClick = useConvertToSubtask(itemKey, onClick);

	return (
		<DropdownItem key="convert-to-subtask" onClick={handleClick}>
			{label}
		</DropdownItem>
	);
};

export default ConvertToSubtask;
