import type { OnChangeCallback } from '../../model/on-change-callback';
import type { ContextState } from './types';

// SET_CONTEXT
export const SET_CONTEXT = 'state.context.SET_CONTEXT' as const;

export const setContext = (payload: ContextState) => ({
	type: SET_CONTEXT,
	payload,
});

export type SetContextAction = ReturnType<typeof setContext>;

export const SET_IS_ISSUE_VIEW_COMPLETE = 'state.context.SET_IS_ISSUE_VIEW_COMPLETE' as const;

export const setIsIssueViewComplete = (payload: boolean) => ({
	type: SET_IS_ISSUE_VIEW_COMPLETE,
	payload,
});

export type SetIsIssueViewCompleteAction = ReturnType<typeof setIsIssueViewComplete>;

// REQUIRES_FULL_CREATE_CHECK
export const REQUIRES_FULL_CREATE_CHECK = 'state.context.REQUIRES_FULL_CREATE_CHECK' as const;

export const requiresFullCreateCheckRequest = () => ({
	type: REQUIRES_FULL_CREATE_CHECK,
});

export type RequiresFullCreateCheckRequestAction = ReturnType<
	typeof requiresFullCreateCheckRequest
>;

// REQUIRES_FULL_CREATE_CHECK_SUCCESS
export const REQUIRES_FULL_CREATE_CHECK_SUCCESS =
	'state.context.REQUIRES_FULL_CREATE_CHECK_SUCCESS' as const;

export const requiresFullCreateCheckByIssueTypeSuccess = (requiresFullCreateByIssueType: {
	[issueTypeId: string]: boolean;
}) => ({
	type: REQUIRES_FULL_CREATE_CHECK_SUCCESS,
	payload: { requiresFullCreateByIssueType },
});

export type RequiresFullCreateCheckSuccessAction = ReturnType<
	typeof requiresFullCreateCheckByIssueTypeSuccess
>;

// REQUIRES_FULL_CREATE_CHECK_FAILED
export const REQUIRES_FULL_CREATE_CHECK_FAILED =
	'state.context.REQUIRES_FULL_CREATE_CHECK_FAILED' as const;

export const requiresFullCreateCheckFailed = () => ({
	type: REQUIRES_FULL_CREATE_CHECK_FAILED,
});

export type RequiresFullCreateCheckFailedAction = ReturnType<typeof requiresFullCreateCheckFailed>;

// SET_ONCHANGE
export const SET_ONCHANGE = 'state.context.SET_ONCHANGE' as const;

export const setOnChange = (payload: OnChangeCallback) => ({
	type: SET_ONCHANGE,
	payload,
});

export type SetOnChangeAction = ReturnType<typeof setOnChange>;

export type Action =
	| SetContextAction
	| SetOnChangeAction
	| SetIsIssueViewCompleteAction
	| RequiresFullCreateCheckRequestAction
	| RequiresFullCreateCheckSuccessAction
	| RequiresFullCreateCheckFailedAction;
