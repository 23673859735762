import type { UIAnalyticsEvent, AnalyticsEventPayload } from '@atlaskit/analytics-next';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import type { ChildIssue } from '@atlassian/jira-issue-view-common-types/src/children-issues-type';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import {
	getNoValueText,
	getEpicLinkFieldKey,
	getChildIssuesPanelType,
	getParentKey,
	getBaseUrl,
	getIssueTypeWFValidators,
	getParentIssueTypeId,
	getProjectId,
} from '../state/context/selectors';
import { childCreatedInFullDialog, createChildSuccess } from '../state/entities/actions';
import {
	getAllIssues,
	getIncompleteIssues,
	getIssueHierarchyLevel,
	getIssueTypes,
} from '../state/entities/selectors';
import type { State } from '../state/types';
import { CHILD_PANEL_VIEWS } from '../state/ui/actions';
import { getAddClickCount, getChildPanelView } from '../state/ui/selectors';
import AddExistingIssue from './add-existing-issue';
import Heading from './heading';
import InlineCreate from './inline-create';
import Issues from './issues';
import ProgressSummary from './progress-summary';
import { ChildIssuesPanel } from './view';

export default flowWithSafeComponent(
	// @ts-expect-error - Argument of type '<T extends ComponentType<any>, Props = ComponentPropsWithRef<T>>(WrappedComponent: T) => FC<Omit<{ [K in keyof Props]: K extends "onFullDialogCreate" | "onSuggestedChildIssueCreateSuccess" ? (...args: [......]) => ReturnType<...> : Props[K]; }, keyof WithAnalyticsEventsProps>>' is not assignable to parameter of type 'FlowStep<T, ComponentType<Matching<({ Heading: ConnectedComponent<typeof Heading, { childIssuesLimitStatus: ChildIssuesLimitStatus; sourceName?: string | undefined; onAddChildClick: AddChildEventHandler | undefined; issueHierarchyLevel: number | undefined; context?: Context<...> | undefined; store?: Store<...> | und...'.
	ComponentWithAnalytics('childIssuePanelInlineCreate', {
		onFullDialogCreate: 'onFullDialogCreate',
		onSuggestedChildIssueCreateSuccess: 'onSuggestedChildIssueCreateSuccess',
	}),
	connect(
		(state: State) => {
			if (!state) {
				// Monitoring has shown that state can be `undefined` but we have
				// not been able to replicate it.
				return {
					Heading,
					InlineCreate,
					Issues,
					ProgressSummary,
					AddExistingIssue,
					childPanelView: CHILD_PANEL_VIEWS.InlineCreate,
					allIssues: [],
					incompleteIssues: [],
					addClickCount: 0,
					noValueText: null,
					projectId: null,
					parentIssueTypeId: null,
				};
			}

			return {
				Heading,
				InlineCreate,
				Issues,
				ProgressSummary,
				AddExistingIssue,
				childPanelView: getChildPanelView(state),
				allIssues: getAllIssues(state),
				incompleteIssues: getIncompleteIssues(state),
				addClickCount: getAddClickCount(state),
				noValueText: getNoValueText(state),
				childIssuesPanelType: getChildIssuesPanelType(state),
				issueHierarchyLevel: getIssueHierarchyLevel(state),
				epicLinkFieldKey: getEpicLinkFieldKey(state),
				parentIssueKey: getParentKey(state),
				baseUrl: getBaseUrl(state),

				// getIssueTypes only gets the issue types available for child issues
				childIssueTypes: getIssueTypes(state),
				issueTypeWithWorkflowValidators: getIssueTypeWFValidators(state),
				projectId: getProjectId(state),
				parentIssueTypeId: getParentIssueTypeId(state),
			};
		},
		{
			onFullDialogCreate: (
				optimisticId: string,
				childIssue: ChildIssue,
				analyticsEvent: UIAnalyticsEvent,
			) => childCreatedInFullDialog(optimisticId, childIssue, analyticsEvent),
			onSuggestedChildIssueCreateSuccess: (
				createdChild: ChildIssue,
				additionalAttributes: AnalyticsEventPayload['attributes'],
				analyticsEvent: UIAnalyticsEvent,
			) =>
				createChildSuccess(
					createdChild.id,
					createdChild,
					analyticsEvent,
					false,
					additionalAttributes,
				),
		},
	),
)(ChildIssuesPanel);
