import { Component } from 'react';
import { dismissFlag as dismissFlagAction } from '../../services/dismiss';
import type { Flag as FlagType, FlagService, FlagId } from '../../services/types';

type Props = {
	flag: FlagType;
	flagService: FlagService;
};

// eslint-disable-next-line jira/react/no-class-components
export default class PureFlagRenderer extends Component<Props> {
	componentDidMount() {
		const { flagService, flag } = this.props;
		if (flagService) {
			this.flagId = flagService.showFlag(flag);
		}
	}

	componentWillUnmount() {
		const { flagService } = this.props;
		if (flagService && this.flagId) {
			flagService.dismissFlag(dismissFlagAction(this.flagId));
		}
	}

	flagId: FlagId | undefined;

	render() {
		return null;
	}
}
