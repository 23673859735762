import type { Dispatch } from 'redux';
import memoizeOne from 'memoize-one';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import versionsCache from '@atlassian/jira-cache/src/services/versions/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import FixVersionsInlineEditView from '@atlassian/jira-issue-internal-fields/src/fix-version/view';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field';
import {
	baseUrlSelector,
	isMobileSelector,
	issueIdSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import {
	fieldAllowedValuesSelector,
	getVersionsFieldIds,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector';
import { projectIdSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import {
	canAdministerProjectPermissionsSelector,
	canAdministerJiraPermissionsSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/permissions-selector';
import { fieldAddAllowedValues } from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-actions';
import {
	fireUIAnalytics,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { Version } from '@atlassian/jira-version-modals/src/common/model/index.tsx';
import messages from './messages';
import versionsRanker from './versions-ranker';
import {
	transformFromStateValueWithVersionLink as transformFromStateValue,
	transformToStateValue,
	transformSuggestionsFromServerAndCache,
} from './versions-transformer';

const rankVersions = memoizeOne(
	(versionFieldId, suggestion, issueId, projectId, sessionId, createAnalyticsEvent) =>
		versionsRanker(versionFieldId, suggestion, issueId, projectId, sessionId, createAnalyticsEvent),
);

const fetchSuggestionsFactory = memoizeOne(
	(suggestions, intl, versionFieldId, issueId, projectId, createAnalyticsEvent) =>
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(query: any, sessionId: any) => {
			const dataFromServer = rankVersions(
				versionFieldId,
				suggestions,
				issueId,
				projectId,
				sessionId,
				createAnalyticsEvent,
			);

			return Promise.all([dataFromServer, versionsCache.refresh()]).then(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				async ([dataFromAPI, cache]: [any, any]) =>
					transformSuggestionsFromServerAndCache(dataFromAPI, cache, intl, projectId),
			);
		},
);

const saveField = (
	// @ts-expect-error - TS7031 - Binding element 'baseUrl' implicitly has an 'any' type. | TS7031 - Binding element 'issueKey' implicitly has an 'any' type. | TS7031 - Binding element 'fieldMetaKey' implicitly has an 'any' type. | TS7031 - Binding element 'value' implicitly has an 'any' type. | TS7031 - Binding element 'fieldEditSessionId' implicitly has an 'any' type.
	{ baseUrl, issueKey, fieldMetaKey, value, fieldEditSessionId },
	// @ts-expect-error - TS2304 - Cannot find name 'State'.
	state: State,
	// @ts-expect-error - TS7006 - Parameter 'ownPropsOnMount' implicitly has an 'any' type.
	ownPropsOnMount,
) => {
	const url =
		fieldEditSessionId !== undefined
			? `${baseUrl}/rest/api/2/issue/${issueKey}?fieldEditSessionId=${fieldEditSessionId}`
			: `${baseUrl}/rest/api/2/issue/${issueKey}`;

	const projectId = projectIdSelector(state);
	// @ts-expect-error - TS7006 - Parameter 'current' implicitly has an 'any' type.
	value.forEach((current) => {
		try {
			if (ownPropsOnMount.createAnalyticsEvent) {
				fireOperationalAnalytics(
					ownPropsOnMount.createAnalyticsEvent({}),
					'versionPickerCache set',
					{
						isFromCache: current.fromCache,
					},
				);
			}
			current.id && versionsCache.set(`${current.id}`, { ...current, projectId });
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			log.safeErrorWithoutCustomerData('issue-view.versions.cache.set', error.message);
		}
	});

	return performPutRequest(url, {
		body: JSON.stringify({
			fields: {
				[fieldMetaKey]: value,
			},
		}),
	}).catch((error) => {
		// @ts-expect-error - TS7006 - Parameter 'current' implicitly has an 'any' type.
		value.forEach((current) => {
			try {
				if (ownPropsOnMount.createAnalyticsEvent) {
					fireOperationalAnalytics(
						ownPropsOnMount.createAnalyticsEvent({}),
						'versionPickerCache removed',
					);
				}
				current.id && versionsCache.remove(`${current.id}`);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				log.safeErrorWithoutCustomerData('issue-view.versions.cache.remove', e.message);
			}
		});

		throw error;
	});
};

const additionalCallbacksFactory = memoizeOne((dispatch: Dispatch) => ({
	onVersionCreated: (version: Version, versionFieldIds: string[]) => {
		versionFieldIds.forEach((fieldId) => {
			dispatch(fieldAddAllowedValues(fieldId, [version]));
		});
	},
}));

export default connectField((stateOnMount, ownPropsOnMount) => {
	const onTagClick = (analyticEvent: UIAnalyticsEvent) =>
		fireUIAnalytics(analyticEvent, { name: ownPropsOnMount.fieldId });

	// use memoized factory in the absence of redux-thunk
	const getDataFromCacheFactory = memoizeOne(
		(projectId) => () =>
			versionsCache
				.getAll()
				.then((versions) =>
					transformSuggestionsFromServerAndCache([], versions, ownPropsOnMount.intl, projectId),
				),
	);

	return {
		fieldId: ownPropsOnMount.fieldId,
		transformFromStateValue,
		transformToStateValue,
		additionalProps: (state, intl) => ({
			fetchSuggestions: fetchSuggestionsFactory(
				fieldAllowedValuesSelector(ownPropsOnMount.fieldId)(state),
				intl,
				ownPropsOnMount.fieldId,
				issueIdSelector(state),
				projectIdSelector(state),
				ownPropsOnMount.createAnalyticsEvent,
			),
			canAdministerProject: canAdministerProjectPermissionsSelector(state),
			canAdministerJira: canAdministerJiraPermissionsSelector(state),
			createNewItemMessage: intl.formatMessage(messages.createNewItem),
			baseUrl: baseUrlSelector(state),
			projectId: projectIdSelector(state),
			isMobile: isMobileSelector(state),
			placeholder: intl.formatMessage(genericMessages.version_no_value_placeholder),
			noValueText: intl.formatMessage(genericMessages.noValue),
			// Passing these in so we can use them in `onVersionCreated` down below.
			// There appears to be no way to reference them directly in the 'onVersionCreated' method
			versionFieldIds: getVersionsFieldIds(state),
			onTagClick,
			showPinButton: getShowPinButton(ownPropsOnMount.area),
			getDataFromCache: getDataFromCacheFactory(projectIdSelector(state)),
		}),
		additionalCallbacks: additionalCallbacksFactory,
		// @ts-expect-error - TS2345 - Argument of type 'SaveFieldArguments<unknown>' is not assignable to parameter of type '{ baseUrl: any; issueKey: any; fieldMetaKey: any; value: any; fieldEditSessionId: any; }'.
		saveField: (...args) => saveField(...args, ownPropsOnMount),
	};
})(FixVersionsInlineEditView);
