import React, { useCallback, useMemo } from 'react';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { useScreenTabs as useUiModificationsScreenTabs } from '@atlassian/jira-issue-adjustments/src/controllers/use-screen-tabs/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { IssueViewRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { useIssueFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { useFieldsValues } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import {
	CONTENT_AREA,
	isLayoutContainerTabItem,
	type LayoutContainerTemplateItem,
} from '@atlassian/jira-issue-layout-common-constants';
import { isForgeUiModificationsScreenTabsSupportEnabled } from '@atlassian/jira-issue-view-feature-flags';
import { ItemList } from '@atlassian/jira-issue-view-layout-templates-item-list';
import {
	TabViewOld,
	TabViewNew,
} from '@atlassian/jira-issue-view-layout-templates-tab-view/src/ui/view';
import {
	extractTabArrayNew,
	isTabFieldVisibleUtil,
} from '@atlassian/jira-issue-view-layout-templates-tab-view/src/utils';
import { getLayoutFieldTypes } from '@atlassian/jira-platform-field-config';

type Props = {
	// TODO Decomp BENTO-12514 - add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment?: IssueViewRelayFragment | null;
	items: LayoutContainerTemplateItem[];
};

export const ContentSectionViewOld = ({ items, issueViewRelayFragment }: Props) => {
	/**
	 * items can either have tabs or fieldItems but never both at the same time
	 * i.e. JiraIssueItemTabContainer can be in items
	 * so can multiple JiraIssueItemFieldItem
	 * but never JiraIssueItemFieldItem and JiraIssueItemTabContainer since if there are multiple tabs, each field should be in a tab not by itself.
	 * See JRACLOUD-76139 for more information.
	 */
	const tabItems = items.filter(isLayoutContainerTabItem);

	if (tabItems.length > 0) {
		return (
			<TabViewOld
				items={tabItems}
				area={CONTENT_AREA}
				issueViewRelayFragment={issueViewRelayFragment}
			/>
		);
	}
	return (
		<ItemList
			items={items}
			area={CONTENT_AREA}
			layoutItemsDataFragment={issueViewRelayFragment ?? null}
		/>
	);
};

export const ContentSectionViewNew = ({ items, issueViewRelayFragment }: Props) => {
	const issueKey = useIssueKey();
	const [{ value: issueFieldsConfig }] = useIssueFieldConfig(issueKey);
	const [issueFieldsValues] = useFieldsValues(issueKey);
	const layoutFieldTypes = getLayoutFieldTypes();

	const isTabFieldVisible = useCallback(
		(item: LayoutContainerTemplateItem) =>
			isTabFieldVisibleUtil({ item, issueFieldsConfig, issueFieldsValues, layoutFieldTypes }),
		[issueFieldsConfig, issueFieldsValues, layoutFieldTypes],
	);

	/**
	 * items can either have tabs or fieldItems but never both at the same time
	 * i.e. JiraIssueItemTabContainer can be in items
	 * so can multiple JiraIssueItemFieldItem
	 * but never JiraIssueItemFieldItem and JiraIssueItemTabContainer since if there are multiple tabs, each field should be in a tab not by itself.
	 * See JRACLOUD-76139 for more information.
	 */
	const tabItems = items.filter(isLayoutContainerTabItem);
	const tabArray = useMemo(
		() =>
			extractTabArrayNew({
				items: tabItems,
				area: CONTENT_AREA,
				issueViewRelayFragment,
				isTabFieldVisible,
				ItemListComponent: ItemList,
			}),
		[tabItems, issueViewRelayFragment, isTabFieldVisible],
	);

	// Forge UI Modifications need to have this to initialize when there's no tab
	useUiModificationsScreenTabs({
		tabs: tabArray,
	});

	if (tabArray.length > 0) {
		return <TabViewNew items={tabItems} area={CONTENT_AREA} tabArray={tabArray} />;
	}

	return (
		<ItemList
			items={items}
			area={CONTENT_AREA}
			layoutItemsDataFragment={issueViewRelayFragment ?? null}
		/>
	);
};

export const ContentSectionView = componentWithCondition(
	() => isForgeUiModificationsScreenTabsSupportEnabled(),
	ContentSectionViewNew,
	ContentSectionViewOld,
);
