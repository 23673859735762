import React from 'react';
import { AiIssueBreakdownEntryPointContextProvider } from '@atlassian/jira-ai-work-breakdown/src/controllers/context-provider';
import { useIsAiOptInEnabled } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	IssueSmartRequestSummaryEntryPointContextProvider,
	JsmSmartRequestSummaryEntryPointContextProvider,
} from '@atlassian/jira-issue-view-smart-request-summary-entrypoint';
import { useIsPremium } from '@atlassian/jira-platform-react-hooks-use-ai-opt-in';
import { useSubProduct } from '@atlassian/jira-subproduct';

export const MultipleEntrypointsContextProvider = ({ children }: { children: React.ReactNode }) => {
	const subProduct = useSubProduct();

	const SmartRequestSummaryEntryPointContextProvider =
		subProduct !== 'serviceDesk'
			? IssueSmartRequestSummaryEntryPointContextProvider
			: JsmSmartRequestSummaryEntryPointContextProvider;

	// TODO GRAVITYAI-1511 Check if these checks are correct for all AI features
	const isAiOptInEnabled = useIsAiOptInEnabled();
	const isPremium = useIsPremium();
	const isFeatureEnabled = isPremium && isAiOptInEnabled;

	if (!isFeatureEnabled) {
		return children;
	}

	return (
		<AiIssueBreakdownEntryPointContextProvider>
			<SmartRequestSummaryEntryPointContextProvider>
				{children}
			</SmartRequestSummaryEntryPointContextProvider>
		</AiIssueBreakdownEntryPointContextProvider>
	);
};
