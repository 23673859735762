import type { AllowedValuesOverrides } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';

export const filterAllowedValues = <StateValue extends { id: string | number }>(
	suggestions: StateValue[],
	allowedValuesOverrides?: AllowedValuesOverrides,
): StateValue[] => {
	if (!allowedValuesOverrides || !Array.isArray(allowedValuesOverrides.ids)) {
		return suggestions;
	}

	return suggestions.filter((value) => {
		// stringify because multi-select and single-select id can be of type number.
		const id = `${value.id}`;
		const isValueInIds = allowedValuesOverrides.ids.includes(id);

		if (allowedValuesOverrides.operation === 'EXCLUDE') {
			return !isValueInIds;
		}
		return isValueInIds;
	});
};
