import React from 'react';
import trimEnd from 'lodash/trimEnd';
import trimStart from 'lodash/trimStart';
import { expVal } from '@atlassian/jira-feature-experiments';
import { FormattedMessage } from '@atlassian/jira-intl';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { Emphasise } from '@atlassian/jira-issue-view-activity-common/src/styles';
import Change from '../../../../common/ui/change';
import GroupedChange from '../../../../common/ui/grouped-change';
import HistoryItem from '../../../../common/ui/history-item';
import type { AffectedServicesChangedHistoryItem } from '../../../../types';
import messages from './messages';
import { ServicesList } from './services-list';

type Props = {
	isGrouped?: Boolean;
	historyItem: AffectedServicesChangedHistoryItem;
};

// Get services from display value which could be either service names 'name1, name2' or ARIs '[ari1, ari2]'.
const getServicesFromDisplayValue = (
	value: string | null,
	displayValue: string | null,
): string[] => {
	if (displayValue === null) return [];
	if (value === null) {
		let trimmedDisplayValue = trimStart(displayValue, '[');
		trimmedDisplayValue = trimEnd(trimmedDisplayValue, ']');
		return trimmedDisplayValue.split(', ').filter((name) => name);
	}
	return displayValue.split(', ').filter((name) => name);
};

export const AffectedServicesHistoryItem = ({ historyItem, isGrouped = false }: Props) => {
	const { formatMessage } = useIntl();
	const { actor, timestamp, fromValue, toValue, fromDisplayValue, toDisplayValue } = historyItem;

	const fromServices = getServicesFromDisplayValue(fromValue, fromDisplayValue);
	const toServices = getServicesFromDisplayValue(toValue, toDisplayValue);

	const actionMessage = fromServices.length > toServices.length ? messages.removed : messages.added;

	const action = (
		<FormattedMessage
			{...actionMessage}
			values={{
				services: <Emphasise>{formatMessage(messages.affectedServices)}</Emphasise>,
			}}
		/>
	);

	return isGrouped && expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false) ? (
		<GroupedChange
			field={formatMessage(messages.affectedServices)}
			from={fromServices.length > 0 ? <ServicesList services={fromServices} /> : null}
			to={toServices.length > 0 ? <ServicesList services={toServices} /> : null}
		/>
	) : (
		<HistoryItem
			actor={actor}
			action={action}
			timestamp={timestamp}
			change={
				<Change
					from={fromServices.length > 0 ? <ServicesList services={fromServices} /> : null}
					to={toServices.length > 0 ? <ServicesList services={toServices} /> : null}
				/>
			}
		/>
	);
};
