import React, { useCallback, useState, memo } from 'react';
import { styled } from '@compiled/react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import { MOBILE_ISSUE } from '@atlassian/jira-common-constants/src/analytics-sources';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsSource } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldFlags } from '@atlassian/jira-issue-field-base/src/services/field-flags-service/index.tsx';
import {
	READ_VIEW_CONTAINER_SELECTOR,
	InlineEditContainer,
	ReadViewContainer,
} from '@atlassian/jira-issue-field-inline-edit/src/styled.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { useIsMounted } from '@atlassian/jira-platform-react-hooks-use-is-mounted/src/common/utils/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ParentFieldOption } from '../common/types';
import { useParentField } from '../services/parent-field/index.tsx';
import { ParentFieldEdit } from './edit';
import messages from './messages';
import type { Props } from './types';
import { ParentViewField } from './view';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const InlineEditParentContainer = styled(InlineEditContainer)<{ isEditable: boolean }>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditable }) =>
		!isEditable
			? {
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					[READ_VIEW_CONTAINER_SELECTOR]: {
						lineHeight: 1,
						padding: `${token('space.025', '2px')} 0`,
					},
				}
			: {
					'div[data-read-view-fit-container-width]': {
						padding: `${token('space.050', '4px')} 0`,
					},
				},
);

InlineEditParentContainer.displayName = 'InlineEditParentContainer';

const ParentField = memo<Props>((props: Props) => {
	const {
		fieldKey,
		issueKey,
		label,
		onCancel,
		onEdit,
		onEscape,
		onConfirm,
		onEnter,
		onUpdate,
		onCloseMenuOnScroll,
		readView,
		editView,
		showPinButton,
		issueId,
		startWithEditViewOpen = false,
		isDropdownMenuFixedAndLayered,
		selectedProjectId,
		selectedIssueTypeId,
		isExistingIssue,
		classNamePrefix,
		...rest
	} = props;

	// We need to track the mounted state as it is possible that a component
	// using this component can be unmounted before async operations are completed.
	// Causing a react warning about setting state on an unmounted component.
	const isMounted = useIsMounted();

	const source = useAnalyticsSource();
	const { formatMessage } = useIntl();

	const { onFailure: showErrorFlag } = useFieldFlags();
	const [isEditing, setIsEditing] = useState<boolean>(startWithEditViewOpen);

	const onSuccess = useCallback(
		(value: ParentFieldOption | null) => {
			onUpdate?.(value);
		},
		[onUpdate],
	);

	const onFailure = useCallback(
		(error: Error) => {
			showErrorFlag({
				error,
				title: messages.errorTitle,
				description: messages.errorMessage,
			});

			if (isMounted.current) {
				setIsEditing(true);
			}
		},
		[isMounted, showErrorFlag],
	);

	const [{ fieldConfig, value }, { resetError, saveValue }] = useParentField({
		fieldKey,
		issueId,
		issueKey: toIssueKey(issueKey),
		onSuccess,
		onFailure,
	});

	const isFieldEditable = get(fieldConfig, 'isEditable', false);
	const isMobile = source === MOBILE_ISSUE;

	const save = useCallback(
		(option: ParentFieldOption | null, analyticsEvent: UIAnalyticsEvent) => {
			if (!isEqual(option, value)) {
				saveValue(option || null, null, analyticsEvent);
			}

			setIsEditing(false);
			fireUIAnalytics(analyticsEvent);
		},
		[saveValue, value, setIsEditing],
	);

	const cancel = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			resetError();
			setIsEditing(false);
			fireUIAnalytics(analyticsEvent);
		},
		[resetError, setIsEditing],
	);

	const onCancelRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			cancel(analyticsEvent);
			onCancel && onCancel(analyticsEvent);
		},
		[cancel, onCancel],
	);

	const onConfirmRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setIsEditing(false);
			onConfirm && onConfirm(analyticsEvent);
		},
		[onConfirm],
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onEditRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setIsEditing(true);
			fireUIAnalytics(analyticsEvent);
			onEdit && onEdit(analyticsEvent);
		},
		[onEdit, setIsEditing],
	);

	const onEscapeRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			cancel(analyticsEvent);
			onEscape && onEscape(analyticsEvent);
		},
		[cancel, onEscape],
	);

	const onChange = useCallback(
		(selectedOption: ParentFieldOption | null) => {
			resetError();

			const analyticsEvent = createAnalyticsEvent({
				action: 'confirmed',
				actionSubject: 'fieldInlineEdit',
			});

			save(selectedOption, analyticsEvent);
			onConfirmRequest(analyticsEvent);
		},
		[resetError, createAnalyticsEvent, onConfirmRequest, save],
	);

	const renderEditView = () =>
		editView !== undefined ? (
			editView
		) : (
			<span>
				<ParentFieldEdit
					fieldId={fieldKey}
					value={value}
					onChange={onChange}
					isEditing={isEditing}
					autoFocus
					isExistingIssue={isExistingIssue}
					onCloseMenuOnScroll={onCloseMenuOnScroll}
					isDropdownMenuFixedAndLayered={isDropdownMenuFixedAndLayered}
					selectedProjectId={selectedProjectId}
					selectedIssueTypeId={selectedIssueTypeId}
					classNamePrefix={classNamePrefix}
					fetchSuggestionsOnFocus
					defaultMenuIsOpen
				/>
			</span>
		);

	const renderReadView = useCallback(
		() =>
			readView !== undefined ? (
				readView
			) : (
				<ReadViewContainer data-testid="issue-field-parent.ui.read-view-container">
					<ParentViewField
						noValueText={formatMessage(messages.noValueText)}
						value={value}
						isMobile={isMobile}
					/>
				</ReadViewContainer>
			),
		[isMobile, formatMessage, readView, value],
	);

	return (
		<InlineEditParentContainer isEditable={isFieldEditable}>
			<FieldInlineEditStateLess
				isEditable={isFieldEditable}
				isEditing={isEditing}
				isFitContainerWidthReadView={!isMobile}
				testId="issue-field-parent.ui.inline-edit-field"
				label={label}
				readView={renderReadView()}
				editView={isFieldEditable ? renderEditView() : null}
				onCancel={onCancelRequest}
				onConfirm={onConfirmRequest}
				onEdit={onEditRequest}
				onEscape={onEscapeRequest}
				disableEditViewFieldBase
				areActionButtonsHidden
				isLabelHidden
				editButtonLabel={formatMessage(messages.editButtonLabel, { fieldName: fieldKey })}
				{...rest}
			/>
		</InlineEditParentContainer>
	);
});

ParentField.displayName = 'ParentField';
export { ParentField };
