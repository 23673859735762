import React, { useCallback, useMemo, useState } from 'react';
import noop from 'lodash/noop';
import { graphql, useMutation, useFragment } from 'react-relay';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main';
import { ChangeIssueType } from '@atlassian/jira-issue-type-changer/src/ui/main.tsx';
import { ISSUE_HIERARCHY_LEVEL_SUBTASK } from '@atlassian/jira-issue-type-hierarchies';
import type { IssueType } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events';
import type { ui_issueTypeSwitcher_Mutation } from '@atlassian/jira-relay/src/__generated__/ui_issueTypeSwitcher_Mutation.graphql';
import type {
	ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcher$key,
	ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcher$data,
} from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcher.graphql';
import type { IssueId, IssueKey } from '@atlassian/jira-shared-types/src/general';

type AggValueShape = Pick<
	ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcher$data,
	'issueType' | 'issueTypes'
>;

export type AggItemValue = {
	readonly avatar:
		| {
				readonly xsmall: string | null | undefined;
		  }
		| null
		| undefined;
	readonly hierarchy:
		| {
				readonly level: number | null | undefined;
		  }
		| null
		| undefined;
	readonly id: string;
	readonly issueTypeId: string | null | undefined;
	readonly name: string;
};

const toComponentValueShape = (fieldData: AggItemValue): IssueType => ({
	id: Number(fieldData.issueTypeId ?? ''),
	iconUrl: fieldData.avatar?.xsmall ?? '',
	name: fieldData.name ?? '',
	subtask: fieldData.hierarchy?.level === ISSUE_HIERARCHY_LEVEL_SUBTASK,
});

function transformComponentValueToAggShape(
	issueTypeValueLegacy: IssueType,
	issueTypes: ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcher$data['issueTypes'],
): AggValueShape {
	return {
		issueType: issueTypes?.edges?.find(
			(fieldOption) => fieldOption?.node?.issueTypeId === issueTypeValueLegacy.id.toString(),
		)?.node,
		issueTypes,
	};
}

export type IssueTypeSwitcherProps = {
	fragmentKey: ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcher$key;
};

export const useIsOpen = () => useState(false);

export const IssueTypeSwitcher = (props: IssueTypeSwitcherProps) => {
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();
	const data = useFragment<ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcher$key>(
		graphql`
			fragment ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcher on JiraIssueTypeField {
				id
				fieldId
				type
				__typename
				issue {
					issueId
				}
				issueType {
					id
					name
					issueTypeId
					avatar {
						xsmall
					}
					hierarchy {
						level
					}
				}
				issueTypes {
					edges {
						node {
							id
							issueTypeId
							name
							avatar {
								xsmall
							}
							hierarchy {
								level
							}
						}
					}
				}
			}
		`,
		props.fragmentKey,
	);

	const [isOpen, setIsOpen] = useIsOpen();

	const { id: uniqueFieldId, issueTypes, issue, issueType, fieldId, type } = data;

	const options = useMemo(
		() => mapNodes(issueTypes).map((option) => toComponentValueShape(option)),
		[issueTypes],
	);

	const onSubmit = useCallback(
		(value: AggItemValue | null | undefined) => {
			issue?.issueId &&
				fieldChangeRequested(issue?.issueId, fieldId, value, undefined, {
					type,
					__typename: data.__typename,
				});
		},
		[data.__typename, fieldId, type, fieldChangeRequested, issue?.issueId],
	);

	const onSubmitSucceeded = useCallback(
		(value: AggItemValue | null | undefined) => {
			issue?.issueId &&
				fieldChanged(issue?.issueId, fieldId, value, {
					type,
					__typename: data.__typename,
				});
		},
		[data.__typename, fieldId, type, fieldChanged, issue?.issueId],
	);

	const onSubmitFailed = useCallback(
		() => issue?.issueId && fieldChangeFailed(issue?.issueId, fieldId),
		[fieldId, fieldChangeFailed, issue?.issueId],
	);

	const [commit] = useMutation<ui_issueTypeSwitcher_Mutation>(graphql`
		mutation ui_issueTypeSwitcher_Mutation($input: JiraUpdateIssueTypeFieldInput!)
		@raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateIssueTypeField(input: $input) {
					success
					errors {
						message
					}
					field {
						issueType {
							id
							name
							issueTypeId
							avatar {
								xsmall
							}
							hierarchy {
								level
							}
						}
						issueTypes {
							edges {
								node {
									id
									issueTypeId
									name
									avatar {
										xsmall
									}
									hierarchy {
										level
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	const handleSubmit = useCallback(
		(_issueKey: IssueKey, _issueId: IssueId, _currentType: IssueType, newType: IssueType) => {
			const newAggValue = transformComponentValueToAggShape(newType, issueTypes);
			onSubmit(newAggValue?.issueType);

			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operation: {
							operation: 'SET',
							id: newAggValue.issueType?.id ?? '',
						},
					},
				},
				onCompleted(mutationData) {
					if (mutationData.jira?.updateIssueTypeField?.success) {
						onSubmitSucceeded(newAggValue?.issueType);
					} else {
						onSubmitFailed();
					}
				},
				onError() {
					onSubmitFailed();
				},
				optimisticResponse: {
					jira: {
						updateIssueTypeField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								...newAggValue,
							},
						},
					},
				},
			});
		},
		[commit, issueTypes, onSubmit, onSubmitFailed, onSubmitSucceeded, uniqueFieldId],
	);

	return (
		<ErrorBoundary id="change-issue-type" packageName="issue-view">
			<ChangeIssueType
				issueId={issue?.issueId ?? ''}
				issueTypes={options}
				isOpen={isOpen}
				value={issueType ? toComponentValueShape(issueType) : undefined}
				showIssueTypeList={setIsOpen}
				changeIssueType={handleSubmit}
				loadIssueTypes={noop}
				icon={<AsyncIcon url={issueType?.avatar?.xsmall} alt={issueType?.name} />}
			/>
		</ErrorBoundary>
	);
};

// TODO: move to internal package
type Nullable<T> = T | null | undefined;

type Connection<TNode> = {
	readonly edges?: Nullable<
		ReadonlyArray<
			Nullable<{
				readonly node?: Nullable<TNode>;
			}>
		>
	>;
} | null;

function mapNodes<TNode>(conn: Nullable<Connection<TNode>>): TNode[] {
	const nodes: TNode[] = [];

	return (
		conn?.edges?.reduce((acc, edge) => {
			if (edge?.node) {
				acc.push(edge.node);
			}

			return acc;
		}, nodes) ?? []
	);
}
