import { createStore, createHook } from '@atlassian/react-sweet-state';
import { actions, type Actions } from './actions';
import type { State } from './types';

export const initialState: State = {
	ui: {
		showLozenge: false,
	},
};

export const store = createStore<State, Actions>({
	name: 'issue-history',
	initialState,
	actions,
});

export const useIssueHistoryUi = createHook(store, {
	selector: (state) => state.ui,
});

export const useIssueHistoryStoreActions = createHook(store, {
	selector: null,
});
