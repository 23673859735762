import React from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import Placeholder from '@atlassian/jira-placeholder';

type Props = {
	fieldId: string;
	showPinButton?: boolean;
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const SentimentDropdown = lazyAfterPaint(
	() =>
		import(
			/* webpackChunkName: "sentiment-field" */ '@atlassian/jira-servicedesk-sentiment-field/src/ui/sentiment-panel'
		),
	{ ssr: false },
);

export const SentimentPanel = ({ fieldId, showPinButton }: Props) => {
	const issueKey = useIssueKey();

	return (
		<Placeholder name="sentiment-field" fallback={null}>
			<SentimentDropdown issueKey={issueKey} fieldId={fieldId} showPinButton={showPinButton} />
		</Placeholder>
	);
};

export default flowWithSafeComponent(
	connect(
		(state: State, props: { fieldId: string; area?: Area }) => ({
			fieldId: props.fieldId,
			showPinButton: getShowPinButton(props.area),
		}),
		{},
	),
)(SentimentPanel);
