import React, { useCallback } from 'react';
import { Text } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { FieldDescription } from '@atlassian/jira-issue-field-description/src/ui/index.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading';
import {
	FieldHeadingTitle,
	SideBySideField,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin';
import { useReadOnlyField } from '@atlassian/jira-issue-field-read-only/src/services/index.tsx';
import ReadOnlyField from '@atlassian/jira-issue-field-read-only/src/ui/main.tsx';
import {
	ReadOnlyFieldView,
	type Props as ViewProps,
} from '@atlassian/jira-issue-field-read-only/src/ui/view/index.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button';
import { ReadOnlyFieldWrapper } from './styled';
import type { Props } from './types';

const ReadOnlyView = ({ fieldId: fieldKey, label, area }: Props) => {
	const issueKey = useIssueKey();
	const { formatMessage } = useIntl();
	const [{ fieldConfig }] = useReadOnlyField({ issueKey, fieldKey });
	const fieldLabel = fieldConfig?.title || label;
	const { noValue } = genericMessages;
	const renderReadView = useCallback(
		({ value }: ViewProps) => {
			if (value == null) {
				return <Text color="color.text.subtlest">{formatMessage(noValue)}</Text>;
			}
			return <ReadOnlyFieldView value={value} />;
		},
		[formatMessage, noValue],
	);

	const showPinButton = getShowPinButton(area);

	return (
		<ReadOnlyFieldWrapper>
			<FieldHeading>
				<FieldHeadingTitle>{fieldLabel}</FieldHeadingTitle>
				{issueKey !== undefined && fieldKey !== undefined && (
					<FieldDescription issueKey={issueKey} fieldKey={fieldKey} label={fieldLabel} />
				)}
				{showPinButton === true && <FieldPin fieldId={fieldKey} label={fieldLabel} />}
			</FieldHeading>
			<SideBySideField>
				<ReadOnlyField fieldKey={fieldKey} readView={renderReadView} />
			</SideBySideField>
		</ReadOnlyFieldWrapper>
	);
};
export default ReadOnlyView;
