import React, { type ReactNode, Component } from 'react';
import AddIcon from '@atlaskit/icon/core/migration/add';
import { Text } from '@atlaskit/primitives';
import Skeleton from '@atlaskit/skeleton';
import iframeRedirect from '@atlassian/jira-common-navigation/src/iframe-redirect';
import { useGetCompassPermissions } from '@atlassian/jira-compass-common/src/services/use-get-compass-permissions/index.tsx';
import { useGlobalComponentsProperty } from '@atlassian/jira-compass-common/src/services/use-global-components-property/main.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { ServerSuggestions } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types';
import {
	FooterContainer,
	OptionLabel,
	selectFooterStyles,
} from '@atlassian/jira-issue-internal-field-select/src/common/styled.tsx';
import MultiSelectInlineEditView, {
	type Props as SelectProps,
} from '@atlassian/jira-issue-internal-field-select/src/multi-select-inline-edit';
import TagView, { type Item } from '@atlassian/jira-issue-view-internal-tagview/src/tag-view';
import type { BaseUrl, ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { ComponentTag } from './component-tag';
import messages from './messages';

type Props = {
	shouldShowFooter: boolean;
	getDataFromCache?: () => Promise<ServerSuggestions>;
	isGlobalComponentsEnabled: boolean;
	globalComponentsPropertyLoading: boolean;
} & SelectProps & {
		baseUrl: BaseUrl;
		projectKey: ProjectKey;
		intl: IntlShape;
	};
type State = {
	data: ServerSuggestions;
	loading: boolean;
	error: boolean;
};

const isGlobalComponent = (
	item: Item & {
		ari?: string;
		metadata?: { typeId: string };
	},
) => typeof item.ari === 'string' && item.metadata?.typeId !== undefined;
const isComponentInReadonlyState = (item: Item, isGlobalComponentsEnabledForProject: boolean) =>
	isGlobalComponent(item)
		? !isGlobalComponentsEnabledForProject
		: isGlobalComponentsEnabledForProject;

// eslint-disable-next-line jira/react/no-class-components
class ComponentsView extends Component<Props, State> {
	state = {
		data: [],
		loading: false,
		error: false,
	};

	componentDidMount = () => {
		this.getDataFromCache();
	};

	getDataFromCache = () => {
		this.props.getDataFromCache?.().then((data: ServerSuggestions) => {
			this.setState({ data, loading: false });
		});
	};

	fetchSuggestions = (query: string, sessionId?: string) =>
		this.props.fetchSuggestions(query, sessionId).then((data) => {
			this.setState({ data });
			return data;
		});

	renderFooter() {
		const { intl } = this.props;

		return (
			<FooterContainer
				/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */
				className="issue-view__select-footer"
			>
				<AddIcon label="" color="currentColor" LEGACY_size="small" />
				<OptionLabel>{intl.formatMessage(messages.createNewItem)}</OptionLabel>
			</FooterContainer>
		);
	}

	getCustomReadViewProp = (): SelectProps['customReadView'] => {
		const {
			shouldShowFooter,
			baseUrl,
			projectKey,
			intl,
			isGlobalComponentsEnabled,
			globalComponentsPropertyLoading,
			...selectProps
		} = this.props;

		return ff('compass-components-in-jira-components_xw42u', false)
			? (value) => (
					<UFOSegment name="compass-components-hybrid-field">
						{globalComponentsPropertyLoading ? (
							<Skeleton
								width="150px"
								height="16px"
								borderRadius={3}
								testId="issue-view-base.context.components.skeleton"
								isShimmering
							/>
						) : (
							<TagView
								{...selectProps}
								// @ts-expect-error - Type 'Value | (Value & SelectValueShape[])' is not assignable to type 'Item[] | null | undefined'.
								value={value}
								// @ts-expect-error - Type '(item: Item) => string' is not assignable to type '(item: Item) => ReactElement<any, string | JSXElementConstructor<any>>'.
								renderTagTooltipContent={(item: Item) => {
									if (isComponentInReadonlyState(item, isGlobalComponentsEnabled)) {
										return intl.formatMessage(
											isGlobalComponent(item)
												? messages.disabledCompassComponent
												: messages.disabledJiraComponent,
											{
												strong: (chunks: ReactNode[]) => <Text as="strong">{chunks}</Text>,
												italic: (chunks: ReactNode) => <Text as="em">{chunks}</Text>,
												componentName: 'content' in item ? item.content : item.label,
											},
										);
									}

									return 'content' in item && item.content !== undefined && item.content.length > 20
										? item.content
										: '';
								}}
								renderTagItem={(item: Item) => (
									<ComponentTag item={item} globalComponentsEnabled={isGlobalComponentsEnabled} />
								)}
								projectKey={projectKey}
							/>
						)}
					</UFOSegment>
				)
			: undefined;
	};

	render() {
		const {
			shouldShowFooter,
			onFooterSelect,
			baseUrl,
			projectKey,
			intl,
			isGlobalComponentsEnabled,
			...selectProps
		} = this.props;
		const { data, loading, error } = this.state;
		const initialData = { data, loading, error };

		return (
			<MultiSelectInlineEditView
				{...selectProps}
				customReadView={this.getCustomReadViewProp()}
				styles={selectFooterStyles}
				fetchSuggestions={this.fetchSuggestions}
				debounceFetchSuggestionsTime={300}
				initialData={initialData}
				shouldShowFooter={shouldShowFooter}
				footer={this.renderFooter()}
				onFooterSelect={
					ff('compass-components-in-jira-components_xw42u') && onFooterSelect
						? onFooterSelect
						: () =>
								iframeRedirect(
									`${baseUrl}/plugins/servlet/project-config/${projectKey}/administer-components`,
									false,
								)
				}
				allowEmptyValue
				optionValuesSafeForAnalytics
				showNewLozenge={!!isGlobalComponentsEnabled}
			/>
		);
	}
}

const ComponentsViewWithGlobalComponentsEnabled = (props: Props) => {
	const { isMobile, projectKey, shouldShowFooter, baseUrl } = props;
	const { hasCompassPermissions } = useGetCompassPermissions();

	const getCreateCompassComponentUrl = () => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const originUrl = window.location.origin;
		const projectLink = encodeURI(`${originUrl}/browse/${projectKey}`);
		return `${originUrl}/compass/home?showCreateComponentModal=true&projectLink=${projectLink}`;
	};

	return (
		<ComponentsView
			{...props}
			isGlobalComponentsEnabled
			shouldShowFooter={!isMobile ? hasCompassPermissions : shouldShowFooter}
			onFooterSelect={() =>
				hasCompassPermissions
					? iframeRedirect(getCreateCompassComponentUrl(), true)
					: iframeRedirect(
							`${baseUrl}/plugins/servlet/project-config/${projectKey}/administer-components`,
							false,
						)
			}
		/>
	);
};

const ComponentsViewWithHooks = (props: Props) => {
	const { projectKey } = props;
	const { enabled: isGlobalComponentsEnabled, loading: globalComponentsPropertyLoading } =
		useGlobalComponentsProperty(projectKey);

	if (isGlobalComponentsEnabled) {
		return (
			<ComponentsViewWithGlobalComponentsEnabled
				{...props}
				globalComponentsPropertyLoading={globalComponentsPropertyLoading}
			/>
		);
	}

	return (
		<ComponentsView {...props} globalComponentsPropertyLoading={globalComponentsPropertyLoading} />
	);
};

export const View = componentWithCondition(
	() => ff('compass-components-in-jira-components_xw42u'),
	ComponentsViewWithHooks,
	ComponentsView,
);
