import get from 'lodash/get';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { FieldOptions } from '@atlassian/jira-issue-field-base/src/common/types';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { NumberValue } from '../common/types';

export type Props = {
	issueKey: IssueKey;
	fieldKey: string;
	initialValue?: NumberValue;
	onSubmit?: (
		oldValue: NumberValue,
		newValue: NumberValue,
		meta: unknown,
		event: UIAnalyticsEvent,
	) => void;
	onSuccess?: (arg1: NumberValue) => void;
	onFailure?: (arg1: Error) => void;
	saveField?: FieldOptions<NumberValue, unknown, undefined, undefined>['saveField'];
};

export const useNumberField = ({
	issueKey,
	fieldKey,
	initialValue,
	onSubmit,
	onSuccess,
	onFailure,
	saveField,
}: Props) => {
	// useFieldConfig now returns an object { value, loading } instead of just the fieldConfig value
	// if possible when touching this, try and refactor to pass the new object instead of just the value
	const [{ value: fieldConfig }] = useFieldConfig(issueKey, fieldKey);
	const fieldType = get(fieldConfig, 'schema.type', 'number');

	const [{ value, error }, { saveValue, resetError }] = useEditField<NumberValue, null>({
		fieldKey,
		issueKey,
		fieldType,
		initialValue: initialValue || null,
		onSubmit,
		onSuccess,
		onFailure,
		saveField,
	});

	return [
		{ value, error, fieldConfig },
		{ saveValue, resetError },
	] as const;
};
