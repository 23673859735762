import { type DependencyList, useEffect } from 'react';
import type { CommandSections } from '../../common/types/commands/index.tsx';
import { useActionsCommandPalette } from '../command-palette';

export const useRegisterCommandSections = (
	sections: CommandSections,
	deps: DependencyList = [],
) => {
	const { registerSections, unregisterSections } = useActionsCommandPalette();
	const ids = Object.keys(sections);

	useEffect(() => {
		registerSections(sections);

		return () => {
			unregisterSections(sections);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...deps, registerSections, unregisterSections, ids.toString()]);
};
