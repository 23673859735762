import type { UserGroup } from '../model/types';
import * as single from '../single/transformer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toItems = (groups: UserGroup[]): any[] =>
	groups.map((group) => single.transformFromStateValue(group));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformFromStateValue = (stateValues: UserGroup[] | null): any[] => {
	if (!stateValues) {
		return [];
	}

	return toItems(stateValues);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformToStateValue = (values: any[] | null): UserGroup[] => {
	if (!values) {
		return [];
	}

	return values.map((value) => single.transformToStateValue(value));
};
