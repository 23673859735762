import {
	CORE_PROJECT,
	SERVICE_DESK_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	JSD_TEMPLATE,
	JCS_TEMPLATE,
	JWM_TEMPLATE,
	DEFAULT_TEMPLATE,
} from '@atlassian/jira-issue-view-layout-templates-constants';
import {
	useProjectType,
	useProjectKey,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useIsJCS } from '@atlassian/jira-servicedesk-customer-service-common/src/utils/use-is-jcs';

export const useTemplateRendererKey = () => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);
	const isJCS = useIsJCS();

	// TODO: For the time being, we can only check if we're in a JCS project
	// using this useIsJCS hook. This will be changed to use the Project Type
	// identifier as part of: https://jplat.atlassian.net/browse/BERRY-3192
	if (isJCS) {
		return JCS_TEMPLATE;
	}

	switch (projectType) {
		case SERVICE_DESK_PROJECT:
			return JSD_TEMPLATE;
		case CORE_PROJECT:
			return JWM_TEMPLATE;
		default:
			return DEFAULT_TEMPLATE;
	}
};
