import { OPEN } from '../common/constants';
import type { RelatedIssue, RelatedIssueAnalytics, IssueStatus } from '../common/types';
import type { SimilarityResponse } from './types';

const OPEN_JQL = 'statusCategory != Done';

const RESOLVED_JQL = 'statusCategory = Done';

export const transformResponseToSimilarIssues = (response: SimilarityResponse): RelatedIssue[] =>
	response.issues.map((rawIssue, idx) => ({
		id: rawIssue.id,
		issueKey: rawIssue.key,
		issueLink: rawIssue.self,
		issueTypeIconUrl: rawIssue.fields.issuetype.iconUrl,
		issueTypeName: rawIssue.fields.issuetype.name,
		issueSummary: rawIssue.fields.summary,
		position: idx,
		distance: rawIssue.distance,
		crossEncoderScore: rawIssue.crossEncoderScore,
	}));

export const transformResponseForAnalytics = (
	response: SimilarityResponse,
): RelatedIssueAnalytics[] =>
	response.issues.map((rawIssue) => ({
		id: rawIssue.id,
	}));

// IssueStatus can only be one of two states
export const buildJql = (issueStatus: IssueStatus) =>
	issueStatus === OPEN ? OPEN_JQL : RESOLVED_JQL;

export const getTopFiveDistances = (similarIssues?: RelatedIssue[]) =>
	similarIssues ? similarIssues.slice(0, 5).map((issue) => issue.distance) : [];

export const getTopFiveCrossEncoderScores = (similarIssues?: RelatedIssue[]) =>
	similarIssues ? similarIssues.slice(0, 5).map((issue) => issue.crossEncoderScore) : [];
