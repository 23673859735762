import React from 'react';

import { token } from '@atlaskit/tokens';

import { RainbowBorder } from './RainbowBorder';
import type { IconProps } from './types';

export const Icon = (props: IconProps) => {
	const { shownAt } = props;

	return (
		<RainbowBorder shownAt={shownAt}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill={token('color.icon', '#44546F')}
				xmlns="http://www.w3.org/2000/svg"
				role="presentation"
			>
				<path d="M13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V8Z" />
				<path d="M17 6H7C6.44772 6 6 6.44772 6 7C6 7.55228 6.44772 8 7 8H17C17.5523 8 18 7.55228 18 7C18 6.44772 17.5523 6 17 6Z" />
			</svg>
		</RainbowBorder>
	);
};
