import React, { useCallback } from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { CannedCommentsPlaceholder } from './main';

type CannedCommentsPlaceHolderWithClickProps = {
	collapsedPlaceholder?: string;
	onChange?: (value: ADF) => void;
	onExpanded?: () => void;
};
type CannedCommentsPlaceHolderSkeletonProps = {
	onClick?: () => void;
	shouldShowLoadingStateSpinner?: boolean;
};
export const CannedCommentsPlaceHolderWithClick = ({
	onChange,
	onExpanded,
	collapsedPlaceholder,
}: CannedCommentsPlaceHolderWithClickProps) => {
	const onClickCannedComment = useCallback(
		(value: ADF) => {
			onChange && onChange(value);
			onExpanded && onExpanded();
		},
		[onExpanded, onChange],
	);

	return (
		<CannedCommentsPlaceholder
			placeholder={collapsedPlaceholder}
			onFocus={onExpanded}
			onClick={onExpanded}
			onClickCannedComment={onClickCannedComment}
		/>
	);
};

export const CannedCommentsPlaceHolderSkeleton = ({
	onClick,
	shouldShowLoadingStateSpinner,
}: CannedCommentsPlaceHolderSkeletonProps) => (
	<CannedCommentsPlaceholder
		onClick={onClick}
		isLoading
		shouldShowLoadingStateSpinner={shouldShowLoadingStateSpinner}
	/>
);
