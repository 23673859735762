import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type {
	ElementStyles,
	SelectElementState,
} from '@atlassian/jira-common-components-picker/src/model';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OptionContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OptionLabel = styled.span({
	marginLeft: token('space.100', '8px'),
	flexGrow: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FooterContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.brand', colors.B400),
	padding: `${token('space.075', '6px')} ${token('space.150', '12px')}`,
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.neutral', colors.N20),
	},
	'&:active': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.text.brand', colors.B75),
	},
});

// feel free to remove these styles once ak/select supports footer option
export const selectFooterStyles = {
	option: (base: ElementStyles, state: SelectElementState): ElementStyles => {
		const akSelectMenuPaddingBottom = token('space.100', '8px');
		const akSelectMenuPaddingBottomOffset = token('space.negative.100', '-8px');

		return {
			...base,

			// apply styles only for 'Create new' option: https://github.com/JedWatson/react-select/issues/3377

			...(state.data.__isNew__ === true
				? {
						...(state.isFocused
							? {
									'> .issue-view__select-footer': {
										backgroundColor: token('color.background.neutral', colors.N20),
									},
								}
							: {}),

						position: 'sticky',
						bottom: 0,
						backgroundColor: `${token('elevation.surface', colors.N0)} !important`,

						// N900, hex does not work with rgba()
						borderTop: `2px solid ${token('color.border', 'rgba(9, 30, 66, 0.13)')}`,

						padding: 0,
						paddingTop: token('space.100', '8px'),

						// resetting 'padding' alone does not work in this component's case
						paddingBottom: 0,

						// to ensure that :after element is shown even though it's outside of the main one
						overflow: 'visible',

						':after': {
							// need this element to avoid having another option part being shown below Create one on scrolling (it is shown because options list element has padding)
							content: '""',
							display: 'block',
							position: 'absolute',
							bottom: akSelectMenuPaddingBottomOffset,
							left: 0,
							width: '100%',
							height: akSelectMenuPaddingBottom, // to have same footer height both when list scrolling is at the top and at the very bottom.
							backgroundColor: token('elevation.surface', colors.N0),
							borderBottomLeftRadius: '4px', // need to match menu's border-radius, not to hide menu's border
							borderBottomRightRadius: '4px',
						},
					}
				: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					({} as ElementStyles)),

			...(state.data.isAkSelectOption === true
				? {
						textAlign: 'center',
						paddingTop: token('space.100', '8px'),
						paddingBottom: token('space.200', '16px'),
					}
				: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					({} as ElementStyles)),
		};
	},
	menu: (base: ElementStyles) => ({
		...base,
		// without properly set zIndex value dropdown with options is shown behind the action buttons
		zIndex: layers.modal,
	}),
} as const;
