// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	updated: {
		defaultMessage: 'Updated',
		id: 'issue.views.confluence-pages.link-list.page-item.updated',
		description: 'Label for the last modified date shown in Search',
	},
	recent: {
		defaultMessage: 'Recently viewed',
		id: 'issue.views.confluence-pages.link-list.page-item.recent',
		description: 'Label for the recently viewed shown in Search ',
	},
});
