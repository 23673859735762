import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { ENTITY_LIMIT_TYPE as COLLECTION_ENTITY_LIMIT_TYPE } from '@atlassian/jira-polaris-domain-collection/src/constants';
import { ENTITY_LIMIT_TYPE as PROJECT_ENTITY_LIMIT_TYPE } from '@atlassian/jira-polaris-domain-project/src/constants.tsx';
import type { EntityLimitType, EntityLimits } from '../../../common/types';
import type { EntityLimitsResponse, EntityLimitsResponseType } from '../types';

const ENTITY_LIMIT_TYPE = { ...PROJECT_ENTITY_LIMIT_TYPE, ...COLLECTION_ENTITY_LIMIT_TYPE };

export const fetchEntityLimits = async (): Promise<EntityLimits> => {
	const entityLimits: EntityLimits = {};

	const response = await fetchJson<EntityLimitsResponse>('/gateway/api/polaris/rest/limits');

	// Convert the response to the format expected by the UI
	for (const [key, value] of Object.entries(response?.limits ?? {})) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const limitType: EntityLimitType = ENTITY_LIMIT_TYPE[key as EntityLimitsResponseType];
		if (limitType) {
			entityLimits[limitType] = value;
		}
	}

	return entityLimits;
};
