// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentType, type ComponentProps } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { FlagContainer } from './index';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const UpdateAffectedServicesFlagContainer = lazyForPaint<
	ComponentType<
		JSX.LibraryManagedAttributes<typeof FlagContainer, ComponentProps<typeof FlagContainer>>
	>
>(
	() =>
		import(/* webpackChunkName: "async-update-affected-services-flag" */ './index').then(
			(module) => module.FlagContainer,
		),
	{ ssr: false },
);

export const AsyncUpdateAffectedServicesFlagContainer = () => (
	<Placeholder name="update-affected-services-flag" fallback={null}>
		<UpdateAffectedServicesFlagContainer />
	</Placeholder>
);
