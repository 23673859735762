import { type Environment, fetchQuery, graphql } from 'react-relay';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { utilsUseStatusMutationMissingDataQuery } from '@atlassian/jira-relay/src/__generated__/utilsUseStatusMutationMissingDataQuery.graphql';
import type { StatusSaveId } from '../types';

interface FetchOptionAriVars {
	cloudId: string;
	issueKey: string;
	fieldId: string;
}
type FetchMissingIssueAndFieldOptionDataReturn = {
	issueId: string;
	transition: TransitionData;
	status: StatusData;
} | null;

type StatusData = {
	statusId: string;
	name: string;
	statusCategory: {
		statusCategoryId: string;
		name: string;
	};
};

type TransitionData = {
	transitionId: string;
	hasScreen: boolean;
	name: string;
	to: StatusData;
};

export async function fetchStatusTransitionData(
	option: StatusSaveId | null,
	environment: Environment,
	vars: FetchOptionAriVars,
): Promise<FetchMissingIssueAndFieldOptionDataReturn | null> {
	if (option === null || typeof option !== 'object' || !('transitionId' in option)) {
		return null;
	}

	const result = await fetchQuery<utilsUseStatusMutationMissingDataQuery>(
		environment,
		graphql`
			query utilsUseStatusMutationMissingDataQuery(
				$cloudId: ID!
				$issueKey: String!
				$fieldId: ID!
			) {
				jira @optIn(to: "JiraStatusFieldOptions") {
					issueByKey(cloudId: $cloudId, key: $issueKey) {
						issueId
						fieldsById(ids: [$fieldId]) {
							edges {
								node {
									... on JiraStatusField {
										transitions {
											edges {
												node {
													hasScreen
													transitionId
													name
													to {
														statusId
														name
														statusCategory {
															statusCategoryId
															name
														}
													}
												}
											}
										}
										status {
											statusId
											name
											statusCategory {
												name
												statusCategoryId
											}
										}
									}
								}
							}
						}
					}
				}
			}
		`,
		vars,
	).toPromise();

	const jiraIssue = result?.jira?.issueByKey;

	if (!jiraIssue?.issueId) {
		throw new Error('failed fetch issue ID');
	}

	const transition = jiraIssue?.fieldsById?.edges?.[0]?.node?.transitions?.edges?.find(
		(opt) => opt?.node?.transitionId === Number(option.transitionId),
	);

	if (!transition) {
		throw new ValidationError("Specify a valid transition for 'Status'");
	}

	const status = jiraIssue?.fieldsById?.edges?.[0]?.node?.status;

	const returnData: FetchMissingIssueAndFieldOptionDataReturn =
		status?.statusId &&
		status?.name &&
		status?.statusCategory &&
		status?.statusCategory.statusCategoryId &&
		status?.statusCategory.name &&
		transition?.node &&
		transition.node.to &&
		transition.node.transitionId &&
		Object.prototype.hasOwnProperty.call(transition?.node, 'hasScreen') &&
		transition?.node.name &&
		transition?.node.to.statusId &&
		transition?.node.to.name &&
		transition?.node.to.statusCategory &&
		transition?.node.to.statusCategory.statusCategoryId &&
		transition?.node.to.statusCategory.name
			? {
					issueId: jiraIssue.issueId,
					status: {
						statusId: status.statusId,
						name: status.name,
						statusCategory: {
							statusCategoryId: status.statusCategory.statusCategoryId,
							name: status.statusCategory.name,
						},
					},
					transition: {
						hasScreen: !!transition.node.hasScreen,
						transitionId: transition.node.transitionId.toString(),
						name: transition.node.name,
						to: {
							statusId: transition.node.to.statusId,
							name: transition.node.to.name,
							statusCategory: {
								statusCategoryId: transition.node.to.statusCategory.statusCategoryId,
								name: transition.node.to.statusCategory.name,
							},
						},
					},
				}
			: null;
	return returnData;
}
