import React from 'react';
import { styled } from '@compiled/react';
import ColorPicker from '@atlaskit/color-picker';
// eslint-disable-next-line import/order
import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { Color, PaletteColor } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import { getDefaultColorPalette } from '@atlassian/jira-issue-epic-color/src/common/utils.tsx';
import messages from './messages';
import { ReadOnlyColorWrapper } from './styled';

type ReadOnlyProps = {
	value: string;
	palette: PaletteColor[];
};
export type Props = {
	isEditable: boolean;
	value: string;
	onChange: (arg1: string) => void;
	onConfirm: () => void;
};

export const ReadOnlyColorPicker = ({ value, palette }: ReadOnlyProps) => {
	const colorLabel = palette.filter((color) => color.value === value)[0].label;
	const { formatMessage } = useIntl();
	const label = formatMessage(messages.readOnlyLabel, { color: colorLabel });
	return (
		<ReadOnlyColorWrapper
			aria-label={label}
			data-testid="issue-view-foundation.color.read-only-color-wrapper"
		>
			<ReadOnlyColourPickerStyle selectedColor={value} />
		</ReadOnlyColorWrapper>
	);
};

export const UnsafeColorPicker = (props: Props) => {
	// static displayName = 'ColorPicker';

	const { formatMessage } = useIntl();

	const handleOnChange = (newColor: Color) => {
		props.onChange(newColor);
		props.onConfirm();
	};

	const colorPalette = getDefaultColorPalette(formatMessage);

	return (
		<ColorPickerWrapper
			// eslint-disable-next-line jira/integration/test-id-by-folder-structure
			data-testid="issue.views.issue-base.foundation.color.color-picker-wrapper"
		>
			{props.isEditable ? (
				<ColorPicker
					label={formatMessage(messages.label)}
					popperProps={{
						positionFixed: true,
						modifiers: {
							// @ts-expect-error - TS2322 - Type '{ offset: { offset: string; }; }' is not assignable to type 'readonly Partial<Modifier<string, object>>[]'.
							offset: {
								offset: '-10 5',
							},
						},
					}}
					cols={colorPalette.length / 2}
					selectedColor={props.value}
					palette={colorPalette}
					// @ts-expect-error - TS2322 - Type '(newColor: Color) => void' is not assignable to type '(value: string, analyticsEvent?: object | undefined) => void'.
					onChange={handleOnChange}
				/>
			) : (
				<ReadOnlyColorPicker value={props.value} palette={colorPalette} />
			)}
		</ColorPickerWrapper>
	);
};

// move 2px to the left to align with other components

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColorPickerWrapper = styled.div({
	marginLeft: token('space.negative.025', '-2px'),
	paddingTop: token('space.100', '8px'),
	paddingRight: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 4}px`,
	/* color picker focused style clashes with some global css style on the page */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'button:focus': {
		boxShadow: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadOnlyColourPickerStyle = styled.div<{ selectedColor: string }>({
	position: 'absolute',
	top: '1px',
	left: '1px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 3}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 3}px`,
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: (props) => props.selectedColor,
});
