import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { User } from '../../model/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toItem = (user: any) => ({
	avatarUrl: user.avatarUrls && user.avatarUrls['48x48'],
	id: user.accountId,
	name: user.displayName,
	byline: user.emailAddress,
	type: 'user',
	fixed: false,
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transform = (value: any) => (value ? toItem(value) : null);

const getUrl = (projectKey: string | undefined, issueKey: string | undefined, query: string) =>
	`/rest/api/3/user/recommend?${new URLSearchParams({
		context: 'People',
		...(projectKey && { project: projectKey }),
		...(issueKey && { issueKey }),
		query,
	})}`;

const searchUser = async (
	projectKey: string | undefined,
	issueKey: string | undefined,
	query: string,
): Promise<User[]> => {
	const response = await fetchJson(getUrl(projectKey, issueKey, query));

	return response.map(transform);
};

export default searchUser;
