import { stickyLineExtraLengthLeft } from '@atlassian/jira-issue-view-sticky-header-container';
import type { PassedDownRefs } from './types';

export const getPanelWidth = (
	refs: PassedDownRefs,
	panelColumnStyle: ReturnType<typeof getComputedStyle> | null,
): number => {
	const sidePanelContainer = refs.refSidePanelContainerElement;
	if (!sidePanelContainer || !(sidePanelContainer instanceof HTMLElement)) {
		return 0;
	}
	return (
		sidePanelContainer.offsetWidth +
		stickyLineExtraLengthLeft -
		(panelColumnStyle ? parseFloat(panelColumnStyle.paddingLeft) : 0)
	);
};

export const getContentWidth = (
	refs: PassedDownRefs,
	panelColumnStyle: ReturnType<typeof getComputedStyle> | null,
): number | undefined => {
	const panelColumn = refs.refPanelColumnElement;
	if (!panelColumn || !panelColumnStyle) {
		return undefined;
	}
	return (
		panelColumn.offsetWidth -
		parseFloat(panelColumnStyle.paddingLeft) -
		parseFloat(panelColumnStyle.paddingRight)
	);
};

export const getHeaderWidth = (
	panelColumnHeader: HTMLElement | null,
	contentWidth?: number,
): number =>
	panelColumnHeader ? panelColumnHeader.offsetWidth : contentWidth ?? 0 + stickyLineExtraLengthLeft;

export const getContextWidth = (refs: PassedDownRefs): number | undefined =>
	refs.refVisibilityContainer ? refs.refVisibilityContainer.offsetWidth : undefined;
