import urlModule from 'url';
import pathToRegexp, { type Key } from 'path-to-regexp';
import { matchPath } from 'react-router';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { FetchConfluenceWhiteboardType } from '@atlassian/jira-issue-create-confluence-content/src/controllers/use-fetch-whiteboard';
import {
	CONFLUENCE_WHITEBOARD,
	type ConfluenceWhiteboard,
} from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';

export const createConfluenceWhiteboardAri = ({
	siteId,
	whiteboardId,
}: {
	siteId: string;
	whiteboardId: string;
}) => `ari:cloud:confluence:${siteId}:whiteboard/${whiteboardId}`;

const matchUrl = (path: string, pattern: string) => {
	const keys: Key[] = [];
	const regexp = pathToRegexp(pattern, keys);
	const result = regexp.exec(path);
	if (!result) {
		return false;
	}
	return true;
};

export const isWhiteboardContentTypeFromUrl = (url: string) => {
	const pattern = '/wiki/spaces/:spaceKey/whiteboard/:contentId(\\d+)';

	const urlObject = new URL(url);
	const path = urlObject.pathname;

	return matchUrl(path, pattern);
};

export const getWhiteboardIdFromUrl = (url: string) => {
	const pattern = '/wiki/spaces/:spaceKey/whiteboard/:contentId(\\d+)';
	const parsed = urlModule.parse(url, true);
	const pathname: string = parsed.pathname ?? '';
	const match = matchPath<{ [key: string]: string }>(pathname, {
		path: pattern,
		exact: true,
	});

	return match?.params.contentId;
};

export const getWhiteboard = async ({
	fetchConfluenceWhiteboard,
	cloudId,
	link,
	embeddedConfluenceSource,
}: {
	fetchConfluenceWhiteboard: FetchConfluenceWhiteboardType;
	cloudId: string;
	link: string;
	embeddedConfluenceSource: string;
}): Promise<ConfluenceWhiteboard | undefined> => {
	const whiteboardAri = createConfluenceWhiteboardAri({
		siteId: cloudId,
		whiteboardId: getWhiteboardIdFromUrl(link) ?? '',
	});
	try {
		const confluenceWhiteboardData = await fetchConfluenceWhiteboard(whiteboardAri);
		const whiteboard: ConfluenceWhiteboard = {
			whiteboardId: confluenceWhiteboardData.whiteboardId,
			href: `${confluenceWhiteboardData.links?.base}${confluenceWhiteboardData.links?.webUi}`,
			author: confluenceWhiteboardData.author,
			title: confluenceWhiteboardData.title,
			id: confluenceWhiteboardData.id,
			type: CONFLUENCE_WHITEBOARD,
		};
		return whiteboard;
	} catch (error) {
		fireErrorAnalytics({
			meta: {
				id: 'fetchConfluenceWhiteboard',
				teamName: 'bento',
				packageName: 'jiraIssueViewBase',
			},
			attributes: { embeddedConfluenceSource },
			error: new Error(`Failed to fetch Confluence whiteboard: ${error}`),
		});
		return undefined;
	}
};
