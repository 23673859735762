import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { trackFetchErrors } from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getEscalatorBaseUrl } from '@atlassian/jira-servicedesk-incident-management-common/src/utils';
import type { IssueId, CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { FETCH_INCIDENT_CAPABILITIES_PROVISION_STATE_ERROR, DONE } from '../../constants';
import type { State, IncidentCapabilitiesProvisionState } from '../../types';

export const fetchIncidentCapabilitiesProvisionState =
	(issueId: IssueId, cloudId: CloudId, analyticsEvent?: UIAnalyticsEvent) =>
	async ({ getState, setState }: StoreActionApi<State>) => {
		const { incidentCapabilitiesProvisionState, provisioningIncidentCapabalities } = getState();

		if (incidentCapabilitiesProvisionState.isLoading || !issueId) {
			return;
		}

		setState({
			incidentCapabilitiesProvisionState: {
				...incidentCapabilitiesProvisionState,
				forIssueId: issueId,
				isLoading: true,
				error: undefined,
			},
		});

		try {
			const response: IncidentCapabilitiesProvisionState = await fetchJson(
				`${getEscalatorBaseUrl(cloudId)}/issue/${issueId}/incident/provision`,
			);

			if (provisioningIncidentCapabalities?.isLoading && response?.provisionState === DONE) {
				setState({
					incidentCapabilitiesProvisionState: {
						data: response,
						forIssueId: issueId,
						isLoading: false,
						error: undefined,
					},
					provisioningIncidentCapabalities: {
						hasUpdateCompleted: true,
						isLoading: false,
						error: undefined,
					},
				});
			} else {
				setState({
					incidentCapabilitiesProvisionState: {
						data: response,
						forIssueId: issueId,
						isLoading: false,
						error: undefined,
					},
				});
			}

			analyticsEvent &&
				fireOperationalAnalytics(
					analyticsEvent,
					'fetchIncidentCapabilitiesProvisionState succeeded',
				);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			trackFetchErrors({
				event: analyticsEvent,
				error: e,
				id: 'fetchIncidentCapabilitiesProvisionState',
				sendToPrivacyUnsafeSplunk: true,
				packageName: 'jiraServicedeskSystemIncidentStatus',
			});

			if (provisioningIncidentCapabalities?.isLoading) {
				setState({
					incidentCapabilitiesProvisionState: {
						data: undefined,
						forIssueId: issueId,
						isLoading: false,
						error: {
							value: e.message,
							type: FETCH_INCIDENT_CAPABILITIES_PROVISION_STATE_ERROR,
						},
					},
					provisioningIncidentCapabalities: {
						hasUpdateCompleted: false,
						isLoading: false,
						error: undefined,
					},
				});
			} else {
				setState({
					incidentCapabilitiesProvisionState: {
						data: undefined,
						forIssueId: issueId,
						isLoading: false,
						error: {
							value: e.message,
							type: FETCH_INCIDENT_CAPABILITIES_PROVISION_STATE_ERROR,
						},
					},
				});
			}
		}
	};
